import { colorAction } from "../constants/ActionConstants"

export const ColorActions = {
  colorSuccess,
  fontColorSuccess,
  setColorSuccess
}

export function colorSuccess(response) {
  return { type: colorAction.COLOR_SUCCESS, response }
}

export function fontColorSuccess(response) {
  return { type: colorAction.FONT_COLOR_SUCCESS, response }
}
export function setColorSuccess(response) {
  // console.log()
  return { type: colorAction.SET_COLOR_SUCCESS, response }
}
