import { apiAction } from '../constants/ActionConstants';

const initialState = {
    apiStatus: 0,
};

export function loader(state = initialState, action) {
    switch (action.type) {
        case apiAction.BEGIN_API_CALL:
            return {
                apiStatus: state.apiStatus + 1,
            };
        case apiAction.END_API_CALL:
            return {
                apiStatus: state.apiStatus > 0 ? state.apiStatus - 1 : 0,
            };
        default:
            return state;
    }
}
