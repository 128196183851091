import * as alertActions from "./AlertActions"
import * as userActions from "./UserActions"
import * as personalDetailsActions from "./KYC/PersonalDetailsActions"
import * as enumOptionsActions from "./EnumOptionsActions"
import * as netWorthActions from "./KYC/NetWorth/NetWorthActions"
import * as netWorthFormActions from "./KYC/NetWorth/NetWorthFormActions"
import * as insuranceActions from "./KYC/InsuranceActions"
import * as goalActions from "./KYC/Goal/GoalActions"
import * as modalWindowActions from "./ModalWindowActions"
import * as appSettingsActions from "./AppSettingsActions"
import * as manageAccessLevelActions from "./ManageAccessLevelActions"
import * as entityActions from "./EntityActions"
import * as dashboardActions from "./DashboardActions"
import * as fileOperationActions from "./FileOperationActions"
import * as feedbackActions from "./FeedbackActions"
import * as currentStandingActions from "./CurrentStanding/CurrentStandingActions"
import * as cashFlowActions from "./KYC/CashFlowActions"
import * as emailActions from "./Email/EmailActions"
import * as colorActions from "./ColorAction"
import * as implementationActions from "./ImplementationActions"
import * as scenariosActions from "./LifeStyleBuilder/ScenarioAction"

export const ActionCreators = Object.assign(
  {},
  alertActions,
  userActions,
  personalDetailsActions,
  enumOptionsActions,
  netWorthActions,
  netWorthFormActions,
  insuranceActions,
  goalActions,
  modalWindowActions,
  appSettingsActions,
  manageAccessLevelActions,
  entityActions,
  dashboardActions,
  fileOperationActions,
  feedbackActions,
  currentStandingActions,
  cashFlowActions,
  emailActions,
  colorActions,
  implementationActions,
  scenariosActions
)

export default ActionCreators
