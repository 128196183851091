import React from 'react';
import intl from 'react-intl-universal';

function PensionBeneficaryDetails() {
    return (
        <table
            className="table-investment"
            width="100%"
            cellSpacing="0"
            cellPadding="0"
            border="0"
        >
            <tbody>
                <tr>
                    <th>
                        {intl.get(
                            'netWorth.investmentDetails.beneficiaryDetails.name'
                        )}
                    </th>
                    <th>
                        {intl.get(
                            'netWorth.investmentDetails.beneficiaryDetails.relationship'
                        )}
                    </th>
                    <th>
                        {intl.get(
                            'netWorth.investmentDetails.beneficiaryDetails.contact'
                        )}
                    </th>
                    <th>
                        {intl.get(
                            'netWorth.investmentDetails.beneficiaryDetails.email'
                        )}
                    </th>
                    <th>&nbsp;</th>
                    <th>
                        <button type="button" className="btn btn-plus">
                            &nbsp;
                        </button>
                    </th>
                </tr>
                <tr>
                    <td>Eliza Lam</td>
                    <td>Daughter</td>
                    <td>
                        <div className="telformat">
                            <input
                                className="text-input text-input-countryarea"
                                placeholder="Country"
                                type="text"
                            />
                            <div className="dash">-</div>
                            <input
                                className="text-input text-input-countryarea"
                                placeholder="Area"
                                type="text"
                            />
                            <div className="dash">-</div>
                            <input
                                className="text-input text-input-phonenumber"
                                placeholder="Number"
                                type="text"
                            />
                        </div>
                    </td>
                    <td>
                        <a href="mailto:XYZ@gmail.com">XYZ@gmail.com</a>
                    </td>
                    <td>
                        <button type="button" className="btn-remove">
                            &nbsp;
                        </button>
                    </td>
                    <td>&nbsp;</td>
                </tr>
            </tbody>
        </table>
    );
}

export default PensionBeneficaryDetails;
