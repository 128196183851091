import React, { useState } from "react";
import { getSymbolFromCode } from '../../helpers';
import Get from "../../helpers/NullHelper";
import intl from 'react-intl-universal';
import RenderSimpleFields, {
    FieldType,
} from '../../components/Form/RenderSimpleFields';
import LoanApi from "../../api/LoanApi";


const CarLeasing = ({ optionsData, readOnly, defaultCurrency, model, mutators}) => {
    const {
        currencyOptions,
        frequencyOptions,
        loanFrequencyOptions,
    } = optionsData;

    

    function calculateRepaymentAmount(){
        let data = {
            principal: Get(model, "carLease.financingAmountBorrowed"),
            interestRate: Get(model, "carLease.financingAnnualInterestRate"),
            startDate : Get(model, "carLease.financingStartDate"),
            term: Get(model, "carLease.financingTermInMonths"),
            termFreq: 'MONTHLY',
            paymentFreq: Get(model, "carLease.financingPaymentFrequency"),
            balloonPayment:Get(model, "carLease.residualValue"),

        }
        LoanApi.postCalculateRepayment(data)
        .then((res) => {
            if(res.data){
                mutators.setFormAttribute("carLease.financingPayments", res.data.repaymentAmount);
            }
            
        })
      
    }

    const [currency, setCurrency] = useState({
        name: Get(model, "carLease.borrowingsCurrency") || defaultCurrency,
        symbol: getSymbolFromCode(
            Get(model, "carLease.borrowingsCurrency") || defaultCurrency
        )
    });

   
    const onCurrencyChanged = value => {
        setCurrency({ name: value, symbol: getSymbolFromCode(value) });
    };

    function recalculateAmountBorrowed(purchasePrice, downPayment){
        let borrowedAmount = (purchasePrice?purchasePrice:0) - (downPayment?downPayment:0);
        mutators.setFormAttribute("carLease.financingAmountBorrowed", borrowedAmount);
    }

    const onPurchasePriceChanged = value => {
        recalculateAmountBorrowed(value, Get(model, "carLease.downPayment"))
    };

    const onDownPaymentChanged = value => {
        recalculateAmountBorrowed(Get(model, "carLease.financingPurchasePrice"), value);
    };

  

    return (
    <div className="pop-holder">
        <RenderSimpleFields
            fields={[
                {
                    name: 'carLease.financialInstitutionName',
                    label: intl.get('field.financialInstitutionName'),
                    type: FieldType.TEXT,
                    disabled: readOnly,
                    required: true,
                },
                {
                    name: 'carLease.financingLocation',
                    type: FieldType.SELECTION,
                    label: intl.get('field.financingLocation', {
                        financingType: intl.get('field.carLease'),
                    }),
                    selectionData: optionsData.countryOptions,
                    valueProperty: 'code',
                    labelProperty: 'name',
                    disabled: readOnly,
                },
                {
                    name: 'carLease.financingPartOfSalaryPackaging',
                    label: intl.get('field.isFinancingPartOfSalaryPackaging'),
                    checkboxLabel: intl.get('YesNoEnum_YES'),
                    type: FieldType.CHECKBOX,
                    disabled: readOnly,
                },
                {
                    name: 'carLease.annualKilometres',
                    label: intl.get('field.annualKilometres'),
                    type: FieldType.NUMBER,
                    disabled: readOnly,
                },
                {
                    name: 'carLease.typeOfLease',
                    label: intl.get('field.typeOfLease'),
                    type: FieldType.ENUM_SELECTION,
                    enumData: optionsData.leaseTypeOptions,
                    disabled: readOnly,
                },
                {
                    name: "carLease.borrowingsCurrency",
                    label: intl.get("field.currency"),
                    type: FieldType.ENUM_SELECTION,
                    enumData: currencyOptions,
                    onChange: onCurrencyChanged,
                    disabled: readOnly,
                    required: true
                },
                {
                    name: 'carLease.financingPurchasePrice',
                    label: intl.get('field.financingPurchasePrice'),
                    type: FieldType.MONEY,
                    symbolPrefix: currency.symbol,
                    defaultCurrency: currency.name,
                    disabled: readOnly,
                    allowNegative: false,
                    required: true,
                    onChange: onPurchasePriceChanged,
                },
                {
                    name: 'carLease.downPayment',
                    label: intl.get('field.downPayment'),
                    type: FieldType.MONEY,
                    symbolPrefix: currency.symbol,
                    defaultCurrency: currency.name,
                    disabled: readOnly,
                    allowNegative: false,
                    onChange: onDownPaymentChanged,
                },
                {
                    name: 'carLease.financingAmountBorrowed',
                    label: intl.get('field.financingAmountBorrowed'),
                    type: FieldType.MONEY,
                    symbolPrefix: currency.symbol,
                    defaultCurrency: currency.name,
                    readOnly: true,
                    allowNegative: false
                },
                
                {
                    name: 'carLease.residualValue',
                    label: intl.get('field.leaseResidualValue'),
                    type: FieldType.MONEY,
                    symbolPrefix: currency.symbol,
                    defaultCurrency: currency.name,
                    disabled: readOnly,
                    allowNegative: false
                },
               
                {
                    name: 'carLease.financingStartDate',
                    label: intl.get('field.financingStartDate', {
                        financingType: intl.get('field.leasing'),
                    }),
                    type: FieldType.DATE,
                    disabled: readOnly,
                    required: true,
                },
                {
                    name: 'carLease.financingTermInMonths',
                    label: intl.get('field.financingTermInMonths', {
                        financingType: intl.get('field.lease'),
                    }),
                    type: FieldType.NUMBER,
                    disabled: readOnly,
                    required: true,
                },
                {
                    name: 'carLease.inArrear',
                    label: intl.get('field.inArrear'),
                    checkboxLabel: intl.get('YesNoEnum_YES'),
                    type: FieldType.CHECKBOX,
                },
                {
                    name: 'carLease.financingAnnualInterestRate',
                    label: intl.get('field.financingAnnualInterestRate', {
                        financingType: intl.get('field.lease'),
                    }),
                    type: FieldType.PERCENTAGE,
                    disabled: readOnly,
                    required:true,
                },
                {
                    name: 'carLease.financingPaymentFrequency',
                    label: intl.get('field.financingPaymentFrequency', {
                        financingType: intl.get('field.lease'),
                    }),
                    type: FieldType.ENUM_SELECTION,
                    enumData: optionsData.loanFrequencyOptions,
                    disabled: readOnly,
                    required: true,
                },
                {
                    name: 'carLease.financingPayments',
                    label: intl.get('field.monthlyPaymentAmount', {
                        financingType: intl.get('field.lease'),
                    }),
                    type: FieldType.MONEY,
                    symbolPrefix: currency.symbol,
                    defaultCurrency: currency.name,
                    readOnly: true,
                    allowNegative: false,
                    popupPickerTitle:'calculate',
                    popupPickerEvent: e => calculateRepaymentAmount()
                },
                
                
               
            ]}
        />
    </div>
    );
}

export default CarLeasing;
