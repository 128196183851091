import React, { useEffect, useState } from "react"
import {
  DefaultPieChartLegendsOptions,
  PieLegendsForcedLeftOptions
} from "../../../helpers/ChartHelper"
import { Doughnut } from "react-chartjs-2"
import { classificationEnumsAllLowercase } from "../../../utils/enums/Enum"
import { getSymbolFromCode } from "../../../helpers"
import { DECIMAL_SCALE } from "../../../constants/NumberFormatConstants"

const AumSummary = ({ currency, totalSummary }) => {
  const emptyChartProps = {
    data: {
      labels: [""],
      datasets: [
        {
          data: [0.1],
          backgroundColor: ["#dadada"]
        }
      ]
    },

    options: {
      legend: {
        display: false,
        labels: {
          display: false
        }
      },
      tooltips: {
        enabled: false
      }
    },
    height: 400
  }
  const [doughnutData, setDoughnutData] = useState()

  useEffect(() => {
    const chartData = {
      data: {
        labels: Object.keys(totalSummary).map((item) => {
          return `${classificationEnumsAllLowercase[item]}`
        }),

        datasets: [
          {
            data: Object.values(totalSummary).map((item) => {
              return `${item.aum.toFixed(DECIMAL_SCALE)}`
            }),
            label: "AUM",
            backgroundColor: ["#216082", "#E97132", "#376C25", "#7A3E1D"]
          }
        ]
      }
    }
    setDoughnutData(chartData.data)
  }, [totalSummary])

  const [allZero, setAllZero] = useState(true)
  useEffect(() => {
    if (doughnutData) {
      let allZeroValue =
        doughnutData &&
        doughnutData.datasets &&
        doughnutData.datasets[0].data.every((number) => number === 0)
      setAllZero(allZeroValue)
    } else {
      setAllZero(true)
    }
  }, [doughnutData])
  const [chartProps, setChartProps] = useState({
    data: {
      labels: [""],
      datasets: [
        {
          data: [0.1],
          backgroundColor: ["#dadada"]
        }
      ]
    },

    options: {
      legend: {
        display: false,
        labels: {
          display: false
        }
      },
      tooltips: {
        enabled: false
      }
    },
    height: 400
  })

  useEffect(() => {
    if (doughnutData) {
      let chartPropsItem = {
        data: doughnutData,
        options: {
          legend: {
            position: "bottom",
            align: "start",
            fullWidth: true,

            ...DefaultPieChartLegendsOptions,
            ...PieLegendsForcedLeftOptions
          },
          maintainAspectRatio: false,
          tooltips: {
            enabled: true,
            callbacks: {
              // 20200717 MABT 359
              title: function (tooltipItem, data) {
                return data["labels"][tooltipItem[0]["index"]]
              },

              label: (tooltipItem, cData) => {
                const { index } = tooltipItem
                const { datasets } = cData
                const [dataset] = datasets

                const value = dataset.data[index] || 0
                return `${getSymbolFromCode(currency)}${Number(value).toFixed(
                  DECIMAL_SCALE
                )}`
              }
            }
          },
          scales: {}
        },
        height: 400
      }
      setChartProps(chartPropsItem)
    }
  }, [doughnutData])
  return (
    <div className="mt-2">
      {allZero ? (
        <Doughnut {...{ ...emptyChartProps, height: 400 }} />
      ) : (
        <Doughnut {...{ ...chartProps, height: 400 }} />
      )}
    </div>
  )
}

export default AumSummary
