import intl from 'react-intl-universal';
import RenderSimpleFields, {
    FieldType,
} from '../../components/Form/RenderSimpleFields';

import React, { useState } from "react";
import { getSymbolFromCode } from '../../helpers';
import Get from "../../helpers/NullHelper";
import LoanApi from '../../api/LoanApi';

const CarFinancing = ({ optionsData, readOnly, defaultCurrency, model, mutators}) => {
    const {
        currencyOptions,
        frequencyOptions,
    } = optionsData;

    function calculateRepaymentAmount(){
        let data = {
            principal: Get(model, "hirePurchase.financingAmountBorrowed"),
            interestRate: Get(model, "hirePurchase.financingAnnualInterestRate"),
            startDate : Get(model, "hirePurchase.financingStartDate"),
            term: Get(model, "hirePurchase.financingTermInMonths"),
            termFreq: 'MONTHLY',
            paymentFreq: 'MONTHLY',
            balloonPayment:Get(model, "hirePurchase.residualValue"),

        }
        LoanApi.postCalculateRepayment(data)
        .then((res) => {
            if(res.data){
                mutators.setFormAttribute("hirePurchase.financingPayments", res.data.repaymentAmount);
            }
            
        })
      
    }

    const [currency, setCurrency] = useState({
        name: Get(model, "hirePurchase.borrowingsCurrency") || defaultCurrency,
        symbol: getSymbolFromCode(
            Get(model, "hirePurchase.borrowingsCurrency") || defaultCurrency
        )
    });

    const onCurrencyChanged = value => {
        setCurrency({ name: value, symbol: getSymbolFromCode(value) });
    };

    return (

    <div className="pop-holder">
        <RenderSimpleFields
            fields={[
                {
                    name: 'hirePurchase.financialInstitutionName',
                    label: intl.get('field.financialInstitutionName'),
                    type: FieldType.TEXT,
                    disabled: readOnly,
                    required: true,
                },
                {
                    name: 'hirePurchase.financingLocation',
                    type: FieldType.SELECTION,
                    label: intl.get('field.financingLocation', {
                        financingType: intl.get('field.hirePurchase'),
                    }),
                    selectionData: optionsData.countryOptions,
                    valueProperty: 'code',
                    labelProperty: 'name',
                    disabled: readOnly,
                },
                {
                    name: 'hirePurchase.annualKilometres',
                    label: intl.get('field.annualKilometres'),
                    type: FieldType.NUMBER,
                    disabled: readOnly,
                },
                {
                    name: "hirePurchase.borrowingsCurrency",
                    label: intl.get("field.currency"),
                    type: FieldType.ENUM_SELECTION,
                    enumData: currencyOptions,
                    onChange: onCurrencyChanged,
                    disabled: readOnly,
                    required: true
                },
                {
                    name: 'hirePurchase.financingAmountBorrowed',
                    label: intl.get('field.financingAmountBorrowed'),
                    type: FieldType.MONEY,
                    symbolPrefix: currency.symbol,
                    defaultCurrency: currency.name,
                    disabled: readOnly,
                    required: true,
                    allowNegative: false
                    
                },
                
                {
                    name: 'hirePurchase.residualValue',
                    label: intl.get('field.residualBalloonValue'),
                    type: FieldType.MONEY,
                    symbolPrefix: currency.symbol,
                    defaultCurrency: currency.name,
                    disabled: readOnly,
                    allowNegative: false
                },
                {
                    name: 'hirePurchase.financingAnnualInterestRate',
                    label: intl.get('field.financingAnnualInterestRate', {
                        financingType: intl.get('field.hirePurchase'),
                    }),
                    type: FieldType.PERCENTAGE,
                    disabled: readOnly,
                    required:true,
                },
                {
                    name: 'hirePurchase.financingStartDate',
                    label: intl.get('field.financingStartDate', {
                        financingType: intl.get('field.hirePurchase'),
                    }),
                    type: FieldType.DATE,
                    disabled: readOnly,
                    required: true,
                },
                {
                    name: 'hirePurchase.financingTermInMonths',
                    label: intl.get('field.financingTermInMonths', {
                        financingType: intl.get('field.hirePurchase'),
                    }),
                    type: FieldType.NUMBER,
                    disabled: readOnly,
                    required: true,
                },
                {
                    name: 'hirePurchase.inArrear',
                    label: intl.get('field.inArrear'),
                    checkboxLabel: intl.get('YesNoEnum_YES'),
                    type: FieldType.CHECKBOX,
                },
                // {
                //     name: 'hirePurchase.financingPaymentFrequency',
                //     label: intl.get('field.financingPaymentFrequency', {
                //         financingType: intl.get('field.hirePurchase'),
                //     }),
                //     type: FieldType.ENUM_SELECTION,
                //     enumData: optionsData.frequencyOptions,
                //     disabled: readOnly,
                //     required: true,
                // },
                
                {
                    name: 'hirePurchase.financingPayments',
                    label: intl.get('field.financingPayments', {
                        financingType: intl.get('field.hirePurchase'),
                    }),
                    type: FieldType.MONEY,
                    symbolPrefix: currency.symbol,
                    defaultCurrency: currency.name,
                    disabled: true,
                    allowNegative: false,
                    popupPickerTitle:'calculate',
                    popupPickerEvent: e => calculateRepaymentAmount()
                },
                
               
               
            ]}
        />
    </div>
    );
}

export default CarFinancing;
