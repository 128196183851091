import React, { useState } from "react"
import intl from "react-intl-universal"
import { CgNotes } from "react-icons/cg"
import { TiGroup } from "react-icons/ti"
import { FaFolderOpen } from "react-icons/fa"
import { RiTodoFill } from "react-icons/ri"
import { Editor } from "react-draft-wysiwyg"
import draftToHtml from "draftjs-to-html"

import { EditorState, convertToRaw } from "draft-js"
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { NoteCategoryEnum } from "../../constants/Enums"
import { notesActions } from "../../actions"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import Alert from "react-s-alert"
import PastFileNotes from "./PastFileNotes"
const Notes = ({ user, closeModal, addNotes, getNotes, setModal, name }) => {
  const getDate = (date) =>
    date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric"
    })
  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!subject) {
      Alert.error("Enter the subject")
      return
    }
    if (draftToHtml(convertToRaw(editorState.getCurrentContent())).length < 9) {
      Alert.error("Write the note please")
      return
    }

    const submittedData = {
      clientId: user.id,
      subject,
      dateSent: getDate(dateSent),
      deadline: completedBy,
      type: notesType,
      place: place,
      description: draftToHtml(convertToRaw(editorState.getCurrentContent()))
    }
    // await getNotes(user.id);
    await addNotes(submittedData)
    setSubject("")
    setEditorState(EditorState.createEmpty())
    setPlace("")
    closeModal()
    // await closeModal();
  }
  const [subject, setSubject] = useState("")
  const [place, setPlace] = useState("")
  const [completedBy, setCompletedBy] = useState("")
  const [dateSent] = useState(new Date())
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [notesType, setNotesType] = useState(NoteCategoryEnum.GENERAL_NOTES)
  const handleCheck = (event, name) => {
    setNotesType(event)
    document.querySelectorAll(".feedback-icon").forEach((a) => {
      a.classList.remove("addstyle")
    })
    document.querySelector(`.${event}`).classList.toggle("addstyle")
  }

  return (
    <>
      <div
        className="portal-form-popup"
        style={{ overflow: "hidden", height: "auto", minWidth: "800px" }}
      >
        <form
          className="container-feedback"
          style={{ maxWidth: "100%" }}
          onSubmit={handleSubmit}
        >
          <div className="portal-form-feedback">
            {intl.get("note.selectNotesCategory")}
            <div className="feedback-icon-holder notes-icon-holder">
              <span
                className="feedback-icon notes-1 GENERAL_NOTE addstyle"
                onClick={() => {
                  handleCheck(NoteCategoryEnum.GENERAL_NOTES)
                }}
                style={{ cursor: "pointer" }}
              >
                <CgNotes />
                <div className="text">
                  {intl.get("NoteCategoryEnum_GENERAL_NOTES")}
                </div>
              </span>
              <span
                className="feedback-icon notes-1 MEETING_NOTE"
                style={{ cursor: "pointer" }}
                onClick={() => handleCheck(NoteCategoryEnum.MEETING_NOTES)}
              >
                <TiGroup />
                <span className="text">
                  {intl.get("NoteCategoryEnum_MEETING_NOTES")}
                </span>
              </span>
              <span
                className="feedback-icon notes-1 TO_DO"
                onClick={() => handleCheck(NoteCategoryEnum.TODO)}
                style={{ cursor: "pointer" }}
              >
                <RiTodoFill />
                <span className="text">
                  {intl.get("NoteCategoryEnum_TODO")}
                </span>
              </span>
              <span
                className="feedback-icon notes-1 PAST_FILE_NOTES"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setModal({
                    title: "Past File Notes",
                    content: (
                      <PastFileNotes
                        userID={user.id}
                        getNotes={getNotes}
                        clearModal={closeModal}
                        setModal={setModal}
                        user={user}
                        name={name}
                        addNotes={addNotes}
                      />
                    ),
                    isSmall: true
                  })
                }}
              >
                <FaFolderOpen />

                <span className="text">
                  {intl.get("NoteCategoryEnum_PAST_FILE_NOTES")}
                </span>
              </span>
            </div>{" "}
            <div className="feedback-content">
              <div className="portal-form-row feedback-form-row">
                {" "}
                <label>{intl.get("Note.field.clientName")}:</label>
                <div className="row-holder">
                  {name ? name : user.info.clientName}
                </div>
              </div>

              <div className="portal-form-row feedback-form-row">
                {" "}
                <label>{intl.get("Note.field.subject")}:</label>
                <div className="row-holder">
                  <input
                    className={`text-input ${
                      user.info.clientCategoryStatus === "Archive" && "disabled"
                    }`}
                    type="text"
                    value={subject}
                    placeholder="Enter the subject"
                    onChange={(e) => setSubject(e.target.value)}
                    disabled={user.info.clientCategoryStatus === "Archive"}
                  />
                </div>
              </div>
              {notesType === "MEETING_NOTE" && (
                <div className="portal-form-row feedback-form-row">
                  {" "}
                  <label>{intl.get("Note.field.place")}:</label>
                  <div className="row-holder">
                    <input
                      className={`text-input ${
                        user.info.clientCategoryStatus === "Archive" &&
                        "disabled"
                      }`}
                      type="text"
                      value={place}
                      placeholder="Place of taking note..."
                      onChange={(e) => setPlace(e.target.value)}
                      disabled={user.info.clientCategoryStatus === "Archive"}
                      required
                    />
                  </div>
                </div>
              )}

              <div className="portal-form-row feedback-form-row">
                <label>{intl.get("feedback.field.date")}:</label>
                <div className="row-holder">{getDate(dateSent)}</div>
              </div>
              <div className="portal-form-row feedback-form-row">
                <label>Deadline:</label>
                <div className="row-holder">
                  <input
                    className={`text-input ${
                      user.info.clientCategoryStatus === "Archive" && "disabled"
                    }`}
                    type="datetime-local"
                    value={completedBy}
                    onChange={(e) => setCompletedBy(e.target.value)}
                    disabled={user.info.clientCategoryStatus === "Archive"}
                  />
                </div>
              </div>
              <div className="portal-form-row feedback-form-row">
                <label>{intl.get("Note.field.noteType")}:</label>
                <div className="row-holder">
                  {notesType === NoteCategoryEnum.GENERAL_NOTES
                    ? intl.get("NoteCategoryEnum_GENERAL_NOTES")
                    : notesType === NoteCategoryEnum.MEETING_NOTES
                    ? intl.get("NoteCategoryEnum_MEETING_NOTES")
                    : intl.get("NoteCategoryEnum_TODO")}
                </div>
              </div>
              <Editor
                editorState={editorState}
                toolbarClassName="demo-toolbar-custom demo-toolbar-custom1"
                wrapperClassName="demo-wrapper"
                readOnly={user.info.clientCategoryStatus === "Archive"}
                editorClassName="demo-editor"
                onEditorStateChange={(editorState) =>
                  setEditorState(editorState)
                }
                placeholder="Type here..."
                toolbar={{
                  options: [
                    "inline",
                    "blockType",
                    "fontSize",
                    // "fontFamily",
                    "list"
                    // "textAlign",
                    // "colorPicker",
                    // "link",
                    // "embedded",
                    // "emoji",
                    // "image",
                    // "remove",
                    // "history"
                  ],
                  inline: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: ["bold", "italic", "underline", "strikethrough"]

                    // monospace: { icon: monospace, className: undefined },
                    // superscript: { icon: superscript, className: undefined },
                    // subscript: { icon: subscript, className: undefined },
                  },
                  list: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: ["unordered", "ordered"]
                  }
                }}
              />
            </div>
          </div>
          <div className="portal-btn-popup">
            <div className="col-half">
              <input
                className="portal-btn portal-btn-s portal-btn-cancel"
                value={intl.get("button.cancel")}
                type="reset"
                onClick={closeModal}
              />
            </div>
            <div className="col-half">
              <input
                className={`portal-btn portal-btn-s portal-btn-submit ${
                  user.info.clientCategoryStatus === "Archive" && "disabled"
                } `}
                value={intl.get("button.save")}
                type="submit"
                disabled={user.info.clientCategoryStatus === "Archive"}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, notesActions), dispatch)
}
export default connect(null, mapDispatchToProps)(Notes)
