import React, { useContext } from 'react';
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem } from 'rc-menu';
import { AppContext } from '../../../context/AppContext';
import { enumOptionsActions } from '../../../actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CPFTermDeposit from './CPFTermDeposit';
import CPFCashAccount from './CPFCashAccount';
import CPFUnitTrust from './CPFUnitTrust';
import CPFBond from './CPFBond';
import CPFShare from './CPFShare';
import CPFTreasuryBills from './CPFTreasuryBills';
import CPFExchangeTrade from './CPFExchangeTrade';
import CPFInvestmentLink from './CPFInvestmentLink';
import CPFPropertyFund from './CPFPropertyFund';
import CPFGold from './CPFGold';

function CPFInvestmentAllocationTable({
    mutators,
    model,
    fieldName
}) {
    const {
        modal: { setModal, clearModal },
    } = useContext(AppContext);


    const menuItems = () => {
        return [
            { label: 'Cash Account', value: 'Cash Account' },
            { label: 'Term Deposit', value: 'Term Deposit' },
            { label: 'Shares', value: 'Shares' },
            { label: 'Unit Trusts', value: 'Unit Trusts' },
            { label: 'Bonds', value: 'Bonds' },
            { label: 'Treasury Bills', value: 'Treasury Bills' },
            { label: 'Property Fund', value: 'Property Fund' },
            { label: 'Gold', value: 'Gold' },
            { label: 'Insurance Linked Products', value: 'Insurance Linked Products' },
            { label: 'Exchange Trade Fund (ETF)', value: 'Exchange Trade Fund (ETF)' },
        ]
    };

    const editItem = (name, value, idx) => {
        switch (name) {
            case 'Cash Account':
                var handleSaveCA = (model) => {
                    mutators.update(`${fieldName}.cashAccounts`, idx, model);
                    clearModal();
                }
                setModal({
                    title: 'Cash Account Information – CPFIS Singapore',
                    content: <CPFCashAccount handleSave={handleSaveCA} model={Object.assign(value, { classification: model.classification })} />,
                    formType: 'CPFModal',
                });
                break;
            case 'Term Deposit':
                var handleSaveTD = (model) => {
                    mutators.update(`${fieldName}.termDeposits`, idx, model);
                    clearModal();
                }
                setModal({
                    title: 'Term Deposit Information – CPFIS Singapore',
                    content: <CPFTermDeposit handleSave={handleSaveTD} model={value} />,
                    formType: 'CPFModal',
                });
                break;
            case 'Shares':
                const handleSaveS = (model) => {
                    mutators.update(`${fieldName}.shares`, idx, model);
                    clearModal();
                }
                setModal({
                    title: 'Share Information – CPFIS Singapore',
                    content: <CPFShare handleSave={handleSaveS} model={value} />,
                    formType: 'CPFModal',
                });
                break;
            case 'Unit Trusts':
                var handleSaveUT = (model) => {
                    mutators.update(`${fieldName}.unitTrusts`, idx, model);
                    clearModal();
                }
                setModal({
                    title: 'Unit Trust Holding Information – CPFIS Singapore',
                    content: <CPFUnitTrust handleSave={handleSaveUT} model={value} />,
                    formType: 'CPFModal',
                });
                break;
            case 'Bonds':
                var handleSaveB = (model) => {
                    mutators.update(`${fieldName}.bonds`, idx, model);
                    clearModal();
                }
                setModal({
                    title: 'Bonds Holding Information – CPFIS Singapore',
                    content: <CPFBond handleSave={handleSaveB} model={Object.assign(value, { classification: model.classification })} />,
                    formType: 'CPFModal',
                });
                break;
            case 'Treasury Bills':
                const handleSaveTB = (model) => {
                    mutators.update(`${fieldName}.treasuryBills`, idx, model);
                    clearModal();
                }
                setModal({
                    title: 'Treasury Bills Information – CPFIS Singapore',
                    content: <CPFTreasuryBills handleSave={handleSaveTB} model={value} />,
                    formType: 'CPFModal',
                });
                break;
            case 'Property Fund':
                const handleSavePF = (model) => {
                    mutators.update(`${fieldName}.propertyFunds`, idx, model);
                    clearModal();
                }
                setModal({
                    title: 'Property Fund Information – CPFIS Singapore',
                    content: <CPFPropertyFund handleSave={handleSavePF} model={value} />,
                    formType: 'CPFModal',
                });
                break;
            case 'Gold'://blm ada
                const handleSaveG = (model) => {
                    mutators.update(`${fieldName}.golds`, idx, model);
                    clearModal();
                }
                setModal({
                    title: 'Gold Investment Information – CPFIS Singapore',
                    content: <CPFGold handleSave={handleSaveG} model={value} />,
                    formType: 'CPFModal',
                });
                break;
            case 'Exchange Trade Fund (ETF)':
                const handleSaveETF = (model) => {
                    mutators.update(`${fieldName}.etfList`, idx, model);
                    clearModal();
                }
                setModal({
                    title: 'Exchange Trade Fund  (ETF) Holding Information – CPFIS Singapore',
                    content: <CPFExchangeTrade handleSave={handleSaveETF} model={value} />,
                    formType: 'CPFModal',
                });
                break;
            case 'Insurance Linked Products':
                const handleSaveILP = (model) => {
                    mutators.update(`${fieldName}.ilasList`, idx, model);
                    clearModal();
                }
                setModal({
                    title: 'Exchange Trade Fund  (ETF) Holding Information – CPFIS Singapore',
                    content: <CPFInvestmentLink handleSave={handleSaveILP} model={value} />,
                    formType: 'CPFInvestmentLink',
                });
                break;
            default: break;

        }

    }


    const deleteItem = (name, idx) => {
        switch (name) {
            case 'Cash Account':
                mutators.remove(`${fieldName}.cashAccounts`, idx);
                break;
            case 'Term Deposit':
                mutators.remove(`${fieldName}.termDeposits`, idx);
                break;
            case 'Shares':
                mutators.remove(`${fieldName}.shares`, idx);
                break;
            case 'Unit Trusts':
                mutators.remove(`${fieldName}.unitTrusts`, idx);
                break;
            case 'Bonds':
                mutators.remove(`${fieldName}.bonds`, idx);
                break;
            case 'Treasury Bills':
                mutators.remove(`${fieldName}.treasuryBills`, idx);
                break;
            case 'Property Fund':
                mutators.remove(`${fieldName}.propertyFunds`, idx);
                break;
            case 'Gold':
                mutators.remove(`${fieldName}.golds`, idx);
                break;
            case 'Exchange Trade Fund (ETF)':
                mutators.remove(`${fieldName}.etfList`, idx);
                break;
            case 'Insurance Linked Products':
                mutators.remove(`${fieldName}.ilasList`, idx);
                break;
            default: break;

        }
    }
    const showModal = (value) => {
        switch (value.value) {
            case 'Cash Account':
                const handleSaveCA = (model) => {
                    mutators.push(`${fieldName}.cashAccounts`, model);
                    clearModal();
                }
                setModal({
                    title: 'Cash Account Information – CPFIS Singapore',
                    content: <CPFCashAccount handleSave={handleSaveCA} model={{ classification: model.classification }} />,
                    formType: 'CPFModal',
                });
                break;
            case 'Term Deposit':
                const handleSaveTD = (model) => {
                    mutators.push(`${fieldName}.termDeposits`, model);
                    clearModal();
                }
                setModal({
                    title: 'Term Deposit Information – CPFIS Singapore',
                    content: <CPFTermDeposit handleSave={handleSaveTD} model={{ classification: model.classification }} />,
                    formType: 'CPFModal',
                });
                break;
            case 'Shares': //blm ada
                const handleSaveS = (model) => {
                    mutators.push(`${fieldName}.shares`, model);
                    clearModal();
                }
                setModal({
                    title: 'Term Deposit Information – CPFIS Singapore',
                    content: <CPFShare handleSave={handleSaveS} model={{ classification: model.classification }} />,
                    formType: 'CPFModal',
                });
                break;
            case 'Unit Trusts':
                const handleSaveUT = (model) => {
                    mutators.push(`${fieldName}.unitTrusts`, model);
                    clearModal();
                }
                setModal({
                    title: 'Unit Trust Holding Information – CPFIS Singapore',
                    content: <CPFUnitTrust handleSave={handleSaveUT} model={{ classification: model.classification }} />,
                    formType: 'CPFModal',
                });
                break;
            case 'Bonds':
                const handleSaveB = (model) => {
                    mutators.push(`${fieldName}.bonds`, model);
                    clearModal();
                }
                setModal({
                    title: 'Bonds Holding Information – CPFIS Singapore',
                    content: <CPFBond handleSave={handleSaveB} model={{ classification: model.classification }} />,
                    formType: 'CPFModal',
                });
                break;
            case 'Treasury Bills':
                const handleSaveTB = (model) => {
                    mutators.push(`${fieldName}.treasuryBills`, model);
                    clearModal();
                }
                setModal({
                    title: 'Treasury Bills Information – CPFIS Singapore',
                    content: <CPFTreasuryBills handleSave={handleSaveTB} model={{ classification: model.classification }} />,
                    formType: 'CPFModal',
                });
                break;
            case 'Property Fund': //blm ada
                const handleSavePF = (model) => {
                    mutators.push(`${fieldName}.propertyFunds`, model);
                    clearModal();
                }
                setModal({
                    title: 'Property Fund Information – CPFIS Singapore',
                    content: <CPFPropertyFund handleSave={handleSavePF} model={{ classification: model.classification }} />,
                    formType: 'CPFModal',
                });
                break;
            case 'Gold'://blm ada
                const handleSaveG = (model) => {
                    mutators.push(`${fieldName}.golds`, model);
                    clearModal();
                }
                setModal({
                    title: 'Gold Investment Information – CPFIS Singapore',
                    content: <CPFGold handleSave={handleSaveG} model={{ classification: model.classification }} />,
                    formType: 'CPFModal',
                });
                break;
            case 'Exchange Trade Fund (ETF)':
                const handleSaveETF = (model) => {
                    mutators.push(`${fieldName}.etfList`, model);
                    clearModal();
                }
                setModal({
                    title: 'Exchange Trade Fund (ETF) Holding Information – CPFIS Singapore',
                    content: <CPFExchangeTrade handleSave={handleSaveETF} model={{ classification: model.classification }} />,
                    formType: 'CPFModal',
                });
                break;
            case 'Insurance Linked Products':
                const handleSaveILP = (model) => {
                    mutators.push(`${fieldName}.ilasList`, model);
                    clearModal();
                }
                setModal({
                    title: 'Insurance Linked Product Information – CPFIS Singapore',
                    content: <CPFInvestmentLink handleSave={handleSaveILP} model={{ classification: model.classification }} />,
                    formType: 'CPFInvestmentLink',
                });
                break;
        }
    }

    const generateCashAccount = () => {
        if (model) {
            if (model.cashAccounts) {
                return model.cashAccounts.map((a, idx) => (
                    <tr key={idx}>
                        <td>{a.financialInstitutionName}</td>
                        <td>{'Cash Account'}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan="2">
                            <button
                                onClick={() => editItem('Cash Account', a, idx)}
                                className="btn btn-edit btn-padding"
                            />
                            <button type="button"
                                onClick={() => deleteItem('Cash Account', a, idx)}
                                className="btn-remove"></button>
                        </td>
                    </tr>
                ));
            }
        }
    }

    const generatetermDeposits = () => {
        if (model) {
            if (model.termDeposits) {
                return model.termDeposits.map((a, idx) => (
                    <tr key={idx}>
                        <td>{a.financialInstitutionName}</td>
                        <td>{'Term Deposit'}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan="2">
                            <button
                                onClick={() => editItem('Term Deposit', a, idx)}
                                className="btn btn-edit btn-padding"
                            />
                            <button type="button"
                                onClick={() => deleteItem('Term Deposit', a, idx)}
                                className="btn-remove"></button>
                        </td>
                    </tr>
                ));
            }
        }
    }

    const generateGold = () => {
        if (model) {
            if (model.golds) {
                return model.golds.map((a, idx) => (
                    <tr key={idx}>
                        <td>{a.financialInstitutionName}</td>
                        <td>{'Gold'}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan="2">
                            <button
                                onClick={() => editItem('Gold', a, idx)}
                                className="btn btn-edit btn-padding"
                            />
                            <button type="button"
                                onClick={() => deleteItem('Gold', a, idx)}
                                className="btn-remove"></button>
                        </td>
                    </tr>
                ));
            }
        }
    }

    const generatePropertyFund = () => {
        if (model) {
            if (model.propertyFunds) {
                return model.propertyFunds.map((a, idx) => (
                    <tr key={idx}>
                        <td>{a.financialInstitutionName}</td>
                        <td>{'Property Fund'}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan="2">
                            <button
                                onClick={() => editItem('Property Fund', a, idx)}
                                className="btn btn-edit btn-padding"
                            />
                            <button type="button"
                                onClick={() => deleteItem('Property Fund', a, idx)}
                                className="btn-remove"></button>
                        </td>
                    </tr>
                ));
            }
        }
    }

    const generateShare = () => {
        if (model) {
            if (model.shares) {
                return model.shares.map((a, idx) => (
                    <tr key={idx}>
                        <td>{a.financialInstitutionName}</td>
                        <td>{'Shares'}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan="2">
                            <button
                                onClick={() => editItem('Shares', a, idx)}
                                className="btn btn-edit btn-padding"
                            />
                            <button type="button"
                                onClick={() => deleteItem('Shares', a, idx)}
                                className="btn-remove"></button>
                        </td>
                    </tr>
                ));
            }
        }
    }
    const generateUnitTrusts = () => {
        if (model) {
            if (model.unitTrusts) {
                return model.unitTrusts.map((a, idx) => (
                    <tr key={idx}>
                        <td>{a.financialInstitutionName}</td>
                        <td>{'Unit Trust'}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan="2">
                            <button
                                onClick={() => editItem('Unit Trusts', a, idx)}
                                className="btn btn-edit btn-padding"
                            />
                            <button type="button"
                                onClick={() => deleteItem('Unit Trusts', a, idx)}
                                className="btn-remove"></button>
                        </td>
                    </tr>
                ));
            }
        }
    }
    const generateBonds = () => {
        if (model) {
            if (model.bonds) {
                return model.bonds.map((a, idx) => (
                    <tr key={idx}>
                        <td>{a.financialInstitutionName}</td>
                        <td>{'Bond'}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan="2">
                            <button
                                onClick={() => editItem('Bonds', a, idx)}
                                className="btn btn-edit btn-padding"
                            />
                            <button type="button"
                                onClick={() => deleteItem('Bonds', a, idx)}
                                className="btn-remove"></button>
                        </td>
                    </tr>
                ));
            }
        }
    }

    const generateTreasuryBills = () => {
        if (model) {
            if (model.treasuryBills) {
                return model.treasuryBills.map((a, idx) => (
                    <tr key={idx}>
                        <td>{a.financialInstitutionName}</td>
                        <td>{'Treasury Bill'}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan="2">
                            <button
                                onClick={() => editItem('Treasury Bills', a, idx)}
                                className="btn btn-edit btn-padding"
                            />
                            <button type="button"
                                onClick={() => deleteItem('Treasury Bills', a, idx)}
                                className="btn-remove"></button>
                        </td>
                    </tr>
                ));
            }
        }
    }
    const generateExchangeTradeFunds = () => {
        if (model) {
            if (model.etfList) {
                return model.etfList.map((a, idx) => (
                    <tr key={idx}>
                        <td>{a.financialInstitutionName}</td>
                        <td>{'Exchange Trade Fund (ETF)'}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan="2">
                            <button
                                onClick={() => editItem('Exchange Trade Fund (ETF)', a, idx)}
                                className="btn btn-edit btn-padding"
                            />
                            <button type="button"
                                onClick={() => deleteItem('Exchange Trade Fund (ETF)', idx)}
                                className="btn-remove"></button>
                        </td>
                    </tr>
                ));
            }
        }
    }
    const generateInsuranceLinkedProducts = () => {
        if (model) {
            if (model.ilasList) {
                return model.ilasList.map((a, idx) => (
                    <tr key={idx}>
                        <td>{a.financialInstitutionName}</td>
                        <td>{'Insurance Linked Products'}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan="2">
                            <button
                                onClick={() => editItem('Insurance Linked Products', a, idx)}
                                className="btn btn-edit btn-padding"
                            />
                            <button type="button"
                                onClick={() => deleteItem('Insurance Linked Products', idx)}
                                className="btn-remove"></button>
                        </td>
                    </tr>
                ));
            }
        }
    }

    return (
        <table
            className="table-investment"
            width="100%"
            cellSpacing="0"
            cellPadding="0"
            border="0"
        >
            <thead>
                <tr>
                    <th>
                        Investment Name
                    </th>
                    <th>
                        Type
                    </th>
                    <th>
                        Unit Held
                    </th>
                    <th>
                        Unit Price
                    </th>
                    <th>
                        Cost
                    </th>
                    <th>
                        Value
                    </th>
                    <th>
                        Current Allocation
                    </th>
                    <th>
                    </th>
                    <th>
                        <Dropdown
                            trigger={['click']}
                            overlay={
                                <Menu
                                    onSelect={menu => showModal(menuItems()[menu.key])}
                                >
                                    {menuItems().map((menu, idx) => (
                                        <MenuItem key={idx}>
                                            {menu.label}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            }
                            animation="slide-up"
                        >
                            <span className="btn btn-plus" />
                        </Dropdown>
                    </th>
                </tr>
            </thead>
            <tbody>
                {generateBonds()}
                {generateCashAccount()}
                {generateShare()}
                {generateExchangeTradeFunds()}
                {generateInsuranceLinkedProducts()}
                {generateUnitTrusts()}
                {generatePropertyFund()}
                {generateGold()}
                {generatetermDeposits()}
                {generateTreasuryBills()}
                {/* <tr className="total">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr> */}
            </tbody>
        </table >
    );
}

function mapStateToProps(state) {
    const { optionsData } = state.enumOptions;

    return {
        optionsData,
    };
}

function mapDispatchToProps(dispatch) {

    return bindActionCreators(enumOptionsActions, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CPFInvestmentAllocationTable);