import React, { useContext, useState, useEffect } from "react"
import { EntityTypeEnum } from "../../../constants/Enums"
import { AppContext } from "../../../context/AppContext"
import { ClientHeaderMenu, NonClientHeaderMenu } from "."
import SettingForm from "../../../forms/SettingForm"
import UserProfile from "../../../pages/UserProfile/UserProfile"
import ClientProfile from "../../../pages/UserProfile/ClientProfile"
import AdviserUserProfile from "../../../pages/UserProfile/AdviserUserProfile"
import NotificationsDialog from "../../../forms/NotificationsDialog"
import TipsDialog from "../../../forms/TipsDialog"
import NotificationApi from "../../../api/NotificationApi"

function getComponent({
  entityTypeEnum,
  title,
  onNotificationClick,
  onSettingClick,
  photoUrl,
  onUserProfileClick,
  titleBarColour,
  fontColour,
  selectedEntity,
  firstTimeLogin,
  country,
  forceRefresh,
  dashboardData,
  notification,
  blinkNotification,
  onQuestionClick,
  toggleSidebar
}) {
  //handle country
  const handleCountry = (country) => {
    country = "HK" ? "cn" : country
    return country.toLowerCase()
  }

  const props = {
    title,
    onSettingClick,
    onNotificationClick,
    onQuestionClick,
    photoUrl,
    onUserProfileClick,
    titleBarColour, // get titleBarColour
    fontColour,
    selectedEntity,
    firstTimeLogin,
    country,
    forceRefresh,
    dashboardData,
    notification,
    blinkNotification,
    toggleSidebar
  }

  if (entityTypeEnum === EntityTypeEnum.EntityTypeEnum_CLIENT) {
    return <ClientHeaderMenu {...props} />
  }

  return <NonClientHeaderMenu {...props} />
}

function HeaderMenuSelector(props) {
  const {
    entityTypeEnum,
    title,
    photoUrl,
    forceRefresh,
    titleBarColour,
    fontColour,
    firstTimeLogin,
    selectedEntity,
    country,
    dashboardData,
    toggleSidebar
  } = props
  const {
    modal: { setModal, clearModal }
  } = useContext(AppContext)

  const [notification, setNotification] = useState([])
  const [blinkNotification, setBlinkNotification] = useState(false)
  useEffect(() => {
    refreshNotificationList()
  }, [])

  function refreshNotificationList() {
    NotificationApi.getNotificationList()
      .then(({ data }) => {
        let loginEntityType = localStorage.getItem("loginEntityType")
        setNotification(data)
        let blinkCount = 0;
        let waitingList = data.userNotificationList.filter((n) => n.acknowledged == false);
        if(waitingList.length>0){
          blinkCount = blinkCount + waitingList.length;
        }
        
        if (
          data.clientNotificationList &&
          data.clientNotificationList.length > 0
        ) {
          waitingList = data.clientNotificationList.filter((n) => n.status == "WAITING")
          blinkCount = blinkCount + waitingList.length;
        }

        if (
          loginEntityType != "CLIENT" &&
          data.transferNotificationList &&
          data.transferNotificationList.length > 0
        ) {
          waitingList = data.transferNotificationList.filter((n) => n.status == "WAITING")
          blinkCount = blinkCount + waitingList.length;
        }
        if (blinkCount>0) {
          setBlinkNotification(true);
         
        }
      })
     
    return notification
  }

  const onUserProfileClick = () => {
    if (entityTypeEnum == EntityTypeEnum.EntityTypeEnum_BUILT_IN_ADVISER) {
      setModal({
        title: "User Profile : Adviser",
        formType: "UserProfile",
        content: <AdviserUserProfile clearModal={clearModal} />
      })
    } else if (entityTypeEnum == EntityTypeEnum.EntityTypeEnum_CLIENT) {
      setModal({
        title: "User Profile : Client",
        formType: "ClientProfile",
        content: <ClientProfile clearModal={clearModal} />
      })
    } else {
      setModal({
        title: "User Profile : Entity",
        formType: "UserProfile",
        content: <UserProfile clearModal={clearModal} />
      })
    }
  }

  const onNotificationClick = () => {
    setModal({
      title: `Notifications`,
      content: (
        <NotificationsDialog
          notification={notification}
          setBlinkNotification={setBlinkNotification}
          clearModal={clearModal}
        />
      ),
      isSmall: true
    })
  }

  const onQuestionClick = () => {
    setModal({
      title: `Tips & Tutorial`,
      content: <TipsDialog />,
      isSmall: true
    })
  }

  const onSettingClick = () => {
    setModal({
      title: "User Setting ",
      content: (
        <SettingForm
          forceRefresh={forceRefresh}
          firstTimeLogin={firstTimeLogin}
          selectedEntity={selectedEntity}
        />
      ),
      isSmall: true
    })
  }
  return getComponent({
    entityTypeEnum,
    title,
    onNotificationClick,
    onSettingClick,
    photoUrl,
    onUserProfileClick,
    titleBarColour,
    fontColour,
    firstTimeLogin,
    country,
    forceRefresh,
    dashboardData,
    notification,
    blinkNotification,
    onQuestionClick,
    toggleSidebar
  })
}

export default HeaderMenuSelector
