import React, { useEffect, useState } from "react"
import intl from "react-intl-universal"
import { Form } from "react-final-form"
import { MeetingType } from "../../constants/Lists"
import api from "../../lib/api"
import { connect } from "react-redux"
import { NoteCategoryEnum } from "../../constants/Enums"
import Alert from "react-s-alert"
import NoteEdit from "./NoteEdit"

import RenderSimpleFields, {
  FieldType
} from "../../components/Form/RenderSimpleFields"
import Notes from "./Notes"
import { FaEdit, FaTrash } from "react-icons/fa"
import moment from "moment"
const PastFileNotes = ({
  getNotes,
  userID,
  notes,
  setModal,
  clearModal,
  user,
  name,
  addNotes
}) => {
  const [deleted, setDeleted] = useState(false)
  const [noteType, setNoteType] = useState("")
  const [notesList, setNotesList] = useState([])
  const onChangeMeeting = (e) => {
    // e.preventDefault();
    setNoteType(e)
  }

  useEffect(() => {
    const getNotes = async (e) => {
      await api
        .get(`/note/client/${userID}?type=${noteType}`)
        .then((response) => {
          setNotesList(response.data.data.notes)
        })
        .catch((error) => {
          console.log("error is", error)
        })
    }
    getNotes()
  }, [noteType, deleted])
  const fields = [
    {
      // label: "",
      name: "meetingType",
      type: FieldType.SELECTION,
      selectionData: MeetingType,
      valueProperty: "value",
      labelProperty: "name",
      // required: true,
      onChange: onChangeMeeting
    }
  ]
  function handleSubmit(values) {}

  return (
    <div className="notification-dialog">
      <div
        onClick={() => {
          setModal({
            title: "Past File Notes",
            content: (
              <Notes
                user={user}
                getNotes={getNotes}
                closeModal={clearModal}
                setModal={setModal}
                name={name}
                addNotes={addNotes}
              />
            ),
            isSmall: true
          })
        }}
        className="navigateBack"
        style={{ cursor: "pointer" }}
      >
        <span>&#x2190;</span> {"  "} <span>Create a new note</span>
      </div>
      <Form
        onSubmit={handleSubmit}
        // initialValues={meetingType}
        render={({
          handleSubmit,
          form,
          // submitting,
          // pristine,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="pastNotesOuter">
              <table
                className="table-blue table-cs-popup_edu"
                width="100%"
                cellSpacing="0"
                cellPadding="0"
                border="0"
              >
                <thead>
                  <tr>
                    <th>Date</th>
                    <th> Client Name</th>
                    <th>Subject</th>
                    <th
                      className="meetingSelect"
                      style={{
                        color: "black",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <span
                        style={{
                          marginRight: "10px",
                          color: "white",
                          width: "58px"
                        }}
                      >
                        Note Types
                      </span>

                      <span style={{ width: "180px" }}>
                        <RenderSimpleFields fields={fields} />
                      </span>
                    </th>
                    <th>Note</th>
                    <th>Note Created By</th>
                    <th>Deadline</th>

                    <th width="10%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {notesList.length ? (
                    notesList.map((note, key) => (
                      <tr key={key} className="trow">
                        <td>
                          <div className="text-center">
                            {moment(note.dateTimeCreated).format(
                              "YYYY-MM-DD h:mm A"
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="text-center">{note.clientName}</div>
                        </td>

                        <td>
                          <div className="text-center">{note.subject}</div>
                        </td>
                        <td>
                          <div className="text-center">
                            {note.type === NoteCategoryEnum.GENERAL_NOTES
                              ? intl.get("NoteCategoryEnum_GENERAL_NOTES")
                              : note.type === NoteCategoryEnum.MEETING_NOTES
                              ? intl.get("NoteCategoryEnum_MEETING_NOTES")
                              : intl.get("NoteCategoryEnum_TODO")}
                          </div>
                        </td>
                        <td
                          dangerouslySetInnerHTML={{
                            __html: note.description
                          }}
                        />
                        <td>
                          <div className="text-center">
                            {note.authorName && note.authorName}{" "}
                            {note.type === NoteCategoryEnum.MEETING_NOTES &&
                              ` at ${note.place}`}
                          </div>
                        </td>
                        <td>
                          <div className="text-center">
                            {moment(note.deadline).format("YYYY-MM-DD h:mm A")}
                          </div>
                        </td>
                        <td width="10%">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "24px",
                              justifyContent: "center"
                            }}
                          >
                            <div
                              style={{
                                color: "red",
                                fontSize: "20px",
                                textAlign: "center",
                                cursor: "pointer"
                              }}
                              onClick={async () => {
                                await api.delete(`/note/${note.id}`)
                                Alert.success(intl.get("alert.noteDelete"))
                                setDeleted(!deleted)
                              }}
                            >
                              <FaTrash
                                style={{ width: "16px", height: "16px" }}
                              />
                            </div>
                            <div
                              style={{
                                fontSize: "20px",
                                textAlign: "center",
                                cursor: "pointer"
                              }}
                              onClick={async () => {
                                setModal({
                                  title: "Edit Note",
                                  content: (
                                    <NoteEdit
                                      user={user}
                                      noteId={note.id}
                                      closeModal={clearModal}
                                      setModal={setModal}
                                      name={name}
                                    />
                                  ),
                                  isSmall: true
                                })
                              }}
                            >
                              <FaEdit
                                style={{
                                  width: "16px",
                                  height: "16px",
                                  color: "black"
                                }}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <div className="warn-msg">No notes Created Yet</div>
                  )}
                </tbody>
              </table>
            </div>
          </form>
        )}
      />
    </div>
  )
}

function mapStateToProps(state) {
  return {
    notes: state.notes.items && state.notes.items.data.notes
  }
}
// function mapDispatchToProps(dispatch) {
//     return bindActionCreators(Object.assign({}, notesActions), dispatch);
// }
export default connect(mapStateToProps, null)(PastFileNotes)

// export default PastFileNotes;
