import React, { useContext } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';
import intl from 'react-intl-universal';

import { AppContext } from '../../../../context/AppContext';

import CashFlowListing from '../../CashFlowListing';
import CashFlowListingGroup from '../../CashFlowListingGroup';
import CashFlowHeaderListing from '../../CashFlowHeaderListing';
import { ResidencePropertyForm } from '../../../../forms';

import { AdditionalArrayInput } from '../FieldArrayList';

import api from '../../../../lib/api';
import { netWorthFormActions } from '../../../../actions';
import { useDispatch } from 'react-redux';

const HomeExpenses = ({
    varPrefix,
    push,
    clientTotalValue,
    partnerTotalValue,
    jointTotalValue,
    optionsData,
    readOnlyAccessComponent,
    clientInformation,
    onExternalUpdated,
    currency,
    isJointHide,
    values,
}) => {
    const {
        modal: { setModal, clearModal },
    } = useContext(AppContext);

    const handleSave = a => {
        // save the model
        api.put(a.url, a).then(response => {
            onExternalUpdated(true);
        });

        clearModal();
    };
    const dispatch = useDispatch()

    return (
        <div className="portal-part">
            <Accordion accordion={false}>
                <AccordionItem>
                    <AccordionItemTitle>
                        <CashFlowHeaderListing
                            title={intl.get(
                                'cashflowlisting.personalExpenses.homeExpenses'
                            )}
                            isAccordion
                            isRoundColorRed={false}
                            clientTotalValue={clientTotalValue}
                            partnerTotalValue={partnerTotalValue}
                            jointTotalValue={jointTotalValue}
                            currency={currency}
                            isJointHide={isJointHide}
                        />
                    </AccordionItemTitle>
                    <AccordionItemBody>
                        <CashFlowListingGroup
                            varPrefix={`${varPrefix}.homes`}
                            dataArray={values.detailResult.homes}
                            isRoundColorRed
                            isExternalModal
                            isJointHide={isJointHide}
                            currency={currency}
                            onEditClick={model =>{
                                dispatch(
                                  netWorthFormActions.setNewForm(
                                    false,
                                    model.classification
                                  )
                                )
                                setModal({
                                    title: intl.get(
                                        'cashflowlisting.personalExpenses.homeExpenses'
                                    ),
                                    formType: 'homeExpenses',
                                    content: (
                                        <ResidencePropertyForm
                                            handleSave={handleSave}
                                            model={model}
                                            readOnlyAccess={readOnlyAccessComponent()}
                                            clientInformation={
                                                clientInformation
                                            }
                                            optionsData={optionsData}
                                        />
                                    ),
                                })
                            }}
                        />
                        <CashFlowListing
                            varPrefix={`${varPrefix}`}
                            title={intl.get(
                                'cashflowlisting.personalExpenses.rent'
                            )}
                            isAdditionalInput
                            isJointHide={isJointHide}
                            isShowAddButton
                            push={push}
                            additionName={`${varPrefix}.rents`}
                            defaultDeductibleValue={0}
                            currency={currency}
                        />
                        <AdditionalArrayInput
                            placeholderLabel={intl.get(
                                'cashflowlisting.pleaseSpecify'
                            )}
                            AdditionalInput
                            arrayName={`${varPrefix}.rents`}
                            isJointHide={isJointHide}
                            currency={currency}
                        />
                        <CashFlowListing
                            varPrefix={`${varPrefix}`}
                            title={intl.get('cashflowlisting.others')}
                            isAdditionalInput
                            isJointHide={isJointHide}
                            isShowAddButton
                            push={push}
                            additionName={`${varPrefix}.others`}
                            defaultDeductibleValue={0}
                            currency={currency}
                        />
                        <AdditionalArrayInput
                            placeholderLabel={intl.get(
                                'cashflowlisting.pleaseSpecify'
                            )}
                            AdditionalInput
                            arrayName={`${varPrefix}.others`}
                            isJointHide={isJointHide}
                            currency={currency}
                        />
                    </AccordionItemBody>
                </AccordionItem>
            </Accordion>
        </div>
    );
};

export default HomeExpenses;
