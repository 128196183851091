import React from 'react';
import intl from 'react-intl-universal';
import { Field } from 'react-final-form';
import RenderSimpleFields, {
    FieldType,
} from '../../../components/Form/RenderSimpleFields';
import { renderMoneyCustom } from '../../../helpers';

function PensionInformationRightSide({
    optionsData
}) {
    return (
        <React.Fragment>
            <RenderSimpleFields
                fields={[
                    {
                        name: 'pensionPayment',
                        label: intl.get('field.pensionPayment'),
                        type: FieldType.TEXT,
                    },
                    {
                        name: 'pensionPaymentFrequency',
                        label: intl.get('field.pensionPaymentFrequency'),
                        type: FieldType.ENUM_SELECTION,
                        enumData: optionsData.frequencyOptions
                    },
                    {
                        name: 'pensionTaxableComponent',
                        label: intl.get('field.pensionTaxableComponent'),
                        type: FieldType.MONEY,
                        allowNegative: false,
                    },
                    {
                        name: 'pensionTaxFreeComponent',
                        label: intl.get('field.pensionTaxFreeComponent'),
                        type: FieldType.MONEY,
                        allowNegative: false,
                    },
                    {
                        name: 'pensionConcessionalContributionComponent',
                        label: intl.get(
                            'field.pensionConcessionalContributionComponent'
                        ),
                        type: FieldType.MONEY,
                        allowNegative: false,
                    },
                    {
                        name: 'pensionNonConcessionalContributionComponent',
                        label: intl.get(
                            'field.pensionNonConcessionalContributionComponent'
                        ),
                        type: FieldType.MONEY,
                        allowNegative: false,
                    },
                    {
                        name: 'pensionAnnualGrowthRate',
                        label: intl.get('field.pensionAnnualGrowthRate'),
                        type: FieldType.PERCENTAGE,
                    },
                ]}
            />
            <div className="subtitle2">{intl.get('netWorth.pensionInformation.fee')}</div>
            <div className="portal-form-row align-items-center">
                <label>{intl.get('field.annualAdviserFee')}</label>
                <div className="row-holder">
                    <Field
                        name="annualAdviserFee"
                        isLabel={false}
                        component={renderMoneyCustom}
                        isForGrid
                        allowNegative={false}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}

export default PensionInformationRightSide;
