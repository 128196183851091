import { netWorthFormAction } from "../../../constants/ActionConstants"
import { netWorthActions } from "./NetWorthActions"
import api from "../../../lib/api"
import FileOperationApi from "../../../api/FileOperationApi"
import NotificationApi from "../../../api/NotificationApi"
import Alert from "react-s-alert"

export const netWorthFormActions = {
  setUpdateNetWorthForm,
  deleteNetWorthForm,
  saveNetWorthForm,
  saveOneDataOnlyNetWorthForm,
  setNetWorthFormModel,
  setOneDataOnlyUpdateNetWorthForm,
  saveEmploymentTerminationPayments,
  saveCentralProvidenceFund,
  setNewForm
}

function setUpdateNetWorthFormSuccess(formData, index) {
  return {
    type: netWorthFormAction.SET_UPDATE_NET_WORTH_FORM_SUCCESS,
    formData
  }
}

function setNetWorthFormModel(model) {
  return (dispatch) => {
    dispatch(setNetWorthModel(model))
  }
}

function setNetWorthModel(model) {
  return {
    type: netWorthFormAction.SET_NET_WORTH_FORM_MODEL,
    model
  }
}
function setNewForm(isNew, classification, data) {
  return (dispatch) => {
    let formData = {
      data: {},
      index: 0,
      model: {
        isNew: isNew,
        classification: classification,
        ...data
      }
    }
    dispatch(setUpdateNetWorthFormSuccess(formData, 1))
  }
}
function setUpdateNetWorthForm(index, client, path, title) {
  const url = getSaveNetWorthFormUrl(client, path)
  return (dispatch) => {
    api.get(url).then((response) => {
      const { data } = response
      let formData = {}

      if (index || index === 0) {
        const model = Array.isArray(data) ? { ...data[index], index } : data
        formData = {
          data,
          model
        }
      } else {
        formData = {
          data,
          model: {
            isNew: true,
            classification: "CLIENT",
            assetType: title
          }
        }
      }

      //add index
      formData.index = index

      dispatch(setUpdateNetWorthFormSuccess(formData, index))
    })
  }
}

function setOneDataOnlyUpdateNetWorthForm(type, client, path, isJoint) {
  const url = getSaveNetWorthFormUrl(client, path, true, isJoint)
  return (dispatch) => {
    api.get(url).then((response) => {
      const { data } = response
      let formData = {}
      if (isJoint) {
        formData = {
          data,
          model:
            type === "CLIENT"
              ? { ...data.client, classification: "CLIENT" }
              : { ...data.partner, classification: "PARTNER" }
        }
      } else {
        formData = {
          data: { ...data, classification: "CLIENT" },
          model: { ...data, classification: "CLIENT" }
        }
      }
      dispatch(setUpdateNetWorthFormSuccess(formData))
    })
  }
}

function deleteNetWorthForm(
  index,
  client,
  path,
  oneDataOnly = false,
  isJoint = false,
  currency = "MYR"
) {
  const cry = path.split("=")[1]

  const url = getSaveNetWorthFormUrl(
    client,
    `${path}?reportingCurrency=${currency}`,
    oneDataOnly,
    isJoint
  )
  return (dispatch) => {
    api.get(`${url}?reportingCurrency=${currency}`).then((response) => {
      let { data } = response
      if (data && data.length > 0) {
        let item = data[index]
        api.delete(url + "/" + item.id).then(() => {
          recallGetList(client, path, cry, dispatch)
        })
      }
    })
  }
}

function recallGetList(client, path, cry, dispatch) {
  switch (path.split(".")[0]) {
    case "personalAssets": {
      dispatch(netWorthActions.getPersonalAssetsListing(client, cry))
      break
    }
    case "liquidAssets": {
      dispatch(netWorthActions.getLiquidAssetsListing(client, cry))
      break
    }
    case "illiquidAssets": {
      dispatch(netWorthActions.getIlliquidAssetsListing(client, cry))
      break
    }
    case "retirementAssets": {
      dispatch(netWorthActions.getRetirementAssetsListing(client, cry))
      break
    }
    case "liabilities": {
      dispatch(netWorthActions.getLiabilitiesListing(client, cry))
      break
    }
    case "geal": {
      dispatch(netWorthActions.getGEALListing(client, cry))
      break
    }
    default: {
      break
    }
  }
  dispatch(netWorthActions.getNetWorthTotalSummary(client, cry))
}

function saveNetWorthForm(client, netWorthField, path, callBack) {
  const cry = path.split("=")[1]

  const url = getSaveNetWorthFormUrl(client, path)
  return async (dispatch) => {
    try {
      await api.put(url, netWorthField).then(() => {
        let showWarning = NotificationApi.checkAndDisplayWarning()
        if (!showWarning) {
          Alert.success("Data has been saved successfully!")
        }

        recallGetList(client, path, cry, dispatch)
      })
      callBack("success")
    } catch (error) {
      console.log("error encountered.", error)
      callBack("error")
    }
  }
}

function saveOneDataOnlyNetWorthForm(
  client,
  netWorthField,
  path,
  isJoint,
  callBack
) {
  const url = getSaveNetWorthFormUrl(client, path, true, isJoint)
  const cry = path.split("=")[1]

  return async (dispatch) => {
    try {
      await api.put(url, netWorthField).then(() => {
        switch (path.split(".")[0]) {
          case "taxation": {
            dispatch(netWorthActions.getTaxationListing(client, cry))
            break
          }
          default: {
            break
          }
        }
      })
      callBack("success")
    } catch (error) {
      callBack("error")
      console.log("error", error)
    }
  }
}

function saveEmploymentTerminationPayments(
  client,
  employmentTerminationPayments,
  path,
  isJoint,
  classification,
  callBack
) {
  const url = getSaveNetWorthFormUrl(client, path, true, isJoint)
  const cry = path.split("=")[1]

  return async (dispatch) => {
    try {
      let updatedModel

      if (isJoint) {
        updatedModel = employmentTerminationPayments[classification]
      } else {
        updatedModel = employmentTerminationPayments
      }

      const paymentDetailsFile = updatedModel.paymentDetailsFile || {}
      const { rawFile } = paymentDetailsFile

      if (rawFile) {
        const formData = new FormData()
        formData.append("sourceType", "CLIENT")
        formData.append("sourceId", client.id)
        formData.append("title", "Employment Termination Payment")
        formData.append("module", "NETWORTH-ETP")
        formData.append("moduleId", updatedModel.id)
        formData.append("file", rawFile)
        FileOperationApi.upload(formData).then((response) => {
          const { file } = response.data
          updatedModel.paymentDetailsFile = file

          api.put(url, employmentTerminationPayments).then(() => {
            dispatch(
              netWorthActions.getEmploymentTerminationPaymentsListing(
                client,
                cry
              )
            )
          })
        })
      } else {
        api.put(url, employmentTerminationPayments).then(() => {
          dispatch(
            netWorthActions.getEmploymentTerminationPaymentsListing(client, cry)
          )
        })
      }
      callBack("success")
    } catch (error) {
      callBack("error")
    }
  }
}

function saveCentralProvidenceFund(client, model, path, callBack) {
  const url = getSaveNetWorthFormUrl(client, path, true, false)
  const cry = path.split("=")[1]

  return async (dispatch) => {
    try {
      api.put(url, model).then(() => {
        dispatch(netWorthActions.getRetirementAssetsListing(client, cry))
      })
      callBack("success")
    } catch (error) {
      console.log("error", error)
      callBack("error")
    }
  }
}

function getSaveNetWorthFormUrl(
  client,
  path,
  oneDataOnly = false,
  isJoint = false
) {
  const a = path.split(".")[1]
  const b = a.split("?")[0]
  let c = a
  if (b) {
    c = b
  }
  if (oneDataOnly && isJoint && b !== "centralProvidenceFund") {
    return client._embedded[`${c}AlongWithPartner`]._links.self.href
  }
  return client._embedded[c]._links.self.href
}
