import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import intl from "react-intl-universal"
import { netWorthFormActions, modalWindowActions } from "../../../../actions"
import { modalWindow } from "../../../../constants/ModalWindowLists"
import NetWorthForm, {
  NetWorthPath,
  NetWorthFormPropsComposer
} from "../../NetWorthForm"
import { AppContext } from "../../../../context/AppContext"
import EPFForm from "./EPFForm"




class EmployeeProvidenceFund extends NetWorthForm {
  
  constructor(props) {
    super(
      props,
      intl.get("employeeProvidenceFund.title"),
      modalWindow.EMPLOYEE_PROVIDENCE_FUND,
      NetWorthPath.employeeProvidenceFunds,
      "EmployeeProvidenceFund"
    )

   
  }

  render() {
    const {
      optionsData,
      model,
      client,
      partner,
      isJoint,
      currency,
    } = this.props;
    const { handleSave, contentWrapper, readOnlyAccessComponent } = this;
    

    return contentWrapper(
       <EPFForm 
          handleSave = {handleSave}
          model={model} 
          client = {client}
          partner = {partner} 
          optionsData={optionsData} 
          isjoint = {isJoint}
       />
            
    )
  }
        
}

 



function mapStateToProps(state) {

  const props = NetWorthFormPropsComposer(
      state,
      modalWindow.EMPLOYEE_PROVIDENCE_FUND,
      NetWorthPath.employeeProvidenceFunds
  );
  return {
      ...props
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
      Object.assign({}, netWorthFormActions, modalWindowActions),
      dispatch
  );
}

EmployeeProvidenceFund.contextType = AppContext

EmployeeProvidenceFund = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeProvidenceFund)

export default EmployeeProvidenceFund
