import {bondsHoldingInformationAction} from '../../../constants/ActionConstants';

export function bondsHoldingInformation(state = {}, action) {
    switch (action.type) {
        case bondsHoldingInformationAction.GET_BONDSHOLDINGINFORMATION_REQUEST:
            return {
                loading: true
            };
        case bondsHoldingInformationAction.GET_BONDSHOLDINGINFORMATION_SUCCESS:
            return {
                items: action.data
            };
        case bondsHoldingInformationAction.GET_BONDSHOLDINGINFORMATION_FAILURE:
            return {
                error: action.error
            };
        case bondsHoldingInformationAction.ADD_BONDSHOLDINGINFORMATION_REQUEST:
            return {
                loading: true
            };
        case bondsHoldingInformationAction.ADD_BONDSHOLDINGINFORMATION_SUCCESS:
            return {
                items: action.response
            };
        case bondsHoldingInformationAction.ADD_BONDSHOLDINGINFORMATION_FAILURE:
            return {
                error: action.error
            };
        case bondsHoldingInformationAction.EDIT_BONDSHOLDINGINFORMATION_REQUEST:
            return {
                loading: true
            };
        case bondsHoldingInformationAction.EDIT_BONDSHOLDINGINFORMATION_SUCCESS:
            return {
                items: action.response
            };
        case bondsHoldingInformationAction.EDIT_BONDSHOLDINGINFORMATION_FAILURE:
            return {
                error: action.error
            };
        case bondsHoldingInformationAction.DELETE_BONDSHOLDINGINFORMATION_REQUEST:
            return {
                loading: true
            };
        case bondsHoldingInformationAction.DELETE_BONDSHOLDINGINFORMATION_SUCCESS:
            return {
                items: action.response
            };
        case bondsHoldingInformationAction.DELETE_BONDSHOLDINGINFORMATION_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}