export const _formHelper = {
  saveForm
}

function saveForm(
  model,
  client,
  data,
  dataSaver,
  path,
  isList = true,
  currency = "MYR",
  callBack
) {
  if (model) {
    let dataToBeSaved
    if (isList) {
      dataToBeSaved = data || []
      if (model.isNew) {
        dataToBeSaved.push(model)
      } else {
        dataToBeSaved[model.index] = model
      }
    } else {
      dataToBeSaved = model
    }

    dataSaver(client, dataToBeSaved, `${path}?reportingCurrency=${currency}`,callBack)
  }
}
