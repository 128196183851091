/*
    Author : Erik (erik.malik@devstack.com.au)
    Description : Employment Termination Payments Form main page (modal)
*/

import React from "react"
import { connect } from "react-redux"
import { Form } from "react-final-form"
import { bindActionCreators } from "redux"
import intl from "react-intl-universal"
import { netWorthFormActions, modalWindowActions } from "../../../actions"
import { modalWindow } from "../../../constants/ModalWindowLists"
import NetWorthForm, {
  NetWorthPath,
  NetWorthFormPropsComposer
} from "../NetWorthForm"
import NetWorthFormButtons from "../NetWorthFormButtons"
import RenderSimpleFields, {
  FieldType
} from "../../../components/Form/RenderSimpleFields"

class EmploymentTerminationPaymentsForm extends NetWorthForm {
  constructor(props) {
    super(
      props,
      intl.get(
        "employmentTerminationPayments.employmentTerminationPayments.title"
      ),
      modalWindow.EMPLOYMENT_TERMINATION_PAYMENTS,
      NetWorthPath.employmentTerminationPayments,
      "EmploymentTerminationPaymentsForm"
    )

    this.oneDataOnly = true
  }

  render() {
    const { optionsData, model } = this.props
    const { contentWrapper, readOnlyAccessComponent, handleSave } = this

    // required since default value in Field will be String and we need an object to wrap the file.
    model.paymentDetailsFile = model.paymentDetailsFile || {}
    return contentWrapper(
      <Form
        onSubmit={handleSave}
        initialValues={model}
        render={({ handleSubmit, reset }) => (
          <form id="employmentTerminationPaymentsForm" onSubmit={handleSubmit}>
            <div className="portal-form-popup">
              <RenderSimpleFields
                fields={[
                  {
                    name: "terminationDate",
                    type: FieldType.DATE,
                    label: intl.get(
                      "employmentTerminationPayments.employmentTerminationPayments.terminationDate"
                    )
                  },
                  {
                    name: "exGratiaAmount",
                    type: FieldType.MONEY,
                    label: intl.get(
                      "employmentTerminationPayments.employmentTerminationPayments.exGratiaAmount"
                    ),
                    allowNegative: false
                  },
                  {
                    name: "approvedEarlyRetirementAmount",
                    type: FieldType.MONEY,
                    label: intl.get(
                      "employmentTerminationPayments.employmentTerminationPayments.approvedEarlyRetirementAmount"
                    ),
                    allowNegative: false
                  },
                  {
                    name: "longServiceLeaveAmount",
                    type: FieldType.MONEY,
                    label: intl.get(
                      "employmentTerminationPayments.employmentTerminationPayments.longServiceLeaveAmount"
                    ),
                    allowNegative: false
                  },
                  {
                    name: "annualLeaveAmount",
                    type: FieldType.MONEY,
                    label: intl.get(
                      "employmentTerminationPayments.employmentTerminationPayments.annualLeaveAmount"
                    ),
                    allowNegative: false
                  },
                  {
                    name: "sickLeaveAmount",
                    type: FieldType.MONEY,
                    label: intl.get(
                      "employmentTerminationPayments.employmentTerminationPayments.sickLeaveAmount"
                    ),
                    allowNegative: false
                  },
                  {
                    name: "paymentCurrency",
                    type: FieldType.ENUM_SELECTION,
                    label: intl.get(
                      "employmentTerminationPayments.employmentTerminationPayments.paymentCurrency"
                    ),
                    enumData: optionsData.currencyOptions,
                    required: true
                  },
                  {
                    name: "receivingRedundancy",
                    type: FieldType.CHECKBOX,
                    label: intl.get(
                      "employmentTerminationPayments.employmentTerminationPayments.receivingRedundancy"
                    ),
                    checkboxLabel: intl.get("YesNoEnum_YES")
                  },
                  {
                    name: "paymentDetailsFile",
                    type: FieldType.FILE,
                    label: intl.get(
                      "employmentTerminationPayments.employmentTerminationPayments.paymentDetailsFile"
                    )
                  }
                ]}
              />

              <NetWorthFormButtons
                reset={reset}
                readOnlyAccess={readOnlyAccessComponent()}
              />
            </div>
          </form>
        )}
      />
    )
  }
}
function mapStateToProps(state) {
  return NetWorthFormPropsComposer(
    state,
    modalWindow.EMPLOYMENT_TERMINATION_PAYMENTS,
    NetWorthPath.employmentTerminationPayments
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, netWorthFormActions, modalWindowActions),
    dispatch
  )
}

EmploymentTerminationPaymentsForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmploymentTerminationPaymentsForm)
export default EmploymentTerminationPaymentsForm
