import React, { useContext } from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from "react-accessible-accordion"

import intl from "react-intl-universal"
import { Form, FormSpy } from "react-final-form"

import arrayMutators from "final-form-arrays"
import { FormButtons } from "../../../../components"
import { AppContext } from "../../../../context/AppContext"

import CashFlowListingGroup from "../../CashFlowListingGroup"
import CashFlowHeaderListing from "../../CashFlowHeaderListing"
// import forms here
import {
  HealthAndMedicalInsuranceForm,
  LifeInsuranceForm,
  TotalPermanentDisabilityInsuranceForm,
  IncomeProtectionInsuranceForm,
  OtherGeneralInsuranceForm
} from "../../../Insurance/_form"
import {
  InsuranceDetails,
  PropertyInsuranceDetails,
  MotorVehicleMainForm,
  CollectibleAntiqueArtsMainForm,
  PersonalContentMainForm,
  InvestmentPropertyMainForm,
  ResidencePropertyMainForm
} from "../../../../forms"

import api from "../../../../lib/api"
import ILASInvestmentForm from "../../../NetWorth/ILASInvestment/ILASInvestmentForm"

const OtherInsurancePremium = ({
  varPrefix,
  clientTotalValue,
  partnerTotalValue,
  jointTotalValue,
  optionsData,
  clientInformation,
  onExternalUpdated,
  currency,
  isJointHide,
  values
}) => {
  const {
    modal: { setModal, clearModal }
  } = useContext(AppContext)

  const handleSave = (a) => {
    // save the model
    api
      .put(a.url, a)
      .then(() => {
        // refresh main cashflow page calculation
        onExternalUpdated(true)
      })
      .finally(() => {
        clearModal()
      })
  }

  const handleSaveLinkedData = (model, type, url) => {
    // for handle save that are linked to other (networth)
    let insuranceModel = {}
    if (
      type.includes("primary_residence_property") ||
      type.includes("other_residence_properties") ||
      type.includes("investment_properties")
    ) {
      if (url.includes("CONTENT")) {
        insuranceModel = model.fireAndContentInsurance
      } else if (url.includes("LOAN")) {
        insuranceModel = model.mortgageInsurance
      }
    } else {
      insuranceModel = model.insuranceDetails
    }

    // must change the 'findByInsurance' to 'insurance' in the url string for PUT
    api
      .put(url.replace("findByInsurance", "insurance"), insuranceModel)
      .then(() => {
        // refresh main cashflow page calculation
        onExternalUpdated(true)
      })
      .finally(() => {
        clearModal()
      })
  }

  const createLinkedInsuranceForm = (
    type,
    url,
    newOptionsData,
    userData,
    values,
    model
  ) => {
    const props = {
      client: userData.client,
      partner: userData.partner,
      isJoint: userData.isJoint,
      readOnly: true,
      values,
      model
    }

    if (type === "motor_vehicles") {
      return (
        <div className="portal-form-popup">
          {/* 20200914 MABT-415 toggle checkbox */}
          <div className={values.insured ? "portal-form-half" : ""}>
            <MotorVehicleMainForm {...props} />
          </div>

          {/* 20200914 MABT-415 toggle checkbox */}
          {values.insured && (
            <div className="portal-form-half">
              <div className="subtitle2">
                {intl.get("tabs.insuranceDetails")}
              </div>
              <InsuranceDetails optionsData={newOptionsData} />
            </div>
          )}
        </div>
      )
    }
    if (type === "collectibles") {
      return (
        <div className="portal-form-popup">
          <div className="portal-form-half">
            <CollectibleAntiqueArtsMainForm {...props} />
          </div>
          <div className="portal-form-half">
            <div className="subtitle2">{intl.get("tabs.insuranceDetails")}</div>
            <InsuranceDetails optionsData={newOptionsData} />
          </div>
        </div>
      )
    }
    if (type === "personal_contents") {
      return (
        <div className="portal-form-popup">
          {/* 20200914 MABT-415 toggle checkbox */}
          <div className={values.personalItemInsured ? "portal-form-half" : ""}>
            <PersonalContentMainForm {...props} />
          </div>

          {/* 20200914 MABT-415 toggle checkbox */}
          {values.personalItemInsured && (
            <div className="portal-form-half">
              <div className="subtitle2">
                {intl.get("tabs.insuranceDetails")}
              </div>
              <InsuranceDetails optionsData={newOptionsData} />
            </div>
          )}
        </div>
      )
    }
    if (type === "investment_properties") {
      return (
        <div className="portal-form-popup investment_properties">
          {/* 20200918 MABT-415 toggle checkbox */}
          <div
            className={
              values.fireAndContentInsured || values.mortgageInsured
                ? "portal-form-half"
                : ""
            }
          >
            <InvestmentPropertyMainForm
              {...props}
              optionsData={newOptionsData}
            />
          </div>

          {/* 20200918 Fire & Contents Insurance */}
          {(values.fireAndContentInsured || values.mortgageInsured) && (
            <div className="portal-form-half">
              <div className="subtitle2">
                {intl.get("tabs.insuranceDetails")}
              </div>
              {/* {url.includes('CONTENT') && ( */}
              <PropertyInsuranceDetails
                optionsData={newOptionsData}
                objectName="fireAndContentInsurance"
                varPrefix={"fireAndContentInsurance"}
                varPrefix2={"mortgageInsurance"}
                isFireAndContentInsured={values.fireAndContentInsured}
                isLoanInsured={values.mortgageInsured}
                values={values}
              />
            </div>
          )}
        </div>
      )
    }
    if (
      type === "other_residence_properties" ||
      type === "primary_residence_property"
    ) {
      return (
        <div className="portal-form-popup primary_residence_property">
          {/* 20200914 MABT-415 toggle checkbox */}
          <div
            className={
              values.propertyDetails.fireAndContentInsured ||
              values.propertyDetails.mortgageInsured
                ? "portal-form-half"
                : ""
            }
          >
            <ResidencePropertyMainForm
              {...props}
              optionsData={newOptionsData}
            />
          </div>

          {/* 20200917 MABT-415 toggle checkbox */}
          {(values.propertyDetails.fireAndContentInsured ||
            values.propertyDetails.mortgageInsured) && (
            <div className="portal-form-half">
              <div className="subtitle2">
                {intl.get("tabs.insuranceDetails")}
              </div>

              {/* 20200917 Fire & Contents Insurance */}
              {/* {url.includes('CONTENT') && ( */}
              {(values.propertyDetails.fireAndContentInsured ||
                values.propertyDetails.mortgageInsured) && (
                <PropertyInsuranceDetails
                  optionsData={newOptionsData}
                  objectName="fireAndContentInsurance"
                  varPrefix={"fireAndContentInsurance"}
                  varPrefix2={"mortgageInsurance"}
                  isFireAndContentInsured={
                    values.propertyDetails.fireAndContentInsured
                  }
                  isLoanInsured={values.propertyDetails.mortgageInsured}
                  values={values}
                />
              )}
            </div>
          )}
        </div>
      )
    }
    return <p />
  }

  const showOtherInsuranceModalByUrl = (
    onSubmit,
    model,
    userData,
    newOptionsData
  ) => {
    const modalType = model.url.split("/")[3]

    switch (modalType) {
      case "health_and_medical_insurances":
        return (
          <HealthAndMedicalInsuranceForm
            onSubmit={onSubmit}
            model={model}
            user={userData}
            optionsData={newOptionsData}
          />
        )
      case "life_insurances":
        return (
          <LifeInsuranceForm
            onSubmit={onSubmit}
            model={model}
            user={userData}
            optionsData={newOptionsData}
          />
        )
      case "total_disability_insurances":
        return (
          <TotalPermanentDisabilityInsuranceForm
            onSubmit={onSubmit}
            model={model}
            user={userData}
            optionsData={newOptionsData}
          />
        )
      case "income_protection_insurances":
        return (
          <IncomeProtectionInsuranceForm
            onSubmit={onSubmit}
            model={model}
            user={userData}
            optionsData={newOptionsData}
          />
        )
      case "other_general_insurances":
        return (
          <OtherGeneralInsuranceForm
            onSubmit={onSubmit}
            model={model}
            user={userData}
            optionsData={newOptionsData}
          />
        )
      case "ilas":
        return (
          <Form
            onSubmit={onSubmit}
            initialValues={model}
            mutators={{
              ...arrayMutators,
              setFormAttribute: (
                [fieldName, fieldVal],
                state,
                { changeValue }
              ) => {
                changeValue(state, fieldName, () => fieldVal)
              }
            }}
            render={({ handleSubmit, mutators, reset }) => (
              <form id="insuranceForm" onSubmit={handleSubmit}>
                <ILASInvestmentForm
                  handleSubmit={handleSubmit}
                  optionsData={optionsData}
                  mutators={mutators}
                  reset={reset}
                  readOnlyAccessComponent={() => {}}
                  clientInformation={clientInformation}
                  client
                  partner
                  isJoint
                />
              </form>
            )}
          />
        )
      default:
        return (
          <Form
            onSubmit={(val) => {
              handleSaveLinkedData(val, modalType, model.url)
            }}
            initialValues={model}
            render={({ handleSubmit }) => (
              <form id="insuranceForm" onSubmit={handleSubmit}>
                <FormSpy subscription={{ values: true }}>
                  {({ values }) =>
                    createLinkedInsuranceForm(
                      modalType,
                      model.url,
                      newOptionsData,
                      userData,
                      values,
                      model // 20200914 MABT-415 add [values] into state
                    )
                  }
                </FormSpy>
                <FormButtons />
              </form>
            )}
          />
        )
    }
  }

  return (
    <div className="portal-part">
      <Accordion accordion={false}>
        <AccordionItem>
          <AccordionItemTitle>
            <CashFlowHeaderListing
              title={intl.get(
                "cashflowlisting.personalExpenses.otherInsurancePremium"
              )}
              isAccordion
              isJointHide={isJointHide}
              clientTotalValue={clientTotalValue}
              partnerTotalValue={partnerTotalValue}
              jointTotalValue={jointTotalValue}
              currency={currency}
            />
          </AccordionItemTitle>
          <AccordionItemBody>
            <CashFlowListingGroup
              varPrefix={`${varPrefix}`}
              dataArray={values.detailResult}
              isRoundColorRed
              isExternalModal
              isJointHide={isJointHide}
              currency={currency}
              onEditClick={(model) =>
                setModal({
                  title: intl.get(
                    "cashflowlisting.personalExpenses.otherInsurancePremium"
                  ),
                  formType: "otherInsurancePremium",
                  content: showOtherInsuranceModalByUrl(
                    handleSave,
                    model,
                    clientInformation,
                    optionsData
                  )
                })
              }
            />
          </AccordionItemBody>
        </AccordionItem>
      </Accordion>
    </div>
  )
}

export default OtherInsurancePremium
