/*
    Author : Erik (erik.malik@devstack.com.au)
    Description : Selection for classification for networth form, read client and partner information directly from networth reducers
*/

import React from "react"
import { Field } from "react-final-form"
import { renderJointSelectionMenuCustom } from "../../helpers"
import { useSelector } from "react-redux"
function ClassificationSelection({ client, partner, isJoint, hideJoint }) {
  const { clientFullNamePreferred, partnerFullNamePreferred } = useSelector(
    (state) => state.entity
  )
  const { model } = useSelector((state) => state.netWorthForm)
  let isNew = model && model.isNew ? model.isNew : false
  let classification = model && model.classification
  return (
    <>
      {partner && isNew && (
        <Field
          name="classification"
          component={renderJointSelectionMenuCustom}
          clientName={
            clientFullNamePreferred ? clientFullNamePreferred : "Client"
          }
          partnerName={
            partnerFullNamePreferred ? partnerFullNamePreferred : "Partner"
          }
          hideJoint={!isJoint || hideJoint}
        />
      )}

      {!partner && isNew && (
        <Field
          name="classification"
          component={renderJointSelectionMenuCustom}
          clientName={
            clientFullNamePreferred ? clientFullNamePreferred : "Client"
          }
          hideJoint={!isJoint || hideJoint}
        />
      )}
      {classification && !isNew && (
        <Field
          name="classification"
          component={renderJointSelectionMenuCustom}
          clientName={
            classification === "CLIENT"
              ? clientFullNamePreferred
                ? clientFullNamePreferred
                : "Client"
              : undefined
          }
          partnerName={
            classification === "PARTNER"
              ? partnerFullNamePreferred
                ? partnerFullNamePreferred
                : "Partner"
              : undefined
          }
          hideJoint={classification !== "JOINT"}
          classification={classification}
        />
      )}
    </>
  )
}

export default ClassificationSelection
