import { isNullOrUndefined } from 'util';
import { getDirectVariableValue } from './GetDirectValue';
import { countFromArray } from './CountFromArray';

export const childrenExpenses = (values, currency) => {
    if (!isNullOrUndefined(values)) {
        const {
            allowance,
            babySitterAndDaycare,
            babySupplies,
            childSupport,
            kidsActivities,
            toys,
            others,
        } = values;

        // count direct variables
        const countAllowance = getDirectVariableValue(allowance, currency);
        const countBabySitterAndDaycare = getDirectVariableValue(
            babySitterAndDaycare,
            currency
        );
        const countBabySupplies = getDirectVariableValue(
            babySupplies,
            currency
        );
        const countChildSupport = getDirectVariableValue(
            childSupport,
            currency
        );
        const countKidActivities = getDirectVariableValue(
            kidsActivities,
            currency
        );
        const countToys = getDirectVariableValue(toys, currency);
        const countOthers = countFromArray(others, currency);
        return {
            clientTotal:
                countAllowance.clientTotal +
                countBabySitterAndDaycare.clientTotal +
                countBabySupplies.clientTotal +
                countChildSupport.clientTotal +
                countKidActivities.clientTotal +
                countToys.clientTotal +
                countOthers.clientTotal,
            partnerTotal:
                countAllowance.partnerTotal +
                countBabySitterAndDaycare.partnerTotal +
                countBabySupplies.partnerTotal +
                countChildSupport.partnerTotal +
                countKidActivities.partnerTotal +
                countToys.partnerTotal +
                countOthers.partnerTotal,
            jointTotal:
                countAllowance.jointTotal +
                countBabySitterAndDaycare.jointTotal +
                countBabySupplies.jointTotal +
                countChildSupport.jointTotal +
                countKidActivities.jointTotal +
                countToys.jointTotal +
                countOthers.jointTotal,
        };
    }
};
