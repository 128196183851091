/*
    Author : Erik Farhan Malik (erikfarhanmalik@gmail.com)
    Description : Property Expenses Form
*/

import React from "react"
import intl from "react-intl-universal"
import { Field } from "react-final-form"
import { FieldArray } from "react-final-form-arrays"
import NumberFormat from "react-number-format"
import {
  renderCurrencyCustom,
  renderTextBoxCustom,
  renderSelectEnumFieldCustom,
  getSymbolFromCode,
  getNewCurrencyValue
} from "../../helpers"
import { required } from "../../helpers/Validator"
import LoanRepaymentAmountWithInterestCalculation from "../../services/LoanRepaymentAmountWithInterestCalculation"
import { DECIMAL_SCALE } from "../../constants/NumberFormatConstants"
import { LoanFrequencyEnum } from "../../constants/Enums"

export const PropertyExpensesMutators = {
  adjustMonthlyYearlyValue
}

function adjustMonthlyYearlyValue(args, state, utils) {
  const value = args[0]
  const inputName = args[1]
  const targetInputName = inputName.includes("Yearly")
    ? inputName.replace("Yearly", "")
    : `${inputName}Yearly`

  if (targetInputName.includes("others")) {
    utils.changeValue(state, targetInputName, () =>
      inputName.includes("Yearly") ? value / 12 : value * 12
    )
  } else {
    utils.changeValue(state, `propertyExpenses.${targetInputName}`, () =>
      inputName.includes("Yearly") ? value / 12 : value * 12
    )
  }
}

const PropertyExpenses = ({
  onMonthlyYearlyValueChange,
  addOtherExpense,
  removeOtherExpense,
  disabled,
  optionsData,
  model,
  currencyItem
}) => {
  const currency = {
    name: currencyItem,
    symbol: getSymbolFromCode(currencyItem)
  }

  const formDefinition = [
    { name: "bodyCorporateFees" },
    { name: "repairs" },
    { name: "propertyTaxes" },
    { name: "furnishings" },
    { name: "improvement" },
    { name: "services" },
    { name: "supplies" },
    { name: "lawnAndGarden" }
  ]

  const grandTotal = (values) => {
    let total =
      countValues(values) +
      (values.propertyExpenses && values.propertyExpenses.propertyLandTax
        ? +values.propertyExpenses.propertyLandTax
        : 0)
    //      +
    // subtotalFinancePayments(values) +
    // insurancePremiumSubtotal(values);

    return total
  }

  function mortgageInsuranceConversion(values) {
    let mortgageInsurance = 0
    if (values.mortgageInsurance && values.mortgageInsurance.premiumMonthly) {
      mortgageInsurance = getNewCurrencyValue(
        values.mortgageInsurance.premiumMonthly,
        currency.name
      )
    }
    return mortgageInsurance
  }

  function fireAndContentInsuranceConversion(values) {
    let fireAndContentInsurance = 0
    if (
      values.fireAndContentInsurance &&
      values.fireAndContentInsurance.premiumMonthly
    ) {
      fireAndContentInsurance = getNewCurrencyValue(
        values.fireAndContentInsurance.premiumMonthly,
        currency.name
      )
    }
    return fireAndContentInsurance
  }
  function insurancePremiumSubtotal(values) {
    return (
      mortgageInsuranceConversion(values) +
      fireAndContentInsuranceConversion(values)
    )
  }
  function countValuesYearly(values) {
    if (values.propertyExpenses) {
      const expenses = formDefinition.map((key) => {
        const value = Number(values.propertyExpenses[`${key.name}Yearly`])
        return Number.isNaN(value) ? 0 : value
      })

      const otherExpenses = (values.propertyExpenses.others || [])
        .filter((otherExpense) =>
          Object.keys(otherExpense).find((key) => key.includes("Yearly"))
        )
        .map((otherExpense) => Number(otherExpense.valueYearly) || 0)

      return [...expenses, ...otherExpenses].reduce(
        (sum, current) => sum + current,
        0
      )
    }
    return 0
  }
  const loanRepaymentAndInterest = (values) =>
    getNewCurrencyValue(
      {
        currency:
          values.mortgage && (values.mortgage.borrowingsCurrency || "AUD"),
        value: LoanRepaymentAmountWithInterestCalculation(values.mortgage)
      },
      currency.name || "AUD"
    )
  const mortgagePaymentAndInterest = (values) =>
    // +loanRepaymentAndInterest(values, currency) +
    values.mortgage && (+values.mortgage.additionalPaymentAmount || 0)
  const mortgageAssociatedCharges = (values) => {
    if (!values.mortgage) {
      return 0
    }
    let x = 1
    if (
      values.mortgage.borrowingsManagementFeesFrequency ===
      LoanFrequencyEnum.Frequency_FORTNIGHTLY
    ) {
      x = 26 / 12
    }
    let y = 1
    if (
      values.mortgage.additionalRepaymentFrequency ===
      LoanFrequencyEnum.Frequency_FORTNIGHTLY
    ) {
      y = 26 / 12
    }
    return (
      y * +(values.mortgage.additionalRepaymentHandlingFees || 0) +
      x * +(values.mortgage.borrowingsManagementFees || 0)
    )
  }

  const subtotalFinancePayments = (values) =>
    mortgagePaymentAndInterest(values, currency) +
      mortgageAssociatedCharges(values) || 0

  const countValues = (values) => {
    if (values.propertyExpenses) {
      const expenses = formDefinition.map((key) => {
        const value = Number(values.propertyExpenses[key.name])
        return Number.isNaN(value) ? 0 : value
      })

      const otherExpenses = (values.propertyExpenses.others || [])
        .filter((otherExpense) =>
          Object.keys(otherExpense).find((key) => !key.includes("Yearly"))
        )
        .map((otherExpense) => Number(otherExpense.value) || 0)

      return [...expenses, ...otherExpenses].reduce(
        (sum, current) => sum + current,
        0
      )
    }
    return 0
  }

  return (
    <div className="pop-holder2col">
      <div className="content-panel-title">
        <div className="title-box">{intl.get("field.monthly")}</div>
        <div className="title-box">{intl.get("field.yearly")}</div>
      </div>

      {/* <div className="portal-form-row expensesCurrency">
        <label className="subtitlelabel label-expensesCurrency">
          {intl.get("field.expensesCurrency")}
        </label>
        <Field
          name="propertyExpenses.expensesCurrency"
          component={renderSelectEnumFieldCustom}
          enumData={optionsData.currencyOptions}
          disabled={disabled}
        />
      </div> */}
      <div className="portal-form-row">
        <label className="subtitlelabel">{"General Expenses"}</label>
      </div>
      {formDefinition.map((item, index) => (
        <div className="portal-form-row" key={index}>
          <label>
            {intl.get(
              `primaryResidencePropertyOwnership.details.expense.${item.name}`
            )}
          </label>
          <Field
            name={`propertyExpenses.${item.name}`}
            type="text"
            component={renderCurrencyCustom}
            onChange={(newValue) =>
              onMonthlyYearlyValueChange(newValue, item.name)
            }
            disabled={disabled || item.disabled}
            symbolPrefix={currency.symbol}
            allowNegative={false}
          />
          <Field
            name={`propertyExpenses.${item.name}Yearly`}
            type="text"
            component={renderCurrencyCustom}
            onChange={(newValue) =>
              onMonthlyYearlyValueChange(newValue, `${item.name}Yearly`)
            }
            disabled={disabled || item.disabled}
            symbolPrefix={currency.symbol}
            allowNegative={false}
          />
        </div>
      ))}
      <div className="portal-form-row">
        <label>
          <b>Others</b>
        </label>
        {!disabled && (
          <div className="row-holder">
            <button
              type="button"
              onClick={() =>
                addOtherExpense("propertyExpenses.others", {
                  value: 0,
                  valueYearly: 0
                })
              }
              className="btn btn-plus"
            />
          </div>
        )}
      </div>
      <FieldArray name="propertyExpenses.others">
        {({ fields }) =>
          fields.map((name, index) => (
            <div className="portal-form-row other-expense" key={index}>
              <Field
                name={`${name}.name`}
                component={renderTextBoxCustom}
                disabled={disabled}
                validate={required}
                containerCssClass={"networth-other-input"}
              />
              <Field
                name={`${name}.value`}
                type="text"
                component={renderCurrencyCustom}
                onChange={(newValue) =>
                  onMonthlyYearlyValueChange(newValue, `${name}.value`)
                }
                disabled={disabled}
                containerCssClass={"networth-other-col"}
                symbolPrefix={currency.symbol}
              />
              <Field
                name={`${name}.valueYearly`}
                type="text"
                component={renderCurrencyCustom}
                onChange={(newValue) =>
                  onMonthlyYearlyValueChange(newValue, `${name}.valueYearly`)
                }
                disabled={disabled}
                symbolPrefix={currency.symbol}
                containerCssClass={"networth-other-col"}
              />
              <button
                type="button"
                onClick={() =>
                  removeOtherExpense("propertyExpenses.others", index)
                }
                className="btn-remove networth-other-remove"
              />
            </div>
          ))
        }
      </FieldArray>

      <div className="portal-form-row">
        <label className="subtitlelabel">
          {"Sub Total - General Expenses"}
        </label>
        <div className="row-holder">
          <NumberFormat
            value={countValues(model)}
            displayType={"input"}
            allowNegative
            decimalScale={DECIMAL_SCALE}
            prefix={currency.symbol}
            decimalSeparator={"."}
            thousandSeparator={","}
            className={`text-input currency-input text-input-total`}
            readOnly
          />
        </div>
        <div className="row-holder">
          <NumberFormat
            value={countValuesYearly(model)}
            displayType={"input"}
            allowNegative
            decimalScale={DECIMAL_SCALE}
            prefix={currency.symbol}
            decimalSeparator={"."}
            thousandSeparator={","}
            className={`text-input currency-input text-input-total`}
            readOnly
          />
        </div>
      </div>
      <div className="portal-form-row">
        <label className="subtitlelabel">{"Tax"}</label>
      </div>
      <div className="portal-form-row">
        <label>{"Property / Land Tax"}</label>
        <Field
          name={`propertyExpenses.propertyLandTax`}
          type="text"
          component={renderCurrencyCustom}
          symbolPrefix={currency.symbol}
          onChange={(newValue) =>
            onMonthlyYearlyValueChange(newValue, `propertyLandTax`)
          }
        />
        <Field
          name={`propertyExpenses.propertyLandTaxYearly`}
          type="text"
          component={renderCurrencyCustom}
          symbolPrefix={currency.symbol}
          onChange={(newValue) =>
            onMonthlyYearlyValueChange(newValue, `propertyLandTaxYearly`)
          }
        />
      </div>

      <div className="portal-form-row">
        <label className="subtitlelabel">{"GRAND TOTAL"}</label>
        <div className="row-holder">
          <NumberFormat
            value={grandTotal(model)}
            displayType={"input"}
            allowNegative
            decimalScale={DECIMAL_SCALE}
            prefix={currency.symbol}
            decimalSeparator={"."}
            thousandSeparator={","}
            className={`text-input currency-input text-input-total`}
            readOnly
          />
        </div>
        <div className="row-holder">
          <NumberFormat
            value={grandTotal(model) * 12}
            displayType={"input"}
            allowNegative
            decimalScale={DECIMAL_SCALE}
            prefix={currency.symbol}
            decimalSeparator={"."}
            thousandSeparator={","}
            className={`text-input currency-input text-input-total`}
            readOnly
          />
        </div>
      </div>
    </div>
  )
}

export default PropertyExpenses
