/*
    Author : Marlina (marlina@devstack.com.au)
    Author : Erik (erik.malik@devstack.com.au)
    Description : Unit trust main page (modal)
*/

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import intl from 'react-intl-universal';
import { netWorthFormActions, modalWindowActions } from '../../actions';
import { modalWindow } from '../../constants/ModalWindowLists';
import NetWorthForm, {
    NetWorthPath,
    NetWorthFormPropsComposer,
} from './NetWorthForm';
import { UnitTrustForm } from '../../forms';

class UnitTrust extends NetWorthForm {
    constructor(props) {
        super(
            props,
            intl.get('unitTrusts.title'),
            modalWindow.UNIT_TRUST_FORM,
            NetWorthPath.unitTrusts,
            'UnitTrust'
        );
    }

    render() {
        const {
            optionsData,
            model,
            client,
            partner,
            isJoint,
            currency,
        } = this.props;
        const { handleSave, contentWrapper, readOnlyAccessComponent } = this;

        return contentWrapper(
            <UnitTrustForm
                handleSave={handleSave}
                model={model}
                optionsData={optionsData}
                readOnlyAccess={readOnlyAccessComponent()}
                clientInformation={{ client, partner, isJoint }}
                defaultCurrency={currency}
            />
        );
    }
}

function mapStateToProps(state) {
    return NetWorthFormPropsComposer(
        state,
        modalWindow.UNIT_TRUST_FORM,
        NetWorthPath.unitTrusts
    );
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        Object.assign({}, netWorthFormActions, modalWindowActions),
        dispatch
    );
}

UnitTrust = connect(
    mapStateToProps,
    mapDispatchToProps
)(UnitTrust);
export default UnitTrust;
