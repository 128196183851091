/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Description : Car Expenses page (tab component)
*/

import React from "react"
import intl from "react-intl-universal"
import { Field } from "react-final-form"
import NumberFormat from "react-number-format"
import {
  renderSelectEnumFieldCustom,
  renderCurrencyCustom,
  getNewCurrencyValue
} from "../../helpers"
import { DECIMAL_SCALE } from "../../constants/NumberFormatConstants"
import { useSelector } from "react-redux"

const prefixMonthly = "vehicleExpenses"
const prefixYearly = "vehicleExpensesYearly"

function FinacePayment({ values, currency }) {
  const { underLoan, underLease, underHirePurchase } = values
  const { defaultCurrency } = useSelector((state) => state.personalDetails)
  return (
    <React.Fragment>
      <br />
      <br />
      <div className="subtitle2">{intl.get("field.financePayments")}</div>
      {underLoan && (
        <React.Fragment>
          <div className="portal-form-row">
            <label>Loan Payment + Interest</label>
            <div className="row-holder ">
              <NumberFormat
                value={
                  getNewCurrencyValue(
                    {
                      currency:
                        values.loan &&
                        (values.loan.borrowingsCurrency || defaultCurrency),
                      value:
                        values.loan &&
                        (values.loan.borrowingsRepaymentAmount || 0)
                    },
                    values.vehicleExpenses &&
                      (values.vehicleExpenses.expensesCurrency ||
                        defaultCurrency)
                  ) +
                  (getNewCurrencyValue(
                    {
                      currency:
                        values.loan &&
                        (values.loan.borrowingsCurrency || defaultCurrency),
                      value:
                        values.loan &&
                        (values.loan.additionalPaymentAmount || 0)
                    },
                    values.vehicleExpenses &&
                      (values.vehicleExpenses.expensesCurrency ||
                        defaultCurrency)
                  ) || 0)
                }
                disabled
                prefix={currency}
                decimalScale={DECIMAL_SCALE}
                decimalSeparator={"."}
                thousandSeparator={","}
                className={`text-input currency-input `}
              />
            </div>
            <div className="row-holder ">
              <NumberFormat
                value={
                  (getNewCurrencyValue(
                    {
                      currency:
                        values.loan &&
                        (values.loan.borrowingsCurrency || defaultCurrency),
                      value:
                        values.loan &&
                        (values.loan.borrowingsRepaymentAmount || 0)
                    },
                    values.vehicleExpenses.expensesCurrency || defaultCurrency
                  ) +
                    (getNewCurrencyValue(
                      {
                        currency:
                          values.loan &&
                          (values.loan.borrowingsCurrency || defaultCurrency),
                        value:
                          values.loan &&
                          (values.loan.additionalPaymentAmount || 0)
                      },
                      values.vehicleExpenses.expensesCurrency || defaultCurrency
                    ) || 0)) *
                  12
                }
                disabled
                prefix={currency}
                decimalScale={DECIMAL_SCALE}
                decimalSeparator={"."}
                thousandSeparator={","}
                className={`text-input currency-input `}
              />
            </div>
          </div>
          <div className="portal-form-row">
            <label>Loan Assciated Charges</label>
            <div className="row-holder ">
              <NumberFormat
                value={
                  values.loan &&
                  (values.loan.additionalRepaymentHandlingFees || 0) +
                    values.loan &&
                  (values.loan.borrowingsManagementFees || 0)
                }
                disabled
                prefix={currency}
                decimalScale={DECIMAL_SCALE}
                decimalSeparator={"."}
                thousandSeparator={","}
                className={`text-input currency-input `}
              />
            </div>
            <div className="row-holder ">
              <NumberFormat
                value={
                  (values.loan &&
                    (values.loan.additionalRepaymentHandlingFees || 0) +
                      values.loan &&
                    (values.loan.borrowingsManagementFees || 0)) * 12
                }
                disabled
                prefix={currency}
                decimalScale={DECIMAL_SCALE}
                decimalSeparator={"."}
                thousandSeparator={","}
                className={`text-input currency-input `}
              />
            </div>
          </div>
        </React.Fragment>
      )}
      {underLease && (
        <div className="portal-form-row">
          <label>Car Lease Finance Payment</label>
          <div className="row-holder ">
            <NumberFormat
              value={getNewCurrencyValue(
                {
                  currency:
                    values.carLease &&
                    (values.carLease.borrowingsCurrency || defaultCurrency),
                  value:
                    values.carLease && (values.carLease.financingPayments || 0)
                },
                values.vehicleExpenses &&
                  (values.vehicleExpenses.expensesCurrency || defaultCurrency)
              )}
              disabled
              prefix={currency}
              decimalScale={DECIMAL_SCALE}
              decimalSeparator={"."}
              thousandSeparator={","}
              className={`text-input currency-input `}
            />
          </div>
          <div className="row-holder ">
            <NumberFormat
              value={
                getNewCurrencyValue(
                  {
                    currency:
                      values.carLease &&
                      (values.carLease.borrowingsCurrency || defaultCurrency),
                    value:
                      values.carLease &&
                      (values.carLease.financingPayments || 0)
                  },
                  values.vehicleExpenses &&
                    (values.vehicleExpenses.expensesCurrency || defaultCurrency)
                ) * 12
              }
              disabled
              prefix={currency}
              decimalScale={DECIMAL_SCALE}
              decimalSeparator={"."}
              thousandSeparator={","}
              className={`text-input currency-input `}
            />
          </div>
        </div>
      )}
      {underHirePurchase && (
        <div className="portal-form-row">
          <label>Hire Purchase Finance Payment</label>
          <div className="row-holder ">
            <NumberFormat
              value={getNewCurrencyValue(
                {
                  currency:
                    values.hirePurchase &&
                    (values.hirePurchase.borrowingsCurrency || defaultCurrency),
                  value:
                    values.hirePurchase &&
                    (values.hirePurchase.financingPayments || 0)
                },
                values.vehicleExpenses &&
                  (values.vehicleExpenses.expensesCurrency || defaultCurrency)
              )}
              disabled
              prefix={currency}
              decimalScale={DECIMAL_SCALE}
              decimalSeparator={"."}
              thousandSeparator={","}
              className={`text-input currency-input `}
            />
          </div>
          <div className="row-holder ">
            <NumberFormat
              value={
                getNewCurrencyValue(
                  {
                    currency:
                      values.hirePurchase &&
                      (values.hirePurchase.borrowingsCurrency ||
                        defaultCurrency),
                    value:
                      values.hirePurchase &&
                      (values.hirePurchase.financingPayments || 0)
                  },
                  values.vehicleExpenses &&
                    (values.vehicleExpenses.expensesCurrency || defaultCurrency)
                ) * 12
              }
              disabled
              prefix={currency}
              decimalScale={DECIMAL_SCALE}
              decimalSeparator={"."}
              thousandSeparator={","}
              className={`text-input currency-input `}
            />
          </div>
        </div>
      )}

      <div className="portal-form-row">
        <label style={{ fontWeight: "bold" }}>
          Sub Total - Finance Payments
        </label>
        <div className="row-holder ">
          <NumberFormat
            value={financeTotal(values, prefixMonthly)}
            disabled
            prefix={currency}
            decimalScale={DECIMAL_SCALE}
            decimalSeparator={"."}
            thousandSeparator={","}
            className={`text-input currency-input text-input-total`}
          />
        </div>
        <div className="row-holder ">
          <NumberFormat
            value={financeTotal(values, prefixYearly)}
            disabled
            prefix={currency}
            decimalScale={DECIMAL_SCALE}
            decimalSeparator={"."}
            thousandSeparator={","}
            className={`text-input currency-input text-input-total`}
          />
        </div>
      </div>
    </React.Fragment>
  )
}
function InsurancePremium({ values, currency }) {
  let defaultCurrency = localStorage.getItem("theCurrency")

  return (
    <React.Fragment>
      <br />
      <br />
      <div className="subtitle2">{intl.get("field.insurancePremium")}</div>
      <div className="portal-form-row">
        <label>Motor Vehicle Insurance</label>
        <div className="row-holder ">
          <NumberFormat
            value={
              values.insuranceDetails
                ? getNewCurrencyValue(
                    values.insuranceDetails.premiumMonthly,
                    values.vehicleExpenses &&
                      (values.vehicleExpenses.expensesCurrency ||
                        defaultCurrency)
                  ) || 0
                : 0
            }
            disabled
            prefix={currency}
            decimalScale={DECIMAL_SCALE}
            decimalSeparator={"."}
            thousandSeparator={","}
            className={`text-input currency-input `}
          />
        </div>
        <div className="row-holder ">
          <NumberFormat
            value={
              (values.insuranceDetails
                ? getNewCurrencyValue(
                    values.insuranceDetails.premiumMonthly,
                    values.vehicleExpenses &&
                      (values.vehicleExpenses.expensesCurrency ||
                        defaultCurrency)
                  ) || 0
                : 0) * 12
            }
            disabled
            prefix={currency}
            decimalScale={DECIMAL_SCALE}
            decimalSeparator={"."}
            thousandSeparator={","}
            className={`text-input currency-input `}
          />
        </div>
      </div>
      <div className="portal-form-row">
        <label style={{ fontWeight: "bold" }}>
          Sub Total - Insurance Premium
        </label>
        <div className="row-holder ">
          <NumberFormat
            value={
              values.insuranceDetails
                ? getNewCurrencyValue(
                    values.insuranceDetails.premiumMonthly,
                    values.vehicleExpenses &&
                      (values.vehicleExpenses.expensesCurrency ||
                        defaultCurrency)
                  ) || 0
                : 0
            }
            disabled
            prefix={currency}
            decimalScale={DECIMAL_SCALE}
            decimalSeparator={"."}
            thousandSeparator={","}
            className={`text-input currency-input `}
          />
        </div>
        <div className="row-holder ">
          <NumberFormat
            value={
              (values.insuranceDetails
                ? getNewCurrencyValue(
                    values.insuranceDetails.premiumMonthly,
                    values.vehicleExpenses &&
                      (values.vehicleExpenses.expensesCurrency ||
                        defaultCurrency)
                  ) || 0
                : 0) * 12
            }
            disabled
            prefix={currency}
            decimalScale={DECIMAL_SCALE}
            decimalSeparator={"."}
            thousandSeparator={","}
            className={`text-input currency-input `}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

const CarExpense = ({
  currencyOptions,
  onMonthlyExpenseChange,
  onYearlyExpenseChange,
  currency,
  values,
  readOnly
}) => {
  return (
    <div className="pop-holder2col">
      <div className="content-panel-title">
        <div className="title-box">{intl.get("field.monthly")}</div>
        <div className="title-box">{intl.get("field.yearly")}</div>
      </div>

      <br />
      <br />
      <div className="subtitle2">{intl.get("field.generalExpenses")}</div>
      <div className="portal-form-row">
        <label>{intl.get("field.serviceAndMaintenance")}</label>
        <Field
          name={`${prefixMonthly}.serviceAndMaintenance`}
          component={renderCurrencyCustom}
          onChange={(value) =>
            onMonthlyExpenseChange(value, "serviceAndMaintenance")
          }
          symbolPrefix={currency}
          disabled={readOnly}
          allowNegative={false}
        />
        <Field
          name={`${prefixYearly}.serviceAndMaintenance`}
          component={renderCurrencyCustom}
          onChange={(value) =>
            onYearlyExpenseChange(value, "serviceAndMaintenance")
          }
          symbolPrefix={currency}
          disabled={readOnly}
          allowNegative={false}
        />
      </div>

      <div className="portal-form-row">
        <label>{intl.get("field.replacementTypes")}</label>
        <Field
          name={`${prefixMonthly}.replacementTypes`}
          component={renderCurrencyCustom}
          onChange={(value) =>
            onMonthlyExpenseChange(value, "replacementTypes")
          }
          symbolPrefix={currency}
          disabled={readOnly}
          allowNegative={false}
        />
        <Field
          name={`${prefixYearly}.replacementTypes`}
          component={renderCurrencyCustom}
          onChange={(value) => onYearlyExpenseChange(value, "replacementTypes")}
          symbolPrefix={currency}
          disabled={readOnly}
          allowNegative={false}
        />
      </div>

      <div className="portal-form-row">
        <label>{intl.get("field.carRegistration")}</label>
        <Field
          name={`${prefixMonthly}.carRegistration`}
          component={renderCurrencyCustom}
          onChange={(value) => onMonthlyExpenseChange(value, "carRegistration")}
          symbolPrefix={currency}
          disabled={readOnly}
          allowNegative={false}
        />
        <Field
          name={`${prefixYearly}.carRegistration`}
          component={renderCurrencyCustom}
          onChange={(value) => onYearlyExpenseChange(value, "carRegistration")}
          symbolPrefix={currency}
          disabled={readOnly}
          allowNegative={false}
        />
      </div>

      <div className="portal-form-row">
        <label>{intl.get("field.fuel")}</label>
        <Field
          name={`${prefixMonthly}.fuel`}
          component={renderCurrencyCustom}
          onChange={(value) => onMonthlyExpenseChange(value, "fuel")}
          symbolPrefix={currency}
          disabled={readOnly}
          allowNegative={false}
        />
        <Field
          name={`${prefixYearly}.fuel`}
          component={renderCurrencyCustom}
          onChange={(value) => onYearlyExpenseChange(value, "fuel")}
          symbolPrefix={currency}
          disabled={readOnly}
          allowNegative={false}
        />
      </div>

      <div className="portal-form-row">
        <label>{intl.get("field.roadsideAssistance")}</label>
        <Field
          name={`${prefixMonthly}.roadsideAssistance`}
          component={renderCurrencyCustom}
          onChange={(value) =>
            onMonthlyExpenseChange(value, "roadsideAssistance")
          }
          symbolPrefix={currency}
          disabled={readOnly}
          allowNegative={false}
        />
        <Field
          name={`${prefixYearly}.roadsideAssistance`}
          component={renderCurrencyCustom}
          onChange={(value) =>
            onYearlyExpenseChange(value, "roadsideAssistance")
          }
          symbolPrefix={currency}
          disabled={readOnly}
          allowNegative={false}
        />
      </div>

      <div className="portal-form-row">
        <label>{intl.get("field.administrationFee")}</label>
        <Field
          name={`${prefixMonthly}.administrationFee`}
          component={renderCurrencyCustom}
          onChange={(value) =>
            onMonthlyExpenseChange(value, "administrationFee")
          }
          symbolPrefix={currency}
          disabled={readOnly}
          allowNegative={false}
        />
        <Field
          name={`${prefixYearly}.administrationFee`}
          component={renderCurrencyCustom}
          onChange={(value) =>
            onYearlyExpenseChange(value, "administrationFee")
          }
          symbolPrefix={currency}
          disabled={readOnly}
          allowNegative={false}
        />
      </div>

      <div className="portal-form-row">
        <label>{intl.get("field.carbonEmissionOffset")}</label>
        <Field
          name={`${prefixMonthly}.carbonEmissionOffset`}
          component={renderCurrencyCustom}
          onChange={(value) =>
            onMonthlyExpenseChange(value, "carbonEmissionOffset")
          }
          symbolPrefix={currency}
          allowNegative={false}
        />
        <Field
          name={`${prefixYearly}.carbonEmissionOffset`}
          component={renderCurrencyCustom}
          onChange={(value) =>
            onYearlyExpenseChange(value, "carbonEmissionOffset")
          }
          symbolPrefix={currency}
          disabled={readOnly}
          allowNegative={false}
        />
      </div>

      <div className="portal-form-row">
        <label>{intl.get("field.otherExpenses")}</label>
        <Field
          name={`${prefixMonthly}.otherExpenses`}
          component={renderCurrencyCustom}
          onChange={(value) => onMonthlyExpenseChange(value, "otherExpenses")}
          symbolPrefix={currency}
          disabled={readOnly}
          allowNegative={false}
        />
        <Field
          name={`${prefixYearly}.otherExpenses`}
          component={renderCurrencyCustom}
          onChange={(value) => onYearlyExpenseChange(value, "otherExpenses")}
          symbolPrefix={currency}
          disabled={readOnly}
          allowNegative={false}
        />
      </div>

      <div className="portal-form-row">
        <label style={{ fontWeight: "bold" }}>
          Sub Total - General Expenses
        </label>
        <div className="row-holder">
          <NumberFormat
            value={countValues(values, prefixMonthly)}
            displayType={"input"}
            allowNegative
            decimalScale={DECIMAL_SCALE}
            prefix={currency}
            decimalSeparator={"."}
            thousandSeparator={","}
            className={`text-input currency-input text-input-total`}
            readOnly
          />
        </div>
        <div className="row-holder">
          <NumberFormat
            value={countValues(values, prefixYearly)}
            displayType={"input"}
            allowNegative
            decimalScale={DECIMAL_SCALE}
            prefix={currency}
            decimalSeparator={"."}
            thousandSeparator={","}
            className={`text-input currency-input text-input-total`}
            readOnly
          />
        </div>
      </div>

      {(values.underLoan || values.underLease || values.underHirePurchase) && (
        <FinacePayment values={values} currency={currency} />
      )}

      {values.insured && (
        <InsurancePremium values={values} currency={currency} />
      )}

      <div className="portal-form-row">
        <label style={{ fontWeight: "bold" }}>GRAND TOTAL</label>
        <div className="row-holder ">
          <NumberFormat
            value={grandTotal(values, prefixMonthly)}
            disabled
            prefix={currency}
            decimalScale={DECIMAL_SCALE}
            decimalSeparator={"."}
            thousandSeparator={","}
            className={`text-input currency-input text-input-total`}
          />
        </div>
        <div className="row-holder ">
          <NumberFormat
            value={grandTotal(values, prefixYearly)}
            disabled
            prefix={currency}
            decimalScale={DECIMAL_SCALE}
            decimalSeparator={"."}
            thousandSeparator={","}
            className={`text-input currency-input text-input-total`}
          />
        </div>
      </div>
    </div>
  )
}
const countValues = (values, prefix) => {
  const object = values[prefix]
  if (object && Object.keys(object).length > 0) {
    return Object.keys(object)
      .map((key) => {
        const value = key !== "totalExpenses" ? Number(object[key]) : 0
        return Number.isNaN(value) ? 0 : value
      })
      .reduce((sum, current) => sum + current)
  }
  return 0
}

const financeTotal = (values, prefix) => {
  const { underLoan, underLease, underHirePurchase } = values
  const { defaultCurrency } = useSelector((state) => state.personalDetails)

  let financeTotalIs = 0
  if (underLoan) {
    financeTotalIs =
      getNewCurrencyValue(
        {
          currency:
            values.loan && (values.loan.borrowingsCurrency || defaultCurrency),
          value: values.loan && (values.loan.borrowingsRepaymentAmount || 0)
        },
        values.vehicleExpenses &&
          (values.vehicleExpenses.expensesCurrency || defaultCurrency)
      ) +
      (getNewCurrencyValue(
        {
          currency:
            values.loan && (values.loan.borrowingsCurrency || defaultCurrency),
          value: values.loan && (values.loan.additionalPaymentAmount || 0)
        },
        values.vehicleExpenses &&
          (values.vehicleExpenses.expensesCurrency || defaultCurrency)
      ) || 0) +
      (getNewCurrencyValue(
        {
          currency:
            values.loan && (values.loan.borrowingsCurrency || defaultCurrency),
          value:
            values.loan &&
            (values.loan.additionalRepaymentHandlingFees || 0) + values.loan &&
            (values.loan.borrowingsManagementFees || 0)
        },
        values.vehicleExpenses &&
          (values.vehicleExpenses.expensesCurrency || defaultCurrency)
      ) || 0)
  } else if (underLease) {
    financeTotalIs = getNewCurrencyValue(
      {
        currency:
          values.carLease &&
          (values.carLease.borrowingsCurrency || defaultCurrency),
        value: values.carLease && (values.carLease.financingPayments || 0)
      },
      values.vehicleExpenses.expensesCurrency
    )
  } else if (underHirePurchase) {
    financeTotalIs = getNewCurrencyValue(
      {
        currency:
          values.hirePurchase &&
          (values.hirePurchase.borrowingsCurrency || defaultCurrency),
        value:
          values.hirePurchase && (values.hirePurchase.financingPayments || 0)
      },
      values.vehicleExpenses.expensesCurrency
    )
  }
  financeTotalIs =
    prefix === prefixMonthly ? financeTotalIs : financeTotalIs * 12
  return financeTotalIs
}

const grandTotal = (values, prefix) => {
  const { defaultCurrency } = useSelector((state) => state.personalDetails)

  const ft = financeTotal(values, prefix)
  const generalTotal = countValues(values, prefix)
  let insuranceTotal = values.insuranceDetails
    ? getNewCurrencyValue(
        values.insuranceDetails.premiumMonthly,
        values.vehicleExpenses &&
          (values.vehicleExpenses.expensesCurrency || defaultCurrency)
      ) || 0
    : 0

  // insuranceTotal = insuranceTotal
  return generalTotal + ft + insuranceTotal
}
export default CarExpense

function setMonthlyExpense(args, state, utils) {
  const value = parseFloat(args[0]) * 12
  utils.changeValue(state, `${prefixYearly}.${args[1]}`, () => value)
}
function setYearlyExpense(args, state, utils) {
  const value = parseFloat(args[0]) / 12
  utils.changeValue(state, `${prefixMonthly}.${args[1]}`, () => value)
}
export const CarExpenseMutators = {
  setMonthlyExpense,
  setYearlyExpense
}

export function adjustYearlyExpensesOnLoad(model = {}) {
  model[prefixYearly] = model[prefixYearly] ? model[prefixYearly] : {}
  if (model[prefixMonthly]) {
    Object.keys(model[prefixMonthly]).forEach((key) => {
      const value = Number(model[prefixMonthly][key])
      model[prefixYearly][key] = Number.isNaN(value) ? 0 : value * 12
    })
  }

  return model
}
