export const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
}

export const OwnershipEnum = {
  CLIENT: "CLIENT",
  PARTNER: "PARTNER"
}

export const OwnershipTypeEnum = {
  CLIENT: "CLIENT",
  JOINT: "JOINT",
  PARTNER: "PARTNER"
}

export const EntityLayoutClassName = {
  BUILT_IN_MALABAR_AI_GLOBAL: "dashboard-admin",
  BUILT_IN_MALABAR_AI_SUBSIDIARY: "dashboard-admin",
  BUILT_IN_DEALERSHIP: "dashboard-dealership",
  BUILT_IN_ADVISER_GROUP: "dashboard-advisergroups",
  BUILT_IN_SUB_BRANCH: "dashboard-advisergroups",
  BUILT_IN_ADVISER: "dashboard-adviser"
  // CUSTOM: '',
}

export const DashboardRoute = {
  BUILT_IN_MALABAR_AI_GLOBAL: "/dashboard/subsidiaries",
  BUILT_IN_MALABAR_AI_SUBSIDIARY: "/dashboard/dealerships",
  BUILT_IN_DEALERSHIP: "/dashboard/advisergroups",
  BUILT_IN_ADVISER_GROUP: "/dashboard/subbranches",
  BUILT_IN_SUB_BRANCH: "/dashboard/advisers",
  BUILT_IN_ADVISER: "/dashboard/clients",
  CLIENT: "/dashboard/clientsDashboard",
  DEMO_CLIENT: "/demo/clients"
}
