/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Description : Parse url parameter string helper
*/

export const getJsonFromUrl = url => {
    const query = url.substr(1);
    const result = {};
    query.split('&').forEach(part => {
        const item = part.split('=');
        result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
};
