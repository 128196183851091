/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Library : DashboardApi
    Description : methods related with dashboard
*/

import api from '../lib/api';

// change base route for different entity/domain here
const BASE_ROUTE = '/dashboard';

class DashboardApi {
    // get dashboard data by type
    static getDashboardByType(entityType) {
        return api.get(`${BASE_ROUTE}-by-type/${entityType}`);
    }

    // get dashboard data by id
    static getDashboardById(id) {
        return api.get(`${BASE_ROUTE}-by-id/${id}`);
    }

    // get dashboard data by id
    static getClientDashboardById(id) {
        return api.get(`/client/${id}/dashboard`);
    }

    // get data based on filtering and pagination
    static getFilteredPagedData(baseUrl, queryString) {
        return api.get(`${baseUrl}?${queryString}`);
    }
}

export default DashboardApi;
