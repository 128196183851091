/*
    Author : Erik Farhan Malik (erikfarhanmalik@gmail.com)
    Description : Mortgage details form
*/

import React from "react"
import intl from "react-intl-universal"
import ReadOnlyCurrency from "../../components/ReadOnlyCurrency"

const NetWorthListingSummary = ({
  title,
  data,
  hideSubTotal = false,
  currency = "MYR"
}) => {
  const flattenData = flat(data)
  const classifications = new Set(flattenData.map((d) => d.classification))
  const groupedData = groupDataByClassification(classifications, flattenData)
  const grossTotal = countGrossTotal(groupedData)
  return (
    <React.Fragment>
      <div className="subtitle3">
        {/* <div className="btn-holder">
                    <span className="icon2 ico-eye" />
                </div> */}
      </div>
      {/* asdfadsfadsfjdsjfdasjfdsjfdsjkfh */}
      {!hideSubTotal &&
        groupedData.map((groupedItem, index) => (
          <div className="portal-form-row" key={index}>
            <label style={{ padding: 0 }}>
              <h3>{title}</h3>
            </label>
            <div className="row-holder text-right">
              {groupedItem.classification}
            </div>
            <div className="row-holder">
              <ReadOnlyCurrency
                value={groupedItem.assets}
                type="2"
                currency={currency}
              />
            </div>
            <div className="row-holder">
              <ReadOnlyCurrency
                value={groupedItem.liabilities * -1}
                type="2"
                additionalClass="c-red"
                currency={currency}
              />
            </div>
            <div className="row-holder">
              <ReadOnlyCurrency
                value={groupedItem.assets - groupedItem.liabilities}
                type="2"
                currency={currency}
              />
            </div>
          </div>
        ))}
      <div className="portal-form-row">
        <label />
        <div className="row-holder text-grey text-black text-right">
          {intl.get("netWorth.grossTotal")}
        </div>
        <div className="row-holder">
          <ReadOnlyCurrency
            value={grossTotal.assets}
            className="text-input text-input-total"
            currency={currency}
          />
        </div>
        <div className="row-holder">
          <ReadOnlyCurrency
            value={grossTotal.liabilities * -1}
            className="text-input text-input-total"
            additionalClass="c-red"
            currency={currency}
          />
        </div>
        <div className="row-holder">
          <ReadOnlyCurrency
            value={grossTotal.assets - grossTotal.liabilities}
            className="text-input text-input-total"
            currency={currency}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default NetWorthListingSummary

function groupDataByClassification(classifications, flattenData) {
  const items = []
  classifications.forEach((classification) => {
    if (classification) {
      const item = {
        assets: 0,
        liabilities: 0
      }
      flattenData
        .filter((d) => d.classification === classification)
        .forEach((d) => {
          item.assets += d.assets
          item.liabilities += d.liabilities
        })
      items.push({
        ...item,
        classification
      })
    }
  })
  return items
}

function flat(data) {
  let flattenData = []
  if (data) {
    Object.keys(data).forEach((key) => {
      if (data[key]) {
        if (Array.isArray(data[key])) {
          flattenData = flattenData.concat(data[key])
        } else {
          flattenData.push(data[key])
        }
      }
    })
  }
  return flattenData
}

function countGrossTotal(items) {
  return items.reduce(
    (sum, current) => {
      sum.assets += current.assets
      sum.liabilities += current.liabilities
      return sum
    },
    {
      assets: 0,
      liabilities: 0
    }
  )
}
