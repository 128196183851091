import React from "react"
import intl from "react-intl-universal"
import RenderSimpleFields, {
  FieldType
} from "../components/Form/RenderSimpleFields"
import ClassificationSelection from "../components/_hook/ClassificationSelection"
import { CountryList } from "../constants/Lists"
import { translateListLabel } from "../helpers/list-translation"
import { getSymbolFromCode } from "../helpers"

const PersonalContentMainForm = ({
  type,
  client,
  partner,
  isJoint,
  readOnly = false,
  defaultCurrency,
  values
}) => {
  let fields = [
    {
      name: "itemDescription",
      label: intl.get("field.itemDescription"),
      type: FieldType.TEXT,
      required: true
    },
    {
      name: "itemLocation",
      label: intl.get("field.locationOfItem"),
      type: FieldType.SELECTION,
      required: false,
      valueProperty: "code",
      labelProperty: "name",
      selectionData: translateListLabel(CountryList)
    },
    {
      name: "currentValue",
      label: intl.get("field.currentValue"),
      type: FieldType.MONEY_WITH_CURRENCY,
      required: true,
      defaultCurrency,
      symbolPrefix: getSymbolFromCode(defaultCurrency),
      allowNegative: false
    },
    {
      name: "valuationDate",
      label: intl.get("field.valuationDate"),
      type: FieldType.DATE,
      required: true
    },
    {
      name: "annualDepreciationRate",
      label: intl.get("field.annualDepreciationRate"),
      type: FieldType.PERCENTAGE,
      required: false,
      defaultValue: 3
    },
    {
      name: "residualValue",
      label: intl.get("field.residualValue"),
      type: FieldType.MONEY_WITH_CURRENCY,
      defaultCurrency,
      symbolPrefix: getSymbolFromCode(defaultCurrency),
      allowNegative: false
    },
    {
      label: intl.get("field.underLoan"),
      name: "underLoan",
      checkboxLabel: intl.get("YesNoEnum_YES"),
      type: FieldType.CHECKBOX
    },
    {
      label: intl.get("field.personalItemInsured"),
      name: "personalItemInsured",
      checkboxLabel: intl.get("YesNoEnum_YES"),
      type: FieldType.CHECKBOX
    }
  ]
  if (readOnly) {
    fields = fields
      .filter(
        (key) => key.name !== "underLoan" //20200915 disable 'Is the personal item under a loan?'
      )
      .map((field) => ({ ...field, readOnly: true }))
  }
  return (
    <React.Fragment>
      <div className="subtitle2">{intl.get("personalContents.subtitle")}</div>

      <ClassificationSelection
        client={client}
        partner={partner}
        isJoint={isJoint}
        hideJoint={!isJoint || readOnly}
      />

      <RenderSimpleFields fields={fields} />
    </React.Fragment>
  )
}
export default PersonalContentMainForm
