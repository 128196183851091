import { isNullOrUndefined } from 'util';
import { getDirectVariableValue } from './GetDirectValue';
import { countFromArray } from './CountFromArray';
import { totalSum } from './TotalSum';

export const giftAndDonation = (values, currency) => {
    if (!isNullOrUndefined(values)) {
        const { charity, gift, others } = values;

        const model = {
            charity: getDirectVariableValue(charity, currency),
            gift: getDirectVariableValue(gift, currency),
            others: countFromArray(others, currency),
        };

        return {
            clientTotal: totalSum(model, 'clientTotal'),
            partnerTotal: totalSum(model, 'partnerTotal'),
            jointTotal: totalSum(model, 'jointTotal'),
        };
    }
};
