/*
    Author : Hendri Firmana (hendri.firmana@devstack.com.au)
             Anova Fawzi (anovafawzi@gmail.com) - rework for cashflow demo
    Description : Personal income component
*/

import React, { useContext } from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from "react-accessible-accordion"
import intl from "react-intl-universal"
import CashFlowListing from "../CashFlowListing"
import CashFlowHeaderListing from "../CashFlowHeaderListing"
import { AdditionalArrayInput } from "./FieldArrayList"
import {
  InvestmentPropertyRentalIncome,
  BusinessIncome,
  CashAccount,
  UnitTrust,
  Bond,
  TermDeposit,
  Share
} from "./ExpensesList"
import { AppContext } from "../../../context/AppContext"
import SalaryDetailsForm from "../../NetWorth/SubForm/SalaryDetailsForm"
import Api from "../../../lib/api"
import Ilas from "./ExpensesList/Ilas"
import { netWorthActions } from "../../../actions"
import { useDispatch } from "react-redux"

const PersonalIncome = ({
  varPrefix,
  optionsData,
  push,
  incomeTotalValues: {
    employment,
    total,
    invesmentPropertyRentalIncome,
    businessOwnership,
    cashAccount,
    termDeposit,
    share,
    unitTrust,
    bond
  },
  currency,
  onExternalUpdated,
  clientInformation,
  values
}) => {
  const dispatch = useDispatch()
  const readOnlyAccessComponent = () => null
  const client = clientInformation
  const partner = clientInformation.partner || {}
  const isJoint = clientInformation.partner || false

  const {
    modal: { setModal, clearModal }
  } = useContext(AppContext)

  return (
    <React.Fragment>
      <Accordion>
        <AccordionItem>
          <AccordionItemTitle>
            <div className="subtitle2b">
              {intl.get("cashflowheader.top.personalIncome")}
              <div className="accordion__arrow" />
            </div>
          </AccordionItemTitle>
          <AccordionItemBody>
            <div className="portal-part">
              <Accordion accordion={false}>
                <AccordionItem>
                  <AccordionItemTitle>
                    <CashFlowHeaderListing
                      title="{intl.get(
                                                'cashflowheader.employmentIncome'
                                            )}"
                      isAccordion
                      varPrefix={`${varPrefix}`}
                      clientTotalValue={employment.clientTotal}
                      partnerTotalValue={employment.partnerTotal}
                      jointTotalValue={employment.jointTotal}
                      currency={currency}
                      isJointHide={!isJoint}
                    />
                  </AccordionItemTitle>
                  <AccordionItemBody>
                    <CashFlowListing
                      varPrefix={`${varPrefix}.employment`}
                      title={intl.get(
                        "cashflowlisting.employmentIncome.salary"
                      )}
                      isJointHide={!isJoint}
                      isOnlySectionTitle
                      isRoundColorRed
                      isShowAddButton
                      push={push}
                      additionName={`${varPrefix}.employment.salaries`}
                      defaultDeductibleValue={100}
                      onAddClick={() =>
                        setModal({
                          title: intl.get("salaryDetails.title"),
                          content: (
                            <SalaryDetailsForm
                              model={{}}
                              onSave={async (model) => {
                                const response = await Api.put(
                                  `/client/${client.id}/salary`,
                                  model
                                )
                                if (response.status === 200) {
                                  onExternalUpdated(true)
                                  dispatch(
                                    netWorthActions.getSalariesList({id:client.id})
                                  )
                                }
                                clearModal()
                              }}
                              optionsData={optionsData}
                              client={client}
                              partner={partner}
                              isJoint={isJoint}
                            />
                          )
                        })
                      }
                    />
                    <AdditionalArrayInput
                      arrayName={`${varPrefix}.employment.salaries`}
                      isRoundColorRed
                      isJointHide={!isJoint}
                      isShowItemDescription
                      isShowEditButton
                      dataArray={
                        values &&
                        values.personalIncome &&
                        values.personalIncome.employment &&
                        values.personalIncome.employment.salaries
                          ? values.personalIncome.employment.salaries
                          : []
                      }
                      onDeleteClick={() => {
                        onExternalUpdated(true)
                        clearModal()
                      }}
                      onEditClick={(dataModel) =>
                        setModal({
                          title: intl.get("salaryDetails.title"),
                          content: (
                            <SalaryDetailsForm
                              model={dataModel}
                              onSave={async (model) => {
                                const response = await Api.put(
                                  `/client/${client.id}/salary`,
                                  model
                                )
                                if (response.status === 200) {
                                  onExternalUpdated(true)
                                  dispatch(
                                    netWorthActions.getSalariesList({
                                       id: client.id 
                                    })
                                  )
                                }
                                clearModal()
                              }}
                              optionsData={optionsData}
                              client={client}
                              partner={partner}
                              isJoint={isJoint}
                            />
                          )
                        })
                      }
                    />

                    <CashFlowListing
                      varPrefix={`${varPrefix}.employment`}
                      title={intl.get(
                        "cashflowlisting.employmentIncome.workAllowance"
                      )}
                      isOnlySectionTitle
                      isRoundColorRed
                      isShowAddButton
                      push={push}
                      additionName={`${varPrefix}.employment.workAllowances`}
                      isJointHide={!isJoint}
                      defaultDeductibleValue={100}
                    />
                    <AdditionalArrayInput
                      isRoundColorRed
                      arrayName={`${varPrefix}.employment.workAllowances`}
                      isJointHide={!isJoint}
                    />

                    <CashFlowListing
                      varPrefix={`${varPrefix}.employment`}
                      title={intl.get("cashflowlisting.others")}
                      isAdditionalInput
                      isRoundColorRed
                      isShowAddButton
                      push={push}
                      additionName={`${varPrefix}.employment.others`}
                      isJointHide={!isJoint}
                      defaultDeductibleValue={100}
                    />
                    <AdditionalArrayInput
                      isRoundColorRed
                      placeholderLabel={intl.get(
                        "cashflowlisting.pleaseSpecify"
                      )}
                      AdditionalInput
                      arrayName={`${varPrefix}.employment.others`}
                      isJointHide={!isJoint}
                    />
                  </AccordionItemBody>
                </AccordionItem>
              </Accordion>
            </div>

            <InvestmentPropertyRentalIncome
              varPrefix={varPrefix}
              push={push}
              clientTotalValue={invesmentPropertyRentalIncome.clientTotal}
              partnerTotalValue={invesmentPropertyRentalIncome.partnerTotal}
              jointTotalValue={invesmentPropertyRentalIncome.jointTotal}
              optionsData={optionsData}
              clientInformation={{
                client,
                partner,
                isJoint
              }}
              readOnlyAccessComponent={readOnlyAccessComponent}
              currency={currency}
              onExternalUpdated={(result) => onExternalUpdated(result)}
              isJointHide={!isJoint}
              values={values}
            />

            <BusinessIncome
              varPrefix={varPrefix}
              push={push}
              clientTotalValue={businessOwnership.clientTotal}
              partnerTotalValue={businessOwnership.partnerTotal}
              jointTotalValue={businessOwnership.jointTotal}
              optionsData={optionsData}
              clientInformation={{
                client,
                partner,
                isJoint
              }}
              readOnlyAccessComponent={readOnlyAccessComponent}
              currency={currency}
              onExternalUpdated={(result) => onExternalUpdated(result)}
              isJointHide={!isJoint}
              values={values}
            />

            <CashAccount
              varPrefix={varPrefix}
              push={push}
              clientTotalValue={cashAccount.clientTotal}
              partnerTotalValue={cashAccount.partnerTotal}
              jointTotalValue={cashAccount.jointTotal}
              optionsData={optionsData}
              clientInformation={{
                client,
                partner,
                isJoint
              }}
              readOnlyAccessComponent={readOnlyAccessComponent}
              currency={currency}
              onExternalUpdated={(result) => onExternalUpdated(result)}
              isJointHide={!isJoint}
              values={values}
            />

            <TermDeposit
              varPrefix={varPrefix}
              push={push}
              clientTotalValue={termDeposit.clientTotal}
              partnerTotalValue={termDeposit.partnerTotal}
              jointTotalValue={termDeposit.jointTotal}
              optionsData={optionsData}
              clientInformation={{
                client,
                partner,
                isJoint
              }}
              readOnlyAccessComponent={readOnlyAccessComponent}
              currency={currency}
              onExternalUpdated={(result) => onExternalUpdated(result)}
              isJointHide={!isJoint}
              values={values}
            />

            <Share
              varPrefix={varPrefix}
              push={push}
              clientTotalValue={share.clientTotal}
              partnerTotalValue={share.partnerTotal}
              jointTotalValue={share.jointTotal}
              optionsData={optionsData}
              clientInformation={{
                client,
                partner,
                isJoint
              }}
              readOnlyAccessComponent={readOnlyAccessComponent}
              currency={currency}
              isJoint={isJoint}
              onExternalUpdated={(result) => onExternalUpdated(result)}
              values={values}
            />
            <UnitTrust
              varPrefix={varPrefix}
              push={push}
              clientTotalValue={unitTrust.clientTotal}
              partnerTotalValue={unitTrust.partnerTotal}
              jointTotalValue={unitTrust.jointTotal}
              optionsData={optionsData}
              clientInformation={{
                client,
                partner,
                isJoint
              }}
              readOnlyAccessComponent={readOnlyAccessComponent}
              currency={currency}
              isJoint={isJoint}
              onExternalUpdated={(result) => onExternalUpdated(result)}
              values={values}
            />

            <Bond
              varPrefix={varPrefix}
              push={push}
              clientTotalValue={bond.clientTotal}
              partnerTotalValue={bond.partnerTotal}
              jointTotalValue={bond.jointTotal}
              optionsData={optionsData}
              clientInformation={{
                client,
                partner,
                isJoint
              }}
              readOnlyAccessComponent={readOnlyAccessComponent}
              currency={currency}
              isJoint={isJoint}
              onExternalUpdated={(result) => onExternalUpdated(result)}
              values={values}
            />

            <Ilas
              varPrefix={varPrefix}
              push={push}
              clientTotalValue={unitTrust.clientTotal}
              partnerTotalValue={unitTrust.partnerTotal}
              jointTotalValue={unitTrust.jointTotal}
              optionsData={optionsData}
              clientInformation={{
                client,
                partner,
                isJoint
              }}
              readOnlyAccessComponent={readOnlyAccessComponent}
              currency={currency}
              isJoint={isJoint}
              onExternalUpdated={(result) => onExternalUpdated(result)}
              values={values}
            />

            <div className="portal-part">
              <CashFlowHeaderListing
                title="Total Income"
                clientTotalValue={total.clientTotal}
                partnerTotalValue={total.partnerTotal}
                jointTotalValue={total.jointTotal}
                currency={currency}
                isJointHide={!isJoint}
              />
            </div>
          </AccordionItemBody>
        </AccordionItem>
      </Accordion>
    </React.Fragment>
  )
}

export default PersonalIncome
