import { useEffect, useState } from "react"

export const nullifyFields = (values, fieldsName) => {
  fieldsName.forEach((item) => {
    values[item] = undefined
  })
}

export const useDebounce = (value, delay) => {
  const [debounceValue, setDebounceValue] = useState(value)
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])
  return debounceValue
}
