import { useReducer } from "react";


const burgerDefault = {
    isMenuClosed: false,
    firstInit: false
};

export const useBurgerActions = () => {
    const [state, dispatch] = useReducer(burgerReducer, burgerDefault);

    const toggleBurgerMenu = () => {
        document.body.classList.toggle("collape-nav");
        dispatch({ type: "TOGGLE_BURGER_MENU" });
    };

    
    const defaultToggle = value => {
        dispatch({ type: "DEFAULT_TOGGLE", value });
    };
    return [
        {
            state,
            toggleBurgerMenu,
          
            defaultToggle
        }
    ];
};

const burgerReducer = (state, action) => {
    switch (action.type) {
        case "TOGGLE_BURGER_MENU":
            return {
                ...state,
                isMenuClosed: !state.isMenuClosed
            };
       
        case "DEFAULT_TOGGLE":
            return {
                ...state
            };
        default:
            return state;
    }
};
