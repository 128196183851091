import api from "../../lib/api"

// change base route for different entity/domain here
const BASE_ROUTE = "/current_standing/"

class CurrentStandingApi {
  // retrieve data
  static get(id) {
    return api.get(`${BASE_ROUTE}get`, id)
  }

  static add(model) {
    return api.post(`${BASE_ROUTE}add`, model)
  }

  static edit(model) {
    return api.put(`${BASE_ROUTE}edit`, model)
  }

  static delete(model) {
    return api.delete(`${BASE_ROUTE}add`, model)
  }

  static getFinancialRatioSettings() {
    return api.get(`${BASE_ROUTE}financial-ratio-settings`)
  }

  static saveFinancialRatioSettings(model) {
    return api.post(`${BASE_ROUTE}financial-ratio-settings`, model);
  }

  // get lifeline data (for chart etc)
  static getLifeline(id, retirementAge, reportingCurrency, retirementAge1) {
    if (
      (retirementAge1 !== 0 && retirementAge1 !== undefined) ||
      retirementAge !== 0
    ) {
      return api.post(`/lifestyle/lifeline-without-scenario`, {
        clientId: id,
        reportingCurrency,
        client: {
          retirementAge
        },
        partner: {
          retirementAge: retirementAge1
        }
      })
    }
    return api.get(
      // `${BASE_ROUTE}life_line/${id}/${classification}?reportingCurrency=${reportingCurrency}`
      `${BASE_ROUTE}life_line/${id}?reportingCurrency=${reportingCurrency}`
    )
  }
  // get lifeline for scenario
  static getLifelineForScenario(id, currency, scenarioId) {
    // if (retirementAge !== 0) {
    return api.post(`/lifestyle/lifeline`, {
      clientId: id,
      reportingCurrency: currency,
      scenarioId: scenarioId
    })
  }
  // return api.get(
  //     // `${BASE_ROUTE}life_line/${id}/${classification}?reportingCurrency=${reportingCurrency}`
  //     `${BASE_ROUTE}life_line/${id}?reportingCurrency=${reportingCurrency}`
  // );

  // get lifeline data (for chart etc)
  static getFinancialRatio(id, reportingCurrency) {
    return api.get(
      `${BASE_ROUTE}${id}/financial_ratio?reportingCurrency=${reportingCurrency}`
    )
  }

  // get investment data (for chart etc)
  static getInvestment(id, startYear, endYear, currency, classification) {
    return api.get(
      `${BASE_ROUTE}${id}/investment?endYear=${endYear}&reportingCurrency=${currency}&startYear=${startYear}`
    )
  }

  static retirement(clientId, currency, classification) {
    return api.get(
      `/current_standing/${clientId}/retirement?reportingCurrency=${currency}`
    )
  }

  static education(clientId, currency, classification) {
    return api.get(
      `/current_standing/${clientId}/education?reportingCurrency=${currency}`
    )
  }

  static finance(clientId, currency, classification) {
    return api.get(
      `/current_standing/${clientId}/finance-bundle?reportingCurrency=${currency}`
    )
  }
}

export default CurrentStandingApi
