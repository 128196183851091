import React, { useContext } from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from "react-accessible-accordion"

import { Field, FormSpy } from "react-final-form"
import { FieldArray } from "react-final-form-arrays"
import intl from "react-intl-universal"
import {
  renderSelectFieldCustom,
  renderSelectEnumFieldCustom,
  required,
  renderTextBoxCustom,
  renderEmailFieldCustom,
  renderPhoneNumberFieldCustom,
  composeValidators,
  malabarPhone,
  malabarEmail,
  renderCheckboxCustom
} from "../../../helpers"
import { CountryList } from "../../../constants/Lists"
import { optionsType, getOptions } from "../../../helpers/OptionsHelper"
import PersonalDetailsContext from "../PersonalDetailsContext"
import { translateListLabel } from "../../../helpers/list-translation"
import PairField from "./PairField"
import { EmailTypeEnum, PhoneTypeEnum } from "../../../constants/Enums"

export const ContactDetailMutators = {
  setPostalAddress: (args, state, utils) => {
    utils.changeValue(state, "client.personalDetails.postalAddress", () => ({}))
  },
  unsetPostalAddress: (args, state, utils) => {
    utils.changeValue(
      state,
      "client.personalDetails.postalAddress",
      () => undefined
    )
    utils.changeValue(
      state,
      "partner.personalDetails.postalAddress",
      () => undefined
    )
  },

  setPostalAddressSameWithPrimaryAddress: (args, state, utils) => {
    const { personalDetails } = state.formState.values.client
    if (personalDetails && personalDetails.primaryAddress) {
      utils.changeValue(state, "client.personalDetails.postalAddress", () => ({
        ...personalDetails.primaryAddress
      }))
    }
  }
}

function AddressForm({
  name,
  forPrimaryAddress,
  forPostalAddress,
  forOtherAddress
}) {
  const { client, partner } = useContext(PersonalDetailsContext)

  name = name.replace("client.", "")

  let form
  let newcontact_form

  const { classification } = useContext(PersonalDetailsContext)
  const optionsData = getOptions([optionsType.residentialTenureOptions])

  const requiredAddress1 = [
    {
      name: `${name}.addressLine1`,
      label: intl.get("address.line1"),
      component: renderTextBoxCustom,
      isRequired: true
      // isFullWidth: true,
    }
  ]
  const notRequiredAddress1 = [
    {
      name: `${name}.addressLine1`,
      label: intl.get("address.line1"),
      component: renderTextBoxCustom
      // isRequired: true
      // isFullWidth: true,
    }
  ]
  //copy address to partner

  //duplicate address
  form = [
    {
      name: `${name}.addressLine2`,
      label: intl.get("address.line2"),
      component: renderTextBoxCustom
      // isFullWidth: true,
    },
    {
      name: `${name}.addressLine3`,
      label: intl.get("address.line3"),
      component: renderTextBoxCustom
      // isFullWidth: true,
    },
    {
      name: `${name}.suburb`,
      label: intl.get("address.suburbCity"),
      component: renderTextBoxCustom,
      isRequired: true
    },
    {
      name: `${name}.state`,
      label: intl.get("address.state"),
      component: renderTextBoxCustom,
      isRequired: true
    },
    {
      name: `${name}.country`,
      component: renderSelectFieldCustom,
      label: intl.get("address.country"),
      data: translateListLabel(CountryList),
      valueProperty: "code",
      labelProperty: "name",
      isRequired: true
    },
    {
      name: `${name}.postalCode`,
      label: intl.get("address.postcode"),
      component: renderTextBoxCustom
    },
    {
      name: `${name}.residentialTenure`,
      component: renderSelectEnumFieldCustom,
      label: intl.get("address.residentialTenure"),
      enumData: optionsData.residentialTenureOptions
    }
  ]

  //duplicate address
  newcontact_form = [
    {
      name: `${name}.addressLine2`,
      label: intl.get("address.line2"),
      component: renderTextBoxCustom
      // isFullWidth: true,
    },
    {
      name: `${name}.addressLine3`,
      label: intl.get("address.line3"),
      component: renderTextBoxCustom
      // isFullWidth: true,
    },
    {
      name: `${name}.suburb`,
      label: intl.get("address.suburbCity"),
      component: renderTextBoxCustom
    },
    {
      name: `${name}.state`,
      label: intl.get("address.state"),
      component: renderTextBoxCustom
    },
    {
      name: `${name}.country`,
      component: renderSelectFieldCustom,
      label: intl.get("address.country"),
      data: translateListLabel(CountryList),
      valueProperty: "code",
      labelProperty: "name"
    },
    {
      name: `${name}.postalCode`,
      label: intl.get("address.postcode"),
      component: renderTextBoxCustom
    },
    {
      name: `${name}.residentialTenure`,
      component: renderSelectEnumFieldCustom,
      label: intl.get("address.residentialTenure"),
      enumData: optionsData.residentialTenureOptions
    }
  ]

  return (
    <React.Fragment>
      <FormSpy subscription={{ values: true }}>
        {({ values }) => (
          <div>
            {client && client.classification == "JOINT" && (
              <div className="row copy-row">
                <div className="col">
                  <div className="input-check my-2 copyaddress-row">
                    {forPrimaryAddress ? (
                      <Field
                        component={renderCheckboxCustom}
                        name="partner.sameAddressAsClient"
                        label={intl.get("address.sameAsClient")}
                      />
                    ) : forPostalAddress ? (
                      <Field
                        component={renderCheckboxCustom}
                        name="partner.samePostalAddressAsClient"
                        label={intl.get("address.sameAsClient")}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* Client category validation here */}

            {client.status === "CLIENT" ||
            client.status === "PROSPECT" ||
            !client.status
              ? requiredAddress1.map((field) => (
                  <PairField
                    key={field.name}
                    field={field}
                    classification={classification}
                  />
                ))
              : notRequiredAddress1.map((field) => (
                  <PairField
                    key={field.name}
                    field={field}
                    classification={classification}
                  />
                ))}

            {client &&
              client.status !== "NEW_CONTACT" &&
              form.map((field) => (
                <PairField
                  key={field.name}
                  field={field}
                  classification={classification}
                />
              ))}

            {client &&
              client.status == "NEW_CONTACT" &&
              newcontact_form.map((field) => (
                <PairField
                  key={field.name}
                  field={field}
                  classification={classification}
                />
              ))}
          </div>
        )}
      </FormSpy>
    </React.Fragment>
  )
}
function PrimaryAddress(client, partner) {
  const { fieldName } = useContext(PersonalDetailsContext)

  return (
    <React.Fragment>
      <div className="subtitle3">
        <h3>{intl.get("address.primaryResidentialAddress")}</h3>
      </div>
      <AddressForm
        name={`${fieldName}.primaryAddress`}
        client={client}
        partner={partner}
        forPrimaryAddress={true}
      />
    </React.Fragment>
  )
}
function PostalAddress() {
  const {
    fieldName,
    mutators: {
      setPostalAddress,
      unsetPostalAddress,
      setPostalAddressSameWithPrimaryAddress
    },
    client,
    partner
  } = useContext(PersonalDetailsContext)
  const { postalAddress } = client.personalDetails || {}
  return (
    <React.Fragment>
      <span
        onClick={postalAddress ? unsetPostalAddress : setPostalAddress}
        className={postalAddress ? "icon-holder selected" : "icon-holder"}
      >
        <div className="icon ico-postaladd" />
        <div className="text">{intl.get("address.postalAddress")}</div>
      </span>
      {postalAddress && (
        <React.Fragment>
          <span
            style={{
              textAlign: "center",
              padding: "8px 15px",
              margin: "0 20px 0 0",
              color: "#ffffff",
              borderRadius: "12px",
              background: "rgba(0,174,243,1)",
              borderBottom: "3px solid #e6e6e6",
              cursor: "pointer"
            }}
            onClick={setPostalAddressSameWithPrimaryAddress}
          >
            Set Postal Address Same With primary Address
          </span>
          <AddressForm
            name={`${fieldName}.postalAddress`}
            // client={client}
            // partner={partner}
            forPostalAddress={true}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
function OtherAddress() {
  const {
    fieldName,
    mutators: { arrayPush, arrayRemove },
    client,
    partner
  } = useContext(PersonalDetailsContext)

  return (
    <React.Fragment>
      <FieldArray name={`${fieldName}.otherAddresses`}>
        {({ fields }) => (
          <React.Fragment>
            <span
              onClick={() => {
                arrayPush(`${fieldName}.otherAddresses`, {})
              }}
              className="icon-holder"
            >
              <div className="icon ico-otheradd" />
              <div className="text">{intl.get("address.otherAddress")}</div>
            </span>
            {fields.map((name, index) => (
              <React.Fragment key={name}>
                <div className="portal-form-row kyc-otheradd">
                  <div>
                    <b>Other Address - {index + 1}</b>
                  </div>
                  <div className="col-md-1">
                    <button
                      type="button"
                      className="btn-remove"
                      onClick={() => {
                        arrayRemove(`${fieldName}.otherAddresses`, index)
                        arrayRemove(
                          `partner.personalDetails.otherAddresses`,
                          index
                        )
                      }}
                    ></button>
                  </div>
                </div>
                <AddressForm name={name} forOtherAddress={true} s />
              </React.Fragment>
            ))}
          </React.Fragment>
        )}
      </FieldArray>
    </React.Fragment>
  )
}
function PhoneNumberForm({ isJoint }) {
  const {
    fieldName,
    mutators: { arrayPush, arrayRemove }
  } = useContext(PersonalDetailsContext)

  return (
    <React.Fragment>
      <FieldArray name={`${fieldName}.otherPhones`}>
        {({ fields }) => (
          <React.Fragment>
            <div className="ContactDetails">
              <div className="subtitle3">
                <label>
                  <h3>{intl.get("personaldetail.phone")}</h3>
                </label>
                <div className="btn-holder">
                  <span
                    onClick={() =>
                      arrayPush(`${fieldName}.otherPhones`, {
                        phoneType: PhoneTypeEnum.PhoneType_OTHER
                      })
                    }
                    className="btn btn-plus"
                  />
                </div>
              </div>

              <div>
                <div className="portal-grid ">
                  {/* client prefer phone */}
                  <div className="portal-form-colsm">
                    {intl.get("field.prefPhoneNumber")}
                    <span className="mandatory-star">*</span>
                  </div>
                  <div
                    className={`portal-form-colsm portal-form-colsm-partner-${isJoint} contactDetail-preferredPhone-label`}
                  >
                    <Field
                      name={`${fieldName}.preferredPhone`}
                      label={""}
                      required
                      preferred
                      component={renderPhoneNumberFieldCustom}
                      validate={composeValidators(required, malabarPhone)}
                    />
                  </div>

                  {/* partner prefer phone */}
                  {isJoint && (
                    <div className="portal-form-colsm contactDetail-preferredPhone-label">
                      <Field
                        name={`${fieldName.replace(
                          "client",
                          "partner"
                        )}.preferredPhone`}
                        label={""}
                        required
                        preferred
                        component={renderPhoneNumberFieldCustom}
                        validate={composeValidators(required, malabarPhone)}
                      />
                    </div>
                  )}
                </div>
              </div>

              {fields.map((name, index) => (
                <div key={name}>
                  <div className="portal-grid mb-2">
                    {/* client other phone */}
                    <div className="portal-form-colsm">
                      {intl.get("field.otherPhoneNumber")}
                    </div>
                    <div
                      className={`portal-form-colsm portal-form-colsm-partner-${isJoint} contactDetail-preferredPhone-label`}
                    >
                      <Field
                        name={name}
                        label={""}
                        required
                        preferred={false}
                        component={renderPhoneNumberFieldCustom}
                        validate={composeValidators(required, malabarPhone)}
                        onRemoveDataClick={() => {
                          arrayRemove(`${fieldName}.otherPhones`, index)
                          arrayRemove(
                            `partner.personalDetails.otherPhones`,
                            index
                          )
                        }}
                      />
                    </div>

                    {/* partner other phone */}
                    {isJoint && (
                      <div className="portal-form-colsm contactDetail-preferredPhone-label">
                        <Field
                          name={name.replace("client", "partner")}
                          label={""}
                          required
                          preferred={false}
                          component={renderPhoneNumberFieldCustom}
                          validate={composeValidators(required, malabarPhone)}
                          onRemoveDataClick={() => {
                            arrayRemove(`${fieldName}.otherPhones`, index)
                            arrayRemove(
                              `partner.personalDetails.otherPhones`,
                              index
                            )
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </React.Fragment>
        )}
      </FieldArray>
    </React.Fragment>
  )
}
function EmailForm({ isJoint }) {
  const { classification } = useContext(PersonalDetailsContext)

  const {
    fieldName,
    mutators: { arrayPush, arrayRemove }
  } = useContext(PersonalDetailsContext)

  return (
    <React.Fragment>
      <FieldArray name={`${fieldName}.otherEmails`}>
        {({ fields }) => (
          <React.Fragment>
            <div className="EmailForm">
              <div className="subtitle3">
                <label>
                  <h3>{intl.get("personaldetail.email")}</h3>
                </label>
                <div className="btn-holder">
                  <span
                    onClick={() =>
                      arrayPush(`${fieldName}.otherEmails`, {
                        emailType: EmailTypeEnum.EmailType_OTHER
                      })
                    }
                    className="btn btn-plus"
                  />
                </div>
              </div>
              <div>
                <div className="portal-grid">
                  {/* client prefer email */}
                  <div className="portal-form-colsm">
                    {intl.get("field.prefEmail")}
                    <span className="mandatory-star">*</span>
                  </div>
                  <div
                    className={`portal-form-colsm portal-form-colsm-partner-${isJoint} contactDetail-preferredEmail-label`}
                  >
                    <Field
                      name={`${fieldName}.preferredEmail`}
                      label={""}
                      required
                      preferred
                      placeholder={intl.get("field.prefEmail")}
                      component={renderEmailFieldCustom}
                      validate={composeValidators(required, malabarEmail)}
                    />
                  </div>

                  {/* partner prefer email */}
                  {isJoint && (
                    <div className="portal-form-colsm contactDetail-preferredEmail-label">
                      <Field
                        name={`partner.personalDetails.preferredEmail`}
                        label={""}
                        required
                        preferred
                        placeholder={intl.get("field.prefEmail")}
                        component={renderEmailFieldCustom}
                        validate={composeValidators(required, malabarEmail)}
                      />
                    </div>
                  )}
                </div>
              </div>

              {fields.map((name, index) => (
                <div key={name}>
                  <div className="portal-grid">
                    {/* client other email */}
                    <div className="portal-form-colsm">
                      {intl.get("field.otherEmail")}
                    </div>
                    <div
                      className={`portal-form-colsm portal-form-colsm-partner-${isJoint} contactDetail-preferredEmail-label`}
                    >
                      <Field
                        name={name}
                        label={""}
                        required
                        preferred={false}
                        component={renderEmailFieldCustom}
                        validate={composeValidators(required, malabarEmail)}
                        onRemoveDataClick={() => {
                          arrayRemove(`${fieldName}.otherEmails`, index)
                          arrayRemove(
                            `partner.personalDetails.otherEmails`,
                            index
                          )
                        }}
                      />
                    </div>

                    {/* partner other email */}
                    {isJoint && (
                      <div className="portal-form-colsm contactDetail-preferredEmail-label">
                        <Field
                          name={name.replace("client", "partner")}
                          // name={`partner.personalDetails.otherEmails`}
                          label={""}
                          required
                          preferred={false}
                          component={renderEmailFieldCustom}
                          validate={composeValidators(required, malabarEmail)}
                          onRemoveDataClick={() => {
                            arrayRemove(`${fieldName}.otherEmails`, index)
                            arrayRemove(
                              `partner.personalDetails.otherEmails`,
                              index
                            )
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </React.Fragment>
        )}
      </FieldArray>
    </React.Fragment>
  )
}

function ContactDetail({ isJoint, client, partner }) {
  return (
    <Accordion>
      <AccordionItem expanded>
        <div
          className={`portal-part contactDetail-portal-part ${
            client.status == "ARCHIVE" ? "personal-details-disabled" : ""
          }`}
        >
          <AccordionItemTitle>
            <div className="subtitle2">
              {intl.get("personaldetail.contactDetails")}
              <span className="accordion__arrow" role="presentation" />
            </div>
          </AccordionItemTitle>
          <AccordionItemBody>
            <div className="content-panel-header">
              <div className="title-holder">
                <h3>{intl.get("personaldetail.addressDetails")}</h3>
              </div>
            </div>
            <PrimaryAddress client={client} partner={partner} />
            <PhoneNumberForm isJoint={isJoint} />
            <EmailForm isJoint={isJoint} />
            <hr />
            <PostalAddress client={client} partner={partner} />
            <hr />
            <OtherAddress />
          </AccordionItemBody>
        </div>
      </AccordionItem>
    </Accordion>
  )
}

export default ContactDetail
