import React from 'react';

export const FilterDateOptions = (filter, onChange) => (
    <select
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'all'}
    >
        <option value="oldest">Oldest on Top</option>
        <option value="latest">Latest on Top</option>
        <option value="week">This Week</option>
        <option value="month">Last 30 Days</option>
        <option value="two-months">Last 60 Days</option>
        <option value="three-months">Last 90 Days</option>
    </select>
);

export const FilterAToZOptions = (filter, onChange) => (
    <select
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'all'}
    >
        <option value="asc">A-Z</option>
        <option value="desc">Z-A</option>
    </select>
);

export const FilterStatusOptions = (filter, onChange) => (
    <select
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'all'}
    >
        <option value="all">Show All</option>
        <option value="Open">Open</option>
        <option value="Investigation">Investigation</option>
        <option value="Resolved">Resolved</option>
        <option value="Closed">Closed</option>
    </select>
);

export const FilterSeverityOptions = (filter, onChange) => (
    <select
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'all'}
    >
        <option value="all">Show All</option>
        <option value="High">High</option>
        <option value="Moderate">Moderate</option>
        <option value="Low">Low</option>
    </select>
);

export const FilterByListObjectOptions = (filter, onChange, listObject) => (
    <select
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'all'}
    >
        <option value="all">Show All</option>
        {listObject.map((item, index) => (
            <option key={`${index}filterDD`} value={item.value}>
                {item.name}
            </option>
        ))}
    </select>
);

export const sortAndFilterDate = (param, rows, filter) => {
    rows.forEach(x => {
        x.originDate = new Date(x[param]);
    });
    const sortedRows = sortList(rows, 'originDate');
    const initDate = new Date();
    if (filter.value === 'week') {
        initDate.setDate(initDate.getDate() - 7);
        return rows.filter(x => x.originDate > initDate);
    }
    if (filter.value === 'month') {
        initDate.setDate(initDate.getDate() - 30);
        return rows.filter(x => x.originDate > initDate);
    }
    if (filter.value === 'two-months') {
        initDate.setDate(initDate.getDate() - 60);
        return rows.filter(x => x.originDate > initDate);
    }
    if (filter.value === 'three-months') {
        initDate.setDate(initDate.getDate() - 90);
        return rows.filter(x => x.originDate > initDate);
    }
    if (filter.value === 'latest') {
        return sortedRows.reverse();
    }
    if (filter.value === 'oldest') {
        return sortedRows;
    }
};

export const sortList = (list, value) =>
    list.sort((a, b) => {
        if (a[value] < b[value]) {
            return -1;
        }
        if (a[value] > b[value]) {
            return 1;
        }
        return 0;
    });
