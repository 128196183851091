/*
    Author : Marlina (marlina@devstack.com.au)
    Description : Action for feedback state
*/
import Alert from "react-s-alert"
import intl from "react-intl-universal"
// eslint-disable-next-line import/no-extraneous-dependencies
import { feedbackAction } from "../constants/ActionConstants"
import FeedbackApi from "../api/FeedbackApi"
import FileOperationApi from "../api/FileOperationApi"

export const feedbackActions = {
  addFeedback,
  updateFeedback
}

export function addFeedbackSuccess() {
  return { type: feedbackAction.ADD_FEEDBACK_SUCCESS }
}
export function updateFeedbackSuccess() {
  return { type: feedbackAction.UPDATE_FEEDBACK_SUCCESS }
}
export function getByTypeFeedbackSuccess(data) {
  return { type: feedbackAction.GET_BY_TYPE_FEEDBACK_SUCCESS, data }
}
export function getByIdFeedbackSuccess(data) {
  return { type: feedbackAction.GET_BY_ENTITY_FEEDBACK_SUCCESS, data }
}

export function addFeedback(data, file = null) {
  return async (dispatch) => {
    // api request here
    if (file !== null) {
      FileOperationApi.upload(file).then((response) => {
        if (response && response.data) {
          data.file = response.data.file
          data.fileId = response.data.id
          FeedbackApi.add(data).then((feedbackResponse) => {
            Alert.success(
              intl.get("alert.addDataSuccess", {
                name: "Feedback with File"
              })
            )
            dispatch(addFeedbackSuccess(feedbackResponse))
          })
        }
      })
    } else {
      FeedbackApi.add(data).then((feedbackResponse) => {
        Alert.success(
          intl.get("alert.addDataSuccess", {
            name: "Feedback"
          })
        )
        dispatch(addFeedbackSuccess(feedbackResponse))
      })
    }
  }
}
export function updateFeedback(id, data) {
  return async (dispatch) => {
    FeedbackApi.update(id, data).then((feedbackResponse) => {
      Alert.success(
        intl.get("alert.editDataSuccess", {
          name: "Feedback"
        })
      )

      dispatch(updateFeedbackSuccess(feedbackResponse))
    })
  }
}
