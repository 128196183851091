import { AccessLevelEnum } from '../constants/Enums';
import { history } from '.';

export const readOnlyAccess = (
    activeAccessLevel,
    componentName,
    isShowForm
) => {
    // const currentPageAccess = activeAccessLevel[componentName];
    const currentPageAccess = localStorage.getItem('activeClientAccessLevel');
    if (currentPageAccess !== null && currentPageAccess !== undefined) {
        return currentPageAccess === AccessLevelEnum.AccessLevelEnum_READ_ONLY;
    }

    // eslint-disable-next-line no-unused-expressions
    if (
        !isShowForm &&
        (componentName === 'PersonalDetails' || componentName === 'RiskProfile')
    ) {
        history.push('/page403');
    }
};

export const checkAccessMenu = (currentPath,clientId) => {
    let activeClientAccessLevel = localStorage.getItem("activeClientAccessLevel");
    if(activeClientAccessLevel == null){
        activeClientAccessLevel = AccessLevelEnum.AccessLevelEnum_READ_WRITE; //temporary fix
        //console.log('current path : ', currentPath);
         //since the personal detail always first time accessed page
         //todo : it should check based on path but we can check based on ID
        let isPersonalDetailPage = currentPath.indexOf('personalDetails')>-1;
        if(isPersonalDetailPage){
            let storedUserRole = JSON.parse(localStorage.getItem("activeUserRole"));
            if(storedUserRole){
                let roleMenus = storedUserRole.clientMenuList;
                roleMenus.forEach(g => {
                    if(g.children && g.children.length>0){
                        g.children.map((m, index)=>{
                            let menuPath = m.path.replace("${clientId}", clientId);
                            if(menuPath == currentPath){
                                //weird??? never match
                            }
                            //assuming the first accessed page is PERSONAL DETAIL PAGE
                            if(m.id == 'PERSONAL_DETAIL'){
                                activeClientAccessLevel = m.accessLevel;
                            }
                        });
                    }
                });
            }
        }
       
    }
    return activeClientAccessLevel;
};
