import React from 'react';
import NameBox from './NameBox';

const TopPeopleBox = ({ title, users }) => (
    <div className="box-half topmonth-box">
        <div className="title">
            <h1>{title}</h1>
        </div>
        <div className="flexbox">
            {users.map((user, index) => (
                <NameBox key={index} user={user} />
            ))}
        </div>
    </div>
);

export default TopPeopleBox;
