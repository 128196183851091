import React, { useState, useContext, useEffect } from "react"
import intl from 'react-intl-universal';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import { renderSelectFieldCustom, renderPercentageCustom, renderTextBoxField, renderTextBoxFieldColumn, renderTextBoxCustom, numberWithCommas, rateWithCommas } from '../../helpers';
import AssetRiskClassificationApi from "../../api/AssetRiskClassificationApi";


import Alert from "react-s-alert"
import NumberFormat from "react-number-format";
import { AppContext } from "../../context/AppContext"
import InvestmentAllocationDetails from "./InvestmentAllocationDetails";
import { uuidv4 } from "../../helpers/DateHelper";
import { DECIMAL_SCALE, PERCENT_SCALE } from "../../constants/NumberFormatConstants";

const InvestmentAllocation = ({
    currency,
    mutators,
    addInvestmentAllocation,
    removeInvestmentAllocation,
    values
    
}) => {
    const [setting, setSetting] = useState("")
    const [allocationTypes, setAllocationTypes] = useState([])
    const [productTypes, setProductTypes] = useState([])
    const {
        modal: { setModal, clearModal }
      } = useContext(AppContext)

    useEffect(() => {

        AssetRiskClassificationApi.getLoggedEntitySetting(true).then(({data})=>{
            if(data){
                setAllocationTypes(data.assetTypes);
                if(data.setting){
                    setSetting(data.setting);
                    setProductTypes(data.setting.assetProductTypes);
                }else{
                    Alert.warning("Please contact your admin or dealership manager to configure investment asset risk settings, you can't allocate any investment without it.")
                }
             
            }
        })

        
    }, [])

    function getFilteredProductTypes(index){
         let filterAssetType = values && values.investmentAllocations[index].allocationType;
         return productTypes && productTypes.filter(p=>p.assetType==filterAssetType)
    }

    function getAssetRiskType(code){
        let found ;
        if(setting && setting.riskClassificationTypes){
            for (let index = 0; index < setting.riskClassificationTypes.length; index++) {
                let r = setting.riskClassificationTypes[index];
                if(r.code == code){
                    found = r;
                    break;
                }
            }
        }
        return found;
    }

    function onProductTypeChanged(index, productCode){
        let searchRiskTypeCode;
        if(productTypes){
            for (let index = 0; index < productTypes.length; index++) {
                let p = productTypes[index];
                if(p.code == productCode){
                    searchRiskTypeCode = p.assetRiskClassificationTypeCode;
                    break;
                }
                
            }

        }

        if(searchRiskTypeCode){
            let allo = values.investmentAllocations[index];
            let riskType = getAssetRiskType(searchRiskTypeCode);
            allo.allocationPercent = riskType.allocationPercent;
            allo.annualAverageReturnPercent = riskType.expectedRoiPercent;
            updateAllocationItem(allo);

            calculateWeightReturnPercent("allocationPercent",index, allo.allocationPercent )
        }
        
    }

   

    

    function validateNewRow(){
        let result = true;
        let insurancePortion = values && values.insuranceProtectionPercentage?values.insuranceProtectionPercentage:0;
        let investmentPortion = 0;
        let investmentAllocations = values.investmentAllocations;
        let rowNo = 1;
        let errorText = "";
        if(investmentAllocations && investmentAllocations.length>0){
            
            for (let index = 0; index < investmentAllocations.length; index++) {
                const a = investmentAllocations[index];
                let rowErrors = "";
                if(!a.allocationType){
                    if(rowErrors.length==0){
                        rowErrors = "Allocation type";
                    }else{
                        rowErrors = rowErrors + ", " + "Allocation type";
                    }
                }

                if(a.allocationPercent){
                    investmentPortion = investmentPortion + a.allocationPercent;
                }else{
                    if(rowErrors.length==0){
                        rowErrors = "Allocation %";
                    }else{
                        rowErrors = rowErrors + ", " + "Allocation%";
                    }
                }

               
                if(rowErrors.length>0){
                    let msg = `Row-${rowNo}: ${rowErrors} required`;
                    if(errorText.length==0){
                        errorText = msg;
                    }else{
                        errorText = errorText + ", " + msg;
                    } 
                }
               
                rowNo++;
            }


        }
        let totalPortion = insurancePortion + investmentPortion;
        if(totalPortion >= 100){
            let msg = `Total portion insurance + investment already 100% (current total=${totalPortion})`;
            if(errorText.length==0){
                errorText = msg;
            }else{
                errorText = errorText + ", " + msg;
            } 
        }

        if(errorText && errorText.length>0){
            Alert.error(errorText);
            result = false;
        }
        return result;
    }
    
    function onAddButtonClicked(e){
        if(validateNewRow()){
            let newModel = {
                allocationId: uuidv4(),
                allocationType: '',
                allocationPercentage: 0,
                annualAverageReturnPercent:0,
                weightReturnPercent:0,
                detailInstruments:[]
            }
            addInvestmentAllocation('investmentAllocations', newModel);
        }
       
    }
    function getDetailInstrumentsDesc(arr){
        let res = "";
        if(arr){
            res = `${arr.length} items`;
        }
        return res
    }

    function getTotalAllocations(){
        let totalAllocations = 0;
        let list = values && values.investmentAllocations?values.investmentAllocations:[];
        if(list){
            for (let index = 0; index < list.length; index++) {
                let a = list[index];
                let pct =a && a.allocationPercent?a.allocationPercent:0;
                totalAllocations = totalAllocations + pct;
            }
        }
        return totalAllocations;
    }
    function updateAllocationItem(updatedData){
        let newList = [];
        let list = values && values.investmentAllocations?values.investmentAllocations:[];
        if(list){
            for (let index = 0; index < list.length; index++) {
                let a = list[index];
                if(a.allocationId == updatedData.id){
                    newList.push(updatedData);
                }else{
                    newList.push(a);
                }
                
            }
        }
        mutators.setFormAttribute("investmentAllocations", newList);
       
    }

  

    

    function calculateWeightReturnPercent(key, index, newVal){
        if(values && values.investmentAllocations){
            let totalAllocations = getTotalAllocations();
            let item = values.investmentAllocations[index];
            let allo = item.allocationPercent?parseFloat(item.allocationPercent):0;
            let ret = item.annualAverageReturnPercent?parseFloat(item.annualAverageReturnPercent):0;
            if(key=="allocationPercent"){
                allo = parseFloat(newVal);
            }else if(key=="annualAverageReturnPercent"){
                ret= parseFloat(newVal); 
            }
            
            let weightReturnPct = allo/totalAllocations * ret;
            
            if(key == "allocationPercent"){
                item.allocationPercent = newVal;
                item.weightReturnPercent = weightReturnPct;
            }else if(key=="annualAverageReturnPercent"){
                item.annualAverageReturnPercent = newVal;
                item.weightReturnPercent = weightReturnPct;
            }
            updateAllocationItem(item);
           
        }
        
        
    }

    function getSummaryRow(){
        let sumAllocations = 0;
        let sumReturns = 0;
        let sumWeightReturns = 0;
        let sumFeesPct = 0;
        let insuranceProtectionPct = values && values.insuranceProtectionPercentage?values.insuranceProtectionPercentage:0;
        let list = values && values.investmentAllocations?values.investmentAllocations:[];
        if(list){
            for (let index = 0; index < list.length; index++) {
                let a = list[index];
                let pct =a && a.allocationPercent?a.allocationPercent:0;
                sumAllocations = sumAllocations + pct;

                let roi = a && a.annualAverageReturnPercent?a.annualAverageReturnPercent:0;
                sumReturns = sumReturns + roi;

                let weightReturn = a && a.weightReturnPercent?a.weightReturnPercent:0;
                sumWeightReturns = sumWeightReturns + weightReturn;

                let feePct = a && a.feePercent?a.feePercent:0;
                sumFeesPct = sumFeesPct + feePct;
                
            }
        }

        let total = sumAllocations + insuranceProtectionPct;
        let remaining = 100 - total;
        let summaryClassName = "text-input text-bold text-right"
        
        return (
            <>  
                {list && list.length > 0 && (
                    <tr key="total">
                        <td style={{width:"15%"}} colSpan={2}>
                            <span className="text-bold">Total Summary</span>             
                        </td>
                        
                        <td style={{width:"10%"}}>
                            <NumberFormat className={summaryClassName} value={sumAllocations} suffix=" %" disabled={true} />
                        </td>
                        <td style={{width:"10%"}} >
                            <NumberFormat className={summaryClassName} value={sumReturns} suffix=" %" disabled={true} />
                        </td>
                        <td style={{width:"10%"}} >
                            <NumberFormat className={summaryClassName} value={sumWeightReturns.toFixed(PERCENT_SCALE)} suffix=" %" disabled={true} />
                        </td>
                        <td style={{width:"25%"}}>
                        </td>
                        <td style={{width:"10%"}}>
                            <NumberFormat className={summaryClassName} value={sumFeesPct} suffix=" %" disabled={true} />
                        </td>
                        <td style={{width:"5%"}}>
                        </td>
                                
                    </tr>
                )}
               
                <tr key="remaining">
                    <td style={{width:"15%"}} colSpan={2}>
                        <span className="text-bold">Remaining Allocation</span> 
                    </td>
                    <td style={{width:"10%"}}>
                        <NumberFormat className={summaryClassName} value={remaining} suffix=" %" disabled={true} />
                    </td>
                    <td colSpan={5}>
                        
                    </td>                 
                                
                </tr>
            </>
            

            
        )
    }

    return (
       
                <>
                
                <div className="portal-form-row text-right">
                    <span
                        className="btn btn-plus btn-plus-grey"
                        onClick={(e) => onAddButtonClicked(e)}
                    />
                </div>
                <table
                    className="table-blue"
                    width="100%"
                    cellSpacing={0}
                    cellPadding={0}
                    border={0}
                >
                    <tbody>
                        <tr>
                            <th style={{width:"15%"}}>Allocation Type</th>
                            <th style={{width:"15%"}}>Product Type</th>
                            <th style={{width:"10%"}}>Allocation %</th>
                            <th style={{width:"10%"}}>Annual Return %</th>
                            <th style={{width:"10%"}}>Weight Return %</th>
                            <th style={{width:"25%"}}>Portfolio Instruments</th>
                            <th style={{width:"10%"}}>Fees %</th>
                            <th style={{width:"5%"}}></th>
                        </tr>
                        <FieldArray name="investmentAllocations">
                            {({ fields }) =>
                                fields.map((name, index) => {
                                    return (
                                        <tr key={index}>
                                            <td style={{width:"15%"}}>
                                                <Field
                                                    name={`${name}.allocationType`}
                                                    component={renderSelectFieldCustom}
                                                    valueProperty="key"
                                                    labelProperty="name"
                                                    data={allocationTypes}
                                                   
                                                />
                                            </td>
                                            <td style={{width:"15%"}}>
                                                <Field
                                                    name={`${name}.productType`}
                                                    component={renderSelectFieldCustom}
                                                    valueProperty="code"
                                                    labelProperty="name"
                                                    data={getFilteredProductTypes(index)}
                                                    onChange={(newVal) => onProductTypeChanged(index, newVal)}
                                                />
                                            </td>
                                            
                                            <td style={{width:"10%"}}>
                                                <Field
                                                    name={`${name}.allocationPercent`}
                                                    component={
                                                        renderPercentageCustom
                                                    }
                                                    onChange={(newVal) => calculateWeightReturnPercent("allocationPercent", index, newVal)}
                                                />
                                            </td>
                                            <td style={{width:"10%"}}>
                                                <Field
                                                    name={`${name}.annualAverageReturnPercent`}
                                                    component={
                                                        renderPercentageCustom
                                                    }
                                                    maxValue={9999}
                                                    onChange={(newVal) => calculateWeightReturnPercent("annualAverageReturnPercent", index, newVal)}
                                                   
                                                />
                                            </td>
                                            <td style={{width:"10%"}}>
                                                <Field
                                                    name={`${name}.weightReturnPercent`}
                                                    component={
                                                        renderPercentageCustom
                                                    }
                                                    disabled={true}
                                                   
                                                />
                                                                                           
                                            </td>
                                            
                                            <td style={{width:"25%", textAlign:"center"}}>
                                                <button
                                                    className="portal-btn portal-btn-submit fa fa-list"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setModal({
                                                          title: `Investment Allocation Details`,
                                                          content: <InvestmentAllocationDetails currency={currency} keyName={`${name}.detailInstruments`} parent={values.investmentAllocations[index]} mutators={mutators} />,
                                                          isSmall: true
                                                        })
                                                    }}
                                                >
                                                </button>
                                                &nbsp;
                                                {getDetailInstrumentsDesc(values.investmentAllocations[index]?values.investmentAllocations[index].detailInstruments:[])}
                                            </td>
                                            <td style={{width:"10%"}}>
                                                <Field
                                                    name={`${name}.feePercent`}
                                                    component={
                                                        renderPercentageCustom
                                                    }
                                                   
                                                />
                                                                                           
                                            </td>
                                            <td style={{width:"5%"}}>
                                                <span
                                                    className="btn-remove"
                                                    onClick={() => {
                                                        removeInvestmentAllocation(
                                                            'investmentAllocations',
                                                            index
                                                        );
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                        </FieldArray>
                        {getSummaryRow()}
                    </tbody>
                </table>
             </>
    );         
};

export default InvestmentAllocation;
