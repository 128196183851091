/*
    Author : Erik Farhan Malik (erikfarhanmalik@gmail.com)
    Description : Residence Property main page (modal)
*/

import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import intl from "react-intl-universal"
import { netWorthFormActions, modalWindowActions } from "../../actions"
import { modalWindow } from "../../constants/ModalWindowLists"
import NetWorthForm from "./NetWorthForm"
import { ResidencePropertyForm } from "../../forms"

class ResidenceProperty extends NetWorthForm {
  constructor(props) {
    const { type } = props
    let title = intl.get("otherResidencePropertyOwnership.title")
    let modalName = modalWindow.OTHER_RESIDENCE_PROPERTY
    let path = "personalAssets.otherResidenceProperties"
    let componentName = "OtherResidenceProperties"

    if (type === "PRIMARY") {
      title = intl.get("primaryResidencePropertyOwnership.title")
      modalName = modalWindow.PRIMARY_RESIDENCE_PROPERTY
      path = "personalAssets.primaryResidenceProperty"
      componentName = "PrimaryResidenceProperty"
    }

    super(props, title, modalName, path, componentName)
  }

  render() {
    const {
      model,
      ableToPurchaseUsingCpf,
      client,
      partner,
      isJoint,
      optionsData,
      currency
    } = this.props

    const { handleSave, contentWrapper, readOnlyAccessComponent } = this
    return contentWrapper(
      <ResidencePropertyForm
        handleSave={handleSave}
        model={model}
        readOnlyAccess={readOnlyAccessComponent()}
        optionsData={optionsData}
        ableToPurchaseUsingCpf={ableToPurchaseUsingCpf}
        currency={currency}
        clientInformation={{
          isJoint,
          client,
          partner
        }}
      />
    )
  }
}

function mapStateToProps(state, elementProperties) {
  const { client, partner, hasPartner: isJoint } = state.entity

  const { ableToUseCpf } = state.netWorth
  const { optionsData } = state.enumOptions
  const { model, data } = state.netWorthForm

  let isShowForm
  if (elementProperties.type === "PRIMARY") {
    isShowForm = state.modalWindow[modalWindow.PRIMARY_RESIDENCE_PROPERTY]
  } else {
    isShowForm = state.modalWindow[modalWindow.OTHER_RESIDENCE_PROPERTY]
  }

  const openMortgageCashAccountFormModal =
    state.modalWindow[modalWindow.MORTGAGE_CASH_ACCOUNT_FORM]
  const { activeAccessLevel } = state.appSettings
  return {
    isShowForm,
    client,
    model: { ...model, residencePropertyType: elementProperties.type },
    ableToPurchaseUsingCpf: ableToUseCpf,
    partner,
    isJoint,
    optionsData,
    openMortgageCashAccountFormModal,
    data,
    activeAccessLevel
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, netWorthFormActions, modalWindowActions),
    dispatch
  )
}

ResidenceProperty = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResidenceProperty)

export default ResidenceProperty
