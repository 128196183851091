import api from "../../lib/api";

class EmailApi {
    // retrieve data
    static recipients(userId) {
        return api.get(`/entity/${userId}/emailClients`);
    }
   
}

export default EmailApi;
