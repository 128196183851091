import React, { useContext } from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from "react-accessible-accordion"
import intl from "react-intl-universal"
import { AppContext } from "../../../../context/AppContext"
import CashFlowHeaderListing from "../../CashFlowHeaderListing"
import CashFlowListingGroup from "../../CashFlowListingGroup"
import api from "../../../../lib/api"
import BondForm from "../../../../forms/BondForm"
import { useDispatch } from "react-redux"
import { netWorthFormActions } from "../../../../actions"

const Bond = ({
  clientTotalValue,
  partnerTotalValue,
  jointTotalValue,
  optionsData,
  readOnlyAccessComponent,
  clientInformation,
  currency,
  onExternalUpdated,
  isJoint,
  values,
  varPrefix
}) => {
  const {
    modal: { setModal, clearModal }
  } = useContext(AppContext)

  const handleSave = (newValues) => {
    // save the model
    api.put(newValues.url, newValues).then((response) => {
      onExternalUpdated(true)
    })

    clearModal()
  }
  const dispatch = useDispatch()

  return (
    <div className="portal-part">
      <Accordion accordion={false}>
        <AccordionItem>
          <AccordionItemTitle>
            <CashFlowHeaderListing
              title={intl.get("bonds.title")}
              isAccordion
              clientTotalValue={clientTotalValue}
              partnerTotalValue={partnerTotalValue}
              jointTotalValue={jointTotalValue}
              currency={currency}
              isJointHide={!isJoint}
            />
          </AccordionItemTitle>
          <AccordionItemBody>
            <CashFlowListingGroup
              varPrefix={`${varPrefix}`}
              dataArray={
                values.detailResult
                // values.personalIncome.investmentPropertyRental
              }
              isRoundColorRed
              isExternalModal
              isJointHide={!isJoint}
              onEditClick={(model) => {
                dispatch(
                  netWorthFormActions.setNewForm(false, model.classification)
                )

                setModal({
                  title: intl.get("bonds.title"),
                  modalClassName: "Bond",
                  content: (
                    <BondForm
                      handleSave={handleSave}
                      model={model}
                      optionsData={optionsData}
                      readOnlyAccess={readOnlyAccessComponent()}
                      clientInformation={clientInformation}
                    />
                  ),
                  formType: "bonds"
                })
              }}
            />
          </AccordionItemBody>
        </AccordionItem>
      </Accordion>
    </div>
  )
}

export default Bond
