/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Description : Taxation form page (modal)
*/

import React from 'react';
import intl from 'react-intl-universal';
import { Form } from 'react-final-form';

import { ClassificationSelection, FormButtons, ModalNoAccess } from '../components';
import RenderSimpleFields, {
    FieldType,
} from '../components/Form/RenderSimpleFields';
import TaxSelector from '../pages/NetWorth/Taxation/TaxSelector';

const PersonalTaxForm = ({ handleSave, model, client, optionsData, readOnlyAccess, closeModalWindow }) =>
    !readOnlyAccess ? (
        <Form
            onSubmit={handleSave}
            initialValues={model}
            render={({ handleSubmit, reset }) => (
                <form id="personalTaxForm" onSubmit={handleSubmit}>
                    <div className="portal-form-popup personalTaxForm-content">
                    <ClassificationSelection/>
                        <TaxSelector model={model} client={client} handleSave={handleSave} closeParentWindow={closeModalWindow}/>
                        
                        <RenderSimpleFields
                            fields={[
                                {
                                    name: 'taxPaymentCountry',
                                    type: FieldType.SELECTION,
                                    label: intl.get(
                                        'taxation.personalTax.taxPaymentCountry'
                                    ),
                                    selectionData:optionsData.operationCountryOptions,
                                    valueProperty: "code",
                                    labelProperty: "label",
                                    required: true,
                                },
                                {
                                    name: 'taxPaymentAmount',
                                    type: FieldType.MONEY_WITH_CURRENCY,
                                    label: intl.get(
                                        'taxation.personalTax.taxPaymentAmount'
                                    ),
                                    required: true,
                                    allowNegative: false,
                                },
                                {
                                    name: 'taxPaymentFrequency',
                                    type: FieldType.ENUM_SELECTION,
                                    label: intl.get(
                                        'taxation.personalTax.taxPaymentFrequency'
                                    ),
                                    enumData: optionsData.frequencyOptions,
                                    required: true,
                                },
                                {
                                    name: 'firstInstallmentDate',
                                    type: FieldType.DATE,
                                    label: intl.get(
                                        'taxation.personalTax.firstInstallmentDate'
                                    ),
                                }
                               
                            ]}
                        />

                        <FormButtons
                            reset={reset}
                            readOnlyAccess={readOnlyAccess}
                        />
                    </div>
                </form>
            )}
        />
    ) : (
        <ModalNoAccess />
    );

export default PersonalTaxForm;
