import LaptopLoginImage from "./laptop-login.png"
import ChevronDownIcon from "./chevron-down.svg"
import KoizaiLogoLG from "./koizai-logo-lg.png"
import KoizaiLogoSM from "./koizai-logo-sm.png"
import LoginLeftBg from "./loginleftBg.png"
import PartnerIcon from "./partner-icon.svg"

export {
  LaptopLoginImage,
  ChevronDownIcon,
  KoizaiLogoLG,
  KoizaiLogoSM,
  LoginLeftBg,
  PartnerIcon
}
