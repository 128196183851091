/*
    Author : Erik (erik.malik@devstack.com.au)
    Description : Selection for classification for networth form, read client and partner information directly from networth reducers
*/

import React, { Component } from "react"
import { connect } from "react-redux"
import { Field } from "react-final-form"
import { renderJointSelectionMenuCustom } from "../../helpers"
class ClassificationSelection extends Component {
  render() {
    const {
      client,
      partner,
      isJoint,
      hideJoint,
      model,
      clientFullNamePreferred,
      partnerFullNamePreferred
    } = this.props
    let isNew = model && model.isNew ? model.isNew : false
    let classification = model && model.classification

    return (
      <React.Fragment>
        {partner && isNew && (
          <Field
            name="classification"
            component={renderJointSelectionMenuCustom}
            clientName={
              clientFullNamePreferred ? clientFullNamePreferred : "Client"
            }
            partnerName={
              partnerFullNamePreferred ? partnerFullNamePreferred : "Partner"
            }
            hideJoint={!isJoint || hideJoint}
          />
        )}

        {!partner && isNew && (
          <Field
            name="classification"
            component={renderJointSelectionMenuCustom}
            clientName={
              clientFullNamePreferred ? clientFullNamePreferred : "Client"
            }
            hideJoint={!isJoint || hideJoint}
          />
        )}
        {classification && !isNew && (
          <Field
            name="classification"
            component={renderJointSelectionMenuCustom}
            clientName={
              classification === "CLIENT"
                ? clientFullNamePreferred
                  ? clientFullNamePreferred
                  : "Client"
                : undefined
            }
            partnerName={
              classification === "PARTNER"
                ? partnerFullNamePreferred
                  ? partnerFullNamePreferred
                  : "Partner"
                : undefined
            }
            hideJoint={classification !== "JOINT"}
            classification={classification}
          />
        )}
      </React.Fragment>
    )
  }
}
function mapStateToProps(state) {
  const { client, partner, isJoint } = state.jointSelection.data
  const { model } = state.netWorthForm
  const { clientFullNamePreferred, partnerFullNamePreferred } = state.entity
  return {
    client,
    partner,
    isJoint,
    model,
    clientFullNamePreferred,
    partnerFullNamePreferred
  }
}

ClassificationSelection = connect(mapStateToProps)(ClassificationSelection)
export default ClassificationSelection
