import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import {userActions } from "../../actions"
import { LoginFormNew } from "../../components/LoginForm"
import { VerificationForm } from "../../components/TwoFactor"

import { history } from "../../helpers"
import { LaptopLoginImage } from "../../assets/images/new-skin-images"
import { LoginLeftBg } from "../../assets/images/new-skin-images"
import AppVersion from "../AppSettings/AppVersion"


export class Login extends Component {
  constructor(props){
    super(props);
    this.state = {
      
    }
    
  }

  componentDidMount() {
    const API_URL =
      process.env.REACT_APP_BASE_LOGIN_URL + process.env.REACT_APP_LOGIN

    const activeDashboardRoute = localStorage.getItem("activeDashboardRoute")
    const loggingIn = localStorage.getItem("loggingIn")
   
     

    if (!(activeDashboardRoute && loggingIn)) {
      localStorage.setItem("base-route", API_URL)
      
    }
  }



  
  render() {
    const { twoStep} = this.props

    //redirect to dashboard
    const activeDashboardRoute = localStorage.getItem("activeDashboardRoute")
    const loggingIn = localStorage.getItem("loggingIn")

    if (activeDashboardRoute && loggingIn) {
      history.push(activeDashboardRoute)
    }

    

    return (
      <div className="login-outer_container">
        <div className="login-container">
          <div className="d-flex flex-column h-100 login-container_inner flex-md-row">
            <div
              style={{
                backgroundImage: `url(${LoginLeftBg})`
              }}
              className=" col-md-7  d-none d-md-flex flex-column  h-md-100 login-left align-items-center justify-content-center"
            >
              <img className="logo" src={LaptopLoginImage} alt="logo" />
              <div className="lorem-text text-center">
                Financial Planning And Wealth Management Solution Utilising
                Applied Fintech
              </div>
              <div className="info-text text-center">
                We aim to empower, inspire and democratise the financial
                advisory and management industry <br className="" />
                with collaborative and leading-edge technology focused on
                AdviceTech solutions.
              </div>
              <div className="version">
                  <AppVersion />
              </div>
              
             
            </div>
            <div
              className="col-12 col-md-5 h-100 px-4"
              style={{ overflow: "auto" }}
            >
              {/* {twoStep ? (
                <VerificationForm />
              ) : ( */}
              <LoginFormNew twoStep={twoStep} />
              {/* // )} */}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { twoStep } = state.authentication
  return {
    twoStep
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(userActions, dispatch)
}

Login = connect(mapStateToProps, mapDispatchToProps)(Login)

export default Login
