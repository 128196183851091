
import React, { useState, useContext, useEffect } from "react"
import intl from 'react-intl-universal';

import Alert from "react-s-alert"
import NumberFormat from "react-number-format";
import { AppContext } from "../../context/AppContext"
import { getSymbolFromCode } from "../../helpers";
import { numberWithCommas } from "../../helpers";
import SuperAnnuationInvestmentDialog from "./SuperAnnuationInvestmentDialog";

function InvestmentAllocationDetails({currency, keyName, parent, mutators}){
    const currencySymbol = getSymbolFromCode(currency);
    const [list, setList] = useState(parent && parent.detailInstruments? parent.detailInstruments: [])
    const {
        modal: { setModal, clearModal }
      } = useContext(AppContext)

      console.log('parent : ', parent);

    //get data from dialog
    const handleInvestmentData = (data) => {
        list.push(data);
    }

    function getInvestmentTable(){
    let rows = [];
    if(list){
        for (let index = 0; index < list.length; index++) {
            let inv = list[index];
            rows.push(
                <tr>
                  <td style={{width:"10%", textAlign:"left"}}>{inv.platformName}</td>
                  <td style={{width:"10%", textAlign:"left"}}>{inv.productName}</td>
                  <td style={{width:"10%", textAlign:"center"}}>{inv.numberOfUnit}</td>
                  <td style={{width:"10%", textAlign:"right"}}>{currencySymbol}{numberWithCommas(inv.purchasePricePerUnit)}</td>
                  <td style={{width:"10%", textAlign:"right"}}>{currencySymbol}{numberWithCommas(inv.currentPricePerUnit)}</td>
                  <td style={{width:"10%", textAlign:"right"}}>{currencySymbol}{numberWithCommas(inv.currentValue)}</td>
                  <td style={{width:"10%", textAlign:"center"}}>{numberWithCommas(inv.currentGrowthPercent)}%</td>
                
                </tr>
            )
        
        }


    }
    
    return (
        <table className="table-blue">
            <tbody>
                <tr>
                    <th style={{width:"10%"}}>Platform Name</th>
                    <th style={{width:"10%"}}>Product Name</th>
                    <th style={{width:"10%", textAlign:"center"}}>Number of Unit</th>
                    <th style={{width:"10%", textAlign:"center"}}>Purchase Price (unit)</th>
                    <th style={{width:"10%", textAlign:"center"}}>Current Price (Unit)</th>
                    <th style={{width:"10%", textAlign:"center"}}>Current Value</th>
                    <th style={{width:"10%", textAlign:"center"}}>Current Growth (%)</th>
                
                </tr>

                {rows}

                
            </tbody>
        </table>  
    )
     
  }

  const onClickInvestmentAdd = (e) => {
    
    e.preventDefault();
    //open dialog
    setModal({
      title: `Add Investment Instrument Details`,
      content: (
        <SuperAnnuationInvestmentDialog
          parentId = {parent.allocationId}
          defaultCurrency={currency}
          doAdd={handleInvestmentData}
          clearModal={clearModal}
      
        />
      ),
      isSmall: true
    })
  }


  return (
     <div className="notification-dialog">
         <button className="btn btn-plus" onClick={(e)=>onClickInvestmentAdd(e)}></button>
         {getInvestmentTable()}    
     </div>
  )
}
export default InvestmentAllocationDetails