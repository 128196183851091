import { currentStandingAction } from "../../constants/ActionConstants"

const initialState = {
  lifeline: {},
  clientCurrentStanding: {}
}

export function currentStanding(state = initialState, action) {
  switch (action.type) {
    case currentStandingAction.GET_CURRENT_STANDING_SUCCESS:
      return {
        ...state,
        clientCurrentStanding: action.data
      }
    case currentStandingAction.GET_LIFELINE_SUCCESS:
        // clg
      return {
        ...state,
        lifeline: action.data
      }

    default:
      return state
  }
}
