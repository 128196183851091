import React from 'react';
import { Form } from 'react-final-form';
import intl from 'react-intl-universal';
import RenderSimpleFields, {
    FieldType,
} from '../../../components/Form/RenderSimpleFields';
import { getSymbolFromCode } from '../../../helpers';
import NetWorthFormButtons from '../NetWorthFormButtons';

function InvestmentPlatformForm({ handleSave, model, optionsData, currency = 'MYR' }) {
    if (!model) {
        model = Object.assign({}, { reportingCurrency: currency })
    }
    return (
        <Form
            onSubmit={handleSave}
            initialValues={model}
            render={({ handleSubmit, values }) => (
                <form id="investmentPlatform" onSubmit={handleSubmit}>
                    <div className="portal-form-popup">
                        <div className="subtitle2">
                            {intl.get(
                                'netWorth.investmentDetails.investmentPlatform.subtitle'
                            )}
                        </div>

                        <RenderSimpleFields
                            fields={[
                                {
                                    name: 'investmentProviderName',
                                    label: intl.get(
                                        'netWorth.investmentDetails.investmentPlatform.investmentPlatformName'
                                    ),
                                    type: FieldType.TEXT,
                                    required: true,
                                },
                                {
                                    name: 'institutionName',
                                    label: intl.get(
                                        'netWorth.investmentDetails.investmentPlatform.institutionName'
                                    ),
                                    type: FieldType.TEXT,
                                    required: true,
                                },
                                {
                                    name: 'platformAccountNumber',
                                    label: intl.get(
                                        'netWorth.investmentDetails.investmentPlatform.platformAccountNumber'
                                    ),
                                    type: FieldType.TEXT,
                                    required: true,
                                },
                                {
                                    name: 'accountStartDate',
                                    label: intl.get(
                                        'netWorth.investmentDetails.investmentPlatform.accountStartDate'
                                    ),
                                    type: FieldType.DATE,
                                    required: true,
                                },
                                {
                                    name: 'accountBalance',
                                    label: intl.get(
                                        'netWorth.investmentDetails.investmentPlatform.accountBalance'
                                    ),
                                    type: FieldType.MONEY_WITH_CURRENCY,
                                    required: true,
                                    symbolPrefix: getSymbolFromCode(values.reportingCurrency),
                                    defaultCurrency: values.reportingCurrency
                                },
                                {
                                    name: 'accountValuationDate',
                                    label: intl.get(
                                        'netWorth.investmentDetails.investmentPlatform.accountValuationDate'
                                    ),
                                    type: FieldType.DATE,
                                    required: true,
                                },
                                {
                                    name: 'reportingCurrency',
                                    label: intl.get(
                                        'netWorth.investmentDetails.investmentPlatform.investmentCurrency'
                                    ),
                                    type: FieldType.ENUM_SELECTION,
                                    enumData: optionsData.currencyOptions,
                                    required: true,
                                    defaultValue: getSymbolFromCode(currency),
                                },
                            ]}
                        />

                        <div className="subtitle2">
                            {intl.get(
                                'netWorth.investmentDetails.investmentPlatform.fees'
                            )}
                        </div>

                        <RenderSimpleFields
                            fields={[
                                {
                                    name: 'adviserManagementFee',
                                    label: intl.get(
                                        'netWorth.investmentDetails.investmentPlatform.advisorManagementFee'
                                    ),
                                    type: FieldType.MONEY_WITH_PERCENTAGE,
                                    getPercentageValueSource: () => values.accountBalance,
                                    currency: values.reportingCurrency,
                                },
                                {
                                    name: 'adviserManagementFeeFrequency',
                                    label: intl.get(
                                        'netWorth.investmentDetails.investmentPlatform.advisorManagementFeeFrequency'
                                    ),
                                    type: FieldType.ENUM_SELECTION,
                                    enumData: optionsData.frequencyOptions,
                                },
                                {
                                    name: 'administrationFee',
                                    label: intl.get(
                                        'netWorth.investmentDetails.investmentPlatform.administrationFee'
                                    ),
                                    type: FieldType.MONEY_WITH_PERCENTAGE,
                                    getPercentageValueSource: () => values.accountBalance,
                                    currency: values.reportingCurrency,
                                },
                                {
                                    name: 'administrationFeeFrequency',
                                    label: intl.get(
                                        'netWorth.investmentDetails.investmentPlatform.administrationFeeFrequency'
                                    ),
                                    type: FieldType.ENUM_SELECTION,
                                    enumData: optionsData.frequencyOptions,
                                },
                                {
                                    name: 'platformFee',
                                    label: intl.get(
                                        'netWorth.investmentDetails.investmentPlatform.platformFee'
                                    ),
                                    type: FieldType.MONEY_WITH_PERCENTAGE,
                                    getPercentageValueSource: () => values.accountBalance,
                                    currency: values.reportingCurrency,
                                },
                                {
                                    name: 'platformFeeFrequency',
                                    label: intl.get(
                                        'netWorth.investmentDetails.investmentPlatform.platformFeeFrequency'
                                    ),
                                    type: FieldType.ENUM_SELECTION,
                                    enumData: optionsData.frequencyOptions,
                                },
                            ]}
                        />

                        <NetWorthFormButtons />
                    </div>
                </form>
            )}
        />
    );
}

export default InvestmentPlatformForm;
