/*
    Author : Erik (erik.malik@devstack.com.au)
    Description : Base class for forms in networth, that set general behaviour of networth forms
*/

import React, { Component } from "react"
import ReactModal from "react-modal"
import { _formHelper } from "../_helper/NetWorthFormHelper"
import NetWorthFormHeader from "./NetWorthFormHeader"
import { readOnlyAccess } from "../../helpers"
import Alert from "react-s-alert"

import { modalWindow } from "../../constants/ModalWindowLists"
import {
  validateEmploymentTerminationPayment,
  validateEpfBeforeSave
} from "./EmployeeProvidenceFundValidator"
import { FileOperationApi } from "../../api"
import { AssetTypesEnums } from "../../utils/enums/Enum"

class NetWorthForm extends Component {
  constructor(
    props,
    formTitle,
    modalName,
    path,
    componentName,
    noMarginWindow = false
  ) {
    super(props)
    this.formTitle = formTitle
    this.modalName = modalName
    this.path = path
    this.componentName = componentName

    this.isList = false
    this.isJointAble = true
    this.oneDataOnly = false
    this.noMarginWindow = noMarginWindow
  }

  adjustLoanData = (model) => {
    if (model.paidUpCapitalFinancedThruLoan) {
      return model
    } else if (!model.underLoan) {
      var getstring = JSON.stringify(model)
      var myObject = JSON.parse(getstring)
      delete myObject.loan
      return myObject
    } else {
      if (
        (!model.financedThruLoan || !model.isUnderLoan) &&
        model.assetType !== "INVESTMENT_PLATFORM"
      ) {
        var getstring = JSON.stringify(model)
        var myObject = JSON.parse(getstring)
        delete myObject.marginLoan

        return myObject
      }

      if (
        model.assetType !== "INVESTMENT_PLATFORM" &&
        !model.propertyDetail.underMortgage
      ) {
        var getstring = JSON.stringify(model)
        var myObject = JSON.parse(getstring)
        delete myObject.mortgage

        return myObject
      }

      return model
    }
  }

  keepLoanData = (model) => {
    if (!model.underLoan) {
      // var getstring = JSON.stringify(model);
      // var myObject = JSON.parse(getstring);
      // delete myObject.loan;

      return model
    } else {
      if (!model.financedThruLoan || !model.isUnderLoan) {
        var getstring = JSON.stringify(model)
        var myObject = JSON.parse(getstring)
        // delete myObject.marginLoan;

        return myObject
      }

      if (!model.propertyDetail.underMortgage) {
        var getstring = JSON.stringify(model)
        var myObject = JSON.parse(getstring)
        // delete myObject.mortgage;

        return myObject
      }

      return model
    }
  }

  handleSave = async (model) => {
    let proofDocument
    if (model && model.proofDocument && model.proofDocument.file) {
      const formData = new FormData()
      formData.append("sourceType", "CLIENT")
      formData.append("sourceId", this.props.client.id)
      formData.append(
        "title",
        AssetTypesEnums[model.assetType] || model.assetType
      )
      formData.append("module", "INVESTMENT")
      formData.append(
        "moduleId",
        AssetTypesEnums[model.assetType] || model.assetType
      )
      formData.append("file", model.proofDocument.file)

      const response = await FileOperationApi.upload(formData)

      proofDocument = {
        genericUploadFileId: response.data.id,
        fileName: response.data.file.filename,
        fileUrl: response.data.file.path
      }
      model.proofDocument = proofDocument || model.proofDocument
    }
    if (model.updateusi) {
      model.fundAbn = model.updateusi.fundAbn
      model.fundName = model.updateusi.fundName
      model.productName = model.updateusi.productName
      model.usi = model.updateusi.usi

      if (model.employmentincome[0].data) {
        model.employeeConcessionalContribution =
          model.employmentincome[0].data.salaryValue.value *
          (model.employmentincome[0].data.contributionTaxPercent / 100)

        model.employerExtraConcessionalContribution =
          model.employerExtraConcessionalContribution
            ? model.employerExtraConcessionalContribution
            : 0
        model.salarySacrifice = model.salarySacrifice
          ? model.salarySacrifice
          : 0
        model.employeeNonConcessionalContribution =
          model.employmentincome[0].data.salaryValue.value *
          (model.employmentincome[0].data.contributionTaxPercent / 100)
        model.employerContributionPercent =
          model.employmentincome[0].data.contributionTaxPercent
        model.monthlyWages = model.monthlyWages
          ? model.monthlyWages
          : model.employmentincome &&
            model.employmentincome[0].data &&
            model.employmentincome[0].data.salaryValue.value
        model.yearlyWages = model.yearlyWages
          ? model.yearlyWages
          : model.employmentincome &&
            model.employmentincome[0].data &&
            model.employmentincome[0].data.salaryValue.value * 12
      }
    }

    if (model.newinvestmentarray) {
      model.investmentDetailList = model.newinvestmentarray
    }

    const checkMandatoryLoanFields = (loan) => {
      if (!loan) {
        return false
      }
      const {
        borrowingsAccountNumber,
        borrowingsPrinciple,
        // borrowingsAnnualInterestRate,
        // borrowingsStartDate,
        // borrowingsTermInYears,
        interestRates,
        borrowingsRepaymentFrequency
      } = loan

      return (
        borrowingsAccountNumber &&
        borrowingsPrinciple &&
        interestRates &&
        borrowingsRepaymentFrequency
      )
    }

    const checkMandatoryInsuranceFields = (insuranceDetails) => {
      if (!insuranceDetails) {
        return false
      }
      const {
        policyName,
        insuredAmount,
        policyPremium,
        policyPremiumFrequency,
        policyStartDate
      } = insuranceDetails
      return (
        policyName &&
        insuredAmount &&
        policyPremium &&
        policyPremiumFrequency &&
        policyStartDate
      )
    }
    if (
      model.mortgage &&
      model.mortgage.interestRates &&
      model.mortgage.interestRates.length
    ) {
      let previousEndDateInMilliseconds = null // Initialize to track the previous end date

      for (let idx = 0; idx < model.mortgage.interestRates.length; idx++) {
        const item = model.mortgage.interestRates[idx]
        let startDateInMilliseconds = new Date(item.startDate).getTime()
        let endDateInMilliseconds = new Date(item.endDate).getTime()

        // Check if endDate is greater than startDate
        if (endDateInMilliseconds <= startDateInMilliseconds) {
          Alert.error(
            `Invalid date range for item ${
              idx + 1
            }: End date should be after the start date`
          )
          return // Exit immediately if there's an issue
        }

        // Check if startDate is after the previous endDate for subsequent interest rates
        if (
          previousEndDateInMilliseconds !== null &&
          startDateInMilliseconds <= previousEndDateInMilliseconds
        ) {
          Alert.error(
            `Invalid date for item ${
              idx + 1
            }: Start date should be after the end date of item ${idx}`
          )
          return // Exit immediately if there's an issue
        }

        // Update previousEndDateInMilliseconds for the next iteration
        previousEndDateInMilliseconds = endDateInMilliseconds
      }
    }

    if (model.underLoan && model.assetType !== "INVESTMENT_PLATFORM") {
      // if (!checkMandatoryLoanFields(model.loan)) {
      //   Alert.error("Loan Tab fields are mandatory!")
      //   return
      // }
    }

    if (model.propertyDetails) {
      if (model.propertyDetails.underMortgage) {
        // if (!checkMandatoryLoanFields(model.mortgage)) {
        //   Alert.error("Loan Tab fields are mandatory !")
        //   return
        // }
      }

      if (model.propertyDetails.fireAndContentInsured) {
        if (!checkMandatoryInsuranceFields(model.fireAndContentInsurance)) {
          Alert.error("Fire and Content Insurance Tab fields are mandatory !")
          return
        }
      }

      if (model.propertyDetails.mortgageInsured) {
        if (!checkMandatoryInsuranceFields(model.mortgageInsurance)) {
          Alert.error("Mortgage Insurance Tab fields are mandatory !")
          return
        }
      }
    }

    // for personalContent
    if (model.personalItemInsured) {
      if (!checkMandatoryInsuranceFields(model.insuranceDetails)) {
        Alert.error("Insurance Tab fields are mandatory !")
        return
      }
    }

    // for MotorVehicle insurance
    if (model.insured) {
      if (!checkMandatoryInsuranceFields(model.insuranceDetails)) {
        Alert.error("Insurance Tab fields are mandatory !")
        return
      }
    }

    // for investment property form
    if (model.propertyUnderMortgage) {
      if (!checkMandatoryLoanFields(model.mortgage)) {
        Alert.error("Mortgage Tab fields are be mandatory !")
        return
      }
    }

    // for investment mortgage insurance
    if (model.mortgageInsured) {
      if (!checkMandatoryInsuranceFields(model.mortgageInsurance)) {
        Alert.error("Mortgage Insurance Tab fields are mandatory !")
        return
      }
    }

    // for investment fireAndContentInsurance
    if (model.fireAndContentInsured) {
      if (!checkMandatoryInsuranceFields(model.fireAndContentInsurance)) {
        Alert.error("Mortgage Insurance Tab fields are mandatory !")
        return
      }
    }

    // for Business ownership loan tab checkup
    if (model.paidUpCapitalFinancedThruLoan) {
      // if (!checkMandatoryLoanFields(model.loan)) {
      //   Alert.error("Loan Tab fields are mandatory !")
      //   return
      // }
    }

    const {
      client,
      data,
      saveNetWorthForm,
      saveOneDataOnlyNetWorthForm,
      saveEmploymentTerminationPayments,
      isJoint,
      setNetWorthFormModel,
      currency,
      saveCentralProvidenceFund,
      closeModalWindow
    } = this.props
    const callBack = (type) => {
      if (type === "success") {
        closeModalWindow()
      }
    }

    const { path, isList, oneDataOnly } = this

    let isValid = false

    switch (this.modalName) {
      case "employeeProvidenceFund":
        isValid = validateEpfBeforeSave(model)
        break
      case modalWindow.EMPLOYMENT_TERMINATION_PAYMENTS:
        isValid = validateEmploymentTerminationPayment(model)
        break
      default:
        isValid = true
        break
    }

    if (isValid) {
      if (oneDataOnly) {
        if (path.includes("employmentTerminationPayments")) {
          saveEmploymentTerminationPayments(
            client,
            isJoint
              ? {
                  ...data,
                  [model.classification.toLowerCase()]: model
                }
              : model,
            `${path}?reportingCurrency=${currency}`,
            isJoint,
            model.classification.toLowerCase(),
            callBack
          )
        } else if (path.includes("centralProvidenceFund")) {
          saveCentralProvidenceFund(
            client,
            model,
            `${path}?reportingCurrency=${currency}`,
            callBack
          )
        } else {
          saveOneDataOnlyNetWorthForm(
            client,
            isJoint
              ? {
                  ...data,
                  [model.classification.toLowerCase()]: model
                }
              : model,
            `${path}?reportingCurrency=${currency}`,
            isJoint,
            callBack
          )
        }
      } else {
        if (this.modalName === "retirementSchemeMpf") {
          if (model.mpfTrusteeList && model.mpfTrusteeList.length > 0) {
            let totalPercent = 0
            model.mpfTrusteeList.map((item) => {
              totalPercent += item.allocationPercent
            })

            if (totalPercent > 100) {
              Alert.error("Total Allocation Percentages cannot exceed 100")
              return
            }
          }
        }
        if (this.modalName === "investmentfundPlatform") {
          _formHelper.saveForm(
            this.adjustLoanData({
              isNew: model.isNew,
              classification: model.classification,
              ...model.investmentFundFormValues
            }),
            client,
            data.investmentFundFormValues,
            saveNetWorthForm,
            path,
            isList,
            currency,
            callBack
          )
          return
        }
        _formHelper.saveForm(
          this.adjustLoanData(model),
          client,
          data,
          saveNetWorthForm,
          path,
          isList,
          currency,
          callBack
        )
        if (this.modalName !== "businessOwnershipModal") {
          setNetWorthFormModel(this.adjustLoanData(model))
        }
      }
    }
  }

  handleSaveLoanDetails = (model) => {
    const callBack = (type) => {
      if (type === "success") {
        closeModalWindow()
      }
    }
    if (model.updateusi) {
      model.fundAbn = model.updateusi.fundAbn
      model.fundName = model.updateusi.fundName
      model.productName = model.updateusi.productName
      model.usi = model.updateusi.usi

      if (model.employmentincome[0].data) {
        model.employeeConcessionalContribution =
          model.employmentincome[0].data.salaryValue.value *
          (model.employmentincome[0].data.contributionTaxPercent / 100)

        model.employerExtraConcessionalContribution =
          model.employerExtraConcessionalContribution
            ? model.employerExtraConcessionalContribution
            : 0
        model.salarySacrifice = model.salarySacrifice
          ? model.salarySacrifice
          : 0
        model.employeeNonConcessionalContribution =
          model.employmentincome[0].data.salaryValue.value *
          (model.employmentincome[0].data.contributionTaxPercent / 100)
        model.employerContributionPercent =
          model.employmentincome[0].data.contributionTaxPercent
        model.monthlyWages = model.monthlyWages
          ? model.monthlyWages
          : model.employmentincome &&
            model.employmentincome[0].data &&
            model.employmentincome[0].data.salaryValue.value
        model.yearlyWages = model.yearlyWages
          ? model.yearlyWages
          : model.employmentincome &&
            model.employmentincome[0].data &&
            model.employmentincome[0].data.salaryValue.value * 12
      }
    }

    if (model.newinvestmentarray) {
      model.investmentDetailList = model.newinvestmentarray
    }

    const {
      client,
      data,
      saveNetWorthForm,
      saveOneDataOnlyNetWorthForm,
      saveEmploymentTerminationPayments,
      isJoint,
      setNetWorthFormModel,
      currency,
      saveCentralProvidenceFund,
      closeModalWindow
    } = this.props

    const { path, isList, oneDataOnly } = this

    let isValid = false

    switch (this.modalName) {
      case "employeeProvidenceFund":
        isValid = validateEpfBeforeSave(model)
        break
      case modalWindow.EMPLOYMENT_TERMINATION_PAYMENTS:
        isValid = validateEmploymentTerminationPayment(model)
        break
      default:
        isValid = true
        break
    }

    if (isValid) {
      if (oneDataOnly) {
        if (path.includes("employmentTerminationPayments")) {
          saveEmploymentTerminationPayments(
            client,
            isJoint
              ? {
                  ...data,
                  [model.classification.toLowerCase()]: model
                }
              : model,
            `${path}?reportingCurrency=${currency}`,
            isJoint,
            model.classification.toLowerCase(),
            callBack
          )
        } else if (path.includes("centralProvidenceFund")) {
          saveCentralProvidenceFund(
            client,
            model,
            `${path}?reportingCurrency=${currency}`
          )
        } else {
          saveOneDataOnlyNetWorthForm(
            client,
            isJoint
              ? {
                  ...data,
                  [model.classification.toLowerCase()]: model
                }
              : model,
            `${path}?reportingCurrency=${currency}`,
            isJoint,
            callBack
          )
        }
      } else {
        if (this.modalName === "retirementSchemeMpf") {
          if (model.mpfTrusteeList && model.mpfTrusteeList.length > 0) {
            let totalPercent = 0
            model.mpfTrusteeList.map((item) => {
              totalPercent += item.allocationPercent
            })

            if (totalPercent > 100) {
              Alert.error("Total Allocation Percentages cannot exceed 100")
              return
            }
          }
        }
        _formHelper.saveForm(
          model,
          client,
          data,
          saveNetWorthForm,
          path,
          isList,
          currency,
          callBack
        )
      }
    }
  }

  updateModelInStore = (model) => {
    const { setNetWorthFormModel } = this.props
    setNetWorthFormModel(model)
  }

  readOnlyAccessComponent = () => {
    const { props, componentName } = this
    const { activeAccessLevel, isShowForm } = props
    return readOnlyAccess(activeAccessLevel, componentName, isShowForm)
  }

  contentWrapper = (form, clearState) => {
    const { isShowForm, closeModalWindow } = this.props
    const {
      formTitle,
      modalName,
      readOnlyAccessComponent,
      noMarginWindow,
      componentName
    } = this

    return (
      <ReactModal
        isOpen={isShowForm}
        className={
          !noMarginWindow
            ? "malabar-modal-large networth"
            : "malabar-modal-no-margin networth"
        }
        overlayClassName="malabar-modal-overlay networth"
        ariaHideApp={false}
        shouldCloseOnEsc
        onRequestClose={() => {
          clearState && clearState()
          closeModalWindow(modalName)
        }}
      >
        <div className={`popup popup-networth ${componentName}`}>
          <div className="container-fluid container-fluid-networth-form">
            <NetWorthFormHeader
              title={formTitle}
              closeForm={() => {
                clearState && clearState()
                closeModalWindow(modalName)
              }}
            />
            {form}
          </div>
        </div>
      </ReactModal>
    )
  }
}

export default NetWorthForm

export const NetWorthPath = {
  // Personal Assets
  primaryResidenceProperty: "personalAssets.primaryResidenceProperty",
  otherResidenceProperties: "personalAssets.otherResidenceProperties",
  personalContents: "personalAssets.personalContents",
  motorVehicles: "personalAssets.motorVehicles",

  // Liquid Assets
  cashAccounts: "liquidAssets.cashAccounts",
  termDeposits: "liquidAssets.termDeposits",
  shares: "liquidAssets.shares",
  unitTrusts: "liquidAssets.unitTrusts",
  bonds: "liquidAssets.bonds",
  genericPortfolios: "liquidAssets.genericPortfolios",
  investmentPlatforms: "liquidAssets.investmentPlatforms",
  investmentFundPlatforms: "liquidAssets.investmentFundPlatforms",

  // Illiquid Assets
  investmentProperties: "illiquidAssets.investmentProperties",
  businessOwnerships: "illiquidAssets.businessOwnerships",
  collectibles: "illiquidAssets.collectibles",
  ilasInvestment: "illiquidAssets.ilas",

  // Retirement Assets
  mandatoryProvidentFunds: "retirementAssets.mandatoryProvidentFunds",
  occupationalRetirementSchemeOrdinances:
    "retirementAssets.occupationalRetirementSchemeOrdinances",
  superannuationFunds: "retirementAssets.superannuationFunds",
  employeeProvidenceFunds: "retirementAssets.employeeProvidenceFunds",
  privateRetirementSchemes: "retirementAssets.privateRetirementSchemes",
  malaysianOtherPensionFunds: "retirementAssets.malaysianOtherPensionFunds",
  centralProvidenceFundInformation: "retirementAssets.centralProvidenceFund",
  suplementaryRetirementSchemeInformation:
    "retirementAssets.mandatoryProvidentFunds",
  pensionInformation: "retirementAssets.mandatoryProvidentFunds",

  // GEAL
  australianGEAL: "geal.australiaGovernmentEducationAssistanceLoans",
  singaporeGEAL: "geal.singaporeGovernmentEducationAssistanceLoans",
  hongkongGEAL: "geal.hongkongGovernmentEducationAssistanceLoans",
  newZealandGEAL: "geal.newZealandGovernmentEducationAssistanceLoans",
  malaysiaGEAL: "geal.malaysiaGovernmentEducationAssistanceLoans",

  // Liabilities
  creditCards: "liabilities.creditCards",
  personalLoans: "liabilities.personalLoans",
  educationLoans: "liabilities.educationLoans",

  personalTax: "taxation.personalTax",
  employmentTerminationPayments:
    "employmentTerminationPayments.employmentTerminationPayments"
}

export const NetWorthFormPropsComposer = (state, modalName) => {
  const {
    employeeMandatoryContribution,
    employerMandatoryContribution,
    salaries
  } = state.netWorth
  const { client, partner, hasPartner: isJoint } = state.entity
  const { model, data } = state.netWorthForm
  const { activeAccessLevel } = state.appSettings
  const { optionsData } = state.enumOptions

  return {
    isShowForm: state.modalWindow[modalName],
    isJoint,
    client,
    partner,
    model: model || {},
    data,
    activeAccessLevel,
    employeeMandatoryContribution,
    employerMandatoryContribution,
    optionsData,
    salaries
  }
}
