import React, { useContext } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody
} from "react-accessible-accordion";
import intl from "react-intl-universal";
import { FormSpy } from "react-final-form";
import { AppContext } from "../../../../context/AppContext";
import CashFlowHeaderListing from "../../CashFlowHeaderListing";
import {
    BusinessOwnershipForm,
} from "../../../../forms";
import CashFlowListingGroup from "../../CashFlowListingGroup";
import api from "../../../../lib/api";
import Get from "../../../../helpers/NullHelper";


const PersonalBusinessExpenses = ({
    varPrefix,
    clientTotalValue,
    partnerTotalValue,
    jointTotalValue,
    optionsData,
    readOnlyAccessComponent,
    clientInformation,
    currency,
    onExternalUpdated,
    isJoint
}) => {
    const {
        modal: { setModal, clearModal }
    } = useContext(AppContext);

    const handleSave = values => {
        // save the model
        api.put(values.url, values).then(() => {
            onExternalUpdated(true);
        });

        clearModal();
    };

    
    const showModalByUrl = model => {
        const modalType = model.url.split("/")[3];
        switch (modalType) {
           
            case "business_ownerships":
                return {
                    title: intl.get("businessOwnerships.title"),
                    content: (
                        <BusinessOwnershipForm
                            handleSave={handleSave}
                            model={model}
                            readOnlyAccess={readOnlyAccessComponent()}
                            clientInformation={clientInformation}
                            isJoint={isJoint}
                            optionsData={optionsData}
                        />
                    ),
                    formType: "personalBusiness"
                };
            default:
                return null;
        }
    };
    return (
        <FormSpy subscription={{ values: true }}>
            {({ values }) => (
                <div className="portal-part">
                    <Accordion accordion={false}>
                        <AccordionItem>
                            <AccordionItemTitle>
                                <CashFlowHeaderListing
                                    title={intl.get(
                                        'cashflowlisting.personalExpenses.personalBusiness'
                                    )}
                                    isAccordion
                                    clientTotalValue={clientTotalValue}
                                    partnerTotalValue={partnerTotalValue}
                                    jointTotalValue={jointTotalValue}
                                    currency={currency}
                                    isJointHide={!isJoint}
                                />
                            </AccordionItemTitle>
                            <AccordionItemBody>
                                {Get(values, "detailResult") && (
                                    <CashFlowListingGroup
                                        varPrefix={`${varPrefix}`}
                                        dataArray={values.detailResult}
                                        isRoundColorRed
                                        currency={currency}
                                        isJointHide={!isJoint}
                                        isExternalModal
                                        onEditClick={(model, client) =>
                                            setModal(
                                                showModalByUrl(model, client)
                                            )
                                        }
                                    />
                                )}
                            </AccordionItemBody>
                        </AccordionItem>
                    </Accordion>
                </div>
            )}
        </FormSpy>
    );
};

export default PersonalBusinessExpenses;
