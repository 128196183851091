import React from 'react';
import ReactModal from 'react-modal';
import Alert from 'react-s-alert';
import api from '../../lib/api';

import { Form } from 'react-final-form';

import RenderSimpleFields, {
    FieldType,
} from '../../components/Form/RenderSimpleFields';


class ChangePasswordDialog extends React.Component {
    constructor (props) {
      super(props);
      this.loginUser = JSON.parse(localStorage.getItem('user'));

      this.fields = [
            {
                label: 'Current Password',
                name: 'currentPassword',
                type: FieldType.PASSWORD_SIMPLE,
                required: true,
            },
            {
                label: 'New Password',
                name: 'newPassword',
                type: FieldType.PASSWORD_SIMPLE,
                required: true,
            },
            {
                label: 'Confirm Password',
                name: 'confirmPassword',
                type: FieldType.PASSWORD_SIMPLE,
                required: true,
            },
        ];

      this.state = {
        showModal: this.loginUser.firstTimeLogin,
      
        result: null,
      };
      
      this.handleOpenModal = this.handleOpenModal.bind(this);
      this.handleCloseModal = this.handleCloseModal.bind(this);
      this.handleChangePassword = this.handleChangePassword.bind(this);
      
     
    }

    customStyles = {
        content : {
          top                   : '50%',
          left                  : '50%',
          right                 : 'auto',
          bottom                : 'auto',
          
        }
      };

    handleOpenModal () {
      this.setState({ showModal: true });
    }
    
    handleCloseModal () {
        this.setState({ showModal: false });
      
    }

    handleChangePassword (model) {
        let prom;
        if(this.loginUser.selectedEntity.entityType == 'CLIENT') {
            prom = api.put(`/client/${this.loginUser.selectedEntity.id.replace((this.loginUser.selectedEntity.resourceServerId + '-'), '')}/change-password`, model);
        } else {
            prom =  api.put(`/user/${this.loginUser.id}/change-password`, model);
        }
        prom.then((response) => {
            if (response && response.status === 200) {
                Alert.success('Successfully changed your password!');
            }
           this.loginUser.firstTimeLogin = false;  
           localStorage.setItem('user', JSON.stringify(this.loginUser));  
           this.handleCloseModal();
        });
       
        
    }
   
    componentDidMount() {
        
    }
    
    render () {
      return (
        <div>
          
          <ReactModal
            isOpen={this.state.showModal}
            className="portfolio-modal PortfolioHistorySimple-modal"
            overlayClassName="malabar-modal-overlay"
            ariaHideApp={false}
            shouldCloseOnEsc
            style={this.customStyles}
            onRequestClose={this.handleCloseModal}
        >
                <div className="container">
                    
                    <div className="row ">
                        <div className="col-md-12">
                            <div className="page-title">
                                   Change Password
                                <div className="close-holder">
                                    <span
                                        onClick={this.handleCloseModal}
                                        className="close-button"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <Form
                            onSubmit={model => this.handleChangePassword(model)}
                            initialValues={{}}
                            validate={values => {
                                const errors = {};
                                if (values.newPassword && values.confirmPassword) {
                                    if (values.newPassword !== values.confirmPassword) {
                                        errors.confirmPassword = 'Password is different';
                                    }
                                }
                                return errors;
                            }}
                            render={({ handleSubmit, reset }) => (
                                <form
                                    id="changePassword"
                                    onSubmit={event => {
                                        const promise = handleSubmit(event);
                                        if (promise) {
                                            promise.then(response => {
                                                if (response && response.status === 200) {
                                                    reset();
                                                   
                                                }
                                            });
                                        }
                                    }}
                                >
                        <div className="row ">
                            <div className="col-md-1">
                                
                            </div>
                            <div className="col-md-10">
                                <div className="subtitle2">Change Password</div>
                                <RenderSimpleFields fields={this.fields} />
                            </div>
                           
                        </div>
                        <br></br>
                        <div className="row ">
                            <div className="col-md-10">
                                
                            </div>
                            <div className="col-md-2">
                                <button
                                    className="portal-btn portal-btn-submit"
                                    type="submit"
                                >
                                    Change
                                </button>
                            </div>
                        </div>

                    </form>
                    )}
                />
                    
                   
                    
                </div>
            </ReactModal>
        </div>
      );
    }
  }


  export default ChangePasswordDialog;
  
 