import React from "react"
import Loadable from "react-loadable"
import Cpd from "./pages/Compliance/Cpd"

function Loading() {
  return <div>Loading...</div>
}
const MarketingCollaterals = Loadable({
  loader: () => import("./pages/BusinessManagement/MarketingCollaterals.js"),
  loading: Loading
})
const InternalDocuments = Loadable({
  loader: () => import("./pages/Efiles/InternalDocuments"),
  loading: Loading
})
const SharePoint = Loadable({
  loader: () => import("./pages/SharePoint/SharePoint.js"),
  loading: Loading
})
const PersonalDetails = Loadable({
  loader: () => import("./pages/PersonalDetails/PersonalDetails"),
  loading: Loading
})

const NetWorth = Loadable({
  loader: () => import("./pages/NetWorth/NetWorth"),
  loading: Loading
})

const Goal = Loadable({
  loader: () => import("./pages/Goal/Goal"),
  loading: Loading
})

const ClientRiskProfile = Loadable({
  loader: () => import("./pages/RiskProfile/ClientRiskProfile"),
  loading: Loading
})

const ManageAccessLevel = Loadable({
  loader: () => import("./pages/AppSettings/ManageAccessLevel"),
  loading: Loading
})

const TaskManagement = Loadable({
  loader: () => import("./pages/TaskManagement/TaskManagement.js"),
  loading: Loading
})

const ModuleManagement = Loadable({
  loader: () => import("./pages/AppSettings/ModuleManagement"),
  loading: Loading
})
const ManagePackageModules = Loadable({
  loader: () => import("./pages/AppSettings/ManagePackageModules"),
  loading: Loading
})
const investmentSettings = Loadable({
  loader: () => import("./pages/AppSettings/InvestmentSettings"),
  loading: Loading
})
const ReportGenSettings = Loadable({
  loader: () => import("./pages/Compliance/ReportGeneratorSettings"),
  loading: Loading
})
const ReportDocumentApprovalList = Loadable({
  loader: () => import("./pages/Compliance/ReportDocumentApprovalList"),
  loading: Loading
})
const FinancialRatioSettings = Loadable({
  loader: () => import("./pages/Compliance/FinancialRatioSettings.js"),
  loading: Loading
})

const lifeExpectancies = Loadable({
  loader: () => import("./pages/Compliance/LifeExpectancies"),
  loading: Loading
})
const epfRules = Loadable({
  loader: () =>
    import("./pages/BusinessManagement/RetirementSettings/EpfRules.js"),
  loading: Loading
})
const prsRules = Loadable({
  loader: () =>
    import("./pages/BusinessManagement/RetirementSettings/PrsRules.js"),
  loading: Loading
})
const SuperannuationRules = Loadable({
  loader: () =>
    import(
      "./pages/BusinessManagement/RetirementSettings/SuperannuationRules.js"
    ),
  loading: Loading
})
const MpfRules = Loadable({
  loader: () =>
    import("./pages/BusinessManagement/RetirementSettings/MpfRules.js"),
  loading: Loading
})
const financialRegulators = Loadable({
  loader: () => import("./pages/Compliance/FinancialRegulators"),
  loading: Loading
})
const NominatedBrokers = Loadable({
  loader: () => import("./pages/Compliance/NominatedBrokers"),
  loading: Loading
})
const financialAssociations = Loadable({
  loader: () => import("./pages/Compliance/FinancialAssociations"),
  loading: Loading
})
const TaxRules = Loadable({
  loader: () => import("./pages/Tax/TaxRules"),
  loading: Loading
})
const learningCourses = Loadable({
  loader: () => import("./pages/Compliance/LearningCourses"),
  loading: Loading
})
const educationTargets = Loadable({
  loader: () => import("./pages/Compliance/EducationTargets"),
  loading: Loading
})
const SetupCompany = Loadable({
  loader: () => import("./pages/AppSettings/SetupCompany"),
  loading: Loading
})
const ApplicationParameter = Loadable({
  loader: () => import("./pages/AppSettings/AppParameters"),
  loading: Loading
})

const UserManagement = Loadable({
  loader: () => import("./pages/AppSettings/UserManagement"),
  loading: Loading
})
const MenuManagement = Loadable({
  loader: () => import("./pages/AppSettings/MenuManagement"),
  loading: Loading
})
const VersionManagement = Loadable({
  loader: () => import("./pages/AppSettings/VersionManagement"),
  loading: Loading
})
const AIAssistantSetings = Loadable({
  loader: () => import("./pages/AppSettings/AIAssistantSettings"),
  loading: Loading
})
const ManageEnumerations = Loadable({
  loader: () => import("./pages/AppSettings/ManageEnumerations"),
  loading: Loading
})
const LogsMonitoring = Loadable({
  loader: () => import("./pages/AppSettings/LogsMonitoring"),
  loading: Loading
})

const TipsManagement = Loadable({
  loader: () => import("./pages/Tips/TipsManagement"),
  loading: Loading
})

const FeedbacksManagement = Loadable({
  loader: () => import("./pages/Tips/FeedbacksManagement"),
  loading: Loading
})

const Tools = Loadable({
  loader: () => import("./pages/AppSettings/Tools"),
  loading: Loading
})

const GlobalDashboard = Loadable({
  loader: () => import("./pages/Dashboard/GlobalDashboard"),
  loading: Loading
})

const FeedbackLog = Loadable({
  loader: () => import("./pages/Dashboard/Subsidiary/FeedbackLogDashboard"),
  loading: Loading
})

const NoAccessPage = Loadable({
  loader: () => import("./pages/Page404/Page404"),
  loading: Loading
})

const MainDashboard = Loadable({
  loader: () => import("./pages/Dashboard/MainDashboard"),
  loading: Loading
})

const ClientMainDashboardSimple = Loadable({
  loader: () => import("./pages/Dashboard/ClientMainDashboardSimple"),
  loading: Loading
})

const ClientMainDashboardDemo = Loadable({
  loader: () => import("./pages/Dashboard/ClientMainDashboardDemo"),
  loading: Loading
})

const DealerMainDashboard = Loadable({
  loader: () => import("./pages/Dashboard/DealerMainDashboard"),
  loading: Loading
})

const AdviserGroupMainDashboard = Loadable({
  loader: () => import("./pages/Dashboard/AdviserGroupMainDashboard"),
  loading: Loading
})

const SubBranchesMainDashboard = Loadable({
  loader: () => import("./pages/Dashboard/SubBranchesMainDashboard"),
  loading: Loading
})

const ClientDashboard = Loadable({
  loader: () => import("./pages/Dashboard/ClientDashboard"),
  loading: Loading
})

const Client = Loadable({
  loader: () => import("./pages/Client/Client"),
  loading: Loading
})

const CashFlow = Loadable({
  loader: () => import("./pages/CashFlow/CashlflowMockup"),
  loading: Loading
})

const InvestmentBuilder = Loadable({
  loader: () => import("./pages/InvestmentBuilder/InvestmentBuilder"),
  loading: Loading
})

const Ifast = Loadable({
  loader: () => import("./pages/InvestmentBuilder/Ifast"),
  loading: Loading
})

const UnderMaintenance = Loadable({
  loader: () => import("./pages/InvestmentBuilder/UnderMaintenance"),
  loading: Loading
})

const EstatePlanning = Loadable({
  loader: () => import("./pages/EstatePlanning/EstatePlanning"),
  loading: Loading
})

const AdditionalInformation = Loadable({
  loader: () => import("./pages/AdditionalInformation/AdditionalInformation"),
  loading: Loading
})

const TermAndCondition = Loadable({
  loader: () => import("./pages/TermAndCondition/TermAndCondition"),
  loading: Loading
})

const Insurance = Loadable({
  loader: () => import("./pages/Insurance/Insurance"),
  loading: Loading
})

const LifeLine = Loadable({
  loader: () => import("./pages/CurrentStanding/LifeLine"),
  loading: Loading
})

const KeyFinancialRatios = Loadable({
  loader: () => import("./pages/CurrentStanding/KeyFinancialRatios"),
  loading: Loading
})

const DependantEducation = Loadable({
  loader: () => import("./pages/CurrentStanding/DependantEducation"),
  loading: Loading
})

const FireAndContentInsurnaceStatus = Loadable({
  loader: () => import("./pages/CurrentStanding/FireAndContentInsurnaceStatus"),
  loading: Loading
})

const NetWorthCurrentStanding = Loadable({
  loader: () => import("./pages/CurrentStanding/NetWorth"),
  loading: Loading
})

const CashflowCurrentStanding = Loadable({
  loader: () => import("./pages/CurrentStanding/Cashflow"),
  loading: Loading
})

const InvestmentCurrentStanding = Loadable({
  loader: () => import("./pages/CurrentStanding/Investment"),
  loading: Loading
})

const NetWorthDetails = Loadable({
  loader: () => import("./pages/CurrentStanding/NetWorthDetails"),
  loading: Loading
})

const RetirementPosition = Loadable({
  loader: () => import("./pages/CurrentStanding/RetirementPosition"),
  loading: Loading
})

const InsuranceGap = Loadable({
  loader: () => import("./pages/CurrentStanding/InsuranceGap"),
  loading: Loading
})

const Finance = Loadable({
  loader: () => import("./pages/CurrentStanding/Finance"),
  loading: Loading
})

// 20201105 MABT-423
const PersonalTaxCenter = Loadable({
  loader: () => import("./pages/CurrentStanding/PersonalTaxCenter"),
  loading: Loading
})

const LifestyleBuilder = Loadable({
  loader: () => import("./pages/LifestyleBuilder/Scenario"),
  loading: Loading
})

const DocumentReport = Loadable({
  loader: () => import("./pages/Reporting/DocumentReport"),
  loading: Loading
})

const KycCheckClient = Loadable({
  loader: () => import("./pages/Implementation/KycCheckClient"),
  loading: Loading
})

const SendPassword = Loadable({
  loader: () => import("./pages/Implementation/SendPassword"),
  loading: Loading
})

const ImportDocument = Loadable({
  loader: () => import("./pages/Implementation/ImportDocument"),
  loading: Loading
})

const ProductApplication = Loadable({
  loader: () => import("./pages/Implementation/ProductApplication"),
  loading: Loading
})

const EmployementIncomeAndBenefit = Loadable({
  loader: () =>
    import("./pages/CashFlow/PersonalIncomes/EmployementIncomeAndBenefit"),
  loading: Loading
})

const InvestmentPropertyRentalIncomeDetail = Loadable({
  loader: () =>
    import(
      "./pages/CashFlow/PersonalIncomes/InvestmentPropertyRentalIncomeDetail"
    ),
  loading: Loading
})

const BusinessIncomeDetail = Loadable({
  loader: () => import("./pages/CashFlow/PersonalIncomes/BusinessIncomeDetail"),
  loading: Loading
})

const CashAccountDetail = Loadable({
  loader: () => import("./pages/CashFlow/PersonalIncomes/CashAccountDetail"),
  loading: Loading
})

const ShareDetail = Loadable({
  loader: () => import("./pages/CashFlow/PersonalIncomes/ShareDetail"),
  loading: Loading
})

const UnitTrustDetail = Loadable({
  loader: () => import("./pages/CashFlow/PersonalIncomes/UnitTrustDetail"),
  loading: Loading
})
const GenericPortfolioDetail = Loadable({
  loader: () =>
    import("./pages/CashFlow/PersonalIncomes/GenericPortfolioDetail"),
  loading: Loading
})

const BondDetail = Loadable({
  loader: () => import("./pages/CashFlow/PersonalIncomes/BondDetail"),
  loading: Loading
})

const TermDepositDetail = Loadable({
  loader: () => import("./pages/CashFlow/PersonalIncomes/TermDepositDetail"),
  loading: Loading
})

const IlasDetail = Loadable({
  loader: () => import("./pages/CashFlow/PersonalIncomes/IlasDetail"),
  loading: Loading
})

const InvestmentPlatform = Loadable({
  loader: () =>
    import("./pages/CashFlow/PersonalIncomes/InvestmentPlatformDetail"),
  loading: Loading
})

const CarTransportDetail = Loadable({
  loader: () => import("./pages/CashFlow/PersonalExpenses/CarTransportDetail"),
  loading: Loading
})

const HomeExpenseDetail = Loadable({
  loader: () => import("./pages/CashFlow/PersonalExpenses/HomeExpenseDetail"),
  loading: Loading
})

const BillUtilityDetail = Loadable({
  loader: () => import("./pages/CashFlow/PersonalExpenses/BillUtilityDetail"),
  loading: Loading
})

const PersonalCareDetail = Loadable({
  loader: () => import("./pages/CashFlow/PersonalExpenses/PersonalCareDetail"),
  loading: Loading
})

const HealthFitnessDetail = Loadable({
  loader: () => import("./pages/CashFlow/PersonalExpenses/HealthFitnessDetail"),
  loading: Loading
})

const FoodDiningDetail = Loadable({
  loader: () => import("./pages/CashFlow/PersonalExpenses/FoodDiningDetail"),
  loading: Loading
})

const EducationExpenseDetail = Loadable({
  loader: () =>
    import("./pages/CashFlow/PersonalExpenses/EducationExpenseDetail"),
  loading: Loading
})

const ChildrenExpenseDetail = Loadable({
  loader: () =>
    import("./pages/CashFlow/PersonalExpenses/ChildrenExpenseDetail"),
  loading: Loading
})

const ParentExpenseDetail = Loadable({
  loader: () => import("./pages/CashFlow/PersonalExpenses/ParentExpenseDetail"),
  loading: Loading
})

const EntertainmentExpenseDetail = Loadable({
  loader: () =>
    import("./pages/CashFlow/PersonalExpenses/EntertaimentExpenseDetail"),
  loading: Loading
})

const ShoppingExpenseDetail = Loadable({
  loader: () =>
    import("./pages/CashFlow/PersonalExpenses/ShoppingExpenseDetail"),
  loading: Loading
})

const TravelExpenseDetail = Loadable({
  loader: () => import("./pages/CashFlow/PersonalExpenses/TravelExpenseDetail"),
  loading: Loading
})

const OtherInsurancePremiumExpenseDetail = Loadable({
  loader: () =>
    import("./pages/CashFlow/PersonalExpenses/OtherInsuranceExpenseDetail"),
  loading: Loading
})

const PetExpenseDetail = Loadable({
  loader: () => import("./pages/CashFlow/PersonalExpenses/PetExpensesDetail"),
  loading: Loading
})
const PersonalBusinessExpenseDetail = Loadable({
  loader: () =>
    import("./pages/CashFlow/PersonalExpenses/PersonalBusinessExpense"),
  loading: Loading
})

const GiftDonationsExpenseDetail = Loadable({
  loader: () =>
    import("./pages/CashFlow/PersonalExpenses/GiftDonationExpenseDetail"),
  loading: Loading
})

const PersonalFinanceExpenseDetail = Loadable({
  loader: () =>
    import("./pages/CashFlow/PersonalExpenses/PersonalFinanceExpenseDetail"),
  loading: Loading
})

const InvestmentExpensesDetail = Loadable({
  loader: () =>
    import("./pages/CashFlow/PersonalExpenses/InvestmentExpensesDetail"),
  loading: Loading
})

// 20201102 MABT-423
const TaxPaymentDetail = Loadable({
  loader: () => import("./pages/CashFlow/PersonalExpenses/TaxPaymentDetail"),
  loading: Loading
})

const InvestmentPropertyDetail = Loadable({
  loader: () =>
    import("./pages/CashFlow/InvestmentOutflow/InvestmentPropertyDetail"),
  loading: Loading
})
const RegularSavingDetail = Loadable({
  loader: () =>
    import("./pages/CashFlow/InvestmentOutflow/RegularSavingDetail"),
  loading: Loading
})
const fxRates = Loadable({
  loader: () => import("./pages/FXRates/FXRates"),
  loading: Loading
})

const lobbyMenu = Loadable({
  loader: () => import("./pages/ClientMenu/Lobby"),
  loading: Loading
})

const businessManagementDashboardMenu = Loadable({
  loader: () => import("./pages/Dashboard/BusinessManagementDashboard"),
  loading: Loading
})
const teamStructure = Loadable({
  loader: () => import("./pages/TeamManagement/TeamStructureTable"),
  loading: Loading
})
const reportManagement = Loadable({
  loader: () => import("./pages/BusinessManagement/ReportManagement"),
  loading: Loading
})

const singleAdviceBuilder = Loadable({
  loader: () => import("./pages/Recommendation/SingleAdviceBuilder"),
  loading: Loading
})

const recommendationHistory = Loadable({
  loader: () => import("./pages/Recommendation/RecommendationHistory"),
  loading: Loading
})

const PersonalDealings = Loadable({
  loader: () => import("./pages/Compliance/PersonalDealings"),
  loading: Loading
})

const DealershipRiskProfileSettings = Loadable({
  loader: () => import("./pages/Compliance/DealershipRiskProfileSettings"),
  loading: Loading
})

const DealershipAssetRiskClassificationSettings = Loadable({
  loader: () =>
    import("./pages/Compliance/DealershipAssetRiskClassificationSettings"),
  loading: Loading
})

const KycChainAdmin = Loadable({
  loader: () => import("./pages/Compliance/KycChainAdmin"),
  loading: Loading
})

const KycChainDealershipAdmin = Loadable({
  loader: () => import("./pages/Compliance/KycChainDealershipAdmin.js"),
  loading: Loading
})

const IBConfiguration = Loadable({
  loader: () => import("./pages/Compliance/IBConfiguration"),
  loading: Loading
})

const SaxoConfiguration = Loadable({
  loader: () => import("./pages/Compliance/SaxoConfiguration"),
  loading: Loading
})
const EmailConfiguration = Loadable({
  loader: () => import("./pages/Compliance/EmailConfiguration"),
  loading: Loading
})
const IfastConfiguration = Loadable({
  loader: () => import("./pages/Compliance/IfastConfiguration"),
  loading: Loading
})

const SharePointConfiguration = Loadable({
  loader: () => import("./pages/Compliance/SharePointConfiguration"),
  loading: Loading
})

const Research = Loadable({
  loader: () => import("./pages/BusinessManagement/Learning/Research"),
  loading: Loading
})
const OtherTraining = Loadable({
  loader: () => import("./pages/BusinessManagement/Learning/OtherTraining"),
  loading: Loading
})
const ProductManagementApproval = Loadable({
  loader: () => import("./pages/BusinessManagement/ProductManagement"),
  loading: Loading
})
const routes = [
  { path: "/client/:id?", name: "Client", component: Client, isClient: true },
  {
    path: "/personaldetails/:id?",
    name: "Personal Detail",
    component: PersonalDetails,
    componentName: "PersonalDetails",
    isClient: true
  },
  {
    path: "/efiles/internal-documents/:id",
    name: "eFiles – Company Internal Files",
    component: InternalDocuments,
    componentName: "InternalDocuments",
    isClient: true
  },
  {
    path: "/networth/:id",
    name: "Net Worth",
    component: NetWorth,
    componentName: "NetWorth",
    isClient: true
  },
  {
    path: "/goals/:id",
    name: "Goals",
    component: Goal,
    componentName: "Goal",
    isClient: true
  },

  {
    path: "/clientriskprofile/:id",
    name: "Client Risk Profile",
    component: ClientRiskProfile,
    componentName: "ClientRiskProfile",
    isClient: true
  },
  {
    path: "/manageaccesslevel/:entityId",
    name: "Manage Access Level",
    component: ManageAccessLevel,
    componentName: "ManageAccessLevel"
  },
  {
    path: "/taskmanagement",
    name: "Task Management",
    component: TaskManagement,
    componentName: "TaskManagement"
  },
  {
    path: "/usermanagement",
    name: "User Management",
    component: UserManagement,
    componentName: "UserManagement"
  },
  {
    path: "/appparams",
    name: "Application Parameter",
    component: ApplicationParameter,
    componentName: "Application Parameter"
  },
  {
    path: "/menumanagement",
    name: "Menu Management",
    component: MenuManagement,
    componentName: "MenuManagement"
  },
  {
    path: "/versionmanagement",
    name: "Version Management",
    component: VersionManagement,
    componentName: "VersionManagement"
  },
  {
    path: "/aiassistantsettings",
    name: "AI Assistant Settings",
    component: AIAssistantSetings,
    componentName: "AIAssistantSetings"
  },
  {
    path: "/manageenums",
    name: "Manage Enumerations",
    component: ManageEnumerations,
    componentName: "ManageEnumerations"
  },
  {
    path: "/logsmonitoring",
    name: "Logs Monitoring",
    component: LogsMonitoring,
    componentName: "LogsMonitoring"
  },
  {
    path: "/modulemanagement",
    name: "Module Management",
    component: ModuleManagement,
    componentName: "ModuleManagement"
  },
  {
    path: "/managepackagemodules",
    name: "Manage Package Modules",
    component: ManagePackageModules,
    componentName: "ManagePackageModules"
  },
  {
    path: "/setupcompany",
    name: "Setup New Company",
    component: SetupCompany,
    componentName: "SetupCompany"
  },
  {
    path: "/investmentsettings",
    name: "Investment Settings",
    component: investmentSettings,
    componentName: "InvestmentSettings"
  },
  {
    path: "/reportgensettings",
    name: "Report Generator Settings",
    component: ReportGenSettings,
    componentName: "ReportGenSettings"
  },
  {
    path: "/reportapprovallist",
    name: "ReportDocumentApprovalList",
    component: ReportDocumentApprovalList,
    componentName: "ReportDocumentApprovalList"
  },
  {
    path: "/finratiosettings",
    name: "Financial Ratio Settings",
    component: FinancialRatioSettings,
    componentName: "FinancialRatioSettings"
  },
  {
    path: "/lifeexpectancies",
    name: "Life Expectancies",
    component: lifeExpectancies,
    componentName: "LifeExpectancies"
  },
  {
    path: "/epfrules",
    name: "EPF Rules",
    component: epfRules,
    componentName: "EpfRules"
  },
  {
    path: "/prsrules",
    name: "PRS Rules",
    component: prsRules,
    componentName: "PrsRules"
  },
  {
    path: "/superannuationrules",
    name: "Superannuation Rules",
    component: SuperannuationRules,
    componentName: "SuperannuationRules"
  },
  {
    path: "/mpfrules",
    name: "MPF Rules",
    component: MpfRules,
    componentName: "MpfRules"
  },
  {
    path: "/financialregulators",
    name: "Financial Regulators",
    component: financialRegulators,
    componentName: "FinancialRegulators"
  },
  {
    path: "/nominatedbrokers",
    name: "Nominated Brokers",
    component: NominatedBrokers,
    componentName: "NominatedBrokers"
  },
  {
    path: "/financialassociations",
    name: "Financial Associations",
    component: financialAssociations,
    componentName: "FinancialAssociations"
  },
  {
    path: "/learningcourses",
    name: "Learning Courses",
    component: learningCourses,
    componentName: "LearningCourses"
  },
  {
    path: "/educationtargets",
    name: "Education Targets",
    component: educationTargets,
    componentName: "EducationTargets"
  },
  {
    path: "/tools",
    name: "Administration Tools",
    component: Tools,
    componentName: "Tools"
  },
  {
    path: "/managetips",
    name: "Tips Management",
    component: TipsManagement,
    componentName: "TipsManagement"
  },
  {
    path: "/managefeedbacks",
    name: "Feedbacks Management",
    component: FeedbacksManagement,
    componentName: "FeedbacksManagement"
  },
  {
    path: "/insurance/:id",
    name: "Insurance",
    component: Insurance,
    isClient: true
  },
  {
    path: "/lifeline/:id",
    name: "LifeLine",
    component: LifeLine,
    isClient: true
  },
  {
    path: "/financialratios/:id",
    name: "KeyFinancialRatios",
    component: KeyFinancialRatios,
    isClient: true
  },
  {
    path: "/dependanteducation/:id",
    name: "DependantEducation",
    component: DependantEducation,
    isClient: true
  },
  {
    path: "/personaltaxcenter/:id",
    name: "PersonalTaxCenter",
    component: PersonalTaxCenter,
    isClient: true
  },
  {
    path: "/fireandcontentinsurnacestatus",
    name: "FireAndContentInsurnaceStatus",
    component: FireAndContentInsurnaceStatus,
    isClient: true
  },
  {
    path: "/networthcs/:id",
    name: "NetWorthCurrentStanding",
    component: NetWorthCurrentStanding,
    isClient: true
  },
  {
    path: "/cashflowcs/:id",
    name: "CashflowCurrentStanding",
    component: CashflowCurrentStanding,
    isClient: true,
    exact: true
  },
  {
    path: "/cashflow/:id/EmployementIncomeAndBenefit",
    name: "EmployementIncomeAndBenefit",
    component: EmployementIncomeAndBenefit,
    isClient: true,
    exact: true
  },
  {
    path: "/cashflow/:id/InvestmentPropertyRentalIncomes",
    name: "InvestmentPropertyRentalIncomeDetail",
    component: InvestmentPropertyRentalIncomeDetail,
    isClient: true,
    exact: true
  },
  {
    path: "/cashflow/:id/BusinessIncomes",
    name: "BusinessIncomeDetail",
    component: BusinessIncomeDetail,
    isClient: true,
    exact: true
  },
  {
    path: "/cashflow/:id/CashAccounts",
    name: "InvestmentPropertyRentalIncomeDetail",
    component: CashAccountDetail,
    isClient: true,
    exact: true
  },
  {
    path: "/cashflow/:id/TermDeposits",
    name: "TermDepositDetail",
    component: TermDepositDetail,
    isClient: true,
    exact: true
  },
  {
    path: "/cashflow/:id/Shares",
    name: "ShareDetail",
    component: ShareDetail,
    isClient: true,
    exact: true
  },
  {
    path: "/sharepoint/:id",
    name: "SharePoint",
    component: SharePoint,
    isClient: true,
    exact: true
  },
  {
    path: "/cashflow/:id/UnitTrusts",
    name: "UnitTrustDetail",
    component: UnitTrustDetail,
    isClient: true,
    exact: true
  },
  {
    path: "/cashflow/:id/GenericPortfolios",
    name: "GenericPortfolioDetail",
    component: GenericPortfolioDetail,
    isClient: true,
    exact: true
  },
  {
    path: "/cashflow/:id/Ilass",
    name: "IlasDetail",
    component: IlasDetail,
    isClient: true,
    exact: true
  },
  {
    path: "/cashflow/:id/InvestmentPlatforms",
    name: "InvestmentPlatformDeetail",
    component: InvestmentPlatform,
    isClient: true,
    exact: true
  },
  {
    path: "/cashflow/:id/Bonds",
    name: "BondDetail",
    component: BondDetail,
    isClient: true,
    exact: true
  },
  {
    path: "/cashflow/:id/CarTransports",
    name: "CarTransports",
    component: CarTransportDetail,
    isClient: true,
    exact: true
  },
  {
    path: "/cashflow/:id/HomeExpenses",
    name: "HomeExpenses",
    component: HomeExpenseDetail,
    isClient: true,
    exact: true
  },
  {
    path: "/cashflow/:id/BillUtilities",
    name: "BillUtilities",
    component: BillUtilityDetail,
    isClient: true,
    exact: true
  },
  {
    path: "/cashflow/:id/PersonalCares",
    name: "PersonalCares",
    component: PersonalCareDetail,
    isClient: true,
    exact: true
  },
  {
    path: "/cashflow/:id/HealthFitness",
    name: "HealthFitness",
    component: HealthFitnessDetail,
    isClient: true,
    exact: true
  },
  {
    path: "/cashflow/:id/FoodDining",
    name: "FoodDining",
    component: FoodDiningDetail,
    isClient: true,
    exact: true
  },
  {
    path: "/cashflow/:id/EducationExpenses",
    name: "EducationExpenses",
    component: EducationExpenseDetail,
    isClient: true,
    exact: true
  },
  {
    path: "/cashflow/:id/ChildrenExpenses",
    name: "ChildrenExpenses",
    component: ChildrenExpenseDetail,
    isClient: true,
    exact: true
  },
  {
    path: "/cashflow/:id/ParentsExpenses",
    name: "ParentsExpenses",
    component: ParentExpenseDetail,
    isClient: true,
    exact: true
  },
  {
    path: "/cashflow/:id/Entertainment",
    name: "Entertainment",
    component: EntertainmentExpenseDetail,
    isClient: true,
    exact: true
  },
  {
    path: "/cashflow/:id/TravelExpenses",
    name: "TravelExpenses",
    component: TravelExpenseDetail,
    isClient: true,
    exact: true
  },
  {
    path: "/cashflow/:id/OtherInsurancePremium",
    name: "OtherInsurancePremium",
    component: OtherInsurancePremiumExpenseDetail,
    isClient: true,
    exact: true
  },
  {
    path: "/cashflow/:id/PetExpenses",
    name: "PetExpenses",
    component: PetExpenseDetail,
    isClient: true,
    exact: true
  },
  {
    path: "/cashflow/:id/PersonalFinanceExpenses",
    name: "PersonalFinanceExpenses",
    component: PersonalFinanceExpenseDetail,
    isClient: true,
    exact: true
  },
  {
    path: "/cashflow/:id/InvestmentExpenses",
    name: "InvestmentExpenses",
    component: InvestmentExpensesDetail,
    isClient: true,
    exact: true
  },
  // 20201102 MABT-423
  {
    path: "/cashflow/:id/TaxPayments",
    name: "TaxPayments",
    component: TaxPaymentDetail,
    isClient: true,
    exact: true
  },
  {
    path: "/cashflow/:id/GiftDonations",
    name: "GiftDonations",
    component: GiftDonationsExpenseDetail,
    isClient: true,
    exact: true
  },
  {
    path: "/cashflow/:id/PersonalBusinessExpenses",
    name: "PersonalBusiness",
    component: PersonalBusinessExpenseDetail,
    isClient: true,
    exact: true
  },
  {
    path: "/cashflow/:id/ShoppingExpenses",
    name: "ShoppingExpenses",
    component: ShoppingExpenseDetail,
    isClient: true,
    exact: true
  },
  {
    path: "/cashflow/:id/InvestmentProperty",
    name: "ShoppingExpenses",
    component: InvestmentPropertyDetail,
    isClient: true
  },
  {
    path: "/cashflow/:id/RegularSaving",
    name: "ShoppingExpenses",
    component: RegularSavingDetail,
    isClient: true
  },
  {
    path: "/investmentcs/:id",
    name: "InvestmentCurrentStanding",
    component: InvestmentCurrentStanding,
    isClient: true
  },
  {
    path: "/networthcsdetails",
    name: "NetWorthDetails",
    component: NetWorthDetails,
    isClient: true
  },
  {
    path: "/retirement/:id",
    name: "RetirementPosition",
    component: RetirementPosition,
    isClient: true
  },
  {
    path: "/insurancegap/:id",
    name: "InsuranceGap",
    component: InsuranceGap,
    isClient: true
  },
  {
    path: "/finance/:id",
    name: "Finance",
    component: Finance,
    isClient: true
  },
  {
    path: "/cashflow/:id",
    name: "Cash Flow",
    component: CashFlow,
    isClient: true
  },
  {
    path: "/estateplanning/:id",
    name: "Estate Planning",
    component: EstatePlanning,
    isClient: true
  },
  {
    path: "/additionalinformation/:id",
    name: "Additional Information",
    component: AdditionalInformation,
    isClient: true
  },
  {
    path: "/termandcondition/:id",
    name: "Term And Conditions",
    component: TermAndCondition,
    isClient: true
  },

  {
    path: "/reportdocument/:id",
    name: "Scenario",
    component: DocumentReport,
    isClient: true
  },
  {
    path: "/create-login/:id",
    name: "Scenario",
    component: SendPassword,
    isClient: true
  },
  {
    path: "/create-document/:id",
    name: "Scenario",
    component: ImportDocument,
    isClient: true
  },
  {
    path: "/ekyc-chain/:id",
    name: "Kyc Check Client",
    component: KycCheckClient,
    isClient: true
  },

  {
    path: "/product-application/:id",
    name: "Scenario",
    component: ProductApplication,
    isClient: true
  },
  {
    path: "/lifestylebuilder/:id",
    name: "Scenario",
    component: LifestyleBuilder,
    isClient: true
  },

  // {
  //   path: "/investmentbuilder/:id",
  //   name: "Investment Builder",
  //   component: InvestmentBuilder,
  //   isClient: true
  // },
  {
    path: "/ib/:id",
    name: "Interactive Broker",
    component: InvestmentBuilder,
    isClient: true
  },
  {
    path: "/saxo/:id",
    name: "Saxo",
    component: InvestmentBuilder,
    isClient: true
  },
  {
    path: "/ifast/:id",
    name: "Saxo",
    component: InvestmentBuilder,
    isClient: true
  },

  {
    path: "/undermaintenance/:title",
    name: "Under Maintenance",
    component: UnderMaintenance,
    isClient: true
  },

  {
    path: "/ifasts/:id",
    name: "IFast",
    component: Ifast,
    isClient: true
  },

  {
    path: "/dashboard/subsidiaries",
    name: "Subsidiaries Dashboard",
    component: MainDashboard
  },
  {
    path: "/dashboard/clients",
    name: "Clients Dashboard",
    component: ClientMainDashboardSimple
  },
  {
    path: "/dashboard/clientsDashboard",
    name: "Clients Dashboard",
    component: ClientDashboard
  },
  {
    path: "/dashboard/dealerships",
    name: "Dealerships Dashboard",
    component: DealerMainDashboard
  },
  {
    path: "/dashboard/advisergroups",
    name: "Dashboard Adviser Groups",
    component: AdviserGroupMainDashboard
  },
  {
    path: "/dashboard/subbranches",
    name: "Dashboard Sub-Branches",
    component: SubBranchesMainDashboard
  },
  {
    path: "/dashboard/advisers",
    name: "Dashboard Advisers",
    component: MainDashboard
  },
  {
    path: "/demo/clients",
    name: "Dashboard Demo Clients",
    component: ClientMainDashboardDemo
  },
  // end summary dashboard
  // detail dashboard
  {
    path: "/dashboard/detail",
    name: "Dashboard Detail",
    component: MainDashboard
  },
  // end detail dashboard
  {
    path: "/dashboard/global",
    name: "Dashboard Global",
    component: GlobalDashboard
  },
  {
    path: "/dashboard/feedbacklog",
    name: "Feedback Log",
    component: FeedbackLog
  },
  {
    path: "/notfound",
    name: "Page 403",
    component: NoAccessPage
  },
  {
    path: "/fxRates",
    name: "FX Rates",
    component: fxRates
  },
  {
    path: "/lobby",
    name: "Lobby",
    component: lobbyMenu
  },
  {
    path: "/businessManagement",
    name: "Business Management",
    component: businessManagementDashboardMenu
  },
  {
    path: "/teamstructure",
    name: "Team Structure",
    component: teamStructure
  },
  {
    path: "/reportmanagement",
    name: "Report Management",
    component: reportManagement
  },

  {
    path: "/recommendation/singleAdviceBuilder/:id",
    name: "Single Advice Builder",
    component: singleAdviceBuilder,
    isClient: true,
    exact: true
  },
  {
    path: "/recommendation/recommendationHistory/:id",
    name: "Recommendation History",
    component: recommendationHistory,
    isClient: true,
    exact: true
  },
  {
    path: "/personaldealings",
    name: "Securities And Approvals",
    component: PersonalDealings,
    exact: true
  },
  {
    path: "/riskprofilesettings",
    name: "Risk Profile Settings",
    component: DealershipRiskProfileSettings,
    exact: true
  },
  {
    path: "/assetriskclassification",
    name: "Asset Risk Classification Settings",
    component: DealershipAssetRiskClassificationSettings,
    exact: true
  },
  {
    path: "/kyccadmin",
    name: "Kyc Chain Admin",
    component: KycChainAdmin,
    exact: true
  },
  {
    path: "/kyccdealershipadmin",
    name: "Kyc Chain Dealership Admin",
    component: KycChainDealershipAdmin,
    exact: true
  },

  {
    path: "/ibconfig",
    name: "IB configuration",
    component: IBConfiguration,
    exact: true
  },

  {
    path: "/saxoconfig",
    name: "Saxo configuration",
    component: SaxoConfiguration,
    exact: true
  },
  {
    path: "/emailconfig",
    name: "Email configuration",
    component: EmailConfiguration,
    exact: true
  },
  {
    path: "/sharepointconfig",
    name: "SharePointConfiguration",
    component: SharePointConfiguration,
    exact: true
  },

  {
    path: "/ifastconfig",
    name: "Ifast Configuration",
    component: IfastConfiguration,
    exact: true
  },

  {
    path: "/cpd",
    name: "CPD",
    component: Cpd,
    exact: true
  },
  {
    path: "/research",
    name: "Research",
    component: Research,
    exact: true
  },
  {
    path: "/othertrainings",
    name: "Research",
    component: OtherTraining,
    exact: true
  },

  {
    path: "/marketingcollaterals",
    name: "Marketing Collaterals",
    component: MarketingCollaterals,
    exact: true
  },
  {
    path: "/taxrules",
    name: "Tax Rules",
    component: TaxRules,
    isClient: false,
    exact: true
  },
  {
    name: "Product Management Approval",
    component: ProductManagementApproval,
    isClient: false,
    exact: true,
    path: "/productmanagementapproval"
  }
]

export default routes
