import React from 'react';
import OverviewBox from './OverviewBox';


const getColorFromStatus = status => {
    switch (status) {
        case 'POSITIVE':
            return 'green';
        case 'NEGATIVE':
            return 'red';

        default:
            return null;
    }
};

const GroupedOverviewBoxes = ({
    overviews,
    selected,
    noPanel = false,
    feedbacksTotal,
    entityType,
    onClickCategory,
}) => {
    const overviewComponents = overviews.map((overview, index) => (
        <OverviewBox
            key={index}
            title={overview.title}
            figure={overview.value}
            timing={overview.note}
            color={getColorFromStatus(overview.status)}
            selected={overview.title === selected}
            link={'/dashboard/feedbackLog'}
            entityType={entityType}
            onClickCategory={onClickCategory}
        />
    ));

    if (!noPanel) {
        return <div className="overview-panel">{overviewComponents}</div>;
    }

    return <React.Fragment>{overviewComponents}</React.Fragment>;
};

export default GroupedOverviewBoxes;
