import React from "react"

const OverviewBox = ({
  title,
  figure,
  timing,
  color = "",
  selected = false,
  link,
  entityType,
  onClickCategory
}) => (
  // change state based on category
  <div
    // to={entityType == "CLIENT" ? "/dashboard/clientsDashboard" : link}
    className={`overview-box overview-box-${title
      .toLowerCase()
      .replace(" ", "-")} ${selected ? "selected" : ""}`}
    onClick={() => (onClickCategory ? onClickCategory(title) : () => {})}
  >
    <div className="title">{title}</div>
    <div className="figure">{figure}</div>
    <div className="timing">{timing}</div>
  </div>
)

export default OverviewBox
