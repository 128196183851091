import React from "react"
import intl from "react-intl-universal"
import NumberFormat from "react-number-format"
import { getSymbolFromCode, getNewCurrencyValue } from "../../helpers"
import { DECIMAL_SCALE } from "../../constants/NumberFormatConstants"
import { LoanFrequencyEnum } from "../../constants/Enums"
import LoanRepaymentAmountCalculation from "../../services/LoanRepaymentAmountCalculation"

const SummaryExpenses = ({
  disabled,
  optionsData,
  model,
  totalPropertyExpenses,
  currencyItem
}) => {
  const totalIs = (values) => {
    return (
      grandTotal(values) +
      totalMortgageAssociatedCharges(values) +
      totalInsuranceAmount(values)
    )
  }
  const totalMortgageAssociatedCharges = (values) => {
    // return 0;
    return (
      LoanRepaymentAmountCalculation(model.mortgage) +
      parseFloat(subtotalFinancePayments(values))
    )
  }
  const currency = {
    name: currencyItem,
    symbol: getSymbolFromCode(currencyItem)
  }

  const formDefinition = [
    { name: "bodyCorporateFees" },
    { name: "repairs" },
    { name: "propertyTaxes" },
    { name: "furnishings" },
    { name: "improvement" },
    { name: "services" },
    { name: "supplies" },
    { name: "lawnAndGarden" }
  ]

  const grandTotal = (values) => {
    let firstValuetoAdd = parseFloat(
      parseFloat(countValues(values)) +
        (values.propertyExpenses && values.propertyExpenses.propertyLandTax
          ? parseFloat(values.propertyExpenses.propertyLandTax)
          : 0)
    )
    let secondValueToAdd = 0
    let total = firstValuetoAdd + secondValueToAdd
    // +
    // insurancePremiumSubtotal(values);

    return total
  }

  function mortgageInsuranceConversion(values) {
    let mortgageInsurance = 0
    if (
      values.mortgageInsurance &&
      values.mortgageInsurance.premiumMonthly &&
      values.mortgageInsurance.policyPremiumFrequency !== "ONE_OFF"
    ) {
      mortgageInsurance = getNewCurrencyValue(
        values.mortgageInsurance.premiumMonthly,
        currency.name
      )
    }
    return mortgageInsurance
  }
  function mortgageInsuranceConversionOneOff(values) {
    let mortgageInsurance = 0
    if (
      values.mortgageInsurance &&
      values.mortgageInsurance.premiumMonthly &&
      values.mortgageInsurance.policyPremiumFrequency === "ONE_OFF"
    ) {
      mortgageInsurance = getNewCurrencyValue(
        values.mortgageInsurance.premiumMonthly,
        currency.name
      )
    }
    return mortgageInsurance
  }
  function fireAndContentInsuranceConversionOneoff(values) {
    let fireAndContentInsurance = 0
    if (
      values.fireAndContentInsurance &&
      values.fireAndContentInsurance.premiumMonthly &&
      values.fireAndContentInsurance.policyPremiumFrequency === "ONE_OFF"
    ) {
      fireAndContentInsurance = getNewCurrencyValue(
        values.fireAndContentInsurance.premiumMonthly,
        currency.name
      )
    }
    return fireAndContentInsurance
  }
  function fireAndContentInsuranceConversion(values) {
    let fireAndContentInsurance = 0
    if (
      values.fireAndContentInsurance &&
      values.fireAndContentInsurance.premiumMonthly &&
      values.fireAndContentInsurance.policyPremiumFrequency !== "ONE_OFF"
    ) {
      fireAndContentInsurance = getNewCurrencyValue(
        values.fireAndContentInsurance.premiumMonthly,
        currency.name
      )
    }
    return fireAndContentInsurance
  }
  const totalInsuranceAmount = (values) => {
    return (
      fireAndContentInsuranceConversion(values) +
      mortgageInsuranceConversion(values)
    )
  }

  const mortgagePaymentAndInterest = (values) =>
    values.mortgage && (values.mortgage.additionalPaymentAmount || 0)
  const mortgageAssociatedCharges = (values) => {
    if (!values.mortgage) {
      return 0
    }
    let x = 1
    if (
      values.mortgage.borrowingsManagementFeesFrequency ===
      LoanFrequencyEnum.Frequency_FORTNIGHTLY
    ) {
      x = 26 / 12
    }
    let y = 1
    if (
      values.mortgage.additionalRepaymentFrequency ===
      LoanFrequencyEnum.Frequency_FORTNIGHTLY
    ) {
      y = 26 / 12
    }
    return (
      y * +(values.mortgage.additionalRepaymentHandlingFees || 0) +
      x * +(values.mortgage.borrowingsManagementFees || 0)
    )
  }

  const subtotalFinancePayments = (values) =>
    mortgagePaymentAndInterest(values, currency) +
      mortgageAssociatedCharges(values) || 0

  const countValues = (values) => {
    if (values.propertyExpenses) {
      const expenses = formDefinition.map((key) => {
        const value = Number(values.propertyExpenses[key.name])
        return Number.isNaN(value) ? 0 : value
      })

      const otherExpenses = (values.propertyExpenses.others || [])
        .filter((otherExpense) =>
          Object.keys(otherExpense).find((key) => !key.includes("Yearly"))
        )
        .map((otherExpense) => Number(otherExpense.value) || 0)

      return [...expenses, ...otherExpenses].reduce(
        (sum, current) => sum + current,
        0
      )
    }
    return 0
  }
  return (
    <div className="pop-holder2col">
      <div className="portal-form-row gap-10 summary-section content-panel-title-summary">
        <label className="summary-label width-100"></label>
        <div className="title-box test-wrapper">
          {intl.get("field.monthly")}
        </div>
        <div className="title-box test-wrapper">{intl.get("field.yearly")}</div>
        <div className="title-box test-wrapper">One-Off</div>
      </div>

      <div className="portal-form-row">
        <label className="subtitlelabel">
          {"Total Ongoing Property Expenses"}
        </label>
      </div>
      <div className="portal-form-row gap-10">
        <label className="width-100">
          {" "}
          {"Total Ongoing Property Expenses"}
        </label>
        <div className="width-100">
          <NumberFormat
            value={grandTotal(model)}
            displayType={"input"}
            allowNegative
            decimalScale={DECIMAL_SCALE}
            prefix={currency.symbol}
            decimalSeparator={"."}
            thousandSeparator={","}
            className={`text-input currency-input text-input-total`}
            readOnly
          />
        </div>
        <div className="width-100">
          <NumberFormat
            value={grandTotal(model) * 12}
            displayType={"input"}
            allowNegative
            decimalScale={DECIMAL_SCALE}
            prefix={currency.symbol}
            decimalSeparator={"."}
            thousandSeparator={","}
            className={`text-input currency-input text-input-total`}
            readOnly
          />
        </div>
        <div className="width-100"></div>
      </div>
      <div className="portal-form-row">
        <label className="width-100">
          {"Total Mortgage Repayments Amount"}
        </label>
        <div className="width-100">
          <NumberFormat
            value={totalMortgageAssociatedCharges(model)}
            displayType={"input"}
            allowNegative
            decimalScale={DECIMAL_SCALE}
            prefix={currency.symbol}
            decimalSeparator={"."}
            thousandSeparator={","}
            className={`text-input currency-input text-input-total`}
            readOnly
          />
        </div>
        <div className="width-100">
          <NumberFormat
            value={totalMortgageAssociatedCharges(model) * 12}
            displayType={"input"}
            allowNegative
            decimalScale={DECIMAL_SCALE}
            prefix={currency.symbol}
            decimalSeparator={"."}
            thousandSeparator={","}
            className={`text-input currency-input text-input-total`}
            readOnly
          />
        </div>
        <div className="width-100"></div>
      </div>
      <div className="portal-form-row">
        <label className="width-100">{"Insurance Premiums"}</label>
        <div className="width-100">
          <NumberFormat
            value={totalInsuranceAmount(model)}
            displayType={"input"}
            allowNegative
            decimalScale={DECIMAL_SCALE}
            prefix={currency.symbol}
            decimalSeparator={"."}
            thousandSeparator={","}
            className={`text-input currency-input text-input-total`}
            readOnly
          />
        </div>
        <div className="width-100">
          <NumberFormat
            value={totalInsuranceAmount(model) * 12}
            displayType={"input"}
            allowNegative
            decimalScale={DECIMAL_SCALE}
            prefix={currency.symbol}
            decimalSeparator={"."}
            thousandSeparator={","}
            className={`text-input currency-input text-input-total`}
            readOnly
          />
        </div>
        <div className="width-100">
          <NumberFormat
            value={
              fireAndContentInsuranceConversionOneoff(model) +
              mortgageInsuranceConversionOneOff(model)
            }
            displayType={"input"}
            allowNegative
            decimalScale={DECIMAL_SCALE}
            prefix={currency.symbol}
            decimalSeparator={"."}
            thousandSeparator={","}
            className={`text-input currency-input text-input-total`}
            readOnly
          />
        </div>
      </div>
      <div className="portal-form-row">
        <label className="subtitlelabel width-100">
          {"Total Property Expenses"}
        </label>
        <div className="width-100">
          <NumberFormat
            value={totalIs(model)}
            displayType={"input"}
            allowNegative
            decimalScale={DECIMAL_SCALE}
            prefix={currency.symbol}
            decimalSeparator={"."}
            thousandSeparator={","}
            className={`text-input currency-input text-input-total`}
            readOnly
          />
        </div>
        <div className="width-100">
          <NumberFormat
            value={totalIs(model) * 12}
            displayType={"input"}
            allowNegative
            decimalScale={DECIMAL_SCALE}
            prefix={currency.symbol}
            decimalSeparator={"."}
            thousandSeparator={","}
            className={`text-input currency-input text-input-total`}
            readOnly
          />
        </div>
        <div className="width-100">
          <NumberFormat
            value={
              fireAndContentInsuranceConversionOneoff(model) +
              mortgageInsuranceConversionOneOff(model)
            }
            displayType={"input"}
            allowNegative
            decimalScale={DECIMAL_SCALE}
            prefix={currency.symbol}
            decimalSeparator={"."}
            thousandSeparator={","}
            className={`text-input currency-input text-input-total`}
            readOnly
          />
        </div>
      </div>
    </div>
  )
}

export default SummaryExpenses
