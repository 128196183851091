import React from "react"
import intl from "react-intl-universal"
import { Field } from "react-final-form"
import { greaterThan, required, lessThan } from "../../helpers/Validator"

import {
  renderTextBoxCustom,
  renderSelectEnumFieldCustom,
  renderMoneyCustom,
  renderDateFieldCustom,
  composeValidators,
  getSymbolFromCode
} from "../../helpers"

// 20200915
const FireContentAndLoanInsuranceDetails = ({
  optionsData,
  isFireAndContentInsured,
  isLoanInsured,
  varPrefix,
  varPrefix2,
  values,
  defaultCurrency
}) => (
  <div
    className={
      isFireAndContentInsured && isLoanInsured
        ? "pop-holder2col modal-wider"
        : "pop-holder"
    }
  >
    {/* Label Button */}

    <div className="content-panel-title content-panel-title-fireAndContentInsurance d-flex">
      {isFireAndContentInsured && (
        <div className="title-box">
          {intl.get("field.fireAndContentInsurance")}
        </div>
      )}

      {/* Label Button */}
      {isLoanInsured && (
        <div className="title-box">{intl.get("field.loanInsurance")}</div>
      )}
    </div>

    {[
      {
        name: "policyNumber",
        component: renderTextBoxCustom,
        isRequired: true
      },
      {
        name: "policyName",
        component: renderTextBoxCustom,
        isRequired: false
      },
      {
        name: "insuranceOrganization",
        component: renderTextBoxCustom,
        isRequired: false
      },
      {
        name: "managedBy",
        component: renderSelectEnumFieldCustom,
        enumData: optionsData.managedByOptions,
        isRequired: false
      },
      {
        name: "insuredAmount",
        component: renderMoneyCustom,
        isFullWidth: false,
        isRequired: true,
        symbolPrefix: getSymbolFromCode(defaultCurrency),
        defaultCurrency
      },
      {
        name: "policyPremium",
        component: renderMoneyCustom,
        isFullWidth: false,
        isRequired: true,
        symbolPrefix: getSymbolFromCode(defaultCurrency),
        defaultCurrency: "AUD"
      },
      {
        name: "policyPremiumFrequency",
        component: renderSelectEnumFieldCustom,
        enumData: optionsData.frequencyOptions,
        isRequired: true
      },
      {
        name: "policyStartDate",
        component: renderDateFieldCustom,
        isRequired: true
      },
      {
        name: "policyMaturityDate",
        component: renderDateFieldCustom
      }
    ].map((item, index) => (
      <div className={`portal-form-row `} key={index}>
        <label>
          {item.name === "policyNumber"
            ? intl.get(`field.uniqueCode`)
            : intl.get(`field.${item.name}`)}
          {item.isRequired && <span>*</span>}
        </label>
        {isFireAndContentInsured && (
          <>
            {item.isRequired ? (
              <Field
                name={`${varPrefix}.${item.name}`}
                component={item.component}
                enumData={item.enumData}
                noPadding={true}
                isFullWidth={item.isFullWidth}
                validate={composeValidators(
                  required,
                  lessThan(
                    values.fireAndContentInsurance &&
                      values.fireAndContentInsurance.policyMaturityDate
                      ? values.fireAndContentInsurance.policyMaturityDate
                      : null
                  )
                )}
              />
            ) : (
              <Field
                name={`${varPrefix}.${item.name}`}
                component={item.component}
                enumData={item.enumData}
                noPadding={true}
                isFullWidth={item.isFullWidth}
                validate={composeValidators(
                  greaterThan(
                    values.fireAndContentInsurance &&
                      values.fireAndContentInsurance.policyStartDate
                      ? values.fireAndContentInsurance.policyStartDate
                      : null
                  )
                )}
              />
            )}
          </>
        )}
        {isLoanInsured && (
          <>
            {item.isRequired ? (
              <Field
                name={`${varPrefix2}.${item.name}`}
                component={item.component}
                noPadding={true}
                enumData={item.enumData}
                isFullWidth={item.isFullWidth}
                validate={composeValidators(
                  required,
                  lessThan(
                    values.fireAndContentInsurance &&
                      values.fireAndContentInsurance.policyMaturityDate
                      ? values.fireAndContentInsurance.policyMaturityDate
                      : null
                  )
                )}
              />
            ) : (
              <Field
                name={`${varPrefix2}.${item.name}`}
                component={item.component}
                noPadding={true}
                enumData={item.enumData}
                isFullWidth={item.isFullWidth}
                validate={composeValidators(
                  greaterThan(
                    values.fireAndContentInsurance &&
                      values.fireAndContentInsurance.policyStartDate
                      ? values.fireAndContentInsurance.policyStartDate
                      : null
                  )
                )}
              />
            )}
          </>
        )}
      </div>
    ))}
  </div>
)

export default FireContentAndLoanInsuranceDetails
