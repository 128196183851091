import React, { useState, useEffect } from "react"
import intl from "react-intl-universal"
import { Form, FormSpy } from "react-final-form"
import {
  FormButtons,
  ClassificationSelection,
  ModalNoAccess
} from "../components"
import RenderSimpleFields, {
  FieldType
} from "../components/Form/RenderSimpleFields"
import { getSymbolFromCode } from "../helpers"
import { getCurrency } from "../helpers/CurrencyConversion"
import { FrequencyEnum } from "../constants/Enums"
import { NetWorthApi } from "../api"
import Api from "../lib/api"
import ContractDetail from "../pages/InvestmentBuilder/ContractDetail"
import { DECIMAL_SCALE } from "../constants/NumberFormatConstants"

const InstrumentDetailsForm = ({
  handleSave,
  model,
  optionsData,
  readOnlyAccess,
  showClassification = true,
  defaultCurrency = "MYR",
  clientInformation: { client = {}, partner = {}, isJoint },
  subFormModel,
  readOnly,
  values: dataValues
}) => {
  model = subFormModel ? subFormModel : model
  if (!model.rolloverType) {
    model.rolloverType = "P"
  }
  const [currency, setcurrency] = useState(model.accountCurrency || "MYR")

  if (!model.accountCurrency) {
    model.accountCurrency = getCurrency(client.taxResidency)
    setcurrency(model.accountCurrency)
  }

  if (!model.id) {
    model.rollover = true
  }
  if (!model.rolloverType) {
    model.rolloverType = "P"
  }

  if (readOnlyAccess) {
    return <ModalNoAccess />
  }

  function getFilteredFrequencyOptions() {
    let result = []
    let enumData = FrequencyEnum
    Object.keys(enumData).forEach((key) => {
      if (
        enumData[key] !== FrequencyEnum.Frequency_ONE_OFF &&
        enumData[key] !== FrequencyEnum.Frequency_FORTNIGHTLY
      ) {
        result.push(
          <option key={`enum-${key}`} value={enumData[key]}>
            {intl.get(key)}
          </option>
        )
      }
    })
    return result
  }
  const [instrumentsTypes, setInstrumentsTypes] = useState([])
  const getInstrumentsTypes = async () => {
    try {
      const res = await Api.get("/investment-provider/list-asset")
      if (res && res.data && res.data.data.length) {
        setInstrumentsTypes(res.data.data)
      } else {
        setInstrumentsTypes([])
      }
    } catch (error) {
      console.log("error", error)
    }
  }
  useEffect(() => {
    getInstrumentsTypes()
  }, [])

  return (
    <Form
      onSubmit={handleSave}
      initialValues={{
        ...model,
        purchasedDate:
          model && model.purchasedDate
            ? model.purchasedDate.substring(0, 10)
            : null
      }}
      keepDirtyOnReinitialize={true}
      render={({ handleSubmit, reset }) => (
        <form id="termDepositForm" onSubmit={handleSubmit}>
          <FormSpy subscription={{ values: true }}>
            {({ values }) => {
              const fields = [
                {
                  name: "currency",
                  label: intl.get("field.accountCurrency"),
                  type: FieldType.ENUM_SELECTION,
                  enumData: optionsData.currencyOptions,
                  defaultValue: defaultCurrency,
                  onChange: (value) => {
                    setcurrency(value)
                  },
                  readOnly: readOnly,
                  required: true
                },
                {
                  name: "instrumentType",
                  label: "Instrument Type",
                  type: FieldType.SELECTION,
                  readOnly: readOnly,
                  required: true,
                  selectionData: instrumentsTypes,
                  valueProperty: "key",
                  labelProperty: "name"
                },
                {
                  name: "name",
                  label: "Name",
                  type: FieldType.TEXT,
                  readOnly: readOnly,
                  required: true
                },

                {
                  name: "numberOfUnitsPurchased",
                  label: "Number of units purchased",
                  type: FieldType.MONEY,
                  symbolPrefix: "",
                  required: true
                },
                {
                  name: "purchasedPrice",
                  label: "Purchased Price",
                  type: FieldType.MONEY,
                  readOnly: readOnly,
                  required: true
                },
                {
                  name: "currentPrice",
                  label: "Current Price",
                  type: FieldType.MONEY,
                  readOnly: readOnly,
                  required: true
                },
                {
                  name: "purchasedDate",
                  label: "Purchased Date",
                  type: FieldType.DATE,
                  readOnly: readOnly,
                  required: true
                },
                {
                  name: "symbol",
                  label: "Symbol",
                  type: FieldType.TEXT,
                  readOnly: readOnly,
                  required: false
                },

                {
                  name: "transactionId",
                  label: "Transaction Id",
                  type: FieldType.TEXT,
                  readOnly: readOnly,
                  required: false
                }
              ]

              return (
                <div>
                  <div className="portal-form-popup">
                    <ClassificationSelection
                      {...{ client, partner, isJoint }}
                    />

                    {dataValues.verify ? (
                      <div className="my-2">
                        <ContractDetail
                          conid={model.transactionId}
                          symbol={model.symbol}
                          provider={dataValues.investmentProviderName}
                          adviser={dataValues.investmentAdviserNo}
                          purchasePrice={model.purchasedPrice}
                          purchaseDate={new Date(model.purchasedDate).getTime()}
                          secType={model.assetClass || model.secType}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="subtitle2 mt-4">
                      {/* {intl.get("termDeposit.subtitle")} */}
                      Instrument Holding Details
                    </div>
                    <RenderSimpleFields fields={fields} />
                  </div>
                  <FormButtons reset={reset} readOnlyAccess={readOnlyAccess} />
                </div>
              )
            }}
          </FormSpy>
        </form>
      )}
    />
  )
}
export default InstrumentDetailsForm
