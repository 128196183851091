import React, { useEffect, useState, useMemo } from "react"
import intl from "react-intl-universal"
import { Form, FormSpy } from "react-final-form"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import arrayMutators from "final-form-arrays"
import { FormButtons, ModalNoAccess } from "../components"
// sub-forms
import MortgageDetails from "./sub-forms/MortgageDetails"
import CoOwnershipDetails, {
  CoOwnershipDetailsMutators
} from "./sub-forms/CoOwnershipDetails"
import CpfDetails from "./sub-forms/CpfDetails"
import FireContentAndLoanInsuranceDetails from "./sub-forms/FireContentAndLoanInsuranceDetails"
import DeductibleExpenses, {
  DeductibleExpensesMutators
} from "./sub-forms/DeductibleExpenses"
import PropertyAddress from "./sub-forms/PropertyAddress"
import Get from "../helpers/NullHelper"
import InvestmentPropertyMainForm from "./InvestmentPropertyMainForm"
import Api from "../lib/api"
import createDecorator from "final-form-calculate"
import { getCurrency } from "../helpers"
import { useSelector } from "react-redux"
const InvestmentPropertyForm = ({
  handleSave,
  model,
  optionsData,
  readOnlyAccess,
  clientInformation: { client = {}, partner = {}, isJoint = {} },
  currency
}) => {
  const [selectedValue, setSelectedValue] = useState({
    currency: currency,
    value: 0
  })

  // for current estimated value calcualtion
  const [cityList, setCityList] = useState([])
  const [finalValue, setFinalValue] = useState()
  const [currencyValue, setCurrencyValue] = useState()
  const decorator = createDecorator({
    field: "propertyLocation",
    updates: {
      cities: async (fooValue, allValues) => {
        try {
          const data = await Api.get(
            `/analytic/property/city-prices?countryCode=${allValues.propertyLocation}`
          )
          data.data.cityPriceList.length
            ? setCityList(data.data.cityPriceList)
            : setCityList([])
          return data.data.cityPriceList
        } catch (error) {
          console.log("error is", error)
        }
      }
    }
  })
  const { defaultCurrency } = useSelector((state) => state.personalDetails)

  const getDefaultCurrency = (client) => {
    if (client.personalDetails.employmentDetails.taxResidency) {
      return getCurrency(client.personalDetails.employmentDetails.taxResidency)
    }
    return ""
  }
  const formDecorator = useMemo(() => {
    return decorator
  }, [])

  useEffect(() => {
    if (model && model.rentalIncome && model.rentalIncome.value) {
      setSelectedValue(model.rentalIncome)
    }
  }, [model])

  return !readOnlyAccess ? (
    <Form
      onSubmit={(values) => {
        if (values.fireAndContentInsurance) {
          values.fireAndContentInsurance.insuranceType = "CONTENT"
        }
        if (values.mortgageInsurance) {
          values.mortgageInsurance.insuranceType = "LOAN"
        }
        handleSave(values)
      }}
      initialValues={{
        ...model,
        // rentalIncome: selectedValue,
        currentValue: {
          value: finalValue
            ? finalValue
            : model && model && model.currentValue && model.currentValue.value,
          currency: currencyValue ? currencyValue : defaultCurrency
        },
        mortgage: {
          ...model.mortgage,
          interestRates:
            model.mortgage &&
            model.mortgage.interestRates &&
            model.mortgage.interestRates.length
              ? model.mortgage.interestRates
              : [
                  {
                    rateInPercent: "",
                    startDate: "",
                    endDate: ""
                  }
                ]
        }
      }}
      keepDirtyOnReinitialize={true}
      decorators={[formDecorator]}
      mutators={{
        pushArray: arrayMutators.push,
        removeArray: arrayMutators.remove,
        setFormAttribute: ([fieldName, fieldVal], state, { changeValue }) => {
          changeValue(state, fieldName, () => fieldVal)
        },
        adjustMonthlyYearlyValue:
          DeductibleExpensesMutators.adjustMonthlyYearlyValue,
        calculateCoOwnershipPercentage:
          CoOwnershipDetailsMutators.calculateCoOwnershipPercentage,
        ...arrayMutators
      }}
      render={({ handleSubmit, form, reset, values }) => (
        <form id="investmentPropertyForm" onSubmit={handleSubmit}>
          <div className="portal-form-popup">
            <InvestmentPropertyMainForm
              client={client}
              partner={partner}
              isJoint={isJoint}
              optionsData={optionsData}
              currency={currency}
              values={values}
              selectedRentalIncome={selectedValue}
              setSelectedRentalIncome={setSelectedValue}
              cityList={cityList}
              currentCity={values.currentValueCity}
              setFinalValue={setFinalValue}
              setCurrencyValue={setCurrencyValue}
              mutators={form.mutators}
            />

            <FormSpy subscription={{ values: true }}>
              {({ values }) => {
                const {
                  pushArray,
                  removeArray,
                  adjustMonthlyYearlyValue,
                  calculateCoOwnershipPercentage,
                  setFormAttribute
                } = form.mutators
                return (
                  // Right Panel
                  <div className="portal-form-half portal-form-half-right">
                    <Tabs className="InvestmentProperty-Tabs">
                      <TabList>
                        <Tab>{intl.get("tabs.propertyExpenses")}</Tab>
                        <Tab>{intl.get("tabs.propertyAddress")}</Tab>

                        <React.Fragment>
                          {values.propertyUnderMortgage && (
                            <Tab>{intl.get("tabs.mortgageDetails")}</Tab>
                          )}
                          {(values.fireAndContentInsured ||
                            values.mortgageInsured) && (
                            <Tab>{intl.get("tabs.insuranceDetails")}</Tab>
                          )}
                          {values.propertyOwnedWithSomeone && (
                            <Tab>{intl.get("tabs.coOwnershipDetails")}</Tab>
                          )}
                          {values.propertyPurchasedWithCpf && (
                            <Tab>{intl.get("tabs.cpfBorrowings")}</Tab>
                          )}
                        </React.Fragment>
                      </TabList>
                      <TabPanel>
                        <DeductibleExpenses
                          onMonthlyYearlyValueChange={(value, name) =>
                            adjustMonthlyYearlyValue(value, name)
                          }
                          addOtherExpense={pushArray}
                          removeOtherExpense={removeArray}
                          optionsData={optionsData}
                          disabled={readOnlyAccess}
                          model={values}
                          currency={values.currentValue.currency}
                        />
                      </TabPanel>
                      <TabPanel>
                        <PropertyAddress
                          optionsData={optionsData}
                          readOnlyAccess={readOnlyAccess}
                        />
                      </TabPanel>
                      {values.propertyUnderMortgage && (
                        <TabPanel>
                          <MortgageDetails
                            optionsData={optionsData}
                            readOnlyAccess={readOnlyAccess}
                            mutators={form.mutators}
                            model={values}
                            isAustralia={() =>
                              client.personalDetails.employmentDetails
                                .taxResidency === "Australia" || false
                            }
                            clientInformation={{
                              isJoint: client.isJoint,
                              client,
                              partner
                            }}
                          />
                        </TabPanel>
                      )}
                      {(values.fireAndContentInsured ||
                        values.mortgageInsured) && (
                        <TabPanel>
                          <FireContentAndLoanInsuranceDetails
                            optionsData={optionsData}
                            isFireAndContentInsured={
                              values.fireAndContentInsured
                            }
                            isLoanInsured={values.mortgageInsured}
                            varPrefix={"fireAndContentInsurance"}
                            varPrefix2={"mortgageInsurance"}
                            values={values}
                            defaultCurrency={
                              Get(values, "mortgage.borrowingsCurrency") ||
                              "AUD"
                            }
                          />
                        </TabPanel>
                      )}
                      {values.propertyOwnedWithSomeone && (
                        <TabPanel>
                          <CoOwnershipDetails
                            varPrefix={"coOwnership"}
                            names={{
                              client: Get(
                                client,
                                "personalDetails.personalInformation.firstName"
                              ),
                              partner: Get(
                                partner,
                                "personalDetails.personalInformation.firstName"
                              )
                            }}
                            onValueChange={(value, name) =>
                              calculateCoOwnershipPercentage(value, name)
                            }
                          />
                        </TabPanel>
                      )}
                      {values.propertyPurchasedWithCpf && (
                        <TabPanel>
                          <CpfDetails optionsData={optionsData} />
                        </TabPanel>
                      )}
                    </Tabs>
                  </div>
                )
              }}
            </FormSpy>
          </div>
          <FormButtons reset={reset} readOnlyAccess={readOnlyAccess} />
        </form>
      )}
    />
  ) : (
    <ModalNoAccess />
  )
}
export default InvestmentPropertyForm
