import React, { useEffect } from "react"
import { useParams, useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import Api from "../../lib/api"
import {
  toggleFieldsVisibility
} from "../../actions"
import { useDispatch } from "react-redux"
const Layout = ({ children }) => {
  const dispatch = useDispatch()
  const { data } = useSelector((state) => state.personalDetails)
  const { hasAcknowledgedTermsAndConditions } = useSelector(
    (state) => state.entity
  )
  const route = useHistory()
  const { id } = useParams()
  const kycRoutesList = [
    `personaldetails/${id}`,
    `goals/${id}`,
    `clientriskprofile/${id}`,
    `networth/${id}`,
    `insurance/${id}`,
    `cashflow/${id}`,
    `estateplanning/${id}`,
    `additionalinformation/${id}`,
    `termandcondition/${id}`,
    `create-login/${id}`
  ]
  const checkPersonalDetails = async (clientId) => {
    try {
      const res = await Api.get(`/client/personal-details/${clientId}`)
      if (res) {
        let checkForKycRoute = kycRoutesList.find((item) =>
          route.location.pathname.toLowerCase().includes(item.toLowerCase())
        )
        if (!checkForKycRoute) {
          if (!res.data.client.hasAcknowledgedTermsAndConditions) {
            route.push(`/termandcondition/${id}`)
          }
        }
      }
    } catch (error) {
      console.log("error", error)
    }
  }
  useEffect(() => {
    if (id) {
      let checkForKycRoute = kycRoutesList.find((item) =>
        route.location.pathname.toLowerCase().includes(item.toLowerCase())
      )
      if (!checkForKycRoute) {
        checkPersonalDetails(id)
      }
    }
  }, [id, hasAcknowledgedTermsAndConditions])

  useEffect(() => {
    let checkForKycRoute = kycRoutesList.find((item) =>
      route.location.pathname.toLowerCase().includes(item.toLowerCase())
    )
    if (id && !checkForKycRoute) {
      checkPersonalDetails(id)
    }
  }, [id])

  const getMinStatus = async () => {
    try {
      const res = await Api.get(`/client/${id}/minimal-flag`)
      if (res && res.data) {
        dispatch(toggleFieldsVisibility(res.data.data))
      }
    } catch (error) {
      console.log("error", error)
    }
  }
  useEffect(() => {
    if (id) {
      getMinStatus()
    }
  }, [id])

  return <>{children}</>
}

export default Layout
