/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Library : MalabarMonthYearAge
    Description : component specific for showing year & month along with the age
*/

import React, { useState } from 'react';
import moment from 'moment';
import Datetime from 'react-datetime';

const MalabarMonthYearAge = ({
    value,
    name,
    onChange = null,
    disabled,
    birthDate,
    dateFormat = 'MMM YYYY',
    dateFormatResult = 'YYYY-MM-DD',
    readonly = false,
    touched,
    error,
    warning,
}) => {
    const birthDateMoment = moment(birthDate, dateFormatResult);
    const validYear = function(current) {
        // return current.isAfter(moment(birthDateMoment.year() + 1, 'YYYY')); // open this if only year after he was born is active
        // return current.isAfter(moment().add(0, 'y')); // open this if only next year is active
        return current.isAfter(moment());
    };
    const initVal =
        moment(value, dateFormatResult).diff(birthDateMoment, 'years') || null;
    const formatDisabledVal = moment(value).format(dateFormat);
    const [countAge, setCountAge] = useState(initVal !== null ? initVal : 0);

    return (
        <React.Fragment>
            {readonly ? (
                <label>{`${formatDisabledVal} - Age ${countAge}`}</label>
            ) : (
                <React.Fragment>
                    <div className="year-age-input">
                        <Datetime
                            inputProps={{
                                name,
                                className: 'text-input',
                                readOnly: true,
                            }}
                            value={value}
                            className={
                                touched ? (error ? 'is-invalid' : '') : ''
                            }
                            onChange={param => {
                                onChange(moment(param).format(dateFormat));
                                setCountAge(
                                    moment(param, dateFormatResult).diff(
                                        birthDateMoment,
                                        'years'
                                    )
                                );
                            }}
                            dateFormat={dateFormat}
                            isValidDate={validYear}
                        />
                        {touched &&
                            (error && <span className="alert">{error}</span>)}
                    </div>
                    <label className="age-label">{` - Age ${countAge}`}</label>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default MalabarMonthYearAge;
