/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Description : Main Dashboard
*/

import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Pagination from "react-js-pagination";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import {
    enumOptionsActions,
    modalWindowActions,
    appSettingsActions,
    dashboardActions
} from "../../actions";
import { modalWindow } from "../../constants/ModalWindowLists";
import {
    GroupedOverviewBoxes,
    BoxUser,
    TaskUser,
    TableCustom,
    ScrollUpButton
} from "../../components";
import Entity from "../Entity/Entity";
import UserForm from "../User/UserForm";
import { MalabarDashboardRightMenu } from "../../components/MalabarMenu";
import { getJsonFromUrl } from "../../helpers";

import "./MainDashboard.css";


// temporary
const dataPerPage = 18;


const prefixAppUrl =  process.env.REACT_APP_BASE_URL;

export class MainDashboard extends Component {
    state = {
        queryString: "",
        keyword: "",
        selectedLetter: "all",
        isToggleList: false,
        entityId: ""
    };

    componentDidMount() {
        // check if id exist here
        const { pathname } = this.props.location;
        if (this.props.location.search) {
            const paramObj = getJsonFromUrl(this.props.location.search);

            // save active entity id for further usage
            localStorage.setItem("activeEntityId", paramObj.cid);

            this.props.getDashboardDataById(paramObj.cid);
            this.setState({
                entityId: paramObj.cid,
                isClientListData: pathname === "/dashboard/advisers"
            });

            
        } else {
            this.props.setDashboardEntityAndData(this.props.match.path);
            if (pathname === "/dashboard/clients") {
                this.setState({
                    isClientListData: true
                });
            }
        }
        // set dashboard menu not client menu
        this.props.setBurgerMenu(false);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.location.search !== nextProps.location.search) {
            const { pathname } = nextProps.location;

            if (nextProps.location.search) {
                const paramObj = getJsonFromUrl(nextProps.location.search);

                // save active entity id for further usage
                localStorage.setItem("activeEntityId", paramObj.cid);

                this.props.getDashboardDataById(paramObj.cid);
                this.setState({
                    entityId: paramObj.cid,
                    isClientListData: pathname === "/dashboard/advisers"
                });
            } else {
                this.props.setDashboardEntityAndData(nextProps.match.path);
                if (pathname === "/dashboard/clients") {
                    this.setState({
                        isClientListData: true
                    });
                }
            }
        }
    }

    randomNumber = () => Math.floor(Math.random() * Math.floor(1000000));

    newEntityClicked = entityType => {
        if (entityType === undefined) {
            this.props.showModalWindow(modalWindow.USER_MODAL);
        } else {
            this.props.showModalWindowAndValue(
                modalWindow.ENTITY_MODAL,
                entityType
            );
        }
    };

    onClientCategoryChange = clientCategory => {
        this.setState(
            prevState => ({
                ...prevState,
                clientCategory
            }),
            () => {
                if (this.state.selectedLetter) {
                    this.getDataByLetter(this.state.selectedLetter);
                } else if (this.state.keyword) {
                    this.getDataByKeyword(this.state.keyword);
                } else {
                    this.getDataByLetter("all");
                }
            }
        );
    };

    getParsedClientCategory = () => {
        const { clientCategory } = this.state;
        return clientCategory.map(category => category.value).join(",");
    };

    getDataByLetter = selectedLetter => {
        const { getFilteredAndPagedData, dashboardUserData } = this.props;
        let category = "";
        if (this.state.clientCategory) {
            category = `&category=${this.getParsedClientCategory()}`;
        }
        let queryString = "";
        if (selectedLetter !== "all") {
            queryString = `letter=${selectedLetter}`;
        }
        queryString += category;
        this.setState(
            prevState => ({
                ...prevState,
                queryString,
                selectedLetter,
                keyword: undefined
            }),
            () => {
                // search data
                getFilteredAndPagedData(
                    dashboardUserData.pagination.baseLink,
                    this.state.queryString
                );
            }
        );
    };

    getDataByKeyword = keyword => {
        const { getFilteredAndPagedData, dashboardUserData } = this.props;
        let category = "";
        if (this.state.clientCategory) {
            category = `&category=${this.getParsedClientCategory()}`;
        }
        const queryString = `keyword=${keyword}${category}`;

        this.setState(
            prevState => ({
                ...prevState,
                queryString,
                keyword,
                selectedLetter: undefined
            }),
            () => {
                // search data
                getFilteredAndPagedData(
                    dashboardUserData.pagination.baseLink,
                    this.state.queryString
                );
            }
        );
    };

    getDataByPage = pageNumber => {
        const { getFilteredAndPagedData, dashboardUserData } = this.props;
        // search data
        getFilteredAndPagedData(
            dashboardUserData.pagination.baseLink,
            `page=${pageNumber - 1}&size=${dataPerPage}&${
                this.state.queryString
            }`
        );
    };

    handleKeywordPress = event => {
        if (event.key === "Enter" || event.charCode === 13) {
            this.getDataByKeyword(this.state.keyword);
        }
    };

    handleKeywordChange = keyword => {
        if (keyword) {
            this.setState(prevState => ({
                ...prevState,
                keyword
            }));

            //clear all checkboxes
        } else {
            this.getDataByLetter("all");

            this.setState(prevState => ({
                ...prevState,
                clientCategory: []
            }));
        }
    };

    onToggleList = () => {
        this.setState(prevState => ({
            ...prevState,
            isToggleList: !prevState.isToggleList
        }));
    };

    render() {
        const {
            newEntityClicked,
            getDataByLetter,
            handleKeywordPress,
            handleKeywordChange,
            onToggleList,
            getDataByPage,
            onClientCategoryChange
        } = this;
        const {
            activeEntityType,
            dashboardEntityToCheck,
            dashboardData,
            dashboardUserData,
            feedbacksTotal,
            abcde
        } = this.props;

        const { entityId, isToggleList, isClientListData } = this.state;

        const dashboardUserDataColumns = [
            {
                accessor: "info",
                Header: () => (
                    <div>
                        #
                        <span className="ico-arrowtop" />
                    </div>
                ),
                Cell: row => <div>{row.index + 1}</div>,
                minWidth: 40
            },

            {
                accessor: "info",
                Header: () => (
                    <div>
                        Name
                        <span className="ico-arrowtop" />
                    </div>
                ),
                Cell: row => (
                    <div>
                        <a
                            href={`${prefixAppUrl}/personaldetails/${row.original.id}`}
                        >
                            {" "}
                            {row.value.clientName
                                ? row.value.clientName
                                : row.value[0]}
                        </a>
                    </div>
                )
            },

            {
                accessor: "info",
                Header: () => (
                    <div>
                        Adviser
                        <span className="ico-arrowtop" />
                    </div>
                ),
                Cell: row => (
                    <div className="client-listing-adviser">
                        <span>{row.value.adviserName}</span>
                    </div>
                )
            },

            {
                accessor: "info",
                Header: () => (
                    <div>
                        Type
                        <span className="ico-arrowtop" />
                    </div>
                ),
                Cell: row => (
                    <div>
                        <span>{row.value.type}</span>
                    </div>
                )
            },

            {
                accessor: "info",
                Header: () => (
                    <div>
                        Partner Name
                        <span className="ico-arrowtop" />
                    </div>
                ),
                Cell: row => (
                    <div>
                        <span>{row.value.partnerName}</span>
                    </div>
                )
            },

            {
                accessor: "info",
                Header: () => (
                    <div>
                        Last Submission
                        <span className="ico-arrowtop" />
                    </div>
                ),
                Cell: row => (
                    <div>
                        <span>{row.value.lastSubmission}</span>
                    </div>
                )
            },

            {
                accessor: "info",
                Header: () => (
                    <div>
                        Last Contact
                        <span className="ico-arrowtop" />
                    </div>
                ),
                Cell: row => (
                    <div>
                        <span>{row.value.lastContact}</span>
                    </div>
                )
            },

            {
                accessor: "info",
                Header: () => (
                    <div>
                        Last Action
                        <span className="ico-arrowtop" />
                    </div>
                ),
                Cell: row => (
                    <div>
                        <span>{row.value.lastAction}</span>
                    </div>
                )
            },

            {
                accessor: "info",
                Header: () => (
                    <div>
                        Current Action
                        <span className="ico-arrowtop" />
                    </div>
                ),
                Cell: row => (
                    <div>
                        <span>{row.value.currentAction}</span>
                    </div>
                )
            },

            {
                Header: "Tasks",
                // accessor: 'info',
                filterable: false,
                Cell: row => (
                    <TaskUser
                        user={row.original}
                        activeEntityType={activeEntityType}
                        // key={index}
                    />
                ),
                minWidth: 150
            }
        ];

        // 20211029
        const nonclientdashboardUserDataColumns = [
            {
                accessor: "info",
                Header: () => (
                    <div>
                        #
                        <span className="ico-arrowtop" />
                    </div>
                ),
                Cell: row => <div>{row.index + 1}</div>,
                minWidth: 40
            },

            {
                accessor: "info",
                Header: row => (
                    <div>
                        Name
                        <span className="ico-arrowtop" />
                    </div>
                ),
                Cell: row => (
                    <div>
                        {/* check url param and USER TYPE */}
                        {window.location.href.search("cid=") < 0 ? (
                            <a
                                href={`${prefixAppUrl}/dashboard/advisers?cid=${row.original.id}`}
                            >
                                {" "}
                                {row.value.clientName
                                    ? row.value.clientName
                                    : row.value[0]}
                            </a>
                        ) : // check url

                        window.location.href.search("advisers?cid=") > 0 ? (
                            <a
                                href={`${prefixAppUrl}/personaldetails/${row.original.id}`}
                            >
                                {" "}
                                {row.value.clientName
                                    ? row.value.clientName
                                    : row.value[0]}
                            </a>
                        ) : row.original.type == "BUILT_IN_ADVISER" ? (
                            <a
                                href={`${prefixAppUrl}/dashboard/advisers?cid=${row.original.id}`}
                            >
                                {" "}
                                {row.value.clientName
                                    ? row.value.clientName
                                    : row.value[0]}
                            </a>
                        ) : (
                            <a
                                href={`${prefixAppUrl}/client/${row.original.id}`}
                            >
                                {" "}
                                {row.value.clientName
                                    ? row.value.clientName
                                    : row.value[0]}
                            </a>
                        )}
                    </div>
                )
            },

            //company reg no
            {
                accessor: "info",
                Header: row => (
                    <div>
                        Company Reg No:
                        <span className="ico-arrowtop" />
                    </div>
                ),
                Cell: row =>
                    row.value[1] !== undefined && (
                        <div className="client-listing-adviser">
                            <span>
                                {row.value[1].replace("Company Reg No: ", "")}
                            </span>
                        </div>
                    ),
                show: true
            },

            //identifier
            {
                accessor: "info",
                Header: row => (
                    <div>
                        Identifier:
                        <span className="ico-arrowtop" />
                    </div>
                ),
                Cell: row =>
                    row.value[2] !== undefined && (
                        <div className="client-listing-adviser">
                            <span>
                                {row.value[2].replace("Identifier: ", "")}
                            </span>
                        </div>
                    ),
                show: true
            },

            //supervisor
            {
                accessor: "info",
                Header: row => (
                    <div>
                        Supervisor:
                        <span className="ico-arrowtop" />
                    </div>
                ),
                Cell: row =>
                    row.value[3] !== undefined && (
                        <div className="client-listing-adviser">
                            <span>
                                {row.value[3].indexOf("Supervisor") >= 0 &&
                                    row.value[3].replace("Supervisor: ", "")}
                            </span>
                        </div>
                    ),
                show: true
            },

            //mic
            {
                accessor: "info",
                Header: row => (
                    <div>
                        MIC:
                        <span className="ico-arrowtop" />
                    </div>
                ),
                Cell: row =>
                    row.value[4] !== undefined && (
                        <div className="client-listing-adviser">
                            <span>
                                {row.value[4].indexOf("MIC") >= 0 &&
                                    row.value[4].replace("MIC: ", "")}
                            </span>
                        </div>
                    ),
                show: true
            },

            //aum
            {
                accessor: "info",
                Header: row => (
                    <div>
                        AUM:
                        <span className="ico-arrowtop" />
                    </div>
                ),
                Cell: row =>
                    row.value[5] !== undefined && (
                        <div className="client-listing-adviser">
                            <span>
                                {row.value[5].indexOf("AUM") >= 0 &&
                                    row.value[5].replace("AUM: ", "")}
                            </span>
                        </div>
                    ),
                show: true
            },

            //client
            {
                accessor: "info",
                Header: row => (
                    <div>
                        Clients:
                        <span className="ico-arrowtop" />
                    </div>
                ),
                Cell: row =>
                    row.value[6] !== undefined && (
                        <div className="client-listing-adviser">
                            <span>
                                {row.value[6].indexOf("Clients") >= 0 &&
                                    row.value[6].replace("Clients: ", "")}
                            </span>
                        </div>
                    ),
                show: true
            },

            {
                Header: "Tasks",
                // accessor: 'info',
                filterable: false,
                Cell: row => (
                    <TaskUser
                        user={row.original}
                        activeEntityType={activeEntityType}
                        // key={index}
                    />
                ),
                minWidth: 150
            }
        ];

        const { selectedLetter } = this.state;

        if (isClientListData && !this.state.clientCategory) {
            this.setState(prevState => ({
                ...prevState,
                clientCategory: [
                    {
                        label: "Client",
                        value: "CLIENT"
                    }
                ]
            }));
        }

        const addClient = () => {
            console.log("addClient");
        };

        const onClose = () => {
            //refresh
            console.log("this.props", this.props);
            this.props.setDashboardEntityAndData(this.props.match.path);
        };

        return (
            <React.Fragment>
                <div className="height-100 dashboard-admin" id="scroll-section">
                    {/* <!--Main Panel--> */}
                    <div className="portal-maincontent">
                        <div className="page-arrow-holder">
                            <span className="arrow arrow-holder-L">
                                <div className="arrow-left" />
                            </span>{" "}
                            <span className="arrow arrow-holder-R">
                                <div className="arrow-right" />
                            </span>
                        </div>
                        <div className="dashboard-content-panel">
                            {dashboardData.overviews && (
                                <GroupedOverviewBoxes
                                    overviews={dashboardData.overviews}
                                    feedbacksTotal={feedbacksTotal}
                                />
                            )}

                            <div className="alphabet-box">
                                {isClientListData && (
                                    <ReactMultiSelectCheckboxes
                                        className="dashboard-client-category"
                                        value={this.state.clientCategory} //store the checkbox value
                                        onChange={onClientCategoryChange}
                                        options={[
                                            {
                                                label: "New Contact",
                                                value: "NEW_CONTACT"
                                            },
                                            {
                                                label: "Prospect",
                                                value: "PROSPECT"
                                            },
                                            {
                                                label: "Client",
                                                value: "CLIENT"
                                            },
                                            {
                                                label: "Archive",
                                                value: "ARCHIVE"
                                            }
                                        ]}
                                    />
                                )}

                                <div
                                    className="alphabet-R"
                                    style={{ marginRight: "5px" }}
                                >
                                    {/* <button
                                        type="button"
                                        onClick={addClient}
                                        className="btn btn-plus addClient"
                                    /> */}

                                    <input
                                        id="Search"
                                        type="search"
                                        placeholder="Search"
                                        onKeyPress={handleKeywordPress}
                                        onChange={event =>
                                            handleKeywordChange(
                                                event.target.value
                                            )
                                        }
                                    />
                                </div>
                            </div>

                            {isToggleList ? (
                                <TableCustom
                                    data={dashboardUserData.data}
                                    // columns={dashboardUserDataColumns}
                                    columns={nonclientdashboardUserDataColumns}
                                    filterable={false}
                                    minRows={3}
                                    className="table-blue table-clientlist"
                                    sortable
                                    showPaginationBottom={false}
                                />
                            ) : (
                                <React.Fragment>
                                    <div
                                        className="flexbox"
                                        style={{ gap: "30px" }}
                                    >
                                        {dashboardUserData &&
                                            dashboardUserData.data.map(
                                                (user, index) => (
                                                    <BoxUser
                                                        user={user}
                                                        key={index}
                                                        activeEntityType={
                                                            activeEntityType
                                                        }
                                                        dashboardEntityToCheck={
                                                            dashboardEntityToCheck
                                                        }
                                                    />
                                                )
                                            )}
                                    </div>
                                </React.Fragment>
                            )}
                            {dashboardUserData && (
                                <div
                                    className="pagination-center"
                                    style={{
                                        bottom: "-80px"
                                    }}
                                >
                                    <Pagination
                                        activePage={
                                            dashboardUserData.pagination
                                                .currentPage + 1
                                        }
                                        itemsCountPerPage={dataPerPage}
                                        totalItemsCount={
                                            (dashboardUserData.pagination
                                                .maxPage +
                                                1) *
                                            dataPerPage
                                        }
                                        pageRangeDisplayed={5}
                                        onChange={pagenumber =>
                                            getDataByPage(pagenumber)
                                        }
                                    />
                                </div>
                            )}
                            {/* <ScrollUpButton sectionId={"scroll-section"} /> */}
                        </div>
                        <MalabarDashboardRightMenu
                            entityType={dashboardEntityToCheck}
                            onNewClick={newEntityClicked}
                            allowAddNewEntity={
                                activeEntityType === dashboardEntityToCheck
                            }
                            entityId={entityId}
                            process={process}
                        />
                    </div>
                </div>
                <Entity
                    entityId={entityId}
                    type="PRIMARY"
                    componentName={modalWindow.ENTITY_MODAL}
                    refreshContainerData={() => {
                        if (entityId) {
                            this.props.getDashboardDataById(entityId);
                        } else {
                            this.props.setDashboardEntityAndData(
                                this.props.match.path
                            );
                        }
                    }}
                />
                <UserForm
                    componentName={modalWindow.USER_MODAL}
                    entityId={entityId}
                />
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { optionsData } = state.enumOptions;
    const { activeEntityType } = state.appSettings;
    const { abcde } = state.appSettings;
    const {
        dashboardData,
        dashboardUserData,
        dashboardEntityToLoad,
        dashboardEntityToCheck
    } = state.dashboard;

    return {
        optionsData,
        activeEntityType,
        dashboardData,
        dashboardEntityToLoad,
        dashboardEntityToCheck,
        dashboardUserData,
        feedbacksTotal:
            state.feedbacks.items === null ? 0 : state.feedbacks.items.length,
        abcde
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        Object.assign(
            {},
            enumOptionsActions,
            modalWindowActions,
            appSettingsActions,
            dashboardActions
        ),
        dispatch
    );
}

MainDashboard = connect(mapStateToProps, mapDispatchToProps)(MainDashboard);

export default MainDashboard;
