import ReactModal from "react-modal";

import React, { Component } from "react";

export class TermsAndConditions extends Component {
    render() {

        return (
            <ReactModal
                isOpen={this.props.isOpen}
                className="malabar-modal-large user-modal terms-conditions-dialog"
                overlayClassName="malabar-modal-overlay"
                shouldCloseOnEsc
                onRequestClose={() => this.props.closeModalWindow()}
            >
                <div className="inner-body-content">
                    <div className="page-title-green">
                        Terms And Conditions
                        <div className="close-holder">
                            <span
                                onClick={() => this.props.closeModalWindow()}
                                className="close-button"
                            />
                        </div>
                    </div>
                    <div className="terms-conditions-content">
                        <div className="detail-intro_section" id="Introduction">
                            <div className="title-point">Introduction</div>
                            <div className="point">
                                Please read these terms of use carefully before
                                using or accessing this website. By using or
                                accessing this site, you agree to the terms of
                                use listed below. Using, browsing or reading the
                                website signifies you have read, understood and
                                agree to be bound by the terms. If you do not
                                agree to the terms below, please do not use or
                                access this site. <br />
                                <br />
                                KoiZai limited reserves the right to review and
                                change any terms by updating this page at its
                                sole discretion. When KoiZai updates the terms,
                                it will use reasonable endeavours to provide you
                                with notice of updates to the terms. Any changes
                                to the terms take immediate effect from the date
                                of their publication. Before you continue, we
                                recommend you keep a copy of the terms for your
                                records.
                            </div>
                        </div>
                        <div
                            className="detail-intro_section"
                            id="Intellectual Property Rights"
                        >
                            <div className="title-point">
                                Intellectual Property Rights
                            </div>
                            <div className="point">
                                All contents of, and materials located on this
                                site including but not limited to the text,
                                images and other information herein and their
                                arrangement, are protected by copyright,
                                trademark and other proprietary rights of KoiZai
                                limited, its group of companies and where
                                applicable, the proprietary rights of the
                                relevant third-party providers.
                                <br />
                                <br /> This means that you shall not, without
                                the express written consent of KoiZai,
                                distribute the text or graphics to others or for
                                any unlawful or unauthorised purpose; mirror or
                                include this information on your server or
                                documents, or modify or re-use the text or
                                graphics on this system. KoiZai reserves all
                                other rights. The &quot;KoiZai&quot; logo is a
                                trademark of KoiZai limited. This prohibition
                                does not extend to materials on the website
                                which are freely available for re-use or are in
                                the public domain.
                                <br />
                                <br /> All trademarks, service marks, logos and
                                any other marks whatsoever are used for the
                                benefit of their respective owners and are not
                                intended to infringe the trademark of any such
                                person.
                                <br />
                                <br /> KoiZai or any of its relevant third-party
                                providers (as an intended third-party
                                beneficiary) may enforce its rights against you.
                            </div>
                        </div>
                        <div className="detail-intro_section" id="License">
                            <div className="title-point">License</div>
                            <div className="point">
                                Nothing contained herein shall be construed as
                                conferring by implication, estoppel, or any
                                license or right under any patent, trademark, or
                                proprietary rights of KoiZai or any third party.
                                Except as expressly provided above, nothing
                                contained herein shall be construed as
                                conferring any license or right under any KoiZai
                                copyright.
                            </div>
                        </div>
                        <div className="detail-intro_section" id="Revisions">
                            <div className="title-point">Revisions</div>
                            <div className="point">
                                All information (including, without limitation,
                                specifications, descriptions and prices)
                                appearing in this site may be revised or
                                withdrawn, in whole or in part, at any time
                                without notice and shall not in any event form
                                or constitute part of an offer or terms and
                                conditions of a contract. By using this site,
                                you agree to be bound by any such revisions, and
                                you agree to periodically visit this page to
                                determine the current terms of use to which you
                                are bound.
                            </div>
                        </div>
                        <div className="detail-intro_section" id="Disclaimers">
                            <div className="title-point">Disclaimers</div>
                            <div className="point">
                                The information published on this Site is
                                provided without charge as a convenience to
                                visitors, to be used for informational purposes
                                only. It shall not be construed as an offer or
                                solicitation to deal in the funds or products
                                referred to or contained in this site. While
                                KoiZai (which shall include any of its relevant
                                third-party providers) has tried to provide
                                accurate and timely information, there may be
                                inadvertent delays, omissions, technical or
                                factual inaccuracies and typographical errors.{" "}
                                <br />
                                The information on this Site is provided
                                &quot;AS IS&quot; and &quot;AS AVAILABLE&quot;
                                and is provided by a simple summary. KoiZai
                                (which shall include any of its relevant
                                third-party providers) does not warrant the
                                accuracy or completeness of the information,
                                text, graphics, links or other items contained
                                in this site, and KoiZai expressly disclaims
                                liability for errors or omissions in these
                                materials. KoiZai does not commit to updating
                                the information contained in this Site. <br />{" "}
                                All materials and contents found in this Site
                                are strictly for information purposes only.
                                Please make your own assessment of the
                                relevance, accuracy and adequacy of the
                                information contained in this Site. Any opinion
                                or estimate contained in this Site is made on a
                                general basis, and neither KoiZai nor any of its
                                servants or agents have given any consideration
                                to nor have they or any of them made any
                                investigation of the particular need of any user
                                or reader, any specific person or group of
                                persons. Accordingly, KoiZai expressly disclaims
                                all liability for the use or interpretation by
                                others of information contained in this site or
                                for any loss arising, whether directly or
                                indirectly, as a result of the visitor, user,
                                any person or group of persons acting on any
                                information, opinion or estimate contained in
                                this Site. Speculation or stated beliefs about
                                future events, such as market and economic
                                conditions, company or security performance,
                                upcoming product offerings or other projections,
                                are &quot;forward-looking statements&quot; and
                                involve risks and uncertainties. General
                                business, market, economic and political
                                conditions could cause results to differ
                                materially from what we presently anticipate or
                                project.
                                <br /> If you are accessing this site from a
                                jurisdiction outside of Hong Kong, you are
                                responsible for compliance with your
                                jurisdiction&apos;s applicable laws and
                                regulations. Please do not access this site if
                                the publication or availability of this site is
                                prohibited in your jurisdiction in any way.
                            </div>
                        </div>
                        <div className="accept-decline_section">
                            <button
                                className="accept-btn"
                                onClick={() =>
                                    this.props.termsAndConditionsChecked()
                                }
                            >
                                Accept
                            </button>
                            <button
                                onClick={() => this.props.closeModalWindow()}
                                className="decline-btn"
                            >
                                Decline
                            </button>
                        </div>
                    </div>
                </div>
            </ReactModal>
        );
    }
}

export default TermsAndConditions;
