import React from 'react';
import intl from 'react-intl-universal';
import { RenderSimpleFields } from '../../../components';
import { FieldType } from '../../../components/Form/RenderSimpleFields';

import CentralProvidenceVoluntaryContributions from './CentralProvidenceVoluntaryContributions';
import CPFClassification from './CPFClassification';

function CentralProvidenceBoardDetailsLeftSide({ mutators, values = { classification: 'CLIENT' } }) {
    const updateSelection = (value) => {
        mutators.setValue('classification', value)
    }
    return (
        <React.Fragment>
            <CPFClassification onClick={updateSelection} value={values.classification || 'CLIENT'} />
            <div className="subtitle2">
                {intl.get('centralProvidenceFundInformation.details')}
            </div>
            <div >
                <RenderSimpleFields
                    fields={[
                        {
                            name: 'cpfAccountNumber',
                            label: intl.get('field.cpfAccountNumber'),
                            type: FieldType.TEXT,
                        },
                        {
                            name: 'cpfIssuer',
                            label: intl.get('field.cpfIssuer'),
                            type: FieldType.TEXT,
                        }
                    ]}
                />
                <CentralProvidenceVoluntaryContributions mutators={mutators} model={values} />
            </div >
        </React.Fragment>
    );
}



export default CentralProvidenceBoardDetailsLeftSide