import React, { useState, useContext, useEffect } from "react"
import intl from 'react-intl-universal';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import { renderSelectFieldCustom, renderPercentageCustom, renderTextBoxField, renderTextBoxFieldColumn, renderTextBoxCustom, numberWithCommas, rateWithCommas, renderMoneyCustom, renderCurrencyCustom } from '../../../../helpers';
import AssetRiskClassificationApi from "../../../../api/AssetRiskClassificationApi";


import Alert from "react-s-alert"
import NumberFormat from "react-number-format";
import { AppContext } from "../../../../context/AppContext";

import { uuidv4 } from "../../../../helpers/DateHelper";
import { PERCENT_SCALE } from "../../../../constants/NumberFormatConstants";
import RetirementApi from "../../../../api/RetirementApi";

const PRSFundAllocations = ({
    currency,
    currencySymbol,
    mutators,
    addInvestmentAllocation,
    removeInvestmentAllocation,
    values
    
}) => {
    const [setting, setSetting] = useState("")
    const [productTypes, setProductTypes] = useState([])
    
    const {
        modal: { setModal, clearModal }
      } = useContext(AppContext)

    

    useEffect(() => {
        

        AssetRiskClassificationApi.getLoggedEntitySetting(true).then(({data})=>{
            if(data){
                if(data.setting){
                    setSetting(data.setting);
                    setProductTypes(data.setting.assetProductTypes);
                }else{
                    Alert.warning("Please contact your admin or dealership manager to configure investment asset risk settings, you can't allocate any investment without it.")
                }
             
            }
        })

        
    }, [])

    const PRS_FUND_TYPE = [{code:"CONVENTIONAL", name:"Conventional"}, {code:"SHARIA", name:"Sharia"}]

   
    function getFundsOptions(){
        let options = [];
        if(values.selectedScheme){
            if(values.selectedScheme.funds){
                for (let index = 0; index < values.selectedScheme.funds.length; index++) {
                    const f = values.selectedScheme.funds[index];
                    options.push({code:f, name:f})
                    
                }
            }
        }
        return options;
       
        
    }

    function getFilteredProductTypes(index){
        
         return productTypes && productTypes.filter(p=>p.assetType=="PRS")
    }

    function getAssetRiskType(code){
        let found ;
        if(setting && setting.riskClassificationTypes){
            for (let index = 0; index < setting.riskClassificationTypes.length; index++) {
                let r = setting.riskClassificationTypes[index];
                if(r.code == code){
                    found = r;
                    break;
                }
            }
        }
        return found;
    }

    function onProductTypeChanged(index, productCode){
        let searchRiskTypeCode;
        if(productTypes){
            for (let index = 0; index < productTypes.length; index++) {
                let p = productTypes[index];
                if(p.code == productCode){
                    searchRiskTypeCode = p.assetRiskClassificationTypeCode;
                    break;
                }
                
            }

        }

        if(searchRiskTypeCode){
            let allo = values.funds[index];
            let riskType = getAssetRiskType(searchRiskTypeCode);
            allo.allocationPercentage = riskType.allocationPercent;
            allo.annualReturnPercentage = riskType.expectedRoiPercent;
            updateAllocationItem(allo);

           
        }
        
    }

   

    

    function validateNewRow(){
        let result = true;
        let providerName = values.providerName;
        let funds = values.funds;
        let rowNo = 1;
        let errorText = "";
        let investmentPortion = 0;
        if(!providerName){
            let pErrorMsg = 'Please select provider name first in tab "PRS Details" to load all funds';
            if(errorText.length==0){
                errorText = pErrorMsg;
            }else{
                errorText = errorText + ", " + pErrorMsg;
            }
        }
        if(funds && funds.length>0){
            
            for (let index = 0; index < funds.length; index++) {
                const a = funds[index];
                let rowErrors = "";
                if(!a.fundName){
                    if(rowErrors.length==0){
                        rowErrors = "Fund Name";
                    }else{
                        rowErrors = rowErrors + ", " + "Fund Name";
                    }
                }

                if(!a.fundType){
                    if(rowErrors.length==0){
                        rowErrors = "Fund Type";
                    }else{
                        rowErrors = rowErrors + ", " + "Fund Type";
                    }
                }
                if(!a.productTypeCode){
                    if(rowErrors.length==0){
                        rowErrors = "Product Type Code";
                    }else{
                        rowErrors = rowErrors + ", " + "Product Type Code";
                    }
                }

               


                if(a.allocationPercentage){
                    investmentPortion = investmentPortion + a.allocationPercentage;
                }else{
                    if(rowErrors.length==0){
                        rowErrors = "Allocation %";
                    }else{
                        rowErrors = rowErrors + ", " + "Allocation%";
                    }
                }

               
                if(rowErrors.length>0){
                    let msg = `Row-${rowNo}: ${rowErrors} required`;
                    if(errorText.length==0){
                        errorText = msg;
                    }else{
                        errorText = errorText + ", " + msg;
                    } 
                }
               
                rowNo++;
            }


        }
        if(investmentPortion >= 100){
            let msg = `Total portion insurance + investment already 100% (current total=${investmentPortion})`;
            if(errorText.length==0){
                errorText = msg;
            }else{
                errorText = errorText + ", " + msg;
            } 
        }

        if(errorText && errorText.length>0){
            Alert.error(errorText);
            result = false;
        }
        return result;
    }
    
    function onAddButtonClicked(e){
        if(validateNewRow()){
            let newModel = {
                fundName: '',
                fundType: '',
                fundManager: '',
                productTypeCode: '',
                navPerUnit: 0,
                allocationPercentage: 0,
                annualAverageReturnPercent:0,
                feePercentage:0
            }
            addInvestmentAllocation('funds', newModel);
        }
       
    }
   

    function getTotalAllocations(){
        let totalAllocations = 0;
        let list = values && values.funds?values.funds:[];
        if(list){
            for (let index = 0; index < list.length; index++) {
                let a = list[index];
                let pct =a && a.allocationPercentage?a.allocationPercentage:0;
                totalAllocations = totalAllocations + pct;
            }
        }
        return totalAllocations;
    }
    function updateAllocationItem(updatedData){
        let newList = [];
        let list = values && values.funds?values.funds:[];
        if(list){
            for (let index = 0; index < list.length; index++) {
                let a = list[index];
                if(a.fundName == updatedData.fundName){
                    newList.push(updatedData);
                }else{
                    newList.push(a);
                }
                
            }
        }
        mutators.setFormAttribute("funds", newList);
       
    }

  

   

    function getSummaryRow(){
        let sumAllocations = 0;
        let sumReturns = 0;
        let sumWeightReturns = 0;
        let sumFeesPct = 0;
        let list = values && values.funds?values.funds:[];
        if(list){
            for (let index = 0; index < list.length; index++) {
                let a = list[index];
                let pct =a && a.allocationPercentage?a.allocationPercentage:0;
                sumAllocations = sumAllocations + pct;

                let roi = a && a.annualReturnPercentage?a.annualReturnPercentage:0;
                sumReturns = sumReturns + roi;

                let feePct = a && a.feePercentage?a.feePercentage:0;
                sumFeesPct = sumFeesPct + feePct;
                
            }
        }

        let total = sumAllocations ;
        let remaining = 100 - total;
        let summaryClassName = "text-input text-bold text-right"
        
        return (
            <>  
                {list && list.length > 0 && (
                    <tr key="total">
                        <td style={{width:"15%"}} colSpan={4}>
                            <span className="text-bold">Total Summary</span>             
                        </td>
                        
                        <td style={{width:"10%"}}>
                            <NumberFormat className={summaryClassName} value={sumAllocations} suffix=" %" disabled={true} />
                        </td>
                        <td style={{width:"10%"}} >
                            <NumberFormat className={summaryClassName} value={sumReturns} suffix=" %" disabled={true} />
                        </td>
                        <td style={{width:"10%"}}>
                            <NumberFormat className={summaryClassName} value={sumFeesPct} suffix=" %" disabled={true} />
                        </td>
                        <td style={{width:"5%"}}>
                        </td>
                                
                    </tr>
                )}
               
                <tr key="remaining">
                    <td style={{width:"15%"}} colSpan={4}>
                        <span className="text-bold">Remaining Allocation</span> 
                    </td>
                    <td style={{width:"10%"}}>
                        <NumberFormat className={summaryClassName} value={remaining} suffix=" %" disabled={true} />
                    </td>
                    <td colSpan={3}>
                        
                    </td>                 
                                
                </tr>
            </>
            

            
        )
    }

    function displaySchemeInfo(){
        if(values.selectedScheme){
            return (<span>{values.selectedScheme.scheme}</span>);
        }else{
            return (<span className="text-redchili">You have not selected PRS scheme yet.</span>);
        }
    }

    return (
       
                <>
                
                <div className="portal-form-row">
                    {displaySchemeInfo()}
                </div>
                <div className="portal-form-row">
                    <span
                        className="btn btn-plus btn-plus-grey"
                        onClick={(e) => onAddButtonClicked(e)}
                    />
                    &nbsp;
                </div>
                <table
                    className="table-blue"
                    width="100%"
                    cellSpacing={0}
                    cellPadding={0}
                    border={0}
                >
                    <tbody>
                        <tr>
                            <th style={{width:"20%"}}>Fund Name</th>
                            <th style={{width:"15%"}}>Fund Type</th>
                            <th style={{width:"15%"}}>Fund Manager</th>
                            <th style={{width:"15%"}}>Product Type</th>
                            <th style={{width:"10%"}}>Allocation %</th>
                            <th style={{width:"10%"}}>Annual Return %</th>
                            <th style={{width:"10%"}}>Fees %</th>
                            <th style={{width:"5%"}}></th>
                        </tr>
                        <FieldArray name="funds">
                            {({ fields }) =>
                                fields.map((name, index) => {
                                    return (
                                        <tr key={index}>
                                            <td style={{width:"20%"}}>
                                                <Field
                                                    name={`${name}.fundName`}
                                                    component={renderSelectFieldCustom}
                                                    valueProperty="code"
                                                    labelProperty="name"
                                                    data={getFundsOptions()}
                                                   
                                                />
                                            </td>
                                            <td style={{width:"15%"}}>
                                                <Field
                                                    name={`${name}.fundType`}
                                                    component={renderSelectFieldCustom}
                                                    valueProperty="code"
                                                    labelProperty="name"
                                                    data={PRS_FUND_TYPE}
                                                   
                                                />
                                            </td>
                                            <td style={{width:"15%"}}>
                                                <Field
                                                    name={`${name}.fundManager`}
                                                    component={renderTextBoxCustom}
                                                    
                                                />
                                            </td>
                                           
                                            <td style={{width:"15%"}}>
                                                <Field
                                                    name={`${name}.productTypeCode`}
                                                    component={renderSelectFieldCustom}
                                                    valueProperty="code"
                                                    labelProperty="name"
                                                    data={getFilteredProductTypes(index)}
                                                    onChange={(newVal) => onProductTypeChanged(index, newVal)}
                                                />
                                            </td>
                                            
                                            <td style={{width:"10%"}}>
                                                <Field
                                                    name={`${name}.allocationPercentage`}
                                                    component={
                                                        renderPercentageCustom
                                                    }
                                                   
                                                />
                                            </td>
                                            <td style={{width:"10%"}}>
                                                <Field
                                                    name={`${name}.annualReturnPercentage`}
                                                    component={
                                                        renderPercentageCustom
                                                    }
                                                    maxValue={9999}
                                                   
                                                />
                                            </td>
                                         
                                            <td style={{width:"10%"}}>
                                                <Field
                                                    name={`${name}.feePercentage`}
                                                    component={
                                                        renderPercentageCustom
                                                    }
                                                   
                                                />
                                                                                           
                                            </td>
                                            <td style={{width:"5%"}}>
                                                <span
                                                    className="btn-remove"
                                                    onClick={() => {
                                                        removeInvestmentAllocation(
                                                            'funds',
                                                            index
                                                        );
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                        </FieldArray>
                        {getSummaryRow()}
                    </tbody>
                </table>
             </>
    );         
};

export default PRSFundAllocations;
