import React, { Component } from "react";
import {
    Col,
    Container,
    Row
} from "reactstrap";
import intl from "react-intl-universal";

class Page404 extends Component {
    render() {
        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="6">
                            <h1 className="d-flex align-items-center justify-content-center mt-5 notfound-text">
                                404
                            </h1>
                            <div className="d-flex justify-content-center align-items-center flex-column">
                                <h4 className="mt-3">
                                    {" "}
                                    {intl.get("notfound.lostText")}
                                </h4>
                                <p className="text-muted">
                                    {intl.get("notfound.pageLooking")}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Page404;
