import React from 'react';
import intl from 'react-intl-universal';

const AlphabetSearch = ({
    onSelect,
    isToggleList,
    onToggleList,
    selectedLetter = 'all',
    hideAll = false,
}) => {
    const alphabet = [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
    ];

    return (
        <div className="alphabet-L">
            {!hideAll && (
                <span
                    className={selectedLetter === 'all' ? 'selected' : ''}
                    onClick={() => onSelect('all')}
                >
                    {intl.get('select.all')}
                </span>
            )}
            <span
                className={isToggleList ? 'selected' : ''}
                onClick={() => onToggleList()}
            >
                List
            </span>
            {alphabet.map((letter, index) => (
                <span
                    className={selectedLetter === letter ? 'selected' : ''}
                    key={index}
                    onClick={() => onSelect(letter)}
                >
                    {letter}
                </span>
            ))}

            {/* clear all search */}
            <span
                onClick={() => onSelect('all')}
            >
                <input className="portal-btn portal-btn-submit clear-listing-clear" type="submit" value="Clear" />
            </span>
        </div>
    );
};

export default AlphabetSearch;
