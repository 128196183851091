import React, { Component } from "react"
import intl from "react-intl-universal"
import { logout } from "../../helpers/SessionHelper"
import { ConfirmationDialog } from "../../components"

export class Logout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      save: false
    }
  }

  handleLogout = () => {
    this.setState({
      save: true
    })
  }

  clickHandler1 = () => {
    this.setState({
      save: false
    })
  }

  clickHandler2 = () => {
    this.setState({
      save: false
    })
    localStorage.removeItem("Mode")

    logout()
  }

  render() {
    const { save } = this.state
    const enhancedComponent =
      this.props.component && React.isValidElement(this.props.component)
        ? React.cloneElement(this.props.component, {
            onClick: this.handleLogout
          })
        : null

    return (
      <div>
        <ConfirmationDialog
          isOpen={save}
          onClose={this.clickHandler1}
          onYes={this.clickHandler2}
          message="Confirm Log out ?"
        />

        {enhancedComponent ? (
          enhancedComponent
        ) : (
          <a className="client-menu-logout" onClick={this.handleLogout}>
            {intl.get("leftmenu.logout")}
          </a>
        )}
      </div>
    )
  }
}

export default Logout
