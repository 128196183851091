import React, { useContext } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';
import intl from 'react-intl-universal';
import { FormSpy } from 'react-final-form';
import { AppContext } from '../../../../context/AppContext';
import CashFlowHeaderListing from '../../CashFlowHeaderListing';
import {
    CreditCardForm,
    ShareForm,
    UnitTrustForm,
    PersonalContentForm,
    BondForm,
    CollectibleAntiqueArtsForm,
    BusinessOwnershipForm,
} from '../../../../forms';

import CashFlowListingGroup from '../../CashFlowListingGroup';
import api from '../../../../lib/api';
import LoanExpensesForm from '../../../../forms/LoanExpensesForm';
import Get from '../../../../helpers/NullHelper';
import ResidencePropertyForm from '../../../../forms/ResidencePropertyForm';
import { InvestmentPropertyForm } from '../../../../forms';
import { netWorthFormActions } from '../../../../actions';
import { useDispatch } from 'react-redux';

const InvestmentExpenses = ({
    varPrefix,
    clientTotalValue,
    partnerTotalValue,
    jointTotalValue,
    optionsData,
    readOnlyAccessComponent,
    clientInformation,
    currency,
    onExternalUpdated,
    isJoint,
}) => {
  const dispatch = useDispatch()

    const {
        modal: { setModal, clearModal },
    } = useContext(AppContext);

    const handleSave = values => {
        // save the model
        api.put(values.url, values).then(() => {
            onExternalUpdated(true);
        });

        clearModal();
    };

    // 20201203 - MABT-366 : pass client information to here
    const showModalByUrl = model => {
        const modalType = model.url.split('/')[3];
dispatch(netWorthFormActions.setNewForm(false, model.classification))
        switch (modalType) {
            case 'credit_cards':
                return {
                    title: intl.get('liabilities.creditCard.title'),
                    content: (
                        <CreditCardForm
                            handleSave={handleSave}
                            model={model}
                            readOnlyAccess={readOnlyAccessComponent()}
                            clientInformation={clientInformation}
                            isJoint={isJoint}
                            optionsData={optionsData}
                        />
                    ),
                    formType: 'creditCard',
                };

            case 'personal_loans':
                return {
                    title: intl.get('liabilities.personalLoan.title'),
                    content: (
                        <LoanExpensesForm
                            handleSave={handleSave}
                            model={model}
                            client={model.client}   //20201203 MABT-366
                            readOnlyAccess={readOnlyAccessComponent()}
                            clientInformation={clientInformation}
                            isJoint={isJoint}
                            optionsData={optionsData}
                            type={'personalLoan'}
                        />
                    ),
                    formType: 'personalLoan',
                };

            case 'education_loans':
                return {
                    title: intl.get('liabilities.educationLoan.title'),
                    content: (
                        <LoanExpensesForm
                            handleSave={handleSave}
                            model={model}
                            readOnlyAccess={readOnlyAccessComponent()}
                            clientInformation={clientInformation}
                            isJoint={isJoint}
                            optionsData={optionsData}
                            type={'educationLoan'}
                        />
                    ),
                    formType: 'educationLoan',
                };

            case 'shares':
                return {
                    title: intl.get('shares.title'),
                    content: (
                        <ShareForm
                            handleSave={handleSave}
                            model={model}
                            readOnlyAccess={readOnlyAccessComponent()}
                            clientInformation={clientInformation}
                            isJoint={isJoint}
                            optionsData={optionsData}
                        />
                    ),
                    formType: 'personalExpenses-shares',
                };

            case 'unit_trusts':
                return {
                    title: intl.get('unitTrusts.title'),
                    content: (
                        <UnitTrustForm
                            handleSave={handleSave}
                            model={model}
                            readOnlyAccess={readOnlyAccessComponent()}
                            clientInformation={clientInformation}
                            isJoint={isJoint}
                            optionsData={optionsData}
                        />
                    ),
                    formType: 'unit_trusts',
                };

            case 'bonds':
                return {
                    title: intl.get('bonds.title'),
                    content: (
                        <BondForm
                            handleSave={handleSave}
                            model={model}
                            readOnlyAccess={readOnlyAccessComponent()}
                            clientInformation={clientInformation}
                            isJoint={isJoint}
                            optionsData={optionsData}
                        />
                    ),
                    formType: 'bonds',
                };
            case "business_ownerships":
                return {
                    title: intl.get("businessOwnerships.title"),
                    content: (
                        <BusinessOwnershipForm
                            handleSave={handleSave}
                            model={model}
                            readOnlyAccess={readOnlyAccessComponent()}
                            clientInformation={clientInformation}
                            isJoint={isJoint}
                            optionsData={optionsData}
                        />
                    ),
                    formType: "personalBusiness"
                };

            case 'collectibles':
                return {
                    title: intl.get('collectibleAntiques.title'),
                    content: (
                        <CollectibleAntiqueArtsForm
                            handleSave={handleSave}
                            model={model}
                            readOnlyAccess={readOnlyAccessComponent()}
                            clientInformation={clientInformation}
                            isJoint={isJoint}
                            optionsData={optionsData}
                        />
                    ),
                    formType: 'collectibles',
                };

            case 'personal_contents':
                return {
                    title: intl.get('personalContents.title'),
                    content: (
                        <PersonalContentForm
                            handleSave={handleSave}
                            model={model}
                            readOnlyAccess={readOnlyAccessComponent()}
                            clientInformation={clientInformation}
                            isJoint={isJoint}
                            optionsData={optionsData}
                        />
                    ),
                    formType: 'personal_contents',
                };
            case 'primary_residence_property':
                return {
                    title: intl.get('primaryResidencePropertyOwnership.title'),
                    content: (
                        <ResidencePropertyForm
                            handleSave={handleSave}
                            model={model}
                            readOnlyAccess={readOnlyAccessComponent()}
                            clientInformation={clientInformation}
                            isJoint={isJoint}
                            optionsData={optionsData}
                        />
                    ),
                    formType: 'homeExpenses',
                };
            case 'other_residence_properties':
                return {
                    title: intl.get('otherResidencePropertyOwnership.title'),
                    content: (
                        <ResidencePropertyForm
                            handleSave={handleSave}
                            model={model}
                            readOnlyAccess={readOnlyAccessComponent()}
                            clientInformation={clientInformation}
                            isJoint={isJoint}
                            optionsData={optionsData}
                        />
                    ),
                    formType: 'homeExpenses',
                };
            case 'investment_properties':
                return {
                    title: intl.get('investmentProperty.title'),
                    content: (
                        <InvestmentPropertyForm
                            handleSave={handleSave}
                            model={model}
                            readOnlyAccess={readOnlyAccessComponent()}
                            clientInformation={clientInformation}
                            isJoint={isJoint}
                            optionsData={optionsData}
                        />
                    ),
                    formType: 'InvestmentProperty',
                };
            default:
                return null;
        }
    };
    return (
        <FormSpy subscription={{ values: true }}>
            {({ values }) => (
                <div className="portal-part">
                    <Accordion accordion={false}>
                        <AccordionItem>
                            <AccordionItemTitle>
                                <CashFlowHeaderListing
                                    title={intl.get(
                                        'cashflowlisting.personalExpenses.investmentExpenses'
                                    )}
                                    isAccordion
                                    clientTotalValue={clientTotalValue}
                                    partnerTotalValue={partnerTotalValue}
                                    jointTotalValue={jointTotalValue}
                                    currency={currency}
                                    isJointHide={!isJoint}
                                />
                            </AccordionItemTitle>
                            <AccordionItemBody>
                                {Get(values, 'detailResult') && (
                                    <CashFlowListingGroup
                                        varPrefix={`${varPrefix}`}
                                        dataArray={values.detailResult}
                                        isRoundColorRed
                                        currency={currency}
                                        isJointHide={!isJoint}
                                        isExternalModal
                                        onEditClick={(model, client) =>
                                            setModal(showModalByUrl(model,client))
                                        }
                                    />
                                )}
                            </AccordionItemBody>
                        </AccordionItem>
                    </Accordion>
                </div>
            )}
        </FormSpy>
    );
};

export default InvestmentExpenses;
