import { useReducer } from 'react';

const modalDefault = () => ({
    modal: undefined,
    dialogProperties: undefined,
});

export const useModalActions = () => {
    const [state, dispatch] = useReducer(modalReducer, modalDefault);

    const setModal = modal => {
        dispatch({ type: 'SET_MODAL', modal });
    };

    const clearModal = () => {
        dispatch({ type: 'CLEAR_MODAL' });
    };

    const showConfirmationDialog = ({ message, onYes }) => {
        dispatch({ type: 'SHOW_CONFIRMATION_MODAL', message, onYes });
    };

    const hideConfirmationDialog = () => {
        dispatch({ type: 'HIDE_CONFIRMATION_MODAL' });
    };

    return [
        {
            state,
            setModal,
            clearModal,
            showConfirmationDialog,
            hideConfirmationDialog,
        },
    ];
};

const modalReducer = (state, action) => {
    switch (action.type) {
        case 'SET_MODAL':
            return {
                ...state,
                modal: action.modal,
            };
        case 'CLEAR_MODAL':
            return {
                ...state,
                modal: undefined,
            };
        case 'SHOW_CONFIRMATION_MODAL':
            return {
                ...state,
                dialogProperties: {
                    isOpen: true,
                    message: action.message,
                    onYes: action.onYes,
                },
            };
        case 'HIDE_CONFIRMATION_MODAL':
            return {
                ...state,
                dialogProperties: undefined,
            };
        default:
            return state;
    }
};
