import React, { } from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { enumOptionsActions } from "../../../actions";
import { FormButtons } from "../../../components";
import arrayMutators from 'final-form-arrays';
import ILASDetailsForm from "../ILASInvestment/ILASDetailsForm";
import ILASTabs from "../ILASInvestment/ILASTabs";

import InvestmentAllocation from "../InvestmentAllocation";
import ContinuityBonus from "../ILASInvestment/ContinuityBonus";

import AdditionalInvestmentTopUpPlan from "../ILASInvestment/AdditionalInvestmentTopUpPlan";
import CPFClassification from "./CPFClassification";

const CPFInvestmentLink = ({ optionsData, model, handleSave }) => {
    if (!model.investmentReturnsTradingCurrency) {
        Object.assign(model, { investmentReturnsTradingCurrency: 'SGD' })
    }
    return (
        <div>
            <Form
                onSubmit={handleSave}
                initialValues={model}
                mutators={{
                    // potentially other mutators could be merged here
                    ...arrayMutators,
                }}
                initialValuesEqual={() => true}
                render={({
                    handleSubmit,
                    reset,
                    mutators }) => (
                        <form
                            id="cpfCashAccount"
                            onSubmit={handleSubmit}
                            noValidate
                        >
                            <div className="portal-form-popup">
                                <CPFClassification value={model.classification} disabled />
                                <div className="subtitle2">
                                    Investment Linked Life Insurance & Annuity Details
                                </div>
                                <div className="d-flex">
                                    <div className="w-50 pr-3">
                                        <ILASDetailsForm optionsData={optionsData} />
                                        <ILASTabs optionsData={optionsData} mutators={mutators} />
                                    </div>
                                    <div className="w-50">
                                        
                                        <InvestmentAllocation
                                            addInvestmentAllocation={mutators.push}
                                            removeInvestmentAllocation={mutators.remove}
                                        />
                                        <ContinuityBonus
                                            addContinuityBonus={mutators.push}
                                            removeContinuityBonus={mutators.remove}
                                        />
                                        <div className="additional">
                                            <AdditionalInvestmentTopUpPlan
                                                optionsData={optionsData}
                                                addAdditionalInvestmentTopUpPlan={mutators.push}
                                                removeAdditionalInvestmentTopUpPlan={mutators.remove}
                                            />
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <FormButtons
                                reset={reset}
                            />
                        </form>
                    )}
            />
        </div>


    )
}


function mapStateToProps(state) {
    const { optionsData } = state.enumOptions;

    return {
        optionsData,
    };
}

function mapDispatchToProps(dispatch) {

    return bindActionCreators(enumOptionsActions, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CPFInvestmentLink);