/*
    Author : Erik (erik.malik@devstack.com.au)
    Description : Cash account main page (modal)
*/

import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import intl from "react-intl-universal"
import { netWorthFormActions, modalWindowActions } from "../../actions"
import { modalWindow } from "../../constants/ModalWindowLists"
import NetWorthForm, {
  NetWorthPath,
  NetWorthFormPropsComposer
} from "./NetWorthForm"
import CashAccountForm from "../../forms/CashAccountForm"

class CashAccount extends NetWorthForm {
  constructor(props) {
    super(
      props,
      intl.get("cashAccount.form.title"),
      modalWindow.CASH_ACCOUNT_FORM,
      NetWorthPath.cashAccounts,
      "CashAccount"
    )
  }

  setDefaultData = (originalModel, defaultCurrency) => {
    let model = originalModel
    if (!originalModel || (!originalModel.accountCurrency && defaultCurrency)) {
      if (!originalModel) {
        model = { accountCurrency: defaultCurrency }
      } else {
        model.accountCurrency = defaultCurrency
      }
    }

    return model
  }

  render() {
    const { optionsData, model, client, partner, isJoint, currency } =
      this.props
    const {
      handleSave,
      readOnlyAccessComponent,
      contentWrapper,
      setDefaultData
    } = this
    return contentWrapper(
      <CashAccountForm
        handleSave={handleSave}
        model={setDefaultData(model, currency)}
        optionsData={optionsData}
        clientInformation={{ client, partner, isJoint }}
        readOnlyAccess={readOnlyAccessComponent()}
        defaultCurrency={currency}
      />
    )
  }
}

function mapStateToProps(state) {
  return NetWorthFormPropsComposer(
    state,
    modalWindow.CASH_ACCOUNT_FORM,
    NetWorthPath.cashAccounts
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, netWorthFormActions, modalWindowActions),
    dispatch
  )
}

CashAccount = connect(mapStateToProps, mapDispatchToProps)(CashAccount)
export default CashAccount
