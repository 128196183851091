import React, { Component, Fragment, useState } from "react"
import { connect } from "react-redux"
import intl from "react-intl-universal"
import { bindActionCreators } from "redux"
import { Pie } from "react-chartjs-2"
import { InsuranceApi } from "../../../api"
import { getSymbolFromCode } from "../../../helpers"

import "./InsuranceDashboard.css"
import { DefaultPieChartLegendsOptions } from "../../../helpers/ChartHelper"
import {
  numberWithCommas,
  numberFormat
} from "../../../helpers/CurrencyConversion"
import IumSummary from "./IumSummary"

function sumOfObject(obj) {
  if (obj) {
    return Object.keys(obj).reduce(
      (sum, key) => sum + parseFloat(obj[key] || 0),
      0
    )
  }
  return 0
}

function InsuranceSummaryItem(props) {
  const [showSubitem, setShowSubitem] = useState(false)
  const subtitleMap = {
    lifeInsuranceTotal: "Life Insurance",
    totalPermanentDisabilityInsuranceTotal:
      "Total Permanent Disability Insurance",
    criticalIllnessInsuranceTotal: "Critical Illness Insurance Total",
    incomeProtectionInsuranceTotal: "Income Protection Insurance",
    motorVehicleInsuranceTotal: "Motor Vehicle Insurance",
    personalContentsInsuranceTotal: "Personal Contents Insurance",
    fireContentsInsuranceTotal: "Fire Contents Insurance",
    loanInsuranceTotal: "Loan Insurance",
    collectibleInsuranceTotal: "Collectible Insurance",
    otherGeneralInsuranceTotal: "Other General Insurance",
    healthAndMedicalInsuranceTotal: "Health & Medical Insurance"
  }
  const currencySymbol = props.currency
    ? getSymbolFromCode(props.currency)
    : getSymbolFromCode("MYR")
  return (
    <div className="insurance-summary ">
      <span
        className="g-summary-box"
        onDoubleClick={() => setShowSubitem(!showSubitem)}
      >
        <div className="text">{props.title}</div>
        <div className="figure">
          {currencySymbol}
          {numberWithCommas(sumOfObject(props.item))}
        </div>
      </span>
      {showSubitem &&
        Object.keys(props.item).map((key) => (
          <div className="sub-item">
            <div className="text">{subtitleMap[key]}</div>
            <div className="figure">
              {currencySymbol}
              {numberWithCommas(props.item[key])}
            </div>
          </div>
        ))}
    </div>
  )
}

class InsuranceDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      insuranceSummaryClassification: "",
      client: null,
      insuranceSummary: null
    }
  }

  componentDidUpdate(previousProps) {
    const { insuranceSummaryClassification } = this.state
    if (
      (previousProps.currency !== this.props.currency ||
        previousProps.insuranceData !== this.props.insuranceData) &&
      this.props.client.id
    ) {
      InsuranceApi.getInsuranceSummary(
        this.props.client.id,
        this.props.currency,
        insuranceSummaryClassification === "ium"
          ? ""
          : insuranceSummaryClassification
      ).then((response) => {
        this.setState((prevProps) => ({
          ...prevProps,
          insuranceSummary: response.data
        }))
      })
    }
  }

  changeInsuranceSummaryClassification = async (e) => {
    const { value } = e.target
    if (value === "ium") {
      this.setState((prevState) => ({
        ...prevState,
        insuranceSummaryClassification: value
      }))
      InsuranceApi.getInsuranceSummary(
        this.props.client.id,
        this.props.currency,
        ""
      ).then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          insuranceSummary: response.data
        }))
      })
    }
    if (value !== "ium") {
      InsuranceApi.getInsuranceSummary(
        this.props.client.id,
        this.props.currency,
        value
      ).then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          insuranceSummaryClassification: value,
          insuranceSummary: response.data
        }))
      })
    }
  }

  getInsuranceSummaryChartData = () => {
    const { insuranceSummary } = this.state

    const personalInsuranceTotal =
      insuranceSummary && insuranceSummary.personalInsuranceSummary
        ? sumOfObject(insuranceSummary.personalInsuranceSummary)
        : 0

    const generalInsuranceTotal =
      insuranceSummary && insuranceSummary.generalInsuranceSummary
        ? sumOfObject(insuranceSummary.generalInsuranceSummary)
        : 0

    const healthMedicalInsuranceTotal =
      insuranceSummary && insuranceSummary.healthAndMedicalInsuranceSummary
        ? sumOfObject(insuranceSummary.healthAndMedicalInsuranceSummary)
        : 0

    // 20201120 MABT-395
    const ilasInsuranceTotal =
      insuranceSummary && insuranceSummary.ilasSummary
        ? sumOfObject(insuranceSummary.ilasSummary)
        : 0

    let insuranceSummaryPieChartData = {
      labels: [
        intl.get("insurance.title.personal"),
        intl.get("insurance.title.general"),
        intl.get("insurance.title.healthAndMedicalInsurances"),
        intl.get("insurance.title.ilas")
      ],
      datasets: [
        {
          data: [
            personalInsuranceTotal,
            generalInsuranceTotal,
            healthMedicalInsuranceTotal,
            ilasInsuranceTotal
          ],
          backgroundColor: ["#8557d8", "#b075e1", "#d496eb", "#f2baf8"] //MABT-395
        }
      ]
    }

    // MABT-239 empty data
    const emptyData = {
      labels: [""],
      datasets: [
        {
          data: [0.1],
          backgroundColor: ["#dadada"]
        }
      ]
    }

    if (insuranceSummaryPieChartData) {
      insuranceSummaryPieChartData =
        insuranceSummaryPieChartData.datasets[0].data.some((item) => item > 0)
          ? insuranceSummaryPieChartData
          : emptyData
    }

    // 20200721 END of MABT-239 empty data

    return insuranceSummaryPieChartData
  }

  render() {
    const { insuranceSummaryClassification, insuranceSummary } = this.state

    const { showInsuranceSummary } = this.props

    const { currency } = this.props
    return (
      <Fragment>
        <div className={`graph-panel w-100`}>
          <div
            className="title text-center"
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "row"
            }}
          >
            {showInsuranceSummary ? (
              <div style={{ flex: 3 }}>
                {intl.get("insurance.dashboard.title")}
              </div>
            ) : (
              <div>&nbsp;</div>
            )}
          </div>
          {showInsuranceSummary && (
            <Fragment>
              <div className="select-box">
                <select
                  required
                  value={insuranceSummaryClassification}
                  onChange={this.changeInsuranceSummaryClassification}
                >
                  <option value="">
                    {intl.get("insurance.dashboard.options.overview")}
                  </option>
                  <option value="CLIENT">
                    {intl.get("insurance.dashboard.options.client")}
                  </option>
                  <option value="PARTNER">
                    {intl.get("insurance.dashboard.options.partner")}
                  </option>
                  <option value="JOINT">
                    {intl.get("insurance.dashboard.options.joint")}
                  </option>
                  <option value="ium">IUM</option>
                </select>
              </div>

              <div className="graph-holder">
                <img
                  alt=""
                  src="images/graph-sample.jpg"
                  className="img-responsive"
                />

                {/* if data is available */}
                {insuranceSummaryClassification !== "ium" && (
                  <>
                    {this.getInsuranceSummaryChartData().datasets[0].data[0] !==
                      0.1 && (
                      <Pie
                        data={this.getInsuranceSummaryChartData()}
                        width={260}
                        height={250}
                        legend={DefaultPieChartLegendsOptions}
                        options={{
                          tooltips: {
                            callbacks: {
                              // 20200717 MABT 359
                              title(tooltipItem, data) {
                                return data.labels[tooltipItem[0].index]
                              },

                              label(tooltipItem, data) {
                                return numberFormat(
                                  data.datasets[tooltipItem.datasetIndex].data[
                                    tooltipItem.index
                                  ],
                                  currency
                                )
                              }
                            }
                          }
                        }}
                      />
                    )}

                    {/* empty data */}
                    {this.getInsuranceSummaryChartData().datasets[0].data[0] ==
                      0.1 && (
                      <Pie
                        data={this.getInsuranceSummaryChartData()}
                        width={200}
                        height={250}
                        legend={{
                          display: false,
                          labels: {
                            display: false
                          }
                        }}
                        options={{
                          tooltips: { enabled: false }
                        }}
                      />
                    )}
                  </>
                )}
              </div>
              {insuranceSummaryClassification !== "ium" && (
                <div className="graph-summary">
                  <div className="subtitle">
                    {intl.get("insurance.dashboard.insurancePremiumSummary")}
                  </div>
                  <InsuranceSummaryItem
                    title={intl.get("insurance.title.personal")}
                    item={
                      insuranceSummary
                        ? insuranceSummary.personalInsuranceSummary
                        : {}
                    }
                    currency={currency}
                  />
                  <InsuranceSummaryItem
                    title={intl.get("insurance.title.general")}
                    item={
                      insuranceSummary
                        ? insuranceSummary.generalInsuranceSummary
                        : {}
                    }
                    currency={currency}
                  />
                  <InsuranceSummaryItem
                    title={intl.get(
                      "insurance.title.healthAndMedicalInsurances"
                    )}
                    item={
                      insuranceSummary
                        ? insuranceSummary.healthAndMedicalInsuranceSummary
                        : {}
                    }
                    currency={currency}
                  />
                  <InsuranceSummaryItem
                    title={intl.get("insurance.title.ilas")}
                    item={insuranceSummary ? insuranceSummary.ilasSummary : {}}
                    currency={currency}
                  />
                </div>
              )}
              {insuranceSummaryClassification === "ium" && (
                <IumSummary
                  currency={currency}
                  totalSummary={{
                    personalInsuranceSummary:
                      insuranceSummary.personalInsuranceSummary,
                    generalInsuranceSummary:
                      insuranceSummary.generalInsuranceSummary,
                    healthAndMedicalInsuranceSummary:
                      insuranceSummary.healthAndMedicalInsuranceSummary,
                    ilasSummary: insuranceSummary.ilasSummary
                  }}
                />
              )}
            </Fragment>
          )}
        </div>
      </Fragment>
    )
  }
}
function mapStateToProps(state) {
  return {
    activeAccessLevel: state.appSettings
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}), dispatch)
}

InsuranceDashboard = connect(
  mapStateToProps,
  mapDispatchToProps
)(InsuranceDashboard)
export default InsuranceDashboard
