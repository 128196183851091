import { notesAction } from "../constants/ActionConstants";
import Alert from "react-s-alert";
import intl from "react-intl-universal";
import NotesApi from "../api/NotesApi";
export const notesActions = {
    addNotes,
    getNotes
};

export function getNotesRequest() {
    return { type: notesAction.GET_NOTES_REQUEST };
}
export function addNotesSuccess() {
    return { type: notesAction.ADD_NOTE_SUCCESS };
}
export function getNotesSuccess(data) {
    return { type: notesAction.GET_NOTES_SUCCESS, data };
}

export function addNotes(data) {
    return async dispatch => {
        NotesApi.add(data)
            .then(noteResponse => {
                Alert.success(intl.get("alert.addNoteSuccess"));
                dispatch(addNotesSuccess(noteResponse));
            })
            .catch(error => console.log("error", error));
    };
}

export function getNotes(id) {
    return async dispatch => {
        NotesApi.getNotes(id)
            .then(noteResponse => {
                noteResponse.data.data.notes.length !== 0
                    ? Alert.success(intl.get("alert.getNotesSuccess"))
                    : Alert.error(intl.get("alert.noNotesFound"));

                dispatch(getNotesRequest());
                dispatch(getNotesSuccess(noteResponse.data));
            })
            .catch(error => console.log("error", error));
    };
}
