/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    File : CashFlowHeaderListing
    Description : header for cashflow section (total section)
*/

import React from "react";
import { MalabarMoneyCashflow } from "../../components";
import { getSymbolFromCode } from "../../helpers";
import { DECIMAL_SCALE } from "../../constants/NumberFormatConstants";

const CashFlowHeaderListing = ({
    title,
    isJointHide,
    isAccordion,
    varPrefix,
    clientTotalValue,
    partnerTotalValue,
    jointTotalValue,
    currency = "AUD"
}) => (
    <React.Fragment>
        <div className="portal-form-row portal-form-row-goal2col goals-text">
            {title ? (
                <label className="no-toppadding">
                    <div className="header-button-plus subtitle2 ">
                        {title}{" "}
                        {isAccordion ? (
                            <div className="accordion__arrow" />
                        ) : (
                            ""
                        )}
                    </div>
                </label>
            ) : (
                <label className="no-toppadding">
                    <div className="header-button-plus " />
                </label>
            )}

            <div className="row-holder">
                <MalabarMoneyCashflow
                    value={{
                        currency,
                        value: clientTotalValue
                    }}
                    name={`${varPrefix}.totalClient`}
                    isLabel={false}
                    isForGrid
                    required={false}
                    isRounded
                    disabled
                    decimalScale={DECIMAL_SCALE}
                    symbolPrefix={getSymbolFromCode(currency)}
                    isTotal={true}
                />
            </div>
            {isJointHide ? (
                ""
            ) : (
                <div className="row-holder">
                    <MalabarMoneyCashflow
                        value={{
                            currency,
                            value: partnerTotalValue
                        }}
                        name={`${varPrefix}.totalPartner`}
                        isLabel={false}
                        isForGrid
                        required={false}
                        isRounded
                        disabled
                        decimalScale={DECIMAL_SCALE}
                        symbolPrefix={getSymbolFromCode(currency)}
                        isTotal={true}
                    />
                </div>
            )}
            {isJointHide ? (
                ""
            ) : (
                <div className="row-holder">
                    <MalabarMoneyCashflow
                        value={{
                            currency,
                            value: jointTotalValue
                        }}
                        name={`${varPrefix}.totalJoint`}
                        isLabel={false}
                        isForGrid
                        required={false}
                        isRounded
                        disabled
                        decimalScale={DECIMAL_SCALE}
                        symbolPrefix={getSymbolFromCode(currency)}
                        isTotal={true}
                    />
                </div>
            )}
        </div>
    </React.Fragment>
);

export default CashFlowHeaderListing;
