import React, { useContext } from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from "react-accessible-accordion"
import { Field } from "react-final-form"
import { FieldArray } from "react-final-form-arrays"
import intl from "react-intl-universal"
import {
  renderSelectEnumFieldCustom,
  required,
  onlyAlphabetic,
  renderTextBoxCustom,
  renderDateFieldCustom,
  renderCheckboxCustom,
  composeValidators
} from "../../../helpers"
import { optionsType, getOptions } from "../../../helpers/OptionsHelper"
import PersonalDetailsContext from "../PersonalDetailsContext"

const DependentType = {
  CHILD: "CHILD",
  SIBLING: "SIBLING",
  PARENT: "PARENT"
}

function DependentTable({ type, isJoint }) {
  const {
    mutators: { arrayPush, arrayRemove }
  } = useContext(PersonalDetailsContext)
  const fieldName = "client.personalDetails"
  const optionsData = getOptions([
    optionsType.childrenRelationshipTypeOptions,
    optionsType.siblingRelationshipTypeOptions,
    optionsType.parentRelationshipTypeOptions,
    optionsType.schoolLevelOptions,
    optionsType.livingWithOptions,
    optionsType.generalClassificationOptions
  ])
  const data = {
    [DependentType.CHILD]: {
      dependantRelationOptions: optionsData.childrenRelationshipTypeOptions,
      dependantTitle: intl.get("field.children"),
      relationshipTitle: intl.get("field.childName"),
      // the following is going as input
      relationshipFieldName: `${fieldName}.children`
    },
    [DependentType.SIBLING]: {
      dependantRelationOptions: optionsData.siblingRelationshipTypeOptions,
      dependantTitle: intl.get("field.siblings"),
      relationshipTitle: intl.get("field.siblingName"),
      relationshipFieldName: `${fieldName}.siblings`
    },
    [DependentType.PARENT]: {
      dependantRelationOptions: optionsData.parentRelationshipTypeOptions,
      dependantTitle: intl.get("field.parentsGrandParents"),
      relationshipTitle: intl.get("field.parentName"),
      relationshipFieldName: `${fieldName}.parents`
    }
  }

  // following is the content at the top of table
  const tableHeader = [
    { className: "name", label: data[type].relationshipTitle },
    { className: "relationship", label: intl.get("field.relationship") },
    { className: "dob", label: intl.get("field.birthDate") },
    {
      className: "schoollevel",
      label: intl.get("field.schoolLevel")
    },
    { className: "isdisabled", label: intl.get("field.disabled") }
  ]

  if (isJoint) {
    if (type !== DependentType.CHILD) {
      tableHeader.splice(2, 0, {
        label: "Classification"
      })
    }
  }

  if (type === DependentType.PARENT) {
    tableHeader.splice(tableHeader.length - 2, 1, {
      className: "livingWith",
      label: intl.get("field.livingWith")
    })
  }

  const form = [
    {
      component: renderTextBoxCustom,
      name: "name",
      required: true,
      validate: composeValidators(required, onlyAlphabetic)
    },
    {
      component: renderSelectEnumFieldCustom,
      name: type === DependentType.PARENT ? "dependantType" : "relationship",
      required: true,
      validate: required,
      enumData: data[type].dependantRelationOptions
    },
    {
      component: renderDateFieldCustom,
      name: "birthDate",
      validate: required,
      maxToday: true,
      showAgeFromCurrentAge: true
    },
    {
      component: renderSelectEnumFieldCustom,
      name: "schoolLevel",
      required: true,
      validate: required,
      enumData: optionsData.schoolLevelOptions
    },
    {
      component: renderCheckboxCustom,
      name: "disabled",
      label: intl.get("general.yes")
    }
  ]

  if (isJoint) {
    if (type !== DependentType.CHILD) {
      form.splice(2, 0, {
        component: renderSelectEnumFieldCustom,
        name: "classification",
        enumData: [
          optionsData.generalClassificationOptions[0],
          optionsData.generalClassificationOptions[1]
        ],
        required: true,
        validate: required
      })
    }
  }

  if (type === DependentType.PARENT) {
    form.splice(form.length - 2, 1, {
      component: renderSelectEnumFieldCustom,
      name: "livingWith",
      isLabel: false,
      required: true,
      validate: required,
      enumData: optionsData.livingWithOptions
    })
  }

  return (
    <React.Fragment>
      <div className="subtitle3">
        <label>
          <h3>{data[type].dependantTitle}</h3>
        </label>
        <div className="btn-holder">
          <span
            onClick={() => {
              let defaultObject = {}
              if (type !== DependentType.PARENT) {
                defaultObject = {
                  dependantType: type
                }
              }
              arrayPush(data[type].relationshipFieldName, defaultObject)
            }}
            className="btn btn-plus"
          />
        </div>
      </div>
      <div className="portal-form-row">
        <table
          width="100%"
          border="0"
          cellSpacing="0"
          cellPadding="0"
          className="table-blue"
        >
          <tbody>
            <tr>
              {tableHeader.map((item, idx) => (
                <React.Fragment key={idx + Math.random()}>
                  <th className={item.className} key={idx + Math.random()}>
                    {item.label}
                  </th>
                </React.Fragment>
              ))}
              <th />
            </tr>
            <FieldArray name={data[type].relationshipFieldName}>
              {({ fields }) => {
                return fields.map((name, index, array) => (
                  <tr key={index}>
                    {form.map(({ name: theName, ...item }, a) => (
                      <td
                        key={a}
                        className={
                          item.component === renderDateFieldCustom
                            ? "kyc-dob"
                            : ""
                        }
                      >
                        <Field
                          isForGrid
                          name={`${name}.${theName}`}
                          {...item}
                        />
                      </td>
                    ))}
                    <td>
                      <span
                        onClick={() =>
                          arrayRemove(data[type].relationshipFieldName, index)
                        }
                        className="btn-remove"
                      />
                    </td>
                  </tr>
                ))
              }}
            </FieldArray>
          </tbody>
        </table>
      </div>
    </React.Fragment>
  )
}
function DependentDetails({ client, partner, isJoint }) {
  return (
    <div className="DependentDetails">
      <Accordion>
        <AccordionItem expanded>
          <div
            className={`portal-part dependentDetails-portal-part ${
              client.status == "ARCHIVE" ? "personal-details-disabled" : ""
            }`}
          >
            <AccordionItemTitle>
              <div className="subtitle2">
                {intl.get("personaldetail.dependentDetails")}
                <span className="accordion__arrow" role="presentation" />
              </div>
            </AccordionItemTitle>
            <AccordionItemBody>
              <React.Fragment>
                <DependentTable type={DependentType.CHILD} />
                <DependentTable
                  type={DependentType.SIBLING}
                  isJoint={isJoint}
                />
                <DependentTable type={DependentType.PARENT} isJoint={isJoint} />
              </React.Fragment>
            </AccordionItemBody>
          </div>
        </AccordionItem>
      </Accordion>
    </div>
  )
}

export default DependentDetails
