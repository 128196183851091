import moment from "moment"
export function convertToLocalDateDefault(utcDate) {
  let result = moment.utc(utcDate).local().format("DD/MM/YYYY")
  return result
}
export function convertToLocalDate(utcDate, pattern) {
  let result = moment.utc(utcDate).local().format(pattern)
  return result
}
export function convertToLocalTime(utcDateTime, pattern) {
  let result = moment.utc(utcDateTime).local().format(pattern)
  return result
}

export function convertToLocalTimeDefault(utcDateTime) {
  let result = moment.utc(utcDateTime).local().format("DD/MM/YYYY h:mm:ss a")
  return result
}

export function getStrLocalTimeInfo() {
  let result = moment().format("(UTCZ)HH:mm:ss")
  return result
}

export function addYears(date, years) {
  date.setFullYear(date.getFullYear() + Number(years))
  return date
}

export function generateTimestampId() {
  let result = moment().format("YYYYMMDD_HHmmss")
  return result
}

export function uuidv4() {
  return "xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}
