import { colorAction } from "../constants/ActionConstants"
const initialState = {
  titleBarColour: "",
  fontColour: "",
  dealershipPhoto: ""
}
export function color(state = initialState, action) {
  switch (action.type) {
    case colorAction.COLOR_SUCCESS:
      return {
        ...state,
        color: action.response
      }

    case colorAction.FONT_COLOR_SUCCESS:
      return {
        ...state,
        fontColor: action.response //20210111 asign [fontColor] to state
      }
    case colorAction.SET_COLOR_SUCCESS:
      return {
        ...state,
        titleBarColour: action.response.titleBarColour,
        fontColour: action.response.fontColour,
        dealershipPhoto: action.response.dealershipPhoto
      }

    default:
      return state
  }
}
