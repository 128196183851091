import React, { useState } from "react"
import intl from "react-intl-universal"
import { Form, FormSpy } from "react-final-form"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import arrayMutators from "final-form-arrays"
import api from "../../lib/api"
import {
  clientDataAdapter,
  optionsType,
  getOptions,
  getSymbolFromCode,
  getCurrency
} from "../../helpers"
import MotorVehicleMainForm from "../../forms/MotorVehicleMainForm"
import InsuranceDetails from "../../forms/sub-forms/InsuranceDetails"
import { FormButtons } from "../../components"
import CollectibleAntiqueArtsMainForm from "../../forms/CollectibleAntiqueArtsMainForm"
import PersonalContentMainForm from "../../forms/PersonalContentMainForm"
import {
  LifeInsuranceForm,
  TotalPermanentDisabilityInsuranceForm,
  CriticalIllnessInsuranceForm,
  HealthAndMedicalInsuranceForm,
  IncomeProtectionInsuranceForm,
  OtherGeneralInsuranceForm
} from "./_form"
import InvestmentPropertyMainForm from "../../forms/InvestmentPropertyMainForm"
import ResidencePropertyMainForm from "../../forms/ResidencePropertyMainForm"
import CarLeasing from "../../forms/sub-forms/CarLeasing"
import CarFinancing from "../../forms/sub-forms/CarFinancing"
import CarExpense, {
  CarExpenseMutators,
  adjustYearlyExpensesOnLoad
} from "../../forms/sub-forms/CarExpense"
import GeneralLoanDetails from "../../forms/sub-forms/GeneralLoanDetails"
import PropertyExpenses from "../../forms/sub-forms/PropertyExpenses"
import MortgageDetails from "../../forms/sub-forms/MortgageDetails"
import CoOwnershipDetails from "../../forms/sub-forms/CoOwnershipDetails"
import { calculateYearlyExpensesOnLoad } from "../../forms/ResidencePropertyForm"
import { CountryList } from "../../constants/Lists"
import { translateListLabel } from "../../helpers/list-translation"
import ILASInvestmentForm from "../NetWorth/ILASInvestment/ILASInvestmentForm"
import Get from "../../helpers/NullHelper"
import NotificationApi from "../../api/NotificationApi"
import Alert from "react-s-alert"
import { FileOperationApi } from "../../api"
import { insuranceTypesEnums } from "../../utils/enums/Enum"

export const InsurancePageActions = ({
  state,
  setState,
  setModal,
  clearModal,
  hrefs
}) => {
  const [value, setValue] = useState(false)
  const StateMutator = {
    updateSectionData: (section, sectionData) =>
      setState((prevState) => ({
        ...prevState,
        insurance: {
          ...prevState.insurance,
          [section]: sectionData
        }
      })),
    updateSaveStatus: () => {
      setValue(!value)
      setState((prevState) => ({
        ...prevState,
        savedForm: value
      }))
    },

    setClient: (clientData) =>
      setState((prevState) => ({
        ...prevState,
        client: clientData,
        currency: clientData.taxResidency
          ? getCurrency(clientData.taxResidency)
          : "MYR"
      })),
    setClientWithPartner: (clientData, partnerData) => {
      setState((prevState) => ({
        ...prevState,
        client: clientData,
        partner: partnerData,
        isJoint: true,
        currency: clientData.taxResidency
          ? getCurrency(clientData.taxResidency)
          : "MYR"
      }))
    },
    removeDeletedInsurance: ({ id, type, typeGroup }) => {
      setState((prevState) => {
        const typeData = [
          ...prevState.insurance[typeGroup][type].client,
          ...prevState.insurance[typeGroup][type].partner,
          ...prevState.insurance[typeGroup][type].joint
        ].filter((item) => item.id !== id)

        return {
          ...prevState,
          insurance: {
            ...prevState.insurance,
            [typeGroup]: {
              ...prevState.insurance[typeGroup],
              [type]: groupDataByClassification(typeData)
            }
          }
        }
      })
    },
    toggleSection: (section) =>
      setState((prevState) => ({
        ...prevState,
        openedSection: toogleOppenedSection(section)
      }))
  }
  const optionsData = {
    ...getOptions([
      optionsType.frequencyOptions,
      optionsType.hospitalCoverTypeOptions,
      optionsType.peopleCoveredOptions,
      optionsType.policyAreaCoveredOptions,
      optionsType.generalInsuranceTypeOptions,
      optionsType.insurancePolicyTypeOptions,
      optionsType.coverageTypeOptions,
      optionsType.insuranceRiderTypeOptions,
      optionsType.benefitPeriodOptions,
      optionsType.propertyTypeOptions,
      optionsType.currencyOptions,
      optionsType.leaseTypeOptions,
      optionsType.loanInterestTypeOptions,
      optionsType.loanFrequencyOptions,
      optionsType.criticalIllnessPolicyTypeOptions,
      optionsType.relationshipTypeOptions,
      optionsType.managedByOptions
    ]),

    countryOptions: translateListLabel(CountryList)
  }

  const {
    client,
    partner,
    isJoint,
    currency,
    openedSection = [],
    insurance = {}
    // savedForm = false
  } = state

  let openedSection_ = [
    "ilas",
    "personal",
    "general",
    "healthAndMedicalInsurances"
  ]

  const skeletonData = () => ({
    personal: {
      lifeInsurances: {
        title: intl.get("insurance.title.personal.lifeInsurance")
      },
      totalDisabilityInsurances: {
        title: intl.get(
          "insurance.title.personal.totalAndPermanentDisabilityInsurance"
        )
      },
      criticalIllnessInsurances: {
        title: intl.get("insurance.title.personal.criticalIllnessInsurance")
      },
      incomeProtectionInsurances: {
        title: intl.get("insurance.title.personal.incomeProtectionInsurance")
      }
    },
    general: {
      motorVehicleInsurances: {
        title: intl.get("insurance.title.general.motorVehicleInsurance"),
        isNotAppendable: true
      },
      personalContentsInsurances: {
        title: intl.get("insurance.title.general.personalContentsInsurance"),
        isNotAppendable: true
      },
      contentsInsurances: {
        title: intl.get("insurance.title.general.fireAndContentsInsurance"),
        isNotAppendable: true
      },
      loanInsurances: {
        title: intl.get("insurance.title.general.loanInsurance"),
        isNotAppendable: true
      },
      collectibleInsurances: {
        title: intl.get("insurance.title.general.collectibleInsurance"),
        isNotAppendable: true
      },
      otherGeneralInsurances: {
        title: intl.get("insurance.title.general.otherGeneralInsurances")
      }
    },
    healthAndMedicalInsurances: {
      healthAndMedicalInsurances: {
        title: intl.get("insurance.title.personal.healthAndMedicalInsurance")
      }
    },
    ilas: {
      ilas: {
        title: intl.get("insurance.title.ilas"),
        isNotAppendable: true
      }
    }
  })

  function submitForm({ type, model, onSaveSuccess, clearModal }) {
    if (type === "healthAndMedicalInsurances") {
      if (model.peopleCovered === "FAMILY") {
        model.classification = "JOINT"
      }
      model.policyType = "HEALTH_AND_MEDICAL_INSURANCE"
    }

    const url = client.getLink(type)
    api.put(url, model).then((res) => {
      let showWarning = NotificationApi.checkAndDisplayWarning()
      if (!showWarning) {
        if (res && res.errorMessage) {
          Alert.error(res.errorMessage)
        } else {
          Alert.success("Data has been saved successfully!")
          onSaveSuccess(getTypeGroup(type))
          clearModal()
        }
      }
    })
  }

  function initSectionData(section, client) {
    let url
    if (section === "personal") {
      url = client.getLink("personalInsuranceListing")
    } else if (section === "general") {
      url = client.getLink("generalInsuranceListing")
    } else if (section === "healthAndMedicalInsurances") {
      url = client.getLink("healthAndMedicalInsuranceListing")
    } else if (section === "ilas") {
      url = client.getLink("ilasListing")
    }

    if (url) {
      api
        .get(`${url}?reportingCurrency=${getCurrency(client.taxResidency)}`)
        .then((response) => {
          StateMutator.updateSectionData(
            section,
            groupSectionDataByClassification(response.data)
          )
        })
    }
  }

  function getSectionData(section, client) {
    let url
    if (client) {
      if (section === "personal") {
        url = client.getLink("personalInsuranceListing")
      } else if (section === "general") {
        url = client.getLink("generalInsuranceListing")
      } else if (section === "healthAndMedicalInsurances") {
        url = client.getLink("healthAndMedicalInsuranceListing")
      } else if (section === "ilas") {
        url = client.getLink("ilasListing")
      }

      if (url) {
        api
          .get(`${url}?reportingCurrency=${getCurrency(client.taxResidency)}`)
          .then((response) => {
            StateMutator.updateSectionData(
              section,
              groupSectionDataByClassification(response.data)
            )
          })
      }
    }
  }

  function groupSectionDataByClassification(data = {}) {
    //if(data && Array.isArray(data)){
    Object.keys(data).forEach((key) => {
      const subData = data[key] || []
      data[key] = groupDataByClassification(subData)
    })
    //}

    return data
  }

  function groupDataByClassification(data = []) {
    if (Array.isArray(data)) {
      return {
        client: data.filter((item) => item.classification === "CLIENT"),
        partner: data.filter((item) => item.classification === "PARTNER"),
        joint: data.filter((item) => item.classification === "JOINT")
      }
    } else {
      return {
        client: data,
        partner: data,
        joint: data
      }
    }
  }

  function toogleOppenedSection(section) {
    if (openedSection.includes(section)) {
      return openedSection.filter((s) => s !== section)
    }

    return [...openedSection_, section]
  }

  function createModalForm({ type, props }) {
    props.formType = type

    if (type === "lifeInsurances") {
      if (!props.model) {
        props.model = {}
      }
      if (!props.model.basicAssured) {
        props.model.basicAssured = {
          currency,
          value: null
        }
      }
      if (!props.model.policyPremium) {
        props.model.policyPremium = {
          currency,
          value: null
        }
      }
      return {
        title: intl.get("insurance.title.personal.lifeInsurance"),
        content: <LifeInsuranceForm {...props} />
      }
    }
    if (type === "totalDisabilityInsurances") {
      return {
        title: intl.get(
          "insurance.title.personal.totalAndPermanentDisabilityInsurance"
        ),
        content: <TotalPermanentDisabilityInsuranceForm {...props} />
      }
    }
    if (type === "criticalIllnessInsurances") {
      return {
        title: intl.get("insurance.title.personal.criticalIllnessInsurance"),
        content: <CriticalIllnessInsuranceForm {...props} />
      }
    }
    if (type === "healthAndMedicalInsurances") {
      return {
        title: intl.get("insurance.title.personal.healthAndMedicalInsurance"),
        content: <HealthAndMedicalInsuranceForm {...props} />
      }
    }
    if (type === "incomeProtectionInsurances") {
      return {
        title: intl.get("insurance.title.personal.incomeProtectionInsurance"),
        content: <IncomeProtectionInsuranceForm {...props} />
      }
    }
    if (type === "otherGeneralInsurances") {
      return {
        title: intl.get("insurance.title.general"),
        content: <OtherGeneralInsuranceForm {...props} />
      }
    }

    if (type === "ilas") {
      return {
        title: intl.get("insurance.title.ilas"),
        content: <ILASInvestmentForm {...props} />
      }
    }
  }

  function getTypeGroup(type) {
    const skeleton = skeletonData()
    let groupType
    for (const key of Object.keys(skeleton)) {
      if (skeleton[key][type]) {
        groupType = key
        break
      }
    }
    return groupType
  }

  // 20200415 General Insurance
  function createLinkedInsuranceForm({
    type,
    objectType,
    insuranceType,
    data,
    mutators,
    values
  }) {
    const props = {
      type,
      client,
      partner,
      isJoint,
      readOnly: true,
      mutators
    }

    props.formType = type

    if (type === "motorVehicleInsurances") {
      return (
        <div className="portal-form-popup d-flex">
          <MotorVehicleMainForm {...props} />

          <div className="portal-form-half">
            <Tabs>
              <TabList>
                <Tab>{intl.get("tabs.carExpenses")}</Tab>
                {data.underLoan && <Tab>{intl.get("tabs.loanDetails")}</Tab>}
                {data.underLease && (
                  <Tab>{intl.get("tabs.carLeaseDetails")}</Tab>
                )}
                {data.underHirePurchase && (
                  <Tab>{intl.get("tabs.hirePurchaseDetails")}</Tab>
                )}

                {/* 20200924 MABT-400 */}
                {values.insured && (
                  <Tab>{intl.get("tabs.insuranceDetails")}</Tab>
                )}
              </TabList>

              <TabPanel>
                <CarExpense
                  currencyOptions={optionsData.currencyOptions}
                  onMonthlyExpenseChange={CarExpenseMutators.setMonthlyExpense}
                  onYearlyExpenseChange={CarExpenseMutators.setYearlyExpense}
                  values={data}
                  currency={getSymbolFromCode(
                    data.vehicleExpenses.expensesCurrency
                  )}
                  readOnly
                />
              </TabPanel>
              {data.underLoan && (
                <TabPanel>
                  <GeneralLoanDetails
                    optionsData={optionsData}
                    defaultCurrency={
                      data && data.loan && data.loan.borrowingsCurrency
                    }
                    values={data.marginLoan || {}}
                    readOnly
                  />
                </TabPanel>
              )}
              {data.underLease && (
                <TabPanel>
                  <CarLeasing
                    optionsData={optionsData}
                    readOnly
                    currency={data && data.loan && data.loan.borrowingsCurrency}
                  />
                </TabPanel>
              )}
              {data.underHirePurchase && (
                <TabPanel>
                  <CarFinancing
                    optionsData={optionsData}
                    readOnly
                    currency={data && data.loan && data.loan.borrowingsCurrency}
                  />
                </TabPanel>
              )}

              {/* 20200925 MABT-400 */}
              {data.insured && (
                <TabPanel>
                  <InsuranceDetails
                    optionsData={optionsData}
                    currency={
                      data && data.insuredAmount && data.insuredAmount.currency
                    }
                  />
                </TabPanel>
              )}
            </Tabs>
          </div>
        </div>
      )
    }
    // let paramForCollectiblese
    // 20200929 MABT-400
    if (type === "collectibleInsurances") {
      return (
        <div className="portal-form-popup d-flex">
          <div
            className={values.insured ? "portal-form-half" : "portal-form-full"}
          >
            <CollectibleAntiqueArtsMainForm {...props} model={data} />
          </div>

          {/* 20200929 MABT-400 toggle */}
          {values.insured && (
            <div className="portal-form-half">
              <div className="subtitle2">
                {intl.get("tabs.insuranceDetails")}
              </div>

              <InsuranceDetails
                optionsData={optionsData}
                currency={
                  data && data.insuredAmount && data.insuredAmount.currency
                }
              />
            </div>
          )}
        </div>
      )
    }
    if (type === "personalContentsInsurances") {
      return (
        <div className="portal-form-popup d-flex">
          {/* 20200924 MABT-400 */}
          <div
            className={
              values.personalItemInsured
                ? "portal-form-half"
                : "portal-form-full"
            }
          >
            <PersonalContentMainForm {...props} />
          </div>

          {values.personalItemInsured && (
            <div className="portal-form-half">
              <Tabs>
                <TabList>
                  <Tab>{intl.get("tabs.insuranceDetails")}</Tab>
                </TabList>

                <TabPanel>
                  <InsuranceDetails
                    optionsData={optionsData}
                    currency={getSymbolFromCode(
                      data && data.insuredAmount && data.insuredAmount.currency
                    )}
                  />
                </TabPanel>
              </Tabs>
            </div>
          )}
        </div>
      )
    }

    if (objectType === "InvestmentProperty") {
      return (
        <div className="portal-form-popup d-flex">
          <InvestmentPropertyMainForm {...props} optionsData={optionsData} />
          <div className="portal-form-half">
            <div className="subtitle2">{intl.get("tabs.insuranceDetails")}</div>
            {insuranceType === "CONTENT" && (
              <InsuranceDetails
                optionsData={optionsData}
                objectName="fireAndContentInsurance"
                currency={getSymbolFromCode(
                  data.propertyExpenses.expensesCurrency
                )}
              />
            )}
            {insuranceType === "LOAN" && (
              <InsuranceDetails
                optionsData={optionsData}
                objectName="mortgageInsurance"
                currency={getSymbolFromCode(
                  data.propertyExpenses.expensesCurrency
                )}
              />
            )}
          </div>
        </div>
      )
    }

    if (
      objectType === "OtherResidencePropertyOwnership" ||
      objectType === "PrimaryResidencePropertyOwnership"
    ) {
      return (
        <div className="portal-form-popup d-flex">
          <ResidencePropertyMainForm
            {...props}
            optionsData={optionsData}
            defaultCurrency={data.propertyExpenses.expensesCurrency}
          />
          <div className="portal-form-half">
            <Tabs>
              <TabList>
                <Tab>{intl.get("tabs.propertyExpenses")}</Tab>
                <React.Fragment>
                  {data &&
                    data.propertyDetails &&
                    data.propertyDetails.underMortgage && (
                      <Tab>{intl.get("tabs.mortgageDetails")}</Tab>
                    )}
                  {data &&
                    data.propertyDetails &&
                    values.propertyDetails.hasMultiOwners && (
                      <Tab>{intl.get("tabs.coOwnershipDetails")}</Tab>
                    )}

                  {/* 20200924 MABT-400 */}
                  {(values.propertyDetails.fireAndContentInsured ||
                    values.propertyDetails.mortgageInsured) && (
                    <Tab>{intl.get("tabs.insuranceDetails")}</Tab>
                  )}
                </React.Fragment>
              </TabList>
              <TabPanel>
                <PropertyExpenses
                  optionsData={optionsData}
                  model={data}
                  disabled
                />
              </TabPanel>
              {data &&
                data.propertyDetails &&
                data.propertyDetails.underMortgage && (
                  <TabPanel>
                    <MortgageDetails
                      optionsData={optionsData}
                      readOnlyAccess
                      model={data}
                      isAustralia={() =>
                        client.personalDetails.employmentDetails
                          .taxResidency === "Australia" || false
                      }
                      readOnly
                    />
                  </TabPanel>
                )}
              {values && values.propertyDetails.hasMultiOwners && (
                <TabPanel>
                  <CoOwnershipDetails
                    varPrefix={"coOwnership"}
                    names={{
                      client: Get(
                        client,
                        "personalDetails.personalInformation.firstName"
                      ),
                      partner: Get(
                        partner,
                        "personalDetails.personalInformation.firstName"
                      )
                    }}
                  />
                </TabPanel>
              )}
              <TabPanel>
                {/* 20200925 MABT-400 */}
                <InsuranceDetails
                  optionsData={optionsData}
                  objectName="fireAndContentInsurance"
                  currency={getSymbolFromCode(
                    data.propertyExpenses.expensesCurrency
                  )}
                  varPrefix={"fireAndContentInsurance"}
                  varPrefix2={"mortgageInsurance"}
                  isFireAndContentInsured={
                    values.propertyDetails.fireAndContentInsured
                  }
                  isLoanInsured={values.propertyDetails.mortgageInsured}
                  values={values}
                />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      )
    }

    return <p />
  }

  function getModalTitle({ type, objectType }) {
    if (type === "motorVehicleInsurances") {
      return "Motor Vehicle Information"
    }
    if (type === "personalContentsInsurances") {
      return "Personal Contents Information"
    }
    if (objectType === "OtherResidencePropertyOwnership") {
      return "Other Residence Property Ownership Information"
    }
    if (objectType === "PrimaryResidencePropertyOwnership") {
      return "Primary Residence Property Ownership Information"
    }
    return "Insurance Information"
  }

  function showLinkedInsuranceForm({
    url,
    type,
    objectType,
    insuranceType,
    currency
  }) {
    api.get(url.get).then((response) => {
      const { data } = response
      const section = getTypeGroup(type)

      if (type === "motorVehicleInsurances") {
        if (data.underHirePurchase) {
          data.carFinancing = "HIRE_PURCHASE"
        } else if (data.underLease) {
          data.carFinancing = "CAR_LEASE"
        } else if (data.underLoan) {
          data.carFinancing = "LOAN"
        } else {
          data.carFinancing = "NONE"
        }
        adjustYearlyExpensesOnLoad(data)
      }

      if (
        objectType === "OtherResidencePropertyOwnership" ||
        objectType === "PrimaryResidencePropertyOwnership"
      ) {
        calculateYearlyExpensesOnLoad(data)
      }

      // modal window for [General Insurance]
      setModal({
        title: getModalTitle({ type, objectType }),
        formType: type, // 20200417 added Class
        content: (
          <Form
            onSubmit={(model) => {
              let insuranceModel = {}
              if (
                [
                  "InvestmentProperty",
                  "OtherResidencePropertyOwnership",
                  "PrimaryResidencePropertyOwnership"
                ].includes(objectType)
              ) {
                if (insuranceType === "CONTENT") {
                  insuranceModel = model.fireAndContentInsurance
                } else if (insuranceType === "LOAN") {
                  insuranceModel = model.mortgageInsurance
                }
              } else {
                //20201224 MABT-451
                insuranceModel = model
              }

              api.put(url.initial, insuranceModel).then(() => {
                getSectionData(section, client)
                clearModal()
              })
            }}
            initialValues={data}
            mutators={arrayMutators}
            render={({ handleSubmit, mutators }) => (
              <form id="insuranceForm" onSubmit={handleSubmit}>
                <FormSpy subscription={{ values: true }}>
                  {({ values }) =>
                    createLinkedInsuranceForm({
                      type,
                      objectType,
                      insuranceType,
                      data,
                      mutators,
                      values
                    })
                  }
                </FormSpy>

                <FormButtons />
              </form>
            )}
          />
        )
      })
    })
  }

  function showIlasModal({ id, type }) {
    if (id) {
      api.get(client.getLink(type)).then((response) => {
        const mdl = (response.data || []).find((item) => item.id === id)
        // modal window for [General Insurance]
        setModal({
          title: intl.get("insurance.title.ilas"),
          formType: type, // 20200417 added Class into Modal
          content: (
            <Form
              onSubmit={(model) => {
                const onSaveSuccess = (section) => getSectionData(section)
                StateMutator.updateSaveStatus()

                submitForm({
                  type,
                  model,
                  onSaveSuccess,
                  clearModal
                })
                //clearModal();
              }}
              initialValues={mdl}
              mutators={{
                ...arrayMutators,
                setFormAttribute: (
                  [fieldName, fieldVal],
                  state,
                  { changeValue }
                ) => {
                  changeValue(state, fieldName, () => fieldVal)
                }
              }}
              render={({ handleSubmit, mutators, reset }) => (
                <form id="insuranceForm" onSubmit={handleSubmit}>
                  <ILASInvestmentForm
                    handleSubmit={handleSubmit}
                    optionsData={optionsData}
                    mutators={mutators}
                    reset={reset}
                    readOnlyAccessComponent={() => {}}
                    clientInformation={{
                      client,
                      partner,
                      isJoint
                    }}
                  />
                </form>
              )}
            />
          )
        })
      })
    }
  }

  function showDirectInsuranceForm({ id, type, currency, insuranceTitle }) {
    const props = {
      model: {},
      user: { client, partner, isJoint },
      onSubmit: async (model) => {
        // api call to save the file to backend.
        let proofDocument
        if (model && model.proofDocument && model.proofDocument.file) {
          const formData = new FormData()
          formData.append("sourceType", "CLIENT")
          formData.append("sourceId", client.id)
          formData.append("title", insuranceTitle)
          formData.append("module", "INSURANCE")
          formData.append("moduleId", insuranceTitle)
          formData.append("file", model.proofDocument.file)

          const response = await FileOperationApi.upload(formData)

          proofDocument = {
            genericUploadFileId: response.data.id,
            fileName: response.data.file.filename,
            fileUrl: response.data.file.path
          }
        }
        const modelAlongWithUploadedDocument = {
          ...model,
          proofDocument: proofDocument || model.proofDocument
        }

        const onSaveSuccess = (section) => getSectionData(section)
        StateMutator.updateSaveStatus()
        submitForm({
          type,
          model: modelAlongWithUploadedDocument,
          onSaveSuccess,
          clearModal
        })
      },
      optionsData,
      currency
    }
    if (id) {
      api.get(client.getLink(type)).then((response) => {
        props.model = (response.data || []).find((item) => item.id === id)
        setModal(createModalForm({ type, props }))
      })
    } else {
      setModal(createModalForm({ type, props }))
    }
  }

  function getLinkedInsuranceUrl({
    type,
    insuranceId,
    insuranceType,
    objectType
  }) {
    const pathDictionary = {
      motorVehicleInsurances: "motor_vehicles",
      collectibleInsurances: "collectibles",
      personalContentsInsurances: "personal_contents",
      loanInsurances: "loan_insurancess",
      contentsInsurances: "contents_insurances",
      ilas: "ilas"
    }

    if (pathDictionary[type]) {
      let path = pathDictionary[type]
      if (objectType) {
        switch (objectType) {
          case "InvestmentProperty": {
            path = "investment_properties"
            break
          }
          case "OtherResidencePropertyOwnership": {
            path = "other_residence_properties"
            break
          }
          case "PrimaryResidencePropertyOwnership": {
            path = "primary_residence_property"
            break
          }

          default: {
            return undefined
          }
        }
      }

      const baseUrl = `/client/${client.id}/${path}`
      const typeParam = insuranceType ? `?type=${insuranceType}` : ""
      let get = `${baseUrl}/findByInsurance/${insuranceId}`
      let persist = `${baseUrl}/insurance/${insuranceId}`

      // 20201224 MABT-451 - submit whole json data to backend
      let initial = `${baseUrl}`
      if (typeParam) {
        get += typeParam
        persist += typeParam
      }
      return {
        get,
        persist,
        initial
      }
    }

    return undefined
  }

  return {
    initializeStateOnCreated: ({ clientId }) => {
      api.get(`/client/${clientId}`).then((getClientResponse) => {
        const clientData = clientDataAdapter(getClientResponse.data)

        if (getClientResponse.data.classification === "JOINT") {
          api.get(clientData.getLink("partner")).then((getPartnerResponse) => {
            const partnerData = clientDataAdapter(getPartnerResponse.data)

            StateMutator.setClientWithPartner(clientData, partnerData)

            //get section name
            initSectionData("personal", clientData)
            initSectionData("general", clientData)
            initSectionData("healthAndMedicalInsurances", clientData)
            initSectionData("ilas", clientData)
          })
        } else {
          StateMutator.setClient(clientData)
          getSectionData("personal", clientData)
          getSectionData("general", clientData)
          getSectionData("healthAndMedicalInsurances", clientData)
          getSectionData("ilas", clientData)
        }
      })
    },

    toggleSection: ({ section }) => {
      if (!insurance[section]) {
        getSectionData(section)
      }
      StateMutator.toggleSection(section)
    },

    constructData: (insuranceData = {}) => {
      const insuranceListing = skeletonData()
      ;["personal", "general", "healthAndMedicalInsurances", "ilas"].forEach(
        (key) => {
          Object.keys(insuranceListing[key]).forEach((subKey) => {
            if ((insuranceData[key] || {})[subKey]) {
              const {
                client: clientData,
                partner: partnerData,
                joint: jointData
              } = insuranceData[key][subKey]

              insuranceListing[key][subKey] = {
                ...insuranceListing[key][subKey],
                client: clientData,
                partner: partnerData,
                joint: jointData
              }
            }
          })
        }
      )
      return insuranceListing
    },

    showForm: ({ id, type, insuranceType, objectType, currency = "USD" }) => {
      const url = getLinkedInsuranceUrl({
        type,
        insuranceId: id,
        insuranceType,
        objectType,
        insuranceTitle: insuranceTypesEnums[type] || type.toLowerCase()
      })

      if (type === "ilas") {
        showIlasModal({ id, type })
      } else if (url) {
        showLinkedInsuranceForm({
          url,
          type,
          objectType,
          insuranceType,
          currency,
          insuranceTitle: insuranceTypesEnums[type] || type.toLowerCase()
        })
      } else {
        showDirectInsuranceForm({
          id,
          type,
          currency,
          insuranceTitle: insuranceTypesEnums[type] || type.toLowerCase()
        })
      }
    },

    deleteInsurance: async ({ id, type, insuranceType, objectType }) => {
      const typeGroup = getTypeGroup(type)

      const refreshDataAfterDelete = () =>
        StateMutator.removeDeletedInsurance({
          id,
          type,
          typeGroup
        })
      const urlIs = hrefs[type]
      try {
        const res = await api.delete(`${urlIs._links.self.href}/${id}`)
        if (res) {
          refreshDataAfterDelete()
        }
      } catch (error) {
        console.log("error", error)
      }
    }
  }
}
