import React, { useState, useEffect } from "react"
import intl from "react-intl-universal"
import { RenderSimpleFields } from "../../../components"
import { FieldType } from "../../../components/Form/RenderSimpleFields"
import { getSymbolFromCode } from "../../../helpers"
import LoanApi from "../../../api/LoanApi"
import Alert from "react-s-alert"
import moment from "moment"

const ILASDetailsForm = ({ values, mutators, optionsData }) => {
  const [ccy, setCcy] = useState(
    values.investmentReturnsTradingCurrency
      ? values.investmentReturnsTradingCurrency
      : localStorage.getItem("theCurrency")
  )
  const [ccySymbol, setCcySymbol] = useState(getSymbolFromCode(ccy))
  function onCurrencyChanged(val) {
    setCcy(val)
    setCcySymbol(getSymbolFromCode(val))
  }

  
  function calculateTerm(startDate, endDate, freq) {
    if (startDate && endDate && freq) {
      if(endDate <= startDate){
        Alert.error('Policy maturity date must be bigger than start date!'); 
      }else{
        let model = {
            startDate: startDate,
            endDate: endDate,
            freq: freq
          }
          LoanApi.postCalculateTermsPeriods(model).then(({ data }) => {
            if (mutators && data) {
              mutators.setFormAttribute("policyTerm", data.termInYears)
              mutators.setFormAttribute("policyPeriods", data.periods)
            }
          })
      }  
      
    } 
  }

  function onStartDateChanged(val) {
    calculateTerm(val, values.policyMaturityDate, values.policyPremiumFrequency)
  }

  function onEndDateChanged(val) {
    calculateTerm(values.policyStartDate, val, values.policyPremiumFrequency)
  }

  function onFrequencyChanged(val) {
    calculateTerm(values.policyStartDate, values.policyMaturityDate, val)
  }

  return (
    <>
      
      <RenderSimpleFields
        fields={[
          {
            name: "insuranceOrganization",
            label: intl.get("ilasInvestment.insuranceOrganization"),
            type: FieldType.TEXT,
            required: true
          },

          {
            name: "policyNumber",
            label: intl.get("ilasInvestment.policyNumber"),
            type: FieldType.TEXT,
            required: true
          },
          {
            name: "policyProductName",
            label: intl.get("ilasInvestment.policyProductName"),
            type: FieldType.TEXT
          },
          {
            name: "managedBy",
            label: intl.get("field.managedBy"),
            type: FieldType.ENUM_SELECTION,
            enumData: optionsData.managedByOptions,
            required: false
          },
          {
            name: "assuredPersonName",
            label: intl.get("ilasInvestment.assuredPersonName"),
            type: FieldType.TEXT
          },
          {
            name: "insuranceProtectionPercentage",
            label: intl.get("ilasInvestment.insuranceProtectionPercentage"),
            type: FieldType.PERCENTAGE,
            required: true,
            allowNegative: false
          },
         
          {
            name: "investmentReturnsTradingCurrency",
            label: intl.get("ilasInvestment.tradingCurrency"),
            type: FieldType.ENUM_SELECTION,
            enumData: optionsData.currencyOptions,
            required: true,
            onChange: onCurrencyChanged
          },
          {
            name: "sumAssuredAmount",
            label: intl.get("ilasInvestment.sumAssuredAmount"),
            type: FieldType.MONEY,
            isMoney: true,
            defaultCurrency: ccy,
            symbolPrefix: ccySymbol,
            required: true,
            allowNegative: false
          },
          {
            name: "deathCoverageBenefitAmount",
            label: intl.get("ilasInvestment.deathCoverageBenefitAmount"),
            type: FieldType.MONEY,
            isMoney: true,
            defaultCurrency: ccy,
            symbolPrefix: ccySymbol
          },
          {
            name: "totalDisabilityCoverageBenefitAmount",
            label: intl.get(
              "ilasInvestment.totalDisabilityCoverageBenefitAmount"
            ),
            type: FieldType.MONEY,
            isMoney: true,
            defaultCurrency: ccy,
            symbolPrefix: ccySymbol
          },
          {
            name: "criticalIllnessCoverageBenefitAmount",
            label: intl.get(
              "ilasInvestment.criticalIllnessCoverageBenefitAmount"
            ),
            type: FieldType.MONEY,
            isMoney: true,
            defaultCurrency: ccy,
            symbolPrefix: ccySymbol
          },
          {
            name: "maturityValue",
            label: intl.get("ilasInvestment.maturityValue"),
            type: FieldType.MONEY,
            isMoney: true,
            defaultCurrency: ccy,
            symbolPrefix: ccySymbol
          },
          {
            name: "policyStartDate",
            label: intl.get("ilasInvestment.policyStartDate"),
            type: FieldType.DATE,
            onChange: onStartDateChanged,
            required: true
          },

          {
            name: "policyMaturityDate",
            label: intl.get("ilasInvestment.policyMaturityDate"),
            type: FieldType.DATE,
            onChange: onEndDateChanged,
            required: true
          },
          
          {
            name: "policyPremiumFrequency",
            label: intl.get("ilasInvestment.policyPremiumFrequency"),
            type: FieldType.ENUM_SELECTION,
            enumData: optionsData.frequencyOptions,
            required: true,
            onChange: onFrequencyChanged
          },
          {
            name: "policyPremiumAmount",
            label: intl.get("ilasInvestment.policyPremiumAmount"),
            type: FieldType.MONEY,
            isMoney: true,
            defaultCurrency: ccy,
            symbolPrefix: ccySymbol,
            required: true,
            allowNegative: false
          },
         
          {
            name: "policyTerm",
            label: intl.get("ilasInvestment.policyTerm"),
            type: FieldType.NUMBER,
            readOnly: true
          },
          {
            name: "policyPeriods",
            label: "Number of Periods",
            type: FieldType.NUMBER,
            readOnly: true
          },
        ]}
      />
    </>
  )
}

export default ILASDetailsForm
