import MalabarDefaultRightMenu from "./MalabarRightMenu/MalabarDefaultRightMenu";
import MalabarClientRightMenu from "./MalabarRightMenu/MalabarClientRightMenu";
import MalabarDashboardRightMenu from "./MalabarRightMenu/MalabarDashboardRightMenu";
import MalabarTopMenu from "./MalabarTopMenu";
import JointSelectionMenu from "./JointSelectionMenu";
import FeedbackSelectionMenu from "./FeedbackSelectionMenu";
export {
    MalabarDefaultRightMenu,
    MalabarTopMenu,
    JointSelectionMenu,
    MalabarClientRightMenu,
    MalabarDashboardRightMenu,
    FeedbackSelectionMenu,
};
