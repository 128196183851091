import React from "react";
import { InvestmentPropertyForm } from "../../../forms";

const CPFInvestmentProperty = ({ optionsData, client, partner, isJoint, handleSave, model }) => {

    const currency = 'SGD';
    const initModel = mdl => {
        if (!mdl) {
            mdl = {};
        }
        if (!mdl.mortgage) {
            mdl.mortgage = {};
        }
        if (!mdl.mortgage.borrowingsCurrency) {
            mdl.mortgage.borrowingsCurrency = currency;
        }
        if (!mdl.deductibleExpenses) {
            mdl.deductibleExpenses = {};
        }
        if (!mdl.deductibleExpenses.expensesCurrency) {
            mdl.deductibleExpenses.expensesCurrency = currency;
        }
        return mdl;
    };
    return (

        <InvestmentPropertyForm
            handleSave={handleSave}
            model={initModel(model)}
            clientInformation={{
                client, partner, isJoint
            }}
            optionsData={optionsData}
            currency={currency}
        />

    )
}

export default CPFInvestmentProperty;