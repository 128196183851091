import React, { useState, useEffect } from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import CpdComponent from "./CpdComponent"
import api from "../../lib/api"
import { MalabarDefaultRightMenu } from "../../components"
import Summary from "./Summary"
const Cpd = () => {
  const [availableCoursesCPD, setAvailableCoursesCPD] = useState([])
  const [availableCoursesCPT, setAvailableCoursesCPT] = useState([])
  const [availableCoursesCE, setAvailableCoursesCE] = useState([])
  const [cpdData, setCpdData] = useState(["CPD"])

  const getCpd = async () => {
    try {
      const data = await api.get("/cpd")
      if (data.data) {
        setCpdData(data.data)
      }
    } catch (error) {
      console.log("error is", error)
    }
  }

  const getAvailableCourses = async () => {
    try {
      const data = await api.get(`/cpd/courses?courseType=CPD`)
      setAvailableCoursesCPD(data.data.list.length ? data.data.list : [])

      const dataIs = await api.get(`/cpd/courses?courseType=CPT`)
      setAvailableCoursesCPT(dataIs.data.list.length ? dataIs.data.list : [])

      const dataCe = await api.get(`/cpd/courses?courseType=CE`)
      setAvailableCoursesCE(dataCe.data.list.length ? dataCe.data.list : [])
    } catch (error) {
      console.log("error is", error)
    }
  }

  const isTypeSupported = (type) => {
    let result = false
    if (cpdData && cpdData.supportedTypes) {
      cpdData.supportedTypes.forEach((t) => {
        if (t == type) {
          result = true
        }
      })
    }
    return result
  }

  useEffect(() => {
    getAvailableCourses()
    getCpd()
  }, [])
  return (
    <div className="portal-maincontent">
      <div className="dashboard-content-panel">
        <h1>{"Continuous Education Development Register"}</h1>
        <p className="cpd-info">
          This is the CPD Points for central documentation and repository and
          history
        </p>
        {/* <span className="recommendationTopics"> */}
        <Tabs>
          <TabList>
            <Tab>{"Summary"}</Tab>
            {isTypeSupported("CPD") && <Tab>{"CPD"}</Tab>}

            {isTypeSupported("CPT") && <Tab>{"CPT"}</Tab>}

            {isTypeSupported("CE") && <Tab>{"CE"}</Tab>}
          </TabList>
          <TabPanel>
            <Summary />
          </TabPanel>
          {isTypeSupported("CPD") && (
            <TabPanel>
              <CpdComponent type="CPD" availableCourses={availableCoursesCPD} />
            </TabPanel>
          )}

          {isTypeSupported("CPT") && (
            <TabPanel>
              <CpdComponent type="CPT" availableCourses={availableCoursesCPT} />
            </TabPanel>
          )}

          {isTypeSupported("CE") && (
            <TabPanel>
              <CpdComponent type="CE" availableCourses={availableCoursesCE} />
            </TabPanel>
          )}
        </Tabs>
      </div>
      <MalabarDefaultRightMenu
        enableSaveButton={false}
        enableClearButton={false}
        // onClearClick={clearData}
        enableNoteButton={false}
        // onSaveClick={false}
      />
    </div>
  )
}

export default Cpd
