import LifeInsuranceForm from './LifeInsuranceForm';
import TotalPermanentDisabilityInsuranceForm from './TotalPermanentDisabilityInsuranceForm';
import CriticalIllnessInsuranceForm from './CriticalIllnessInsuranceForm';
import HealthAndMedicalInsuranceForm from './HealthAndMedicalInsuranceForm';
import IncomeProtectionInsuranceForm from './IncomeProtectionInsuranceForm';
import OtherGeneralInsuranceForm from './OtherGeneralInsuranceForm';

export {
    LifeInsuranceForm,
    TotalPermanentDisabilityInsuranceForm,
    CriticalIllnessInsuranceForm,
    HealthAndMedicalInsuranceForm,
    IncomeProtectionInsuranceForm,
    OtherGeneralInsuranceForm
}
