import { mandatoryProvidentFundAction } from '../../../constants/ActionConstants';

export function mandatoryProvidentFund(state = {}, action) {
    switch (action.type) {
        case mandatoryProvidentFundAction.GET_MPFINFORMATION_REQUEST:
            return {
                loading: true
            };
        case mandatoryProvidentFundAction.GET_MPFINFORMATION_SUCCESS:
            return {
                items: action.data
            };
        case mandatoryProvidentFundAction.GET_MPFINFORMATION_FAILURE:
            return {
                error: action.error
            };
        case mandatoryProvidentFundAction.ADD_MPFINFORMATION_REQUEST:
            return {
                loading: true
            };
        case mandatoryProvidentFundAction.ADD_MPFINFORMATION_SUCCESS:
            return {
                items: action.response
            };
        case mandatoryProvidentFundAction.ADD_MPFINFORMATION_FAILURE:
            return {
                error: action.error
            };
        case mandatoryProvidentFundAction.EDIT_MPFINFORMATION_REQUEST:
            return {
                loading: true
            };
        case mandatoryProvidentFundAction.EDIT_MPFINFORMATION_SUCCESS:
            return {
                items: action.response
            };
        case mandatoryProvidentFundAction.EDIT_MPFINFORMATION_FAILURE:
            return {
                error: action.error
            };
        case mandatoryProvidentFundAction.DELETE_MPFINFORMATION_REQUEST:
            return {
                loading: true
            };
        case mandatoryProvidentFundAction.DELETE_MPFINFORMATION_SUCCESS:
            return {
                items: action.response
            };
        case mandatoryProvidentFundAction.DELETE_MPFINFORMATION_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}