/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Description : GEAL placeholder for hongkong
*/

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import intl from 'react-intl-universal';
import { netWorthFormActions } from '../../actions/KYC/NetWorth/NetWorthFormActions';
import { modalWindowActions } from '../../actions';
import { modalWindow } from '../../constants/ModalWindowLists';
import NetWorthForm, {
    NetWorthPath,
    NetWorthFormPropsComposer,
} from './NetWorthForm';
import { GEALHongkongForm } from '../../forms';

class GEALHongkong extends NetWorthForm {
    constructor(props) {
        super(
            props,
            intl.get('geal.title.modal', { countryDesc: 'Hong Kong' }),
            modalWindow.GEAL_HK_MODAL,
            NetWorthPath.hongkongGEAL,
            'GEALHongkong'
        );
    }

    render() {
        const { optionsData, model } = this.props;
        const { handleSave, contentWrapper, readOnlyAccessComponent } = this;
        const { readOnlyAccess } = readOnlyAccessComponent();

        return contentWrapper(
            <GEALHongkongForm
                handleSave={handleSave}
                model={model}
                optionsData={optionsData}
                readOnlyAccess={readOnlyAccess}
            />
        );
    }
}

function mapStateToProps(state) {
    return NetWorthFormPropsComposer(
        state,
        modalWindow.GEAL_HK_MODAL,
        NetWorthPath.australianGEAL
    );
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        Object.assign({}, modalWindowActions, netWorthFormActions),
        dispatch
    );
}

GEALHongkong = connect(
    mapStateToProps,
    mapDispatchToProps
)(GEALHongkong);

export default GEALHongkong;
