import React from 'react';
import intl from 'react-intl-universal';
import { FieldArray } from 'react-final-form-arrays';
import { Field, FormSpy } from 'react-final-form';
import { renderTextBoxCustom, renderPercentageCustom } from '../../../helpers';
import { RenderSimpleFields } from '../../../components';
import { FieldType } from '../../../components/Form/RenderSimpleFields';

const ContinuityBonus = ({ addContinuityBonus, removeContinuityBonus }) => {
    return (
        <FormSpy subscription={{ values: true }}>
            {({ values }) => {
                return (
                    <>
                        <RenderSimpleFields
                            fields={[
                                {
                                    name: 'hasContinuityBonus',
                                    label: intl.get(
                                        'ilasInvestment.doesHasContinuityBonus'
                                    ),
                                    type: FieldType.CHECKBOX,
                                },
                            ]}
                        />
                        {values.hasContinuityBonus && (
                            <>
                                <div className="portal-form-row text-right">
                                    <span
                                        className="btn btn-plus btn-plus-grey"
                                        onClick={() => {
                                            addContinuityBonus(
                                                'continuityBonus',
                                                {
                                                    policyYear: '',
                                                    allocationPercentage: 0,
                                                }
                                            );
                                        }}
                                    />
                                </div>
                                <table
                                    className="table-blue"
                                    width="100%"
                                    cellSpacing={0}
                                    cellPadding={0}
                                    border={0}
                                >
                                    <tbody>
                                        <tr>
                                            <th>
                                                {intl.get(
                                                    'ilasInvestment.policyYear'
                                                )}
                                            </th>
                                            <th>
                                                Bonus %
                                            </th>
                                            <th>&nbsp;</th>
                                        </tr>
                                        <FieldArray name="continuityBonus">
                                            {({ fields }) =>
                                                fields.map((name, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <Field
                                                                    name={`${name}.policyYear`}
                                                                    component={
                                                                        renderTextBoxCustom
                                                                    }
                                                                    isFullWidth
                                                                    isForGrid
                                                                />
                                                            </td>
                                                            <td>
                                                                <Field
                                                                    name={`${name}.bonusPercent`}
                                                                    component={
                                                                        renderPercentageCustom
                                                                    }
                                                                />
                                                            </td>
                                                            <td>
                                                                <span
                                                                    className="btn-remove"
                                                                    onClick={() => {
                                                                        removeContinuityBonus(
                                                                            'continuityBonus',
                                                                            index
                                                                        );
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </FieldArray>
                                    </tbody>
                                </table>
                            </>
                        )}
                    </>
                );
            }}
        </FormSpy>
    );
};

export default ContinuityBonus;
