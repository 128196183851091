import React, { Component } from "react"
import intl from "react-intl-universal"

const JOINT = "JOINT"
const CLIENT = "CLIENT"
const PARTNER = "PARTNER"

class JointSelectionMenu extends Component {
  state = {
    checkVal: this.props.value || CLIENT
  }

  // eslint-disable-next-line react/sort-comp
  handleCheck = (selected) => {
    this.setState(
      {
        checkVal: selected
      },
      () => {
        this.props.onToggle(this.state.checkVal)
        this.props.onChange(this.state.checkVal)
      }
    )
  }

  componentWillMount() {
    this.handleCheck(this.props.value || CLIENT)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.handleCheck(nextProps.value)
    }
  }

  render() {
    const { checkVal } = this.state
    const { name, clientName, partnerName, hideJoint, classification } =
      this.props
    const { handleCheck } = this
    const selectedClass = "selected"

    return (
      <React.Fragment>
        <input
          {...this.props.input}
          type={"hidden"}
          name={name}
          value={checkVal}
        />
        <div className="content-panel-header">
          {!hideJoint && (
            <span
              onClick={() => handleCheck(JOINT)}
              className={`icon-holder ${
                checkVal === JOINT || classification === JOINT
                  ? selectedClass
                  : ""
              }`}
            >
              <div className="icon ico-joint" />
              <div className="text">{intl.get("topmenu.joint")}</div>
            </span>
          )}
          {clientName ? (
            <span
              onClick={() => handleCheck(CLIENT)}
              className={`icon-holder ${
                checkVal === CLIENT || classification === CLIENT
                  ? selectedClass
                  : ""
              }`}
            >
              <div className="icon ico-single" />
              <div className="text">{clientName}</div>
            </span>
          ) : (
            <></>
          )}
          {partnerName ? (
            <span
              onClick={() => handleCheck(PARTNER)}
              className={`icon-holder ${
                checkVal === PARTNER || classification === PARTNER
                  ? selectedClass
                  : ""
              }`}
            >
              <div className="icon ico-single" />
              <div className="text">{partnerName}</div>
            </span>
          ) : (
            <></>
          )}
        </div>
      </React.Fragment>
    )
  }
}

export default JointSelectionMenu
