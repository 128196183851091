import React from 'react';
import intl from 'react-intl-universal';
import { renderCurrencyCustom } from '../../../helpers';
import { RenderSimpleFields } from '../../../components';
import { FieldType } from './../../../components/Form/RenderSimpleFields';
import { Field } from 'react-final-form';

function SuplementaryRetirementSchemeRightSide(props) {
    return (
        <React.Fragment>
            <RenderSimpleFields
                fields={[
                    {
                        name: 'srsMonthlyPaymentAmount',
                        label: intl.get('field.monthlyPaymentAmount'),
                        type: FieldType.MONEY,
                    },
                    {
                        name: 'srsAnnualGrowthRate',
                        label: intl.get('field.annualGrowthRate'),
                        type: FieldType.PERCENTAGE,
                    }
                ]}
            />

            <div className="subtitle2">Fee</div>
            <div className="portal-form-row align-items-center">
                <label >
                    {intl.get(
                        'field.annualAdviserFee'
                    )}
                </label>
                <Field
                    name="srsAnnualAdvisorFee"
                    label={intl.get('field.annualAdviserFee')}
                    component={renderCurrencyCustom}
                />
            </div>
        </React.Fragment>
    )
}

export default SuplementaryRetirementSchemeRightSide;