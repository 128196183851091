import React from 'react';
import { Field } from 'react-final-form';
import { renderCurrencyCustom, composeValidators } from '../../../helpers';
import { isValidDate } from '../../../helpers/Validator';
import { renderDateFieldCustom, renderMoneyCustom, renderTextBoxCustom } from './../../../helpers/FormRender';
import intl from 'react-intl-universal';

function CPFLife(props) {
    return (
        <React.Fragment>
            <div className="rs-col2">
                <div className="subtitle2">
                    {intl.get('centralProvidenceFundInformation.RADetails')}
                </div>
                <div className="portal-form-row">
                    <label>
                        {intl.get('centralProvidenceFundInformation.retirementAccount')}
                    </label>
                    <div className="row-holder">
                        <Field
                            name="cpfLifeDetails.retirementAccount"
                            component={renderTextBoxCustom}
                            isForGrid={true}
                        />
                    </div>
                </div>

                <div className="portal-form-row">
                    <label>
                        {intl.get('centralProvidenceFundInformation.currentValue')}
                    </label>
                    <div className="row-holder">
                        <Field
                            name="cpfLifeDetails.currentValue"
                            component={renderCurrencyCustom}
                            isForGrid={true}
                        />
                    </div>
                </div>

                <div className="portal-form-row dateformat">
                    <label>
                        {intl.get('centralProvidenceFundInformation.valuationDate')}
                    </label>
                    <div className="row-holder">
                        <Field
                            name="cpfLifeDetails.valuationDate"
                            component={renderDateFieldCustom}
                            isForGrid={true}
                            validate={composeValidators(isValidDate)}
                        />
                    </div>
                </div>

            </div>
            <div className="rs-col2">
                <div className="subtitle2">
                    {intl.get('centralProvidenceFundInformation.CPFLifePlanDetaiils')}
                </div>


                <div className="portal-form-row">
                    <label>
                        {intl.get('centralProvidenceFundInformation.CPFLifeAccount')}
                    </label>
                    <div className="row-holder">
                        <Field
                            name="cpfLifeDetails.cpfLifeAccountNumber"
                            component={renderTextBoxCustom}
                            isForGrid={true}
                        />
                    </div>
                </div>

                <div className="portal-form-row">
                    <label>
                        {intl.get('centralProvidenceFundInformation.CPFLifeIssuer')}
                    </label>
                    <div className="row-holder">
                        <Field
                            name="cpfLifeDetails.cpfLifeIssuer"
                            component={renderTextBoxCustom}
                            isForGrid={true}
                        />
                    </div>
                </div>

                <div className="portal-form-row padtop_radio">
                    <label>
                        {intl.get('centralProvidenceFundInformation.type')}
                    </label>
                    <div className="row-holder">
                        <div className="row-holder_3col">
                            <Field
                                name={`cpfLifeDetails.cpfLifeType`}
                                type="radio"
                                value="BASIC_PLAN"
                                component="input"
                            />
                            {intl.get('centralProvidenceFundInformation.basic')}
                        </div>

                        <div className="row-holder_3col">
                            <Field
                                name={`cpfLifeDetails.cpfLifeType`}
                                type="radio"
                                value="STANDARD_PLAN"
                                component="input"
                            />
                            {intl.get('centralProvidenceFundInformation.standard')}
                        </div>

                        <div className="row-holder_3col">
                            <Field
                                name={`cpfLifeDetails.cpfLifeType`}
                                type="radio"
                                value="ESCALATING_PLAN"
                                component="input"
                            />
                            {intl.get('centralProvidenceFundInformation.escalating')}
                        </div>
                    </div>
                </div>

                <div className="portal-form-row">
                    <label>
                        {intl.get('centralProvidenceFundInformation.monthlyPaymentAmount')}
                    </label>
                    <div className="row-holder">
                        <Field
                            name="cpfLifeDetails.monthlyPaymentAmount"
                            component={renderMoneyCustom}
                            isForGrid={true}
                            defaultCurrency={'SGD'}
                        />
                    </div>
                </div>

                <div className="portal-form-row">
                    <label>
                        {intl.get('centralProvidenceFundInformation.indexation')}
                    </label>
                    <div className="row-holder">
                        <Field
                            name="cpfLifeDetails.indexation"
                            component={renderMoneyCustom}
                            isForGrid={true}
                            defaultCurrency={'SGD'}
                        />
                    </div>
                </div>

                <div className="portal-form-row">
                    <label>
                        {intl.get('centralProvidenceFundInformation.residualLumpSum')}
                    </label>
                    <div className="row-holder">
                        <Field
                            name="cpfLifeDetails.residentialLumpSum"
                            component={renderMoneyCustom}
                            isForGrid={true}
                            defaultCurrency={'SGD'}
                        />
                    </div>
                </div>

                <div className="portal-form-row dateformat">
                    <label>
                        {intl.get('centralProvidenceFundInformation.valuationDate')}
                    </label>
                    <div className="row-holder">
                        <Field
                            name="cpfLifeDetails.valuationDate"
                            component={renderDateFieldCustom}
                            isForGrid={true}
                            validate={composeValidators(isValidDate)}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
}

export default CPFLife;