import React, { PureComponent } from "react"
import intl from "react-intl-universal"
import { PhoneTypeEnum } from "../../constants/Enums"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"

export default class MalabarPhoneNumber extends PureComponent {
  state = {
    phoneType: PhoneTypeEnum.PhoneType_WORK,
    region: "",
    number: "",
    preferred: this.props.preferred || false,
    value: this.props.value,
    phoneTypeOptions: ""
  }

  componentWillMount() {
    this.setComponentState(this.props.value)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setComponentState(nextProps.value)
    }
  }

  setComponentState = (valueSource) => {
    // get phone type options list
    const phoneOptions = this.getPhoneTypeOptions()
    this.setState(
      {
        phoneTypeOptions: phoneOptions
      },
      () => {
        if (valueSource) {
          this.setState({
            phoneType: valueSource.phoneType,
            region: valueSource.countryCode,
            number: valueSource.number,
            value: valueSource
          })
        } else {
          this.setState({
            phoneType: PhoneTypeEnum.PhoneType_WORK,
            region: "",
            number: "",
            value: null
          })
        }
      }
    )
  }

  getPhoneTypeOptions = () => {
    const phoneTypeStr = []
    Object.keys(PhoneTypeEnum).forEach((key) => {
      phoneTypeStr.push(
        <option key={PhoneTypeEnum[key]} value={PhoneTypeEnum[key]}>
          {intl.get(key)}
        </option>
      )
    })
    return phoneTypeStr
  }

  onPhoneTypeChanged = (element) => {
    const inputValue = element.target.value

    this.setState(
      {
        phoneType: inputValue,
        value: {
          phoneType: inputValue,
          countryCode: this.state.region,
          number: this.state.number,
          preferred: this.state.preferred
        }
      },
      () => {
        // notify final-form
        this.props.onChange(this.state.value)
      }
    )
  }

  onNumberChanged = (inputValue, country) => {
    this.setState(
      {
        phoneType: this.state.phoneType,
        value: {
          phoneType: this.state.phoneType,
          countryCode: country.dialCode,
          number: inputValue,
          preferred: this.state.preferred
        }
      },
      () => {
        this.props.onChange(this.state.value)
      }
    )
  }

  render() {
    const {
      label,
      required,
      name,
      touched,
      error,
      preferred,
      onRemoveDataClick
    } = this.props
    const { phoneType, number } = this.state

    return (
      <React.Fragment>
        {/* <label>
          {label} {required && <span className="required-asterix">*</span>}
        </label> */}
        <div className="row-holder-full row-holder-phone-full">
          <div className="select-box">
            <select
              name={"_phoneType"}
              value={phoneType}
              onChange={(e) => this.onPhoneTypeChanged(e)}
              required
            >
              {this.state.phoneTypeOptions}
            </select>
          </div>
          {!preferred && (
            <span onClick={onRemoveDataClick} className="btn-remove" />
          )}
          <div className="d-flex align-items-center justify-content-center tel-input">
            <PhoneInput
              inputClass="react-tel-input"
              value={number}
              placeholder={"number"}
              onChange={(value, country) => {
                this.onNumberChanged(value, country)
              }}
              countryCodeEditable={false}
              preferredCountries={["au", "hk", "my", "nz", "sg"]}
            />
          </div>
          {touched && error && <div className="alert phone-alert">{error}</div>}
        </div>
      </React.Fragment>
    )
  }
}
