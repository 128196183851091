import { sharesHoldingInformationAction } from '../../../constants/ActionConstants';

export function sharesHoldingInformation(state = {}, action) {
    switch (action.type) {
        case sharesHoldingInformationAction.GET_SFDETAIL_REQUEST:
            return {
                loading: true
            };
        case sharesHoldingInformationAction.GET_SFDETAIL_SUCCESS:
            return {
                items: action.data
            };
        case sharesHoldingInformationAction.GET_SFDETAIL_FAILURE:
            return {
                error: action.error
            };
        case sharesHoldingInformationAction.ADD_SFDETAIL_REQUEST:
            return {
                loading: true
            };
        case sharesHoldingInformationAction.ADD_SFDETAIL_SUCCESS:
            return {
                items: action.response
            };
        case sharesHoldingInformationAction.ADD_SFDETAIL_FAILURE:
            return {
                error: action.error
            };
        case sharesHoldingInformationAction.EDIT_SFDETAIL_REQUEST:
            return {
                loading: true
            };
        case sharesHoldingInformationAction.EDIT_SFDETAIL_SUCCESS:
            return {
                items: action.response
            };
        case sharesHoldingInformationAction.EDIT_SFDETAIL_FAILURE:
            return {
                error: action.error
            };
        case sharesHoldingInformationAction.DELETE_SFDETAIL_REQUEST:
            return {
                loading: true
            };
        case sharesHoldingInformationAction.DELETE_SFDETAIL_SUCCESS:
            return {
                items: action.response
            };
        case sharesHoldingInformationAction.DELETE_SFDETAIL_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}