import React from 'react';
import intl from 'react-intl-universal';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { RenderSimpleFields } from '../../../components';
import { FieldType } from '../../../components/Form/RenderSimpleFields';
import { renderTextBoxCustom } from '../../../helpers';

const Riders = ({ addOtherRiders, removeOtherRiders }) => {
    return (
        <>
           
            <div className="portal-form-row portal-form-row2col portal-row-otherrider">
                <label className="text-bold">
                    {intl.get('ilasInvestment.otherRiders')} &nbsp; &nbsp;
                    <span
                        onClick={() =>
                            addOtherRiders('riderBenefits', {
                                name: '',
                                benefits: 0,
                            })
                        }
                        className="btn btn-plus"
                    />
                </label>
                <div className="row-holder">
                    <label className="text-bold">
                        {intl.get('ilasInvestment.riderBenefits')}{' '}
                    </label>
                </div>
            </div>
            <FieldArray name="riderBenefits">
                {({ fields }) =>
                    fields.map((name, index) => (
                        <div
                            className="portal-form-row other-expense"
                            key={index}
                        >
                            <Field
                                name={`${name}.name`}
                                component={renderTextBoxCustom}
                                containerCssClass={'networth-other-input'}
                            />
                            <Field
                                name={`${name}.benefitAmount`}
                                type="text"
                                component={renderTextBoxCustom}
                            />
                            <button
                                type="button"
                                onClick={() =>
                                    removeOtherRiders('riderBenefits', index)
                                }
                                className="btn-remove networth-other-remove"
                            />
                        </div>
                    ))
                }
            </FieldArray>
        </>
    );
};

export default Riders;
