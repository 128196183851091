import React, { useEffect, useState } from "react"
import RenderSimpleFields, {
  FieldType
} from "../components/Form/RenderSimpleFields"
import ClassificationSelection from "../components/_hook/ClassificationSelection"
import { useSelector } from "react-redux"
import { FormButtons } from "../components"
import intl from "react-intl-universal"
import { Field, Form } from "react-final-form"
import { translateListLabel } from "../helpers/list-translation"
import { CountryList } from "../constants/Lists"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import PortfolioDetails from "../pages/NetWorth/components/InvestmentFund/PortfolioDetails"
import InvestmentFundTableData from "../pages/NetWorth/components/InvestmentFund/TableData"
import {
  composeValidators,
  fileFieldRender,
  renderTextBoxCustom,
  required
} from "../helpers"
import arrayMutators from "final-form-arrays"
import Api from "../lib/api"
import Alert from "react-s-alert"

const InvestmentFundPlatform = ({
  handleSave,
  model = {},
  readOnlyAccess,
  optionsData,
  clientInformation,
  currency = "AUD"
}) => {
  // const { optionsData } = useSelector((state) => state.enumOptions)
  const fields = [
    {
      name: "country",
      label: "Regulated in Which Country",
      required: true,
      type: FieldType.SELECTION,
      // selectionData: optionsData.businessLocationOptions,
      selectionData: translateListLabel(CountryList),
      valueProperty: "name",
      labelProperty: "name"
    },
    {
      name: "investmentProviderName",
      label: "Investment Provider Name",
      required: true,
      type: FieldType.TEXT
    },
    {
      name: "institutionName",
      label: "Institutional Name",
      required: true,
      type: FieldType.TEXT
    },
    {
      name: "userId",
      label: "User Id",
      required: true,
      type: FieldType.TEXT
    },

    {
      name: "accountStartDate",
      label: intl.get("field.accountStartDate"),
      type: FieldType.DATE,
      required: true
    },
    {
      name: "portfolioCurrency",
      label: intl.get(`field.reportingCurrency`),
      type: FieldType.ENUM_SELECTION,
      enumData: optionsData.currencyOptions,
      required: true
    },

    {
      name: "investmentClientName",
      label: "Client Name",
      required: true,
      type: FieldType.TEXT
    }
  ]
  const adviserDetailsFields = [
    {
      name: "managedByUser",
      label: "Managed By User",
      type: FieldType.CHECKBOX
    },
    {
      name: "licensedEntity",
      label: "Licensed Corporation/Entity",
      required: true,
      type: FieldType.TEXT
    },
    {
      name: "investmentAdviserName",
      label: "Adviser Name",
      required: true,
      type: FieldType.TEXT
    },
    {
      name: "investmentAdviserNo",
      label: "Adviser No",
      required: true,
      type: FieldType.TEXT
    }
  ]
  const { client, partner, hasPartner } = useSelector((state) => state.entity)

  const [loading, setLoding] = useState(false)
  const [summaryData, setSummaryData] = useState()
  useEffect(() => {
    setSummaryData({
      summary: model.summary || {},
      rsp: model.rsp || {},
      portfolios: model.portfolios || {},
      verify: model.verify || false,
      portfolioMap: model.portfolioMap || {}
    })
  }, [model])

  return (
    <div
      className="portal-form-popup"
      style={{ maxHeight: "500px", overflow: "auto" }}
    >
      <Form
        onSubmit={(values) => {
          handleSave({
            ...model,
            investmentFundFormValues: {
              ...values,
              summary: summaryData.summary,
              rsp: summaryData.rsp,
              portfolios: summaryData.portfolios,
              verify: summaryData.verify
            }
          })
        }}
        initialValues={{ ...model }}
        mutators={{
          ...arrayMutators,
          setFormAttribute: ([fieldName, fieldVal], state, { changeValue }) => {
            changeValue(state, fieldName, () => fieldVal)
          }
        }}
        keepDirtyOnReinitialize
        render={({
          handleSubmit,
          mutators: { push, remove, setFormAttribute },
          values
        }) => (
          <div>
            <form
              className="flexbox"
              id="investmentPlatformForm"
              onSubmit={handleSubmit}
            >
              <div className="portal-form-half">
                <ClassificationSelection
                  {...{
                    client: client,
                    partner: partner,
                    isJoint: hasPartner
                  }}
                />

                <div className="subtitle2">Platform Details</div>
                <div id="investmentPlatformForm" onSubmit={handleSubmit}>
                  <RenderSimpleFields
                    fields={fields.map((field) => {
                      if (!field.type) {
                        field.type = FieldType.TEXT
                      }
                      field.disabled = true
                      return field
                    })}
                  />

                  <div className=" portal-form-row">
                    <label for="investmentClientNo">
                      Client Account Id Number <span>*</span>
                    </label>
                    <div className="d-flex flex-column row-holder">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "20px"
                        }}
                      >
                        <Field
                          component={renderTextBoxCustom}
                          validate={composeValidators(required)}
                          name="investmentClientNo"
                          placeholder="Client Account Id Number"
                          className="text-input"
                        />

                        <button
                          className="portal-btn portal-btn-submit"
                          disabled={
                            loading ||
                            (values &&
                              (!values.investmentAdviserNo ||
                                !values.investmentClientNo ||
                                !values.investmentProviderName ||
                                !values.portfolioCurrency))
                          }
                          onClick={async () => {
                            setLoding(true)
                            let payload = {
                              adviser: values.investmentAdviserNo.trim(),
                              client: values.investmentClientNo.trim(),
                              provider: values.investmentProviderName.trim(),
                              requestedCurrency:
                                values.portfolioCurrency || "USD"
                            }
                            try {
                              const res = await Api.post(
                                `/investment-provider/verify-fund-data`,
                                payload
                              )
                              if (res) {
                                Alert.success("Data verified successfully")
                                setLoding(false)
                                setSummaryData({
                                  summary: res.data.summary,
                                  rsp: res.data.rsp,
                                  portfolios: res.data.portfolios,
                                  verify: res.data.verify,
                                  portfolioMap: res.data.portfolioMap
                                })
                              }
                            } catch (error) {
                              console.log("error", error)
                              // Alert.success("Data could not be verified")
                              setLoding(false)
                            }
                          }}
                        >
                          Verify
                        </button>
                      </div>
                    </div>
                  </div>
                  <Field
                    component={fileFieldRender}
                    customClassName="mt-1 d-block"
                    labelIdle="Drag, Drop or Browse"
                    label="Upload Documents"
                    onupdatefiles={(fileItems) => {
                      setFormAttribute("proofDocument", fileItems[0])
                    }}
                    fileInformation={(values && values.proofDocument) || null}
                  />
                </div>
              </div>
              <div className="">
                <Tabs
                  justify={true}
                  // onSelect={(value) => {
                  //   console.log("value is", value)
                  // }}
                >
                  <TabList className="custom_tabs">
                    <Tab>Adviser Details</Tab>
                  </TabList>
                  <TabPanel>
                    <div className="pop-holder py-0">
                      <RenderSimpleFields fields={adviserDetailsFields} />
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
              <FormButtons />
            </form>
          </div>
        )}
      />
      {summaryData && summaryData.verify ? (
        <>
          <PortfolioDetails model={model} />
          <InvestmentFundTableData
            summary={summaryData && summaryData.summary}
            investmentPortfolioHoldings={summaryData && summaryData.portfolios}
            rsp={summaryData && summaryData.rsp}
            portfolioMap={summaryData && summaryData.portfolioMap}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

export default InvestmentFundPlatform
