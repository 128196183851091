import React, { useState, useEffect } from "react"
import ClientApi from "../../api/ClientApi"
import { getSymbolFromCode } from "../../helpers"
import { numberWithCommas } from "../../helpers/CurrencyConversion"
import Alert from "react-s-alert"
import NumberFormat from "react-number-format"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"

function Separate({ client, partner, clearModal, clearModalOnly }) {
  const REASON_TYPES = [
    { key: "DIVORCE", name: "Divorce" },
    { key: "PASSED_AWAY", name: "Passed Away" },
    { key: "MODIFY", name: "Modify (Remove partner human error input)" }
  ]

  const WHO_TARGETS = [
    { key: "CLIENT", name: "Client" },
    { key: "PARTNER", name: "Partner" },
    { key: "BOTH", name: "Both" }
  ]

  const JOINT_SEPARATION_ACTIONS = [
    { key: "REM", name: "Remove" },
    { key: "MOVE_TO_CLIENT", name: "Move all to Client" },
    { key: "MOVE_TO_PARTNER", name: "Move all to Partner" },
    { key: "FIFTY_FIFTY", name: "Fifty-Fifty(50-50)" },
    { key: "CUSTOM", name: "Customize" }
  ]

  const ASSET_ACTIONS = [
    { key: "MOVE_ASSET", name: "Move Asset" },
    { key: "MOVE_MONEY", name: "Move Money" }
  ]

  const numberFormat = (currency, value) =>
    ` ${getSymbolFromCode(currency)}${numberWithCommas(value)}`

  const [successContent, setSuccessContent] = useState()
  const [reason, setReason] = useState("DIVORCE")
  const [whoPassedAway, setWhoPassedAway] = useState("CLIENT")
  const [dependants, setDependants] = useState([])
  const [dependantTo, setDependantTo] = useState("CLIENT")

  const [jointAssetAction, setJointAssetAction] = useState("MOVE_TO_CLIENT")
  const [jointAssetClientPortion, setJointAssetClientPortion] = useState(0)
  const [jointAssetPartnerPortion, setJointAssetPartnerPortion] = useState(0)

  const [passedAwayAssets, setPassedAwayAssets] = useState([])
  const [passedAwayInsurances, setPassedAwayInsurances] = useState([])
  const [cashAccounts, setCashAccounts] = useState([])
  useEffect(() => {
    initDependants()
  }, [])

  function initDependants() {
    let defaultMoveTo = "CLIENT"
    if (reason == "PASSED_AWAY" && whoPassedAway.length > 0) {
      defaultMoveTo = whoPassedAway == "CLIENT" ? "PARTNER" : "CLIENT"
    }
    let concatedDependants = []
    if (client.personalDetails) {
      let pd = client.personalDetails
      if (pd.children && pd.children.length > 0) {
        pd.children.forEach((c) => {
          concatedDependants.push({
            name: c.name,
            relationship: c.relationship,
            moveTo: defaultMoveTo
          })
        })
      }
      if (pd.siblings && pd.siblings.length > 0) {
        pd.siblings.forEach((c) => {
          concatedDependants.push({
            name: c.name,
            relationship: c.relationship,
            moveTo: defaultMoveTo
          })
        })
      }
      if (pd.parents && pd.parents.length > 0) {
        pd.parents.forEach((c) => {
          concatedDependants.push({
            name: c.name,
            relationship: c.relationship,
            moveTo: defaultMoveTo
          })
        })
      }

      if (pd.grandparents && pd.grandparents.length > 0) {
        pd.grandparents.forEach((c) => {
          concatedDependants.push({
            name: c.name,
            relationship: c.relationship,
            moveTo: defaultMoveTo
          })
        })
      }

      if (pd.parentsInLaw && pd.parentsInLaw.length > 0) {
        pd.parentsInLaw.forEach((c) => {
          concatedDependants.push({
            name: c.name,
            relationship: c.relationship,
            moveTo: defaultMoveTo
          })
        })
      }
      setDependants(concatedDependants)
    }
  }

  const tableDependants = () => {
    return (
      <table className="table-blue " width="75%">
        <tbody>
          <tr>
            <th width="40%">Name</th>
            <th width="20%">Relationship</th>
            <th width="10%">Move to</th>
          </tr>
          {getDependantRows()}
        </tbody>
      </table>
    )
  }

  const getDependantRows = () => {
    if (dependants) {
      return dependants.map((item, index) => (
        //add asset
        <tr key={index}>
          <td>{item.name}</td>
          <td>{item.relationship}</td>
          <td className="table-width-15">
            <select className="select-box"
              value={item.moveTo}
              onChange={(e) => onDependantItemChanged(e, item)}
              disabled={reason == "PASSED_AWAY"}
            >
              {getWhoOptions(false)}
            </select>
          </td>
        </tr>
      ))
    }

    return (
      <tr>
        <td colSpan="13">no data</td>
      </tr>
    )
  }

  function onDependantItemChanged(e, item) {
    if (dependants) {
      let temp = []
      dependants.forEach((d) => {
        if (d.name == item.name) {
          d.moveTo = e.target.value
        }
        temp.push(d)
      })
      setDependants(temp)
    }
  }

  function requestInsurances(classification) {
    ClientApi.getInsurances(client.id, classification).then((res) => {
      if (res && res.data) {
        setPassedAwayInsurances(res.data.insuranceClaimDtoList)
      }
    })
  }

  function requestAssets(classification) {
    ClientApi.getAssets(client.id, classification).then((res) => {
      if (res && res.data) {
        setPassedAwayAssets(res.data.assetList)
      }
    })
  }

  function requestCashAccounts(classification) {
    let reverseClassification
    if (classification == "CLIENT") {
      reverseClassification = "PARTNER"
    } else if (classification == "PARTNER") {
      reverseClassification = "CLIENT"
    } else {
      reverseClassification = "BOTH"
    }
    ClientApi.getCashAccounts(client.id, reverseClassification).then((res) => {
      let options = []
      if (res && res.data && res.data.list && res.data.list.length > 0) {
        options.push(<option value={""}></option>)
        res.data.list.forEach((c) => {
          options.push(
            <option key={c.key} value={c.key}>
              {c.name}
            </option>
          )
        })
      }
      setCashAccounts(options)
    })
  }

  const tableMoveAssets = () => {
    return (
      <table className="table-blue" width="100%">
        <tbody>
          <tr>
            <th width="25%">Name</th>
            <th width="20%">Type</th>
            <th width="5%">Currency</th>
            <th width="10%">Balance</th>
            <th width="10%">Action</th>
            <th width="10%">Actual Amount</th>
            <th width="20%">Target Cash Account</th>
          </tr>
          {getAssetRows()}
        </tbody>
      </table>
    )
  }

  const getAssetRows = () => {
    if (passedAwayAssets) {
      return passedAwayAssets.map((item, index) => (
        //add asset
        <tr key={index}>
          <td>{item.name}</td>
          <td>{item.type}</td>
          <td>{item.currency}</td>
          <td className="text-right">{numberFormat(item.currency, item.currentBalance)}</td>
          <td>
            <select className="select-box"
              value={item.actionType}
              onChange={(e) => onAssetActionChanged(e, item)}
              disabled={whoPassedAway == "BOTH" || !item.movableAsset}
            >
              {getAssetActionOptions()}
            </select>
          </td>
          <td >
            {whoPassedAway != "BOTH" && item.actionType == "MOVE_MONEY" && (
              <NumberFormat
                disabled={whoPassedAway == "BOTH"}
                isAllowed={(values) => {
                  const { floatValue } = values
                  return floatValue <= item.currentBalance
                }}
                value={item.actualAmountMoved}
                displayType={"input"}
                decimalScale={0}
                decimalSeparator={"."}
                thousandSeparator={","}
                className="text-input text-right"
                onChange={(e) => onMoveAssetAmountChanged(e, item)}
              />
            )}
          </td>
          <td>
            {whoPassedAway != "BOTH" && item.actionType == "MOVE_MONEY" && (
              <select className="select-box"
                value={item.targetCashId}
                onChange={(e) => onAssetTargetCashChanged(e, item)}
                disabled={whoPassedAway == "BOTH"}
              >
                {cashAccounts}
              </select>
            )}
          </td>
        </tr>
      ))
    }

    return (
      <tr>
        <td colSpan="13">no data</td>
      </tr>
    )
  }

  const onAssetActionChanged = (e, item) => {
    if (passedAwayAssets) {
      let temp = []
      passedAwayAssets.forEach((d) => {
        if (d.id == item.id) {
          d.actionType = e.target.value
        }
        temp.push(d)
      })
      setPassedAwayAssets(temp)
    }
  }

  function onMoveAssetAmountChanged(e, item) {
    if (passedAwayAssets) {
      let temp = []
      passedAwayAssets.forEach((d) => {
        if (d.id == item.id) {
          d.actualAmountMoved = parseFloat(e.target.value)
        }
        temp.push(d)
      })
      setPassedAwayAssets(temp)
    }
  }

  function onAssetTargetCashChanged(e, item) {
    if (passedAwayAssets) {
      let temp = []
      passedAwayAssets.forEach((d) => {
        if (d.id == item.id) {
          d.targetCashId = e.target.value
        }
        temp.push(d)
      })
      setPassedAwayAssets(temp)
    }
  }

  const tableClaimInsurances = () => {
    return (
      <table className="table-blue" width="100%">
        <tbody>
          <tr>
            <th width="30%">Name</th>
            <th width="20%">Type</th>
            <th width="5%">Currency</th>
            <th width="10%">Claimable Amount</th>
            <th width="10%">Actual Amount Claimed</th>
            <th width="25%">
              Transfer to {whoPassedAway == "CLIENT" ? "Partner" : "Client"}'s
              Cash Account
            </th>
          </tr>
          {getClaimInsuranceRows()}
        </tbody>
      </table>
    )
  }

  const getClaimInsuranceRows = () => {
    if (passedAwayInsurances) {
      return passedAwayInsurances.map((item, index) => (
        //add asset
        <tr key={index}>
          <td>{item.name}</td>
          <td>{item.type}</td>
          <td>{item.currency}</td>
          <td className="text-right">{numberFormat(item.currency, item.claimableAmount)}</td>
          <td>
            {whoPassedAway != "BOTH" && (
              <NumberFormat
                disabled={whoPassedAway == "BOTH"}
                isAllowed={(values) => {
                  const { floatValue } = values
                  return floatValue <= item.claimableAmount
                }}
                value={item.actualAmountClaimed}
                displayType={"input"}
                decimalScale={0}
                decimalSeparator={"."}
                thousandSeparator={","}
                className="text-input text-right"
                onChange={(e) => onClaimInsuranceAmountChanged(e, item)}
              />
            )}
          </td>
          <td>
            {whoPassedAway != "BOTH" && (
              <select className="select-box"
                value={item.targetCashId}
                onChange={(e) => onClaimInsuranceCashTargetChanged(e, item)}
                disabled={whoPassedAway == "BOTH"}
              >
                {cashAccounts}
              </select>
            )}
          </td>
        </tr>
      ))
    }

    return (
      <tr>
        <td colSpan="13">no data</td>
      </tr>
    )
  }

  function onClaimInsuranceAmountChanged(e, item) {
    if (passedAwayInsurances) {
      let temp = []
      passedAwayInsurances.forEach((d) => {
        if (d.id == item.id) {
          d.actualAmountClaimed = parseFloat(e.target.value)
        }
        temp.push(d)
      })
      setPassedAwayInsurances(temp)
    }
  }

  function onClaimInsuranceCashTargetChanged(e, item) {
    if (passedAwayInsurances) {
      let temp = []
      passedAwayInsurances.forEach((d) => {
        if (d.id == item.id) {
          d.targetCashId = e.target.value
        }
        temp.push(d)
      })
      setPassedAwayInsurances(temp)
    }
  }

  function onReasonChanged(e) {
    let val = e.target.value
    setReason(val)
    if (val == "PASSED_AWAY") {
      requestAssets(whoPassedAway)
      requestInsurances(whoPassedAway)
      requestCashAccounts(whoPassedAway)

      if (whoPassedAway == "CLIENT") {
        setJointAssetAction("MOVE_TO_PARTNER")

        if (dependants) {
          let temp = []
          dependants.forEach((d) => {
            d.moveTo = "PARTNER"
            temp.push(d)
          })
          setDependants(temp)
        }
      } else if (whoPassedAway == "PARTNER") {
        setJointAssetAction("MOVE_TO_CLIENT")

        if (dependants) {
          let temp = []
          dependants.forEach((d) => {
            d.moveTo = "CLIENT"
            temp.push(d)
          })
          setDependants(temp)
        }
      }
    }
  }

  function getReasonOptions() {
    let result = []
    REASON_TYPES.forEach((item) => {
      result.push(
        <option key={item.key} value={item.key}>
          {item.name}
        </option>
      )
    })

    return result
  }

  function getWhoOptions(includeBoth) {
    let result = []
    WHO_TARGETS.forEach((item) => {
      if (!includeBoth && item.key == "BOTH") {
        //do nothing
      } else {
        result.push(
          <option key={item.key} value={item.key}>
            {item.name}
          </option>
        )
      }
    })

    return result
  }

  function getJointSeparationActionOptions() {
    let result = []
    JOINT_SEPARATION_ACTIONS.forEach((item) => {
      result.push(
        <option key={item.key} value={item.key}>
          {item.name}
        </option>
      )
    })

    return result
  }

  function getPortionOptions() {
    let result = []
    for (let index = 0; index <= 100; index = index + 5) {
      result.push(
        <option key={"" + index} value={index}>
          {index}%
        </option>
      )
    }

    return result
  }

  function getAssetActionOptions() {
    let result = []
    ASSET_ACTIONS.forEach((item) => {
      result.push(
        <option key={item.key} value={item.key}>
          {item.name}
        </option>
      )
    })

    return result
  }

  function onJointAssetActionChanged(val) {
    if (val == "MOVE_TO_CLIENT") {
      setJointAssetClientPortion(100)
      setJointAssetPartnerPortion(0)
    } else if (val == "MOVE_TO_PARTNER") {
      setJointAssetClientPortion(0)
      setJointAssetPartnerPortion(100)
    } else if (val == "FIFTY_FIFTY") {
      setJointAssetClientPortion(50)
      setJointAssetPartnerPortion(50)
    } else {
      setJointAssetClientPortion(0)
      setJointAssetPartnerPortion(0)
    }
    setJointAssetAction(val)
  }

  function onJointAssetPortionChanged(client, partner) {
    setJointAssetClientPortion(client)
    setJointAssetPartnerPortion(partner)
  }

  function onWhoPassedAwayChanged(e) {
    let val = e.target.value
    setWhoPassedAway(val)

    requestInsurances(val)
    requestAssets(val)
    requestCashAccounts(val)

    if (dependants) {
      let temp = []
      dependants.forEach((d) => {
        d.moveTo = val == "CLIENT" ? "PARTNER" : "CLIENT"
        temp.push(d)
      })
      setDependants(temp)
    }

    if (val == "CLIENT") {
      setJointAssetAction("MOVE_TO_PARTNER")
    } else if (val == "PARTNER") {
      setJointAssetAction("MOVE_TO_CLIENT")
    } else {
      setJointAssetAction("REM")
    }
  }

  function getWhoPassedAwayName() {
    let result = ""
    let clientName =
      client &&
      client.personalDetails &&
      client.personalDetails.personalInformation
        ? client.personalDetails.personalInformation.firstName +
          " " +
          client.personalDetails.personalInformation.familyName
        : ""
    let partnerName =
      partner &&
      partner.personalDetails &&
      partner.personalDetails.personalInformation
        ? partner.personalDetails.personalInformation.firstName +
          " " +
          partner.personalDetails.personalInformation.familyName
        : ""
    if (whoPassedAway == "CLIENT") {
      result = clientName
    } else if (whoPassedAway == "PARTNER") {
      result = partnerName
    } else {
      result = clientName + " and " + partnerName
    }

    return result
  }

  function doProcess() {
    let yes = window.confirm("Confirm?")
    if (yes) {
      let model = {
        clientId: client.id,
        reason: reason,
        whoPassedAway: reason == "PASSED_AWAY" ? whoPassedAway : "",
        dependantList: dependants,
        jointAssetSeparation: {
          jointSeparationAction: jointAssetAction,
          clientPercentage: jointAssetClientPortion,
          partnerPercentage: jointAssetPartnerPortion
        },

        treatmentAssets: passedAwayAssets,
        treatmentInsurances: passedAwayInsurances.filter(
          (item) => item.actualAmountClaimed > 0 && item.targetCashId
        )
      }
      ClientApi.separate(model)
        .then((res) => {
          if (res && res.data) {
            setSuccessContent(res.data)
            Alert.success("Separation Done Successfully")
            clearModal()
          }
        })
        .catch((res) => {
          Alert.error(res.errorMessage)
        })
    }
  }

  function getContent() {
    return (
      <div className="notification-dialog">
        <div className="row mt-4">
          <div className="col-md-3">
            <label>Reason</label>
          </div>
          <div className="col-md-9">
            <select className="select-box" value={reason} onChange={(e) => onReasonChanged(e)}>
              {getReasonOptions()}
            </select>
          </div>
        </div>
        {reason !== "MODIFY" ? (
          <>
            {reason == "PASSED_AWAY" && (
              <div className="row mt-4">
                <div className="col-md-3">
                  <label>Who passed away</label>
                </div>
                <div className="col-md-2">
                  <select className="select-box"
                    value={whoPassedAway}
                    onChange={(e) => onWhoPassedAwayChanged(e)}
                  >
                    {getWhoOptions(true)}
                  </select>
                </div>
                <div className="col-md-7">
                  <label className="text-input text-bold">{getWhoPassedAwayName()}</label>
                </div>
              </div>
            )}
            <div className="row mt-4">
              <div className="col-md-3">
                <label>Dependants</label>
              </div>
              <div className="col-md-9">{tableDependants()}</div>
            </div>
            <div className="row mt-4">
              <div className="col-md-3">
                <label>Joint Assets</label>
              </div>
              <div className="col-md-9">
                <select className="select-box"
                  value={jointAssetAction}
                  onChange={(e) => onJointAssetActionChanged(e.target.value)}
                  disabled={reason == "PASSED_AWAY"}
                >
                  {getJointSeparationActionOptions()}
                </select>
              </div>
            </div>
            {jointAssetAction == "CUSTOM" && (
              <div className="row mt-4">
                <div className="col-md-3"></div>
                <div className="col-md-2">
                  <label>client </label>
                  <select className="select-box"
                    value={jointAssetClientPortion}
                    onChange={(e) =>
                      onJointAssetPortionChanged(
                        e.target.value,
                        100 - e.target.value
                      )
                    }
                  >
                    {getPortionOptions()}
                  </select>
                </div>
                <div className="col-md-2">
                  <label>partner </label>
                  <select className="select-box"
                    value={jointAssetPartnerPortion}
                    onChange={(e) =>
                      onJointAssetPortionChanged(
                        100 - e.target.value,
                        e.target.value
                      )
                    }
                  >
                    {getPortionOptions()}
                  </select>
                </div>
              </div>
            )}
            {reason == "PASSED_AWAY" && (
              <div className="row mt-4">
                <div className="col-md-12">
                  <div className="subtitle2">
                    <h3>Passed-Away Treatment</h3>
                  </div>
                  <Tabs>
                    <TabList>
                      <Tab>Assets</Tab>
                      <Tab>Insurances</Tab>
                    </TabList>

                    <TabPanel>{tableMoveAssets()}</TabPanel>
                    <TabPanel>{tableClaimInsurances()}</TabPanel>
                  </Tabs>
                </div>
              </div>
            )}
          </>
        ) : (
          ""
        )}
        &nbsp;
        <div className="row mt-4">
          <div className="col d-flex justify-content-end ">
            <button
              className="portal-btn portal-btn-submit"
              onClick={(e) => clearModalOnly()}
            >
              Cancel
            </button>
            &nbsp;
            <button
              className="portal-btn portal-btn-submit"
              onClick={(e) => doProcess()}
            >
              Process
            </button>
          </div>
        </div>
      </div>
    )
  }

  function getSuccessContent() {
    const onGotoClick = (id) => {
      localStorage.setItem("activeClientId", id)
      clearModal()
      // window.location.href = `/personaldetails/${id}`
    }
    return (
      <div className="portal-form-popup">
        <div className="row mt-4">
          <h2>Data has been successfully separated</h2>
        </div>

        <div className="row mt-4">
          {successContent && successContent.clientId && (
            <div>
              <button
                type="button"
                className="portal-btn portal-btn-submit"
                onClick={(e) => onGotoClick(successContent.clientId)}
              >
                Go to {successContent.clientName}
              </button>
            </div>
          )}
          &nbsp;
          {successContent && successContent.partnerId && (
            <div>
              <button
                type="button"
                className="portal-btn portal-btn-submit"
                onClick={(e) => onGotoClick(successContent.partnerId)}
              >
                Go to {successContent.partnerName}
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }

  return getContent()
}

export default Separate
