import React, { Component, useEffect } from "react"
import {
  Redirect,
  Route,
  Switch,
  withRouter,
  matchPath
} from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { AppContext } from "../../context/AppContext"

// routes config
import routes from "../../routes"

// actions
import { ColorActions, entityActions, modalWindowActions } from "../../actions"

// helper
import { generateBreadCrumbLabel, truncate } from "../../helpers"

// components
import { BurgerMenuSelector } from "./BurgerMenu"
import { HeaderMenuSelector } from "./HeaderMenu"
import Feedback from "../../pages/Feedback/Feedback"
import { modalWindow } from "../../constants/ModalWindowLists"
import { createUrlFromPath } from "../../helpers/ImageHelper"
import { dashboardActions } from "../../actions/DashboardActions"

import { EntityTypeEnum } from "../../constants/Enums"
import Loadable from "react-loadable"
import Api from "../../lib/api.js"
import Layout from "../../components/Layout/Layout.js"
function Loading() {
  return <div>Loading...</div>
}
const LobbyPage = Loadable({
  loader: () => import("../../pages/ClientMenu/Lobby.js"),
  loading: Loading
})
function isClientManagementPathAllowed(activeUserRole, menuPath) {
  let cmAllowedPaths = activeUserRole
    ? activeUserRole.clientManagementAllowedPaths
    : undefined
  let arrAllowedPaths = cmAllowedPaths ? cmAllowedPaths.split(";") : []

  let result = false
  if (cmAllowedPaths) {
    if (menuPath.indexOf(":id") > -1) {
      menuPath = menuPath.replace(":id", "${clientId}") //to synch path with menu got from backend
    }
    if (menuPath.indexOf("?") > -1) {
      menuPath = menuPath.replace("?", "")
    }

    arrAllowedPaths.forEach((el) => {
      if (menuPath.indexOf(el) > -1) {
        result = true
      }
    })
  }
  return result
}

function isBusinessManagementPathAllowed(activeUserRole, menuPath) {
  let bmAllowedPaths = activeUserRole
    ? activeUserRole.businessManagementAllowedPaths
    : ""
  let result = false
  if (bmAllowedPaths) {
    result = bmAllowedPaths.indexOf(menuPath) > -1
  }
  return result
}

function isExceptionalRoute(r) {
  let result = false
  let arrExceptional = [
    "/businessManagement",
    "/taskmanagement",
    "/client",
    "/undermaintenance",
    "/fxRates",
    "/notfound",
    "/demo/clients",
    "/koizai_files"
  ]
  let loginEntityType = localStorage.getItem("loginEntityType")
  arrExceptional.push("/dashboard/clients")
  if (loginEntityType == EntityTypeEnum.EntityTypeEnum_BUILT_IN_ADVISER) {
  } else if (
    loginEntityType == EntityTypeEnum.EntityTypeEnum_BUILT_IN_SUB_BRANCH
  ) {
    arrExceptional.push("/dashboard/advisers")
  } else if (
    loginEntityType == EntityTypeEnum.EntityTypeEnum_BUILT_IN_ADVISER_GROUP
  ) {
    arrExceptional.push("/dashboard/subbranches")
    arrExceptional.push("/dashboard/advisers")
  } else if (
    loginEntityType == EntityTypeEnum.EntityTypeEnum_BUILT_IN_DEALERSHIP
  ) {
    arrExceptional.push("/dashboard/advisergroups")
    arrExceptional.push("/dashboard/subbranches")
    arrExceptional.push("/dashboard/advisers")
  } else if (
    loginEntityType ==
    EntityTypeEnum.EntityTypeEnum_BUILT_IN_MALABAR_AI_SUBSIDIARY
  ) {
    arrExceptional.push("/dashboard/dealerships")
    arrExceptional.push("/dashboard/advisergroups")
    arrExceptional.push("/dashboard/subbranches")
    arrExceptional.push("/dashboard/advisers")
  } else if (
    loginEntityType == EntityTypeEnum.EntityTypeEnum_BUILT_IN_MALABAR_AI_GLOBAL
  ) {
    arrExceptional.push("/dashboard/subsidiaries")
    arrExceptional.push("/dashboard/dealerships")
    arrExceptional.push("/dashboard/advisergroups")
    arrExceptional.push("/dashboard/subbranches")
    arrExceptional.push("/dashboard/advisers")
  }

  arrExceptional.forEach((element) => {
    if (r.path.startsWith(element)) {
      result = true
    }
  })
  return result
}

function getDynamicRoutes() {
  let dynamicRoutes = []
  let storedItem = localStorage.getItem("activeUserRole")
  if (storedItem) {
    let activeUserRole = JSON.parse(storedItem)
    routes.forEach((r) => {
      //start checking
      if (r.path != null) {
        if (isExceptionalRoute(r)) {
          dynamicRoutes.push(r)
        } else {
          if (r.isClient) {
            let isCmAllowed = isClientManagementPathAllowed(
              activeUserRole,
              r.path
            )
            if (isCmAllowed) {
              dynamicRoutes.push(r)
            }
          } else {
            let isBmAllowed = isBusinessManagementPathAllowed(
              activeUserRole,
              r.path
            )
            if (isBmAllowed) {
              dynamicRoutes.push(r)
            }
          }
        }
      }
    })
  }

  return dynamicRoutes
}

class CustomerLayout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isSidebarInActive: false
    }

    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  handleKeyPress(event) {
    if (event.key === "[") {
      this.setState({ isSidebarInActive: !this.state.isSidebarInActive })
    }
  }

  async componentDidMount() {
    //get dashboard data by client ID or adviser entity ID
    if (!localStorage.getItem("activeEntityId")) {
      this.props.getDashboardDataById(localStorage.getItem("activeEntityId"))
    }
    const res = await Api.get(`/entity/logged-entity-breadcrumbs`)
    if (res.data && res.data.list.length) {
      let filteredList = res.data.list.map((item, idx) => item.split(";")[1])
      this.props.setDashboardBreadCrumbLabelRequest(
        generateBreadCrumbLabel(filteredList)
      )
    }

    // Event for toggling sidebar when pressing '[' button.
    window.addEventListener("keydown", this.handleKeyPress)
  }

  forceRefresh = () => {
    this.setState((prevState) => ({
      ...prevState
    }))
  }

  // check path name and check is this page belongs to client
  getClientStatusFromPathName = () => {
    const currentRoute = getDynamicRoutes().find((d) =>
      matchPath(this.props.location.pathname, d)
    )
    return currentRoute ? currentRoute.isClient : ""
  }
  componentDidMount() {
    this.fetchGlobalColors()
  }

  fetchGlobalColors = async () => {
    try {
      const res = await Api.get("/user/profile-theme")
      let colors = {
        titleBarColour: res.data.titleBarColour,
        fontColour: res.data.fontColour,
        dealershipPhoto: res.data.companyLogoUrl
          ? createUrlFromPath(res.data.companyLogoUrl)
          : ""
      }
      this.props.setColorSuccess(colors)
    } catch (error) {
      console.log("error", error)
    }
  }

  render() {
    const {
      activeClassName,
      activeEntityType,
      activeBreadCrumbLabel,
      location,
      activeUserEntity,
      activeDashboardRoute,
      titleBarColour,
      fontColour,
      entityType,
      selectedEntity,
      firstTimeLogin,
      dashboardData,
      setDashboardBreadCrumbLabelRequest
    } = this.props

    let selectedEntityId = this.props.selectedEntity.id.replace(
      this.props.selectedEntity.resourceServerId + "-",
      ""
    )

    const toggleSidebar = () => {
      this.setState({ isSidebarInActive: !this.state.isSidebarInActive })
    }

    function getNotMatchRedirection() {
      let result = "/notfound"
      let currentUrlPath = window.location.pathname
      if (currentUrlPath == process.env.REACT_APP_BASE_URL) {
        result = activeDashboardRoute
      }
      return result
    }

    let lobbyPage = {
      component: LobbyPage,
      name: "Home",
      path: "/lobby"
    }

    return (
      <React.Fragment>
        <Switch>
          {[...getDynamicRoutes(), lobbyPage].map((route, idx) =>
            route.component ? (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                render={(props) => (
                  <React.Fragment>
                    <div
                      data-test
                      style={{ height: `100%` }}
                      className={`${
                        window.location.pathname.includes("/lobby")
                          ? "lobby-container"
                          : ""
                      }  ${
                        props.match.params.id
                          ? ""
                          : activeClassName
                          ? activeClassName
                          : ""
                      } ${
                        this.state.isSidebarInActive === true
                          ? "sidebar-inactive"
                          : ""
                      }`}
                    >
                    
                      

                      
                      <Layout>
                        
                        <HeaderMenuSelector
                          title={truncate(activeBreadCrumbLabel, 100, true)}
                          entityTypeEnum={activeEntityType}
                          photoUrl={
                            activeUserEntity.photo && activeUserEntity.photo.url
                              ? createUrlFromPath(activeUserEntity.photo.url)
                              : undefined
                          }
                          forceRefresh={this.forceRefresh}
                          titleBarColour={titleBarColour}
                          fontColour={fontColour}
                          selectedEntity={selectedEntity}
                          firstTimeLogin={firstTimeLogin}
                          country={selectedEntity.country}
                          dashboardData={dashboardData.length}
                          toggleSidebar={toggleSidebar}
                        />
                       
                        <BurgerMenuSelector
                          clientId={selectedEntityId}
                          entityTypeEnum={activeEntityType}
                          feedbackClicked={() =>
                            this.props.showModalWindowAndValue(
                              modalWindow.FEEDBACK_MODAL,
                              null
                            )
                          }
                          toggleSidebar={toggleSidebar}
                          isSidebarInActive= {this.state.isSidebarInActive}
                          location={location}
                          titleBarColour={titleBarColour}
                          fontColour={fontColour}
                          entityType={entityType}
                          {...props}
                        />

                        <route.component
                          {...props}
                          componentName={route.componentName}
                        />
                        
                      </Layout>
                    </div>
                  </React.Fragment>
                )}
              />
            ) : null
          )}
          {/* <Route/> */}

          <Redirect from="/" to={getNotMatchRedirection()} />
        </Switch>
        <Feedback componentName={modalWindow.FEEDBACK_MODAL} />
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const {
    activeClassName,
    activeDashboardRoute,
    activeEntityType,
    isBurgerMenuClient,
    activeUserId,
    activeUserEntity
  } = state.appSettings

  const { dashboardBreadCrumb, dashboardData } = state.dashboard
  const { clientId } = state.entity

  return {
    activeBreadCrumbLabel: dashboardBreadCrumb,
    activeDashboardRoute,
    activeClassName,
    activeEntityType,
    isBurgerMenuClient,
    activeUserId,
    activeUserEntity,
    clientId,
    entityType: state.authentication.user.selectedEntity.entityType,
    selectedEntity: state.authentication.user.selectedEntity,
    firstTimeLogin: state.authentication.user.firstTimeLogin,
    titleBarColour: state.color.titleBarColour
      ? state.color.titleBarColour
      : "",
    fontColour: state.color.fontColour ? state.color.fontColour : "",
    dashboardData: state.dashboard.dashboardData
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign(
      {},
      entityActions,
      dashboardActions,
      modalWindowActions,
      ColorActions
    ),
    dispatch
  )
}

CustomerLayout.contextType = AppContext

CustomerLayout = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomerLayout)
)

export default CustomerLayout
