/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Component : MalabarGrid
    Description : create custom grid that accept data in json, including its column property, call onGridChange props to return back the data

    exampleData: [
        {
            childName: 'Child 1',
            relationship: 'OPT2',
            dob: '2018-01-31',
            livingWith: 'OPT3',
            isDisabled: false,
            yearOfSupport: '1988'
        },
        {
            childName: 'Child 2',
            relationship: 'OPT3',
            dob: '2019-12-01',
            livingWith: 'OPT1',
            isDisabled: true,
            yearOfSupport: '2007'
        },
        {
            childName: 'Child 3',
            relationship: 'OPT1',
            dob: '2019-12-01',
            livingWith: 'OPT1',
            isDisabled: true,
            yearOfSupport: '2007'
        },
    ]

    exampleColumns: [
        {
            header: "Child Name",
            accessor: "childName",
            dataType: "string"
        },
        {
            header: "Relationship",
            accessor: "relationship",
            dataType: "select",
            dataSelect: [
                {
                    value: "OPT1",
                    text: "option 1"
                },
                {
                    value: "OPT2",
                    text: "option 2"
                },
                {
                    value: "OPT3",
                    text: "option 3"
                },
            ]
        },
        {
            header: "Date of Birth",
            accessor: "dob",
            dataType: "date"
        },
        {
            header: "Living With",
            accessor: "livingWith",
            dataType: "select",
            dataSelect: [
                {
                    value: "OPT1",
                    text: "option 1"
                },
                {
                    value: "OPT2",
                    text: "option 2"
                },
                {
                    value: "OPT3",
                    text: "option 3"
                },
            ]
        },
        {
            header: "Disabled ?",
            accessor: "isDisabled",
            dataType: "bool"
        },
        {
            header: "Years of Support",
            accessor: "yearOfSupport",
            dataType: "number"
        },
    ]

*/

import React, { Component } from 'react';
import _ from 'lodash';
import { Input } from 'reactstrap';
import { MalabarDate, MalabarPhoneNumber } from '..';

export default class MalabarGrid extends Component {
    state = {
        data: this.props.data,
        columns: this.props.columns,
        checkedIndex: [],
    };

    componentDidMount() {
        this.uncheckAll(this.state.data);
    }

    // toggle check header datarow checkbox
    toggleCheckAll = e => {
        if (e.target.checked) {
            this.checkAll(this.state.data);
        } else {
            this.uncheckAll(this.state.data);
        }
    };

    // check all datarow checkbox
    checkAll = dataRef => {
        const checkedIndex = [];
        for (let index = 0; index < dataRef.length; index++) {
            checkedIndex.push(true);
        }
        this.setState({ checkedIndex });
    };

    // uncheck all datarow checkbox
    uncheckAll = dataRef => {
        const checkedIndex = [];
        for (let index = 0; index < dataRef.length; index++) {
            checkedIndex.push(false);
        }
        this.setState({ checkedIndex });
    };

    handleInputOnChange = (
        e,
        dataIndex,
        key,
        isBoolean = false,
        isCallbackValue = false
    ) => {
        const value = isCallbackValue ? e : e.target.value;
        const checked = isBoolean ? e.target.checked : null;

        // get items to edit
        const items = [...this.state.data];
        // get the index
        const editedDataIdx = _.findIndex(this.state.data, {
            index: dataIndex,
        });
        // edit data
        const editedItem = {
            ...this.state.data[editedDataIdx],
            [key]: isBoolean ? checked : value,
        };
        items[editedDataIdx] = editedItem;
        // save to state
        this.setState({ data: items }, () => {
            this.props.onGridChange(this.state.data);
        });
    };

    onSelectGridRowIndex = index => {
        const currentArray = this.state.checkedIndex;
        currentArray[index] = !currentArray[index];
        this.setState({
            checkedIndex: currentArray,
        });
    };

    handleAddData = () => {
        const newDataArray = this.state.data;
        let newItem = Object;

        // check each data type for different default value
        this.state.columns.forEach(item => {
            switch (item.dataType) {
                case 'string':
                case 'select':
                    newItem = {
                        ...newItem,
                        [item.accessor]: '',
                    };
                    break;
                case 'date':
                    newItem = {
                        ...newItem,
                        [item.accessor]: '1900-01-01',
                    };
                    break;
                case 'bool':
                    newItem = {
                        ...newItem,
                        [item.accessor]: false,
                    };
                    break;
                case 'number':
                    newItem = {
                        ...newItem,
                        [item.accessor]: 0,
                    };
                    break;

                default:
                    break;
            }
        });

        // push new data to array
        newDataArray.push(newItem);
        this.setState(
            {
                data: newDataArray,
            },
            () => {
                // send data to caller
                this.props.onGridChange(this.state.data);
            }
        );
    };

    handleDeleteData = () => {
        // remove selected index data
        const newDataArray = this.state.data;
        for (
            let index = this.state.checkedIndex.length - 1;
            index > -1;
            index--
        ) {
            if (this.state.checkedIndex[index]) {
                newDataArray.splice(index, 1);
            }
        }

        this.setState(
            {
                data: newDataArray,
            },
            () => {
                // uncheck all
                this.uncheckAll(newDataArray);
                // send data to caller
                this.props.onGridChange(this.state.data);
            }
        );
    };

    render() {
        const {
            handleInputOnChange,
            handleAddData,
            handleDeleteData,
            onSelectGridRowIndex,
            toggleCheckAll,
        } = this;
        const { tableTitle } = this.props;
        const { data, columns, checkedIndex } = this.state;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col">
                        <h6 className="sub-title">{tableTitle}:</h6>
                    </div>
                    <div className="col col-md-auto text-right">
                        <span
                            className="btn-icon primary white-button"
                            onClick={handleAddData}
                        >
                            <ion-icon name="ios-add" />
                        </span>
                        <span
                            className="btn-icon outline"
                            onClick={handleDeleteData}
                        >
                            <ion-icon name="ios-trash" />
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>
                                        <Input
                                            type={'checkbox'}
                                            className="header-check-input-grid"
                                            onChange={e => toggleCheckAll(e)}
                                            defaultChecked={false}
                                        />
                                    </th>
                                    {columns.map((item, index) => (
                                        <th key={index}>{item.header}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => {
                                    item.index = index;
                                    const renderGrid = [];
                                    let idx = 0;

                                    renderGrid.push(
                                        <td id={`ch_${index}`} key={idx}>
                                            <Input
                                                type={'checkbox'}
                                                className="header-check-input-grid row-check-grid"
                                                onChange={() =>
                                                    onSelectGridRowIndex(index)
                                                }
                                                checked={checkedIndex[index]}
                                            />
                                        </td>
                                    );
                                    idx++;

                                    _.forEach(item, (value, key) => {
                                        const columnProps = _.find(columns, [
                                            'accessor',
                                            key,
                                        ]);

                                        if (columnProps !== undefined) {
                                            switch (columnProps.dataType) {
                                                case 'string':
                                                    renderGrid.push(
                                                        <td key={idx}>
                                                            <Input
                                                                type={'text'}
                                                                value={value}
                                                                onChange={el =>
                                                                    handleInputOnChange(
                                                                        el,
                                                                        index,
                                                                        key
                                                                    )
                                                                }
                                                            />
                                                        </td>
                                                    );
                                                    break;
                                                case 'select':
                                                    const renderSelect = [];
                                                    columnProps.dataSelect.forEach(
                                                        (
                                                            selectItem,
                                                            selectIndex
                                                        ) => {
                                                            renderSelect.push(
                                                                <option
                                                                    key={
                                                                        selectIndex
                                                                    }
                                                                    value={
                                                                        selectItem.value
                                                                    }
                                                                >
                                                                    {
                                                                        selectItem.text
                                                                    }
                                                                </option>
                                                            );
                                                        }
                                                    );

                                                    renderGrid.push(
                                                        <td key={idx}>
                                                            <div className="select-wrapper">
                                                                <select
                                                                    value={
                                                                        value
                                                                    }
                                                                    onChange={el =>
                                                                        handleInputOnChange(
                                                                            el,
                                                                            index,
                                                                            key
                                                                        )
                                                                    }
                                                                >
                                                                    {
                                                                        renderSelect
                                                                    }
                                                                </select>
                                                            </div>
                                                        </td>
                                                    );
                                                    break;
                                                case 'date':
                                                    renderGrid.push(
                                                        <td key={idx}>
                                                            {/* <Input type={'date'} value={value} onChange={(el)=>handleInputOnChange(el, index, key)}/> */}
                                                            <MalabarDate
                                                                isLabel={false}
                                                                name={`${columnProps.accessor}_${idx}`}
                                                                required={false}
                                                                onChange={el =>
                                                                    handleInputOnChange(
                                                                        el,
                                                                        index,
                                                                        key,
                                                                        false,
                                                                        true
                                                                    )
                                                                }
                                                                value={value}
                                                            />
                                                        </td>
                                                    );
                                                    break;
                                                case 'bool':
                                                    renderGrid.push(
                                                        <td key={idx}>
                                                            <div className="form-check check-right">
                                                                <Input
                                                                    type={
                                                                        'checkbox'
                                                                    }
                                                                    className="form-check-input"
                                                                    onChange={el =>
                                                                        handleInputOnChange(
                                                                            el,
                                                                            index,
                                                                            key,
                                                                            true
                                                                        )
                                                                    }
                                                                    defaultChecked={
                                                                        value
                                                                    }
                                                                />
                                                                <p>Yes</p>
                                                            </div>
                                                        </td>
                                                    );
                                                    break;
                                                case 'number':
                                                    renderGrid.push(
                                                        <td key={idx}>
                                                            <Input
                                                                type={'number'}
                                                                value={value}
                                                                onChange={el =>
                                                                    handleInputOnChange(
                                                                        el,
                                                                        index,
                                                                        key
                                                                    )
                                                                }
                                                            />
                                                        </td>
                                                    );
                                                    break;
                                                case 'phone':
                                                    renderGrid.push(
                                                        <td key={idx}>
                                                            <MalabarPhoneNumber
                                                                isLabel={false}
                                                                name={`${columnProps.accessor}_${idx}`}
                                                                required={false}
                                                                onChange={el =>
                                                                    handleInputOnChange(
                                                                        el,
                                                                        index,
                                                                        key,
                                                                        false,
                                                                        true
                                                                    )
                                                                }
                                                                value={value}
                                                            />
                                                        </td>
                                                    );
                                                    break;
                                                default:
                                                    break;
                                            }
                                        }
                                        idx++;
                                    });

                                    return <tr key={index}>{renderGrid}</tr>;
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
