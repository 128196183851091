/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Description : collectible/antiques/arts page (modal)
*/

import React, { useState, useEffect } from "react"
import intl from "react-intl-universal"
import RenderSimpleFields, {
  FieldType
} from "../components/Form/RenderSimpleFields"
import ClassificationSelection from "../components/_hook/ClassificationSelection"
import { useSelector } from "react-redux"
import { NetWorthApi } from "../api"
import AssetRiskClassificationApi from "../api/AssetRiskClassificationApi"
import { Field } from "react-final-form"
import { fileFieldRender } from "../helpers"
function CollectibleAntiqueArtsMainForm({
  client,
  partner,
  isJoint,
  readOnly = false,
  model,
  mutators
}) {
  const { defaultCurrency } = useSelector((state) => state.personalDetails)
  const [cashAccounts, setCashAccounts] = useState([])
  const [productTypes, setProductTypes] = useState([])
  useEffect(() => {
    let clientId = localStorage.getItem("activeClientId")
    NetWorthApi.getClientCashAccounts(clientId).then(({ data }) => {
      setCashAccounts(data)
    })
    AssetRiskClassificationApi.getProductTypes("COLLECTIBLE").then(
      ({ data }) => {
        if (data) {
          setProductTypes(data.data)
        }
      }
    )
  }, [])

  function getCashOptions() {
    let result = []
    if (cashAccounts) {
      cashAccounts.forEach((c) => {
        result.push(
          <option key={`enum-${c.id}`} value={c.id}>
            {c.accountNumber ? c.accountNumber : c.accountName}{" "}
            {c.primary ? "(primary)" : ""}
          </option>
        )
      })
    }
    return result
  }
  if (!model.id) {
    model.currentValue.currency = defaultCurrency
    model.valuationDate = new Date()
  }
  let fields = [
    {
      name: "productTypeCode",
      label: intl.get("field.productType"),
      type: FieldType.SELECTION,
      selectionData: productTypes,
      valueProperty: "code",
      labelProperty: "name",
      required: true
    },
    {
      name: "description",
      label: intl.get("field.itemDescription"),
      type: FieldType.TEXT,
      required: true
    },
    {
      name: "yearPurchased",
      label: intl.get("field.yearPurchased"),
      type: FieldType.YEAR,
      min: 1900,
      max: new Date().getFullYear()
    },
    {
      name: "currentValue",
      label: intl.get("field.currentValue"),
      type: FieldType.MONEY_WITH_CURRENCY,
      required: true
    },
    {
      name: "valuationDate",
      label: intl.get("field.valuationDate"),
      type: FieldType.DATE,
      required: true
    },
    {
      name: "annualGrowthRate",
      label: intl.get("field.annualGrowthRate"),
      type: FieldType.PERCENTAGE,
      required: true
    },
    {
      name: "underLoan",
      label: intl.get("field.isItemFinancedThruLoan"),
      type: FieldType.CHECKBOX,
      checkboxLabel: intl.get("YesNoEnum_YES")
    },
    {
      name: "insured",
      label: intl.get("field.isItemInsured"),
      type: FieldType.CHECKBOX,
      checkboxLabel: intl.get("YesNoEnum_YES")
    }
  ]

  return (
    <React.Fragment>
      <ClassificationSelection
        client={{
          name:
            client && client.personalDetails
              ? client.personalDetails.personalInformation.firstName
              : ""
        }}
        partner={{
          name:
            partner && partner.personalDetails
              ? partner.personalDetails.personalInformation.firstName
              : ""
        }}
        isJoint={isJoint}
        hideJoint={!isJoint || readOnly}
      />

      <div className="subtitle2">
        {intl.get("collectibleAntiques.subtitle")}
      </div>

      <RenderSimpleFields fields={fields} />
      <Field
        component={fileFieldRender}
        customClassName="mt-1 d-block"
        labelIdle="Drag, Drop or Browse"
        label="Upload Documents"
        onupdatefiles={(fileItems) => {
          mutators.setFormAttribute("proofDocument", fileItems[0])
        }}
        fileInformation={(model && model.proofDocument) || null}
      />
    </React.Fragment>
  )
}

export default CollectibleAntiqueArtsMainForm
