/*
    Author : Marlina (marlina@devstack.com.au)
    Description : Feedback title component
*/
import React from 'react';

const FeedbackTitle = ({ title }) => (
    <div className="dahboard-feedbacklog">
        <div className="title">{title}</div>

        <div className="close-holder">
            <button className="close-button" />
        </div>
    </div>
);
export default FeedbackTitle;
