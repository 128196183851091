/*
    Author : Erik (erik.malik@devstack.com.au)
    Description : Adviser Weekly Sales Lodged dashboard page
*/

import React, { Component } from 'react';
import { AlphabetSearch, GroupedOverviewBoxes } from '../../../components';
import { MalabarDashboardRightMenu } from '../../../components/MalabarMenu';
import { EntityTypeEnum } from '../../../constants/Enums';

class AdviserWeeklySalesLodgedDashboard extends Component {
    // TODO: get it from back end through store code:0992384302984
    getOverviews = () => [
        {
            title: 'Weekly Sales Lodged',
            figure: '50',
            timing: '5% From Last week',
            color: 'green',
            link: '/dashboard/adviser/weeklySalesLodged',
        },
        {
            title: 'Monthly Revenue Lodged',
            figure: '$12,600',
            timing: '0% Last Year',
        },
        {
            title: 'Top Business',
            figure: 'Financial Planning Fees',
        },
        {
            title: 'Monthly Revenue Received',
            figure: '$15,600',
            timing: '-10% From Last week',
            color: 'red',
        },
        {
            title: 'Sales Revenue Paid To Date',
            figure: '$150,890',
            timing: '0% Last Year',
        },
        {
            title: 'Complaints',
            figure: '1',
            timing: '100% Last Year',
            color: 'red',
        },
    ];

    render() {
        const { getOverviews } = this;
        const overviews = getOverviews();
        const entityType = EntityTypeEnum.EntityTypeEnum_BUILT_IN_ADVISER_GROUP;

        return (
            <React.Fragment>
                <div className="height-100 dashboard-adviser">
                    <div className="portal-maincontent">
                        <div className="dashboard-content-panel">
                            <GroupedOverviewBoxes
                                overviews={overviews}
                                selected="Weekly Sales Lodged"
                            />

                            <div className="dahboard-toolbar">
                                <div className="title">Dashboard</div>
                                <div className="toolbar-links">
                                    <span role="button">Sales</span>
                                    <span className="selected">Revenue</span>
                                </div>
                                <div className="toolbar-time">
                                    <span className="ico-calendar" />
                                    <span>YTD</span>
                                    <span>YTM</span>
                                    <span className="selected">
                                        Last 12 Months
                                    </span>
                                </div>
                                <div className="close-holder">
                                    <button
                                        type="button"
                                        className="close-button"
                                    />
                                </div>
                            </div>
                            <div className="flexbox">
                                <div className="box-half box-info">
                                    <div className="title">
                                        <h1>Performance</h1>
                                        <div className="icon-holder">
                                            <span className="ico-print" />
                                        </div>
                                    </div>
                                    <div className="graph-holder">
                                        <img
                                            alt=""
                                            src="images/graph-bar1.jpg"
                                            className="img-responsive70"
                                        />
                                    </div>
                                </div>

                                <div className="box-half box-info">
                                    <div className="title">
                                        <h1>Business Line</h1>
                                        <div className="icon-holder">
                                            <span className="ico-print" />
                                        </div>
                                    </div>
                                    <div className="graph-holder">
                                        <img
                                            alt=""
                                            src="images/graph-bar2.jpg"
                                            className="img-responsive70"
                                        />
                                    </div>
                                </div>

                                <div className="box-full box-info">
                                    <div className="title">
                                        <h1>Winnie Cheung</h1>
                                        <div className="middle">
                                            <span className="button button-graph">
                                                Vs. Prior Year
                                            </span>
                                            <span className="button button-graph">
                                                Vs. Planned
                                            </span>
                                        </div>
                                        <div className="icon-holder">
                                            <span className="ico-print" />
                                        </div>
                                    </div>
                                    <div className="graph-holder">
                                        <img
                                            alt="graph"
                                            src="images/graph-2.jpg"
                                            className="img-responsive"
                                        />
                                    </div>
                                </div>

                                <div className="box-full box-info">
                                    <div className="title">
                                        <h1>Clients</h1>
                                        <div className="icon-holder">
                                            <span className="ico-print" />
                                        </div>
                                    </div>
                                    <div className="alphabet-box">
                                        <AlphabetSearch
                                            onSelect={letter =>
                                                console.log(
                                                    `Letter ${letter} is selected!`
                                                )
                                            }
                                        />

                                        <div className="alphabet-R">
                                            <input
                                                type="search"
                                                placeholder="Search"
                                            />
                                        </div>
                                    </div>

                                    <table
                                        width="100%"
                                        border="0"
                                        cellSpacing="0"
                                        cellPadding="0"
                                        className="table-blue table-client"
                                    >
                                        <tbody>
                                            <tr>
                                                <th>&nbsp;</th>
                                                <th>Client</th>
                                                <th>Last Review</th>
                                                <th>Category</th>
                                                <th>FUM $</th>
                                                <th>New Revenue $</th>
                                                <th>Revenue to Date $</th>
                                            </tr>
                                            <tr>
                                                <td>1.</td>
                                                <td>Peter Wong</td>
                                                <td>8/8/2018</td>
                                                <td>Silver</td>
                                                <td>3,750,000</td>
                                                <td>$15,000</td>
                                                <td>$22,000</td>
                                            </tr>

                                            <tr>
                                                <td>2.</td>
                                                <td>Paula Jones</td>
                                                <td>9/2/2017</td>
                                                <td>Gold</td>
                                                <td>8,980,000</td>
                                                <td>$4.250</td>
                                                <td>$5,120</td>
                                            </tr>

                                            <tr>
                                                <td>3.</td>
                                                <td>Lee Ryan</td>
                                                <td>8/7/2018</td>
                                                <td>Bronze</td>
                                                <td>750,000</td>
                                                <td>$1,500</td>
                                                <td>$1,500</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <MalabarDashboardRightMenu
                            entityType={entityType}
                            
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AdviserWeeklySalesLodgedDashboard;
