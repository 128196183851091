export const modalWindowActions = {
    showModalWindow,
    showModalWindowAndValue,
    closeModalWindow
};

// action types
export function showModalWindowRequest(modalWindowName) {
    return { type: `OPEN_${modalWindowName}` };
}
// action types
export function showModalWindowAndValueRequest(modalWindowName, value) {
    return { type: `OPEN_${modalWindowName}`, value };
}

export function closeModalWindowRequest(modalWindowName) {
    return { type: `CLOSE_${modalWindowName}` };
}

// action functions
export function showModalWindow(modalWindowName) {
    return async (dispatch, getState) => {
        // call request is here
        dispatch(showModalWindowRequest(modalWindowName));
    };
}
// action functions
export function showModalWindowAndValue(modalWindowName, value) {
    return async (dispatch, getState) => {
        // call request is here
        dispatch(showModalWindowAndValueRequest(modalWindowName, value));
    };
}

export function closeModalWindow(modalWindowName) {
    return async (dispatch, getState) => {
        // call request is here
        dispatch(closeModalWindowRequest(modalWindowName));
    };
}
