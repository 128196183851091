import React from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';
import intl from 'react-intl-universal';
import CashFlowHeaderListing from '../CashFlowHeaderListing';
import { InvestmentProperty, RegularSaving } from './OutflowList';

const InvestmentOutflow = ({
    varPrefix,
    optionsData,
    push,
    outflowTotalValues: { investmentProperty, regularSaving, total },
    currency,
    onExternalUpdated,
    clientInformation,
    values,
}) => {
    const readOnlyAccessComponent = () => null;
    const client = clientInformation;
    const partner = clientInformation.partner || {};
    const isJoint = clientInformation.partner || false;

    return (
        <Accordion>
            <AccordionItem>
                <AccordionItemTitle>
                    <div className="subtitle2b">
                        {intl.get('cashflowheader.top.investmentOutflow')}
                        <div className="accordion__arrow" />
                    </div>
                </AccordionItemTitle>
                <AccordionItemBody>
                    <InvestmentProperty
                        varPrefix={varPrefix}
                        push={push}
                        clientTotalValue={investmentProperty.clientTotal}
                        partnerTotalValue={investmentProperty.partnerTotal}
                        jointTotalValue={investmentProperty.jointTotal}
                        optionsData={optionsData}
                        clientInformation={{
                            client,
                            partner,
                            isJoint,
                        }}
                        readOnlyAccessComponent={readOnlyAccessComponent}
                        currency={currency}
                        onExternalUpdated={result => onExternalUpdated(result)}
                        isJointHide={!isJoint}
                        values={values}
                    />

                    <RegularSaving
                        varPrefix={varPrefix}
                        push={push}
                        clientTotalValue={regularSaving.clientTotal}
                        partnerTotalValue={regularSaving.partnerTotal}
                        jointTotalValue={regularSaving.jointTotal}
                        optionsData={optionsData}
                        clientInformation={{
                            client,
                            partner,
                            isJoint,
                        }}
                        readOnlyAccessComponent={readOnlyAccessComponent}
                        currency={currency}
                        onExternalUpdated={result => onExternalUpdated(result)}
                        isJointHide={!isJoint}
                        values={values}
                    />

                    <div className="portal-part">
                        <CashFlowHeaderListing
                            title="Total Outflow"
                            clientTotalValue={total.clientTotal}
                            partnerTotalValue={total.partnerTotal}
                            jointTotalValue={total.jointTotal}
                            currency={currency}
                            isJointHide={!isJoint}
                        />
                    </div>
                </AccordionItemBody>
            </AccordionItem>
        </Accordion>
    );
};

export default InvestmentOutflow;
