import React, { useState, useEffect } from "react"
import intl from "react-intl-universal"
import { Field, Form, FormSpy } from "react-final-form"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { FormButtons, ModalNoAccess } from "../components"
import RenderSimpleFields, {
  FieldType
} from "../components/Form/RenderSimpleFields"
import ClassificationSelection from "../components/_hook/ClassificationSelection"
import GeneralLoanDetails from "./sub-forms/GeneralLoanDetails"
import { getSymbolFromCode, getCurrency, fileFieldRender } from "../helpers"
import { NetWorthApi } from "../api"
import AssetRiskClassificationApi from "../api/AssetRiskClassificationApi"
function SidePane({
  isShowSidePane,
  optionsData,
  showMortgageAssociatedCharges,
  defaultCurrency = "MYR",
  values
}) {
  if (!isShowSidePane) {
    return <></>
  }

  return (
    <div className="portal-form-half">
      <Tabs>
        <TabList>
          <Tab>{intl.get("tabs.marginLoanDetails")}</Tab>
        </TabList>
        <TabPanel>
          <GeneralLoanDetails
            optionsData={optionsData}
            showMortgageAssociatedCharges={showMortgageAssociatedCharges}
            objectName="marginLoan"
            defaultCurrency={defaultCurrency}
            values={values.marginLoan || {}}
          />
        </TabPanel>
      </Tabs>
    </div>
  )
}
function MainForm({
  values,
  clientInformation: { client, partner = {}, isJoint },
  optionsData,
  onFaceValueChange,
  onTradingCurrencyChange,
  defaultCurrency = "MYR",
  setFormAttribute
}) {
  const { taxResidency = "" } = client
  const taxRecidencyIsAustralia =
    taxResidency.toLowerCase() === "australia" ||
    taxResidency.toLowerCase() === "au"
  const { financedThruLoan: isShowSidePane } = values
  const [cashAccounts, setCashAccounts] = useState([])
  const [productTypes, setProductTypes] = useState([])
  useEffect(() => {
    let clientId = localStorage.getItem("activeClientId")
    NetWorthApi.getClientCashAccounts(clientId).then(({ data }) => {
      setCashAccounts(data)
    })
    AssetRiskClassificationApi.getProductTypes("BOND").then(({ data }) => {
      if (data) {
        setProductTypes(data.data)
      }
    })
  }, [])

  function getCashOptions() {
    let result = []
    if (cashAccounts) {
      cashAccounts.forEach((c) => {
        result.push(
          <option key={`enum-${c.id}`} value={c.id}>
            {c.accountNumber ? c.accountNumber : c.accountName}{" "}
            {c.primary ? "(primary)" : ""}
          </option>
        )
      })
    }
    return result
  }

  const fields = [
    {
      name: "bondNumber",
      label: intl.get("field.uniqueCode"),
      type: FieldType.TEXT,
      required: true
    },
    {
      name: "bondIssuer",
      label: intl.get("field.bondIssuer"),
      type: FieldType.TEXT,
      required: false
    },
    {
      name: "productTypeCode",
      label: intl.get("field.productType"),
      type: FieldType.SELECTION,
      selectionData: productTypes,
      valueProperty: "code",
      labelProperty: "name",
      required: true
    },
    {
      name: "managedBy",
      label: intl.get("field.managedBy"),
      type: FieldType.ENUM_SELECTION,
      enumData: optionsData.managedByOptions,
      required: false
    },

    {
      name: "bondType",
      label: intl.get("field.bondType"),
      type: FieldType.ENUM_SELECTION,
      enumData: optionsData.bondTypeOptions,
      required: false
    },

    {
      name: "tradingCurrency",
      label: intl.get("field.tradingCurrency"),
      type: FieldType.ENUM_SELECTION,
      enumData: optionsData.currencyOptions,
      onChange: onTradingCurrencyChange,
      required: false,
      defaultValue: defaultCurrency
    },
    {
      name: "faceValue",
      label: intl.get("field.faceValue"),
      type: FieldType.MONEY,
      onChange: onFaceValueChange,
      required: true,
      symbolPrefix: getSymbolFromCode(
        values.tradingCurrency || defaultCurrency
      ),
      defaultCurrency: values.tradingCurrency || defaultCurrency,
      allowNegative: false
    },
    {
      name: "couponRate",
      label: intl.get("field.couponRate"),
      type: FieldType.PERCENTAGE,
      required: true
    },
    {
      name: "couponFrequency",
      label: intl.get("field.couponFrequency"),
      type: FieldType.ENUM_SELECTION,
      enumData: optionsData.frequencyOptions,
      required: true
    },
    {
      name: "purchasedDate",
      label: intl.get("field.purchasedDate"),
      type: FieldType.DATE,
      required: true
    },
    {
      name: "maturityDate",
      label: intl.get("field.maturityDate"),
      type: FieldType.DATE,
      required: true
    },
    {
      name: "targetCashId",
      label: intl.get("field.settlementCash"),
      type: FieldType.ENUM_SELECTION,
      enumData: getCashOptions(),
      required: true
    },
    {
      name: "rollover",
      label: intl.get("field.rollover"),
      checkboxLabel: intl.get("YesNoEnum_YES"),
      type: FieldType.CHECKBOX
    },
    {
      name: "brokageFee",
      type: FieldType.MONEY_WITH_PERCENTAGE,
      label: intl.get("field.brokageFee"),
      getPercentageValueSource: () => values.faceValue || 0,
      currency: values.tradingCurrency || defaultCurrency,
      symbolPrefix: getSymbolFromCode(
        values.tradingCurrency || defaultCurrency
      ),
      defaultCurrency: values.tradingCurrency || defaultCurrency,
      allowNegative: false
    },
    {
      name: "financedThruLoan",
      label: intl.get("field.investmentUnderLoan"),
      checkboxLabel: intl.get("YesNoEnum_YES"),
      type: FieldType.CHECKBOX
    }
  ]
  return (
    <div className="portal-form-popup">
      <div className={isShowSidePane && "portal-form-half"}>
        <ClassificationSelection {...{ client, partner, isJoint }} />
        <div className="subtitle2">{intl.get("bonds.subtitle")}</div>
        <RenderSimpleFields fields={fields} />
        <Field
          component={fileFieldRender}
          customClassName="mt-1 d-block"
          labelIdle="Drag, Drop or Browse"
          label="Upload Documents"
          onupdatefiles={(fileItems) => {
            setFormAttribute("proofDocument", fileItems[0])
          }}
          fileInformation={(values && values.proofDocument) || null}
        />
      </div>

      <SidePane
        {...{
          isShowSidePane,
          optionsData,
          showMortgageAssociatedCharges: taxRecidencyIsAustralia,
          defaultCurrency,
          values
        }}
      />
    </div>
  )
}

function BondForm({
  handleSave,
  model = {},
  optionsData,
  readOnlyAccess,
  clientInformation,
  defaultCurrency,
  subFormModel //20201007
}) {
  if (readOnlyAccess) {
    return <ModalNoAccess />
  }

  model = subFormModel ? subFormModel : model

  const {
    client: { taxResidency }
  } = clientInformation

  const dC = defaultCurrency || getCurrency(taxResidency)

  if (!model.marginLoan) {
    model.marginLoan = null
  }
  if (!model.tradingCurrency) {
    model.tradingCurrency = dC
  }
  if (!model.id) {
    model.rollover = true
  }
  // if(!model.purchasedDate){
  //     model.purchasedDate = new Date();
  // }

  if (model.couponRate == 0) {
    model.couponRate = "0"
  }

  return (
    <Form
      onSubmit={handleSave}
      initialValues={model}
      mutators={{
        onFaceValueChange: (args, state, utils) => {
          const [faceValue] = args
          const {
            values: { brokageFee = {}, tradingCurrency }
          } = state.formState
          if (brokageFee && brokageFee.percentage) {
            utils.changeValue(state, "brokageFee", () => ({
              ...brokageFee,
              money: {
                currency: tradingCurrency || dC,
                value: ((faceValue || 0) * brokageFee.percentage) / 100
              }
            }))
          }
        },
        onTradingCurrencyChange: (args, state, utils) => {
          const [tradingCurrency] = args
          const {
            values: { brokageFee = {} }
          } = state.formState
          if (tradingCurrency !== (brokageFee.money || {}).currency) {
            utils.changeValue(state, "brokageFee", () => ({
              ...brokageFee,
              money: {
                ...brokageFee.money,
                tradingCurrency
              }
            }))
          }
        },
        setFormAttribute: ([fieldName, fieldVal], state, { changeValue }) => {
          changeValue(state, fieldName, () => fieldVal)
        }
      }}
      render={({
        handleSubmit,
        reset,
        form: {
          mutators: {
            onFaceValueChange,
            onTradingCurrencyChange,
            setFormAttribute
          }
        }
      }) => (
        <form id="bondForm" onSubmit={handleSubmit}>
          <FormSpy subscription={{ values: true }}>
            {({ values }) => (
              <MainForm
                {...{
                  values,
                  clientInformation,
                  optionsData,
                  onFaceValueChange,
                  onTradingCurrencyChange,
                  defaultCurrency: dC,
                  setFormAttribute
                }}
              />
            )}
          </FormSpy>
          <FormButtons reset={reset} readOnlyAccess={readOnlyAccess} />
        </form>
      )}
    />
  )
}
export default BondForm
