import React from "react"
import { Form, Field } from "react-final-form"
import arrayMutators from "final-form-arrays"
import { FieldArray } from "react-final-form-arrays"
import intl from "react-intl-universal"
import RenderSimpleFields, {
  FieldType
} from "../../../components/Form/RenderSimpleFields"
import {
  renderTextBoxCustom,
  renderSelectEnumFieldCustom,
  renderMoneyCustom,
  getSymbolFromCode,
  onlyAlphabetic,
  email,
  fileFieldRender,
  renderCurrencyCustom
} from "../../../helpers"
import { FormButtons } from "../../../components"
import ClassificationSelection from "../../../components/_hook/ClassificationSelection"

const LifeInsuranceForm = ({
  onSubmit,
  model,
  optionsData,
  user,
  currency
}) => (
  <Form
    onSubmit={onSubmit}
    mutators={{
      ...arrayMutators,
      setFormAttribute: ([fieldName, fieldVal], state, { changeValue }) => {
        changeValue(state, fieldName, () => fieldVal)
      }
    }}
    initialValues={model}
    render={({
      handleSubmit,
      reset,
      mutators: { push, remove, setFormAttribute },
      values
    }) => (
      <form id="collectiblesForm" onSubmit={handleSubmit}>
        <div className="portal-form-popup">
          {user.isJoint && (
            <ClassificationSelection
              client={user.client}
              partner={user.partner}
              isJoint={user.isJoint}
              hideJoint={!user.isJoint}
            />
          )}
          <div className="subtitle2">
            {intl.get("insurance.title.personal.lifeInsuranceDetails")}
          </div>
          <RenderSimpleFields
            fields={[
              {
                name: "policyNumber",
                label: intl.get("field.uniqueCode"),
                type: FieldType.TEXT,
                required: true
              },
              {
                name: "insuranceOrganization",
                label: intl.get("field.insuranceOrganization"),
                type: FieldType.TEXT,
                required: false
              },
              {
                name: "policyProductName",
                label: intl.get("field.policyProductName"),
                type: FieldType.TEXT,
                required: false
              },
              {
                name: "managedBy",
                label: intl.get("field.managedBy"),
                type: FieldType.ENUM_SELECTION,
                enumData: optionsData.managedByOptions,
                required: false
              },
              {
                name: "currency",
                label: intl.get("goal.property.currency"),
                type: FieldType.ENUM_SELECTION,
                enumData: optionsData.currencyOptions,
                required: true
              },
              {
                name: "policyType",
                label: intl.get("field.policyType"),
                type: FieldType.ENUM_SELECTION,
                enumData: optionsData.insurancePolicyTypeOptions,
                required: false
              },

              {
                name: "assuredPersonName",
                label: intl.get("field.assuredPersonName"),
                type: FieldType.TEXT
              },
              {
                name: "policyOwnerName",
                label: intl.get("field.policyOwnerName"),
                type: FieldType.TEXT
              },
              {
                name: "policyStartDate",
                label: intl.get("field.policyStartDate"),
                type: FieldType.DATE,
                required: true
              },

              {
                name: "policyTerm",
                label: intl.get("field.policyTerm"),
                type: FieldType.NUMBER,
                numberFormat: "##",
                required: true
              },

              {
                name: "basicAssured.value",
                label: intl.get("field.basicAssured"),
                type: FieldType.MONEY,
                required: true,
                symbolPrefix: getSymbolFromCode(values.currency || currency),
                required: true
              },
              {
                name: "policyPremiumFrequency",
                label: intl.get("field.policyPremiumFrequency"),
                type: FieldType.ENUM_SELECTION,
                enumData: optionsData.frequencyOptions,
                required: true
              },
              {
                name: "policyPremium.value",
                label: intl.get("field.policyPremium"),
                type: FieldType.MONEY,
                symbolPrefix: getSymbolFromCode(values.currency || currency),

                required: true
              },

              {
                name: "coverageType",
                label: intl.get("field.coverageType"),
                type: FieldType.ENUM_SELECTION,
                enumData: optionsData.coverageTypeOptions
              },
              {
                name: "coverageLimit.value",
                label: intl.get("field.coverageLimit"),
                type: FieldType.MONEY,
                required: false,
                symbolPrefix: getSymbolFromCode(values.currency || currency)
              }
            ]}
          />
          <div className="portal-form-row">
            <label>{intl.get("field.exclusions")}</label>
            <div className="row-holder">
              <button
                type="button"
                onClick={() => push("exclusions", undefined)}
                className="btn btn-plus"
              />
            </div>
          </div>
          <FieldArray name="exclusions">
            {({ fields }) =>
              fields.map((name, index) => (
                <div className="portal-form-row " key={index}>
                  <label />
                  <Field
                    name={name}
                    component={renderTextBoxCustom}
                    containerCssClass="deletable-input"
                  />
                  <button
                    type="button"
                    onClick={() => remove("exclusions", index)}
                    className="btn-remove networth-other-remove"
                  />
                </div>
              ))
            }
          </FieldArray>
          <RenderSimpleFields
            fields={[
              {
                name: "loadingPercentage",
                label: intl.get("field.loadingPercentage"),
                type: FieldType.PERCENTAGE
              }
            ]}
          />

          <div className="subtitle2">
            {intl.get("insurance.title.personal.lifeInsurance.riders")}
          </div>
          <RenderSimpleFields
            fields={[
              {
                name: "deathCoverageBenefit.value",
                label: intl.get("field.deathCoverageBenefit"),
                type: FieldType.MONEY,
                symbolPrefix: getSymbolFromCode(values.currency || currency),
                required: true
              },
              {
                name: "totalDisabilityCoverageBenefit.value",
                label: intl.get("field.totalDisabilityCoverageBenefit"),
                type: FieldType.MONEY,
                symbolPrefix: getSymbolFromCode(values.currency || currency)
              },
              {
                name: "criticalIllnessCoverageBenefit.value",
                label: intl.get("field.criticalIllnessCoverageBenefit"),
                type: FieldType.MONEY,
                symbolPrefix: getSymbolFromCode(values.currency || currency)
              }
            ]}
          />
          <div className="portal-row-rider">
            <div className="portal-form-row portal-form-row2col-close">
              <div className="subtitle2">
                Other Riders &nbsp;&nbsp;
                <button
                  type="button"
                  onClick={() => push("riderBenefits", undefined)}
                  className="btn btn-plus"
                />
              </div>
            </div>
          </div>
          <FieldArray name="riderBenefits">
            {({ fields }) => (
              <>
                {fields.length > 0 && (
                  <div className="portal-form-row ">
                    <div className="row-holder">Rider Name</div>
                    <div className="row-holder">Rider Benefits $</div>
                  </div>
                )}
                {fields.map((name, index) => (
                  <div className="portal-form-row " key={index}>
                    <div className="row-holder">
                      <Field
                        name={`${name}.riderType`}
                        component={renderSelectEnumFieldCustom}
                        enumData={optionsData.insuranceRiderTypeOptions}
                        isForGrid
                        containerCssClass="deletable-input"
                      />
                    </div>
                    <div className="row-holder">
                      <Field
                        name={`${name}.benefitAmount.value`}
                        component={renderCurrencyCustom}
                        containerCssClass="deletable-input"
                        isForGrid
                        isFullWidth
                        defaultCurrency={currency}
                        symbolPrefix={getSymbolFromCode(
                          values.currency || currency
                        )}
                      />
                    </div>
                    <button
                      type="button"
                      onClick={() => remove("riderBenefits", index)}
                      className="btn-remove"
                    />
                  </div>
                ))}
              </>
            )}
          </FieldArray>
          <div className="portal-row-rider">
            <div className="portal-form-row portal-form-row2col-close">
              <div className="subtitle2">
                Beneficiary Details &nbsp;&nbsp;
                <button
                  type="button"
                  onClick={() => push("beneficiaries", undefined)}
                  className="btn btn-plus"
                />
              </div>
            </div>
          </div>
          <FieldArray name="beneficiaries">
            {({ fields }) => (
              <>
                {fields.length > 0 && (
                  <div className="portal-form-row beneficiaries-wrapper">
                    <div className="row-holder">Name</div>
                    <div className="row-holder">Relationship</div>
                    <div className="row-holder">Email</div>
                    <div className="row-holder">Phone</div>
                  </div>
                )}
                {fields.map((name, index) => (
                  <div
                    className="portal-form-row beneficiaries-wrapper"
                    key={index}
                  >
                    <div className="row-holder">
                      <Field
                        name={`${name}.name`}
                        component={renderTextBoxCustom}
                        isForGrid
                        validate={onlyAlphabetic}
                      />
                    </div>
                    <div className="row-holder">
                      <Field
                        name={`${name}.relationship`}
                        component={renderSelectEnumFieldCustom}
                        enumData={optionsData.relationshipTypeOptions}
                        isForGrid
                      />
                    </div>
                    <div className="row-holder">
                      <Field
                        name={`${name}.emailAddress`}
                        component={renderTextBoxCustom}
                        validate={email}
                        isForGrid
                      />
                    </div>
                    <div className="row-holder">
                      <Field
                        name={`${name}.contactNumber`}
                        component={renderTextBoxCustom}
                        isForGrid
                      />
                    </div>

                    <button
                      type="button"
                      onClick={() => remove("beneficiaries", index)}
                      className="btn-remove"
                    />
                  </div>
                ))}
              </>
            )}
          </FieldArray>
          <Field
            component={fileFieldRender}
            customClassName="mt-1 d-block"
            labelIdle="Drag, Drop or Browse"
            label="Upload Documents"
            onupdatefiles={(fileItems) => {
              setFormAttribute("proofDocument", fileItems[0])
            }}
            fileInformation={(model && model.proofDocument) || null}
          />
          <FormButtons reset={reset} />
        </div>
      </form>
    )}
  />
)

export default LifeInsuranceForm
