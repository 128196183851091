import React from "react"
import intl from "react-intl-universal"
import RenderSimpleFields, {
  FieldType
} from "../../components/Form/RenderSimpleFields"
import { createListingData } from "../../services/FundAllocationListingData"

function FeeSection({ values, frequencyOptions }) {
  const listingData = createListingData(values, values.reportingCurrency)
  const total = listingData.reduce((sum, current) => sum + current.value, 0)
  const feeFields = [
    {
      name: "adviserManagementFeeFrequency",
      label: intl.get("field.adviserManagementFeeFrequency"),
      type: FieldType.ENUM_SELECTION,
      enumData: frequencyOptions
    },
    {
      name: "adviserManagementFee",
      label: intl.get("field.adviserManagementFee"),
      type: FieldType.MONEY_WITH_PERCENTAGE,
      getPercentageValueSource: () => total,
      currency: values.reportingCurrency,
      allowNegative: false
    },
    {
      name: "administrationFeeFrequency",
      label: intl.get("field.administrationFeeFrequency"),
      type: FieldType.ENUM_SELECTION,
      enumData: frequencyOptions
    },
    {
      name: "administrationFee",
      label: intl.get("field.administrationFee"),
      type: FieldType.MONEY_WITH_PERCENTAGE,
      getPercentageValueSource: () => total,
      currency: values.reportingCurrency,
      allowNegative: false
    },
    {
      name: "platformFeeFrequency",
      label: intl.get("field.platformFeeFrequency"),
      type: FieldType.ENUM_SELECTION,
      enumData: frequencyOptions
    },
    {
      name: "platformFee",
      label: intl.get("field.platformFee"),
      type: FieldType.MONEY_WITH_PERCENTAGE,
      getPercentageValueSource: () => total,
      currency: values.reportingCurrency,
      allowNegative: false
    }
  ]
  return (
    <React.Fragment>
      <RenderSimpleFields fields={feeFields} />
      <div style={{ marginBottom: "40px" }}></div>
    </React.Fragment>
  )
}

export default FeeSection
