/*
    Author : Erik Farhan Malik (erikfarhanmalik@gmail.com)
             Anova Fawzi (anovafawzi@gmail.com) - updated 21 Feb 2019
    Description : Government Education Assistance main page (modal)
*/

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import intl from 'react-intl-universal';
import {
    netWorthActions,
    modalWindowActions,
    netWorthFormActions,
} from '../../actions';
import {
    GEALAustralia,
    GEALNewZealand,
    GEALHongkong,
    GEALSingapore,
    GEALMalaysia,
} from '..';
import NetWorthListingGroup, {
    NetWorthListingGroupPropsComposer,
} from './NetWorthListingGroup';
import { NetWorthPath } from './NetWorthForm';
import { modalWindow } from '../../constants/ModalWindowLists';

class GovernmentEducationAssistanceLoans extends NetWorthListingGroup {
    constructor(props) {
        const { getGEALListing } = props;
        const listingDefinition = [
            {
                title: intl.get('geal.title.australia'),
                path: NetWorthPath.australianGEAL,
                modalName: modalWindow.GEAL_AU_MODAL,
                descriptionI18nPrefix: 'EducationAssistanceLoanTypeAUEnum_',
            },
            {
                title: intl.get('geal.title.singapore'),
                path: NetWorthPath.singaporeGEAL,
                modalName: modalWindow.GEAL_SG_MODAL,
                descriptionI18nPrefix: 'EducationAssistanceLoanTypeSGEnum_',
            },
            {
                title: intl.get('geal.title.hongkong'),
                path: NetWorthPath.hongkongGEAL,
                modalName: modalWindow.GEAL_HK_MODAL,
                descriptionI18nPrefix: 'EducationAssistanceLoanTypeHKEnum_',
            },
            {
                title: intl.get('geal.title.newZealand'),
                path: NetWorthPath.newZealandGEAL,
                modalName: modalWindow.GEAL_NZ_MODAL,
                descriptionI18nPrefix: 'EducationAssistanceLoanTypeNZEnum_',
            },
            {
                title: intl.get('geal.title.malaysia'),
                path: NetWorthPath.malaysiaGEAL,
                modalName: modalWindow.GEAL_MY_MODAL,
                descriptionI18nPrefix: 'EducationAssistanceLoanTypeMYEnum_',
            },
        ];
        super(props, 'geal', getGEALListing, listingDefinition);
    }

    render() {
        const { optionsData, currency } = this.props;
        const { contentWrapper } = this;
        return contentWrapper(
            <React.Fragment>
                <GEALAustralia optionsData={optionsData} currency={currency} />
                <GEALSingapore optionsData={optionsData} currency={currency} />
                <GEALHongkong optionsData={optionsData} currency={currency} />
                <GEALNewZealand optionsData={optionsData} currency={currency} />
                <GEALMalaysia optionsData={optionsData} currency={currency} />
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { gealListing } = state.netWorth;
    return NetWorthListingGroupPropsComposer(state, gealListing);
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        Object.assign(
            {},
            netWorthActions,
            modalWindowActions,
            netWorthFormActions
        ),
        dispatch
    );
}

GovernmentEducationAssistanceLoans = connect(
    mapStateToProps,
    mapDispatchToProps
)(GovernmentEducationAssistanceLoans);

export default GovernmentEducationAssistanceLoans;
