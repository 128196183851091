import { dashboardAction } from "../constants/ActionConstants"
import { DashboardApi } from "../api"
import { generateBreadCrumbLabel, menuItems } from "../helpers"
import { setActiveClassLayout } from "./AppSettingsActions"

export const dashboardActions = {
  getDashboardDataByType,
  getDashboardDataById,
  getClientDashboardDataById,
  setDashboardBreadCrumbLabel,
  setDashboardEntity,
  setDashboardEntityAndData,
  setDashboardDemoClients,
  getFilteredAndPagedData,
  setDashboardBreadCrumbLabelRequest
}

// action types
export function getDashboardDataByTypeRequest(response, entityType) {
  return {
    type: dashboardAction.GET_DATA_BY_TYPE_SUCCESS,
    response,
    entityType
  }
}

export function getDashboardDataByIdRequest(response) {
  return { type: dashboardAction.GET_DATA_BY_ID_SUCCESS, response }
}

export function getClientDashboardDataByIdRequest(response) {
  return { type: dashboardAction.GET_DATA_BY_ID_SUCCESS, response }
}

export function setDashboardBreadCrumbLabelRequest(label) {
  return { type: dashboardAction.SET_BREAD_CRUMB_LABEL, label }
}

export function setDashboardEntityRequest(entities) {
  return { type: dashboardAction.SET_DASHBOARD_ENTITY, entities }
}

export function getFilteredAndPagedDataRequest(response) {
  return { type: dashboardAction.GET_DATA_BY_FILTER_AND_PAGE, response }
}

// action functions
export function getDashboardDataByType(entityType) {
  return async (dispatch) => {
    DashboardApi.getDashboardByType(entityType).then((response) => {
      if (response && response.data) {
        dispatch(getDashboardDataByTypeRequest(response.data, entityType))
        dispatch(
          setDashboardBreadCrumbLabelRequest(
            generateBreadCrumbLabel(response.data.path)
          )
        )
      }
    })
  }
}

export function getDashboardDataById(id) {
  return async (dispatch) =>
    DashboardApi.getDashboardById(id).then((response) => {
      dispatch(getDashboardDataByIdRequest(response.data))

      // set classname of dashboard here
      dispatch(setActiveClassLayout(response.data.type))

      // set dashboard entity data
      const findEntityItem = menuItems.find((o) => o.id === response.data.type)
      dispatch(setDashboardEntity(findEntityItem, true))

      dispatch(
        setDashboardBreadCrumbLabelRequest(
          generateBreadCrumbLabel(response.data.path)
        )
      )
    })
}

export function getClientDashboardDataById(id) {
  return async (dispatch) =>
    DashboardApi.getClientDashboardById(id).then((response) => {
      dispatch(getClientDashboardDataByIdRequest(response.data))
    })
}

export function setDashboardBreadCrumbLabel(label) {
  return async (dispatch) => {
    dispatch(setDashboardBreadCrumbLabelRequest(label))
  }
}

export function setDashboardEntity(currentEntityItem, isDetail = false) {
  return async (dispatch) => {
    // set classname of dashboard here
    if (isDetail) {
      dispatch(setActiveClassLayout(currentEntityItem.id))
    } else {
      dispatch(setActiveClassLayout(currentEntityItem.entityType))
    }

    // send values
    dispatch(
      setDashboardEntityRequest({
        entityToLoad: currentEntityItem.entityType,
        entityToCheck: currentEntityItem.id
      })
    )
  }
}
export function setDashboardDemoClients() {
  return async (dispatch) => {
    // search next entity below current entity

    dispatch(getDashboardDataByType("DEMO_CLIENT"))
  }
}
export function setDashboardEntityAndData(currentPath, mode) {
  return async (dispatch) => {
    // search next entity below current entity

    const findEntityItem = menuItems.find((o) => o.path === currentPath)

    if (findEntityItem) {
      dispatch(setDashboardEntity(findEntityItem))
      if (mode === "DEMO_CLIENT") {
        dispatch(getDashboardDataByType("DEMO_CLIENT"))
      } else {
        dispatch(getDashboardDataByType(findEntityItem.entityType))
      }
    }
  }
}

export function getFilteredAndPagedData(baseUrl, queryString) {
  return async (dispatch) => {
    DashboardApi.getFilteredPagedData(baseUrl, queryString).then((response) => {
      dispatch(getFilteredAndPagedDataRequest(response.data))
    })
  }
}

// private functions
// export function generateBreadCrumbLabel(labelArray) {
//   let result = ""
//   if (labelArray) {
//     labelArray.forEach((element) => {
//       result += `${element} - `
//     })
//   }
//   return result.substring(0, result.length - 3)
// }
