/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Description : Cashflow Listing main component for group
*/

import React from "react"
import { Field } from "react-final-form"
import { FieldArray } from "react-final-form-arrays"
import {
  renderCurrencyCashflow,
  renderPercentageCustom,
  renderTextOnlyCustom
} from "../../helpers"
import api from "../../lib/api"

const CashFlowListingGroup = ({
  varPrefix,
  isJointHide,
  isRoundColorRed = false,
  isExternalModal = false,
  clientVarName = "client",
  partnerVarName = "partner",
  jointVarName = "joint",
  onEditClick,
  dataArray,
  currency = "MYR"
}) => {
  const handleEditClick = (url, client, index) => {
    // get the model
    api.get(url).then((response) => {
      const { data } = response
      onEditClick({
        ...data,
        url,
        client,
        index //20201203 MABT-366 : pass [client]
      })
    })
  }

  return (
    <React.Fragment>
      <FieldArray name={varPrefix}>
        {({ fields }) =>
          fields.map((name, index) => (
            <div
              className="portal-form-row portal-form-row-goal2col"
              key={name}
            >
              <label>
                <div className="goals-title">
                  <Field
                    name={`${name}.description`}
                    component={renderTextOnlyCustom}
                  />
                </div>
                <span
                  onClick={() => {
                    console.log("here is this", dataArray[index]._self)
                    //20201203 MABT-366
                    handleEditClick(
                      dataArray[index]._self,
                      dataArray[index].client,
                      index
                    )
                  }}
                  className="btn btn-edit"
                />
              </label>
              <div className="row-holder">
                <Field
                  name={`${name}.${clientVarName}`}
                  component={renderCurrencyCashflow}
                  isLabel={false}
                  isForGrid
                  required={false}
                  isRounded
                  placeholderLabel={""}
                  disabled={isExternalModal}
                  currency={currency}
                />
              </div>
              {isJointHide ? (
                ""
              ) : (
                <div className="row-holder">
                  <Field
                    name={`${name}.${partnerVarName}`}
                    component={renderCurrencyCashflow}
                    isLabel={false}
                    isForGrid
                    required={false}
                    isRounded
                    placeholderLabel={""}
                    disabled={isExternalModal}
                    currency={currency}
                  />
                </div>
              )}
              {isJointHide ? (
                ""
              ) : (
                <div className="row-holder">
                  <Field
                    name={`${name}.${jointVarName}`}
                    component={renderCurrencyCashflow}
                    isLabel={false}
                    isForGrid
                    required={false}
                    isRounded
                    placeholderLabel={""}
                    disabled={isExternalModal}
                    currency={currency}
                  />
                </div>
              )}
              <div className="row-holder-last">
                <Field
                  name={`${name}.deductible`}
                  component={renderPercentageCustom}
                  isLabel={false}
                  isForGrid
                  required={false}
                  isRounded
                  isRoundColorRed={isRoundColorRed}
                />
              </div>
            </div>
          ))
        }
      </FieldArray>
    </React.Fragment>
  )
}

export default CashFlowListingGroup
