import { isNullOrUndefined } from 'util';
import { getDirectVariableValue } from './GetDirectValue';
import { countFromArray } from './CountFromArray';
import { totalSum } from './TotalSum';

export const personalBusinessCounter = (values, currency) => {
    if (!isNullOrUndefined(values)) {
        const {
            advertising,
            legal,
            officeSupplies,
            printing,
            shipping,
            workMobilePhone,
            others,
        } = values;

        const model = {
            advertising: getDirectVariableValue(advertising, currency),
            legal: getDirectVariableValue(legal, currency),
            officeSupplies: getDirectVariableValue(officeSupplies, currency),
            printing: getDirectVariableValue(printing, currency),
            shipping: getDirectVariableValue(shipping, currency),
            workMobilePhone: getDirectVariableValue(workMobilePhone, currency),
            others: countFromArray(others, currency),
        };

        return {
            clientTotal: totalSum(model, 'clientTotal'),
            partnerTotal: totalSum(model, 'partnerTotal'),
            jointTotal: totalSum(model, 'jointTotal'),
        };
    }
};
