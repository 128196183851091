import { userAction } from '../constants/ActionConstants';

const user = JSON.parse(localStorage.getItem('user'));

const initialState = {
    user,
    test: 0,
    loggingIn: localStorage.getItem('loggingIn'),
};

export function authentication(state = initialState, action) {

    switch (action.type) {
        case userAction.LOGIN_REQUEST:
            return {
                ...state,
                loggingIn: false,
                user: action.user,
            };
        case userAction.LOGIN_SUCCESS:
            return {
                ...state,
                loggingIn: true,
                user: action.user,
            };
        case userAction.USER_ENTITIES:
            return {
                ...state,
                entities: action.entities,
            };
        case userAction.User:
            return {
                ...state,
                loggingIn: true,
                user: action.user,
            };
        case userAction.LOGIN_TWO_FA_STEP:
            return {
                ...state,
                twoStep: true,
                user: action.user,
            };
        // case userAction.LOGIN_FAILURE:
        //     return {};
        case userAction.LOGOUT:
            return {
                loggingIn: false,
            };
        default:
            return state;
    }
}
