import React, { PureComponent } from "react"
import moment from "moment"
import "./style.css"
export default class MalabarDate extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      value: props.value || moment(new Date()).format("YYYY-MM-DD")
    }
  }

  onValueChanged(newValue) {
    this.setState(
      {
        value: newValue
      },
      () => {
        // notify final-form
        this.props.onChange(newValue)
      }
    )
  }
  componentDidMount() {
    setTimeout(() => {
      if (this.props.value) {
        this.onValueChanged(this.props.value)
      } else {
        this.onValueChanged(moment(new Date()).format("YYYY-MM-DD"))
      }
    }, 1000)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.props.onChange(this.props.value)
    }
  }

  getAge(birthDate, showAgeFromCurrentAge) {
    let age = 0
    if (birthDate && this.state && this.state.value) {
      let momentBirthDate = moment(birthDate, "YYYY-MM-DD")
      let momentCurrValue = moment(this.state.value, "YYYY-MM-DD")

      if (showAgeFromCurrentAge) {
        age = momentBirthDate.diff(momentCurrValue, "years")
      } else {
        age = momentCurrValue.diff(momentBirthDate, "years")
      }
    }

    return (
      <span className="age" style={{ textAlign: "center" }}>
        &nbsp;{age}
      </span>
    )
  }

  render() {
    const {
      touched,
      error,
      min,
      max,
      isLabel,
      label,
      disabled,
      value,
      showAge,
      birthDate,
      showAgeFromCurrentAge
    } = this.props

    function isShowError() {
      return touched && error
    }

    return (
      <React.Fragment>
        <div className={"d-flex flex-column w-100"}>
          <div className={`${showAgeFromCurrentAge ? "date-wrapper-dob" : ""}`}>
            <input
              type="date"
              className="text-input"
              value={this.state.value}
              min={
                min ? min : moment().subtract(100, "years").format("YYYY-MM-DD")
              }
              max={max ? max : moment().add(100, "years").format("YYYY-MM-DD")}
              disabled={disabled}
              onChange={(e) => this.onValueChanged(e.target.value)}
              onKeyDown={(e) => e.preventDefault()}
            />
            {showAgeFromCurrentAge && <span className="mr-1">Age</span>}
            {showAgeFromCurrentAge &&
              this.getAge(new Date(), showAgeFromCurrentAge)}
          </div>

          {isShowError() && <div className="alert">{error}</div>}
        </div>
        {showAge && birthDate && this.getAge(birthDate)}
      </React.Fragment>
    )
  }
}
