import React from "react"

const CollapseDropDown = ({ show, children, className }) => {
  return (
    <div className={`${show ? "show-dropdown" : "hide-dropdown"} ${className}`}>
      {children}
    </div>
  )
}

export default CollapseDropDown
