import React from 'react';

const ArrowMenu = ({
    onNextClick,
    onPrevClick,
    nextDescription,
    prevDescription,
}) => (
    <div className="page-arrow-holder">
        <button
            className="arrow arrow-holder-L"
            onClick={onPrevClick}
            title={prevDescription}
        >
            <div className="arrow-left" />
        </button>
        <button
            className="arrow arrow-holder-R"
            onClick={onNextClick}
            title={nextDescription}
        >
            <div className="arrow-right" />
        </button>
    </div>
);

export default ArrowMenu;
