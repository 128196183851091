import api from "../lib/api";

// change base route for different entity/domain here
const BASE_ROUTE = "/client/";

class ClientApi {
    // get current entity
    static get(id) {
        return api.get(`${BASE_ROUTE}${id}`);
    }

    static getInsurances(id, classification) {
        return api.get(`${BASE_ROUTE}${id}/insurances/${classification}`);
    }
    static getCashAccounts(id, classification) {
        return api.get(`${BASE_ROUTE}${id}/combo-cash/${classification}`);
    }

    static getAssets(id, classification) {
        return api.get(`${BASE_ROUTE}${id}/assets/${classification}`);
    }

    static separate(model) {
        return api.post(`${BASE_ROUTE}separate`, model);
    }

    static termsAndConditions(id, model) {
        return api.put(`${BASE_ROUTE}${id}/terms-conditions`, model);
    }

    static clientStatus(data) {
        return api.post(`${BASE_ROUTE}change-status`, data);
    }

    static clientDelete(clientID) {
        return api.delete(`${BASE_ROUTE}${clientID}`);
    }
}

export default ClientApi;
