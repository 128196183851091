import React from 'react';

import {
    useBurgerActions,
    useLayoutActions,
    useModalActions,
} from './actionReducer';

export const AppContext = React.createContext();

export const AppContextProvider = ({ children }) => {
    const [BurgerActions] = useBurgerActions();
    const [LayoutActions] = useLayoutActions();
    const [ModalActions] = useModalActions();

    return (
        <AppContext.Provider
            value={{
                burger: { ...BurgerActions },
                layout: { ...LayoutActions },
                modal: { ...ModalActions },
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export const AppContextConsumer = AppContext.Consumer;
