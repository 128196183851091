import { history } from "."
import UserApi from "../api/UserApi"
import { EntityTypeEnum } from "../constants/Enums"
import Api from "../lib/api"
import AuthServerApi from "../lib/AuthServerApi"

//anton: we need to reduce the localStorage usages in the future!
const keysToRemove = [
  "user",
  "loggingIn",
  "loginUserEntity",
  "loginEntityType",
  "activeUserRole",
  "activeDashboardRoute",
  "feedbacks",
  "feedbacksType",
  "feedbacksValue",
  "activeEntityId",
  "activeClientId",
  "theCurrency",
  "currencyRates",
  "clientName",
  "clientCurrentStanding",
  "page_available",
  "selectedGroupMenu",
  "selectedEntityMenu",
  "activeClientAccessLevel",
  "notificationMode",
  "reloadReason",
  "activities"
]

function clearLocalStorage(arrayOfKeys) {
  arrayOfKeys.forEach((keyItem) => {
    localStorage.removeItem(keyItem)
  })
}

export function reloadWindow(reason) {
  localStorage.setItem("reloadReason", reason)
  window.location.reload()
}

export function clearAllLocalStorage() {
  keysToRemove.forEach((keyItem) => {
    localStorage.removeItem(keyItem)
  })
}
export function refreshToken() {
  let strUser = localStorage.getItem("user")
  if (strUser) {
    let user = JSON.parse(strUser)
    let model = {
      userId: user.aid,
      entityId: user.selectedEntity.id,
      refreshToken: user.refresh
    }
    const url =
      process.env.REACT_APP_BASE_LOGIN_URL +
      process.env.REACT_APP_LOGIN +
      "/auth/refresh-token"
    AuthServerApi.post(url, model)
      .then((res) => {
        if (res.data) {
          let newJwtToken = res.data.newToken
          let newRefreshToken = res.data.refreshToken
          let storedUser = {
            id: user.id,
            aid: user.aid,
            loginId: user.loginId,
            firstTimeLogin: user.firstTimeLogin,
            selectedEntity: user.selectedEntity,
            timeout: user.timeout,
            decimalPoints: user.decimalPoints,
            token: newJwtToken,
            refresh: newRefreshToken
          }
          localStorage.setItem("user", JSON.stringify(storedUser))
          Api.token(newJwtToken)
        }
      })
      .catch((err) => {
        logout()
      })
  }
}

export function logout() {
  const LOGIN_URL =
    process.env.REACT_APP_BASE_LOGIN_URL + process.env.REACT_APP_LOGIN
  UserApi.sendLogout("NORMAL_LOGOUT").then(() => {
    clearLocalStorage(keysToRemove)
    localStorage.setItem("base-route", LOGIN_URL)

    history.go(0)
    history.push("/")
  })
}
export function isLoginAsGlobal() {
  let userEntityType = localStorage.getItem("loginEntityType")
  let result =
    userEntityType == EntityTypeEnum.EntityTypeEnum_BUILT_IN_MALABAR_AI_GLOBAL
  return result
}
export function isLoginAsDealership() {
  let userEntityType = localStorage.getItem("loginEntityType")
  let result =
    userEntityType == EntityTypeEnum.EntityTypeEnum_BUILT_IN_DEALERSHIP
  return result
}

export function timeout() {
  const LOGIN_URL =
    process.env.REACT_APP_BASE_LOGIN_URL + process.env.REACT_APP_LOGIN
  UserApi.sendLogout("TIMEOUT").then(() => {
    clearLocalStorage(keysToRemove)
    localStorage.setItem("base-route", LOGIN_URL)

    history.go(0)
    history.push("/")
  })
}
