import { unitTrustHoldingInformationAction } from '../../../constants/ActionConstants';

export function unitTrustHoldingInformation(state = {}, action) {
    switch (action.type) {
        case unitTrustHoldingInformationAction.GET_unitTrustHoldingInformation_REQUEST:
            return {
                loading: true
            };
        case unitTrustHoldingInformationAction.GET_unitTrustHoldingInformation_SUCCESS:
            return {
                items: action.data
            };
        case unitTrustHoldingInformationAction.GET_unitTrustHoldingInformation_FAILURE:
            return {
                error: action.error
            };
        case unitTrustHoldingInformationAction.ADD_unitTrustHoldingInformation_REQUEST:
            return {
                loading: true
            };
        case unitTrustHoldingInformationAction.ADD_unitTrustHoldingInformation_SUCCESS:
            return {
                items: action.response
            };
        case unitTrustHoldingInformationAction.ADD_unitTrustHoldingInformation_FAILURE:
            return {
                error: action.error
            };
        case unitTrustHoldingInformationAction.EDIT_unitTrustHoldingInformation_REQUEST:
            return {
                loading: true
            };
        case unitTrustHoldingInformationAction.EDIT_unitTrustHoldingInformation_SUCCESS:
            return {
                items: action.response
            };
        case unitTrustHoldingInformationAction.EDIT_unitTrustHoldingInformation_FAILURE:
            return {
                error: action.error
            };
        case unitTrustHoldingInformationAction.DELETE_unitTrustHoldingInformation_REQUEST:
            return {
                loading: true
            };
        case unitTrustHoldingInformationAction.DELETE_unitTrustHoldingInformation_SUCCESS:
            return {
                items: action.response
            };
        case unitTrustHoldingInformationAction.DELETE_unitTrustHoldingInformation_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}