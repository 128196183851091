/*
    Author : Erik Farhan Malik (erikfarhanmalik@gmail.com)
    Description : Mortgage details form
*/

import React, { useState, useContext } from "react"
import intl from "react-intl-universal"

import { Field } from "react-final-form"
import NumberFormat from "react-number-format"
import BorrowingsCashAccountListing from "../misc/BorrowingsCashAccountListing"
import RenderSimpleFields, {
  FieldType
} from "../../components/Form/RenderSimpleFields"

import {
  renderCurrencyCustom,
  getSymbolFromCode,
  renderDateFieldCustom,
  required,
  renderTextBoxCustom,
  renderPercentageCustom
} from "../../helpers"
import { DECIMAL_SCALE } from "../../constants/NumberFormatConstants"
import { LoanFrequencyEnum } from "../../constants/Enums"
import Get from "../../helpers/NullHelper"

import { AppContext } from "../../context/AppContext"
import LoanSimulationDialog from "./LoanSimulationDialog"
import { FieldArray } from "react-final-form-arrays"
import LoanApi from "../../api/LoanApi"

export const MortgageDetailsMutators = {
  removeCashAccountFromMortgage,
  updateBorrowingsRepaymentAmountAndInterest
}

function removeCashAccountFromMortgage(args, state, utils) {
  utils.changeValue(state, "mortgage.linkedCashAccount", () => undefined)
}

function updateBorrowingsRepaymentAmountAndInterest(args, state, utils) {
  utils.changeValue(
    state,
    "mortgage.borrowingsRepaymentAmountAndInterest",
    (val) => ({
      currency: "MYR",
      value: val
    })
  )
}

const MortgageDetails = ({
  optionsData,
  readOnlyAccess,
  removeCashAccount,
  isAustralia,
  model,
  clientInformation,
  mutators,
  readOnly
}) => {
  const {
    modal: { setModal, clearModal }
  } = useContext(AppContext)

  const {
    loanInterestTypeOptions,
    loanFrequencyOptions,
    currencyOptions,
    cashAccountTypeOptions
  } = optionsData

  const [currency, setCurrency] = useState({
    name: Get(model, "mortgage.borrowingsCurrency") || "AUD",
    symbol: getSymbolFromCode(
      Get(model, "mortgage.borrowingsCurrency") || "AUD"
    )
  })

  const loanCurrencyChange = (value) => {
    setCurrency({ name: value, symbol: getSymbolFromCode(value) })
  }

  const totalMortgageAssociatedCharges = (values) => {
    let x = 1
    if (
      values.mortgage.borrowingsManagementFeesFrequency ===
      LoanFrequencyEnum.Frequency_FORTNIGHTLY
    ) {
      x = 26 / 12
    }
    let y = 1
    if (
      values.mortgage.additionalRepaymentFrequency ===
      LoanFrequencyEnum.Frequency_FORTNIGHTLY
    ) {
      y = 26 / 12
    }
    return (
      y * +(values.mortgage.additionalRepaymentHandlingFees || 0) +
      x * +(values.mortgage.borrowingsManagementFees || 0)
    )
  }

  function showLoanSimulationPreview(){
    let inputModel = model.mortgage;
    if(inputModel){
      //console.log('inputModel: ', inputModel);
      let ccy = inputModel.borrowingsCurrency?inputModel.borrowingsCurrency:localStorage.getItem("theCurrency");
      let inArrears = inputModel.inArrear?true:false;
      let reqModel = {
         principal: inputModel.borrowingsPrinciple,
         interestRate: inputModel.borrowingsAnnualInterestRate,
         interestRates: inputModel.interestRates,
         startDate: inputModel.borrowingsStartDate,
         payInterestOnlyPeriods: inputModel.payInterestOnlyPeriods,
         term: inputModel.borrowingsTermInYears,
         termFreq: "YEARLY",
         paymentFreq: inputModel.borrowingsRepaymentFrequency,
         inArrear: inputModel.inArrear,
         balloonPayment: 0,
      }
      LoanApi.postCalculateRepayment(reqModel).then(({data})=>{
        if(data){
          setModal({
            title: `Repayments Simulation Preview`,
            content: (
              <LoanSimulationDialog
                loan = {data}
                ccy={ccy}
                inArrears={inArrears}
              />
            ),
            isSmall: true
          })
        }
     })

    }
   
  }

  return (
    <div className="pop-holder">
      <RenderSimpleFields
        fields={[
          {
            name: "mortgage.borrowingsAccountNumber",
            type: FieldType.TEXT,
            label: intl.get("field.uniqueCode"),
            required: true
          },
          {
            name: "mortgage.financialInstitutionName",
            label: intl.get("field.financialInstitutionName"),
            type: FieldType.TEXT,
            disabled: readOnly,
            required: false
          },

          {
            name: "mortgage.borrowingsCurrency",
            label: intl.get("field.loan.borrowingsCurrency"),
            type: FieldType.ENUM_SELECTION,
            enumData: currencyOptions,
            onChange: loanCurrencyChange,
            disabled: readOnly,
            required: false
          },
          {
            name: "mortgage.borrowingsPrinciple",
            label: intl.get("field.loan.borrowingsPrinciple"),
            type: FieldType.MONEY,
            symbolPrefix: currency.symbol,
            defaultCurrency: currency.name,
            disabled: readOnly,
            required: true,
            allowNegative: false
          }
          // onChange: loanRepaymentAndInterest
        ]}
      />
      <FieldArray name="mortgage.interestRates">
        {({ fields }) =>
          fields.map((name, index) => (
            <div className="portal-form-row" key={index}>
              {index === 0 ? (
                <>
                  <label htmlFor="interestRates">
                    Interest Rates
                    <span>*</span>
                  </label>
                </>
              ) : (
                <label />
              )}
              <div
                style={{ display: "flex", alignItems: "flex-end", gap: "0px" }}
                className="row-holder"
              >
                <Field
                  name={`${name}.desc`}
                  component={renderTextBoxCustom}
                  containerCssClass="row-holder"
                  placeholder="Fixed or Variable"
                  validate={required}
                />
                <Field
                  name={`${name}.rateInPercent`}
                  component={renderPercentageCustom}
                  containerCssClass="row-holder"
                  placeholder="Interest Rate"
                  required={index === 0}
                  validate={required}
                />
                <Field
                  name={`${name}.startDate`}
                  component={renderDateFieldCustom}
                  containerCssClass="row-holder"
                />
                <Field
                  name={`${name}.endDate`}
                  component={renderDateFieldCustom}
                  containerCssClass="row-holder"
                />
              </div>
              {index !== 0 ? (
                <button
                  type="button"
                  onClick={() =>
                    mutators.remove("mortgage.interestRates", index)
                  }
                  className="btn btn-remove"
                />
              ) : (
                <button
                  className="btn btn-plus"
                  type="button"
                  onClick={() =>
                    mutators.push("mortgage.interestRates", undefined)
                  }
                />
              )}
            </div>
          ))
        }
      </FieldArray>

      <RenderSimpleFields
        fields={[
          {
            label: intl.get("field.loan.borrowingsStartDate"),
            name: "mortgage.borrowingsStartDate",
            type: FieldType.DATE,
            disabled: readOnly,
            required: true
          },
          {
            name: "mortgage.borrowingsTermInYears",
            label: intl.get("field.loan.borrowingsTermInYears"),
            type: FieldType.NUMBER,
            disabled: readOnly,
            numberFormat: "##",
            required: true
          },
          {
            name: "mortgage.inArrear",
            label: intl.get("field.inArrear"),
            checkboxLabel: intl.get("YesNoEnum_YES"),
            type: FieldType.CHECKBOX
          },
          {
            name: "mortgage.borrowingsRepaymentFrequency",
            label: intl.get("field.loan.borrowingsRepaymentFrequency"),
            type: FieldType.ENUM_SELECTION,
            enumData: loanFrequencyOptions,
            disabled: readOnly,
            required: true
          },
          {
            name: "mortgage.payInterestOnlyPeriods",
            label: intl.get("field.loan.payInterestOnlyPeriods"),
            type: FieldType.NUMBER,
            disabled: readOnly,
            numberFormat: "##",
            required: false
          }
        ]}
      />

      <div className="portal-form-row">
        <label className="subtitlelabel">Repayments</label>
        <div className="row-holder">
          <button
            className="portal-btn portal-btn-submit"
            type="button"
            onClick={(e) => showLoanSimulationPreview()}
          >
            Preview
          </button>
        </div>
      </div>
      <div className="portal-form-row">
        <label>{intl.get("field.additionalPaymentAmount")}</label>
        <Field
          name={`mortgage.additionalPaymentAmount`}
          type="text"
          component={renderCurrencyCustom}
          symbolPrefix={currency.symbol}
          defaultCurrency={currency.name}
          disabled={readOnly}
        />
      </div>

      {isAustralia && (
        <div>
          <div className="portal-form-row">
            <label className="subtitlelabel">
              {"Mortgage Associated Charges"}
            </label>
          </div>
          <RenderSimpleFields
            fields={[
              {
                name: "mortgage.additionalRepaymentHandlingFees",
                label: intl.get("field.additionalRepaymentHandlingFees"),
                type: FieldType.MONEY,
                symbolPrefix: currency.symbol,
                defaultCurrency: currency.name,
                disabled: readOnly,
                allowNegative: false
              },
              {
                name: "mortgage.additionalRepaymentFrequency",
                label: intl.get("field.additionalRepaymentFrequency"),
                type: FieldType.ENUM_SELECTION,
                enumData: loanFrequencyOptions,
                disabled: readOnly
              },
              {
                label: intl.get("field.nextAdditionalRepaymentDate"),
                name: "mortgage.additionalRepaymentNextPaymentDate",
                type: FieldType.DATE,
                disabled: readOnly
              },
              {
                name: "mortgage.borrowingsManagementFees",
                label: intl.get("field.loan.borrowingsManagementFees"),
                type: FieldType.MONEY,
                symbolPrefix: currency.symbol,
                defaultCurrency: currency.name,
                disabled: readOnly,
                allowNegative: false
              },
              {
                name: "mortgage.borrowingsManagementFeesFrequency",
                label: intl.get("field.loan.borrowingsManagementFeesFrequency"),
                type: FieldType.ENUM_SELECTION,
                enumData: loanFrequencyOptions,
                disabled: readOnly
              },
              {
                label: intl.get(
                  "field.loan.borrowingsManagementFeesNextPaymentDate"
                ),
                name: "mortgage.borrowingsManagementFeesNextPaymentDate",
                type: FieldType.DATE,
                disabled: readOnly
              }
            ]}
          />
          <div className="portal-form-row">
            <label className="subtitlelabel">
              {"Total - Mortgage Associated Charges"}
            </label>
            <NumberFormat
              value={totalMortgageAssociatedCharges(model)}
              displayType={"input"}
              allowNegative
              decimalScale={DECIMAL_SCALE}
              prefix={currency.symbol}
              decimalSeparator={"."}
              thousandSeparator={","}
              className={`text-input currency-input`}
              readOnly
            />
          </div>
          <RenderSimpleFields
            fields={[
              {
                name: "mortgage.borrowingsPaymentAttachedToOffsetCashAccount",
                label: intl.get(
                  "field.mortgage.borrowingsPaymentAttachedToOffsetCashAccount"
                ),
                type: FieldType.CHECKBOX,
                checkboxLabel: intl.get("YesNoEnum_YES")
              }
            ]}
          />
          {model.mortgage &&
            model.mortgage.borrowingsPaymentAttachedToOffsetCashAccount && (
              <BorrowingsCashAccountListing
                model={model.mortgage.linkedCashAccount}
                hasData={model.mortgage.linkedCashAccount}
                optionsData={{
                  currencyOptions,
                  cashAccountTypeOptions
                }}
                readOnlyAccess={readOnlyAccess}
                onDeleteCashAccount={removeCashAccount}
                clientInformation={clientInformation}
              />
            )}
        </div>
      )}
    </div>
  )
}

export default MortgageDetails
