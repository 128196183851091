import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { Field } from 'react-final-form';
import CashAccountForm from '../CashAccountForm';
import RenderSimpleFields, {
    FieldType,
} from '../../components/Form/RenderSimpleFields';
import MalabarDialog from '../../components/Dialog/MalabarDialog';

const BorrowingsCashAccountListing = ({
    model,
    hasData,
    optionsData,
    readOnlyAccess,
    onDeleteCashAccount,
    clientInformation,
}) => {
    const [state, setState] = useState(0);
    const openCashAccountForm = () => {
        setState({ ...state, isCashAccountFormOpen: true });
    };
    const closeCashAccountForm = () => {
        setState({ ...state, isCashAccountFormOpen: false });
    };

    return (
        <React.Fragment>
            <div className="portal-row-mortgage">
                <div className="portal-form-row">
                    <div className="subtitle2">
                        {intl.get(
                            'primaryResidencePropertyOwnership.details.mortgage.linkedAccountDetails'
                        )}
                    </div>
                    <div className="row-holder">
                        {!hasData && (
                            <span
                                onClick={openCashAccountForm}
                                className="btn btn-plus"
                            />
                        )}
                        {hasData && (
                            <React.Fragment>
                                <span
                                    onClick={openCashAccountForm}
                                    className="btn btn-edit"
                                />
                                <span
                                    onClick={onDeleteCashAccount}
                                    className="btn-remove"
                                />
                            </React.Fragment>
                        )}
                    </div>
                </div>

                {hasData && (
                    <RenderSimpleFields
                        readOnly
                        fields={[
                            {
                                name: 'mortgage.linkedCashAccount.accountName',
                                label: intl.get('field.accountName'),
                                type: FieldType.TEXT,
                            },
                            {
                                name:
                                    'mortgage.linkedCashAccount.accountBalance',
                                label: intl.get('field.accountBalance'),
                                type: FieldType.MONEY,
                            },
                            {
                                name:
                                    'mortgage.linkedCashAccount.valuationDate',
                                label: intl.get('field.valuationDate'),
                                type: FieldType.DATE,
                            },
                        ]}
                    />
                )}
            </div>

            <MalabarDialog
                title={intl.get('cashAccount.form.title')}
                isOpen={(state || {}).isCashAccountFormOpen}
                onClose={closeCashAccountForm}
            >
                <Field
                    name="mortgage.linkedCashAccount"
                    component={props => {
                        const handleSave = cashAccountModel => {
                            props.input.onChange(cashAccountModel);
                            closeCashAccountForm();
                        };
                        return (
                            <CashAccountForm
                                readOnlyAccess={readOnlyAccess}
                                handleSave={handleSave}
                                model={model || {}}
                                optionsData={optionsData}
                                clientInformation={clientInformation}
                            />
                        );
                    }}
                />
            </MalabarDialog>
        </React.Fragment>
    );
};

export default BorrowingsCashAccountListing;
