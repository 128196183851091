/*
    Author : Hendri Firmana (hendri.firmana@devstack.com.au)
             Anova Fawzi (anovafawzi@gmail.com) - rework for cashflow demo
    Description : Personal expense component
*/

import React from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody
} from "react-accessible-accordion";
import intl from "react-intl-universal";
import CashFlowHeaderListing from "../CashFlowHeaderListing";
import {
    CarTransports,
    // HomeExpenses,
    BillUtilities,
    PersonalCares,
    HealthFitness,
    FoodDining,
    EducationExpenses,
    ChildrenExpenses,
    ParentsExpenses,
    Entertainment,
    ShoppingExpenses,
    TravelExpenses,
    OtherInsurancePremium,
    PetExpenses,
    PersonalBusinessExpenses,
    GiftDonations,
    TaxPayments,
    PersonalFinanceExpenses,
    InvestmentExpenses
} from "./ExpensesList";

const PersonalExpenses = ({
    varPrefix,
    optionsData,
    push,
    expenseTotalValues: {
        personalCare,
        carAndTransport,
        home,
        billAndUtilities,
        healthAndFitness,
        otherInsurancePremium,
        total,
        foodAndDining,
        educationExpenses,
        childrenExpenses,
        parentExpenses,
        entertainment,
        shoppingExpenses,
        travelExpenses,
        personalFinanceExpenses,
        investmentExpenses,
        petExpenses,
        personalBusiness,
        giftAndDonations
    },
    currency,
    onExternalUpdated,
    clientInformation,
    personalExpensesData,
    values
}) => {
    const readOnlyAccessComponent = () => null;
    const client = clientInformation;
    const partner = clientInformation.partner || {};
    const isJoint = clientInformation.partner || false;
    return (
        <Accordion>
            <AccordionItem>
                <AccordionItemTitle>
                    <div className="subtitle2b">
                        {intl.get("cashflowheader.top.personalExpenses")}
                        <div className="accordion__arrow" />{" "}
                    </div>
                </AccordionItemTitle>
                <AccordionItemBody>
                    <CarTransports
                        varPrefix={varPrefix}
                        push={push}
                        clientTotalValue={carAndTransport.clientTotal}
                        partnerTotalValue={carAndTransport.partnerTotal}
                        jointTotalValue={carAndTransport.jointTotal}
                        optionsData={optionsData}
                        clientInformation={{
                            client,
                            partner,
                            isJoint
                        }}
                        readOnlyAccessComponent={readOnlyAccessComponent}
                        onExternalUpdated={result => onExternalUpdated(result)}
                        currency={currency}
                        isJointHide={!isJoint}
                        values={values}
                    />

                    <BillUtilities
                        varPrefix={varPrefix}
                        push={push}
                        clientTotalValue={billAndUtilities.clientTotal}
                        partnerTotalValue={billAndUtilities.partnerTotal}
                        jointTotalValue={billAndUtilities.jointTotal}
                        currency={currency}
                        isJointHide={!isJoint}
                    />
                    <PersonalCares
                        varPrefix={varPrefix}
                        optionsData={optionsData}
                        push={push}
                        clientTotalValue={personalCare.clientTotal}
                        partnerTotalValue={personalCare.partnerTotal}
                        jointTotalValue={personalCare.jointTotal}
                        isJointHide={!isJoint}
                        currency={currency}
                    />
                    <HealthFitness
                        varPrefix={varPrefix}
                        optionsData={optionsData}
                        push={push}
                        clientTotalValue={healthAndFitness.clientTotal}
                        partnerTotalValue={healthAndFitness.partnerTotal}
                        jointTotalValue={healthAndFitness.jointTotal}
                        clientInformation={{
                            client,
                            partner,
                            isJoint
                        }}
                        onExternalUpdated={result => onExternalUpdated(result)}
                        currency={currency}
                        isJointHide={!isJoint}
                        values={values}
                    />
                    <FoodDining
                        varPrefix={varPrefix}
                        optionsData={optionsData}
                        push={push}
                        clientTotalValue={foodAndDining.clientTotal}
                        partnerTotalValue={foodAndDining.partnerTotal}
                        jointTotalValue={foodAndDining.jointTotal}
                        currency={currency}
                        isJointHide={!isJoint}
                    />
                    <EducationExpenses
                        varPrefix={varPrefix}
                        optionsData={optionsData}
                        push={push}
                        clientTotalValue={educationExpenses.clientTotal}
                        partnerTotalValue={educationExpenses.partnerTotal}
                        jointTotalValue={educationExpenses.jointTotal}
                        currency={currency}
                        isJointHide={!isJoint}
                        educationExpensesData={
                            personalExpensesData
                                ? personalExpensesData.educationExpenses
                                : {}
                        }
                        onExternalUpdated={result => onExternalUpdated(result)}
                    />
                    <ChildrenExpenses
                        varPrefix={varPrefix}
                        optionsData={optionsData}
                        push={push}
                        clientTotalValue={childrenExpenses.clientTotal}
                        partnerTotalValue={childrenExpenses.partnerTotal}
                        jointTotalValue={childrenExpenses.jointTotal}
                        currency={currency}
                        isJointHide={!isJoint}
                    />
                    <ParentsExpenses
                        varPrefix={varPrefix}
                        optionsData={optionsData}
                        push={push}
                        clientTotalValue={parentExpenses.clientTotal}
                        partnerTotalValue={parentExpenses.partnerTotal}
                        jointTotalValue={parentExpenses.jointTotal}
                        currency={currency}
                        isJointHide={!isJoint}
                    />
                    <Entertainment
                        varPrefix={varPrefix}
                        optionsData={optionsData}
                        push={push}
                        clientTotalValue={entertainment.clientTotal}
                        partnerTotalValue={entertainment.partnerTotal}
                        jointTotalValue={entertainment.jointTotal}
                        currency={currency}
                        isJointHide={!isJoint}
                    />
                    <ShoppingExpenses
                        varPrefix={varPrefix}
                        optionsData={optionsData}
                        push={push}
                        clientTotalValue={shoppingExpenses.clientTotal}
                        partnerTotalValue={shoppingExpenses.partnerTotal}
                        jointTotalValue={shoppingExpenses.jointTotal}
                        currency={currency}
                        isJointHide={!isJoint}
                    />
                    <TravelExpenses
                        varPrefix={varPrefix}
                        optionsData={optionsData}
                        push={push}
                        clientTotalValue={travelExpenses.clientTotal}
                        partnerTotalValue={travelExpenses.partnerTotal}
                        jointTotalValue={travelExpenses.jointTotal}
                        currency={currency}
                        isJointHide={!isJoint}
                    />
                    <OtherInsurancePremium
                        varPrefix={varPrefix}
                        push={push}
                        clientTotalValue={otherInsurancePremium.clientTotal}
                        partnerTotalValue={otherInsurancePremium.partnerTotal}
                        jointTotalValue={otherInsurancePremium.jointTotal}
                        optionsData={optionsData}
                        clientInformation={{
                            client,
                            partner,
                            isJoint
                        }}
                        readOnlyAccessComponent={readOnlyAccessComponent}
                        onExternalUpdated={result => onExternalUpdated(result)}
                        currency={currency}
                        isJointHide={!isJoint}
                        values={values}
                    />
                    <PetExpenses
                        varPrefix={varPrefix}
                        optionsData={optionsData}
                        push={push}
                        clientTotalValue={petExpenses.clientTotal}
                        partnerTotalValue={petExpenses.partnerTotal}
                        jointTotalValue={petExpenses.jointTotal}
                        isJointHide={!isJoint}
                        currency={currency}
                    />
                    <PersonalBusinessExpenses
                        varPrefix={varPrefix}
                        push={push}
                        clientTotalValue={personalBusiness.clientTotal}
                        partnerTotalValue={personalBusiness.partnerTotal}
                        jointTotalValue={personalBusiness.jointTotal}
                        optionsData={optionsData}
                        clientInformation={{
                            client,
                            partner,
                            isJoint
                        }}
                        isJoint={isJoint}
                        readOnlyAccessComponent={readOnlyAccessComponent}
                        currency={currency}
                        onExternalUpdated={result => onExternalUpdated(result)}
                    />

                    <GiftDonations
                        varPrefix={varPrefix}
                        optionsData={optionsData}
                        push={push}
                        clientTotalValue={giftAndDonations.clientTotal}
                        partnerTotalValue={giftAndDonations.partnerTotal}
                        jointTotalValue={giftAndDonations.jointTotal}
                        isJointHide={!isJoint}
                        currency={currency}
                    />
                    <PersonalFinanceExpenses
                        varPrefix={varPrefix}
                        push={push}
                        clientTotalValue={personalFinanceExpenses.clientTotal}
                        partnerTotalValue={personalFinanceExpenses.partnerTotal}
                        jointTotalValue={personalFinanceExpenses.jointTotal}
                        optionsData={optionsData}
                        clientInformation={{
                            client,
                            partner,
                            isJoint
                        }}
                        isJoint={isJoint}
                        readOnlyAccessComponent={readOnlyAccessComponent}
                        currency={currency}
                        onExternalUpdated={result => onExternalUpdated(result)}
                    />

                    <InvestmentExpenses
                        varPrefix={varPrefix}
                        push={push}
                        clientTotalValue={investmentExpenses.clientTotal}
                        partnerTotalValue={investmentExpenses.partnerTotal}
                        jointTotalValue={investmentExpenses.jointTotal}
                        optionsData={optionsData}
                        clientInformation={{
                            client,
                            partner,
                            isJoint
                        }}
                        isJoint={isJoint}
                        readOnlyAccessComponent={readOnlyAccessComponent}
                        currency={currency}
                        onExternalUpdated={result => onExternalUpdated(result)}
                    />
                    <TaxPayments
                        varPrefix={varPrefix}
                        optionsData={optionsData}
                        push={push}
                        clientTotalValue={giftAndDonations.clientTotal}
                        partnerTotalValue={giftAndDonations.partnerTotal}
                        jointTotalValue={giftAndDonations.jointTotal}
                        isJointHide={!isJoint}
                        currency={currency}
                    />
                    {/* <SameAdditionalInput
                        title={'Other Personal Expenses'}
                        optionsData={optionsData}
                        push={push}
                    /> */}

                    <div className="portal-part">
                        <CashFlowHeaderListing
                            title={"Total Personal Expenses $"}
                            isAccordion={false}
                            clientTotalValue={total.clientTotal}
                            partnerTotalValue={total.partnerTotal}
                            jointTotalValue={total.jointTotal}
                            currency={currency}
                            isJointHide={!isJoint}
                        />
                    </div>
                </AccordionItemBody>
            </AccordionItem>
        </Accordion>
    );
};

export default PersonalExpenses;
