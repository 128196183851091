// reference: http://casesandberg.github.io/react-color/?spm=a2c6h.14275010.0.0.909c3b15S5enh5#examples

// 'use strict'

import React from 'react';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ActionCreators } from '../actions/ActionCreators';

class ColorPicker extends React.Component {
    state = {
        displayColorPicker: false,
        // color: {
        //   r: '241',
        //   g: '112',
        //   b: '19',
        //   a: '1',
        // },
        // color: "#fff",
    };

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker });
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false });
    };

    handleChange = color => {
        this.setState({
          // color: color.rgb
          color: color.hex
        })

        // //store in redux
        this.props.colorSuccess(color.hex);
        this.props.onChange(color.hex);
    };

    defaultColor = () => {
        this.props.colorSuccess('#79a14c');
        this.props.onChange('#79a14c');
    };

    render() {
        const { value } = this.props;

        const styles = reactCSS({
            default: {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    // background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
                    background: value,
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });

        return (
            <div className="user-profile-colorpicker">
                <div style={styles.swatch} onClick={this.handleClick}>
                    <div style={styles.color} />
                </div>
                {this.state.displayColorPicker ? (
                    <div style={styles.popover}>
                        <div style={styles.cover} onClick={this.handleClose} />

                        {/* 20200626 get color from parent */}
                        <SketchPicker
                            color={value}
                            onChange={this.handleChange.bind(this)}
                        />
                    </div>
                ) : null}

                {/* Button Holder */}
                <div className="button-holder-default-color-picker">
                    <span
                        onClick={this.defaultColor}
                        className="portal-btn portal-btn-s btn-default-color-picker"
                    >
                        <span className="btn-default-color-picker-text">
                            Use Default Colour
                        </span>
                    </span>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        color: state.color, // 20200629 pass color to PROPS
    };
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(ActionCreators, dispatch);
// return {
//   colorSucess: (color) => { dispatch({type: 'COLOR_SUCCESS', COLOR: color}) }
// }
ColorPicker = connect(
    mapStateToProps,
    mapDispatchToProps
)(ColorPicker);

export { ColorPicker };
