/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Description : Co-Ownership page (tab component)
*/

import React from 'react';
import intl from 'react-intl-universal';
import { Field } from 'react-final-form';
import { renderPercentageCustom } from '../../helpers';

export const CoOwnershipDetailsMutators = {
    calculateCoOwnershipPercentage,
};

function calculateCoOwnershipPercentage(args, state, utils) {
    const value = args[0];
    const inputName = args[1];
    if (inputName.includes('selfPercentage')) {
        utils.changeValue(
            state,
            'coOwnership.partnerPercentage',
            () => 100 - (Number(value) || 0)
        );
    } else if (inputName.includes('partnerPercentage')) {
        utils.changeValue(
            state,
            'coOwnership.selfPercentage',
            () => 100 - (Number(value) || 0)
        );
    }
}

const CoOwnershipDetails = ({ varPrefix, names, onValueChange, readOnly }) => (
    <div className="pop-holder">
        <div className="content-panel-title2">
            <div className="subtitle2">{intl.get('coOwnership.title')}</div>
        </div>
        <Field
            name={`${varPrefix}.selfPercentage`}
            label={names.client}
            component={renderPercentageCustom}
            required={false}
            isLabel
            onChange={newValue =>
                !readOnly
                    ? onValueChange(newValue, `${varPrefix}.selfPercentage`)
                    : null
            }
            disabled={readOnly}
        />
        <Field
            name={`${varPrefix}.partnerPercentage`}
            label={names.partner}
            component={renderPercentageCustom}
            required={false}
            isLabel
            onChange={newValue =>
                !readOnly
                    ? onValueChange(newValue, `${varPrefix}.partnerPercentage`)
                    : null
            }
            disabled={readOnly}
        />
    </div>
);

export default CoOwnershipDetails;
