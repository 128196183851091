import React, { useEffect, useState } from "react"
import intl from "react-intl-universal"
import { Link } from "react-router-dom"
import { bindActionCreators } from "redux"
import { connect, useSelector } from "react-redux"
import { Logout } from "../../../components/Logout"
import { createMenu, lightOrDark } from "./_helper/MenuHelper"
import { entityActions } from "../../../actions/EntityActions"
import { AccessLevelEnum } from "../../../constants/Enums"
import { getPersonalDetails } from "../../../actions"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { logout } from "../../../helpers/SessionHelper"
const ClientBurgerMenu = (props) => {
  const {
    entityTypeEnum,
    feedbackClicked,
    hasAcknowledgedTermsAndConditions,
    match,
    clientId,
    getClient,
    client,
    entityType,
    toggleSidebar,
    isSidebarInActive,
    titleBarColour,
    fontColour
  } = props
  const { dealershipPhoto } = useSelector((state) => state.color)

  const dispatch = useDispatch()
  const loginEntityType = localStorage.getItem("loginEntityType")
  const isLoginAsClient = loginEntityType == "CLIENT"
  const activeRole = JSON.parse(localStorage.getItem("activeUserRole"))
  const [arrGroupMenu, setArrGroupMenu] = useState(activeRole.clientMenuList)
  const [selectedGroupMenu, setSelectedGroupMenu] = useState(arrGroupMenu[0].id)
  const history = useHistory()
  useEffect(() => {
    if (activeRole.clientMenuList.length) {
      activeRole.clientMenuList.map((item, index) => {
        if (item.id) {
          item.children.length &&
            item.children.map((itemIs, indexIs) => {
              if (itemIs.path == match.path.replace(":id", "${clientId}")) {
                setSelectedGroupMenu(item.id)
              }
            })
        }
      })
    }
  }, [])

  // const [isMenuClosed, setMenuClosed] = useState(false)
  function currentPageIsLobby() {
    let currentUrl = window.location.href
    let result = currentUrl.indexOf("lobby") > -1
    return result
  }
  function goBack() {
    history.goBack()
  }
  function toggleGroupMenu(id) {
    setSelectedGroupMenu(id)
  }

  function createGroupMenu(data, clientId) {
    return (
      <li key={data.id}>
        <input
          id={data.id}
          type="checkbox"
          checked={data.id != selectedGroupMenu}
          onChange={() => toggleGroupMenu(data.id)}
        />
        <span className="hand" onClick={() => toggleGroupMenu(data.id)}>
          {data.label}
        </span>
        <nav className="secondary-menu">
          {isLoginAsClient &&
            data &&
            data.children &&
            data.children
              .filter(
                (d) =>
                  !d.disabled &&
                  d.accessLevel != AccessLevelEnum.AccessLevelEnum_NO_ACCESS &&
                  d.showAtClientLogin
              )
              .map((item, idx) => createMenu(item, clientId, idx))}
          {!isLoginAsClient &&
            data &&
            data.children &&
            data.children
              .filter(
                (d) =>
                  !d.disabled &&
                  d.accessLevel != AccessLevelEnum.AccessLevelEnum_NO_ACCESS
              )
              .map((item, idx) => createMenu(item, clientId, idx))}
        </nav>
      </li>
    )
  }

  function generateGroupMenuList() {
    let arr = []
    if (!isLoginAsClient) {
      if (hasAcknowledgedTermsAndConditions) {
        arrGroupMenu &&
          arrGroupMenu
            .filter(
              (g) =>
                !g.disabled &&
                g.accessLevel != AccessLevelEnum.AccessLevelEnum_NO_ACCESS
            )
            .map((g) => {
              arr.push(createGroupMenu(g, clientId))
            })
      } else {
        if (arrGroupMenu) {
          arr.push(createGroupMenu(arrGroupMenu[0], clientId)) //e-kyc only without efiles
        }
      }
    } else {
      if (hasAcknowledgedTermsAndConditions) {
        arrGroupMenu &&
          arrGroupMenu
            .filter(
              (g) =>
                !g.disabled &&
                g.accessLevel != AccessLevelEnum.AccessLevelEnum_NO_ACCESS &&
                g.showAtClientLogin
            )
            .map((g) => {
              arr.push(createGroupMenu(g, clientId))
            })
      } else {
        arr.push(createGroupMenu(arrGroupMenu[0], clientId)) //e-kyc only without efiles
      }
    }

    return arr
  }

  // get client
  useEffect(() => {
    if (client) {
      getClient(client)
      dispatch(getPersonalDetails(client))
    }
  }, [client])
  let mode = localStorage.getItem("Mode")
  return (
    <div className="portal-left">
      <a href={`${process.env.REACT_APP_BASE_URL}/lobby`}>
        <div className="sidebar-navigation-header">
          <img
            className="logo mx-auto"
            src={`${
              dealershipPhoto
                ? dealershipPhoto
                : `${process.env.PUBLIC_URL}/assets/logo/Logo-Horizontal-LightBG.svg`
            }`}
            alt="Koizai logo"
            style={{
              margin: "auto",
              width: "100%",
              maxWidth: "120px",
              maxHeight: "40px",
              height: "100%"
            }}
          />
        </div>
      </a>
      <button
        className="arrow-sidebar-toggler"
        data-toggle-tooltip="to Expand/Collapse"
        onClick={() => {
          toggleSidebar()
        }}
      >
        <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
          <path
            d="M10.294 9.698a.988.988 0 010-1.407 1.01 1.01 0 011.419 0l2.965 2.94a1.09 1.09 0 010 1.548l-2.955 2.93a1.01 1.01 0 01-1.42 0 .988.988 0 010-1.407l2.318-2.297-2.327-2.307z"
            fill="currentColor"
            fillRule="evenodd"
          ></path>
        </svg>
      </button>

      {isSidebarInActive && (
        <div style={{ marginLeft: "0.5em" }}>
          <a href={`${process.env.REACT_APP_BASE_URL}/lobby`}>
            <span
              className="fa fa-lg fa-home"
              style={{ marginTop: "2em", cursor: "pointer" }}
              alt="Go to Lobby"
            ></span>
          </a>
          {!currentPageIsLobby() && (
            <span
              className="fa fa-lg fa-arrow-left"
              style={{ marginTop: "2em", cursor: "pointer" }}
              onClick={() => goBack()}
              alt="Go Back"
            ></span>
          )}
          <span
            className="fa fa-lg fa-comments"
            style={{ marginTop: "2em" }}
            onClick={feedbackClicked}
            alt="User Feedback"
          ></span>
          <Logout
            component={
              <span
                className="fa fa-lg fa-sign-out"
                style={{ marginTop: "2em", cursor: "pointer" }}
                onClick={() => logout()}
                alt="Logout"
              ></span>
            }
          />
        </div>
      )}

      <div className="sidebar-navigation-wrap">
        <nav
          className={`primary-menu primary-menu-${lightOrDark(titleBarColour)}`}
        >
          <ul>
            <li>
              <input type="checkbox" />
              <Link
                to={
                  entityType == "CLIENT"
                    ? "/dashboard/clientsDashboard"
                    : mode === "DEMO_CLIENT"
                    ? "/demo/clients"
                    : localStorage.getItem("activeDashboardRoute")
                }
                style={{
                  background: titleBarColour,
                  color: fontColour
                }}
                className="back-link"
              >
                {intl.get("leftmenu.lifeStylePlanner")}
              </Link>
            </li>
            {generateGroupMenuList()}
          </ul>
        </nav>
        <nav className="primary-menu-end">
          <ul>
            {!currentPageIsLobby() && (
              <li style={{ cursor: "pointer" }}>
                <span onClick={(e) => goBack()} activeclassname="selected">
                  {"Go Back"}
                </span>
              </li>
            )}
            <li>
              <span onClick={feedbackClicked}>
                {intl.get("leftmenu.feedback")}
              </span>
            </li>
            <li>
              <Logout />
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  const { clientId, hasAcknowledgedTermsAndConditions, statusOfClient } =
    state.entity
  const { dealershipPhoto } = state.color

  return {
    clientId,
    hasAcknowledgedTermsAndConditions,
    statusOfClient,
    dealershipPhoto
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, entityActions), dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientBurgerMenu)
