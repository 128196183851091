import React, { useContext } from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from "react-accessible-accordion"
import intl from "react-intl-universal"

import {
  renderSelectFieldCustom,
  renderSelectEnumFieldCustom,
  renderIdentityFieldCustom,
  composeValidators,
  required,
  renderYearCustom,
  identityNumberRequired
} from "../../../helpers"
import { Field } from "react-final-form"
import { FieldArray } from "react-final-form-arrays"
import { optionsType, getOptions } from "../../../helpers/OptionsHelper"
import { CountryList } from "../../../constants/Lists"
import PairField from "./PairField"
import PersonalDetailsContext from "../PersonalDetailsContext"
import { translateListLabel } from "../../../helpers/list-translation"
function IdentityForm({
  prefixName,
  isJoint,
  client,
  partner,
  classification
}) {
  const {
    fieldName,
    mutators: { arrayPush, arrayRemove }
  } = useContext(PersonalDetailsContext)

  let varName = fieldName + "." + prefixName

  return (
    <React.Fragment>
      <FieldArray name={`${varName}.identities`}>
        {({ fields }) => (
          <React.Fragment>
            <Accordion>
              <AccordionItem expanded>
                <div className="ContactDetails">
                  <div className="subtitle3">
                    <label className="identity-label">
                      <span className="subtitle-2 d-flex flex-row">
                        {intl.get("personaldetail.Identities")}{" "}
                        <span className="required-field">*</span>
                      </span>
                      {client.personalDetails &&
                      client.personalDetails.residencyDetails &&
                      client.personalDetails.residencyDetails.identities &&
                      client.personalDetails.residencyDetails.identities
                        .length ? (
                        <AccordionItemTitle>
                          <div className="arrow-identity">
                            <span
                              className="accordion__arrow"
                              role="presentation"
                            />
                          </div>
                        </AccordionItemTitle>
                      ) : (
                        ""
                      )}
                    </label>

                    <div className="btn-holder accordion-identity">
                      <span
                        onClick={() =>
                          arrayPush(`${varName}.identities`, undefined)
                        }
                        className="btn btn-plus"
                      />
                      {"    "}
                      {client.personalDetails &&
                      client.personalDetails.residencyDetails &&
                      client.personalDetails.residencyDetails.identities &&
                      client.personalDetails.residencyDetails.identities.length
                        ? `(${
                            client.personalDetails &&
                            client.personalDetails.residencyDetails &&
                            client.personalDetails.residencyDetails.identities
                              .length
                          })`
                        : ""}
                    </div>
                  </div>
                  <AccordionItemBody>
                    {fields.map((name, index) => {
                      let hideIt = false
                      let identity =
                        client.personalDetails &&
                        client.personalDetails.residencyDetails &&
                        client.personalDetails.residencyDetails.identities &&
                        client.personalDetails.residencyDetails.identities
                          .length &&
                        client.personalDetails.residencyDetails.identities[
                          index
                        ]
                      let identityPartner = ""
                      if (partner) {
                        identityPartner =
                          partner.personalDetails &&
                          partner.personalDetails.residencyDetails &&
                          partner.personalDetails.residencyDetails.identities &&
                          partner.personalDetails.residencyDetails.identities
                            .length &&
                          partner.personalDetails.residencyDetails.identities[
                            index
                          ]
                      }

                      if (identity || identityPartner) {
                        if (client && identity && classification === "SINGLE") {
                          hideIt =
                            identity.idCountry === "MY" &&
                            identity.idType === "NATIONAL_ID"
                        } else if (
                          client &&
                          partner &&
                          identityPartner &&
                          classification === "JOINT"
                        ) {
                          hideIt =
                            identity.idCountry === "MY" &&
                            identity.idType === "NATIONAL_ID" &&
                            identityPartner.idCountry === "MY" &&
                            identityPartner.idType === "NATIONAL_ID"
                        }
                      }
                      return (
                        <div key={name} className="bottom-margin">
                          <div className="portal-grid">
                            {/* client identity */}
                            <div className="portal-form-colsm">
                              &nbsp;&nbsp;
                              {intl.get("personaldetail.Identity")} -{" "}
                              {index + 1} <br />
                              <br />
                              <br />
                              &nbsp;{" "}
                              {!hideIt ? (
                                intl.get("personaldetail.issueDate")
                              ) : (
                                <></>
                              )}
                              <br />
                              <br />
                              &nbsp;{" "}
                              {!hideIt ? (
                                intl.get("personaldetail.expiryDate")
                              ) : (
                                <></>
                              )}{" "}
                            </div>

                            <div
                              className={`portal-form-colsm portal-form-colsm-partner-${isJoint} contactDetail-preferredPhone-label`}
                            >
                              <Field
                                name={name}
                                label={""}
                                required
                                preferred={false}
                                component={renderIdentityFieldCustom}
                                validate={composeValidators(
                                  identityNumberRequired
                                )}
                                onRemoveDataClick={() => {
                                  arrayRemove(`${varName}.identities`, index)
                                  arrayRemove(
                                    `partner.personalDetails.residencyDetails.identities`,
                                    index
                                  )
                                }}
                                index={index}
                              />
                            </div>

                            {/* partner identity */}
                            {isJoint && (
                              <div className="portal-form-colsm contactDetail-preferredPhone-label">
                                <Field
                                  name={name.replace("client", "partner")}
                                  label={""}
                                  required
                                  preferred={false}
                                  component={renderIdentityFieldCustom}
                                  validate={composeValidators(
                                    identityNumberRequired
                                  )}
                                  onRemoveDataClick={() => {
                                    arrayRemove(`${varName}.identities`, index)
                                    arrayRemove(
                                      `partner.personalDetails.residencyDetails.identities`,
                                      index
                                    )
                                  }}
                                  index={index}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    })}
                  </AccordionItemBody>
                </div>
              </AccordionItem>
            </Accordion>
          </React.Fragment>
        )}
      </FieldArray>
    </React.Fragment>
  )
}

function ResidencyDetail({
  name = "personalDetails.residencyDetails",
  isJoint,
  client,
  partner,
  values
}) {
  const { classification } = useContext(PersonalDetailsContext)

  const optionsData = getOptions([optionsType.residencyStatusOptions])
  const form = [
    {
      name: `${name}.residencyStatus`,
      label: intl.get("field.residencyStatus"),
      component: renderSelectEnumFieldCustom,
      enumData: optionsData.residencyStatusOptions,
      isRequired: false
    },
    {
      name: `${name}.residencySince`,
      label: intl.get("field.residencySince"),
      component: renderYearCustom,
      min: new Date().getFullYear() - 124,
      max: new Date().getFullYear(),
      isRequired: false,
      forResidency: true,
      clientDob:
        values.client &&
        values.client.personalDetails &&
        values.client.personalDetails.personalInformation &&
        values.client.personalDetails.personalInformation.birthDate.substring(
          0,
          4
        ),
      partnerDob:
        values.partner &&
        values.partner.personalDetails &&
        values.partner.personalDetails.personalInformation &&
        values.partner.personalDetails.personalInformation.birthDate.substring(
          0,
          4
        )
      // validate: composeValidators(yearLiesInCorrectRange)
    },
    {
      name: `${name}.citizenship.country`,
      label: intl.get("field.citizenships"),
      component: renderSelectFieldCustom,
      data: translateListLabel(CountryList),
      valueProperty: "code",
      labelProperty: "name",
      isRequired: false
    }
  ]

  const formWithResidencySinceMandatory = [
    {
      name: `${name}.residencyStatus`,
      label: intl.get("field.residencyStatus"),
      component: renderSelectEnumFieldCustom,
      enumData: optionsData.residencyStatusOptions
    },
    {
      name: `${name}.residencySince`,
      label: intl.get("field.residencySince"),
      component: renderYearCustom,
      validate: composeValidators(required),
      min: new Date().getFullYear() - 124,
      max: new Date().getFullYear(),
      isRequired: true,
      forResidency: true,
      clientDob:
        values.client &&
        values.client.personalDetails &&
        values.client.personalDetails.personalInformation &&
        values.client.personalDetails.personalInformation.birthDate.substring(
          0,
          4
        ),
      partnerDob:
        values.partner &&
        values.partner.personalDetails &&
        values.partner.personalDetails.personalInformation &&
        values.partner.personalDetails.personalInformation.birthDate.substring(
          0,
          4
        )
    },
    {
      name: `${name}.citizenship.country`,
      label: intl.get("field.citizenships"),
      component: renderSelectFieldCustom,
      data: translateListLabel(CountryList),
      valueProperty: "code",
      labelProperty: "name",
      isRequired: false
    }
  ]
  const formWithCitizenshipMandatory = [
    {
      name: `${name}.residencyStatus`,
      label: intl.get("field.residencyStatus"),
      component: renderSelectEnumFieldCustom,
      enumData: optionsData.residencyStatusOptions
    },
    {
      name: `${name}.residencySince`,
      label: intl.get("field.residencySince"),
      component: renderYearCustom,
      // validate: composeValidators(yearLiesInCorrectRange),
      min: new Date().getFullYear() - 124,
      max: new Date().getFullYear(),
      isRequired: false,
      forResidency: true,
      clientDob:
        values.client &&
        values.client.personalDetails &&
        values.client.personalDetails.personalInformation &&
        values.client.personalDetails.personalInformation.birthDate.substring(
          0,
          4
        ),
      partnerDob:
        values.partner &&
        values.partner.personalDetails &&
        values.partner.personalDetails.personalInformation &&
        values.partner.personalDetails.personalInformation.birthDate.substring(
          0,
          4
        )
    },
    {
      name: `${name}.citizenship.country`,
      label: intl.get("field.citizenships"),
      component: renderSelectFieldCustom,
      data: translateListLabel(CountryList),
      valueProperty: "code",
      labelProperty: "name",
      isRequired: true
    }
  ]
  let residencyStatusOfClient =
    client &&
    client.personalDetails &&
    client.personalDetails.residencyDetails &&
    client.personalDetails.residencyDetails.residencyStatus
  return (
    <Accordion>
      <AccordionItem expanded>
        <div
          className={`portal-part residencydetails-portal-part ${
            client.status == "ARCHIVE" ? "personal-details-disabled" : ""
          }`}
        >
          <AccordionItemTitle>
            <div className="subtitle2">
              {intl.get("personaldetail.residencyDetails")}
              <span className="accordion__arrow" role="presentation" />
            </div>
          </AccordionItemTitle>
          <AccordionItemBody>
            {residencyStatusOfClient === "CITIZEN" &&
              formWithCitizenshipMandatory.map((field) => (
                <PairField
                  key={field.name}
                  field={field}
                  classification={classification}
                />
              ))}
            {residencyStatusOfClient === "PERMANENT_RESIDENT" &&
              formWithResidencySinceMandatory.map((field) => (
                <PairField
                  key={field.name}
                  field={field}
                  classification={classification}
                />
              ))}
            {residencyStatusOfClient !== "CITIZEN" &&
              residencyStatusOfClient !== "PERMANENT_RESIDENT" &&
              form.map((field) => (
                <PairField
                  key={field.name}
                  field={field}
                  classification={classification}
                />
              ))}
            {client.status !== "NEW_CONTACT" && (
              <IdentityForm
                prefixName={"residencyDetails"}
                isJoint={isJoint}
                client={client}
                partner={partner}
                classification={classification}
              />
            )}
          </AccordionItemBody>
        </div>
      </AccordionItem>
    </Accordion>
  )
}

export default ResidencyDetail
