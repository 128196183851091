import React, { useState, useEffect } from "react"
import InvestmentBuilderApi from "../../api/InvestmentBuilderApi"
import { Line } from "react-chartjs-2"

function ContractDetail({
  provider,
  adviser,
  conid,
  symbol,
  requestedCurrency = "USD",
  secType,
  purchasePrice,
  purchaseDate
}) {
  const [result, setResult] = useState([])
  const [period, setPeriod] = useState("1m")
  const [bar, setBar] = useState("1d")

  useEffect(() => {
    update("1m", "1d")
  }, [conid])

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: false
          }
        }
      ]
    }
  }

  //update chart based on period
  const update = (period) => {
    //set state
    setPeriod(period)

    let newdata = []
    const date_value = []
    const closePrice_value = []
    const openPrice_value = []
    const highPrice_value = []
    const lowPrice_value = []
    const purchasePrice_value = []
    const profitPrice_value = []

    const contractInput = {
      provider: provider,
      adviser: adviser,
      conid: conid,
      period: period, //based on selection
      bar: bar, //based on selection
      requestedCurrency: requestedCurrency,
      secType
    }

    InvestmentBuilderApi.getContractDetail(contractInput)
      .then(({ data }) => {
        //store close price only
        let json = {
          labels: [],
          datasets: [
            {
              label: "Close Price",
              data: [],
              fill: false,
              backgroundColor: "rgb(102, 209, 131)",
              borderWidth: 1,
              borderColor: "rgba(65, 209, 104)"
            },
            {
              label: "Open Price",
              data: [],
              fill: false,
              backgroundColor: "rgb(255, 99, 132)",
              borderWidth: 1,
              borderColor: "rgba(255, 99, 132)"
            },
            {
              label: "High Price",
              data: [],
              fill: false,
              backgroundColor: "rgb(199, 128, 237)",
              borderWidth: 1,
              borderColor: "rgba(175, 53, 240)"
            },
            {
              label: "Low Price",
              data: [],
              fill: false,
              backgroundColor: "rgb(69, 140, 255)",
              borderWidth: 1,
              borderColor: "rgba(12, 99, 240)"
            }
          ]
        }
        if (purchasePrice) {
          json.datasets = json.datasets.concat([
            {
              label: "Purchase Price",
              data: [],
              fill: false,
              backgroundColor: "rgb(30, 78, 244)",
              borderWidth: 1,
              borderColor: "rgb(30, 78, 244)"
            },
            {
              label: "Profit Price",
              data: [],
              fill: false,
              backgroundColor: "rgb(210, 78, 244)",
              borderWidth: 1,
              borderColor: "rgb(210, 78, 244)"
            }
          ])
        }
        //generate json

        data.dataList.map((key, index) => {
          //concate
          if (key.timestamp) {
            var s = new Date(key.timestamp).toLocaleDateString("en-US")
            date_value.push(s)
          }

          if (key.close) {
            closePrice_value.push(key.close)
            if (purchasePrice) {
              profitPrice_value.push(key.close - purchasePrice)
            }
          }

          if (key.open) {
            openPrice_value.push(key.open)
          }

          if (key.high) {
            highPrice_value.push(key.high)
          }

          if (key.low) {
            lowPrice_value.push(key.low)
          }
          if (purchasePrice) {
            purchasePrice_value.push(purchasePrice)
          }
        })

        //push into big json
        json.labels = date_value
        json.datasets[0].data = closePrice_value
        json.datasets[1].data = openPrice_value
        json.datasets[2].data = highPrice_value
        json.datasets[3].data = lowPrice_value

        if (purchasePrice) {
          json.datasets[4].data = purchasePrice_value
          json.datasets[5].data = profitPrice_value
        }

        newdata.push(json)
        setResult(newdata[0])
      })
      .catch((error) => {
        console.log("error", error)
      })
  }

  //update chart based on bar
  const updateBar = (bar) => {
    // //set state
    setBar(bar)

    let newdata = []
    const date_value = []
    const closePrice_value = []
    const openPrice_value = []
    const highPrice_value = []
    const lowPrice_value = []
    const purchasePrice_value = []
    const profitPrice_value = []

    const contractInput = {
      provider: provider,
      adviser: adviser,
      conid: conid,
      period: period, //based on selection
      bar: bar, //based on selection
      requestedCurrency: requestedCurrency,
      secType
    }

    InvestmentBuilderApi.getContractDetail(contractInput)
      .then(({ data }) => {
        //store close price only
        let json = {
          labels: [],
          datasets: [
            {
              label: "Close Price",
              data: [],
              fill: false,
              backgroundColor: "rgb(102, 209, 131)",
              borderColor: "rgba(65, 209, 104)"
            },
            {
              label: "Open Price",
              data: [],
              fill: false,
              backgroundColor: "rgb(255, 99, 132)",
              borderColor: "rgba(255, 99, 132)"
            },
            {
              label: "High Price",
              data: [],
              fill: false,
              backgroundColor: "rgb(199, 128, 237)",
              borderColor: "rgba(175, 53, 240)"
            },
            {
              label: "Low Price",
              data: [],
              fill: false,
              backgroundColor: "rgb(255, 99, 132)",
              borderColor: "rgba(255, 99, 132)"
            }
          ]
        }
        if (purchasePrice) {
          json.datasets.concat([
            {
              label: "Purchase Price",
              data: [],
              fill: false,
              backgroundColor: "rgb(30, 78, 244)",
              borderWidth: 1,
              borderColor: "rgb(30, 78, 244)"
            },
            {
              label: "Profit Price",
              data: [],
              fill: false,
              backgroundColor: "rgb(210, 78, 244)",
              borderWidth: 1,
              borderColor: "rgb(210, 78, 244)"
            }
          ])
        }

        //generate json

        data.dataList.map((key, index) => {
          //concate
          if (key.timestamp) {
            var s = new Date(key.timestamp).toLocaleDateString("en-US")
            date_value.push(s)
          }

          if (key.close) {
            closePrice_value.push(key.close)
            profitPrice_value.push(key.close - purchasePrice)
          }

          if (key.open) {
            openPrice_value.push(key.open)
          }

          if (key.high) {
            highPrice_value.push(key.high)
          }

          if (key.low) {
            lowPrice_value.push(key.low)
          }
        })
        purchasePrice_value.push(purchasePrice)

        //push into big json
        json.labels = date_value
        json.datasets[0].data = closePrice_value
        json.datasets[1].data = openPrice_value
        json.datasets[2].data = highPrice_value
        json.datasets[3].data = closePrice_value
        json.datasets[4].data = purchasePrice_value
        json.datasets[5].data = profitPrice_value

        newdata.push(json)
        setResult(newdata[0])
      })
      .catch(({ error }) => {
        console.log("error", error)
      })
  }

  return (
    <div className="PortfolioHistorySimple-modal">
      <div className="header">
        <h1 className="title">{symbol}</h1>
      </div>
      <div>
        <p>
          Period:
          <span className="ContractDetail-button">
            <button
              type="button"
              className={`portal-btn portal-btn-submit ${
                period === "1d" ? "portal-btn-submit-active" : ""
              }`}
              onClick={() => update("1d")}
            >
              1d
            </button>
          </span>
          <span className="ContractDetail-button">
            <button
              type="button"
              className={`portal-btn portal-btn-submit ${
                period === "1w" ? "portal-btn-submit-active" : ""
              }`}
              onClick={() => update("1w")}
            >
              1w
            </button>
          </span>
          <span className="ContractDetail-button">
            <button
              type="button"
              className={`portal-btn portal-btn-submit ${
                period === "1m" ? "portal-btn-submit-active" : ""
              }`}
              onClick={() => update("1m")}
            >
              1m
            </button>
          </span>
          <span className="ContractDetail-button">
            <button
              type="button"
              className={`portal-btn portal-btn-submit ${
                period === "1y" ? "portal-btn-submit-active" : ""
              }`}
              onClick={() => update("1y")}
            >
              1y
            </button>
          </span>
          <span className="ContractDetail-button">
            <button
              type="button"
              className={`portal-btn portal-btn-submit ${
                period === "5y" ? "portal-btn-submit-active" : ""
              }`}
              onClick={() => update("5y")}
            >
              5y
            </button>
          </span>
        </p>
      </div>

      <Line data={result} options={options} />
      {/* <div>
        <p>
          Bar:
          <span className="ContractDetail-button">
            <button
              type="button"
              className={`portal-btn portal-btn-submit ${
                bar === "5m" ? "portal-btn-submit-active" : ""
              }`}
              onClick={() => updateBar("5m")}
            >
              5m
            </button>
          </span>
          <span className="ContractDetail-button">
            <button
              type="button"
              className={`portal-btn portal-btn-submit ${
                bar === "1h" ? "portal-btn-submit-active" : ""
              }`}
              onClick={() => updateBar("1h")}
            >
              1h
            </button>
          </span>
          <span className="ContractDetail-button">
            <button
              type="button"
              className={`portal-btn portal-btn-submit ${
                bar === "1d" ? "portal-btn-submit-active" : ""
              }`}
              onClick={() => updateBar("1d")}
            >
              1d
            </button>
          </span>
          <span className="ContractDetail-button">
            <button
              type="button"
              className={`portal-btn portal-btn-submit ${
                bar === "1w" ? "portal-btn-submit-active" : ""
              }`}
              onClick={() => updateBar("1w")}
            >
              1w
            </button>
          </span>
        </p>
      </div> */}
    </div>
  )
}

export default ContractDetail
