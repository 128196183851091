import { useReducer } from 'react';

const layoutDefault = () => ({
    isClientPage: '',
    currentLayout: '',
});

export const useLayoutActions = () => {
    const [state, dispatch] = useReducer(layoutReducer, layoutDefault);

    const setIsClientPage = isClientPage => {
        dispatch({ type: 'SET_IS_CLIENT_PAGE', isClientPage });
    };

    const setCurrentLayout = currentLayout => {
        dispatch({ type: 'SET_CURRENT_LAYOUT', currentLayout });
    };

    return [
        {
            state,
            setIsClientPage,
            setCurrentLayout,
        },
    ];
};

const layoutReducer = (state, action) => {
    switch (action.type) {
        case 'SET_IS_CLIENT_PAGE':
            return {
                ...state,
                isClientPage: action.isClientPage,
            };
        case 'SET_CURRENT_LAYOUT':
            return {
                ...state,
                currentLayout: action.currentLayout,
            };
        default:
            return state;
    }
};
