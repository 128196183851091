/*
    Author : Marlina (marlina@devstack.com.au)
    Description : Custom table component
*/
import React from "react";
import ReactTable from "react-table";

const TableCustom = props => (
    <ReactTable
        {...props}
        data={props.data}
        columns={props.columns}
        filterable={props.filterable}
        minRows={props.minRows}
        className={props.className}
        sortable={props.sortable}
        getTdProps={props.getTdProps}
    />
);

export default TableCustom;
