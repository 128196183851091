/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Description : collectible/antiques/arts page (modal)
*/

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import intl from 'react-intl-universal';
import { modalWindow } from '../../constants/ModalWindowLists';
import { netWorthFormActions, modalWindowActions } from '../../actions';
import NetWorthForm, {
    NetWorthPath,
    NetWorthFormPropsComposer,
} from './NetWorthForm';
import { CollectibleAntiqueArtsForm } from '../../forms';

class CollectibleAntiqueArts extends NetWorthForm {
    constructor(props) {
        super(
            props,
            intl.get('collectibleAntiques.title'),
            modalWindow.COLLECTIBLE_ANTIQUES_MODAL,
            NetWorthPath.collectibles,
            'CollectibleAntiqueArts'
        );
    }

    isShowSidePane = () => {
        const { model } = this.props;
        return model && (model.insured || model.underLoan);
    };

    setDefaultData = originalModel => {
        const { currency = 'USD' } = this.props;

        if (!originalModel) {
            originalModel = {};
        }
        if (!originalModel.currentValue) {
            originalModel.currentValue = { currency };
        }
        if (!originalModel.loan) {
            originalModel.loan = {};
        }
        if (!originalModel.loan.borrowingsCurrency) {
            originalModel.loan = { borrowingsCurrency: currency };
        }
        return originalModel;
    };

    render() {
        const {
            optionsData,
            model,
            client,
            partner,
            isJoint,
            currency,
        } = this.props;
        const {
            handleSave,
            contentWrapper,
            readOnlyAccessComponent,
            setDefaultData,
        } = this;

        return contentWrapper(
            <CollectibleAntiqueArtsForm
                handleSave={handleSave}
                model={setDefaultData(model)}
                optionsData={optionsData}
                readOnlyAccess={readOnlyAccessComponent()}
                clientInformation={{ client, partner, isJoint }}
                currency={currency}
            />
        );
    }
}

function mapStateToProps(state) {
    return NetWorthFormPropsComposer(
        state,
        modalWindow.COLLECTIBLE_ANTIQUES_MODAL,
        NetWorthPath.collectibles
    );
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        Object.assign({}, netWorthFormActions, modalWindowActions),
        dispatch
    );
}

CollectibleAntiqueArts = connect(
    mapStateToProps,
    mapDispatchToProps
)(CollectibleAntiqueArts);

export default CollectibleAntiqueArts;
