import React, { useState, useMemo } from "react"
import { Form, FormSpy } from "react-final-form"
import intl from "react-intl-universal"
import { ModalNoAccess } from "../components"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import arrayMutators from "final-form-arrays"
import CoOwnershipDetails, {
  CoOwnershipDetailsMutators
} from "./sub-forms/CoOwnershipDetails"
import PropertyExpenses, {
  PropertyExpensesMutators
} from "./sub-forms/PropertyExpenses"
import MortgageDetails, {
  MortgageDetailsMutators
} from "./sub-forms/MortgageDetails"
import FireContentAndLoanInsuranceDetails from "./sub-forms/FireContentAndLoanInsuranceDetails"
import CpfDetails from "./sub-forms/CpfDetails"
import FormButtons from "../components/Form/FormButtons"
import ResidencePropertyMainForm from "./ResidencePropertyMainForm"
import { getCurrency } from "../helpers"
import Get from "../helpers/NullHelper"
import SummaryExpenses from "./sub-forms/Summary"
import createDecorator from "final-form-calculate"
import Api from "../lib/api"
import { useSelector } from "react-redux"
const getDefaultCurrency = (client) => {
  if (client.personalDetails.employmentDetails.taxResidency) {
    return getCurrency(client.personalDetails.employmentDetails.taxResidency)
  }
  return ""
}
const calculateYearlyExpensesOnLoad = (model) => {
  if (model && model.propertyExpenses) {
    Object.keys(model.propertyExpenses)
      .filter(
        (key) =>
          !key.includes("Yearly") &&
          key !== "others" &&
          key !== "expensesCurrency"
      )
      .forEach((key) => {
        model.propertyExpenses[key] = Number(model.propertyExpenses[key]) || 0

        model.propertyExpenses[`${key}Yearly`] =
          model.propertyExpenses[key] * 12
      })

    if (model.propertyExpenses.others && model.propertyExpenses.others.length) {
      model.propertyExpenses.others = model.propertyExpenses.others.map(
        (item) => ({ ...item, valueYearly: item.value * 12 })
      )
    }
  }
}

const setDefaultPropertyLocation = (model, client) => {
  let propertyDetails = model.propertyDetails || {}
  let propertyExpenses = model.propertyExpenses || {}
  let mortgage = model.mortgage || {}
  if (!propertyDetails.propertyLocation) {
    if (
      client.personalDetails &&
      client.personalDetails.employmentDetails &&
      client.personalDetails.employmentDetails.taxResidency
    ) {
      propertyDetails = {
        ...propertyDetails,
        ...{
          propertyLocation:
            client.personalDetails.employmentDetails.taxResidency
        }
      }
    }
  }

  if (!propertyExpenses.expensesCurrency) {
    propertyExpenses = {
      ...propertyExpenses,
      ...{
        expensesCurrency: getDefaultCurrency(client)
      }
    }
  }
  if (!mortgage.borrowingsCurrency) {
    mortgage = {
      ...mortgage,
      ...{
        borrowingsCurrency: getDefaultCurrency(client)
      }
    }
  }

  return {
    ...model,
    ...{
      propertyDetails,
      propertyExpenses,
      mortgage
    }
  }
}
const calculateExpenseFromMortgageAndInsurance = (model) => {
  model.mortgage = model.mortgage || {}
  model.propertyExpenses = {
    ...model.propertyExpenses,
    mortgageAmount: model.mortgage.currentBorrowingsBalance,
    mortgageExpenses: model.mortgage.borrowingsPrinciple,
    fireAndContentInsurance: (model.fireAndContentInsurance || {})
      .policyPremium,
    mortgageInsurance: (model.mortgageInsurance || {}).policyPremium
  }
}
const residencePropertyFormMutator = (am) => ({
  ...am,
  ...{
    adjustMonthlyYearlyValue: PropertyExpensesMutators.adjustMonthlyYearlyValue,
    pushArray: am.push,
    removeArray: am.remove,
    removeCashAccountFromMortgage:
      MortgageDetailsMutators.removeCashAccountFromMortgage,
    calculateCoOwnershipPercentage:
      CoOwnershipDetailsMutators.calculateCoOwnershipPercentage,
    updateBorrowingsRepaymentAmountAndInterest:
      MortgageDetailsMutators.updateBorrowingsRepaymentAmountAndInterest
  }
})

// MABT-389 - set Current Price as same as Purchase Price
function adjustOutgoingData(model) {
  const { propertyDetails } = model

  //if current price is 0
  if (propertyDetails.currentValue) {
    propertyDetails.currentValue.currency =
      propertyDetails.currentValue.value == 0
        ? propertyDetails.purchasePrice.currency
        : propertyDetails.currentValue.currency
    propertyDetails.currentValue.value =
      propertyDetails.currentValue.value == 0
        ? propertyDetails.purchasePrice.value
        : propertyDetails.currentValue.value
  } else {
    propertyDetails.currentValue = {
      currency: propertyDetails.purchasePrice.currency,
      value: propertyDetails.purchasePrice.value
    }
  }

  return {
    ...model,
    propertyDetails: propertyDetails
  }
}

const ResidencePropertyForm = ({
  handleSave,
  model = {},
  readOnlyAccess,
  optionsData,
  ableToPurchaseUsingCpf,
  clientInformation: { client, partner = {}, isJoint },
  currency
}) => {
  const [cityList, setCityList] = useState([])
  const [finalValue, setFinalValue] = useState()
  const [currencyValue, setCurrencyValue] = useState()
  const { defaultCurrency } = useSelector((state) => state.personalDetails)

  const decorator = createDecorator({
    field: "propertyDetails.propertyLocation",
    updates: {
      "propertyDetails.cities": async (fooValue, allValues) => {
        try {
          const data = await Api.get(
            `/analytic/property/city-prices?countryCode=${allValues.propertyDetails.propertyLocation}`
          )
          data.data.cityPriceList.length
            ? setCityList(data.data.cityPriceList)
            : setCityList([])
          return data.data.cityPriceList
        } catch (error) {
          console.log("error is", error)
        }
      }
    }
  })

  const handleTabSelect = (firstTab, lastTab) => {
    console.log("Form validate")
    console.log(`${firstTab}, ${lastTab}`)
    setTabIndex(firstTab)
  }
  const [tabIndex, setTabIndex] = useState(0)
  if (readOnlyAccess) {
    return <ModalNoAccess />
  }
  const [totalPropertyExpenses, setTotalPropertyExpenses] = useState("")
  calculateExpenseFromMortgageAndInsurance(model)
  calculateYearlyExpensesOnLoad(model)
  model = {
    ...model,
    ...setDefaultPropertyLocation(model, client)
  }
  const SecondaryForms = ({ mutators, clientInformation, currency }) => (
    <div className="portal-form-half SecondaryForms">
      <FormSpy subscription={{ values: true }}>
        {({ values }) => {
          const { propertyDetails = {} } = values
          const {
            adjustMonthlyYearlyValue,
            pushArray,
            removeArray,
            calculateCoOwnershipPercentage,
            removeCashAccountFromMortgage
          } = mutators

          const {
            underMortgage = false,
            fireAndContentInsured,
            mortgageInsured,
            hasMultiOwners,
            wasPurchasedWithCpf
          } = propertyDetails

          const baseTabLabel = "primaryResidencePropertyOwnership.details"
          const tabs = [
            {
              render: true,
              label: `${baseTabLabel}.expenseSummary.title`,
              panel: (
                <SummaryExpenses
                  onMonthlyYearlyValueChange={(value, name) =>
                    adjustMonthlyYearlyValue(value, name)
                  }
                  totalPropertyExpenses={totalPropertyExpenses}
                  addOtherExpense={pushArray}
                  removeOtherExpense={removeArray}
                  optionsData={optionsData}
                  model={values}
                  currencyItem={currency}
                />
              )
            },
            {
              render: true,
              label: `${baseTabLabel}.expense.title`,
              panel: (
                <PropertyExpenses
                  onMonthlyYearlyValueChange={(value, name) =>
                    adjustMonthlyYearlyValue(value, name)
                  }
                  setTotalPropertyExpenses={setTotalPropertyExpenses}
                  totalPropertyExpenses={totalPropertyExpenses}
                  addOtherExpense={pushArray}
                  removeOtherExpense={removeArray}
                  optionsData={optionsData}
                  model={values}
                  currencyItem={currency}
                />
              )
            },

            {
              render: underMortgage,
              label: `${baseTabLabel}.mortgage.title`,
              panel: (
                <MortgageDetails
                  optionsData={optionsData}
                  readOnlyAccess={readOnlyAccess}
                  removeCashAccount={removeCashAccountFromMortgage}
                  model={values}
                  isAustralia={
                    client.personalDetails.employmentDetails.taxResidency.toLowerCase() ===
                      "australia" ||
                    client.personalDetails.employmentDetails.taxResidency.toLowerCase() ===
                      "au"
                  }
                  mutators={mutators}
                  clientInformation={{
                    isJoint: clientInformation.isJoint,
                    client: clientInformation.client,
                    partner: clientInformation.partner
                  }}
                  values={values}
                />
              )
            },
            {
              render: fireAndContentInsured || mortgageInsured,
              label: `${baseTabLabel}.insurance.title`,
              panel: (
                <FireContentAndLoanInsuranceDetails
                  optionsData={optionsData}
                  isFireAndContentInsured={fireAndContentInsured}
                  isLoanInsured={mortgageInsured}
                  varPrefix={"fireAndContentInsurance"}
                  varPrefix2={"mortgageInsurance"}
                  values={values}
                  defaultCurrency={
                    Get(values, "mortgage.borrowingsCurrency") ||
                    defaultCurrency
                  }
                />
              )
            },
            {
              render: hasMultiOwners && isJoint,
              label: `${baseTabLabel}.coOwnership.title`,
              panel: (
                <CoOwnershipDetails
                  varPrefix={"coOwnership"}
                  names={{
                    client: Get(
                      client,
                      "personalDetails.personalInformation.firstName"
                    ),
                    partner: Get(
                      partner,
                      "personalDetails.personalInformation.firstName"
                    )
                  }}
                  onValueChange={(value, name) =>
                    calculateCoOwnershipPercentage(value, name)
                  }
                />
              )
            },
            {
              render: wasPurchasedWithCpf,
              label: `${baseTabLabel}.cpfBorrowings.title`,
              panel: <CpfDetails optionsData={optionsData} />
            }
          ]
          return (
            <Tabs
            // selectedIndex={tabIndex}
            // onSelect={(firstTab, lastTab) =>
            //   handleTabSelect(firstTab, lastTab)
            // }
            >
              <TabList>
                {tabs
                  .filter((item) => item.render)
                  .map((item, index) => (
                    <Tab key={index}>{intl.get(item.label)}</Tab>
                  ))}
              </TabList>

              {tabs
                .filter((item) => item.render)
                .map((item, index) => (
                  <TabPanel key={index}>{item.panel}</TabPanel>
                ))}
            </Tabs>
          )
        }}
      </FormSpy>
    </div>
  )
  return (
    <Form
      onSubmit={(values) => {
        if (values.fireAndContentInsurance) {
          values.fireAndContentInsurance.insuranceType = "CONTENT"
        }
        if (values.mortgageInsurance) {
          values.mortgageInsurance.insuranceType = "LOAN"
        }
        if (!values.propertyDetails.underMortgage) {
          values.mortgage = null
        }
        if (!values.propertyDetails.fireAndContentInsured) {
          values.fireAndContentInsurance = null
        }
        if (!values.propertyDetails.mortgageInsured) {
          values.mortgageInsurance = null
        }
        handleSave(adjustOutgoingData(values))
      }}
      initialValues={{
        ...model,
        propertyDetails: {
          ...model.propertyDetails,
          currentValue: {
            value: finalValue
              ? finalValue
              : model &&
                model.propertyDetails &&
                model.propertyDetails.currentValue &&
                model.propertyDetails.currentValue.value
              ? model.propertyDetails.currentValue.value
              : "",
            currency: currencyValue ? currencyValue : defaultCurrency
          }
        },
        mortgage: {
          ...model.mortgage,
          interestRates:
            model.mortgage &&
            model.mortgage.interestRates &&
            model.mortgage.interestRates.length
              ? model.mortgage.interestRates
              : [
                  {
                    rateInPercent: "",
                    startDate: "",
                    endDate: ""
                  }
                ]
        }
      }}
      keepDirtyOnReinitialize={true}
      mutators={{
        ...residencePropertyFormMutator(arrayMutators),
        setFormAttribute: ([fieldName, fieldVal], state, { changeValue }) => {
          changeValue(state, fieldName, () => fieldVal)
        }
      }}
      render={({ handleSubmit, form, reset, values }) => (
        <form onSubmit={handleSubmit}>
          <div className="portal-form-popup">
            <ResidencePropertyMainForm
              client={client}
              partner={partner}
              isJoint={isJoint}
              optionsData={optionsData}
              ableToPurchaseUsingCpf={ableToPurchaseUsingCpf}
              defaultCurrency={currency || defaultCurrency}
              cityList={cityList}
              currentCity={values.propertyDetails.currentValueCity}
              setFinalValue={setFinalValue}
              setCurrencyValue={setCurrencyValue}
              values={values.propertyDetails}
              isNew={model.isNew ? true : false}
              classification={model.classification}
              mutators={form.mutators}
              model={model}
            />
            <SecondaryForms
              mutators={form.mutators}
              clientInformation={{ client, partner, isJoint }}
              currency={
                values &&
                values.propertyDetails &&
                values.propertyDetails.currentValue &&
                values.propertyDetails.currentValue.currency
              }
            />
          </div>
          <FormButtons reset={reset} readOnlyAccess={readOnlyAccess} />
        </form>
      )}
    />
  )
}

export default ResidencePropertyForm
export { calculateYearlyExpensesOnLoad }
