import { divide } from "lodash"
import React from "react"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import SummaryTable from "./SummaryTable"
import PortfolioTable from "./PortfolioTable"
import RspTable from "./RspTable"
import InvestmentPorfolioAnalysis from "./InvestmentPorfolioAnalysis"

const InvestmentFundTableData = ({
  summary,
  investmentPortfolioHoldings,
  rsp,
  portfolioMap
}) => {
  console.log("res is", rsp)
  return (
    <div className="mt-3">
      <Tabs
        justify={true}
        onSelect={(value) => {
          console.log("value is", value)
        }}
      >
        <TabList className="custom_tabs">
          <Tab>Summary</Tab>
          <Tab>Investment Porfolio Holdings</Tab>
          <Tab>Investment Portfolio Analysis</Tab>
          <Tab>Regular Savings Plan(RSP)</Tab>
        </TabList>
        <TabPanel>
          <SummaryTable summary={summary} />
        </TabPanel>
        <TabPanel>
          <PortfolioTable
            investmentPortfolioHoldings={investmentPortfolioHoldings}
          />
        </TabPanel>
        <TabPanel>
          <InvestmentPorfolioAnalysis portfolioMap={portfolioMap} />
        </TabPanel>
        <TabPanel>
          <RspTable rsp={rsp} />
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default InvestmentFundTableData

// activatedDate: null
// amount: 3000
// currency: "HKD"
// dividendInstruction: "Dimension Equity Fund"
// instrumentType: null
// name: "Dimension Equity Fund"
// paymentMethod: "DDA"
// rateFee: 1.2
// status: "Active"
