import { isNullOrUndefined } from 'util';
import { getDirectVariableValue } from './GetDirectValue';
import { countFromArray } from './CountFromArray';

export const educationExpense = (values, currency) => {
    if (!isNullOrUndefined(values)) {
        const {
            bookAndSupplies,
            privateTuitionFees,
            schoolOrUniversityFees,
            schoolUniform,
            computerAndOtherEquipment,
            others,
            australiaGealExpenses,
            hongkongGealExpenses,
            malaysiaGealExpenses,
            newZealandGealExpenses,
            singaporeGealExpenses,
        } = values;
        // count direct variables
        const countBookAndSupplies = getDirectVariableValue(
            bookAndSupplies,
            currency
        );
        const countComputerAndOtherEquipment = getDirectVariableValue(
            privateTuitionFees,
            currency
        );
        const countPrivateTuitionFees = getDirectVariableValue(
            schoolOrUniversityFees,
            currency
        );
        const countSchoolOrUniversityFees = getDirectVariableValue(
            schoolUniform,
            currency
        );
        const countSchoolUniform = getDirectVariableValue(
            computerAndOtherEquipment,
            currency
        );
        const countOthers = countFromArray(others, currency);

        const countAustralianGeal = countFromArray(
            australiaGealExpenses,
            currency
        );
        const countHongkongGeal = countFromArray(
            hongkongGealExpenses,
            currency
        );
        const countMalaysiaGeal = countFromArray(
            malaysiaGealExpenses,
            currency
        );
        const countNewZealandGeal = countFromArray(
            newZealandGealExpenses,
            currency
        );
        const countSingaporeGeal = countFromArray(
            singaporeGealExpenses,
            currency
        );

        return {
            clientTotal:
                countBookAndSupplies.clientTotal +
                countComputerAndOtherEquipment.clientTotal +
                countPrivateTuitionFees.clientTotal +
                countSchoolOrUniversityFees.clientTotal +
                countSchoolUniform.clientTotal +
                countOthers.clientTotal +
                countAustralianGeal.clientTotal +
                countHongkongGeal.clientTotal +
                countMalaysiaGeal.clientTotal +
                countNewZealandGeal.clientTotal +
                countSingaporeGeal.clientTotal,
            partnerTotal:
                countBookAndSupplies.partnerTotal +
                countComputerAndOtherEquipment.partnerTotal +
                countPrivateTuitionFees.partnerTotal +
                countSchoolOrUniversityFees.partnerTotal +
                countSchoolUniform.partnerTotal +
                countOthers.partnerTotal +
                countAustralianGeal.partnerTotal +
                countHongkongGeal.partnerTotal +
                countMalaysiaGeal.partnerTotal +
                countNewZealandGeal.partnerTotal +
                countSingaporeGeal.partnerTotal,
            jointTotal:
                countBookAndSupplies.jointTotal +
                countComputerAndOtherEquipment.jointTotal +
                countPrivateTuitionFees.jointTotal +
                countSchoolOrUniversityFees.jointTotal +
                countSchoolUniform.jointTotal +
                countOthers.jointTotal +
                countAustralianGeal.jointTotal +
                countHongkongGeal.jointTotal +
                countMalaysiaGeal.jointTotal +
                countNewZealandGeal.jointTotal +
                countSingaporeGeal.jointTotal,
        };
    }
    return {
        clientTotal: 0,
        partnerTotal: 0,
        jointTotal: 0,
    };
};
