export const modalWindow = {
  INFORMATION_MODAL: "informationModal",
  CONFIRMATION_MODAL: "confirmationModal",
  // net worth
  BUSINESS_OWNERSHIP_MODAL: "businessOwnershipModal",
  PRIMARY_RESIDENCE_PROPERTY: "primaryResidenceProperty",
  OTHER_RESIDENCE_PROPERTY: "otherResidenceProperty",
  COLLECTIBLE_ANTIQUES_MODAL: "collectibleAntiqueArtsModal",
  MORTGAGE_CASH_ACCOUNT_FORM: "mortgageCashAccountForm",
  PERSONAL_CONTENT_FORM: "personalContentsFormForm",
  MOTOR_VEHICLE_FORM: "motorVehicleForm",
  INVESTMENT_PROPERTY_MODAL: "investmentPropertyModal",
  BOND_FORM: "bondForm",
  GENERIC_PORTFOLIO_FORM: "genericPortfolioForm",
  INVESTMENT_PLATFORM: "investmentPlatform",
  INVESTMENT_FUND_PLATFORM: "investmentfundPlatform",

  INVESTMENT_PLATFORM_SUB_FORM: "investmentPlatformSubForm",
  UNIT_TRUST_FORM: "unitTrustForm",
  UNIT_TRUST_INNER_FORM: "unitTrustInnerForm",
  SHARE_FORM: "shareForm",
  CASH_ACCOUNT_FORM: "cashAccountForm",
  TERM_DEPOSIT_FORM: "termDepositForm",
  ENTITY_MODAL: "entityModal",
  USER_MODAL: "userModal",
  RESET_PASSWORD_MODAL: "resetPasswordModal",
  USER_PROFILE_MODAL: "userProfileModal",
  RETIREMENT_SCHEME_MPF: "retirementSchemeMpf",
  RETIREMENT_SCHEME_CPF: "retirementSchemeCpf",
  RETIREMENT_SCHEME_ORSO: "retirementSchemeOrso",
  SUPERANNUATION_FUND: "superannuationFund",
  SUPERANNUATION_FUND_SUB_FORM: "superannuationFundSubForm",
  DELETE_CONFIRMATION_MODAL: "deleteConfirmationModal",
  FEEDBACK_MODAL: "feedbackModal",
  NOTE_MODAL: "noteModal",
  CAR_FEEDBACK_MODAL: "correctiveActionReportFeedbackModal",
  GEAL_AU_MODAL: "gealAustraliaModal",
  GEAL_SG_MODAL: "gealSingaporeModal",
  GEAL_HK_MODAL: "gealHongkongModal",
  GEAL_NZ_MODAL: "gealNewZealandModal",
  GEAL_MY_MODAL: "gealMalaysiaModal",
  CREDIT_CARD_FORM: "creditCardForm",
  PERSONAL_LOAN_FORM: "personalLoanForm",
  EDUCATION_LOAN_FORM: "educationLoanForm",
  PERSONAL_TAX_FORM: "personalTaxForm",
  EMPLOYMENT_TERMINATION_PAYMENTS: "employmentTerminationPayments",
  EMPLOYEE_PROVIDENCE_FUND: "employeeProvidenceFund",
  PRIVATE_RETIREMENT_SCHEME: "privateRetirementScheme",
  MALAYSIAN_OTHER_PENSION_FUND: "malaysianOtherPensionFund",
  CENTRAL_PROVIDENCE_FUND_INFORMATION: "centralprovidencefundinformation",
  SUPLEMENTARY_RETIREMENT_SCHEME_INFORMATION:
    "suplementaryRetirementSchemezInformation",
  PENSION_INFORMATION: "pensionInformation",
  ILAS_INVESTMENT: "ilasInvestment"
  // add new modal here, the string value will be the
  // variable state that you need to subscribe in mapStateToProps
}
