/*
    Author : Erik (erik.malik@devstack.com.au)
    Description : Personal Tax main page (modal)
*/

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import intl from 'react-intl-universal';
import { netWorthFormActions, modalWindowActions } from '../../../actions';
import { modalWindow } from '../../../constants/ModalWindowLists';
import NetWorthForm, {
    NetWorthPath,
    NetWorthFormPropsComposer,
} from '../NetWorthForm';
import { PersonalTaxForm } from '../../../forms';


class PersonalTax extends NetWorthForm {
    constructor(props) {
        super(
            props,
            intl.get('taxation.personalTax.title'),
            modalWindow.PERSONAL_TAX_FORM,
            NetWorthPath.personalTax,
            'PersonalTax'
        );

        this.oneDataOnly = true;
        
    }

    

    render() {
        const { optionsData, client, model, closeModalWindow} = this.props;
        const { handleSave, contentWrapper, readOnlyAccessComponent } = this;
        const { readOnlyAccess } = readOnlyAccessComponent();
        return contentWrapper(
            <PersonalTaxForm
                handleSave={handleSave}
                model={model}
                client={client}
                optionsData={optionsData}
                readOnlyAccess={readOnlyAccess}
                closeModalWindow={closeModalWindow}
            />
        );
       
        
        
    }
}
function mapStateToProps(state) {
    return NetWorthFormPropsComposer(
        state,
        modalWindow.PERSONAL_TAX_FORM,
        NetWorthPath.personalTax
    );
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        Object.assign({}, netWorthFormActions, modalWindowActions),
        dispatch
    );
}

PersonalTax = connect(
    mapStateToProps,
    mapDispatchToProps
)(PersonalTax);
export default PersonalTax;
