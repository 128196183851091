/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Library : MalabarMoneyWithPercentage
    Description : component specific for money related input
*/

import React, { PureComponent } from 'react';
import NumberFormat from 'react-number-format';
import { DECIMAL_SCALE } from '../../constants/NumberFormatConstants';

const CURRENCY_PART = 'CURRENCY';
const PERCENTAGE_PART = 'PERCENTAGE_PART';
const VALUE_PART = 'VALUE';

export default class MalabarMoneyWithPercentage extends PureComponent {
    componentWillMount() {
        this.setComponentState(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setComponentState(nextProps);
        }
    }

    setComponentState = props => {
        const { value, currency = 'MYR' } = props;
        this.setState({
            value: value === '' ? {} : value,
            selectorValue: value.percentage ? '%' : '',
            currency,
        });
    };

    onPartChange = (part, event) => {
        const { currency } = this.state;
        const { getPercentageValueSource } = this.props;
        switch (part) {
            case CURRENCY_PART: {
                const selectorValue = event.target.value;
                this.setState(prevState => ({
                    ...prevState,
                    selectorValue,
                    value: {
                        percentage: selectorValue === '%' ? 0 : undefined,
                    },
                }));
                break;
            }
            case PERCENTAGE_PART: {
                this.setState(
                    prevState => ({
                        ...prevState,
                        value: {
                            ...prevState.value,
                            percentage: Number(event.value) || 0,
                            money: {
                                currency,
                                value:
                                    ((Number(event.value) || 0) *
                                        (getPercentageValueSource() || 0)) /
                                    100,
                            },
                        },
                    }),
                    () => {
                        // notify final-form
                        this.props.onChange(this.state.value);
                    }
                );
                break;
            }
            case VALUE_PART: {
                this.setState(
                    prevState => ({
                        ...prevState,
                        value: {
                            ...prevState.value,
                            money: {
                                currency,
                                value: +event.value,
                            },
                        },
                    }),
                    () => {
                        // notify final-form
                        this.props.onChange(this.state.value);
                    }
                );
                break;
            }
            default:
                break;
        }
    };

    render() {
        const {
            label,
            required,
            name,
            touched,
            error,
            allowNegative,
            disabled,
            decimalSeparator,
            thousandSeparator,
            isFullWidth,
            currency = 'MYR'
        } = this.props;
        const { selectorValue, value } = this.state;
        const isUsingPercentage =
            value.percentage !== undefined && value.percentage !== null;
        if (!value.money) {
            value.money = {
                currency: 'MYR',
                value: 0,
            };
        }

        return (
            <React.Fragment>
                <label>
                    {label}{' '}
                    {required && <span className="required-asterix">*</span>}
                </label>
                {/* added [d-flex] */}
                <div className={isFullWidth ? 'row-holder d-flex' : ''}>
                    {isUsingPercentage && (
                        <NumberFormat
                            value={value.percentage}
                            displayType={'input'}
                            className="text-input fee-percentage"
                            isAllowed={values => {
                                const lowerBound = 0;
                                const upperBound = 100;
                                const { value: vs } = values;
                                if (
                                    vs === '' ||
                                    vs === undefined ||
                                    vs === null
                                ) {
                                    return true;
                                }
                                return (
                                    lowerBound <= values.floatValue &&
                                    values.floatValue <= upperBound
                                );
                            }}
                            onValueChange={e =>
                                this.onPartChange(PERCENTAGE_PART, e, name)
                            }
                            allowNegative={allowNegative}
                        />
                    )}
                    <div
                        className={`select-box ${isUsingPercentage ? 'money-fee' : ''
                            }`}
                    >
                        <select
                            value={selectorValue}
                            onChange={e => this.onPartChange(CURRENCY_PART, e)}
                            disabled={disabled}
                        >
                            <option value={currency}>
                                {currency}
                            </option>
                            <option value={'%'}>
                                %
                            </option>

                        </select>
                    </div>
                    <NumberFormat
                        value={value.money.value}
                        displayType={'input'}
                        allowNegative={allowNegative}
                        disabled={disabled || isUsingPercentage}
                        decimalScale={DECIMAL_SCALE}
                        decimalSeparator={decimalSeparator}
                        thousandSeparator={thousandSeparator}
                        className="text-input currency-input"
                        onValueChange={e =>
                            this.onPartChange(VALUE_PART, e, name)
                        }
                    />
                    {touched &&
                        (error && (
                            <div className="alert currency-alert">{error}</div>
                        ))}
                </div>
            </React.Fragment>
        );
    }
}
