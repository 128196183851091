import React from 'react';
import { connect } from 'react-redux';
import intl from 'react-intl-universal';
import { bindActionCreators } from 'redux';
import NetWorthListingGroup, {
    NetWorthListingGroupPropsComposer,
} from './NetWorthListingGroup';
import { NetWorthPath } from './NetWorthForm';
import { modalWindow } from '../../constants/ModalWindowLists';
import {
    netWorthActions,
    netWorthFormActions,
    modalWindowActions,
} from '../../actions';
import CreditCard from './Liabilities/CreditCard';
import LoanForm from './Liabilities/LoanForm';

class Liabilities extends NetWorthListingGroup {
    constructor(props) {
        const { getLiabilitiesListing } = props;
        const listingDefinition = [
            {
                title: intl.get('liabilities.creditCard'),
                path: NetWorthPath.creditCards,
                modalName: modalWindow.CREDIT_CARD_FORM,
            },
            {
                title: intl.get('liabilities.personalLoan'),
                path: NetWorthPath.personalLoans,
                modalName: modalWindow.PERSONAL_LOAN_FORM,
            },
            {
                title: intl.get('liabilities.educationLoan'),
                path: NetWorthPath.educationLoans,
                modalName: modalWindow.EDUCATION_LOAN_FORM,
            },
        ];
        super(props, 'liabilities', getLiabilitiesListing, listingDefinition);
    }

    render() {
        const { optionsData, currency } = this.props;
        const { contentWrapper } = this;

        return contentWrapper(
            <React.Fragment>
                <CreditCard optionsData={optionsData} currency={currency} />
                <LoanForm
                    optionsData={optionsData}
                    type="personalLoan"
                    currency={currency}
                />
                <LoanForm
                    optionsData={optionsData}
                    type="educationLoan"
                    currency={currency}
                />
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { liabilitiesListing } = state.netWorth;
    return NetWorthListingGroupPropsComposer(state, liabilitiesListing);
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        Object.assign(
            {},
            netWorthActions,
            modalWindowActions,
            netWorthFormActions
        ),
        dispatch
    );
}

Liabilities = connect(mapStateToProps, mapDispatchToProps)(Liabilities);

export default Liabilities;
