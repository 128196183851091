/*
    Author : Marlina (marlina@devstack.com.au)
    Description : User creation main page (modal)
*/

import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Form, Field } from "react-final-form"
import intl from "react-intl-universal"
import ReactModal from "react-modal"
import { modalWindow } from "../../constants/ModalWindowLists"
import { modalWindowActions, userActions } from "../../actions"
import { renderTextBoxCustom, renderCheckboxCustom } from "../../helpers"
import { required, composeValidators, email } from "../../helpers/Validator"
class UserForm extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {}

  handleSave = async (values) => {
    await this.props.addUser(
      this.props.entityId || this.props.activeAccessLevel.activeUserEntity.id,
      values
    )
    this.props.closeModalWindow(modalWindow.USER_MODAL)
  }

  render() {
    const { model, isShowForm, readOnlyAccess, closeModalWindow } = this.props
    const { handleSave } = this

    return (
      <ReactModal
        isOpen={isShowForm}
        className="malabar-modal-large user-modal"
        overlayClassName="malabar-modal-overlay"
        shouldCloseOnEsc
        onRequestClose={() => closeModalWindow(modalWindow.USER_MODAL)}
      >
        <div className="popup">
          <div className="container-fluid">
            <div className="container">
              <div className="page-title-green">
                {intl.get("user.title")}
                <div className="close-holder">
                  <span
                    onClick={() => closeModalWindow(modalWindow.USER_MODAL)}
                    className="close-button"
                  />
                </div>
              </div>
              <Form
                onSubmit={handleSave}
                initialValues={model}
                validate={(values) => {
                  const errors = {}
                  if (
                    values.password !== undefined &&
                    values.password !== null &&
                    values.confirmPassword !== undefined &&
                    values.confirmPassword !== null
                  ) {
                    if (values.password !== values.confirmPassword) {
                      errors.confirmPassword = intl.get(
                        "validator.passwordMismatch"
                      )
                    }

                    if (values.password.length < 8) {
                      errors.password = intl.get("validator.minLength8")
                    }

                    if (!/\d/.test(values.password)) {
                      errors.password = intl.get("validator.requredADigit")
                    }

                    if (values.password.search(/[a-zA-Z]/) == -1) {
                      errors.password = intl.get("validator.requredALetter")
                    }
                  }
                  return errors
                }}
                render={({ handleSubmit }) => (
                  <form id="bondForm" onSubmit={handleSubmit}>
                    <div className="portal-form-popup">
                      <div className="subtitle2b">{intl.get("user.title")}</div>
                      <Field
                        name="demoUser"
                        label={"Demo User"}
                        component={renderCheckboxCustom}
                      />
                      <Field
                        name="emailAddress"
                        label={intl.get("field.userEmailAddress")}
                        component={renderTextBoxCustom}
                        isLabel
                        type="text"
                        className="portal-form-row"
                        validate={composeValidators(required, email)}
                      />
                      <Field
                        name="password"
                        label={intl.get("field.password")}
                        type="password"
                        component={renderTextBoxCustom}
                        isLabel
                        className="portal-form-row"
                        validate={required}
                      />
                      <Field
                        name="confirmPassword"
                        label={intl.get("field.confirmPassword")}
                        type="password"
                        component={renderTextBoxCustom}
                        isLabel
                        className="portal-form-row"
                        validate={required}
                      />
                    </div>
                    <div className="portal-btn-popup">
                      {!readOnlyAccess && (
                        <div className="text-right">
                          <input
                            className="portal-btn portal-btn-submit portal-btn-submit-mr-10"
                            value={intl.get("button.save")}
                            type="submit"
                          />
                        </div>
                      )}
                    </div>
                  </form>
                )}
              />
            </div>
          </div>
        </div>
      </ReactModal>
    )
  }
}
function mapStateToProps(state) {
  return {
    isShowForm: state.modalWindow[modalWindow.USER_MODAL],
    entityType: state.dashboard.dashboardEntityToCheck,
    // client,
    // model: model || {},
    // updateTracker,
    activeAccessLevel: state.appSettings
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, modalWindowActions, userActions),
    dispatch
  )
}

UserForm = connect(mapStateToProps, mapDispatchToProps)(UserForm)
export default UserForm
