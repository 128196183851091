import intl from "react-intl-universal"
import moment from "moment"

export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    )
export const required = (value) => {
  if (value || value === 0) {
    if (Array.isArray(value)) {
      if (value.length === 0) {
        return intl.get("validator.required")
      }
    }
    return undefined
  }
  return intl.get("validator.required")
}
export const identityNumberRequired = (idObj) => {
  if (idObj && !idObj.idNumber) {
    return intl.get("validator.idRequired")
  } else if (idObj && !idObj.idCountry) {
    return intl.get("validator.idCountry")
  } else if (
    idObj &&
    !(idObj.idCountry === "MY" && idObj.idType === "NATIONAL_ID") &&
    (new Date(idObj && idObj.idExpiredDate) <
      new Date(idObj && idObj.idIssuedDate) ||
      new Date(idObj && idObj.idExpiredDate).getTime() ===
        new Date(idObj && idObj.idIssuedDate).getTime())
  ) {
    return intl.get("validator.greaterThan", { value: idObj.idIssuedDate })
  }

  return undefined
}
export const maxLength = (max) => (value) =>
  value && value.length > max
    ? intl.get("validator.maxLength", { value: max })
    : undefined
export const yearLiesInCorrectRange = (value) => {
  let dateInitial = Number(new Date().getFullYear()) - 100
  let dateEnd = Number(new Date().getFullYear()) + 100
  if ((value < dateInitial || value > dateEnd) && value !== null) {
    return intl.get("validator.inappropriateDate")
  }
  return undefined
}
export const greaterThan = (min) => (value) => {
  if (value && min) {
    if (new Date(value) < new Date(min) || value === min) {
      return `Enter > than ${min}`
    } else {
      return undefined
    }
  }
}
export const lessThan = (min) => (value) =>
  value && min && (new Date(value) > new Date(min) || value === min)
    ? `Enter < than ${min}`
    : undefined
export const greaterThanToday = (value) =>
  value && new Date(value) < Date.now()
    ? intl.get("validator.greaterThan", {
        value: moment().format("DD-MM-YYYY")
      })
    : undefined
export const maxLength15 = maxLength(15)
export const minLength = (min) => (value) =>
  value && value.length < min
    ? intl.get("validator.minLength", { value: min })
    : undefined
export const minLength8 = minLength(8)
export const number = (value) =>
  value && isNaN(Number(value)) ? intl.get("validator.number") : undefined
export const minValue = (min) => (value) =>
  value && value < min
    ? intl.get("validator.minValue", { value: min })
    : undefined
export const minValue18 = minValue(18)
export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? intl.get("validator.email")
    : undefined

export const emails = (value) =>
  value &&
  value.split(",").filter((a) => email(a.trim()) !== undefined).length > 0
    ? intl.get("validator.email")
    : undefined

export const malabarPhone = (value) => {
  if (!value) {
    return undefined
  }
  const { countryCode, number } = value
  return number !== "" && countryCode != ""
    ? undefined
    : intl.get("validator.phoneNumber")
  // if (phoneType === 'MOBILE') {
  //     return countryCode !== '' && pNumber !== ''
  //         ? undefined
  //         : intl.get('validator.phoneNumber');
  // }
  // return countryCode !== '' && areaCode != '' && pNumber !== ''
  //     ? undefined
  //     : intl.get('validator.phoneNumber');
}
export const malabarEmail = (value) =>
  value !== undefined
    ? value.email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.email)
      ? intl.get("validator.email")
      : undefined
    : undefined
export const tooOld = (value) =>
  value && value > 65 ? intl.get("validator.tooOld") : undefined
export const alphaNumeric = (value) =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? intl.get("validator.alphaNumeric")
    : undefined

export const onlyAlphabetic = (value) =>
  value && /[^a-zA-Z -]/i.test(value)
    ? intl.get("validator.alphabetic")
    : undefined
export const phoneNumber = (value) =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? intl.get("validator.phoneNumber")
    : undefined

export const truncate = (checkString, n, useWordBoundary) => {
  if (checkString.length <= n) {
    return checkString
  }
  const subString = checkString.substr(0, n - 1)
  return `${
    useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(" "))
      : subString
  } ...`
}

export const isEmpty = (obj) =>
  Object.entries(obj).length === 0 && obj.constructor === Object
export const isNullOrUndefined = (obj) => obj === null || obj === undefined
// less than 100kb
export const maxPhotoSize = (value) => {
  if (value && value.size) {
    return value.size <= 1024000
      ? undefined
      : intl.get("validator.maxPhotoSize")
  }
  return undefined
}
export const image = (value) => {
  if (value && value.name) {
    return /\.(jpe?g|png|gif)$/i.test(value.name)
      ? undefined
      : intl.get("validator.image")
  }
  return undefined
}
export const ExcelFile = (value) => {
  if (value && value.name) {
    return /\.(csv)$/i.test(value.name)
      ? undefined
      : intl.get("validators.excelFile")
  }
  return undefined
}

export const isValidDate = (value) => {
  if (!value || moment(value).isValid()) {
    return undefined
  }
  return intl.get("validator.invalidDate")
}

export const checkDate = (dateString) => {
  // First check for the pattern
  if (!/^\d{4}\-\d{1,2}\-\d{1,2}$/.test(dateString)) return false

  // Parse the date parts to integers
  const parts = dateString.split("-")

  const year = parseInt(parts[0], 10)
  const month = parseInt(parts[1], 10)
  const day = parseInt(parts[2], 10)

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month == 0 || month > 12) return false

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

  // Adjust for leap years
  if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) monthLength[1] = 29

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1]
}

// validate for null money value
export const validateMoney = (value) => {
  if (value && !value.value && value.value !== 0) {
    return intl.get("validator.required")
  }
  return undefined
}

export const validateTotalPercentages = (currentValues) => (values) => {
  if (currentValues > 100) {
    return "Tot. % exceeded 100"
  }
  return undefined
}
