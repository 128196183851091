import React, { useState, useEffect } from "react";
import { createUrlFromPath } from "../../helpers";
import ClientApi from "../../api/ClientApi";
import Alert from "react-s-alert";

const ChangeStatusDialog = ({ id, item, clearModal, fetchLatest }) => {
    const type = item.type.replace("BUILT_IN_", "");
    const currentPhotoUrl = item.imagePath;

    const info = item.info;
    const name = type == "CLIENT" ? info.clientName : info[0];
    const [preview, setPreview] = useState(createUrlFromPath(currentPhotoUrl));
    const [entityType, setEntityType] = useState([
        {
            label: "New Contact",
            value: "NEW_CONTACT"
        },
        {
            label: "Prospect",
            value: "PROSPECT"
        },
        {
            label: "Client",
            value: "CLIENT"
        }
    ]);

    const [status, setStatus] = useState("");
    const [reason, setReason] = useState("");

    useEffect(() => {}, []);

    const updateStatusHandler = async e => {
        e.preventDefault();
        try {
            const data = {
                clientId: id,
                newStatus: status,
                reason
            };
            const res = await ClientApi.clientStatus(data);
            if (res) {
                Alert.success("Status updated successfully.");
            } else {
                Alert.error("Something went wrong.");
            }
            clearModal();
            fetchLatest();
        } catch (err) {
            console.error(err);
        }
    };
    return (
        <div className="portal-form-popup">
            <div className="row">
                <div className="col-md-8">
                    <h2>{name}</h2>
                </div>
                <div className="col d-flex justify-content-end ">
                    <img
                        className="photo-preview"
                        src={preview}
                        alt="preview"
                    />
                </div>
            </div>

            <div className="row mt-4 align-items-center">
                <div className="col-md-3">
                    <label>Client Status :</label>
                </div>
                <div className="col-md-9 select-box ">
                    <select
                        id="cmbDestList"
                        value={status}
                        onChange={e => setStatus(e.target.value)}
                    >
                        <option value="">Select Options</option>
                        {entityType.map(item => {
                            return (
                                <option value={item.value}>{item.label}</option>
                            );
                        })}
                    </select>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-md-3">
                    <label>Reason :</label>
                </div>
                <div className="col-md-9">
                    <textarea
                        style={{ width: "100%" }}
                        className="form-control"
                        rows="5"
                        onChange={e => setReason(e.target.value)}
                    >
                        {reason}
                    </textarea>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col d-flex justify-content-end ">
                    <button
                        className="portal-btn portal-btn-submit"
                        onClick={updateStatusHandler}
                    >
                        Update
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ChangeStatusDialog;
