import messages_de from "./de.json";
import messages_en from "./en.json";
import messages_id from "./id.json";
import messages_zht from "./zh-traditional.json";
import messages_zhs from "./zh-simplified.json";
import messages_my from "./my.json";
import messages_vn from "./vn.json";

const locales = {
    "en-US": messages_en,
    "de-DE": messages_de,
    "id-ID": messages_id,
    "zh-Hant": messages_zht,
    "zh-Hans": messages_zhs,
    "my-MY": messages_my,
    "vn-VN": messages_vn
};

export default locales;
