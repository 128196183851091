import React, { useContext } from "react"
import intl from "react-intl-universal"
import RenderSimpleFields, {
  FieldType
} from "../components/Form/RenderSimpleFields"
import { CountryList } from "../constants/Lists"
import ClassificationSelection from "../components/_hook/ClassificationSelection"
import { translateListLabel } from "../helpers/list-translation"
import { AppContext } from "../context/AppContext"
import DynamicPropetyPrice from "./misc/DynamicPropertyPrice"

import Alert from "react-s-alert"
import { useSelector } from "react-redux"
import { Field } from "react-final-form"
import { fileFieldRender } from "../helpers"
function ResidencePropertyMainForm({
  client,
  partner,
  isJoint,
  optionsData,
  readOnly = false,
  ableToPurchaseUsingCpf,
  defaultCurrency = "",
  values,
  cityList,
  currentCity,
  setFinalValue,
  setCurrencyValue,
  isNew,
  classification,
  mutators,
  model
}) {
  const { clientFullNamePreferred, partnerFullNamePreferred, hasPartner } =
    useSelector((state) => state.entity)
  const {
    modal: { setModal, clearModal }
  } = useContext(AppContext)
  const limitYear = (values) => {
    const { formattedValue, floatValue } = values
    if (floatValue == null) {
      return formattedValue === ""
    } else {
      return floatValue <= new Date().getFullYear()
    }
  }
  const onEstimatedValueClick = () => {
    if (!currentCity) {
      Alert.error("Please fill field country and city first")
    } else {
      setModal({
        title: "Estimate Property Price",
        content: (
          <DynamicPropetyPrice
            cityList={cityList}
            currentCity={currentCity}
            setFinalValue={setFinalValue}
            setCurrencyValue={setCurrencyValue}
            values={values}
          />
        )
      })
    }
  }
  let fields = [
    {
      name: "propertyDetails.propertyOwner",
      label: intl.get("primaryResidencePropertyOwnership.propertyOwner"),
      type: FieldType.AUTOSUGGEST,
      required: true,
      names: hasPartner
        ? [clientFullNamePreferred, partnerFullNamePreferred]
        : [clientFullNamePreferred],
      isPortalFormRowType: true
    },
    {
      name: "propertyDetails.propertyName",
      label: intl.get("primaryResidencePropertyOwnership.propertyName"),
      type: FieldType.TEXT,
      required: true
    },

    {
      name: "propertyDetails.propertyType",
      label: intl.get("primaryResidencePropertyOwnership.propertyType"),
      type: FieldType.ENUM_SELECTION,
      enumData: optionsData.propertyTypeOptions
    },
    {
      name: "propertyDetails.propertyLocation",
      label: intl.get("primaryResidencePropertyOwnership.propertyLocation"),
      type: FieldType.SELECTION,
      selectionData: translateListLabel(CountryList),
      valueProperty: "code",
      labelProperty: "name",
      required: false
    },

    {
      name: "propertyDetails.currentValueCity",
      label: intl.get("primaryResidencePropertyOwnership.city"),
      type: FieldType.SELECTION,
      selectionData: cityList,
      valueProperty: "city",
      labelProperty: "city",
      required: false
    },
    {
      name: "propertyDetails.yearPurchased",
      label: intl.get("primaryResidencePropertyOwnership.yearPurchased"),
      type: FieldType.YEAR,
      min: 1900,
      max: new Date().getFullYear()
    },

    {
      name: "propertyDetails.purchasePrice",
      label: intl.get("primaryResidencePropertyOwnership.purchasePrice"),
      type: FieldType.MONEY_WITH_CURRENCY,
      defaultCurrency,
      allowNegative: false
    },

    {
      name: "propertyDetails.currentValue",
      label: intl.get("primaryResidencePropertyOwnership.currentValue"),
      type: FieldType.MONEY_WITH_CURRENCY,
      defaultCurrency,
      required: true,
      allowNegative: false,
      popupPickerEvent: (e) => onEstimatedValueClick()
    },
    {
      name: "propertyDetails.valuationDate",
      label: intl.get("primaryResidencePropertyOwnership.valuationDate"),
      type: FieldType.DATE,

      required: true
    },

    {
      name: "propertyDetails.annualGrowthRate",
      label: intl.get("primaryResidencePropertyOwnership.annualGrowthRate"),
      type: FieldType.PERCENTAGE,
      required: true
    },
    {
      name: "propertyDetails.annualDepreciationRate",
      label: intl.get("field.annualDepreciationRate"),
      type: FieldType.PERCENTAGE,
      required: true
    },
    {
      name: "propertyDetails.underMortgage",
      label: intl.get("primaryResidencePropertyOwnership.underMortgage"),
      type: FieldType.CHECKBOX,
      checkboxLabel: intl.get("YesNoEnum_YES"),
      required: true
    }
  ]

  if (readOnly) {
    fields = fields
      .filter(
        (key) => key.name !== "propertyDetails.underMortgage" //disable 'Is the property under a mortgage?'
      )
      .map((field) => ({ ...field, readOnly: true }))
  }

  return (
    //toggle to show side panel
    <div className={values !== undefined ? "portal-form-half" : ""}>
      <ClassificationSelection
        client={client}
        partner={partner}
        isJoint={isJoint}
        hideJoint={!isJoint || readOnly}
        isNew={isNew}
        classification={classification}
      />
      <div className="subtitle2">
        {intl.get("primaryResidencePropertyOwnership.propertyDetails")}
      </div>
      <RenderSimpleFields fields={fields} />
      <div className="portal-form-row">
        <label>{intl.get("primaryResidencePropertyOwnership.insured")}</label>
        <div className="row-holder">
          <RenderSimpleFields
            fields={[
              {
                name: "propertyDetails.fireAndContentInsured",
                checkboxLabel: intl.get(
                  "primaryResidencePropertyOwnership.fireAndContentInsuredLabel"
                ),
                type: FieldType.CHECKBOX,
                isLabel: false,
                readOnly,
                required: true
              },
              {
                name: "propertyDetails.mortgageInsured",
                checkboxLabel: intl.get(
                  "primaryResidencePropertyOwnership.mortgageInsuredLabel"
                ),
                type: FieldType.CHECKBOX,
                isLabel: false,
                readOnly,
                required: true
              }
            ]}
          />
        </div>
      </div>
      {/* MABT-415 hide the checkbox if it is insurance */}
      {isJoint && values === undefined && (
        <RenderSimpleFields
          fields={[
            {
              name: "propertyDetails.hasMultiOwners",
              label: intl.get("primaryResidencePropertyOwnership.shared"),
              type: FieldType.CHECKBOX,
              checkboxLabel: intl.get("YesNoEnum_YES"),
              readOnly,
              required: true
            }
          ]}
        />
      )}
      {ableToPurchaseUsingCpf && (
        <RenderSimpleFields
          fields={[
            {
              name: "propertyDetails.wasPurchasedWithCpf",
              label: intl.get(
                "primaryResidencePropertyOwnership.useCpfToPurchase"
              ),
              type: FieldType.CHECKBOX,
              checkboxLabel: intl.get("YesNoEnum_YES"),
              required: true
            }
          ]}
        />
      )}
      <Field
        component={fileFieldRender}
        customClassName="mt-1 d-block"
        labelIdle="Drag, Drop or Browse"
        label="Upload Documents"
        onupdatefiles={(fileItems) => {
          mutators.setFormAttribute("proofDocument", fileItems[0])
        }}
        fileInformation={(model && model.proofDocument) || null}
      />
    </div>
  )
}

export default ResidencePropertyMainForm
