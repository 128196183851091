import { combineReducers } from "redux"
import { authentication } from "./AuthenticationReducer"
import { users } from "./UserReducer"
import { alert } from "./AlertReducer"
import { enumOptions } from "./EnumOptionsReducer"
import { modalWindow } from "./ModalWindowReducer"
import { appSettings } from "./AppSettingsReducer"
import { loader } from "./LoaderReducer"
import { dashboard } from "./DashboardReducer"
import { notes } from "./NotesReducer"
// KYC
import { primaryResidence } from "./KYC/PrimaryResidenceReducer"
import { personalDetails } from "./KYC/PersonalDetailsReducer"
import { estatePlanning } from "./KYC/EstatePlanningReducer"
// Net Worth
import { netWorth } from "./KYC/NetWorth/NetWorthReducer"
import { netWorthForm } from "./KYC/NetWorth/NetWorthFormReducer"
// Personal Assets
import { businessOwnership } from "./KYC/NetWorth/BusinessOwnershipReducer"
import { insuranceLifestylebuilder } from "./LifeStyleBuilder/InsuranceTabReducer"
// Retirement Assets
import { fundAllocationDetail } from "./KYC/RetirementAsset/FundAllocationDetailReducer"
import { investmentPlatformInformation } from "./KYC/RetirementAsset/InvestmentPlatformInformationReducer"
import { mandatoryProvidentFund } from "./KYC/RetirementAsset/MandatoryProvidentFundReducer"
import { occupationalRetirementSchemeOrdinance } from "./KYC/RetirementAsset/OccupationalRetirementSchemeOrdinanceReducer"
import { unitTrustHoldingInformation } from "./KYC/RetirementAsset/UnitTrustHoldingInformationReducer"
import { superAnnuationFund } from "./KYC/RetirementAsset/SuperAnnuationFundReducer"
import { superAnnuationFundInsurance } from "./KYC/RetirementAsset/SuperAnnuationFundInsuranceReducer"
import { bondsHoldingInformation } from "./KYC/RetirementAsset/BondsHoldingInformationReducer"
import { cashAccountInformation } from "./KYC/RetirementAsset/CashAccountInformationReducer"
import { sharesHoldingInformation } from "./KYC/RetirementAsset/SharesHoldingInformationReducer"
import { termDepositInformation } from "./KYC/RetirementAsset/TermDepositInformationReducer"
import { eTFHoldingInformation } from "./KYC/RetirementAsset/ETFHoldingInformationReducer"
import { manageAccessLevel } from "./ManageAccessLevelReducer"
import { feedbacks } from "./FeedbackReducer"
import { jointSelection } from "./JointSelectionReducer"

// Goal
import { goal } from "./KYC/Goal/GoalReducer"

import { riskProfile } from "./KYC/RiskProfile/RiskProfileReducer"

// Cash Flow
import { cashFlow } from "./KYC/CashFlow/CashFlowReducer"
import { insurance } from "./KYC/InsuranceReducer"

// Current Standing
import { currentStanding } from "./CurrentStanding/CurrentStandingReducer"
import { emailReducer } from "./Email/EmailReducer"
import { entity } from "./EntityReducer"

// Color Picker
import { color } from "./ColorReducer"

// Implementation
import { Implementation } from "./ImplementationReducer"
import { scenariosReducer as scenarios } from "./LifeStyleBuilder/ScenarioReducer"
const rootReducer = combineReducers({
  // auth
  authentication,
  // app settings
  appSettings,

  // loader
  loader,
  notes,
  // alert
  alert,

  // users
  users,

  // modal window
  modalWindow,

  // enum options
  enumOptions,

  // dashboard
  dashboard,

  // KYC
  primaryResidence,
  personalDetails,
  estatePlanning,
  entity,
  // net worth
  netWorth,
  netWorthForm,

  // Personal Assets
  businessOwnership,

  // asset retirement
  fundAllocationDetail,
  investmentPlatformInformation,
  mandatoryProvidentFund,
  occupationalRetirementSchemeOrdinance,
  unitTrustHoldingInformation,
  superAnnuationFund,
  superAnnuationFundInsurance,
  bondsHoldingInformation,
  cashAccountInformation,
  sharesHoldingInformation,
  termDepositInformation,
  eTFHoldingInformation,

  // access level
  manageAccessLevel,
  feedbacks,

  goal,
  cashFlow,

  riskProfile,

  insurance,
  jointSelection,

  // Current Standing
  currentStanding,

  emailReducer,
  color,
  Implementation,
  insuranceLifestylebuilder,
  scenarios
})

export default rootReducer
