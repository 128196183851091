/*
    Author : anton.nuansantio@koizai.com
    Library : TaxRulesApi
    Description : methods related with Tax Rules
*/

import api from "../../lib/api";
// change base route for different entity/domain here
const BASE_ROUTE = '/client';

class TaxApi {

    static initMalaysiaTax(clientId) {
        return api.get(`${BASE_ROUTE}/${clientId}/personal_tax/init-malaysia`);
    }
    static saveTaxWithDetails(clientId, model) {
        return api.post(`${BASE_ROUTE}/${clientId}/personal_tax/tax-estimator`, model);
    }
    
    
}

export default TaxApi;
