import React, { useContext } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody
} from "react-accessible-accordion";
import intl from "react-intl-universal";
import { AppContext } from "../../../../context/AppContext";
import CashFlowHeaderListing from "../../CashFlowHeaderListing";
import { InvestmentPropertyForm } from "../../../../forms";
import CashFlowListingGroup from "../../CashFlowListingGroup";
import api from "../../../../lib/api";
import { netWorthFormActions } from "../../../../actions";
import { useDispatch } from "react-redux";

const InvestmentProperty = ({
    varPrefix,
    push,
    clientTotalValue,
    partnerTotalValue,
    jointTotalValue,
    optionsData,
    readOnlyAccessComponent,
    clientInformation,
    currency,
    onExternalUpdated,
    isJointHide,
    values
}) => {
    const {
        modal: { setModal, clearModal }
    } = useContext(AppContext);

    const handleSave = newValues => {
        // save the model
        api.put(newValues.url, newValues).then(response => {
            onExternalUpdated(true);
        });

        clearModal();
    };
  const dispatch = useDispatch()


    return (
        <div className="portal-part">
            <Accordion accordion={false}>
                <AccordionItem>
                    <AccordionItemTitle>
                        <CashFlowHeaderListing
                            title={intl.get(
                                "cashflowlisting.investmentOutflow.investmentProperty"
                            )}
                            isAccordion
                            clientTotalValue={clientTotalValue}
                            partnerTotalValue={partnerTotalValue}
                            jointTotalValue={jointTotalValue}
                            currency={currency}
                            isJointHide={isJointHide}
                        />
                    </AccordionItemTitle>
                    <AccordionItemBody>
                        <CashFlowListingGroup
                            varPrefix={`${varPrefix}`}
                            dataArray={values.detailResult}
                            isJointHide={isJointHide}
                            isRoundColorRed
                            isExternalModal
                            currency={currency}
                            onEditClick={model =>{
                                dispatch(
                                  netWorthFormActions.setNewForm(
                                    false,
                                    model.classification
                                  )
                                )
                                setModal({
                                    title: intl.get(
                                        "cashflowlisting.investmentOutflow.investmentProperty"
                                    ),
                                    modalClassName: "InvestmentProperty",
                                    content: (
                                        <InvestmentPropertyForm
                                            handleSave={handleSave}
                                            model={model}
                                            readOnlyAccess={readOnlyAccessComponent()}
                                            clientInformation={
                                                clientInformation
                                            }
                                            optionsData={optionsData}
                                            currency={currency} //20200609
                                        />
                                    ),
                                    formType: "InvestmentProperty"
                                })
                            }}
                        />
                    </AccordionItemBody>
                </AccordionItem>
            </Accordion>
        </div>
    );
};

export default InvestmentProperty;
