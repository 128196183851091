/*
    Author : Marlina (marlina@devstack.com.au)
    Author : Erik (erik.malik@devstack.com.au)
    Description : Term deposit main page (modal)
*/

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import intl from 'react-intl-universal';
import { netWorthFormActions, modalWindowActions } from '../../actions';
import { modalWindow } from '../../constants/ModalWindowLists';
import NetWorthForm, {
    NetWorthPath,
    NetWorthFormPropsComposer,
} from './NetWorthForm';
import { TermDepositForm } from '../../forms';

class TermDeposit extends NetWorthForm {
    constructor(props) {
        super(
            props,
            intl.get('termDeposit.title'),
            modalWindow.TERM_DEPOSIT_FORM,
            NetWorthPath.termDeposits,
            'TermDeposit'
        );
    }

    render() {
        const { optionsData, model, client, partner, isJoint, currency } = this.props;
        
        const { handleSave, contentWrapper, readOnlyAccessComponent } = this;
        
        return contentWrapper(
            <TermDepositForm
                handleSave={handleSave}
                model={model}
                optionsData={optionsData}
                readOnlyAccess={readOnlyAccessComponent()}
                clientInformation={{ client, partner, isJoint }}
                defaultCurrency={currency}
            />
        );
    }
}

function mapStateToProps(state) {
    return NetWorthFormPropsComposer(
        state,
        modalWindow.TERM_DEPOSIT_FORM,
        NetWorthPath.termDeposits
    );
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        Object.assign({}, netWorthFormActions, modalWindowActions),
        dispatch
    );
}

TermDeposit = connect(
    mapStateToProps,
    mapDispatchToProps
)(TermDeposit);
export default TermDeposit;
