import React from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { enumOptionsActions } from "../../../actions";
import { FormButtons, RenderSimpleFields } from "../../../components";
import { FieldType } from "../../../components/Form/RenderSimpleFields";
import arrayMutators from 'final-form-arrays';
import CPFClassification from "./CPFClassification";

const CPFTreasuryBills = ({ optionsData, handleSave, model }) => {

    return (
        <div>
            <Form
                onSubmit={handleSave}
                initialValues={model}
                mutators={{
                    // potentially other mutators could be merged here
                    ...arrayMutators,
                }}
                initialValuesEqual={() => true}
                render={({
                    handleSubmit,
                    reset,
                    values
                }) => (
                        <form
                            id="cpfCashAccount"
                            onSubmit={handleSubmit}
                            noValidate
                        >
                            <div className="portal-form-popup">
                                <CPFClassification value={model.classification} disabled />
                                <div className="subtitle2">
                                    Treasury Bills Details
                                </div>
                                <RenderSimpleFields
                                    fields={[
                                        {
                                            name: 'treasuryBillIssuer',
                                            label: 'Treasury Bill Issuer',
                                            type: FieldType.TEXT,
                                        },
                                        {
                                            name: 'issueCode',
                                            label: 'Issuer Code',
                                            type: FieldType.TEXT,
                                        },
                                        {
                                            name: 'isinCode',
                                            label: 'ISIN Code',
                                            type: FieldType.TEXT,
                                        },
                                        {
                                            name: 'datePurchased',
                                            label: 'Date Purchased',
                                            type: FieldType.DATE,
                                        },
                                        {
                                            name: 'couponPercent',
                                            label: 'Coupon (Interest) Rate',
                                            type: FieldType.PERCENTAGE,
                                        },
                                        {
                                            name: 'faceValue',
                                            label: 'Face Value',
                                            type: FieldType.MONEY,
                                            isMoney: true,
                                            defaultCurrency: 'SGD'
                                        },
                                        {
                                            name: 'maturityDate',
                                            label: 'Maturity Date',
                                            type: FieldType.DATE,
                                        },
                                    ]}
                                />
                            </div>
                            <FormButtons
                                reset={reset}
                            />
                        </form>
                    )}
            />
        </div>


    )
}


function mapStateToProps(state) {
    const { optionsData } = state.enumOptions;

    return {
        optionsData,
    };
}

function mapDispatchToProps(dispatch) {

    return bindActionCreators(enumOptionsActions, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CPFTreasuryBills);