import React, { useContext } from "react"
import ReactModal from "react-modal"
import { AppContext } from "../../context/AppContext"

const GlobalMalabarDialog = () => {
  const {
    modal: {
      state: { modal },
      clearModal
    }
  } = useContext(AppContext)

  // added [formType]
  if (modal) {
    //  get form type
    let getFormType = modal.content.props.formType
      ? modal.content.props.formType
      : modal.formType

    return (
      <ReactModal
        isOpen
        className={
          modal.isSmall
            ? `malabar-modal ${getFormType}`
            : modal.isNoMargin
            ? `malabar-modal-no-margin ${getFormType}`
            : `malabar-modal-large ${getFormType} ${
                modal.large && "make-large"
              }`
        }
        overlayClassName="malabar-modal-overlay"
        ariaHideApp={false}
        shouldCloseOnEsc
        onRequestClose={clearModal}
      >
        {/* Dyanamic Class added */}
        <div
          className={`popup  popup-networth ${
            modal.modalClassName !== null ? modal.modalClassName : "UnitTrust"
          } ${modal.type === "EPF" ? "popup-networth-epf" : ""}`}
        >
          <div className="container-fluid">
            {/* <div className="container"> */}
            <div className="page-title">
              {modal.title}
              <div className="close-holder">
                <span onClick={clearModal} className="close-button" />
              </div>
            </div>
            {modal.content}
          </div>
          {/* </div> */}
        </div>
      </ReactModal>
    )
  }

  return <React.Fragment />
}

export default GlobalMalabarDialog
