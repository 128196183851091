/*
    Author : Erik Farhan Malik (erikfarhanmalik@gmail.com)
    Description : Residence Property main page (modal)
*/

import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import intl from "react-intl-universal"
import {
  netWorthActions,
  netWorthFormActions,
  modalWindowActions
} from "../../actions"
import { Bond, UnitTrust, InvestmentPlatform } from ".."
import TermDeposit from "./TermDeposit"
import Share from "./Share"
import CashAccount from "./CashAccount"
import NetWorthListingGroup, {
  NetWorthListingGroupPropsComposer
} from "./NetWorthListingGroup"
import { NetWorthPath } from "./NetWorthForm"
import { modalWindow } from "../../constants/ModalWindowLists"
import GenericPortfolio from "./GenericPortfolio"
import InvestmentFund from "./InvestmentFund"

class LiquidAssets extends NetWorthListingGroup {
  constructor(props) {
    const { getLiquidAssetsListing } = props
    const listingDefinition = [
      {
        title: intl.get("netWorth.cashAccount"),
        path: NetWorthPath.cashAccounts,
        modalName: modalWindow.CASH_ACCOUNT_FORM
      },
      {
        title: intl.get("netWorth.termDeposit"),
        path: NetWorthPath.termDeposits,
        modalName: modalWindow.TERM_DEPOSIT_FORM
      },
      {
        title: intl.get("netWorth.shares"),
        path: NetWorthPath.shares,
        modalName: modalWindow.SHARE_FORM
      },
      {
        title: intl.get("netWorth.unitTrusts"),
        path: NetWorthPath.unitTrusts,
        modalName: modalWindow.UNIT_TRUST_FORM
      },
      {
        title: intl.get("netWorth.bonds"),
        path: NetWorthPath.bonds,
        modalName: modalWindow.BOND_FORM
      },
      {
        title: intl.get("netWorth.genericPortfolios"),
        path: NetWorthPath.genericPortfolios,
        modalName: modalWindow.GENERIC_PORTFOLIO_FORM
      },
      {
        title: intl.get("netWorth.investmentPlatform"),
        path: NetWorthPath.investmentPlatforms,
        modalName: modalWindow.INVESTMENT_PLATFORM
      },
      // new one by donald
      {
        title: "Investment Fund Platform Account Information",
        path: NetWorthPath.investmentFundPlatforms,
        modalName: modalWindow.INVESTMENT_FUND_PLATFORM
      }
    ]
    super(props, "liquidAssets", getLiquidAssetsListing, listingDefinition)
  }

  render() {
    const { optionsData, currency, currencySymbol } = this.props
    const { contentWrapper } = this

    return contentWrapper(
      <React.Fragment>
        <CashAccount optionsData={optionsData} currency={currency} currencySymbol={currencySymbol}/>
        <TermDeposit optionsData={optionsData} currency={currency}  currencySymbol={currencySymbol}/>
        <Share optionsData={optionsData} currency={currency} currencySymbol={currencySymbol} />
        <UnitTrust optionsData={optionsData} currency={currency} currencySymbol={currencySymbol} />
        <Bond optionsData={optionsData} currency={currency} currencySymbol={currencySymbol} />
        <GenericPortfolio optionsData={optionsData} currency={currency} currencySymbol={currencySymbol} />
        <InvestmentPlatform optionsData={optionsData} currency={currency} currencySymbol={currencySymbol} />
        <InvestmentFund optionsData={optionsData} currency={currency} currencySymbol={currencySymbol} />
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { liquidAssetsListing } = state.netWorth
  return NetWorthListingGroupPropsComposer(state, liquidAssetsListing)
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, netWorthActions, modalWindowActions, netWorthFormActions),
    dispatch
  )
}

LiquidAssets = connect(mapStateToProps, mapDispatchToProps)(LiquidAssets)

export default LiquidAssets
