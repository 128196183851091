import React, { PureComponent } from 'react';

const REGION_PART = 'REGION';
const AREA_PART = 'AREA';
const NUMBER_PART = 'NUMBER';
const REGION_MAXLENGTH = 4;
const AREA_MAXLENGTH = 4;
const NUMBER_MAXLENGTH = 16;

export default class MalabarPhoneNumberSimple extends PureComponent {
    state = {
        region: '',
        area: '',
        number: '',
        value: this.props.value,
    };

    componentWillMount() {
        this.setComponentState(this.props.value);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setComponentState(nextProps.value);
        }
    }

    setComponentState = valueSource => {
        if (
            valueSource !== '' &&
            valueSource !== null &&
            valueSource !== undefined
        ) {
            const splitter = valueSource.split('-');
            this.setState({
                region: splitter[0],
                area: splitter[1],
                number: splitter[2],
                value: {
                    countryCode: splitter[0],
                    areaCode: splitter[1],
                    number: splitter[2],
                },
            });
        } else {
            this.setState({
                region: '',
                area: '',
                number: '',
                value: null,
            });
        }
    };

    onPartChange = (part, element, name) => {
        const inputValue = element.target.value;

        switch (part) {
            case REGION_PART:
                this.setState(
                    {
                        region:
                            (inputValue.length <= REGION_MAXLENGTH &&
                                !isNaN(Number(inputValue)) &&
                                inputValue) ||
                            this.state.region,
                        value: {
                            countryCode: inputValue,
                            areaCode: this.state.area,
                            number: this.state.number,
                        },
                    },
                    () => {
                        // notify final-form
                        this.props.onChange(this.state.value);
                    }
                );
                break;
            case AREA_PART:
                this.setState(
                    {
                        area:
                            (inputValue.length <= AREA_MAXLENGTH &&
                                !isNaN(Number(inputValue)) &&
                                inputValue) ||
                            this.state.area,
                        value: {
                            countryCode: this.state.region,
                            areaCode: inputValue,
                            number: this.state.number,
                        },
                    },
                    () => {
                        // notify final-form
                        this.props.onChange(this.state.value);
                    }
                );
                break;
            case NUMBER_PART:
                this.setState(
                    {
                        number:
                            (inputValue.length <= NUMBER_MAXLENGTH &&
                                !isNaN(Number(inputValue)) &&
                                inputValue) ||
                            this.state.number,
                        value: {
                            countryCode: this.state.region,
                            areaCode: this.state.area,
                            number: inputValue,
                        },
                    },
                    () => {
                        // notify final-form
                        this.props.onChange(this.state.value);
                    }
                );
                break;

            default:
                break;
        }
    };

    render() {
        const {
            isLabel,
            label,
            required,
            name,
            touched,
            error,
        } = this.props;
        const { region, area, number } = this.state;

        return (
            <React.Fragment>
                {isLabel && (
                    <label>
                        {label}{' '}
                        {required && (
                            <span className="required-asterix">*</span>
                        )}
                    </label>
                )}
                <div className="row-holder-full row-holder-phone">
                    <input
                        className="text-input text-input-countryarea"
                        type="text"
                        value={region}
                        name={'_region'}
                        placeholder={'country'}
                        onChange={e => this.onPartChange(REGION_PART, e, name)}
                    />
                    <div className="dash">-</div>
                    <input
                        className="text-input text-input-countryarea"
                        type="text"
                        value={area}
                        name={'_area'}
                        placeholder={'area'}
                        onChange={e => this.onPartChange(AREA_PART, e, name)}
                    />
                    <div className="dash">-</div>
                    <input
                        className="text-input text-input-phonenumber"
                        type="text"
                        value={number}
                        name={'_number'}
                        placeholder={'number'}
                        onChange={e => this.onPartChange(NUMBER_PART, e, name)}
                    />
                    {touched &&
                        (error && (
                            <div className="alert phone-alert">{error}</div>
                        ))}
                </div>
            </React.Fragment>
        );
    }
}
