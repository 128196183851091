import React, { useEffect, useState } from "react"
import { Provider } from "react-redux"
import intl from "react-intl-universal"
import Alert from "react-s-alert"
import { useIdleTimer } from "react-idle-timer"

import StoreApp from "./store"
import GlobalMalabarDialog from "./components/Dialog/GlobalMalabarDialog"
import GlobalConfirmationDialog from "./components/Dialog/GlobalConfirmationDialog"
import locales from "./translations/locales"

// Import App css (not recommended, better change it from _custom.scss)
// import 'bootstrap/dist/css/bootstrap-reboot.min.css';

import "bootstrap/dist/css/bootstrap-grid.min.css"
import "./scss/style.css"

// Styles
// Import Flag Icons Set
import "flag-icon-css/css/flag-icon.min.css"
// Import Font Awesome Icons Set
import "font-awesome/css/font-awesome.min.css"
// Import style for react-table
import "react-table/react-table.css"

// Import react-s-alert styling
import "react-s-alert/dist/s-alert-default.css"
// choose the react-s-alert effect
import "react-s-alert/dist/s-alert-css-effects/slide.css"
import "react-s-alert/dist/s-alert-css-effects/scale.css"
import "react-s-alert/dist/s-alert-css-effects/bouncyflip.css"
import "react-s-alert/dist/s-alert-css-effects/flip.css"
import "react-s-alert/dist/s-alert-css-effects/genie.css"
import "react-s-alert/dist/s-alert-css-effects/jelly.css"
import "react-s-alert/dist/s-alert-css-effects/stackslide.css"
// Import Main styles for this application
// Utils
import AppRoute from "./utils/AppRoute"

// Context API
import { AppContextProvider } from "./context/AppContext"
import { timeout } from "./helpers/SessionHelper"

const App = () => {
  const [initDone, setInitDone] = useState(false)
  const loadLocales = () => {
    // react-intl-universal singleton
    intl
      .init({
        currentLocale: localStorage.getItem("currentLanguage") || "en-US",
        locales
      })
      .then(() => {
        setInitDone(true)
      })
  }
  useEffect(() => {
    loadLocales()

    window.addEventListener("beforeunload", (ev) => {})
  }, [])
  const [remaining, setRemaining] = useState()

  const { timeout: timeoutInMinutes = 0 } =
    JSON.parse(localStorage.getItem("user")) || {}
  const { getRemainingTime } = useIdleTimer({
    timeout: timeoutInMinutes * 60 * 1000,
    throttle: 500
  })
  // 1s interval will make app slow
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     console.log("ran this again")
  //     setRemaining(Math.ceil(getRemainingTime() / 1000))
  //   }, 5000)

  //   return () => {
  //     clearInterval(interval)
  //   }
  // })
  useEffect(() => {
    if (remaining < 30 && remaining % 5 === 0 && remaining !== 0) {
      Alert.error(`Only ${remaining} seconds left in your ideal time`)
    }
    if (remaining === 0) {
      timeout()
    }
  }, [remaining])

  return (
    initDone && (
      <AppContextProvider>
        <Alert
          stack={{ limit: 3 }}
          effect="slide"
          position="top-right"
          timeout={10000}
        />
        <Provider store={StoreApp}>
          <React.Fragment>
            {/* <Layout> */}
            <AppRoute />
            <GlobalMalabarDialog />
            <GlobalConfirmationDialog />
            {/* </Layout> */}
          </React.Fragment>
        </Provider>
      </AppContextProvider>
    )
  )
}

export default App
