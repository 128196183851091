import api from '../../lib/api';

// change base route for different entity/domain here
const BASE_ROUTE = '/client/';

class CashFlowApi {
    // retrieve data
    static get(id, currency) {
        return api.get(`${BASE_ROUTE}${id}/cashflow?currency=${currency}`);
    }

    static getDetail(id, currency, detailPath) {
        return api.get(
            `${BASE_ROUTE}${id}/cashflow${detailPath}?currency=${currency}`
        );
    }

    // static add(model) {
    //     return api.post(BASE_ROUTE, model);
    // }

    static edit(id, model) {
        return api.put(`${BASE_ROUTE}${id}/cashflow`, model);
    }

    static editDetail(id, model) {
        return api.put(`${BASE_ROUTE}${id}/cashflow/detail`, model);
    }

    // static delete(model) {
    //     return api.delete(`${BASE_ROUTE}add`, model);
    // }
}

export default CashFlowApi;
