const EmptySpaceForLabels =
  "                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   "

const DefaultGridLinesOptions = {
  display: true,
  drawBorder: true,
  drawOnChartArea: false
}

const DefaultTicksOptions = {
  callback(value) {
    if (parseInt(value) >= 1000 || parseInt(value) <= -1000) {
      return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
    }
    return `${value}`
  }
}

const DefaultXAxesScalesOptions = [
  {
    gridLines: DefaultGridLinesOptions,
    ticks: {
      // Include a dollar sign in the ticks
      callback(value, index, values) {
        if (values.length > 10) return index % 10 === 0 ? value : ""
        else return value
      },
      stepSize: 10,
      autoSkip: false
    }
  }
]

const DefaultYAxesScalesOptions = [
  {
    gridLines: DefaultGridLinesOptions,
    ticks: DefaultTicksOptions
  }
]

const PieLegendsForcedLeftOptions = {
  labels: {
    generateLabels: (chart) => {
      const { data } = chart
      const dataset = data.datasets[0]
      if (dataset) {
        const meta = chart.getDatasetMeta(0)
        const metaData = meta.data
        return data.labels.map((label, index) => ({
          index,
          datasetIndex: 0,
          text: label + EmptySpaceForLabels,
          fillStyle: dataset.backgroundColor[index],
          chart,
          hidden: metaData[index].hidden
        }))
      }
      return data.labels.map((label, index) => ({
        index,
        datasetIndex: 0,
        text: label + EmptySpaceForLabels,
        fillStyle: dataset.backgroundColor[index],
        chart
      }))
    }
  },
  onClick: (e, legendItem) => {
    const ci = legendItem.chart
    const meta = ci.getDatasetMeta(0)
    const metaData = meta.data
    metaData[legendItem.index].hidden =
      metaData[legendItem.index].hidden === null
        ? true
        : !metaData[legendItem.index].hidden
    ci.update()
  }
}

const BarLegendsForcedLeftOptions = {
  labels: {
    generateLabels: (chart) => {
      const { data } = chart
      if (data.datasets.length > 0) {
        const meta = chart.getDatasetMeta(0)
        const metaData = meta.data
        return data.datasets.map((dataset, index) => ({
          index,
          datasetIndex: 0,
          text: data.labels[index] + EmptySpaceForLabels,
          fillStyle: dataset.backgroundColor,
          chart,
          hidden: metaData[index].hidden
        }))
      }
      return data.datasets.map((dataset, index) => ({
        index,
        datasetIndex: 0,
        text: data.labels[index] + EmptySpaceForLabels,
        fillStyle: dataset.backgroundColor,
        chart
      }))
    }
  },
  onClick: (e, legendItem) => {
    const ci = legendItem.chart
    const { data } = ci
    const meta = ci.getDatasetMeta(0)
    const metaData = meta.data
    metaData[legendItem.index].hidden =
      metaData[legendItem.index].hidden === null
        ? true
        : !metaData[legendItem.index].hidden
    data.datasets[legendItem.index]._meta["0"].hidden =
      metaData[legendItem.index].hidden
    ci.update()
  }
}

const DefaultLegendOptions = {
  position: "bottom",
  display: true,
  align: "start",
  fullWidth: true
}

const DefaultLineChartOptions = {
  scales: {
    xAxes: DefaultXAxesScalesOptions,
    yAxes: DefaultYAxesScalesOptions
  },
  legend: DefaultLegendOptions
}

const DefaultBarChartOptions = {
  ...DefaultLineChartOptions
}

const DefaultPieChartLegendsOptions = {
  ...DefaultLegendOptions
}

const chartCommaSeparator = (value) => {
  if (parseInt(value) >= 1000 || parseInt(value) <= -1000) {
    return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
  }
  return `${value}`
}

export {
  DefaultGridLinesOptions,
  DefaultTicksOptions,
  DefaultXAxesScalesOptions,
  DefaultYAxesScalesOptions,
  DefaultLineChartOptions,
  DefaultBarChartOptions,
  chartCommaSeparator,
  DefaultPieChartLegendsOptions,
  PieLegendsForcedLeftOptions,
  BarLegendsForcedLeftOptions,
  DefaultLegendOptions
}
