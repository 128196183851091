import React, { useState, useContext, useEffect } from 'react';

import Menu, { MenuItem } from 'rc-menu';
import Dropdown from 'rc-dropdown';

import intl from 'react-intl-universal';
import { AppContext } from '../../../context/AppContext';
import IntegratedShieldForm from './IntegratedShieldForm';
import CPFApi from '../../../api/CPFApi';



function IntegratedShieldPlan({ mutators, model = { integratedShieldPlan: [] } }) {
    const [insuranceCompanies, setInsuranceCompanies] = useState();

    useEffect(() => {
        if (!insuranceCompanies) {
            CPFApi.getInsuranceCompany().then(({ data }) => {
                if (Array.isArray(data)) {
                    const items = data.map(a => ({ label: a.name, value: a.id }));
                    setInsuranceCompanies(items);
                } else {
                    setInsuranceCompanies([]);
                }

            });
        }

        

    }, [insuranceCompanies])

    const {
        modal: { setModal, clearModal },
    } = useContext(AppContext);


    const showModal = (value) => {
        const handleSave = (model) => {
            mutators.push('integratedShieldPlans', model);
            clearModal();
        }
        setModal({
            title: 'Integrated Shield Plan Information - CPF Singapore',
            content: <IntegratedShieldForm insuranceName={value.value} handleSave={handleSave} model={{ insuranceCompany: value.value, classification: model.classification }} />,
            formType: 'CPFModal',
        });
    }

    const editPlan = (value, idx) => {
        const handleSave = (model) => {
            mutators.update('integratedShieldPlans', idx, model);
            clearModal();
        }
        setModal({
            title: 'Integrated Shield Plan Information - CPF Singapore',
            content: <IntegratedShieldForm insuranceName={value.insuranceCompany} handleSave={handleSave} model={value} />,
            formType: 'CPFModal',
        });
    }

    const deletePlan = (idx) => {
        mutators.remove('integratedShieldPlans', idx);
    }

    const generateBody = () => {
        if (model.integratedShieldPlans) {
            return model.integratedShieldPlans.map((a, idx) => (
                <tr key={idx}>
                    <td>{a.issuer}</td>
                    <td>{a.policyProductNameForTable}</td>
                    <td>{a.policyNumber}</td>
                    <td>{a.policyStartDate}</td>
                    <td>{a.premiumAmount}</td>
                    <td>{a.premiumFrequency}</td>
                    <td>{a.policyTypeName}</td>
                    <td>
                        <button
                            type="button"
                            onClick={() => editPlan(a, idx)}
                            className="btn btn-edit btn-padding"
                        />
                        <button type="button"
                            onClick={() => deletePlan(a, idx)}
                            className="btn-remove"></button>
                    </td>
                    <td>
                    </td>
                </tr>
            ));
        }
    }


    return (
        <table className="table-investment mt-2" width="100%" cellSpacing="0" cellPadding="0" border="0">
            <thead>
                <tr>
                    <th>
                        {intl.get('centralProvidenceFundInformation.insuranceIssuer')}
                    </th>
                    <th>
                        {intl.get('centralProvidenceFundInformation.productName')}
                    </th>
                    <th>
                        {intl.get('centralProvidenceFundInformation.policyNumber')}
                    </th>
                    <th>
                        {intl.get('centralProvidenceFundInformation.startDate')}
                    </th>
                    <th>
                        {intl.get('centralProvidenceFundInformation.premiumAmount')}
                    </th>
                    <th>
                        {intl.get('centralProvidenceFundInformation.premiumFrequency')}
                    </th>
                    <th>
                        {intl.get('centralProvidenceFundInformation.policyTypes')}
                    </th>
                    <th colSpan="2">
                        {Array.isArray(insuranceCompanies) && (
                            <Dropdown
                                trigger={['click']}
                                overlay={
                                    <Menu
                                        onSelect={menu => showModal(insuranceCompanies[menu.key])}
                                    >
                                        {insuranceCompanies.map((menu, idx) => (
                                            <MenuItem key={idx}>
                                                {menu.label}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                }
                                animation="slide-up"
                            >
                                <span className="btn btn-plus" />
                            </Dropdown>
                        )}

                    </th>
                </tr>
            </thead>
            <tbody>
                {generateBody()}
            </tbody>
        </table>
    );
}

export default IntegratedShieldPlan;