import React, { useEffect, useState } from "react"
import ReactModal from "react-modal"
import { Field, Form } from "react-final-form"
import arrayMutators from "final-form-arrays"
import { FieldArray } from "react-final-form-arrays"
import Alert from "react-s-alert"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import intl from "react-intl-universal"
import { required } from "../../helpers"
import Textbox from "../Textbox/Textbox"
import InputFile from "../InputFile/InputFile"
import TreeMultiSelect from "../TreeMultiSelect/TreeMultiSelect"
import { EditorState, convertToRaw, ContentState } from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import EmailApi from "../../api/Email/EmailApi"
import { maxPhotoSize } from "../../helpers/Validator"
import Api from "../../lib/api"
import { Editor } from "react-draft-wysiwyg"
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { entityActions } from "../../actions/EntityActions"
import { AppContext } from "../../context/AppContext"

function Email({ userId, user, clearModal }) {
  const [isOpentemplateNameDialog, setTemplateNameDialog] = useState(false)
  const [isLoadtemplateDialog, setLoadtemplateDialog] = useState(false)
  const [templateName, setTemplateName] = useState("")
  const [templateList, setTemplateList] = useState([])
  const [showEditorError, setShowEditorError] = useState(false)
  const [templateId, setTemplateId] = useState(null)
  const [templateNameError, setTemplateNameError] = useState(false)

  function deleteTemplateByKey(deletedKey) {
    if (templateList) {
      for (let index = 0; index < templateList.length; index++) {
        let t = templateList[index]
        if (t.key == deletedKey) {
          Api.delete(`/email-template/delete-template/${deletedKey}`).then(
            ({ data }) => {
              Api.get(`/email-template/adviser-template-names`).then(
                ({ data }) => {
                  if (data) {
                    setTemplateList(data.list)
                  }
                }
              )
            }
          )
        }
      }
    }
  }
  const submit = (model) => {
    // temporary alert message
    if (draftToHtml(convertToRaw(editorState.getCurrentContent())).length < 9) {
      alert("Enter message")
      return
    }
    const formData = new FormData()
    // if (Array.isArray(model.recipients)) {
    //     if (
    //         model.secondaryRecipients &&
    //         model.secondaryRecipients.trim().length > 0
    //     ) {
    //         // eslint-disable-next-line no-shadow
    //         const recipients = model.recipients.map(e => e.email).join(',');
    //         const secondaryRecipients = model.secondaryRecipients
    //             .split(',')
    //             .map(a => a.trim())
    //             .join(',');
    //         formData.append('bcc', `${recipients},${secondaryRecipients}`);
    //     } else if (model.recipients.length > 1) {
    //         formData.append(
    //             'bcc',
    //             model.recipients.map(e => e.email).join(',')
    //         );
    //     } else if (model.recipients.length === 1) {
    //         formData.append('recipients', model.recipients[0].email);
    //     }
    // }

    // get logged in email address
    let logged_in_user = JSON.parse(localStorage.getItem("user"))

    formData.append("recipients", model.recipients[0].email)
    if (model.secondaryRecipients) {
      formData.append("cc", model.secondaryRecipients)
    }
    // formData.append('cc', model.secondaryRecipients?model.secondaryRecipients:'');
    formData.append("bcc", logged_in_user.loginId)
    formData.append("subject", model.subject)

    // convert data and properly send to server
    formData.append(
      "body",
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    )
    if (Array.isArray(model.attachments)) {
      model.attachments.forEach((a, i) => {
        formData.append(`attachments[${i}]`, a)
      })
    }

    return Api.post(`/email/send`, formData, {
      headers: {
        "Content-Type": null
      }
    })
  }
  function onNodeToggle(currentNode) {
    EmailApi.recipients(userId).then((response) => {
      setRecipients(
        response.data.clients.map((client) => ({
          label: `${client.lastName}, ${client.firstName} (${client.emailAddress})`,
          email: `${client.emailAddress}`
        }))
      )
    })
  }

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [recipients, setRecipients] = useState({})
  const [defaultValue, setDefaultValue] = useState([])

  const onAttachmentUpload = (e, mutators) => {
    if (!maxPhotoSize(e.target.files[0]))
      mutators.push("attachments", e.target.files[0])
  }

  const assignObjectPaths = (obj, stack) => {
    Object.keys(obj).forEach((k) => {
      const node = obj[k]
      if (typeof node === "object") {
        node.path = stack ? `${stack}.${k}` : k
        node.checked = true
        assignObjectPaths(node, node.path)
      }
    })
  }

  const fetchTemplateList = async () => {
    try {
      const getting_template_details = await Api.get(
        `/email-template/adviser-template-names`
      )
      if (getting_template_details.data.list.length > 0) {
        setTemplateList(getting_template_details.data.list)
      }
    } catch (error) {
      alert("Problem. Try again later.")
    }
  }

  const loadTemplates = () => {
    setLoadtemplateDialog(true)
    fetchTemplateList()
  }
  const emailTemplateDto = {
    name: templateName,
    content: draftToHtml(convertToRaw(editorState.getCurrentContent()))
  }

  const saveEmailTemplate = () => {
    if (draftToHtml(convertToRaw(editorState.getCurrentContent())).length < 9) {
      setShowEditorError(true)
      return
    } else {
      setTemplateNameDialog(true)
    }
  }

  const storeTemplate = async () => {
    try {
      if (!templateName) {
        setTemplateNameError(true)
        return
      }
      const saving_template_details = await Api.post(
        `/email-template/save-template`,
        emailTemplateDto
      )
      setTemplateName("")
      setTemplateNameError(false)
      setTemplateNameDialog(false)
    } catch (error) {
      console.log("saving email template error", error)
    }
  }

  useEffect(() => {
    async function fetchTemplate() {
      try {
        const templateDetail = await Api.get(
          `/email-template/get-template?id=${templateId}`
        )
        const templateContent = templateDetail.data.content
        if (templateContent) {
          setLoadtemplateDialog(false)
          console.log("template content is", htmlToDraft(templateContent))
        }
        const contentBlock = htmlToDraft(templateContent)
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          )
          const editorState = EditorState.createWithContent(contentState)
          setEditorState(editorState)
        }
      } catch (error) {
        console.log("error is", error)
      }
    }
    if (templateId) {
      fetchTemplate()
    }
  }, [templateId])

  const handleTemplateNameChange = (event) => {
    setTemplateName(event.target.value)
  }

  const closeLoadTemplate = () => {
    setLoadtemplateDialog(false)
  }

  const closeSaveTemplate = () => {
    setTemplateName("")
    setTemplateNameError(false)
    setTemplateNameDialog(false)
  }

  useEffect(() => {
    EmailApi.recipients(userId).then((response) => {
      const x = response.data.clients.map((client) => ({
        label: `${client.lastName}, ${client.firstName} (${
          client.emailAddress || "No Preferred Email"
        })`,
        email: `${client.emailAddress}`
      }))
      if (x.length === 1) {
        const a = x
        assignObjectPaths(a)
        setDefaultValue(a)
      }
      setRecipients(x)
    })
  }, [userId])

  const handleEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState)

    // check if the email editor is empty
    if (
      newEditorState.getCurrentContent().getBlockMap().size === 1 &&
      newEditorState.getCurrentContent().getFirstBlock().getText().length === 0
    ) {
      setShowEditorError(true)
    } else {
      setShowEditorError(false)
    }
  }

  return (
    <>
      <Form
        onSubmit={(model) => submit(model)}
        mutators={{
          ...arrayMutators
        }}
        initialValues={{ recipients: defaultValue }}
        render={({ handleSubmit, reset, form: { mutators } }) => (
          <div className="notification-dialog">
            <form
              id="emailForm"
              onSubmit={(event) => {
                const promise = handleSubmit(event)
                if (promise) {
                  promise.then((response) => {
                    if (response && response.status === 200) {
                      console.log("email response is", response)
                      Alert.success(intl.get("email.successMessage"))
                      reset()
                      clearModal()
                    }
                  })
                }
              }}
            >
              <div className="row m-2">
                <div className="col">
                  <label>To*:</label>
                  <Field
                    name="recipients"
                    type="text"
                    data={recipients}
                    component={TreeMultiSelect}
                    onNodeToggle={onNodeToggle}
                    mutators={mutators}
                    validate={required}
                  />
                </div>
              </div>
              <div className="row m-2">
                <div className="col">
                  <label>Cc:</label>
                  <Field
                    name="secondaryRecipients"
                    type="text"
                    component={Textbox}
                    placeholder="Additional Recipient"
                  />
                </div>
              </div>
              <div className="row m-2">
                <div className="col">
                  <label>
                    {intl.get("email.subject")}{" "}
                    <span className="required-asterix">*</span>
                    <Field
                      name="subject"
                      type="text"
                      component={Textbox}
                      validate={required}
                      placeholder="Subject"
                    />
                  </label>
                </div>
              </div>
              <div className="d-flex flex-column itemvalidate bad">
                <div className="row m-2">
                  <div className="col">
                    <label>
                      {intl.get("email.message")}{" "}
                      <span className="required-asterix">*</span>
                      &nbsp;&nbsp;
                      <div style={{ display: "inline-block" }}>
                        <button
                          className="portal-btn portal-btn-submit fa fa-save"
                          onClick={saveEmailTemplate}
                          type="button"
                          disabled={!editorState.getCurrentContent().hasText()}
                        >
                          &nbsp;save as template
                        </button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <button
                          className="portal-btn portal-btn-submit fa fa-download"
                          // className='btn-greenary child-1'
                          type="button"
                          onClick={loadTemplates}
                        >
                          &nbsp;load from template
                        </button>
                      </div>
                    </label>
                    <Editor
                      editorState={editorState}
                      toolbarClassName="demo-toolbar-custom demo-toolbar-custom1 for-border-box mb-0"
                      wrapperClassName="demo-wrapper for-margin-top"
                      placeholder="Message"
                      editorStyle={{
                        height: "120px",
                        overflow: "auto"
                      }}
                      editorClassName="demo-editor"
                      onEditorStateChange={handleEditorStateChange}
                      toolbar={{
                        options: [
                          "inline",
                          "fontSize",
                          "textAlign",
                          "list",
                          "colorPicker",
                          "remove",
                          "history"
                        ],
                        inline: {
                          options: [
                            "bold",
                            "italic",
                            "underline",
                            "strikethrough"
                          ]
                        }
                      }}
                    />
                    {showEditorError && (
                      <span className="alert">
                        {intl.get("validator.required")}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="row m-2">
                <div className="col">
                  <label>{intl.get("email.attachments")} </label>
                  <Field
                    name="test"
                    type="text"
                    component={InputFile}
                    onChange={(e) => onAttachmentUpload(e, mutators)}
                    validate={maxPhotoSize}
                  />
                </div>
              </div>

              <div className="row m-2">
                <div className="col">
                  <FieldArray name="attachments">
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <div
                          className="d-flex align-items-center justify-content-space-between"
                          key={index}
                        >
                          <Field name={name}>
                            {(props) => <label>{props.input.value.name}</label>}
                          </Field>
                          <button
                            type="button"
                            className="btn-remove"
                            onClick={() => fields.remove(index)}
                          />
                        </div>
                      ))
                    }
                  </FieldArray>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col d-flex justify-content-end ">
                  <button
                    className="portal-btn portal-btn-submit "
                    type="submit"
                  >
                    {intl.get("button.send")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      />
      <ReactModal
        isOpen={isOpentemplateNameDialog}
        overlayClassName="malabar-modal-overlay"
        className="malabar-modal template-modal"
      >
        <div className="popup popup-template-modal">
          <div className="container-fluid">
            <div className="container">
              <div className="page-title">
                {"Template Name"}
                <div className="close-holder">
                  <span className="close-button" onClick={closeSaveTemplate} />
                </div>
              </div>
              <div
                className={`d-flex flex-column ${
                  templateNameError ? "itemvalidate bad" : ""
                }`}
              >
                <div className="portal-form-popup">
                  <input
                    type="text"
                    className="template-name-textbox"
                    placeholder="Template name"
                    onChange={handleTemplateNameChange}
                  />
                  {templateNameError && (
                    <span className="alert">
                      {intl.get("validator.required")}
                    </span>
                  )}
                </div>
              </div>
              <div className="portal-btn-popup center-save-template-button">
                <input
                  className="portal-btn portal-btn-submit"
                  value="OK"
                  type="submit"
                  onClick={storeTemplate}
                />
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={isLoadtemplateDialog}
        overlayClassName="malabar-modal-overlay"
        className="malabar-modal template-modal"
      >
        <div className="popup popup-template-modal">
          <div className="container-fluid">
            <div className="container">
              <div className="page-title">
                {"Templates"}
                <div className="close-holder">
                  <span className="close-button" onClick={closeLoadTemplate} />
                </div>
              </div>
              <table
                className="loan-table"
                style={{ margin: "10px", width: "96%" }}
              >
                <thead>
                  <tr>
                    <th> Template Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {templateList.length ? (
                    templateList.map((template, key) => (
                      <tr key={key} className="trow">
                        <td>{template.name}</td>
                        <td className="action-templates">
                          <span
                            onClick={() => {
                              setTemplateId(template.key)
                              setLoadtemplateDialog(false)
                            }}
                          >
                            Use
                          </span>
                          <span
                            onClick={() => {
                              deleteTemplateByKey(template.key)
                            }}
                          >
                            Delete
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <div className="warn-msg">No Templates created</div>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ReactModal>
    </>
  )
}

function mapStateToProps(state) {
  const { user } = state.authentication

  return {
    user
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, entityActions), dispatch)
}

Email.contextType = AppContext

export default connect(mapStateToProps, mapDispatchToProps)(Email)
