import React, { useState, useEffect } from "react";
import { Field, Form } from "react-final-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { enumOptionsActions } from "../../../actions";
import { FormButtons, RenderSimpleFields } from "../../../components";
import { FieldType } from "../../../components/Form/RenderSimpleFields";
import { FieldArray } from 'react-final-form-arrays';
import { renderCurrencyCustom, renderSelectEnumFieldCustom } from "../../../helpers";
import arrayMutators from 'final-form-arrays';
import TextboxInput from "../../../components/Textbox/TextboxInput";
import { renderCheckboxOnlyCustom } from "../../../helpers/FormRenderBootstrap";
import CPFApi from "../../../api/CPFApi";
import { lowerCase } from "lodash";
import CPFClassification from "./CPFClassification";

const IntegratedShieldForm = ({ insuranceName, optionsData, model = {}, handleSave }) => {

    const [policyProducts, setPolicyProducts] = useState([]);
    const [policyTypes, setPolicyTypes] = useState([]);

    const productOnChange = (value) => {
        CPFApi.getPolicyTypes(value).then(({ data }) => {
            const a = data.map(a => ({ label: a.name, value: a.id }));
            setPolicyTypes(a);
        });
    }
    useEffect(() => {
        CPFApi.getProducts(insuranceName).then(({ data }) => {
            const a = data.map(a => ({ label: a.name, value: a.id }));
            setPolicyProducts(a);
        });
        if (model.policyProductName) {
            productOnChange(model.policyProductName);
        }

    }, [insuranceName]);


    const mapModel = (model) => {
        if (policyProducts.length > 0 && model.policyProductName) {
            var name = policyProducts.find(a => a.value === model.policyProductName);
            Object.assign(model, { policyProductNameForTable: name.label });
            if (model.policyType && policyTypes.length > 0) {
                var policyType = policyTypes.find(a => a.value === model.policyType);
                if (lowerCase(policyType.label) === 'other' || lowerCase(policyType.label) === 'others') {
                    Object.assign(model, { policyTypeName: model.otherPolicyType });
                } else {
                    Object.assign(model, { policyTypeName: policyType.label });
                }
            }
            return model;
        }
        Object.assign(model, { policyProductNameForTable: model.policyProductName, policyTypeName: model.policyType });
        return model;
    }

    const save = (model) => {
        handleSave(mapModel(model));
    }

    const renderForm = (value) => {
        if (policyProducts.length > 0) {
            if (value.policyType) {
                var name = policyTypes.find(a => a.value === value.policyType);
                if (name && (lowerCase(name.label) === 'other' || lowerCase(name.label) === 'others')) {
                    return (
                        <RenderSimpleFields
                            fields={[
                                {
                                    name: 'issuer',
                                    label: 'Issuer',
                                    type: FieldType.TEXT,
                                },
                                {
                                    name: 'policyProductName',
                                    label: 'Policy Product Name',
                                    type: policyProducts.length > 0 ? FieldType.SELECTION : FieldType.TEXT,
                                    selectionData: policyProducts,
                                    onChange: productOnChange
                                },
                                {
                                    name: 'sourceOfFund',
                                    label: 'Source of Fund',
                                    type: FieldType.TEXT,
                                },
                                {
                                    name: 'policyType',
                                    label: 'Policy Type',
                                    type: FieldType.SELECTION,
                                    selectionData: policyTypes
                                },
                                {
                                    name: 'otherPolicyType',
                                    label: 'Other Policy Type',
                                    type: FieldType.TEXT,
                                },
                                {
                                    name: 'policyNumber',
                                    label: 'Policy Number',
                                    type: FieldType.TEXT,
                                },
                                {
                                    name: 'policyTerm',
                                    label: 'Policy Term',
                                    type: FieldType.NUMBER,
                                },
                                {
                                    name: 'policyStartDate',
                                    label: 'Policy Start Date',
                                    type: FieldType.DATE,
                                },
                                {
                                    name: 'premiumAmount',
                                    label: 'Premium Amount',
                                    type: FieldType.MONEY,
                                },
                                {
                                    name: 'premiumFrequency',
                                    label: 'Premium Frequency',
                                    type: FieldType.ENUM_SELECTION,
                                    enumData: optionsData.premiumFrequencyOptions,
                                },
                                {
                                    name: 'loadingPercent',
                                    label: 'Loading Percentage',
                                    type: FieldType.PERCENTAGE,
                                },

                            ]}
                        />
                    )
                }

            }
            return (
                <RenderSimpleFields
                    fields={[
                        {
                            name: 'issuer',
                            label: 'Issuer',
                            type: FieldType.TEXT,
                        },
                        {
                            name: 'policyProductName',
                            label: 'Policy Product Name',
                            type: policyProducts.length > 0 ? FieldType.SELECTION : FieldType.TEXT,
                            selectionData: policyProducts,
                            onChange: productOnChange
                        },
                        {
                            name: 'sourceOfFund',
                            label: 'Source of Fund',
                            type: FieldType.TEXT,
                        },
                        {
                            name: 'policyType',
                            label: 'Policy Type',
                            type: FieldType.SELECTION,
                            selectionData: policyTypes
                        },
                        {
                            name: 'policyNumber',
                            label: 'Policy Number',
                            type: FieldType.TEXT,
                        },
                        {
                            name: 'policyTerm',
                            label: 'Policy Term',
                            type: FieldType.NUMBER,
                        },
                        {
                            name: 'policyStartDate',
                            label: 'Policy Start Date',
                            type: FieldType.DATE,
                        },
                        {
                            name: 'premiumAmount',
                            label: 'Premium Amount',
                            type: FieldType.MONEY,
                        },
                        {
                            name: 'premiumFrequency',
                            label: 'Premium Frequency',
                            type: FieldType.ENUM_SELECTION,
                            enumData: optionsData.premiumFrequencyOptions,
                        },
                        {
                            name: 'loadingPercent',
                            label: 'Loading Percentage',
                            type: FieldType.PERCENTAGE,
                        },

                    ]}
                />
            )
        } else {

            return (
                <RenderSimpleFields
                    fields={[
                        {
                            name: 'issuer',
                            label: 'Issuer',
                            type: FieldType.TEXT,
                        },
                        {
                            name: 'policyProductName',
                            label: 'Policy Product Name',
                            type: FieldType.TEXT,
                        },
                        {
                            name: 'sourceOfFund',
                            label: 'Source of Fund',
                            type: FieldType.TEXT,
                        },
                        {
                            name: 'policyType',
                            label: 'Policy Type',
                            type: FieldType.TEXT,
                        },
                        {
                            name: 'policyNumber',
                            label: 'Policy Number',
                            type: FieldType.TEXT,
                        },
                        {
                            name: 'policyTerm',
                            label: 'Policy Term',
                            type: FieldType.NUMBER,
                        },
                        {
                            name: 'policyStartDate',
                            label: 'Policy Start Date',
                            type: FieldType.DATE,
                        },
                        {
                            name: 'premiumAmount',
                            label: 'Premium Amount',
                            type: FieldType.MONEY,
                        },
                        {
                            name: 'premiumFrequency',
                            label: 'Premium Frequency',
                            type: FieldType.ENUM_SELECTION,
                            enumData: optionsData.premiumFrequencyOptions,
                        },
                        {
                            name: 'loadingPercent',
                            label: 'Loading Percentage',
                            type: FieldType.PERCENTAGE,
                        },

                    ]}
                />
            )
        }
    }

    return (
        <div>
            <Form
                onSubmit={save}
                initialValues={model}
                mutators={{
                    // potentially other mutators could be merged here
                    ...arrayMutators,
                }}
                initialValuesEqual={() => true}
                render={({
                    handleSubmit,
                    form: {
                        mutators: { push, remove },
                    },
                    reset,
                    values
                }) => (
                        <form
                            id="healtInsuranceDetails"
                            onSubmit={handleSubmit}
                            noValidate
                        >
                            <div className="portal-form-popup">
                                <CPFClassification value={values.classification} disabled />
                                <div className="subtitle2">
                                    Health Insurance Details
                                </div>
                                {renderForm(values)}
                                <div className="portal-form-row">
                                    <label className="d-flex align-items-center">Exclusion
                                    <button
                                            type="button"
                                            className="btn btn-plus ml-2"
                                            onClick={() =>
                                                push(
                                                    'exclusionAmount',
                                                    ''
                                                )
                                            }
                                        >
                                            <span />
                                        </button>
                                    </label>

                                    <div className="d-flex flex-column row-holder">
                                        <FieldArray
                                            name="exclusionAmount"
                                            render={({ fields }) => (
                                                fields.map((name, index) => (
                                                    <div key={index} className="d-flex">
                                                        <div className="textfield-area mr-2">
                                                            <Field
                                                                required
                                                                type="text"
                                                                component={
                                                                    TextboxInput
                                                                }
                                                                name={`${name}.name`}
                                                            />
                                                        </div>
                                                        <div className="delete-area">
                                                            <button
                                                                onClick={() => remove('exclusionAmount', index)}
                                                                className="btn-remove"
                                                            />
                                                        </div>


                                                    </div>
                                                ))
                                            )}
                                        />
                                    </div>
                                </div>

                                <table className="cpf-summary-table mt-3">
                                    <thead>
                                        <tr>
                                            <th>
                                                Riders
                                                <button
                                                    type="button"
                                                    className="btn btn-plus ml-2"
                                                    onClick={() =>
                                                        push(
                                                            'integratedShieldRiders',
                                                            {
                                                                riderName: '',
                                                                payableByMedisave: false,
                                                                premiumAmount: '',
                                                                premiumFrequency: ''
                                                            }
                                                        )
                                                    }
                                                >
                                                    <span />
                                                </button>
                                            </th>
                                            <th>
                                                Payable
                                                By MediSave

                                            </th>
                                            <th>
                                                Premium
                                                Amount
                                            </th>
                                            <th>
                                                Premium
                                                Frequency
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <FieldArray
                                            name="integratedShieldRiders"
                                            render={({ fields }) => (
                                                fields.map((name, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <Field
                                                                key={index}
                                                                required
                                                                type="text"
                                                                component={
                                                                    TextboxInput
                                                                }
                                                                name={`${name}.riderName`}
                                                            />
                                                        </td>
                                                        <td className="text-center input-check">
                                                            <Field
                                                                key={index}
                                                                required
                                                                type="text"
                                                                component={
                                                                    renderCheckboxOnlyCustom
                                                                }
                                                                name={`${name}.payableByMedisave`}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Field
                                                                key={index}
                                                                required
                                                                type="text"
                                                                component={
                                                                    renderCurrencyCustom
                                                                }
                                                                name={`${name}.premiumAmount`}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Field
                                                                key={index}
                                                                required
                                                                type="text"
                                                                component={
                                                                    renderSelectEnumFieldCustom
                                                                }
                                                                name={`${name}.premiumFrequency`}
                                                                enumData={optionsData.premiumFrequencyOptions}
                                                            />
                                                        </td>
                                                        <td>
                                                            <button
                                                                onClick={() => remove('integratedShieldRiders', index)}
                                                                className="btn-remove"
                                                            />
                                                        </td>
                                                    </tr>

                                                ))
                                            )}
                                        />
                                    </tbody>

                                </table>

                            </div>
                            <FormButtons
                                reset={reset}
                            />
                        </form>
                    )}
            />
        </div>


    )
}


function mapStateToProps(state) {
    const { optionsData } = state.enumOptions;

    return {
        optionsData,
    };
}

function mapDispatchToProps(dispatch) {

    return bindActionCreators(enumOptionsActions, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IntegratedShieldForm);