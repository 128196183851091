import React, { PureComponent } from "react"
import intl from "react-intl-universal"
import { EmailTypeEnum } from "../../constants/Enums"

const EMAILTYPE_PART = "EMAILTYPE"
const EMAILPART = "EMAIL"

export default class MalabarEmail extends PureComponent {
  state = {
    emailType: EmailTypeEnum.EmailType_PRIMARY,
    email: "",
    preferred: this.props.preferred || false,
    value: this.props.value,
    emailTypeOptions: "",
    invalidFormatError: ""
  }

  componentWillMount() {
    this.setComponentState(this.props.value)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setComponentState(nextProps.value)
    }
  }

  setComponentState = (valueSource) => {
    // get email type options list
    const emailOptions = this.getEmailTypeOptions()
    this.setState(
      {
        emailTypeOptions: emailOptions
      },
      () => {
        if (
          valueSource !== "" &&
          valueSource !== null &&
          valueSource !== undefined
        ) {
          this.setState({
            emailType: valueSource.emailType,
            email: valueSource.email,
            value: valueSource
          })
        } else {
          this.setState({
            emailType: EmailTypeEnum.EmailType_PRIMARY,
            email: "",
            value: null
          })
        }
      }
    )
  }

  getEmailTypeOptions = () => {
    const emailTypeStr = []
    Object.keys(EmailTypeEnum).forEach((key) => {
      emailTypeStr.push(
        <option key={EmailTypeEnum[key]} value={EmailTypeEnum[key]}>
          {intl.get(key)}
        </option>
      )
    })
    return emailTypeStr
  }

  onBlurEmail(e) {
    const inputValue = e.target.value
    if (inputValue && inputValue.length > 0) {
      //anton: email format refer to RFC-5322 standard (Same as backend)
      let regexTest =
        /^((?:[A-Za-z0-9!#$%&'*+\-\/=?^_`{|}~]|(?<=^|\.)"|"(?=$|\.|@)|(?<=".*)[ .](?=.*")|(?<!\.)\.){1,64})(@)((?:[A-Za-z0-9.\-])*(?:[A-Za-z0-9])\.(?:[A-Za-z0-9]){2,})$/gm.test(
          inputValue
        )
      if (!regexTest) {
        let errMsg = '"' + inputValue + '" invalid email format'
        this.setState({ invalidFormatError: errMsg })
      } else {
        if (this.state.invalidFormatError.length > 0) {
          this.setState({ invalidFormatError: "" })
        }
      }
    }
  }

  onPartChange = (part, element, name) => {
    const inputValue = element.target.value
    this.setState({ invalidFormatError: "" })

    switch (part) {
      case EMAILTYPE_PART:
        this.setState(
          {
            emailType: inputValue,
            value: {
              emailType: inputValue,
              email: this.state.email,
              preferred: this.state.preferred
            }
          },
          () => {
            // notify final-form
            this.props.onChange(this.state.value)
          }
        )
        break
      case EMAILPART:
        this.setState(
          {
            email: inputValue,
            value: {
              emailType: this.state.emailType,
              email: inputValue,
              preferred: this.state.preferred
            }
          },
          () => {
            // notify final-form
            this.props.onChange(this.state.value)
          }
        )
        break

      default:
        break
    }
  }

  render() {
    const {
      placeholder,
      label,
      required,
      name,
      touched,
      error,
      preferred,
      onRemoveDataClick
    } = this.props
    const { emailType, email } = this.state

    return (
      <React.Fragment>
        {/* <label>
                    {label}{' '}
                    {required && <span className="required-asterix">*</span>}
                    
                </label> */}

        <div className="row-holder-full">
          <div className="select-box">
            <select
              name={"_emailType"}
              value={emailType}
              onChange={(e) => this.onPartChange(EMAILTYPE_PART, e, name)}
              required
            >
              {this.state.emailTypeOptions}
            </select>
          </div>
          <input
            className="text-input text-input-email"
            type="text"
            value={email}
            name={"_email"}
            placeholder={placeholder}
            onBlur={(e) => this.onBlurEmail(e)}
            onChange={(e) => this.onPartChange(EMAILPART, e, name)}
          />

          {!preferred && (
            <span onClick={onRemoveDataClick} className="btn-remove" />
          )}
          {touched && error && <div className="alert email-alert">{error}</div>}

          {this.state.invalidFormatError.length > 0 && (
            <span style={{ color: "red" }}>
              {this.state.invalidFormatError}
            </span>
          )}
        </div>
      </React.Fragment>
    )
  }
}
