import React from 'react';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';
import './TreeMultiSelect.css';

function TreeMultiSelect({
    meta: { touched, error },
    data = [],
    input,
    mutators,
}) {
    const assignObjectPaths = (obj, stack) => {
        Object.keys(obj).forEach(k => {
            const node = obj[k];
            if (typeof node === 'object') {
                node.path = stack ? `${stack}.${k}` : k;
                const found = Array.isArray(input.value)
                    ? input.value.find(a => a.path === node.path) !== undefined
                    : false;
                node.checked = found;
                assignObjectPaths(node, node.path);
            }
        });
    };

    const onRecipientChange = (currentNode, selectedNodes) => {
        mutators.shift(input.name);
        mutators.concat(input.name, selectedNodes);
    };

    assignObjectPaths(data);

    return (
        <div
            className={`d-flex flex-column  ${
                touched && error ? 'itemvalidate bad' : ''
            }`}
        >
            <DropdownTreeSelect
                showChooseOption={false}
                data={data}
                className="bootstrap-demo"
                mode="multiSelect"
                // showPartiallySelected
                // showDropdown="initial"
                placeholder="Recieptent"
                onChange={onRecipientChange}
            />

            {touched && (error && <span className="alert">{error}</span>)}
        </div>
    );
}

export default TreeMultiSelect; 
