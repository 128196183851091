import { CountryList } from '../constants/Lists';
import { translateListLabel } from './list-translation';

const countryCurrency = [
    { currency: 'AUD', country: 'Australia' },
    { currency: 'HKD', country: 'Hong Kong' },
    { currency: 'NZD', country: 'New Zealand' },
    { currency: 'SGD', country: 'Singapore' },
    { currency: 'MYR', country: 'Malaysia' },
    { currency: 'GBP', country: 'United Kingdom' },
    { currency: 'USD', country: 'United States of America' },
];

export function getInAppCountryCurrency(country = '') {
    if (isCountryUsingEuro(country)) {
        return 'EUR';
    }

    return (
        countryCurrency.find(
            item => item.country.toLowerCase() === country.toLowerCase()
        ) || {}
    ).currency;
}

function isCountryUsingEuro(country) {
    const euro = (
        translateListLabel(CountryList).find(
            item => item.name.toLowerCase() === country.toLowerCase()
        ) || {}
    ).abbreviation;

    return euro === 'EUR';
}
