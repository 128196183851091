/*
    Author : Gredy (gredy.prasetya@devstack.com.au)
    Library : ScenarioApi
    Description : methods related with api part
*/

import api from "../lib/api"

// change base route for different entity/domain here

class ScenarioApi {
  static getScenarios(id) {
    return api.get(`/lifestyle/${id}/scenario`)
  }

  static deleteScenario(id) {
    return api.delete(`/lifestyle/scenario/${id}`)
  }
  static createScenario(data) {
    return api.post(`/lifestyle/scenario`, data)
  }
}

export default ScenarioApi
