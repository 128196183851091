import BondForm from "./BondForm"
import BusinessOwnershipForm from "./BusinessOwnershipForm"
import CashAccountForm from "./CashAccountForm"
import CollectibleAntiqueArtsForm from "./CollectibleAntiqueArtsForm"
import InvestmentPropertyForm from "./InvestmentPropertyForm"
import MotorVehicleForm from "./MotorVehicleForm"
import PersonalContentForm from "./PersonalContentForm"
import ShareForm from "./ShareForm"
import TermDepositForm from "./TermDepositForm"
import UnitTrustForm from "./UnitTrustForm"
import ResidencePropertyForm from "./ResidencePropertyForm"
import GEALAustraliaForm from "./GEALAustraliaForm"
import GEALHongkongForm from "./GEALHongkongForm"
import GEALNewZealandForm from "./GEALNewZealandForm"
import GEALSingaporeForm from "./GEALSingaporeForm"
import GEALMalaysiaForm from "./GEALMalaysiaForm"
import PersonalTaxForm from "./PersonalTaxForm"
import InsuranceDetails from "./sub-forms/InsuranceDetails"
import PropertyInsuranceDetails from "./sub-forms/PropertyInsuranceDetails"
import MotorVehicleMainForm from "./MotorVehicleMainForm"
import CollectibleAntiqueArtsMainForm from "./CollectibleAntiqueArtsMainForm"
import PersonalContentMainForm from "./PersonalContentMainForm"
import InvestmentPropertyMainForm from "./InvestmentPropertyMainForm"
import ResidencePropertyMainForm from "./ResidencePropertyMainForm"
import InvestmentPlatformForm from "./InvestmentPlatformForm"
import CreditCardForm from "./CreditCardForm"
import ShareExpensesForm from "./ShareExpensesForm"
import UnitTrustExpensesForm from "./UnitTrustExpensesForm"
import BondExpensesForm from "./BondExpensesForm"
import CollectibleAntiqueArtsEpensesForm from "./CollectibleAntiqueArtsEpensesForm"
import CashAccountPersonalIncome from "./CashAccountPersonalIncome"
import InvestmentFundPlatform from "./InvestmentFundPlatform"
import EPFForm from "../pages/NetWorth/Retirements/Malaysia/EPFForm"
import PRSForm from "../pages/NetWorth/Retirements/Malaysia/PRSForm"

export {
  CashAccountForm,
  BondForm,
  BusinessOwnershipForm,
  CollectibleAntiqueArtsForm,
  InvestmentPropertyForm,
  MotorVehicleForm,
  PersonalContentForm,
  ShareForm,
  TermDepositForm,
  UnitTrustForm,
  ResidencePropertyForm,
  GEALAustraliaForm,
  GEALHongkongForm,
  GEALNewZealandForm,
  GEALSingaporeForm,
  GEALMalaysiaForm,
  PersonalTaxForm,
  InsuranceDetails,
  PropertyInsuranceDetails,
  MotorVehicleMainForm,
  CollectibleAntiqueArtsMainForm,
  PersonalContentMainForm,
  InvestmentPropertyMainForm,
  ResidencePropertyMainForm,
  InvestmentPlatformForm,
  CreditCardForm,
  ShareExpensesForm,
  UnitTrustExpensesForm,
  BondExpensesForm,
  CollectibleAntiqueArtsEpensesForm,
  CashAccountPersonalIncome,
  InvestmentFundPlatform,
  EPFForm,
  PRSForm
}
