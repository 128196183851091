import React, { useState, useEffect } from "react"


import TipsApi from "../api/TipsApi"
import MediaApi from "../api/MediaApi"
import { Editor } from "react-draft-wysiwyg"
import htmlToDraft from "html-to-draftjs"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, ContentState } from "draft-js"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
function TipsDialog({ tipId }) {
  const [tips, setTips] = useState([])
  const [mediaList, setMediaList] = useState([])
  const [selectedTipId, setSelectedTipId] = useState(tipId ? tipId : "")
  const [selectedMediaFile, setSelectedMediaFile] = useState("")
  const baseRoute = localStorage.getItem('base-route'); // base route for multiple region

  const displayWidth = 800;
  const displayheight = 600;

  useEffect(() => {
    refreshList()
  }, [])

  function refreshList() {
    TipsApi.getList().then(({ data }) => {
      if (data.tips && data.tips.length > 0) {
        setTips(data.tips)
        setSelectedTipId(selectedTipId ? selectedTipId : data.tips[0].id)
      }
    })

    MediaApi.getList().then(({data})=>{
      if(data){
        let list = data.data;
        setMediaList(list)
        if(list && list.length>0){
          setSelectedMediaFile(list[0].id);
        }
        
      }
      
    });
  }

  function getEditorState() {
    let editorState = EditorState.createEmpty()
    let foundTip
    if (tips && selectedTipId) {
      tips.forEach((t) => {
        if (t.id == selectedTipId) {
          foundTip = t
        }
      })
    }

    if (foundTip) {
      const contentBlock = htmlToDraft(foundTip.content)
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      )
      editorState = EditorState.createWithContent(contentState)
    }
    return editorState
  }

  function getTipOptions() {
    let arr = []
    if(tips){
      tips.forEach((c) => {
        arr.push(
          <option key={c.id} value={c.id}>
            [{c.refCode}] {c.title} by {c.authorName}
          </option>
        )
      })
    }
    
    return arr
  }

  function onTipSelected(e) {
    setSelectedTipId(e.target.value)
  }

  function getMediaOptions() {
    let arr = []
    if(mediaList){
      mediaList.forEach((m) => {
        arr.push(
          <option key={m.id} value={m.id}>
            {m.title}
          </option>
        )
      })
    }
   
    return arr
  }

  function getMediaBy(id){
    let found;
    if(selectedMediaFile && mediaList){
      let len = mediaList.length;
      for (let i = 0; i < len; i++) {
        let m = mediaList[i];
        if(m.id==selectedMediaFile){
          found = m;
          break;
        }
      }
      
    }
    return found;
  }

  function displayMedia(){
    let result = [];
    let found = getMediaBy(selectedMediaFile);
    if(found){
       if(found.contentType.indexOf("video")>-1){
         result.push(
          <video width={displayWidth} height={displayheight} controls src={`${baseRoute}${found.path}`}></video>
         )
       }else if(found.contentType.indexOf("image")>-1){
         result.push(
          <img width={displayWidth} height={displayheight}   src={`${baseRoute}${found.path}`}/>
         )
       }else{
        result.push(
          <embed type="application/pdf" src={`${baseRoute}${found.path}`} width={displayWidth} height={displayheight} ></embed>
        )
       }
    }

    if(result && result.length>0){
      return (
        <div className="col-md-10 text-center" style={{backgroundColor:"black", marginTop:"10px"}}>
           {result}
        </div>
      )
    }else{
      return (
        <></>
      )
    }
    
   
  }
  
  return (
    <div className="notification-dialog">
      <Tabs>
          <TabList>
              <Tab>Tips</Tab>
              <Tab>Tutorial</Tab>
          </TabList>
          <TabPanel>
            <div className="row">
              <div className="col-md-2 select-box">
                <select
                  id="cmbTips"
                  className="text-input"
                  value={selectedTipId}
                  onChange={(e) => onTipSelected(e)}
                  disabled={tipId ? true : false}
                >
                  {getTipOptions()}
                </select>

              </div>
              <div className="col-md-10">
                <Editor
                  readOnly={true}
                  toolbarHidden={true}
                  // editorStyle={{minHeight:"400px"}}
                  editorState={getEditorState()}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                />
              </div>  
            </div>
          </TabPanel>
          <TabPanel>
              <div className="row">
                <div className="col-md-2 select-box">
                  <select
                    id="cmbMedia"
                    className="text-input"
                    value={selectedMediaFile}
                    onChange={(e) => setSelectedMediaFile(e.target.value)}
                    
                  >
                    {getMediaOptions()}
                  </select>

                </div>
               
                {displayMedia()}
                
              </div>
              
          </TabPanel>
      </Tabs>
      
    </div>
  )
}

export default TipsDialog
