import React, { useContext } from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from "react-accessible-accordion"
import intl from "react-intl-universal"
import { FormSpy } from "react-final-form"

import {
  renderDateFieldCustom,
  renderSelectEnumFieldCustom,
  renderSelectFieldCustom,
  renderCheckboxCustom
} from "../../../helpers"
import { optionsType, getOptions } from "../../../helpers/OptionsHelper"
import PairField from "./PairField"
import PersonalDetailsContext from "../PersonalDetailsContext"
import { CountryList } from "../../../constants/Lists"
import { translateListLabel } from "../../../helpers/list-translation"
import AutoSuggest from "../../../components/AutoSuggest/AutoSuggest"

function PersonalInformation({
  name = "personalDetails.personalInformation",
  id = "id",
  client,
  partner
}) {
  const { classification } = useContext(PersonalDetailsContext)
  const optionsData = getOptions([
    optionsType.salutationOptions,
    optionsType.genderOptions,
    optionsType.raceOptions,
    optionsType.maritalStatusOptions,
    optionsType.yesNoOptions,
    optionsType.clientCategoryTypeOptions,
    optionsType.cpfEmploymentClassificationOptions,
    optionsType.taxPaymentPreferenceOptions,
    optionsType.religionTypeOptions
  ])

  const form = [
    {
      label: intl.get("field.gender"),
      name: `${name}.gender`,
      component: renderSelectEnumFieldCustom,
      enumData: optionsData.genderOptions,
      isRequired: true
    },
    {
      label: intl.get("field.salutation"),
      name: `${name}.salutation`,
      component: renderSelectEnumFieldCustom,
      enumData: optionsData.salutationOptions
    },

    {
      label: intl.get("field.firstName"),
      name: `${name}.firstName`,
      isRequired: true,
      isOnlyAlphabetic: true
    },
    {
      label: intl.get("field.middleName"),
      name: `${name}.middleName`,
      isOnlyAlphabetic: true
    },
    {
      label: intl.get("field.familyName"),
      name: `${name}.familyName`,
      isRequired: true,
      isOnlyAlphabetic: true
    },
    {
      label: intl.get("field.preferredName"),
      name: `${name}.preferredName`
      // component: AutoSuggest,
      // names: ["mayor", "test"],
      // isRequired: true
      // validate: required
    },
    {
      label: intl.get("field.birthDate"),
      name: `${name}.birthDate`,
      component: renderDateFieldCustom,
      isRequired: true,
      maxToday: true,
      showAgeFromCurrentAge: true
    },
    {
      label: intl.get("field.disabled"),
      component: renderCheckboxCustom,
      name: `${name}.disabled`,
      
    }
  ]

  const newcontact_form = [
    {
      label: intl.get("field.gender"),
      name: `${name}.gender`,
      component: renderSelectEnumFieldCustom,
      // isRequired: true,
      enumData: optionsData.genderOptions
    },
    {
      label: intl.get("field.salutation"),
      name: `${name}.salutation`,
      component: renderSelectEnumFieldCustom,
      enumData: optionsData.salutationOptions
    },

    {
      label: intl.get("field.firstName"),
      name: `${name}.firstName`,
      isRequired: true,
      isOnlyAlphabetic: true
    },
    {
      label: intl.get("field.middleName"),
      name: `${name}.middleName`,
      isOnlyAlphabetic: true
    },
    {
      label: intl.get("field.familyName"),
      name: `${name}.familyName`,
      isRequired: true,
      isOnlyAlphabetic: true
    },
    {
      label: intl.get("field.preferredName"),
      name: `${name}.preferredName`
      // component: AutoSuggest,
      // names: ["mayor", "test"],
      // isRequired: true
      // validate: required
    },
    // {
    //   label: intl.get("field.preferredName"),
    //   name: `${name}.preferredName`,
    //   isOnlyAlphabetic: true,
    //   isRequired: true
    // },
    {
      label: intl.get("field.birthDate"),
      name: `${name}.birthDate`,
      component: renderDateFieldCustom,
      showAgeFromCurrentAge: true

      // isRequired: true
    }
  ]

  const form2 = [
    {
      label: intl.get("field.maritalStatus"),
      name: `${name}.maritalStatus`,
      component: renderSelectEnumFieldCustom,
      enumData: optionsData.maritalStatusOptions
    },

    {
      name: `personalDetails.employmentDetails.taxResidency`,
      label: intl.get("field.taxResidency"),
      component: renderSelectFieldCustom,
      isRequired: true,
      data: translateListLabel(CountryList),
      valueProperty: "code",
      labelProperty: "name"
    },
    {
      name: `${name}.religion`,
      label: intl.get("field.religion"),
      component: renderSelectEnumFieldCustom,
      enumData: optionsData.religionTypeOptions
    }
  ]

  const formTaxResidenceInfo = [
    {
      name: "personalDetails.employmentDetails.cpfEmploymentClassification",
      label: intl.get("field.employmentClassificationCPF"),
      component: renderSelectEnumFieldCustom,
      enumData: optionsData.cpfEmploymentClassificationOptions
    },

    {
      name: "personalDetails.employmentDetails.taxPaymentPreference",
      label: intl.get("field.taxPaymentPreference"),
      component: renderSelectEnumFieldCustom,
      enumData: optionsData.taxPaymentPreferenceOptions
    }
  ]

  return (
    <Accordion>
      <AccordionItem expanded>
        <div
          className={`portal-part ${
            client.status && client.status == "ARCHIVE"
              ? "personal-details-disabled"
              : ""
          }`}
        >
          <AccordionItemTitle>
            <div className="subtitle2">
              {intl.get("personaldetail.personalInformation")}
              <span className="accordion__arrow" role="presentation" />
            </div>
          </AccordionItemTitle>
          <AccordionItemBody>
            {!client.status &&
              form.map((field) => (
                <PairField
                  key={field.name}
                  field={field}
                  classification={classification}
                />
              ))}

            {client.status &&
              client.status !== "NEW_CONTACT" &&
              form.map((field) => (
                <PairField
                  key={field.name}
                  field={field}
                  classification={classification}
                />
              ))}

            {client.status &&
              client.status == "NEW_CONTACT" &&
              newcontact_form.map((field) => (
                <PairField
                  key={field.name}
                  field={field}
                  classification={classification}
                />
              ))}

            {form2.map((field) => (
              <PairField
                key={field.name}
                field={field}
                classification={classification}
              />
            ))}

            <FormSpy subscription={{ values: true }}>
              {({ values }) => {
                const { client, partner = {} } = values
                const getTaxRecidency = (clnt) => {
                  return (clnt.personalDetails || {}).employmentDetails || {}
                }
                const isRaceRequired = (c) => {
                  const tr = getTaxRecidency(c).taxResidency
                  return (
                    tr &&
                    (tr.toLowerCase() === "my" || tr.toLowerCase() === "sg")
                  )
                }

                if (isRaceRequired(client) || isRaceRequired(partner)) {
                  return (
                    <>
                      <div className="subtitle3">
                        <h3>{intl.get("field.additionalTaxResidencyInfo")}</h3>
                      </div>
                      {formTaxResidenceInfo.map((field) => (
                        <PairField
                          key={field.name}
                          field={field}
                          classification={classification}
                        />
                      ))}
                    </>
                  )
                }
                return <p />
              }}
            </FormSpy>
          </AccordionItemBody>
        </div>
      </AccordionItem>
    </Accordion>
  )
}

export default PersonalInformation
