import React from "react";
import { Form } from "react-final-form";
import { FormButtons, RenderSimpleFields } from "../../../components";
import { FieldType } from "../../../components/Form/RenderSimpleFields";
import CPFClassification from "./CPFClassification";

const CPFChildrenEducation = ({ model, handleSave }) => {
    return (
        <div>
            <Form
                onSubmit={handleSave}
                initialValues={model}
                initialValuesEqual={() => true}
                render={({
                    handleSubmit,
                    reset
                }) => (
                        <form
                            id="cpfChildrenEducationForm"
                            onSubmit={handleSubmit}
                            noValidate
                        >
                            <div className="portal-form-popup">
                                <CPFClassification value={model.classification} disabled />
                                <div className="subtitle2">
                                    CPF Education Scheme Details
                                </div>
                                <RenderSimpleFields
                                    fields={[
                                        {
                                            name: 'educationAssistanceLoanType',
                                            label: 'Education Assistance Loan Type',
                                            type: FieldType.TEXT,
                                        },
                                        {
                                            name: 'currentLoanBalance',
                                            label: 'Current Loan Balance',
                                            type: FieldType.MONEY_WITH_CURRENCY,
                                            defaultCurrency: 'SGD'

                                        },
                                        {
                                            name: 'valuationDate',
                                            label: 'Valuation Date',
                                            type: FieldType.DATE,
                                        },
                                        {
                                            name: 'loanStartDate',
                                            label: 'Loan Start Date',
                                            type: FieldType.DATE,
                                        },
                                        {
                                            name: 'repaymentStartDate',
                                            label: 'Repayment Start Date',
                                            type: FieldType.DATE,
                                        },
                                        {
                                            name: 'loanAnnualInterestRate',
                                            label: 'Loan Annual Interest Rate',
                                            type: FieldType.PERCENTAGE,
                                        },
                                        {
                                            name: 'monthlyInstalmentAmount',
                                            label: 'Monthly Instalment Amount',
                                            type: FieldType.MONEY_WITH_CURRENCY,
                                            defaultCurrency: 'SGD'
                                        }
                                    ]}
                                />
                            </div>
                            <FormButtons
                                reset={reset}
                            />
                        </form>
                    )}
            />
        </div>


    )
}

export default CPFChildrenEducation;