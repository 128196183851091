import { notesAction } from "../constants/ActionConstants";
const initialState = {
    items: null
};

export function notes(state = initialState, action) {
    switch (action.type) {
        case notesAction.GET_NOTES_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.data
            };
        case notesAction.GET_NOTES_REQUEST:
            return {
                loading: true
            };
        case notesAction.GET_NOTES_FAIL:
            return {
                error: action.error,
                loading: false
            };
        default:
            return state;
    }
}
