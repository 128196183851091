import React from "react"
import intl from "react-intl-universal"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { NetWorthPath } from "./NetWorthForm"
import { modalWindow } from "../../constants/ModalWindowLists"
import {
  netWorthActions,
  netWorthFormActions,
  modalWindowActions
} from "../../actions"
import NetWorthListingGroup, {
  NetWorthListingGroupPropsComposer
} from "./NetWorthListingGroup"
import PersonalTax from "./Taxation/PersonalTax"

class Taxation extends NetWorthListingGroup {
  constructor(props) {
    const { getTaxationListing } = props
    const listingDefinition = [
      {
        title: intl.get("taxation.title"),
        path: NetWorthPath.personalTax,
        modalName: modalWindow.PERSONAL_TAX_FORM,
        showAddButton: () => false,
        hideDeleteButton: true,
        oneDataOnly: true
      }
    ]
    super(props, "taxation", getTaxationListing, listingDefinition, true)
  }

  render() {
    const { optionsData, currency } = this.props
    const { contentWrapper } = this

    return contentWrapper(
      <React.Fragment>
        <PersonalTax optionsData={optionsData} currency={currency} />
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { taxationListing } = state.netWorth
  return NetWorthListingGroupPropsComposer(state, taxationListing)
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, netWorthActions, modalWindowActions, netWorthFormActions),
    dispatch
  )
}

Taxation = connect(mapStateToProps, mapDispatchToProps)(Taxation)

export default Taxation
