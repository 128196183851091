import React from 'react';

function SRSInvestmentPlatformAssetAllocation(props) {
    return (
        <table className="table-investment" width="100%" cellSpacing="0" cellPadding="0" border="0">
            <tbody><tr>
                <th>Investment  Name</th>
                <th>Type</th>
                <th>Unit Held</th>
                <th>Unit Price</th>
                <th>Cost</th>
                <th>Value</th>
                <th>Current Allocation</th>
                <th>Monthly Allocation<div className="select-box select-box-table">
                    <select name="" required="">
                        <option>$ </option>
                        <option>%</option>
                    </select>
                </div></th>
                <th><button type="button" className="btn btn-plus"></button></th>
            </tr>
                <tr>
                    <td>BT Cash Account</td>
                    <td>Cash Account</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>100,000</td>
                    <td>20.00%</td>
                    <td>50.00%</td>
                    <td><button type="button" className="btn-remove"></button></td>
                </tr>
                <tr>
                    <td>BT Shares</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>400,000</td>
                    <td>80.00%</td>
                    <td>50.00%</td>
                    <td><button type="button" className="btn-remove"></button></td>
                </tr>
                <tr className="total">
                    <td>Total</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>600,000</td>
                    <td>100.00%</td>
                    <td>100.00%</td>
                    <td></td>
                </tr>
            </tbody></table>
    );
}

export default SRSInvestmentPlatformAssetAllocation;