import React from "react"
import intl from "react-intl-universal"
import { useSelector } from "react-redux"
export default function FianancialPlaningScope({ input }) {
  const { value = "SINGLE", name, onChange } = input
  const { hasPartner } = useSelector((state) => state.entity)
  return (
    <div className="content-panel-header">
      <div className="title-holder">
        <h1>{intl.get("personaldetail.title")}</h1>
      </div>
      <input {...input} type={"hidden"} name={name} value={value} />
      <span>
        {[
          {
            label: intl.get("topmenu.single"),
            icon: "ico-single",
            value: "SINGLE"
          },
          {
            label: intl.get("topmenu.couple"),
            icon: "ico-couple",
            value: "JOINT"
          },
          // {
          //   label: intl.get("topmenu.familyoffice"),
          //   icon: "ico-familyoffice",
          //   value: "FAMILY_OFFICE"
          // },
          // {
          //   label: intl.get("topmenu.corporate"),
          //   icon: "ico-corporate",
          //   value: "CORPORATE"
          // },
          // {
          //   label: intl.get("topmenu.trust"),
          //   icon: "ico-trust",
          //   value: "TRUST"
          // }
        ].map((item) => (
          <span
            className={`icon-holder ${value === item.value ? "selected" : ""}`}
            key={item.value}
            onClick={(e) => {
              e.preventDefault()
              if (hasPartner) {
                return
              }
              if (item.value === "SINGLE" || item.value === "JOINT") {
                onChange(item.value)
              }
            }}
          >
            <div className={`icon ${item.icon}`} />
            <div className="text">{item.label}</div>
          </span>
        ))}
      </span>
    </div>
  )
}
