import { primaryResidenceAction } from '../../constants/ActionConstants';

export function primaryResidence(state = {}, action) {
    switch (action.type) {
        case primaryResidenceAction.GET_PRIMARYRESIDENCE_REQUEST:
            return {
                loading: true
            };
        case primaryResidenceAction.GET_PRIMARYRESIDENCE_SUCCESS:
            return {
                items: action.data
            };
        case primaryResidenceAction.GET_PRIMARYRESIDENCE_FAILURE:
            return {
                error: action.error
            };
        case primaryResidenceAction.ADD_PRIMARYRESIDENCE_REQUEST:
            return {
                loading: true
            };
        case primaryResidenceAction.ADD_PRIMARYRESIDENCE_SUCCESS:
            return {
                items: action.response
            };
        case primaryResidenceAction.ADD_PRIMARYRESIDENCE_FAILURE:
            return {
                error: action.error
            };
        case primaryResidenceAction.EDIT_PRIMARYRESIDENCE_REQUEST:
            return {
                loading: true
            };
        case primaryResidenceAction.EDIT_PRIMARYRESIDENCE_SUCCESS:
            return {
                items: action.response
            };
        case primaryResidenceAction.EDIT_PRIMARYRESIDENCE_FAILURE:
            return {
                error: action.error
            };
        case primaryResidenceAction.DELETE_PRIMARYRESIDENCE_REQUEST:
            return {
                loading: true
            };
        case primaryResidenceAction.DELETE_PRIMARYRESIDENCE_SUCCESS:
            return {
                items: action.data
            };
        case primaryResidenceAction.DELETE_PRIMARYRESIDENCE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
