import React from 'react';
import SRSInvestmentPlatformDetails from './SRSInvestmentPlatformDetails';

function SRSFundAllocationDetails(props) {

    return (
        <React.Fragment>
            <div className="portal-form-table">
                <div className="subtitle-holder">
                    <div className="title">Fund Allocation Details </div>
                </div>

                <table className="table-investment" width="100%" cellSpacing="0" cellPadding="0" border="0">
                    <tbody><tr>
                        <th>Investment  Name</th>
                        <th>Type</th>
                        <th>Unit Held</th>
                        <th>Unit Price</th>
                        <th>Cost</th>
                        <th>Value</th>
                        <th>Current Allocation</th>
                        <th></th>
                        <th><button type="button" className="btn btn-plus"></button></th>
                    </tr>
                        <tr>
                            <td>DBS Singapore Cash Fund</td>
                            <td>Share Fund</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>100,000</td>
                            <td>20.00%</td>
                            <td><button type="button" className="btn-remove"></button></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>DBS Singapore Cash Fund</td>
                            <td>Investment Platform</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>400,000</td>
                            <td>80.00%</td>
                            <td><button type="button" className="btn-remove"></button></td>
                            <td></td>
                        </tr>
                        <tr className="total">
                            <td>Total</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>500,000</td>
                            <td>100.00%</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody></table>
            </div>
            <SRSInvestmentPlatformDetails />
        </React.Fragment>
    );
}

export default SRSFundAllocationDetails;