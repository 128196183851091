import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import Alert from "react-s-alert";
import Api from "../../lib/api";
import { fileFieldRenderBootstrapWithoutLabel } from "../../helpers/FormRenderBootstrap";
import {
    composeValidators,
    required,
    maxPhotoSize,
    image
} from "../../helpers/Validator";
import { EntityTypeEnum } from "../../constants/Enums";
import { createUrlFromPath } from "../../helpers/ImageHelper";
import { reloadWindow } from "../../helpers/SessionHelper";

function UploadPhoto({ id, currentPhotoUrl, clearModal, isClient, type }) {
    const upload = model => {
        const formData = new FormData();
        formData.append("image", model.image);
        formData.append("clientId", model.clientId);
        if (type === EntityTypeEnum.EntityTypeEnum_BUILT_IN_ADVISER) {
            return Api.post(`/adviser/${model.clientId}/photo`, formData);
        }
        return Api.post(
            `/${isClient ? "client" : "entity"}/${model.clientId}/photo`,
            formData
        );
    };
    const [preview, setPreview] = useState(createUrlFromPath(currentPhotoUrl));
    const getBase64 = (file, cb) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function() {
            cb(reader.result);
        };
        reader.onerror = function(error) {
            console.log("Error: ", error);
        };
    };
    const uploaded = event => {
        getBase64(event.target.files[0], result => {
            setPreview(result);
        });
    };
    return (
        <div className="portal-form-popup">
            <Form
                onSubmit={model => upload(model)}
                initialValues={{ clientId: id, image: undefined }}
                render={({ handleSubmit, reset }) => (
                    <form
                        id="uploadPhotoForm"
                        onSubmit={event => {
                            const promise = handleSubmit(event);
                            if (promise) {
                                promise.then(response => {
                                    if (response && response.status === 200) {
                                        Alert.success(
                                            "Successfully update the  profile photo"
                                        );
                                        reset();
                                        clearModal();

                                        reloadWindow("UPLOAD_PHOTO");
                                    }
                                });
                            }
                        }}
                    >
                        <div className="row">
                            <div className="col d-flex justify-content-center align-items-center">
                                <img
                                    className="photo-preview"
                                    src={preview}
                                    alt="preview"
                                />
                            </div>
                        </div>
                        <div className="row justify-content-center align-items-center mt-2">
                            <div className="col-8 justify-content-center align-items-center">
                                <Field
                                    name="image"
                                    inputOnChange={uploaded}
                                    component={
                                        fileFieldRenderBootstrapWithoutLabel
                                    }
                                    validate={composeValidators(
                                        required,
                                        maxPhotoSize,
                                        image
                                    )}
                                />
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col d-flex justify-content-end ">
                                <button
                                    className="portal-btn portal-btn-submit "
                                    type="submit"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </form>
                )}
            />
        </div>
    );
}
export default UploadPhoto;
