import { manageAccessLevelAction } from '../constants/ActionConstants';

export function manageAccessLevel(state = {}, action) {
    switch (action.type) {
        case manageAccessLevelAction.GET_ACCESS_LEVEL_ENTITY_SUCCESS: {
            return {
                ...state,
                entity: action.entity,
            };
        }
        case manageAccessLevelAction.SAVE_ACCESS_LEVEL_ENTITY_SUCCESS: {
            return {
                ...state,
                entity: action.entity,
            };
        }
        default:
            return state;
    }
}
