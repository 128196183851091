/*
    Author : Marlina (marlina@devstack.com.au)
    Description : Organization right menu component
    Updated by : Anova (31 Jan 2019 - added checking for client and active client id)
*/
import React, { useContext, useEffect, useState } from "react"
import intl from "react-intl-universal";

import { EntityTypeEnum } from "../../../../constants/Enums";
import { history } from "../../../../helpers";
import UserApi from "../../../../api/UserApi";
import { AppContext } from "../../../../context/AppContext";
import TaskDialog from "../../../../pages/TaskManagement/TaskDialog";
import Tooltip from "rc-tooltip";
import { uuidv4 } from "../../../../helpers/DateHelper";



function NewClientEntityButton({ mode, onNewClick }) {
    const onNewClientClicked = (e)=>{
        let path = '';
        if(mode === "DEMO_CLIENT"){
            path = '/personaldetails?DEMO_CLIENT';
        }else{
            path = '/personaldetails';
        }
        UserApi.track(path);
        history.push(path);
    }
    return (
        <span className="action-button" onClick={(e)=>onNewClientClicked(e)}>
            <div className="large">NEW</div>
            <div className="small">client</div>
        </span>
    );
}



function NewEntityButton({
    allowAddNewEntity,
    entityType,
    onNewClick,
    entityId,
    mode
}) {
    let loginEntityType = localStorage.getItem("loginEntityType");
    if(loginEntityType==EntityTypeEnum.EntityTypeEnum_BUILT_IN_ADVISER){
        return <NewClientEntityButton mode={mode} onNewClick={onNewClick} />;
    }else{
        return <></>
    }
    
}



function TaskMgmtButton({}){
    const {
        modal: { setModal, clearModal }
    } = useContext(AppContext)

    const showForm = () => {
        setModal({
            title: `Task Form`,
            content: (
            <TaskDialog
                clearModal={clearModal}
            
            />
            ),
            isSmall: true
        })
    }

    function showTooltipMenus() {
        return (
          <Tooltip
            zIndex={9999}
            showArrow={true}
            placement="left"
            overlay={
              <div className="cover">
                <div
                    className="menu-items"
                    onClick={(e) => showForm()}
                >
                    <span >New Task</span>
                </div>
    
                <div
                    className="menu-items"
                    onClick={(e) => history.push("/taskmanagement")}
                >
                    <span>Show Tasks</span>
                </div>
              </div>
            }
            arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
          >
            <span
                key={"task"}
                className={`action-button `}
            >
                <div className="large">TSK</div>
                <div className="small">Tasks</div>
            </span>
          </Tooltip>
        )
      }

   return showTooltipMenus();
}

function MalabarDashboardRightMenu({
    entityType,
    allowAddNewEntity,
    onManagementClick,
    onRevenueClick,
    onResearchClick,
    onAnalyticClick,
    onComplianceClick,
    onNoteClick,
    onNewClick,
    entityId,
    crmUrl,
    mode
}) {
  
    const hrefHandler = () => {
        if (entityType === "BUILT_IN_ADVISER") {
            return "/personaldealings";
        } else {
            return "/teamstructure";
        }
    };

    const onButtonClicked = (e, button) => {
        let path = '';
        
        if(button && button.href){
            path = button.href;
            UserApi.track(path);
            history.push(path);
            if(button.icon=='BRM'){
                localStorage.setItem("Mode", "BM");
            }
            
        }
        
    };
    const topButtons = [
        
        {
            icon: "BRM",
            desc: intl.get("rightmenu.brm"),
            href: hrefHandler(),
        },
        {
            icon: "FXR",
            desc: intl.get("rightmenu.currencyExchange"),
            href: "/fxRates"
        }
      
       
    ];



    

    return (
        <div className="action-panel">
            <div className="action-panel-top">
                {mode !== "DEMO_CLIENT" &&
                    topButtons.map((button, idx) =>
                        <span
                            key={idx}
                            className={`action-button `}
                            onClick={(e)=>onButtonClicked(e, button)}
                        
                        >
                            <div className="large">{button.icon}</div>
                            {button.desc && (
                                <div className="small">{button.desc}</div>
                            )}
                        </span>
                    )}
                    <TaskMgmtButton/>
            </div>
            <div className="action-panel-exit">
                <NewEntityButton
                    {...{
                        allowAddNewEntity,
                        entityId,
                        entityType,
                        onNewClick
                    }}
                    mode={mode}
                />
            </div>
        </div>
    );
}

export default MalabarDashboardRightMenu;
