import React, { useState, useEffect } from "react"
import Api from "../../lib/api"

function PropertyRentPricesInfo({ onClosed, country, mutators }) {
  const rowClassName = "portal-form-row";
  const [filterCity, setFilterCity] = useState("All")
  const [filterAvailable, setFilterAvailable] = useState(false)
  const [data, setData] = useState([])
  const [cityOptions, setCityOptions] = useState([])
  const [selectedValue, setSelectedValue] = useState(0)
  const [selectedCurrency, setSelectedCurrency] = useState(country.abbreviation)

  const tableCityPrices = () => {
    return (
      <table className="table-blue">
        <tbody>
          <tr>
            <th width="25%">City</th>
            <th width="15%">Monthly Rent Average</th>
            <th width="15%">Monthly 1-Bed Rent Low </th>
            <th width="15%">Monthly 1-Bed Rent High </th>
            <th width="15%">Monthly 3-Bed Rent Low </th>
            <th width="15%">Monthly 3-Bed Rent High </th>
          </tr>
          {getCityPriceRows()}
        </tbody>
      </table>
    )
  }

  const onPriceSelected = (price, ccy) => {
    // rentalIncome
    setSelectedValue(price)
    setSelectedCurrency(ccy)
    mutators.setFormAttribute("rentalIncome", { currency: ccy, value: price })
  }

  const onEstimatedValueChanged = (val) => {
    setSelectedValue(val)
  }

  const createButton = (itemValue, itemCurrency) => {
    return (
      <button
        disabled={itemValue == 0}
        className="portal-btn portal-btn-submit"
        onClick={(e) => onPriceSelected(itemValue, itemCurrency)}
      >
        {itemCurrency}&nbsp;{itemValue}
      </button>
    )
  }

  const getCityPriceRows = () => {
    if (data.cityPriceList) {
      let filteredList =
        filterCity == "All"
          ? data.cityPriceList
          : data.cityPriceList.filter((c) => c.city === filterCity)
      filteredList = filterAvailable
        ? filteredList.filter((c) => c.available)
        : filteredList
      return filteredList.map((item, index) => (
        <tr key={index}>
          <td>{item.city}</td>
          <td className="text-right">
            {createButton(item.rentMonthlyAvg, item.currency)}
          </td>
          <td className="text-right">
            {createButton(item.rentMonthly1BLow, item.currency)}
          </td>
          <td className="text-right">
            {createButton(item.rentMonthly1BHigh, item.currency)}
          </td>
          <td className="text-right">
            {createButton(item.rentMonthly3BLow, item.currency)}
          </td>
          <td className="text-right">
            {createButton(item.rentMonthly3BHigh, item.currency)}
          </td>
        </tr>
      ))
    }

    return (
      <tr>
        <td colSpan="13">No Data</td>
      </tr>
    )
  }

  useEffect(() => {
    Api.get(`/analytic/property/city-prices?countryCode=${country.code}`).then(
      (res) => {
        if (res.data) {
          setData(res.data)
          if (res.data.cityPriceList && res.data.cityPriceList.length > 0) {
            let options = []
            options.push(<option key={"All"}>All</option>)
            res.data.cityPriceList.forEach((element) => {
              options.push(<option key={element.city}>{element.city}</option>)
            })
            setCityOptions(options)
          }
        }
      }
    )
  }, [])

  return (
    <div className="notification-dialog">
      <div className={rowClassName}>
        <div className="col-md-2">Filtered By City</div>
        <div className="col-md-4">
          <select
            className="text-input"
            id="cmbFilterCity"
            type="text"
            value={filterCity}
            onChange={(e) => setFilterCity(e.target.value)}
          >
            {cityOptions}
          </select>
        </div>
        <div className="col-md-4">
          <div class="input-check input-check-alert">
            <input type="checkbox" id="chkFilterAvailable"
              value={filterAvailable}
              checked={filterAvailable == true}
              onChange={(e) => setFilterAvailable(e.target.checked)}
            />
            <label for="chkFilterAvailable">Show data only for available value(s)</label>
          </div>

        
        </div>
      </div>
      <div className={rowClassName}>
        <div className="col-md-2">Estimated Value</div>
        <div className="col-md-1">
          {selectedCurrency}
        </div>
        <div className="col-md-3">
          <input
            className="text-input text-right"
            value={selectedValue}
            onChange={(e) => onEstimatedValueChanged(e.target.value)}
          ></input>
        </div>
        <div className="col-md-2">
          <button
            className="portal-btn portal-btn-submit"
            onClick={(e) =>
              onClosed({ currency: selectedCurrency, value: selectedValue })
            }
          >
            Confirm Value
          </button>
        </div>
      </div>

      <div className={rowClassName}>
        <div className="col-md-12">{tableCityPrices()}</div>
      </div>
    </div>
  )
}
export default PropertyRentPricesInfo
