/*
    Author : Marlina (marlina@devstack.com.au)
    Description : Global page in dashboard
*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { enumOptionsActions, modalWindowActions } from "../../actions";
import { modalWindow } from "../../constants/ModalWindowLists";
import {
    GroupedOverviewBoxes,
    AlphabetSearch,
    BoxUser
} from "../../components";
import Entity from "../Entity/Entity";
import UserForm from "../User/UserForm";
import { MalabarDashboardRightMenu } from "../../components/MalabarMenu";
import { EntityTypeEnum } from "../../constants/Enums";


export class GlobalDashboard extends Component {
    getOverviews = () => [
        {
            title: "Total Business Users",
            figure: "89",
            timing: "0% Last Year"
        },
        {
            title: "Total Users",
            figure: "2350",
            timing: "0% Last Year"
        },
        {
            title: "Weekly New users",
            figure: "89",
            timing: "5% From Last Week",
            color: "green"
        },
        {
            title: "Monthly Revenue",
            figure: "$15,600",
            timing: "0% From Last week",
            color: "red"
        },
        {
            title: "Top Monthly Sales",
            figure: "Financial Planning Fees"
        },
        {
            title: "Feedback",
            figure: "1",
            timing: "100% Last Year",
            color: "red"
        }
    ];

    generateUsers = count =>
        Array.from(Array(count).keys()).map(i => ({
            image: "/image/user-profile.jpg",
            name: `Dummy ${i}`,
            location: `Location ${i}`,
            worth: i * 10000
        }));

    // TODO: get it from back end through store
    getTopPeopleData = () => {
        const { generateUsers } = this;
        return {
            title: "Monthly Top Advisers – ToloHP Shatin - Eagle",
            users: generateUsers(5)
        };
    };

    randomNumber = () => Math.floor(Math.random() * Math.floor(1000000));

    // TODO: get it from back end through store
    getData = count => {
        const { randomNumber } = this;
        const users = [];
        Array.from(Array(count).keys()).forEach(i =>
            users.push({
                fullName: `Dummy User ${i}`,
                lastReviewed: "25/05/2018",
                aum: randomNumber(),
                ium: randomNumber(),
                goals: randomNumber(),
                image: "/image/user-profile.jpg",
                status: "Client"
            })
        );
        return users;
    };

    newEntityClicked = entityType => {
        if (entityType === undefined) {
            this.props.showModalWindow(modalWindow.USER_MODAL);
        } else {
            this.props.showModalWindowAndValue(
                modalWindow.ENTITY_MODAL,
                entityType
            );
        }
    };

    render() {
        const {
            getOverviews,
            getData,
            getTopPeopleData,
            newEntityClicked
        } = this;
        getTopPeopleData();
        const overviews = getOverviews();
        const data = getData(3);
       
        return (
            <React.Fragment>
                <div className="height-100 dashboard-admin">
                    {/* <!--Main Panel--> */}
                    <div className="portal-maincontent">
                        <div className="page-arrow-holder">
                            <button
                                type="button"
                                className="arrow arrow-holder-L"
                            >
                                <div className="arrow-left" />
                            </button>{" "}
                            <button
                                type="button"
                                className="arrow arrow-holder-R"
                            >
                                <div className="arrow-right" />
                            </button>
                        </div>
                        <div className="dashboard-content-panel">
                            <GroupedOverviewBoxes overviews={overviews} />

                            <div className="flexbox">
                             
                            </div>

                            <div className="alphabet-box">
                                <AlphabetSearch
                                    onSelect={letter =>
                                        console.log(
                                            `Letter ${letter} is selected!`
                                        )
                                    }
                                />
                                <div className="alphabet-R">
                                    <input type="search" placeholder="Search" />
                                </div>
                            </div>

                            <div className="flexbox" style={{ gap: "30px" }}>
                                {data.map((user, index) => (
                                    <BoxUser user={user} key={index} />
                                ))}
                            </div>
                            <div className="back-to-top">
                                <span
                                    role="button"
                                    className="arrow-back-to-top ico-arrowtop"
                                />
                            </div>
                        </div>
                        <MalabarDashboardRightMenu
                            entityType={
                                EntityTypeEnum.EntityTypeEnum_BUILT_IN_MALABAR_AI_GLOBAL
                            }
                            onNewClick={newEntityClicked}
                            allowAddNewEntity
                            process={process}
                        />
                    </div>
                </div>
                <Entity
                    type="PRIMARY"
                    componentName={modalWindow.ENTITY_MODAL}
                />
                <UserForm componentName={modalWindow.USER_MODAL} />
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { optionsData } = state.enumOptions;
    return {
        optionsData
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        Object.assign({}, enumOptionsActions, modalWindowActions),
        dispatch
    );
}

GlobalDashboard = connect(mapStateToProps, mapDispatchToProps)(GlobalDashboard);

export default GlobalDashboard;
