/*
    Author : Erik (erik.malik@devstack.com.au)
    Description : Adviser dashboard page
*/

import React, { Component } from 'react';
import { Pie, Line } from 'react-chartjs-2';
import intl from 'react-intl-universal';
import { GroupedOverviewBoxes, TopPeopleBox } from '../../../components';
import { MalabarDashboardRightMenu } from '../../../components/MalabarMenu';
import { EntityTypeEnum } from '../../../constants/Enums';
import {
    DefaultLineChartOptions,
    DefaultPieChartLegendsOptions,
} from '../../../helpers/ChartHelper';

class AdviserDashboard extends Component {
    // TODO: get it from back end through store code:0992384302984
    getOverviews = () => [
        {
            title: 'Weekly Sales Lodged',
            figure: '50',
            timing: '5% From Last week',
            color: 'green',
            link: '/dashboard/adviser/weeklySalesLodged',
        },
        {
            title: 'Monthly Revenue Lodged',
            figure: '$12,600',
            timing: '0% Last Year',
        },
        {
            title: 'Top Business',
            figure: 'Financial Planning Fees',
        },
        {
            title: 'Monthly Revenue Received',
            figure: '$15,600',
            timing: '-10% From Last week',
            color: 'red',
        },
        {
            title: 'Sales Revenue Paid To Date',
            figure: '$150,890',
            timing: '0% Last Year',
        },
        {
            title: 'Complaints',
            figure: '1',
            timing: '100% Last Year',
            color: 'red',
        },
    ];

    getLineData = () => ({
        labels: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
        ],
        datasets: [
            {
                label: 'Alert',
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: [1, 0, 5, 6, 7, 4, 5],
            },
        ],
    });

    getPieData = () => ({
        labels: ['Red', 'Green', 'Yellow'],
        datasets: [
            {
                data: [300, 50, 100],
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
                hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
            },
        ],
    });

    generateUsers = count =>
        Array.from(Array(count).keys()).map(i => ({
            image: '/image/user-profile.jpg',
            name: `Dummy ${i}`,
            location: `Location ${i}`,
            worth: i * 10000,
        }));

    // TODO: get it from back end through store
    getTopPeopleData = () => {
        const { generateUsers } = this;
        return {
            title: 'Top Clients',
            users: generateUsers(5),
        };
    };

    render() {
        const {
            getOverviews,
            getLineData,
            getPieData,
            getTopPeopleData,
        } = this;
        const overviews = getOverviews();
        const data = getTopPeopleData();
        const entityType =
            EntityTypeEnum.EntityTypeEnum_BUILT_IN_MALABAR_AI_GLOBAL;

        return (
            <React.Fragment>
                <div className="dashboard-adviser">
                    <div className="portal-maincontent">
                        <div className="page-arrow-holder">
                            <button className="arrow arrow-holder-L">
                                <div className="arrow-left" />
                            </button>{' '}
                            <button className="arrow arrow-holder-R">
                                <div className="arrow-right" />
                            </button>
                        </div>
                        <div className="dashboard-content-panel">
                            <GroupedOverviewBoxes overviews={overviews} />

                            <div className="flexbox">
                                <div className="box-full">
                                    <Line
                                        data={getLineData}
                                        options={DefaultLineChartOptions}
                                    />
                                </div>

                                <div className="box-half map-box">
                                    <div className="title text-center">
                                        <h1>
                                            {intl.get(
                                                'dashboard.clientsCategories'
                                            )}
                                        </h1>
                                    </div>
                                    <div className="graph-holder">
                                        <Pie
                                            data={getPieData}
                                            legend={
                                                DefaultPieChartLegendsOptions
                                            }
                                        />
                                    </div>
                                </div>

                                <TopPeopleBox
                                    title={data.title}
                                    users={data.users}
                                />
                            </div>

                            <div className="back-to-top">
                                <button className="arrow-back-to-top ico-arrowtop" />
                            </div>
                        </div>
                        <MalabarDashboardRightMenu
                            entityType={entityType}
                            process={process}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AdviserDashboard;
