/*
    Author : Hendri Firmana (hendri.firmana@devstack.com.au)
             Anova Fawzi (anovafawzi@gmail.com) - rework for cashflow demo
    Description : Personal expense component
*/

import React, { useState } from "react"
import intl from "react-intl-universal"
import CashFlowHeaderListingMockup from "../CashFlowHeaderListingMockup"

const PersonalExpensesMockup = ({
  values: {
    client,
    partner,
    joint,
    carAndTransport,
    home,
    healthAndFitness,
    educationExpenses,
    personalFinanceExpense,
    investmentExpenses,
    otherInsurancePremium,
    personalCare,
    billAndUtilities,
    foodAndDining,
    childrenExpenses,
    parentExpenses,
    entertainment,
    shoppingExpenses,
    travelExpenses,
    petExpenses,
    personalBusiness,
    giftAndDonations,
    taxPayment //20201102 MABT-423
  },
  currency,
  clientInformation
}) => {
  const isJoint = clientInformation.partner || false
  const [toggle, setToggle] = useState(true)

  return (
    <React.Fragment>
      <div
        className="subtitle2b accordion"
        onClick={() => setToggle(!toggle)}
        aria-selected={toggle}
      >
        {intl.get("cashflowheader.top.personalExpenses")}
        <div className="accordion__arrow" />{" "}
      </div>
      {toggle && (
        <React.Fragment>
          <div className="portal-part">
            <CashFlowHeaderListingMockup
              title={intl.get(
                "cashflowlisting.personalExpenses.carTransportExpenses"
              )}
              clientTotalValue={carAndTransport.client.totalMonthly}
              partnerTotalValue={carAndTransport.partner.totalMonthly}
              jointTotalValue={carAndTransport.joint.totalMonthly}
              currency={currency}
              isJointHide={!isJoint}
              clientId={clientInformation.id}
              detailUrl={`/cashflow/${clientInformation.id}/CarTransports?currency=${currency}`}
            />
          </div>

          <div className="portal-part">
            <CashFlowHeaderListingMockup
              title={intl.get("cashflowlisting.personalExpenses.homeExpenses")}
              isRoundColorRed={false}
              clientTotalValue={home.client.totalMonthly}
              partnerTotalValue={home.partner.totalMonthly}
              jointTotalValue={home.joint.totalMonthly}
              currency={currency}
              isJointHide={!isJoint}
              clientId={clientInformation.id}
              detailUrl={`/cashflow/${clientInformation.id}/HomeExpenses?currency=${currency}`}
            />
          </div>

          <div className="portal-part">
            <CashFlowHeaderListingMockup
              title={intl.get("cashflowlisting.personalExpenses.billUtilities")}
              isJointHide={!isJoint}
              clientTotalValue={billAndUtilities.client.totalMonthly}
              partnerTotalValue={billAndUtilities.partner.totalMonthly}
              jointTotalValue={billAndUtilities.joint.totalMonthly}
              currency={currency}
              clientId={clientInformation.id}
              detailUrl={`/cashflow/${clientInformation.id}/BillUtilities?currency=${currency}`}
            />
          </div>

          <div className="portal-part">
            <CashFlowHeaderListingMockup
              title={intl.get("cashflowlisting.personalExpenses.personalCares")}
              isJointHide={!isJoint}
              clientTotalValue={personalCare.client.totalMonthly}
              partnerTotalValue={personalCare.partner.totalMonthly}
              jointTotalValue={personalCare.joint.totalMonthly}
              currency={currency}
              clientId={clientInformation.id}
              detailUrl={`/cashflow/${clientInformation.id}/PersonalCares?currency=${currency}`}
            />
          </div>

          <div className="portal-part">
            <CashFlowHeaderListingMockup
              title={intl.get("cashflowlisting.personalExpenses.healthFitness")}
              isJointHide={!isJoint}
              clientTotalValue={healthAndFitness.client.totalMonthly}
              partnerTotalValue={healthAndFitness.partner.totalMonthly}
              jointTotalValue={healthAndFitness.joint.totalMonthly}
              currency={currency}
              clientId={clientInformation.id}
              detailUrl={`/cashflow/${clientInformation.id}/HealthFitness?currency=${currency}`}
            />
          </div>

          <div className="portal-part">
            <CashFlowHeaderListingMockup
              title={intl.get("cashflowlisting.personalExpenses.foodDining")}
              isJointHide={!isJoint}
              clientTotalValue={foodAndDining.client.totalMonthly}
              partnerTotalValue={foodAndDining.partner.totalMonthly}
              jointTotalValue={foodAndDining.joint.totalMonthly}
              currency={currency}
              clientId={clientInformation.id}
              detailUrl={`/cashflow/${clientInformation.id}/FoodDining?currency=${currency}`}
            />
          </div>

          <div className="portal-part">
            <CashFlowHeaderListingMockup
              title={intl.get(
                "cashflowlisting.personalExpenses.educationExpenses"
              )}
              isJointHide={!isJoint}
              clientTotalValue={educationExpenses.client.totalMonthly}
              partnerTotalValue={educationExpenses.partner.totalMonthly}
              jointTotalValue={educationExpenses.joint.totalMonthly}
              currency={currency}
              clientId={clientInformation.id}
              detailUrl={`/cashflow/${clientInformation.id}/EducationExpenses?currency=${currency}`}
            />
          </div>

          <div className="portal-part">
            <CashFlowHeaderListingMockup
              title={intl.get(
                "cashflowlisting.personalExpenses.childrenExpenses"
              )}
              isJointHide={!isJoint}
              clientTotalValue={childrenExpenses.client.totalMonthly}
              partnerTotalValue={childrenExpenses.partner.totalMonthly}
              jointTotalValue={childrenExpenses.joint.totalMonthly}
              currency={currency}
              clientId={clientInformation.id}
              detailUrl={`/cashflow/${clientInformation.id}/ChildrenExpenses?currency=${currency}`}
            />
          </div>

          <div className="portal-part">
            <CashFlowHeaderListingMockup
              title={intl.get(
                "cashflowlisting.personalExpenses.parentsExpenses"
              )}
              isJointHide={!isJoint}
              clientTotalValue={parentExpenses.client.totalMonthly}
              partnerTotalValue={parentExpenses.partner.totalMonthly}
              jointTotalValue={parentExpenses.joint.totalMonthly}
              currency={currency}
              clientId={clientInformation.id}
              detailUrl={`/cashflow/${clientInformation.id}/ParentsExpenses?currency=${currency}`}
            />
          </div>

          <div className="portal-part">
            <CashFlowHeaderListingMockup
              title={intl.get("cashflowlisting.personalExpenses.entertainment")}
              isJointHide={!isJoint}
              clientTotalValue={entertainment.client.totalMonthly}
              partnerTotalValue={entertainment.partner.totalMonthly}
              jointTotalValue={entertainment.joint.totalMonthly}
              currency={currency}
              clientId={clientInformation.id}
              detailUrl={`/cashflow/${clientInformation.id}/Entertainment?currency=${currency}`}
            />
          </div>

          <div className="portal-part">
            <CashFlowHeaderListingMockup
              title={intl.get(
                "cashflowlisting.personalExpenses.shoppingExpenses"
              )}
              isJointHide={!isJoint}
              clientTotalValue={shoppingExpenses.client.totalMonthly}
              partnerTotalValue={shoppingExpenses.partner.totalMonthly}
              jointTotalValue={shoppingExpenses.joint.totalMonthly}
              currency={currency}
              clientId={clientInformation.id}
              detailUrl={`/cashflow/${clientInformation.id}/ShoppingExpenses?currency=${currency}`}
            />
          </div>

          <div className="portal-part">
            <CashFlowHeaderListingMockup
              title={intl.get(
                "cashflowlisting.personalExpenses.travelExpenses"
              )}
              isJointHide={!isJoint}
              clientTotalValue={travelExpenses.client.totalMonthly}
              partnerTotalValue={travelExpenses.partner.totalMonthly}
              jointTotalValue={travelExpenses.joint.totalMonthly}
              currency={currency}
              clientId={clientInformation.id}
              detailUrl={`/cashflow/${clientInformation.id}/TravelExpenses?currency=${currency}`}
            />
          </div>

          <div className="portal-part">
            <CashFlowHeaderListingMockup
              title={intl.get(
                "cashflowlisting.personalExpenses.otherInsurancePremium"
              )}
              isJointHide={!isJoint}
              clientTotalValue={otherInsurancePremium.client.totalMonthly}
              partnerTotalValue={otherInsurancePremium.partner.totalMonthly}
              jointTotalValue={otherInsurancePremium.joint.totalMonthly}
              currency={currency}
              clientId={clientInformation.id}
              detailUrl={`/cashflow/${clientInformation.id}/OtherInsurancePremium?currency=${currency}`}
            />
          </div>

          <div className="portal-part">
            <CashFlowHeaderListingMockup
              title={intl.get("cashflowlisting.personalExpenses.petExpenses")}
              isJointHide={!isJoint}
              clientTotalValue={petExpenses.client.totalMonthly}
              partnerTotalValue={petExpenses.partner.totalMonthly}
              jointTotalValue={petExpenses.joint.totalMonthly}
              currency={currency}
              clientId={clientInformation.id}
              detailUrl={`/cashflow/${clientInformation.id}/PetExpenses?currency=${currency}`}
            />
          </div>

          <div className="portal-part">
            <CashFlowHeaderListingMockup
              title={intl.get(
                "cashflowlisting.personalExpenses.personalBusiness"
              )}
              isJointHide={!isJoint}
              clientTotalValue={personalBusiness.client.totalMonthly}
              partnerTotalValue={personalBusiness.partner.totalMonthly}
              jointTotalValue={personalBusiness.joint.totalMonthly}
              currency={currency}
              clientId={clientInformation.id}
              detailUrl={`/cashflow/${clientInformation.id}/PersonalBusinessExpenses?currency=${currency}`}
            />
          </div>

          <div className="portal-part">
            <CashFlowHeaderListingMockup
              title={intl.get(
                "cashflowlisting.personalExpenses.giftsDonations"
              )}
              isJointHide={!isJoint}
              clientTotalValue={giftAndDonations.client.totalMonthly}
              partnerTotalValue={giftAndDonations.partner.totalMonthly}
              jointTotalValue={giftAndDonations.joint.totalMonthly}
              currency={currency}
              clientId={clientInformation.id}
              detailUrl={`/cashflow/${clientInformation.id}/GiftDonations?currency=${currency}`}
            />
          </div>

          <div className="portal-part">
            <CashFlowHeaderListingMockup
              title={intl.get(
                "cashflowlisting.personalExpenses.personalFinanceExpenses"
              )}
              isJointHide={!isJoint}
              clientTotalValue={personalFinanceExpense.client.totalMonthly}
              partnerTotalValue={personalFinanceExpense.partner.totalMonthly}
              jointTotalValue={personalFinanceExpense.partner.totalMonthly}
              currency={currency}
              clientId={clientInformation.id}
              detailUrl={`/cashflow/${clientInformation.id}/PersonalFinanceExpenses?currency=${currency}`}
            />
          </div>

          <div className="portal-part">
            <CashFlowHeaderListingMockup
              title={intl.get(
                "cashflowlisting.personalExpenses.investmentExpenses"
              )}
              isJointHide={!isJoint}
              clientTotalValue={investmentExpenses.client.totalMonthly}
              partnerTotalValue={investmentExpenses.partner.totalMonthly}
              jointTotalValue={investmentExpenses.partner.totalMonthly}
              currency={currency}
              clientId={clientInformation.id}
              detailUrl={`/cashflow/${clientInformation.id}/InvestmentExpenses?currency=${currency}`}
            />
          </div>

          {/* 20201102 MABT-423 */}
          <div className="portal-part">
            <CashFlowHeaderListingMockup
              title={intl.get("cashflowlisting.personalExpenses.taxPayment")}
              clientTotalValue={taxPayment.client.totalMonthly}
              partnerTotalValue={taxPayment.partner.totalMonthly}
              jointTotalValue={taxPayment.joint.totalMonthly}
              currency={currency}
              isJointHide={!isJoint}
              clientId={clientInformation.id}
              detailUrl={`/cashflow/${clientInformation.id}/TaxPayments?currency=${currency}`}
            />
          </div>
        </React.Fragment>
      )}
      <div className="portal-part">
        <CashFlowHeaderListingMockup
          title={"Total Personal Expenses"}
          clientTotalValue={client.totalMonthly}
          partnerTotalValue={partner.totalMonthly}
          jointTotalValue={joint.totalMonthly}
          currency={currency}
          isJointHide={!isJoint}
          clientId={clientInformation.id}
          isTotal
        />
      </div>
    </React.Fragment>
  )
}

export default PersonalExpensesMockup
