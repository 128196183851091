import React from "react"
import intl from "react-intl-universal"
import RenderSimpleFields, {
  FieldType
} from "../../../components/Form/RenderSimpleFields"
import { LanguageList } from "../../../constants/Lists"
import { Form } from "react-final-form"
import { setCurrentLanguage } from "./../../../actions/AppSettingsActions"
import Alert from "react-s-alert"
import { lightOrDark } from "../BurgerMenu/_helper/MenuHelper"
import { logout } from "../../../helpers/SessionHelper"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Logout } from "../../../components"

import { CgEye } from "react-icons/cg"
import { history } from "../../../helpers"
const changeLanguage = (model, forceRefresh) => {
  localStorage.setItem("currentLanguage", model.currentLanguage)
  setCurrentLanguage()
  Alert.success("Successfully set your language.")
  forceRefresh()
}

function LanguageSettingForm({ id, setting, forceRefresh }) {
  const onChangeLanguage = (e) => {
    const model = { currentLanguage: e }
    changeLanguage(model, forceRefresh)
  }

  const fields = [
    {
      label: "",
      name: "currentLanguage",
      type: FieldType.SELECTION,
      selectionData: LanguageList,
      valueProperty: "value",
      labelProperty: "name",
      // required: false,
      showAlways: true,
      onChange: onChangeLanguage
    }
  ]
  return (
    <Form
      onSubmit={(model) => changeLanguage(model, forceRefresh)}
      initialValues={{
        currentLanguage: localStorage.getItem("currentLanguage") || "en-US"
      }}
      render={({ handleSubmit, reset }) => (
        <form
          id="languageSettingForm"
          onSubmit={(event) => {
            event.preventDefault()
            handleSubmit(event)
          }}
        >
          <RenderSimpleFields fields={fields} />
        </form>
      )}
    />
  )
}

const NonClientHeaderMenu = (props) => {
  const {
    onNotificationClick,
    onSettingClick,
    onQuestionClick,
    onUserProfileClick,
    title,
    photoUrl,
    titleBarColour,
    fontColour,
    country,
    firstTimeLogin,
    forceRefresh,
    notification,
    blinkNotification,
    toggleSidebar
  } = props

  const { id } = useParams()
  const { clientId, clientFullNamePreferred, partnerFullNamePreferred } =
    useSelector((state) => state.entity)

  return (
    <div className="header-holder  d-flex ">
      {/* get from state */}
      <div
        className="header-bar  d-flex align-items-center"
        style={{ background: titleBarColour }}
      >
        <div className="header-bar__left">
          <div className={`title w-75 title-${lightOrDark(titleBarColour)}`}>
            {/* country flag */}
            <div className="flexbox">
              <div className="flag-wrapper">
                <div
                  className={`img-thumbnail flag flag-icon-background flag-icon-${country.toLowerCase()}`}
                  title={`${country}`}
                  id={`${country}`}
                ></div>
              </div>
              <div className="flag-title-wrapper">
                <span style={{ color: `${fontColour}` }}>
                  {id && clientId && id === clientId
                    ? `${clientFullNamePreferred} ${
                        partnerFullNamePreferred
                          ? `- ${partnerFullNamePreferred}`
                          : ""
                      }`
                    : title}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="user-control  d-flex justify-content-end align-items-center w-25">
          <CgEye
            className={`icon-${lightOrDark(titleBarColour)}`}
            alt={"View Clients"}
            title={"View Clients"}
            style={{ width: "12px", height: "12px", cursor: "pointer" }}
            onClick={() => {
              history.push("/dashboard/clients")
            }}
          />
          <span
            className={
              blinkNotification ? "fa fa-bell blink" : "fa fa-bell icon-light"
            }
            onClick={onNotificationClick}
            alt={intl.get("headermenu.notification")}
            title={intl.get("headermenu.notification")}
            style={{ cursor: "pointer", paddingLeft: "15px" }}
          />
          <span
            className={`ico-setting`}
            onClick={onSettingClick}
            alt={intl.get("headermenu.settings")}
            title={intl.get("headermenu.settings")}
            style={{ cursor: "pointer" }}
          />
          <span
            className={`ico-question`}
            onClick={onQuestionClick}
            alt={intl.get("headermenu.help")}
            title={intl.get("headermenu.help")}
            style={{ cursor: "pointer" }}
          />
          <span
            className={`ico-avatar`}
            onClick={onUserProfileClick}
            alt={intl.get("headermenu.profile")}
            title={intl.get("headermenu.profile")}
            style={{ cursor: "pointer" }}
          ></span>
          <Logout
            component={
              <span
                className="fa fa-sign-out fa-lg"
                alt={intl.get("leftmenu.logout")}
                title={intl.get("leftmenu.logout")}
                onClick={(e) => logout()}
                style={{ cursor: "pointer" }}
              ></span>
            }
          />

          <div>
            <LanguageSettingForm
              id={JSON.parse(localStorage.getItem("user"))}
              forceRefresh={forceRefresh}
            />
          </div>
          <div className="avatar-img">
            <span className={`ico-avatar `} onClick={onUserProfileClick}>
              <img
                src={photoUrl || "/assets/images/user-profile.jpg"}
                alt="User Profile"
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NonClientHeaderMenu
