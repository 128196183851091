import React from "react"
import NumberFormat from "react-number-format"
import { DECIMAL_SCALE } from "../../constants/NumberFormatConstants"
import { getSymbolFromCode } from "../../helpers"
import { useSelector } from "react-redux"

function LiquityRatioDetails({
  bestdescription: { minValue, maxValue },
  description,
  data,
  value,
  numeratorLabel,
  denominatorLabel,
  numeratorValue,
  denominatorValue,
  inPercentage
}) {
  const { defaultCurrency } = useSelector((state) => state.personalDetails)
  return (
    <div className="portal-form-popup popup-LiquityRatioDetails">
      <div>
        <div className="portal-form-row LiquityRatioDetails-row">
          {description}
        </div>

        <div className="portal-form-row LiquityRatioDetails-row flex-container">
          {/* <div className="col">Best Practice : </div> */}
          <div className="LiquityRatioDetails-description col col-right">
            Best Practice: {minValue}
            {inPercentage ? "%" : ""} to
            {maxValue === 9999 ? " Above" : ` ${maxValue}`}
            {maxValue !== 9999 && inPercentage ? "%" : ""}
            {/* &ge; {maxValue}
            {inPercentage ? "%" : ""} */}
          </div>
        </div>

        <div className="portal-form-row LiquityRatioDetails-row flex-container">
          <div className="col">{numeratorLabel} : </div>
          <div className="LiquityRatioDetails-description col col-right">
            {data && (
              <NumberFormat
                value={numeratorValue}
                disabled
                prefix={getSymbolFromCode(defaultCurrency)}
                decimalScale={DECIMAL_SCALE}
                decimalSeparator={"."}
                thousandSeparator={","}
                className={`text-input currency-input text-input-total`}
              />
            )}
          </div>
        </div>

        <div className="portal-form-row LiquityRatioDetails-row flex-container">
          <div className="col">Divided by {denominatorLabel} : </div>
          <div className="LiquityRatioDetails-description col col-right">
            {data && (
              <NumberFormat
                value={denominatorValue}
                disabled
                prefix={getSymbolFromCode(defaultCurrency)}
                decimalScale={DECIMAL_SCALE}
                decimalSeparator={"."}
                thousandSeparator={","}
                className={`text-input currency-input text-input-total`}
              />
            )}
          </div>
        </div>
        <div style={{ borderBottom: "1px solid #000000" }} />
        <div className="portal-form-row LiquityRatioDetails-row flex-container">
          <div className="col liquid-ratio-text">
            <strong>Value :</strong>
          </div>
          <div className="LiquityRatioDetails-description col col-right">
            {value}
            {inPercentage ? "%" : ""}
          </div>
        </div>
      </div>
    </div>
  )
}
export default LiquityRatioDetails
