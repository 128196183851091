import React, { useState, useEffect } from "react"

const ClassificationSelector = ({
  title,
  client,
  partner,
  isJoint,
  changeHandler,
  show,
  insurance,
  forInsurance = false
}) => {
  const [selected, setSelected] = useState("")
  useEffect(() => {
    setSelected(isJoint ? "COMBINED" : "CLIENT")
  }, [isJoint])

  const onChange = (value) => {
    if (selected !== value) {
      setSelected(value)
      if (changeHandler) {
        changeHandler(value)
      }
    }
  }

  return (
    <div
      className={`content-panel-header ${
        forInsurance && "insurance-content-panel-header"
      }`}
    >
      <h1>{title}</h1>
      <>
        {show && isJoint && (
          <span
            className={`icon-holder icon-holder-joint ${
              insurance ? "icon-holder-joint-1" : ""
            } ${selected === "COMBINED" ? "selected" : ""}`}
            onClick={() => onChange("COMBINED")}
          >
            <div className="icon ico-couple" />
            <div className="text">Combined</div>
          </span>
        )}
        {show && isJoint && (
          <span
            className={`icon-holder icon-holder-joint ${
              selected === "JOINT" ? "selected" : ""
            }`}
            onClick={() => onChange("JOINT")}
          >
            <div className="icon ico-couple" />
            <div className="text">Joint</div>
          </span>
        )}

        {show && (
          <span
            className={`icon-holder icon-holder-client-${
              partner ? "haspartner" : "nopartner"
            } ${selected === "CLIENT" ? "selected" : ""}`}
            onClick={() => onChange("CLIENT")}
          >
            <div className="icon ico-single" />
            <div className="text">Client</div>
          </span>
        )}
        {show && partner && (
          <span
            className={`icon-holder icon-holder-partner ${
              selected === "PARTNER" ? "selected" : ""
            }`}
            onClick={() => onChange("PARTNER")}
          >
            <div className="icon ico-single" />
            <div className="text">Partner</div>
          </span>
        )}
      </>
    </div>
  )
}

export default ClassificationSelector
