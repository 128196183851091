import React, { useState, useEffect } from "react"
import intl from 'react-intl-universal';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import Alert from 'react-s-alert';
import moment from "moment";
import {
    renderTextBoxCustom,
    renderSelectEnumFieldCustom,
    renderDateFieldCustom,
    renderPercentageCustom,
    renderCurrencyCustom,
    getSymbolFromCode,
} from '../../../helpers';

const AdditionalInvestmentTopUpPlan = ({
    values,
    optionsData,
    addPlan,
    removePlan,
    setFormAttribute
}) => {
    const [ccy, setCcy] = useState(values.investmentReturnsTradingCurrency?values.investmentReturnsTradingCurrency:localStorage.getItem("theCurrency"));
    const [ccySymbol, setCcySymbol] = useState(getSymbolFromCode(ccy))
    function validateNewRow(){
        let result = true;
        let topUpPlan = values.topUpPlan;
        let rowNo = 1;
        let errorText = "";
        if(topUpPlan && topUpPlan.length>0){
            let size = topUpPlan.length;    
            for (let index = 0; index < size; index++) {
                const a = topUpPlan[index];
                let rowErrors = "";
              
                if(!a.amount){
                    if(rowErrors.length==0){
                        rowErrors = "Amount";
                    }else{
                        rowErrors = rowErrors + ", " + "Amount";
                    }
                }
                if(!a.frequency){
                    if(rowErrors.length==0){
                        rowErrors = "Frequency";
                    }else{
                        rowErrors = rowErrors + ", " + "Frequency";
                    }
                }
                if(!a.startDate){
                    if(rowErrors.length==0){
                        rowErrors = "Start Date";
                    }else{
                        rowErrors = rowErrors + ", " + "Start Date";
                    }
                }

                if(!a.endDate){
                    if(rowErrors.length==0){
                        rowErrors = "End Date";
                    }else{
                        rowErrors = rowErrors + ", " + "End Date";
                    }
                }
               
               
                if(rowErrors.length>0){
                    let msg = `Row-${rowNo}: ${rowErrors} required`;
                    if(errorText.length==0){
                        errorText = msg;
                    }else{
                        errorText = errorText + ", " + msg;
                    } 
                }
               
                rowNo++;
            }


        }
       
        if(errorText && errorText.length>0){
            Alert.error(`Top Up ${errorText}`);
            result = false;
        }
        return result;
    }

    function getElementAt(searchIndex){
        let result;
        if(values && values.topUpPlan){
            for (let index = 0; index < values.topUpPlan.length; index++) {
                let element = values.topUpPlan[index];
                if(index==searchIndex){
                    result = element
                }
                
            }
        }
        return result;
    }
    
    function onAddButtonClicked(e){
        let topUpPlan = values.topUpPlan;
        let size = topUpPlan && topUpPlan.length>0?topUpPlan.length:0;    
        if(validateNewRow()){
            let newModel = {
                description: `Topup-${size +1}`,
                amount: 0,
                frequency:'ONE_OFF',
                startDate:undefined,
                endDate:undefined,
                fee:0
            }
            addPlan('topUpPlan', newModel);
        }
       
    }


    return (
        <>
           <div className="portal-form-row text-right">
                <span
                    className="btn btn-plus btn-plus-grey"
                    onClick={(e) => onAddButtonClicked(e)}
                />
            </div>
            <table
                className="table-blue"
                width="100%"
                cellSpacing={0}
                cellPadding={0}
                border={0}
            >
                <tbody>
                    <tr>
                        <th className="ilas-description">{intl.get('ilasInvestment.description')}</th>
                        <th className="ilas-amount">
                            {intl.get('ilasInvestment.amount')}
                        </th>
                        <th className="ilas-frequency">{intl.get('ilasInvestment.frequency')}</th>
                        <th className="ilas-startDate">{intl.get('ilasInvestment.startDate')}</th>
                        <th className="ilas-endDate">{intl.get('ilasInvestment.endDate')}</th>
                        <th className="ilas-amount">{intl.get('ilasInvestment.fee')}</th>
                       
                        <th>&nbsp;</th>
                    </tr>
                    <FieldArray name="topUpPlan">
                        {({ fields }) =>
                            fields.map((name, index) => {
                                return (
                                    <tr key={index}>
                                        <td style={{width:"20%"}} >
                                            <Field
                                                name={`${name}.description`}
                                                component={renderTextBoxCustom}
                                            />
                                        </td>
                                        <td style={{width:"15%"}} >
                                            <Field
                                                name={`${name}.amount`}
                                                defaultCurrency={ccy}
                                                symbolPrefix= {ccySymbol}
                                                component={renderCurrencyCustom}
                                            />
                                        </td>
                                        <td style={{width:"20%"}} >
                                            <Field
                                                name={`${name}.frequency`}
                                                component={
                                                    renderSelectEnumFieldCustom
                                                }
                                                enumData={
                                                    optionsData.frequencyOptions
                                                }
                                                
                                            />
                                        </td>
                                        <td style={{width:"10%"}} >
                                            <Field
                                                name={`${name}.startDate`}
                                                component={
                                                    renderDateFieldCustom
                                                }
                                                isForGrid
                                            />
                                        </td>
                                        <td style={{width:"10%"}} >
                                            <Field
                                                name={`${name}.endDate`}
                                                component={
                                                    renderDateFieldCustom
                                                }
                                                isForGrid
                                                disabled={getElementAt(index).frequency=="ONE_OFF"}
                                            />
                                        </td>
                                        <td style={{width:"15%"}} >
                                            <Field
                                                name={`${name}.fee`}
                                                defaultCurrency={ccy}
                                                symbolPrefix= {ccySymbol}
                                                component={renderCurrencyCustom}
                                            />
                                        </td>
                                        <td style={{width:"5%"}} >
                                            <span
                                                className="btn-remove"
                                                onClick={() => {
                                                    removePlan(
                                                        'topUpPlan',
                                                        index
                                                    );
                                                }}
                                            />
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </FieldArray>
                </tbody>
            </table>
        </>
    );
};

export default AdditionalInvestmentTopUpPlan;
