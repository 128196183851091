import React from 'react';

function TextboxInput({ input, meta: { touched, error }, ...props }) {
    return (
        <div
            className={`d-flex flex-column ${
                touched && error ? 'itemvalidate bad' : ''
            }`}
        >
            <input {...input} {...props} type="text" className="text-input" />
            {touched && (error && <span className="alert">{error}</span>)}
        </div>
    );
}

export default TextboxInput;
