/*
    Author : Erik Farhan Malik (erikfarhanmalik@gmail.com)
    Description : Mortgage details form
*/

import React from 'react';
import intl from 'react-intl-universal';
import RenderSimpleFields, {
    FieldType,
} from '../../components/Form/RenderSimpleFields';

const CpfDetails = ({ optionsData }) => {
    const {
        loanInterestTypeOptions,
        frequencyOptions,
        currencyOptions,
    } = optionsData;

    return (
        <div className="pop-holder">
            <RenderSimpleFields
                fields={[
                    {
                        name: 'cpf.financialInstitutionName',
                        label: intl.get('field.financialInstitutionName'),
                        type: FieldType.TEXT,
                    },
                    {
                        name: 'cpf.borrowingsCurrency',
                        label: intl.get('field.loan.borrowingsCurrency'),
                        type: FieldType.ENUM_SELECTION,
                        enumData: currencyOptions,
                    },
                  
                    {
                        name: 'cpf.borrowingsPrinciple',
                        label: intl.get('field.loan.borrowingsPrinciple'),
                        type: FieldType.MONEY,
                        allowNegative: false, 
                    },
                    {
                        label: intl.get('field.loan.borrowingsStartDate'),
                        name: 'cpf.borrowingsStartDate',
                        type: FieldType.DATE,
                    },
                    {
                        name: 'cpf.borrowingsTermInYears',
                        label: intl.get('field.loan.borrowingsTermInYears'),
                        type: FieldType.TEXT,
                    },
                    {
                        name: 'cpf.borrowingsAnnualInterestRate',
                        label: intl.get(
                            'field.loan.borrowingsAnnualInterestRate'
                        ),
                        type: FieldType.PERCENTAGE,
                    },
                    {
                        name: 'cpf.borrowingsRepaymentFrequency',
                        label: intl.get(
                            'field.loan.borrowingsRepaymentFrequency'
                        ),
                        type: FieldType.ENUM_SELECTION,
                        enumData: frequencyOptions,
                    },
                    {
                        name: 'cpf.currentBorrowingsBalance',
                        label: intl.get('field.loan.currentBorrowingsBalance'),
                        type: FieldType.MONEY,
                        allowNegative: false, 
                    },
                    {
                        name: 'cpf.valuationDate',
                        label: intl.get('field.valuationDate'),
                        type: FieldType.DATE,
                    },
                    {
                        name: 'cpf.amountBorrowedFromTheOrdinaryAccount',
                        label: intl.get(
                            'field.loan.amountBorrowedFromTheOrdinaryAccount'
                        ),
                        type: FieldType.MONEY,
                        allowNegative: false, 
                    },
                ]}
            />
        </div>
    );
};

export default CpfDetails;
