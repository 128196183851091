import Login from './Login';
import Page404 from './Page404';
import Page500 from './Page500';
import Register from './Register';
import Chart from './Chart';

import PersonalDetails from './PersonalDetails/PersonalDetails';

import NetWorth from './NetWorth/NetWorth';
import BusinessOwnership from './NetWorth/BusinessOwnership';
import CollectibleAntiqueArts from './NetWorth/CollectibleAntiqueArts';
import ResidenceProperty from './NetWorth/ResidenceProperty';
import PersonalContents from './NetWorth/PersonalContents';
import MotorVehicle from './NetWorth/MotorVehicle';
import InvestmentProperty from './NetWorth/InvestmentProperty';
import Bond from './NetWorth/Bond';
import UnitTrust from './NetWorth/UnitTrust';
import Share from './NetWorth/Share';
import TermDeposit from './NetWorth/TermDeposit';
import InvestmentPlatform from './NetWorth/InvestmentPlatform';

import GEALCountry from './NetWorth/GEALCountry';
import GEALAustralia from './NetWorth/GEALAustralia';
import GEALNewZealand from './NetWorth/GEALNewZealand';
import GEALSingapore from './NetWorth/GEALSingapore';
import GEALHongkong from './NetWorth/GEALHongkong';
import GEALMalaysia from './NetWorth/GEALMalaysia';

import AdviserClientsDashboard from './Dashboard/Adviser/AdviserClientsDashboard';
import AdviserDashboard from './Dashboard/Adviser/AdviserDashboard';
import AdviserGroupAdvisersDashboard from './Dashboard/Adviser/AdviserGroupAdvisersDashboard';
import AdviserGroupClientsDashboard from './Dashboard/Adviser/AdviserGroupClientsDashboard';
import AdviserGroupSubBranchDashboard from './Dashboard/Adviser/AdviserGroupSubBranchDashboard';
import AdviserWeeklySalesLodgedDashboard from './Dashboard/Adviser/AdviserWeeklySalesLodgedDashboard';

import GlobalDashboard from './Dashboard/GlobalDashboard';
import MainDashboard from './Dashboard/MainDashboard';
import ClientDashboard from './Dashboard/ClientDashboard';
import Entity from './Entity/Entity';
import UserForm from './User/UserForm';

import CashFlow from './CashFlow/CashFlow';
import Insurance from './Insurance/Insurance';
import LifeLine from './CurrentStanding/LifeLine';
import KeyFinancialRatios from './CurrentStanding/KeyFinancialRatios';



export {
    Login,
    Page404,
    Page500,
    Register,
    Chart,
    PersonalDetails,
    // net worth pages
    NetWorth,
    BusinessOwnership,
    CollectibleAntiqueArts,
    PersonalContents,
    ResidenceProperty,
    MotorVehicle,
    InvestmentProperty,
    Bond,
    UnitTrust,
    Share,
    TermDeposit,
    InvestmentPlatform,
    // dashboard pages
    AdviserClientsDashboard,
    AdviserDashboard,
    AdviserGroupAdvisersDashboard,
    AdviserGroupClientsDashboard,
    AdviserGroupSubBranchDashboard,
    AdviserWeeklySalesLodgedDashboard,
    GlobalDashboard,
    MainDashboard,
    ClientDashboard,
    // dashboard modal pages
    Entity,
    UserForm,
    // cash flow pages
    CashFlow,
    // GEAL
    GEALCountry,
    GEALAustralia,
    GEALNewZealand,
    GEALSingapore,
    GEALHongkong,
    GEALMalaysia,
    // insurance pages
    Insurance,
    // current standing
    LifeLine,
    KeyFinancialRatios
};
