import api from "../lib/api"

// change base route for different entity/domain here
const NEW_BASE_ROUTE = "/investment-provider"

class InvestmentBuilderApi {
  static invite(clientId) {
    return api.post(`/investment-account/${clientId}/account-invitation`, {})
  }

  static getInvestmentPlatform() {
    return api.get(`${NEW_BASE_ROUTE}/providers`)
  }

  static getRegisteredAdviserAccounts(ip) {
    return api.get(`${NEW_BASE_ROUTE}/registered-accounts?provider=${ip}`)
  }
  static getRegisteredDealershipAccounts(ip) {
    return api.get(
      `${NEW_BASE_ROUTE}/registered-accounts/dealership?provider=${ip}`
    )
  }

  static getAdviserAccounts(ip) {
    return api.get(`${NEW_BASE_ROUTE}/adviser-accounts?provider=${ip}`)
  }

  static checkAdviserAccount(adviserBrokerAccount) {
    return api.post(
      `${NEW_BASE_ROUTE}/check-adviser-account`,
      adviserBrokerAccount
    )
  }

  static saveAdviserAccounts(adviserBrokerAccounts) {
    return api.post(`${NEW_BASE_ROUTE}/save-advisers`, adviserBrokerAccounts)
  }

  static getSubAccounts(model) {
    return api.post(`${NEW_BASE_ROUTE}/adviser-sub-accounts`, model)
  }

  static searchInvestment(model) {
    return api.post(`${NEW_BASE_ROUTE}/search-name`, model)
  }

  static getInvestmentDetail(model) {
    return api.post(`${NEW_BASE_ROUTE}/market-data`, model)
  }

  static getContractDetail(model) {
    return api.post(`${NEW_BASE_ROUTE}/market-history`, model)
  }

  static getPortfolioHistoryCash(model) {
    return api.post(`${NEW_BASE_ROUTE}/client-cash-history`, model)
  }

  static getPortfolioHistoryPosition(model) {
    return api.post(`${NEW_BASE_ROUTE}/client-position-history`, model)
  }

  static accountPortfolio(model) {
    return api.post(`${NEW_BASE_ROUTE}/client-portfolio`, model)
  }

  static getPortfolioHistory(model) {
    return api.post(`${NEW_BASE_ROUTE}/client-portfolio-history`, model)
  }

  static transaction(model) {
    return api.post(`${NEW_BASE_ROUTE}/client-transaction`, model)
  }

  static confirm(model) {
    return api.post(`${NEW_BASE_ROUTE}/client-confirm-transaction`, model)
  }

  static orders(model) {
    return api.post(`${NEW_BASE_ROUTE}/client-orders`, model)
  }

  static trades(clientId, model) {
    return api.post(`${NEW_BASE_ROUTE}/client-trades`, model)
  }

  static logout(model) {
    return api.post(`${NEW_BASE_ROUTE}/adviser-logout`, model)
  }
}

export default InvestmentBuilderApi
