import React, { useState, useEffect } from "react"
import intl from "react-intl-universal"
import { Field, Form } from "react-final-form"
import { FormButtons, ModalNoAccess } from "../components"
import ClassificationSelection from "../components/_hook/ClassificationSelection"
import RenderSimpleFields, {
  FieldType
} from "../components/Form/RenderSimpleFields"
import { fileFieldRender, getSymbolFromCode } from "../helpers"

import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from "react-accessible-accordion"
import AssetRiskClassificationApi from "../api/AssetRiskClassificationApi"

const CashAccountForm = ({
  handleSave,
  model,
  optionsData,
  readOnlyAccess,
  clientInformation: { client = {}, partner = {}, isJoint },
  subFormModel
}) => {
  model = subFormModel ? subFormModel : model

  if (!model.accountType) {
    model.accountType = "SAVINGS"
  }
  const [productTypes, setProductTypes] = useState([])
  const [currency, setcurrency] = useState(model.accountCurrency || "MYR")

  useEffect(() => {
    AssetRiskClassificationApi.getProductTypes("CASH").then(({ data }) => {
      if (data) {
        setProductTypes(data.data)
      }
    })
    if (!!model.accountCurrency && currency !== model.accountCurrency) {
      setcurrency(model.accountCurrency)
    }
  }, [model.accountCurrency])

  if (readOnlyAccess) {
    return <ModalNoAccess />
  }

  const fields = [
    {
      name: "accountNumber",
      label: intl.get("field.uniqueCode"),
      type: FieldType.TEXT,
      required: true
    },
    {
      name: "financialInstitutionName",
      label: intl.get("field.financialInstitutionName"),
      type: FieldType.TEXT,
      required: false
    },
    {
      name: "accountName",
      label: intl.get("field.accountName"),
      type: FieldType.TEXT,
      required: false
    },
    {
      name: "accountType",
      label: intl.get("field.accountType"),
      type: FieldType.ENUM_SELECTION,
      enumData: optionsData.cashAccountTypeOptions,
      required: false
    },
    {
      name: "productTypeCode",
      label: intl.get("field.productType"),
      type: FieldType.SELECTION,
      selectionData: productTypes,
      valueProperty: "code",
      labelProperty: "name",
      required: true
    },
    {
      name: "managedBy",
      label: intl.get("field.managedBy"),
      type: FieldType.ENUM_SELECTION,
      enumData: optionsData.managedByOptions,
      required: false
    },
    {
      name: "accountCurrency",
      label: intl.get("field.accountCurrency"),
      type: FieldType.ENUM_SELECTION,
      enumData: optionsData.currencyOptions,
      required: false,
      onChange: (value) => {
        setcurrency(value)
      }
    },
    {
      name: "accountBalance",
      type: FieldType.MONEY,
      label: intl.get("field.accountBalance"),
      required: true,
      symbolPrefix: getSymbolFromCode(currency),
      allowNegative: false
    },

    {
      name: "annualInterestRate",
      label: intl.get("field.annualInterestRate"),
      type: FieldType.PERCENTAGE,
      required: true
    },
    {
      name: "primary",
      label: intl.get("field.isPrimaryBankAccount"),
      checkboxLabel: intl.get("YesNoEnum_YES"),
      type: FieldType.CHECKBOX
    },
    {
      name: "validationDate",
      label: intl.get("field.valuationDate"),
      type: FieldType.DATE,
      required: true
    }
  ]

  const tableMovementHistories = () => {
    if (model && model.cashMovementHistories) {
      return (
        <Accordion>
          <AccordionItem>
            <AccordionItemTitle>
              <div className="subtitle2b">
                Movement Histories
                <div className="accordion__arrow" />
              </div>
            </AccordionItemTitle>
            <AccordionItemBody>
              <table className="table-blue " width="75%">
                <tbody>
                  <tr>
                    <th width="40%">Event Time</th>
                    <th width="20%">Type</th>
                    <th width="10%">Description</th>
                    <th width="10%">amount</th>
                    <th width="10%">Reference Id</th>
                  </tr>
                  {getMovementHistoryRows()}
                </tbody>
              </table>
            </AccordionItemBody>
          </AccordionItem>
        </Accordion>
      )
    } else {
      return <></>
    }
  }

  const getMovementHistoryRows = () => {
    if (model && model.cashMovementHistories) {
      return model.cashMovementHistories.map((item, index) => (
        //add asset
        <tr key={index}>
          <td>{item.eventDate}</td>
          <td>{item.type}</td>
          <td>{item.description}</td>
          <td>{item.amount}</td>
          <td>{item.refId}</td>
        </tr>
      ))
    }

    return (
      <tr>
        <td colSpan="13">no data</td>
      </tr>
    )
  }

  return (
    <Form
      onSubmit={handleSave}
      initialValues={model}
      mutators={{
        setFormAttribute: ([fieldName, fieldVal], state, { changeValue }) => {
          changeValue(state, fieldName, () => fieldVal)
        }
      }}
      render={({ handleSubmit, reset, mutators: { setFormAttribute } }) => (
        <form id="termDepositForm" onSubmit={handleSubmit}>
          <div className="portal-form-popup">
            <ClassificationSelection {...{ client, partner, isJoint }} />
            <div className="subtitle2">
              {intl.get("cashflowlisting.personalIncome.cashAccountDetails")}
            </div>
            <RenderSimpleFields fields={fields} />
            <Field
              component={fileFieldRender}
              customClassName="mt-1 d-block"
              labelIdle="Drag, Drop or Browse"
              label="Upload Documents"
              onupdatefiles={(fileItems) => {
                setFormAttribute("proofDocument", fileItems[0])
              }}
              fileInformation={(model && model.proofDocument) || null}
            />
            {tableMovementHistories()}
          </div>
          <FormButtons reset={reset} readOnlyAccess={readOnlyAccess} />
        </form>
      )}
    />
  )
}

export default CashAccountForm
