import React, { } from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { enumOptionsActions } from "../../../actions";
import { FormButtons, RenderSimpleFields } from "../../../components";
import { FieldType } from "../../../components/Form/RenderSimpleFields";
import arrayMutators from 'final-form-arrays';
import { getSymbolFromCode } from "../../../helpers";
import CPFClassification from "./CPFClassification";

const CPFCashAccount = ({ optionsData, model = {}, handleSave }) => {
    if (!model.accountCurrency) {
        Object.assign(model, { accountCurrency: 'SGD' })
    }
    return (
        <div>
            <Form
                onSubmit={handleSave}
                initialValues={model}
                mutators={{
                    // potentially other mutators could be merged here
                    ...arrayMutators,
                }}
                render={({
                    handleSubmit,
                    reset,
                    values
                }) => (
                        <form
                            id="cpfCashAccount"
                            onSubmit={handleSubmit}
                            noValidate
                        >
                            <div className="portal-form-popup">
                                <CPFClassification value={model.classification} disabled />
                                <div className="subtitle2">
                                    Cash Account Details
                                </div>
                                <RenderSimpleFields
                                    fields={[
                                        {
                                            name: 'financialInstitutionName',
                                            label: 'Financial Institution Name',
                                            type: FieldType.TEXT,
                                        },
                                        {
                                            name: 'accountName',
                                            label: 'Account Name',
                                            type: FieldType.TEXT,
                                        },
                                        {
                                            name: 'accountNumber',
                                            label: 'Account Number',
                                            type: FieldType.TEXT,
                                        },
                                        {
                                            name: 'accountType',
                                            label: 'Account Type',
                                            type: FieldType.ENUM_SELECTION,
                                            enumData: optionsData.cashAccountTypeOptions,
                                        },
                                        {
                                            name: 'accountBalance',
                                            label: 'Account Balance',
                                            type: FieldType.MONEY,
                                            symbolPrefix: getSymbolFromCode(values.accountCurrency),
                                        },
                                        {
                                            name: 'valuationDate',
                                            label: 'Valuation Date',
                                            type: FieldType.DATE,
                                        },
                                        {
                                            name: 'accountCurrency',
                                            label: 'Account Currency',
                                            type: FieldType.ENUM_SELECTION,
                                            enumData: optionsData.currencyOptions,
                                        },
                                        {
                                            name: 'annualInterestRate',
                                            label: 'Annual Interest Rate',
                                            type: FieldType.PERCENTAGE,
                                        },
                                    ]}
                                />

                            </div>
                            <FormButtons
                                reset={reset}
                            />
                        </form>
                    )}
            />
        </div>


    )
}


function mapStateToProps(state) {
    const { optionsData } = state.enumOptions;

    return {
        optionsData,
    };
}

function mapDispatchToProps(dispatch) {

    return bindActionCreators(enumOptionsActions, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CPFCashAccount);