/*
    Author : Marlina (marlina@devstack.com.au)
    Description : Selection menu for feedback category
*/
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { FeedbackCategoryEnum } from '../../../constants/Enums';

class FeedbackSelectionMenu extends Component {
    state = {
        checkVal: this.props.value,
    };

    // eslint-disable-next-line react/sort-comp
    handleCheck = selected => {
        this.setState(
            {
                checkVal: selected,
            },
            () => {
                this.props.onToggle(this.state.checkVal);
                this.props.onChange(this.state.checkVal);
            }
        );
    };

    componentWillMount() {
        this.handleCheck(this.props.value);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.handleCheck(nextProps.value);
        }
    }

    render() {
        const { checkVal } = this.state;
        const { name } = this.props;
        const { handleCheck } = this;

        return (
            <React.Fragment>
                <input
                    {...this.props.input}
                    type={'hidden'}
                    name={name}
                    value={checkVal}
                />
                <div className="feedback-icon-holder">
                    <span
                        className="feedback-icon ico-technicalissue"
                        onClick={() =>
                            handleCheck(FeedbackCategoryEnum.TECHNICAL_ISSUE)
                        }
                    >
                        <div className="text">
                            {intl.get('FeedbackCategoryEnum_TECHNICAL_ISSUE')}
                        </div>
                    </span>
                    <span
                        className="feedback-icon ico-facehappy"
                        onClick={() =>
                            handleCheck(FeedbackCategoryEnum.LIKE_SOMETHING)
                        }
                    >
                        <span className="text">
                            {intl.get('FeedbackCategoryEnum_LIKE_SOMETHING')}
                        </span>
                    </span>
                    <span
                        className="feedback-icon ico-facesad"
                        onClick={() =>
                            handleCheck(FeedbackCategoryEnum.DISLIKE_SOMETHING)
                        }
                    >
                        <span className="text">
                            {intl.get('FeedbackCategoryEnum_DISLIKE_SOMETHING')}
                        </span>
                    </span>
                    <span
                        className="feedback-icon ico-suggestion"
                        onClick={() =>
                            handleCheck(FeedbackCategoryEnum.SUGGESTION)
                        }
                    >
                        <span className="text">
                            {intl.get('FeedbackCategoryEnum_SUGGESTION')}
                        </span>
                    </span>
                    <span
                        className="feedback-icon ico-others"
                        onClick={() => handleCheck(FeedbackCategoryEnum.OTHER)}
                    >
                        <span className="text">
                            {intl.get('FeedbackCategoryEnum_OTHER')}
                        </span>
                    </span>
                </div>
            </React.Fragment>
        );
    }
}

export default FeedbackSelectionMenu;
