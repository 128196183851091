/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Description : Margin Loan details form
*/

import React from 'react';
import intl from 'react-intl-universal';
import RenderSimpleFields, {
    FieldType,
} from '../../components/Form/RenderSimpleFields';

const MarginLoanDetails = ({ optionsData }) => {
    const {
        loanInterestTypeOptions,
        frequencyOptions,
        currencyOptions,
    } = optionsData;
    return (
        <div className="pop-holder">
            <RenderSimpleFields
                fields={[
                    {
                        name: 'marginLoan.financialInstitutionName',
                        label: intl.get('field.financialInstitutionName'),
                        type: FieldType.TEXT,
                        required: true,    
                    },
                    {
                        name: 'marginLoan.borrowingsCurrency',
                        label: intl.get('field.loan.borrowingsCurrency'),
                        type: FieldType.ENUM_SELECTION,
                        enumData: currencyOptions,
                        required: true,    
                    },
                    {
                        name: 'marginLoan.borrowingsPrinciple',
                        label: intl.get('field.loan.borrowingsPrinciple'),
                        type: FieldType.MONEY,
                        required: true,         
                        allowNegative: false,  
                    },
                   
                    {
                        name: 'marginLoan.borrowingsAnnualInterestRate',
                        label: intl.get(
                            'field.loan.borrowingsAnnualInterestRate'
                        ),
                        type: FieldType.PERCENTAGE,
                        required: true,        
                    },
                    {
                        label: intl.get('field.loan.borrowingsStartDate'),
                        name: 'marginLoan.borrowingsStartDate',
                        type: FieldType.DATE,
                        required: true,     
                    },
                    {
                        name: 'marginLoan.borrowingsTermInYears',
                        label: intl.get('field.loan.borrowingsTermInYears'),
                        type: FieldType.TEXT,
                        required: true,     
                    },
                    {
                        name: 'marginLoan.inArrear',
                        label: intl.get('field.inArrear'),
                        checkboxLabel: intl.get('YesNoEnum_YES'),
                        type: FieldType.CHECKBOX,
                    },
                    {
                        name: 'marginLoan.borrowingsRepaymentFrequency',
                        label: intl.get(
                            'field.loan.borrowingsRepaymentFrequency'
                        ),
                        type: FieldType.ENUM_SELECTION,
                        enumData: frequencyOptions,
                        required: true,        
                    },
                    {
                        name: 'marginLoan.currentBorrowingsBalance',
                        label: intl.get('field.loan.currentBorrowingsBalance'),
                        type: FieldType.MONEY,
                        required: true,    
                        allowNegative: false,
                    },
                    {
                        name: 'marginLoan.additionalRepaymentHandlingFees',
                        label: intl.get(
                            'field.additionalRepaymentHandlingFees'
                        ),
                        type: FieldType.MONEY,
                        allowNegative: false,
                    },
                    {
                        name: 'marginLoan.additionalRepaymentFrequency',
                        label: intl.get('field.additionalRepaymentFrequency'),
                        type: FieldType.ENUM_SELECTION,
                        enumData: frequencyOptions,
                    },
                    {
                        label: intl.get('field.nextAdditionalRepaymentDate'),
                        name: 'marginLoan.additionalRepaymentNextPaymentDate',
                        type: FieldType.DATE,
                    },
                    {
                        name: 'marginLoan.borrowingsManagementFees',
                        label: intl.get('field.loan.borrowingsManagementFees'),
                        type: FieldType.MONEY,
                        allowNegative: false,
                    },
                    {
                        name: 'marginLoan.borrowingsManagementFeesFrequency',
                        label: intl.get(
                            'field.loan.borrowingsManagementFeesFrequency'
                        ),
                        type: FieldType.ENUM_SELECTION,
                        enumData: frequencyOptions,
                    },
                    {
                        label: intl.get(
                            'field.loan.borrowingsManagementFeesNextPaymentDate'
                        ),
                        name:
                            'marginLoan.borrowingsManagementFeesNextPaymentDate',
                        type: FieldType.DATE,
                    },
                ]}
            />
        </div>
    );
};

export default MarginLoanDetails;
