import React from "react"
import intl from "react-intl-universal"
import {
  HospitalCoverTypeEnum,
  PeopleCoveredEnum,
  PolicyAreaCoveredEnum,
  FrequencyEnum,
  InvestmentFrequencyEnum,
  GeneralInsuranceTypeEnum,
  InsurancePolicyTypeEnum,
  CoverageTypeEnum,
  InsuranceRiderTypeEnum,
  GeneralClassificationEnum,
  CurrencyEnum,
  TopUpRedemptionGoalFrequencyEnum,
  SalutationEnum,
  GenderEnum,
  RaceEnum,
  MaritalStatusEnum,
  YesNoEnum,
  ClientCategoryTypeEnum,
  ReligionTypeEnum,
  ResidencyStatusEnum,
  EmploymentStatusEnum,
  QualificationEnum,
  CpfEmploymentClassificationEnum,
  TaxPaymentPreferenceEnum,
  ResidentialTenureEnum,
  ChildrenRelationshipTypeEnum,
  SiblingRelationshipTypeEnum,
  ParentRelationshipTypeEnum,
  SchoolLevelEnum,
  LivingWithEnum,
  BenefitPeriodEnum,
  PropertyTypeEnum,
  LeaseTypeEnum,
  LoanInterestTypeEnum,
  LoanFrequencyEnum,
  CriticalIllnessPolicyTypeEnum,
  PensionContributionTypeEnum,
  SalaryTypeEnum,
  sectionCategoryTypeEnum,
  IfastDataTypes,
  AccessLevelEnum,
  EntityTypeEnum,
  CompanyScopeEntityTypeEnum,
  clientInvestorTypeEnum,
  RelationshipTypeEnum,
  ClientCategoryExceptArchiveTypeEnum,
  ManagedByEnum
} from "../constants/Enums"

export const getEnumOptions = (enumData) => {
  const enumOptionStr = []
  Object.keys(enumData).forEach((key) => {
    enumOptionStr.push(
      <option key={`enum-${key}`} value={enumData[key]}>
        {intl.get(key)}
      </option>
    )
  })
  return enumOptionStr
}

export const getEnumOptionsNoTranslation = (enumData) => {
  const enumOptionStr = []
  Object.keys(enumData).forEach((key) => {
    let val = enumData[key];
    enumOptionStr.push(
      <option key={`enum-${key}`} value={val}>
        {val}
      </option>
    )
  })
  return enumOptionStr
}

export const optionsType = {
  hospitalCoverTypeOptions: {
    type: "hospitalCoverTypeOptions",
    enum: HospitalCoverTypeEnum
  },
  peopleCoveredOptions: {
    type: "peopleCoveredOptions",
    enum: PeopleCoveredEnum
  },
  policyAreaCoveredOptions: {
    type: "policyAreaCoveredOptions",
    enum: PolicyAreaCoveredEnum
  },
  frequencyOptions: {
    type: "frequencyOptions",
    enum: FrequencyEnum
  },
  investmentFrequencyOptions: {
    type: "investmentFrequencyOptions",
    enum: InvestmentFrequencyEnum
  },
  generalInsuranceTypeOptions: {
    type: "generalInsuranceTypeOptions",
    enum: GeneralInsuranceTypeEnum
  },
  insurancePolicyTypeOptions: {
    type: "insurancePolicyTypeOptions",
    enum: InsurancePolicyTypeEnum
  },
  criticalIllnessPolicyTypeOptions: {
    type: "criticalIllnessPolicyTypeOptions",
    enum: CriticalIllnessPolicyTypeEnum
  },
  coverageTypeOptions: {
    type: "coverageTypeOptions",
    enum: CoverageTypeEnum
  },
  insuranceRiderTypeOptions: {
    type: "insuranceRiderTypeOptions",
    enum: InsuranceRiderTypeEnum
  },
  generalClassificationOptions: {
    type: "generalClassificationOptions",
    enum: GeneralClassificationEnum
  },
  currencyOptions: { type: "currencyOptions", enum: CurrencyEnum },
  topUpRedemptionGoalFrequencyOptions: {
    type: "topUpRedemptionGoalFrequencyOptions",
    enum: TopUpRedemptionGoalFrequencyEnum
  },
  salutationOptions: {
    type: "salutationOptions",
    enum: SalutationEnum
  },
  genderOptions: {
    type: "genderOptions",
    enum: GenderEnum
  },
  raceOptions: {
    type: "raceOptions",
    enum: RaceEnum
  },
  maritalStatusOptions: {
    type: "maritalStatusOptions",
    enum: MaritalStatusEnum
  },
  yesNoOptions: {
    type: "yesNoOptions",
    enum: YesNoEnum
  },
  ifastTypes: {
    type: "ifastTypes",
    enum: IfastDataTypes
  },
  recommendationSectionCategory: {
    type: "recommendationSectionCategory",
    enum: sectionCategoryTypeEnum
  },

  religionTypeOptions: {
    type: "religionTypeOptions",
    enum: ReligionTypeEnum
  },

  residencyStatusOptions: {
    type: "residencyStatusOptions",
    enum: ResidencyStatusEnum
  },
  employmentStatusOptions: {
    type: "employmentStatusOptions",
    enum: EmploymentStatusEnum
  },
  qualificationOptions: {
    type: "qualificationOptions",
    enum: QualificationEnum
  },
  cpfEmploymentClassificationOptions: {
    type: "cpfEmploymentClassificationOptions",
    enum: CpfEmploymentClassificationEnum
  },
  taxPaymentPreferenceOptions: {
    type: "taxPaymentPreferenceOptions",
    enum: TaxPaymentPreferenceEnum
  },
  residentialTenureOptions: {
    type: "residentialTenureOptions",
    enum: ResidentialTenureEnum
  },

  relationshipTypeOptions: {
    type: "relationshipTypeOptions",
    enum: RelationshipTypeEnum
  },

  childrenRelationshipTypeOptions: {
    type: "childrenRelationshipTypeOptions",
    enum: ChildrenRelationshipTypeEnum
  },
  siblingRelationshipTypeOptions: {
    type: "siblingRelationshipTypeOptions",
    enum: SiblingRelationshipTypeEnum
  },
  parentRelationshipTypeOptions: {
    type: "parentRelationshipTypeOptions",
    enum: ParentRelationshipTypeEnum
  },
  schoolLevelOptions: {
    type: "schoolLevelOptions",
    enum: SchoolLevelEnum
  },
  livingWithOptions: {
    type: "livingWithOptions",
    enum: LivingWithEnum
  },
  benefitPeriodOptions: {
    type: "benefitPeriodOptions",
    enum: BenefitPeriodEnum
  },
  propertyTypeOptions: {
    type: "propertyTypeOptions",
    enum: PropertyTypeEnum
  },
  leaseTypeOptions: {
    type: "leaseTypeOptions",
    enum: LeaseTypeEnum
  },
  loanInterestTypeOptions: {
    type: "loanInterestTypeOptions",
    enum: LoanInterestTypeEnum
  },
  loanFrequencyOptions: {
    type: "loanFrequencyOptions",
    enum: LoanFrequencyEnum
  },
  pensionContributionTypeOptions: {
    type: "pensionContributionTypeOptions",
    enum: PensionContributionTypeEnum
  },
  salaryTypeOptions: {
    type: "salaryTypeEnum",
    enum: SalaryTypeEnum
  },

 
  accessLevelOptions: {
    type: "accessLevelOptions",
    enum: AccessLevelEnum
  },
  entityTypeOptions: {
    type: "entityTypeOptions",
    enum: EntityTypeEnum
  },

  companyScopeEntityTypeOptions: {
    type: "companyScopeEntityTypeOptions",
    enum: CompanyScopeEntityTypeEnum
  },

  clientInvestorOptions: {
    type: "clientInvestorOptions",
    enum: clientInvestorTypeEnum
  },
  clientCategoryTypeOptions: {
    type: "clientCategoryTypeOptions",
    enum: ClientCategoryTypeEnum
  },

  clientCategoryExceptArchiveTypeOptions: {
    type: "clientCategoryExceptArchiveTypeOptions",
    enum: ClientCategoryExceptArchiveTypeEnum
  },

  managedByOptions: {
    type: "managedByOptions",
    enum: ManagedByEnum
  },
}

export const getOptions = (optionsTypes) => {
  const result = {}
  optionsTypes.forEach((oType) => {
    result[oType.type] = getEnumOptions(optionsType[oType.type].enum)
  })
  return result
}

