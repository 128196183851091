import React from 'react';
import intl from 'react-intl-universal';
import { Pie } from 'react-chartjs-2';
import { DefaultPieChartLegendsOptions } from '../../../helpers/ChartHelper';

function SuplementaryRetirementSchemeChart({ chartData }) {
    return (
        <React.Fragment>
            <div className="subtitle2">
                {intl.get('field.srsAssetAllocation')}
            </div>
            <div className="graph-holder">
                <Pie
                    data={chartData}
                    width={500}
                    height={500}
                    legend={DefaultPieChartLegendsOptions}
                />
            </div>
        </React.Fragment>
    );
}

export default SuplementaryRetirementSchemeChart;