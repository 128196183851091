import { goalAction } from '../../../constants/ActionConstants';

export function goal(state = {}, action) {
    switch (action.type) {
        case goalAction.GET_CLIENT_SUCCESS: {
            return {
                ...state,
                client: {
                    ...action.response.data,
                    name: getClientName(action.response.data),
                },
                isJoint: false,
                loading: false,
            };
        }
        case goalAction.GET_CLIENT_WITH_PARTNER_SUCCESS: {
            return {
                ...state,
                client: {
                    ...action.client,
                    name: getClientName(action.client),
                },
                partner: {
                    ...action.partner,
                    name: getClientName(action.partner),
                },
                isJoint: true,
                loading: false,
            };
        }
        case goalAction.GET_GOAL_SUCCESS: {
            const sectionDisplay = ['paramater-setting'];
            const { data } = action;
            return {
                ...state,
                data,
                sectionDisplay,
            };
        }
        case goalAction.SAVE_GOAL_SUCCESS: {
            const { data } = action;
            return {
                ...state,
                data,
            };
        }
        case goalAction.TOOGLE_SECTION_DISPLAY: {
            const { sectionName } = action;
            const sectionDisplay = [...state.sectionDisplay];

            if (sectionDisplay.includes(sectionName)) {
                sectionDisplay.splice(sectionDisplay.indexOf(sectionName), 1);
            } else {
                sectionDisplay.push(sectionName);
            }

            return {
                ...state,
                sectionDisplay,
            };
        }
        case goalAction.SHOW_GOAL_DIALOG: {
            const { goalDialog } = action;
            return {
                ...state,
                goalDialog: {
                    ...goalDialog,
                    isShowGoalDialog: true,
                },
            };
        }
        case goalAction.HIDE_GOAL_DIALOG: {
            return {
                ...state,
                goalDialog: {
                    isShowGoalDialog: false,
                },
            };
        }
        case goalAction.UPDATE_DATA_IN_STORE: {
            const { data } = action;
            return {
                ...state,
                data,
            };
        }
        case goalAction.GET_LIQUID_ASSETS_GOAL_SUCCESS: {
            const { data } = action;
            return {
                ...state,
                liquidAssets: data,
            };
        }
        case goalAction.GET_PROPERTIES_GOAL_SUCCESS: {
            const { data } = action;
            return {
                ...state,
                properties: data,
            };
        }
        default:
            return state;
    }
}

function getClientName(client) {
    return client.personalDetails.personalInformation.firstName;
}
