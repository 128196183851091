import React, { useContext } from "react"
import { Link } from "react-router-dom"
import { createUrlFromPath } from "../../helpers/ImageHelper"

import { AppContext } from "../../context/AppContext"
import UploadPhoto from "./UploadPhoto"
import intl from "react-intl-universal"
import { numberFormat } from "../../helpers"

function BoxClient({ client }) {
  const {
    modal: { setModal, clearModal }
  } = useContext(AppContext)
  return (
    <div className="v2-box-user">
      <img
        src={`${process.env.PUBLIC_URL}/assets/images/v2Images/profileUser.png`}
        alt="profile"
        className="personIcon"
      />

      <div className="card-box">
        <div
          className="banner"
          style={{
            backgroundImage:
              "url(" + `${createUrlFromPath(client.photoPath)}` + ")",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat"
          }}
        >
          <div className="images">
            <img
              onClick={() =>
                setModal({
                  title: "Upload Photo Profile",
                  content: (
                    <UploadPhoto
                      id={client.id}
                      currentPhotoUrl={client.imagePath}
                      clearModal={clearModal}
                      isClient={client.type === "CLIENT"}
                      type={client.type}
                    />
                  ),
                  isSmall: true
                })
              }
              src={`${process.env.PUBLIC_URL}/assets/images/v2Images/camera.png`}
              alt="profile"
              className="camera"
            />
          </div>
        </div>

        <div className="box-user-description-v2">
          <div className="details">
            <Link to={`/personaldetails/${client.id}`} className={`content `}>
              <p className="clientName">{client.completeName}</p>
              <p>
                {intl.get("client.lastSubmission")}:
                <span className="value"></span>
              </p>
              <p>
                {intl.get("client.aum")}:
                <span className="value">
                  {client &&
                    client.aum &&
                    client.aum.value &&
                    numberFormat(client.aum.value, client.aum.currency)}
                </span>
              </p>
              <p>
                {intl.get("client.ium")}:
                <span className="value">
                  {client &&
                    client.ium &&
                    client.ium.value &&
                    numberFormat(client.ium.value, client.ium.currency)}
                </span>
              </p>
              <p>
                {intl.get("client.adviserName")}:
                <span className="value">{client.adviserName}</span>
              </p>
              <p>
                {intl.get("client.partnerName")}:
                <span className="value">{client.partnerName}</span>
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BoxClient
