import { isNullOrUndefined } from 'util';
import { getDirectVariableValue } from './GetDirectValue';
import { countFromArray } from './CountFromArray';

export const parentExpenses = (values, currency) => {
    if (!isNullOrUndefined(values)) {
        const { allowance, parentResidentialCare, others } = values;

        // count direct variables
        const countAllowance = getDirectVariableValue(allowance, currency);
        const countparentResidentialCare = getDirectVariableValue(
            parentResidentialCare,
            currency
        );
        const countOthers = countFromArray(others, currency);
        return {
            clientTotal:
                countAllowance.clientTotal +
                countparentResidentialCare.clientTotal +
                countOthers.clientTotal,
            partnerTotal:
                countAllowance.partnerTotal +
                countparentResidentialCare.partnerTotal +
                countOthers.partnerTotal,
            jointTotal:
                countAllowance.jointTotal +
                countparentResidentialCare.jointTotal +
                countOthers.jointTotal,
        };
    }
    return {
        clientTotal: 0,
        partnerTotal: 0,
        jointTotal: 0,
    };
};
