import React, { useContext } from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from "react-accessible-accordion"
import intl from "react-intl-universal"
import { AppContext } from "../../../../context/AppContext"
import CashFlowHeaderListing from "../../CashFlowHeaderListing"
import { BusinessOwnershipForm } from "../../../../forms"
import CashFlowListingGroup from "../../CashFlowListingGroup"
import api from "../../../../lib/api"
import { netWorthFormActions } from "../../../../actions"
import { useDispatch } from "react-redux"

const BusinessIncome = ({
  varPrefix,
  clientTotalValue,
  partnerTotalValue,
  jointTotalValue,
  optionsData,
  readOnlyAccessComponent,
  clientInformation,
  currency,
  onExternalUpdated,
  isJointHide,
  values
}) => {
  const {
    modal: { setModal, clearModal }
  } = useContext(AppContext)

  const handleSave = (newValues) => {
    // save the model
    api.put(newValues.url, newValues).then((response) => {
      if (response && !response.errorMessage) {
        onExternalUpdated(true)
        clearModal()
      }
    })
  }
  const calculateYearlyExpensesOnLoad = (model) => {
    model.deductibleExpenses = model.deductibleExpenses || {}
    Object.keys(model.deductibleExpenses)
      .filter((key) => !key.includes("Yearly"))
      .forEach((key) => {
        model.deductibleExpenses[`${key}Yearly`] = model.deductibleExpenses[key]
          ? (Number(model.deductibleExpenses[key]) || 0) * 12
          : null
      })
    return model
  }
  const dispatch = useDispatch()

  return (
    <div className="portal-part">
      <Accordion accordion={false}>
        <AccordionItem>
          <AccordionItemTitle>
            <CashFlowHeaderListing
              title={intl.get("cashflowlisting.personalIncome.business")}
              isAccordion
              clientTotalValue={clientTotalValue}
              partnerTotalValue={partnerTotalValue}
              jointTotalValue={jointTotalValue}
              currency={currency}
              isJointHide={isJointHide}
            />
          </AccordionItemTitle>
          <AccordionItemBody>
            <CashFlowListingGroup
              varPrefix={`${varPrefix}`}
              dataArray={
                values.detailResult
                // values.personalIncome.investmentPropertyRental
              }
              isJointHide={isJointHide}
              isRoundColorRed
              isExternalModal
              currency={currency}
              onEditClick={(model) => {
                const a = calculateYearlyExpensesOnLoad(model)
                dispatch(
                  netWorthFormActions.setNewForm(false, a.classification)
                )
                setModal({
                  title: intl.get("cashflowlisting.personalIncome.business"),
                  formType: "BusinessIncome",
                  modalClassName: "BusinessOwnership",
                  content: (
                    <BusinessOwnershipForm
                      handleSave={handleSave}
                      model={a}
                      readOnlyAccess={readOnlyAccessComponent()}
                      clientInformation={clientInformation}
                      optionsData={optionsData}
                    />
                  )
                })
              }}
            />
          </AccordionItemBody>
        </AccordionItem>
      </Accordion>
    </div>
  )
}

export default BusinessIncome
