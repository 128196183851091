import React, { useState, useEffect } from "react"
import intl from "react-intl-universal"
import { CgNotes } from "react-icons/cg"
import { TiGroup } from "react-icons/ti"
import { FaFolderOpen } from "react-icons/fa"
import { RiTodoFill } from "react-icons/ri"
import api from "../../lib/api"
import Alert from "react-s-alert"
import { NoteCategoryEnum } from "../../constants/Enums"
import { notesActions } from "../../actions"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import PastFileNotes from "./PastFileNotes"
import { Editor } from "react-draft-wysiwyg"
import htmlToDraft from "html-to-draftjs"
import draftToHtml from "draftjs-to-html"
import { EditorState, convertToRaw, ContentState } from "draft-js"
import moment from "moment"
const NoteEdit = ({
  user,
  closeModal,
  getNotes,
  setModal,
  noteId,
  showPastFiles = true,
  refresh,
  setRefresh,
  efiles = false
}) => {
  const getDate = (date) =>
    date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric"
    })
  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!subject) {
      Alert.error("Enter the subject")
      return
    }
    if (draftToHtml(convertToRaw(editorState.getCurrentContent())).length < 9) {
      Alert.error("Write the note please")
      return
    }
    const submittedData = {
      clientId: user.id,
      subject,
      dateSent: getDate(dateSent),
      place: place,
      type: notesType,
      deadline: completedBy,
      description: draftToHtml(convertToRaw(editorState.getCurrentContent()))
    }

    await api
      .put(`/note/${noteId}`, submittedData)
      .then((response) => {
        // Alert.success(intl.get("alert.noteEdited"))
        closeModal()
      })
      .then((error) => {
        console.log("error occurred", error)
      })
    if (efiles) {
      setRefresh(!refresh)
    }
  }
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const [subject, setSubject] = useState("")
  const [place, setPlace] = useState("")
  const [dateSent, setDateSent] = useState(new Date())
  const [notesType, setNotesType] = useState(NoteCategoryEnum.GENERAL_NOTES)
  const [completedBy, setCompletedBy] = useState("")
  useEffect(() => {
    const gettingNotes = async () => {
      await api.get(`/note/${noteId}`).then((response) => {
        setNotesType(response.data.type)

        setDateSent(new Date(response.data.dateTimeCreated))
        setSubject(response.data.subject)
        setPlace(response.data.place)
        if (response.data && response.data.deadline) {
          setCompletedBy(response.data.deadline)
        }
        const html = response.data.description
        const contentBlock = htmlToDraft(html)
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          )
          const editorState = EditorState.createWithContent(contentState)
          setEditorState(editorState)
        }
      })
    }
    gettingNotes()
  }, [noteId])
  const handleCheck = (event, name) => {
    setNotesType(event)
    document.querySelectorAll(".feedback-icon").forEach((a) => {
      a.classList.remove("addstyle")
    })
    document.querySelector(`.${event}`).classList.toggle("addstyle")
  }

  return (
    <>
      <div className="popup" style={{ overflow: "hidden", height: "auto" }}>
        <div className="container-fluid">
          <form className="container-feedback" onSubmit={handleSubmit}>
            <div className="portal-form-feedback">
              {intl.get("note.selectNotesCategory")}
              <div className="feedback-icon-holder notes-icon-holder">
                <span
                  className={`feedback-icon notes-1 GENERAL_NOTE ${
                    notesType === NoteCategoryEnum.GENERAL_NOTES && "addstyle"
                  }`}
                  onClick={() => {
                    handleCheck(NoteCategoryEnum.GENERAL_NOTES)
                  }}
                >
                  <CgNotes />
                  <div className="text">
                    {intl.get("NoteCategoryEnum_GENERAL_NOTES")}
                  </div>
                </span>
                <span
                  className={`feedback-icon notes-1 MEETING_NOTE ${
                    notesType === NoteCategoryEnum.MEETING_NOTES && "addstyle"
                  }`}
                  onClick={() => handleCheck(NoteCategoryEnum.MEETING_NOTES)}
                >
                  <TiGroup />
                  <span className="text">
                    {intl.get("NoteCategoryEnum_MEETING_NOTES")}
                  </span>
                </span>
                <span
                  className={`feedback-icon notes-1 TO_DO ${
                    notesType === NoteCategoryEnum.TODO && "addstyle"
                  }  `}
                  onClick={() => handleCheck(NoteCategoryEnum.TODO)}
                >
                  <RiTodoFill />
                  <span className="text">
                    {intl.get("NoteCategoryEnum_TODO")}
                  </span>
                </span>
                {showPastFiles && (
                  <span
                    className="feedback-icon notes-1 PAST_FILE_NOTES"
                    onClick={() => {
                      setModal({
                        title: "Past File Notes",
                        content: (
                          <PastFileNotes
                            userID={user.id}
                            getNotes={getNotes}
                            clearModal={closeModal}
                            setModal={setModal}
                            user={user}
                          />
                        ),
                        isSmall: true
                      })
                    }}
                  >
                    <FaFolderOpen />

                    <span className="text">
                      {intl.get("NoteCategoryEnum_PAST_FILE_NOTES")}
                    </span>
                  </span>
                )}
              </div>{" "}
              <div className="feedback-content">
                <div className="portal-form-row feedback-form-row">
                  {" "}
                  <label>{intl.get("Note.field.subject")}:</label>
                  <div className="row-holder">
                    <input
                      className={`text-input ${
                        user.info.clientCategoryStatus === "Archive" &&
                        "disabled"
                      }`}
                      type="text"
                      placeholder="Enter the subject"
                      value={subject}
                      disabled={user.info.clientCategoryStatus === "Archive"}
                      onChange={(e) => setSubject(e.target.value)}
                      required
                    />
                  </div>
                </div>
                {notesType === "MEETING_NOTE" && (
                  <div className="portal-form-row feedback-form-row">
                    {" "}
                    <label>{intl.get("Note.field.place")}:</label>
                    <div className="row-holder">
                      <input
                        className={`text-input ${
                          user.info.clientCategoryStatus === "Archive" &&
                          "disabled"
                        }`}
                        type="text"
                        value={place}
                        placeholder="Place of taking note..."
                        disabled={user.info.clientCategoryStatus === "Archive"}
                        onChange={(e) => setPlace(e.target.value)}
                      />
                    </div>
                  </div>
                )}
                <div className="portal-form-row feedback-form-row">
                  {" "}
                  <label>{intl.get("feedback.field.date")}:</label>
                  <div className="row-holder">{getDate(dateSent)}</div>
                </div>
                <div className="portal-form-row feedback-form-row">
                  <label>Deadline:</label>
                  <div className="row-holder">
                    <input
                      className={`text-input ${
                        user.info.clientCategoryStatus === "Archive" &&
                        "disabled"
                      }`}
                      type="datetime-local"
                      value={completedBy}
                      onChange={(e) => setCompletedBy(e.target.value)}
                      disabled={user.info.clientCategoryStatus === "Archive"}
                    />
                  </div>
                </div>
                <div className="portal-form-row feedback-form-row">
                  {" "}
                  <label>{intl.get("Note.field.noteType")}:</label>
                  <div className="row-holder">
                    {notesType === NoteCategoryEnum.GENERAL_NOTES
                      ? intl.get("NoteCategoryEnum_GENERAL_NOTES")
                      : notesType === NoteCategoryEnum.MEETING_NOTES
                      ? intl.get("NoteCategoryEnum_MEETING_NOTES")
                      : intl.get("NoteCategoryEnum_TODO")}
                  </div>
                </div>
                <Editor
                  editorState={editorState}
                  toolbarClassName="demo-toolbar-custom"
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  readOnly={user.info.clientCategoryStatus === "Archive"}
                  onEditorStateChange={(editorState) =>
                    setEditorState(editorState)
                  }
                  placeholder="Type here..."
                  toolbar={{
                    options: [
                      "inline",
                      "blockType",
                      "fontSize",
                      // "fontFamily",
                      "list"
                      // "textAlign",
                      // "colorPicker",
                      // "link",
                      // "embedded",
                      // "emoji",
                      // "image",
                      // "remove",
                      // "history"
                    ],
                    inline: {
                      inDropdown: false,
                      className: undefined,
                      component: undefined,
                      dropdownClassName: undefined,
                      options: ["bold", "italic", "underline", "strikethrough"]

                      // monospace: { icon: monospace, className: undefined },
                      // superscript: { icon: superscript, className: undefined },
                      // subscript: { icon: subscript, className: undefined },
                    },
                    list: {
                      inDropdown: false,
                      className: undefined,
                      component: undefined,
                      dropdownClassName: undefined,
                      options: ["unordered", "ordered"]
                    }
                  }}
                />
              </div>
            </div>
            <div className="portal-btn-popup">
              <div className="col-half">
                <input
                  className="portal-btn portal-btn-s portal-btn-cancel"
                  value={intl.get("button.cancel")}
                  type="reset"
                  onClick={closeModal}
                />
              </div>
              <div className="col-half">
                <input
                  className={`portal-btn portal-btn-s portal-btn-submit ${
                    user.info.clientCategoryStatus === "Archive" && "disabled"
                  } `}
                  value={intl.get("button.save")}
                  type="submit"
                  disabled={user.info.clientCategoryStatus === "Archive"}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, notesActions), dispatch)
}
export default connect(null, mapDispatchToProps)(NoteEdit)
