import React from "react";
import intl from "react-intl-universal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import NetWorthForm, {
    NetWorthPath,
    NetWorthFormPropsComposer
} from "../NetWorthForm";
import { modalWindow } from "../../../constants/ModalWindowLists";
import { AppContext } from "../../../context/AppContext";
import { netWorthFormActions, modalWindowActions } from "../../../actions";
import ILASInvestmentForm from "./ILASInvestmentForm";

class ILASInvestmentModal extends NetWorthForm {
    constructor(props) {
        super(
            props,
            intl.get("ilasInvestment.title"),
            modalWindow.ILAS_INVESTMENT,
            NetWorthPath.ilasInvestment,
            "ILASInvestmentModal",
            true
        );
        
    }

    render() {
        const {
            props,
            contentWrapper,
            readOnlyAccessComponent,
            handleSave
            // updateModelInStore,
        } = this;

        const { optionsData, model, client, partner, isJoint } = props;

        return contentWrapper(
            <Form
                onSubmit={handleSave}
                initialValues={model}
                mutators={
                    { ...arrayMutators,
                       setFormAttribute: ([fieldName, fieldVal], state, { changeValue }) => {
                         changeValue(state, fieldName, () => fieldVal)
                       }
                    }
                    
                }
                render={({ handleSubmit, reset, values, mutators }) => (
                    <>
                        <ILASInvestmentForm
                            handleSubmit={handleSubmit}
                            optionsData={optionsData}
                            mutators={mutators}
                            reset={reset}
                            readOnlyAccessComponent={readOnlyAccessComponent}
                            clientInformation={{ client, partner, isJoint }}
                            values={values}
                            // client={client}
                            // partner={partner}
                            // isJoint={isJoint}
                        />
                    </>
                )}
            />
        );
    }
}

function mapStateToProps(state) {
    // const isShowSubForm = state.modalWindow[modalWindow.ILAS_INVESTMENT];

    const props = NetWorthFormPropsComposer(
        state,
        modalWindow.ILAS_INVESTMENT,
        NetWorthPath.ilasInvestment
    );
    return {
        ...props
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        Object.assign({}, netWorthFormActions, modalWindowActions),
        dispatch
    );
}

ILASInvestmentModal.contextType = AppContext;

ILASInvestmentModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(ILASInvestmentModal);

export default ILASInvestmentModal;
