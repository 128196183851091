import React, { useState, useEffect } from "react";
import RenderSimpleFields from "../../components/Form/RenderSimpleFields";
import { FieldType } from "../../components/Form/RenderSimpleFields";
import { Field, Form } from "react-final-form";
import { FormButtons } from "../../components";
import api from "../../lib/api";
import createDecorator from "final-form-calculate";
import { fileFieldRenderBootstrapWithoutLabel } from "../../helpers/FormRenderBootstrap";
import Alert from "react-s-alert";
const CpdCptForm = ({
    clearModal,
    refresh,
    setRefresh,
    currentCpdData,
    viewMode,
    type,
    availableCourses
}) => {
    const [modaldata, setModalData] = useState({});
    const fileOperationUrl = localStorage.getItem("base-route"); // base route for multiple region

    useEffect(() => {
        if (currentCpdData) {
            let data = {
                adviserId: currentCpdData.adviserId,
                learningCourseId:
                    currentCpdData &&
                    currentCpdData.learningCourse &&
                    currentCpdData.learningCourse.id,
                eventDate: currentCpdData.eventDate,
                hoursOrPointsAwarded: currentCpdData.hoursOrPointsAwarded,
                certificateIssuedDate: currentCpdData.certificateIssuedDate,
                id: currentCpdData.id,
                uploadedFile:
                    currentCpdData &&
                    currentCpdData.certificateInfo &&
                    currentCpdData.certificateInfo.originalFileName,
                uploadedFilePath:
                    currentCpdData &&
                    currentCpdData.certificateInfo &&
                    currentCpdData.certificateInfo.url
            };
            setModalData({ ...data });
        }
    }, [currentCpdData, availableCourses]);
    const [adviserNames, setAdviserNames] = useState([]);

    const getAdviserNames = async () => {
        try {
            const adviserNamesData = await api.get(`/compliance/adviser_names`);
            if (adviserNamesData.data.list.length) {
                setAdviserNames(adviserNamesData.data.list);
            }
        } catch (error) {
            console.log("Error", error);
        }
    };

    useEffect(() => {
        getAdviserNames();
    }, []);
    let fields = [
        {
            name: "adviserId",
            label: "Adviser Name",
            selectionData: adviserNames,
            type: FieldType.SELECTION,
            required: true,
            valueProperty: "key",
            labelProperty: "name",
            readOnly: viewMode
        },
        {
            name: "eventDate",
            label: "Date of the Event",
            type: FieldType.DATE,
            allowNegative: false,
            readOnly: viewMode
        },

        {
            name: "learningCourseId",
            label: "Learning Course",
            selectionData: availableCourses,
            type: FieldType.SELECTION,
            valueProperty: "id",
            required: true,
            labelProperty: "learningCode,topic",
            readOnly: viewMode
        },
        {
            name: "educationProvider",
            label: "Education Provider",
            type: FieldType.TEXT,
            readOnly: true
        },
        {
            name: "category",
            label: "Category",
            type: FieldType.TEXT,
            readOnly: true
        },
        {
            name: "learningObjectives",
            label: "Learning Objectives",
            type: FieldType.TEXT,
            readOnly: true
        },

        {
            name: "hoursOrPointsAwarded",
            label: "Points / Hours Awarded",
            type: FieldType.NUMBER,
            required: true,
            readOnly: true
        },
        {
            name: "certificateIssuedDate",
            label: "Certificate / Award Issue Date",
            type: FieldType.DATE,
            required: true,
            readOnly: viewMode
        }
    ];
    const decorator = createDecorator({
        field: "learningCourseId",
        updates: {
            educationProvider: async (fooValue, allValues) => {
                if (fooValue && availableCourses.length) {
                    const found = availableCourses.filter(
                        item => fooValue == item.id
                    );
                    return found[0].educationProvider;
                } else {
                    return null;
                }
            },
            category: async (fooValue, allValues) => {
                if (fooValue && availableCourses.length) {
                    const found = availableCourses.filter(
                        item => fooValue == item.id
                    );
                    return found[0].category;
                } else {
                    return null;
                }
            },
            learningObjectives: async (fooValue, allValues) => {
                if (fooValue && availableCourses.length) {
                    const found = availableCourses.filter(
                        item => fooValue == item.id
                    );
                    return found[0].learningObjectives;
                } else {
                    return null;
                }
            },
            hoursOrPointsAwarded: async (fooValue, allValues) => {
                if (fooValue && availableCourses.length) {
                    const found = availableCourses.filter(
                        item => fooValue == item.id
                    );
                    return found[0].pointsAwarded;
                } else {
                    return null;
                }
            }
        }
    });
    const onSubmit = async values => {
        const formData = new FormData();
        typeof values.uploadedFile == "object" &&
            formData.append("uploadedFile", values.uploadedFile);
        formData.append("adviserId", values.adviserId);
        formData.append("learningCourseId", values.learningCourseId);
        formData.append("eventDate", values.eventDate);
        currentCpdData &&
            currentCpdData.id &&
            formData.append("id", currentCpdData.id);
        formData.append("certificateIssuedDate", values.certificateIssuedDate);
        const data = await api.post("/cpd", formData);
        if (data.data.errorCode === null) {
            setRefresh(!refresh);
            clearModal();
            Alert.success("CPD Successfully Added");
        }
    };
    const getBase64 = (file, cb) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function() {
            cb(reader.result);
        };
        reader.onerror = function(error) {
            console.log("Error: ", error);
        };
    };
    const uploaded = event => {
        // console.log("file is", event.target.files[0]);
        getBase64(event.target.files[0], result => {
            // setPreview(result);
        });
    };
    return (
        <div className="portal-form-popup">
            <Form
                onSubmit={onSubmit}
                keepDirtyOnReinitialize={true}
                initialValues={modaldata}
                decorators={[decorator]}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <div>
                            <div className="subtitle2">Adviser Section</div>
                            <RenderSimpleFields fields={fields} />
                            <div className="portal-form-row">
                                <label htmlFor="uploadedFile">
                                    {viewMode
                                        ? "Uploaded File"
                                        : "File To Upload"}
                                </label>
                                <div className="row-holder">
                                    {!viewMode ? (
                                        <Field
                                            name="uploadedFile"
                                            label="Upload Excel Files"
                                            inputOnChange={uploaded}
                                            component={
                                                fileFieldRenderBootstrapWithoutLabel
                                            }
                                        />
                                    ) : (
                                        <span>
                                            {modaldata.uploadedFile ? (
                                                <a
                                                    href={`${fileOperationUrl}${modaldata.uploadedFilePath}`}
                                                    target="_blank"
                                                    className="font-weight-bold" rel="noreferrer"
                                                >
                                                    {modaldata.uploadedFile
                                                        .length > 20
                                                        ? modaldata.uploadedFile.substring(
                                                              0,
                                                              20
                                                          ) +
                                                          "..." +
                                                          modaldata.uploadedFile.split(
                                                              "."
                                                          )[1]
                                                        : modaldata.uploadedFile}
                                                </a>
                                            ) : (
                                                "File Not Uploaded"
                                            )}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                        {viewMode ? "" : <FormButtons />}
                    </form>
                )}
            />
        </div>
    );
};

export default CpdCptForm;
