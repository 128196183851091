import React, { useState, useContext, useEffect } from "react"
import { Field, Form, FormSpy } from "react-final-form"
import intl from "react-intl-universal"
import arrayMutators from "final-form-arrays"
import "rc-dropdown/assets/index.css"
import FormButtons from "../components/Form/FormButtons"
import CashAccountForm from "./CashAccountForm"
import BondForm from "./BondForm"
import ShareForm from "./ShareForm"
import TermDepositForm from "./TermDepositForm"
import UnitTrustForm from "./UnitTrustForm"
import ModalNoAccess from "../components/Modal/ModalNoAccess"
import { FundAllocationListing } from "../pages/NetWorth/FundAllocationListing"
import MalabarDialog from "../components/Dialog/MalabarDialog"
import ClassificationSelection from "../components/_hook/ClassificationSelection"
import { AppContext } from "../context/AppContext"
import GeneralLoanDetails from "./sub-forms/GeneralLoanDetails"
import RenderSimpleFields, {
  FieldType
} from "../components/Form/RenderSimpleFields"
import Alert from "react-s-alert"

import { contextMenuItem } from "../services/FundAllocationListingData"
import { fileFieldRender, getSymbolFromCode, numberFormat } from "../helpers"
import { DefaultPieChartLegendsOptions } from "../helpers/ChartHelper"
import Get from "../helpers/NullHelper"
import InvestmentBuilderApi from "../api/InvestmentBuilderApi"
import FeeSection from "./sub-forms/InvestmentPlatformDetailsForm"
import createDecorator from "final-form-calculate"
import Api from "../lib/api"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import MortageCharges from "./sub-forms/MortageCharges"
import InstrumentDetailsForm from "./InstrumentDetailForm"

function adjustIncomingData(model) {
  return model
}
function adjustOutgoingData(model) {
  const mapAllocation = (models) => {
    const { savingPlanAllocations, withdrawalPlanAllocations } = model
    return models.map((item, index) => ({
      ...item
    }))
  }

  return {
    ...model,
    // cashAccounts: mapAllocation(model.cashAccounts),
    // termDeposits: mapAllocation(model.termDeposits),
    instruments: mapAllocation(model.instruments)
    // bonds: mapAllocation(model.bonds),
    // unitTrusts: mapAllocation(model.unitTrusts)
  }
}

function saveInnerForm(
  mutators,
  subModelType,
  subModel,
  closeSubForm,
  handleSave
) {
  const { index } = subModel

  if (index || index === 0) {
    mutators.updateArray(subModelType, index, subModel)

    //submit the [Fund Allocation Details]
    subModel.typeKey = subModelType
    subModel.action = "update"
  } else {
    // allow for multiple insertion for [cash account], [term deposit], [share], [unit trust], [bond]
    mutators.pushArray(subModelType, subModel)

    //submit the [Fund Allocation Details]
    subModel.typeKey = subModelType
    subModel.action = "push"

    // 20211210
  }

  // 20211210
  closeSubForm()
}

// 20201126 MABT-420
function verifyValues(array) {
  if (
    (array.cashAccounts === undefined || array.cashAccounts.length == 0) &&
    (array.bonds === undefined || array.bonds.length == 0) &&
    (array.instruments === undefined || array.instruments.length == 0) &&
    (array.termDeposits === undefined || array.termDeposits.length == 0) &&
    (array.unitTrusts === undefined || array.unitTrusts.length == 0)
  ) {
    // array empty or does not exist
    return false
  } else {
    return true
  }
}

function createChartData(model) {
  const groupedValueData = Object.keys(contextMenuItem).map((key) =>
    (model[key] || []).map((item) => {
      const simplifiedItem = { type: key }
      switch (key) {
        case "cashAccounts":
        case "termDeposits": {
          simplifiedItem.value = Number(item.accountBalance) || 0
          break
        }
        case "instruments": {
          simplifiedItem.value = Number((item.currentValue || {}).value) || 0
          break
        }
        case "unitTrusts": {
          simplifiedItem.value = Number((item.currentValue || {}).value) || 0
          break
        }
        case "bonds": {
          simplifiedItem.value = Number((item.faceValue || {}).value) || 0
          break
        }
        default: {
          // do nothing
        }
      }
      return simplifiedItem
    })
  )

  const flattenGroupedValueData = [].concat(...groupedValueData)
  const labels = []
  const data = []

  Object.keys(contextMenuItem).forEach((key) => {
    const value = flattenGroupedValueData
      .filter((item) => item.type === key)
      .reduce((sum, current) => sum + current.value, 0)
    if (value > 0) {
      labels.push(contextMenuItem[key])
      data.push(value)
    }
  })

  return {
    labels,
    datasets: [
      {
        data,
        backgroundColor: ["#FF6384", "#4BC0C0", "#FFCE56", "#9893DA", "#36A2EB"]
      }
    ]
  }
}
function validateAllocation(values) {
  const errors = {}
  const {
    regularSaving,
    regularWithdrawal,
    savingPlanAllocations,
    withdrawalPlanAllocations
  } = values

  return errors
}

function SubForm({
  isOpenSubForm,
  closeSubForm,
  mutators,
  subFormType,
  readOnlyAccess,
  optionsData,
  subFormModel,
  clientInformation,
  defaultCurrency,
  handleSave,
  values
}) {
  if (!subFormType) {
    return null
  }
  const setModel = () => {
    if (!subFormModel) {
      switch (subFormType) {
        case "cashAccounts":
          return { accountCurrency: defaultCurrency }
        case "termDeposits":
          return { accountCurrency: defaultCurrency }
        case "instruments":
          return {
            // purchasePrice: {
            //   currency: defaultCurrency,
            //   value: values.purchasedPrice
            // },
            // currentValue: {
            //   currency: defaultCurrency,
            //   value: values.purchasedPrice
            // },
            // marginLoan: { borrowingsCurrency: defaultCurrency }
          }
        case "unitTrusts":
          return {
            marginLoan: { borrowingsCurrency: defaultCurrency }
          }
        case "bonds":
          return {
            marginLoan: { borrowingsCurrency: defaultCurrency }
          }
        default:
          return {}
      }
    }
  }

  const props = {
    handleSave: (model) =>
      saveInnerForm(mutators, subFormType, model, closeSubForm, handleSave),
    model: setModel(),
    optionsData,
    readOnlyAccess,
    showClassification: false,
    clientInformation,
    defaultCurrency,
    subFormModel,
    readOnly: values.verify ? true : false,
    values
  }

  const forms = {
    cashAccounts: {
      title: intl.get("cashAccount.form.title"),
      form: <CashAccountForm {...props} />
    },
    termDeposits: {
      title: intl.get("termDeposit.title"),
      form: <TermDepositForm {...props} />
    },
    instruments: {
      title: "Instrument Holding Information",
      form: <InstrumentDetailsForm {...props} />
    },
    unitTrusts: {
      title: intl.get("unitTrusts.title"),
      form: <UnitTrustForm {...props} />
    },
    bonds: {
      title: intl.get("bonds.title"),
      form: <BondForm {...props} />
    }
  }

  return (
    <MalabarDialog
      title={(forms[subFormType] || {}).title}
      isOpen={isOpenSubForm}
      onClose={closeSubForm}
      formType={subFormType}
      isLarge={false}
      large={false}
    >
      {(forms[subFormType] || {}).form}
    </MalabarDialog>
  )
}
function AdditionalInformation({
  optionsData,
  onRegularSavingChange,
  onRegularWithdrawalChange,
  defaultCurrency
}) {
  const fields = [
    {
      name: "regularSavingFrequency",
      label: intl.get("field.regularSavingFrequency"),
      type: FieldType.ENUM_SELECTION,
      enumData: optionsData.investmentFrequencyOptions
    },
    {
      name: "regularSaving",
      label: intl.get("field.regularSaving"),
      type: FieldType.MONEY_WITH_CURRENCY,
      onChange: onRegularSavingChange,
      defaultCurrency,
      symbolPrefix: getSymbolFromCode(defaultCurrency),
      allowNegative: false
    },
    {
      name: "regularWithdrawalFrequency",
      label: intl.get("field.regularWithdrawalFrequency"),
      type: FieldType.ENUM_SELECTION,
      enumData: optionsData.investmentFrequencyOptions
    },
    {
      name: "regularWithdrawal",
      label: intl.get("field.regularWithdrawal"),
      type: FieldType.MONEY_WITH_CURRENCY,
      onChange: onRegularWithdrawalChange,
      defaultCurrency,
      symbolPrefix: getSymbolFromCode(defaultCurrency),
      allowNegative: false
    },
    {
      name: "accountValuationDate",
      label: intl.get("field.accountBallanceValuationDate"),
      type: FieldType.DATE
    }
  ]
  return (
    <React.Fragment>
      {/* <div className="subtitle2">{intl.get("field.additionalInformation")}</div> */}
      <RenderSimpleFields fields={fields} />
    </React.Fragment>
  )
}
function MainForm({
  optionsData,
  clientInformation: { client, partner = {}, isJoint },
  setModal,
  clearModal,
  values,
  mutators: {
    setInvestmentPlatformDetails,
    onRegularSavingChange,
    onRegularWithdrawalChange,
    setFormAttribute
  },
  adviseraccounts,
  otherType
  // ExtraNode
}) {
  const onVerify = async () => {
    try {
      if (!values.investmentAdviserNo) {
        Alert.error("Adviser Account is a required field")
        return
      }
      if (!values.investmentClientNo) {
        Alert.error("Client Account Number is a required field")
        return
      }
      if (!values.investmentProviderName) {
        Alert.error("Investment Platform is a required field")
        return
      }
      const res = await Api.post(`/investment-provider/verify-data`, {
        adviser: values.investmentAdviserNo,
        clientId: values.investmentClientNo,
        provider: values.investmentProviderName
      })
      if (res) {
        Alert.success("Verify status has been updated successfully")
        setFormAttribute("verify", res.data.verify)
        if (res.data.verify) {
          setFormAttribute("instruments", res.data.instruments)
        }
      }
    } catch (error) {
      console.log("error", error)
    }
  }
  const ExtraNode = () => {
    return (
      <button
        className="portal-btn portal-btn-submit"
        type="button"
        onClick={onVerify}
      >
        {intl.get("settingForm.verify")}
      </button>
    )
  }
  const [investmentPlatforms, setInvestmentPlatforms] = useState([])
  const [investmentPlatform, setInvestmentPlatform] = useState([])

  useEffect(() => {
    InvestmentBuilderApi.getInvestmentPlatform()
      .then(({ data }) => {
        setInvestmentPlatforms([
          ...data.list,
          { key: "others", name: "others" }
        ])
        if (data.list.length > 0) {
          setInvestmentPlatform(data.list[0].key)
        }
      })
      .catch(({ response }) => {
        // handleErrorResponse(response);
      })
  }, [])

  let checkValues = "" //20201126 MABT-420

  const emptyValues = () => ({
    labels: [""],
    datasets: [
      {
        data: [123],

        backgroundColor: ["#dadada"] //20201119 MABT-388
      }
    ]
  })

  const { taxResidency = "" } = Get(client, "personalDetails.employmentDetails")
  const taxRecidencyIsAustralia =
    taxResidency.toLowerCase() === "australia" ||
    taxResidency.toLowerCase() === "au"
  const fields = [
    {
      name: "investmentProviderName",
      label: intl.get("field.investmentPlatformName"),
      required: true,
      type: FieldType.SELECTION,

      selectionData: investmentPlatforms,
      valueProperty: "key",
      labelProperty: "name"
    },
    {
      name: "institutionName",
      label: intl.get("field.institutionName"),
      required: true
    },
    {
      name: "investmentAdviserNo",
      label: intl.get("field.faAdviserAccount"),
      required: true,
      type: otherType ? FieldType.TEXT : FieldType.SELECTION,
      selectionData: adviseraccounts,
      valueProperty: "adviser",
      labelProperty: "adviser"
    },
    {
      name: "investmentClientNo",
      label: intl.get("field.clientAccountNumber"),
      required: true,
      type: FieldType.TEXT,
      extraNode:
        values.investmentProviderName !== "others" ? ExtraNode() : <></>
    },
    {
      name: "verify",
      label: "Is Verified",
      type: FieldType.CHECKBOX,
      readOnly: true
    },
    {
      name: "accountStartDate",
      label: intl.get("field.accountStartDate"),
      type: FieldType.DATE,
      required: true
    },
    {
      name: "reportingCurrency",
      label: intl.get(`field.reportingCurrency`),
      type: FieldType.ENUM_SELECTION,
      enumData: optionsData.currencyOptions,
      required: true
    },
    {
      name: "underLoan",
      label: intl.get("field.investmentIsUnderLoan"),
      checkboxLabel: intl.get("YesNoEnum_YES"),
      type: FieldType.CHECKBOX
    }
  ]

  const { underLoan = false } = values

  //verify value
  checkValues = verifyValues(values)

  return (
    <div className="flexbox">
      <div className="portal-form-half">
        <ClassificationSelection
          {...{
            client: client,
            partner: partner,
            isJoint: isJoint
          }}
        />
        <div className="subtitle2">Platform Details</div>

        <RenderSimpleFields
          fields={fields.map((field) => {
            if (!field.type) {
              field.type = FieldType.TEXT
            }
            field.disabled = true
            return field
          })}
        />
        <Field
          component={fileFieldRender}
          customClassName="mt-1 d-block"
          labelIdle="Drag, Drop or Browse"
          label="Upload Documents"
          onupdatefiles={(fileItems) => {
            setFormAttribute("proofDocument", fileItems[0])
          }}
          fileInformation={(values && values.proofDocument) || null}
        />
      </div>
      <div className={underLoan ? "portal-form-half" : ""}>
        <Tabs
          justify={true}
          onSelect={(item) => {
            if (item === 0 && underLoan) {
              setFormAttribute("isLoanTab", true)
            } else {
              setFormAttribute("isLoanTab", false)
            }
          }}
        >
          <TabList className="custom_tabs">
            {underLoan ? <Tab>Loan</Tab> : <></>}
            {underLoan && taxRecidencyIsAustralia ? (
              <Tab>Mortage Charges</Tab>
            ) : (
              <></>
            )}

            <Tab>Saving/Withdrawal</Tab>
            <Tab>Fees</Tab>
          </TabList>

          {underLoan && (
            <TabPanel>
              <GeneralLoanDetails
                optionsData={optionsData}
                showMortgageAssociatedCharges={taxRecidencyIsAustralia}
                objectName="marginLoan"
                defaultCurrency={values.reportingCurrency}
                values={values.marginLoan || {}}
              />
            </TabPanel>
          )}

          {underLoan && taxRecidencyIsAustralia && (
            <TabPanel>
              <MortageCharges
                optionsData={optionsData}
                defaultCurrency={values.reportingCurrency}
                values={values.marginLoan || {}}
              />
            </TabPanel>
          )}
          <TabPanel>
            <AdditionalInformation
              {...{
                optionsData,
                onRegularSavingChange,
                onRegularWithdrawalChange,
                defaultCurrency: values.reportingCurrency
              }}
            />
          </TabPanel>

          <TabPanel>
            <FeeSection
              frequencyOptions={optionsData.frequencyOptions}
              values={values}
            />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  )
}

function InvestmentPlatformForm({
  handleSave,
  model = {},
  readOnlyAccess,
  optionsData,
  clientInformation,
  currency = "AUD"
}) {
  const {
    modal: { setModal, clearModal }
  } = useContext(AppContext)

  useEffect(() => {
    if (!adjustedModel.reportingCurrency) {
      adjustedModel.reportingCurrency = currency
      setAAdjustedModel(adjustedModel)
    }
  }, [currency])

  // -- start of state definition
  const [state, setState] = useState({})
  const [adjustedModel, setAAdjustedModel] = useState(adjustIncomingData(model))
  const closeSubForm = () => {
    setState({ ...state, isOpenSubForm: false })
  }
  const setSubFormModelAndSubOpenForm = (subModel) => {
    setState({
      ...state,
      subFormType: subModel.typeKey,
      subFormModel: subModel,
      isOpenSubForm: true
    })
  }

  const setSubFormTypeAndSubOpenForm = (menu) => {
    setState({
      ...state,
      subFormType: menu,
      isOpenSubForm: true,
      subFormModel: undefined
    })
  }
  // -- end of state definition

  if (readOnlyAccess) {
    return <ModalNoAccess />
  }

  const { isOpenSubForm, subFormType, subFormModel } = state

  if (!adjustedModel.reportingCurrency) {
    adjustedModel.reportingCurrency = currency
  }
  const [adviseraccounts, setAdviseraccounts] = useState([])
  const [otherType, setOtherType] = useState(false)
  const decorator = createDecorator({
    field: "investmentProviderName",
    updates: {
      investmentAdviserNo: (fooValue, allValues) => {
        if (fooValue !== "others") {
          setOtherType(false)
          InvestmentBuilderApi.getAdviserAccounts(fooValue)
            .then(({ data }) => {
              setAdviseraccounts(data.adviserBrokerAccounts)
            })
            .catch(({ error }) => {
              console.log("error is", error)
            })
          return allValues.investmentAdviserNo
        } else {
          setOtherType(true)
          return allValues.investmentAdviserNo
        }
      }
    }
  })

  return (
    <Form
      onSubmit={(v) => {
        const { savingAllocationError, withdrawalAllocationError } =
          validateAllocation(v)
        if (
          (v.underLoan && !v.marginLoan) ||
          (v.underLoan &&
            v.marginLoan &&
            (!v.marginLoan.borrowingsAccountNumber ||
              !v.marginLoan.borrowingsPrinciple ||
              !v.marginLoan.borrowingsStartDate ||
              !v.marginLoan.borrowingsTermInYears ||
              !v.marginLoan.borrowingsAnnualInterestRate ||
              !v.marginLoan.borrowingsRepaymentFrequency))
        ) {
          Alert.error("Please fill all mandatory loan parameters")
          return
        }

        setState((prevState) => ({
          ...prevState,
          savingAllocationError,
          withdrawalAllocationError
        }))

        if (savingAllocationError || withdrawalAllocationError) {
          return
        }

        handleSave(adjustOutgoingData(v))
      }}
      keepDirtyOnReinitialize={true}
      initialValues={{
        ...model,
        isLoanTab: true
      }}
      decorators={[decorator]}
      mutators={{
        pushArray: arrayMutators.push,
        updateArray: arrayMutators.update,
        removeArray: arrayMutators.remove,
        setInvestmentPlatformDetails: (args, formState, utils) => {
          const [details] = args

          Object.keys(details).forEach((key) => {
            utils.changeValue(formState, key, () => details[key])
          })
          if (!details.regularSaving || details.regularSaving.value === "") {
            utils.changeValue(formState, "regularSaving", () => ({
              currency: details.reportingCurrency,
              value: ""
            }))
          }
          if (
            !details.regularWithdrawal ||
            details.regularWithdrawal.value === ""
          ) {
            utils.changeValue(formState, "regularWithdrawal", () => ({
              currency: details.reportingCurrency,
              value: ""
            }))
          }

          // MABT-405 : to save the [Detail Form] data
          handleSave(details)
        },
        setFormAttribute: ([fieldName, fieldVal], state, { changeValue }) => {
          changeValue(state, fieldName, () => fieldVal)
        },

        setFundAllocationDetails: (args, formState, utils) => {
          // When add new [investment Platform] and new [Fund Allocation Details]
          // let details = formState.formState.initialValues;
          let details = formState.formState.values

          //remove current item
          let action = args[0].action
          let index = args[0].index

          // pass to [data json]
          switch (args[0].typeKey) {
            case "cashAccounts": {
              //delete current old data
              if (action === "update") {
                details.cashAccounts.splice(index, 1) //delete item by Index
              } else {
                //delete extra
                details.cashAccounts.splice(index, 1) //delete item by Index
                details.cashAccounts.push(args[0])
              }

              break
            }
            case "termDeposits": {
              //delete extra
              details.termDeposits.splice(index, 1) //delete item by Index
              details.termDeposits.push(args[0])
              break
            }
            case "instruments": {
              //delete extra
              details.instruments.splice(index, 1) //delete item by Index
              details.instruments.push(args[0])
              break
            }
            case "unitTrusts": {
              //delete extra
              details.unitTrusts.splice(index, 1) //delete item by Index
              details.unitTrusts.push(args[0])
              break
            }
            case "bonds": {
              //delete extra
              details.bonds.splice(index, 1) //delete item by Index
              details.bonds.push(args[0])
              break
            }
            default: {
              // do nothing
            }
          }

          //to save the [Cash Account] data
          // 20211210 - close innner dialog
          handleSave(details)
        },

        // 20201009 remove item from [Fund Allocation Listing]
        removeFundAllocationDetails: (args, formState, utils) => {
          let details = formState.formState.initialValues

          //remove current item
          let index = args[0].index

          // pass to [data json]
          switch (args[0].typeKey) {
            case "cashAccounts": {
              details.cashAccounts = args
              details.cashAccounts.splice(index, 1) //delete item by Index
              break
            }
            case "termDeposits": {
              details.termDeposits = args
              details.termDeposits.splice(index, 1) //delete item by Index
              break
            }
            case "instruments": {
              details.instruments = args
              details.instruments.splice(index, 1) //delete item by Index
              break
            }
            case "unitTrusts": {
              details.unitTrusts = args
              details.unitTrusts.splice(index, 1) //delete item by Index
              break
            }
            case "bonds": {
              details.bonds = args
              details.bonds.splice(index, 1) //delete item by Index
              break
            }
            default: {
              // do nothing
            }
          }

          //20201009 MABT-405 : submit to API
          // handleSave(details)
        },

        onRegularSavingChange: (args, formState, utils) => {
          const [regularSaving] = args
          const { values } = formState.formState
          const savingPlanAllocations = values.savingPlanAllocations || []

          const { currency, value } = regularSaving
          savingPlanAllocations.forEach((item, index) => {
            if (item.percentage || item.percentage === 0) {
              utils.changeValue(
                formState,
                `savingPlanAllocations[${index}]`,
                () => ({
                  ...item,
                  // money: {
                  currency,
                  value: (value * item.percentage) / 100
                  // }
                })
              )
            }
          })
        },
        onRegularWithdrawalChange: (args, formState, utils) => {
          const [regularWithdrawal] = args
          const { values } = formState.formState
          const withdrawalPlanAllocations =
            values.withdrawalPlanAllocations || []

          const { currency, value } = regularWithdrawal
          withdrawalPlanAllocations.forEach((item, index) => {
            if (item.percentage || item.percentage === 0) {
              utils.changeValue(
                formState,
                `withdrawalPlanAllocations[${index}]`,
                () => ({
                  ...item,
                  // money: {
                  currency,
                  value: (value * item.percentage) / 100
                  // }
                })
              )
            }
          })
        },

        onAllocationTypeChange: (args, formState, utils) => {
          const { values } = formState.formState
          const savingPlanAllocations = values.savingPlanAllocations || []
          const withdrawalAllocations = values.withdrawalPlanAllocations || []
          const regularSaving = values.regularSaving || {}
          const regularWithdrawal = values.regularWithdrawal || {}

          utils.changeValue(formState, `savingPlanAllocations`, () =>
            savingPlanAllocations.map((item) => ({
              ...item,
              percentage: undefined,
              // money: {
              currency: regularSaving.currency,
              value: 0
              // }
            }))
          )
          utils.changeValue(formState, `withdrawalPlanAllocations`, () =>
            withdrawalAllocations.map((item) => ({
              ...item,
              percentage: undefined,
              // money: {
              currency: regularWithdrawal.currency,
              value: 0
              // }
            }))
          )
        }
      }}
      render={({ handleSubmit, form, reset }) => (
        <form id="investmentPlatformForm" onSubmit={handleSubmit}>
          <FormSpy subscription={{ values: true }}>
            {({ values }) => (
              <React.Fragment>
                <div
                  className="portal-form-popup"
                  style={{ maxHeight: "500px", overflow: "auto" }}
                >
                  <MainForm
                    {...{
                      optionsData,
                      clientInformation,
                      setModal,
                      clearModal,
                      values,
                      mutators: form.mutators,
                      adviseraccounts,
                      otherType,
                      values
                    }}
                  />
                  <div
                    className={`portal-form-table ${
                      values.isLoanTab && values.underLoan
                        ? " table-spacing-above"
                        : "no-spacing-above "
                    }`}
                    style={{ borderTop: 0 }}
                  >
                    <FundAllocationListing
                      data={values}
                      contextMenuItem={contextMenuItem}
                      onSelectContextMenu={setSubFormTypeAndSubOpenForm}
                      onItemSelect={setSubFormModelAndSubOpenForm}
                      onItemDelete={(item) => {
                        form.mutators.removeArray(item.typeKey, item.index)

                        form.mutators.removeFundAllocationDetails(item)
                      }}
                      onAllocationTypeChange={
                        form.mutators.onAllocationTypeChange
                      }
                    />
                  </div>

                  <SubForm
                    {...{
                      isOpenSubForm,
                      closeSubForm,
                      mutators: form.mutators,
                      subFormType,
                      readOnlyAccess,
                      optionsData,
                      subFormModel,
                      clientInformation,
                      defaultCurrency: adjustedModel.reportingCurrency,
                      handleSave,
                      values
                    }}
                  />
                  <FormButtons reset={reset} readOnlyAccess={readOnlyAccess} />
                </div>
              </React.Fragment>
            )}
          </FormSpy>
        </form>
      )}
    />
  )
}

export default InvestmentPlatformForm
