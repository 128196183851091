import React, { useContext } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody
} from "react-accessible-accordion";
import intl from "react-intl-universal";
import { AppContext } from "../../../../context/AppContext";
import CashFlowHeaderListing from "../../CashFlowHeaderListing";
import CashFlowListingGroup from "../../CashFlowListingGroup";
import api from "../../../../lib/api";
import ShareForm from "../../../../forms/ShareForm";
import { netWorthFormActions } from "../../../../actions";
import { useDispatch } from "react-redux";

const Share = ({
    varPrefix,
    clientTotalValue,
    partnerTotalValue,
    jointTotalValue,
    optionsData,
    readOnlyAccessComponent,
    clientInformation,
    currency,
    onExternalUpdated,
    isJoint,
    values
}) => {
    const {
        modal: { setModal, clearModal }
    } = useContext(AppContext);

    const handleSave = newValues => {
        // save the model
        api.put(newValues.url, newValues).then(() => {
            onExternalUpdated(true);
        });

        clearModal();
    };
    const dispatch = useDispatch()

    return (
        <div className="portal-part">
            <Accordion accordion={false}>
                <AccordionItem>
                    <AccordionItemTitle>
                        <CashFlowHeaderListing
                            title={intl.get(
                                "cashflowlisting.personalIncome.share"
                            )}
                            isAccordion
                            clientTotalValue={clientTotalValue}
                            partnerTotalValue={partnerTotalValue}
                            jointTotalValue={jointTotalValue}
                            currency={currency}
                            isJointHide={!isJoint}
                        />
                    </AccordionItemTitle>
                    <AccordionItemBody>
                        <CashFlowListingGroup
                            varPrefix={`${varPrefix}`}
                            dataArray={values.detailResult}
                            isRoundColorRed
                            isExternalModal
                            isJointHide={!isJoint}
                            currency={currency}
                            onEditClick={model =>{
                                 dispatch(
                                   netWorthFormActions.setNewForm(
                                     false,
                                     model.classification
                                   )
                                 )
                                setModal({
                                    title: intl.get("shares.title"),
                                    formType: "shares",
                                    modalClassName: "Share",
                                    content: (
                                        <ShareForm
                                            handleSave={handleSave}
                                            model={model}
                                            optionsData={optionsData}
                                            readOnlyAccess={readOnlyAccessComponent()}
                                            clientInformation={
                                                clientInformation
                                            }
                                        />
                                    )
                                })
                            }}
                        />
                    </AccordionItemBody>
                </AccordionItem>
            </Accordion>
        </div>
    );
};

export default Share;
