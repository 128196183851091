export const alertAction = {
  SUCCESS: "ALERT_SUCCESS",
  ERROR: "ALERT_ERROR",
  CLEAR: "ALERT_CLEAR"
}

export const insuranceRequestAction = {
  SUCCESS: "INSURANCE_DETAILS_FETCH_SUCCESS",
  ERROR: "INSURANCE_DETAILS_FETCH_ERROR",
  REQUEST: "INSURANCE_DETAILS_FETCH_REQUEST",
  SAVETOREDUX: "SAVE_TO_REDUX"
}
export const enumOptionsAction = {
  GET_ALL_OPTIONS: "GET_ALL_OPTIONS"
}

export const manageAccessLevelAction = {
  GET_ACCESS_LEVEL_ENTITY_SUCCESS: "GET_ACCESS_LEVEL_ENTITY_SUCCESS",
  SAVE_ACCESS_LEVEL_ENTITY_SUCCESS: "SAVE_ACCESS_LEVEL_ENTITY_SUCCESS"
}

export const userAction = {
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  USER_ENTITIES: "USER_ENTITIES",
  // LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
  LOGIN_TWO_FA_STEP: "USERS_LOGIN_TWO_FA_STEP",

  LOGOUT: "USERS_LOGOUT",

  GETALL_REQUEST: "USERS_GETALL_REQUEST",
  GETALL_SUCCESS: "USERS_GETALL_SUCCESS",
  GETALL_FAILURE: "USERS_GETALL_FAILURE",
  ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
  SHOW_ALL_FIELDS: "SHOW_ALL_FIELDS",
  SHOW_REQUIRED_FIELDS: "SHOW_REQUIRED_FIELDS",
  TOGGLE_VISIBILITY_OF_FIELDS: "TOGGLE_VISIBILITY_OF_FIELDS"
}

export const clientAction = {
  GET_CLIENT_SUCCESS: "GET_CLIENT_SUCCESS",
  GET_CLIENT_WITH_PARTNER_SUCCESS: "GET_CLIENT_WITH_PARTNER_SUCCESS"
}

export const apiAction = {
  API_CALL_START: "API_START",
  API_CALL_FAILURE: "API_FAILURE",
  BEGIN_API_CALL: "BEGIN_API_CALL",
  END_API_CALL: "END_API_CALL",
  API_CALL_ERROR: "API_CALL_ERROR"
}

export const jointSelectionAction = {
  UPDATE_SELECTION_DATA: "UPDATE_SELECTION_DATA"
}

export const primaryResidenceAction = {
  GET_PRIMARYRESIDENCE_REQUEST: "GET_PRIMARYRESIDENCE_REQUEST",
  GET_PRIMARYRESIDENCE_SUCCESS: "GET_PRIMARYRESIDENCE_SUCCESS",
  GET_PRIMARYRESIDENCE_FAILURE: "GET_PRIMARYRESIDENCE_FAILURE",

  ADD_PRIMARYRESIDENCE_REQUEST: "ADD_PRIMARYRESIDENCE_REQUEST",
  ADD_PRIMARYRESIDENCE_SUCCESS: "ADD_PRIMARYRESIDENCE_SUCCESS",
  ADD_PRIMARYRESIDENCE_FAILURE: "ADD_PRIMARYRESIDENCE_FAILURE",

  EDIT_PRIMARYRESIDENCE_REQUEST: "EDIT_PRIMARYRESIDENCE_REQUEST",
  EDIT_PRIMARYRESIDENCE_SUCCESS: "EDIT_PRIMARYRESIDENCE_SUCCESS",
  EDIT_PRIMARYRESIDENCE_FAILURE: "EDIT_PRIMARYRESIDENCE_FAILURE",

  DELETE_PRIMARYRESIDENCE_REQUEST: "DELETE_PRIMARYRESIDENCE_REQUEST",
  DELETE_PRIMARYRESIDENCE_SUCCESS: "DELETE_PRIMARYRESIDENCE_SUCCESS",
  DELETE_PRIMARYRESIDENCE_FAILURE: "DELETE_PRIMARYRESIDENCE_FAILURE"
}

export const cashAccountFormAction = {
  SET_UPDATE_CASH_ACCOUNT_TRACKER: "SET_UPDATE_CASH_ACCOUNT_TRACKER",
  SET_CASH_ACCOUNT_MODEL: "SET_CASH_ACCOUNT_MODEL"
}

export const personalDetailsAction = {
  GET_PERSONALDETAIL_SUCCESS: "GET_KYCPERSONALDETAIL_SUCCESS",
  ADD_PERSONALDETAIL_SUCCESS: "ADD_KYCPERSONALDETAIL_SUCCESS",
  EDIT_PERSONALDETAIL_SUCCESS: "EDIT_KYCPERSONALDETAIL_SUCCESS",
  DELETE_PERSONALDETAIL_SUCCESS: "DELETE_KYCPERSONALDETAIL_SUCCESS",
  ADD_PERSONALDETAIL_PARTNER_SUCCESS: "ADD_KYCPERSONALDETAILPARTNER_SUCCESS"
}

export const estatePlanningAction = {
  GET_ESTATEPLANNING_REQUEST: "GET_KYCESTATEPLANNING_REQUEST",
  GET_ESTATEPLANNING_SUCCESS: "GET_KYCESTATEPLANNING_SUCCESS",
  GET_ESTATEPLANNING_FAILURE: "GET_KYCESTATEPLANNING_FAILURE",

  ADD_ESTATEPLANNING_REQUEST: "ADD_KYCESTATEPLANNING_REQUEST",
  ADD_ESTATEPLANNING_SUCCESS: "ADD_KYCESTATEPLANNING_SUCCESS",
  ADD_ESTATEPLANNING_FAILURE: "ADD_KYCESTATEPLANNING_FAILURE",

  EDIT_ESTATEPLANNING_REQUEST: "EDIT_KYCESTATEPLANNING_REQUEST",
  EDIT_ESTATEPLANNING_SUCCESS: "EDIT_KYCESTATEPLANNING_SUCCESS",
  EDIT_ESTATEPLANNING_FAILURE: "EDIT_KYCESTATEPLANNING_FAILURE",

  DELETE_ESTATEPLANNING_REQUEST: "DELETE_KYCESTATEPLANNING_REQUEST",
  DELETE_ESTATEPLANNING_SUCCESS: "DELETE_KYCESTATEPLANNING_SUCCESS",
  DELETE_ESTATEPLANNING_FAILURE: "DELETE_KYCESTATEPLANNING_FAILURE"
}

export const goalAction = {
  GET_CLIENT_SUCCESS: "GOAL_GET_CLIENT_SUCCESS",
  GET_CLIENT_WITH_PARTNER_SUCCESS: "GOAL_GET_CLIENT_WITH_PARTNER_SUCCESS",
  GET_GOAL_SUCCESS: "GET_GOAL_SUCCESS",
  SAVE_GOAL_SUCCESS: "SAVE_GOAL_SUCCESS",
  TOOGLE_SECTION_DISPLAY: "TOOGLE_SECTION_DISPLAY",
  SHOW_GOAL_DIALOG: "SHOW_GOAL_DIALOG",
  HIDE_GOAL_DIALOG: "HIDE_GOAL_DIALOG",
  UPDATE_DATA_IN_STORE: "UPDATE_GOAL_DATA_IN_STORE",
  GET_LIQUID_ASSETS_GOAL_SUCCESS: "GET_LIQUID_ASSETS_GOAL_SUCCESS",
  GET_PROPERTIES_GOAL_SUCCESS: "GET_PROPERTIES_GOAL_SUCCESS"
}

export const riskProfileAction = {
  GET_CLIENT_SUCCESS: "RISK_PROFILE_GET_CLIENT_SUCCESS",
  GET_CLIENT_WITH_PARTNER_SUCCESS:
    "RISK_PROFILE_GET_CLIENT_WITH_PARTNER_SUCCESS",
  GET_RISK_PROFILE_SUCCESS: "GET_RISK_PROFILE_SUCCESS",
  SAVE_RISK_PROFILE_SUCCESS: "SAVE_RISK_PROFILE_SUCCESS",
  UPDATE_DATA_IN_STORE: "UPDATE_RISK_PROFILE_DATA_IN_STORE"
}

// / net worth
export const netWorthAction = {
  TOGGLE_ACCORDION: "TOGGLE_ACCORDION",
  CLEAR_ACCORDION: "CLEAR_ACCORDION",
  UPDATE_LISTING_FILTER: "UPDATE_LISTING_FILTER",
  GET_PERSONAL_ASSETS_LISTING_SUCCESS: "GET_PERSONAL_ASSETS_LISTING_SUCCESS",
  GET_LIQUID_ASSETS_LISTING_SUCCESS: "GET_LIQUID_ASSETS_LISTING_SUCCESS",
  GET_ILLIQUID_ASSETS_LISTING_SUCCESS: "GET_ILLIQUID_ASSETS_LISTING_SUCCESS",
  GET_RETIREMENT_ASSETS_LISTING_SUCCESS:
    "GET_RETIREMENT_ASSETS_LISTING_SUCCESS",
  GET_GEAL_SUCCESS: "GET_GEAL_SUCCESS",
  GET_CPF_SUCCESS: "GET_CPF_SUCCESS",
  GET_LIABILITIES_LISTING_SUCCESS: "GET_LIABILITIES_LISTING_SUCCESS",
  GET_TAXATION_LISTING_SUCCESS: "GET_TAXATION_LISTING_SUCCESS",
  GET_EMPLOYMENT_TERMINATION_PAYMENTS_SUCCESS:
    "GET_EMPLOYMENT_TERMINATION_PAYMENTS_SUCCESS",
  GET_NET_WORTH_TOTAL_SUMMARY_SUCCESS: "GET_NET_WORTH_TOTAL_SUMMARY_SUCCESS",
  OPEN_CONFIRMATION_DIALOG: "OPEN_CONFIRMATION_DIALOG",
  CLOSE_CONFIRMATION_DIALOG: "CLOSE_CONFIRMATION_DIALOG",
  GET_EMPLOYEE_MANDATORY_CONTRIBUTION: "GET_EMPLOYEE_MANDATORY_CONTRIBUTION",
  GET_EMPLOYER_MANDATORY_CONTRIBUTION: "GET_EMPLOYER_MANDATORY_CONTRIBUTION"
}

export const netWorthFormAction = {
  SET_UPDATE_NET_WORTH_FORM_SUCCESS: "SET_UPDATE_NET_WORTH_FORM_SUCCESS",
  SET_NET_WORTH_FORM_MODEL: "SET_NET_WORTH_FORM_MODEL",
  SET_SALARIES_LIST_SUCCESS: "SET_SALARIES_LIST_SUCCESS"
}

export const insuranceAction = {
  TOGGLE_SECTION: "TOGGLE_ACCORDION",
  GET_PERSONAL_INSURANCE_SUCCESS: "GET_PERSONAL_INSURANCE_SUCCESS",
  GET_ILAS_INSURANCE_SUCCESS: "GET_ILAS_INSURANCE_SUCCESS",
  GET_GENERAL_INSURANCE_SUCCESS: "GET_GENERAL_INSURANCE_SUCCESS"
}

export const businessOwnershipAction = {
  GET_BUSINESSOWNERSHIP_REQUEST: "GET_BUSINESSOWNERSHIP_REQUEST",
  GET_BUSINESSOWNERSHIP_SUCCESS: "GET_BUSINESSOWNERSHIP_SUCCESS",
  GET_BUSINESSOWNERSHIP_FAILURE: "GET_BUSINESSOWNERSHIP_FAILURE",

  ADD_BUSINESSOWNERSHIP_REQUEST: "ADD_BUSINESSOWNERSHIP_REQUEST",
  ADD_BUSINESSOWNERSHIP_SUCCESS: "ADD_BUSINESSOWNERSHIP_SUCCESS",
  ADD_BUSINESSOWNERSHIP_FAILURE: "ADD_BUSINESSOWNERSHIP_FAILURE",

  EDIT_BUSINESSOWNERSHIP_REQUEST: "EDIT_BUSINESSOWNERSHIP_REQUEST",
  EDIT_BUSINESSOWNERSHIP_SUCCESS: "EDIT_BUSINESSOWNERSHIP_SUCCESS",
  EDIT_BUSINESSOWNERSHIP_FAILURE: "EDIT_BUSINESSOWNERSHIP_FAILURE",

  DELETE_BUSINESSOWNERSHIP_REQUEST: "DELETE_BUSINESSOWNERSHIP_REQUEST",
  DELETE_BUSINESSOWNERSHIP_SUCCESS: "DELETE_BUSINESSOWNERSHIP_SUCCESS",
  DELETE_BUSINESSOWNERSHIP_FAILURE: "DELETE_BUSINESSOWNERSHIP_FAILURE"
}
// / end net worth


export const superAnnuationFundAction = {
  GET_SUPERANNUATIONFUND_REQUEST: "GET_SUPERANNUATIONFUND_REQUEST",
  GET_SUPERANNUATIONFUND_SUCCESS: "GET_SUPERANNUATIONFUND_SUCCESS",
  GET_SUPERANNUATIONFUND_FAILURE: "GET_SUPERANNUATIONFUND_FAILURE",

  ADD_SUPERANNUATIONFUND_REQUEST: "ADD_SUPERANNUATIONFUND_REQUEST",
  ADD_SUPERANNUATIONFUND_SUCCESS: "ADD_SUPERANNUATIONFUND_SUCCESS",
  ADD_SUPERANNUATIONFUND_FAILURE: "ADD_SUPERANNUATIONFUND_FAILURE",

  EDIT_SUPERANNUATIONFUND_REQUEST: "EDIT_SUPERANNUATIONFUND_REQUEST",
  EDIT_SUPERANNUATIONFUND_SUCCESS: "EDIT_SUPERANNUATIONFUND_SUCCESS",
  EDIT_SUPERANNUATIONFUND_FAILURE: "EDIT_SUPERANNUATIONFUND_FAILURE",

  DELETE_SUPERANNUATIONFUND_REQUEST: "DELETE_SUPERANNUATIONFUND_REQUEST",
  DELETE_SUPERANNUATIONFUND_SUCCESS: "DELETE_SUPERANNUATIONFUND_SUCCESS",
  DELETE_SUPERANNUATIONFUND_FAILURE: "DELETE_SUPERANNUATIONFUND_FAILURE"
}

export const superAnnuationFundInsuranceAction = {
  GET_SFINSURANCE_REQUEST: "GET_SFINSURANCE_REQUEST",
  GET_SFINSURANCE_SUCCESS: "GET_SFINSURANCE_SUCCESS",
  GET_SFINSURANCE_FAILURE: "GET_SFINSURANCE_FAILURE",

  ADD_SFINSURANCE_REQUEST: "ADD_SFINSURANCE_REQUEST",
  ADD_SFINSURANCE_SUCCESS: "ADD_SFINSURANCE_SUCCESS",
  ADD_SFINSURANCE_FAILURE: "ADD_SFINSURANCE_FAILURE",

  EDIT_SFINSURANCE_REQUEST: "EDIT_SFINSURANCE_REQUEST",
  EDIT_SFINSURANCE_SUCCESS: "EDIT_SFINSURANCE_SUCCESS",
  EDIT_SFINSURANCE_FAILURE: "EDIT_SFINSURANCE_FAILURE",

  DELETE_SFINSURANCE_REQUEST: "DELETE_SFINSURANCE_REQUEST",
  DELETE_SFINSURANCE_SUCCESS: "DELETE_SFINSURANCE_SUCCESS",
  DELETE_SFINSURANCE_FAILURE: "DELETE_SFINSURANCE_FAILURE"
}

export const investmentPlatformInformationAction = {
  GET_INVESTMENTPLATFORMINFORMATION_REQUEST:
    "GET_INVESTMENTPLATFORMINFORMATION_REQUEST",
  GET_INVESTMENTPLATFORMINFORMATION_SUCCESS:
    "GET_INVESTMENTPLATFORMINFORMATION_SUCCESS",
  GET_INVESTMENTPLATFORMINFORMATION_FAILURE:
    "GET_INVESTMENTPLATFORMINFORMATION_FAILURE",

  ADD_INVESTMENTPLATFORMINFORMATION_REQUEST:
    "ADD_INVESTMENTPLATFORMINFORMATION_REQUEST",
  ADD_INVESTMENTPLATFORMINFORMATION_SUCCESS:
    "ADD_INVESTMENTPLATFORMINFORMATION_SUCCESS",
  ADD_INVESTMENTPLATFORMINFORMATION_FAILURE:
    "ADD_INVESTMENTPLATFORMINFORMATION_FAILURE",

  EDIT_INVESTMENTPLATFORMINFORMATION_REQUEST:
    "EDIT_INVESTMENTPLATFORMINFORMATION_REQUEST",
  EDIT_INVESTMENTPLATFORMINFORMATION_SUCCESS:
    "EDIT_INVESTMENTPLATFORMINFORMATION_SUCCESS",
  EDIT_INVESTMENTPLATFORMINFORMATION_FAILURE:
    "EDIT_INVESTMENTPLATFORMINFORMATION_FAILURE",

  DELETE_INVESTMENTPLATFORMINFORMATION_REQUEST:
    "DELETE_INVESTMENTPLATFORMINFORMATION_REQUEST",
  DELETE_INVESTMENTPLATFORMINFORMATION_SUCCESS:
    "DELETE_INVESTMENTPLATFORMINFORMATION_SUCCESS",
  DELETE_INVESTMENTPLATFORMINFORMATION_FAILURE:
    "DELETE_INVESTMENTPLATFORMINFORMATION_FAILURE"
}

export const fundAllocationDetailAction = {
  GET_FUNDALLOCATIONDETAIL_REQUEST: "GET_FUNDALLOCATIONDETAIL_REQUEST",
  GET_FUNDALLOCATIONDETAIL_SUCCESS: "GET_FUNDALLOCATIONDETAIL_SUCCESS",
  GET_FUNDALLOCATIONDETAIL_FAILURE: "GET_FUNDALLOCATIONDETAIL_FAILURE",

  ADD_FUNDALLOCATIONDETAIL_REQUEST: "ADD_FUNDALLOCATIONDETAIL_REQUEST",
  ADD_FUNDALLOCATIONDETAIL_SUCCESS: "ADD_FUNDALLOCATIONDETAIL_SUCCESS",
  ADD_FUNDALLOCATIONDETAIL_FAILURE: "ADD_FUNDALLOCATIONDETAIL_FAILURE",

  EDIT_FUNDALLOCATIONDETAIL_REQUEST: "EDIT_FUNDALLOCATIONDETAIL_REQUEST",
  EDIT_FUNDALLOCATIONDETAIL_SUCCESS: "EDIT_FUNDALLOCATIONDETAIL_SUCCESS",
  EDIT_FUNDALLOCATIONDETAIL_FAILURE: "EDIT_FUNDALLOCATIONDETAIL_FAILURE",

  DELETE_FUNDALLOCATIONDETAIL_REQUEST: "DELETE_FUNDALLOCATIONDETAIL_REQUEST",
  DELETE_FUNDALLOCATIONDETAIL_SUCCESS: "DELETE_FUNDALLOCATIONDETAIL_SUCCESS",
  DELETE_FUNDALLOCATIONDETAIL_FAILURE: "DELETE_FUNDALLOCATIONDETAIL_FAILURE"
}

export const mandatoryProvidentFundAction = {
  GET_MPFINFORMATION_REQUEST: "GET_MPFINFORMATION_REQUEST",
  GET_MPFINFORMATION_SUCCESS: "GET_MPFINFORMATION_SUCCESS",
  GET_MPFINFORMATION_FAILURE: "GET_MPFINFORMATION_FAILURE",

  ADD_MPFINFORMATION_REQUEST: "ADD_MPFINFORMATION_REQUEST",
  ADD_MPFINFORMATION_SUCCESS: "ADD_MPFINFORMATION_SUCCESS",
  ADD_MPFINFORMATION_FAILURE: "ADD_MPFINFORMATION_FAILURE",

  EDIT_MPFINFORMATION_REQUEST: "EDIT_MPFINFORMATION_REQUEST",
  EDIT_MPFINFORMATION_SUCCESS: "EDIT_MPFINFORMATION_SUCCESS",
  EDIT_MPFINFORMATION_FAILURE: "EDIT_MPFINFORMATION_FAILURE",

  DELETE_MPFINFORMATION_REQUEST: "DELETE_MPFINFORMATION_REQUEST",
  DELETE_MPFINFORMATION_SUCCESS: "DELETE_MPFINFORMATION_SUCCESS",
  DELETE_MPFINFORMATION_FAILURE: "DELETE_MPFINFORMATION_FAILURE"
}

export const unitTrustHoldingInformationAction = {
  GET_UNITTRUSTHOLDINGINFORMATION_REQUEST:
    "GET_UNITTRUSTHOLDINGINFORMATION_REQUEST",
  GET_UNITTRUSTHOLDINGINFORMATION_SUCCESS:
    "GET_UNITTRUSTHOLDINGINFORMATION_SUCCESS",
  GET_UNITTRUSTHOLDINGINFORMATION_FAILURE:
    "GET_UNITTRUSTHOLDINGINFORMATION_FAILURE",

  ADD_UNITTRUSTHOLDINGINFORMATION_REQUEST:
    "ADD_UNITTRUSTHOLDINGINFORMATION_REQUEST",
  ADD_UNITTRUSTHOLDINGINFORMATION_SUCCESS:
    "ADD_UNITTRUSTHOLDINGINFORMATION_SUCCESS",
  ADD_UNITTRUSTHOLDINGINFORMATION_FAILURE:
    "ADD_UNITTRUSTHOLDINGINFORMATION_FAILURE",

  EDIT_UNITTRUSTHOLDINGINFORMATION_REQUEST:
    "EDIT_UNITTRUSTHOLDINGINFORMATION_REQUEST",
  EDIT_UNITTRUSTHOLDINGINFORMATION_SUCCESS:
    "EDIT_UNITTRUSTHOLDINGINFORMATION_SUCCESS",
  EDIT_UNITTRUSTHOLDINGINFORMATION_FAILURE:
    "EDIT_UNITTRUSTHOLDINGINFORMATION_FAILURE",

  DELETE_UNITTRUSTHOLDINGINFORMATION_REQUEST:
    "DELETE_UNITTRUSTHOLDINGINFORMATION_REQUEST",
  DELETE_UNITTRUSTHOLDINGINFORMATION_SUCCESS:
    "DELETE_UNITTRUSTHOLDINGINFORMATION_SUCCESS",
  DELETE_UNITTRUSTHOLDINGINFORMATION_FAILURE:
    "DELETE_UNITTRUSTHOLDINGINFORMATION_FAILURE"
}

export const occupationalRetirementSchemeOrdinanceAction = {
  GET_ORSODETAIL_REQUEST: "GET_ORSODETAIL_REQUEST",
  GET_ORSODETAIL_SUCCESS: "GET_ORSODETAIL_SUCCESS",
  GET_ORSODETAIL_FAILURE: "GET_ORSODETAIL_FAILURE",

  ADD_ORSODETAIL_REQUEST: "ADD_ORSODETAIL_REQUEST",
  ADD_ORSODETAIL_SUCCESS: "ADD_ORSODETAIL_SUCCESS",
  ADD_ORSODETAIL_FAILURE: "ADD_ORSODETAIL_FAILURE",

  EDIT_ORSODETAIL_REQUEST: "EDIT_ORSODETAIL_REQUEST",
  EDIT_ORSODETAIL_SUCCESS: "EDIT_ORSODETAIL_SUCCESS",
  EDIT_ORSODETAIL_FAILURE: "EDIT_ORSODETAIL_FAILURE",

  DELETE_ORSODETAIL_REQUEST: "DELETE_ORSODETAIL_REQUEST",
  DELETE_ORSODETAIL_SUCCESS: "DELETE_ORSODETAIL_SUCCESS",
  DELETE_ORSODETAIL_FAILURE: "DELETE_ORSODETAIL_FAILURE"
}

export const cashAccountInformationAction = {
  GET_CASHACCOUNTINFORMATION_REQUEST: "GET_CASHACCOUNTINFORMATION_REQUEST",
  GET_CASHACCOUNTINFORMATION_SUCCESS: "GET_CASHACCOUNTINFORMATION_SUCCESS",
  GET_CASHACCOUNTINFORMATION_FAILURE: "GET_CASHACCOUNTINFORMATION_FAILURE",

  ADD_CASHACCOUNTINFORMATION_REQUEST: "ADD_CASHACCOUNTINFORMATION_REQUEST",
  ADD_CASHACCOUNTINFORMATION_SUCCESS: "ADD_CASHACCOUNTINFORMATION_SUCCESS",
  ADD_CASHACCOUNTINFORMATION_FAILURE: "ADD_CASHACCOUNTINFORMATION_FAILURE",

  EDIT_CASHACCOUNTINFORMATION_REQUEST: "EDIT_CASHACCOUNTINFORMATION_REQUEST",
  EDIT_CASHACCOUNTINFORMATION_SUCCESS: "EDIT_CASHACCOUNTINFORMATION_SUCCESS",
  EDIT_CASHACCOUNTINFORMATION_FAILURE: "EDIT_CASHACCOUNTINFORMATION_FAILURE",

  DELETE_CASHACCOUNTINFORMATION_REQUEST:
    "DELETE_CASHACCOUNTINFORMATION_REQUEST",
  DELETE_CASHACCOUNTINFORMATION_SUCCESS:
    "DELETE_CASHACCOUNTINFORMATION_SUCCESS",
  DELETE_CASHACCOUNTINFORMATION_FAILURE: "DELETE_CASHACCOUNTINFORMATION_FAILURE"
}

export const termDepositInformationAction = {
  GET_TERMDEPOSITINFORMATION_REQUEST: "GET_TERMDEPOSITINFORMATION_REQUEST",
  GET_TERMDEPOSITINFORMATION_SUCCESS: "GET_TERMDEPOSITINFORMATION_SUCCESS",
  GET_TERMDEPOSITINFORMATION_FAILURE: "GET_TERMDEPOSITINFORMATION_FAILURE",

  ADD_TERMDEPOSITINFORMATION_REQUEST: "ADD_TERMDEPOSITINFORMATION_REQUEST",
  ADD_TERMDEPOSITINFORMATION_SUCCESS: "ADD_TERMDEPOSITINFORMATION_SUCCESS",
  ADD_TERMDEPOSITINFORMATION_FAILURE: "ADD_TERMDEPOSITINFORMATION_FAILURE",

  EDIT_TERMDEPOSITINFORMATION_REQUEST: "EDIT_TERMDEPOSITINFORMATION_REQUEST",
  EDIT_TERMDEPOSITINFORMATION_SUCCESS: "EDIT_TERMDEPOSITINFORMATION_SUCCESS",
  EDIT_TERMDEPOSITINFORMATION_FAILURE: "EDIT_TERMDEPOSITINFORMATION_FAILURE",

  DELETE_TERMDEPOSITINFORMATION_REQUEST:
    "DELETE_TERMDEPOSITINFORMATION_REQUEST",
  DELETE_TERMDEPOSITINFORMATION_SUCCESS:
    "DELETE_TERMDEPOSITINFORMATION_SUCCESS",
  DELETE_TERMDEPOSITINFORMATION_FAILURE: "DELETE_TERMDEPOSITINFORMATION_FAILURE"
}

export const sharesHoldingInformationAction = {
  GET_SHARESHOLDINGINFORMATION_REQUEST: "GET_SHARESHOLDINGINFORMATION_REQUEST",
  GET_SHARESHOLDINGINFORMATION_SUCCESS: "GET_SHARESHOLDINGINFORMATION_SUCCESS",
  GET_SHARESHOLDINGINFORMATION_FAILURE: "GET_SHARESHOLDINGINFORMATION_FAILURE",

  ADD_SHARESHOLDINGINFORMATION_REQUEST: "ADD_SHARESHOLDINGINFORMATION_REQUEST",
  ADD_SHARESHOLDINGINFORMATION_SUCCESS: "ADD_SHARESHOLDINGINFORMATION_SUCCESS",
  ADD_SHARESHOLDINGINFORMATION_FAILURE: "ADD_SHARESHOLDINGINFORMATION_FAILURE",

  EDIT_SHARESHOLDINGINFORMATION_REQUEST:
    "EDIT_SHARESHOLDINGINFORMATION_REQUEST",
  EDIT_SHARESHOLDINGINFORMATION_SUCCESS:
    "EDIT_SHARESHOLDINGINFORMATION_SUCCESS",
  EDIT_SHARESHOLDINGINFORMATION_FAILURE:
    "EDIT_SHARESHOLDINGINFORMATION_FAILURE",

  DELETE_SHARESHOLDINGINFORMATION_REQUEST:
    "DELETE_SHARESHOLDINGINFORMATION_REQUEST",
  DELETE_SHARESHOLDINGINFORMATION_SUCCESS:
    "DELETE_SHARESHOLDINGINFORMATION_SUCCESS",
  DELETE_SHARESHOLDINGINFORMATION_FAILURE:
    "DELETE_SHARESHOLDINGINFORMATION_FAILURE"
}

export const bondsHoldingInformationAction = {
  GET_BONDSHOLDINGINFORMATION_REQUEST: "GET_BONDSHOLDINGINFORMATION_REQUEST",
  GET_BONDSHOLDINGINFORMATION_SUCCESS: "GET_BONDSHOLDINGINFORMATION_SUCCESS",
  GET_BONDSHOLDINGINFORMATION_FAILURE: "GET_BONDSHOLDINGINFORMATION_FAILURE",

  ADD_BONDSHOLDINGINFORMATION_REQUEST: "ADD_BONDSHOLDINGINFORMATION_REQUEST",
  ADD_BONDSHOLDINGINFORMATION_SUCCESS: "ADD_BONDSHOLDINGINFORMATION_SUCCESS",
  ADD_BONDSHOLDINGINFORMATION_FAILURE: "ADD_BONDSHOLDINGINFORMATION_FAILURE",

  EDIT_BONDSHOLDINGINFORMATION_REQUEST: "EDIT_BONDSHOLDINGINFORMATION_REQUEST",
  EDIT_BONDSHOLDINGINFORMATION_SUCCESS: "EDIT_BONDSHOLDINGINFORMATION_SUCCESS",
  EDIT_BONDSHOLDINGINFORMATION_FAILURE: "EDIT_BONDSHOLDINGINFORMATION_FAILURE",

  DELETE_BONDSHOLDINGINFORMATION_REQUEST:
    "DELETE_BONDSHOLDINGINFORMATION_REQUEST",
  DELETE_BONDSHOLDINGINFORMATION_SUCCESS:
    "DELETE_BONDSHOLDINGINFORMATION_SUCCESS",
  DELETE_BONDSHOLDINGINFORMATION_FAILURE:
    "DELETE_BONDSHOLDINGINFORMATION_FAILURE"
}

export const eTFHoldingInformationAction = {
  GET_ETFHOLDINGINFORMATION_REQUEST: "GET_ETFHOLDINGINFORMATION_REQUEST",
  GET_ETFHOLDINGINFORMATION_SUCCESS: "GET_ETFHOLDINGINFORMATION_SUCCESS",
  GET_ETFHOLDINGINFORMATION_FAILURE: "GET_ETFHOLDINGINFORMATION_FAILURE",

  ADD_ETFHOLDINGINFORMATION_REQUEST: "ADD_ETFHOLDINGINFORMATION_REQUEST",
  ADD_ETFHOLDINGINFORMATION_SUCCESS: "ADD_ETFHOLDINGINFORMATION_SUCCESS",
  ADD_ETFHOLDINGINFORMATION_FAILURE: "ADD_ETFHOLDINGINFORMATION_FAILURE",

  EDIT_ETFHOLDINGINFORMATION_REQUEST: "EDIT_ETFHOLDINGINFORMATION_REQUEST",
  EDIT_ETFHOLDINGINFORMATION_SUCCESS: "EDIT_ETFHOLDINGINFORMATION_SUCCESS",
  EDIT_ETFHOLDINGINFORMATION_FAILURE: "EDIT_ETFHOLDINGINFORMATION_FAILURE",

  DELETE_ETFHOLDINGINFORMATION_REQUEST: "DELETE_ETFHOLDINGINFORMATION_REQUEST",
  DELETE_ETFHOLDINGINFORMATION_SUCCESS: "DELETE_ETFHOLDINGINFORMATION_SUCCESS",
  DELETE_ETFHOLDINGINFORMATION_FAILURE: "DELETE_ETFHOLDINGINFORMATION_FAILURE"
}

export const appSettingAction = {
  SET_ACTIVE_CLASS: "SET_ACTIVE_CLASS",
  SET_ACTIVE_USER: "SET_ACTIVE_USER",
  SET_USER_ENTITIES: "SET_USER_ENTITIES",
  SET_ACTIVE_USER_ID: "SET_ACTIVE_USER_ID",
  SET_BURGER_MENU: "SET_BURGER_MENU",
  SET_CURRENT_LANGUAGE: "SET_CURRENT_LANGUAGE"
}

export const entityAction = {
  GET_ENTITY_SUCCESS: "GET_ENTITY_SUCCESS",
  GET_LOGIN_ENTITY_SUCCESS: "GET_LOGIN_ENTITY_SUCCESS",
  ADD_ENTITY_SUCCESS: "ADD_ENTITY_SUCCESS",
  EMPTY_ENTITY_SUCCESS: "EMPTY_ENTITY_SUCCESS",
  EDIT_ENTITY_SUCCESS: "EDIT_ENTITY_SUCCESS",
  DELETE_ENTITY_SUCCESS: "DELETE_ENTITY_SUCCESS",
  HasAcknowledgedTermsAndConditions: "HasAcknowledgedTermsAndConditions",
  GET_APPLICATION_SUCCESS: "GET_APPLICATION_SUCCESS"
}

export const dashboardAction = {
  GET_DATA_BY_TYPE_SUCCESS: "GET_DATA_BY_TYPE_SUCCESS",
  GET_DATA_BY_ID_SUCCESS: "GET_DATA_BY_ID_SUCCESS",
  SET_BREAD_CRUMB_LABEL: "SET_BREAD_CRUMB_LABEL",
  SET_DASHBOARD_ENTITY: "SET_DASHBOARD_ENTITY",
  GET_DATA_BY_FILTER_AND_PAGE: "GET_DATA_BY_FILTER_AND_PAGE"
  // GET_DATA_ADVISER_SUCCESS: 'GET_DATA_ADVISER_SUCCESS',
}

export const fileOperationAction = {
  UPLOAD_SUCCESS: "UPLOAD_SUCCESS"
}

export const feedbackAction = {
  ADD_FEEDBACK_SUCCESS: "ADD_FEEDBACK_SUCCESS",
  UPDATE_FEEDBACK_SUCCESS: "UPDATE_FEEDBACK_SUCCESS",
  GET_BY_TYPE_FEEDBACK_SUCCESS: "GET_BY_TYPE_FEEDBACK_SUCCESS",
  GET_BY_TYPE_FEEDBACK_REQUEST: "GET_BY_TYPE_FEEDBACK_REQUEST",
  GET_BY_TYPE_FEEDBACK_FAILED: "GET_BY_TYPE_FEEDBACK_FAILED",
  GET_BY_ENTITY_FEEDBACK_SUCCESS: "GET_BY_ENTITY_FEEDBACK_SUCCESS",
  GET_BY_ENTITY_FEEDBACK_REQUEST: "GET_BY_ENTITY_FEEDBACK_REQUEST",
  GET_BY_ENTITY_FEEDBACK_FAILED: "GET_BY_ENTITY_FEEDBACK_FAILED"
}

export const notesAction = {
  ADD_NOTE_SUCCESS: "ADD_NOTE_SUCCESS",
  GET_NOTES_SUCCESS: "GET_NOTES_SUCCESS",
  GET_NOTES_REQUEST: "GET_NOTES_REQUEST",
  GET_NOTES_FAIL: "GET_NOTES_FAIL"
}
export const cashFlowAction = {
  GET_CLIENT_SUCCESS: "CASHFLOW_GET_CLIENT_SUCCESS",
  GET_CLIENT_WITH_PARTNER_SUCCESS: "CASHFLOW_GET_CLIENT_WITH_PARTNER_SUCCESS",
  EDIT_CLIENT_CASHFLOW_SUCCESS: "EDIT_CLIENT_CASHFLOW_SUCCESS"
}

export const currentStandingAction = {
  GET_CURRENT_STANDING_SUCCESS: "GET_CURRENT_STANDING_SUCCESS",
  GET_LIFELINE_SUCCESS: "GET_LIFELINE_SUCCESS",
  CLEAR_CURRENT_STANDING_SUCCESS: "CLEAR_CURRENT_STANDING_SUCCESS"
}

export const emailAction = {
  GET_RECIPIENTS: "GET_RECIPIENTS"
}

export const colorAction = {
  COLOR_SUCCESS: "COLOR_SUCCESS",
  FONT_COLOR_SUCCESS: "FONT_COLOR_SUCCESS",
  SET_COLOR_SUCCESS: "SET_COLOR_SUCCESS"
}

export const implementationActions = {
  SENDPASSWORD_SUCCESS: "SENDPASSWORD_SUCCESS",
  GET_APPLICATION_SUCCESS: "GET_APPLICATION_SUCCESS",
  VALIDATE_KYC_SUCCESS: "VALIDATE_KYC_SUCCESS",
  GET_KYC_STATUS: "GET_KYC_STATUS",
  SET_KYC_DATA_EMPTY: "SET_KYC_DATA_EMPTY"
}

export const scenariosActions = {
  GET_SCENARIO_REQUEST: "GET_SCENARIO_REQUEST",
  GET_SCENARIO_SUCCESS: "GET_SCENARIO_SUCCESS",
  GET_SCENARIO_FAILURE: "GET_SCENARIO_FAILURE",
  SET_ACTIVE_SCENARIO: "SET_ACTIVE_SCENARIO",
  RESET_ACTIVE_SCENARIO: "RESET_ACTIVE_SCENARIO"
}
