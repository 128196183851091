import { clientAction, insuranceAction } from '../../constants/ActionConstants';
import { clientDataAdapter } from '../../helpers/ClientHelper';

export function insurance(state = {}, action) {
    switch (action.type) {
        case clientAction.GET_CLIENT_SUCCESS: {
            return {
                ...state,
                client: clientDataAdapter(action.response.data),
                isJoint: false,
                loading: false,
            };
        }
        case clientAction.GET_CLIENT_WITH_PARTNER_SUCCESS: {
            return {
                ...state,
                client: clientDataAdapter(action.client),
                partner: clientDataAdapter(action.partner),
                isJoint: true,
                loading: false,
            };
        }
        case insuranceAction.TOGGLE_SECTION: {
            let openedSection = [];

            if (
                state.openedSection &&
                state.openedSection.includes(action.sectionName)
            ) {
                openedSection = state.openedSection.filter(
                    o => o !== action.sectionName
                );
            } else if (state.openedSection && state.openedSection.length > 0) {
                openedSection = [...state.openedSection, action.sectionName];
            } else {
                openedSection = [action.sectionName];
            }

            return {
                ...state,
                openedSection,
            };
        }
        case insuranceAction.GET_PERSONAL_INSURANCE_SUCCESS: {
            return {
                ...state,
                listing: {
                    ...(state.listing || {}),
                    personal: splitDataByClassification(
                        action.personalInsurance
                    ),
                },
            };
        }
        case insuranceAction.GET_ILAS_INSURANCE_SUCCESS: {
            return {
                ...state,
                listing: {
                    ...(state.listing || {}),
                    ilas: splitDataByClassification(action.ilasInsurance),
                },
            };
        }
        case insuranceAction.GET_GENERAL_INSURANCE_SUCCESS: {
            return {
                ...state,
                listing: {
                    ...(state.listing || {}),
                    general: splitDataByClassification(action.generalInsurance),
                },
            };
        }
        default: {
            return { ...state };
        }
    }
}

function splitDataByClassification(data = {}) {
    Object.keys(data).forEach(key => {
        const subData = data[key] || [];
        data[key] = {
            client: subData.filter(item => item.classification === 'CLIENT'),
            partner: subData.filter(item => item.classification === 'PARTNER'),
            joint: subData.filter(item => item.classification === 'JOINT'),
        };
    });
    return data;
}
