import React, { useState, useContext } from "react"
import { Link } from "react-router-dom"
import { createUrlFromPath } from "../../helpers/ImageHelper"
import { menuItems } from "../../helpers/EntityMenuHelper"
import { AppContext } from "../../context/AppContext"
import UploadPhoto from "./UploadPhoto"
import Email from "./Email"
import DeleteEntity from "./DeleteEntity"
import intl from "react-intl-universal"
import Notes from "./Notes"
import TransferDialog from "./TransferDialog"
import { history, numberFormat } from "../../helpers"
import UserApi from "../../api/UserApi"

import Tooltip from "rc-tooltip"
import Alert from "react-s-alert"
import { isLoginAsGlobal } from "../../helpers/SessionHelper"
import { PartnerIcon } from "../../assets/images/new-skin-images"

function BoxUser({
  user,
  onTagClicked,
  onAttachmentClicked,
  onEditClicked,
  activeEntityType,
  dashboardEntityToCheck,
  mode
}) {
  const {
    modal: { setModal, clearModal }
  } = useContext(AppContext)

  const [isShowTransferForm, setShowTransferForm] = useState([])
  const closeTransferForm = () => {
    setShowTransferForm(false)
  }

  const isClickAllowed = (user) => {
    let result = false
    if (isLoginAsGlobal()) {
      if (user.grantKoizaiGlobal == true) {
        result = true
      }
    } else {
      result = true
    }

    return result
  }

  function onCardClicked(user, path) {
    if (isClickAllowed(user)) {
      UserApi.track(path)
      history.push(path)
    } else {
      Alert.error(`Sorry, you don't have permission to see this data.`)
    }
  }

  //let isAccessible = true;
  return (
    <div className="v2-box-user">
      <img
        src={`${process.env.PUBLIC_URL}/assets/images/v2Images/profileUser.png`}
        alt="profile"
        className="personIcon"
      />
      {/* card */}
      <div className="card-box">
        <div
          className="banner"
          style={{
            backgroundImage: "url(" + createUrlFromPath(user.imagePath) + ")",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat"
          }}
        >
          <div className="images">
            <img
              onClick={() =>
                setModal({
                  title: "Upload Photo Profile",
                  content: (
                    <UploadPhoto
                      id={user.id}
                      currentPhotoUrl={user.imagePath}
                      clearModal={clearModal}
                      isClient={user.type === "CLIENT"}
                      type={user.type}
                    />
                  ),
                  isSmall: true
                })
              }
              src={`${process.env.PUBLIC_URL}/assets/images/v2Images/camera.png`}
              alt="profile"
              className="camera"
            />
            <Tooltip
              zIndex="100"
              placement="right"
              // trigger={["click"]}
              overlay={
                <div className="cover">
                  {user.type === "CLIENT" && (
                    <div
                      className="menu-items"
                      onClick={() => {
                        mode === "DEMO_CLIENT"
                          ? history.push(
                              `/personaldetails/${user.id}?DEMO_CLIENT`
                            )
                          : history.push(`/personaldetails/${user.id}`)
                      }}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/ico-edit.svg`}
                        alt="edit"
                      />{" "}
                      <span>Edit</span>
                    </div>
                  )}
                  {/* ends here */}

                  {user.type === "CLIENT" && (
                    <div
                      className="menu-items"
                      onClick={() =>
                        setModal({
                          title: "CLIENT FILE NOTES",
                          content: (
                            <Notes
                              user={user}
                              setModal={setModal}
                              closeModal={clearModal}
                            />
                          ),
                          isSmall: false
                        })
                      }
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/ico-notes.svg`}
                        alt="edit"
                        style={{ height: "27px", width: "27px" }}
                      />
                      <span>Note</span>
                    </div>
                  )}
                  {/* for transerfer dialog */}
                  {activeEntityType == "BUILT_IN_DEALERSHIP" && (
                    <div
                      className="menu-items"
                      title="Transfer"
                      onClick={() =>
                        setModal({
                          title: `TRANSFER ${user.type.replace(
                            "BUILT_IN_",
                            ""
                          )}`,
                          content: (
                            <TransferDialog
                              item={user}
                              clearModal={clearModal}
                            />
                          ),
                          isSmall: true
                        })
                      }
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/v2Images/transfer.svg`}
                        alt="email"
                      />{" "}
                      <span>Transfer</span>
                    </div>
                  )}
                  {/*  */}
                  <div
                    className="menu-items"
                    onClick={() =>
                      setModal({
                        title: "Send An Email",
                        content: (
                          <Email
                            userId={user.id}
                            clearModal={clearModal}
                            isClient={user.type === "CLIENT"}
                            type={user.type}
                            cc={user.email}
                          />
                        ),
                        isSmall: true
                      })
                    }
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/v2Images/email.png`}
                      alt="email"
                    />{" "}
                    <span>Email</span>
                  </div>

                  {user.type === "CLIENT" && (
                    <div className="menu-items">
                      <Link
                        to={`/dashboard/clientsDashboard?id=${user.id}`}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: "19px"
                        }}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/v2Images/eye.png`}
                          alt="eye"
                        />{" "}
                        <span>View As Client</span>
                      </Link>
                    </div>
                  )}
                  <div
                    className="menu-items"
                    onClick={() =>
                      setModal({
                        title: `${
                          user.type === "CLIENT"
                            ? "Archive Client"
                            : "Delete Organization"
                        }`,
                        content: (
                          <DeleteEntity
                            userId={user.id}
                            clearModal={clearModal}
                            isClient={user.type === "CLIENT"}
                            type={user.type}
                            cc={user.email}
                          />
                        ),
                        isSmall: true
                      })
                    }
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/v2Images/delete.png`}
                      alt="delete"
                    />{" "}
                    <span style={{ color: "red" }}>Delete</span>
                  </div>
                </div>
              }
              arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
            >
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/v2Images/3dot.png`}
                alt="profile"
                className="3dot"
              />
            </Tooltip>
          </div>
        </div>

        <div className="box-user-description-v2">
          {mode === "DEMO_CLIENT" ? (
            <div
              className="details"
              onClick={(e) =>
                onCardClicked(user, `/personaldetails/${user.id}?DEMO_CLIENT`)
              }
            >
              <a className={`content ${user.info.clientCategoryStatus}`}>
                <div
                  className="clientName"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                    justifyContent: "space-between"
                  }}
                >
                  <div>
                    <p style={{ margin: "0" }}>{user.info.clientName}</p>

                    {user.info.type === "JOINT" && (
                      <p style={{ margin: "0", marginTop: "9px" }}>
                        {`[${intl.get("client.partner")}:`}
                        <span className="value">{user.info.partnerName}]</span>
                      </p>
                    )}
                  </div>
                  {user && user.info && user.info.type === "JOINT" ? (
                    <img
                      src={PartnerIcon}
                      style={{ width: "24px", height: "24px", flexShrink: 0 }}
                    />
                  ) : (
                    <></>
                  )}
                </div>

                <p>
                  {intl.get("client.lastSubmission")}:
                  <span className="value">{user.info.lastSubmission}</span>
                </p>
                <p>
                  {intl.get("client.aum")}:
                  <span className="value">
                    {user.info.aum &&
                      user.info.aum.value &&
                      numberFormat(user.info.aum.value, user.info.aum.currency)}
                  </span>
                </p>
                <p>
                  {intl.get("client.ium")}:
                  <span className="value">
                    {user.info.ium &&
                      user.info.ium.value &&
                      numberFormat(user.info.ium.value, user.info.ium.currency)}
                  </span>
                </p>
                <p>
                  {intl.get("client.adviserName")}:
                  <span className="value">{user.info.adviserName}</span>
                </p>
                <p>
                  {intl.get("client.entityName")}:
                  <span className="value">{user.info.entityName}</span>
                </p>
              </a>
            </div>
          ) : user.type !== "CLIENT" ? (
            <div
              className={`details ${user.info.clientCategoryStatus}`}
              onClick={(e) =>
                onCardClicked(
                  user,
                  `/dashboard/${getUrlPrefix(user.type)}?cid=${user.id}`
                )
              }
            >
              <p className="value">{user.info.name}</p>

              <p>
                <span>Company Reg No:</span>
                <span className="value">{user.info.companyRegNo}</span>
              </p>
              <p>
                <span>Identifier:</span>
                <span className="value">{user.info.identifier}</span>
              </p>
              {user.type === "BUILT_IN_ADVISER" ? (
                <>
                  <p>
                    <span>Supervisor:</span>
                    <span className="value">{user.info.supervisor}</span>
                  </p>
                  <p>
                    <span>Mic:</span>
                    <span className="value">
                      {user.info.managerInCharge || ""}
                    </span>
                  </p>

                  <p>
                    <span>AUM:</span>
                    <span className="value">
                      {user.info.aum &&
                        user.info.aum.value &&
                        numberFormat(
                          user.info.aum.value,
                          user.info.aum.currency
                        )}
                    </span>
                  </p>
                  <p>
                    <span>
                      Clients:{" "}
                      {user.info.downlines &&
                        user.info.downlines.length &&
                        user.info.downlines[0].name}
                    </span>

                    <span className="value">
                      (
                      {user.info.downlines &&
                        user.info.downlines.length &&
                        user.info.downlines.map((item, index) => {
                          if (index === 0) return
                          return (
                            <span
                              style={{ textTransform: "capitalize" }}
                              key={index}
                            >
                              {item.key}={item.name}
                              {`${
                                user.info.downlines &&
                                user.info.downlines.length - 1 !== index
                                  ? ", "
                                  : ""
                              }`}
                            </span>
                          )
                        })}
                      )
                    </span>
                  </p>
                </>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <div
              className="details"
              onClick={(e) =>
                onCardClicked(user, `/personaldetails/${user.id}`)
              }
            >
              <a className={`content ${user.info.clientCategoryStatus}`}>
                <div
                  className="clientName"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                    justifyContent: "space-between"
                  }}
                >
                  <div>
                    <p style={{ margin: "0" }}>{user.info.clientName}</p>

                    {user.info.type === "JOINT" && (
                      <p style={{ margin: "0", marginTop: "9px" }}>
                        {`[${intl.get("client.partner")}:`}
                        <span className="value">{user.info.partnerName}]</span>
                      </p>
                    )}
                  </div>
                  {user && user.info && user.info.type === "JOINT" ? (
                    <img
                      src={PartnerIcon}
                      style={{ width: "24px", height: "24px", flexShrink: 0 }}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <p>
                  {intl.get("client.lastSubmission")}:
                  <span className="value">{user.info.lastSubmission}</span>
                </p>
                <p>
                  {intl.get("client.aum")}:
                  <span className="value">
                    {user.info.aum &&
                      user.info.aum.value &&
                      numberFormat(user.info.aum.value, user.info.aum.currency)}
                  </span>
                </p>
                <p>
                  {intl.get("client.ium")}:
                  <span className="value">
                    {user.info.ium &&
                      user.info.ium.value &&
                      numberFormat(user.info.ium.value, user.info.ium.currency)}
                  </span>
                </p>
                <p>
                  {intl.get("client.adviserName")}:
                  <span className="value">{user.info.adviserName}</span>
                </p>
                <p>
                  {intl.get("client.entityName")}:
                  <span className="value">{user.info.entityName}</span>
                </p>
              </a>
            </div>
          )}
        </div>
      </div>
      {/* end of card */}
    </div>
  )
}

const getUrlPrefix = (entityType) => {
  const findEntityItem = menuItems.find((o) => o.entityType === entityType)
  return findEntityItem.pluralName
}

export default BoxUser
