/*
    Author : anton.nuansantio@koizai.com
    Library : FinancialRegulatorApi
    Description : methods related with Financial Regulators
*/

import api from '../lib/api';
// change base route for different entity/domain here
const BASE_ROUTE = '/media';

class MediaApi {

    static getById(id) {
        return api.get(`${BASE_ROUTE}/${id}`);
    }

  
    static getList() {
        return api.get(`${BASE_ROUTE}`);
        
    }

  

    static addVideo(formData) {
        return api.post(`${BASE_ROUTE}`, formData);
        
    }

    static deleteById(id) {
        return api.delete(`${BASE_ROUTE}/${id}`);
        
    }

  
    
}

export default MediaApi;
