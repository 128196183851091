import intl from "react-intl-universal"
import { appSettingAction } from "../constants/ActionConstants"
import locales from "../translations/locales"

export const appSettingsActions = {
  // getActiveUserEntity,
  setActiveClassLayout,
  setBurgerMenu,
  setActiveUserId,
  setUserEntities
}

// action types
export function setGlobalClassName(entityType) {
  return { type: appSettingAction.SET_ACTIVE_CLASS, entityType }
}

export function setActiveUser(entity) {
  return { type: appSettingAction.SET_ACTIVE_USER, entity }
}

// action functions
export function setActiveClassLayout(entityType) {
  return async (dispatch) => {
    dispatch(setGlobalClassName(entityType))
  }
}

export function setBurgerMenu(isClient = false) {
  return async (dispatch) => {
    dispatch({ type: appSettingAction.SET_BURGER_MENU, isClient })
  }
}

export function setActiveUserId(id = null) {
  return async (dispatch) => {
    dispatch({ type: appSettingAction.SET_ACTIVE_USER_ID, id })
  }
}

export function setUserEntities(entities = []) {
  return async (dispatch) => {
    dispatch({ type: appSettingAction.SET_USER_ENTITIES, entities })
  }
}

export function setCurrentLanguage() {
  intl.init({
    currentLocale: localStorage.getItem("currentLanguage") || "en-US",
    locales
  })
  return async (dispatch) => {
    dispatch({ type: appSettingAction.SET_CURRENT_LANGUAGE })
  }
}
