import React from "react"
import { getSymbolFromCode, numberWithCommas } from "../../helpers"

const TenYearsProjectionTable = ({ currency, data }) => {
  const tableInfoCols = ["Total Assets", "Total Liabilities", "Total Net"]
  const modifiedDataArr =
    data &&
    data.gapProjectionResult &&
    data.gapProjectionResult.map((item) => {
      return {
        name: item.info.name,
        totalAssets: item.totalAssets,
        totalLiabilities: item.totalLiabilities,
        totalNetAssets: item.totalNetAssets
      }
    })
  return (
    <table
      className="table-blue table-cs-popup_edu my-3"
      width="100%"
      cellSpacing="0"
      cellPadding="0"
      border="0"
    >
      <thead>
        <tr>
          <th></th>
          <th style={{ textAlign: "left" }}>Current Stage</th>
          <th style={{ textAlign: "left" }}>Projected(in 10 years)</th>
          <th style={{ textAlign: "left" }}>Projected(in 20 years)</th>
          <th style={{ textAlign: "left" }}>Projected(in 30 years)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{tableInfoCols[0]}</td>
          <td>
            {getSymbolFromCode(currency)}
            {numberWithCommas(modifiedDataArr[0].totalAssets)}
          </td>
          <td>
            {getSymbolFromCode(currency)}
            {numberWithCommas(modifiedDataArr[1].totalAssets)}
          </td>
          <td>
            {getSymbolFromCode(currency)}
            {numberWithCommas(modifiedDataArr[2].totalAssets)}
          </td>
          <td>
            {getSymbolFromCode(currency)}
            {numberWithCommas(modifiedDataArr[3].totalAssets)}
          </td>
        </tr>
        <tr>
          <td>{tableInfoCols[1]}</td>
          <td>
            {getSymbolFromCode(currency)}
            {numberWithCommas(modifiedDataArr[0].totalLiabilities)}
          </td>
          <td>
            {getSymbolFromCode(currency)}
            {numberWithCommas(modifiedDataArr[1].totalLiabilities)}
          </td>
          <td>
            {getSymbolFromCode(currency)}
            {numberWithCommas(modifiedDataArr[2].totalLiabilities)}
          </td>
          <td>
            {getSymbolFromCode(currency)}
            {numberWithCommas(modifiedDataArr[3].totalLiabilities)}
          </td>
        </tr>
        <tr>
          <td>{tableInfoCols[2]}</td>
          <td>
            {getSymbolFromCode(currency)}
            {numberWithCommas(modifiedDataArr[0].totalNetAssets)}
          </td>
          <td>
            {getSymbolFromCode(currency)}
            {numberWithCommas(modifiedDataArr[1].totalNetAssets)}
          </td>
          <td>
            {getSymbolFromCode(currency)}
            {numberWithCommas(modifiedDataArr[2].totalNetAssets)}
          </td>
          <td>
            {getSymbolFromCode(currency)}
            {numberWithCommas(modifiedDataArr[3].totalNetAssets)}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default TenYearsProjectionTable
