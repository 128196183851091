import { emailAction } from '../../constants/ActionConstants';

export function emailReducer(state = {}, action) {
    switch (action.type) {
        case emailAction.GET_RECIPIENTS:
            return {
                ...state,
                recipients: action.response,
            };
        default:
            return state;
    }
}
