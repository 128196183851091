import React, { useState, useEffect } from "react"
import intl from "react-intl-universal"
import NotificationApi from "../api/NotificationApi"
import TransferApi from "../api/TransferApi"
import Alert from "react-s-alert"
import Tooltip from "rc-tooltip"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from "react-accessible-accordion"

import {
  convertToLocalTime,
  convertToLocalTimeDefault
} from "../helpers/DateHelper"
import { FaCheck, FaTimesCircle } from "react-icons/fa"
import { history } from "../helpers"
import { EntityTypeEnum } from "../constants/Enums"
function NotificationsDialog({
  notification,
  setBlinkNotification,
  clearModal
}) {
  const [userNotificationList, setUserNotificationList] = useState(
    notification ? notification.userNotificationList : []
  )
  const [clientNotificationList, setClientNotificationList] = useState(
    notification ? notification.clientNotificationList : []
  )
  const [transferNotificationList, setTransferNotificationList] = useState(
    notification ? notification.transferNotificationList : []
  )
  const [displayItem, setDisplayItem] = useState()
  const loginEntityType = localStorage.getItem("loginEntityType")
  const refreshList = () => {
    NotificationApi.getNotificationList().then(({ data }) => {
      if (data.userNotificationList) {
        setUserNotificationList(data.userNotificationList)
        let waitList =
          data.userNotificationList &&
          data.userNotificationList.filter((x) => x.acknowledged == false)
        if (waitList.length == 0) {
          setBlinkNotification(false)
        }
      }
      if (data.clientNotificationList) {
        setClientNotificationList(data.clientNotificationList)
        let waitList =
          data.clientNotificationList &&
          data.clientNotificationList.filter((x) => x.status == "WAITING")
        if (waitList.length == 0) {
          setBlinkNotification(false)
        }
      }

      if (data.transferNotificationList) {
        setTransferNotificationList(data.transferNotificationList)
        let waitList =
          data.transferNotificationList &&
          data.transferNotificationList.filter((x) => x.status == "WAITING")
        if (waitList.length == 0) {
          setBlinkNotification(false)
        }
      }
    })
  }

  useEffect(() => {
    refreshList()
  }, [])

  const tableGeneralNotifications = (moduleName) => {
    return (
      <table className="table-blue">
            <tbody>
              <tr>
                <th style={{width:"12%", textAlign:"left"}}>Created On</th>
                <th style={{width:"70%", textAlign:"left"}}>Message</th>
                <th style={{width:"18%", textAlign:"center"}}></th>
              </tr>
              {getUserNotificationRows(moduleName)}
            </tbody>
          </table>
      
    )
  }

  const tableUserNotifications = (title, moduleName) => {
    return (
      <>
        <div className="portal-form-row">
            <div className="col-md-8">
              <p style={{ fontWeight: "semibold", fontSize: "14px" }}>
                Click on the content of the relevant message box to view how your
                detail {moduleName.toLowerCase()} is being handled.
              </p>
            </div>
            <div className="col-md-4 text-right">
              <button className="portal-btn portal-btn-submit fa fa-trash" disabled={userNotificationList && userNotificationList.length==0} onClick={(e)=>{
                  NotificationApi.deleteAllMyUserNotifications().then(({data})=>{
                    refreshList();
                    Alert.success("My User Notifcations has been cleared.");
                  })
              }}>
                &nbsp;{`Clear all ${title}`}
              </button>
            </div>
        </div>
        <div>
         
          
          <table className="table-blue">
            <tbody>
              <tr>
                <th style={{width:"12%", textAlign:"left"}}>Created On</th>
                <th style={{width:"70%", textAlign:"left"}}>Message</th>
                <th style={{width:"18%", textAlign:"center"}}></th>
              </tr>
              {getUserNotificationRows(moduleName)}
            </tbody>
          </table>
        </div>
      </>
      
    )
  }

  const getUserNotificationRows = (moduleName) => {
    if (userNotificationList) {
      return userNotificationList.filter(n=>n.moduleName == moduleName).map((item, index) => (
        //add asset
        <tr key={index}>
          <td  style={{width:"12%"}}>
            {item.dateTimeCreated
              ? convertToLocalTimeDefault(item.dateTimeCreated)
              : ""}
          </td>
          <td style={{width:"70%"}}>
            {item.moduleName == "FEEDBACK" && (
              <a
                onClick={(e) => {
                  onUserNotificationAcknowledged(e, item);
                  history.push(
                    `/managefeedbacks?code=${item.message.substring(
                      item.message.indexOf("[") + 1,
                      item.message.indexOf("]")
                    )}`
                  )
                  clearModal()
                }}
              >
                {item.message}
              </a>
            )}
             {item.moduleName == "TASK" && (
              <a
                onClick={(e) => {
                  onUserNotificationAcknowledged(e, item);
                  history.push(
                    `/taskmanagement?code=${item.sourceId}`
                  )
                  
                  clearModal()
                }}
              >
                {item.message}
              </a>
            )}
            {item.moduleName == "GENERAL" && (
              <span>{item.message}</span>
            )}
          </td>
          <td style={{width:"18%", textAlign:"center"}}>
            {item.acknowledged == false ? (
              <button
                className="portal-btn portal-btn-submit fa fa-book"
                onClick={(e) => onUserNotificationAcknowledged(e, item)}
              >
                {" "} Mark as Read
              </button>
            ) : (
              <p
                className="p-0 m-0 fa fa-check"
                style={{ fontWeight: "bold", color: "green" }}
              >
               
              </p>
            )}
          </td>
        </tr>
      ))
    }

    return (
      <tr>
        <td colSpan="13">{intl.get("productbuilders.noData")}</td>
      </tr>
    )
  }

  function onUserNotificationAcknowledged(e, item) {
    NotificationApi.acknowledgeUserNotification(item.id).then(({ data }) => {
      Alert.success("Notification marked as read")
      refreshList()
    })
  }

  const tableClientNotifications = () => {
    return (
      <table className="table-blue">
        <tbody>
          <tr>
            <th style={{width:"15%"}}>Source</th>
            <th style={{width:"15%"}}>Client</th>
            <th style={{width:"12%"}}>Created On</th>
            <th style={{width:"10%"}}>Type</th>
            <th style={{width:"10%"}}>Status</th>
            <th style={{width:"12%"}}>Approved On</th>
            <th style={{width:"20%"}}>Action</th>
          </tr>
          {getClientNotificationRows()}
        </tbody>
      </table>
    )
  }

  const tableTransferNotifications = () => {
    return (
      <table className="table-blue ">
        <tbody>
          <tr>
            <th width="10%">Created On</th>
            <th width="10%">Transfer Type</th>
            <th width="20%">Item(s)</th>
            <th width="15%">From</th>
            <th width="15%">To</th>
            <th width="15%">Initiator</th>
            <th width="10%">Status</th>
            <th width="5%">Action</th>
          </tr>
          {getTransferNotificationRows()}
        </tbody>
      </table>
    )
  }

  const tableClientNotificationsForClient = () => {
    return (
      <table className="table-blue">
        <tbody>
          <tr>
            <th width="20%">Created On</th>
            <th width="20%">Type</th>
            <th width="20%">Status</th>
            <th width="20%">Approved On</th>
          </tr>
          {getClientNotificationRowsForClient()}
        </tbody>
      </table>
    )
  }

  function getChanges(changes) {
    return changes.map((chg, index) => <li key={"chg-" + index}>{chg}</li>)
  }

 

  function getClientChangeTooltip(item){
    let cRows = item.clientChanges? getChanges(item.clientChanges):[];
    let pRows = item.partnerChanges? getChanges(item.partnerChanges):[];
    return (
      <Tooltip
        zIndex={9999}
        placement="right"
        overlay={
          <div className="cover">
            <table className="table-blue">
              <tbody>
                 <tr key={"head"}>
                    <th style={{width:pRows.length>0?"50%":"100%"}}>Client's changes</th>
                    {pRows.length > 0 && (
                        <th style={{width:"50%"}}>Partner's changes</th> 
                    )}
                  
                 </tr>
                 <tr>
                    <td style={{width:pRows.length>0?"50%":"100%", color:"black"}}>
                      <ul >
                        {cRows}
                      </ul>
                    </td>
                    {pRows.length>0 && (
                       <td  style={{width:"50%", color:"black"}}>
                        <ul>
                          {pRows}
                        </ul>
                        
                       </td>
                    )}
                   
                 </tr>
              </tbody>
            </table>
          
            
          </div>
        }
        arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
      >
        <span>
          {item.typeAlias} {" "}
          <span className="fa fa-question-circle"></span>
        </span>
       
        
        
      </Tooltip>
    )
  }

  const getClientNotificationRows = () => {
    let loginEntityType = localStorage.getItem("loginEntityType");
    if (clientNotificationList) {
      return clientNotificationList.map((item, index) => (
        <tr key={index}>
          <td style={{width:"15%"}}>{item.sourceName}</td>
          <td style={{width:"15%"}}>{item.clientName}</td>
          <td  style={{width:"12%"}}>
            {item.createdOn
              ? convertToLocalTime(item.createdOn, "DD-MM-YYYY HH:mm:ss")
              : ""}
          </td>
          <td style={{width:"15%", textAlign:"center"}}>
            {getClientChangeTooltip(item)}
           
          </td>
          <td style={{width:"10%"}}>{item.status}</td>
          <td style={{width:"15%"}}>
            {item.approvedOn
              ? convertToLocalTime(item.approvedOn, "DD-MM-YYYY HH:mm:ss")
              : ""}
          </td>

          <td  style={{width:"20%", textAlign:"center"}}>
            {loginEntityType == EntityTypeEnum.EntityTypeEnum_BUILT_IN_DEALERSHIP && item.status == "WAITING" && (
              <Tooltip
                zIndex={9999}
                placement="right"
                overlay={
                  <div className="cover">
                    <div
                      className="menu-items"
                      onClick={(e) =>
                        onApproveClientNotificationClicked(e, item)
                      }
                    >
                      <FaCheck style={{ fontSize: "14px" }} />
                      <span>Approve</span>
                    </div>

                    <div
                      className="menu-items"
                      onClick={(e) =>
                        onRejectClientNotificationClicked(e, item)
                      }
                    >
                      <FaTimesCircle style={{ fontSize: "14px" }} />
                      <span>Reject</span>
                    </div>
                  </div>
                }
                arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
              >
                <span className="btn btn-edit"></span>
              </Tooltip>
            )}
          </td>
        </tr>
      ))
    }

    return (
      <tr>
        <td colSpan="13">no data</td>
      </tr>
    )
  }

  const getClientNotificationRowsForClient = () => {
    if (clientNotificationList) {
      return clientNotificationList.map((item, index) => (
        <tr key={index}>
          <td className="td-top">
            {item.createdOn
              ? convertToLocalTime(item.createdOn, "DD-MM-YYYY HH:mm:ss")
              : ""}
          </td>
          <td className="td-top">
            {getClientChangeTooltip(item)}
          </td>
          <td className="td-top">{item.status}</td>
          <td className="td-top">
            {item.approvedOn
              ? convertToLocalTime(item.approvedOn, "DD-MM-YYYY HH:mm:ss")
              : ""}
          </td>
        </tr>
      ))
    }

    return (
      <tr>
        <td colSpan="13">no data</td>
      </tr>
    )
  }

  function doAction(status, item) {
    let approvalModel = {
      id: item.id,
      actionStatus: status
    }
    TransferApi.approval(approvalModel).then(({ data }) => {
      Alert.success(item.itemName + " has been " + item.status.toLowerCase())
      refreshList()
    })
  }

  const getTransferNotificationRows = () => {
    if (transferNotificationList) {
      return transferNotificationList.map((item, index) => (
        //add asset
        <tr key={index}>
          <td className="td-top">
            {convertToLocalTime(item.datetimeCreated, "DD/MM/YYYY HH:mm:ss")}
          </td>
          <td className="td-top">{item.transferType}</td>
          <td className="td-top">{item.itemName}</td>
          <td className="td-top">{item.sourceName}</td>
          <td className="td-top">{item.destName}</td>
          <td className="td-top">{item.initiatorName}</td>
          <td className="td-top">{item.status}</td>
          <td className="td-top" align="center">
            {item.status == "WAITING" && (
              <Tooltip
                placement="right"
                overlay={
                  <div className="cover">
                    <div
                      className="menu-items"
                      onClick={(e) => doAction("APPROVED", item)}
                    >
                      <FaCheck style={{ fontSize: "14px" }} />
                      <span>Approve</span>
                    </div>

                    <div
                      className="menu-items"
                      onClick={(e) => doAction("REJECTED", item)}
                    >
                      <FaTimesCircle style={{ fontSize: "14px" }} />
                      <span>Reject</span>
                    </div>
                  </div>
                }
                arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
              >
                <span className="btn btn-edit"></span>
              </Tooltip>
              //   <div>
              //     <button  className="portal-btn portal-btn-submit" onClick={(e)=>doAction('APPROVED', item)}>Approve</button> &nbsp;
              //     <button  className="portal-btn portal-btn-submit" onClick={(e)=>doAction('REJECTED', item)}>Reject</button>
              //   </div>
            )}
          </td>
        </tr>
      ))
    }

    return (
      <tr>
        <td colSpan="13">{intl.get("productbuilders.noData")}</td>
      </tr>
    )
  }

  function onViewChangesClicked(e, item) {
    if (!displayItem) {
      setDisplayItem(item)
    } else {
      setDisplayItem(undefined)
    }
  }

  function onApproveClientNotificationClicked(e, item) {
    let postModel = {
      clientId: item.clientId,
      clientUpdateRequestId: item.clientUpdateDetailId,
      action: "APPROVE"
    }
    NotificationApi.processApprovalRequest(postModel)
      .then(({ data }) => {
        Alert.success("Approval successfully sent")
        refreshList()
      })
      .catch(({ response }) => {
        Alert.error("error when approving data")
      })
  }

  function onRejectClientNotificationClicked(e, item) {
    let postModel = {
      clientId: item.clientId,
      clientUpdateRequestId: item.clientUpdateDetailId,
      action: "REJECT"
    }
    NotificationApi.processApprovalRequest(postModel)
      .then(({ data }) => {
        Alert.success("Rejection successfully sent")
        refreshList()
      })
      .catch(({ response }) => {
        Alert.error("error when approving data")
      })
  }

  function getMarkCount(moduleName){
    let count = userNotificationList && userNotificationList.filter(n=>n.moduleName == moduleName && n.acknowledged == false).length;
    if(count > 0){
      return (
        <sup className="text-redchili blink">{" "}{count}</sup>
      )
    }else{
      return <></>;
    }
   
   
  }

  function getApprovalMarkCount(list){
    let count = list && list.filter(n=>n.status == "WAITING").length;
    if(count > 0){
      return (
        <sup className="text-redchili blink">{" "}{count}</sup>
      )
    }else{
      return <></>;
    }
   
   
  }

  function getContent() {
    if(loginEntityType == EntityTypeEnum.EntityTypeEnum_CLIENT){
      return (
        <div className="notification-dialog">
          <Tabs justify={true}>
            <TabList>
              <Tab>Client Changes</Tab>
            </TabList>
            <TabPanel>
              {tableClientNotifications()}
            </TabPanel>
  
          </Tabs>
        </div>
      )
    }else{
      return (
        <div className="notification-dialog">
          <Tabs justify={true}>
            <TabList>
              <Tab>Notifications</Tab>
              <Tab>Approval Notifications</Tab>
            </TabList>
            <TabPanel>
                <div className="portal-form-row">
                  <p className="text-bold">All notifications below are coming from your team</p>
                </div>
                
                <Tabs>
                  <TabList>
                    <Tab>General {getMarkCount("GENERAL")}</Tab>
                    <Tab>Task {getMarkCount("TASK")}</Tab>
                    <Tab>Feedback {getMarkCount("FEEDBACK")}</Tab>
                  </TabList>
                  <TabPanel>
                    {tableGeneralNotifications("GENERAL")}
                  </TabPanel>
                  <TabPanel>
                   {tableUserNotifications("Task Notifications","TASK")}
                  </TabPanel>
                  <TabPanel>
                    {tableUserNotifications("Feedback Notifications", "FEEDBACK")}
                  </TabPanel>
                </Tabs>
            </TabPanel>
            <TabPanel>
                <div className="portal-form-row">
                  <p className="text-bold">All approval notifications below are coming from your team and probably requiring your action</p>
                </div>
                <Tabs>
                  <TabList>
                    <Tab>Client Changes {getApprovalMarkCount(clientNotificationList)}</Tab>
                    <Tab>Team Transfer {getApprovalMarkCount(transferNotificationList)}</Tab>
                  </TabList>
                  <TabPanel>{tableClientNotifications()}</TabPanel>
                  <TabPanel>{tableTransferNotifications()}</TabPanel>
                </Tabs>
            </TabPanel>
            
          </Tabs>
        </div>
      )
    }
    
  }

  return getContent()
}

export default NotificationsDialog
