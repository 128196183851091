import React from "react"
import intl from "react-intl-universal"
import { createUrlFromPath } from "../../../helpers/ImageHelper"
import { lightOrDark } from "../BurgerMenu/_helper/MenuHelper"
import { logout } from "../../../helpers/SessionHelper"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Logout } from "../../../components"
const ClientHeaderMenu = (props) => {
  const {
    onNotificationClick,
    onSettingClick,
    onQuestionClick,
    onUserProfileClick,
    title,
    photoUrl,
    titleBarColour,
    dashboardData
  } = props
  const { id } = useParams()
  const { clientId, clientFullNamePreferred, partnerFullNamePreferred } =
    useSelector((state) => state.entity)
  return (
    <div className="header-holder  d-flex">
      <div
        className="header-bar  d-flex justify-content-between align-items-center"
        style={{ background: titleBarColour }}
      >
        <div className="title w-75">
          {id && clientId && id === clientId
            ? `${clientFullNamePreferred} ${
                partnerFullNamePreferred ? `- ${partnerFullNamePreferred}` : ""
              }`
            : title}
        </div>
        <div className="user-control  d-flex justify-content-end align-items-center w-25">
          <div className={`icon-${lightOrDark(titleBarColour)}`}>
            <span
              className="ico-bell"
              onClick={onNotificationClick}
              alt={intl.get("headermenu.notification")}
              title={intl.get("headermenu.notification")}
            />
            <span
              className="ico-setting"
              onClick={onSettingClick}
              alt={intl.get("headermenu.settings")}
              title={intl.get("headermenu.settings")}
            />
            <span
              className="ico-question"
              onClick={onQuestionClick}
              alt={intl.get("headermenu.help")}
              title={intl.get("headermenu.help")}
            />

            <span style={{ marginLeft: 20 }} />
            <span
              className="user-avatar"
              onClick={onUserProfileClick}
              alt={intl.get("headermenu.profile")}
              title={intl.get("headermenu.profile")}
            >
              <div className="user-profile" onClick={onUserProfileClick}>
                <img
                  src={`${
                    dashboardData && dashboardData.photoPath
                      ? createUrlFromPath(dashboardData.photoPath)
                      : photoUrl
                  }`}
                  alt="User Profile"
                />
              </div>
            </span>
          </div>
          <div className={`icon-${lightOrDark(titleBarColour)}`}>
            <Logout
              component={
                <span
                  className="fa fa-sign-out fa-lg"
                  alt={intl.get("leftmenu.logout")}
                  title={intl.get("leftmenu.logout")}
                  onClick={(e) => logout()}
                  style={{ cursor: "pointer" }}
                ></span>
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientHeaderMenu
