/*
    Author : Marlina (marlina@devstack.com.au)
    Description : Add feedback modal
*/

import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Form, Field } from "react-final-form"
import intl from "react-intl-universal"
import { File, registerPlugin } from "react-filepond"
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size"
import ReactModal from "react-modal"
import { modalWindow } from "../../constants/ModalWindowLists"
import { FeedbackCategoryEnum } from "../../constants/Enums"
import { history } from "../../helpers"

import {
  modalWindowActions,
  fileOperationActions,
  enumOptionsActions,
  feedbackActions
} from "../../actions"
import {
  renderFeedbackSelectionMenuCustom,
  renderSelectEnumFieldCustom,
  renderTextAreaCustom,
  renderTextBoxCustom,
  required
} from "../../helpers"

import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"
import { FileOperationApi } from "../../api"
import CustomFilePond from "../../components/FileUploader/CustomFilePond"

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize
)
const maxFileSize = process.env.REACT_APP_MAX_FILE_SIZE
const baseUrl = localStorage.getItem("base-route") // base route for multiple region
const feedbackListUrl = "/managefeedbacks"
class Feedback extends Component {
  state = {
    files: [],
    model: {
      senderName: this.props.activeUser.id,
      dateSent: new Date()
    },
    feedbackCategory: "",
    isReadOnly: false
  }
  gotoFeedbackList() {
    const { closeModalWindow } = this.props
    history.push(feedbackListUrl)
    closeModalWindow(modalWindow.FEEDBACK_MODAL)
  }

  componentDidMount() {
    const { getAllEnumOptions } = this.props

    getAllEnumOptions()
  }

  componentWillReceiveProps(nextProps) {
    const { object } = this.props
    if (nextProps.object !== undefined && nextProps.object !== null) {
      if (nextProps.object !== object) {
        this.setState((prevState) => ({
          ...prevState,
          model: {
            ...nextProps.object,
            dateSent: new Date(nextProps.object.dateSent),
            dateLastUpdated: new Date(),
            actionBy: nextProps.activeUser.id,
            lastModifiedBy: nextProps.activeUser.id
          },
          isReadOnly: true,
          feedbackCategory: intl.get(
            `FeedbackCategoryEnum_${nextProps.object.category}`
          )
        }))
      }
    } else {
      this.setInitialState()
    }
  }

  setInitialState = () => {
    this.setState((prevState) => ({
      ...prevState,
      model: {
        feedbackCategory: "",
        priority: ""
      },
      files: [],
      isReadOnly: false
    }))
  }

  handleSave = async (values) => {
    const { addFeedback, closeModalWindow } = this.props
    const { files } = this.state

    // Add entityType
    values.entityType = this.props.selectedEntity.entityType

    if (files.length > 0) {
      const filesUploaded = []
      const maxFileSize = 1024 * 1024 * 10 // Replace with actual file size limit

      if (files.every((file) => file.size < maxFileSize)) {
        await Promise.all(
          files.map(async (file) => {
            const formData = new FormData()
            formData.append("sourceType", "ADVISER")
            formData.append("sourceId", this.props.activeUser.id)
            formData.append("title", "Feedback")
            formData.append("module", "FEEDBACK")
            formData.append("moduleId", values.description)
            formData.append("file", file)

            const response = await FileOperationApi.upload(formData)
            // let fileDetails = response.data.files
            filesUploaded.push({ ...response.data.file, id: response.data.id })
          })
        )

        if (filesUploaded.length === files.length) {
          await addFeedback({ ...values, files: filesUploaded })
        }
      } else {
        console.error("One or more files exceed the maximum size limit.")
        await addFeedback(values)
      }
    } else {
      await addFeedback(values)
    }

    this.setInitialState()
    closeModalWindow(modalWindow.FEEDBACK_MODAL)
  }

  getDate = (date) =>
    date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric"
    })

  getImagePath = (path) => `${baseUrl}${path}`

  render() {
    const { isShowForm, closeModalWindow, optionsData } = this.props
    const { handleSave, getDate, getImagePath, setInitialState } = this
    const { model, isReadOnly, files, feedbackCategory } = this.state
    const activeRole = JSON.parse(localStorage.getItem("activeUserRole"))

    return (
      <ReactModal
        isOpen={isShowForm}
        className="malabar-modal-large feedback-modal"
        overlayClassName="malabar-modal-overlay malabar-modal-overlay-transparent"
        shouldCloseOnEsc
        onRequestClose={() => closeModalWindow(modalWindow.FEEDBACK_MODAL)}
      >
        <Form
          onSubmit={handleSave}
          initialValues={model}
          render={({ handleSubmit, reset }) => (
            <div className="popup">
              <div className="container-fluid">
                <form
                  className="container-feedback"
                  onSubmit={handleSubmit}
                  encType="multipart/form-data"
                >
                  <div
                    className="page-title"
                    style={{ position: "sticky", top: 0 }}
                  >
                    {intl.get("feedback.title")}
                    <div className="close-holder">
                      <span
                        className="close-button-s"
                        onClick={() =>
                          closeModalWindow(modalWindow.FEEDBACK_MODAL)
                        }
                      />
                    </div>
                  </div>

                  <div className="portal-form-feedback ">
                    <div className="row">
                      <div className="col-md-8 text-left">
                        {intl.get("feedback.selectFeedbackCategory")}
                      </div>

                      <div className="col-md-4 text-right">
                        {activeRole.businessManagementAllowedPaths &&
                          activeRole.businessManagementAllowedPaths.indexOf(
                            feedbackListUrl
                          ) > -1 && (
                            <span
                              className="portal-btn portal-btn-submit fa fa-list"
                              onClick={(e) => this.gotoFeedbackList()}
                            >
                              {" "}
                              monitor list
                            </span>
                          )}
                      </div>
                    </div>

                    <Field
                      name="category"
                      component={renderFeedbackSelectionMenuCustom}
                      onChange={(val) => {
                        this.setState((prevState) => ({
                          ...prevState,
                          feedbackCategory: intl.get(
                            `FeedbackCategoryEnum_${val}`
                          )
                        }))
                      }}
                      validate={required}
                      disabled={isReadOnly}
                    />

                    <div className="feedback-content">
                      <div className="portal-form-row feedback-form-row">
                        {" "}
                        <label>{intl.get("feedback.field.category")}</label>
                        <div className="row-holder">
                          <div className="feedback-issue-text">
                            {feedbackCategory}
                          </div>
                          {feedbackCategory ===
                            intl.get(
                              `FeedbackCategoryEnum_${FeedbackCategoryEnum.TECHNICAL_ISSUE}`
                            ) && (
                            <Field
                              name="subCategory"
                              type="text"
                              component={renderSelectEnumFieldCustom}
                              enumData={
                                optionsData.feedbackTechnicalIssueOptions
                              }
                              disabled={isReadOnly}
                              validate={
                                feedbackCategory ===
                                  intl.get(
                                    `FeedbackCategoryEnum_${FeedbackCategoryEnum.TECHNICAL_ISSUE}`
                                  ) && required
                              }
                            />
                          )}
                        </div>
                      </div>

                      <div className="portal-form-row feedback-form-row">
                        <label>{intl.get("feedback.field.subject")}</label>
                        <div className="row-holder">
                          <Field
                            name="title"
                            component={renderTextBoxCustom}
                            validate={required}
                          />
                        </div>
                      </div>
                      <div className="portal-form-row feedback-form-row">
                        {" "}
                        <label>{intl.get("feedback.field.priority")}</label>
                        <div className="row-holder">
                          <Field
                            name="priority"
                            component={renderSelectEnumFieldCustom}
                            enumData={
                              optionsData.feedbackPrioritySeverityOptions
                            }
                            validate={required}
                          />
                        </div>
                      </div>
                      <Field
                        name="description"
                        component={renderTextAreaCustom}
                        label={intl.get("feedback.field.description")}
                        isLabel
                        disabled={isReadOnly}
                        validate={required}
                      />
                    </div>
                    {isReadOnly ? (
                      model.file != null && (
                        <div className="feedback-download">
                          <i className="fa fa-link" />
                          <a href={getImagePath(model.file.path)}>
                            {model.file.filename}
                          </a>
                        </div>
                      )
                    ) : (
                      <CustomFilePond
                        ref={(ref) => (this.pond = ref)}
                        allowMultiple={true}
                        maxFiles={5}
                        labelIdle="Drag, Drop or Browse"
                        allowReplace
                        maxFileSize={maxFileSize}
                        onupdatefiles={(fileItems) => {
                          this.setState((prevState) => ({
                            ...prevState,
                            files: fileItems.map((fileItem) => fileItem.file)
                          }))
                          // Set current file objects to this.state
                        }}
                      >
                        {files.map((file) => (
                          <File key={file} src={file} origin="local" />
                        ))}
                      </CustomFilePond>
                    )}
                  </div>

                  {!isReadOnly && (
                    <div className="portal-btn-popup">
                      <div className="col-half">
                        <input
                          className="portal-btn portal-btn-s portal-btn-cancel"
                          value={intl.get("button.cancel")}
                          type="reset"
                          onClick={() => {
                            setInitialState()
                            closeModalWindow(modalWindow.FEEDBACK_MODAL)
                          }}
                        />
                      </div>
                      <div className="col-half">
                        <input
                          className="portal-btn portal-btn-s portal-btn-submit"
                          value={intl.get("button.send")}
                          type="submit"
                        />
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          )}
        />
      </ReactModal>
    )
  }
}
function mapStateToProps(state) {
  return {
    isShowForm: state.modalWindow[modalWindow.FEEDBACK_MODAL],
    object: state.modalWindow.object,
    optionsData: state.enumOptions.optionsData,
    activeAccessLevel: state.appSettings,
    activeUser: state.authentication.user,
    selectedEntity: state.authentication.user.selectedEntity
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign(
      {},
      modalWindowActions,
      fileOperationActions,
      enumOptionsActions,
      feedbackActions
    ),
    dispatch
  )
}

Feedback = connect(mapStateToProps, mapDispatchToProps)(Feedback)
export default Feedback
