/*
    Author : Erik (erik.malik@devstack.com.au)
    Description : Adviser clients dashboard page
*/

import React, { Component } from "react";
import intl from "react-intl-universal";
import {
    BoxUser,
    AlphabetSearch,
    GroupedOverviewBoxes,
    GroupedBoxUser
} from "../../../components";
import { MalabarClientRightMenu } from "../../../components/MalabarMenu";

class AdviserClientsDashboard extends Component {
    // TODO: get it from back end through store code:2938409800
    getOverviews = () => [
        {
            title: "Monthly New Clients",
            figure: "21",
            timing: "5% From Last week",
            color: "green",
            link: "/dashboard/adviser/client"
        },
        {
            title: "Client Alerts",
            figure: "9",
            color: "red",
            link: "/dashboard/adviser/client/alert"
        },
        {
            title: "Client Reviews",
            figure: "6",
            timing: "Next 30 Days",
            link: "/dashboard/adviser/client/review"
        },
        {
            title: "Prospects",
            figure: "9",
            timing: "To Follow Up",
            link: "/dashboard/adviser/client/prospect"
        },
        {
            title: "Work In Progress",
            figure: "5",
            timing: "To be Completed",
            link: "/dashboard/adviser/client/inProgress"
        },
        {
            title: "Implementation",
            figure: "3",
            timing: "Business In Progress",
            link: "/dashboard/adviser/client/implementation"
        }
    ];

    getTitleByType = type => {
        const map = new Map();
        map.set("alert", "Client Alerts");
        map.set("review", "Client Reviews");
        map.set("prospect", "Prospects");
        map.set("inProgress", "Work In Progress");
        map.set("implementation", "Implementation");
        return type ? map.get(type) : "Monthly New Clients";
    };

    randomNumber = () => Math.floor(Math.random() * Math.floor(1000000));

    // TODO: get it from back end through store
    generateUsers = count => {
        const { randomNumber } = this;
        const users = [];
        Array.from(Array(count).keys()).forEach(i =>
            users.push({
                fullName: `Dummy User ${i}${randomNumber()}`,
                lastReviewed: "25/05/2018",
                aum: randomNumber(),
                ium: randomNumber(),
                goals: randomNumber(),
                image: "/image/user-profile.jpg",
                status: "Client"
            })
        );
        return users;
    };

    generateUserGroups = () => {
        const { generateUsers } = this;
        return [
            {
                title: "Market Condition Reviews",
                users: generateUsers(6)
            },
            {
                title: "Emergency Meeting Needed ",
                users: generateUsers(3)
            }
        ];
    };

    generateData = type => {
        const { generateUsers, generateUserGroups } = this;
        if (type !== "alert") {
            const types = {
                review: 6,
                prospect: 9,
                inProgress: 5,
                implementation: 3
            };
            return generateUsers(type ? types[type] : 21);
        }
        return generateUserGroups();
    };

    render() {
        const { generateData, getOverviews, getTitleByType } = this;
        const { match } = this.props;
        const { type } = match.params;
        const data = generateData(type);
        const overviews = getOverviews();
        const overviewTitle = getTitleByType(type);

        return (
            <React.Fragment>
                <div className="dashboard-adviser">
                    <div className="portal-maincontent">
                        <div className="dashboard-content-panel">
                            <div className="overview-panel overview-panel-client">
                                <GroupedOverviewBoxes
                                    overviews={overviews}
                                    selected={overviewTitle}
                                    noPanel="true"
                                />

                                <button
                                    type="button"
                                    className="item-half ico-calendar"
                                />

                                <button
                                    type="button"
                                    className="item-half ico-relationship"
                                />
                            </div>

                            <div className="alphabet-box">
                                <AlphabetSearch
                                    onSelect={letter =>
                                        console.log(
                                            `Letter ${letter} is selected!`
                                        )
                                    }
                                />

                                <div className="alphabet-R">
                                    <input type="search" placeholder="Search" />
                                    <div className="alphabet-category">
                                        <p> {intl.get("dashboard.category")}</p>
                                        <div className="select-box">
                                            <select name="" required="">
                                                <option />
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {type !== "alert" && (
                                <div
                                    className="flexbox"
                                    id="flexbox"
                                    style={{
                                        position: "relative",
                                        gap: "30px"

                                        // marginBottom: "100px"
                                    }}
                                >
                                    {data.map((user, index) => (
                                        <BoxUser
                                            user={user}
                                            key={index}
                                            onCameraClicked={
                                                () => alert("camera")
                                                // console.log('camera cliked!');
                                            }
                                        />
                                    ))}
                                </div>
                            )}

                            {type === "alert" && (
                                <GroupedBoxUser
                                    userGroups={data}
                                    onAddClicked={title =>
                                        console.log(
                                            `Add ${title} button is clicked!`
                                        )
                                    }
                                />
                            )}
                        </div>

                        <MalabarClientRightMenu />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AdviserClientsDashboard;
