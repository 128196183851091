/*
    Author : Hendri Firmana (hendri.firmana@gmail.com)
             Anova Fawzi (anovafawzi@gmail.com) - modified to stateless component
    Description : Content for no access modal
*/

import React from 'react';
import intl from 'react-intl-universal';

const ModalNoAccess = () => (
    <div className="no-access-text-modal">
        <h1>{intl.get('modal.noAccess')}</h1>
    </div>
);

export default ModalNoAccess;
