import React, { useState } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Form, Field } from "react-final-form"
import intl from "react-intl-universal"
import ReactModal from "react-modal"
import Alert from "react-s-alert"
import { modalWindow } from "../../constants/ModalWindowLists"
import { modalWindowActions } from "../../actions"
import { renderTextBoxCustom } from "../../helpers"
import { required, composeValidators, email } from "../../helpers/Validator"
import Api from "../../lib/api"

function ResetPassword({ isOpen, closeModalWindow }) {
  const [error, setError] = useState("")
  const handleSave = (values) => {
    Api.post(`/auth/reset-password?emailAddress=${values.emailAddress}`, {})
      .then(() => {
        Alert.success(
          "The new password has been sent to your email. Please check your email."
        )
        closeModalWindow(modalWindow.RESET_PASSWORD_MODAL)
      })
      .catch((err) => {
        setError(err.response.data.errorMessage)
      })
  }
  return (
    <ReactModal
      isOpen={isOpen}
      className="malabar-modal-large user-modal"
      overlayClassName="malabar-modal-overlay"
      shouldCloseOnEsc
      onRequestClose={() => closeModalWindow(modalWindow.RESET_PASSWORD_MODAL)}
    >
      <div className="popup">
        <div className="container-fluid">
          <div className="container">
            <div className="page-title-green">
              Reset Password
              <div className="close-holder">
                <span
                  onClick={() =>
                    closeModalWindow(modalWindow.RESET_PASSWORD_MODAL)
                  }
                  className="close-button"
                />
              </div>
            </div>
            <Form
              onSubmit={handleSave}
              render={({ handleSubmit }) => (
                <form id="bondForm" onSubmit={handleSubmit}>
                  <div className="portal-form-popup">
                    <div className="row m-2">
                      <div className="col">
                        <label>
                          {intl.get("field.userEmailAddress")}{" "}
                          <span className="required-asterix">*</span>{" "}
                        </label>
                        <Field
                          name="emailAddress"
                          component={renderTextBoxCustom}
                          type="text"
                          validate={composeValidators(required, email)}
                        />
                        {error && (
                          <p className="text-bold ico-alertquestion">{error}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="portal-btn-popup">
                    <div className="text-right">
                      <input
                        className="portal-btn portal-btn-submit portal-btn-submit-mr-10"
                        value={intl.get("login.continue")}
                        type="submit"
                      />
                    </div>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </div>
    </ReactModal>
  )
}

function mapStateToProps(state) {
  return {
    isOpen: state.modalWindow[modalWindow.RESET_PASSWORD_MODAL]
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, modalWindowActions), dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
