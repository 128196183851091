/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Description : collectible/antiques/arts page (modal)
*/

import React from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { Form, FormSpy } from "react-final-form"
import intl from "react-intl-universal"
import { FormButtons, ModalNoAccess } from "../components"
// sub-forms
import LoanDetails from "./sub-forms/LoanDetails"
import InsuranceDetails from "./sub-forms/InsuranceDetails"
import CollectibleAntiqueArtsMainForm from "./CollectibleAntiqueArtsMainForm"

const CollectibleAntiqueArtsForm = ({
  handleSave,
  model,
  optionsData,
  readOnlyAccess,
  clientInformation: { client, partner, isJoint },
  currency = "USD"
}) => {
  return !readOnlyAccess ? (
    <Form
      onSubmit={handleSave}
      initialValues={model}
      mutators={{
        setFormAttribute: ([fieldName, fieldVal], state, { changeValue }) => {
          changeValue(state, fieldName, () => fieldVal)
        }
      }}
      render={({ handleSubmit, reset, form: { mutators } }) => (
        <form id="collectiblesForm" onSubmit={handleSubmit}>
          <div className="portal-form-popup">
            <FormSpy subscription={{ values: true }}>
              {({ values }) => (
                <React.Fragment>
                  <div
                    className={
                      values.insured || values.underLoan
                        ? "portal-form-half"
                        : ""
                    }
                  >
                    <CollectibleAntiqueArtsMainForm
                      client={client}
                      partner={partner}
                      isJoint={isJoint}
                      model={model}
                      mutators={mutators}
                    />
                  </div>

                  {(values.insured || values.underLoan) && (
                    <div className="portal-form-half">
                      <Tabs>
                        <TabList>
                          {values.underLoan && (
                            <Tab>{intl.get("tabs.loanDetails")}</Tab>
                          )}
                          {values.insured && (
                            <Tab>{intl.get("tabs.insuranceDetails")}</Tab>
                          )}
                        </TabList>

                        {values.underLoan && (
                          <TabPanel>
                            <LoanDetails
                              optionsData={optionsData}
                              currency={currency}
                              model={values}
                              mutators={mutators}
                            />
                          </TabPanel>
                        )}
                        {values.insured && (
                          <TabPanel>
                            <InsuranceDetails
                              optionsData={optionsData}
                              defaultCurrency={currency}
                            />
                          </TabPanel>
                        )}
                      </Tabs>
                    </div>
                  )}
                </React.Fragment>
              )}
            </FormSpy>
          </div>

          <FormButtons reset={reset} readOnlyAccess={readOnlyAccess} />
        </form>
      )}
    />
  ) : (
    <ModalNoAccess />
  )
}

export default CollectibleAntiqueArtsForm
