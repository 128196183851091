/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Library : AuthApi
    Description : methods related with authentication part
*/

import api from "../lib/api";

// change base route for different entity/domain here
const BASE_ROUTE = "/auth/";

class AuthApi {
    // login logout, change when api are ready
    static login(loginModel) {
        return api.post(`${BASE_ROUTE}user-authentication`, loginModel);
    }
    static termsandconditions(id) {
        return api.post(`${BASE_ROUTE}accept-terms-conditions?id=${id}`);
    }

    static selectEntity(loginModel) {
        return api.post(`${BASE_ROUTE}select-entity`, loginModel);
    }

    static logout() {
        return true;
    }

    static login2FAStep(tfaModel) {
        return api.post(`${BASE_ROUTE}tfa-authentication`, tfaModel);
    }

    static getAppVersion(ver) {
        if(ver){
            return  api.get(`/auth/app-version?ver=${ver}`)
        }else{
            return  api.get(`/auth/app-version`)
        }
       
    }

}

export default AuthApi;
