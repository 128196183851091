import React, { useContext } from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from "react-accessible-accordion"
import intl from "react-intl-universal"
import PersonalDetailsContext from "../PersonalDetailsContext"
import PairField from "./PairField"
import {
  renderSelectEnumFieldCustom,
  renderTextAreaCustom,
  renderTextBoxCustom
} from "../../../helpers"
import { optionsType, getOptions } from "../../../helpers/OptionsHelper"

function ClientCategory({ name = "", client, partner, clientType }) {
  const { classification } = useContext(PersonalDetailsContext)
  const optionsData = getOptions([
    optionsType.clientCategoryExceptArchiveTypeOptions
  ])

  const clientInvestorOptionsData = getOptions([
    optionsType.clientInvestorOptions
  ])

  const form = [
    {
      label: intl.get("field.clientCategory"),
      name: `status`,
      component: renderSelectEnumFieldCustom,
      enumData: optionsData.clientCategoryExceptArchiveTypeOptions,
      isRequired: true
      //   disabled: true
    },

    {
      label: intl.get("field.clientInvestorClassification"),
      name: `investorClassification`,
      component: renderSelectEnumFieldCustom,
      enumData: clientInvestorOptionsData.clientInvestorOptions,
      isRequired:
        clientType == "CLIENT" || clientType == "PROSPECT" ? true : false
    },
    {
      label: intl.get("field.uniqueId"),
      name: `uniqueId`,
      isRequired: false
    }
  ]

  return (
    <Accordion>
      <AccordionItem expanded>
        <div className="portal-part clientCategory">
          <AccordionItemTitle>
            <div className="subtitle2">
              {intl.get("personaldetail.clientCategory")}
              <span className="accordion__arrow" role="presentation" />
            </div>
          </AccordionItemTitle>
          <AccordionItemBody>
            {form.map((field) => (
              <PairField
                key={field.name}
                field={field}
                classification={classification}
              />
            ))}
          </AccordionItemBody>
        </div>
      </AccordionItem>
    </Accordion>
  )
}

export default ClientCategory
