import { jointSelectionAction } from '../constants/ActionConstants';

export function jointSelection(state = {}, action) {
    switch (action.type) {
        case jointSelectionAction.UPDATE_SELECTION_DATA:
            return {
                ...state,
                data: composeData(action.selectionData),
            };
        default:
            return { ...state };
    }
}

function composeData(data) {
    const result = { ...data };
    if (result.client) {
        result.client = simplifyClientData(result.client);
    }
    if (result.partner) {
        result.partner = simplifyClientData(result.partner);
    }
    return result;
}

function simplifyClientData(client) {
    return {
        name: client.personalDetails.personalInformation.firstName,
    };
}
