import React, { useContext, useEffect,  useState } from "react"

import { Form, Field, FormSpy } from "react-final-form"
import arrayMutators from "final-form-arrays"
import intl from "react-intl-universal"
import TaskManagementApi from "../../api/TaskManagementApi"
import Alert from "react-s-alert"
import { RenderSimpleFields } from "../../components"
import { FieldType } from "../../components/Form/RenderSimpleFields"
import NetWorthFormButtons from "../NetWorth/NetWorthFormButtons"
import { convertToLocalTimeDefault, uuidv4 } from "../../helpers/DateHelper"
import { FileOperationApi } from "../../api"
import CustomFilePond from "../../components/FileUploader/CustomFilePond"

function TableComments({values, mutators, setHideStatus, hideStatus}){
   
    function validateNewRow(){
        let result = true;
        let comments = values.comments;
        let rowNo = 1;
        let errorText = "";
        if(comments && comments.length>0){
            let size = comments.length;    
            for (let index = 0; index < size; index++) {
                const a = comments[index];
                let rowErrors = "";
                if(!a.description){
                    if(rowErrors.length==0){
                        rowErrors = "Description";
                    }else{
                        rowErrors = rowErrors + ", " + "Description";
                    }
                }
               
               
                if(rowErrors.length>0){
                    let msg = `Row-${rowNo}: ${rowErrors} required`;
                    if(errorText.length==0){
                        errorText = msg;
                    }else{
                        errorText = errorText + ", " + msg;
                    } 
                }
               
                rowNo++;
            }


        }
       
        if(errorText && errorText.length>0){
            Alert.error(`Comment ${errorText}`);
            result = false;
        }
        return result;
    }

    function getCommentRows(){
        let rows = [];
        if(values && values.comments){
            for (let index = 0; index < values.comments.length; index++) {
                let c = values.comments[index];
                if(c && c.editing){
                    rows.push(
                        <tr key={c.id}>
                          <td style={{width:"100%"}}>
                            <div className="portal-form-row subtitle-bar" >
                                <div className="col-md-4">
                                    <span>{c.fromEntityName}</span>
                                </div>
                                <div className="col-md-8 text-right">
                                    {c.dateTimeCreated?convertToLocalTimeDefault(c.dateTimeCreated):convertToLocalTimeDefault(c.dateTimeLastModified)}
                                </div>
                                
                            </div>
                            <div className="portal-form-row">
                                <div className="col-md-10">
                                    <textarea className="text-input text-area-input" rows="4" cols="150" value={c.description} onChange={(e)=>onCommentDescriptionChanged(e.target.value, c)} ></textarea>
                                </div>
                                <div className="col-md-1">
                                    <span
                                            className="portal-btn portal-btn-submit fa fa-ban"
                                            onClick={() => onCommentEditingCancelled(c)}
                                        />
                                        <span
                                            className="portal-btn portal-btn-submit fa fa-floppy-o  mt-2"
                                            onClick={() => onCommentSaved(c)}
                                        />
                                        <span
                                            className="portal-btn portal-btn-submit fa fa-trash mt-2"
                                            onClick={() => onCommentRemoved(c)}
                                        />
                                        
                                </div>
                            </div>
                            
                               
                          </td>
                        </tr>
                    )
                }else{
                    rows.push(
                        <tr key={c.id}>
                          <td style={{width:"100%"}}>
                            <div className="portal-form-row subtitle-bar">
                                <div className="col-md-4">
                                 <span>
                                    {c.fromEntityName}
                                 </span>   
                                </div>
                                <div className="col-md-8 text-right">
                                    {c.dateTimeCreated?convertToLocalTimeDefault(c.dateTimeCreated):convertToLocalTimeDefault(c.dateTimeLastModified)}
                                </div>
                            </div>
                            <div className="portal-form-row">
                                <div className="col-md-10">
                                   <p>{c.description}</p>
                                </div>
                                <div className="col-md-1">
                                    <span
                                        className="portal-btn portal-btn-submit fa fa-pencil-square-o"
                                        onClick={() => onCommentEdited(c)}
                                    />
                                    <span
                                        className="portal-btn portal-btn-submit fa fa-trash mt-2"
                                        onClick={() => onCommentRemoved(c)}
                                    />
                                </div>
                            </div>
                          </td>
                        </tr>
                    )
                }
               
            }
        }
        return rows;
    }

    function onAddCommentButtonClicked(e){
        if(validateNewRow()){
            let newComment = {
                id: uuidv4(),
                taskId: values.id,
                description: "",
                editing: true
            }
            mutators.pushToArray('comments', newComment);
        }
       
    }

    function updateComment(cmt){
        let newComments = [];
        if(values && values.comments){
            let foundIndex = -1;
            for (let index = 0; index < values.comments.length; index++) {
                let c = values.comments[index];
                if(c.id == cmt.id){
                    newComments.push(cmt)
                    foundIndex = index;
                }else{
                    newComments.push(c);
                }
            }

            if(foundIndex == -1){
                newComments.push(cmt);
            }

            mutators.setFormAttribute("comments", newComments);
        }

        
    }

    function onCommentDescriptionChanged(newDesc, c){
        updateComment({...c, description:newDesc})
    }

    function onCommentEdited(c){
        updateComment({...c, editing: true})
       
    }

    function onCommentEditingCancelled(c){
        updateComment({...c, editing: false})
    }

    function onCommentSaved(c){
        if(!values.id){
            mutators.setFormAttribute("id", uuidv4());

            TaskManagementApi.save(values).then(({data})=>{
                if(data){
                    TaskManagementApi.postComment(c).then(({data})=>{
                        if(data){
                            Alert.success("comment saved");
                            updateComment({...c, editing:false});

                        }
                    })
                }
                
                
            })
           
        }else{
            TaskManagementApi.postComment(c).then(({data})=>{
                if(data){
                    Alert.success("comment saved");
                    updateComment({...c, editing:false});
                }
            })
        }
       
       
    }

    function onCommentRemoved(comment){
        TaskManagementApi.deleteComment(comment.taskId, comment.id).then(({data})=>{
            if(data.data && data.data.comments){
                mutators.setFormAttribute("comments", data.data.comments);
            }
        });

        
    }

    let toggleCss = hideStatus?"fa fa-caret-right":"fa fa-caret-down";
    return (
        <>
            <div className="portal-form-row ml-2">
                <div className="col-md-3">
                    <div className="subtitle2b">
                        Comments  &nbsp; <span className={toggleCss} onClick={(e)=>setHideStatus(!hideStatus)}/>
                    </div>
                </div>
              
            </div>
            {!hideStatus && (
                <div className="portal-form-row ml-2">
                    <div className="col-md-12">
                        <span className="btn btn-plus" onClick={(e)=>onAddCommentButtonClicked(e)}/>
                        <table className="table-blue">
                            <tbody>
                                {getCommentRows()}
                            </tbody>
                        </table>
                    </div>
                
                </div>

            )}
            
            
        </>
    )
}
function getStateHistoryRows(item) {
    let result = []
    if (item.stateHistory) {
      item.stateHistory.forEach((element, index) => {
        result.push(
          <tr key={index}>
            <td width={"22%"}>
              {convertToLocalTimeDefault(element.modifiedOn)}
            </td>
            <td width={"8%"}>{element.previousStatus}</td>
            <td width={"3%"}>to</td>
            <td width={"8%"}>{element.newStatus}</td>
            <td width={"25%"}>
              {element.modifiedName ? element.modifiedName : element.modifiedBy}
            </td>
            <td width={"34%"}>{element.comment}</td>
          </tr>
        )
      })
    }
    return result
  }

function TableStateHistory({values, setHideStatus, hideStatus}){
    let toggleCss = hideStatus?"fa fa-caret-right":"fa fa-caret-down";
    return(
        <>
            <div className="portal-form-row ml-2">
              
                <div className="col-md-4">
                    <div className="subtitle2b">
                       Status History &nbsp; <span className={toggleCss} onClick={(e)=>setHideStatus(!hideStatus)}/>
                    </div>
                </div>
                
            </div>
            {!hideStatus && (
                <div id="pnStateHistory" className="portal-form-row ml-2">
                    <div className="col-md-12">
                        <table className="table-blue">
                            <tbody>
                                <tr key={"sh"}>
                                    <th width={"22%"}>Modified On</th>
                                    <th width={"8%"}>Previous Status</th>
                                    <th width={"3%"}>to</th>
                                    <th width={"8%"}>New Status</th>
                                    <th width={"25%"}>Modified By</th>
                                    <th width={"34%"}>Desc</th>
                                </tr>
                                {getStateHistoryRows(values)}
                            </tbody>
                        </table>
                    </div>

                </div>
            )}
            
        </>
        
        
    )
}

function TaskDialog({ model, clearModal, onModalClosed }){
    const [errorMessage, setErrorMessage] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [statusList, setStatusList] = useState([])
    const [priorityList, setPriorityList] = useState([])
    const [targetList, setTargetList] = useState([])
    const [selectedTargetName, setSelectedTargetName] = useState(model && model.targetEntityName?model.targetEntityName:"");

    const [hideComment, setHideComment] = useState(false)
    const [hideStateHistory, setHideStateHistory] = useState(false)
    const [hideFiles, setHideFiles] = useState(false)

    const [uploadFiles, setUploadFiles] = useState()

    useEffect(() => {
        //get salary id
        TaskManagementApi.getEnumList().then(({data}) => {
          if(data){
             if(!data.categoryList || data.categoryList.length == 0){
                setErrorMessage("No Task Categories has been setup by your admin");
             }   
             setCategoryList(data.categoryList)
             setPriorityList(data.priorityList)
             setStatusList(data.statusList)
             setTargetList(data.targetList)
          }
          
        })
 
      }, [])

     
    function handleSave(values){
        let loginEntityType = localStorage.getItem("loginEntityType");
        let formData = new FormData()
        formData.append("sourceType", loginEntityType.indexOf("ADVISER")>-1?"ADVISER":"ENTITY");
        formData.append("sourceId", values.id)
        formData.append("module", "TASK")
  
        uploadFiles && uploadFiles.forEach((f, index) => {
          formData.append(`file${index}`, f.file)
        })
  
        FileOperationApi.uploadMultiple(formData).then(({data})=>{
          if(data && data.data ){
            let payload = {...values, fileIds: data.data};
            TaskManagementApi.save(payload).then(()=>{
                Alert.success("Task has been saved.")
                if(onModalClosed){
                    onModalClosed();
                }else{
                    clearModal();
                }
                
            })


            setUploadFiles([])
          }
          
        })

        
    }

    function getTableMembers(mutators){
        let rows = [];
        for (let index = 0; index < targetList.length; index++) {
            let m = targetList[index];
            rows.push(
                <tr key={'m-'+ index} onClick={(e)=>{
                        mutators.setFormAttribute("targetEntityId", m.id);
                        setSelectedTargetName(m.name);   
                    }
                }>
                    <td style={{width:"30%"}}>{m.name}</td>
                    <td style={{width:"30%"}}>{m.entityTypeShort}</td>
                    <td style={{width:"30%"}}>{m.parentName}</td>
                </tr>
            )
        }
        return (
            <table className="table-blue tr-hover">
                <tbody>
                    <tr>
                      <th style={{width:"30%"}}>Name</th>
                      <th style={{width:"30%"}}>Type</th>
                      <th style={{width:"30%"}}>Under</th>
                    </tr>
                    {rows}

                </tbody>
            </table>
         )
    }

    function getImagePath(path){
        const baseUrl = localStorage.getItem("base-route") 
        return `${baseUrl}${path}`
    }

   
    return (
        
        <Form
            onSubmit={handleSave}
            initialValues={model}
            keepDirtyOnReinitialize={true}
            mutators={{
                pushToArray: arrayMutators.push,
                removeFromArray: arrayMutators.remove,
                updateArray: arrayMutators.update,
                setFormAttribute: ([fieldName, fieldVal], state, { changeValue }) => {
                    changeValue(state, fieldName, () => fieldVal)
                }
            }}
            render={({ handleSubmit, reset, values, form: { mutators } }) => (
            <form
                id="taskForm"
                onSubmit={async (event) => {
                    await handleSubmit(event)
                    reset()
                }}
            >
                <div className="simple-dialog">
                    <div className="portal-form-row">
                        <span className="text-redchili">{errorMessage}</span>

                    </div>
                    <RenderSimpleFields
                        fields={[
                            {
                                name: "title",
                                label: "Title",
                                required: true,
                                type: FieldType.TEXT,
                                placeholderlabel: "Task Title",
                                readOnly: (values && values.id)
                            },
                            {
                                name: "category",
                                label: "Category",
                                required: true,
                                type: FieldType.SELECTION,
                                required: true,
                                selectionData: categoryList,
                                valueProperty: "key",
                                labelProperty: "name",
                            },
                            {
                                name: "targetEntityId",
                                label:(values && values.id?"Re-assign to":"Assign to"),
                                type: FieldType.PICKER_TABLE,
                                required: true,
                                title:"Pick Member",
                                table: getTableMembers(mutators),
                                selectedName: selectedTargetName,
                                placeholderLabel: "Assignee"
                                
                            
                            },
                            {
                                name: "status",
                                label: intl.get("feedback.field.status"),
                                type: FieldType.SELECTION,
                                required: true,
                                selectionData: statusList,
                                valueProperty: "key",
                                labelProperty: "name",
                            
                            },
                            {
                                name: "priority",
                                label: intl.get("feedback.field.priority"),
                                type: FieldType.SELECTION,
                                required: true,
                                selectionData: priorityList,
                                valueProperty: "key",
                                labelProperty: "name",
                                readOnly: (values && values.id)
                            },
                            {
                                name: "dueDate",
                                label:"Due Date",
                                type: FieldType.DATETIME,
                            
                            
                            },
                            {
                                name: "description",
                                label: intl.get("field.description"),
                                type: FieldType.AREA,
                                required: true,
                                readOnly: (values && values.id)
                            }
                        
            
                        ]}
                    />

                </div>
              
                <div className="portal-form-row">
                    <div className="col-md-12">
                        <CustomFilePond
                            labelIdle="Drag, Drop or Browse"
                            files={uploadFiles}
                            maxFiles={3}
                            name={"other_doc"}
                            allowMultiple={true}
                            onupdatefiles={(uploadFiles, e) => setUploadFiles(uploadFiles)}
                            allowReplace
                        />
                    </div>
                </div> 

                
                {values && values.files && (
                    <div className="portal-form-row ml-2">
                        <div className="col-md-4">
                            <div className="subtitle2b">
                                Uploaded Files&nbsp; <span className={hideFiles?"fa fa-caret-right":"fa fa-caret-down"} onClick={(e)=>setHideFiles(!hideFiles)}/>
                            </div>
                        </div>
                    </div>
                )}            
                
                {!hideFiles && (
                    <div className="portal-form-row">
                        <div className="col-md-12">
                            {values && values.files && values.files.map((f)=>(
                                <div className="feedback-download">
                                    <i className="fa fa-link" />
                                    <a href={getImagePath(f.path)}>
                                        {f.filename}
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>     
                )}
               
                    
               
                {model && (
                    <TableComments values={values} mutators={mutators} setHideStatus={setHideComment} hideStatus={hideComment}/>
                )}
            
                {model && (
                    <TableStateHistory values={values} setHideStatus={setHideStateHistory} hideStatus={hideStateHistory}/>
                )}
               

                <NetWorthFormButtons
                        reset={reset}
                        readOnlyAccess={false}
                    
                    />
            </form>
            )}


        />
        
    )
}
export default TaskDialog