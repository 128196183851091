import React, { useState } from 'react';
import intl from 'react-intl-universal';
import SRSInvestmentPlatform from './SRSInvestmentPlatform';
import SRSInvestmentPlatformAssetAllocation from './SRSInvestmentPlatformAssetAllocation';

function SRSInvestmentPlatformDetails(props) {
    const [selectedTab, setTab] = useState('InvestmentPlatform');
    const tabs = [
        {
            name: 'InvestmentPlatform',
            label: intl.get('SRS.InvestmentPlatform'),
            // eslint-disable-next-line react/display-name
            component: key => <SRSInvestmentPlatform key={key} />,
        },
        {
            name: 'InvestmentPlatformAssetAllocation',
            label: intl.get('SRS.InvestmentPlatformAssetAllocation'),
            // eslint-disable-next-line react/display-name
            component: key => (
                <SRSInvestmentPlatformAssetAllocation key={key} />
            ),
        },
    ];
    return (
        <div className="portal-form-table2">
            <div className="subtitle-holder">
                <div className="title">
                    {intl.get('netWorth.investmentDetails.title')}
                </div>
            </div>
            <div className="content-panel-tab">
                {tabs.map((item, index) => (
                    <a
                        href="# "
                        role="button"
                        key={index}
                        onClick={() => setTab(item.name)}
                        className={selectedTab === item.name ? 'selected' : ''}
                    >
                        {item.label}
                    </a>
                ))}
            </div>

            {tabs
                .filter(item => item.name === selectedTab)
                .map((item, key) => item.component(key))}
        </div>
    );
}

export default SRSInvestmentPlatformDetails;
