/*
    Author : Erik Farhan Malik (erikfarhanmalik@gmail.com)
    Description : Residence Property main page (modal)
*/

import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import intl from "react-intl-universal"
import {
  netWorthActions,
  modalWindowActions,
  netWorthFormActions
} from "../../actions"
import { CountryList } from "../../constants/Lists"
import { ResidenceProperty, PersonalContents, MotorVehicle } from ".."
import NetWorthListingGroup, {
  NetWorthListingGroupPropsComposer
} from "./NetWorthListingGroup"
import { NetWorthPath } from "./NetWorthForm"
import { modalWindow } from "../../constants/ModalWindowLists"
import { translateListLabel } from "../../helpers/list-translation"

class PersonalAssets extends NetWorthListingGroup {
  constructor(props) {
    const { getPersonalAssetsListing } = props
    const listingDefinition = [
      {
        title: intl.get("personalAssets.title.primaryResidenceProperty"),
        // 20220103
        showAddButton: (listing) =>
          !(
            listing &&
            listing.primaryResidenceProperty &&
            listing.primaryResidenceProperty.length
          ),
        path: NetWorthPath.primaryResidenceProperty,
        modalName: modalWindow.PRIMARY_RESIDENCE_PROPERTY
      },
      {
        title: intl.get("personalAssets.title.otherResidenceProperty"),
        path: NetWorthPath.otherResidenceProperties,
        modalName: modalWindow.OTHER_RESIDENCE_PROPERTY
      },
      {
        title: intl.get("personalAssets.title.personalContents"),
        path: NetWorthPath.personalContents,
        modalName: modalWindow.PERSONAL_CONTENT_FORM
      },
      {
        title: intl.get("personalAssets.title.motorVehicles"),
        path: NetWorthPath.motorVehicles,
        modalName: modalWindow.MOTOR_VEHICLE_FORM
      }
    ]
    super(props, "personalAssets", getPersonalAssetsListing, listingDefinition)
  }

  render() {
    const { optionsData, currency, currencySymbol } = this.props
    const { contentWrapper } = this
    return contentWrapper(
      <React.Fragment>
        
        <ResidenceProperty
          optionsData={optionsData}
          countryOptions={translateListLabel(CountryList)}
          type="PRIMARY"
          currency={currency}
          currencySymbol = {currencySymbol}
        />
        <ResidenceProperty
          optionsData={optionsData}
          countryOptions={translateListLabel(CountryList)}
          type="OTHER"
          currency={currency}
          currencySymbol = {currencySymbol}
        />
        <PersonalContents optionsData={optionsData} currency={currency} currencySymbol = {currencySymbol}/>
        <MotorVehicle optionsData={optionsData} currency={currency} currencySymbol = {currencySymbol} />
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { personalAssetsListing } = state.netWorth
  return NetWorthListingGroupPropsComposer(state, personalAssetsListing)
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, netWorthActions, modalWindowActions, netWorthFormActions),
    dispatch
  )
}

PersonalAssets = connect(mapStateToProps, mapDispatchToProps)(PersonalAssets)

export default PersonalAssets
