import React from "react"
import { getSymbolFromCode, numberWithCommas } from "../../helpers"

const CrucialStageProjectionTable = ({ currency, data }) => {
  const tableInfoCols = ["Income", "Expenses", "Surplus/Deficit"]
  let modifiedCrucialStages = [
    data.crucialStages["currentStage"],
    data.crucialStages["retirementStage"],
    data.crucialStages["maxAgeStage"]
  ]
  const modifiedDataArr =
    data &&
    data.crucialStages &&
    modifiedCrucialStages.map((item) => {
      if (item) {
        return {
          income: item.totalIncome,
          expense: item.totalExpense,
          surplusOrDeficit: item.surplusOrDeficit
        }
      } else
        return {
          income: 0,
          expense: 0,
          surplusOrDeficit: 0
        }
    })
  return (
    <table
      className="table-blue table-cs-popup_edu my-3"
      width="100%"
      cellSpacing="0"
      cellPadding="0"
      border="0"
    >
      <thead>
        <tr>
          <th></th>
          <th style={{ textAlign: "left" }}>Current Stage</th>
          <th style={{ textAlign: "left" }}>Retirement Stage</th>
          <th style={{ textAlign: "left" }}>End Of Life Stage</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{tableInfoCols[0]}</td>
          <td>
            {getSymbolFromCode(currency)}
            {numberWithCommas(modifiedDataArr[0].income)}
          </td>
          <td>
            {getSymbolFromCode(currency)}
            {numberWithCommas(modifiedDataArr[1].income)}
          </td>
          <td>
            {getSymbolFromCode(currency)}
            {numberWithCommas(modifiedDataArr[2].income)}
          </td>
        </tr>
        <tr>
          <td>{tableInfoCols[1]}</td>
          <td>
            {getSymbolFromCode(currency)}
            {numberWithCommas(modifiedDataArr[0].expense)}
          </td>
          <td>
            {getSymbolFromCode(currency)}
            {numberWithCommas(modifiedDataArr[1].expense)}
          </td>
          <td>
            {getSymbolFromCode(currency)}
            {numberWithCommas(modifiedDataArr[2].expense)}
          </td>
        </tr>
        <tr>
          <td>{tableInfoCols[2]}</td>
          <td>
            {getSymbolFromCode(currency)}
            {numberWithCommas(modifiedDataArr[0].surplusOrDeficit)}
          </td>
          <td>
            {getSymbolFromCode(currency)}
            {numberWithCommas(modifiedDataArr[1].surplusOrDeficit)}
          </td>
          <td>
            {getSymbolFromCode(currency)}
            {numberWithCommas(modifiedDataArr[2].surplusOrDeficit)}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default CrucialStageProjectionTable
