import React, { useState, useEffect } from "react";
import RetirementApi from "../../../../api/RetirementApi";



function UsiDialog({ getData, clearModal }) {
    const rowClassName = "portal-form-row";
    const [resultRows, setResultRows] = useState("");
    const [keyword, setKeyword] = useState("");
    
    useEffect(() => showResult(), []);

    const onRowClicked = data => {
        getData(data);
        clearModal();
    };

    function showResult() {
        RetirementApi.getFind(keyword).then(({data}) => {
            setResultRows(data);
        });
    };

    const onSearchKeywordKeyDown = (e) => {
        if (e.key === "Enter") {
          showResult()
        }
    
        if (e.key === "Backspace" && keyword.length == 1) {
            showResult()
        }
    
        if (e.keyCode === 46 && keyword.length == 0) {
            showResult()
        }
      }
    
      const onSearchKeywordChange = (e) => {
        setKeyword(e.target.value)
      }

    return (
        <div className="notification-dialog">
            <div className={rowClassName}>
                <div className="col-md-2">
                   Keyword  <span className="text-redchili">*</span>
                </div>
                <div className="col-md-2">
                    <div className="alphabet-R-v1">
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/images/ico-search.svg`}
                            alt="search"
                        />
                        <input
                            className="Search-v1"
                            placeholder="Search USI or Fund Name"
                            onChange={(e) => onSearchKeywordChange(e)}
                            onKeyDown={(e) => onSearchKeywordKeyDown(e)}
                        />
                    </div>
                </div>
               
            </div>
            <div className={rowClassName}>
                <div className="col-md-4">
                    Please pick or select data by clicking row below
                </div>    
            </div>
                            
            <div className={rowClassName}>
                <div className="col-md-12">
                    <table className="table-blue">
                        <tbody>
                            <tr>
                                <th style={{width:"8%"}}>USI</th>
                                <th style={{width:"8%"}}>Fund ABN</th>
                                <th style={{width:"40%"}}>Fund Name</th>
                                <th style={{width:"34%"}}>Product Name</th>
                                <th style={{width:"10%"}}>Contribution Restrictions</th>
                            </tr>

                            {resultRows &&
                                resultRows.map((r, idx) => (
                                    <tr onClick={(e) => onRowClicked(r)} >
                                        <td style={{width:"8"}}>{r.usi}</td>
                                        <td style={{width:"8%"}}>{r.fundAbn}</td>
                                        <td style={{width:"40%"}}>{r.fundName}</td>
                                        <td style={{width:"34%"}}>{r.productName}</td>
                                        <td style={{width:"10%"}}>{r.contributionRestrictions=="Y"?'Yes':'No'}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
export default UsiDialog;
