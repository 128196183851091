import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NetWorthPath } from './NetWorthForm';
import { modalWindow } from '../../constants/ModalWindowLists';
import {
    netWorthActions,
    netWorthFormActions,
    modalWindowActions,
} from '../../actions';
import NetWorthListingGroup, {
    NetWorthListingGroupPropsComposer,
} from './NetWorthListingGroup';
import EmploymentTerminationPaymentsForm from './EmploymentTerminationPayments/EmploymentTerminationPaymentsForm';

class EmploymentTerminationPayments extends NetWorthListingGroup {
    constructor(props) {
        const { getEmploymentTerminationPaymentsListing } = props;
        const listingDefinition = [
            {
                title: intl.get('employmentTerminationPayments.title'),
                path: NetWorthPath.employmentTerminationPayments,
                modalName: modalWindow.EMPLOYMENT_TERMINATION_PAYMENTS,
                showAddButton: () => false,
                hideDeleteButton: true,
                oneDataOnly: true,
            },
        ];
        super(
            props,
            'employmentTerminationPayments',
            getEmploymentTerminationPaymentsListing,
            listingDefinition,
            true
        );
    }

    render() {
        const { optionsData, currency } = this.props;
        const { contentWrapper } = this;

        return contentWrapper(
            <React.Fragment>
                <EmploymentTerminationPaymentsForm optionsData={optionsData} currency={currency}/>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { employmentTerminationPaymentsListing } = state.netWorth;
    return NetWorthListingGroupPropsComposer(
        state,
        employmentTerminationPaymentsListing
    );
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        Object.assign(
            {},
            netWorthActions,
            modalWindowActions,
            netWorthFormActions
        ),
        dispatch
    );
}

EmploymentTerminationPayments = connect(
    mapStateToProps,
    mapDispatchToProps
)(EmploymentTerminationPayments);

export default EmploymentTerminationPayments;
