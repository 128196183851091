import React from "react"

const SummaryTable = ({ summary }) => {
  return (
    <div>
      <table className="table-blue" width="100%">
        <tbody>
          <tr>
            <th>Account No</th>
            <th>Client Name</th>
            <th>User Id</th>
            <th>Account Nature</th>
            <th>Account Type</th>
            <th>Account Status</th>
            <th>Professional Investor</th>
            <th>Discretionary Investor</th>
            <th>RSP</th>
            <th>Portfolio Type</th>
            <th>Summary Date</th>
            <th>Total Available Cash</th>
            <th>Total Purchasing Power</th>
            <th>Total Relationship Balance</th>
          </tr>
          {summary.length ? (
            summary.map((item) => {
              return (
                <tr>
                  <td>{item.accountNo}</td>
                  <td>{item.clientName}</td>
                  <td>{item.userId}</td>
                  <td>{item.accountNature}</td>
                  <td>{item.accountType}</td>
                  <td>{item.accountStatus}</td>
                  <td>{item.professionalInvestor}</td>
                  <td>{item.discretionaryInvestor}</td>
                  <td>{item.rsp}</td>
                  <td>{item.portfolioType}</td>
                  <td>{item.summaryDate}</td>
                  <td>$ {item.totalAvailableCash.toFixed(2)}</td>
                  <td>$ {item.totalPurchasingPower.toFixed(2)}</td>
                  <td>$ {item.totalRelationshipBalance.toFixed(2)}</td>
                </tr>
              )
            })
          ) : (
            <></>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default SummaryTable
