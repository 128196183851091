import { estatePlanningAction } from '../../constants/ActionConstants';

export function estatePlanning(state = {}, action) {
  switch (action.type) {
    case estatePlanningAction.GET_KYCESTATEPLANNING_REQUEST:
      return {
        loading: true
      };
    case estatePlanningAction.GET_KYCESTATEPLANNING_SUCCESS:
      return {
        items: action.kycEstatePlanning
      };
    case estatePlanningAction.GET_KYCESTATEPLANNING_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}
