import React from 'react';
import './Textbox.css';

function Textbox({ input, meta: { touched, error }, ...props }) {
    return (
        <div
            className={`d-flex flex-column ${
                touched && error ? 'itemvalidate bad' : ''
            }`}
        >
            <input {...input} {...props} type="text" className="textbox" />
            {touched && (error && <span className="alert">{error}</span>)}
        </div>
    );
}

export default Textbox;
