import React from "react";
import BoxUser from "./BoxUser";

const GroupedBoxUser = ({ userGroups, onAddClicked }) =>
    userGroups.map((userGroup, index) => (
        <div className="flexbox" key={index}>
            <div className="box-user-title">
                <h1>
                    <span className="ico-alertlighting" /> {userGroup.title}
                </h1>
                <div className="btn-holder">
                    <button
                        className="btn btn-plus btn-plus-grey"
                        onClick={() => onAddClicked(userGroup.title)}
                    />
                </div>
            </div>

            {userGroup.users.map((user, idx) => (
                <BoxUser user={user} key={idx} />
            ))}
        </div>
    ));

export default GroupedBoxUser;
