/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Library : EntityApi
    Description : methods related with entity
*/

import api from "../lib/api";

// change base route for different entity/domain here
const BASE_ROUTE = "/entity/";

class EntityApi {
    // get current entity
    static getCurrentEntity() {
        return api.get(`${BASE_ROUTE}current/`);
    }


    static getEntityTypes() {
        return api.get(`${BASE_ROUTE}types`);
    }

    static getUserEntityById(id) {
        return api.get(`${BASE_ROUTE}detail/${id}`);
    }

  

    static addEntity(model) {
        return api.post(`${BASE_ROUTE}`, model);
    }

    static addEntityAsChild(parentId, model) {
        return api.post(`${BASE_ROUTE}?parentId=${parentId}`, model);
    }

    static setupNewCompany(model) {
        return api.post(`${BASE_ROUTE}setup-company`, model);
    }

    static getEntityById(id) {
        return api.get(`${BASE_ROUTE}${id}`);
    }

    static updateEntityById(id, model) {
        return api.post(`${BASE_ROUTE}${id}`, model);
    }

    static getFinancialInstitutions(countryCode) {
        if(countryCode){
            return api.get(`${BASE_ROUTE}financial-institutions?countryCode=${countryCode}`);
        }else{
            return api.get(`${BASE_ROUTE}financial-institutions`);
        }
        
        
    }

      // get entity list backup
      static getListBackup(module) {
        return api.get(`${BASE_ROUTE}backup-list?module=${module}`);
    }
    static deleteBackupById(id) {
        return api.delete(`${BASE_ROUTE}backup-delete/${id}`);
    }


    static getDealershipSettings() {
        return api.get(`${BASE_ROUTE}dealership-settings`);
    }

    static saveDealershipSettings(model) {
        return api.post(`${BASE_ROUTE}dealership-settings`, model);
    }
}

export default EntityApi;
