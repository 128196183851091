import React, { Component } from "react"
import { Route, Switch, Router } from "react-router-dom"
import { connect } from "react-redux"
import { history } from "../helpers"

// Containers
import { CustomerLayout } from "../containers"
// Pages
import { Login } from "../pages"
// Helpers
import LoadingSpinner from "../helpers/LoadingSpinner"

import PrivateRoute from "../PrivateRoute"

// import SignUp from '../components/LoginForm/SignUp';

class AppRoute extends Component {
  render() {
    return (
      <React.Fragment>
        <LoadingSpinner show={this.props.loading} />
        <Router history={history}>
          <Switch>
            <Route exact path="/login" name="Login Page" component={Login} />
            <PrivateRoute path="/" name="Home" component={CustomerLayout} />
          </Switch>
        </Router>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    loading: state.loader.apiStatus > 0
  }
}

export default connect(mapStateToProps)(AppRoute)
