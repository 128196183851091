/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Library : MalabarMoney
    Description : component specific for money related input
*/

import React, { PureComponent } from "react"
import NumberFormat from "react-number-format"
import { CurrencyEnum } from "../../constants/Enums"
import { getSymbolFromCode } from "../../helpers"
import { DECIMAL_SCALE } from "../../constants/NumberFormatConstants"

const CURRENCY_PART = "CURRENCY"
const VALUE_PART = "VALUE"
const PRIORITY = ["USD", "AUD", "GBP", "EUR", "SGD", "HKD", "MYR", "NZD"]

export default class MalabarMoney extends PureComponent {
  state = {
    currency: this.props.defaultCurrency,
    moneyValue: "",
    value: this.props.value,
    currencyOptions: "",
    symbol: ""
  }

  componentWillMount() {
    this.setComponentState(this.props.value)
  }

  componentWillReceiveProps(nextProps) {
    // if(nextProps.defaultCurrency!==thi)
    if (!this.props.value.value) {
      this.setComponentState({ currency: this.props.defaultCurrency })
    }
    if (nextProps.value !== this.props.value) {
      this.setComponentState(nextProps.value)
    }
  }

  setComponentState = (valueSource) => {
    // get currency options list
    const { lockToCurrency } = this.props
    const currencies = this.getCurrencyOptions()
    this.setState(
      {
        currencyOptions: currencies
      },
      () => {
        if (
          valueSource !== "" &&
          valueSource !== null &&
          valueSource !== undefined
        ) {
          this.setState({
            currency: valueSource.currency,
            moneyValue: valueSource.value,
            value: valueSource,
            symbol: getSymbolFromCode(valueSource.currency)
          })
        } else {
          this.setState({
            currency:
              lockToCurrency !== ""
                ? lockToCurrency
                : this.props.defaultCurrency,
            moneyValue: "",
            value: null,
            symbol: ""
          })
        }
      }
    )
  }

  getCurrencyOptions = () => {
    const currencyStr = []
    Object.keys(CurrencyEnum).forEach((key) => {
      let ccy = CurrencyEnum[key]
      if (!PRIORITY.includes(ccy)) {
        currencyStr.push(
          <option key={ccy} value={ccy}>
            {ccy}
          </option>
        )
      }
    })
    return currencyStr
  }

  onPartChange = (part, element) => {
    const inputValue = element
    const { currency, moneyValue } = this.state

    switch (part) {
      case CURRENCY_PART:
        this.setState(
          {
            currency: inputValue.target.value,
            value: {
              currency: inputValue.target.value,
              value: +moneyValue
            },
            symbol: getSymbolFromCode(inputValue.target.value)
          },
          () => {
            // notify final-form
            this.props.onChange(this.state.value) //20201117 MABT-437
          }
        )
        break
      case VALUE_PART:
        this.setState(
          {
            moneyValue: inputValue.value,
            value: {
              currency,
              value: +inputValue.value
            }
          },
          () => {
            // notify final-form
            this.props.onChange(this.state.value) //20201117 MABT-437
          }
        )
        break

      default:
        break
    }
  }

  render() {
    const {
      label,
      required,
      name,
      touched,
      error,
      allowNegative,
      disabled,
      decimalSeparator,
      thousandSeparator,
      lockToCurrency = "",
      isFullWidth,
      popupPickerEvent,
      popupPickerTitle
    } = this.props

    const { currency, moneyValue } = this.state

    return (
      <React.Fragment>
        {label && (
          <label className="d-flex align-items-center">
            {label} {required && <span className="required-asterix">*</span>}
          </label>
        )}

        <div
          className={isFullWidth ? "row-holder d-flex flex-column" : " w-100"}
        >
          <div className="d-flex">
            <div className="select-box">
              <select
                name={"_currency"}
                value={currency}
                onChange={(e) => this.onPartChange(CURRENCY_PART, e, name)}
                required
                disabled={lockToCurrency !== "" || disabled}
              >
                <optgroup>
                  {PRIORITY.map((v, idx) => (
                    <option key={idx}>{v}</option>
                  ))}
                </optgroup>
                <optgroup label="-------------------------">
                  {this.getCurrencyOptions()}
                </optgroup>
              </select>
            </div>
            <NumberFormat
              value={moneyValue}
              displayType={"input"}
              allowNegative={allowNegative}
              disabled={disabled}
              decimalScale={DECIMAL_SCALE}
              decimalSeparator={decimalSeparator}
              thousandSeparator={thousandSeparator}
              className="text-input currency-input flex-grow-1"
              onValueChange={(e) => this.onPartChange(VALUE_PART, e, name)}
            />
            {popupPickerEvent && (
              <input
                className="portal-btn portal-btn-submit"
                type="button"
                value={popupPickerTitle ? popupPickerTitle : "..."}
                onClick={popupPickerEvent}
              />
            )}
          </div>

          {touched && error && <div className="alert">{error}</div>}
        </div>
      </React.Fragment>
    )
  }
}
