import React, { useState, useEffect } from "react"
import intl from "react-intl-universal"
import { Field, Form, FormSpy } from "react-final-form"
import {
  FormButtons,
  ClassificationSelection,
  ModalNoAccess
} from "../components"
import RenderSimpleFields, {
  FieldType
} from "../components/Form/RenderSimpleFields"
import { fileFieldRender, getSymbolFromCode } from "../helpers"
import { getCurrency } from "../helpers/CurrencyConversion"
import { FrequencyEnum } from "../constants/Enums"
import { NetWorthApi } from "../api"
import AssetRiskClassificationApi from "../api/AssetRiskClassificationApi"
const TermDepositForm = ({
  handleSave,
  model,
  optionsData,
  readOnlyAccess,
  showClassification = true,
  defaultCurrency = "MYR",
  clientInformation: { client = {}, partner = {}, isJoint },
  subFormModel
}) => {
  model = subFormModel ? subFormModel : model
  if (!model.rolloverType) {
    model.rolloverType = "P"
  }
  const [currency, setcurrency] = useState(model.accountCurrency || "MYR")
  const [rollOver, setRollOver] = useState(true)
  const [cashAccounts, setCashAccounts] = useState([])
  const [productTypes, setProductTypes] = useState([])
  useEffect(() => {
    let clientId = localStorage.getItem("activeClientId")
    NetWorthApi.getClientCashAccounts(clientId).then(({ data }) => {
      setCashAccounts(data)
    })
    AssetRiskClassificationApi.getProductTypes("TERM_DEPOSIT").then(
      ({ data }) => {
        if (data) {
          setProductTypes(data.data)
        }
      }
    )
  }, [])

  function getCashOptions() {
    let result = []
    if (cashAccounts) {
      cashAccounts.forEach((c) => {
        result.push(
          <option key={`enum-${c.id}`} value={c.id}>
            {c.accountNumber ? c.accountNumber : c.accountName}{" "}
            {c.primary ? "(primary)" : ""}
          </option>
        )
      })
    }
    return result
  }

  if (!model.accountCurrency) {
    model.accountCurrency = getCurrency(client.taxResidency)
    setcurrency(model.accountCurrency)
  }

  if (!model.id) {
    model.rollover = true
  }
  if (!model.rolloverType) {
    model.rolloverType = "P"
  }

  if (readOnlyAccess) {
    return <ModalNoAccess />
  }

  function getFilteredFrequencyOptions() {
    let result = []
    let enumData = FrequencyEnum
    Object.keys(enumData).forEach((key) => {
      if (
        enumData[key] !== FrequencyEnum.Frequency_ONE_OFF &&
        enumData[key] !== FrequencyEnum.Frequency_FORTNIGHTLY
      ) {
        result.push(
          <option key={`enum-${key}`} value={enumData[key]}>
            {intl.get(key)}
          </option>
        )
      }
    })
    return result
  }

  return (
    <Form
      onSubmit={handleSave}
      initialValues={model}
      mutators={{
        setFormAttribute: ([fieldName, fieldVal], state, { changeValue }) => {
          changeValue(state, fieldName, () => fieldVal)
        }
      }}
      render={({ handleSubmit, reset, mutators: { setFormAttribute } }) => (
        <form id="termDepositForm" onSubmit={handleSubmit}>
          <FormSpy subscription={{ values: true }}>
            {({ values }) => {
              const fields = [
                {
                  name: "accountNumber",
                  label: intl.get("field.uniqueCode"),
                  type: FieldType.TEXT,
                  required: true
                },
                {
                  name: "financialInstitutionName",
                  label: intl.get("field.financialInstitutionName"),
                  type: FieldType.TEXT,
                  required: false
                },

                {
                  name: "accountName",
                  label: intl.get("field.accountName"),
                  type: FieldType.TEXT,
                  required: false
                },
                {
                  name: "productTypeCode",
                  label: intl.get("field.productType"),
                  type: FieldType.SELECTION,
                  selectionData: productTypes,
                  valueProperty: "code",
                  labelProperty: "name",
                  required: true
                },
                {
                  name: "managedBy",
                  label: intl.get("field.managedBy"),
                  type: FieldType.ENUM_SELECTION,
                  enumData: optionsData.managedByOptions,
                  required: false
                },
                {
                  name: "accountCurrency",
                  label: intl.get("field.accountCurrency"),
                  type: FieldType.ENUM_SELECTION,
                  enumData: optionsData.currencyOptions,
                  required: false,
                  defaultValue: defaultCurrency,
                  onChange: (value) => {
                    setcurrency(value)
                  }
                },
                {
                  name: "accountBalance",
                  label: intl.get("field.accountBalance"),
                  type: FieldType.MONEY,
                  required: true,
                  defaultCurrency,
                  symbolPrefix: getSymbolFromCode(currency),
                  allowNegative: false
                },
                {
                  name: "termInterestRate",
                  label: intl.get("field.annualInterestRate"),
                  type: FieldType.PERCENTAGE,
                  required: true
                },

                {
                  name: "termFrequency",
                  label: intl.get("field.frequency"),
                  type: FieldType.ENUM_SELECTION,
                  enumData: getFilteredFrequencyOptions(),
                  required: true
                },
                {
                  name: "termStartDate",
                  label: intl.get("field.termStartDate"),
                  type: FieldType.DATE,
                  required: true
                },
                {
                  name: "termEndDate",
                  label: intl.get("field.termEndDate"),
                  type: FieldType.DATE,
                  required: true
                },
                {
                  name: "targetCashId",
                  label: intl.get("field.settlementCash"),
                  type: FieldType.ENUM_SELECTION,
                  enumData: getCashOptions(),
                  required: true
                },
                {
                  name: "rollover",
                  label: intl.get("field.rollover"),
                  checkboxLabel: intl.get("YesNoEnum_YES"),
                  type: FieldType.CHECKBOX,
                  onChange: (value) => {
                    // alert(value);s
                    setRollOver(value)
                  }
                }
              ]

              const field1 = [
                {
                  name: "rolloverType",
                  label: intl.get("field.rolloverType"),
                  type: FieldType.ENUM_SELECTION,
                  enumData: optionsData.TermDepositRolloverTypeOptions,
                  required: true
                  // onChange: value => {
                  //     setRollOver(
                  //         value.length > 0 ? true : false
                  //     );
                  // }
                  // readOnly: values.rollover ? false : true
                }
              ]

              return (
                <div>
                  <div className="portal-form-popup">
                    {/* {showClassification && <ClassificationSelection />} */}
                    <ClassificationSelection
                      {...{ client, partner, isJoint }}
                    />
                    <div className="subtitle2">
                      {intl.get("termDeposit.subtitle")}
                    </div>
                    <RenderSimpleFields fields={fields} />
                    {values.rollover && <RenderSimpleFields fields={field1} />}
                    <Field
                      component={fileFieldRender}
                      customClassName="mt-1 d-block"
                      labelIdle="Drag, Drop or Browse"
                      label="Upload Documents"
                      onupdatefiles={(fileItems) => {
                        setFormAttribute("proofDocument", fileItems[0])
                      }}
                      fileInformation={(model && model.proofDocument) || null}
                    />
                  </div>
                  <FormButtons reset={reset} readOnlyAccess={readOnlyAccess} />
                </div>
              )
            }}
          </FormSpy>
        </form>
      )}
    />
  )
}
export default TermDepositForm
