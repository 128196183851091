/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Description : Loan details form
*/

import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import RenderSimpleFields, {
    FieldType,
} from '../../components/Form/RenderSimpleFields';
import { getSymbolFromCode } from '../../helpers';
import Get from "../../helpers/NullHelper";
import LoanApi from '../../api/LoanApi';

const LoanDetails = ({ optionsData, currency = 'USD' , model, mutators}) => {
    const {
        loanInterestTypeOptions,
        frequencyOptions,
        currencyOptions,
    } = optionsData;

    const [cry, setCurrency] = useState(currency);

    
    function calculateRepaymentAmount(){
        let data = {
            principal: Get(model, "loan.borrowingsPrinciple"),
            interestRate: Get(model, "loan.borrowingsAnnualInterestRate"),
            startDate : Get(model, "loan.borrowingsStartDate"),
            term: Get(model, "loan.borrowingsTermInYears"),
            termFreq: 'YEARLY',
            paymentFreq:  Get(model, "loan.borrowingsRepaymentFrequency"),
            balloonPayment:0,

        }
        LoanApi.postCalculateRepayment(data)
        .then((res) => {
            if(res.data){
                mutators.setFormAttribute("loan.borrowingsRepaymentAmount", res.data.repaymentAmount);
            }
            
        })
      
    }

    useEffect(() => {
        

        if (cry !== currency) {
            setCurrency(cry);
        }
    }, [currency]);

    const onChangeCurrency = val => {
        setCurrency(val);
    };

    return (
        <div className="pop-holder">
            <RenderSimpleFields
                fields={[
                    {
                        name: 'loan.borrowingsAccountNumber',
                        label: intl.get('field.uniqueCode'),
                        type: FieldType.TEXT,
                        required: true,    
                    },
                    {
                        name: 'loan.financialInstitutionName',
                        label: intl.get('field.financialInstitutionName'),
                        type: FieldType.TEXT,
                        required: false,    
                    },
                   
                    {
                        name: 'loan.borrowingsCurrency',
                        label: intl.get('field.loan.borrowingsCurrency'),
                        type: FieldType.ENUM_SELECTION,
                        enumData: currencyOptions,
                        onChange: onChangeCurrency,
                        required: false,     
                    },
                    {
                        name: 'loan.borrowingsPrinciple',
                        label: intl.get('field.loan.borrowingsPrinciple'),
                        type: FieldType.MONEY,
                        defaultCurrency: cry,
                        symbolPrefix: getSymbolFromCode(cry),
                        required: true,      
                        allowNegative: false,    
                    },
                    {
                        name: 'loan.borrowingsAnnualInterestRate',
                        label: intl.get(
                            'field.loan.borrowingsAnnualInterestRate'
                        ),
                        type: FieldType.PERCENTAGE,
                        required: true,         
                    },
                    {
                        label: intl.get('field.loan.borrowingsStartDate'),
                        name: 'loan.borrowingsStartDate',
                        type: FieldType.DATE,
                        required: true,     
                    },
                    {
                        name: 'loan.borrowingsTermInYears',
                        label: intl.get('field.loan.borrowingsTermInYears'),
                        type: FieldType.TEXT,
                        required: true,     
                    },
                    {
                        name: 'loan.inArrear',
                        label: intl.get('field.inArrear'),
                        checkboxLabel: intl.get('YesNoEnum_YES'),
                        type: FieldType.CHECKBOX,
                    },
                    {
                        name: 'loan.borrowingsRepaymentFrequency',
                        label: intl.get(
                            'field.loan.borrowingsRepaymentFrequency'
                        ),
                        type: FieldType.ENUM_SELECTION,
                        enumData: frequencyOptions,
                        required: true,         
                    },
                    {
                        name: 'loan.borrowingsRepaymentAmount',
                        label: intl.get('field.loan.loanRepaymentAmount'),
                        type: FieldType.MONEY,
                        symbolPrefix: currency.symbol,
                        defaultCurrency: currency.name,
                        readOnly: true,
                        allowNegative: false,
                        popupPickerTitle:'calculate',
                        popupPickerEvent: e => calculateRepaymentAmount()
                    },
                  
                    {
                        name: 'loan.valuationDate',
                        label: intl.get('field.loan.valuationDate'),
                        type: FieldType.DATE,
                    },
                    {
                        name: 'loan.additionalPaymentAmount',
                        label: intl.get('field.additionalPaymentAmount'),
                        type: FieldType.MONEY,
                        defaultCurrency: cry,
                        symbolPrefix: getSymbolFromCode(cry),
                        allowNegative: false,  
                    },
                    {
                        name: 'loan.additionalRepaymentHandlingFees',
                        label: intl.get(
                            'field.additionalRepaymentHandlingFees'
                        ),
                        type: FieldType.MONEY,
                        defaultCurrency: cry,
                        symbolPrefix: getSymbolFromCode(cry),
                        allowNegative: false,  
                    },
                    {
                        name: 'loan.additionalRepaymentFrequency',
                        label: intl.get('field.additionalRepaymentFrequency'),
                        type: FieldType.ENUM_SELECTION,
                        enumData: frequencyOptions,
                    },
                    {
                        label: intl.get('field.nextAdditionalRepaymentDate'),
                        name: 'loan.additionalRepaymentNextPaymentDate',
                        type: FieldType.DATE,
                    },
                    {
                        name: 'loan.borrowingsManagementFees',
                        label: intl.get('field.loan.borrowingsManagementFees'),
                        type: FieldType.MONEY,
                        defaultCurrency: cry,
                        symbolPrefix: getSymbolFromCode(cry),
                        allowNegative: false,  
                    },
                    {
                        name: 'loan.borrowingsManagementFeesFrequency',
                        label: intl.get(
                            'field.loan.borrowingsManagementFeesFrequency'
                        ),
                        type: FieldType.ENUM_SELECTION,
                        enumData: frequencyOptions,
                    },
                    {
                        label: intl.get(
                            'field.loan.borrowingsManagementFeesNextPaymentDate'
                        ),
                        name: 'loan.borrowingsManagementFeesNextPaymentDate',
                        type: FieldType.DATE,
                    },
                ]}
            />
        </div>
    );
};

export default LoanDetails;
