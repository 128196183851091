/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Library : MalabarMoneyCashflow
    Description : component specific for money related input (for cashflow page)
*/

import React, { PureComponent } from "react"
import NumberFormat from "react-number-format"
import { CurrencyEnum } from "../../constants/Enums"
import { getSymbolFromCode } from "../../helpers"
import { DECIMAL_SCALE } from "../../constants/NumberFormatConstants"

const CURRENCY_PART = "CURRENCY"
const VALUE_PART = "VALUE"
const VALUE_YEARLY_PART = "VALUE_YEARLY_PART"

export default class MalabarMoneyCashflow extends PureComponent {
  state = {
    currency: this.props.currency || CurrencyEnum.Currency_AUD,
    moneyValue: "",
    value: this.props.value,
    currencyOptions: "",
    symbolPrefix: ""
  }

  componentWillMount() {
    this.setComponentState(this.props.value, this.props.currency)
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.value !== this.props.value ||
      nextProps.currency !== this.props.currency
    ) {
      this.setComponentState(nextProps.value, nextProps.currency)
    }
  }

  setComponentState = (valueSource, currency) => {
    // get currency options list
    const { lockToCurrency } = this.props
    const currencies = this.getCurrencyOptions()
    this.setState(
      {
        currencyOptions: currencies
      },
      () => {
        if (
          valueSource !== "" &&
          valueSource !== null &&
          valueSource !== undefined
        ) {
          this.setState({
            currency: valueSource.currency,
            moneyValue: valueSource.value,
            value: valueSource,
            symbolPrefix: getSymbolFromCode(valueSource.currency)
          })
        } else {
          this.setState({
            currency,
            moneyValue: "",
            value: null,
            symbolPrefix: getSymbolFromCode(
              lockToCurrency !== "" ? lockToCurrency : CurrencyEnum.Currency_AUD
            )
          })
        }
      }
    )
  }

  getCurrencyOptions = () => {
    const currencyStr = []
    Object.keys(CurrencyEnum).forEach((key) => {
      currencyStr.push(
        <option key={CurrencyEnum[key]} value={CurrencyEnum[key]}>
          {/* {intl.get(key)} */}
          key
        </option>
      )
    })
    return currencyStr
  }

  onPartChange = (part, element) => {
    const inputValue = element
    const { currency, moneyValue } = this.state
    switch (part) {
      case CURRENCY_PART:
        this.setState(
          {
            currency: inputValue.target.value,
            value: {
              currency: inputValue.target.value,
              value: +moneyValue
            },
            symbolPrefix: getSymbolFromCode(inputValue.target.value)
          },
          () => {
            // notify final-form
            this.props.onChange(this.state.value)
          }
        )
        break
      case VALUE_PART:
        this.setState(
          {
            moneyValue: +inputValue.value,
            value: {
              currency,
              value: +inputValue.value
            }
          },
          () => {
            // notify final-form
            this.props.onChange(this.state.value)
          }
        )
        break
      case VALUE_YEARLY_PART:
        this.setState(
          {
            moneyValue: +inputValue.value / 12,
            value: {
              currency,
              value: +inputValue.value / 12
            }
          },
          () => {
            // notify final-form
            this.props.onChange(this.state.value)
          }
        )
        break

      default:
        break
    }
  }

  calculateYearly = (value) => {
    if (value !== "") {
      return value * 12
    }
    return ""
  }

  render() {
    const {
      name,
      touched,
      error,
      allowNegative,
      disabled,
      decimalSeparator = ".",
      thousandSeparator = ",",
      lockToCurrency = "",
      isTotal = false,
      isfixedDecimalScale = true
    } = this.props
    const { currency, moneyValue, symbolPrefix } = this.state
    const { calculateYearly } = this

    return (
      <React.Fragment>
        {/* <label>
                    {label}{' '}
                    {required && <span className="required-asterix">*</span>}
                </label> */}
        <div className={`cashflow-inp ${isTotal ? "cashflow-inp-total" : ""} `}>
          <div className={`inp-group ${disabled ? "disabled-cashflow" : ""}`}>
            <div>
              <select
                className={`cashflow-select inp-group-item plain-input ${
                  disabled ? "disabled-cashflow" : ""
                }`}
                name={"_currency"}
                value={currency}
                onChange={(e) => this.onPartChange(CURRENCY_PART, e, name)}
                required
                disabled={lockToCurrency !== "" || disabled}
              >
                {this.state.currencyOptions}
              </select>
            </div>
            <NumberFormat
              value={moneyValue}
              displayType={"input"}
              allowNegative={allowNegative}
              disabled={disabled}
              decimalScale={DECIMAL_SCALE}
              // isNumericString={true}
              prefix={symbolPrefix}
              decimalSeparator={decimalSeparator}
              thousandSeparator={thousandSeparator}
              className="inp-group-field plain-input text-right"
              fixedDecimalScale={isfixedDecimalScale}
              onValueChange={(e) => this.onPartChange(VALUE_PART, e, name)}
            />
            {touched && error && (
              <div className="alert currency-alert">{error}</div>
            )}
          </div>
        </div>
        <div className={`cashflow-inp ${isTotal ? "cashflow-inp-total" : ""} `}>
          {" "}
          <div className={`inp-group ${disabled ? "disabled-cashflow" : ""}`}>
            <div>
              <select
                className={`cashflow-select inp-group-item plain-input ${
                  disabled ? "disabled-cashflow" : ""
                }`}
                name={"_currency"}
                value={currency}
                onChange={(e) => this.onPartChange(CURRENCY_PART, e, name)}
                required
                disabled={lockToCurrency !== "" || disabled}
              >
                {this.state.currencyOptions}
              </select>
            </div>
            <NumberFormat
              value={calculateYearly(moneyValue)}
              displayType={"input"}
              allowNegative={allowNegative}
              disabled={disabled}
              decimalScale={DECIMAL_SCALE}
              // isNumericString={true}
              prefix={symbolPrefix}
              decimalSeparator={decimalSeparator}
              thousandSeparator={thousandSeparator}
              className="inp-group-field plain-input text-right"
              onValueChange={(e) =>
                this.onPartChange(VALUE_YEARLY_PART, e, name)
              }
              fixedDecimalScale={isfixedDecimalScale}
            />
            {touched && error && (
              <div className="alert currency-alert">{error}</div>
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }
}
