import axios from "axios"




const customAxiosInstance = axios.create()
const AuthServerApi = {
  post: (url, data = null, config = null) => {
    return customAxiosInstance.post(
      url,
      data,
      config
    )
    
  },

  get: (url, config = null) => {
    return customAxiosInstance.get(
      url,
      config
    )
  
  }
 
}
export default AuthServerApi

