/*
    Author : anton.nuansantio@koizai.com
    Library : FinancialRegulatorApi
    Description : methods related with Financial Regulators
*/

import api from '../lib/api';
// change base route for different entity/domain here
const BASE_ROUTE = '/financial-regulator';

class FinancialRegulatorApi {

    static getById(id) {
        return api.get(`${BASE_ROUTE}/${id}`);
    }
    static resetFromDefault() {
        return api.get(`${BASE_ROUTE}/reset-default`);
    }
  
    static getAvaliableList(countryCode) {
        if(countryCode){
            return api.get(`${BASE_ROUTE}?countryCode=${countryCode}`);
        }else{
            return api.get(`${BASE_ROUTE}`);
        }
        
        
    }

  

    static save(dto) {
        return api.post(`${BASE_ROUTE}`, dto);
        
    }

    static deleteById(id) {
        return api.delete(`${BASE_ROUTE}/${id}`);
        
    }

  
    
}

export default FinancialRegulatorApi;
