/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Library : MalabarDOBDate
    Description : component specific for custom date
*/

import React, { PureComponent } from "react";
import moment from "moment";
import "./style.css";
// import intl from 'react-intl-universal';

const DAY_PART = "DAY";
const MONTH_PART = "MONTH";
const YEAR_PART = "YEAR";

export default class MalabarDOBDate extends PureComponent {
    state = {
        day: "",
        month: "",
        year: "",
        value: this.props.value,
        arrDays: this.createDaysOptions(31)
    };

    getMaxDayOf(year, month) {
        let maxDay = new Date(year, ++month, 0).getDate();
        return maxDay;
    }
    createDaysOptions(max) {
        let result = [];
        let now = new Date();
        let year = now.getFullYear();
        let min = 1;
        result.push(<option key={"none"} value={""}></option>);
        for (let index = min; index <= max; index++) {
            let strIndex = "" + index;
            result.push(
                <option key={strIndex} value={strIndex}>
                    {strIndex}
                </option>
            );
        }
        return result;
    }

    getYearList() {
        let result = [];
        let now = new Date();
        let year = now.getFullYear();
        let min = year - 100;
        let max = year;
        result.push(<option key={"none"} value={""}></option>);
        for (let index = min; index <= max; index++) {
            let strIndex = "" + index;
            result.push(
                <option key={strIndex} value={strIndex}>
                    {strIndex}
                </option>
            );
        }
        return result;
    }

    componentWillMount() {
        this.setComponentState(this.props.value);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setComponentState(nextProps.value);
        }
    }

    setComponentState = valueSource => {
        const dateString = moment(valueSource);

        if (this.props.onlyYear) {
            if (
                valueSource !== "" &&
                valueSource !== null &&
                valueSource !== undefined
            ) {
                if (dateString.isValid()) {
                    this.setState({
                        year: valueSource,
                        value: valueSource
                    });
                }
            }
            return;
        }
        if (
            valueSource !== "" &&
            valueSource !== null &&
            valueSource !== undefined
        ) {
            if (dateString.isValid()) {
                this.setState({
                    day: dateString.format("D"),
                    month: dateString.format("MM"),
                    year: dateString.format("YYYY"),
                    value: this.combineDateParts(
                        dateString.format("D"),
                        dateString.format("MM"),
                        dateString.format("YYYY")
                    )
                });
            }
        } else {
            this.setState({
                day: "",
                month: "0",
                year: "",
                value: null
            });
        }
    };

    combineDateParts = (date, month, year) => {
        if (date === "" && year === "") return undefined;
        if (date === "") {
            return moment({
                years: year,
                months: month,
                date: undefined,
                hours: "0",
                minutes: "0",
                seconds: "0",
                milliseconds: "000"
            }).format("YYYY-MM-DD");
        }
        if (year === "") {
            return moment({
                years: "0000",
                months: month,
                date,
                hours: "0",
                minutes: "0",
                seconds: "0",
                milliseconds: "000"
            }).format("YYYY-MM-DD");
        }
        return moment({
            years: year,
            months: month,
            date,
            hours: "0",
            minutes: "0",
            seconds: "0",
            milliseconds: "000"
        }).format("YYYY-MM-DD");
    };

    onPartChange = (part, element, name, onlyYear) => {
        const inputValue = element.target.value;

        switch (part) {
            case DAY_PART:
                this.setState(
                    {
                        day: inputValue,
                        value: this.combineDateParts(
                            inputValue,
                            this.state.month,
                            this.state.year
                        )
                    },
                    () => {
                        // if not using final-form
                        // this.props.onDateChange(this.state.value, name);
                        // notify final-form
                        this.props.onChange(this.state.value);
                    }
                );
                break;
            case MONTH_PART:
                this.setState(
                    {
                        month: inputValue,
                        value: this.combineDateParts(
                            this.state.day,
                            inputValue,
                            this.state.year
                        ),
                        arrDays: this.createDaysOptions(
                            this.getMaxDayOf(this.state.year, inputValue)
                        )
                    },
                    () => {
                        // if not using final-form
                        // this.props.onDateChange(this.state.value, name);
                        // notify final-form
                        this.props.onChange(this.state.value);
                    }
                );

                break;
            case YEAR_PART:
                if (onlyYear) {
                    this.setState(
                        {
                            year: inputValue,
                            value: inputValue

                            // arrDays: this.createDaysOptions(
                            //     this.getMaxDayOf(inputValue, this.state.month)
                            // )
                        },
                        () => {
                            // if not using final-form
                            // this.props.onDateChange(this.state.value, name);
                            // notify final-form
                            this.props.onChange(this.state.value);
                        }
                    );
                } else
                    this.setState(
                        {
                            year: inputValue,
                            value: this.combineDateParts(
                                this.state.day,
                                this.state.month,
                                inputValue
                            ),
                            arrDays: this.createDaysOptions(
                                this.getMaxDayOf(inputValue, this.state.month)
                            )
                        },
                        () => {
                            // if not using final-form
                            // this.props.onDateChange(this.state.value, name);
                            // notify final-form
                            this.props.onChange(this.state.value);
                        }
                    );

                break;

            default:
                break;
        }
    };

    render() {
        const {
            name,
            touched,
            error,
            disabled,
            dropdowncss = "",
            noPadding,
            onlyYear
        } = this.props;

        return (
            <React.Fragment>
                {!onlyYear ? (
                    <div className="datepicker-container">
                        <div
                            className={
                                `${noPadding &&
                                    "noPadding"} select-box month-box${dropdowncss}` ||
                                ""
                            }
                        >
                            <select
                                value={this.state.day}
                                name={"_day"}
                                onChange={e =>
                                    this.onPartChange(DAY_PART, e, name)
                                }
                                disabled={disabled}
                                // required
                            >
                                {this.state.arrDays}
                            </select>
                        </div>

                        <div
                            className={
                                `${noPadding &&
                                    "noPadding"} select-box month-box${dropdowncss}` ||
                                ""
                            }
                        >
                            <select
                                name={"_month"}
                                value={this.state.month}
                                onChange={e =>
                                    this.onPartChange(MONTH_PART, e, name)
                                }
                                // required
                                disabled={disabled}
                            >
                                <option value="0">JAN</option>
                                <option value="1">FEB</option>
                                <option value="2">MAR</option>
                                <option value="3">APR</option>
                                <option value="4">MAY</option>
                                <option value="5">JUN</option>
                                <option value="6">JUL</option>
                                <option value="7">AUG</option>
                                <option value="8">SEP</option>
                                <option value="9">OCT</option>
                                <option value="10">NOV</option>
                                <option value="11">DEC</option>
                            </select>
                        </div>

                        <div
                            className={
                                `${noPadding &&
                                    "noPadding"} select-box month-box${dropdowncss}` ||
                                ""
                            }
                        >
                            <select
                                value={this.state.year}
                                name={"_year"}
                                onChange={e =>
                                    this.onPartChange(YEAR_PART, e, name)
                                }
                                disabled={disabled}
                                // required
                            >
                                {this.getYearList()}
                            </select>
                        </div>
                    </div>
                ) : (
                    <div
                        className={
                            `${noPadding &&
                                "noPadding"} select-box month-box${dropdowncss}` ||
                            ""
                        }
                    >
                        <select
                            value={this.state.year}
                            name={"_year"}
                            onChange={e =>
                                this.onPartChange(YEAR_PART, e, name, onlyYear)
                            }
                            disabled={disabled}
                            // required
                        >
                            {this.getYearList()}
                        </select>
                    </div>
                )}
                {touched && error && <div className="alert">{error}</div>}
            </React.Fragment>
        );
    }
}
