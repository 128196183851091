import { superAnnuationFundAction } from '../../../constants/ActionConstants';

export function superAnnuationFund(state = {}, action) {
    switch (action.type) {
        case superAnnuationFundAction.GET_SUPERANNUATIONFUND_REQUEST:
            return {
                loading: true
            };
        case superAnnuationFundAction.GET_SUPERANNUATIONFUND_SUCCESS:
            return {
                items: action.data
            };
        case superAnnuationFundAction.GET_SUPERANNUATIONFUND_FAILURE:
            return {
                error: action.error
            };
        case superAnnuationFundAction.ADD_SUPERANNUATIONFUND_REQUEST:
            return {
                loading: true
            };
        case superAnnuationFundAction.ADD_SUPERANNUATIONFUND_SUCCESS:
            return {
                items: action.response
            };
        case superAnnuationFundAction.ADD_SUPERANNUATIONFUND_FAILURE:
            return {
                error: action.error
            };
        case superAnnuationFundAction.EDIT_SUPERANNUATIONFUND_REQUEST:
            return {
                loading: true
            };
        case superAnnuationFundAction.EDIT_SUPERANNUATIONFUND_SUCCESS:
            return {
                items: action.response
            };
        case superAnnuationFundAction.EDIT_SUPERANNUATIONFUND_FAILURE:
            return {
                error: action.error
            };
        case superAnnuationFundAction.DELETE_SUPERANNUATIONFUND_REQUEST:
            return {
                loading: true
            };
        case superAnnuationFundAction.DELETE_SUPERANNUATIONFUND_SUCCESS:
            return {
                items: action.response
            };
        case superAnnuationFundAction.DELETE_SUPERANNUATIONFUND_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
