import api from '../../../lib/api';

// change base route for different entity/domain here
const BASE_ROUTE = '/client/';

class NetWorthApi {
    static getClient(id) {
        return api.get(BASE_ROUTE + id);
    }

    static getClientCashAccounts(id) {
        return api.get(`${BASE_ROUTE}${id}/cash_accounts`);
    }
}

export default NetWorthApi;
