import { isNullOrUndefined } from 'util';
import { getDirectVariableValue } from './GetDirectValue';
import { countFromArray } from './CountFromArray';

export const foodAndDining = (values, currency) => {
    if (!isNullOrUndefined(values)) {
        const {
            alcoholAndBars,
            coffeeShops,
            fastFood,
            groceries,
            restaurants,
            others,
        } = values;

        // count direct variables
        const countAlcoholBar = getDirectVariableValue(
            alcoholAndBars,
            currency
        );
        const countCoffee = getDirectVariableValue(coffeeShops, currency);
        const countFastFood = getDirectVariableValue(fastFood, currency);
        const countGroceries = getDirectVariableValue(groceries, currency);
        const countRestaurants = getDirectVariableValue(restaurants, currency);
        const countOthers = countFromArray(others, currency);
        return {
            clientTotal:
                countAlcoholBar.clientTotal +
                countCoffee.clientTotal +
                countFastFood.clientTotal +
                countGroceries.clientTotal +
                countRestaurants.clientTotal +
                countOthers.clientTotal,
            partnerTotal:
                countAlcoholBar.partnerTotal +
                countCoffee.partnerTotal +
                countFastFood.partnerTotal +
                countGroceries.partnerTotal +
                countRestaurants.partnerTotal +
                countOthers.partnerTotal,
            jointTotal:
                countAlcoholBar.jointTotal +
                countCoffee.jointTotal +
                countFastFood.jointTotal +
                countGroceries.jointTotal +
                countRestaurants.jointTotal +
                countOthers.jointTotal,
        };
    }
    return {
        clientTotal: 0,
        partnerTotal: 0,
        jointTotal: 0,
    };
};
