import React from 'react';
import intl from 'react-intl-universal';
import { Form, Field } from 'react-final-form';
import { ModalNoAccess, RenderSimpleFields, FormButtons, ClassificationSelection } from '../components';
import { FieldType } from '../components/Form/RenderSimpleFields';
import { renderJointSelectionMenuCustom } from '../helpers';

const TermDepositPersonalIncome = ({
    handleSave,
    model,
    optionsData,
    readOnlyAccess,
    clientInformation: { client = {}, partner = {}, isJoint = {} },
}) =>
    !readOnlyAccess ? (
        <Form
            onSubmit={handleSave}
            initialValues={model}
            render={({ handleSubmit, reset }) => (
                <form
                    id="TermDepositPersonalIncomeForm"
                    onSubmit={handleSubmit}
                >
                    <div className="portal-form-popup">
                       <ClassificationSelection/>
                        <div className="subtitle2">
                            {intl.get('termDeposit.subtitle')}
                        </div>
                        <RenderSimpleFields
                            fields={[
                                {
                                    name: 'financialInstitutionName',
                                    label: intl.get(
                                        'field.financialInstitutionName'
                                    ),
                                    type: FieldType.TEXT,
                                    required: true,
                                },
                                {
                                    name: 'accountNumber',
                                    label: intl.get('field.uniqueCode'),
                                    type: FieldType.TEXT,
                                    required: true,
                                },
                                {
                                    name: 'accountName',
                                    label: intl.get('field.accountName'),
                                    type: FieldType.TEXT,
                                },
                               
                                {
                                    name: 'termInterestRate',
                                    label: intl.get('field.termInterestRate'),
                                    type: FieldType.PERCENTAGE,
                                },
                                {
                                    name: 'termStartDate',
                                    label: intl.get('field.termStartDate'),
                                    type: FieldType.DATE,
                                },
                                {
                                    name: 'termEndDate',
                                    label: intl.get('field.termEndDate'),
                                    type: FieldType.DATE,
                                },
                                {
                                    name: 'accountBalance',
                                    label: intl.get('field.accountBalance'),
                                    type: FieldType.MONEY,
                                },
                                {
                                    name: 'accountCurrency',
                                    label: intl.get('field.accountCurrency'),
                                    type: FieldType.ENUM_SELECTION,
                                    enumData: optionsData.currencyOptions,
                                },
                            ]}
                        />
                    </div>
                    <FormButtons
                        reset={reset}
                        readOnlyAccess={readOnlyAccess}
                    />
                </form>
            )}
        />
    ) : (
        <ModalNoAccess />
    );
export default TermDepositPersonalIncome;
