import React, { useState, useEffect } from "react"
import intl from "react-intl-universal"
import { CurrencyEnum } from "../../constants/Enums"

export const getCurrencyOptions = () => {
  const currencyStr = []
  Object.keys(CurrencyEnum).forEach((key) => {
    currencyStr.push(
      <option key={CurrencyEnum[key]} value={CurrencyEnum[key]}>
        {CurrencyEnum[key]}
      </option>
    )
  })
  return currencyStr
}

const ReportingCurrency = ({
  onChange,
  lockToCurrency = "",
  disabled = false,
  defaultCurrency = CurrencyEnum.Currency_USD,
  currentCurrency = undefined
}) => {
  const [currencyOptions] = useState(getCurrencyOptions())
  const [currency, setCurrency] = useState(defaultCurrency)

  useEffect(() => {
    if (currentCurrency !== null) {
      setCurrency(currentCurrency)
    }
  }, [currentCurrency])

  useEffect(() => {
    if (defaultCurrency !== null && !currentCurrency) {
      setCurrency(defaultCurrency)
    }
  }, [defaultCurrency])

  const onCurrencyChange = (element) => {
    const { value } = element.target
    setCurrency(value)
    onChange(value)
  }
  return (
    <div className="reporting-currency reporting-currency-ekyc-insurance">
      <div className="title reporting-currency-title">
        {intl.get("field.reportingCurrency")}
      </div>
      <div className="currency">
        <select
          className={`cashflow-select inp-group-item plain-input ${
            disabled ? "disabled-cashflow" : ""
          }`}
          name={"_currency"}
          value={currency}
          onChange={(e) => onCurrencyChange(e)}
          required
          disabled={lockToCurrency !== "" || disabled}
        >
          {currencyOptions}
        </select>
      </div>
    </div>
  )
}

export default ReportingCurrency
