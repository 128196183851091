function Get(obj, key) {
    return key
        .split('.')
        .reduce(
            (o, x) => (typeof o === 'undefined' || o === null ? o : o[x]),
            obj
        );
}

export default Get;
