import React, { useEffect, useState } from "react"
// import { getRandomColors } from "../../../../helpers/ColorHelper"
import { DefaultPieChartLegendsOptions } from "../../../../helpers/ChartHelper"
import { Doughnut } from "react-chartjs-2"
import { numberFormat } from "../../../../helpers"
const InvestmentPorfolioAnalysis = ({ portfolioMap }) => {
  const colors = [
    "rgba(69,114,199,1)",
    "rgba(249, 190, 66, 1)",
    "rgba(0, 149, 255, 0.8)",
    "rgba(242,46,46,1)",
    "rgba(238,124,55,1)",
    "rgba(119, 180, 173, 1)"
  ]
  // const [colorsList, setcolorsList] = useState([])
  // useEffect(() => {
  //   setcolorsList(colorsList)
  // }, [colorsList])

  const [chartData, setChartData] = useState()
  useEffect(() => {
    if (portfolioMap) {
      let chartDatum = {
        labels: Object.keys(portfolioMap),
        datasets: [
          { data: Object.values(portfolioMap), backgroundColor: colors }
        ]
      }
      setChartData(chartDatum)
    } else {
      let chartDatum = {
        labels: [""],
        datasets: [{ data: [0], backgroundColor: ["#D3D3D3"] }]
      }
      setChartData(chartDatum)
    }
  }, [portfolioMap])

  return (
    <div>
      <h1 className="mt-1 mb-3 subtitle2">Invesment Summary</h1>
      <div className="portfolio-stocks">
        <div className="keys-section">
          {portfolioMap &&
            Object.keys(portfolioMap).map((item, idx) => {
              return (
                <div className="key" key={idx}>
                  <div
                    className="dot"
                    style={{ background: colors[idx] }}
                  ></div>
                  <p>{item}</p>
                </div>
              )
            })}
        </div>
        <div className="values-section">
          {portfolioMap &&
            Object.values(portfolioMap).map((item, idx) => {
              return (
                <div key={idx} className="key values-section">
                  {item}
                </div>
              )
            })}
        </div>
      </div>
      <h1 className="my-3 subtitle2">Asset Allocation Summary</h1>
      <div style={{ width: 200, height: 200 }}>
        {chartData ? (
          <Doughnut
            data={chartData}
            width={200}
            height={200}
            legend={DefaultPieChartLegendsOptions}
            options={{
              tooltips: {
                enabled: chartData ? true : false,
                callbacks: {
                  label(tooltipItem, data) {
                    return (
                      data.labels[tooltipItem.index] +
                      numberFormat(
                        data.datasets[tooltipItem.datasetIndex].data[
                          tooltipItem.index
                        ]
                      )
                    )
                  }
                } // end callbacks:
              }
            }}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default InvestmentPorfolioAnalysis
