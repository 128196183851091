import React, { useState, useEffect, useContext } from "react"
import { Form, Field, FormSpy } from "react-final-form"
import arrayMutators from "final-form-arrays"
import intl from "react-intl-universal"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { readOnlyAccess } from "../../helpers"
import { MalabarDefaultRightMenu } from "../../components"
import { clientDataAdapter } from "../../helpers/ClientHelper"
import api from "../../lib/api"
import { ConfirmationDialog } from "../../components"
import { ScrollUpButton } from "../../components"
import FianancialPlaningScope from "./_component/FianancialPlaningScope"
import PersonalInformation from "./_component/PersonalInformation"
import ResidencyDetail from "./_component/ResidencyDetails"
import ClientCategory from "./_component/ClientCategory"
import createDecorator from "final-form-calculate"
import PlanningClassification from "./_component/PlanningClassification"
import EmploymentDetails from "./_component/EmploymentDetails"
import DependentDetails from "./_component/DependentDetails"
import PersonalDetailsContext from "./PersonalDetailsContext"
import {
  emptyEntityState,
  entityActions,
  getClientIdSuccess
} from "../../actions/EntityActions"
import { AppContext } from "../../context/AppContext"
import { userActions } from "../../actions/UserActions"
import ContactDetail, {
  ContactDetailMutators
} from "./_component/ContactDetail"
import Alert from "react-s-alert"
import Separate from "./Separate"
import NotificationApi from "../../api/NotificationApi"
import { useDispatch } from "react-redux"
import {
  getPersonalDetails,
  getPersonalDetailsSuccess,
  personalDetailsActions
} from "../../actions"
export const removeNullValue = (values) => {
  const model = values
  if (values && values.personalDetails) {
    if (
      Array.isArray(
        values.personalDetails.countriesOverseasAssetsAndLiabilities
      ) &&
      values.personalDetails.countriesOverseasAssetsAndLiabilities.length > 0
    ) {
      model.personalDetails.countriesOverseasAssetsAndLiabilities =
        values.personalDetails.countriesOverseasAssetsAndLiabilities.filter(
          (a) => a
        )
    }
    if (
      Array.isArray(values.personalDetails.countriesOverseasPensionSchemes) &&
      values.personalDetails.countriesOverseasPensionSchemes.length > 0
    ) {
      model.personalDetails.countriesOverseasPensionSchemes =
        values.personalDetails.countriesOverseasPensionSchemes.filter((a) => a)
    }
    if (
      Array.isArray(
        values.personalDetails.countriesOverseasInsurancePolicies
      ) &&
      values.personalDetails.countriesOverseasInsurancePolicies.length > 0
    ) {
      model.personalDetails.countriesOverseasInsurancePolicies =
        values.personalDetails.countriesOverseasInsurancePolicies.filter(
          (a) => a
        )
    }
    if (
      Array.isArray(
        values.personalDetails
          .countriesOverseasGovernmentEducationAssistanceLoans
      ) &&
      values.personalDetails.countriesOverseasGovernmentEducationAssistanceLoans
        .length > 0
    ) {
      model.personalDetails.countriesOverseasGovernmentEducationAssistanceLoans =
        values.personalDetails.countriesOverseasGovernmentEducationAssistanceLoans.filter(
          (a) => a
        )
    }
  }

  return model
}
export const PersonalDetailsMutators = {
  unsetPersonalDetails: (args, state, utils) => {
    utils.changeValue(state, "client", () => ({
      classification: "SINGLE"
    }))
  }
}
function adjustIncomingClientData(client) {
  const adjustedClient = clientDataAdapter(client)
  const { personalDetails } = adjustedClient

  if (personalDetails) {
    const { grandparents, parents, parentsInLaw } = personalDetails
    adjustedClient.personalDetails.parents = [
      ...(grandparents || []),
      ...(parents || []),
      ...(parentsInLaw || [])
    ]
  }
  return adjustedClient
}
function adjustOutGoingClientData(client) {
  let result = client
  const { personalDetails } = client //20210106 get password

  if (personalDetails) {
    const {
      residencyDetails = {},
      parents = [],
      personalInformation = {}
    } = personalDetails
    const { ...rest } = residencyDetails
    const { status } = personalInformation
    const allParent =
      parents &&
      parents.length &&
      parents.map((parent) => ({
        ...parent,
        relationship: parent.dependantType
      }))

    result = {
      ...client,
      password: personalDetails.password ? personalDetails.password : "",
      giveAccessClientLogin: personalDetails.password == true ? true : false,
      personalDetails: {
        ...client.personalDetails,
        residencyDetails: {
          ...rest,
          citizenship: {
            country: (residencyDetails && residencyDetails.country) || "",
            passportID: (residencyDetails && residencyDetails.passportID) || ""
          }
        },
        grandparents:
          allParent &&
          allParent.length &&
          allParent.filter((parent) => parent.dependantType === "GRAND_PARENT"),
        parents:
          allParent &&
          allParent.length &&
          allParent.filter((parent) => parent.dependantType === "PARENT"),
        parentsInLaw:
          allParent &&
          allParent.length &&
          allParent.filter((parent) => parent.dependantType === "PARENT_IN_LAW")
      },
      status
    }
  }
  return result
}
function FormContent({
  mode,
  clientType,
  refresh,
  values,
  onLoadInitialization
}) {
  const {
    modal: { setModal, clearModal }
  } = useContext(AppContext)
  const dispatch = useDispatch()
  const {
    classification,
    client,
    partner = {}
  } = useContext(PersonalDetailsContext)
  function onSeparateDialogModalClosed() {
    clearModal()
    // refresh()
    dispatch(getPersonalDetails(values.client.id))
    onLoadInitialization()
  }

  function showSpecialTreatment() {
    setModal({
      title: `Couple Changes`,
      content: (
        <Separate
          client={client}
          partner={partner}
          clearModal={onSeparateDialogModalClosed}
          clearModalOnly={clearModal}
        />
      ),
      isSmall: true
    })
  }

  const getLastName = (personalDetails) => {
    let name = intl.get("general.clientName")

    if (personalDetails) {
      const { personalInformation = {} } = personalDetails
      name = `${personalInformation.familyName || ""},`
    }
    return name
  }

  const getFirstName = (personalDetails) => {
    let name = ""

    if (personalDetails) {
      const { personalInformation = {} } = personalDetails
      name = ` ${personalInformation.firstName || ""}`
    }
    return name
  }

  const isJoint = classification === "JOINT"
  const loginEntityType = localStorage.getItem("loginEntityType")

  return (
    <React.Fragment>
      <Field name="client.classification" component={FianancialPlaningScope} />

      <div
        id="scroll-section"
        className="portal-form portal-form-personalDetails mt-2"
      >
        {/* Header */}
        <div className="content-panel-title d-flex">
          <div className="title-box personalDetails">
            {getLastName(client.personalDetails)}
            <br />
            {getFirstName(client.personalDetails)}
          </div>
          {classification === "JOINT" && (
            <div className="title-box personalDetails d-flex align-item-center">
              {getLastName(partner.personalDetails)}
              <br />
              {getFirstName(partner.personalDetails)}
            </div>
          )}
        </div>

        {isJoint &&
          client &&
          client.id &&
          partner &&
          partner.id &&
          loginEntityType != "CLIENT" && (
            <div>
              <span
                className="portal-btn portal-btn-submit fa fa-edit"
                onClick={(e) => showSpecialTreatment()}
              >
                &nbsp;
                <b>Couple Changes</b>
              </span>
            </div>
          )}

        {mode !== "?DEMO_CLIENT" && (
          <ClientCategory
            client={client}
            partner={partner}
            clientType={clientType}
          />
        )}

        <PersonalInformation client={client} partner={partner} />

        {/* <ReligionDetails /> */}
        <ResidencyDetail
          isJoint={isJoint}
          client={client}
          partner={partner}
          values={values}
          classification={classification}
        />
        <PlanningClassification
          client={client}
          partner={partner}
          isJoint={isJoint}
        />
        <EmploymentDetails client={client} partner={partner} values={values} />
        <ContactDetail isJoint={isJoint} client={client} partner={partner} />
        <DependentDetails client={client} partner={partner} isJoint={isJoint} />
      </div>
    </React.Fragment>
  )
}

function PersonalDetails({ match, history, location, showOnlyRequiredFields }) {
  const dispatch = useDispatch()
  useEffect(() => {
    if (
      localStorage.getItem("loginEntityType") !== "BUILT_IN_ADVISER" &&
      !match.params.id
    )
      history.push("/notfound")
  }, [])

  const decorator = createDecorator(
    {
      field: "partner.sameAddressAsClient",
      updates: {
        "partner.personalDetails.primaryAddress": (fooValue, allValues) => {
          if (fooValue) {
            return {
              ...allValues.client.personalDetails.primaryAddress
            }
          } else {
            return {}
          }
        }
      }
    },
    {
      field: "client.status",
      updates: {
        "partner.status": (fooValue, allValues) => {
          if (fooValue && allValues.client.classification === "JOINT") {
            return fooValue
          } else {
            return undefined
          }
        }
      }
    },

    {
      field: "client.classification",
      updates: {
        "partner.status": (fooValue, allValues) => {
          if (fooValue && allValues.client.classification === "JOINT") {
            return allValues.client.status
          } else {
            return undefined
          }
        }
      }
    },
    {
      field: "partner.samePostalAddressAsClient",
      updates: {
        "partner.personalDetails.postalAddress": (fooValue, allValues) => {
          if (fooValue) {
            return {
              ...allValues.client.personalDetails.postalAddress
            }
          } else {
            return {}
          }
        }
      }
    },
    {
      field: "partner.sameOtherAddressAsClient",
      updates: {
        "partner.personalDetails.otherAddresses": (fooValue, allValues) => {
          if (fooValue) {
            return {
              ...allValues.client.personalDetails.otherAddresses
            }
          } else {
            return {}
          }
        }
      }
    }
  )

  const { id: clientId } = match.params || {}
  const [mode, setMode] = useState(location.search)

  const [state, setState] = useState({
    client: {},
    partner: {}
  })

  const [show, setShow] = useState(false)

  const onLoadInitialization = () => {
    if (clientId) {
      localStorage.setItem("activeClientId", clientId)
      api
        .get(`/client/personal-details/${clientId}`)
        .then((getClientResponse) => {
          if (getClientResponse) {
            const client = adjustIncomingClientData(
              getClientResponse.data.client
            )
            if (
              client.classification === "JOINT" &&
              getClientResponse.data.partner
            ) {
              const partner = adjustIncomingClientData(
                getClientResponse.data.partner
              )

              setState((prevState) => ({
                ...prevState,
                client,
                partner
              }))
            } else {
              setState((prevState) => ({
                ...prevState,
                client
              }))
            }
          }
        })
    } else {
      setState((prevState) => ({
        ...prevState,
        client: {
          classification: "SINGLE",
          adviserId: localStorage.getItem("activeEntityId") || null,
          personalDetails: {
            residencyDetails: {
              identities: [
                {
                  idType: "NATIONAL_ID",
                  idCountry: "",
                  idNumber: "",
                  documentId: "",
                  idIssuedDate: "",
                  idExpiredDate: ""
                }
              ]
            }
          }
        }
      }))
    }
  }

  // get adviser ID for client and partner
  const clickHandler1 = () => {
    setShow(false)
  }

  const clearData = () => {
    setShow(true)
  }

  const handleSave = async (values) => {
    if (mode === "?DEMO_CLIENT") {
      values.client.status = "DEMO_CLIENT"
      if (values.partner) {
        values.partner.status = "DEMO_CLIENT"
      }
    }

    let modelNew
    values.client.id = clientId ? clientId : null
    values.client.adviserId = localStorage.getItem("activeEntityId")

    if (values.client.classification == "JOINT" && values.partner) {
      if (!values.partner.classification && values.client.classification) {
        values.partner.classification = values.client.classification
      }
      modelNew = {
        client: {
          ...removeNullValue(values.client)
        },
        partner: {
          ...model.partner,
          ...removeNullValue(values.partner)
        },
        newData: clientId ? false : true
      }
    } else {
      modelNew = {
        client: {
          ...removeNullValue(values.client)
        },
        newData: clientId ? false : true
      }
    }

    const client = adjustOutGoingClientData(modelNew.client)
    client.partnerOfClient = false
    await api.post(`/client/personal-details/save`, modelNew).then((res) => {
      dispatch(
        getClientIdSuccess(
          res.data.client,
          res.data.partner ? res.data.partner : undefined
        )
      )
      if (values.client.classification == "JOINT") {
        dispatch(
          getPersonalDetailsSuccess({
            ...res.data.client,
            partner: res.data.partner
          })
        )
      } else {
        dispatch(getPersonalDetailsSuccess(res.data.client))
      }
      handleAfterSave(res, modelNew)
    })
  }

  const handleAfterSave = (response, modelNew) => {
    setState({
      client: response.data.client,
      partner: response.data.partner ? response.data.partner : undefined
    })
    let showWarning = NotificationApi.checkAndDisplayWarning()
    if (!showWarning) {
      Alert.success("Data has been saved successfully!")
    }

    if (modelNew.newData) {
      if (mode === "?DEMO_CLIENT") {
        history.push(`/personaldetails/${response.data.client.id}?DEMO_CLIENT`)
      } else {
        history.push(`/personaldetails/${response.data.client.id}`)
      }
    }
  }

  const readOnlyAccessComponent = () => {
    const { activeAccessLevel, componentName } = {} // TODO implement this
    if (activeAccessLevel !== undefined && activeAccessLevel !== null) {
      return readOnlyAccess(activeAccessLevel, componentName, false)
    }
  }

  useEffect(() => {
    onLoadInitialization()
    dispatch(emptyEntityState())
  }, [])

  const model = {
    client: state.client,
    partner: state.partner
  }

  const setMutators = (am) => ({
    ...PersonalDetailsMutators,
    ...ContactDetailMutators,
    arrayPush: am.push,
    arrayRemove: am.remove,
    ...arrayMutators
  })

  const fieldName = "client.personalDetails"
  return (
    <>
      <div
        className="portal-maincontent personaldetails-2col"
        id="top-personal_details"
      >
        <div className="content-panel personaldetails">
          <Form
            onSubmit={handleSave}
            initialValues={model}
            keepDirtyOnReinitialize={true}
            decorators={[decorator]}
            mutators={setMutators(arrayMutators)}
            render={({ handleSubmit, form, dirty, values }) => (
              <>
                <form id="personalDetailsForm" onSubmit={handleSubmit}>
                  <ConfirmationDialog
                    isOpen={show}
                    onClose={clickHandler1}
                    onYes={form.mutators.unsetPersonalDetails}
                    message={intl.get("clear.areyousure")}
                  />
                  <FormSpy subscription={{ values: true }}>
                    {({ values }) => (
                      <PersonalDetailsContext.Provider
                        value={{
                          fieldName,
                          mutators: form.mutators,
                          classification: values.client.classification,
                          client: values.client,
                          partner: values.partner
                        }}
                      >
                        <FormContent
                          mode={mode}
                          clientType={values.client && values.client.status}
                          refresh={onLoadInitialization}
                          values={values}
                          onLoadInitialization={onLoadInitialization}
                          // id={values.client.id}
                        />
                      </PersonalDetailsContext.Provider>
                    )}
                  </FormSpy>
                </form>
              </>
            )}
          />
          {/* <ScrollUpButton sectionId="scroll-section" /> */}
        </div>
        <MalabarDefaultRightMenu
          readOnlyAccess={readOnlyAccessComponent()}
          onSaveClick={() => {
            document
              .getElementById("personalDetailsForm")
              .dispatchEvent(new Event("submit", { cancelable: true }))
          }}
          onClearClick={clearData}
          enableClearButton={state.client && !state.client.id}
          clientDetails={model}
          enableNoteButton={match.params.id ? true : false}
        />
      </div>
    </>
  )
}

// export default PersonalDetails;

function mapStateToProps(state) {
  return {
    entityType: state.modalWindow.entityType,
    activeAccessLevel: state.appSettings,
    showOnlyRequiredFields: state.users.onlyRequiredFields
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, entityActions, userActions),
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetails)
