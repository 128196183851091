import React from "react"
import ReactModal from "react-modal"

const MalabarDialog = ({
  title,
  isOpen,
  onClose,
  children,
  extraOverlayClassName = "",
  formType,
  isLarge,
  large
}) => (
  <ReactModal
    isOpen={isOpen}
    className={`malabar-modal${isLarge ? "-large" : ""}${
      large ? " large-modal" : ""
    } `}
    overlayClassName={`malabar-modal-overlay ${extraOverlayClassName}`}
    shouldCloseOnEsc
    ariaHideApp={false}
    onRequestClose={onClose}
  >
    <div className={`popup ${formType}`}>
      <div className="container-fluid">
        <div className="">
          <div className="page-title">
            {title}
            <div className="close-holder">
              <span onClick={onClose} className="close-button" />
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>
  </ReactModal>
)

export default MalabarDialog
