/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Description : net worth main page
*/

import React, { Component, useMemo, useState } from "react"
import { connect, useSelector } from "react-redux"
import { bindActionCreators } from "redux"
import intl from "react-intl-universal"
import NumberFormat from "react-number-format"
import { Doughnut, Bar } from "react-chartjs-2"
import {
  enumOptionsActions,
  modalWindowActions,
  netWorthActions,
  appSettingsActions,
  personalDetailsActions
} from "../../actions"
import {
  ArrowMenu,
  MalabarDefaultRightMenu,
  ReportingCurrency
} from "../../components"
import ConfirmationDialog from "../../components/ConfirmationDialog/ConfirmationDialog"
import LoadingSpinner from "../../helpers/LoadingSpinner"
import PersonalAssets from "./PersonalAssets"
import LiquidAssets from "./LiquidAssets"
import IlliquidAssets from "./IlliquidAssets"
import RetirementAssets from "./RetirementAssets"
import Liabilities from "./Liabilities"
import GovernmentEducationAssistanceLoans from "./GovernmentEducationAssistanceLoans"
import Taxation from "./Taxation"
import EmploymentTerminationPayments from "./EmploymentTerminationPayments"
import TotalNetWorthSummary from "./TotalNetWorthSummary"
import NetWorthListingFilter from "./NetWorthListingFilter"
import { getPrefixedColors } from "../../helpers/ColorHelper"
import { DECIMAL_SCALE } from "../../constants/NumberFormatConstants"
import { DefaultBarChartOptions } from "../../helpers/ChartHelper"
import { getCurrency } from "../../helpers/CurrencyConversion"
import Get from "../../helpers/NullHelper"
import { getSymbolFromCode } from "../../helpers"
import { numberWithCommas } from "../../helpers/CurrencyConversion"
import {
  DefaultPieChartLegendsOptions,
  PieLegendsForcedLeftOptions
} from "../../helpers/ChartHelper"
import RiskClassificationSummary from "./components/RiskClassificationSummary"
import AumSummary from "./components/AumSummary"

function ClassificationSummary({
  totalSummary = {},
  summaryType,
  currency,
  currencySymbol
}) {
  let asset = 0
  let liability = 0
  if (summaryType === "overview") {
    const { client = {}, partner = {}, joint = {} } = totalSummary

    asset = [client, partner, joint]
      .map((item) => item.assets || 0)
      .reduce((accumulator, currentValue) => {
        // Check if the value is not undefined and is a number before adding it
        if (typeof currentValue === "number" && !isNaN(currentValue)) {
          return accumulator + currentValue
        } else {
          return accumulator
        }
      }, 0)
    liability = [client, partner, joint]
      .map((item) => item.liabilities || 0)
      .reduce((accumulator, currentValue) => {
        // Check if the value is not undefined and is a number before adding it
        if (typeof currentValue === "number" && !isNaN(currentValue)) {
          return accumulator + currentValue
        } else {
          return accumulator
        }
      }, 0)
  } else if (
    summaryType === "client" ||
    summaryType === "partner" ||
    summaryType === "joint"
  ) {
    asset = totalSummary[summaryType].assets || 0
    liability = totalSummary[summaryType].liabilities || 0
  }

  const data = {
    asset,
    liability,
    netAsset: asset - liability
  }

  function number_format(value, currency, currencySymbol) {
    return ` ${currencySymbol}${numberWithCommas(Math.ceil(value))}`
  }

  // const { red, green, blue } = commonColors();
  const chartData = {
    labels: [
      intl.get("netWorth.summary.chartLabel.asset"),
      intl.get("netWorth.summary.chartLabel.liability"),
      intl.get("netWorth.summary.chartLabel.net")
    ],
    datasets: [
      {
        data: [data.asset, data.liability, data.netAsset],
        backgroundColor: [
          "rgb(69, 114, 199)",
          "rgb(255, 166, 0)",
          data.netAsset <= 0 ? "rgb(242,46,46)" : "rgb(144,197,68)"
        ]
      }
    ]
  }

  let amount = 0

  return (
    <React.Fragment>
      <div className="graph-holder">
        <Bar
          data={chartData}
          height={300}
          options={{
            ...DefaultBarChartOptions, //20200616 MABT-359
            maintainAspectRatio: true,
            tooltips: {
              callbacks: {
                label(tooltipItem, data) {
                  // 20200601
                  return number_format(
                    data.datasets[tooltipItem.datasetIndex].data[
                      tooltipItem.index
                    ].toFixed(0),
                    currency,
                    currencySymbol
                  )
                }
              }
            },

            scales: {
              xAxes: [
                {
                  // 20200821 MABT-399
                  gridLines: {
                    display: true,
                    drawBorder: true,
                    drawOnChartArea: false
                  }
                }
              ],

              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    callback(value) {
                      // if (
                      //     parseInt(value) >= 1000 ||
                      //     parseInt(value) <= -1000
                      // ) {
                      value =
                        value <= 1 && parseInt(value) > 1 ? value * 1000 : value

                      amount = `${value
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`

                      return currencySymbol + amount
                    }
                  },
                  gridLines: {
                    display: true,
                    drawBorder: true,
                    drawOnChartArea: false
                  }
                }
              ]
            },

            legend: {
              display: false
            }
          }}
        />
      </div>
      <div className="graph-summary">
        <div className="subtitle">
          {intl.get("netWorth.assetsLiabilitiesSummary")}
        </div>
        <div className="g-summary-box">
          <div className="text">
            {intl.get("netWorth.assetsLiabilitiesSummary.assets")}
          </div>
          <div className="figure">
            <NumberFormat
              value={data.asset}
              displayType={"text"}
              thousandSeparator={","}
              decimalScale={DECIMAL_SCALE}
              decimalSeparator={"."}
              prefix={currencySymbol}
            />
          </div>
        </div>
        <div className="g-summary-box">
          <div className="text">
            {intl.get("netWorth.assetsLiabilitiesSummary.liabilities")}
          </div>
          <div className="figure">
            <NumberFormat
              value={data.liability}
              displayType={"text"}
              thousandSeparator={","}
              decimalScale={DECIMAL_SCALE}
              decimalSeparator={"."}
              prefix={currencySymbol}
            />
          </div>
        </div>
        <div className="g-summary-box g-summary-box-line m-0 p-0 d-flex">
          <div className="text"></div>
          <div className="figure">
            <div className="total-line" />
          </div>
        </div>
        <div className="g-summary-box">
          {/* <hr /> */}
          <div className="text">
            {intl.get("netWorth.assetsLiabilitiesSummary.netAssets")}
          </div>
          <div className="figure">
            <NumberFormat
              value={data.netAsset}
              displayType={"text"}
              thousandSeparator={","}
              decimalScale={DECIMAL_SCALE}
              decimalSeparator={"."}
              prefix={currencySymbol}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
// totalSummary,
function AssetSummary({
  listings = {},
  summaryType,
  currency,
  currencySymbol,
  totalSummary
}) {
  let data = []
  if (summaryType === "assetClass") {
    data = Object.keys(listings)
      .filter((item) => item.endsWith("AssetsListing"))
      .map((key) => {
        const value = Object.keys(listings[key] || {})
          .map((subKey) => listings[key][subKey] || [])
          .flatMap(
            (items) =>
              items &&
              items.length &&
              items.map((item) => {
                return item.assets || 0
              })
          )
          .reduce((accumulator, currentValue) => {
            // Check if the value is not undefined and is a number before adding it
            if (typeof currentValue === "number" && !isNaN(currentValue)) {
              return accumulator + currentValue
            } else {
              return accumulator
            }
          }, 0)
        return { key, value }
      })
  } else if (summaryType === "liabilityClass") {
    data = Object.keys(listings)
      .filter((item) => !item.endsWith("AssetsListing"))
      .map((key) => {
        const value = Object.keys(listings[key] || {})
          .map((subKey) => listings[key][subKey] || [])
          .flatMap(
            (items) =>
              items.length && items.map((item) => item.liabilities || 0)
          )
          .reduce((accumulator, currentValue) => {
            // Check if the value is not undefined and is a number before adding it
            if (typeof currentValue === "number" && !isNaN(currentValue)) {
              return accumulator + currentValue
            } else {
              return accumulator
            }
          }, 0)
        return { key, value }
      })
  } else if (summaryType === "assets") {
    data = Object.keys(listings)
      .filter((item) => item.endsWith("AssetsListing"))
      .map((key) =>
        Object.keys(listings[key] || {}).map((subKey) => {
          const v =
            (listings[key][subKey] || []) &&
            (listings[key][subKey] || []).length &&
            (listings[key][subKey] || [])
              .map((item) => item.assets || 0)
              .reduce((accumulator, currentValue) => {
                // Check if the value is not undefined and is a number before adding it
                if (typeof currentValue === "number" && !isNaN(currentValue)) {
                  return accumulator + currentValue
                } else {
                  return accumulator
                }
              }, 0)
          return { key: subKey, value: v }
        })
      )
      .flatMap((items) => items)
  } else if (summaryType === "liabilities") {
    data = Object.keys(listings)
      .filter((item) => !item.endsWith("AssetsListing"))
      .map(
        (key) =>
          Object.keys(listings[key] || {}) &&
          Object.keys(listings[key] || {}).length &&
          Object.keys(listings[key] || {}).map((subKey) => {
            const v =
              (listings[key][subKey] || []) &&
              (listings[key][subKey] || []).length &&
              (listings[key][subKey] || [])
                .map((item) => item.liabilities || 0)
                .reduce((accumulator, currentValue) => {
                  // Check if the value is not undefined and is a number before adding it
                  if (
                    typeof currentValue === "number" &&
                    !isNaN(currentValue)
                  ) {
                    return accumulator + currentValue
                  } else {
                    return accumulator
                  }
                }, 0)
            return { key: subKey, value: v }
          })
      )
      .flatMap((items) => items)
  }
  const totalValue = data
    .map((item) => item.value || 0)
    .reduce((accumulator, currentValue) => {
      // Check if the value is not undefined and is a number before adding it
      if (typeof currentValue === "number" && !isNaN(currentValue)) {
        return accumulator + currentValue
      } else {
        return accumulator
      }
    }, 0)

  const chartData = {
    labels:
      data.length &&
      data.map((item) =>
        intl.get(`netWorth.chart.legend.${item.key}`)
          ? intl.get(`netWorth.chart.legend.${item.key}`)
          : `${item.key}`
      ), //20200730 MABT-359
    datasets: [
      {
        data: data.map((item) => item.value),
        backgroundColor:
          data[0].key === "personalAssetsListing"
            ? [
                "#4983f0",
                "#7c9cf5",
                "#a4b7f9",
                "#c8d3fd"
                // "rgba(69,114,199,1",
                // ...getPrefixedColors(data.length).slice(1, data.length)
              ]
            : data[0].key === "liabilitiesListing"
            ? [
                "#dd6e0f",
                "#e78615",
                "#f09e20",
                "#fcc235"
                // "rgba(242,46,46,1)",

                // ...getPrefixedColors(data.length).slice(1, data.length)
              ]
            : data[0].key === "creditCards"
            ? [
                ...getPrefixedColors(data.length).slice(0, data.length - 2),
                "rgba(242,46,46,1)",
                "#B34D4D"
              ]
            : getPrefixedColors(data.length),
        hoverBackgroundColor:
          data[0].key === "personalAssetsListing"
            ? ["#4983f0", "#7c9cf5", "#a4b7f9", "#c8d3fd"]
            : data[0].key === "liabilitiesListing"
            ? ["#dd6e0f", "#e78615", "#f09e20", "#fcc235"]
            : data[0].key === "creditCards"
            ? [
                ...getPrefixedColors(data.length).slice(0, data.length - 2),
                "rgba(242,46,46,1)",
                "#B34D4D"
              ]
            : getPrefixedColors(data.length)
      }
    ]
  }

  let chartProps = {
    data: chartData,
    options: {
      legend: {
        position: "bottom",
        // display: true,
        align: "start", //20200810 legend align to left
        fullWidth: true,
        // padding: 200
        //20200810 legend align to left
        ...DefaultPieChartLegendsOptions,
        ...PieLegendsForcedLeftOptions
      },
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          // 20200717 MABT 359
          title: function (tooltipItem, data) {
            return data["labels"][tooltipItem[0]["index"]]
          },

          label: (tooltipItem, cData) => {
            const { index } = tooltipItem
            const { datasets } = cData
            const [dataset] = datasets
            const percentage = ((dataset.data[index] || 0) / totalValue) * 100
            return `${Math.round(percentage * 100) / 100} %`
          }
        }
      },
      scales: {}
    },
    height: 400
  }

  // 20200721 MABT-239 empty data
  const emptyChartProps = {
    data: {
      labels: [""],
      datasets: [
        {
          data: [0.1],
          backgroundColor: ["#dadada"]
        }
      ]
    },

    options: {
      legend: {
        display: false,
        labels: {
          display: false
        }
      },
      tooltips: {
        enabled: false
      }
    },
    height: 400
  }
  let allZero = true
  if (chartProps) {
    allZero = chartData.datasets[0].data.every((number) => number === 0)
  }
  let modifiedData = data.filter((item) => item.value !== undefined)
  const totalAmount = useMemo(() => {
    const sum = modifiedData.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.value
    }, 0)
    return sum
  }, [modifiedData])
  return (
    <React.Fragment>
      {/* Pie chart */}
      <div className="graph-holder">
        {!allZero &&
          (summaryType === "assetClass" ||
            summaryType === "liabilityClass") && (
            <Doughnut {...{ ...chartProps, height: 400 }} />
          )}

        {/* {!allZero && summaryType === "assets" && (
          <Doughnut {...{ ...chartProps, height: 1000 }} />
        )}
        {!allZero && summaryType === "liabilities" && (
          <Doughnut {...{ ...chartProps, height: 600 }} />
        )} */}
        {allZero && <Doughnut {...{ ...emptyChartProps, height: 400 }} />}
      </div>
      <div className="graph-summary">
        <div className="subtitle">
          {intl.get("netWorth.assetsLiabilitiesSummary")}
        </div>
        {modifiedData.map((item) => (
          <div className="g-summary-box">
            <div className="text">
              {intl.get(`netWorth.chart.legend.${item.key}`)}
              {/* {item.key} */}
            </div>
            <div className="figure">
              <NumberFormat
                value={item.value}
                displayType={"text"}
                thousandSeparator={","}
                decimalScale={DECIMAL_SCALE}
                decimalSeparator={"."}
                prefix={currencySymbol}
              />
            </div>
          </div>
        ))}
        <div className="g-summary-box g-summary-box-line m-0 p-0 d-flex">
          <div className="text"></div>
          <div className="figure">
            <div className="total-line" />
          </div>
        </div>
        <div className="g-summary-box">
          {/* <hr /> */}
          <div className="text">
            {/* {intl.get("netWorth.assetsLiabilitiesSummary.netAssets")} */}
            Total
          </div>
          <div className="figure">
            <NumberFormat
              value={totalAmount}
              displayType={"text"}
              thousandSeparator={","}
              decimalScale={DECIMAL_SCALE}
              decimalSeparator={"."}
              prefix={currencySymbol}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
function NetWorthGraphSummary({
  totalSummary = {},
  listings,
  currency = "MYR",
  currencySymbol = "RM"
}) {
  const [state, setState] = useState({
    summaryType: "overview",
    currency: ""
  })

  const summaryTypes = {
    overview: intl.get("netWorth.summary.options.overview"),
    client: intl.get("netWorth.summary.options.client"),
    partner: intl.get("netWorth.summary.options.partner"),
    joint: intl.get("netWorth.summary.options.joint"),
    aum: "aum",
    assetClass: intl.get("netWorth.summary.options.assetClass"),
    liabilityClass: intl.get("netWorth.summary.options.liabilityClass"),
    assets: intl.get("netWorth.summary.options.assets"),
    liabilities: intl.get("netWorth.summary.options.liabilities"),
    riskSummary: intl.get("netWorth.summary.options.riskSummary"),
    clientriskSummary: intl.get("netWorth.summary.options.clientriskSummary"),
    partnerriskSummary: intl.get("netWorth.summary.options.partnerriskSummary"),
    jointriskSummary: intl.get("netWorth.summary.options.jointriskSummary")

    // contribution: intl.get("netWorth.summary.options.contribution")
  }

  return (
    <React.Fragment>
      <div className="title text-center">
        {intl.get("netWorth.assetsLiabilitiesDistributions")}
      </div>
      <div className="select-box">
        <select
          value={state.summaryType}
          onChange={(e) => {
            const { value: summaryType } = e.target
            setState((prevState) => ({
              ...prevState,
              summaryType
            }))
          }}
        >
          {Object.keys(summaryTypes).map((key) => (
            <option key={key} value={key}>
              {intl.get(`netWorth.summary.options.${key}`)}
            </option>
          ))}
        </select>
      </div>
      {(state.summaryType === "overview" ||
        state.summaryType === "client" ||
        state.summaryType === "partner" ||
        state.summaryType === "joint") && (
        <ClassificationSummary
          {...{
            summaryType: state.summaryType,
            totalSummary,
            currency,
            currencySymbol
          }}
        />
      )}
      {(state.summaryType === "assetClass" ||
        state.summaryType === "liabilityClass" ||
        state.summaryType === "assets" ||
        state.summaryType === "liabilities") && (
        <AssetSummary
          listings={listings}
          summaryType={state.summaryType}
          currency={currency}
          currencySymbol={currencySymbol}
          totalSummary={totalSummary}
        />
      )}
      {(state.summaryType === "riskSummary" ||
        state.summaryType === "clientriskSummary" ||
        state.summaryType === "partnerriskSummary" ||
        state.summaryType === "jointriskSummary") && (
        <RiskClassificationSummary
          summaryType={state.summaryType}
          currency={currency}
          totalSummary={totalSummary}
        />
      )}
      {state.summaryType === "aum" && (
        <AumSummary currency={currency} totalSummary={totalSummary} />
      )}
    </React.Fragment>
  )
}

export class NetWorth extends Component {
  state = {
    currency: "",
    currencySymbol: "",
    first: false
  }

  async componentDidMount() {
    const { match, getAllEnumOptions, getClient, getPersonalDetails } =
      this.props

    // get data
    if (match.params && match.params.id) {
      await getClient(match.params.id, "")
      // await getPersonalDetails(match.params.id)
    }

    await getAllEnumOptions()
  }

  async componentWillReceiveProps(nextProps) {
    // fix for Currency not updating
  }
  componentDidUpdate(prevProps) {
    if (!this.state.currency && this.props.netWorth !== prevProps.netWorth) {
      // You don't have to do this check first, but it can help prevent an unneeded render
      const taxResidency = Get(this.props.netWorth, "client.taxResidency")

      if (taxResidency) {
        const currency = getCurrency(taxResidency)

        if (currency !== this.state.currency && !this.state.first) {
          this.setState({
            currency
          })
        } else {
          this.setState({ currency: currency }) //20200601
        }

        this.setState({
          first: true
        })
      }
    }
    if (this.props.client !== prevProps.client)
      this.props.getSalariesList(this.props.client)
  }

  handleOpenAddModal = (modalHandlerName) => {
    const { showModalWindow } = this.props
    showModalWindow(modalHandlerName)
  }

  async currencyChanged(value) {
    const { match, getClient } = this.props
    // this.setState({ currency: value });
    this.setState((prevState) => ({
      ...prevState,
      currency: value,
      currencySymbol: value ? getSymbolFromCode(value) : ""
    }))
    await getClient(match.params.id, value)
  }

  render() {
    const { currency, currencySymbol } = this.state
    const {
      netWorth,
      updateListingFilter,
      closeConfirmationDialog,
      clientFullNamePreferred,
      partnerFullNamePreferred
    } = this.props
    const { totalSummary, isJoint, client, partner, listingFilter } = netWorth
    const {
      personalAssetsListing,
      liquidAssetsListing,
      illiquidAssetsListing,
      retirementAssetsListing,
      liabilitiesListing,
      gealListing,
      taxationListing,
      employmentTerminationPaymentsListing
    } = netWorth
    return (
      <React.Fragment>
        <LoadingSpinner />
        <div className="portal-maincontent networth networth-listing">
          <div
            className="content-panel networth"
            style={{ overflowX: "hidden" }}
          >
            <h1>{intl.get("netWorth.personalNetWorth")}</h1>
            <ReportingCurrency
              onChange={(val) => this.currencyChanged(val)}
              currentCurrency={currency}
            />

            {/* form */}
            <div className="portal-form portal-form-ekyc-networth">
              <div className="primary-content">
                <div id="scroll-section" className="primary-content__left">
                  {isJoint && (
                    <NetWorthListingFilter
                      listingFilter={listingFilter ? listingFilter : "All"}
                      updatefilter={(value) => updateListingFilter(value)}
                      clientName={clientFullNamePreferred}
                      partnerName={partnerFullNamePreferred}
                    />
                  )}
                  {/* header */}
                  <div className="content-panel-title">
                    <div className="title-box">
                      {intl.get("netWorth.assetsLiabilitiesSummary.assets")}
                    </div>
                    <div className="title-box">
                      {intl.get(
                        "netWorth.assetsLiabilitiesSummary.liabilities"
                      )}
                    </div>
                    <div className="title-box">
                      {intl.get("netWorth.assetsLiabilitiesSummary.netAssets")}
                    </div>
                  </div>
                  <PersonalAssets
                    currency={currency}
                    currencySymbol={currencySymbol}
                  />
                  <LiquidAssets
                    currency={currency}
                    currencySymbol={currencySymbol}
                  />
                  <IlliquidAssets
                    isJoint={isJoint}
                    currency={currency}
                    currencySymbol={currencySymbol}
                  />
                  <RetirementAssets
                    isJoint={isJoint}
                    currency={currency}
                    currencySymbol={currencySymbol}
                    client={client}
                  />
                  <Liabilities currency={currency} />
                  <GovernmentEducationAssistanceLoans
                    currency={currency}
                    currencySymbol={currencySymbol}
                  />
                  <Taxation currency={currency} />
                  <EmploymentTerminationPayments
                    currency={currency}
                    currencySymbol={currencySymbol}
                  />
                  <TotalNetWorthSummary
                    data={totalSummary}
                    listingFilter={listingFilter}
                    currency={currency}
                    currencySymbol={currencySymbol}
                  />
                </div>
                <div className="primary-content__right">
                  <div className="graph-panel graph-panel-ekyc-networth">
                    <NetWorthGraphSummary
                      {...{
                        totalSummary,
                        listings: {
                          personalAssetsListing,
                          liquidAssetsListing,
                          illiquidAssetsListing,
                          retirementAssetsListing,
                          liabilitiesListing,
                          gealListing,
                          taxationListing,
                          employmentTerminationPaymentsListing
                        },
                        currency,
                        currencySymbol
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <ScrollUpButton sectionId="scroll-section" /> */}
          </div>

          <MalabarDefaultRightMenu
            enableSaveButton={false}
            enableClearButton={false}
          />

          <ArrowMenu nextDescription={"next"} prevDescription={"prev"} />
        </div>

        {/* 20220103 */}
        <ConfirmationDialog
          isOpen={
            netWorth.confirmationDialog && netWorth.confirmationDialog.show
          }
          message={intl.get("confirmation.message.delete")}
          onClose={closeConfirmationDialog}
          onYes={
            netWorth.confirmationDialog
              ? netWorth.confirmationDialog.onYes
              : () => ""
          }
        />
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { netWorth, loader, entity } = state
  const { optionsData } = state.enumOptions
  const { client } = entity
  const apiStatus = loader.apiStatus || 0
  const { clientFullNamePreferred, partnerFullNamePreferred } = state.entity
  return {
    netWorth,
    optionsData,
    loading: apiStatus > 0,
    entity,
    client,
    clientFullNamePreferred,
    partnerFullNamePreferred
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign(
      {},
      netWorthActions,
      modalWindowActions,
      enumOptionsActions,
      appSettingsActions,
      personalDetailsActions
    ),
    dispatch
  )
}

NetWorth = connect(mapStateToProps, mapDispatchToProps)(NetWorth)

export default NetWorth
