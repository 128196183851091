import React, { useState, useEffect, useContext } from "react"
import { Form, Field } from "react-final-form"
import Alert from "react-s-alert"
import RenderSimpleFields, {
  FieldType
} from "../components/Form/RenderSimpleFields"
import api from "../lib/api"
import { renderNumberCustom } from "../helpers/FormRender"
import { AppContext } from "../context/AppContext"
import { setCurrentLanguage } from "./../actions/AppSettingsActions"

function ChangePasswordForm({ id, selectedEntity, setting }) {
  const [show, setShow] = useState(false)
  const [show1, setShow1] = useState(false)
  const [show2, setShow2] = useState(false)

  // const [fieldid, setFieldid] = useState("");
  const showPassword = (id) => {
    let field = document.getElementById(id)
    // setFieldid(id);
    id === "current" && setShow(!show)
    id === "newPassword" && setShow1(!show1)
    id === "confirmNewPassword" && setShow2(!show2)

    if (field.type === "text") {
      field.type = "password"
    } else {
      field.type = "text"
    }
  }
  const fields = [
    {
      label: "Current Password",
      name: "currentPassword",
      type: FieldType.PASSWORD,
      id: "current",
      show: show,

      showPassword: showPassword
    },
    {
      label: "New Password",
      name: "newPassword",
      type: FieldType.PASSWORD,
      show1: show1,

      id: "newPassword",
      showPassword: showPassword
    },
    {
      label: "Confirm Password",
      name: "confirmPassword",
      show2: show2,

      type: FieldType.PASSWORD,
      id: "confirmNewPassword",
      showPassword: showPassword
    }
  ]

  const changePassword = (model, id) => {
    if (selectedEntity.entityType == "CLIENT") {
      return api.put(
        `/client/${selectedEntity.id.replace(
          selectedEntity.resourceServerId + "-",
          ""
        )}/change-password`,
        model
      )
    } else {
      return api.put(`/user/${id}/change-password`, model)
    }
  }

  return (
    <Form
      onSubmit={(model) => changePassword(model, id)}
      validate={(values) => {
        const errors = {}
        if (values.newPassword && values.confirmPassword) {
          if (values.newPassword !== values.confirmPassword) {
            errors.confirmPassword = "Password is different"
          }
        }
        return errors
      }}
      render={({ handleSubmit, reset }) => (
        <form
          id="changePassword"
          onSubmit={(event) => {
            const promise = handleSubmit(event)
            if (promise) {
              promise.then((response) => {
                if (response && response.status === 200) {
                  Alert.success("Successfully changed your password!")
                  reset()
                }
              })
            }
          }}
        >
          <div className="subtitle2">Change Password</div>
          <RenderSimpleFields fields={fields} />
          <div style={{ textAlign: "right", paddingTop: "2em" }}>
            <button className="portal-btn portal-btn-submit" type="submit">
              Apply
            </button>
          </div>
        </form>
      )}
    />
  )
}

function TwoFactorAuthenticationForm({
  id,
  setting = {},
  showConfirmationDialog,
  setIsTwoFactorAuthenticationOn
}) {
  const [state, setState] = useState({})
  const getQrCodeUrlAndKey = () =>
    api.get(`/auth/tfa-qr-code-link?id=${id}`).then((response) => {
      const { data } = response
      setState((prevState) => ({
        ...prevState,
        qrCodeUrl: data.qrCodeUrl,
        key: data.encryptedKey
      }))
    })
  const verifyTfa = (model) => {
    const payload = {
      encryptedKey: state.key,
      id,
      tfaCode: model.tfaCode
    }
    api.post("/auth/first-tfa-authentication", payload).then((response) => {
      if (response && response.status === 200) {
        Alert.success("Successfully activate your Two Factor Authentcication.")
        setState((prevState) => ({
          ...prevState,
          qrCodeUrl: undefined,
          key: undefined
        }))
        setIsTwoFactorAuthenticationOn(true)
      }
    })
  }
  const deactivateTfa = () => {
    api.post(`/auth/deactivate-tfa?id=${id}`).then(() => {
      setIsTwoFactorAuthenticationOn(false)
      Alert.success("Successfully deactivate your Two Factor Authentcication.")
    })
  }

  const { qrCodeUrl } = state
  const { isTwoFactorAuthenticationOn } = setting
  return (
    <React.Fragment>
      <div className="subtitle2">Two Factor Authentication</div>
      {isTwoFactorAuthenticationOn && (
        <button
          onClick={() =>
            showConfirmationDialog({
              message: "You're about to de-activate TFA, continue?",
              onYes: deactivateTfa
            })
          }
          className="portal-btn portal-btn-submit"
          type="button"
        >
          Disable
        </button>
      )}

      {!isTwoFactorAuthenticationOn && (
        <React.Fragment>
          <button
            onClick={getQrCodeUrlAndKey}
            disabled={qrCodeUrl}
            className={`portal-btn portal-btn-submit ${
              qrCodeUrl ? "action-button-disabled" : ""
            }`}
            type="button"
          >
            Enable
          </button>

          {qrCodeUrl && (
            <React.Fragment>
              <p style={{ fontSize: "12px", color: "rgba(121, 121, 121,1" }}>
                Scan the QR code below using authenticator like Google
                Authenticator or Microsoft Authenticator
              </p>
              <br />

              <iframe
                title="google-tfa-qr-code"
                src={qrCodeUrl}
                height="210"
                width="300"
                frameBorder="0"
              />

              <Form
                onSubmit={verifyTfa}
                initialValues={{}}
                validate={(values) => {
                  const errors = {}
                  if (values.tfaCode && values.tfaCode.length !== 6) {
                    errors.tfaCode = "TFA code should be 6 digit numbers"
                  }
                  return errors
                }}
                render={({ handleSubmit }) => (
                  <form
                    id="timeoutForm"
                    onSubmit={handleSubmit}
                    className="pt-2"
                  >
                    <Field
                      name="tfaCode"
                      placeholderlabel="Six digit number from your Authenticator app"
                      component={renderNumberCustom}
                    />
                    <div
                      style={{
                        textAlign: "right",
                        paddingTop: "1em"
                      }}
                    >
                      <button
                        className="portal-btn portal-btn-submit"
                        type="submit"
                      >
                        Verify
                      </button>
                    </div>
                  </form>
                )}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

const changeSessionTimout = (model, id) => {
  api.put(`/user/${id}/timeout`, model).then((response) => {
    const { data } = response
    const userData = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null
    const modifieduserData = { ...userData, timeout: data }
    localStorage.setItem("user", JSON.stringify(modifieduserData))
    setTimeout(data)
    Alert.success("Successfully set your timeout.")
  })
}

const changeDecimalPoints = (model, id) => {
  api.put(`/user/${id}/decimal-points`, model).then((response) => {
    const { data } = response
    const userData = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null
    const modifieduserData = { ...userData, decimalPoints: data }
    localStorage.setItem("user", JSON.stringify(modifieduserData))
    Alert.success("Successfully set your decimal points.")
    if (response) {
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    }
  })
}

function DecimalPointsForm({ id, setting }) {
  const initModel = { decimalPoints: setting.decimalPoints }

  return (
    <Form
      onSubmit={(model) => changeDecimalPoints(model, id)}
      initialValues={initModel}
      render={({ handleSubmit }) => (
        <form id="decimalPointsForm" onSubmit={handleSubmit}>
          <div className="subtitle2">Decimal Points</div>
          <RenderSimpleFields
            fields={[
              {
                label: "Decimal Points",
                name: "decimalPoints",
                type: FieldType.SELECTION,
                required: true,
                selectionData: [
                  { value: "0" },
                  { value: "1" },
                  { value: "2" },
                  { value: "3" },
                  { value: "4" },
                  { value: "5" },
                  { value: "6" }
                ],
                valueProperty: "value",
                labelProperty: "value",
                hideFirstEmptyOption: true
              }
            ]}
          />
          <div style={{ textAlign: "right", paddingTop: "2em" }}>
            <button className="portal-btn portal-btn-submit" type="submit">
              Apply
            </button>
          </div>
        </form>
      )}
    />
  )
}

function SessionTimeOutForm({ id, setting, setTimeout }) {
  const timeoutModel = { timeout: setting.timeout }

  const onChangeSession = (e) => {
    const model = { timeout: e }
    changeSessionTimout(model, id)
  }

  return (
    <Form
      onSubmit={(model) => changeSessionTimout(model, id)}
      initialValues={timeoutModel}
      render={({ handleSubmit }) => (
        <form id="timeoutForm" onSubmit={handleSubmit}>
          <div className="subtitle2">Session Time Out</div>
          <RenderSimpleFields
            fields={[
              {
                label: "Should expire in (minutes)",
                name: "timeout",
                type: FieldType.SELECTION,
                required: true,
                selectionData: [
                  // { value: "0" },
                  { value: "1" },
                  { value: "2" },
                  { value: "5" },
                  { value: "10" },
                  { value: "15" },
                  { value: "20" },
                  { value: "30" },
                  { value: "45" },
                  { value: "60" },
                  { value: "90" },
                  { value: "120" }
                ],
                valueProperty: "value",
                labelProperty: "value",
                hideFirstEmptyOption: true
              }
            ]}
          />
          <div style={{ textAlign: "right", paddingTop: "2em" }}>
            <button className="portal-btn portal-btn-submit" type="submit">
              Apply
            </button>
          </div>
        </form>
      )}
    />
  )
}

const changeLanguage = (model, forceRefresh) => {
  localStorage.setItem("currentLanguage", model.currentLanguage)
  setCurrentLanguage()
  Alert.success("Successfully set your language.")
  forceRefresh()
}

function SettingForm({ forceRefresh, firstTimeLogin, selectedEntity }) {
  const { id } = JSON.parse(localStorage.getItem("user"))
  const [state, setState] = useState({})
  const {
    modal: { showConfirmationDialog }
  } = useContext(AppContext)

  useEffect(() => {
    api.get(`/user/${id}/setting`).then((response) => {
      const { data } = response
      const userData = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null
      console.log("data :", data)
      const modifieduserData = {
        ...userData,
        timeout: data.timeout,
        decimalPoints: data.decimalPoints
      }
      localStorage.setItem("user", JSON.stringify(modifieduserData))
      setState((prevState) => ({ ...prevState, setting: data }))
    })
  }, [])

  const setIsTwoFactorAuthenticationOn = (value) => {
    setState((prevState) => ({
      ...prevState,
      setting: {
        ...prevState.setting,
        isTwoFactorAuthenticationOn: value
      }
    }))
  }

  const setTimeout = (value) => {
    setState((prevState) => ({
      ...prevState,
      setting: {
        ...prevState.setting,
        timeout: value
      }
    }))
  }

  const setDecimalPoints = (value) => {
    setState((prevState) => ({
      ...prevState,
      setting: {
        ...prevState.setting,
        decimalPoints: value
      }
    }))
  }

  const { setting } = state
  return (
    <div
      className="portal-form-popup"
      style={{
        height: "550px",
        overflowY: "scroll"
      }}
    >
      <p className="text-right">
        <i>* Note: you probably need to logout to apply these changes</i>
      </p>
      <ChangePasswordForm
        id={id}
        selectedEntity={selectedEntity}
        setting={state.setting}
      />
      <br />
      {setting && (
        <TwoFactorAuthenticationForm
          setting={state.setting}
          id={id}
          showConfirmationDialog={showConfirmationDialog}
          setIsTwoFactorAuthenticationOn={setIsTwoFactorAuthenticationOn}
        />
      )}

      {setting && (
        <SessionTimeOutForm
          setting={state.setting}
          id={id}
          setTimeout={setTimeout}
        />
      )}

      {setting && (
        <DecimalPointsForm
          setting={state.setting}
          id={id}
          setDecimalPoints={setDecimalPoints}
        />
      )}
    </div>
  )
}

export default SettingForm
