import { LoanFrequencyEnum } from "../constants/Enums";

const numberOfPayments = model => {
    const currentYear = new Date().getFullYear();
    const loanStartYear = model.borrowingsStartDate
        ? new Date(model.borrowingsStartDate).getFullYear()
        : currentYear;
    const yearLoanPaid = currentYear - loanStartYear;
    const yearLoanRemain = model.borrowingsTermInYears - yearLoanPaid;

    return model.borrowingsRepaymentFrequency &&
        model.borrowingsRepaymentFrequency ===
            LoanFrequencyEnum.Frequency_MONTHLY
        ? yearLoanRemain * 12
        : yearLoanRemain * 26;
};

const normalizePayment = (model, result) => {
    if (isNaN(result)) {
        return 0;
    }
    if (
        model.borrowingsRepaymentFrequency &&
        model.borrowingsRepaymentFrequency ===
            LoanFrequencyEnum.Frequency_FORTNIGHTLY
    ) {
        return -((result * 26) / 12);
    }
    return -result;
};

const interestRatePerPaymentPeriod = model => {
    if (!model.borrowingsRepaymentFrequency) {
        return 0;
    }
    return model.borrowingsRepaymentFrequency &&
        model.borrowingsRepaymentFrequency ===
            LoanFrequencyEnum.Frequency_MONTHLY
        ? model.borrowingsAnnualInterestRate / 100 / 12
        : model.borrowingsAnnualInterestRate / 100 / 26;
};
function LoanRepaymentAmountWithInterestCalculation(model) {
    if (!model) {
        return 0;
    }
    if (
        !model.borrowingsStartDate ||
        !model.borrowingsRepaymentFrequency ||
        !model.borrowingsAnnualInterestRate ||
        !model.currentBorrowingsBalance
    ) {
        return 0;
    }
    const np = numberOfPayments(model);
    const presentValue = model.currentBorrowingsBalance || 0;
    const futureValue = 0;
    const type = 0;
    const ir = interestRatePerPaymentPeriod(model);
    if (ir === 0) {
        const x = normalizePayment(model, (presentValue + futureValue) / np);
        return x;
    }

    const pvif = Math.pow(1 + ir, np);
    let pmt = (-ir * presentValue * (pvif + futureValue)) / (pvif - 1);

    if (type === 1) {
        pmt /= 1 + ir;
    }
    const y = normalizePayment(model, pmt);
    return y;
}

export default LoanRepaymentAmountWithInterestCalculation;
