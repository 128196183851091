import React from "react"
import InsuranceCard from "./InsuranceCard"
import InsuranceIlasCard from "./InsuranceIlasCard"
import Api from "../../../lib/api"
import Alert from "react-s-alert"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { useSelector } from "react-redux"
import { netWorthFormActions } from "../../../actions"
import { useDispatch } from "react-redux"
// import setNewForm
const CardWrapHeader = ({ data, showForm }) => {
  const dispatch = useDispatch()
  const { clientId } = useSelector((state) => state.entity)
  const history = useHistory()
  const uploadDocument = async (e) => {
    const data = new FormData()

    const file = e.target.files[0]
    data.append("file", file)
    data.append("clientId", clientId)
    data.append("type", "insurance")

    try {
      await Api.post("/vidado/upload", data)
      Alert.success("Uploaded successfully")
    } catch (error) {
      console.log("Error is", error)
      Alert.success("Error Uploading")
    }
  }

  const { isNotAppendable } = data
  const clickHandler = (item) => {
    dispatch(netWorthFormActions.setNewForm(true, "CLIENT"))
    showForm(item)
  }
  return (
    <div className="row">
      <div className="col-md-4">
        <div
          className="insurance-box-holder"
          style={{ display: "flex", alignItems: "center" }}
        >
          <h3 className="">{data.title}</h3>

          {!isNotAppendable ? (
            <div className="btn-holder btn-holder-list p-0">
              <button
                onClick={clickHandler}
                // onClick={() => showForm()}
                className="btn btn-plus"
              />
            </div>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="btn-holder btn-holder-list p-0">
                <button
                  onClick={() => {
                    history.push(`/networth/${clientId}`)
                  }}
                  className="btn btn-plus"
                />
              </div>
              {data.title === "Motor Vehicle Insurance" && (
                <div className="btn-holder btn-holder-list p-0">
                  <label className="fileUpload ml-2" htmlFor="document">
                    Upload Document
                  </label>
                  <input
                    onChange={uploadDocument}
                    id="document"
                    type="file"
                    accept="application/pdf"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const InsuranceCardWrap = ({
  showForm,
  data,
  isJoint,
  deleteInsurance,
  showDialog,
  currency,
  type
}) => {
  const dispatch = useDispatch()

  let classification = ["client"]
  if (isJoint) {
    classification = ["client", "partner", "joint"]
  }
  const clickHandler = (item) => {
    dispatch(netWorthFormActions.setNewForm(false, item.classification))
    showForm(item)
  }
  return (
    <div className="insurance-box-wrapper">
      <div className="row">
        <div className="col-md-12">
          <CardWrapHeader data={data} showForm={showForm} />
          <div className="row">
            {classification.map((key) => (
              <div className="col-md-4" key={key}>
                {(data[key] || []).map((item, index) => {
                  // if (type === "ilas") {
                  //   return (
                  //     <InsuranceIlasCard
                  //       data={item}
                  //       key={index}
                  //       deleteInsurance={() => deleteInsurance(item)}
                  //       showForm={() => clickHandler(item)}
                  //       showDialog={showDialog}
                  //       currency={currency}
                  //     />
                  //   )
                  // }
                  return (
                    <InsuranceCard
                      data={item}
                      key={index}
                      deleteInsurance={() => deleteInsurance(item)}
                      showForm={() => clickHandler(item)}
                      showDialog={showDialog}
                      currency={currency}
                      type={type}
                    />
                  )
                })}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default InsuranceCardWrap
