import React, { useContext } from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from "react-accessible-accordion"
import intl from "react-intl-universal"
// import { FormSpy } from 'react-final-form';

import { AppContext } from "../../../../context/AppContext"

import CashFlowListingGroup from "../../CashFlowListingGroup"
import CashFlowHeaderListing from "../../CashFlowHeaderListing"
import { MotorVehicleForm } from "../../../../forms"

import api from "../../../../lib/api"
import { adjustYearlyExpensesOnLoad } from "../../../../forms/sub-forms/CarExpense"
import { getCurrency } from "../../../../helpers"
import { netWorthFormActions } from "../../../../actions"
import { useDispatch } from "react-redux"

const CarTransports = ({
  varPrefix,
  clientTotalValue,
  partnerTotalValue,
  jointTotalValue,
  optionsData,
  readOnlyAccessComponent,
  clientInformation,
  onExternalUpdated,
  currency,
  isJointHide,
  values
}) => {
  const dispatch = useDispatch()

  const {
    modal: { setModal, clearModal }
  } = useContext(AppContext)

  const handleSave = (values) => {
    // save the model
    api.put(values.url, values).then((response) => {
      onExternalUpdated(true)
    })

    clearModal()
  }

  const setDefaultData = (originalModel) => {
    const { taxResidency } = clientInformation.client
    const defaultCurrency = getCurrency(taxResidency)
    const {
      vehicleLocation,
      currentValue,
      vehicleExpenses = {}
    } = originalModel

    if (!vehicleLocation) {
      originalModel.vehicleLocation = taxResidency
    }
    if (!currentValue) {
      originalModel.currentValue = { currency: defaultCurrency }
    }

    if (originalModel.underHirePurchase) {
      originalModel.carFinancing = "HIRE_PURCHASE"
    } else if (originalModel.underLease) {
      originalModel.carFinancing = "CAR_LEASE"
    } else if (originalModel.underLoan) {
      originalModel.carFinancing = "LOAN"
    } else {
      originalModel.carFinancing = "NONE"
    }
    if (!vehicleExpenses.expensesCurrency) {
      if (!originalModel.vehicleExpenses) {
        originalModel.vehicleExpenses = {}
      }
      originalModel.vehicleExpenses.expensesCurrency = defaultCurrency
    }

    return originalModel
  }

  return (
    // <FormSpy subscription={{ values: true }}>
    //     {({ values }) => (
    <div className="portal-part">
      <Accordion accordion={false}>
        <AccordionItem>
          <AccordionItemTitle>
            <CashFlowHeaderListing
              title={intl.get(
                "cashflowlisting.personalExpenses.carTransportExpenses"
              )}
              isAccordion
              isJointHide={isJointHide}
              clientTotalValue={clientTotalValue}
              partnerTotalValue={partnerTotalValue}
              jointTotalValue={jointTotalValue}
              currency={currency}
            />
          </AccordionItemTitle>
          <AccordionItemBody>
            <CashFlowListingGroup
              varPrefix={`${varPrefix}.vehicles`}
              dataArray={values.detailResult.vehicles}
              isRoundColorRed
              isExternalModal
              isJointHide={isJointHide}
              currency={currency}
              onEditClick={(model) => {
                dispatch(
                  netWorthFormActions.setNewForm(false, model.classification)
                )
                setModal({
                  title: intl.get(
                    "cashflowlisting.personalExpenses.carTransportExpenses"
                  ),
                  formType: "carTransportExpenses",
                  content: (
                    <MotorVehicleForm
                      handleSave={handleSave}
                      model={setDefaultData(adjustYearlyExpensesOnLoad(model))}
                      readOnlyAccess={readOnlyAccessComponent()}
                      clientInformation={clientInformation}
                      optionsData={optionsData}
                    />
                  )
                })
              }}
            />
          </AccordionItemBody>
        </AccordionItem>
      </Accordion>
    </div>
    //     )}
    // </FormSpy>
  )
}

export default CarTransports
