import React, { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import ConfirmationDialog from '../ConfirmationDialog';

function GlobalMalabarDialog() {
    const {
        modal: {
            state: { dialogProperties },
            hideConfirmationDialog,
        },
    } = useContext(AppContext);

    return (
        <ConfirmationDialog
            {...dialogProperties}
            onClose={hideConfirmationDialog}
        />
    );
}

export default GlobalMalabarDialog;
