import React, { useState } from "react";
import intl from "react-intl-universal";
import PensionInvestmentPlatform from "./PensionInvestmentPlatform";
import PensionInsuranceDetails from "./PensionInsuranceDetails";
import PensionBeneficaryDetails from "./PensionBeneficaryDetails";

function PensionInvestmentDetails() {
    const [selectedTab, setTab] = useState("investmentPlatform");
    const tabs = [
        {
            name: "investmentPlatform",
            label: "Investment Platform",
            component: key => <PensionInvestmentPlatform key={key} />
        },
        {
            name: "investmentPlatformAssetAllocation",
            label: "Investment Platform Asset Allocation",
            component: key => <PensionInvestmentPlatform key={key} />
        },
        {
            name: "insuranceDetails",
            label: "Insurance Details",
            component: key => <PensionInsuranceDetails key={key} />
        },
        {
            name: "beneficiaryDetails",
            label: "Beneficiary Details",
            component: key => <PensionBeneficaryDetails key={key} />
        }
    ];
    return (
        <div className="portal-form-table">
            <div className="subtitle-holder">
                <div className="title">
                    {intl.get("netWorth.investmentDetails.title")}
                </div>
            </div>

            <div className="content-panel-tab">
                {tabs.map((item, index) => (
                    <span
                        key={index}
                        onClick={() => setTab(item.name)}
                        className={selectedTab === item.name ? "selected" : ""}
                    >
                        {item.label}
                    </span>
                ))}
            </div>

            {tabs
                .filter(item => item.name === selectedTab)
                .map((item, key) => item.component(key))}
        </div>
    );
}

export default PensionInvestmentDetails;
