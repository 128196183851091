import React from "react"
import intl from "react-intl-universal"
import { Field, Form, FormSpy } from "react-final-form"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { FormButtons, ModalNoAccess } from "../components"
import InsuranceDetails from "./sub-forms/InsuranceDetails"
import PersonalContentMainForm from "./PersonalContentMainForm"
import GeneralLoanDetails from "./sub-forms/GeneralLoanDetails"
import { fileFieldRender, getCurrency } from "../helpers"
import { useSelector } from "react-redux"
const PersonalContentForm = ({
  handleSave,
  model,
  optionsData,
  readOnlyAccess,
  clientInformation: { client, partner, isJoint }
}) => {
  const { defaultCurrency } = useSelector((state) => state.personalDetails)
  if (readOnlyAccess) {
    return <ModalNoAccess />
  }
  const { taxResidency = "" } = client
  const showMortgageAssociatedCharges =
    taxResidency.toLowerCase() === "australia" ||
    taxResidency.toLowerCase() === "au"
  const content = (values, isShowSidePane, setFormAttribute) => (
    <div className="portal-form-popup">
      <div className={isShowSidePane ? "portal-form-half" : ""}>
        <PersonalContentMainForm
          client={client}
          partner={partner}
          isJoint={isJoint}
          defaultCurrency={defaultCurrency}
        />
        <Field
          component={fileFieldRender}
          customClassName="mt-1 d-block"
          labelIdle="Drag, Drop or Browse"
          label="Upload Documents"
          onupdatefiles={(fileItems) => {
            setFormAttribute("proofDocument", fileItems[0])
          }}
          fileInformation={(values && values.proofDocument) || null}
        />
      </div>

      {isShowSidePane && (
        <div className="portal-form-half">
          <Tabs>
            <TabList>
              {values.underLoan && (
                <Tab>{intl.get("personalContents.loandDetails")}</Tab>
              )}
              {values.personalItemInsured && (
                <Tab>{intl.get("personalContents.insuranceDetails")}</Tab>
              )}
            </TabList>

            {values.underLoan && (
              <TabPanel>
                <GeneralLoanDetails
                  showMortgageAssociatedCharges={showMortgageAssociatedCharges}
                  optionsData={optionsData}
                  defaultCurrency={getCurrency(taxResidency) || "MYR"}
                  values={values.loan || {}}
                />
              </TabPanel>
            )}
            {values.personalItemInsured && (
              <TabPanel>
                <InsuranceDetails
                  optionsData={optionsData}
                  defaultCurrency={getCurrency(taxResidency) || "MYR"}
                />
              </TabPanel>
            )}
          </Tabs>
        </div>
      )}
    </div>
  )

  return (
    <Form
      onSubmit={handleSave}
      initialValues={model}
      mutators={{
        setFormAttribute: ([fieldName, fieldVal], state, { changeValue }) => {
          changeValue(state, fieldName, () => fieldVal)
        }
      }}
      render={({ handleSubmit, reset, mutators: { setFormAttribute } }) => (
        <form id="personalContentsForm" onSubmit={handleSubmit}>
          <FormSpy subscription={{ values: true }}>
            {({ values }) => {
              const isShowSidePane =
                values.underLoan || values.personalItemInsured

              return content(values, isShowSidePane, setFormAttribute)
            }}
          </FormSpy>

          <FormButtons reset={reset} readOnlyAccess={readOnlyAccess} />
        </form>
      )}
    />
  )
}
export default PersonalContentForm
