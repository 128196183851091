import React, { useState } from 'react';
import intl from 'react-intl-universal';
import CPFInvestmentPlatformTable from './CPFInvestmentPlatformTable';
import CPFInvestmentAllocationTable from './CPFInvestmentAllocation';

const CPFInvestmentPlatform = ({
    model = {},
    mutators,
    fieldName
}) => {
    const [selectedTab, setSelectedTab] = useState('investmentPlatform');
    const assets = () => {
        if (model && model.investmentPlatformAllocation) {
            return Object.assign(model.investmentPlatformAllocation, { classification: model.classification });
        } else {
            return { classification: model.classification }
        }
    }

    const investmentPlatforms = () => {
        if (model && model.investmentPlatforms) {
            return model.investmentPlatforms;
        } else {
            return []
        }
    }
    const tabs = [
        {
            name: 'investmentPlatform',
            label: intl.get(
                'netWorth.investmentDetails.tab.investmentPlatform'
            ),
            component: key => (
                <CPFInvestmentPlatformTable
                    key={key}
                    model={investmentPlatforms()}
                    mutators={mutators}
                    fieldName={`${fieldName}.investmentPlatforms`}
                />
            ),
        },
        {
            name: 'investmentAllocation',
            label: 'Investment Plaform Asset Allocation',
            component: key => (
                <CPFInvestmentAllocationTable
                    key={key}
                    model={assets()}
                    mutators={mutators}
                    fieldName={`${fieldName}.investmentPlatformAllocation`}
                />
            ),
        },

    ];

    return (
        <div className="portal-form-table">
            <div className="subtitle-holder">
                <div className="title">
                    Investment Platform Details
                </div>
            </div>

            <div className="content-panel-tab tab5">
                {tabs.map((item, index) => (
                    <a
                        href="# "
                        role="button"
                        key={index}
                        onClick={() => setSelectedTab(item.name)}
                        className={selectedTab === item.name ? 'selected' : ''}
                    >
                        {item.label}
                    </a>
                ))}
            </div>

            {tabs
                .filter(item => item.name === selectedTab)
                .map((item, key) => item.component(key))}
        </div>
    );
};

export default CPFInvestmentPlatform;
