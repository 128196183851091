import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import CpdTable from "./CpdCptTable";
import CpdCptForm from "./CpdCptForm";
const CpdComponent = ({ type, availableCourses }) => {
    const [refresh, setRefresh] = useState(false);
    const {
        modal: { setModal, showConfirmationDialog, clearModal }
    } = useContext(AppContext);

    return (
        <div>
            <div>
                <button
                    type="button"
                    className="portal-btn portal-btn-submit fa fa-plus"
                    onClick={() =>
                        setModal({
                            title: "Continuous Education Points Dialog Box",
                            formType: "cpd",
                            content: (
                                <CpdCptForm
                                    setRefresh={setRefresh}
                                    refresh={refresh}
                                    clearModal={clearModal}
                                    type={type}
                                    availableCourses={availableCourses}
                                />
                            )
                        })
                    }
                >
                    {" "}
                    {"New "+ type}
                </button>
            </div>

            <p className="cpd-history-text">
                {type + " History"}
            </p>
            {/* table part */}
            <CpdTable
                setRefresh={setRefresh}
                refresh={refresh}
                type={type}
                availableCourses={availableCourses}
            />
        </div>
    );
};

export default CpdComponent;
