
/*
    Author : anton.nuansantio@koizai.com
    Library : MenuApi
    Description : methods related with menu list part
*/
import api from '../lib/api';

// change base route for different entity/domain here
const BASE_ROUTE = '/loan';

class LoanApi {
    static postCalculateRepayment(model) {
        return api.post(`${BASE_ROUTE}/repayment`, model);
    }

    static postCalculateTermsPeriods(model) {
        return api.post(`${BASE_ROUTE}/term-periods`, model);
    }

    
}

export default LoanApi;
