import EmailApi from './../../api/Email/EmailApi';
import { emailAction } from '../../constants/ActionConstants';

export function getRecipients(userId) {
    return async dispatch => {
        EmailApi.recipients(userId).then(response => {
            dispatch({
                type: emailAction.GET_RECIPIENTS, response,
            });
        });
    };
}

export const EmailActions = {
    getRecipients,
};