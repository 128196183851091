import React, { useState, useEffect } from "react"
import intl from 'react-intl-universal';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';

import ILASBeneficaryDetails from "./BeneficaryDetails";
import Riders from './Riders';
import InvestmentAllocation from "../InvestmentAllocation";
import ContinuityBonus from "./ContinuityBonus";
import AdditionalInvestmentTopUpPlan from "./AdditionalInvestmentTopUpPlan";
import WithdrawalPlans from './WithdrawalPlans';
import { NetWorthApi } from "../../../api";
import { RenderSimpleFields } from '../../../components';
import { FieldType } from '../../../components/Form/RenderSimpleFields';
import ILASDetailsForm from "./ILASDetailsForm";

function ILASTabs({ values, mutators, optionsData }) {
    const { push, remove } = mutators;
    const [cashAccounts, setCashAccounts] = useState([]);
    
    useEffect(() => {
        let clientId = localStorage.getItem('activeClientId');
        NetWorthApi.getClientCashAccounts(clientId).then(({data})=>{
            setCashAccounts(data);
        })
    
    }, [])

    function getCashOptions(){
        let result = [];
        if(cashAccounts){
            let classificationFilter = values.classification; 
            cashAccounts.filter(c=> c.classification==classificationFilter).forEach((c)=>{
            result.push(
                <option key={`enum-${c.id}`} value={c.id} >
                {c.accountNumber?c.accountNumber:c.accountName} {c.primary?"(primary)":""}
                </option>
            )
            })
        }
        return result
    }

    return (
        <>
            <Tabs>
                <TabList>
                    <Tab>{intl.get("insurance.details")}</Tab>
                    <Tab>{intl.get('ilasInvestment.investmentReturns')}</Tab>
                    <Tab>{intl.get('ilasInvestment.additionalInvestmentTopUpPlan')}</Tab>
                    <Tab>{intl.get('ilasInvestment.withdrawalPlans')}</Tab>
                    <Tab>{intl.get('ilasInvestment.continuityBonus')}</Tab>
                </TabList>
                <TabPanel>
                    <ILASDetailsForm values={values} mutators={mutators} optionsData={optionsData} />
                </TabPanel>
                
                <TabPanel>
                    <RenderSimpleFields
                        fields={[
                            {
                                name: "maturitySettlementType",
                                label: "Maturity Settlement Options",
                                type: FieldType.ENUM_SELECTION,
                                enumData: optionsData.frequencyOptions,
                                required: true
                            },
                            {
                                name: 'investmentReturnsCashValue',
                                label: intl.get('ilasInvestment.cashValue'),
                                type: FieldType.MONEY,
                                isMoney: true,
                                required: true,
                            },
                            {
                                name: "targetCashId",
                                label: intl.get("field.settlementCash"),
                                type: FieldType.ENUM_SELECTION,
                                enumData: getCashOptions(),
                                
                            },
                            {
                                name: 'investmentReturnsValuationDate',
                                label: intl.get('ilasInvestment.valuationDate'),
                                type: FieldType.DATE,
                            },
                            {
                                name: 'maturitySettlementFee',
                                label: intl.get('ilasInvestment.fee'),
                                type: FieldType.MONEY,
                                isMoney: true,
                                required: false,
                            },
                            
                        ]}
                    />
                    <InvestmentAllocation
                        currency = {values && values.investmentReturnsTradingCurrency?values.investmentReturnsTradingCurrency:localStorage.getItem("theCurrency")}
                        mutators = {mutators}
                        addInvestmentAllocation={mutators.push}
                        removeInvestmentAllocation={mutators.remove}
                        optionsData={optionsData}
                        values={values}
                    />
                            
                        
                </TabPanel>
                <TabPanel>
                    <AdditionalInvestmentTopUpPlan
                        values={values}
                        optionsData={optionsData}
                        addPlan={mutators.push}
                        removePlan={mutators.remove}
                        setFormAttribute={mutators.setFormAttribute}
                    />
                </TabPanel>
                <TabPanel>
                    <WithdrawalPlans
                        values={values}
                        optionsData={optionsData}
                        addPlan={mutators.push}
                        removePlan={mutators.remove}
                        setFormAttribute={mutators.setFormAttribute}
                        currency = {values.investmentReturnsTradingCurrency}
                        minDate={values.policyStartDate}
                        maxDate={values.policyMaturityDate}
                    />
                </TabPanel>
                <TabPanel>
                    <ContinuityBonus
                        addContinuityBonus={mutators.push}
                        removeContinuityBonus={mutators.remove}
                    />
                </TabPanel>
            
            </Tabs>
            <br/>
            <br/>
            <Tabs>
                <TabList>
                    <Tab>{intl.get("ilasInvestment.beneficiarydetails")}</Tab>
                    <Tab>{intl.get('ilasInvestment.riders')}</Tab>
                </TabList>

                <TabPanel>
                    <ILASBeneficaryDetails optionsData={optionsData} mutators={mutators}/>
                </TabPanel>
                
                <TabPanel>
                    <Riders addOtherRiders={push} removeOtherRiders={remove} />
                </TabPanel>
            </Tabs>
            
        </>
            
    );
}

export default ILASTabs;
