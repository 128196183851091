import React, { Component } from 'react';
import { Bar, Pie, Line } from 'react-chartjs-2';
import * as d3 from 'd3';
import { MalabarDefaultRightMenu } from '../../components';
import {
    DefaultBarChartOptions,
    DefaultLineChartOptions,
} from '../../helpers/ChartHelper';

export default class Chart extends Component {
    state = {
        dataBar: [
            {
                label: 'somethingA',
                values: [
                    { x: 'SomethingA', y: 10 },
                    { x: 'SomethingB', y: 4 },
                    { x: 'SomethingC', y: 3 },
                ],
            },
        ],
        dataLine: {
            label: '',
            values: [
                { x: new Date(2015, 2, 5), y: 1 },
                { x: new Date(2015, 2, 6), y: 2 },
                { x: new Date(2015, 2, 7), y: 0 },
                { x: new Date(2015, 2, 8), y: 3 },
                { x: new Date(2015, 2, 9), y: 2 },
                { x: new Date(2015, 2, 10), y: 3 },
                { x: new Date(2015, 2, 11), y: 4 },
                { x: new Date(2015, 2, 12), y: 4 },
                { x: new Date(2015, 2, 13), y: 1 },
                { x: new Date(2015, 2, 14), y: 5 },
                { x: new Date(2015, 2, 15), y: 0 },
                { x: new Date(2015, 2, 16), y: 1 },
                { x: new Date(2015, 2, 16), y: 1 },
                { x: new Date(2015, 2, 18), y: 4 },
                { x: new Date(2015, 2, 19), y: 4 },
                { x: new Date(2015, 2, 20), y: 5 },
                { x: new Date(2015, 2, 21), y: 5 },
                { x: new Date(2015, 2, 22), y: 5 },
                { x: new Date(2015, 2, 23), y: 1 },
                { x: new Date(2015, 2, 24), y: 0 },
                { x: new Date(2015, 2, 25), y: 1 },
                { x: new Date(2015, 2, 26), y: 1 },
            ],
        },
        xScale: d3
            .scaleTime()
            .domain([new Date(2015, 2, 5), new Date(2015, 2, 26)])
            .range([0, 400 - 70]),
        xScaleBrush: d3
            .scaleTime()
            .domain([new Date(2015, 2, 5), new Date(2015, 2, 26)])
            .range([0, 400 - 70]),
        dataStackedBar: [
            {
                label: 'somethingA',
                values: [
                    { x: 'SomethingA', y: 10 },
                    { x: 'SomethingB', y: -4 },
                    { x: 'SomethingC', y: 3 },
                ],
            },
            {
                label: 'somethingB',
                values: [
                    { x: 'SomethingA', y: 6 },
                    { x: 'SomethingB', y: 8 },
                    { x: 'SomethingC', y: -5 },
                ],
            },
            {
                label: 'somethingC',
                values: [
                    { x: 'SomethingA', y: -6 },
                    { x: 'SomethingB', y: 8 },
                    { x: 'SomethingC', y: 5 },
                ],
            },
        ],
        dataPie: {
            label: 'somethingA',
            values: [
                { x: 'SomethingA', y: 10 },
                { x: 'SomethingB', y: 2 },
                { x: 'SomethingC', y: 3 },
            ],
        },

        dataBar2: {
            labels: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
            ],
            datasets: [
                {
                    label: 'My First dataset',
                    backgroundColor: 'rgba(255,99,132,0.2)',
                    borderColor: 'rgba(255,99,132,1)',
                    borderWidth: 1,
                    stack: '1',
                    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                    hoverBorderColor: 'rgba(255,99,132,1)',
                    data: [65, 59, 80, 81, 56, 55, 40],
                },
                {
                    label: 'My Second dataset',
                    backgroundColor: 'rgba(155,49,12,0.4)',
                    borderColor: 'rgba(155,49,12,1)',
                    borderWidth: 1,
                    stack: '1',
                    hoverBackgroundColor: 'rgba(155,49,12,0.4)',
                    hoverBorderColor: 'rgba(155,59,12,1)',
                    data: [-5, -40, 10, -20, 65, 59, 80],
                },
            ],
        },
        dataPie2: {
            labels: ['Red', 'Green', 'Yellow'],
            datasets: [
                {
                    data: [300, 50, 100],
                    backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
                    hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
                },
            ],
        },
        dataLine2: {
            labels: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
            ],
            datasets: [
                {
                    label: 'My First dataset',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: [65, 59, 80, 81, 56, 55, 40],
                },
            ],
        },
    };

    onChange = extent => {
        this.setState({
            xScale: d3
                .scaleTime()
                .domain([extent[0], extent[1]])
                .range([0, 400 - 70]),
        });
    };

    tooltip = (x, y0, y) => y;

    tooltipPie = (x, y) => y;

    tooltipLine = (label, data) => `${label} x: ${data.x} y: ${data.y}`;

    render() {
        return (
            <React.Fragment>
                <div className="portal-maincontent personaldetails-2col">
                    <div className="content-panel">
                        <h1>Chart example</h1>
                    </div>

                    <div id="scroll-section" className="portal-form">
                        <Bar
                            data={this.state.dataBar2}
                            width={100}
                            height={50}
                            options={{
                                ...DefaultBarChartOptions,
                                maintainAspectRatio: false,
                            }}
                        />
                        <br />
                        <Pie data={this.state.dataPie2} />
                        <br />
                        <Line
                            data={this.state.dataLine2}
                            options={DefaultLineChartOptions}
                        />
                        {/* <br />
                        <BarChart
                            data={this.state.dataBar}
                            width={400}
                            height={400}
                            margin={{
                                top: 10,
                                bottom: 50,
                                left: 50,
                                right: 10,
                            }}
                        />
                        <br />
                        <div>
                            <LineChart
                                data={this.state.dataLine}
                                width={400}
                                height={400}
                                margin={{
                                    top: 10,
                                    bottom: 50,
                                    left: 50,
                                    right: 20,
                                }}
                                tooltipHtml={this.tooltipLine}
                                tooltipMode={'mouse'}
                                tooltipContained
                                xScale={this.state.xScale}
                                xAxis={{
                                    tickValues: this.state.xScale.ticks(
                                        d3.timeDay,
                                        2
                                    ),
                                    tickFormat: d3.timeFormat('%m/%d'),
                                }}
                            />
                            <div className="brush" style={{ float: 'none' }}>
                                <Brush
                                    width={400}
                                    height={50}
                                    margin={{
                                        top: 0,
                                        bottom: 30,
                                        left: 50,
                                        right: 20,
                                    }}
                                    xScale={this.state.xScaleBrush}
                                    extent={[
                                        new Date(2015, 2, 10),
                                        new Date(2015, 2, 12),
                                    ]}
                                    onChange={this.onChange}
                                    xAxis={{
                                        tickValues: this.state.xScaleBrush.ticks(
                                            d3.timeDay,
                                            2
                                        ),
                                        tickFormat: d3.timeFormat('%m/%d'),
                                    }}
                                    tooltipHtml={this.tooltipLine}
                                    tooltipMode={'mouse'}
                                />
                            </div>
                        </div>
                        <br />
                        <BarChart
                            data={this.state.dataStackedBar}
                            width={400}
                            height={400}
                            margin={{
                                top: 10,
                                bottom: 50,
                                left: 50,
                                right: 10,
                            }}
                            tooltipHtml={this.tooltip}
                            tooltipMode={'mouse'}
                        />
                        <br />
                        <PieChart
                            data={this.state.dataPie}
                            width={600}
                            height={400}
                            margin={{
                                top: 10,
                                bottom: 10,
                                left: 100,
                                right: 100,
                            }}
                            tooltipOffset={{ top: 175, left: 200 }}
                            tooltipHtml={this.tooltipPie}
                            tooltipMode={'fixed'}
                            sort={null}
                        /> */}
                    </div>
                </div>
                {/* <div className="content-panel">
                    <div className="page-arrow-holder">
                        <a href="#" className="arrow arrow-holder-L">
                            <div className="arrow-left" />
                        </a>
                        <a href="#" className="arrow arrow-holder-R">
                            <div className="arrow-right" />
                        </a>
                    </div>

                    <h1>{'Chart Example'}</h1>

                    <div id="scroll-section" className="portal-form">
                        <BarChart
                            data={this.state.dataBar}
                            width={400}
                            height={400}
                            margin={{
                                top: 10,
                                bottom: 50,
                                left: 50,
                                right: 10,
                            }}
                        />
                        <br />
                        <div>
                            <LineChart
                                data={this.state.dataLine}
                                width={400}
                                height={400}
                                margin={{
                                    top: 10,
                                    bottom: 50,
                                    left: 50,
                                    right: 20,
                                }}
                                tooltipHtml={this.tooltipLine}
                                tooltipMode={'mouse'}
                                tooltipContained
                                xScale={this.state.xScale}
                                xAxis={{
                                    tickValues: this.state.xScale.ticks(
                                        d3.timeDay,
                                        2
                                    ),
                                    tickFormat: d3.timeFormat('%m/%d'),
                                }}
                            />
                            <div className="brush" style={{ float: 'none' }}>
                                <Brush
                                    width={400}
                                    height={50}
                                    margin={{
                                        top: 0,
                                        bottom: 30,
                                        left: 50,
                                        right: 20,
                                    }}
                                    xScale={this.state.xScaleBrush}
                                    extent={[
                                        new Date(2015, 2, 10),
                                        new Date(2015, 2, 12),
                                    ]}
                                    onChange={this.onChange}
                                    xAxis={{
                                        tickValues: this.state.xScaleBrush.ticks(
                                            d3.timeDay,
                                            2
                                        ),
                                        tickFormat: d3.timeFormat('%m/%d'),
                                    }}
                                    tooltipHtml={this.tooltipLine}
                                    tooltipMode={'mouse'}
                                />
                            </div>
                        </div>
                        <br />
                        <BarChart
                            data={this.state.dataStackedBar}
                            width={400}
                            height={400}
                            margin={{
                                top: 10,
                                bottom: 50,
                                left: 50,
                                right: 10,
                            }}
                            tooltipHtml={this.tooltip}
                            tooltipMode={'mouse'}
                        />
                        <br />
                        <PieChart
                            data={this.state.dataPie}
                            width={600}
                            height={400}
                            margin={{
                                top: 10,
                                bottom: 10,
                                left: 100,
                                right: 100,
                            }}
                            tooltipOffset={{ top: 175, left: 200 }}
                            tooltipHtml={this.tooltipPie}
                            tooltipMode={'fixed'}
                            sort={null}
                        />
                    </div>
                </div> */}
                <MalabarDefaultRightMenu />
            </React.Fragment>
        );
    }
}
