import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    enumOptionsActions,
    modalWindowActions,
    appSettingsActions,
    dashboardActions,
    userActions
} from "../../actions";
import { LifeLineContext } from "../../pages/CurrentStanding/LifeLine";
import {
    GroupedOverviewBoxes,
    BoxClient,
    ScrollUpButton
} from "../../components";
import { MalabarClientRightMenu } from "../../components/MalabarMenu";
import { LifelineChart } from "../../components";
import { CurrentStandingApi } from "../../api";
import ChangePasswordDialog from "./ChangePasswordDialog";
import "./MainDashboard.css";

import { Link } from "react-router-dom";

export class ClientDashboard extends Component {
    state = {
        currentStandingData: "",
        currency: localStorage.getItem("theCurrency")
            ? localStorage.getItem("theCurrency")
            : "USD",
        clientID: ""
    };

    componentDidMount() {
        let idFromQuery = new URLSearchParams(window.location.search).get("id");

        if (idFromQuery) {
            this.state.clientID = idFromQuery;
        } else {
            this.state.clientID = localStorage.getItem("activeEntityId");
        }
        this.props.getClientDashboardDataById(this.state.clientID);
        this.props.setBurgerMenu(false);
        CurrentStandingApi.getLifeline(
            this.state.clientID,
            0,
            this.state.currency,
            0
        ).then(
            response => {
                this.setState({
                    currentStandingData: response.data
                });
            },
            error => {}
        );
    }

    componentWillReceiveProps(nextProps) {}

    render() {
        const { dashboardData, hasAcknowledgedTermsAndConditions } = this.props;

        return (
            <LifeLineContext.Provider
                value={{
                    lifelineObject: this.state.currentStandingData,
                    classification: this.state.classification
                }}
            >
                <React.Fragment>
                    <ChangePasswordDialog />

                    <div
                        className="height-100 dashboard-admin"
                        id="scroll-section"
                    >
                        <div className="portal-maincontent">
                            <div className="page-arrow-holder">
                                <span className="arrow arrow-holder-L">
                                    <div className="arrow-left" />
                                </span>{" "}
                                <span className="arrow arrow-holder-R">
                                    <div className="arrow-right" />
                                </span>
                            </div>
                            <div className="dashboard-content-panel">
                                {dashboardData.overviews && (
                                    <GroupedOverviewBoxes
                                        overviews={dashboardData.overviews}
                                        entityType={"CLIENT"}
                                    />
                                )}
                                <div className="flexbox info-client-dashboard justify-content-between">
                                    <div className="box-quarter">
                                        <BoxClient client={dashboardData} />
                                    </div>
                                    {/* add a lifeline */}
                                    <div className="box-three-quarter box-info">
                                        {hasAcknowledgedTermsAndConditions ? (
                                            <Link
                                                to={`/lifeline/${this.state.clientID}`}
                                                style={{
                                                    backgroundColor: "#16B7F1",
                                                    color: "#FFF",
                                                    padding: "4px 6px",
                                                    borderRadius: "4px"
                                                }}
                                            >
                                                See More
                                            </Link>
                                        ) : null}
                                        <div
                                            className="graph-holder"
                                            style={{ position: "relative" }}
                                        >
                                            {this.state.currentStandingData
                                                .client !== null &&
                                                this.state.currentStandingData
                                                    .client &&
                                                this.state.currentStandingData
                                                    .client.lifeline !==
                                                    undefined && (
                                                    <React.Fragment>
                                                        <LifelineChart
                                                            data={
                                                                this.state
                                                                    .currentStandingData
                                                                    .client
                                                            }
                                                            currentAge={
                                                                this.state
                                                                    .currentStandingData
                                                                    .client
                                                                    .ageInfo.age
                                                            }
                                                            currentYear={new Date().getFullYear()}
                                                            clientChart={true}
                                                        />
                                                    </React.Fragment>
                                                )}
                                        </div>
                                    </div>
                                </div>

                                {/* <ScrollUpButton sectionId={"scroll-section"} /> */}
                            </div>
                            <MalabarClientRightMenu />
                        </div>
                    </div>
                </React.Fragment>
            </LifeLineContext.Provider>
        );
    }
}

function mapStateToProps(state) {
    const { optionsData } = state.enumOptions;
    const { clientID, hasAcknowledgedTermsAndConditions } = state.entity;
    const {
        user: { id }
    } = state.authentication;

    const { activeEntityType } = state.appSettings;
    const { dashboardData } = state.dashboard;

    return {
        clientID,
        hasAcknowledgedTermsAndConditions,

        optionsData,
        activeEntityType,
        dashboardData,
        selectedEntity: state.authentication.user.selectedEntity
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        Object.assign(
            {},
            enumOptionsActions,
            modalWindowActions,
            appSettingsActions,
            dashboardActions,
            userActions
        ),
        dispatch
    );
}

ClientDashboard = connect(mapStateToProps, mapDispatchToProps)(ClientDashboard);

export default ClientDashboard;
