import React from "react";
import intl from "react-intl-universal";

import ILASTabs from "./ILASTabs";

import NetWorthFormButtons from "../NetWorthFormButtons";
import ClassificationSelection from "../../../components/_hook/ClassificationSelection";

import { FormSpy } from 'react-final-form';
const ILASInvestmentForm = ({
    handleSubmit,
    optionsData,
    mutators,
    reset,
    readOnlyAccessComponent,
    clientInformation: { client = {}, partner = {}, isJoint },
 
}) => (
    <div className="notification-dialog">
        <FormSpy>
            {({ values }) => (
                <form id="ilasInvestmentForm" onSubmit={handleSubmit}>
                
                        <ClassificationSelection
                            client={client}
                            partner={partner}
                            isJoint={isJoint}
                            hideJoint={!isJoint}
                        />
                        <ILASTabs values={values} optionsData={optionsData} mutators={mutators}/>
                
                    
                    <NetWorthFormButtons
                        reset={reset}
                        readOnlyAccess={readOnlyAccessComponent()}
                        showDelete={false}
                    />
                </form>
            )}
        </FormSpy>
    </div>
);

export default ILASInvestmentForm;
