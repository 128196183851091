import { occupationalRetirementSchemeOrdinanceAction } from '../../../constants/ActionConstants';

export function occupationalRetirementSchemeOrdinance(state = {}, action) {
    switch (action.type) {
        case occupationalRetirementSchemeOrdinanceAction.GET_ORSODETAIL_REQUEST:
            return {
                loading: true
            };
        case occupationalRetirementSchemeOrdinanceAction.GET_ORSODETAIL_SUCCESS:
            return {
                items: action.data
            };
        case occupationalRetirementSchemeOrdinanceAction.GET_ORSODETAIL_FAILURE:
            return {
                error: action.error
            };
        case occupationalRetirementSchemeOrdinanceAction.ADD_ORSODETAIL_REQUEST:
            return {
                loading: true
            };
        case occupationalRetirementSchemeOrdinanceAction.ADD_ORSODETAIL_SUCCESS:
            return {
                items: action.response
            };
        case occupationalRetirementSchemeOrdinanceAction.ADD_ORSODETAIL_FAILURE:
            return {
                error: action.error
            };
        case occupationalRetirementSchemeOrdinanceAction.EDIT_ORSODETAIL_REQUEST:
            return {
                loading: true
            };
        case occupationalRetirementSchemeOrdinanceAction.EDIT_ORSODETAIL_SUCCESS:
            return {
                items: action.response
            };
        case occupationalRetirementSchemeOrdinanceAction.EDIT_ORSODETAIL_FAILURE:
            return {
                error: action.error
            };
        case occupationalRetirementSchemeOrdinanceAction.DELETE_ORSODETAIL_REQUEST:
            return {
                loading: true
            };
        case occupationalRetirementSchemeOrdinanceAction.DELETE_ORSODETAIL_SUCCESS:
            return {
                items: action.response
            };
        case occupationalRetirementSchemeOrdinanceAction.DELETE_ORSODETAIL_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}