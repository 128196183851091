/*
    Author : Marlina (marlina@devstack.com.au)
    Author : Erik (erik.malik@devstack.com.au)
    Description : Personal contents main page (modal)
*/

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import intl from "react-intl-universal";
import { netWorthFormActions } from "../../actions/KYC/NetWorth/NetWorthFormActions";
import { modalWindowActions } from "../../actions";
import { modalWindow } from "../../constants/ModalWindowLists";
import NetWorthForm, {
    NetWorthPath,
    NetWorthFormPropsComposer
} from "./NetWorthForm";
import { PersonalContentForm } from "../../forms";
import { getCurrency } from "../../helpers";

class PersonalContents extends NetWorthForm {
    constructor(props) {
        super(
            props,
            intl.get("personalContents.title"),
            modalWindow.PERSONAL_CONTENT_FORM,
            NetWorthPath.personalContents,
            "PersonalContents"
        );
    }

    render() {
        const { optionsData, model, client, partner, isJoint, currency } = this.props;
        const { handleSave, contentWrapper, readOnlyAccessComponent } = this;
        const setDefaultData = originalModel => {
            const {
                itemLocation,
                currentValue,
                residualValue,
                loan = {}
            } = originalModel;

            if (client) {
                const { taxResidency } = client;
                const defaultCurrency = taxResidency?getCurrency(taxResidency):currency;

                if (!itemLocation) {
                    originalModel.itemLocation = taxResidency;
                }
                if (!currentValue) {
                    originalModel.currentValue = { currency: defaultCurrency };
                }
                if (!residualValue) {
                    originalModel.residualValue = { currency: defaultCurrency };
                }
                if (!loan || !loan.borrowingsCurrency) {
                    if (!originalModel.loan) {
                        originalModel.loan = {
                            borrowingsCurrency: defaultCurrency
                        };
                    }
                }
            }

            return originalModel;
        };

        return contentWrapper(
            <PersonalContentForm
                handleSave={handleSave}
                model={setDefaultData(model)}
                optionsData={optionsData}
                readOnlyAccess={readOnlyAccessComponent()}
                clientInformation={{ client, partner, isJoint }}
            />
        );
    }
}

function mapStateToProps(state) {
    return NetWorthFormPropsComposer(
        state,
        modalWindow.PERSONAL_CONTENT_FORM,
        NetWorthPath.personalContents
    );
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        Object.assign({}, modalWindowActions, netWorthFormActions),
        dispatch
    );
}

PersonalContents = connect(
    mapStateToProps,
    mapDispatchToProps
)(PersonalContents);

export default PersonalContents;
