import React, { useEffect, useState, useContext } from "react"
import api from "../../lib/api"
import CpdCptForm from "./CpdCptForm"
import { AppContext } from "../../context/AppContext"
import Tooltip from "rc-tooltip"
import { FaCheck, FaTrash } from "react-icons/fa"
import { AiOutlineClose } from "react-icons/ai"
import Alert from "react-s-alert"
const CpdCptTable = ({ refresh, setRefresh, type, availableCourses }) => {
  const loginEntityType = localStorage.getItem("loginEntityType")
  const [cpdData, setCpdData] = useState([])
  const {
    modal: { setModal, showConfirmationDialog, clearModal }
  } = useContext(AppContext)

  const getCpd = async () => {
    try {
      const data = await api.get("/cpd")
      // filter for cpd and cpt
      if (data.data.list.length) {
        let filtered = data.data.list.filter(
          (item) => item.learningCourse.courseType == type
        )
        setCpdData([...filtered])
      }
    } catch (error) {
      console.log("error is", error)
    }
  }
  useEffect(() => {
    getCpd()
  }, [refresh])
  // approve cpd
  const approveCpd = async ({ id }) => {
    try {
      await api.post("/cpd/approval", {
        action: "APPROVED",
        id: id
      })
      Alert.success("Approved Successfully")
      setRefresh(!refresh)
      clearModal()
    } catch (error) {
      console.log("error is", error)
    }
  }
  const rejectCpd = async ({ id }) => {
    try {
      await api.post("/cpd/approval", {
        action: "REJECTED",
        id: id
      })
      Alert.success("Rejected Successfully")
      setRefresh(!refresh)
      clearModal()
    } catch (error) {
      console.log("error is", error)
    }
  }
  const deleteCpd = async ({ id }) => {
    try {
      await api.delete(`/cpd/${id}`)
      Alert.success("Deleted Successfully")
      setRefresh(!refresh)
      clearModal()
    } catch (error) {
      console.log("error is", error)
    }
  }
  // tolltip
  const ToolTipCpd = ({ index, id, approvalStatus }) => {
    return (
      <Tooltip
        zIndex="1"
        placement="left"
        // trigger={["click"]}
        overlay={
          <div className="cover">
            <>
              <div
                className="menu-items"
                onClick={() => {
                  //   setActiveCpd(index);
                  setModal({
                    title: "Continuous Education Points Dialog Box",
                    formType: "cpd",
                    content: (
                      <CpdCptForm
                        clearModal={clearModal}
                        setRefresh={setRefresh}
                        refresh={refresh}
                        currentCpdData={cpdData[index]}
                        viewMode={true}
                        type={type}
                        availableCourses={availableCourses}
                      />
                    )
                  })
                }}
              >
                <span className="ico-eye table-icon iicon rm-padding"></span>
                <span>View</span>
              </div>
              {(approvalStatus == "WAITING" &&
                loginEntityType == "BUILT_IN_ADVISER") ||
              loginEntityType != "BUILT_IN_ADVISER" ? (
                <div
                  className="menu-items"
                  onClick={() => {
                    setModal({
                      title: "Continuous Education Points Dialog Box",
                      formType: "cpd",
                      content: (
                        <CpdCptForm
                          clearModal={clearModal}
                          setRefresh={setRefresh}
                          refresh={refresh}
                          currentCpdData={cpdData[index]}
                          type={type}
                          availableCourses={availableCourses}
                        />
                      )
                    })
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/v2Images/edit.png`}
                    alt="edit"
                  />{" "}
                  <span>Edit</span>
                </div>
              ) : (
                ""
              )}

              <>
                {approvalStatus == "WAITING" &&
                loginEntityType != "BUILT_IN_ADVISER" ? (
                  <div
                    className="menu-items"
                    onClick={() => {
                      showConfirmationDialog({
                        message: `You're about to approve Cpd. Are you sure?`,
                        onYes: () => {
                          approveCpd({
                            id: id
                          })
                        }
                      })
                    }}
                  >
                    <FaCheck />
                    <span>Approve</span>
                  </div>
                ) : (
                  ""
                )}
                {approvalStatus == "WAITING" &&
                loginEntityType != "BUILT_IN_ADVISER" ? (
                  <div
                    className="menu-items"
                    onClick={() => {
                      showConfirmationDialog({
                        message: `You're about to reject Cpd. Are you sure?`,
                        onYes: () => {
                          rejectCpd({
                            id: id
                          })
                        }
                      })
                    }}
                  >
                    <AiOutlineClose /> <span>Reject</span>
                  </div>
                ) : (
                  ""
                )}
                {approvalStatus == "WAITING" && (
                  <div
                    className="menu-items"
                    onClick={() => {
                      showConfirmationDialog({
                        message: `You're about to delete Cpd. Are you sure?`,
                        onYes: () => {
                          deleteCpd({
                            id: id
                          })
                        }
                      })
                    }}
                  >
                    <FaTrash />
                    <span>Delete</span>
                  </div>
                )}
              </>
            </>
          </div>
        }
        arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
      >
        <span className="btn btn-edit"></span>
      </Tooltip>
    )
  }

  return (
    <div>
      <table
        className="table-blue personal-dealings-table table-cs-popup_edu table-cs-popup_edu-estimate"
        cellSpacing="0"
        cellPadding="0"
        border="0"
      >
        <tbody>
          <tr>
            <th>#</th>
            {loginEntityType !== "BUILT_IN_ADVISER" && <th>Adviser Name</th>}
            <th>Year</th>
            <th>Date of Event</th>
            <th>Topic/Title</th>
            <th>Learning Objectives</th>
            <th>Category</th>
            <th>Points Awarded</th>
            <th>Status</th>
            <th>Certificate Issue Date</th>
            <th style={{ width: "80px" }}></th>
          </tr>
          {cpdData.length
            ? cpdData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    {loginEntityType !== "BUILT_IN_ADVISER" && (
                      <td>{item.adviserName}</td>
                    )}
                    <td>{item.year}</td>
                    <td>{item.eventDate}</td>
                    <td>
                      {item && item.learningCourse && item.learningCourse.topic}
                    </td>
                    <td>
                      {item &&
                        item.learningCourse &&
                        item.learningCourse.learningObjectives}
                    </td>
                    <td>
                      {item &&
                        item.learningCourse &&
                        item.learningCourse.category}
                    </td>

                    <td>
                      {item &&
                        item.learningCourse &&
                        item.learningCourse.pointsAwarded}
                    </td>

                    <td>
                      {item.approvalStatus == "APPROVED" ? (
                        <FaCheck />
                      ) : item.approvalStatus == "REJECTED" ? (
                        <AiOutlineClose />
                      ) : (
                        "Waiting"
                      )}
                    </td>

                    <td>{item.certificateIssuedDate}</td>
                    <td className="col-center">
                      <ToolTipCpd
                        zIndex="1"
                        index={index}
                        id={item.id}
                        approvalStatus={item.approvalStatus}
                      />
 
                    </td>
                  </tr>
                )
              })
            : ""}
        </tbody>
      </table>
    </div>
  )
}

export default CpdCptTable
