import { dashboardAction } from "../constants/ActionConstants"

const initialState = {
  dashboardData: {},
  dashboardBreadCrumb: "KoiZai",
  dashboardEntityToLoad: localStorage.getItem("entityToLoad"),
  dashboardEntityToCheck: localStorage.getItem("entityToCheck"),
  demoData: {}
}

export function dashboard(state = initialState, action) {

  switch (action.type) {
    case dashboardAction.GET_DATA_BY_TYPE_SUCCESS:
      return {
        ...state,
        dashboardData: action.response,
        dashboardUserData:
          action.entityType === "CLIENT"
            ? state.dashboardData.items
            : action.response.items,
        demoData: action.entityType === "DEMO_CLIENT" ? action.response : ""
      }
    case dashboardAction.GET_DATA_BY_ID_SUCCESS:
      return {
        ...state,
        dashboardData: action.response,
        dashboardUserData: action.response.items
      }
    case dashboardAction.SET_BREAD_CRUMB_LABEL:
      return {
        ...state,
        dashboardBreadCrumb: action.label
      }
    case dashboardAction.SET_DASHBOARD_ENTITY:
      return {
        ...state,
        dashboardEntityToLoad: action.entities.entityToLoad,
        dashboardEntityToCheck: action.entities.entityToCheck
      }
    case dashboardAction.GET_DATA_BY_FILTER_AND_PAGE:
      return {
        ...state,
        dashboardUserData: action.response,
        dashboardData: {
          ...state.dashboardData,
          items: action.response
        },
        demoData: action.entityType === "DEMO_CLIENT" ? action.response : ""
      }
    default:
      return state
  }
}
