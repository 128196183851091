/*
    Author : Erik (erik.malik@devstack.com.au)
    Description : Adviser Sub Branch dashboard page
*/

import React, { Component } from "react";
import intl from "react-intl-universal";
import {
    AlphabetSearch,
    BoxUser,
    GroupedOverviewBoxes
} from "../../../components";
import { MalabarDashboardRightMenu } from "../../../components/MalabarMenu";
import { EntityTypeEnum } from "../../../constants/Enums";

class AdviserGroupAdvisersDashboard extends Component {
    // TODO: get it from back end through store code:0992384302984
    getOverviews = () => [
        {
            title: "Weekly Sales Lodged",
            figure: "50",
            timing: "5% From Last week",
            color: "green",
            link: "/dashboard/adviser/weeklySalesLodged"
        },
        {
            title: "Monthly Revenue Lodged",
            figure: "$12,600",
            timing: "0% Last Year"
        },
        {
            title: "Top Business",
            figure: "Financial Planning Fees"
        },
        {
            title: "Monthly Revenue Received",
            figure: "$15,600",
            timing: "-10% From Last week",
            color: "red"
        },
        {
            title: "Sales Revenue Paid To Date",
            figure: "$150,890",
            timing: "0% Last Year"
        },
        {
            title: "Complaints",
            figure: "1",
            timing: "100% Last Year",
            color: "red"
        }
    ];

    randomNumber = () => Math.floor(Math.random() * Math.floor(1000000));

    // TODO: get it from back end through store
    getData = count => {
        const { randomNumber } = this;
        const users = [];
        Array.from(Array(count).keys()).forEach(i =>
            users.push({
                fullName: `Dummy User ${i}${randomNumber()}`,
                lastReviewed: "25/05/2018",
                aum: randomNumber(),
                ium: randomNumber(),
                goals: randomNumber(),
                image: "/image/user-profile.jpg",
                status: "Client"
            })
        );
        return users;
    };

    generateUsers = count =>
        Array.from(Array(count).keys()).map(i => ({
            image: "/image/user-profile.jpg",
            name: `Dummy ${i}`,
            location: `Location ${i}`,
            worth: i * 10000
        }));

    // TODO: get it from back end through store
    getTopPeopleData = () => {
        const { generateUsers } = this;
        return {
            title: "Monthly Top Advisers – ToloHP Shatin - Eagle",
            users: generateUsers(5)
        };
    };

    render() {
        const { getOverviews, getData, getTopPeopleData } = this;
        const overviews = getOverviews();
        const data = getData(3);
        getTopPeopleData();
        const entityType = EntityTypeEnum.EntityTypeEnum_BUILT_IN_ADVISER_GROUP;
        return (
            <React.Fragment>
                <div className="height-100 dashboard-advisergroups">
                    <div className="portal-maincontent">
                        <div className="dashboard-content-panel">
                            <GroupedOverviewBoxes overviews={overviews} />
                            <div className="flexbox">
                                {/* <MapLocation />
                                <TopPeopleBox
                                    title={topPeopleData.title}
                                    users={topPeopleData.users}
                                /> */}
                            </div>
                            <div className="box-full box-info">
                                <div className="title">
                                    <h1>{intl.get("dashboard.clients")}</h1>
                                    <div className="icon-holder">
                                        <span className="ico-print" />
                                    </div>
                                </div>
                                <div className="alphabet-box">
                                    <AlphabetSearch
                                        onSelect={letter =>
                                            console.log(
                                                `Letter ${letter} is selected!`
                                            )
                                        }
                                    />

                                    <div className="alphabet-R">
                                        <input
                                            type="search"
                                            placeholder="Search"
                                        />
                                    </div>
                                </div>

                                <div
                                    className="flexbox"
                                    id="flexbox"
                                    style={{
                                        position: "relative",
                                        gap: "30px",
                                        position:"relative"

                                        // marginBottom: "100px"
                                    }}
                                >
                                    {data.map((user, index) => (
                                        <BoxUser user={user} key={index} />
                                    ))}
                                </div>
                            </div>
                        </div>

                        <MalabarDashboardRightMenu
                            entityType={entityType}
                            
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AdviserGroupAdvisersDashboard;
