import { goalAction } from '../../../constants/ActionConstants';
import api from '../../../lib/api';

export const goalActions = {
    getClient,
    saveGoal,
    toogleSectionDisplay,
    showGoalDialog,
    hideGoalDialog,
    updateDataInStore,
    getLiquidAssets,
    getProperties,
};

function getClientSuccess(response) {
    return { type: goalAction.GET_CLIENT_SUCCESS, response };
}
function getClientWithPartnerSuccess(client, partner) {
    return {
        type: goalAction.GET_CLIENT_WITH_PARTNER_SUCCESS,
        client,
        partner,
    };
}
function getGoalSuccess(data) {
    return { type: goalAction.GET_GOAL_SUCCESS, data };
}
function saveGoalSuccess(data) {
    return { type: goalAction.SAVE_GOAL_SUCCESS, data };
}
function toogleSectionDisplay(sectionName) {
    return { type: goalAction.TOOGLE_SECTION_DISPLAY, sectionName };
}
function showGoalDialog(goalDialog) {
    return { type: goalAction.SHOW_GOAL_DIALOG, goalDialog };
}
function hideGoalDialog() {
    return { type: goalAction.HIDE_GOAL_DIALOG };
}
function updateDataInStore(data) {
    return { type: goalAction.UPDATE_DATA_IN_STORE, data };
}
function getLiquidAssetsSuccess(data) {
    return { type: goalAction.GET_LIQUID_ASSETS_GOAL_SUCCESS, data };
}
function getPropertiesSuccess(data) {
    return { type: goalAction.GET_PROPERTIES_GOAL_SUCCESS, data };
}

function getClient(id) {
    return dispatch => {
        api.get(`/client/${id}`).then(getClientResponse => {
            const client = getClientResponse.data;
            if (getClientResponse.data.classification === 'JOINT') {
                api.get(client._embedded.partner._links.self.href).then(
                    getPartnerResponse => {
                        dispatch(
                            getClientWithPartnerSuccess(
                                client,
                                getPartnerResponse.data
                            )
                        );
                    }
                );
            } else {
                dispatch(getClientSuccess(getClientResponse));
            }

            dispatch(getGoal(client));
        });
    };
}

function getGoal(client) {
    return dispatch => {
        api.get(client._embedded.goal._links.self.href).then(response => {
            // pre-process object that not related with client or partner
            dispatch(getGoalSuccess(preProcessGetGoal(response.data)));
        });
    };
}

function saveGoal(client, data) {
    // pre-process before saving
    const transformedObject = preProcessSaveGoal(data);
    return dispatch => {
        api.put(client._embedded.goal._links.self.href, transformedObject).then(
            response => {
                dispatch(saveGoalSuccess(preProcessGetGoal(response.data)));
            }
        );
    };
}

function preProcessGetGoal(goalData) {
    const newObject = Object.assign(goalData, {});

    // check for children education goal
    if (goalData.childrenEducationGoals) {
        newObject.educationGoal = {
            client: goalData.childrenEducationGoals,
        };
        delete newObject.childrenEducationGoals;
    }

    // check for investment goal
    if (goalData.investmentGoals) {
        newObject.investmentGoal = {
            client: goalData.investmentGoals,
        };
        delete newObject.investmentGoals;
    }

    // check for property goal
    if (goalData.hasOwnProperty('propertyGoal')) {
        newObject.propertyGoal = {
            client: goalData.propertyGoal,
        };
    }

    // check for lifestyle goal
    if (goalData.lifeStyleGoals) {
        newObject.lifeStyleGoals = {
            client: goalData.lifeStyleGoals,
        };
    }

    // check for philanthropy goal
    if (goalData.philanthropyGoals) {
        newObject.philanthropyGoals = {
            client: goalData.philanthropyGoals,
        };
    }

    // TODO: if any goal data need pre-processing here

    return newObject;
}

function preProcessSaveGoal(goalData) {
    const newObject = Object.assign(goalData, {});
    // check for children education goal
    if (goalData.educationGoal) {
        newObject.childrenEducationGoals = goalData.educationGoal.client;
        delete newObject.educationGoal;
    }

    // check for investment goal
    if (goalData.investmentGoal) {
        newObject.investmentGoals = goalData.investmentGoal.client;
        delete newObject.investmentGoal;
    }

    // check for property goal
    if (goalData.hasOwnProperty('propertyGoal')) {
        const currentProperty = Object.assign(goalData.propertyGoal.client, {});
        delete newObject.propertyGoal;
        newObject.propertyGoal = currentProperty;
    }

    // check for investment goal
    if (goalData.lifeStyleGoals) {
        newObject.lifeStyleGoals = goalData.lifeStyleGoals.client;
    }

    // check for philanthropy goal
    if (goalData.philanthropyGoals) {
        newObject.philanthropyGoals = goalData.philanthropyGoals.client;
    }

    // TODO: if any goal data need pre-processing here

    return newObject;
}

// get liquid assets data
function getLiquidAssets(id) {
    return dispatch => {
        api.get(`/client/${id}/reference/liquid-assets`).then(response => {
            const liquidAssets = response.data;
            dispatch(getLiquidAssetsSuccess(liquidAssets));
        });
    };
}

// get properties data
function getProperties(id) {
    return dispatch => {
        api.get(`/client/${id}/reference/properties`).then(response => {
            const properties = response.data;
            dispatch(getPropertiesSuccess(properties));
        });
    };
}
