import React, { PureComponent, Fragment } from "react"
import { Field, Form, FormSpy } from "react-final-form"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
// import { OnChange, onBlur } from "react-final-form-listeners";
import intl from "react-intl-universal"
import {
  RenderSimpleFields,
  ClassificationSelection
} from "../../../components"
import { FieldType } from "../../../components/Form/RenderSimpleFields"
import {
  getSymbolFromCode,
  renderMoneyCustom,
  renderSelectFieldCustom,
  renderTextBoxCustom,
  required,
  validateMoney
} from "../../../helpers"
import { connect } from "react-redux"
import { FieldArray } from "react-final-form-arrays"
import arrayMutators from "final-form-arrays"

class SalaryDetailsForm extends PureComponent {
  getSalaryDetailsField = (values) => {
    const { optionsData } = this.props
    let defaultCurrency = values.currency

    let arr = [
      {
        name: "employedWith",
        label: intl.get("salaryDetails.form.employedWith"),
        type: FieldType.TEXT,
        required: true
      },
      {
        name: "currency",
        label: intl.get("salaryDetails.form.currency"),
        type: FieldType.ENUM_SELECTION,
        enumData: optionsData.currencyOptions,
        required: true
      },

      {
        name: "salaryContributionFrequency",
        label: intl.get("salaryDetails.form.salaryBenefitFrequency"),
        type: FieldType.SELECTION,
        valueProperty: "value",
        labelProperty: "label",
        selectionData: [
          {
            value: "FORTNIGHTLY",
            label: intl.get("Frequency_FORTNIGHTLY")
          },
          { value: "MONTHLY", label: intl.get("Frequency_MONTHLY") },
          { value: "YEARLY", label: intl.get("Frequency_YEARLY") }
        ],
        required: true
      },
      {
        name: "salaryValue",
        label: intl.get("salaryDetails.form.salaryBenefitValue"),
        type: FieldType.MONEY,
        required: true,
        defaultCurrency: values.salaryValue
          ? values.salaryValue.currency
          : defaultCurrency,
        isMoney: true,
        symbolPrefix: values.salaryValue
          ? getSymbolFromCode(values.salaryValue.currency || "")
          : "",
        allowNegative: false
      },

      {
        name: "hasSalarySacrifice",
        label: intl.get("salaryDetails.form.salarySacrifice"),
        type: FieldType.CHECKBOX,
        required: false
      },

      {
        name: "bonusContributionFrequency",
        label: intl.get("salaryDetails.form.bonusBenefitFrequency"),
        type: FieldType.SELECTION,
        valueProperty: "value",
        labelProperty: "label",
        selectionData: [
          { value: "MONTHLY", label: intl.get("Frequency_MONTHLY") },
          {
            value: "QUARTERLY",
            label: intl.get("Frequency_QUARTERLY")
          },
          {
            value: "HALF_YEARLY",
            label: intl.get("Frequency_HALF_YEARLY")
          },
          { value: "YEARLY", label: intl.get("Frequency_YEARLY") }
        ],
        required: false
      },
      {
        name: "bonusValue",
        label: intl.get("salaryDetails.form.bonusBenefitValue"),
        type: FieldType.MONEY,
        required: false,
        defaultCurrency: values.salaryValue
          ? values.salaryValue.currency
          : defaultCurrency,
        isMoney: true,
        symbolPrefix: values.salaryValue
          ? getSymbolFromCode(values.salaryValue.currency || "")
          : "",
        allowNegative: false
      },
      {
        name: "annualIncrease",
        label: intl.get("salaryDetails.form.annualIncrease"),
        type: FieldType.PERCENTAGE
      },
      {
        name: "employmentStartDate",
        label: intl.get("salaryDetails.form.employmentStartDate"),
        type: FieldType.DATE,
        required: true
      },
      {
        name: "untilRetirementAge",
        label: "End Date until Retirement Age",
        type: FieldType.CHECKBOX
      },

      {
        name: "incomeTaxPercent",
        label: intl.get("salaryDetails.form.incomeTaxPercent"),
        type: FieldType.PERCENTAGE
      },
      {
        name: "incomeTaxAmount",
        label: intl.get("salaryDetails.form.incomeTaxAmount"),
        type: FieldType.MONEY,
        required: false,
        defaultCurrency: values.salaryValue
          ? values.salaryValue.currency
          : defaultCurrency,
        isMoney: true,
        symbolPrefix: values.salaryValue
          ? getSymbolFromCode(values.salaryValue.currency || "")
          : "",
        allowNegative: false
      },
      {
        name: "contributionTaxPercent",
        label: intl.get("salaryDetails.form.contributionTaxPercent"),
        type: FieldType.PERCENTAGE
      },
      {
        name: "contributionTaxAmount",
        label: intl.get("salaryDetails.form.contributionTaxAmount"),
        type: FieldType.MONEY,
        required: false,
        defaultCurrency: values.salaryValue
          ? values.salaryValue.currency
          : defaultCurrency,
        isMoney: true,
        symbolPrefix: values.salaryValue
          ? getSymbolFromCode(values.salaryValue.currency || "")
          : "",
        allowNegative: false
      }
    ]
    let result = arr

    if (this.props.defaultCurr !== "AUD") {
      result = arr.filter(
        (e) =>
          e.name != "hasSalarySacrifice" &&
          e.name !== "incomeTaxPercent" &&
          e.name !== "incomeTaxAmount" &&
          e.name !== "contributionTaxAmount" &&
          e.name !== "contributionTaxPercent"
      )
    }
    if (!values.untilRetirementAge) {
      result.splice(11, 0, {
        name: "employmentEndDate",
        label: intl.get("salaryDetails.form.employmentEndDate"),
        type: FieldType.DATE,
        show: values.untilRetirementAge
      })
      return result
    } else {
      delete values.employmentEndDate
      return result
    }
  }

  render() {
    const { model, onSave, client, partner, isJoint, defaultCurr } = this.props

    return (
      <Form
        onSubmit={onSave}
        initialValues={{
          ...model
          // untilRetirementAge:
          //   model.untilRetirementAge === true ||
          //   model.untilRetirementAge === false
          //     ? model.untilRetirementAge
          //     : false
        }}
        mutators={{ ...arrayMutators }}
        subscription={{ submitting: true, dirty: true }}
        keepDirtyOnReinitialize={true}
        render={({ handleSubmit, reset, mutators, values }) => {
          return (
            <form id="risk-profile" onSubmit={handleSubmit}>
              <div className="portal-form-popup">
                <FormSpy subscription={{ values: true }}>
                  {({ values }) => (
                    <Fragment>
                      <div
                        className={
                          values.hasSalarySacrifice ? "portal-form-half" : ""
                        }
                      >
                        {" "}
                        <ClassificationSelection
                          client={client}
                          partner={partner}
                          isJoint={isJoint}
                          hideJoint
                        />
                        <div className="subtitle2">
                          {intl.get("salaryDetails.details")}
                        </div>
                        <RenderSimpleFields
                          fields={this.getSalaryDetailsField(values)}
                        />
                        <div className="portal-form-row">
                          <label>
                            <b>Allowances</b>
                          </label>
                          {/* {!disabled && ( */}
                          <div className="row-holder">
                            <button
                              type="button"
                              onClick={() => {
                                mutators.push("allowances", {
                                  description: "",
                                  amount: {
                                    currency: defaultCurr,
                                    value: ""
                                  },
                                  frequency: ""
                                })
                              }}
                              className="btn btn-plus"
                            />
                          </div>
                        </div>
                        <FieldArray name="allowances">
                          {({ fields }) =>
                            fields.map((name, index) => (
                              <div key={index} className="portal-form-row">
                                <Field
                                  name={`${name}.description`}
                                  label="Description"
                                  required={true}
                                  component={renderTextBoxCustom}
                                  // isLabel={true}
                                  // isSalaryForm={true}
                                  validate={required}
                                />
                                <Field
                                  name={`${name}.frequency`}
                                  placeholder="Frequency"
                                  required={true}
                                  component={renderSelectFieldCustom}
                                  // isLabel={true}
                                  isSalaryForm={true}
                                  validate={required}
                                  data={[
                                    {
                                      value: "MONTHLY",
                                      label: intl.get("Frequency_MONTHLY")
                                    },
                                    {
                                      value: "QUARTERLY",
                                      label: intl.get("Frequency_QUARTERLY")
                                    },
                                    {
                                      value: "HALF_YEARLY",
                                      label: intl.get("Frequency_HALF_YEARLY")
                                    },
                                    {
                                      value: "YEARLY",
                                      label: intl.get("Frequency_YEARLY")
                                    }
                                  ]}
                                />
                                <Field
                                  name={`${name}.amount`}
                                  placeholder={intl.get(
                                    "salaryDetails.form.allowanceBenefitValue"
                                  )}
                                  component={renderMoneyCustom}
                                  required={true}
                                  validate={validateMoney}
                                  defaultCurrency={
                                    values.salaryValue
                                      ? values.salaryValue.currency
                                      : defaultCurr
                                  }
                                  isMoney={true}
                                  symbolPrefix={
                                    values.salaryValue
                                      ? getSymbolFromCode(
                                          values.salaryValue.currency || ""
                                        )
                                      : ""
                                  }
                                  allowNegative={false}
                                  isSalaryForm={true}
                                />

                                <button
                                  type="button"
                                  onClick={() => {
                                    mutators.remove("allowances", index)
                                  }}
                                  className="btn-remove networth-other-remove"
                                />
                              </div>
                            ))
                          }
                        </FieldArray>
                      </div>
                      {values.hasSalarySacrifice ? (
                        <div className="portal-form-half">
                          <Tabs>
                            <TabList>
                              <Tab>
                                {intl.get("salaryDetails.form.salarySacrifice")}
                              </Tab>
                            </TabList>
                            <TabPanel>
                              <RenderSimpleFields
                                fields={[
                                  {
                                    name: "salarySacrifice",
                                    label: intl.get(
                                      "salaryDetails.form.salarySacrifice"
                                    ),
                                    type: FieldType.MONEY,
                                    required: true,
                                    isMoney: true,
                                    symbolPrefix: values.salaryValue
                                      ? getSymbolFromCode(
                                          values.salaryValue.currency || ""
                                        )
                                      : "",
                                    allowNegative: false,
                                    onChange: this.onChangeSalarySacrifice
                                  }
                                ]}
                              />
                            </TabPanel>
                          </Tabs>
                        </div>
                      ) : (
                        ""
                      )}
                    </Fragment>
                  )}
                </FormSpy>
              </div>
              <div className="portal-btn-popup">
                <div className="col d-flex justify-content-end ">
                  <input
                    className="portal-btn portal-btn-submit"
                    value={intl.get("button.save")}
                    type="submit"
                  />
                </div>
              </div>
            </form>
          )
        }}
      />
    )
  }
}
function mapStateToProps(state) {
  const { defaultCurrency: defaultCurr } = state.personalDetails
  return {
    defaultCurr
  }
}
SalaryDetailsForm = connect(mapStateToProps, null)(SalaryDetailsForm)

export default SalaryDetailsForm
