/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Description : arrow indicator component for showing progress
*/

import React from 'react';

function getClassIndicator(valueCheck) {
    if (valueCheck > 0) {
        return 'performance-arrow-up';
    }
    if (valueCheck < 0) {
        return 'performance-arrow-down';
    }
    return 'performance-arrow-constant';
}

function getIconIndicator(valueCheck) {
    if (valueCheck > 0) {
        return 'fa-arrow-up';
    }
    if (valueCheck < 0) {
        return 'fa-arrow-down';
    }
    return 'fa-minus';
}

const ArrowIndicator = ({ leftValue, leftTitle, rightValue, rightTitle }) => {
    const leftClass = getClassIndicator(leftValue);
    const rightClass = getClassIndicator(rightValue);
    const leftIconClass = getIconIndicator(leftValue);
    const rightIconClass = getIconIndicator(rightValue);

    return (
        <div className="flexbox">
            <div className="box-half box-arrow">
                <div className={leftClass}>
                    <div className="arrow-title">{leftTitle}</div>
                    <div className="arrow-icon">
                        <i
                            className={`fa ${leftIconClass}`}
                            aria-hidden="true"
                        />
                    </div>
                    <div className="arrow-text">{leftValue}</div>
                </div>
            </div>
            <div className="box-half box-arrow">
                <div className={rightClass}>
                    <div className="arrow-title">{rightTitle}</div>
                    <div className="arrow-icon">
                        <i
                            className={`fa ${rightIconClass}`}
                            aria-hidden="true"
                        />
                    </div>
                    <div className="arrow-text">{rightValue}</div>
                </div>
            </div>
        </div>
    );
};

export default ArrowIndicator;
