/*
    Author : Erik (erik.malik@devstack.com.au)
    Description : Form buttons for networth form. Make it general for reusability
*/

import React from "react";
import intl from "react-intl-universal";

const NetWorthFormButtons = ({
    reset,
    readOnlyAccess,
    showDelete,
    state,
    type,
    disabled=false
}) => (
    <div
        className={`portal-btn-popup ${type === "superannuation" &&
            "NetWorthFormButtons"}`}
    >
        {!readOnlyAccess && (
            <React.Fragment>
                <div className="col-half">
                    {showDelete && (
                        <input
                            className="portal-btn portal-btn-cancel"
                            value={intl.get("button.delete")}
                            type="button"
                        />
                    )}
                </div>

                <div className="col-half">
                    <input
                        className="portal-btn portal-btn-submit"
                        value={intl.get("button.save")}
                        type="submit"
                        disabled={disabled}
                    />
                </div>
            </React.Fragment>
        )}
    </div>
);
export default NetWorthFormButtons;
