import { termDepositInformationAction } from '../../../constants/ActionConstants';

export function termDepositInformation(state = {}, action) {
    switch (action.type) {
        case termDepositInformationAction.GET_TERMDEPOSITINFORMATION_REQUEST:
            return {
                loading: true
            };
        case termDepositInformationAction.GET_TERMDEPOSITINFORMATION_SUCCESS:
            return {
                items: action.data
            };
        case termDepositInformationAction.GET_TERMDEPOSITINFORMATION_FAILURE:
            return {
                error: action.error
            };
        case termDepositInformationAction.ADD_TERMDEPOSITINFORMATION_REQUEST:
            return {
                loading: true
            };
        case termDepositInformationAction.ADD_TERMDEPOSITINFORMATION_SUCCESS:
            return {
                items: action.response
            };
        case termDepositInformationAction.ADD_TERMDEPOSITINFORMATION_FAILURE:
            return {
                error: action.error
            };
        case termDepositInformationAction.EDIT_TERMDEPOSITINFORMATION_REQUEST:
            return {
                loading: true
            };
        case termDepositInformationAction.EDIT_TERMDEPOSITINFORMATION_SUCCESS:
            return {
                items: action.response
            };
        case termDepositInformationAction.EDIT_TERMDEPOSITINFORMATION_FAILURE:
            return {
                error: action.error
            };
        case termDepositInformationAction.DELETE_TERMDEPOSITINFORMATION_REQUEST:
            return {
                loading: true
            };
        case termDepositInformationAction.DELETE_TERMDEPOSITINFORMATION_SUCCESS:
            return {
                items: action.response
            };
        case termDepositInformationAction.DELETE_TERMDEPOSITINFORMATION_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}