/*
    Author : Gredy Prasetya (gredy.prasetya@devstack.com.au)
    File : CashFlowSubheaderListing
    Description : header for cashflow sub-section (total section)
*/

import React from 'react';
import { MalabarMoneyCashflow } from '../../components';
import { getSymbolFromCode } from '../../helpers';

const CashFlowSubheaderListing = ({
    title,
    isJointHide,
    isAccordion,
    varPrefix,
    clientTotalValue,
    partnerTotalValue,
    jointTotalValue,
    currency = 'AUD',
}) => (
    <React.Fragment>
        <div className="portal-form-row portal-form-row-goal2col goals-text subheader">
            {title ? (
                <label className="no-toppadding">
                    <div className="header-button-plus subheader-title">
                        {title}{' '}
                        {isAccordion ? (
                            <div className="accordion__arrow" />
                        ) : (
                            ''
                        )}
                    </div>
                </label>
            ) : (
                <label className="no-toppadding">
                    <div className="header-button-plus " />
                </label>
            )}

            <div className="row-holder">
                <MalabarMoneyCashflow
                    value={{
                        currency,
                        value: clientTotalValue,
                    }}
                    name={`${varPrefix}.totalClient`}
                    isLabel={false}
                    isForGrid
                    required={false}
                    isRounded
                    disabled
                    symbolPrefix={getSymbolFromCode(currency)}
                />
            </div>
            {isJointHide ? (
                ''
            ) : (
                <div className="row-holder">
                    <MalabarMoneyCashflow
                        value={{
                            currency,
                            value: partnerTotalValue,
                        }}
                        name={`${varPrefix}.totalPartner`}
                        isLabel={false}
                        isForGrid
                        required={false}
                        isRounded
                        disabled
                        symbolPrefix={getSymbolFromCode(currency)}
                    />
                </div>
            )}
            {isJointHide ? (
                ''
            ) : (
                <div className="row-holder">
                    <MalabarMoneyCashflow
                        value={{
                            currency,
                            value: jointTotalValue,
                        }}
                        name={`${varPrefix}.totalJoint`}
                        isLabel={false}
                        isForGrid
                        required={false}
                        isRounded
                        disabled
                        symbolPrefix={getSymbolFromCode(currency)}
                    />
                </div>
            )}
        </div>
    </React.Fragment>
);

export default CashFlowSubheaderListing;
