import React, { useState, useEffect } from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { enumOptionsActions } from "../../../actions";
import { FormButtons, RenderSimpleFields } from "../../../components";
import { FieldType } from "../../../components/Form/RenderSimpleFields";
import arrayMutators from 'final-form-arrays';
import CPFApi from "../../../api/CPFApi";
import CPFClassification from "./CPFClassification";



const CPFBond = ({ optionsData, handleSave, model = {} }) => {
    const [issuers, setIssuers] = useState();
    const [bondTypes, setBondTypes] = useState();
    if (!model.tradingCurrency) {
        Object.assign(model, { tradingCurrency: 'SGD' })
    }

    useEffect(() => {

        if (!issuers) {
            CPFApi.getAgentBanks().then(({ data }) => {
                const a = data.map(a => ({ label: a.name, value: a.id }));
                setIssuers(a);
            });
        }

        if (!bondTypes) {
            CPFApi.getBondTypes().then(({ data }) => {
                const a = data.map(a => ({ label: a.name, value: a.id }));
                setBondTypes(a);
            });
        }
        

    }, [issuers, bondTypes])
    return (
        <div>
            <Form
                onSubmit={handleSave}
                initialValues={model}
                mutators={{
                    // potentially other mutators could be merged here
                    ...arrayMutators,
                }}
                initialValuesEqual={() => true}
                render={({
                    handleSubmit,
                    reset,
                    values
                }) => (
                        <form
                            id="cpfCashAccount"
                            onSubmit={handleSubmit}
                            noValidate
                        >
                            <div className="portal-form-popup">
                                <CPFClassification value={model.classification} disabled />

                                <div className="subtitle2">
                                    Bonds Details
                                </div>
                                <RenderSimpleFields
                                    fields={[
                                        {
                                            name: 'bondIssuer',
                                            label: 'Bond Issuer',
                                            type: FieldType.SELECTION,
                                            selectionData: issuers
                                        },
                                        {
                                            name: 'bondNumber',
                                            label: 'Bond Name',
                                            type: FieldType.TEXT,
                                        },
                                        {
                                            name: 'bondType',
                                            label: 'Type of Bonds',
                                            type: FieldType.SELECTION,
                                            selectionData: bondTypes
                                        },
                                        {
                                            name: 'purchasedDate',
                                            label: 'Date Purchased',
                                            type: FieldType.DATE,
                                        },
                                        {
                                            name: 'tradingCurrency',
                                            label: 'Trading Currency',
                                            type: FieldType.ENUM_SELECTION,
                                            enumData: optionsData.currencyOptions,
                                        },
                                        {
                                            name: 'couponRate',
                                            label: 'Coupon (Interest) Rate',
                                            type: FieldType.PERCENTAGE,
                                        },
                                        {
                                            name: 'faceValue',
                                            label: 'Face Value',
                                            type: FieldType.MONEY,
                                        },
                                        {
                                            name: 'maturityDate',
                                            label: 'Maturity Date',
                                            type: FieldType.DATE,
                                        },
                                        {
                                            name: 'brokageFee',
                                            label: 'Brokerage Fee',
                                            type: FieldType.MONEY_WITH_PERCENTAGE,
                                            getPercentageValueSource: () => values.currentValue,
                                            currency: values.tradingCurrency,
                                            allowNegative: false,
                                        },

                                    ]}
                                />
                            </div>
                            <FormButtons
                                reset={reset}
                            />
                        </form>
                    )}
            />
        </div>


    )
}


function mapStateToProps(state) {
    const { optionsData } = state.enumOptions;

    return {
        optionsData,
    };
}

function mapDispatchToProps(dispatch) {

    return bindActionCreators(enumOptionsActions, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CPFBond);