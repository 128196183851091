import api from '../lib/api';

// change base route for different entity/domain here

class CurrencyApi {
    // get current entity
    static get() {
        return api.get(`/currency/all`);
    }

    static getForFXRates() {
        return api.get(`/currency/allWithDate`);
    }
}

export default CurrencyApi;
