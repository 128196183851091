import React, { useState, useContext } from "react"
import { createUrlFromPath } from "../../helpers/ImageHelper"
import { menuItems } from "../../helpers/EntityMenuHelper"
import { AppContext } from "../../context/AppContext"
import UploadPhoto from "./UploadPhoto"
import DeleteEntity from "./DeleteEntity"
import intl from "react-intl-universal"
import TransferDialog from "./TransferDialog"
import { FaUndo } from "react-icons/fa"

import Tooltip from "rc-tooltip"

import ChangeStatusDialog from "./ChangeStatusDialog"
import TransferMultiDialog from "./TransferMultiDialog"
import { EntityTypeEnum } from "../../constants/Enums"
import ClientPermanentDelete from "./ClientPermanentDelete"
import { history, numberFormat } from "../../helpers"
import UserApi from "../../api/UserApi"
import Alert from "react-s-alert"
import { isLoginAsGlobal } from "../../helpers/SessionHelper"

function TeamBoxUser({ user, keyIndex, fetchLatest, editEntity }) {
  const {
    modal: { setModal, clearModal }
  } = useContext(AppContext)

  const [isShowTransferForm, setShowTransferForm] = useState([])
  const closeTransferForm = () => {
    setShowTransferForm(false)
  }

  function convertClientsIntoItems(clients) {
    let result = []
    clients.forEach((client) => {
      result.push({ id: client.key, name: client.name, selected: false })
    })
    return result
  }

  const isClickAllowed = (user) => {
    let result = false
    if (isLoginAsGlobal()) {
      if (user.grantKoizaiGlobal == true) {
        result = true
      }
    } else {
      result = true
    }

    return result
  }

  function goToPath(path) {
    UserApi.track(path)
    history.push(path)
  }

  function onCardClicked(user, path) {
    if (isClickAllowed(user)) {
      goToPath(path)
    } else {
      Alert.error(`Sorry, you don't have permission to see this data.`)
    }
  }

  return (
    <div className="v2-box-user" key={keyIndex}>
      <img
        src={`${process.env.PUBLIC_URL}/assets/images/v2Images/profileUser.png`}
        alt="profile"
        className="personIcon"
      />

      {/* card */}
      <div className="card-box">
        <div
          className="banner"
          style={{
            backgroundImage: "url(" + createUrlFromPath(user.imagePath) + ")",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat"
          }}
        >
          <div className="images">
            <img
              onClick={() =>
                setModal({
                  title: "Upload Photo Profile",
                  content: (
                    <UploadPhoto
                      id={user.id}
                      currentPhotoUrl={user.imagePath}
                      clearModal={clearModal}
                      isClient={user.type === "CLIENT"}
                      type={user.type}
                    />
                  ),
                  isSmall: true
                })
              }
              src={`${process.env.PUBLIC_URL}/assets/images/v2Images/camera.png`}
              alt="profile"
              className="camera"
            />
            <Tooltip
              zIndex="1"
              placement="right"
              // trigger={["click"]}
              overlay={
                <div className="cover">
                  {user.type && user.type === "CLIENT" && (
                    <>
                      {user.info &&
                        user.info.clientCategoryStatus === "Archive" && (
                          <>
                            <div
                              className="menu-items"
                              onClick={() =>
                                setModal({
                                  title: `UPDATE ${user.type} STATUS`,

                                  content: (
                                    <ChangeStatusDialog
                                      id={user.id}
                                      item={user}
                                      clearModal={clearModal}
                                      fetchLatest={() => {
                                        fetchLatest(`sortBy=client1`)
                                      }}
                                    />
                                  ),
                                  isSmall: true
                                })
                              }
                            >
                              <FaUndo />
                              <span>Restore Archived</span>
                            </div>

                            <div
                              className="menu-items"
                              onClick={() =>
                                setModal({
                                  title: `${
                                    user.type === "CLIENT"
                                      ? "Archive Client"
                                      : "Delete Organization"
                                  }`,
                                  content: (
                                    <ClientPermanentDelete
                                      userId={user.id}
                                      clearModal={clearModal}
                                      type={user.type}
                                    />
                                  ),
                                  isSmall: true
                                })
                              }
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/images/v2Images/delete.png`}
                                alt="delete"
                              />{" "}
                              <span>Permanent Delete </span>
                            </div>
                          </>
                        )}
                    </>
                  )}

                  {user.entityType &&
                    user.entityType.replace("BUILT_IN_", "") === "ADVISER" && (
                      <>
                        <div
                          className="menu-items"
                          onClick={() =>
                            setModal({
                              title: `TRANSFER CLIENT(S)`,

                              content: (
                                <TransferMultiDialog
                                  parent={{
                                    id: user.id,
                                    name: user.name
                                  }}
                                  type={EntityTypeEnum.EntityTypeEnum_CLIENT}
                                  items={convertClientsIntoItems(user.clients)}
                                  clearModal={clearModal}
                                />
                              ),
                              isSmall: true
                            })
                          }
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/v2Images/transfer.svg`}
                            alt="transfer"
                          />{" "}
                          <span>Transfer Client(s)</span>
                        </div>

                        <div
                          className="menu-items"
                          onClick={() => editEntity(user.entityType, user.id)}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/v2Images/edit.png`}
                            alt="edit"
                          />{" "}
                          <span>Edit </span>
                        </div>
                      </>
                    )}

                  <div
                    className="menu-items"
                    onClick={() =>
                      setModal({
                        title: `TRANSFER ${user.type}`,

                        content: (
                          <TransferDialog
                            id={user.id}
                            item={user}
                            clearModal={clearModal}
                          />
                        ),
                        isSmall: true
                      })
                    }
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/v2Images/transfer.svg`}
                      alt="transfer"
                    />{" "}
                    <span>Transfer </span>
                  </div>

                  {((user.info &&
                    user.info.clientCategoryStatus !== "Archive") ||
                    user.entityType === "BUILT_IN_ADVISER") && (
                    <div
                      className="menu-items"
                      onClick={() =>
                        setModal({
                          title: `${
                            user.type === "CLIENT"
                              ? "Archive Client"
                              : "Delete Organization"
                          }`,
                          content: (
                            <DeleteEntity
                              userId={user.id}
                              clearModal={clearModal}
                              isClient={user.type === "CLIENT"}
                              type={user.type}
                              cc={user.email}
                            />
                          ),
                          isSmall: true
                        })
                      }
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/v2Images/delete.png`}
                        alt="delete"
                      />{" "}
                      <span
                        style={{
                          color: "red"
                        }}
                      >
                        Delete
                      </span>
                    </div>
                  )}
                </div>
              }
              arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
            >
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/v2Images/3dot.png`}
                alt="profile"
                className="3dot"
              />
            </Tooltip>
          </div>
        </div>
        <div className="box-user-description-v2">
          {user.entityType === "BUILT_IN_ADVISER" ? (
            <>
              <div className="details">
                <p className="clientName">{user.name}</p>
                <p>
                  {intl.get("address.country")}:
                  <span className="value">{user.country}</span>
                </p>
                <p>
                  {intl.get("client.aum")}:
                  <span className="value">
                    {user &&
                      user.aum &&
                      user.aum.value &&
                      numberFormat(user.aum.value, user.aum.currency)}
                  </span>
                </p>
                <p>
                  {intl.get("client.ium")}:
                  <span className="value">
                    {user &&
                      user.ium &&
                      user.ium.value &&
                      numberFormat(user.ium.value, user.ium.currency)}
                  </span>
                </p>

                <p>
                  {intl.get("adviser.managerInCharge")}:
                  <span className="value">{user.managerInCharge}</span>
                </p>
                <p>
                  {intl.get("client.entityName")}:
                  <span className="value">{user.teamName}</span>
                </p>
              </div>
            </>
          ) : (
            <div
              className="details"
              onClick={(e) =>
                onCardClicked(user, `/personaldetails/${user.id}`)
              }
            >
              <p className="clientName">{user.info.clientName}</p>
              <p>
                {intl.get("client.lastSubmission")}:
                <span className="value">{user.info.lastSubmission}</span>
              </p>
              <p>
                {intl.get("client.aum")}:
                <span className="value">
                  {user.info &&
                    user.info.aum &&
                    user.info.aum.value &&
                    numberFormat(user.info.aum.value, user.info.aum.currency)}
                </span>
              </p>
              <p>
                {intl.get("client.ium")}:
                <span className="value">
                  {user.info &&
                    user.info.ium &&
                    user.info.ium.value &&
                    numberFormat(user.info.ium.value, user.info.ium.currency)}
                </span>
              </p>
              <p>
                {intl.get("client.adviserName")}:
                <span className="value">{user.info.adviserName}</span>
              </p>
              <p>
                {intl.get("client.entityName")}:
                <span className="value">{user.info.entityName}</span>
              </p>
            </div>
          )}
        </div>
      </div>
      {/* end of card */}
    </div>
  )
}

const getUrlPrefix = (entityType) => {
  const findEntityItem = menuItems.find((o) => o.entityType === entityType)
  return findEntityItem.pluralName
}

export default TeamBoxUser
