import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Form } from "react-final-form"
import arrayMutators from "final-form-arrays"
import intl from "react-intl-universal"
import { getPrefixedColors } from "./../../../helpers/ColorHelper"
import NetWorthForm, {
  NetWorthPath,
  NetWorthFormPropsComposer
} from "../NetWorthForm"
import NetWorthFormButtons from "./../NetWorthFormButtons"
import { netWorthFormActions } from "./../../../actions/KYC/NetWorth/NetWorthFormActions"
import { modalWindowActions } from "../../../actions"
import { AppContext } from "../../../context/AppContext"
import { modalWindow } from "./../../../constants/ModalWindowLists"
import CentralProvidenceFundChart from "./CentralProvidenceFundChart"
import CentralProvidenceBoardDetailsLeftSide from "./CentralProvidenceBoardDetailsLeftSide"
import CentralProvidenceBoardDetailsRightSide from "./CentralProvidenceBoardDetailsRightSide"
import CPFAccountAndInvestmentDetails from "./CPFAccountAndInvestmentDetails"

class CentralProvidenceFundInformation extends NetWorthForm {
  constructor(props) {
    super(
      props,
      intl.get("centralProvidenceFundInformation.title"),
      modalWindow.CENTRAL_PROVIDENCE_FUND_INFORMATION,
      NetWorthPath.centralProvidenceFundInformation, // TODO: change when the api is ready
      "CentralProvidenceFundInformation"
    )
    this.oneDataOnly = true
  }

  state = {
    dataPie: {
      labels: ["Platinum", "Gold", "Silver", "Bronze"],
      datasets: [
        {
          data: [70, 23, 31, 35]
        }
      ]
    }
  }
  generateChartData = (values) => {
    const unitTrusts = [
      { unitTrustName: "Platinum", valuePercentage: 70 },
      { unitTrustName: "Gold", valuePercentage: 23 },
      { unitTrustName: "Silver", valuePercentage: 11 },
      { unitTrustName: "Bronze", valuePercentage: 35 }
    ]
    const colorsArray = getPrefixedColors(unitTrusts.length)
    return {
      labels: unitTrusts.map((item) => item.unitTrustName),
      datasets: [
        {
          data: unitTrusts.map((item) => item.valuePercentage),
          backgroundColor: colorsArray,
          hoverBackgroundColor: colorsArray
        }
      ]
    }
  }

  // save = values => {
  //     console.log(values);
  // }
  newModel = () => {
    if (this.props.model.cpfAccountNumber) {
      return Object.assign({}, this.props.model)
    }
    return { isNew: true }
  }

  render() {
    const {
      contentWrapper,
      readOnlyAccessComponent,
      generateChartData,
      handleSave
    } = this

    return contentWrapper(
      <Form
        onSubmit={handleSave}
        initialValues={this.props.model}
        mutators={{
          pushToArray: arrayMutators.push,
          removeFromArray: arrayMutators.remove,
          updateArray: arrayMutators.update,
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value)
          },
          // potentially other mutators could be merged here
          ...arrayMutators
        }}
        render={({ handleSubmit, reset, values, form: { mutators } }) => (
          <form id="CentralProvidenceFundInformation" onSubmit={handleSubmit}>
            <div className="portal-form-popup">
              <div className="d-flex">
                <div className="section">
                  <CentralProvidenceBoardDetailsLeftSide
                    mutators={mutators}
                    values={values}
                  />
                </div>
                <div className="section">
                  <CentralProvidenceBoardDetailsRightSide
                    values={values}
                    mutators={mutators}
                  />
                  <div>
                    <CentralProvidenceFundChart
                      chartData={generateChartData(values)}
                    />
                  </div>
                </div>
              </div>
              <CPFAccountAndInvestmentDetails
                mutators={mutators}
                model={values}
              />
            </div>

            <NetWorthFormButtons
              reset={reset}
              readOnlyAccess={readOnlyAccessComponent()}
            />
          </form>
        )}
      />
    )
  }
}

function mapStateToProps(state) {
  const props = NetWorthFormPropsComposer(
    state,
    modalWindow.CENTRAL_PROVIDENCE_FUND_INFORMATION,
    NetWorthPath.centralProvidenceFundInformation
  )
  return {
    ...props
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, netWorthFormActions, modalWindowActions),
    dispatch
  )
}

CentralProvidenceFundInformation.contextType = AppContext

CentralProvidenceFundInformation = connect(
  mapStateToProps,
  mapDispatchToProps
)(CentralProvidenceFundInformation)

export default CentralProvidenceFundInformation
