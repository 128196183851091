import { isArray } from "lodash"
import {
  clientAction,
  netWorthAction,
  netWorthFormAction
} from "../../../constants/ActionConstants"

import { clientDataAdapter } from "../../../helpers/ClientHelper"

export function netWorth(state = {}, action) {
  switch (action.type) {
    case netWorthFormAction.SET_SALARIES_LIST_SUCCESS:
      return {
        ...state,
        salaries: action.salaries
      }
    case clientAction.GET_CLIENT_SUCCESS: {
      return {
        ...state,
        client: clientDataAdapter(action.response.data),
        isJoint: false,
        loading: false,
        ableToUseCpf: isAbleToUseCpf(action.response.data) //20200908 add CPF
      }
    }
    case netWorthAction.CLEAR_ACCORDION: {
      return {
        ...state,
        openedAccordion: []
      }
    }
    case netWorthAction.TOGGLE_ACCORDION: {
      let openedAccordion = []

      if (
        state.openedAccordion &&
        state.openedAccordion.includes(action.accordionName)
      ) {
        openedAccordion =
          // state.openedAccordion.length !== 7 &&
          state.openedAccordion.filter((o) => o !== action.accordionName)
      } else if (state.openedAccordion && state.openedAccordion.length > 0) {
        openedAccordion = [...state.openedAccordion, action.accordionName]
      } else {
        openedAccordion = [action.accordionName]
      }

      return {
        ...state,
        openedAccordion
      }
    }
    case clientAction.GET_CLIENT_WITH_PARTNER_SUCCESS: {
      return {
        ...state,
        client: clientDataAdapter(action.client),
        partner: clientDataAdapter(action.partner),
        isJoint: true,
        loading: false,
        ableToUseCpf: isAbleToUseCpf(action.client)
      }
    }
    case netWorthAction.GET_PERSONAL_ASSETS_LISTING_SUCCESS: {
      return {
        ...state,
        personalAssetsListing: changeClassificationIntoName(
          state,
          action.personalAssetsListing
        )
      }
    }
    case netWorthAction.GET_LIQUID_ASSETS_LISTING_SUCCESS: {
      return {
        ...state,
        liquidAssetsListing: changeClassificationIntoName(
          state,
          action.liquidAssetsListing
        )
      }
    }
    case netWorthAction.GET_ILLIQUID_ASSETS_LISTING_SUCCESS: {
      return {
        ...state,
        illiquidAssetsListing: changeClassificationIntoName(
          state,
          action.illiquidAssetsListing
        )
      }
    }
    case netWorthAction.GET_RETIREMENT_ASSETS_LISTING_SUCCESS: {
      return {
        ...state,
        retirementAssetsListing: changeClassificationIntoName(
          state,
          action.retirementAssetsListing
        )
      }
    }
    case netWorthAction.GET_GEAL_SUCCESS: {
      return {
        ...state,
        gealListing: changeClassificationIntoName(state, action.gealListing)
      }
    }
    case netWorthAction.GET_LIABILITIES_LISTING_SUCCESS: {
      return {
        ...state,
        liabilitiesListing: changeClassificationIntoName(
          state,
          action.liabilitiesListing
        )
      }
    }
    case netWorthAction.GET_TAXATION_LISTING_SUCCESS: {
      const { taxationListing } = action
      const { personalTax } = taxationListing

      taxationListing.personalTax = convertOnedataOnlyListing(
        state.isJoint,
        personalTax
      )

      return {
        ...state,
        taxationListing: changeClassificationIntoName(state, taxationListing)
      }
    }
    case netWorthAction.GET_EMPLOYMENT_TERMINATION_PAYMENTS_SUCCESS: {
      const { employmentTerminationPaymentsListing } = action
      const { employmentTerminationPayments } =
        employmentTerminationPaymentsListing

      employmentTerminationPaymentsListing.employmentTerminationPayments =
        convertOnedataOnlyListing(state.isJoint, employmentTerminationPayments)

      return {
        ...state,
        employmentTerminationPaymentsListing: changeClassificationIntoName(
          state,
          employmentTerminationPaymentsListing
        )
      }
    }
    case netWorthAction.GET_NET_WORTH_TOTAL_SUMMARY_SUCCESS: {
      return {
        ...state,
        totalSummary: changeClassificationIntoNameForSummary(
          state,
          action.totalSummary
        )
      }
    }
    case netWorthAction.UPDATE_LISTING_FILTER: {
      return {
        ...state,
        listingFilter: action.filter
      }
    }
    case netWorthAction.OPEN_CONFIRMATION_DIALOG: {
      const { onYes } = action
      return {
        ...state,
        confirmationDialog: {
          show: true,
          onYes
        }
      }
    }
    case netWorthAction.CLOSE_CONFIRMATION_DIALOG: {
      return {
        ...state,
        confirmationDialog: {
          show: false
        }
      }
    }
    case netWorthAction.GET_EMPLOYEE_MANDATORY_CONTRIBUTION: {
      return {
        ...state,
        employeeMandatoryContribution: action.contribution.contributionInPercent
      }
    }
    case netWorthAction.GET_EMPLOYER_MANDATORY_CONTRIBUTION: {
      return {
        ...state,
        employerMandatoryContribution: action.contribution.contributionInPercent
      }
    }
    default:
      return state
  }
}

function isAbleToUseCpf(client) {
  return (
    isCitizenOfSingapore(client.personalDetails) ||
    hasSingaporeOverseasPensionScheme(client.personalDetails) ||
    hasSingaporeTaxResidence(client.personalDetails)
  )
}

function isCitizenOfSingapore(personalDetails) {
  return (
    personalDetails.residencyDetails &&
    personalDetails.residencyDetails.residencyStatus === "CITIZEN" &&
    personalDetails.residencyDetails.citizenship &&
    personalDetails.residencyDetails.citizenship.country === "SG"
  )
}

function hasSingaporeOverseasPensionScheme(personalDetails) {
  return (
    personalDetails.countriesOverseasPensionSchemes &&
    personalDetails.countriesOverseasPensionSchemes
      .map((countriesOverseasPensionScheme) =>
        countriesOverseasPensionScheme.toLowerCase()
      )
      .includes("singapore")
  )
}

// 20200907 check if tax residency is SG or Singapore
function hasSingaporeTaxResidence(personalDetails) {
  return (
    personalDetails.employmentDetails &&
    personalDetails.employmentDetails.taxResidency &&
    (personalDetails.employmentDetails.taxResidency.toLowerCase() ===
      "singapore" ||
      personalDetails.employmentDetails.taxResidency.toLowerCase() === "sg")
  )
}

function changeClassificationIntoNameForSummary(state, data) {
  const temp = changeClassificationIntoName(state, data, true)
  return temp
}

function changeClassificationIntoName(state, data, isSummary = false) {
  const clientName =
    state && state.client
      ? state.client.personalDetails.personalInformation.firstName
      : ""
  const partnerName = state.partner
    ? state.partner.personalDetails.personalInformation.firstName
    : ""

  Object.keys(data).forEach((key) => {
    if (isSummary) {
      changeName(data[key], clientName, partnerName)
    } else {
      const listingItem = data[key] || []
      if (isArray(listingItem)) {
        listingItem.forEach((item) => {
          changeName(item, clientName, partnerName)
        })
      }
    }
  })
  return data
}

function changeName(data, clientName, partnerName) {
  const classification = data.classification || ""
  if (classification === "CLIENT") {
    data.classification = "Client"
  } else if (classification === "PARTNER") {
    data.classification = "Partner"
  } else if (classification === "JOINT") {
    data.classification = "Joint"
  }
}

function convertOnedataOnlyListing(isJoint, data) {
  let preparedData
  if (isJoint) {
    preparedData = [
      {
        ...(data.client || {}),
        classification: "CLIENT"
      },
      {
        ...(data.partner || {}),
        classification: "PARTNER"
      }
    ]
  } else {
    preparedData = [
      {
        ...(data.client || {}),
        classification: "CLIENT"
      }
    ]
  }

  return preparedData
}
