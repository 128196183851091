import { createStore, applyMiddleware } from "redux"
import ReduxPromise from "redux-promise"
import thunkMiddleware from "redux-thunk"
import { createLogger } from "redux-logger"
import reducers from "./reducers"

// redux
const loggerMiddleware = createLogger()
const createStoreWithMiddleware = applyMiddleware(
  ReduxPromise,
  thunkMiddleware
  // loggerMiddleware
)(createStore)
const storeApp = createStoreWithMiddleware(
  reducers,
  process.env.REACT_APP_NODE_ENV === "development" &&
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__()
)
export default storeApp
