import Alert from 'react-s-alert';
import intl from 'react-intl-universal';
import { cashFlowAction } from '../../constants/ActionConstants';
import { CashFlowApi } from '../../api';

export const cashFlowActions = {
    getClientCashflow,
    editClientCashflow,
};

export function getClientSuccess(response) {
    return { type: cashFlowAction.GET_CLIENT_SUCCESS, response };
}

export function editClientSuccess(response) {
    return { type: cashFlowAction.EDIT_CLIENT_CASHFLOW_SUCCESS, response };
}

export function getClientCashflow(id, currency) {
    return dispatch => {
        // api request here
        CashFlowApi.get(id, currency).then(response => {
            dispatch(getClientSuccess(response));
            
        });
    };
}

export function editClientCashflow(id, model) {
    return async dispatch => {
        // api request here
        CashFlowApi.edit(id, model).then(response => {
            dispatch(editClientSuccess(response));
            Alert.success(
                intl.get('alert.editDataSuccess', {
                    name: intl.get('leftmenu.cashflow'),
                })
            );
        });
    };
}
