import api from '../lib/api';

class CPFApi {
    // get current adviser
    static getInsuranceCompany() {
        return api.get(`/global-enum/root-list?infoTypeEnum=ENUM_INSURANCE_COMPANY`);
    }

    static getPolicyTypes(id) {
        return api.get(`/global-enum/children-list?infoTypeEnum=ENUM_INSURANCE_COMPANY&parentId=${id}`);
    }
    static getProducts(id) {
        return api.get(`/global-enum/children-list?infoTypeEnum=ENUM_INSURANCE_COMPANY&parentId=${id}`);
    }

    static getAgentBanks() {
        return api.get(`/global-enum/root-list?infoTypeEnum=ENUM_BOND_ISSUER`);
    }

    static getBondTypes() {
        return api.get(`/global-enum/root-list?infoTypeEnum=ENUM_BOND_TYPE`);
    }


}

export default CPFApi;
