import React, { useState, useEffect, useContext } from 'react';
import { Field } from 'react-final-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import intl from 'react-intl-universal';
import { renderCurrencyCustom, composeValidators } from '../../../helpers';
import { isValidDate } from '../../../helpers/Validator';
import {
    renderDateFieldCustom,
    renderSelectEnumFieldCustom,
    renderSelectFieldCustom,
    renderTextBoxCustom
} from '../../../helpers/FormRender';
import { enumOptionsActions } from '../../../actions/EnumOptionsActions';
import Menu, { MenuItem } from 'rc-menu';
import Dropdown from 'rc-dropdown';
import { AppContext } from '../../../context/AppContext';
import CPFCashAccount from './CPFCashAccount';
import CPFTermDeposit from './CPFTermDeposit';
import CPFUnitTrust from './CPFUnitTrust';
import CPFBond from './CPFBond';
import CPFTreasuryBills from './CPFTreasuryBills';
import CPFExchangeTrade from './CPFExchangeTrade';
import CPFInvestmentLink from './CPFInvestmentLink';
import CPFInvestmentPlatform from './CPFInvestmentPlatform';
import CPFApi from '../../../api/CPFApi';



function CPFISOA({ optionsData, mutators, model }) {
    const [agentBanks, setAgentBanks] = useState();
    const {
        modal: { setModal, clearModal },
    } = useContext(AppContext);
    if (!model.cpfIsOA) {
        Object.assign(model, { cpfIsOA: { investmentCurrency: 'SGD' } })
    }

    const editItem = (name, value, idx) => {
        switch (name) {
            case 'Cash Account':
                var handleSaveCA = (model) => {
                    mutators.setValue('cpfIsOA.cashAccount', model);
                    clearModal();
                }
                setModal({
                    title: 'Cash Account Information – CPFIS Singapore',
                    content: <CPFCashAccount handleSave={handleSaveCA} model={value} />,
                    formType: 'CPFModal',
                });
                break;
            case 'Term Deposit':
                var handleSaveTD = (model) => {
                    mutators.update('cpfIsOA.termDeposits', idx, model);
                    clearModal();
                }
                setModal({
                    title: 'Term Deposit Information – CPFIS Singapore',
                    content: <CPFTermDeposit handleSave={handleSaveTD} model={value} />,
                    formType: 'CPFModal',
                });
                break;
            case 'Unit Trusts':
                var handleSaveUT = (model) => {
                    mutators.update('cpfIsOA.unitTrusts', idx, model);
                    clearModal();
                }
                setModal({
                    title: 'Unit Trust Holding Information – CPFIS Singapore',
                    content: <CPFUnitTrust handleSave={handleSaveUT} model={value} />,
                    formType: 'CPFModal',
                });
                break;
            case 'Bonds':
                var handleSaveB = (model) => {
                    mutators.update('cpfIsOA.bonds', idx, model);
                    clearModal();
                }
                setModal({
                    title: 'Bonds Holding Information – CPFIS Singapore',
                    content: <CPFBond handleSave={handleSaveB} model={value} />,
                    formType: 'CPFModal',
                });
                break;
            case 'Treasury Bills':
                const handleSaveTB = (model) => {
                    mutators.update('cpfIsOA.treasuryBills', idx, model);
                    clearModal();
                }
                setModal({
                    title: 'Treasury Bills Information – CPFIS Singapore',
                    content: <CPFTreasuryBills handleSave={handleSaveTB} model={value} />,
                    formType: 'CPFModal',
                });
                break;
            case 'Exchange Trade Fund (ETF)':
                const handleSaveETF = (model) => {
                    mutators.update('cpfIsOA.exchangeTradeFunds', idx, model);
                    clearModal();
                }
                setModal({
                    title: 'Exchange Trade Fund  (ETF) Holding Information – CPFIS Singapore',
                    content: <CPFExchangeTrade handleSave={handleSaveETF} model={value} />,
                    formType: 'CPFModal',
                });
                break;
            case 'Insurance Linked Products':
                const handleSaveILP = (model) => {
                    mutators.update('cpfIsOA.investmentLinkedProducts', idx, model);
                    clearModal();
                }
                setModal({
                    title: 'Exchange Trade Fund  (ETF) Holding Information – CPFIS Singapore',
                    content: <CPFInvestmentLink handleSave={handleSaveILP} model={value} />,
                    formType: 'CPFInvestmentLink',
                });
                break;
            default: break;

        }

    }


    const deleteItem = (name, idx) => {
        switch (name) {
            case 'Cash Account':
                mutators.setValue('cpfIsOA.cashAccount', null);
                break;
            case 'Term Deposit':
                mutators.remove('cpfIsOA.termDeposits', idx);
                break;
            case 'Unit Trusts':
                mutators.remove('cpfIsOA.unitTrusts', idx);
                break;
            case 'Bonds':
                mutators.remove('cpfIsOA.bonds', idx);
                break;
            case 'Treasury Bills':
                mutators.remove('cpfIsOA.treasuryBills', idx);
                break;
            case 'Exchange Trade Fund (ETF)':
                mutators.remove('cpfIsOA.exchangeTradeFunds', idx);
                break;
            case 'Insurance Linked Products':
                mutators.remove('cpfIsOA.investmentLinkedProducts', idx);
                break;
            default: break;

        }
    }

    const generateCashAccount = () => {
        if (model.cpfIsOA) {
            if (model.cpfIsOA.cashAccount) {
                return (
                    <tr >
                        <td>{model.cpfIsOA.cashAccount.financialInstitutionName}</td>
                        <td>{'Cash Account'}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan="2">
                            <button
                                type="button"
                                onClick={() => editItem('Cash Account', model.cpfIsOA.cashAccount, 0)}
                                className="btn btn-edit btn-padding"
                            />
                            <button type="button"
                                onClick={() => deleteItem('Cash Account', model.cpfIsOA.cashAccount, 0)}
                                className="btn-remove"></button>
                        </td>
                    </tr>
                );
            }
        }
    }

    const generatetermDeposits = () => {

        if (model.cpfIsOA) {
            if (model.cpfIsOA.termDeposits) {
                return model.cpfIsOA.termDeposits.map((a, idx) => (
                    <tr key={idx}>
                        <td>{a.financialInstitutionName}</td>
                        <td>{'Term Deposit'}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan="2">
                            <button
                                type="button"
                                onClick={() => editItem('Term Deposit', a, idx)}
                                className="btn btn-edit btn-padding"
                            />
                            <button type="button"
                                onClick={() => deleteItem('Term Deposit', a, idx)}
                                className="btn-remove"></button>
                        </td>
                    </tr>
                ));
            }
        }
    }
    const generateUnitTrusts = () => {
        if (model.cpfIsOA) {

            if (model.cpfIsOA.unitTrusts) {
                return model.cpfIsOA.unitTrusts.map((a, idx) => (
                    <tr key={idx}>
                        <td>{a.financialInstitutionName}</td>
                        <td>{'Unit Trust'}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan="2">
                            <button
                                type="button"
                                onClick={() => editItem('Unit Trusts', a, idx)}
                                className="btn btn-edit btn-padding"
                            />
                            <button type="button"
                                onClick={() => deleteItem('Unit Trusts', a, idx)}
                                className="btn-remove"></button>
                        </td>
                    </tr>
                ));
            }
        }
    }
    const generateBonds = () => {
        if (model.cpfIsOA) {
            if (model.cpfIsOA.bonds) {
                return model.cpfIsOA.bonds.map((a, idx) => (
                    <tr key={idx}>
                        <td>{a.financialInstitutionName}</td>
                        <td>{'Bond'}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan="2">
                            <button
                                type="button"
                                onClick={() => editItem('Bonds', a, idx)}
                                className="btn btn-edit btn-padding"
                            />
                            <button type="button"
                                onClick={() => deleteItem('Bonds', a, idx)}
                                className="btn-remove"></button>
                        </td>
                    </tr>
                ));
            }
        }
    }
    const generateTreasuryBills = () => {
        if (model.cpfIsOA) {
            if (model.cpfIsOA.treasuryBills) {
                return model.cpfIsOA.treasuryBills.map((a, idx) => (
                    <tr key={idx}>
                        <td>{a.financialInstitutionName}</td>
                        <td>{'Treasury Bill'}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan="2">
                            <button
                                type="button"
                                onClick={() => editItem('Treasury Bills', a, idx)}
                                className="btn btn-edit btn-padding"
                            />
                            <button type="button"
                                onClick={() => deleteItem('Treasury Bills', a, idx)}
                                className="btn-remove"></button>
                        </td>
                    </tr>
                ));
            }
        }
    }
    const generateExchangeTradeFunds = () => {
        if (model.cpfIsOA) {
            if (model.cpfIsOA.exchangeTradeFunds) {
                return model.cpfIsOA.exchangeTradeFunds.map((a, idx) => (
                    <tr key={idx}>
                        <td>{a.financialInstitutionName}</td>
                        <td>{'Exchange Trade Fund (ETF)'}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan="2">
                            <button
                                type="button"
                                onClick={() => editItem('Exchange Trade Fund (ETF)', a, idx)}
                                className="btn btn-edit btn-padding"
                            />
                            <button type="button"
                                onClick={() => deleteItem('Exchange Trade Fund (ETF)', idx)}
                                className="btn-remove"></button>
                        </td>
                    </tr>
                ));
            }
        }
    }
    const generateInsuranceLinkedProducts = () => {
        if (model.cpfIsOA) {
            if (model.cpfIsOA.investmentLinkedProducts) {
                return model.cpfIsOA.investmentLinkedProducts.map((a, idx) => (
                    <tr key={idx}>
                        <td>{a.financialInstitutionName}</td>
                        <td>{'Insurance Linked Products'}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan="2">
                            <button
                                type="button"
                                onClick={() => editItem('Insurance Linked Products', a, idx)}
                                className="btn btn-edit btn-padding"
                            />
                            <button type="button"
                                onClick={() => deleteItem('Insurance Linked Products', idx)}
                                className="btn-remove"></button>
                        </td>
                    </tr>
                ));
            }
        }
    }


    const showModal = (value) => {
        switch (value.value) {
            case 'Cash Account':
                var handleSaveCA = (model) => {
                    mutators.setValue('cpfIsOA.cashAccount', model);
                    clearModal();
                }
                setModal({
                    title: 'Cash Account Information – CPFIS Singapore',
                    content: <CPFCashAccount handleSave={handleSaveCA} model={{ classification: model.classification }} />,
                    formType: 'CPFModal',
                });
                break;
            case 'Term Deposit':
                var handleSaveTD = (model) => {
                    mutators.push('cpfIsOA.termDeposits', model);
                    clearModal();
                }
                setModal({
                    title: 'Term Deposit Information – CPFIS Singapore',
                    content: <CPFTermDeposit handleSave={handleSaveTD} model={{ classification: model.classification }} />,
                    formType: 'CPFModal',
                });
                break;
            case 'Unit Trusts':
                var handleSaveUT = (model) => {
                    mutators.push('cpfIsOA.unitTrusts', model);
                    clearModal();
                }
                setModal({
                    title: 'Unit Trust Holding Information – CPFIS Singapore',
                    content: <CPFUnitTrust handleSave={handleSaveUT} model={{ classification: model.classification }} />,
                    formType: 'CPFModal',
                });
                break;
            case 'Bonds':
                var handleSaveB = (model) => {
                    mutators.push('cpfIsOA.bonds', model);
                    clearModal();
                }
                setModal({
                    title: 'Bonds Holding Information – CPFIS Singapore',
                    content: <CPFBond handleSave={handleSaveB} model={{ classification: model.classification }} />,
                    formType: 'CPFModal',
                });
                break;
            case 'Treasury Bills':
                const handleSaveTB = (model) => {
                    mutators.push('cpfIsOA.treasuryBills', model);
                    clearModal();
                }
                setModal({
                    title: 'Treasury Bills Information – CPFIS Singapore',
                    content: <CPFTreasuryBills handleSave={handleSaveTB} model={{ classification: model.classification }} />,
                    formType: 'CPFModal',
                });
                break;
            case 'Exchange Trade Fund (ETF)':
                const handleSaveETF = (model) => {
                    mutators.push('cpfIsOA.exchangeTradeFunds', model);
                    clearModal();
                }
                setModal({
                    title: 'Exchange Trade Fund  (ETF) Holding Information – CPFIS Singapore',
                    content: <CPFExchangeTrade handleSave={handleSaveETF} model={{ classification: model.classification }} />,
                    formType: 'CPFModal',
                });
                break;
            case 'Insurance Linked Products':
                const handleSaveILP = (model) => {
                    mutators.push('cpfIsOA.investmentLinkedProducts', model);
                    clearModal();
                }
                setModal({
                    title: 'Investment Linked Life Insurance And Annuity Information - CPFIS',
                    content: <CPFInvestmentLink handleSave={handleSaveILP} model={{ classification: model.classification }} />,
                    formType: 'CPFInvestmentLink',
                });
                break;
            default: break;

        }

    }
    const menuItems = () => {
        return [
            { label: 'Cash Account', value: 'Cash Account' },
            { label: 'Term Deposit', value: 'Term Deposit' },
            { label: 'Unit Trusts', value: 'Unit Trusts' },
            { label: 'Bonds', value: 'Bonds' },
            { label: 'Treasury Bills', value: 'Treasury Bills' },
            { label: 'Insurance Linked Products', value: 'Insurance Linked Products' },
            { label: 'Exchange Trade Fund (ETF)', value: 'Exchange Trade Fund (ETF)' },
            // { label: 'Investment Platform', value: 'Investment Platform' },
        ]
    };

    useEffect(() => {
        if (!agentBanks) {
            CPFApi.getAgentBanks().then(({ data }) => {
                const a = data.map(a => ({ label: a.name, value: a.id }));
                setAgentBanks(a);
            });
        }

        

    }, [agentBanks])
    return (
        <React.Fragment>
            <div >
                <div className="subtitle2 mt-3">
                    {intl.get('centralProvidenceFundInformation.CPFISOATitle')}
                </div>
                <div className="d-flex w-100">
                    <div className="d-flex flex-column w-30">
                        <Field
                            name="cpfIsOA.cpfisAccountNumber"
                            component={renderTextBoxCustom}
                            label={intl.get(
                                'centralProvidenceFundInformation.CPFISAccount'
                            )}
                            isLabel
                        />

                        <Field
                            name="cpfIsOA.cpfisAgentBank"
                            component={renderSelectFieldCustom}
                            data={agentBanks}
                            label={intl.get(
                                'centralProvidenceFundInformation.CPFISAgentBank'
                            )}
                            isLabel
                        />
                    </div>

                    <div className="d-flex flex-column w-30">

                        <Field
                            name="cpfIsOA.currentTotalInvestmentValue"
                            component={renderCurrencyCustom}
                            label={intl.get(
                                'centralProvidenceFundInformation.CurrentInvestmentValue'
                            )}
                            isLabel
                        />

                        <div className="portal-form-row" >
                            <label>
                                {intl.get(
                                    'centralProvidenceFundInformation.valuationDate')
                                }
                            </label>
                            <Field
                                name="cpfIsOA.valuationDate"
                                component={renderDateFieldCustom}

                                validate={composeValidators(isValidDate)}
                                isLabel
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-column w-30">
                        <Field
                            name={'cpfIsOA.investmentCurrency'}
                            component={renderSelectEnumFieldCustom}
                            enumData={optionsData.currencyOptions}
                            label={intl.get(
                                'centralProvidenceFundInformation.InvestmentCurrency'
                            )}
                            isLabel
                        />
                    </div>

                </div>


            </div>

            <table
                className="table-investment mt-3"
                width="100%"
                cellSpacing="0"
                cellPadding="0"
                border="0"
            >
                <thead>
                    <tr>
                        <th>
                            {intl.get(
                                'centralProvidenceFundInformation.investmentName'
                            )}
                        </th>
                        <th>
                            {intl.get('centralProvidenceFundInformation.type')}
                        </th>
                        <th>
                            {intl.get(
                                'centralProvidenceFundInformation.unitHeld'
                            )}
                        </th>
                        <th>
                            {intl.get(
                                'centralProvidenceFundInformation.unitPrice'
                            )}
                        </th>
                        <th>
                            {intl.get('centralProvidenceFundInformation.cost')}
                        </th>
                        <th>
                            {intl.get('centralProvidenceFundInformation.value')}
                        </th>
                        <th>
                            {intl.get(
                                'centralProvidenceFundInformation.currentAllocation'
                            )}
                        </th>

                        <th />
                        <th>
                            <Dropdown
                                trigger={['click']}
                                overlay={
                                    <Menu
                                        onSelect={menu => showModal(menuItems()[menu.key])}
                                    >
                                        {menuItems().map((menu, idx) => (
                                            <MenuItem key={idx}>
                                                {menu.label}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                }
                                animation="slide-up"
                            >
                                <span className="btn btn-plus" />
                            </Dropdown>
                        </th>
                    </tr>

                </thead>
                <tbody>
                    {generateBonds()}
                    {generateCashAccount()}
                    {generateExchangeTradeFunds()}
                    {generateInsuranceLinkedProducts()}
                    {generateUnitTrusts()}
                    {generatetermDeposits()}
                    {generateTreasuryBills()}
                </tbody>
            </table>

            <div>
                <CPFInvestmentPlatform mutators={mutators} model={Object.assign({ classification: model.classification }, model.cpfIsOA)} fieldName={'cpfIsOA'} />
            </div>
        </React.Fragment >
    );
}

function mapStateToProps(state) {
    const { optionsData } = state.enumOptions;
    return {
        optionsData,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(enumOptionsActions, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CPFISOA);
