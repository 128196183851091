import React, { Component } from "react"
import intl from "react-intl-universal"
import NetWorthListing from "./NetWorthListing"
import NetWorthListingSummary from "./NetWorthListingSummary"

class NetWorthListingGroup extends Component {
  constructor(
    props,
    groupName,
    listingsGetter,
    listingDefinition,
    hideSubTotal = false
  ) {
    super(props)
    this.groupName = groupName
    this.listingsGetter = listingsGetter
    this.listingDefinition = listingDefinition
    this.hideSubTotal = hideSubTotal
  }

  // 20210713 expand all
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    const { listing, client, toggleAccordion, clearAccordion } = this.props
    const { groupName, listingsGetter } = this

    if (this.props.listing !== prevProps.listing) {
      if (!listing) {
        listingsGetter(client)
      }
    }
  }

  componentDidMount() {
    const { groupName } = this
    const { toggleAccordion, clearAccordion, openedAccordion } = this.props

    toggleAccordion(groupName)
  }
  componentWillUnmount() {
    const { clearAccordion } = this.props

    clearAccordion()
  }

  togglePane = () => {
    const { listing, client, toggleAccordion } = this.props
    const { groupName, listingsGetter } = this
    if (!listing) {
      listingsGetter(client)
    }
    toggleAccordion(groupName)
  }

  isPanelOpened = () => {
    const { openedAccordion } = this.props
    const { groupName } = this
    return openedAccordion && openedAccordion.includes(groupName)
  }

  showListingForm = (index, path, modalName, title) => {
    const { setUpdateNetWorthForm, showModalWindow, client } = this.props
    setUpdateNetWorthForm(index, client, path, title)
    showModalWindow(modalName)
  }

  showOneDataOnlyListingForm = (index, path, modalName) => {
    const {
      setOneDataOnlyUpdateNetWorthForm,
      showModalWindow,
      client,
      isJoint
    } = this.props

    setOneDataOnlyUpdateNetWorthForm(
      index === 0 ? "CLIENT" : "PARTNER",
      client,
      path,
      isJoint
    )

    showModalWindow(modalName)
  }

  deleteFromListing = (index, path, isOneDataOnly) => {
    const { client, deleteNetWorthForm, isJoint, currency } = this.props
    deleteNetWorthForm(index, client, path, isOneDataOnly, isJoint, currency)
  }

  filterListing = () => {
    const { listing, listingFilter } = this.props

    if (listingFilter && listingFilter !== "All") {
      const listingCopy = { ...listing }
      Object.keys(listingCopy).forEach((key) => {
        listingCopy[key] =
          listingCopy[key] &&
          listingCopy[key].length &&
          listingCopy[key].filter(
            (item) => item.classification === listingFilter
          )
      })

      return listingCopy
    }

    return listing
  }

  contentWrapper = (content) => {
    const {
      togglePane,
      listingDefinition,
      showListingForm,
      showOneDataOnlyListingForm,
      deleteFromListing,
      groupName,
      isPanelOpened,
      filterListing,
      props,
      hideSubTotal
    } = this

    const { openConfirmationDialog, currency, client, partner } = props
    const filteredListing = filterListing()

    let pensionSchemes = new Set()
    if (client && client.personalDetails) {
      let arrClientPensionSchemes =
        client.personalDetails &&
        client.personalDetails.countriesOverseasPensionSchemes
          ? client.personalDetails.countriesOverseasPensionSchemes
          : []
      pensionSchemes = new Set(arrClientPensionSchemes)
      if (
        client.personalDetails.employmentDetails &&
        client.personalDetails.employmentDetails.taxResidency
      ) {
        pensionSchemes.add(
          client.personalDetails.employmentDetails.taxResidency
        )
      }
    }

    if (partner && partner.personalDetails) {
      let arrPartnerPensionSchemes =
        partner.personalDetails &&
        partner.personalDetails.countriesOverseasPensionSchemes
          ? partner.personalDetails.countriesOverseasPensionSchemes
          : []
      if (arrPartnerPensionSchemes) {
        arrPartnerPensionSchemes.forEach((el) => {
          pensionSchemes.add(el)
        })
      }

      if (
        partner.personalDetails.employmentDetails &&
        partner.personalDetails.employmentDetails.taxResidency
      ) {
        pensionSchemes.add(
          partner.personalDetails.employmentDetails.taxResidency
        )
      }
    }

    let isContainsHK = pensionSchemes && pensionSchemes.has("HK")
    let isContainsAU = pensionSchemes && pensionSchemes.has("AU")
    let isContainsMY = pensionSchemes && pensionSchemes.has("MY")
    let isContainsSG = pensionSchemes && pensionSchemes.has("SG")

    return (
      <div
        className={`portal-part  ${
          client && client.status === "ARCHIVE"
            ? "personal-details-disabled"
            : ""
        }`}
      >
        <div className="accordion__title subtitle2" onClick={togglePane}>
          {intl.get(`${groupName}.title`)}
          <div
            className={isPanelOpened() ? "acc-arrow-up" : "acc-arrow-down"}
          />
        </div>
        {isPanelOpened() && (
          <React.Fragment>
            {listingDefinition.map((definition, idx) => {
              const showAddButton = definition.showAddButton || (() => true)

              switch (definition.modalName) {
                case "retirementSchemeMpf": {
                  if (this.props.client && isContainsHK) {
                    return (
                      <NetWorthListing
                        key={idx}
                        currency={currency}
                        title={definition.title}
                        path={definition.path}
                        showAddButton={showAddButton(filteredListing)}
                        showForm={(index) => {
                          if (definition.oneDataOnly) {
                            showOneDataOnlyListingForm(
                              index,
                              definition.path,
                              definition.modalName
                            )
                          } else {
                            showListingForm(
                              index,
                              definition.path,
                              definition.modalName
                            )
                          }
                        }}
                        data={
                          filteredListing
                            ? filteredListing[definition.path.split(".")[1]]
                            : []
                        }
                        onDelete={(index) => {
                          openConfirmationDialog(() =>
                            deleteFromListing(
                              index,
                              definition.path,
                              definition.oneDataOnly
                            )
                          )
                        }}
                        hideDeleteButton={definition.hideDeleteButton}
                        descriptionI18nPrefix={definition.descriptionI18nPrefix}
                      />
                    )
                  }

                  break
                }

                case "retirementSchemeOrso": {
                  if (this.props.client && isContainsHK) {
                    return (
                      <NetWorthListing
                        key={idx}
                        currency={currency}
                        title={definition.title}
                        path={definition.path}
                        showAddButton={showAddButton(filteredListing)}
                        showForm={(index) => {
                          if (definition.oneDataOnly) {
                            showOneDataOnlyListingForm(
                              index,
                              definition.path,
                              definition.modalName
                            )
                          } else {
                            showListingForm(
                              index,
                              definition.path,
                              definition.modalName,
                              definition.title
                            )
                          }
                        }}
                        data={
                          filteredListing
                            ? filteredListing[definition.path.split(".")[1]]
                            : []
                        }
                        onDelete={(index) => {
                          openConfirmationDialog(() =>
                            deleteFromListing(
                              index,
                              definition.path,
                              definition.oneDataOnly
                            )
                          )
                        }}
                        hideDeleteButton={definition.hideDeleteButton}
                        descriptionI18nPrefix={definition.descriptionI18nPrefix}
                      />
                    )
                  }

                  break
                }

                case "superannuationFund": {
                  if (this.props.client && isContainsAU) {
                    return (
                      <NetWorthListing
                        key={idx}
                        currency={currency}
                        title={definition.title}
                        path={definition.path}
                        showAddButton={showAddButton(filteredListing)}
                        showForm={(index) => {
                          if (definition.oneDataOnly) {
                            showOneDataOnlyListingForm(
                              index,
                              definition.path,
                              definition.modalName
                            )
                          } else {
                            showListingForm(
                              index,
                              definition.path,
                              definition.modalName,
                              definition.title
                            )
                          }
                        }}
                        data={
                          filteredListing
                            ? filteredListing[definition.path.split(".")[1]]
                            : []
                        }
                        onDelete={(index) => {
                          openConfirmationDialog(() =>
                            deleteFromListing(
                              index,
                              definition.path,
                              definition.oneDataOnly
                            )
                          )
                        }}
                        hideDeleteButton={definition.hideDeleteButton}
                        descriptionI18nPrefix={definition.descriptionI18nPrefix}
                      />
                    )
                  }

                  break
                }

                case "employeeProvidenceFund": {
                  if (this.props.client && isContainsMY) {
                    return (
                      <NetWorthListing
                        key={idx}
                        currency={currency}
                        title={definition.title}
                        path={definition.path}
                        showAddButton={showAddButton(filteredListing)}
                        showForm={(index) => {
                          if (definition.oneDataOnly) {
                            showOneDataOnlyListingForm(
                              index,
                              definition.path,
                              definition.modalName
                            )
                          } else {
                            showListingForm(
                              index,
                              definition.path,
                              definition.modalName,
                              definition.title
                            )
                          }
                        }}
                        data={
                          filteredListing
                            ? filteredListing[definition.path.split(".")[1]]
                            : []
                        }
                        onDelete={(index) => {
                          openConfirmationDialog(() =>
                            deleteFromListing(
                              index,
                              definition.path,
                              definition.oneDataOnly
                            )
                          )
                        }}
                        hideDeleteButton={definition.hideDeleteButton}
                        descriptionI18nPrefix={definition.descriptionI18nPrefix}
                      />
                    )
                  }

                  break
                }

                case "centralprovidencefundinformation": {
                  if (this.props.client && isContainsSG) {
                    return (
                      <NetWorthListing
                        key={idx}
                        currency={currency}
                        title={definition.title}
                        path={definition.path}
                        showAddButton={showAddButton(filteredListing)}
                        showForm={(index) => {
                          if (definition.oneDataOnly) {
                            showOneDataOnlyListingForm(
                              index,
                              definition.path,
                              definition.modalName
                            )
                          } else {
                            showListingForm(
                              index,
                              definition.path,
                              definition.modalName,
                              definition.title
                            )
                          }
                        }}
                        data={
                          filteredListing
                            ? filteredListing[definition.path.split(".")[1]]
                            : []
                        }
                        onDelete={(index) => {
                          openConfirmationDialog(() =>
                            deleteFromListing(
                              index,
                              definition.path,
                              definition.oneDataOnly
                            )
                          )
                        }}
                        hideDeleteButton={definition.hideDeleteButton}
                        descriptionI18nPrefix={definition.descriptionI18nPrefix}
                      />
                    )
                  }

                  break
                }

                case "suplementaryRetirementSchemezInformation": {
                  if (this.props.client && isContainsSG) {
                    return (
                      <NetWorthListing
                        key={idx}
                        currency={currency}
                        title={definition.title}
                        path={definition.path}
                        showAddButton={showAddButton(filteredListing)}
                        showForm={(index) => {
                          if (definition.oneDataOnly) {
                            showOneDataOnlyListingForm(
                              index,
                              definition.path,
                              definition.modalName
                            )
                          } else {
                            showListingForm(
                              index,
                              definition.path,
                              definition.modalName,
                              definition.title
                            )
                          }
                        }}
                        data={
                          filteredListing
                            ? filteredListing[definition.path.split(".")[1]]
                            : []
                        }
                        onDelete={(index) => {
                          openConfirmationDialog(() =>
                            deleteFromListing(
                              index,
                              definition.path,
                              definition.oneDataOnly
                            )
                          )
                        }}
                        hideDeleteButton={definition.hideDeleteButton}
                        descriptionI18nPrefix={definition.descriptionI18nPrefix}
                      />
                    )
                  }

                  break
                }

                case "pensionInformation": {
                  if (this.props.client && isContainsAU) {
                    return (
                      <NetWorthListing
                        key={idx}
                        currency={currency}
                        title={definition.title}
                        path={definition.path}
                        showAddButton={showAddButton(filteredListing)}
                        showForm={(index) => {
                          if (definition.oneDataOnly) {
                            showOneDataOnlyListingForm(
                              index,
                              definition.path,
                              definition.modalName
                            )
                          } else {
                            showListingForm(
                              index,
                              definition.path,
                              definition.modalName,
                              definition.title
                            )
                          }
                        }}
                        data={
                          filteredListing
                            ? filteredListing[definition.path.split(".")[1]]
                            : []
                        }
                        onDelete={(index) => {
                          openConfirmationDialog(() =>
                            deleteFromListing(
                              index,
                              definition.path,
                              definition.oneDataOnly
                            )
                          )
                        }}
                        hideDeleteButton={definition.hideDeleteButton}
                        descriptionI18nPrefix={definition.descriptionI18nPrefix}
                      />
                    )
                  }

                  break
                }

                default: {
                  return (
                    <NetWorthListing
                      key={idx}
                      currency={currency}
                      title={definition.title}
                      path={definition.path}
                      showAddButton={showAddButton(filteredListing)}
                      showForm={(index) => {
                        if (definition.oneDataOnly) {
                          showOneDataOnlyListingForm(
                            index,
                            definition.path,
                            definition.modalName
                          )
                        } else {
                          // 20220805
                          showListingForm(
                            index,
                            definition.path,
                            definition.modalName,
                            definition.title
                          )
                        }
                      }}
                      data={
                        filteredListing
                          ? filteredListing[definition.path.split(".")[1]]
                          : []
                      }
                      onDelete={(index) => {
                        openConfirmationDialog(() =>
                          deleteFromListing(
                            index,
                            definition.path,
                            definition.oneDataOnly
                          )
                        )
                      }}
                      hideDeleteButton={definition.hideDeleteButton}
                      descriptionI18nPrefix={definition.descriptionI18nPrefix}
                    />
                  )

                  break
                }
              }
            })}
            <NetWorthListingSummary
              title={intl.get(`${groupName}.title.summary`)}
              data={filteredListing}
              hideSubTotal={hideSubTotal}
              currency={currency}
            />
            {content}
          </React.Fragment>
        )}
      </div>
    )
  }
}

export default NetWorthListingGroup

export const NetWorthListingGroupPropsComposer = (state, listing) => {
  const { netWorth, enumOptions, entity } = state
  const { openedAccordion, listingFilter } = netWorth
  const { client, partner, hasPartner: isJoint } = entity
  const { optionsData } = enumOptions
  return {
    listing,
    client,
    partner,
    openedAccordion,
    optionsData: optionsData || [],
    listingFilter,
    isJoint
  }
}
