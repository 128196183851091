import React, { useContext } from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from "react-accessible-accordion"
import intl from "react-intl-universal"
import {
  renderSelectFieldCustom,
  renderSelectEnumFieldCustom
} from "../../../helpers"
import { optionsType, getOptions } from "../../../helpers/OptionsHelper"
import { Occupation, Industry } from "../../../constants/Lists"
import PairField from "./PairField"
import PersonalDetailsContext from "../PersonalDetailsContext"
import { translateListLabel } from "../../../helpers/list-translation"

function EmploymentDetails({
  name = "personalDetails.employmentDetails",
  client,
  partner,
  values
}) {
  let isClientCountryHongkong =
    values &&
    values.client &&
    values.client.personalDetails &&
    values.client.personalDetails.employmentDetails &&
    values.client.personalDetails.employmentDetails.taxResidency === "HK"
  let isPartnerCountryHongkong =
    values &&
    values.partner &&
    values.partner.personalDetails &&
    values.partner.personalDetails.employmentDetails &&
    values.partner.personalDetails.employmentDetails.taxResidency === "HK"

  const { classification } = useContext(PersonalDetailsContext)
  const optionsData = getOptions([
    optionsType.residencyStatusOptions,
    optionsType.employmentStatusOptions,
    optionsType.qualificationOptions,
    optionsType.cpfEmploymentClassificationOptions,
    optionsType.taxPaymentPreferenceOptions
  ])

  const formWithTaxNumberMandatory = [
    {
      name: `${name}.employmentStatus`,
      label: intl.get("field.employmentStatus"),
      component: renderSelectEnumFieldCustom,
      enumData: optionsData.employmentStatusOptions
    },
    {
      name: `${name}.occupation`,
      label: intl.get("field.occupation"),
      component: renderSelectFieldCustom,
      data: translateListLabel(Occupation),
      valueProperty: "code",
      labelProperty: "name"
    },
    {
      name: `${name}.employedWith`,
      label: intl.get("field.employeedWith")
    },
    {
      name: `${name}.industry`,
      label: intl.get("field.industry"),
      component: renderSelectFieldCustom,
      data: translateListLabel(Industry),
      valueProperty: "code",
      labelProperty: "name"
    },
    {
      name: `${name}.highestQualification`,
      label: intl.get("field.highestQualification"),
      component: renderSelectEnumFieldCustom,
      enumData: optionsData.qualificationOptions
    },
    {
      name: `${name}.taxNumber`,
      label: intl.get("field.taxNumber"),
      isRequired: true
    }
  ]
  const formWithTaxNumberNotMandatory = [
    {
      name: `${name}.employmentStatus`,
      label: intl.get("field.employmentStatus"),
      component: renderSelectEnumFieldCustom,
      enumData: optionsData.employmentStatusOptions
    },
    {
      name: `${name}.occupation`,
      label: intl.get("field.occupation"),
      component: renderSelectFieldCustom,
      data: translateListLabel(Occupation),
      valueProperty: "code",
      labelProperty: "name"
    },
    {
      name: `${name}.employedWith`,
      label: intl.get("field.employeedWith")
    },
    {
      name: `${name}.industry`,
      label: intl.get("field.industry"),
      component: renderSelectFieldCustom,
      data: translateListLabel(Industry),
      valueProperty: "code",
      labelProperty: "name"
    },
    {
      name: `${name}.highestQualification`,
      label: intl.get("field.highestQualification"),
      component: renderSelectEnumFieldCustom,
      enumData: optionsData.qualificationOptions
    },
    ,
    {
      name: `${name}.taxNumber`,
      label: intl.get("field.taxNumber"),
      isRequired: false
    }
  ]

  return (
    <Accordion>
      <AccordionItem expanded>
        {/* 20211206 */}
        <div
          className={`portal-part employmentDetails-portal-part ${
            client.status == "ARCHIVE" ? "personal-details-disabled" : ""
          }`}
        >
          <AccordionItemTitle>
            <div className="subtitle2">
              {intl.get("personaldetail.employmentDetails")}
              <span className="accordion__arrow" role="presentation" />
            </div>
          </AccordionItemTitle>
          <AccordionItemBody>
            {client.status === "CLIENT" &&
              formWithTaxNumberMandatory.map((field) => (
                <PairField
                  key={field.name}
                  field={field}
                  classification={classification}
                />
              ))}
            {client.status !== "CLIENT" &&
              formWithTaxNumberNotMandatory.map((field) => (
                <PairField
                  key={field.name}
                  field={field}
                  classification={classification}
                />
              ))}
          </AccordionItemBody>
        </div>
      </AccordionItem>
    </Accordion>
  )
}

export default EmploymentDetails
