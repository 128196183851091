import React, { useState } from "react"
import { RenderSimpleFields } from "../../components"
import intl from "react-intl-universal"
import { getSymbolFromCode } from "../../helpers"
import { FieldType } from "../../components/Form/RenderSimpleFields"

const MortageCharges = ({ optionsData, defaultCurrency, values, readOnly }) => {
  const { loanInterestTypeOptions, frequencyOptions, currencyOptions } =
    optionsData

  let objectName = "loan"
  const [loanCurrency, setCurrency] = useState(defaultCurrency)
  const mortgageAssociatedFields = [
    {
      name: `${objectName}.additionalRepaymentHandlingFees`,
      label: intl.get("field.additionalRepaymentHandlingFees"),
      type: FieldType.MONEY,
      defaultCurrency: loanCurrency,
      symbolPrefix: getSymbolFromCode(loanCurrency),
      disabled: readOnly,
      allowNegative: false
    },
    {
      name: `${objectName}.additionalRepaymentFrequency`,
      label: intl.get("field.additionalRepaymentFrequency"),
      type: FieldType.ENUM_SELECTION,
      enumData: frequencyOptions,
      disabled: readOnly
    },
    {
      label: intl.get("field.nextAdditionalRepaymentDate"),
      name: `${objectName}.additionalRepaymentNextPaymentDate`,
      type: FieldType.DATE,
      disabled: readOnly
    },
    {
      name: `${objectName}.borrowingsManagementFees`,
      label: intl.get("field.loan.borrowingsManagementFees"),
      type: FieldType.MONEY,
      defaultCurrency: loanCurrency,
      symbolPrefix: getSymbolFromCode(loanCurrency),
      disabled: readOnly,
      allowNegative: false
    },
    {
      name: `${objectName}.borrowingsManagementFeesFrequency`,
      label: intl.get("field.loan.borrowingsManagementFeesFrequency"),
      type: FieldType.ENUM_SELECTION,
      enumData: frequencyOptions,
      disabled: readOnly
    },
    {
      label: intl.get("field.loan.borrowingsManagementFeesNextPaymentDate"),
      name: `${objectName}.borrowingsManagementFeesNextPaymentDate`,
      type: FieldType.DATE,
      disabled: readOnly
    }
  ]
  return (
    <div>
      <React.Fragment>
        <RenderSimpleFields fields={mortgageAssociatedFields} />
      </React.Fragment>
    </div>
  )
}

export default MortageCharges
