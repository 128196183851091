
import { NavLink } from "react-router-dom";
import React from "react";
import intl from "react-intl-universal";
import { AccessLevelEnum } from "../../../../constants/Enums";
import UserApi from "../../../../api/UserApi";


export const lightOrDark = color => {
    // Variables for red, green, blue values
    var r, g, b, hsp;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(
            /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );

        r = color[1];
        g = color[2];
        b = color[3];
    } else {
        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +(
            "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
        );

        r = color >> 16;
        g = (color >> 8) & 255;
        b = color & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {
        return "light";
    } else {
        return "dark";
    }
};

function mandatoryMenu(key){
    return (
        <>
            {intl.get(key)?intl.get(key):key}
            <label style={{ color: "red" }}>*</label>
        </>
    );
}



function prepareMenu(x, clientId){
    let newPath = x.underMaintenance?`/undermaintenance/${intl.get(x.label)?intl.get(x.label):x.label}`:x.path;
    let menu =  {
                    path: newPath?newPath.replace("${clientId}", clientId):undefined,
                    label: x.mandatory?mandatoryMenu(x.label): intl.get(x.label)?intl.get(x.label):x.label,
                    disabled: x.disabled,
                    accessLevel: x.accessLevel,
                    children: x.children
                }
    return menu;
}


function onMenuClicked(e, menu){
    //e.preventDefault();
    if(menu){
        localStorage.setItem('activeClientAccessLevel', menu.accessLevel);
        UserApi.track(menu.path);
    }
    
}



export function createMenu(data, clientId, idx){
    let menu = prepareMenu(data, clientId);
    if(menu.children.length==0 && menu.path){
        return  <NavLink
                    key={`${idx}csm`}
                    to={menu.path}
                    activeClassName={menu.path?"selected":""}
                    onClick={(e)=>onMenuClicked(e, menu)}
                >
                    {menu.label}
                </NavLink>
    }else{
        return (
            <li key={menu.id}>
                <input id = {menu.id} type="checkbox" checked={false}/> 
                <span className="hand" >
                    {menu.label}
                </span>
                <nav className="secondary-menu" >
                    {menu && menu.children && menu.children.filter(d=> !d.disabled && d.accessLevel!=AccessLevelEnum.AccessLevelEnum_NO_ACCESS).map((item, idx) => createMenu(item, '', idx))}
                </nav>
            </li>
        );
        
    }
   
}

