export const classificationEnums = {
  Client: "client",
  Partner: "partner",
  "Total Combined": "total",
  Joint: "joint"
}
export const classificationEnumsCapitalized = {
  Client: "client",
  Partner: "partner",
  "Total Combined": "total",
  Joint: "joint"
}

export const classificationEnumsAllCapitalized = {
  CLIENT: "client",
  PARTNER: "partner",
  COMBINED: "totalCombined",
  JOINT: "joint"
}

export const newclassificationEnumsAllCapitalized = {
  CLIENT: "client",
  PARTNER: "partner",
  COMBINED: "total",
  JOINT: "joint"
}
export const classificationEnumsAllLowercase = {
  client: "Client",
  partner: "Partner",
  combined: "Total Combined",
  joint: "Joint"
}
export const insuranceSummaryEnums = {
  generalInsuranceSummary: "General Insurance",
  healthAndMedicalInsuranceSummary: "Health And Medical Insurance",
  ilasSummary: "ILAS",
  personalInsuranceSummary: "Personal Insurance"
}
export const insuranceTypesEnums = {
  lifeInsurances: "Life Insurances",
  totalDisabilityInsurances: "Total Disability Insurances",
  criticalIllnessInsurances: "Critical Illness Insurances",
  healthAndMedicalInsurances: "Health And Medical Insurances",
  incomeProtectionInsurances: "Income Protection Insurances",
  otherGeneralInsurances: "Other General Insurances",
  ilas: "Ilas"
}

export const AssetTypesEnums = {
  RESIDENCE_PROPERTY: "Residence Property",
  MOTOR_VEHICLE: "Motor Vechicle",
  PERSONAL_CONTENT: "Personal Content",
  CASH: "Cash",
  TERM_DEPOSIT: "Term Deposit",
  BOND: "Bond",
  UNIT_TRUST: "Unit Trust",
  SHARE: "Share",
  STOCK: "Stock",
  BAG: "Bag",
  WARRANT: "Warrant",
  MUTUAL_FUND: "Mututal Fund",
  FOREX: "Forex",
  FUTURES: "Futures",
  OPTION: "Option",
  FUTURES_OPTION: "Futures Option",
  COMMODITY: "Commodity",
  INDEX: "Index",
  CFD: "Cfd",
  CRYPTO: "Crypto",
  INVESTMENT_PLATFORM: "Investment Platform",
  INVESTMENT_FUND_PLATFORM: "Investment Fund Platform",
  GENERIC_PORTFOLIO: "Generic Portfolio",
  COLLECTIBLE: "Collectible",
  BUSINESS: "Business",
  INVESTMENT_PROPERTY: "Investment Property",
  ILAS: "Ilas",
  EPF: "Epf",
  PRS: "Prs",
  MY_PENSION: "My Pension",
  MPF: "MPF",
  ORSO: "ORSO",
  CPF: "CPF",
  SRS: "SRS",
  SUPERANNUATION: "Super Annuation",
  AU_PENSION: "Au Pension",
  NZ_SUPER: "NZ Super",
  KIWI_SAVER: "Kiwi Saver"
}
export const retirementCountriesEnums = {
  containsAU: "Australia",
  containsHK: "Hongkong",
  containsMY: "Malaysia",
  containsNZ: "Newzealand",
  containsSG: "Singapore"
}
