import React, { useState } from "react"
import { Form, FormSpy } from "react-final-form"
import intl from "react-intl-universal"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import CarExpense, { CarExpenseMutators } from "./sub-forms/CarExpense"
import { FormButtons, ModalNoAccess } from "../components"
import CarLeasing from "./sub-forms/CarLeasing"
import CarFinancing from "./sub-forms/CarFinancing"
import InsuranceDetails from "./sub-forms/InsuranceDetails"
import MotorVehicleMainForm from "./MotorVehicleMainForm"
import GeneralLoanDetails from "./sub-forms/GeneralLoanDetails"
import { getSymbolFromCode } from "../helpers"
import { useSelector } from "react-redux"
function MotorVehicleForm({
  handleSave,
  model = {},
  readOnlyAccess,
  optionsData,
  clientInformation: { client, partner = {}, isJoint }
}) {
  if (readOnlyAccess) {
    return <ModalNoAccess />
  }
  const {
    personalDetails: {
      employmentDetails: { taxResidency }
    }
  } = client

  const showMortgageAssociatedCharges =
    taxResidency && taxResidency.toLowerCase() === "au"
  const { defaultCurrency } = useSelector((state) => state.personalDetails)

  return (
    <Form
      onSubmit={handleSave}
      initialValues={
        !model.isNew
          ? model
          : {
              ...model,
              vehicleExpenses: {
                ...model.vehicleExpenses,
                expensesCurrency: defaultCurrency
              }
            }
      }
      mutators={{
        setMonthlyExpense: CarExpenseMutators.setMonthlyExpense,
        setYearlyExpense: CarExpenseMutators.setYearlyExpense,
        setFormAttribute: ([fieldName, fieldVal], state, { changeValue }) => {
          changeValue(state, fieldName, () => fieldVal)
        },
        onCarLeaseChange: (args, state, utils) => {
          const value = args[0]

          const fieldsName = ["underLoan", "underLease", "underHirePurchase"]
          const setValues = (targetName) => {
            fieldsName.forEach((name) => {
              utils.changeValue(state, name, () => {
                if (targetName) {
                  return targetName === name
                }
                return false
              })
            })
          }
          switch (value) {
            case "NONE": {
              setValues()
              break
            }
            case "LOAN": {
              setValues("underLoan")
              break
            }
            case "CAR_LEASE": {
              setValues("underLease")
              break
            }
            case "HIRE_PURCHASE": {
              setValues("underHirePurchase")
              break
            }
            default:
              break
          }
        }
      }}
      render={({ handleSubmit, reset, values, form: { mutators } }) => (
        <form id="motorVehicleForm" onSubmit={handleSubmit}>
          <div className="portal-form-popup">
            <MotorVehicleMainForm
              client={client}
              partner={partner}
              isJoint={isJoint}
              readOnly={false}
              onCarLeaseChange={mutators.onCarLeaseChange}
              values={values}
              setFormAttribute={mutators.setFormAttribute}
            />
            <div className="portal-form-half">
              <FormSpy subscription={{ values: true }}>
                {({ values }) => (
                  <Tabs
                  // selectedIndex={tabIndex}
                  // onSelect={(index) => setTabIndex(index)}
                  >
                    <TabList>
                      <Tab>{intl.get("tabs.carExpenses")}</Tab>

                      {values.underLoan && (
                        <Tab>{intl.get("tabs.loanDetails")}</Tab>
                      )}
                      {values.underLease && (
                        <Tab>{intl.get("tabs.carLeaseDetails")}</Tab>
                      )}
                      {values.underHirePurchase && (
                        <Tab>{intl.get("tabs.hirePurchaseDetails")}</Tab>
                      )}
                      {values.insured && (
                        <Tab>{intl.get("tabs.insuranceDetails")}</Tab>
                      )}
                    </TabList>

                    <TabPanel>
                      <CarExpense
                        currencyOptions={optionsData.currencyOptions}
                        onMonthlyExpenseChange={mutators.setMonthlyExpense}
                        onYearlyExpenseChange={mutators.setYearlyExpense}
                        values={values}
                        currency={getSymbolFromCode(
                          values.currentValue.currency
                        )}
                      />
                    </TabPanel>

                    {values.underLoan && (
                      <TabPanel>
                        <GeneralLoanDetails
                          showMortgageAssociatedCharges={
                            showMortgageAssociatedCharges
                          }
                          optionsData={optionsData}
                          defaultCurrency={
                            values.loan &&
                            (values.loan.borrowingsCurrency || defaultCurrency)
                          }
                          values={values.loan || {}}
                        />
                      </TabPanel>
                    )}
                    {values.underLease && (
                      <TabPanel>
                        <CarLeasing
                          model={values}
                          optionsData={optionsData}
                          defaultCurrency={
                            values.carLease &&
                            (values.carLease.borrowingsCurrency ||
                              defaultCurrency)
                          }
                          mutators={mutators}
                        />
                      </TabPanel>
                    )}
                    {values.underHirePurchase && (
                      <TabPanel>
                        <CarFinancing
                          model={values}
                          optionsData={optionsData}
                          defaultCurrency={
                            values.hirePurchase &&
                            (values.hirePurchase.borrowingsCurrency ||
                              defaultCurrency)
                          }
                          mutators={mutators}
                        />
                      </TabPanel>
                    )}
                    {values.insured && (
                      <TabPanel>
                        <InsuranceDetails
                          optionsData={optionsData}
                          defaultCurrency={
                            values.loan &&
                            (values.loan.borrowingsCurrency || "MYR")
                          }
                        />
                      </TabPanel>
                    )}
                  </Tabs>
                )}
              </FormSpy>
            </div>
          </div>
          <FormButtons reset={reset} readOnlyAccess={readOnlyAccess} />
        </form>
      )}
    />
  )
}

export default MotorVehicleForm
