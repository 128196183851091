/*
    Author : Marlina (marlina@devstack.com.au)
    Library : FileOperationApi
    Description : methods related with file operation part
*/

import api from '../lib/api';

// change base route for different entity/domain here
const BASE_ROUTE = '/file_operation';

class FileOperationApi {
    // register
    // static upload(file) {
    //     return api.post(`${BASE_ROUTE}`, file);
    // }

    static upload(formData) {
        return api.post(`${BASE_ROUTE}`, formData);
    }
    static uploadMultiple(formData) {
        return api.post(`${BASE_ROUTE}/multiple`, formData);
    }

    static getLogFileByName(name) {
        return api.get(`${BASE_ROUTE}/log?name=${name}`);
    }

    static retrieveFile(id) {
        return api.get(`${BASE_ROUTE}?id=${id}`);
    }
}

export default FileOperationApi;
