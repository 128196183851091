import React, { Component } from 'react';
import { isNullOrUndefined } from '../../helpers';

export default class MalabarCheckbox extends Component {
    state = {
        checked: this.props.checked,
    };

    componentWillMount() {
        this.setState({
            checked: this.props.checked,
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.checked !== this.props.checked) {
            this.setState({
                checked: nextProps.checked,
            });
        }
    }

    handleCheck = () => {
        const { disabled } = this.props;

        if (!disabled) {
            this.setState(
                {
                    checked: !this.state.checked,
                },
                () => {
                    if (!isNullOrUndefined(this.props.onToggle)) {
                        this.props.onToggle(this.state.checked);
                    }
                    if (!isNullOrUndefined(this.props.onChange)) {
                        this.props.onChange(this.state.checked);
                    }
                }
            );
        }
    };

    render() {
        const { handleCheck } = this;
        return (
            <React.Fragment>
                <input
                    type="checkbox"
                    {...this.props}
                    // name={this.props.name}
                    checked={this.state.checked}
                />
                <label htmlFor={this.props.name} onClick={handleCheck}>
                    {this.props.label}
                </label>
            </React.Fragment>
        );
    }
}
