export const PhoneTypeEnum = {
  PhoneType_WORK: "WORK",
  PhoneType_RESIDENTIAL: "RESIDENTIAL",
  PhoneType_MOBILE: "MOBILE",
  PhoneType_FAX: "FAX",
  PhoneType_OTHER: "OTHER"
}

export const IdTypeEnum = {
  IdType_NATIONAL_ID: "NATIONAL_ID",
  IdType_PASSPORT: "PASSPORT",
  IdType_DRIVING_LICENCE: "DRIVING_LICENCE",
  IdType_OTHER: "OTHER"
}

export const EmailTypeEnum = {
  EmailType_PRIMARY: "PRIMARY",
  EmailType_SECONDARY: "SECONDARY",
  EmailType_WORK: "WORK",
  EmailType_OTHER: "OTHER"
}
export const sectionCategoryTypeEnum = {
  SectionType_GENERAL: "general",
  SectionType_SUMMARY: "summary",
  SectionType_INVESTMENTS: "investments",
  SectionType_INSURANCE: "insurance",
  SectionType_BUDGETING: "budgeting",
  SectionType_MORTGAGE: "mortgage",
  SectionType_FINANCE: "finance",
  SectionType_RETIREMENTPLANNING: "retirementPlanning",
  SectionType_CHILDRENEDUCATION: "childrenEducation",
  SectionType_PROPERTY: "property",
  SectionType_ESTATEPLANNING: "estatePlanning",
  SectionType_MISCELLANEOUS: "miscellaneous"

  // EmailType_PRIMARY: "PRIMARY",
  // EmailType_SECONDARY: "SECONDARY",
  // EmailType_WORK: "WORK",
  // EmailType_OTHER: "OTHER"
}

export const MaritalStatusEnum = {
  // MaritalStatus_NEVER_MARRIED: "NEVER_MARRIED",
  MaritalStatus_SINGLE: "SINGLE",
  MaritalStatus_MARRIED: "MARRIED",
  MaritalStatus_DIVORCED: "DIVORCED",
  MaritalStatus_WIDOWED: "WIDOWED",
  // MaritalStatus_DE_FACTO: "DE_FACTO",
  MaritalStatus_SEPARATED: "SEPARATED"
}

export const SalutationEnum = {
  Salutation_MR: "MR",
  Salutation_MRS: "MRS",
  Salutation_MISS: "MISS",
  Salutation_MS: "MS",
  Salutation_MADAM: "MADAM",
  Salutation_DR: "DR",
  Salutation_PROF: "PROF",
  Salutation_DATO: "DATO",
  Salutation_TANSRI: "TANSRI"
}

export const GenderEnum = {
  Gender_MALE: "MALE",
  Gender_FEMALE: "FEMALE",
  Gender_OTHERS: "OTHERS"
}

export const RaceEnum = {
  Race_CHINESE: "CHINESE",
  Race_MALAYS: "MALAYS",
  Race_INDIANS: "INDIANS",
  Race_OTHERS: "OTHERS"
}

export const PartyTypeEnum = {
  PartyType_ADVISER: "ADVISER",
  PartyType_BRANCH: "BRANCH",
  PartyType_CLIENT: "CLIENT",
  PartyType_CLIENT_COMPOSITE: "CLIENT_COMPOSITE",
  PartyType_COUNTRY: "COUNTRY",
  PartyType_PRINCIPAL: "PRINCIPAL",
  PartyType_STATE: "STATE",
  PartyType_SUB_BRANCH: "SUB_BRANCH"
}

export const EmploymentStatusEnum = {
  EmploymentStatus_FULL_TIME: "FULL_TIME",
  EmploymentStatus_PART_TIME: "PART_TIME",
  EmploymentStatus_CONTRACT: "CONTRACT",
  EmploymentStatus_CASUAL: "CASUAL",
  EmploymentStatus_SELF_EMPLOYED: "SELF_EMPLOYED",
  EmploymentStatus_EMPLOYEE_SELF_EMPLOYED: "EMPLOYEE_SELF_EMPLOYED",
  EmploymentStatus_RETIRED: "RETIRED",
  EmploymentStatus_SEMI_RETIRED: "SEMI_RETIRED",
  EmploymentStatus_HOME_DUTIES: "HOME_DUTIES",
  EmploymentStatus_UNEMPLOYED: "UNEMPLOYED"
}

export const DependantTypeEnum = {
  DependantType_CHILD: "CHILD",
  DependantType_PARENT: "PARENT",
  DependantType_SIBLING: "SIBLING"
}

export const ResidencyPropertyOwnershipTypeEnum = {
  ResidencyPropertyOwnershipType_PRIMARY: "PRIMARY",
  ResidencyPropertyOwnershipType_OTHER: "OTHER"
}

export const ResidentialTenureEnum = {
  ResidentialTenure_OWNER_OCCUPANCY: "OWNER_OCCUPANCY",
  ResidentialTenure_TENANCY_RENTAL: "TENANCY_RENTAL",
  ResidentialTenure_EMPLOYER_FRINGE_BENEFIT: "EMPLOYER_FRINGE_BENEFIT",
  ResidentialTenure_PUBLIC_HOUSING: "PUBLIC_HOUSING",
  ResidentialTenure_FAMILY_HOUSE: "FAMILY_HOUSE"
}

export const ClientClassificationEnum = {
  ClientClassification_SINGLE: "SINGLE",
  ClientClassification_FAMILY: "FAMILY",
  ClientClassification_FAMILY_OFFICE: "FAMILY_OFFICE",
  ClientClassification_CORPORATE: "CORPORATE",
  ClientClassification_TRUST: "TRUST",
  ClientClassification_JOINT: "JOINT"
}

export const PropertyTypeEnum = {
  PropertyType_APARTMENT: "APARTMENT",

  PropertyType_BUNGALOW: "BUNGALOW",
  PropertyType_CONDOMINIUM: "CONDOMINIUM",
  PropertyType_DUPLEX_HOUSE: "DUPLEX_HOUSE",

  PropertyType_FACTORYLOT: "FACTORYLOT",

  PropertyType_FLAT: "FLAT",
  PropertyType_GOVERNMENT_HOUSING: "GOVERNMENT_HOUSING",
  PropertyType_HOUSE: "HOUSE",
  PropertyType_LAND: "LAND",

  PropertyType_PENTHOUSE: "PENTHOUSE",
  PropertyType_STUDIO: "STUDIO",
  PropertyType_SHOPHOUSE: "SHOP_HOUSE",
  PropertyType_SHOPLOTS: "SHOPLOTS",
  PropertyType_SEMI_DETACHED_HOUSE: "SEMI_DETACHED_HOUSE",

  PropertyType_SERVICE_APARTMENT: "SERVICE_APARTMENT",

  PropertyType_TERRACE_HOUSE: "TERRACE_HOUSE",
  PropertyType_TOWNHOUSE: "TOWNHOUSE",
  PropertyType_UNIT: "UNIT",

  PropertyType_VILLA: "VILLA",
  PropertyType_WATERFRONT_HOUSE: "WATERFRONT_HOUSE"
}

export const QualificationEnum = {
  Qualification_PRIMARY_SCHOOL: "PRIMARY_SCHOOL",
  Qualification_HIGH_SCHOOL: "HIGH_SCHOOL",
  Qualification_COLLEGE: "COLLEGE",
  Qualification_GRADUATE: "GRADUATE",
  Qualification_POST_GRADUATE: "POST_GRADUATE"
}

export const RelationshipTypeEnum = {
  RelationshipType_SPOUSE: "SPOUSE",
  RelationshipType_FATHER: "FATHER",
  RelationshipType_MOTHER: "MOTHER",
  RelationshipType_BROTHER: "BROTHER",
  RelationshipType_SISTER: "SISTER",
  RelationshipType_DAUGHTER: "DAUGHTER",
  RelationshipType_SON: "SON",
  RelationshipType_GRANDMOTHER: "GRANDMOTHER",
  RelationshipType_GRANDFATHER: "GRANDFATHER",
  RelationshipType_GRANDCHILD: "GRANDCHILD",
  RelationshipType_NEPHEW: "NEPHEW",
  RelationshipType_NIECE: "NIECE",
  RelationshipType_UNCLE: "UNCLE",
  RelationshipType_AUNT: "AUNT",
  RelationshipType_COUSIN: "COUSIN",
  RelationshipType_CHILDREN_IN_LAW: "CHILDREN_IN_LAW",
  RelationshipType_PARENT_IN_LAW: "PARENT_IN_LAW",
  RelationshipType_SIBLING_IN_LAW: "SIBLING_IN_LAW"
}

export const ChildrenRelationshipTypeEnum = {
  RelationshipType_SON: "SON",
  RelationshipType_DAUGHTER: "DAUGHTER"
}
export const SiblingRelationshipTypeEnum = {
  RelationshipType_BROTHER: "BROTHER",
  RelationshipType_SISTER: "SISTER"
}
export const ParentRelationshipTypeEnum = {
  RelationshipType_PARENT: "PARENT",
  RelationshipType_PARENT_IN_LAW: "PARENT_IN_LAW",
  RelationshipType_GRAND_PARENT: "GRAND_PARENT"
}

export const SchoolLevelEnum = {
  SchoolLevel_PRESCHOOL: "PRESCHOOL",
  SchoolLevel_KINDERGARTEN: "KINDERGARTEN",
  SchoolLevel_PRIMARY_SCHOOL: "PRIMARY_SCHOOL",
  SchoolLevel_HIGH_SCHOOL: "HIGH_SCHOOL",
  SchoolLevel_COLLEGE_PART_TIME: "COLLEGE_PART_TIME",
  SchoolLevel_COLLEGE_FULL_TIME: "COLLEGE_FULL_TIME",
  SchoolLevel_UNIVERSITY_PART_TIME: "UNIVERSITY_PART_TIME",
  SchoolLevel_UNIVERSITY_FULL_TIME: "UNIVERSITY_FULL_TIME",
  SchoolLevel_POST_GRADUATE_PART_TIME: "POST_GRADUATE_PART_TIME",
  SchoolLevel_POST_GRADUATE_FULL_TIME: "POST_GRADUATE_FULL_TIME",
  SchoolLevel_QUALIFYING_TRANING_PART_TIME: "QUALIFYING_TRANING_PART_TIME",
  SchoolLevel_QUALIFYING_TRANING_FULL_TIME: "QUALIFYING_TRANING_FULL_TIME",
  SchoolLevel_OTHER: "OTHER"
}

export const ReligionTypeEnum = {
  ReligionType_CHRISTIAN: "CHRISTIAN",
  ReligionType_HINDUISM: "HINDUISM",
  ReligionType_JAINISM: "JAINISM",
  ReligionType_SIKHISM: "SIKHISM",
  ReligionType_BUDDHIST: "BUDDHIST",
  ReligionType_ISLAM: "ISLAM",
  ReligionType_CATHOLIC: "CATHOLIC",
  ReligionType_OTHERS: "OTHERS",
  ReligionType_NONDISCLOSED: "NONDISCLOSED"
}

export const ResidencyStatusEnum = {
  ResidencyStatus_CITIZEN: "CITIZEN",
  ResidencyStatus_PERMANENT_RESIDENT: "PERMANENT_RESIDENT",
  ResidencyStatus_STUDENT_VISA: "STUDENT_VISA",
  ResidencyStatus_EMPLOYMENT_VISA: "EMPLOYMENT_VISA",
  ResidencyStatus_DEPENDENT_VISA: "DEPENDENT_VISA",
  ResidencyStatus_SPECIAL_CATEGORY_VISA: "SPECIAL_CATEGORY_VISA"
}

export const CashAccountTypeEnum = {
  CashAccountType_CURRENT: "CURRENT",
  CashAccountType_SAVINGS: "SAVINGS",
  CashAccountType_CASH_MANAGED_TRUST: "CASH_MANAGED_TRUST",
  CashAccountType_CHEQUE_ACCOUNT: "CHEQUE_ACCOUNT"
}

export const CpfEmploymentClassificationEnum = {
  CpfEmploymentClassification_PRIVATE_SECTOR: "PRIVATE_SECTOR",
  CpfEmploymentClassification_PRIVATE_SECTOR_PENSIONABLE:
    "PRIVATE_SECTOR_PENSIONABLE",
  CpfEmploymentClassification_PUBLIC_SECTOR_PENSIONABLE:
    "PUBLIC_SECTOR_PENSIONABLE"
}

export const TaxPaymentPreferenceEnum = {
  TaxPaymentPreference_LUMP_SUM: "LUMP_SUM",
  TaxPaymentPreference_MONTHLY_INSTALLMENT: "MONTHLY_INSTALLMENT"
}

export const LivingWithEnum = {
  LivingWith_HOUSEHOLD: "HOUSEHOLD",
  LivingWith_OTHER_FAMILY_MEMBERS: "OTHER_FAMILY_MEMBERS",
  LivingWith_ELDERLY_RESIDENTIAL_CARE_HOME: "ELDERLY_RESIDENTIAL_CARE_HOME",
  LivingWith_INDEPENDENTLY: "INDEPENDENTLY"
}

export const LoanInterestTypeEnum = {
  LoanInterestType_FIXED: "FIXED",
  LoanInterestType_VARIABLE: "VARIABLE",
  LoanInterestType_FIXED_AND_VARIABLE: "FIXED_AND_VARIABLE"
}

export const FrequencyEnum = {
  Frequency_YEARLY: "YEARLY",
  Frequency_HALF_YEARLY: "HALF_YEARLY",
  Frequency_QUARTERLY: "QUARTERLY",
  Frequency_MONTHLY: "MONTHLY",
  Frequency_FORTNIGHTLY: "FORTNIGHTLY",
  Frequency_WEEKLY: "WEEKLY",
  Frequency_ONE_OFF: "ONE_OFF"
}
export const FrequencyEnumValues = {
  YEARLY: "Yearly",
  HALF_YEARLY: "Half Yearly",
  QUARTERLY: "Quarterly",
  MONTHLY: "Monthly",
  FORTNIGHTLY: "Fortnightly",
  WEEKLY: "Weekly",
  ONE_OFF: "One Off"
}

export const InvestmentFrequencyEnum = {
  Frequency_YEARLY: "YEARLY",
  Frequency_HALF_YEARLY: "HALF_YEARLY",
  Frequency_QUARTERLY: "QUARTERLY",
  Frequency_MONTHLY: "MONTHLY",
  Frequency_FORTNIGHTLY: "FORTNIGHTLY",
  Frequency_WEEKLY: "WEEKLY"
}

export const PremiumFrequencyEnum = {
  PremiumFrequency_ANUALLY: "ANUALLY",
  PremiumFrequency_SEMI_ANUALLY: "SEMI_ANUALLY",
  PremiumFrequency_MONTHLY: "MONTHLY",
  PremiumFrequency_FORTNIGHTLY: "FORTNIGHTLY",
  PremiumFrequency_WEEKLY: "WEEKLY"
}

export const LoanFrequencyEnum = {
  Frequency_MONTHLY: "MONTHLY",
  Frequency_FORTNIGHTLY: "FORTNIGHTLY"
}

export const BenefitPeriodEnum = {
  BenefitPeriod_ONE_YEAR: "ONE_YEAR",
  BenefitPeriod_TWO_YEAR: "TWO_YEARS",
  BenefitPeriod_FIVE_YEAR: "FIVE_YEARS",
  BenefitPeriod_UP_TO_AGE_SIXTY: "UP_TO_AGE_SIXTY",
  BenefitPeriod_UP_TO_AGE_SIXTY_FIVE: "UP_TO_AGE_SIXTY_FIVE"
}

export const SourceOfFundEnum = {
  SourceOfFund_SA: "SA",
  SourceOfFund_OA: "OA"
}

export const InsuranceTypeEnum = {
  InsuranceType_CONTENT: "CONTENT",
  InsuranceType_Fire: "Fire"
}

export const BorrowingsTypeEnum = {
  BorrowingsType_CPF: "CPF",
  BorrowingsType_LOAN: "LOAN",
  BorrowingsType_MORTAGE: "MORTAGE",
  BorrowingsType_MARGIN_LOAN: "MARGIN_LOAN"
}

export const CarFinancingTypeEnum = {
  CarFinancingType_HIRE_PURCHASE: "HIRE_PURCHASE",
  CarFinancingType_LEASE: "LEASE"
}

export const GoldTypeEnum = {
  GOLD_BAR: "GOLD_BAR",
  GOLD_BULLION_COIN: "GOLD_BULLION_COIN",
  GOLD_CERTIFICATE: "GOLD_CERTIFICATE",
  GOLD_SAVING_ACCOUNT: "GOLD_SAVING_ACCOUNT"
}

export const ETFTypeEnum = {
  SPDR_STRAITS_TIME_INDEX_ETF: "SPDR_STRAITS_TIME_INDEX_ETF",
  NIKKO_AM_SINGAPORE_STI_ETF: "NIKKO_AM_SINGAPORE_STI_ETF",
  ABF_SINGAPORE_BOND_INDEX_FUND: "ABF_SINGAPORE_BOND_INDEX_FUND",
  SPDR_GOLD_SHARES: "SPDR_GOLD_SHARES"
}

export const CurrencyEnum = {
  Currency_MYR: "MYR",
  Currency_HKD: "HKD",
  Currency_SGD: "SGD",
  Currency_AUD: "AUD",
  Currency_NZD: "NZD",
  Currency_USD: "USD",
  Currency_EUR: "EUR",
  Currency_GBP: "GBP",
  Currency_JPY: "JPY",
  Currency_CNY: "CNY",
  Currency_CAD: "CAD",
  Currency_CHF: "CHF",
  Currency_SEK: "SEK",
  Currency_MXN: "MXN",
  Currency_NOK: "NOK",
  Currency_KRW: "KRW",
  Currency_TRY: "TRY",
  Currency_RUB: "RUB",
  Currency_INR: "INR",
  Currency_BRL: "BRL",
  Currency_ZAR: "ZAR",
  Currency_SAR: "SAR",
  Currency_AED: "AED",
  Currency_ARS: "ARS",
  Currency_THB: "THB",
  Currency_IDR: "IDR",
  Currency_PHP: "PHP",
  Currency_ILS: "ILS",
  Currency_DKK: "DKK",
  Currency_PLN: "PLN",
  Currency_HUF: "HUF",
  Currency_CZK: "CZK",
  Currency_CLP: "CLP",
  Currency_NGN: "NGN",
  Currency_VND: "VND",
  Currency_COP: "COP",
  Currency_EGP: "EGP",
  Currency_PKR: "PKR",
  Currency_BDT: "BDT",
  Currency_KWD: "KWD"
}
export const loanTypeEnum = {}

export const BondTypeEnum = {
  BondType_GOVERNMENT_BOND: "GOVERNMENT_BOND",
  BondType_CORPORATE_BOND: "CORPORATE_BOND",
  BondType_ASSET_BACK_SECURITY: "ASSET_BACK_SECURITY",
  BondType_MORTAGE_BACK_SECURITY: "MORTAGE_BACK_SECURITY"
}

export const YesNoEnum = {
  YesNoEnum_YES: "YES",
  YesNoEnum_NO: "NO"
}

export const AccessLevelEnum = {
  AccessLevelEnum_READ_WRITE: "READ_WRITE",
  AccessLevelEnum_READ_ONLY: "READ_ONLY",
  AccessLevelEnum_NO_ACCESS: "NO_ACCESS"
}

export const EntityTypeEnum = {
  EntityTypeEnum_BUILT_IN_MALABAR_AI_GLOBAL: "BUILT_IN_MALABAR_AI_GLOBAL",
  EntityTypeEnum_BUILT_IN_MALABAR_AI_SUBSIDIARY:
    "BUILT_IN_MALABAR_AI_SUBSIDIARY",
  EntityTypeEnum_BUILT_IN_DEALERSHIP: "BUILT_IN_DEALERSHIP",
  EntityTypeEnum_BUILT_IN_ADVISER_GROUP: "BUILT_IN_ADVISER_GROUP",
  EntityTypeEnum_BUILT_IN_SUB_BRANCH: "BUILT_IN_SUB_BRANCH",
  EntityTypeEnum_BUILT_IN_ADVISER: "BUILT_IN_ADVISER",
  EntityTypeEnum_CLIENT: "CLIENT"
}

export const CompanyScopeEntityTypeEnum = {
  EntityTypeEnum_BUILT_IN_DEALERSHIP: "BUILT_IN_DEALERSHIP",
  EntityTypeEnum_BUILT_IN_ADVISER_GROUP: "BUILT_IN_ADVISER_GROUP",
  EntityTypeEnum_BUILT_IN_SUB_BRANCH: "BUILT_IN_SUB_BRANCH",
  EntityTypeEnum_BUILT_IN_ADVISER: "BUILT_IN_ADVISER"
}

export const FeedbackCategoryEnum = {
  DISLIKE_SOMETHING: "DISLIKE_SOMETHING",
  LIKE_SOMETHING: "LIKE_SOMETHING",
  OTHER: "OTHER",
  SUGGESTION: "SUGGESTION",
  TECHNICAL_ISSUE: "TECHNICAL_ISSUE"
}

export const NoteCategoryEnum = {
  GENERAL_NOTES: "GENERAL_NOTE",
  MEETING_NOTES: "MEETING_NOTE",
  TODO: "TO_DO",
  PAST_FILE_NOTES: "Past File Notes",
  SEND_EMAIL: "Send Email"
}

export const FeedbackTechnicalIssueEnum = {
  FeedbackTechnicalIssueEnum_USABILITY: "USABILITY",
  FeedbackTechnicalIssueEnum_BUG: "BUG",
  FeedbackTechnicalIssueEnum_PERFORMANCE: "PERFORMANCE",
  FeedbackTechnicalIssueEnum_SECURITY: "SECURITY",
  FeedbackTechnicalIssueEnum_CONTENT: "CONTENT",
  FeedbackTechnicalIssueEnum_ACCESSIBILITY: "ACCESSIBILITY",
  FeedbackTechnicalIssueEnum_OTHER: "OTHER"
}

export const FeedbackPrioritySeverityEnum = {
  FeedbackPrioritySeverityEnum_HIGH: "HIGH",
  FeedbackPrioritySeverityEnum_MODERATE: "MODERATE",
  FeedbackPrioritySeverityEnum_LOW: "LOW"
}

export const FeedbackStatusEnum = {
  FeedbackStatusEnum_OPEN: "OPEN",
  FeedbackStatusEnum_INVESTIGATION: "INVESTIGATION",
  FeedbackStatusEnum_RESOLVED: "RESOLVED",
  FeedbackStatusEnum_CLOSED: "CLOSED"
}

export const GovernmentPaymentAustraliaEnum = {
  GovernmentPaymentAustraliaEnum_ABSTUDY: "ABSTUDY",
  GovernmentPaymentAustraliaEnum_AGEPENSION: "AGE PENSION",
  GovernmentPaymentAustraliaEnum_AUSTUDY: "AUSTUDY",
  GovernmentPaymentAustraliaEnum_CARERALLOWANCE: "CARER ALLOWANCE",
  GovernmentPaymentAustraliaEnum_CARERPAYMENT: "CARER PAYMENT",
  GovernmentPaymentAustraliaEnum_CARERSUPPLEMENT: "CARER SUPPLEMENT",
  GovernmentPaymentAustraliaEnum_CHILDCARESUBSIDY: "CHILD CARE SUBSIDY",
  GovernmentPaymentAustraliaEnum_DISABILITYSUPPORTPENSION:
    "DISABILITY SUPPORT PENSION",
  GovernmentPaymentAustraliaEnum_FAMILYTAXBENEFIT: "FAMILY TAX BENEFIT",
  GovernmentPaymentAustraliaEnum_MOBILITYALLOWANCE: "MOBILITY ALLOWANCE",
  GovernmentPaymentAustraliaEnum_NEWSTARTALLOWANCE: "NEWSTART ALLOWANCE",
  GovernmentPaymentAustraliaEnum_PARENTALLEAVEPAY: "PARENTAL LEAVE PAY",
  GovernmentPaymentAustraliaEnum_PARENTINGPAYMENT: "PARENTING PAYMENT",
  GovernmentPaymentAustraliaEnum_PENSIONBONUSSCHEME: "PENSION BONUS SCHEME",
  GovernmentPaymentAustraliaEnum_SICKNESSALLOWANCE: "SICKNESS ALLOWANCE",
  GovernmentPaymentAustraliaEnum_WIDOWALLOWANCE: "WIDOW ALLOWANCE",
  GovernmentPaymentAustraliaEnum_WIDOWBPENSION: "WIDOW B PENSION",
  GovernmentPaymentAustraliaEnum_WIFEPENSION: "WIFE PENSION",
  GovernmentPaymentAustraliaEnum_YOUTHALLOWANCE: "YOUTH ALLOWANCE​"
  // GovernmentPaymentAustraliaEnum_MOREPAYMENTS: {''}
}

export const EducationAssistanceLoanTypeAUEnum = {
  EducationAssistanceLoanTypeAUEnum_HIGHER_EDUCATION_LOAN_PROGRAM:
    "HIGHER_EDUCATION_LOAN_PROGRAM",
  EducationAssistanceLoanTypeAUEnum_STUDENT_START_UP_LOAN:
    "STUDENT_START_UP_LOAN",
  EducationAssistanceLoanTypeAUEnum_TRADE_SUPPORT_LOAN: "TRADE_SUPPORT_LOAN",
  EducationAssistanceLoanTypeAUEnum_STUDENT_FINANCIAL_SUPPLEMENT:
    "STUDENT_FINANCIAL_SUPPLEMENT"
}

export const EducationAssistanceLoanTypeSGEnum = {
  EducationAssistanceLoanTypeSGEnum_CPF_EDUCATION_SCHEME:
    "CPF_EDUCATION_SCHEME",
  EducationAssistanceLoanTypeSGEnum_MOE_TUITION_FEE_LOAN: "MOE_TUITION_FEE_LOAN"
}

export const EducationAssistanceLoanTypeHKEnum = {
  EducationAssistanceLoanTypeHKEnum_NLSFT: "NLSFT",
  EducationAssistanceLoanTypeHKEnum_NLSPS: "NLSPS",
  EducationAssistanceLoanTypeHKEnum_ENLS: "ENLS",
  EducationAssistanceLoanTypeHKEnum_EMERGENCY_LOAN: "EMERGENCY_LOAN",
  EducationAssistanceLoanTypeHKEnum_INTEREST_FREE_LOAN: "INTEREST_FREE_LOAN",
  EducationAssistanceLoanTypeHKEnum_FASP: "FASP",
  EducationAssistanceLoanTypeHKEnum_TSFS: "TSFS"
}

export const EducationAssistanceLoanTypeNZEnum = {
  EducationAssistanceLoanTypeNZEnum_STUDENT_LOAN: "STUDENT_LOAN"
}
export const EducationAssistanceLoanTypeMYEnum = {
  EducationAssistanceLoanTypeMYEnum_FEDERAL_TRAINING_AWARD_SCHEME:
    "FEDERAL_TRAINING_AWARD_SCHEME",
  EducationAssistanceLoanTypeMYEnum_JPA_LOAN_SCHEMES: "JPA_LOAN_SCHEMES",
  EducationAssistanceLoanTypeMYEnum_KOJADI_EDUCATION_LOAN:
    "KOJADI_EDUCATION_LOAN",
  EducationAssistanceLoanTypeMYEnum_KUOK_FOUNDATION_POLYTECHNIC_STUDY_AWARD:
    "KUOK_FOUNDATION_POLYTECHNIC_STUDY_AWARD",
  EducationAssistanceLoanTypeMYEnum_KUOK_FOUNDATION_POSTGRADUATE_STUDY_AWARD:
    "KUOK_FOUNDATION_POSTGRADUATE_STUDY_AWARD",
  EducationAssistanceLoanTypeMYEnum_KUOK_FOUNDATION_UNDERGRADUATE_STUDY_AWARD:
    "KUOK_FOUNDATION_UNDERGRADUATE_STUDY_AWARD",
  EducationAssistanceLoanTypeMYEnum_PTPTN_LOAN: "PTPTN_LOAN",
  EducationAssistanceLoanTypeMYEnum_STUDY_LOAN: "STUDY_LOAN",
  EducationAssistanceLoanTypeMYEnum_SOCSO_EDUCATION_FUND:
    "SOCSO_EDUCATION_FUND",
  EducationAssistanceLoanTypeMYEnum_OTHERS: "OTHERS"
}

export const LeaseTypeEnum = {
  LeaseTypeEnum_NOVATED_LEASE: "NOVATED_LEASE",
  LeaseTypeEnum_OPERATING_LEASE: "OPERATING_LEASE",
  LeaseTypeEnum_FINANCE_LEASE: "FINANCE_LEASE"
}

export const LoanRepaymentStatusEnum = {
  LoanRepaymentStatusEnum_NEW_ZEALAND_BASED_BORROWER:
    "NEW_ZEALAND_BASED_BORROWER",
  LoanRepaymentStatusEnum_REPAYMENT_HOLIDAY: "REPAYMENT_HOLIDAY",
  LoanRepaymentStatusEnum_OVERSEAS_BASED_BORROWER: "OVERSEAS_BASED_BORROWER"
}

export const HospitalCoverTypeEnum = {
  HospitalCoverTypeEnum_PUBLIC_PATIENT_PUBLIC_HOSPITAL:
    "PUBLIC_PATIENT_PUBLIC_HOSPITAL",
  HospitalCoverTypeEnum_PRIVATE_PATIENT_PUBLIC_HOSPITAL:
    "PRIVATE_PATIENT_PUBLIC_HOSPITAL",
  HospitalCoverTypeEnum_PRIVATE_PATIENT_PRIVATE_HOSPITAL:
    "PRIVATE_PATIENT_PRIVATE_HOSPITAL"
}

export const PeopleCoveredEnum = {
  PeopleCoveredEnum_INDIVIDUAL: "INDIVIDUAL",
  PeopleCoveredEnum_FAMILY: "FAMILY"
}

export const PolicyAreaCoveredEnum = {
  PolicyAreaCoveredEnum_COUNTRY_OF_RESIDENT: "COUNTRY_OF_RESIDENT",
  PolicyAreaCoveredEnum_ASIA_PACIFIC: "ASIA_PACIFIC",
  PolicyAreaCoveredEnum_GLOBAL_EX_USA: "GLOBAL_EX_USA",
  PolicyAreaCoveredEnum_GLOBAL: "GLOBAL"
}

export const GeneralInsuranceTypeEnum = {
  GeneralInsuranceTypeEnum_DOMESTIC_HELPER_INSURANCE:
    "DOMESTIC_HELPER_INSURANCE",
  GeneralInsuranceTypeEnum_PET_INSURANCE: "PET_INSURANCE",
  GeneralInsuranceTypeEnum_COMPUTER_INSURANCE: "COMPUTER_INSURANCE",
  GeneralInsuranceTypeEnum_TRAVEL_INSURANCE: "TRAVEL_INSURANCE",
  GeneralInsuranceTypeEnum_CYBER_INSURACE: "CYBER_INSURACE",
  GeneralInsuranceTypeEnum_MARINE_INSURANCE: "MARINE_INSURANCE",
  GeneralInsuranceTypeEnum_MOBILE_PHONE_INSURANCE: "MOBILE_PHONE_INSURANCE",
  GeneralInsuranceTypeEnum_PERSONAL_ACCIDENT_INSURANCE: "PERSONAL_ACCIDENT",
  GeneralInsuranceTypeEnum_OTHER: "OTHER"
}

export const IfastDataTypes = {
  IFAST_Account: "account",
  IFAST_transaction: "transaction",
  IFAST_cashaccount: "cashaccount",
  IFAST_cashaccounttransaction: "cashaccounttransaction",
  IFAST_etf: "etf",
  IFAST_etftransaction: "etftransaction",
  IFAST_rspinfo: "rspinfo",
  IFAST_stock: "stock",
  IFAST_stocktransaction: "stocktransaction",
  IFAST_holding: "holding"
}

export const InsurancePolicyTypeEnum = {
  InsurancePolicyTypeEnum_TERM_LIFE_INSURANCE: "TERM_LIFE_INSURANCE",
  InsurancePolicyTypeEnum_INCREASING_TERM_LIFE_INSURANCE:
    "INCREASING_TERM_LIFE_INSURANCE",
  InsurancePolicyTypeEnum_DECREASING_TERM_LIFE_INSURANCE:
    "DECREASING_TERM_LIFE_INSURANCE",
  InsurancePolicyTypeEnum_KEY_PERSON_LIFE_INSURANCE: "KEY_PERSON_LIFE_INSURANCE"
  // InsurancePolicyTypeEnum_TOTAL_AND_PERMANENT_DISABILITY_INSURANCE:
  //     'TOTAL_AND_PERMANENT_DISABILITY_INSURANCE',
  // InsurancePolicyTypeEnum_CRITICAL_ILLNESS_INSURANCE:
  //     'CRITICAL_ILLNESS_INSURANCE',
  // InsurancePolicyTypeEnum_HEALTH_AND_MEDICAL_INSURANCE:
  //     'HEALTH_AND_MEDICAL_INSURANCE',
}

// export const InsurancesTypes = {
//   healthAndMedicalInsurances: "health_and_medical_insurances",
//   lifeInsurance: "life_insurances",
//   motorVehicleInsurances:""
// }
export const CriticalIllnessPolicyTypeEnum = {
  InsurancePolicyTypeEnum_CRITICAL_ILLNESS_INSURANCE:
    "CRITICAL_ILLNESS_INSURANCE"
}

export const CoverageTypeEnum = {
  CoverageTypeEnum_STEP: "STEP",
  CoverageTypeEnum_LEVEL: "LEVEL",
  CoverageTypeEnum_DECREASING: "DECREASING"
}

export const InsuranceRiderTypeEnum = {
  InsuranceRiderTypeEnum_DISABILITY_INCOME_RIDER: "DISABILITY_INCOME_RIDER",
  InsuranceRiderTypeEnum_LONG_TERM_CARE_RIDER: "LONG_TERM_CARE_RIDER",
  InsuranceRiderTypeEnum_ACCELERATED_DEATH_BENEFIT_RIDER:
    "ACCELERATED_DEATH_BENEFIT_RIDER",
  InsuranceRiderTypeEnum_CHILD_RIDER: "CHILD_RIDER",
  InsuranceRiderTypeEnum_SPOUSAL_RIDER: "SPOUSAL_RIDER",
  InsuranceRiderTypeEnum_TERM_RIDER: "TERM_RIDER",
  InsuranceRiderTypeEnum_GUARANTEED_INSURABILITY_RIDER:
    "GUARANTEED_INSURABILITY_RIDER",
  InsuranceRiderTypeEnum_WAIVER_OF_PREMIUM_RIDER: "WAIVER_OF_PREMIUM_RIDER",
  InsuranceRiderTypeEnum_UNEMPLOYMENT_PROTECTION_RIDER:
    "UNEMPLOYMENT_PROTECTION_RIDER",
  InsuranceRiderTypeEnum_INCOME_PROTECTION_RIDER: "INCOME_PROTECTION_RIDER",
  InsuranceRiderTypeEnum_ACCIDENTAL_DEATH_BENEFIT_RIDER:
    "ACCIDENTAL_DEATH_BENEFIT_RIDER",
  InsuranceRiderTypeEnum_RETURN_OF_PREMIUM_RIDER: "RETURN_OF_PREMIUM_RIDER",
  InsuranceRiderTypeEnum_TERM_CONVERSION_RIDER: "TERM_CONVERSION_RIDER",

  InsuranceRiderTypeEnum_ACCELERATED_INCOME_PROTECTION:
    "ACCELERATED_INCOME_PROTECTION",
  InsuranceRiderTypeEnum_EARLY_INCOME_PROTECTION: "EARLY_INCOME_PROTECTION",
  InsuranceRiderTypeEnum_MULTIPLE_LONG_TERM_INCOME_PROTECTION:
    "MULTIPLE_LONG_TERM_INCOME_PROTECTION",
  InsuranceRiderTypeEnum_INCOME_ACCIDENT_HOSPITAL: "INCOME_ACCIDENT_HOSPITAL",
  InsuranceRiderTypeEnum_INCOME_ACCIDENT_MEDICAL_REIMBURSEMENT:
    "INCOME_ACCIDENT_MEDICAL_REIMBURSEMENT",
  InsuranceRiderTypeEnum_MEDICAL_ROOM_BOARD: "MEDICAL_ROOM_BOARD",
  InsuranceRiderTypeEnum_MEDICAL_ANNUAL_LIMIT: "MEDICAL_ANNUAL_LIMIT",
  InsuranceRiderTypeEnum_MEDICAL_LIFETIME_LIMIT: "MEDICAL_LIFETIME_LIMIT",
  InsuranceRiderTypeEnum_MEDICAL_ILLNESS_LIMIT: "MEDICAL_ILLNESS_LIMIT",
  InsuranceRiderTypeEnum_MEDICAL_DEDUCTIBLE_CO_INSURANCE:
    "MEDICAL_DEDUCTIBLE_CO_INSURANCE",
  InsuranceRiderTypeEnum_YEARLY_DISABILITY_CASH_LUMP_SUM:
    "YEARLY_DISABILITY_CASH_LUMP_SUM",
  InsuranceRiderTypeEnum_DISABILITY_CASH: "DISABILITY_CASH",
  InsuranceRiderTypeEnum_OTHERS: "OTHERS"
}

export const GeneralClassificationEnum = {
  GeneralClassification_CLIENT: "CLIENT",
  GeneralClassification_PARTNER: "PARTNER",
  GeneralClassification_JOINT: "JOINT"
}

export const TopUpRedemptionGoalFrequencyEnum = {
  TopUpRedemptionGoalFrequencyEnum_ONCE_OFF: "ONCE_OFF",
  TopUpRedemptionGoalFrequencyEnum_EVERY_DAY: "EVERY_DAY",
  TopUpRedemptionGoalFrequencyEnum_EVERY_TWO_DAYS: "EVERY_TWO_DAYS",
  TopUpRedemptionGoalFrequencyEnum_EVERY_THREE_DAYS: "EVERY_THREE_DAYS",
  TopUpRedemptionGoalFrequencyEnum_EVERY_FOUR_DAYS: "EVERY_FOUR_DAYS",
  TopUpRedemptionGoalFrequencyEnum_EVERY_FIVE_DAYS: "EVERY_FIVE_DAYS",
  TopUpRedemptionGoalFrequencyEnum_EVERY_SIX_DAYS: "EVERY_SIX_DAYS",
  TopUpRedemptionGoalFrequencyEnum_EVERY_WEEK: "EVERY_WEEK",
  TopUpRedemptionGoalFrequencyEnum_EVERY_TWO_WEEKS: "EVERY_TWO_WEEKS",
  TopUpRedemptionGoalFrequencyEnum_EVERY_THREE_WEEKS: "EVERY_THREE_WEEKS",
  TopUpRedemptionGoalFrequencyEnum_EVERY_FOUR_WEEKS: "EVERY_FOUR_WEEKS",
  TopUpRedemptionGoalFrequencyEnum_EVERY_MONTH: "EVERY_MONTH",
  TopUpRedemptionGoalFrequencyEnum_EVERY_TWO_MONTHS: "EVERY_TWO_MONTHS",
  TopUpRedemptionGoalFrequencyEnum_EVERY_QUARTER: "EVERY_QUARTER",
  TopUpRedemptionGoalFrequencyEnum_EVERY_SIX_MONTHS: "EVERY_SIX_MONTHS",
  TopUpRedemptionGoalFrequencyEnum_EVERY_YEAR: "EVERY_YEAR",
  TopUpRedemptionGoalFrequencyEnum_EVERY_YEAR_END_OF_MONTH:
    "EVERY_YEAR_END_OF_MONTH",
  TopUpRedemptionGoalFrequencyEnum_EVERY_TWO_YEARS: "EVERY_TWO_YEARS",
  TopUpRedemptionGoalFrequencyEnum_EVERY_THREE_YEARS: "EVERY_THREE_YEARS",
  TopUpRedemptionGoalFrequencyEnum_EVERY_FOUR_YEARS: "EVERY_FOUR_YEARS",
  TopUpRedemptionGoalFrequencyEnum_EVERY_FIVE_YEARS: "EVERY_FIVE_YEARS",
  TopUpRedemptionGoalFrequencyEnum_EVERY_SIX_YEARS: "EVERY_SIX_YEARS",
  TopUpRedemptionGoalFrequencyEnum_EVERY_SEVEN_YEARS: "EVERY_SEVEN_YEARS",
  TopUpRedemptionGoalFrequencyEnum_EVERY_EIGHT_YEARS: "EVERY_EIGHT_YEARS",
  TopUpRedemptionGoalFrequencyEnum_EVERY_NINE_YEARS: "EVERY_NINE_YEARS",
  TopUpRedemptionGoalFrequencyEnum_EVERY_TEN_YEARS: "EVERY_TEN_YEARS"
}

export const PensionContributionTypeEnum = {
  PENSION_CONTRIBUTION_TYPE_MPF: "MPF",
  PENSION_CONTRIBUTION_TYPE_Super: "Super",
  PENSION_CONTRIBUTION_TYPE_SMSF: "SMSF",
  PENSION_CONTRIBUTION_TYPE_CPF: "CPF",
  PENSION_CONTRIBUTION_TYPE_SRS: "SRS",
  PENSION_CONTRIBUTION_TYPE_EPF: "EPF"
}

export const SalaryTypeEnum = {
  SALARY_TYPE_SALARY: "SALARY",
  SALARY_TYPE_BONUS: "BONUS"
}

export const TermDepositRolloverTypeEnum = {
  PRINCIPAL_ONLY: "P",
  PRINCIPAL_INTEREST: "PI",
  INTEREST_ONLY: "I"
}

export const clientInvestorTypeEnum = {
  CLIENTINVESTOR_RETAIL: "RETAIL",
  CLIENTINVESTOR_PROFESSIONAL: "PROFESSIONAL"
}
export const ClientCategoryTypeEnum = {
  ClientCategoryTypeEnum_NEW_CONTACT: "NEW_CONTACT",
  ClientCategoryTypeEnum_PROSPECT: "PROSPECT",
  ClientCategoryTypeEnum_CLIENT: "CLIENT",
  ClientCategoryTypeEnum_ARCHIVE: "ARCHIVE"
}

export const ClientCategoryExceptArchiveTypeEnum = {
  ClientCategoryTypeEnum_NEW_CONTACT: "NEW_CONTACT",
  ClientCategoryTypeEnum_PROSPECT: "PROSPECT",
  ClientCategoryTypeEnum_CLIENT: "CLIENT"
}

export const ManagedByEnum = {
  ManagedByEnum_ADVISOR: "ADVISOR",
  ManagedByEnum_CLIENT: "CLIENT",
  ManagedByEnum_THIRD_PARTY: "THIRD_PARTY"
}
