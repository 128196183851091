import React from "react"
import intl from "react-intl-universal"
import { Form, Field, FormSpy } from "react-final-form"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import {
  ModalNoAccess,
  RenderSimpleFields,
  FormButtons,
  ClassificationSelection
} from "../components"
import { FieldType } from "../components/Form/RenderSimpleFields"
import { renderJointSelectionMenuCustom } from "../helpers"
import MarginLoanDetails from "./sub-forms/MarginLoanDetails"

const UnitTrustExpensesForm = ({
  handleSave,
  model,
  optionsData,
  readOnlyAccess,
  clientInformation: { client = {}, partner = {}, isJoint = {} }
}) =>
  !readOnlyAccess ? (
    <Form
      onSubmit={handleSave}
      initialValues={model}
      render={({ handleSubmit, reset }) => (
        <div className="unit-trust-dialog">
          <form id="unitTrustForm" onSubmit={handleSubmit}>
            <FormSpy subscription={{ values: true }}>
              {({ values }) => {
                const isShowSidePane = values.financedThruLoan
                const isHasRegularSavingPlan = values.hasRegularSavingPlan
                return (
                  <div className="portal-form-popup UnitTrustExpensesForm">
                    <div className={isShowSidePane && "portal-form-half"}>
                      <ClassificationSelection />
                      {/* <Field
                                                name="classification"
                                                component={
                                                    renderJointSelectionMenuCustom
                                                }
                                                clientName={
                                                    client.personalDetails
                                                        .personalInformation
                                                        .firstName
                                                }
                                                partnerName={
                                                    partner &&
                                                    partner.personalDetails
                                                        ? partner
                                                              .personalDetails
                                                              .personalInformation
                                                              .firstName
                                                        : undefined
                                                }
                                            /> */}
                      <div className="subtitle2">
                        {intl.get("unitTrusts.subtitle")}
                      </div>

                      <RenderSimpleFields
                        fields={[
                          {
                            name: "unitTrustIssuerName",
                            label: intl.get("field.unitTrustIssuerName"),
                            type: FieldType.TEXT,
                            required: true
                          },
                          {
                            name: "unitTrustName",
                            label: intl.get("field.unitTrustName"),
                            type: FieldType.TEXT,
                            required: true
                          },

                          {
                            name: "datePurchased",
                            label: intl.get(`field.datePurchased`),
                            type: FieldType.DATE
                          },
                          {
                            name: "numberOfUnitsHeld",
                            label: intl.get("field.unitHeld"),
                            type: FieldType.NUMBER,
                            required: true,
                            isDecimal: true
                          },
                          {
                            name: "tradingCurrency",
                            label: intl.get("field.tradingCurrency"),
                            type: FieldType.ENUM_SELECTION,
                            enumData: optionsData.currencyOptions
                          },
                          {
                            name: "costBase",
                            label: intl.get("field.costBase"),
                            type: FieldType.MONEY_WITH_CURRENCY,
                            className: "portal-form-row popup-purchaseprice",
                            allowNegative: false
                          },
                          {
                            name: "currentValue",
                            label: intl.get("field.currentValueTotal"),
                            type: FieldType.MONEY_WITH_CURRENCY
                          },
                          {
                            name: "valuationDate",
                            label: intl.get(`field.valuationDate`),
                            type: FieldType.DATE
                          },
                          {
                            name: "annualGrowthRate",
                            label: intl.get("field.annualGrowthRate"),
                            type: FieldType.PERCENTAGE
                          },
                          {
                            name: "annualDistributionYield",
                            label: intl.get("field.annualDistributionYield"),
                            type: FieldType.PERCENTAGE
                          },
                          {
                            name: "distributionFrequency",
                            type: FieldType.ENUM_SELECTION,
                            label: intl.get("field.distributionFrequency"),
                            enumData: optionsData.frequencyOptions
                          },
                          {
                            name: "frankingPercent",
                            label: intl.get("field.franking"),
                            type: FieldType.PERCENTAGE
                          },
                          {
                            name: "lastDistributionPaymentDate",
                            label: intl.get(
                              "field.lastDistributionPaymentDate"
                            ),
                            type: FieldType.DATE
                          },
                          {
                            name: "annualTotalReturn",
                            label: intl.get("field.annualTotalReturn"),
                            type: FieldType.PERCENTAGE
                          }
                        ]}
                      />

                      <div className="subtitle2">
                        {intl.get("netWorth.fees")}
                      </div>
                      <RenderSimpleFields
                        fields={[
                          {
                            name: "managementFee",
                            label: intl.get("field.managementFee"),
                            type: FieldType.MONEY_WITH_CURRENCY
                          },
                          {
                            name: "managementFeeFrequency",
                            label: intl.get("field.managementFeeFrequency"),
                            type: FieldType.ENUM_SELECTION,
                            enumData: optionsData.frequencyOptions
                          },
                          {
                            name: "administrationFee",
                            label: intl.get("field.administrationFee"),
                            type: FieldType.MONEY_WITH_CURRENCY
                          },
                          {
                            name: "administrationFeeFrequency",
                            label: intl.get("field.administrationFeeFrequency"),
                            type: FieldType.ENUM_SELECTION,
                            enumData: optionsData.frequencyOptions
                          },
                          {
                            name: "hasRegularSavingPlan",
                            label: intl.get("field.hasRegularSavingPlan"),
                            type: FieldType.CHECKBOX,
                            checkboxLabel: intl.get("YesNoEnum_YES")
                          }
                        ]}
                      />

                      {isHasRegularSavingPlan && (
                        <RenderSimpleFields
                          fields={[
                            {
                              name: "savingAmount",
                              label: intl.get("field.savingAmount"),
                              type: FieldType.MONEY_WITH_CURRENCY
                            },
                            {
                              name: "savingFrequency",
                              label: intl.get("field.savingFrequency"),
                              type: FieldType.ENUM_SELECTION,
                              enumData: optionsData.frequencyOptions
                            }
                          ]}
                        />
                      )}

                      <RenderSimpleFields
                        fields={[
                          {
                            name: "financedThruLoan",
                            label: intl.get("field.investmentUnderLoan"),
                            type: FieldType.CHECKBOX,
                            checkboxLabel: intl.get("YesNoEnum_YES")
                          }
                        ]}
                      />
                    </div>
                    {isShowSidePane && (
                      <div className="portal-form-half">
                        <Tabs>
                          <TabList>
                            <Tab>{intl.get("tabs.marginLoanDetails")}</Tab>
                          </TabList>
                          <TabPanel>
                            <MarginLoanDetails optionsData={optionsData} />
                          </TabPanel>
                        </Tabs>
                      </div>
                    )}
                    <FormButtons
                      reset={reset}
                      readOnlyAccess={readOnlyAccess}
                    />
                  </div>
                )
              }}
            </FormSpy>
          </form>
        </div>
      )}
    />
  ) : (
    <ModalNoAccess />
  )
export default UnitTrustExpensesForm
