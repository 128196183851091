import React, { Component } from "react"
import { Form, Field } from "react-final-form"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import intl from "react-intl-universal"
import {
  renderSelectFieldColumn,
  renderTextBoxFieldColumn
} from "../../helpers"
import { required } from "../../helpers/Validator"
import { userActions } from "../../actions"
import AuthApi from "../../api/AuthApi"

export class VerificationForm extends Component {
  state = {
    entities: [],
    loginId: "",
    entitySelected: false
  }

  handleKeyPress = (event) => {
    if (event.key === "Enter" || event.charCode === 13) {
      this.handleTwoFA()
    }
  }

  handleTwoFA = (values) => {
    const self = this
    const data = {
      id: localStorage.getItem("id"),
      tfaCode: Number(values.tfacode)
    }
    if (!self.state.entitySelected) {
      self.props.tfaLogin(data).then((response) => {
        if (response && response.entities) {
          const entities = Object.entries(response.entities).map(
            ([key, value]) => ({
              key,
              value
            })
          )
          self.setState({
            entities,
            entitySelected: true,
            loginId: response.loginId
          })
        }
      })
    } else {
      AuthApi.selectEntity({
        loginId: self.state.loginId,
        selectedEntityId: values.selectedEntityId
      }).then((response) => {
        localStorage.setItem("base-route", response.data.backendUrl)
        self.props.setUser(response)
      })
    }
  }

  render() {
    const { handleTwoFA, handleKeyPress } = this
    return (
      <Form
        onSubmit={handleTwoFA}
        subscription={{ submitting: true, pristine: true }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <Field
              name="tfacode"
              type="number"
              label="Enter 6 digit number"
              component={renderTextBoxFieldColumn}
              required
              validate={required}
              onKeyPress={handleKeyPress}
            />
            <div className="entities">
              {this.state.entities.length > 1 && (
                <Field
                  name="selectedEntityId"
                  component={renderSelectFieldColumn}
                  label="Entities"
                  data={this.state.entities}
                  valueProperty={"key"}
                  labelProperty={"value"}
                  required
                  validate={required}
                />
              )}
            </div>

            <button disabled={this.props.loading} className="btn-login">
              {intl.get("login.continue")}
            </button>
          </form>
        )}
      />
    )
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication
  return {
    loggingIn
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(userActions, dispatch)
}

VerificationForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(VerificationForm)

export default VerificationForm
