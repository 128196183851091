import React, { useState, useEffect, useContext } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Form, Field, FormSpy } from "react-final-form"
import intl from "react-intl-universal"
import { netWorthFormActions, modalWindowActions } from "../../../../actions"
import { modalWindow } from "../../../../constants/ModalWindowLists"
import NetWorthForm, {
  NetWorthPath,
  NetWorthFormPropsComposer
} from "../../NetWorthForm"
import { AppContext } from "../../../../context/AppContext"

import SuperannuationForm from "./SuperannuationForm"



class SuperannuationFundNew extends NetWorthForm {
  constructor(props) {
    super(
      props,
      intl.get("superannuationFund.title"),
      modalWindow.SUPERANNUATION_FUND,
      NetWorthPath.superannuationFunds,
      "SuperannuationFund"
    )
  }

  render() {
    const {
      optionsData,
      model,
      client,
      partner,
      isJoint,
      currency,
    } = this.props;
    const { handleSave, contentWrapper, readOnlyAccessComponent } = this;

    return contentWrapper(
      <SuperannuationForm 
          handleSave = {handleSave}
          model={model} 
          client = {client}
          partner = {partner} 
          optionsData={optionsData} 
          isjoint = {isJoint}
      />
    )
  }
}

function mapStateToProps(state) {
  const isShowSubForm =
    state.modalWindow[modalWindow.SUPERANNUATION_FUND_SUB_FORM]

  const props = NetWorthFormPropsComposer(
    state,
    modalWindow.SUPERANNUATION_FUND,
    NetWorthPath.superAnnuationFund
  )

  return {
    ...props,
    isShowSubForm
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, netWorthFormActions, modalWindowActions),
    dispatch
  )
}

SuperannuationFundNew.contextType = AppContext

SuperannuationFundNew = connect(
  mapStateToProps,
  mapDispatchToProps
)(SuperannuationFundNew)

export default SuperannuationFundNew
