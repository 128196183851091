import React, { useContext } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { enumOptionsActions } from '../../../actions';
import { AppContext } from '../../../context/AppContext';
import InvestmentPlatformForm from '../SubForm/InvestmentPlatformForm';

const CPFInvestmentPlatformTable = ({
    optionsData,
    model,
    mutators,
    fieldName
}) => {
    const {
        modal: { setModal, clearModal },
    } = useContext(AppContext);
    const handleSave = values => {
        mutators.push(`${fieldName}`, values);
        clearModal();
    };


    const handleDelete = id => {
        mutators.remove(`${fieldName}`, id);
    };
    const generateInvestmentPlatform = () => {
        if (model) {
            return model.map((a, idx) => (
                <tr key={idx}>
                    <td >
                        {a.investmentProviderName}
                    </td>
                    <td >
                        {a.institutionName}

                    </td>
                    <td >
                        {a.platformAccountNumber}

                    </td>
                    <td >
                        {a.accountStartDate}
                    </td>
                    <td >
                        {a.accountBalance.value}
                    </td>
                    <td >
                        {a.accountValuationDate}
                    </td>
                    <td>
                        <span
                            onClick={() => {
                                const edit = (model) => {
                                    mutators.update(`${fieldName}`, idx, model);
                                    clearModal();
                                };

                                setModal({
                                    title: intl.get(
                                        'netWorth.investmentDetails.investmentPlatform.title'
                                    ),
                                    content: (
                                        <InvestmentPlatformForm
                                            optionsData={optionsData}
                                            handleSave={edit}
                                            model={a}
                                            currency={'SGD'}
                                        />
                                    ),
                                    formType: 'investmentPlatform',
                                })
                            }}
                            className="btn btn-edit"
                        />

                        <span
                            onClick={() => handleDelete(idx)}
                            className="btn-remove"
                        />
                    </td>
                </tr>
            ));
        }
    }
    const data = [
        {
            label:
                'netWorth.investmentDetails.investmentPlatform.investmentPlatformName',
            name: 'investmentProviderName',
        },
        {
            label:
                'netWorth.investmentDetails.investmentPlatform.institutionName',
            name: 'institutionName',
        },
        {
            label:
                'netWorth.investmentDetails.investmentPlatform.accountNumber',
            name: 'platformAccountNumber',
        },
        {
            label:
                'netWorth.investmentDetails.investmentPlatform.accountStartDate',
            name: 'accountStartDate',
            isDate: true,
        },
        {
            label:
                'netWorth.investmentDetails.investmentPlatform.accountBalance',
            name: 'accountBalance',
        },
        {
            label:
                'netWorth.investmentDetails.investmentPlatform.valuationDate',
            name: 'accountValuationDate',
            isDate: true,
        },
    ];
    return (
        <table
            className="table-investment"
            width="100%"
            cellSpacing="0"
            cellPadding="0"
            border="0"
        >
            <thead>
                <tr>
                    {data.map((item, id) => (
                        <th key={id}>{intl.get(item.label).toUpperCase()}</th>
                    ))}
                    <th />
                    <th>
                        <span
                            onClick={() =>
                                setModal({
                                    title: intl.get(
                                        'netWorth.investmentDetails.investmentPlatform.title'
                                    ),
                                    content: (
                                        <InvestmentPlatformForm
                                            optionsData={optionsData}
                                            handleSave={handleSave}
                                            currency={'SGD'}
                                        />
                                    ),
                                    formType: 'investmentPlatformForm',
                                })
                            }
                            className="btn btn-plus"
                        />
                    </th>
                </tr>
            </thead>
            <tbody>
                {generateInvestmentPlatform()}
            </tbody>
        </table>
    );
};

function mapStateToProps(state) {
    const { optionsData } = state.enumOptions;

    return {
        optionsData,
    };
}

function mapDispatchToProps(dispatch) {

    return bindActionCreators(enumOptionsActions, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CPFInvestmentPlatformTable);