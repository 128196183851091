/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Description : business ownership main page (modal)
*/

import React from "react"
import intl from "react-intl-universal"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { modalWindow } from "../../constants/ModalWindowLists"
import { netWorthFormActions, modalWindowActions } from "../../actions"
import NetWorthForm, {
  NetWorthPath,
  NetWorthFormPropsComposer
} from "./NetWorthForm"
import { BusinessOwnershipForm } from "../../forms"

class BusinessOwnership extends NetWorthForm {
  constructor(props) {
    super(
      props,
      intl.get("businessOwnership.title"),
      modalWindow.BUSINESS_OWNERSHIP_MODAL,
      NetWorthPath.businessOwnerships,
      "BusinessOwnership"
    )
  }

  isShowSidePane = () => {
    const { model } = this.props
    return model && model.paidUpCapitalFinancedThruLoan
  }

  setDefaultData = (originalModel) => {
    const { currency = "USD" } = this.props

    if (!originalModel) {
      originalModel = {
        paidUpCapital: { currency },
        currentValue: { currency },
        dividendValue: { currency }
      }
    }
    if (!originalModel.paidUpCapital) {
      originalModel.paidUpCapital = { currency }
    }
    if (!originalModel.currentValue) {
      originalModel.currentValue = { currency }
    }
    if (!originalModel.dividendValue) {
      originalModel.dividendValue = { currency }
    }

    return originalModel
  }

  render() {
    const { optionsData, model, currency } = this.props

    const {
      handleSave,
      contentWrapper,
      readOnlyAccessComponent,
      setDefaultData
    } = this
    return contentWrapper(
      <BusinessOwnershipForm
        handleSave={handleSave}
        model={setDefaultData(model)}
        optionsData={optionsData}
        readOnlyAccess={readOnlyAccessComponent()}
        currency={currency}
      />,

      () => {
        model.currentValue = { currency, value: "" }
        model.dividendValue = { currency, value: "" }
        model.paidUpCapital = { currency, value: "" }
      }
    )
  }
}

function mapStateToProps(state) {
  return NetWorthFormPropsComposer(
    state,
    modalWindow.BUSINESS_OWNERSHIP_MODAL,
    NetWorthPath.businessOwnerships
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, netWorthFormActions, modalWindowActions),
    dispatch
  )
}

BusinessOwnership = connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessOwnership)

export default BusinessOwnership
