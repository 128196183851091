/*
    Author : Marlina (marlina@devstack.com.au)
    Author : Erik (erik.malik@devstack.com.au)
    Description : Bond main page (modal)
*/

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import intl from 'react-intl-universal';
import { modalWindow } from '../../constants/ModalWindowLists';
import { netWorthFormActions, modalWindowActions } from '../../actions';
import NetWorthForm, {
    NetWorthPath,
    NetWorthFormPropsComposer,
} from './NetWorthForm';
import { BondForm } from '../../forms';

class Bond extends NetWorthForm {
    constructor(props) {
        super(
            props,
            intl.get('bonds.title'),
            modalWindow.BOND_FORM,
            NetWorthPath.bonds,
            'Bond'
        );
    }

    render() {
        const {
            optionsData,
            model,
            client,
            partner,
            isJoint,
            currency,
        } = this.props;
        const { handleSave, contentWrapper, readOnlyAccessComponent } = this;

        return contentWrapper(
            <BondForm
                handleSave={handleSave}
                model={model}
                optionsData={optionsData}
                readOnlyAccess={readOnlyAccessComponent()}
                clientInformation={{ client, partner, isJoint }}
                defaultCurrency={currency}
            />
        );
    }
}
function mapStateToProps(state) {
    return NetWorthFormPropsComposer(
        state,
        modalWindow.BOND_FORM,
        NetWorthPath.bonds
    );
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        Object.assign({}, netWorthFormActions, modalWindowActions),
        dispatch
    );
}

Bond = connect(
    mapStateToProps,
    mapDispatchToProps
)(Bond);
export default Bond;
