import Alert from "react-s-alert"
import intl from "react-intl-universal"
import { CountryList } from "../constants/Lists"
import { entityAction } from "../constants/ActionConstants"
import { setActiveUser } from "./AppSettingsActions"
import { EntityApi, AdviserApi, PersonalDetailsApi } from "../api"
import { EntityTypeEnum, CurrencyEnum } from "../constants/Enums"
import { history } from "../helpers"

export const entityActions = {
  getEntity,
  addEntity,
  addEntityAsChild,
  storeLoginData,
  getClient,
  emptyEntityState
  // editEntity,
  // deleteEntity,
}

export function getEntitySuccess(entity) {
  return { type: entityAction.GET_ENTITY_SUCCESS, entity }
}

export function emptyEntityState() {
  return { type: entityAction.EMPTY_ENTITY_SUCCESS }
}
export function getClientIdSuccess(client, partner) {
  return {
    type: entityAction.HasAcknowledgedTermsAndConditions,
    client,
    partner
  }
}

export function getLoginEntitySuccess(entity) {
  return { type: entityAction.GET_LOGIN_ENTITY_SUCCESS, entity }
}

export function addEntitySuccess(response) {
  return { type: entityAction.ADD_ENTITY_SUCCESS, response }
}

export function addEntity(model) {
  return async (dispatch, getState) => {
    // api request here
    switch (model.entityDetails.entityType) {
      case EntityTypeEnum.EntityTypeEnum_BUILT_IN_ADVISER:
        return AdviserApi.addAdviser(model).then((response) => {
          Alert.success(
            intl.get("alert.addDataSuccess", {
              name: "Adviser"
            })
          )
          dispatch(addEntitySuccess(response))
        })
      default:
        return EntityApi.addEntity(model).then((response) => {
          Alert.success(
            intl.get("alert.addDataSuccess", {
              name: "Entity"
            })
          )
          dispatch(addEntitySuccess(response))
        })
    }
  }
}

export function addEntityAsChild(parentId, model) {
  return async (dispatch, getState) => {
    // api request here
    switch (model.entityDetails.entityType) {
      case EntityTypeEnum.EntityTypeEnum_BUILT_IN_ADVISER:
        return AdviserApi.addAdviserAsChild(parentId, model).then(
          (response) => {
            Alert.success(
              intl.get("alert.addDataSuccess", {
                name: "Adviser"
              })
            )
            dispatch(addEntitySuccess(response))
          }
        )
      default:
        return EntityApi.addEntityAsChild(parentId, model).then((response) => {
          Alert.success(
            intl.get("alert.addDataSuccess", {
              name: "Entity"
            })
          )
          dispatch(addEntitySuccess(response))
        })
    }
  }
}

export function getEntity(id, setLayout = false) {
  return async (dispatch, getState) => {
    // call request is here
    EntityApi.getUserEntityById(id).then((response) => {
      const { data } = response

      dispatch(getEntitySuccess(data))

      // should we set the layout after retrieve this data
      if (setLayout) {
        dispatch(setActiveUser(data))
      }
    })
  }
}

export function getClient(id) {
  // const [currency, setCurrency] = useState("");

  return async (dispatch, getState) => {
    // call request is here
    PersonalDetailsApi.getWithPartner(id)
      .then((response) => {
        let currency

        if (
          response.data.client.personalDetails.employmentDetails.taxResidency
        ) {
          const countryTaxResidency = CountryList.find(
            (x) =>
              x.code ===
              response.data.client.personalDetails.employmentDetails
                .taxResidency
          )
          if (
            countryTaxResidency &&
            Object.values(CurrencyEnum).includes(
              countryTaxResidency.abbreviation
            )
          ) {
            currency = countryTaxResidency.abbreviation
          } else {
            currency = "USD"
          }
        } else {
          currency = "USD"
        }

        localStorage.setItem("theCurrency", currency)

        dispatch(
          getClientIdSuccess(
            response.data.client,
            response.data.partner ? response.data.partner : undefined
          )
        )
      })
      .catch(function (error) {
        const activeDashboardRoute = localStorage.getItem(
          "activeDashboardRoute"
        )
        const loggingIn = localStorage.getItem("loggingIn")

        if (activeDashboardRoute && loggingIn) {
          history.push(activeDashboardRoute)
          localStorage.removeItem("activeClientId")
        }
      })
  }
}

// store entity data to local storage
export function storeLoginData(loginData) {
  let userRole = loginData.selectedRole
  let entity = loginData.selectedEntity
  let entityLessInfo = {
    id: entity.id,
    country: entity.entityDetails.country,
    photo: {
      id: entity.photo.id,
      url: entity.photo.url,
      title: entity.photo.title,
      type: entity.photo.type
    }
  }
  localStorage.setItem("loginUserEntity", JSON.stringify(entityLessInfo))
  localStorage.setItem("loginEntityType", entity.entityDetails.entityType)
  localStorage.setItem("activeEntityId", entity.id)
  localStorage.setItem("activeUserRole", JSON.stringify(userRole))
}
