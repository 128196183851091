import React from "react";

const ScrollUpButton = ({ sectionId, scrollIntoView = false }) => (
    <div
        onClick={() => {
            scrollIntoView ? 
                document.getElementById(sectionId).scrollIntoView()
            : 
                document.getElementById(sectionId).scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
        }}
        className="back-to-top"
        style={{ cursor: "pointer" }}
    >
        <span className="arrow-back-to-top ico-arrowtop" />
    </div>
);
export default ScrollUpButton;
