/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Description : radial indicator component for showing progress in radial form
*/

import React from "react"
import formatCompactNumber from "../../utils/formatCompactNumber"

// let progressValue = 0;

const RadialIndicator = ({
  currentValue,
  targetValue,
  realValue,
  extraDesc = "",
  size = "large",
  borderColor,
  inPercentage
}) => {
  let displayColor
  if (borderColor === "ratio-red") {
    displayColor = "rgb(242,46,46)"
  } else {
    displayColor = "rgb(144,197,68)"
  }

  const progressPercentageValue = 100

  return (
    <div
      className={`${
        size === "small" ? "set-size-small" : "set-size-large"
      } charts-container`}
    >
      <div
        className={`pie-wrapper progress-${
          progressPercentageValue > 100 ? "100" : progressPercentageValue
        } style-2`}
      >
        <span className="label">
          {realValue != null && !isNaN(realValue)
            ? formatCompactNumber(realValue)
            : "0"}
          {inPercentage ? "%" : ""}
        </span>
        <span className="label">
          <span className="pie-subtitle">{extraDesc}</span>
        </span>
        <div className="pie">
          <div
            className="left-side half-circle"
            style={{ borderColor: displayColor }}
          />
          <div
            className="right-side half-circle"
            style={{ borderColor: displayColor }}
          />
        </div>
        <div className="shadow" />
      </div>
    </div>
  )
}

export default RadialIndicator
