import { entityAction } from "../constants/ActionConstants"
const initialState = {
  hasAcknowledgedTermsAndConditions: false,
  clientId: "",
  statusOfClient: "",
  client: {},
  hasPartner: false,
  partner: {},
  clientFullNamePreferred: "",
  partnerFullNamePreferred: "",
  clientFullNameFamilyNameLast: "",
  clientFullNameFamilyNameFirst: "",
  partnerFullNameFamilyNameLast: "",
  partnerFullNameFamilyNameFirst: ""
}
export function entity(state = {}, action) {
  switch (action.type) {
    case entityAction.GET_ENTITY_SUCCESS:
      return {
        ...state,
        userEntity: action.entity,
        userEntityType: action.entity,
        userAccessLevel: action.entity,
        userClassName: action.entity
      }
    case entityAction.ADD_ENTITY_SUCCESS:
      return {
        items: action.response
      }
    case entityAction.EDIT_ENTITY_SUCCESS:
      return {
        items: action.response
      }
    case entityAction.DELETE_ENTITY_SUCCESS:
      return {
        items: action.response
      }
    case entityAction.EMPTY_ENTITY_SUCCESS:
      return initialState
    case entityAction.HasAcknowledgedTermsAndConditions:
      // client
      let clientFullNameFamilyNameLast = `${action.client.personalDetails.personalInformation.firstName} ${action.client.personalDetails.personalInformation.familyName} `
      let clientFullNameFamilyNameFirst = `${action.client.personalDetails.personalInformation.familyName} ${action.client.personalDetails.personalInformation.firstName}`
      let clientFirstName = `${action.client.personalDetails.personalInformation.firstName}`

      let clientName = `${
        action.client.personalDetails.personalInformation.preferredName
          ? action.client.personalDetails.personalInformation.preferredName
          : clientFirstName
      }`

      // partner
      let partnerName
      let partnerFullNameFamilyNameFirst
      let partnerFullNameFamilyNameLast
      let partnerFirstName

      if (action.partner && action.partner.personalDetails) {
        partnerFirstName = `${action.partner.personalDetails.personalInformation.firstName}`
        partnerFullNameFamilyNameFirst = `${action.partner.personalDetails.personalInformation.familyName} ${action.partner.personalDetails.personalInformation.firstName}`
        partnerFullNameFamilyNameLast = `${action.partner.personalDetails.personalInformation.firstName} ${action.client.personalDetails.personalInformation.familyName}`
        partnerName = `${
          action.partner.personalDetails.personalInformation.preferredName
            ? action.partner.personalDetails.personalInformation.preferredName
            : partnerFirstName
        }`
      }

      return {
        ...state,
        hasAcknowledgedTermsAndConditions:
          action.client.hasAcknowledgedTermsAndConditions,
        clientId: action.client.id,
        statusOfClient: action.client.status,
        client: action.client,
        partner: action.partner,
        hasPartner: action.partner ? true : false,
        clientFullNamePreferred: clientName,
        partnerFullNamePreferred: partnerName,
        clientFullNameFamilyNameLast,
        partnerFullNameFamilyNameLast,
        clientFullNameFamilyNameFirst,
        partnerFullNameFamilyNameFirst
      }
    default:
      return state
  }
}
