import api from "../lib/api";
const BASE_ROUTE = "/note";

class NotesApi {
    static add(data) {
        return api.post(`${BASE_ROUTE}`, data);
    }
    static getNotes(id) {
        return api.get(`/note/client/${id}`);
    }
}
export default NotesApi;
