import React, { useState, useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import intl from 'react-intl-universal';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { enumOptionsActions } from "../../../actions/EnumOptionsActions";
import CPFInvestmentProperty from "./CPFInvestmentProperty";
import CPFResidenceProperty from "./CPFResidenceProperty";
import { personalDetailsActions } from "../../../actions";
import CPFChildrenEducation from "./CPFChildrenEducation";

const CpfLessBorrowing = ({ onClick,
    optionsData,
    personalDetails,
    model = { invesmentProperty: [], residentalProperty: [], childrenEducation: [] },
    mutators
}) => {

    const client = personalDetails;
    const partner = personalDetails.partner || {};
    const isJoint = personalDetails.partner || false;

    const {
        modal: { setModal, clearModal },
    } = useContext(AppContext);

    const showInvestmentProperty = () => {
        const handleSave = (model) => {
            mutators.push(
                'cpfSummary.lessBorrowings.investmentPropertyList',
                model
            )
            clearModal();
        };
        setModal({
            title: intl.get(
                'cashflowlisting.investmentOutflow.investmentProperty'
            ),
            content: <CPFInvestmentProperty
                optionsData={optionsData}
                client={client}
                partner={partner}
                isJoint={isJoint}
                handleSave={handleSave}
            />,
            formType: 'InvestmentProperty',
        });
    };

    const showResidentalProperty = () => {
        const handleSave = (model) => {
            mutators.setValue(
                'cpfSummary.lessBorrowings.residentialProperty',
                model
            )
            clearModal();
        };
        setModal({
            title: 'Residental Property',
            content: <CPFResidenceProperty
                optionsData={optionsData}
                client={client}
                partner={partner}
                isJoint={isJoint}
                handleSave={handleSave}
            />,
            formType: 'homeExpenses',
        });
    };

    const showChildrenEducation = () => {
        const handleSave = (model) => {
            mutators.push(
                'cpfSummary.lessBorrowings.childrenEducationList',
                model
            )
            clearModal();
        };
        setModal({
            title: 'Children Education',
            content: <CPFChildrenEducation
                optionsData={optionsData}
                client={client}
                partner={partner}
                isJoint={isJoint}
                model={{ classification: model.classification }}
                handleSave={handleSave}
            />,
            formType: 'childrenEducation',
        });
    };

    const [lessBorrowing, setLessBorrowing] = useState(false);
    const selected = (value) => {
        switch (value) {
            case 'InvestmentProperty': {
                showInvestmentProperty();
                break;
            }
            case 'ResidentalProperty': {
                showResidentalProperty();
                break;
            }
            case 'ChildrenEducation': {
                showChildrenEducation();
                break;
            }
            default: break;
        }
        onClick(value);
        setLessBorrowing(false);
    }
    return (<div className="dropdown">
        <button type="button" className="btn btn-plus btn-space-L" onClick={() => setLessBorrowing(!lessBorrowing)}></button>
        <div className={`dropdown-content ${lessBorrowing ? 'show' : ''}`}>
            <div onClick={() => selected('ResidentalProperty')}>Residental Property</div>
            <div onClick={() => selected('InvestmentProperty')}>Investment Property</div>
            <div onClick={() => selected('ChildrenEducation')}>Children Education</div>
        </div>
    </div>
    );

}

function mapStateToProps(state) {
    const { optionsData } = state.enumOptions;
    const { personalDetails } = state;

    return {
        optionsData,
        personalDetails: personalDetails.data
    };
}

function mapDispatchToProps(dispatch) {

    return bindActionCreators(enumOptionsActions, personalDetailsActions, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CpfLessBorrowing);
