import React, { useEffect, useState } from "react";
import api from "../../lib/api";
const Summary = () => {
    const loginEntityType = localStorage.getItem("loginEntityType");
    const [summaryData, setSummaryData] = useState();
    const [allYears, setAllYears] = useState([]);
    const [allAdvisers, setAllAdvisers] = useState([]);
    const [supportedTypes, setSupportedTypes] = useState(["CPD"]);

    const isTypeSupported = type => {
        let result = false;
        if (supportedTypes) {
            supportedTypes.forEach(t => {
                if (t == type) {
                    result = true;
                }
            });
        }
        return result;
    };

    const getCpd = async () => {
        try {
            const data = await api.get("/cpd");
            let filteredsummary = [];
            let advisers = [];
            let distinctYears = [];
            if (data && data.data.supportedTypes) {
                setSupportedTypes(data.data.supportedTypes);
            }

            if (data && data.data.summaryPerAdviserList.length) {
                data.data.summaryPerAdviserList.map(item => {
                    let newSummary =
                        item.yearlySummaryList.length &&
                        item.yearlySummaryList.map(itemIs => {
                            advisers.push(item.adviserName);

                            return { ...itemIs, adviserName: item.adviserName };
                        });
                    filteredsummary.push(...newSummary);
                    distinctYears.push(...item.distinctYears);
                    setAllYears([...distinctYears]);

                    setAllAdvisers([...advisers]);
                    setSummaryData({
                        tableData: filteredsummary,
                        advisers: advisers,
                        distinctYears: distinctYears
                    });
                });
            }
        } catch (error) {
            console.log("error is", error);
        }
    };
    useEffect(() => {
        getCpd();
    }, []);
    const [currentYear, setCurrentYear] = useState("");
    const [currentAdviser, setCurrentAdviser] = useState("");
    const [filteredData, setFilteredData] = useState();

    useEffect(() => {
        if (
            summaryData &&
            summaryData.tableData &&
            summaryData.tableData.length
        ) {
            if (currentYear && !currentAdviser) {
                const filtered = summaryData.tableData.filter(
                    item => item.year == currentYear
                );
                if (filtered.length) {
                    setFilteredData({ ...summaryData, tableData: filtered });
                } else {
                    setFilteredData();
                }
            }
            if (currentAdviser && !currentYear) {
                const filtered = summaryData.tableData.filter(
                    item => item.adviserName == currentAdviser
                );
                if (filtered.length) {
                    setFilteredData({ ...summaryData, tableData: filtered });
                } else {
                    setFilteredData();
                }
            }
            if (currentAdviser && currentYear) {
                const filtered = summaryData.tableData.filter(
                    item =>
                        item.adviserName == currentAdviser &&
                        item.year == currentYear
                );
                if (filtered.length) {
                    setFilteredData({ ...summaryData, tableData: filtered });
                } else {
                    setFilteredData();
                }
            }
            if (!currentAdviser && !currentYear) {
                setFilteredData(summaryData);
            }
        }
    }, [summaryData, currentYear, currentAdviser]);

    return (
        <div>
            <div className="filter-part-summary">
                <div className="filter-summary-inner">
                    <label htmlFor="year">Year</label>
                    <div className="select-box-cover select-box">
                        <select
                            name="currentYear"
                            value={currentYear}
                            onChange={e => setCurrentYear(e.target.value)}
                            id="currentYear"
                        >
                            <option value="">All</option>
                            {allYears &&
                                allYears.length &&
                                [...new Set(allYears)].map((item, key) => {
                                    return <option key={key}>{item}</option>;
                                })}
                        </select>
                    </div>
                </div>
                <div className="filter-summary-inner">
                    <label htmlFor="Advisers">Adviser</label>
                    <div className="select-box-cover select-box">
                        <select
                            name="currentAdviser"
                            id="currentAdviser"
                            value={currentAdviser}
                            onChange={e => setCurrentAdviser(e.target.value)}
                        >
                            <option value="">All</option>

                            {allAdvisers &&
                                allAdvisers.length &&
                                [...new Set(allAdvisers)].map((item, key) => {
                                    return <option key={key}>{item}</option>;
                                })}
                        </select>
                    </div>
                </div>
            </div>
            <table
                className="table-blue personal-dealings-table table-cs-popup_edu table-cs-popup_edu-estimate"
                width="100%"
                cellSpacing="0"
                cellPadding="0"
                border="0"
            >
                <tbody>
                    <tr>
                        <th>#</th>
                        {loginEntityType !== "BUILT_IN_ADVISER" && (
                            <th>Adviser Name</th>
                        )}
                        <th>Year</th>
                        <th>Target CPD Points</th>
                        {isTypeSupported("CPT") && <th>Target CPT Points</th>}

                        <th>CPD Points</th>
                        {isTypeSupported("CPT") && <th>CPT Points</th>}

                        {isTypeSupported("CE") && <th>CE Points</th>}

                        <th>Remaining CPD Points</th>
                        {isTypeSupported("CPT") && (
                            <th style={{ width: "80px" }}>
                                Remaining CPT Points
                            </th>
                        )}
                        {isTypeSupported("CE") && (
                            <th style={{ width: "80px" }}>
                                Remaining CE Points
                            </th>
                        )}
                    </tr>
                    {filteredData &&
                    filteredData &&
                    filteredData.tableData.length
                        ? filteredData.tableData.map((item, index) => {
                              return (
                                  <tr key={index}>
                                      <td>{index + 1}</td>
                                      {loginEntityType !==
                                          "BUILT_IN_ADVISER" && (
                                          <td>{item.adviserName}</td>
                                      )}
                                      <td>{item.year}</td>
                                      <td>{item.targetCpdPoints}</td>
                                      {isTypeSupported("CPT") && (
                                          <td>{item.targetCptPoints}</td>
                                      )}

                                      <td>{item.cpdPoints}</td>
                                      {isTypeSupported("CPT") && (
                                          <td>{item.cptPoints}</td>
                                      )}
                                      {isTypeSupported("CE") && (
                                          <td>{item.cePoints}</td>
                                      )}

                                      <td>{item.remainingCpdPoints}</td>
                                      {isTypeSupported("CPT") && (
                                          <td>{item.remainingCptPoints}</td>
                                      )}
                                      {isTypeSupported("CE") && (
                                          <td>{item.remainingCePoints}</td>
                                      )}
                                  </tr>
                              );
                          })
                        : ""}
                </tbody>
            </table>
        </div>
    );
};

export default Summary;
