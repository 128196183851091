import React, { useState, useEffect } from "react"
import { Form, FormSpy, Field } from "react-final-form"
import intl from "react-intl-universal"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Alert from "react-s-alert"
import { ConfirmationDialog, MalabarDate } from "../../components"
import { CountryList } from "../../constants/Lists"
import { translateListLabel } from "../../helpers/list-translation"
import RenderSimpleFieldsBootrap, {
  FieldType
} from "../../components/Form/RenderSimpleFieldsBootstrap"
import {
  renderTextBoxCustom,
  renderCheckboxCustom,
  renderSelectFieldCustom,
  renderDateFieldCustom
} from "../../helpers/FormRender"
import { entityActions } from "../../actions/EntityActions"
import { modalWindowActions } from "../../actions/ModalWindowActions"
import { maxPhotoSize } from "../../helpers"
import { image, composeValidators, required } from "../../helpers/Validator"
import { fileFieldRenderBootstrapWithoutLabel } from "../../helpers/FormRenderBootstrap"
import { createUrlFromPath } from "../../helpers/ImageHelper"
import UserApi from "../../api/UserApi"

import { EntityApi } from "../../api"
import { userActions } from "../../actions/UserActions"
import { reloadWindow } from "../../helpers/SessionHelper"

import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
function FormContainer({
  model,
  readOnlyAccess,
  handleSave,
  entityType,
  preview,
  uploaded,
  removePhoto,
  photoshow,
  clickHandler
}) {
  /* internalIdentifier */
  const operationInformationFields = [
    {
      name: "entityName",
      label: `${intl.get(`entity.field.${entityType}`)} ${intl.get(
        "field.name"
      )}`,
      type: FieldType.TEXT,
      required: true
    },

    {
      name: "businessTitle",
      label: `${intl.get(`entity.field.${entityType}`)} ${intl.get(
        "entity.field.businessTitle"
      )}`,
      type: FieldType.TEXT
    },
    {
      name: "entityUniqueId",
      label: `${intl.get(`entity.field.${entityType}`)} ${intl.get(
        "entity.field.internalIdentifier"
      )}`,
      type: FieldType.TEXT
    },

    {
      name: "supervisor",
      label: `${intl.get(`entity.field.${entityType}`)} ${intl.get(
        "entity.field.SupervisorDirectReport"
      )}`,
      type: FieldType.TEXT
    },
    {
      name: "managerInCharge",
      label: intl.get("field.managerInCharge"),
      type: FieldType.TEXT
    },
    {
      name: "country",
      label: intl.get("field.countryBased"),
      type: FieldType.SELECTION,
      selectionData: translateListLabel(CountryList),
      valueProperty: "code",
      labelProperty: "name"
      // required: true,
    },
    {
      name: "companyRegistrationNumber",
      label: intl.get("field.companyRegistrationNo"),
      type: FieldType.TEXT
    }
  ]

  const webAccessFields = [
    {
      name: "userEmailAddress",
      label: intl.get("field.userEmailAddress"),
      type: FieldType.EMAIL,
      required: true,
      readOnly: true
    },
    {
      name: "username",
      label: intl.get("field.username"),
      type: FieldType.TEXT
    },
    {
      name: "userTypeName",
      label: intl.get("field.userType"),
      type: FieldType.TEXT,
      readOnly: true
    },

    {
      name: "packageTypeName",
      label: intl.get("field.packageType"),
      type: FieldType.TEXT,
      readOnly: true
    },
    {
      name: "userDiskUsagesInMB",
      label: "User's Disk Usages",
      type: FieldType.NUMBER,
      suffix: "MB",
      readOnly: true
    },
    {
      name: "entityDiskUsagesInMB",
      label: "Adviser's Disk Usages",
      type: FieldType.NUMBER,
      suffix: "MB",
      readOnly: true
    }
  ]

  const globalAccess = [
    {
      name: "grantKoizaiGlobal",
      label: "Grant Koizai Access",
      type: FieldType.CHECKBOX
    }
  ]

  //business Contact
  const contactFields = [
    {
      name: "businessContact.mobileNo",
      label: intl.get("field.mobileNo"),
      type: FieldType.PHONE_NUMBER_V2
    },
    {
      name: "businessContact.officeNo",
      label: intl.get("field.officeNo"),
      type: FieldType.PHONE_NUMBER_V2
    },
    {
      name: "businessContact.faxNo",
      label: intl.get("field.faxNo"),
      type: FieldType.PHONE_NUMBER_V2
    },
    {
      name: "businessContact.email",
      label: intl.get("field.emailAddress"),
      type: FieldType.EMAIL
    },
    {
      name: "businessContact.website",
      label: intl.get("field.website"),
      type: FieldType.TEXT
    }
  ]

  // personal Contact
  const personalContactFields = [
    {
      name: "personalContact.mobileNo",
      label: intl.get("field.mobileNo"),
      type: FieldType.PHONE_NUMBER_V2
    },
    {
      name: "personalContact.officeNo",
      label: intl.get("field.homeNo"),
      type: FieldType.PHONE_NUMBER_V2
    },
    {
      name: "personalContact.email",
      label: intl.get("field.emailAddress"),
      type: FieldType.EMAIL
    },
    {
      name: "personalContact.emergencyContactName",
      label: intl.get("field.emergencyContactPersonName"),
      type: FieldType.TEXT
    },
    {
      name: "personalContact.emergencyContactNo",
      label: intl.get("field.emergencyContactPersonNo"),
      type: FieldType.PHONE_NUMBER_V2
    }
  ]

  //business
  const addressField = [
    {
      name: "businessContact.addressLine1",
      label: intl.get("field.address1"),
      type: FieldType.TEXT
    },
    {
      name: "businessContact.addressLine2",
      label: intl.get("field.address2"),
      type: FieldType.TEXT
    },
    {
      name: "businessContact.addressLine3",
      label: intl.get("field.address3"),
      type: FieldType.TEXT
    },
    {
      name: "businessContact.suburb",
      label: intl.get("field.suburb"),
      type: FieldType.TEXT
    },
    {
      name: "businessContact.state",
      label: intl.get("field.state"),
      type: FieldType.TEXT
    },
    {
      name: "businessContact.postalCode",
      label: intl.get("field.postalCode"),
      type: FieldType.TEXT
    },
    {
      name: "businessContact.country",
      label: intl.get("field.country"),
      type: FieldType.SELECTION,
      selectionData: translateListLabel(CountryList),
      valueProperty: "code",
      labelProperty: "name"
    }
  ]

  //personal
  const personalAddressField = [
    {
      name: "personalContact.addressLine1",
      label: intl.get("field.address1"),
      type: FieldType.TEXT
    },
    {
      name: "personalContact.addressLine2",
      label: intl.get("field.address2"),
      type: FieldType.TEXT
    },
    {
      name: "personalContact.addressLine3",
      label: intl.get("field.address3"),
      type: FieldType.TEXT
    },
    {
      name: "personalContact.suburb",
      label: intl.get("field.suburb"),
      type: FieldType.TEXT
    },
    {
      name: "personalContact.state",
      label: intl.get("field.state"),
      type: FieldType.TEXT
    },
    {
      name: "personalContact.postalCode",
      label: intl.get("field.postalCode"),
      type: FieldType.TEXT
    },
    {
      name: "personalContact.country",
      label: intl.get("field.country"),
      type: FieldType.SELECTION,
      selectionData: translateListLabel(CountryList),
      valueProperty: "code",
      labelProperty: "name"
    }
  ]

  const companyField = [
    {
      name: "companyName",
      label: intl.get("field.currentCompany"),
      type: FieldType.TEXT
      // required: true,
    }
  ]

  const colorField = [
    {
      name: "titleBarColour",
      label: intl.get("field.changeThemeColor"),
      type: FieldType.COLOR
    }
  ]

  const fontColorField = [
    {
      name: "fontColour",
      label: intl.get("field.changeThemeFontColor"),
      type: FieldType.FONT_COLOR
    }
  ]

  return (
    <Form
      onSubmit={handleSave}
      initialValues={model} //show data
      mutators={{
        addregulatoryLicence: (args, state, utils) => {
          const { regulatoryLicences } = state.formState.values
          const updatedLicences = regulatoryLicences || [] // Assign an empty array if regulatoryLicences is null

          utils.changeValue(state, "regulatoryLicences", () => [
            ...updatedLicences,
            {}
          ])
        },
        removeregulatoryLicence: (args, state, utils) => {
          const [removedIndex] = args
          const { regulatoryLicences = [] } = state.formState.values
          utils.changeValue(state, "regulatoryLicences", () =>
            regulatoryLicences.filter((item, index) => removedIndex !== index)
          )
        }
      }}
      render={({ handleSubmit, reset, mutators }) => (
        <form
          id="userProfileFrom"
          onSubmit={(event) => {
            const promise = handleSubmit(event)

            //unable to click [Save] if error
            if (promise) {
              promise
                .then((response) => {
                  reset()
                })
                .catch((err) => {
                  console.log(err)
                })
            }
          }}
        >
          <div className="portal-form-popup">
            <div className="portal-form user-profile">
              <Tabs>
                <TabList>
                  <Tab>
                    <h5 className="tab-header">
                      {intl.get("entity.field.businessInformation")}
                    </h5>
                  </Tab>

                  <Tab>
                    <h5 className="tab-header">
                      {intl.get("field.regulatoryLicence")}
                    </h5>
                  </Tab>
                  <Tab>
                    <h5 className="tab-header">
                      {intl.get("entity.field.koizaiSystem")}
                    </h5>
                  </Tab>
                  <Tab>
                    <h5 className="tab-header">Adviser Photo</h5>
                  </Tab>
                  <Tab>
                    <h5 className="tab-header">
                      {intl.get("tabs.businessDetails")}
                    </h5>
                  </Tab>
                  <Tab>
                    <h5 className="tab-header">
                      {intl.get("tabs.personalDetails")}
                    </h5>
                  </Tab>
                </TabList>
                <TabPanel>
                  <div className="user-profile-formgroup">
                    <RenderSimpleFieldsBootrap
                      fields={operationInformationFields}
                    />
                  </div>
                </TabPanel>
                <TabPanel>
                  <RegulatoryLicence mutators={mutators} />
                </TabPanel>
                <TabPanel>
                  <div className="kozai-system-form">
                    <RenderSimpleFieldsBootrap fields={webAccessFields} />
                    <RenderSimpleFieldsBootrap fields={globalAccess} />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="row photo-row" style={{ paddingTop: "10px" }}>
                    <div className="col">
                      <label htmlFor="photoFile">
                        Do you want to update your Adviser Photo?
                      </label>
                    </div>
                    <div className="col">
                      <span>
                        <span
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            marginBottom: "10px"
                          }}
                        >
                          <Field
                            name="photo"
                            component={renderCheckboxCustom}
                            isForGrid
                            onChange={clickHandler}
                          />
                        </span>

                        <span
                          style={{
                            font: "500 0.813rem/1rem Inter",
                            letterSpacing: "-0.26px",
                            color: "#606060",
                            marginLeft: "-10px"
                          }}
                        >
                          Yes
                        </span>

                        {model.photoUrl != "/photo/default" && (
                          <span
                            style={{
                              marginLeft: "20px"
                            }}
                          >
                            <button
                              className="portal-btn btn-delete"
                              type="button"
                              onClick={removePhoto}
                            >
                              Remove
                            </button>
                          </span>
                        )}
                      </span>

                      {photoshow && (
                        <div className="row-holder ">
                          <Field
                            name="photoFile"
                            inputOnChange={uploaded}
                            component={fileFieldRenderBootstrapWithoutLabel}
                            validate={composeValidators(maxPhotoSize, image)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {/* {model.photoUrl && ( */}
                  <div className="row">
                    <div className="col" />
                    <div className="col d-flex mt-2">
                      <img
                        alt="preview"
                        className="photo-preview"
                        src={preview}
                      />
                    </div>
                  </div>
                  {/* )} */}
                </TabPanel>
                <TabPanel>
                  <div
                    id="scroll-section"
                    className="portal-form portal-form-businessDetails"
                  >
                    <RenderSimpleFieldsBootrap fields={contactFields} />
                  </div>

                  <div className="address-row">
                    <div className="subtitle2b"> Business Address Details</div>
                    <RenderSimpleFieldsBootrap fields={addressField} />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div
                    id="scroll-section"
                    className="portal-form portal-form-personalDetails"
                  >
                    <RenderSimpleFieldsBootrap fields={personalContactFields} />
                  </div>

                  <div className="address-row">
                    <div className="subtitle2b"> Personal Address Details</div>
                    <RenderSimpleFieldsBootrap fields={personalAddressField} />
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
          <div className="portal-btn-popup">
            {!readOnlyAccess && (
              <React.Fragment>
                <div className="text-right save-row">
                  <input
                    className="portal-btn portal-btn-submit portal-btn-submit-mr-10"
                    value={intl.get("button.save")}
                    type="submit"
                    disabled={false}
                  />
                </div>
              </React.Fragment>
            )}
          </div>
        </form>
      )}
    />
  )
}

function RegulatoryLicence({ mutators }) {
  const { addregulatoryLicence, removeregulatoryLicence } = mutators
  const [financialRegulators, setFinancialRegulators] = useState("")
  const [financialRegulatorsList, setFinancialRegulatorsList] = useState([])

  let financialRegulators_list = []

  function refreshList() {
    EntityApi.getFinancialInstitutions().then(({ data }) => {
      setFinancialRegulators(data.list)
    })
  }

  useEffect(() => {
    refreshList()
  }, [])

  useEffect(() => {
    if (financialRegulators && financialRegulators.length > 0) {
      financialRegulators.map((key, index) =>
        financialRegulators_list.push({
          index: index,
          name: key.name,
          value: key.id,
          data: key
        })
      )
    }
    setFinancialRegulatorsList(financialRegulators_list)
  }, [financialRegulators])

  return (
    <React.Fragment>
      <div className="portal-form-row">
        <label className="d-flex align-items-center">
          {intl.get("field.regulatoryLicence")}{" "}
          <span
            onClick={() =>
              addregulatoryLicence(
                "entityDetails.regulatoryLicences",
                undefined
              )
            }
            className="ml-2 btn btn-plus"
          />
        </label>
      </div>
      <FormSpy subscription={{ values: true }}>
        {({ values }) => {
          const { regulatoryLicences = [] } = values

          return (
            <React.Fragment>
              {regulatoryLicences && regulatoryLicences.length > 0 && (
                <div className="row mb-2">
                  <div className="col">
                    <div className="row-holder d-flex align-items-center">
                      <div className="_90p-width ">
                        {/* label */}
                        <div
                          // className={`${
                          //   index == 0 ? "" : "hide-half-container"
                          // }`}
                          className="row"
                        >
                          <div className="col-md-4 regulatorylicense-label">
                            Regulator
                          </div>
                          <div className="col-md-4 regulatorylicense-label">
                            License Number
                          </div>
                          <div className="col-md-4 regulatorylicense-label">
                            Expiry Date
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {regulatoryLicences &&
                regulatoryLicences.length > 0 &&
                regulatoryLicences.map((licence, index) => (
                  <div className="row" key={index}>
                    <div className="col">
                      <div className="row-holder d-flex align-items-center">
                        <div className="_90p-width d-flex align-items-center mb-2 ">
                          {/* label */}
                          <div
                            className={`${
                              index == 0 ? "" : "hide-half-container"
                            }`}
                          ></div>
                          <div className="row" style={{ width: "100%" }}>
                            <div className="col-md-4">
                              <Field
                                name={`regulatoryLicences[${index}].regulatoryLicenceId`}
                                component={renderSelectFieldCustom}
                                valueProperty="value"
                                labelProperty="name"
                                data={financialRegulatorsList}
                                isForGrid
                                required={true}
                                validate={required}
                              />
                            </div>
                            <div className="col-md-4">
                              <Field
                                name={`regulatoryLicences[${index}].number`}
                                component={renderTextBoxCustom}
                                isForGrid
                                required={true}
                                validate={required}
                              />
                            </div>
                            <div className="col-md-4">
                              <Field
                                name={`regulatoryLicences[${index}].expiryDate`}
                                component={renderDateFieldCustom}
                                isForGrid
                                required={true}
                                validate={required}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="_10p-width">
                          <span
                            onClick={() => removeregulatoryLicence(index)}
                            className="btn-remove"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </React.Fragment>
          )
        }}
      </FormSpy>
    </React.Fragment>
  )
}
function AdviserUserProfile(props) {
  const { id } = JSON.parse(localStorage.getItem("user"))
  const [preview, setPreview] = useState("")
  const [photoshow, setphotoShow] = useState(false)
  const [model, setModel] = useState({})
  const [photoRemoved, setPhotoRemoved] = useState(false)
  const [confirmationDialog, setConfirmationDialog] = useState({
    show: false,
    message: ""
  })

  const getBase64 = (file, cb) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(reader.result)
    }
    reader.onerror = function (error) {
      console.log("Error: ", error)
    }
  }
  const uploaded = (event) => {
    getBase64(event.target.files[0], (result) => {
      setPreview(result)
    })
  }
  const removePhoto = () => {
    model.photoUrl = "/photo/default"
    setPreview(`${createUrlFromPath(model.photoUrl)}?v=${Math.random()}`)
    setPhotoRemoved(true)
  }
  const clickHandler = () => {
    setphotoShow(!photoshow)
  }
  const getUserData = async (id) => {
    await UserApi.getProfile(id).then((response) => {
      setModel(response.data)
      setPreview(
        `${createUrlFromPath(response.data.photoUrl)}?v=${Math.random()}`
      )
    })
  }

  useEffect(() => {
    if (id) {
      getUserData(id)
    }
    // UserApi.getProfile(id).then((response) => {
    //   setModel(response.data)
    //   setPreview(
    //     `${createUrlFromPath(response.data.photoUrl)}?v=${Math.random()}`
    //   )
    // })
  }, [id])

  const handleConfirmEntity = () => {
    if (confirmationDialog.data) {
      confirmationDialog.data.append("confirmDisconnectEntity", true)

      UserApi.editProfile(id, confirmationDialog.data).then((response) => {
        Alert.success("user profile successfully updated")
        // props.currentUser();
      })
    }
  }

  const handleSave = (values) => {
    const formData = new FormData()

    formData.append("businessTitle", values.businessTitle || "")
    formData.append("country", values.country || "")
    formData.append("supervisor", values.supervisor || "")

    formData.append(
      "businessContact.addressLine1",
      (values.businessContact && values.businessContact.addressLine1) || ""
    )
    formData.append(
      "businessContact.addressLine2",
      (values.businessContact && values.businessContact.addressLine2) || ""
    )
    formData.append(
      "businessContact.addressLine3",
      (values.businessContact && values.businessContact.addressLine3) || ""
    )
    formData.append(
      "businessContact.postalCode",
      (values.businessContact && values.businessContact.postalCode) || ""
    )
    formData.append(
      "businessContact.state",
      (values.businessContact && values.businessContact.state) || ""
    )
    formData.append(
      "businessContact.suburb",
      (values.businessContact && values.businessContact.suburb) || ""
    )
    formData.append(
      "businessContact.country",
      (values.businessContact && values.businessContact.country) || ""
    )
    formData.append(
      "businessContact.email",
      (values.businessContact && values.businessContact.email) || ""
    )
    formData.append(
      "businessContact.faxNo",
      (values.businessContact && values.businessContact.faxNo) || ""
    )
    formData.append(
      "businessContact.mobileNo",
      (values.businessContact && values.businessContact.mobileNo) || ""
    )
    formData.append(
      "businessContact.officeNo",
      (values.businessContact && values.businessContact.officeNo) || ""
    )
    formData.append(
      "businessContact.website",
      (values.businessContact && values.businessContact.website) || ""
    )

    formData.append(
      "personalContact.addressLine1",
      (values.personalContact && values.personalContact.addressLine1) || ""
    )
    formData.append(
      "personalContact.addressLine2",
      (values.personalContact && values.personalContact.addressLine2) || ""
    )
    formData.append(
      "personalContact.addressLine3",
      (values.personalContact && values.personalContact.addressLine3) || ""
    )
    formData.append(
      "personalContact.postalCode",
      (values.personalContact && values.personalContact.postalCode) || ""
    )
    formData.append(
      "personalContact.state",
      (values.personalContact && values.personalContact.state) || ""
    )
    formData.append(
      "personalContact.suburb",
      (values.personalContact && values.personalContact.suburb) || ""
    )
    formData.append(
      "personalContact.country",
      (values.personalContact && values.personalContact.country) || ""
    )
    formData.append(
      "personalContact.email",
      (values.personalContact && values.personalContact.email) || ""
    )
    formData.append(
      "personalContact.faxNo",
      (values.personalContact && values.personalContact.faxNo) || ""
    )
    formData.append(
      "personalContact.mobileNo",
      (values.personalContact && values.personalContact.mobileNo) || ""
    )
    formData.append(
      "personalContact.officeNo",
      (values.personalContact && values.personalContact.officeNo) || ""
    )
    formData.append(
      "personalContact.website",
      (values.personalContact && values.personalContact.website) || ""
    )
    formData.append(
      "personalContact.emergencyContactName",
      (values.personalContact && values.personalContact.emergencyContactName) ||
        ""
    )
    formData.append(
      "personalContact.emergencyContactNo",
      (values.personalContact && values.personalContact.emergencyContactNo) ||
        ""
    )

    formData.append(
      "companyRegistrationNumber",
      values.companyRegistrationNumber || ""
    )
    formData.append("managerInCharge", values.managerInCharge || "")
    formData.append("email", values.email || "")
    formData.append("entityId", values.entityId || "")
    formData.append("entityName", values.entityName || "")
    formData.append("entityUniqueId", values.entityUniqueId || "")
    formData.append("faxNo", values.faxNo || "")
    formData.append("officeNo", values.officeNo || "")

    if (photoRemoved) {
      formData.append("photoUrl", "/photo/default")
    } else {
      formData.append("photoUrl", values.photoUrl)
    }

    formData.append("state", values.state || "")
    formData.append("postalCode", values.postalCode || "")
    formData.append("suburb", values.suburb || "")
    formData.append("userId", values.userId || "")
    formData.append("userEntityId", values.userEntityId || "")
    formData.append("userRoleId", values.userRoleId || "")
    formData.append("userEntityType", values.userEntityType || "")
    formData.append("userEmailAddress", values.userEmailAddress || "")
    formData.append("username", values.username || "")
    formData.append("companyName", values.companyName || "")
    formData.append("titleBarColour", values.titleBarColour || "")
    formData.append("fontColour", values.fontColour || "")
    formData.append("grantKoizaiGlobal", values.grantKoizaiGlobal || false)
    formData.append("systemRefId", values.systemRefId || "")

    if (values.photoFile) {
      formData.append("photoFile", values.photoFile)
    }

    if (Array.isArray(values.regulatoryLicences)) {
      console.log("values are", values.regulatoryLicences)
      values.regulatoryLicences.forEach((a, i) => {
        formData.append(
          `regulatoryLicences[${i}].regulatoryLicenceId`,
          a.regulatoryLicenceId || ""
        )
        formData.append(`regulatoryLicences[${i}].name`, a.name || "")
        formData.append(`regulatoryLicences[${i}].number`, a.number || "")
        formData.append(
          `regulatoryLicences[${i}].expiryDate`,
          a.expiryDate || ""
        )
      })
    }

    return UserApi.editProfile(id, formData).then(
      (response) => {
        if (response.data.errorCode === "confirm_disconnect_entity") {
          setConfirmationDialog({
            show: true,
            message: response.data.errorMessage,
            data: formData
          })
        } else {
          let loginUserEntity = localStorage.getItem("loginUserEntity")
            ? JSON.parse(localStorage.getItem("loginUserEntity"))
            : undefined
          if (loginUserEntity && response.data) {
            if (response.data.country) {
              loginUserEntity.country = response.data.country
            }

            if (response.data.photo) {
              loginUserEntity.photo = {
                id: response.data.photo.id,
                url: response.data.photo.url,
                title: response.data.photo.title,
                type: response.data.photo.type
              }
            }

            localStorage.setItem(
              "loginUserEntity",
              JSON.stringify(loginUserEntity)
            )
          }

          if (response.data.selectedUserRole) {
            let userRole = response.data.selectedUserRole
            localStorage.setItem("activeUserRole", JSON.stringify(userRole))
          }

          Alert.success("Successfully update the profile.")
          props.clearModal()
          getUserData(id)
          // reloadWindow("EDIT_PROFILE")
        }

        return Promise.resolve()
      },
      () => {
        return Promise.reject()
      }
    )
  }

  const { readOnlyAccess, entityType, clearModal } = props
  return (
    <>
      <FormContainer
        {...{
          model,
          readOnlyAccess,
          handleSave,
          entityType,
          preview,
          uploaded,
          removePhoto,
          photoshow,
          clickHandler
        }}
      />
      <ConfirmationDialog
        isOpen={confirmationDialog.show}
        message={confirmationDialog.message}
        onClose={() =>
          setConfirmationDialog({
            message: "",
            show: false
          })
        }
        onYes={handleConfirmEntity}
      />
    </>
  )
}

function mapStateToProps(state) {
  return {
    entityType: state.modalWindow.entityType,
    activeAccessLevel: state.appSettings
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, entityActions, modalWindowActions, userActions),
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AdviserUserProfile)
