/*
    Author : Marlina (marlina@devstack.com.au)
    Description : Entity creation main page (modal)
*/

import React, { useState, useEffect, Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Form, Field, FormSpy } from "react-final-form"
import intl from "react-intl-universal"
import ReactModal from "react-modal"
import { modalWindow } from "../../constants/ModalWindowLists"
import { modalWindowActions, entityActions } from "../../actions"
import { CountryList } from "../../constants/Lists"
import RenderSimpleFields, {
  FieldType
} from "../../components/Form/RenderSimpleFields"
import { renderSelectFieldCustom, renderTextBoxCustom } from "../../helpers"
import { translateListLabel } from "../../helpers/list-translation"
import { EntityApi } from "../../api"

function formValidator(values) {
  const errors = {}
  if (
    values.password !== undefined &&
    values.password !== null &&
    values.confirmPassword !== undefined &&
    values.confirmPassword !== null
  ) {
    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "Password is different"
    }
  }
  return errors
}
function RegulatoryLicence({ mutators }) {
  const { addregulatoryLicence, removeregulatoryLicence } = mutators
  const [financialRegulators, setFinancialRegulators] = useState("")
  const [financialRegulatorsList, setFinancialRegulatorsList] = useState([])

  let financialRegulators_list = []

  function refreshList() {
    EntityApi.getFinancialInstitutions().then(({ data }) => {
      setFinancialRegulators(data.list)
    })
  }

  useEffect(() => {
    refreshList()
  }, [])

  useEffect(() => {
    if (financialRegulators && financialRegulators.length > 0) {
      financialRegulators.map((key, index) =>
        financialRegulators_list.push({
          index: index,
          name: key.name,
          value: key.id,
          data: key
        })
      )
    }
    setFinancialRegulatorsList(financialRegulators_list)
  }, [financialRegulators])

  return (
    <React.Fragment>
      <div className="portal-form-row">
        <label>
          {intl.get("field.regulatoryLicence")}{" "}
          <span
            onClick={() =>
              addregulatoryLicence(
                "entityDetails.regulatoryLicences",
                undefined
              )
            }
            className="btn btn-plus"
          />
        </label>
      </div>
      <FormSpy subscription={{ values: true }}>
        {({ values }) => {
          const { entityDetails: { regulatoryLicences = [] } = {} } = values
          return (
            <React.Fragment>
              {regulatoryLicences && regulatoryLicences.length > 0 && (
                <div className="portal-form-row">
                  <label />
                  <div className="row-holder">
                    <div className="half-container">
                      <b>{intl.get("field.licenceName")} </b>
                    </div>
                    <div className="half-container">
                      <b>{intl.get("field.licenceNumber")} </b>
                    </div>
                  </div>
                </div>
              )}
              {regulatoryLicences.map((licence, index) => (
                <div className="portal-form-row" key={index}>
                  <label />
                  <div className="row-holder">
                    <div className="_90p-width">
                      <div className="half-container">
                        <Field
                          name={`entityDetails.regulatoryLicences[${index}].regulatoryLicenceId`}
                          component={renderSelectFieldCustom}
                          valueProperty="value"
                          labelProperty="name"
                          data={financialRegulatorsList}
                          isForGrid
                        />
                      </div>
                      <div className="half-container">
                        <Field
                          name={`entityDetails.regulatoryLicences[${index}].number`}
                          component={renderTextBoxCustom}
                          isForGrid
                        />
                      </div>
                    </div>
                    <div className="_10p-width">
                      <span
                        onClick={() => removeregulatoryLicence(index)}
                        className="btn-remove"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </React.Fragment>
          )
        }}
      </FormSpy>
    </React.Fragment>
  )
}
function FormContainer({ model, readOnlyAccess, handleSave, entityType }) {
  const operationInformationFields = [
    {
      name: "entityDetails.entityName",
      label: `${intl.get(`entity.field.${entityType}`)} ${intl.get(
        "field.name"
      )}`,
      type: FieldType.TEXT,
      required: true
    },
    {
      name: "entityDetails.entityUniqueId",
      label: `${intl.get(`entity.field.${entityType}`)} ${intl.get(
        "entity.field.internalIdentifier"
      )}`,
      type: FieldType.TEXT
    },
    {
      name: "entityDetails.managerInCharge",
      label: intl.get("field.managerInCharge"),
      type: FieldType.TEXT
    },
    {
      name: "entityDetails.country",
      label: intl.get("field.country"),
      type: FieldType.SELECTION,
      selectionData: translateListLabel(CountryList),
      valueProperty: "code",
      labelProperty: "name",
      required: true
    },
    {
      name: "entityDetails.companyRegistrationNumber",
      label: intl.get("field.companyRegistrationNo"),
      type: FieldType.TEXT
    }
  ]
  const webAccessFields = [
    {
      name: "username",
      label: intl.get("field.userEmailAddress"),
      type: FieldType.EMAIL,
      required: true
    },
    {
      name: "password",
      label: intl.get("field.password"),
      type: FieldType.PASSWORD,
      required: true
    },
    {
      name: "confirmPassword",
      label: intl.get("field.confirmPassword"),
      type: FieldType.PASSWORD,
      required: true
    }
  ]
  const contactFields = [
    {
      name: "entityDetails.entityContactDetails[0].officeNo",
      label: intl.get("field.officeNo"),
      type: FieldType.PHONE_NUMBER_V2
    },
    {
      name: "entityDetails.entityContactDetails[0].faxNo",
      label: intl.get("field.faxNo"),
      type: FieldType.TEXT
    },
    {
      name: "entityDetails.entityContactDetails[0].email",
      label: intl.get("field.emailAddress"),
      type: FieldType.EMAIL
    }
  ]
  const addressField = [
    {
      name: "entityDetails.addressDetails[0].addressLine1",
      label: intl.get("field.address1"),
      type: FieldType.TEXT
    },
    {
      name: "entityDetails.addressDetails[0].addressLine2",
      label: intl.get("field.address2"),
      type: FieldType.TEXT
    },
    {
      name: "entityDetails.addressDetails[0].addressLine3",
      label: intl.get("field.address3"),
      type: FieldType.TEXT
    },
    {
      name: "entityDetails.addressDetails[0].suburb",
      label: intl.get("field.suburb"),
      type: FieldType.TEXT
    },
    {
      name: "entityDetails.addressDetails[0].state",
      label: intl.get("field.state"),
      type: FieldType.TEXT
    },
    {
      name: "entityDetails.addressDetails[0].postalCode",
      label: intl.get("field.postalCode"),
      type: FieldType.TEXT
    },
    {
      name: "entityDetails.addressDetails[0].country",
      label: intl.get("field.country"),
      type: FieldType.SELECTION,
      selectionData: translateListLabel(CountryList),
      valueProperty: "code",
      labelProperty: "name"
    }
  ]

  const globalAccess = [
    {
      name: "entityDetails.grantKoizaiGlobal",
      label: "Grant Koizai Access",
      type: FieldType.CHECKBOX
    }
  ]
  return (
    <Form
      onSubmit={handleSave}
      initialValues={model}
      validate={formValidator}
      mutators={{
        addregulatoryLicence: (args, state, utils) => {
          const { entityDetails: { regulatoryLicences = [] } = {} } =
            state.formState.values
          console.log("args", regulatoryLicences, state, utils)

          utils.changeValue(state, "entityDetails.regulatoryLicences", () => [
            ...regulatoryLicences,
            {}
          ])
        },
        removeregulatoryLicence: (args, state, utils) => {
          const [removedIndex] = args
          const { entityDetails: { regulatoryLicences = [] } = {} } =
            state.formState.values

          utils.changeValue(state, "entityDetails.regulatoryLicences", () =>
            regulatoryLicences.filter((item, index) => removedIndex !== index)
          )
        }
      }}
      render={({ handleSubmit, reset, mutators }) => (
        <form id="bondForm" onSubmit={handleSubmit}>
          <div className="portal-form-popup">
            <div className="portal-form-half ">
              <div className="subtitle2b">
                {intl.get("entity.operationsInformation")}
              </div>
              <RenderSimpleFields fields={operationInformationFields} />
              <RegulatoryLicence mutators={mutators} />
              <div className="subtitle2b">{intl.get("entity.webAccess")}</div>
              <RenderSimpleFields fields={webAccessFields} />
              {entityType === "BUILT_IN_DEALERSHIP" && (
                <RenderSimpleFields fields={globalAccess} />
              )}
            </div>
            <div className="portal-form-half ">
              <div className="subtitle2b">{intl.get("entity.contact")}</div>
              <RenderSimpleFields fields={contactFields} />

              <div className="subtitle2b"> {intl.get("field.address")}</div>
              <RenderSimpleFields fields={addressField} />
            </div>
          </div>
          <div className="portal-btn-popup">
            {!readOnlyAccess && (
              <React.Fragment>
                <div className="text-right">
                  <input
                    className="portal-btn portal-btn-submit"
                    value={intl.get("button.save")}
                    type="submit"
                  />
                </div>
              </React.Fragment>
            )}
          </div>
        </form>
      )}
    />
  )
}

class Entity extends Component {
  handleSave = async (values) => {
    const user = {
      emailAddress: values.username,
      password: values.password
    }
    values.entityDetails.entityType = this.props.entityType
    await this.props.addEntityAsChild(
      this.props.entityId || this.props.activeAccessLevel.activeUserEntity.id,
      {
        entityDetails: values.entityDetails,
        user
      }
    )
    this.props.closeModalWindow(modalWindow.ENTITY_MODAL)
    this.props.refreshContainerData()
  }

  render() {
    const { model, isShowForm, readOnlyAccess, closeModalWindow, entityType } =
      this.props
    const { handleSave } = this

    return (
      <ReactModal
        isOpen={isShowForm}
        className="malabar-modal-large organization-modal"
        overlayClassName="malabar-modal-overlay"
        shouldCloseOnEsc
        onRequestClose={() => closeModalWindow(modalWindow.ENTITY_MODAL)}
      >
        <div className="popup popup-entity">
          <div className="container-fluid">
            <div className="container">
              <div className="page-title-green">
                {intl.get(`entity.title_${entityType}`)}
                <div className="close-holder">
                  <span
                    onClick={() => closeModalWindow(modalWindow.ENTITY_MODAL)}
                    className="close-button"
                  />
                </div>
              </div>
              <FormContainer
                {...{
                  model,
                  readOnlyAccess,
                  handleSave,
                  entityType
                }}
              />
            </div>
          </div>
        </div>
      </ReactModal>
    )
  }
}

function mapStateToProps(state) {
  return {
    isShowForm: state.modalWindow[modalWindow.ENTITY_MODAL],
    entityType: state.modalWindow.entityType,
    activeAccessLevel: state.appSettings
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, entityActions, modalWindowActions),
    dispatch
  )
}

Entity = connect(mapStateToProps, mapDispatchToProps)(Entity)
export default Entity
