import React, { useState, useEffect } from "react";
import Alert from "react-s-alert";
import TransferApi from "../../api/TransferApi";



function TransferMultiDialog({ parent, type, items, clearModal }) {
    const [selectAll, setSelectAll] = useState(false);
    const [forceTransfer, setForceTransfer] = useState(false);
    const [optionItems, setOptionItems] = useState(items);
    const [selectedItemIds, setSelectedItemIds] = useState([]);
    const [destList, setDestList] = useState([]);
    const [destId, setDestId] = useState("");
    const ITEM_SEPARATOR = ";";

    function convertSelectedIdsIntoStringSeparator(separator) {
        let result = "";
        if (selectedItemIds) {
            selectedItemIds.forEach(id => {
                if (result.length == 0) {
                    result = id.trim();
                } else {
                    result = result + separator + id.trim();
                }
            });
        }
        return result;
    }

    const requestTransfer = () => {
        let url = "transfer";
        let itemId = convertSelectedIdsIntoStringSeparator(ITEM_SEPARATOR);
        if (itemId.length == 0) {
            Alert.error("please select items to transfer!");
        } else {
            let sendData = {
                transferType:
                    type == "CLIENT" || type == "ADVISER" ? type : "ENTITY",
                itemId: itemId,
                sourceId: parent.id,
                destId: destId,
                forceWithoutRequest: forceTransfer
            };
            console.log("send data :", sendData);
            TransferApi.requestTransfer(sendData)
                .then(res => {
                    Alert.success("Transfer request successfully submitted!");
                    clearModal();
                })
                .catch(({ response }) => {
                    Alert.error(response.data.errorMessage);
                });
        }
    };

    function updateItem(id, isSelected){
        let updatedSelectedItemIds = selectedItemIds;
        if (isSelected) {
            updatedSelectedItemIds.push(id);
        } else {
            updatedSelectedItemIds.splice(selectedItemIds.indexOf(id), 1);
        }
        setSelectedItemIds(updatedSelectedItemIds);

        let newOptionItems = [];
        if(optionItems){
            optionItems.forEach(a => {
                if(a.id == id){
                    newOptionItems.push({
                        id: a.id,
                        name: a.name,
                        selected: isSelected
                    })
                }else{
                    newOptionItems.push(a);
                }
            });
        }
        setOptionItems(newOptionItems);
    }

    function handleCheck(e) {
        
        let selectedId = e.target.value;
        let isSelected = e.target.checked;
        updateItem(selectedId, isSelected);
       
        
    }

    
    function handleCheckAll(e) {
        let isSelected = e.target.checked;
        setSelectAll(isSelected);
        let newOptionItems = [];
        let newSelectedIds = [];
        if(optionItems){
            optionItems.forEach(a => {
                newOptionItems.push({
                    id: a.id,
                    name: a.name,
                    selected: isSelected
                })
                newSelectedIds.push(a.id);
            });
        }
        setOptionItems(newOptionItems);
        setSelectedItemIds(newSelectedIds);
        
      
    }

 
    useEffect(() => {
        if (type == "CLIENT") {
            TransferApi.getDealershipAdvisers(parent.id).then(res => {
                if (res.data) {
                    let options = [];
                    options.push(<option key="" value=""></option>);
                    res.data.list.map(d => {
                        options.push(
                            <option key={d.key} value={d.key}>
                                {d.name}
                            </option>
                        );
                    });
                    setDestList(options);
                }
            });
        } else {
            TransferApi.getDealershipEntities().then(res => {
                if (res.data) {
                    let options = [];
                    options.push(<option key="" value=""></option>);
                    res.data.list.map(d => {
                        options.push(
                            <option key={d.key} value={d.key}>
                                {d.name}
                            </option>
                        );
                    });
                    setDestList(options);
                }
            });
        }
    }, items);

    function renderCheckboxItems() {
        let arr = [];
        if (optionItems && optionItems.length > 0) {
            optionItems.forEach(item => {
                arr.push(
                    <tr key={item.id}>
                        <td>
                            <input
                                style={{height:"0.9em", width:"0.9em"}}
                                id={`chk-${item.id}`}
                                value={item.id}
                                type="checkbox"
                                onChange={e => handleCheck(e, item.id)}
                                checked={item.selected}
                            />
                            <label htmlFor={`chk-${item.id}`}> {item.name}</label>
                           
                        </td>
                    </tr>
                );
            });
        }
        return arr;
    }

    function getStyleColumns() {
        let len = optionItems?optionItems.length : 0;
        let divResult = len>10?Math.ceil(len / 10) : len>5?5:1;
        return `${divResult} auto`
      }

    return (
        <div className="notification-dialog">
            <div className="row mt-4">
                <div className="col-md-1">
                    <label>From</label>
                </div>
                <div className="col-md-11">
                    <label>{parent.name}</label>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-md-1">
                    <label>To</label>
                </div>
                <div className="col-md-11">
                    <select className="select-box" style={{width:"300px"}}
                        id="cmbDestList"
                        value={destId}
                        onChange={e => setDestId(e.target.value)}
                    >
                        {destList}
                    </select>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-md-1">
                    <label>Select items</label>
                </div>
                <div className="col-md-11" style={{textAlign:'left', columns: getStyleColumns() }}>
                    <table>
                        <tbody>
                            {optionItems && optionItems.length>1 && (
                                <tr key="chk-all">
                                    <td>
                                        <input
                                            style={{height:"0.9em", width:"0.9em"}}
                                            id="chkAll"
                                            type="checkbox"
                                            checked = {selectAll}
                                            onChange={e => handleCheckAll(e)}
                                        />
                                        <label htmlFor="chkAll" className="text-bold">&nbsp;Select All</label>
                                    
                                    </td>
                                </tr>
                            )}
                            
                            {renderCheckboxItems()}
                        </tbody>
                       
                    </table>
                    
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-md-4">
                    <input id="chkForceTransfer" type="checkbox"  style={{height:"0.9em", width:"0.9em"}} onChange={(e)=>setForceTransfer(!forceTransfer)} checked={forceTransfer == true}/>
                    &nbsp;
                    <label className="text-bold" htmlFor="chkForceTransfer">Force Transfer without approval from owner</label>
                </div>
                <div className="col d-flex justify-content-end ">
                    
                    <button
                        className="portal-btn portal-btn-submit"
                        onClick={requestTransfer}
                    >
                        {forceTransfer?"Force Transfer":"Request Transfer"}
                    </button>
                </div>
            </div>
        </div>
    );
}
export default TransferMultiDialog;
