import ScenarioApi from "../../api/ScenarioApi"
import { scenariosActions } from "../../constants/ActionConstants"
export const scenariosApiActions = {
  getScenarios,
  resetActiveScenario,
  setActiveScenario
}

export function getScenarioDataSuccess(data) {
  return { type: scenariosActions.GET_SCENARIO_SUCCESS, payload: data }
}

export function getScenarios(id) {
  return async (dispatch) => {
    ScenarioApi.getScenarios(id)
      .then((response) => {
        dispatch(getScenarioDataSuccess(response.data))
      })
      .catch((error) => console.log("error", error))
  }
}

export function setActiveScenario(item) {
  return async (dispatch) => {
    dispatch({ type: scenariosActions.SET_ACTIVE_SCENARIO, payload: item })
  }
}
export function resetActiveScenario() {
  return async (dispatch) => {
    dispatch({ type: scenariosActions.RESET_ACTIVE_SCENARIO })
  }
}
