import React, { Component, useEffect, useState } from "react"
import { Form, Field, FormSpy } from "react-final-form"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import intl from "react-intl-universal"
import {
  renderCheckboxRememberMe,
  renderSelectFieldColumn,
  renderTextBoxFieldColumn
} from "../../helpers/index.js"
import { modalWindow } from "../../constants/ModalWindowLists.js"
import { required } from "../../helpers/Validator.js"
import { userActions, modalWindowActions } from "../../actions/index.js"
import AuthApi from "../../api/AuthApi.js"

import CollapseDropDown from "./CollapseDropDown.js"
import ResetPassword from "../../pages/ResetPassword/ResetPassword.js"
import { history } from "../../helpers/index.js"

import { setCurrentLanguage } from "../../actions/AppSettingsActions.js"
import Alert from "react-s-alert"
import TermsAndConditions from "./TermsAndConditions.js"
import * as FlagIcons from "../../assets/flags/index.js"

import { CgChevronDown } from "react-icons/cg"
import {
  KoizaiLogoLG,
  KoizaiLogoSM
} from "../../assets/images/new-skin-images/index.js"
import VerificationForm from "../TwoFactor/VerificationForm.js"
const onChangeLanguage = (language) => {
  localStorage.setItem("currentLanguage", language)
  setCurrentLanguage()
  Alert.success("Successfully set your language.")
  window.location.reload()
}

function LanguageSettingForm() {
  const languages = [
    {
      name: "English",
      flagImg: FlagIcons.UkFlag,
      value: "en-US"
    },
    {
      name: "中文 (繁體)",
      flagImg: FlagIcons.ChinaFlag,
      value: "zh-Hant"
    },
    {
      name: "中文 (简体)",
      flagImg: FlagIcons.ChinaFlag,
      value: "zh-Hans"
    },
    {
      name: "Bahasa Malayu",
      flagImg: FlagIcons.MalaysiaFlag,
      value: "my-MY"
    },
    {
      name: "Vietnamese",
      flagImg: FlagIcons.VietnamFlag,
      value: "vn-VN"
    }
  ]
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0])
  const [showLanguageDropDown, setLanguageDropDown] = useState(false)
  useEffect(() => {
    if (localStorage.getItem("currentLanguage")) {
      let lang = languages.find(
        (item) => item.value === localStorage.getItem("currentLanguage")
      )
      setSelectedLanguage(lang)
    } else {
      setSelectedLanguage(languages[0])
    }
  }, [])

  return (
    <div className="languages-container">
      <div
        className="language-current_item d-flex align-items-center"
        onClick={() => {
          setLanguageDropDown(!showLanguageDropDown)
        }}
      >
        <CollapseDropDown show={showLanguageDropDown}>
          {languages.map((item, idx) => {
            return (
              <div
                onClick={() => {
                  setSelectedLanguage(item)
                  onChangeLanguage(item.value)
                }}
                className="language-item d-flex align-items-center"
                key={idx}
              >
                <img
                  src={item.flagImg}
                  alt={`${item.name} Flag`}
                  className=""
                />
                <span className="lang">{item.name}</span>
              </div>
            )
          })}
        </CollapseDropDown>
        <img
          src={selectedLanguage.flagImg}
          alt={`${selectedLanguage.name} Flag`}
        />
        <span className="lang">{selectedLanguage.name}</span>
        <CgChevronDown
          className={`${showLanguageDropDown ? "rotate-180" : ""} down-icon`}
        />
      </div>
    </div>
  )
}

export class LoginForm extends Component {
  state = {
    data: {
      loginId: "",
      password: "",
      rememberme: localStorage.getItem("loginId") ? true : false
    },
    entities: [],
    show: false,
    showtermsandConditions: false,
    termsAndConditionsChecked: false,
    response: {},
    values: {},
    userId: "",
    conditionFortermsandconditions: false,
    step: 1
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.entities !== this.state.entities ||
      prevProps.twoStep !== this.props.twoStep
    ) {
      this.setState((prevState) => ({
        ...prevState,
        step: 2
      }))
    }
  }
  handleKeyPress = (event) => {
    if (event.key === "Enter" || event.charCode === 13) {
      this.onSubmit()
    }
  }

  componentDidMount() {
    const loginId = JSON.parse(localStorage.getItem("loginId"))
    if (loginId) {
      this.setState((prevState) => ({
        ...prevState,
        data: { loginId: loginId.loginId, rememberme: true }
      }))
    }
  }

  onSubmit = async (values) => {
    try {
      if (!this.state.entitySelected) {
        await AuthApi.login(values).then((response) => {
          this.setState({ userId: response.data.id })
          this.setState({
            conditionFortermsandconditions: response.data.acceptTermAndCondition
          })
          if (!response.data.entities) {
            //for client login
            if (
              response.data.selectedEntity &&
              response.data.selectedEntity.entityType == "CLIENT"
            ) {
              localStorage.setItem("activeEntityId", null)

              this.props.setUser(response, values) //pass user authentication
            } else {
              //adviser or entity
              this.props.setUser(response, values) //pass user authentication
            }

            if (response.data.backendUrl) {
              localStorage.setItem("base-route", response.data.backendUrl)
            } else {
              const LOGIN_URL =
                process.env.REACT_APP_BASE_LOGIN_URL +
                process.env.REACT_APP_LOGIN
              localStorage.setItem("base-route", LOGIN_URL)
            }
            this.props.setUser(response, values) //pass user authentication
          } else {
            const entities = Object.entries(response.data.entities).map(
              ([key, value]) => ({
                key,
                value
              })
            )
            this.setState({ entities, entitySelected: true })
          }
        })
      } else {
        if (!this.state.conditionFortermsandconditions) {
          AuthApi.termsandconditions(this.state.userId).then((response) => {})

          this.setState({ showtermsandConditions: true })
          this.setState({ values: values })
        } else {
          let timezoneOffset = new Date().getTimezoneOffset()
          await AuthApi.selectEntity({
            loginId: values.loginId,
            selectedEntityId: values.selectedEntityId,
            timeDifference: timezoneOffset
          }).then((response) => {
            localStorage.setItem("base-route", response.data.backendUrl)
            this.props.setUser(response, values)
          })
        }
      }
      if (values.rememberme) {
        let saveCred = {
          loginId: values.loginId
        }
        localStorage.setItem("loginId", JSON.stringify(saveCred))
      } else {
        localStorage.removeItem("loginId")
      }
    } catch (error) {
      console.log("error", error)
    }
  }
  checkTermsAndConditions = async () => {
    await AuthApi.selectEntity({
      loginId: this.state.values.loginId,
      selectedEntityId: this.state.values.selectedEntityId
    }).then((response) => {
      localStorage.setItem("base-route", response.data.backendUrl)
      this.props.setUser(response, this.state.values)

      // this.props.setUser(response, values);
    })
  }
  togglePassword = () => {
    this.setState({ show: !this.state.show })
    let field = document.getElementById("passwordField")
    if (field.type === "password") {
      field.type = "text"
    } else {
      field.type = "password"
    }
  }
  render() {
    const { onSubmit, handleChange, handleKeyPress } = this
    return (
      <React.Fragment>
        <Form
          onSubmit={onSubmit}
          initialValues={this.state.data}
          subscription={{ submitting: true, pristine: true }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="loginForm">
              {/* <FormSpy onChange={handleChange} subscription={{ values: true }}/> */}

              <FormSpy subscription={{ values: true }}>
                {({ values }) => (
                  <>
                    <div className="language-selector pt-3">
                      <LanguageSettingForm />
                    </div>

                    <div className="login-title">
                      {intl.get("personaldetail.login")}
                    </div>
                    <div className="steps d-flex align-items-center justify-content-center">
                      <div
                        className={`inner-step step-1 ${
                          this.state.step == 1 || this.state.step == 2
                            ? "active"
                            : ""
                        }  ${this.state.step == 2 ? "active-bg" : ""}`}
                      >
                        <div
                          className={`content ${
                            this.state.step == 1 || this.state.step == 2
                              ? "active"
                              : ""
                          } ${this.state.step == 2 ? "active-text" : ""}`}
                        >
                          1
                        </div>
                        <div
                          className={`step-type ${
                            this.state.step == 1 || this.state.step == 2
                              ? "active"
                              : ""
                          }`}
                        >
                          Credentials
                        </div>
                      </div>

                      <div
                        className={`st-line ${
                          this.state.step === 2 ? "active" : ""
                        }`}
                      ></div>
                      <div
                        className={`inner-step step-1 ${
                          this.state.step == 2 ? "active" : ""
                        }`}
                      >
                        <div
                          className={`content ${
                            this.state.step == 2 ? "active" : ""
                          }`}
                        >
                          2
                        </div>
                        <div
                          className={`step-type ${
                            this.state.step == 2 ? "active" : ""
                          }`}
                        >
                          Entity
                        </div>
                      </div>
                    </div>
                    <div className="form-fields">
                      {this.state.step == 1 ? (
                        <>
                          <Field
                            name="loginId"
                            type="text"
                            label={intl.get("login.id")}
                            component={renderTextBoxFieldColumn}
                            disabled={this.state.entities.length > 1}
                            required
                            validate={required}
                            onKeyPress={handleKeyPress}
                          />
                          <Field
                            name="password"
                            type="password"
                            id="passwordField"
                            label={intl.get("login.password")}
                            component={renderTextBoxFieldColumn}
                            required
                            disabled={this.state.entities.length > 1}
                            validate={required}
                            onKeyPress={handleKeyPress}
                            togglePassword={this.togglePassword}
                            show={this.state.show}
                          />
                        </>
                      ) : !this.props.twoStep ? (
                        <div className="entities">
                          {this.state.entities.length > 1 && (
                            <Field
                              name="selectedEntityId"
                              component={renderSelectFieldColumn}
                              label="Entities"
                              data={this.state.entities}
                              valueProperty={"key"}
                              labelProperty={"value"}
                              required
                              validate={required}
                            />
                          )}
                        </div>
                      ) : (
                        <VerificationForm />
                      )}
                      {!this.props.twoStep ? (
                        <button
                          disabled={this.props.loading}
                          className="btn-login"
                        >
                          {intl.get("login.continue")}
                        </button>
                      ) : (
                        <></>
                      )}

                      {this.state.step == 1 ? (
                        <div className="forgot-password d-flex align-items-center justify-content-between">
                          <Field
                            name="rememberme"
                            label={intl.get("login.rememberme")}
                            component={renderCheckboxRememberMe}
                          />
                          <span
                            onClick={() => {
                              this.props.showModalWindow(
                                modalWindow.RESET_PASSWORD_MODAL
                              )
                            }}
                            className="text-content"
                          >
                            {intl.get("login.forgotpassword")}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="logos d-flex align-items-center justify-content-center">
                      <img className="" src={KoizaiLogoLG} />
                      <img className="" src={KoizaiLogoSM} />
                    </div>
                  </>
                )}
              </FormSpy>
            </form>
          )}
        />
        <TermsAndConditions
          isOpen={this.state.showtermsandConditions}
          closeModalWindow={() =>
            this.setState({ showtermsandConditions: false })
          }
          termsAndConditionsChecked={this.checkTermsAndConditions}
        />
        <ResetPassword />
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication
  return {
    loggingIn
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, userActions, modalWindowActions),
    dispatch
  )
}

LoginForm = connect(mapStateToProps, mapDispatchToProps)(LoginForm)

export default LoginForm
