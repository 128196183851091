import React, { } from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { enumOptionsActions } from "../../../actions";
import { FormButtons, RenderSimpleFields } from "../../../components";
import { FieldType } from "../../../components/Form/RenderSimpleFields";
import arrayMutators from 'final-form-arrays';
import { translateListLabel } from "../../../helpers/list-translation";
import { CountryList } from "../../../constants/Lists";
import CPFClassification from "./CPFClassification";

const CPFExchangeTrade = ({ optionsData, model = {}, handleSave }) => {
    if (!model.tradingCurrency) {
        Object.assign(model, { tradingCurrency: 'SGD' })
    }

    return (
        <div>
            <Form
                onSubmit={handleSave}
                initialValues={model}
                mutators={{
                    // potentially other mutators could be merged here
                    ...arrayMutators,
                }}
                initialValuesEqual={() => true}
                render={({
                    handleSubmit,
                    reset,
                    values
                }) => (
                        <form
                            id="cpfCashAccount"
                            onSubmit={handleSubmit}
                            noValidate
                        >
                            <div className="portal-form-popup">
                                <CPFClassification value={model.classification} disabled />
                                <div className="subtitle2">
                                    Exchange Trade Fund Details
                                </div>
                                <RenderSimpleFields
                                    fields={[
                                        {
                                            name: 'etfType',
                                            label: 'Type of ETFs',
                                            type: FieldType.ENUM_SELECTION,
                                            enumData: optionsData.etfTypeOptions,
                                        },
                                        {
                                            name: 'etfName',
                                            label: 'ETF Name',
                                            type: FieldType.TEXT,
                                        },
                                        {
                                            name: 'hin_srn_no',
                                            label: 'HIN/SRN #',
                                            type: FieldType.TEXT,
                                        },
                                        {
                                            name: 'tradingExchangeCountry',
                                            label: 'Trading Exchange Country',
                                            type: FieldType.SELECTION,
                                            selectionData: translateListLabel(CountryList),
                                            valueProperty: 'code',
                                            labelProperty: 'name',
                                        },
                                        {
                                            name: 'tickerCode',
                                            label: 'Ticker Code',
                                            type: FieldType.TEXT,
                                        },
                                        {
                                            name: 'exchangeIsoCode',
                                            label: 'Exchange ISO Code',
                                            type: FieldType.TEXT,
                                        },
                                        {
                                            name: 'yearPurchased',
                                            label: 'Year Purchased',
                                            type: FieldType.NUMBER,
                                        },
                                        {
                                            name: 'sharesHeld',
                                            label: 'Number of Shares Held',
                                            type: FieldType.NUMBER,
                                        },
                                        {
                                            name: 'tradingCurrency',
                                            label: 'Trading Currency',
                                            type: FieldType.ENUM_SELECTION,
                                            enumData: optionsData.currencyOptions,
                                        },
                                        {
                                            name: 'costBase',
                                            label: 'Cost Base',
                                            type: FieldType.MONEY,
                                            currency: values.tradingCurrency,
                                            isMoney: true
                                        },
                                        {
                                            name: 'currentValue',
                                            label: 'Current Value',
                                            type: FieldType.MONEY,
                                        },
                                        {
                                            name: 'valuationDate',
                                            label: 'Valuation Date',
                                            type: FieldType.DATE,
                                        },
                                        {
                                            name: 'brokerageFee',
                                            label: 'Brokerage Fee',
                                            type: FieldType.MONEY_WITH_PERCENTAGE,
                                            getPercentageValueSource: () => values.currentValue,
                                            currency: values.tradingCurrency,
                                            allowNegative: false,
                                        },
                                        {
                                            name: 'annualGrowthRate',
                                            label: 'Annual Growth Rate',
                                            type: FieldType.PERCENTAGE,
                                        },
                                        {
                                            name: 'annualDividendYieldPercent',
                                            label: 'Annual Dividen Yield',
                                            type: FieldType.PERCENTAGE,
                                        },

                                        {
                                            name: 'dividendFrequency',
                                            label: 'Dividend Frequency',
                                            type: FieldType.ENUM_SELECTION,
                                            enumData: optionsData.frequencyOptions,
                                        },
                                        {
                                            name: 'lastDividendPaymentDate',
                                            label: 'Last Divident Payment Date',
                                            type: FieldType.DATE,
                                        },
                                        {
                                            name: 'annualTotalReturn',
                                            label: 'Annual Total Return',
                                            type: FieldType.PERCENTAGE,
                                        },

                                    ]}
                                />

                            </div>
                            <FormButtons
                                reset={reset}
                            />
                        </form>
                    )}
            />
        </div>


    )
}


function mapStateToProps(state) {
    const { optionsData } = state.enumOptions;

    return {
        optionsData,
    };
}

function mapDispatchToProps(dispatch) {

    return bindActionCreators(enumOptionsActions, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CPFExchangeTrade);