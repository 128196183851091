/*
    Author : Erik Farhan Malik (erikfarhanmalik@gmail.com)
    Description : Total Net Worth Summary component
*/

import React from "react"
import intl from "react-intl-universal"
import ReadOnlyCurrency from "../../components/ReadOnlyCurrency"
export const classificationEnums = {
  client: "Male",
  joint: "Joint",
  partner: "Female"
}
const TotalNetWorthSummary = ({
  data = {},
  listingFilter,
  currency = "MYR"
}) => {
  const filteredData = filter({ ...data }, listingFilter)
  //   remove the property combined
  delete filteredData.combined
  const grossTotal = Object.keys(filteredData)
    .map((key) => filteredData[key])
    .reduce(
      (sum, current) => ({
        assets: sum.assets + current.assets,
        liabilities: sum.liabilities + current.liabilities
      }),
      {
        assets: 0,
        liabilities: 0
      }
    )
  return (
    <div className="portal-part">
      <div className="subtitle2">{intl.get("netWorth.netWorth")}</div>
      {Object.keys(filteredData).map((key, index) => {
        const item = filteredData[key]

        return (
          <div className="portal-form-row" key={index}>
            {key === "joint" ? (
              <label style={{ padding: 0 }}>
                <h3>{intl.get("netWorth.totalNetWorth")}</h3>
              </label>
            ) : (
              <label />
            )}{" "}
            <div
              className="row-holder text-right"
              style={{ textTransform: "capitalize" }}
            >
              {/* {classificationEnums[key]} */}
              {key}
            </div>
            <div className="row-holder">
              <ReadOnlyCurrency
                value={item.assets}
                type="2"
                currency={currency}
              />
            </div>
            <div className="row-holder">
              <ReadOnlyCurrency
                value={item.liabilities * -1}
                type="2"
                additionalClass="c-red"
                currency={currency}
              />
            </div>
            <div className="row-holder">
              <ReadOnlyCurrency
                value={item.assets - item.liabilities}
                type="2"
                currency={currency}
              />
            </div>
          </div>
        )
      })}
      <div className="portal-form-row">
        <label />
        <div className="row-holder text-grey text-black text-right">
          {intl.get("netWorth.grossTotal")}
        </div>
        <div className="row-holder">
          <ReadOnlyCurrency
            value={grossTotal.assets}
            type="2"
            currency={currency}
          />
        </div>
        <div className="row-holder">
          <ReadOnlyCurrency
            value={grossTotal.liabilities * -1}
            type="2"
            additionalClass="c-red"
            currency={currency}
          />
        </div>
        <div className="row-holder">
          <ReadOnlyCurrency
            value={grossTotal.assets - grossTotal.liabilities}
            type="2"
            currency={currency}
          />
        </div>
      </div>
    </div>
  )
}

export default TotalNetWorthSummary

function filter(data, listingFilter) {
  if (listingFilter && listingFilter !== "All") {
    Object.keys(data).forEach((key) => {
      if (data[key].classification !== listingFilter) {
        delete data[key]
      }
    })
  }
  return data
}
