import { insuranceRequestAction } from "../../constants/ActionConstants"
export function insuranceLifestylebuilder(state = {}, action) {
  switch (action.type) {
    case insuranceRequestAction.REQUEST:
      return {
        ...state,
        loading: true
      }
    case insuranceRequestAction.SUCCESS:
      return {
        ...state,
        loading: false,
        insuranceData: action.payload
      }
    case insuranceRequestAction.FAILURE:
      return {
        loading: false,
        error: action.error
      }
    case insuranceRequestAction.SAVETOREDUX:
      return {
        ...state,
        loading: false,
        formData: action.payload
      }
    case "CLEAR_FORM_DATA":
      return {
        ...state,
        loading: false,
        formData: null
      }
    default:
      return state
  }
}
