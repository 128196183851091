import { isNullOrUndefined } from 'util';
import { getDirectVariableValue } from './GetDirectValue';
import { countFromArray } from './CountFromArray';
import { totalSum } from './TotalSum';

export const shoppingExpenses = (values, currency) => {
    if (!isNullOrUndefined(values)) {
        const {
            books,
            clothing,
            electronicAndSoftware,
            hobbies,
            sportingGoods,
            others,
        } = values;

        const model = {
            books: getDirectVariableValue(books, currency),
            clothing: getDirectVariableValue(clothing, currency),
            electronicAndSoftware: getDirectVariableValue(
                electronicAndSoftware,
                currency
            ),
            hobbies: getDirectVariableValue(hobbies, currency),
            sportingGoods: getDirectVariableValue(sportingGoods, currency),
            others: countFromArray(others, currency),
        };

        return {
            clientTotal: totalSum(model, 'clientTotal'),
            partnerTotal: totalSum(model, 'partnerTotal'),
            jointTotal: totalSum(model, 'jointTotal'),
        };
    }
    return {
        clientTotal: 0,
        partnerTotal: 0,
        jointTotal: 0,
    };
};
