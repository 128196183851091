
import { implementationActions } from "../constants/ActionConstants"
import Alert from "react-s-alert"
import intl from "react-intl-universal"

export const ImplementationActions = {
  sendPasswordSuccess,
  getApplicationById,
  getApplicationSuccess,
  setKycDataEmpty
}

export function sendPasswordSuccess(response) {
  return { type: implementationActions.SENDPASSWORD_SUCCESS, response }
}
export function setKycDataEmpty() {
  return { type: implementationActions.SET_KYC_DATA_EMPTY }
}
export function createLogin(id) {
  return 0
}
export function validateKycSuccess(response, hasPartner) {
  return {
    type: implementationActions.VALIDATE_KYC_SUCCESS,
    response,
    hasPartner
  }
}
export function getApplicationById(response) {
  return { type: implementationActions.GET_APPLICATION_SUCCESS, response }
}

export function getApplicationSuccess(response) {
  return { type: implementationActions.GET_APPLICATION_SUCCESS, response }
}




