/*
    Author : Marlina (marlina@devstack.com.au)
    Library : FeedbackApi
    Description : methods related with feedback part
*/

import api from '../lib/api';

// change base route for different entity/domain here
const BASE_ROUTE = '/feedback';

class FeedbackApi {
    static add(data) {
        return api.post(`${BASE_ROUTE}`, data);
    }
    static changeStatus(data) {
        return api.post(`${BASE_ROUTE}/change-status`, data);
    }
    static update(id, data) {
        return api.put(`${BASE_ROUTE}/${id}`, data);
    }

    static deleteById(id) {
        return api.delete(`${BASE_ROUTE}/${id}`);
    }

    static getAll() {
        return api.get(`${BASE_ROUTE}`);
    }

    static getByTicketNo(ticketNo) {
        if(ticketNo){
            return api.get(`${BASE_ROUTE}?ticketNo=${ticketNo}`);
        }
        return;
        
        
    }

    static getStatusList() {
        return api.get(`${BASE_ROUTE}/statuses`);
        
    }




    static loggedFeedbacks(keyword) {
        if(keyword){
            return api.get(`${BASE_ROUTE}/logged-feedbacks?keyword=${keyword}`);
        }else{
            return api.get(`${BASE_ROUTE}/logged-feedbacks`);
        }
        
    }

    static loggedFeedbacksPerStatus(status, keyword) {
        if(keyword){
            return api.get(`${BASE_ROUTE}/logged-feedbacks/${status}?keyword=${keyword}`);
        }else{
            return api.get(`${BASE_ROUTE}/logged-feedbacks/${status}`);
        }
        
    }
  
}

export default FeedbackApi;
