import React from "react"
import { Field, Form, FormSpy } from "react-final-form"
import intl from "react-intl-universal"
import RenderSimpleFields, {
  FieldType
} from "../../../components/Form/RenderSimpleFields"
import { FormButtons } from "../../../components"
import ClassificationSelection from "../../../components/_hook/ClassificationSelection"
import { fileFieldRender, getSymbolFromCode } from "../../../helpers"

const OtherGeneralInsuranceForm = ({
  onSubmit,
  model,
  optionsData,
  user,
  currency
}) => (
  <Form
    onSubmit={onSubmit}
    initialValues={model}
    mutators={{
      setFormAttribute: ([fieldName, fieldVal], state, { changeValue }) => {
        changeValue(state, fieldName, () => fieldVal)
      }
    }}
    render={({
      handleSubmit,
      reset,
      mutators: { push, remove, setFormAttribute },
      values
    }) => (
      <form id="collectiblesForm" onSubmit={handleSubmit}>
        <div className="portal-form-popup">
          {user.isJoint && (
            <ClassificationSelection
              client={user.client}
              partner={user.partner}
              isJoint={user.isJoint}
              hideJoint={!user.isJoint}
            />
          )}
          <div className="subtitle2">{intl.get("insurance.title.general")}</div>

          <RenderSimpleFields
            fields={[
              {
                name: "type",
                label: intl.get("field.generalInsurance.type"),
                type: FieldType.ENUM_SELECTION,
                enumData: optionsData.generalInsuranceTypeOptions,
                required: true
              }
            ]}
          />

          <FormSpy subscription={{ values: true }}>
            {({ values }) => {
              if (values.type === "OTHER") {
                return (
                  <RenderSimpleFields
                    fields={[
                      {
                        name: "otherType",
                        label: intl.get("field.generalInsurance.otherType"),
                        type: FieldType.TEXT,
                        required: true
                      }
                    ]}
                  />
                )
              }
              return <></>
            }}
          </FormSpy>

          <RenderSimpleFields
            fields={[
              {
                name: "policyNumber",
                label: intl.get("field.generalInsurance.policyNumber"),
                type: FieldType.TEXT,
                required: true
              },
              {
                name: "insuranceOrganization",
                label: intl.get("field.generalInsurance.insuranceOrganization"),
                type: FieldType.TEXT
              },
              {
                name: "policyName",
                label: intl.get("field.generalInsurance.policyName"),
                type: FieldType.TEXT,
                required: false
              },
              {
                name: "currency",
                label: intl.get("goal.property.currency"),
                type: FieldType.ENUM_SELECTION,
                enumData: optionsData.currencyOptions,
                required: true
              },
              {
                name: "managedBy",
                label: intl.get("field.managedBy"),
                type: FieldType.ENUM_SELECTION,
                enumData: optionsData.managedByOptions,
                required: false
              },
              {
                name: "policyStartDate",
                label: intl.get("field.generalInsurance.policyStartDate"),
                type: FieldType.DATE,
                required: true
              },

              {
                name: "insuredAmount.value",
                label: intl.get("field.generalInsurance.policyInsuredAmount"),
                type: FieldType.MONEY,
                required: true,
                defaultCurrency: currency,
                symbolPrefix: getSymbolFromCode(values.currency || currency)
              },
              {
                name: "policyPremiumFrequency",
                label: intl.get(
                  "field.generalInsurance.policyPremiumFrequency"
                ),
                type: FieldType.ENUM_SELECTION,
                enumData: optionsData.frequencyOptions,
                required: true
              },
              {
                name: "policyPremium.value",
                label: intl.get("field.generalInsurance.policyPremium"),
                type: FieldType.MONEY,
                required: true,
                defaultCurrency: currency,
                symbolPrefix: getSymbolFromCode(values.currency || currency)
              },

              {
                name: "note",
                label: intl.get("field.generalInsurance.note"),
                type: FieldType.TEXT
              }
            ]}
          />
          <Field
            component={fileFieldRender}
            customClassName="mt-1 d-block"
            labelIdle="Drag, Drop or Browse"
            label="Upload Documents"
            onupdatefiles={(fileItems) => {
              setFormAttribute("proofDocument", fileItems[0])
            }}
            fileInformation={(model && model.proofDocument) || null}
          />
          <FormButtons reset={reset} />
        </div>
      </form>
    )}
  />
)

export default OtherGeneralInsuranceForm
