
import React from 'react';
import { connect } from 'react-redux';
import Get from '../../../helpers/NullHelper';

function CPFClassification({ client, partner, onClick, value, disabled = false }) {
    const clientName = Get(
        client.name,
        'personalDetails.personalInformation.firstName'
    ) ? Get(
        client.name,
        'personalDetails.personalInformation.firstName'
    ) : client.name;

    const partnerName =
        partner ? Get(
            partner,
            'personalDetails.personalInformation.firstName'
        ) ? Get(
            partner,
            'personalDetails.personalInformation.firstName'
        ) : partner.name : undefined
    const selectedClass = 'selected';
    const CLIENT = 'CLIENT';
    const PARTNER = 'PARTNER';
    return (
        <React.Fragment>
            {partnerName && (
                <div className="content-panel-header">
                    <span
                        onClick={() => { if (!disabled) { onClick(CLIENT) } }}
                        className={`icon-holder ${value === CLIENT ? selectedClass : ''
                            }`}
                    >
                        <div className="icon ico-single" />
                        <div className="text">{clientName}</div>
                    </span>

                    {partnerName && (
                        <span
                            onClick={() => { if (!disabled) { onClick(PARTNER) } }}
                            className={`icon-holder ${value === PARTNER ? selectedClass : ''
                                }`}
                        >
                            <div className="icon ico-single" />
                            <div className="text">{partnerName}</div>
                        </span>
                    )}
                </div>
            )
            }
        </React.Fragment >
    )
}
function mapStateToProps(state) {
    const { client, partner, } = state.jointSelection.data;
    return { client, partner };
}

export default connect(mapStateToProps)(CPFClassification);
