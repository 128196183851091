import { enumOptionsAction } from '../constants/ActionConstants';

export const enumOptionsActions = {
    getAllEnumOptions,
};

// action types
export function getAllEnumOptionsRequest() {
    return { type: enumOptionsAction.GET_ALL_OPTIONS };
}

// action functions
export function getAllEnumOptions() {
    return async (dispatch, getState) => {
        // call request is here
        dispatch(getAllEnumOptionsRequest());
    };
}
