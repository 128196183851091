import React, { useContext, useEffect } from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from "react-accessible-accordion"
import intl from "react-intl-universal"
import { connect, useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import { AppContext } from "../../../../context/AppContext"
import CashFlowHeaderListing from "../../CashFlowHeaderListing"
import { InvestmentPlatformForm, UnitTrustForm } from "../../../../forms"
import CashFlowListingGroup from "../../CashFlowListingGroup"
import api from "../../../../lib/api"
import UnitTrustExpensesForm from "../../../../forms/UnitTrustExpensesForm"

import { netWorthFormActions } from "../../../../actions/KYC/NetWorth/NetWorthFormActions"
import { netWorthActions } from "../../../../actions"
import EmployeeProvidenceFund from "../../../NetWorth/Retirements/Malaysia/EmployeeProvidenceFund"
import EPFForm from "../../../NetWorth/Retirements/Malaysia/EPFForm"
import PRSForm from "../../../NetWorth/Retirements/Malaysia/PRSForm"
import SuperannuationForm from "../../../NetWorth/Retirements/Australia/SuperannuationForm"

function RegularSaving({
  varPrefix,
  push,
  clientTotalValue,
  partnerTotalValue,
  jointTotalValue,
  optionsData,
  readOnlyAccessComponent,
  currency,
  onExternalUpdated,
  isJointHide,
  values,
  clientInformation,
  
}) {
  const dispatch = useDispatch()
  const { client } = clientInformation

  useEffect(() => {
    if (client) {
      dispatch(netWorthActions.getSalariesList({ id: client.id }))
    }
  }, [client])
  const {
    modal: { setModal, clearModal }
  } = useContext(AppContext)

  const handleSave = (newValues) => {
    // save the model
    api.put(newValues.url, newValues).then((response) => {
      onExternalUpdated(true)
    })

    clearModal()
  }

  return (
    <div className="portal-part">
      <Accordion accordion={false}>
        <AccordionItem>
          <AccordionItemTitle>
            <CashFlowHeaderListing
              title={intl.get(
                "cashflowlisting.investmentOutflow.regularSaving"
              )}
              isAccordion
              clientTotalValue={clientTotalValue}
              partnerTotalValue={partnerTotalValue}
              jointTotalValue={jointTotalValue}
              currency={currency}
              isJointHide={isJointHide}
            />
          </AccordionItemTitle>
          <AccordionItemBody>
            <CashFlowListingGroup
              varPrefix={`${varPrefix}`}
              dataArray={values.detailResult}
              isRoundColorRed
              isExternalModal
              currency={currency}
              isJointHide={isJointHide}
              onEditClick={(model) => {

                const type = model.url.split("/")[3]
                dispatch(
                  netWorthFormActions.setNewForm(false, model.classification)
                )
               

                if (type === "unit_trusts") {
                  return setModal({
                    title: intl.get("unitTrusts.title"),
                    formType: "InvestmentPlatformForm-UnitTrust",
                    content: (
                      <UnitTrustForm
                        handleSave={handleSave}
                        model={model}
                        optionsData={optionsData}
                        readOnlyAccess={readOnlyAccessComponent()}
                        clientInformation={clientInformation}
                      />
                    )
                  })
                }
                if (type === "employee_providence_funds") {
                  return setModal({
                    title: intl.get("employeeProvidenceFund.title"),
                    formType: "employeeProvidenceFund",
                    content: (
                      <EPFForm
                        handleSave={handleSave}
                        client={client}
                        model={model}
                        optionsData={optionsData}
                        currency={currency}
                        
                    />
            
                    ),
                    type: "EPF"
                  })
                }
                if (type === "private_retirement_schemes") {
                  return setModal({
                    title:intl.get('privateRetirementScheme.title'),
                    formType: "privateRetirementScheme",
                    content: (
                      <PRSForm
                        handleSave={handleSave}
                        client={client}
                        model={model}
                        optionsData={optionsData}
                        currency={currency}
                        
                    />
            
                    ),
                    type: "EPF"
                  })
                }
                if (type === "superannuation_funds") {
                  return setModal({
                    title: intl.get("superannuationFund.title"),
                    formType: "superannuationFund",
                    content: (
                      <SuperannuationForm
                        handleSave={handleSave}
                        client={client}
                        model={model}
                        optionsData={optionsData}
                        currency={currency}
                        
                    />
            
                    ),
                    type: "EPF"
                  })
                }
                return setModal({
                  title: intl.get(
                    "cashflowlisting.investmentOutflow.regularSaving"
                  ),
                  formType: "InvestmentPlatformForm",
                  content: (
                    <InvestmentPlatformForm
                      handleSave={handleSave}
                      model={model}
                      readOnlyAccess={readOnlyAccessComponent()}
                      optionsData={optionsData}
                      clientInformation={clientInformation}
                    />
                  )
                })
              }}
            />
          </AccordionItemBody>
        </AccordionItem>
      </Accordion>
    </div>
  )
}

function mapStateToProps(state) {
  const {
    employeeMandatoryContribution,
    employerMandatoryContribution,
    salaries
  } = state.netWorth

  return {
    employeeMandatoryContribution,
    employerMandatoryContribution,
    salaries
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, netWorthFormActions), dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RegularSaving)
