import React, { useState, useContext } from "react"
import { FormText } from "reactstrap"
import NumberFormat from "react-number-format"
import Datetime from "react-datetime"
import moment from "moment"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"

import {
  MalabarDate,
  MalabarMonthYearAge,
  MalabarYearAge,
  MalabarEmail,
  MalabarPhoneNumber,
  MalabarPhoneNumberSimple,
  MalabarCheckbox,
  MalabarMoney,
  MalabarMoneyCashflow,
  JointSelectionMenu,
  FeedbackSelectionMenu
} from "../components"

import "react-datetime/css/react-datetime.css"
import MalabarMoneyWithPercentage from "../components/MalabarMoney/MalabarMoneyWithPercentage"
import MalabarUploadPhoto from "../components/MalabarUploadPhoto/MalabarUploadPhoto"
import { DECIMAL_SCALE } from "../constants/NumberFormatConstants"
import { isValidDate } from "./Validator"
import { ColorPicker } from "./ColorPicker"
import { FontColorPicker } from "./FontColorPicker"
import Alert from "react-s-alert"
import { RiFileUploadLine } from "react-icons/ri"

// IBH use
import intl from "react-intl-universal"
import { AppContext } from "../context/AppContext"
import PorfolioBuilderApi from "../api/PorfolioBuilderApi"
import api from "../lib/api"
import CustomFilePond from "../components/FileUploader/CustomFilePond"

const maxFileSize = process.env.REACT_APP_MAX_FILE_SIZE
const fileBaseUrl = localStorage.getItem("base-route") // base route for multiple region

export const renderTextBoxFieldBootstrap = ({
  input,
  disabled,
  label,
  type,
  meta: { touched, error }
}) => (
  <div className="row align-items-center">
    <div className="col">
      <label htmlFor={input.name}>{label}</label>
    </div>
    <div className="col">
      <div className={touched && error && "row-holder itemvalidate bad"}>
        <input
          {...input}
          disabled={disabled}
          placeholder={label}
          type={type}
          className={
            touched
              ? error
                ? "is-invalid portal-text-input text-input"
                : "portal-text-input text-input"
              : "portal-text-input text-input"
          }
        />
        {/* {touched && ((error && <FormText color="red">{error}</FormText>) || (warning && <FormText color="yellow">{warning}</FormText>))} */}
        {touched && error && <span className="alert">required</span>}
      </div>
    </div>
  </div>
)

export const renderSelectFieldCustomBootstrap = ({
  input,
  required,
  label,
  disabled,
  children,
  data,
  valueProperty = "value",
  labelProperty = "label",
  isLabel,
  isForGrid,
  isRowHolder,
  onRemoveDataClick,
  onChange = null,
  meta: { touched, error },
  hideFirstEmptyOption = false
}) => {
  const cssHolder = isForGrid ? "" : "row-holder "
  const divRowHolder = isRowHolder

  return (
    <React.Fragment>
      {isLabel ? (
        <div className="row align-items-center">
          <div className="col">
            <label>
              {label} {required && <span>*</span>}
            </label>
          </div>
          <div className="col">
            <div
              className={
                touched
                  ? error
                    ? `${cssHolder}itemvalidate bad`
                    : cssHolder
                  : cssHolder
              }
            >
              <div className="select-box">
                <select
                  {...input}
                  onChange={(val) => {
                    if (onChange !== null) {
                      onChange(val.target.value)
                    }
                    input.onChange(val.target.value)
                  }}
                  disabled={disabled}
                >
                  {!hideFirstEmptyOption && <option />}
                  {data
                    ? data.map((item, index) => (
                        <option
                          key={`${index}select`}
                          value={item[valueProperty]}
                        >
                          {item[labelProperty]}
                        </option>
                      ))
                    : children}
                </select>
              </div>
              {touched && error && <div className="alert">{error}</div>}
            </div>
          </div>
        </div>
      ) : divRowHolder ? (
        <div className={touched ? (error ? "itemvalidate bad" : "") : ""}>
          <div className="select-box">
            <select
              {...input}
              onChange={(val) => {
                if (onChange !== null) {
                  onChange(val.target.value)
                }
                input.onChange(val.target.value)
              }}
              disabled={disabled}
            >
              <option />
              {data
                ? data.map((item, index) => (
                    <option key={`${index}select`} value={item[valueProperty]}>
                      {item[labelProperty]}
                    </option>
                  ))
                : children}
            </select>
          </div>
          <div className="btn-holder">
            {" "}
            <span onClick={onRemoveDataClick} className="btn-remove" />
          </div>
          {touched && error && (
            <div className="alert otheraddress-alert">{error}</div>
          )}
        </div>
      ) : (
        <div
          className={
            touched
              ? error
                ? `${cssHolder}itemvalidate bad`
                : cssHolder
              : cssHolder
          }
        >
          <div className="select-box">
            <select
              {...input}
              onChange={(val) => {
                if (onChange !== null) {
                  onChange(val.target.value)
                }
                input.onChange(val.target.value)
              }}
              disabled={disabled}
            >
              <option />
              {data
                ? data.map((item, index) => (
                    <option key={`${index}select`} value={item[valueProperty]}>
                      {item[labelProperty]}
                    </option>
                  ))
                : children}
            </select>
          </div>
          {touched && error && <div className="alert">{error}</div>}
        </div>
      )}
    </React.Fragment>
  )
}

export const renderSelectEnumFieldCustomBootstrap = ({
  input,
  required,
  disabled,
  label,
  enumData,
  isLabel,
  isForGrid,
  isRowHolder,
  isNotUpperDiv,
  onRemoveDataClick,
  onChange,
  meta: { touched, error },
  classNames,
  defaultValue = ""
}) => {
  const cssHolder = isForGrid ? "" : "row-holder "
  const divRowHolder = isRowHolder

  if (input.value === "") {
    input.onChange(defaultValue)
  }

  if (isLabel) {
    return (
      <div className={`row ${classNames}`}>
        <div className="col">
          <label>
            {label} {required && <span style={{ color: "red" }}>*</span>}
          </label>
        </div>
        <div className="col">
          <div
            className={
              touched
                ? error
                  ? `${cssHolder}itemvalidate bad`
                  : cssHolder
                : cssHolder
            }
          >
            <div className="select-box">
              <select
                {...input}
                onChange={(val) => {
                  if (onChange !== null) {
                    if (onChange) {
                      onChange(val.target.value)
                    }
                    input.onChange(val.target.value)
                  }
                }}
                disabled={disabled}
              >
                <option />
                {enumData}
              </select>
            </div>
            {touched && error && <div className="alert">{error}</div>}
          </div>
        </div>
      </div>
    )
  }

  if (divRowHolder) {
    return (
      <div className={touched ? (error ? "itemvalidate bad" : "") : ""}>
        <div className="select-box">
          <select
            {...input}
            onChange={(val) => {
              if (onChange !== null) {
                if (onChange) {
                  onChange(val.target.value)
                }
                input.onChange(val.target.value)
              }
            }}
            disabled={disabled}
          >
            <option />
            {enumData}
          </select>
        </div>
        <div className="btn-holder">
          {" "}
          <span onClick={onRemoveDataClick} className="btn-remove" />
        </div>
        {touched && error && (
          <div className="alert otheraddress-alert">{error}</div>
        )}
      </div>
    )
  }

  if (isNotUpperDiv) {
    return (
      <div className="select-box">
        <select
          {...input}
          onChange={(val) => {
            if (onChange !== null) {
              if (onChange) {
                onChange(val.target.value)
              }
              input.onChange(val.target.value)
            }
          }}
          disabled={disabled}
        >
          <option />
          {enumData}
        </select>
      </div>
    )
  }

  return (
    <div
      className={
        touched
          ? error
            ? `${cssHolder}itemvalidate bad`
            : cssHolder
          : cssHolder
      }
    >
      <div className="select-box">
        <select
          {...input}
          onChange={(val) => {
            if (onChange !== null) {
              if (onChange) {
                onChange(val.target.value)
              }
              input.onChange(val.target.value)
            }
          }}
          disabled={disabled}
        >
          <option />
          {enumData}
        </select>
      </div>
      {touched && error && <div className="alert">{error}</div>}
    </div>
  )
}

export const renderSelectEnumListFieldCustomBootstrap = ({
  input,
  required,
  disabled,
  label,
  enumData,
  isLabel,
  isForGrid,
  isRowHolder,
  onRemoveDataClick,
  // onScoreChange,
  onChange,
  meta: { touched, error },
  classNames
}) => {
  const cssHolder = isForGrid ? "" : "row-holder "
  const divRowHolder = isRowHolder

  return (
    <React.Fragment>
      {isLabel ? (
        <div className={`row ${classNames}`}>
          <div className="col">
            <label>
              {label} {required && <span>*</span>}
            </label>
          </div>
          <div className="col">
            <div
              className={
                touched
                  ? error
                    ? `${cssHolder}itemvalidate bad`
                    : cssHolder
                  : cssHolder
              }
            >
              <div className="select-box">
                <select
                  {...input}
                  onChange={(val) => {
                    // onScoreChange(
                    //     target.selectedOptions[0].getAttribute(
                    //         'data-score'
                    //     )
                    // );
                    if (onChange !== null) {
                      input.onChange(val.target.value)
                    }
                  }}
                  disabled={disabled}
                >
                  <option />
                  {enumData}
                </select>
              </div>
              {touched && error && <div className="alert">{error}</div>}
            </div>
          </div>
        </div>
      ) : divRowHolder ? (
        <div>
          <div className={touched ? (error ? "itemvalidate bad" : "") : ""} />
          <div className="select-box">
            <select
              {...input}
              onChange={(val) => {
                // onScoreChange(
                //     target.selectedOptions[0].getAttribute(
                //         'data-score'
                //     )
                // );
                if (onChange !== null) {
                  input.onChange(val.target.value)
                }
              }}
              disabled={disabled}
            >
              <option />
              {enumData}
            </select>
          </div>
          <div className="btn-holder">
            {" "}
            <span onClick={onRemoveDataClick} className="btn-remove" />
          </div>
          {touched && error && (
            <div className="alert otheraddress-alert">{error}</div>
          )}
        </div>
      ) : (
        <div
          className={
            touched
              ? error
                ? `${cssHolder}itemvalidate bad`
                : cssHolder
              : cssHolder
          }
        >
          <div className="select-box">
            <select
              {...input}
              onChange={(val) => {
                // onScoreChange(
                //     target.selectedOptions[0].getAttribute(
                //         'data-score'
                //     )
                // );
                if (onChange !== null) {
                  input.onChange(val.target.value)
                }
              }}
              disabled={disabled}
            >
              <option />
              {enumData}
            </select>
          </div>
          {touched && error && <div className="alert">{error}</div>}
        </div>
      )}
    </React.Fragment>
  )
}

export const renderSelectFieldArrayCustomBootstrap = ({
  input,
  label,
  isLabel,
  children,
  disabled,
  data,
  valueProperty,
  labelProperty,
  isAddButton,
  onAddButtonClick,
  onRemoveButtonClick,
  meta: { touched, error }
}) => {
  const cssHolder = "row-holder"
  return (
    <React.Fragment>
      <div className="row align-items-center">
        <div className="col">{isLabel && <label>{label}</label>}</div>
        <div className="col">
          <div
            className={
              touched
                ? error
                  ? `${cssHolder} itemvalidate bad`
                  : cssHolder
                : cssHolder
            }
          >
            <div className="where-grid col">
              {" "}
              Where :
              <div className="select-box">
                <select {...input} disabled={disabled}>
                  <option />
                  {data
                    ? data.map((item, index) => (
                        <option
                          key={`${index}select`}
                          value={item[valueProperty]}
                        >
                          {item[labelProperty]}
                        </option>
                      ))
                    : children}
                </select>
              </div>
              <div className="btn-holder">
                {isAddButton ? (
                  <span onClick={onAddButtonClick} className="btn btn-plus" />
                ) : (
                  <span onClick={onRemoveButtonClick} className="btn-remove" />
                )}
              </div>
            </div>
            {touched && error && <div className="alert">{error}</div>}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export const renderCheckboxCustomBootstrap = ({
  input,
  disabled,
  label,
  onChange = null,
  meta: { touched, error, warning }
}) => (
  <div className="input-check my-2">
    <MalabarCheckbox
      {...input}
      name={input.name}
      label={label}
      checked={input.value}
      disabled={disabled}
      onChange={(isInputChecked) => {
        if (onChange !== null) {
          onChange(isInputChecked)
        }
      }}
      onToggle={(isInputChecked) => input.onChange(isInputChecked)}
    />
    {touched &&
      ((error && (
        <FormText color="red" className="alert-validate">
          {error}
        </FormText>
      )) ||
        (warning && <FormText color="yellow">{warning}</FormText>))}
  </div>
)

export const renderCheckboxOnlyCustom = ({
  input,
  disabled,
  label,
  onChange = null
}) => (
  <MalabarCheckbox
    {...input}
    name={input.name}
    label={label}
    checked={input.value}
    disabled={disabled}
    onChange={(isInputChecked) => {
      if (onChange !== null) {
        onChange(isInputChecked)
      }
    }}
    onToggle={(isInputChecked) => input.onChange(isInputChecked)}
  />
)

export const renderDateFieldCustomBootstrap = ({
  input,
  required,
  disabled,
  isLabel,
  label,
  isForGrid,
  meta: { touched, error },
  dropdowncss = ""
}) => {
  const cssHolder = isForGrid ? "" : "malabar-date "
  return (
    <React.Fragment>
      <div
        className={
          touched
            ? error
              ? `${cssHolder}itemvalidate bad`
              : cssHolder
            : cssHolder
        }
      >
        <MalabarDate
          {...input}
          isLabel={isLabel}
          name={input.name}
          label={label}
          required={required}
          onChange={(value) => input.onChange(value)}
          value={input.value}
          touched={touched}
          error={error}
          disabled={disabled}
          dropdowncss={dropdowncss}
          validate={isValidDate}
        />
      </div>
    </React.Fragment>
  )
}

export const renderPhoneNumberFieldCustomBootstrap = ({
  input,
  required,
  isLabel,
  label,
  preferred,
  disabled,
  isForGrid,
  onRemoveDataClick,
  meta: { touched, error }
}) => {
  const cssHolder = isForGrid ? "" : "portal-form-row "
  return (
    <React.Fragment>
      <div
        className={
          touched
            ? error
              ? `${cssHolder}kyc-phone itemvalidate bad`
              : `${cssHolder}kyc-phone`
            : `${cssHolder}kyc-phone`
        }
      >
        <MalabarPhoneNumber
          {...input}
          isLabel={isLabel}
          name={input.name}
          label={label}
          required={required}
          onChange={(value) => input.onChange(value)}
          value={input.value}
          // preferred={input.value.preferred}
          touched={touched}
          error={error}
          preferred={preferred}
          onRemoveDataClick={onRemoveDataClick}
          disabled={disabled}
        />
      </div>
    </React.Fragment>
  )
}

export const renderPhoneNumber = ({
  input,
  required,
  isLabel,
  label,
  preferred,
  disabled,
  isForGrid,
  onRemoveDataClick,
  isFullWidth,
  meta: { touched, error }
}) => {
  console.log("phone input", input)
  const cssHolder = isForGrid
    ? ""
    : isFullWidth
    ? "row-holder-full"
    : "row-holder"

  return (
    <div className="row align-items-center">
      <div className="col">
        <label htmlFor={input.name}>
          {label} {required && <span style={{ color: "red" }}>*</span>}
        </label>
      </div>
      <div className="col">
        <div
          className={
            touched
              ? error
                ? `${cssHolder} itemvalidate bad`
                : `${cssHolder} `
              : `${cssHolder} `
          }
        >
          <PhoneInput
            {...input}
            inputProps={{
              name: input.name,
              required: required,
              autoFocus: true
            }}
            countryCodeEditable={false}
            preferredCountries={["au", "hk", "my", "nz", "sg"]}
            disableCountryCode={disabled}
          />
          {touched && error && <span className="alert">{error}</span>}
        </div>
      </div>
    </div>
  )
}

export const renderColorCustom = ({
  input,
  required,
  disabled,
  isLabel,
  label,
  type,
  isFullWidth,
  isForGrid,
  meta: { touched, error }
  // onchange,   // <---- is it adding here for PROPS?
}) => {
  // const { onchange } = this.props;

  const cssHolder = isForGrid
    ? ""
    : isFullWidth
    ? "row-holder-full"
    : "row-holder"

  // -- start of state definition
  const [colorValue, setColorValue] = useState({})
  //pass color to parent
  const changeColor = (newColor) => {
    this.props.onChange(newColor)
  }

  return (
    <React.Fragment>
      {isLabel ? (
        <div className="portal-form-row">
          <label htmlFor={input.name}>
            {label} {required && <span>*</span>}
          </label>
          <div
            className={
              touched
                ? error
                  ? `${cssHolder} itemvalidate bad`
                  : cssHolder
                : cssHolder
            }
          >
            <ColorPicker
              {...input}
              onChange={(values) => {
                changeColor.bind(this)
                input.onChange(values) //pass to values in formData
              }}
            />
          </div>
        </div>
      ) : (
        <div
          className={
            touched
              ? error
                ? `${cssHolder} itemvalidate bad`
                : cssHolder
              : cssHolder
          }
        >
          <input
            {...input}
            disabled={disabled}
            placeholder={label}
            type={type}
            className="text-input"
          />
          {touched && error && <span className="alert">{error}</span>}
        </div>
      )}
    </React.Fragment>
  )
}

export const renderFontColorCustom = ({
  input,
  required,
  disabled,
  isLabel,
  label,
  type,
  isFullWidth,
  isForGrid,
  meta: { touched, error }
  // onchange,   // <---- is it adding here for PROPS?
}) => {
  // const { onchange } = this.props;

  const cssHolder = isForGrid
    ? ""
    : isFullWidth
    ? "row-holder-full"
    : "row-holder"

  // -- start of state definition
  const [colorValue, setColorValue] = useState({})
  //pass color to parent
  const changeColor = (newColor) => {
    this.props.onChange(newColor)
  }

  return (
    <React.Fragment>
      {isLabel ? (
        <div className="portal-form-row">
          <label htmlFor={input.name}>
            {label} {required && <span>*</span>}
          </label>
          <div
            className={
              touched
                ? error
                  ? `${cssHolder} itemvalidate bad`
                  : cssHolder
                : cssHolder
            }
          >
            <FontColorPicker
              {...input}
              onChange={(values) => {
                changeColor.bind(this)
                input.onChange(values) //pass to values in formData
              }}
            />
          </div>
        </div>
      ) : (
        <div
          className={
            touched
              ? error
                ? `${cssHolder} itemvalidate bad`
                : cssHolder
              : cssHolder
          }
        >
          <input
            {...input}
            disabled={disabled}
            placeholder={label}
            type={type}
            className="text-input"
          />
          {touched && error && <span className="alert">{error}</span>}
        </div>
      )}
    </React.Fragment>
  )
}

export const renderPhoneNumberSimpleFieldCustomBootstrap = ({
  input,
  required,
  isLabel = false,
  label,
  disabled,
  isForGrid,
  onRemoveDataClick,
  meta: { touched, error }
}) => {
  const cssHolder = isForGrid ? "" : "portal-form-row "
  return (
    <React.Fragment>
      <div
        className={
          touched
            ? error
              ? `${cssHolder}kyc-phone itemvalidate bad`
              : `${cssHolder}kyc-phone`
            : `${cssHolder}kyc-phone`
        }
      >
        <MalabarPhoneNumberSimple
          {...input}
          isLabel={isLabel}
          name={input.name}
          label={label}
          required={required}
          onChange={(value) =>
            input.onChange(
              `${value.countryCode}-${value.areaCode}-${value.number}`
            )
          }
          value={input.value}
          touched={touched}
          error={error}
          onRemoveDataClick={onRemoveDataClick}
          disabled={disabled}
        />
      </div>
    </React.Fragment>
  )
}

export const renderEmailFieldCustomBootstrap = ({
  input,
  required,
  isLabel,
  label,
  preferred,
  placeholder,
  disabled,
  isForGrid,
  onRemoveDataClick,
  meta: { touched, error }
}) => {
  const cssHolder = isForGrid ? "" : "portal-form-row "
  return (
    <React.Fragment>
      <div
        className={
          touched
            ? error
              ? `${cssHolder}kyc-email itemvalidate bad`
              : `${cssHolder}kyc-email`
            : `${cssHolder}kyc-email`
        }
      >
        <MalabarEmail
          {...input}
          isLabel={isLabel}
          name={input.name}
          label={label}
          placeholder={placeholder}
          required={required}
          onChange={(value) => input.onChange(value)}
          value={input.value}
          // preferred={input.value.preferred}
          touched={touched}
          error={error}
          preferred={preferred}
          disabled={disabled}
          onRemoveDataClick={onRemoveDataClick}
        />
      </div>
    </React.Fragment>
  )
}

export const renderTextBoxCustomBootstrap = ({
  input,
  required,
  disabled,
  isLabel,
  label,
  placeholderlabel,
  type,
  isFullWidth,
  isForGrid,
  isNotUpperDiv,
  containerCssClass = "",
  meta: { touched, error }
}) => {
  const cssHolder = isForGrid
    ? ""
    : isFullWidth
    ? "row-holder-full"
    : "row-holder"

  return (
    <React.Fragment>
      {isLabel ? (
        <div className="row align-items-center">
          <div className="col">
            <label htmlFor={input.name}>
              {label} {required && <span style={{ color: "red" }}>*</span>}
            </label>
          </div>
          <div className="col">
            <div
              className={
                touched
                  ? error
                    ? `${cssHolder} ${containerCssClass} itemvalidate bad`
                    : `${cssHolder} ${containerCssClass}`
                  : `${cssHolder} ${containerCssClass}`
              }
            >
              <input
                {...input}
                disabled={disabled}
                placeholder={placeholderlabel || label}
                type={type}
                className="text-input"
              />
              {touched && error && <span className="alert">{error}</span>}
            </div>
          </div>
        </div>
      ) : isNotUpperDiv ? (
        <input
          {...input}
          disabled={disabled}
          placeholder={label}
          type={type}
          className="text-input"
        />
      ) : (
        <div
          className={
            touched
              ? error
                ? `${cssHolder} ${containerCssClass} itemvalidate bad`
                : `${cssHolder} ${containerCssClass}`
              : `${cssHolder} ${containerCssClass}`
          }
        >
          <input
            {...input}
            disabled={disabled}
            placeholder={label}
            type={type}
            // value="sdfdsf"
            className="text-input"
          />
          {touched && error && <span className="alert">{error}</span>}
        </div>
      )}
    </React.Fragment>
  )
}

export const renderPasswordCustomBootstrap = ({
  input,
  required,
  disabled,
  isLabel,
  label,
  type,
  isFullWidth,
  isForGrid,
  meta: { touched, error }
}) => {
  const cssHolder = isForGrid
    ? ""
    : isFullWidth
    ? "row-holder-full"
    : "row-holder"
  return (
    <React.Fragment>
      {isLabel ? (
        <div className="row align-items-center">
          <div className="col">
            <label htmlFor={input.name}>
              {label} {required && <span>*</span>}
            </label>
          </div>
          <div className="col">
            <div
              className={
                touched
                  ? error
                    ? `${cssHolder} itemvalidate bad`
                    : cssHolder
                  : cssHolder
              }
            >
              <input
                {...input}
                disabled={disabled}
                placeholder={label}
                type="password"
                className="text-input password"
              />
              <span className="fa fa-lock" />
              {touched && error && <span className="alert">{error}</span>}
            </div>
          </div>
        </div>
      ) : (
        <div
          className={
            touched
              ? error
                ? `${cssHolder} itemvalidate bad`
                : cssHolder
              : cssHolder
          }
        >
          <input
            {...input}
            disabled={disabled}
            placeholder={label}
            type={type}
            className="text-input"
          />
          {touched && error && <span className="alert">{error}</span>}
        </div>
      )}
    </React.Fragment>
  )
}

export const renderTextAreaCustomBootstrap = ({
  input,
  disabled,
  isLabel,
  label,
  isFullWidth,
  isForGrid,
  onChange,
  meta: { touched, error }
}) => {
  const cssHolder = isForGrid
    ? ""
    : isFullWidth
    ? "row-holder-full text-area"
    : "row-holder text-area"
  return (
    <React.Fragment>
      {isLabel ? (
        <div className="row align-items-center">
          <div className="col">
            <label htmlFor={input.name}>{label}</label>
          </div>
          <div className="col">
            <div
              className={
                touched
                  ? error
                    ? `${cssHolder} itemvalidate bad`
                    : cssHolder
                  : cssHolder
              }
            >
              <textarea
                required=""
                style={{
                  height: "170px",
                  resize: "none"
                }}
                className="text-input text-input-greybg"
                {...input}
                placeholder=" "
                onChange={(val) => {
                  if (onChange !== null) {
                    input.onChange(val.target.value)
                  }
                }}
                disabled={disabled}
              />
              {touched && error && (
                <span className="alert alert-textarea">{error}</span>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          className={
            touched
              ? error
                ? `${cssHolder} itemvalidate bad`
                : cssHolder
              : cssHolder
          }
        >
          <textarea
            required=""
            style={{
              height: "170px",
              resize: "none"
            }}
            className="text-input text-input-greybg"
            {...input}
            placeholder=" "
            onChange={(val) => {
              if (onChange !== null) {
                input.onChange(val.target.value)
              }
            }}
            disabled={disabled}
          />
          {touched && error && (
            <span className="alert alert-textarea">{error}</span>
          )}
        </div>
      )}
    </React.Fragment>
  )
}

export const renderCurrencyCustomBootstrap = ({
  input,
  isTotal,
  readonly = false,
  required,
  disabled = false,
  isLabel,
  label,
  isRounded,
  isBlueBackground,
  placeholderLabel,
  isFullWidth,
  isForGrid,
  allowNegative = true,
  symbolPrefix = "$",
  decimalSeparator = ".",
  thousandSeparator = ",",
  onChange = null,
  containerCssClass = "",
  meta: { touched, error },
  classNames,
  isMoney = false, // if true, input.value must be in money type and symbolPrefix need to be there
  defaultCurrency // must be supplied if isMoney is true
}) => {
  const cssHolder = isForGrid
    ? ""
    : isFullWidth
    ? "row-holder-full"
    : "row-holder"
  const realValue = isMoney ? input.value.value : input.value
  const currencyValue = isMoney ? input.value.currency || defaultCurrency : ""
  return (
    <React.Fragment>
      {isLabel ? (
        <div className={`row ${classNames}`}>
          <div className="col">
            <label htmlFor={input.name}>
              {label} {required && <span>*</span>}
            </label>
          </div>
          <div className="col">
            <div
              className={
                touched
                  ? error
                    ? `${cssHolder} ${containerCssClass} itemvalidate bad`
                    : `${cssHolder} ${containerCssClass}`
                  : `${cssHolder} ${containerCssClass}`
              }
            >
              <NumberFormat
                value={realValue}
                displayType={"input"}
                allowNegative={allowNegative}
                disabled={disabled}
                decimalScale={DECIMAL_SCALE}
                // isNumericString={true}
                prefix={symbolPrefix}
                decimalSeparator={decimalSeparator}
                thousandSeparator={thousandSeparator}
                className={`text-input currency-input ${
                  isTotal ? "text-input-total" : ""
                }`}
                readOnly={readonly}
                onValueChange={(values) => {
                  const returned = isMoney
                    ? {
                        currency: currencyValue,
                        value: values.value
                      }
                    : values.value

                  input.onChange(returned)
                  if (onChange !== null) {
                    onChange(returned)
                  }
                }}
              />
              {touched && error && <span className="alert">{error}</span>}
            </div>
          </div>
        </div>
      ) : isRounded ? (
        <NumberFormat
          value={realValue}
          displayType={"input"}
          allowNegative={allowNegative}
          disabled={disabled}
          decimalScale={DECIMAL_SCALE}
          // isNumericString={true}
          prefix={`${placeholderLabel}  `}
          decimalSeparator={decimalSeparator}
          thousandSeparator={thousandSeparator}
          className={`text-input text-input-figure ${
            isBlueBackground ? "text-input-figure-blue" : ""
          }`}
          readOnly={readonly}
          onValueChange={(values) => {
            const returned = isMoney
              ? { currency: currencyValue, value: values.value }
              : values.value

            input.onChange(returned)
            if (onChange !== null) {
              onChange(returned)
            }
          }}
          placeholder={placeholderLabel}
        />
      ) : (
        <div
          className={
            touched
              ? error
                ? `${cssHolder} ${containerCssClass} itemvalidate bad`
                : `${cssHolder} ${containerCssClass}`
              : `${cssHolder} ${containerCssClass}`
          }
        >
          <NumberFormat
            value={realValue}
            displayType={"input"}
            allowNegative={allowNegative}
            disabled={disabled}
            decimalScale={DECIMAL_SCALE}
            // isNumericString={true}
            prefix={symbolPrefix}
            decimalSeparator={decimalSeparator}
            thousandSeparator={thousandSeparator}
            className={`text-input currency-input ${
              isTotal ? "text-input-total" : ""
            }`}
            readOnly={readonly}
            onValueChange={(values) => {
              const returned = isMoney
                ? {
                    currency: currencyValue,
                    value: values.value
                  }
                : values.value

              input.onChange(returned)
              if (onChange !== null) {
                onChange(returned)
              }
            }}
          />
          {touched && error && <span className="alert">{error}</span>}
        </div>
      )}
    </React.Fragment>
  )
}

export const renderCurrencyCashflowBootstrap = ({
  input,
  required,
  placeholder,
  disabled = false,
  isLabel,
  label,
  isFullWidth,
  allowNegative = true,
  symbolPrefix = "$",
  decimalSeparator = ".",
  thousandSeparator = ",",
  onChange = null,
  onRemoveDataClick,
  lockToCurrency = "",
  meta: { touched, error }
}) => (
  <React.Fragment>
    <MalabarMoneyCashflow
      {...input}
      isLabel={isLabel}
      name={input.name}
      label={label}
      placeholder={placeholder}
      required={required}
      onChange={(value) => {
        input.onChange(value)
        if (onChange !== null) {
          onChange(value)
        }
      }}
      value={input.value}
      touched={touched}
      error={error}
      allowNegative={allowNegative}
      symbolPrefix={symbolPrefix}
      decimalScale={DECIMAL_SCALE}
      decimalSeparator={decimalSeparator}
      thousandSeparator={thousandSeparator}
      onRemoveDataClick={onRemoveDataClick}
      lockToCurrency={lockToCurrency}
      isFullWidth={isFullWidth}
      disabled={disabled}
    />
  </React.Fragment>
)

export const renderNumberCustomBootstrap = ({
  input,
  required,
  disabled,
  isLabel,
  label,
  placeholderlabel,
  isFullWidth,
  isForGrid,
  isNotUpperDiv,
  containerCssClass = "",
  meta: { touched, error },
  onChange = null,
  numberFormat = null,
  allowNegative = true,
  readOnly = false,
  suffix = ""
}) => {
  const cssHolder = isForGrid
    ? ""
    : isFullWidth
    ? "row-holder-full"
    : "row-holder"
  return (
    <React.Fragment>
      {isLabel ? (
        <div className="row align-items-center">
          <div className="col">
            <label htmlFor={input.name}>
              {label} {required && <span>*</span>}
            </label>
          </div>
          <div className="col">
            <div
              className={
                touched
                  ? error
                    ? `${cssHolder} ${containerCssClass} itemvalidate bad`
                    : `${cssHolder} ${containerCssClass}`
                  : `${cssHolder} ${containerCssClass}`
              }
            >
              <NumberFormat
                value={input.value}
                displayType={"input"}
                allowNegative={allowNegative}
                disabled={disabled}
                readOnly={readOnly}
                className="text-input"
                onValueChange={(values) => {
                  input.onChange(values.value)
                  if (onChange !== null) {
                    onChange(values.value)
                  }
                }}
                format={numberFormat}
                placeholder={placeholderlabel}
                suffix={suffix}
              />
              {touched && error && <span className="alert">{error}</span>}
            </div>
          </div>
        </div>
      ) : isNotUpperDiv ? (
        <NumberFormat
          value={input.value}
          displayType={"input"}
          allowNegative={allowNegative}
          disabled={disabled}
          readOnly={readOnly}
          className="text-input"
          onValueChange={(values) => {
            input.onChange(values.value)
            if (onChange !== null) {
              onChange(values.value)
            }
          }}
          format={numberFormat}
          placeholder={placeholderlabel}
        />
      ) : (
        <div
          className={
            touched
              ? error
                ? `${cssHolder} ${containerCssClass} itemvalidate bad`
                : `${cssHolder} ${containerCssClass}`
              : `${cssHolder} ${containerCssClass}`
          }
        >
          <NumberFormat
            value={input.value}
            displayType={"input"}
            allowNegative={allowNegative}
            disabled={disabled}
            readOnly={readOnly}
            className="text-input"
            onValueChange={(values) => {
              input.onChange(values.value)
              if (onChange !== null) {
                onChange(values.value)
              }
            }}
            format={numberFormat}
            placeholder={placeholderlabel}
          />
          {touched && error && <span className="alert">{error}</span>}
        </div>
      )}
    </React.Fragment>
  )
}

export const renderPercentageCustomBootstrap = ({
  input,
  required,
  disabled = false,
  isLabel,
  label,
  isFullWidth,
  isForGrid,
  allowNegative = false,
  symbolSuffix = " %",
  decimalSeparator = ".",
  thousandSeparator = ",",
  onChange = null,
  isRounded,
  isRoundColorRed,
  meta: { touched, error },
  classNames
}) => {
  const cssHolder = isForGrid
    ? ""
    : isFullWidth
    ? "row-holder-full"
    : "row-holder"
  return (
    <React.Fragment>
      {isLabel ? (
        <div className={`row ${classNames}`}>
          <div className="col">
            <label htmlFor={input.name}>
              {label} {required && <span>*</span>}
            </label>
          </div>
          <div className="col">
            <div
              className={
                touched
                  ? error
                    ? `${cssHolder} itemvalidate bad`
                    : cssHolder
                  : cssHolder
              }
            >
              <NumberFormat
                value={input.value}
                displayType={"input"}
                thousandSeparator
                allowNegative={allowNegative}
                disabled={disabled}
                decimalScale={DECIMAL_SCALE}
                suffix={symbolSuffix}
                decimalSeparator={decimalSeparator}
                className="text-input"
                onValueChange={(values) => {
                  input.onChange(values.value)
                  if (onChange !== null) {
                    onChange(values.value)
                  }
                }}
                isAllowed={(values) => {
                  const lowerBound = allowNegative ? -100 : 0
                  const { value } = values
                  if (value === "" || value === undefined || value === null) {
                    return true
                  }
                  if (allowNegative && value === "-") {
                    values.floatValue = 0
                  }
                  return (
                    lowerBound <= values.floatValue && values.floatValue <= 100
                  )
                }}
              />
              {touched && error && <span className="alert">{error}</span>}
            </div>
          </div>
        </div>
      ) : isRounded ? (
        <NumberFormat
          value={input.value}
          displayType={"input"}
          allowNegative={allowNegative}
          disabled={disabled}
          decimalScale={DECIMAL_SCALE}
          // isNumericString={true}
          suffix={symbolSuffix}
          decimalSeparator={decimalSeparator}
          thousandSeparator={thousandSeparator}
          className={`float-left text-input text-input-figure ${
            isRoundColorRed
              ? "text-input-figure-red"
              : "text-input-figure-blue2"
          }`}
          onValueChange={(values) => {
            input.onChange(values.value)
            if (onChange !== null) {
              onChange(values.value)
            }
          }}
          isAllowed={(values) => {
            const { floatValue } = values
            if (!floatValue) {
              return true
            }
            return floatValue >= 0 && floatValue <= 100
          }}
        />
      ) : (
        <div
          className={
            touched
              ? error
                ? `${cssHolder} itemvalidate bad`
                : cssHolder
              : cssHolder
          }
        >
          <NumberFormat
            value={input.value}
            displayType={"input"}
            allowNegative={allowNegative}
            disabled={disabled}
            decimalScale={DECIMAL_SCALE}
            // isNumericString={true}
            suffix={symbolSuffix}
            decimalSeparator={decimalSeparator}
            thousandSeparator={thousandSeparator}
            className="text-input"
            onValueChange={(values) => {
              input.onChange(values.value)
              if (onChange !== null) {
                onChange(values.value)
              }
            }}
            isAllowed={(values) => {
              const { floatValue } = values
              if (!floatValue) {
                return true
              }
              return floatValue >= 0 && floatValue <= 100
            }}
          />
          {touched && error && <span className="alert">{error}</span>}
        </div>
      )}
    </React.Fragment>
  )
}

export const renderMoneyCustomBootstrap = ({
  input,
  required,
  disabled,
  isLabel,
  label,
  placeholder,
  isForGrid,
  isFullWidth = true,
  onRemoveDataClick,
  allowNegative = true,
  symbolPrefix = "$",
  decimalSeparator = ".",
  thousandSeparator = ",",
  lockToCurrency = "",
  meta: { touched, error },
  defaultCurrency,
  onChange
}) => {
  const cssHolder = isForGrid ? "" : "portal-form-row "
  return (
    <React.Fragment>
      <div className={isFullWidth ? "" : "row-holder"}>
        <div
          className={
            touched
              ? error
                ? `${cssHolder}popup-purchaseprice itemvalidate bad`
                : `${cssHolder}popup-purchaseprice`
              : `${cssHolder}popup-purchaseprice`
          }
        >
          <MalabarMoney
            {...input}
            isLabel={isLabel}
            name={input.name}
            label={label}
            placeholder={placeholder}
            required={required}
            onChange={(value) => {
              if (onChange) {
                onChange(value)
              }
              // needed by final form
              input.onChange(value)
            }}
            value={input.value}
            touched={touched}
            error={error}
            allowNegative={allowNegative}
            symbolPrefix={symbolPrefix}
            decimalScale={DECIMAL_SCALE}
            decimalSeparator={decimalSeparator}
            thousandSeparator={thousandSeparator}
            onRemoveDataClick={onRemoveDataClick}
            lockToCurrency={lockToCurrency}
            isFullWidth={isForGrid ? false : isFullWidth}
            defaultCurrency={defaultCurrency}
            disabled={disabled}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export const renderMoneyWithPercentageCustomBootstrap = ({
  input,
  required,
  disabled,
  isLabel,
  label,
  placeholder,
  isForGrid,
  isFullWidth = true,
  onRemoveDataClick,
  allowNegative = true,
  symbolPrefix = "$",
  decimalSeparator = ".",
  thousandSeparator = ",",
  lockToCurrency = "",
  meta: { touched, error },
  defaultCurrency,
  onChange,
  getPercentageValueSource,
  currency
}) => {
  const cssHolder = isForGrid ? "" : "portal-form-row "
  return (
    <React.Fragment>
      <div className={isFullWidth ? "" : "row-holder"}>
        <div
          className={
            touched
              ? error
                ? `${cssHolder}popup-purchaseprice itemvalidate bad`
                : `${cssHolder}popup-purchaseprice`
              : `${cssHolder}popup-purchaseprice`
          }
        >
          <MalabarMoneyWithPercentage
            {...input}
            isLabel={isLabel}
            name={input.name}
            label={label}
            placeholder={placeholder}
            required={required}
            onChange={(value) => {
              if (onChange) {
                onChange(value)
              }
              // needed by final form
              input.onChange(value)
            }}
            value={input.value}
            touched={touched}
            error={error}
            allowNegative={allowNegative}
            symbolPrefix={symbolPrefix}
            decimalScale={DECIMAL_SCALE}
            decimalSeparator={decimalSeparator}
            thousandSeparator={thousandSeparator}
            onRemoveDataClick={onRemoveDataClick}
            lockToCurrency={lockToCurrency}
            isFullWidth={isForGrid ? false : isFullWidth}
            defaultCurrency={defaultCurrency}
            disabled={disabled}
            getPercentageValueSource={getPercentageValueSource}
            currency={currency}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export const renderJointSelectionMenuCustomBootstrap = ({
  input,
  onChange = null,
  clientName,
  partnerName,
  hideJoint = false
}) => (
  <JointSelectionMenu
    {...input}
    name={input.name}
    value={input.value}
    clientName={clientName}
    partnerName={partnerName}
    onChange={(val) => {
      if (onChange !== null) {
        onChange(val)
      }
    }}
    onToggle={(selectedType) => input.onChange(selectedType)}
    hideJoint={hideJoint}
  />
)

export const renderFeedbackSelectionMenuCustomBootstrap = ({
  input,
  onChange = null,
  disabled,
  meta: { touched, error }
}) => (
  <div
    className={`feedback-selection ${touched && error && `itemvalidate bad`}`}
  >
    <FeedbackSelectionMenu
      {...input}
      name={input.name}
      value={input.value}
      onChange={(val) => {
        if (onChange !== null && disabled === false) {
          onChange(val)
        }
      }}
      onToggle={(selectedType) => !disabled && input.onChange(selectedType)}
    />
    {touched && error && <span className="alert">{error}</span>}
  </div>
)

export const renderDateTimeCustomBootstrap = ({
  input,
  isForGrid,
  dateFormat = null,
  timeFormat = null,
  setValidDate,
  setDefaultValue,
  meta: { touched, error }
}) => {
  const cssHolder = isForGrid ? "" : "portal-form-row "

  return (
    <React.Fragment>
      <div
        className={
          touched
            ? error
              ? `${cssHolder} itemvalidate bad`
              : `${cssHolder}`
            : `${cssHolder}`
        }
      >
        <Datetime
          inputProps={{ name: input.name, class: "text-input" }}
          value={input.value}
          className={touched ? (error ? "is-invalid" : "") : ""}
          onChange={(param) =>
            input.onChange(
              moment(param).format(
                dateFormat !== null ? dateFormat : timeFormat
              )
            )
          }
          dateFormat={dateFormat}
          timeFormat={timeFormat}
          isValidDate={setValidDate}
          defaultValue={setDefaultValue}
        />
        {touched && error && <span className="alert">{error}</span>}
      </div>
    </React.Fragment>
  )
}

export const renderYearAgeCustomBootstrap = ({
  input,
  disabled,
  isForGrid,
  birthDate,
  dateFormat = "YYYY",
  readonly = false,
  meta: { touched, error }
}) => {
  const cssHolder = isForGrid ? "" : "portal-form-row "

  return (
    <React.Fragment>
      <div
        className={
          touched
            ? error
              ? `${cssHolder} itemvalidate bad`
              : `${cssHolder}`
            : `${cssHolder}`
        }
      >
        <MalabarYearAge
          value={input.value}
          name={input.name}
          onChange={(val) => {
            input.onChange(val)
          }}
          birthDate={birthDate}
          disabled={disabled}
          dateFormat={dateFormat}
          readonly={readonly}
          touched={touched}
          error={error}
        />
      </div>
    </React.Fragment>
  )
}

export const renderMonthYearAgeCustomBootstrap = ({
  input,
  disabled,
  isForGrid,
  birthDate,
  dateFormat = "MMM YYYY",
  readonly = false,
  meta: { touched, error }
}) => {
  const cssHolder = isForGrid ? "" : "portal-form-row "

  return (
    <React.Fragment>
      <div
        className={
          touched
            ? error
              ? `${cssHolder} itemvalidate bad`
              : `${cssHolder}`
            : `${cssHolder}`
        }
      >
        <MalabarMonthYearAge
          value={input.value}
          name={input.name}
          onChange={(val) => {
            input.onChange(val)
          }}
          birthDate={birthDate}
          disabled={disabled}
          dateFormat={dateFormat}
          readonly={readonly}
          touched={touched}
          error={error}
        />
      </div>
    </React.Fragment>
  )
}

export const renderTextOnlyCustomBootstrap = ({ input }) => input.value
export const renderDateAsTextOnlyCustomBootstrap = ({ input, dateFormat }) =>
  moment(input.value).format(dateFormat)

export const importDocumentRender = ({ input, label }) => {
  const { value } = input

  const {
    modal: { showConfirmationDialog }
  } = useContext(AppContext)

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null)

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click()
  }

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0]

    //create form data
    const formData = new FormData()
    formData.append("file", fileUploaded)

    //call api
    let url = "/report-dealership/upload"
    api
      .post(url, formData)
      .then((response) => {
        if (response.status == 200) {
          Alert.success(
            response
              ? response.data.message
              : "The file has been uploaded successfully."
          )
        }
      })
      .catch(({ error }) => {
        Alert.error(error)
      })
  }

  return (
    <div className={`portal-form-row productApplication`}>
      <div className="investmentApplication-button">
        <div className="RiFileUploadLine-wrapper" onClick={handleClick}>
          <RiFileUploadLine className="RiFileUploadLine" />
        </div>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: "none" }}
        />
        <div className="RiFileUploadLine-txt-wrapper">
          <button className="portal-btn portal-btn-submit ">Import</button>
        </div>
      </div>
    </div>
  )
}

export const importDocumentRenderIcon = ({ input, label, handleChange }) => {
  const { value } = input

  const {
    modal: { showConfirmationDialog }
  } = useContext(AppContext)

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null)

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click()
  }

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  // const handleChange = event => {
  //     const fileUploaded = event.target.files[0];

  //     //create form data
  //     const formData = new FormData();
  //     formData.append("file", fileUploaded);

  //     //call api
  //     let url = '/reportDoc/upload';
  //     api.post(url, formData)
  //         .then(response => {
  //             if(response.status == 200) {
  //                 Alert.success(
  //                     response ? response.data.message : 'The file has been uploaded successfully.'
  //                 );
  //             }

  //         })
  //         .catch(({ error }) => {
  //             Alert.error(error);
  //         });

  // };

  return (
    <div className={`portal-form-row productApplication`}>
      <div className="investmentApplication-button">
        <div className="RiFileUploadLine-wrapper" onClick={handleClick}>
          <RiFileUploadLine className="RiFileUploadLine" />
        </div>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: "none" }}
        />
      </div>
    </div>
  )
}

// display IBH
export const ibhFieldRenderBootstrap = ({ input, label }) => {
  const { value } = input

  //get client id
  let clientId = input.client.id

  //get partner id
  let partnerId = input.partner ? input.partner.id : undefined

  let category = input.type

  const {
    modal: { showConfirmationDialog }
  } = useContext(AppContext)

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null)

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click()
  }

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0]

    let data = {
      category: category,
      clientId: clientId,
      name: "product application",
      multipartFile: fileUploaded,
      paper: false
    }

    //call api
    api
      .post(`/client/` + clientId + `/product-application`, data)
      .then((response) => {
        Alert.success("The file has been uploaded successfully.")
      })
  }

  return (
    <div className={`portal-form-row productApplication`}>
      <div className="investmentApplication-button">
        <span
          style={{
            borderRadius: "4px",
            padding: "5px",
            background:
              "linear-gradient(135deg, rgba(0, 110, 155, 1) 0%, rgba(0, 163, 228, 1) 40%, rgba(0, 174, 243, 1) 100%)"
          }}
          className="btn table-icon iicon"
          onClick={handleClick}
        >
          <img
            style={{
              height: "16px",
              width: "16px"
            }}
            src={`${process.env.PUBLIC_URL}/assets/icons/upload-white.svg`}
          />
        </span>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: "none" }}
        />
        <div className="RiFileUploadLine-txt-wrapper">
          <button
            onClick={() =>
              showConfirmationDialog({
                message: `Your client will receive an invitation email. Please click yes to proceed.`,
                onYes: () => {
                  PorfolioBuilderApi.invite(clientId).then(() => {
                    Alert.success(
                      "The invitation email has been sent successfully."
                    )
                  })
                }
              })
            }
            className="portal-btn portal-btn-submit "
          >
            {intl.get("ibhAccount.submitIBAccountOpen")}
          </button>
        </div>
      </div>
    </div>
  )
}

export const saxoFieldRenderBootstrap = ({ input, label }) => {
  const { value } = input

  //get client id
  let clientId = input.client.id

  //get partner id
  let partnerId = input.partner ? input.partner.id : undefined

  let category = input.type

  const {
    modal: { showConfirmationDialog }
  } = useContext(AppContext)

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null)

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click()
  }

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0]

    let data = {
      category: category,
      clientId: clientId,
      name: "product application",
      multipartFile: fileUploaded,
      paper: false
    }

    //call api
    api
      .post(`/client/` + clientId + `/product-application`, data)
      .then((response) => {
        Alert.success("The file has been uploaded successfully.")
      })
  }

  return (
    <div className={`portal-form-row productApplication`}>
      <div className="investmentApplication-button">
        <span
          style={{
            borderRadius: "4px",
            padding: "5px",
            background:
              "linear-gradient(135deg, rgba(0, 110, 155, 1) 0%, rgba(0, 163, 228, 1) 40%, rgba(0, 174, 243, 1) 100%)"
          }}
          className="btn table-icon iicon"
          onClick={handleClick}
        >
          <img
            style={{
              height: "16px",
              width: "16px"
            }}
            src={`${process.env.PUBLIC_URL}/assets/icons/upload-white.svg`}
          />
        </span>
        <input
          type="file"
          // ref={hiddenFileInput}
          // onChange={handleChange}
          style={{ display: "none" }}
        />
        <div className="RiFileUploadLine-txt-wrapper">
          <button className="portal-btn portal-btn-submit ">
            SAXO Account Opening
          </button>
        </div>
      </div>
    </div>
  )
}

export const ifastFieldRenderBootstrap = ({ input, label }) => {
  const { value } = input

  //get client id
  let clientId = input.client.id

  //get partner id
  let partnerId = input.partner ? input.partner.id : undefined

  let category = input.type

  const {
    modal: { showConfirmationDialog }
  } = useContext(AppContext)

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null)

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click()
  }

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0]

    let data = {
      category: category,
      clientId: clientId,
      name: "product application",
      multipartFile: fileUploaded,
      paper: false
    }

    //call api
    api
      .post(`/client/` + clientId + `/product-application`, data)
      .then((response) => {
        Alert.success("The file has been uploaded successfully.")
      })
  }

  return (
    <div className={`portal-form-row productApplication`}>
      <div className="investmentApplication-button">
        <span
          style={{
            borderRadius: "4px",
            padding: "5px",
            background:
              "linear-gradient(135deg, rgba(0, 110, 155, 1) 0%, rgba(0, 163, 228, 1) 40%, rgba(0, 174, 243, 1) 100%)"
          }}
          className="btn table-icon iicon"
          onClick={handleClick}
        >
          <img
            style={{
              height: "16px",
              width: "16px"
            }}
            src={`${process.env.PUBLIC_URL}/assets/icons/upload-white.svg`}
          />
        </span>
        <input
          type="file"
          // ref={hiddenFileInput}
          // onChange={handleChange}
          style={{ display: "none" }}
        />
        <div className="RiFileUploadLine-txt-wrapper">
          <button className="portal-btn portal-btn-submit ">
            IFast Account Opening
          </button>
        </div>
      </div>
    </div>
  )
}

export const fileFieldRenderBootstrap = ({ input, label }) => {
  const { value } = input
  return (
    <div className="row align-items-center">
      {label && (
        <div className="col col1">
          <label>
            <p>{label}</p>
          </label>
        </div>
      )}

      <div className="col col2">
        <div className="row-holder">
          {value && (
            <a href={`${fileBaseUrl}${value.path}`}>{value.filename}</a>
          )}
          <CustomFilePond
            allowMultiple={false}
            maxFiles={1}
            allowReplace
            maxFileSize={maxFileSize}
            onupdatefiles={(fileItems) => {
              input.value.rawFile = fileItems[0]
            }}
          />
        </div>
      </div>
    </div>
  )
}

export const fileFieldRenderBootstrapWithoutLabel = (props) => (
  <MalabarUploadPhoto {...props} />
)
