import api from "../lib/api";


// change base route for different entity/domain here
const BASE_URL = '/transfer';

class TransferApi {
    static getDealershipAdvisers(fromAdviserId) {
        return api.get(`${BASE_URL}/dealership-advisers?fromAdviserId=${fromAdviserId}`);
    }
    
    static getDealershipEntities() {
        return api.get(`/team/logged-available-entities`);
    }

    static requestTransfer(model) {
        let url = BASE_URL + '/request-transfer';
        return api.post(url, model);
    }

    static approval(model) {
        let url = BASE_URL + '/approval';
        return api.post(url, model);
    }

    static getRequestTranferList(status) {
        let url = BASE_URL + '/request-transfer';
        if(status){
            url = url + '?status='+status;
        }
        return api.get(url);
    }

}

export default TransferApi;
