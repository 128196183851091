import React from "react"
import { FilePond } from "react-filepond"

const CustomFilePond = (props) => {
  return (
    <div className={`${props.customClassName}`}>
      <FilePond {...props} />
    </div>
  )
}

export default CustomFilePond
