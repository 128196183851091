import React, { useContext, useEffect, useState } from "react"
import intl from "react-intl-universal"
import Notes from "../../../DashboardElement/Notes"
import { AppContext } from "../../../../context/AppContext"
import { useSelector } from "react-redux"
import { AccessLevelEnum } from "../../../../constants/Enums"
import { checkAccessMenu } from "../../../../helpers"
import { history } from "../../../../helpers"
import UserApi from "../../../../api/UserApi"
import FieldToggle from "../FieldToggle"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { useParams } from "react-router-dom"
import TaskDialog from "../../../../pages/TaskManagement/TaskDialog"
import Tooltip from "rc-tooltip"
import AssistantAIDialog from "../../../../pages/openai/AssistantAIDialog"
const MalabarDefaultRightMenu = ({
  onClearClick,
  onSaveClick,
  onNoteClick,
  onXEClick,
  match,
  enableSaveButton = true,
  enableClearButton = true,
  enableNoteButton = true,
  enableAIAssistantButton = true,
  ...props
}) => {
  const { id } = useParams()

  const dispatch = useDispatch()
  const { onlyRequiredFields } = useSelector((state) => state.users)

  const entityData = useSelector((state) => state.entity)
  const {
    user: {
      selectedEntity: { entityType }
    }
  } = useSelector((state) => state.authentication)

  const { client, clientId, clientFullNamePreferred } = entityData
  // useEffect(() => {
  //   if (client && client.id) {
  //     dispatch(toggleFieldsVisibility(client.showOnlyRequiredFields))
  //   } else {
  //     dispatch(toggleFieldsVisibility(false))
  //   }
  // }, [client])

  let currentPath = window.location.pathname
  let activeClientAccessLevel = checkAccessMenu(currentPath, clientId)

  const {
    modal: { setModal, clearModal }
  } = useContext(AppContext)

  const [user, setUser] = useState("")
  function makeCapitalize(word) {
    let b = []
    for (let i = 0; i < word.length; i++) {
      if (i === 0) {
        b = [word[i].toLocaleUpperCase()]
      } else {
        b.push(word[i].toLocaleLowerCase())
      }
    }
    return b.join("")
  }

  function onExitClicked(e, path) {
    UserApi.track(path)
    history.push(path)
  }

  function TaskMgmtButton({}) {
    const {
      modal: { setModal, clearModal }
    } = useContext(AppContext)

    const showForm = () => {
      setModal({
        title: `Task Form`,
        content: <TaskDialog clearModal={clearModal} />,
        isSmall: true
      })
    }

    function showTooltipMenus() {
      return (
        <Tooltip
          zIndex={9999}
          showArrow={true}
          placement="left"
          overlay={
            <div className="cover">
              <div className="menu-items" onClick={(e) => showForm()}>
                <span>New Task</span>
              </div>

              <div
                className="menu-items"
                onClick={(e) => history.push("/taskmanagement")}
              >
                <span>Show Tasks</span>
              </div>
            </div>
          }
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          <span key={"task"} className={`action-button `}>
            <div className="large">TSK</div>
            <div className="small">Tasks</div>
          </span>
        </Tooltip>
      )
    }

    return showTooltipMenus()
  }

  function onAssistantAIClicked(clientId) {
    setModal({
      title: `KoiZai AI Assistant`,
      content: (
        <AssistantAIDialog clearModal={clearModal} clientId={clientId} />
      ),
      isSmall: true
    })
  }

  useEffect(() => {
    if (clientId && client !== undefined) {
      const userData = async () => {
        let user1 = {
          id: clientId,
          info: {
            clientCategoryStatus: makeCapitalize(client.status)
          }
        }
        setUser(user1)
      }
      userData()
    }
  }, [client, clientId])
  return (
    <div className="action-panel">
      <div className="action-panel-top">
        {id &&
          clientId &&
          id === clientId &&
          process.env.REACT_APP_NODE_ENV != "production" &&
          enableAIAssistantButton && (
            <span
              className={`action-button${
                props.readOnlyAccess || !enableAIAssistantButton
                  ? " disabled-link"
                  : ""
              }`}
              onClick={(e) => onAssistantAIClicked(clientId ? clientId : id)}
            >
              <div className="large">AI</div>
              <div className="small">Assistant</div>
            </span>
          )}

        {id && clientId && id === clientId ? (
          <span
            className={`${onlyRequiredFields ? "active" : ""} action-button`}
            onClick={() => {
              setModal({
                title: intl.get("minimal.title"),
                content: <FieldToggle />,
                isSmall: true
              })
            }}
          >
            <div className="large">MIN</div>
            <div className="small">{intl.get("rightmenu.field")}</div>
          </span>
        ) : (
          <span
            className={`action-button${
              props.readOnlyAccess || !enableClearButton ? " disabled-link" : ""
            }`}
            onClick={onClearClick}
          >
            <div className="large">CLR</div>
            <div className="small">{intl.get("rightmenu.clearplan")}</div>
          </span>
        )}
        {id && clientId && id === clientId ? (
          <span
            className={`action-button${
              props.readOnlyAccess || !enableClearButton ? " disabled-link" : ""
            }`}
            onClick={onClearClick}
          >
            <div className="large">CLR</div>
            <div className="small">{intl.get("rightmenu.clearplan")}</div>
          </span>
        ) : (
          activeClientAccessLevel ==
            AccessLevelEnum.AccessLevelEnum_READ_WRITE && (
            <span
              className={`action-button${
                props.readOnlyAccess || !enableSaveButton
                  ? " disabled-link"
                  : ""
              }`}
              onClick={onSaveClick}
            >
              <div className="large">SAV</div>
              <div className="small">{intl.get("rightmenu.saveplan")}</div>
            </span>
          )
        )}
      </div>
      {id && clientId && id === clientId ? (
        <div className="action-panel-middle d-flex align-items-center justify-content-between">
          {activeClientAccessLevel ==
            AccessLevelEnum.AccessLevelEnum_READ_WRITE && (
            <span
              className={`action-button${
                props.readOnlyAccess || !enableSaveButton
                  ? " disabled-link"
                  : ""
              }`}
              onClick={onSaveClick}
            >
              <div className="large">SAV</div>
              <div className="small">{intl.get("rightmenu.saveplan")}</div>
            </span>
          )}
        </div>
      ) : (
        ""
      )}
      <TaskMgmtButton />
      <div className="action-panel-exit">
        {entityType !== "CLIENT" && (
          <span
            className={`action-button ${
              props.readOnlyAccess || !enableNoteButton ? " disabled-link" : ""
            } ${entityType == "CLIENT" && "disabled-link"} `}
            onClick={() => {
              setModal({
                title: "CLIENT FILE NOTES",
                content: (
                  <Notes
                    user={user}
                    name={clientFullNamePreferred}
                    setModal={setModal}
                    closeModal={clearModal}
                  />
                ),
                isSmall: true
              })
            }}
          >
            <div className="large">NTE</div>
            <div className="small">{intl.get("rightmenu.note")}</div>
          </span>
        )}

        <Link
          to={localStorage.getItem("activeDashboardRoute")}
          className="action-button action-button-exit"
        >
          <div className="large">EXT</div>
          <div className="small">{intl.get("rightmenu.exit")}</div>
        </Link>
      </div>
    </div>
  )
}

export default MalabarDefaultRightMenu
