/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Description : Loan details form
*/

import React, { useState } from "react"
import intl from "react-intl-universal"
import NumberFormat from "react-number-format"
import RenderSimpleFields, {
  FieldType
} from "../../components/Form/RenderSimpleFields"
import { getSymbolFromCode } from "../../helpers"
import { DECIMAL_SCALE } from "../../constants/NumberFormatConstants"
import LoanRepaymentAmountCalculation from "../../services/LoanRepaymentAmountCalculation"

const GeneralLoanDetails = ({
  optionsData,
  showMortgageAssociatedCharges,
  objectName = "loan",
  defaultCurrency = "MYR",
  readOnly,
  values
}) => {
  const { loanInterestTypeOptions, frequencyOptions, currencyOptions } =
    optionsData

  const [loanCurrency, setCurrency] = useState(defaultCurrency)

  const loanRepaymentAmountWithInterest = LoanRepaymentAmountCalculation(values)

  const loanFields = [
    {
      name: `${objectName}.borrowingsAccountNumber`,
      label: intl.get("field.uniqueCode"),
      type: FieldType.TEXT,
      required: true
    },
    {
      name: `${objectName}.financialInstitutionName`,
      label: intl.get("field.financialInstitutionName"),
      type: FieldType.TEXT,
      required: false,
      disabled: readOnly
    },
    {
      name: `${objectName}.borrowingsCurrency`,
      label: intl.get("field.loan.borrowingsCurrency"),
      type: FieldType.ENUM_SELECTION,
      enumData: currencyOptions,
      defaultValue: loanCurrency,
      onChange: (value) => {
        setCurrency(value)
      },
      disabled: readOnly,
      value: loanCurrency,
      required: false
    },
    {
      name: `${objectName}.borrowingsPrinciple`,
      label: intl.get("field.loan.borrowingsPrinciple"),
      type: FieldType.MONEY,
      defaultCurrency: loanCurrency,
      symbolPrefix: getSymbolFromCode(loanCurrency),
      required: true,
      // disabled: readOnly,
      allowNegative: false
    },
    {
      label: intl.get("field.loan.borrowingsStartDate"),
      name: `${objectName}.borrowingsStartDate`,
      type: FieldType.DATE,
      required: true,
      disabled: readOnly
    },
    {
      name: `${objectName}.borrowingsTermInYears`,
      label: intl.get("field.loan.borrowingsTermInYears"),
      type: FieldType.NUMBER,
      disabled: readOnly,
      required: true,
      numberFormat: "##"
    },
    {
      name: `${objectName}.borrowingsAnnualInterestRate`,
      label: intl.get("field.loan.borrowingsAnnualInterestRate"),
      type: FieldType.PERCENTAGE,
      required: true,
      disabled: readOnly
    },

    {
      name: `${objectName}.valuationDate`,
      label: intl.get("field.loan.validationDate"),
      type: FieldType.DATE,
      disabled: readOnly
    },
    {
      name: `${objectName}.borrowingsRepaymentFrequency`,
      label: intl.get("field.loan.borrowingsRepaymentFrequency"),
      type: FieldType.ENUM_SELECTION,
      enumData: frequencyOptions,
      required: true,
      disabled: readOnly
    }
  ]
  const additionals = [
    {
      name: `${objectName}.additionalPaymentAmount`,
      label: intl.get("field.additionalPaymentAmount"),
      type: FieldType.MONEY,
      defaultCurrency: loanCurrency,
      symbolPrefix: getSymbolFromCode(loanCurrency),
      disabled: readOnly,
      allowNegative: false
    }
  ]

  return (
    <div className="pop-holder py-0">
      <RenderSimpleFields fields={loanFields} />
      <div className="portal-form-row undefined">
        <label htmlFor="loan.loanRepaymentAmountWithInterest">
          {intl.get("field.loan.loanRepaymentAmount")}{" "}
        </label>
        <div className="row-holder ">
          <NumberFormat
            value={loanRepaymentAmountWithInterest || 0}
            displayType={"input"}
            allowNegative
            decimalScale={DECIMAL_SCALE}
            prefix={getSymbolFromCode(loanCurrency)}
            decimalSeparator={"."}
            thousandSeparator={","}
            readOnly
            className="text-input currency-input "
          />
        </div>
      </div>

      <RenderSimpleFields fields={additionals} />
    </div>
  )
}

export default GeneralLoanDetails
