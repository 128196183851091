import React, { Component } from 'react';

import classnames from 'classnames';
import LoadingSpinnerCss from './LoadingSpinner.css';
import './MalabarSpinner.css';

const loadingSpinnerCss = classnames.bind(LoadingSpinnerCss);

class LoadingSpinner extends Component {
    render() {
        const loadingMaskStyle = loadingSpinnerCss({
            displayNone: !this.props.show,
            loadingMask: true,
        });

        return (
            <div className={loadingMaskStyle}>
                <div className="bulbaSpinner">
                    <div className="bulbaSpinnerInner one" />
                    <div className="bulbaSpinnerInner two" />
                    <div className="bulbaSpinnerInner three" />
                </div>
            </div>
        );
    }
}

export default LoadingSpinner;
