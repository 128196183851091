import { isNullOrUndefined } from 'util';
import { getDirectVariableValue } from './GetDirectValue';
import { countFromArray } from './CountFromArray';
import { totalSum } from './TotalSum';

export const entertainment = (values, currency) => {
    if (!isNullOrUndefined(values)) {
        const {
            amusement,
            art,
            moviesAndDvds,
            music,
            newspaperAndMagazines,
            others,
        } = values;

        const model = {
            amusement: getDirectVariableValue(amusement, currency),
            art: getDirectVariableValue(art, currency),
            music: getDirectVariableValue(music, currency),
            countMoviesAndDvds: getDirectVariableValue(moviesAndDvds, currency),
            newspaperAndMagazines: getDirectVariableValue(
                newspaperAndMagazines,
                currency
            ),
            others: countFromArray(others, currency),
        };

        return {
            clientTotal: totalSum(model, 'clientTotal'),
            partnerTotal: totalSum(model, 'partnerTotal'),
            jointTotal: totalSum(model, 'jointTotal'),
        };
    }
};
