/* eslint-disable no-plusplus */
import React, { useContext } from "react"
// import ReactSpeedometer from 'react-d3-speedometer';
import { RadialIndicator } from "../../components"
import { AppContext } from "../../context/AppContext"
import LiquityRatioDetails from "./LiquityRatioDetails"

function GaugeRadialChart({
  name,
  title,
  bestPracticeDescription,
  description,
  value,
  dangerLevelValue = 50,
  minValue,
  maxValue,
  isValueInverted = false, // if inverted, green will show what is more than dangerLevelValue
  data,
  numeratorLabel,
  denominatorLabel,
  numeratorValue,
  denominatorValue,
  inPercentage,
  inMonths
}) {
  const {
    modal: { setModal, clearModal }
  } = useContext(AppContext)

  let indicatorVal = value > 0 ? Math.round((value / maxValue) * 100) : 0
  const getColor = () => (minValue < value ? "ratio-green" : "ratio-red")

  // isValueInverted
  //   ? value < dangerLevelValue
  //     ? "ratio-green"
  //     : "ratio-red"
  //   : value >= dangerLevelValue
  //   ? "ratio-green"
  //   : "ratio-red"

  const onDetails = (bestdescription, description, totalLiquidAsset) => {
    setModal({
      title: title,
      content: (
        <LiquityRatioDetails
          bestdescription={{ minValue, maxValue }}
          description={description}
          data={data}
          value={value}
          numeratorLabel={numeratorLabel}
          denominatorLabel={denominatorLabel}
          numeratorValue={numeratorValue}
          denominatorValue={denominatorValue}
          inPercentage={inPercentage}
          minValue={minValue}
          maxValue={maxValue}
        />
        // <NetWorthDialog
        // />
      ),
      isSmall: true
    })
  }

  return (
    <React.Fragment>
      <div className={`ratio-box ${getColor()}`}>
        <div className="ratio-graph">
          <div className="graph-text">{title}</div>

          <div
            className="graph-image-gauge"
            onClick={() =>
              onDetails(bestPracticeDescription, description, data)
            }
          >
            <RadialIndicator
              currentValue={indicatorVal}
              realValue={value}
              targetValue={100}
              extraDesc={""}
              size={"small"}
              borderColor={getColor()}
              inPercentage={inPercentage}
            />
          </div>
        </div>
        <div className="ratio-content">
          <div className="ratio-guide">
            Best Practice: {minValue}
            {inPercentage ? "%" : ""} to
            {maxValue === 9999 ? " Above" : ` ${maxValue}`}
            {maxValue !== 9999 && inPercentage ? "%" : ""}
            {/* {` < Best Practice `} */}
            {/* {inPercentage ? "%" : ""} */}
          </div>
          <p>{description}</p>
        </div>
      </div>
    </React.Fragment>
  )
}

export default GaugeRadialChart
