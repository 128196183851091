/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Description : Property Address page (tab component)
*/

import React from 'react';
import intl from 'react-intl-universal';
import { ModalNoAccess } from '../../components';
import RenderSimpleFields, {
    FieldType,
} from '../../components/Form/RenderSimpleFields';

const PropertyAddress = ({ optionsData, readOnlyAccess }) =>
    !readOnlyAccess ? (
        <React.Fragment>
            {
                <div className="pop-holder">
                    <RenderSimpleFields
                        fields={[
                            {
                                name: 'addressDetails.addressLine1',
                                label: intl.get('address.line1'),
                                type: FieldType.TEXT,
                            },
                            {
                                name: 'addressDetails.addressLine2',
                                label: intl.get('address.line2'),
                                type: FieldType.TEXT,
                            },
                            {
                                name: 'addressDetails.suburb',
                                label: intl.get('address.suburbCity'),
                                type: FieldType.TEXT,
                            },
                            {
                                name: 'addressDetails.state',
                                label: intl.get('address.state'),
                                type: FieldType.TEXT,
                            },
                            {
                                name: 'addressDetails.country',
                                label: intl.get('address.country'),
                                type: FieldType.SELECTION,
                                selectionData: optionsData.countryOptions,
                                valueProperty: 'code',
                                labelProperty: 'name',
                            },
                            {
                                name: 'addressDetails.postalCode',
                                label: intl.get('address.postcode'),
                                type: FieldType.NUMBER,
                            },
                        ]}
                    />
                </div>
            }
        </React.Fragment>
    ) : (
        <ModalNoAccess />
    );

export default PropertyAddress;
