import React, { useState, useEffect, useContext } from "react"
import { AppContext } from "../../../context/AppContext"
import MalaysiaTaxEstimator from "../../../forms/MalaysiaTaxEstimator"
import intl from 'react-intl-universal';


const TaxSelector = ({client, model, handleSave, closeParentWindow}) =>{
    const {
        modal: { setModal, clearModal }
      } = useContext(AppContext)

    function onModalClosed() {
        clearModal()
        closeParentWindow();
    
    }
    function useTaxEstimator() {
        setModal({
            title: intl.get("taxation.estimator.title"),
            content: (
                <MalaysiaTaxEstimator
                    model={model}
                    client={client}
                    handleSave={handleSave}
                    onModalClosed={onModalClosed}

                    
                />
            ),
            isSmall: true
        })
    }  
    return (
        <div className="portal-form-row">
            <div className="col-md-12 text-right">
                <button className="portal-btn portal-btn-submit fa fa-bank" onClick={(e)=>useTaxEstimator()}>
                    {" "}{intl.get("taxation.estimator.title")}
                </button>
            </div>
        </div>
    )
}
export default TaxSelector;