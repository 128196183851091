import React from "react"
import intl from "react-intl-universal"
import { FieldArray } from "react-final-form-arrays"
import { Field } from "react-final-form"
import {
  renderTextBoxCustom,
  renderSelectEnumFieldCustom
} from "../../../helpers"
import { optionsType, getOptions } from "../../../helpers/OptionsHelper"
const ILASBeneficaryDetails = ({
  mutators
  // addBeneficiaryDetails,
  // removeBeneficiaryDetails
}) => {
  const optionsData = getOptions([optionsType.relationshipTypeOptions])
  return (
    <>
     
      <div className="portal-form-row text-right">
        <span
          className="btn btn-plus btn-plus-grey"
          onClick={() => mutators.push("beneficiaries", undefined)}
        />
      </div>
      <table
        className="table-blue"
        width="100%"
        cellSpacing={0}
        cellPadding={0}
        border={0}
      >
        <tbody>
          <tr>
            <th>{intl.get("ilasInvestment.beneficiaryName")}</th>
            <th>{intl.get("ilasInvestment.beneficiaryRelationship")}</th>
            <th>{intl.get("ilasInvestment.beneficiaryEmail")}</th>
            <th>{intl.get("ilasInvestment.beneficiaryPhone")}</th>
            <th>&nbsp;</th>
          </tr>
          <FieldArray name="beneficiaries">
            {({ fields }) =>
              fields.map((name, index) => {
                return (
                  <tr key={index}>
                   
                    <td>
                      <Field
                        name={`${name}.name`}
                        component={renderTextBoxCustom}
                        isFullWidth
                        isForGrid
                      />
                    </td>
                    <td>
                      <Field
                        name={`${name}.relationship`}
                        component={renderSelectEnumFieldCustom}
                        isFullWidth
                        isForGrid
                        enumData={optionsData.relationshipTypeOptions}
                      />
                    </td>
                    <td>
                      <Field
                        name={`${name}.emailAddress`}
                        component={renderTextBoxCustom}
                        isFullWidth
                        isForGrid
                      />
                    </td>
                    <td>
                      <Field
                        name={`${name}.contactNumber`}
                        component={renderTextBoxCustom}
                        isFullWidth
                        isForGrid
                      />
                    </td>
                    <td>
                      <span
                        className="btn-remove"
                        onClick={() => {
                          fields.remove(index)
                        }}
                      />
                    </td>
                  </tr>
                )
              })
            }
          </FieldArray>
        
        </tbody>
      </table>
    </>
  )
}

export default ILASBeneficaryDetails
