/* eslint-disable prettier/prettier */
/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Description : Cashflow Listing main component
*/

import React from "react"
import { Field } from "react-final-form"
import {
  renderCurrencyCashflow,
  renderPercentageCustom,
  renderTextBoxCustom,
  renderSelectEnumFieldCustom
} from "../../helpers"
import { useDispatch } from "react-redux"
import { netWorthFormActions } from "../../actions"

const CashFlowListing = ({
  varPrefix,
  title,
  isRemoveButton,
  placeholderLabel,
  isShowAddButton,
  isEditButton,
  isJointHide,
  isRoundColorRed = false,
  isAdditionalInput,
  isAdditionalMoreInput,
  isAdditionalInputWithSelect,
  isOnlyTextTitle = false,
  isOnlySectionTitle = false,
  isTitleTabbed = false,
  isExternalModal = false,
  placeholderMoreLabel,
  optionsData,
  push,
  additionName,
  index,
  fields,
  clientVarName = "client",
  partnerVarName = "partner",
  jointVarName = "joint",
  onEditClick,
  onAddClick,
  defaultDeductibleValue = 0,
  onChange,
  currency = "MYR"
}) => {
  const dispatch = useDispatch()
  const firstHandler = () => {
    push(`${additionName}`, {
      deductible: defaultDeductibleValue
    })
    dispatch(netWorthFormActions.setNewForm(true, ""))
  }
  const secondHandler = () => {
    onAddClick()
    dispatch(netWorthFormActions.setNewForm(true, ""))
  }
  return (
    <React.Fragment>
      {isShowAddButton && !isExternalModal && (
        <div className="portal-form-row portal-form-row-goal2col">
          <label>
            <div className="goals-title">{title}</div>{" "}
            <span
              onClick={firstHandler}
              className="btn btn-plus btn-plus-grey"
            />
          </label>
        </div>
      )}
      {isShowAddButton && isExternalModal && (
        <div className="portal-form-row portal-form-row-goal2col">
          <label>
            <div className="goals-title">{title}</div>{" "}
            <span
              onClick={secondHandler}
              className="btn btn-plus btn-plus-grey"
            />
          </label>
        </div>
      )}
      {!isOnlySectionTitle && (
        <div
          className={`portal-form-row portal-form-row-goal2col ${
            isTitleTabbed ? "label-intent" : ""
          }`}
        >
          {isAdditionalMoreInput ? (
            isAdditionalInputWithSelect ? (
              <label className="no-toppadding seltext2col">
                <Field
                  name="currency"
                  component={renderSelectEnumFieldCustom}
                  isLabel={false}
                  required={false}
                  enumData={optionsData}
                  isNotUpperDiv
                  disabled={isExternalModal}
                />

                <Field
                  name="test"
                  type="text"
                  component={renderTextBoxCustom}
                  label={placeholderLabel}
                  isNotUpperDiv
                  onChange={onChange}
                  disabled={isExternalModal}
                />
              </label>
            ) : (
              <label className="no-toppadding text2col">
                <Field
                  name="test1"
                  type="text"
                  component={renderTextBoxCustom}
                  label={placeholderMoreLabel.split(",")[0]}
                  isNotUpperDiv
                  onChange={onChange}
                  disabled={isExternalModal}
                />
                <Field
                  name="test2"
                  type="text"
                  component={renderTextBoxCustom}
                  label={placeholderMoreLabel.split(",")[1]}
                  isNotUpperDiv
                  onChange={onChange}
                  disabled={isExternalModal}
                />
              </label>
            )
          ) : isAdditionalInput ? (
            ""
          ) : isOnlyTextTitle ? (
            <label>{title}</label>
          ) : isEditButton ? (
            <label>
              <div className="goals-title">{title}</div>
              <span onClick={onEditClick} className="btn btn-edit" />
            </label>
          ) : (
            <label>
              <div className="goals-title" />
            </label>
          )}

          {!isShowAddButton && (
            <React.Fragment>
              <div className="row-holder">
                <Field
                  name={`${varPrefix}.${clientVarName}`}
                  component={renderCurrencyCashflow}
                  isLabel={false}
                  isForGrid
                  required={false}
                  isRounded
                  placeholderLabel={""}
                  disabled={isExternalModal}
                  onChange={onChange}
                  currency={currency}
                />
              </div>
              {isJointHide ? (
                ""
              ) : (
                <div className="row-holder">
                  <Field
                    name={`${varPrefix}.${partnerVarName}`}
                    component={renderCurrencyCashflow}
                    isLabel={false}
                    isForGrid
                    required={false}
                    isRounded
                    placeholderLabel={""}
                    disabled={isExternalModal}
                    onChange={onChange}
                    currency={currency}
                  />
                </div>
              )}
              {isJointHide ? (
                ""
              ) : (
                <div className="row-holder">
                  <Field
                    name={`${varPrefix}.${jointVarName}`}
                    component={renderCurrencyCashflow}
                    isLabel={false}
                    isForGrid
                    required={false}
                    isRounded
                    placeholderLabel={""}
                    disabled={isExternalModal}
                    onChange={onChange}
                    currency={currency}
                  />
                </div>
              )}
              <div className="row-holder-last">
                <Field
                  name={`${varPrefix}.deductible`}
                  component={renderPercentageCustom}
                  isLabel={false}
                  isForGrid
                  required={false}
                  isRounded
                  isRoundColorRed={isRoundColorRed}
                  initialValue={0}
                  disabled={isExternalModal}
                />
              </div>
              {isRemoveButton ? (
                <span
                  href="#"
                  className="btn-remove"
                  onClick={() => fields.remove(index)}
                />
              ) : (
                ""
              )}
            </React.Fragment>
          )}
        </div>
      )}
    </React.Fragment>
  )
}

export default CashFlowListing
