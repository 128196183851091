import { appSettingAction } from '../constants/ActionConstants';

import { EntityLayoutClassName } from '../constants/AppSettingConstants';

const getLayoutClassName = entityTypeEnum =>EntityLayoutClassName[entityTypeEnum];
const loginUserEntity = JSON.parse(localStorage.getItem('loginUserEntity'));

const initialState = {
    activeUserEntity: loginUserEntity, // at first get the logged-in user
    activeClassName: getLayoutClassName(localStorage.getItem('loginEntityType')),
    activeDashboardRoute: localStorage.getItem('activeDashboardRoute'),
    activeEntityType: localStorage.getItem('loginEntityType'),
    activeUserRole: localStorage.getItem('activeUserRole'),
    activeUserId: localStorage.getItem('activeClientId'),
    currentLanguage: localStorage.getItem('currentLanguage') || 'en-US',
};

export function appSettings(state = initialState, action) {
    switch (action.type) {
        case appSettingAction.SET_ACTIVE_CLASS:
            return {
                ...state,
                activeClassName: getLayoutClassName(action.entityType),
            };
        case appSettingAction.SET_ACTIVE_USER:
            return {
                ...state,
                activeUserEntity: action.entity,
                activeClassName: getLayoutClassName(
                    action.entity.entityDetails.entityType
                ),
                activeEntityType: action.entity.entityDetails.entityType,
                activeDashboardRoute: localStorage.getItem(
                    'activeDashboardRoute'
                ),
                // TODO: replace this with value from API when will be used later
                activeUserRole: '',
                
            };
        case appSettingAction.SET_BURGER_MENU:
            return {
                ...state,
                isBurgerMenuClient: action.isClient,
            };
        case appSettingAction.SET_ACTIVE_USER_ID:
            return {
                ...state,
                activeUserId: action.id,
            };

        case appSettingAction.SET_CURRENT_LANGUAGE:
            return {
                ...state,
                currentLanguage: localStorage.getItem('currentLanguage'),
            };
        default:
            return state;
    }
}
