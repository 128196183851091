/*
    Author : anton.nuansantio@koizai.com
    Library : AiAssistantApi
    Description : methods related with Financial Regulators
*/

import api from '../lib/api';
// change base route for different entity/domain here
const BASE_ROUTE = '/ai';

class AiAssistantApi {

    static resetLoadDefaultSettings() {
        return api.get(`${BASE_ROUTE}/reset-default-settings`);
    }
    static toggleEnableDisable(data) {
        return api.post(`${BASE_ROUTE}/enable-disable-settings`, data);
        
        
    }

    static getDefaultSettings() {
        return api.get(`${BASE_ROUTE}/settings`);
    }

    static savePlanType(model) {
        return api.post(`${BASE_ROUTE}/save-plan-type`, model);
    }

    static saveEnumData(model) {
        return api.post(`${BASE_ROUTE}/save-enum-data`, model);
    }

    static initInfo(clientId) {
        return api.get(`${BASE_ROUTE}/${clientId}/init`);
    }

  
    static ask(data) {
        return api.post(`${BASE_ROUTE}/ask`, data);
        
        
    }

    static saveAsRecommendationDraft(model){
        return api.post("/recommendation", model)
        
    }


    static deleteByCode(data) {
        return api.post(`${BASE_ROUTE}/delete-enum-data`, data);
        
        
    }

  
    
}

export default AiAssistantApi;
