/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Description : Deductible Expenses Form
*/

import React from "react";
import intl from "react-intl-universal";
import { Field } from "react-final-form";
import NumberFormat from "react-number-format";
import { FieldArray } from "react-final-form-arrays";
import {
    renderCurrencyCustom,
    renderSelectEnumFieldCustom,
    renderTextBoxCustom,
    required,
    getSymbolFromCode
} from "../../helpers";
import { getNewCurrencyValue } from "../../helpers/CurrencyConversion";
import LoanRepaymentAmountWithInterestCalculation from "../../services/LoanRepaymentAmountWithInterestCalculation";
import { DECIMAL_SCALE } from "../../constants/NumberFormatConstants";

export const DeductibleExpensesMutators = {
    adjustMonthlyYearlyValue
};

function adjustMonthlyYearlyValue(args, state, utils) {
    const value = args[0];
    const inputName = args[1];
    const targetInputName = inputName.includes("Yearly")
        ? inputName.replace("Yearly", "")
        : `${inputName}Yearly`;

    if (targetInputName.includes("others")) {
        utils.changeValue(state, targetInputName, () =>
            inputName.includes("Yearly") ? value / 12 : value * 12
        );
    } else {
        utils.changeValue(state, `deductibleExpenses.${targetInputName}`, () =>
            inputName.includes("Yearly") ? value / 12 : value * 12
        );
    }
}

const YearlyValues = model => {
    if (model && model.deductibleExpenses) {
        Object.keys(model.deductibleExpenses)
            .filter(key => !key.includes("Yearly") && key.startsWith("monthly"))
            .forEach(key => {
                model.deductibleExpenses[key] =
                    Number(model.deductibleExpenses[key]) || 0;

                model.deductibleExpenses[`${key}Yearly`] =
                    model.deductibleExpenses[key] * 12;

                model.deductibleExpenses.propertyLandTaxYearly =
                    model.deductibleExpenses.propertyLandTax * 12;
            });
    }
};

const DeductibleExpenses = ({
    onMonthlyYearlyValueChange,
    disabled,
    optionsData,
    addOtherExpense,
    removeOtherExpense,
    model,
    currency
}) => {
    YearlyValues(model);
    const defaultCurrency = currency;
    const defaultSymbolPrefix = getSymbolFromCode(currency);
    model.mortgage = model.mortgage || {};
    const expenses = [
        {
            name: "monthlyAcquisitionAndDisposalCosts",
            label: "acquisitionDisposalCosts"
        },
        {
            name: "monthlyAdvertisingForTenants",
            label: "advertisingForTenants"
        },
        { name: "monthlyBodyCorporateFees", label: "bodyCorporateFees" },
        {
            name: "monthlyBorrowingExpensesAndInterest",
            label: "borrowingExpensesInterest"
        },
        {
            name: "monthlyCapitalWorksAndRepairs",
            label: "capitalWorksRepairs"
        },
        { name: "monthlyCleaning", label: "cleaning" },
        {
            name: "monthlyCommissionsAndManagementFees",
            label: "commissionsManagementFees"
        },
        { name: "monthlyCouncilRates", label: "councilRates" },
        { name: "monthlyDepreciation", label: "depreciation" },
        { name: "monthlyElectricityGas", label: "electricityGas" },
        { name: "monthlyGardeningAndYardWork", label: "gardeningYardWork" },
        { name: "monthlyInsurance", label: "insurance" },
        { name: "monthlyLandTax", label: "landTax" },
        { name: "monthlyLegalExpenses", label: "legalExpenses" },
        { name: "monthlyOfficeSupplies", label: "officeSupplies" },
        { name: "monthlyOtherExpenses", label: "otherExpenses" },
        { name: "monthlyPestControl", label: "pestControl" },
        { name: "monthlyTelephoneExpenses", label: "telephoneExpenses" },
        { name: "monthlyTravelExpenses", label: "travelExpenses" },
        { name: "monthlyUtility", label: "utility" }
    ];

    const grandTotal = values =>
        countValues(values.deductibleExpenses) +
        (values.deductibleExpenses && values.deductibleExpenses.propertyLandTax
            ? +values.deductibleExpenses.propertyLandTax
            : 0) +
        subtotalFinancePayments(values) +
        insurancePremiumSubtotal(values);

    function mortgageInsuranceConversion(values) {
        let mortgageInsurance = 0;
        if (
            values.mortgageInsurance &&
            values.mortgageInsurance.premiumMonthly
        ) {
            mortgageInsurance = getNewCurrencyValue(
                values.mortgageInsurance.premiumMonthly,
                defaultCurrency
            );
        }
        return mortgageInsurance;
    }

    function fireAndContentInsuranceConversion(values) {
        let fireAndContentInsurance = 0;
        if (
            values.fireAndContentInsurance &&
            values.fireAndContentInsurance.premiumMonthly
        ) {
            fireAndContentInsurance = getNewCurrencyValue(
                values.fireAndContentInsurance.premiumMonthly,
                defaultCurrency
            );
        }
        return fireAndContentInsurance;
    }
    function insurancePremiumSubtotal(values) {
        return (
            mortgageInsuranceConversion(values) +
            fireAndContentInsuranceConversion(values)
        );
    }
    const loanRepaymentAndInterest = values =>
        getNewCurrencyValue(
            {
                currency: values.mortgage.borrowingsCurrency || "AUD",
                value: LoanRepaymentAmountWithInterestCalculation(
                    values.mortgage
                )
            },
            defaultCurrency
        );
    const mortgagePaymentAndInterest = values =>
        +loanRepaymentAndInterest(values) +
            +values.mortgage.additionalPaymentAmount || 0;
    const mortgageAssociatedCharges = values =>
        +values.mortgage.additionalRepaymentHandlingFees +
            +values.mortgage.borrowingsManagementFees || 0;

    const subtotalFinancePayments = values =>
        mortgagePaymentAndInterest(values) +
            mortgageAssociatedCharges(values) || 0;

    // 20200609
    const handleChange = (e, type) => {};

    return (
        <React.Fragment>
            {
                <div className="pop-holder2col">
                    <div className="content-panel-title content-panel-title-monthly">
                        <div className="title-box">
                            {intl.get("field.monthly")}
                        </div>
                        <div className="title-box">
                            {intl.get("field.yearly")}
                        </div>
                    </div>
{/* 
                    <div className="portal-form-row">
                        <label className="subtitlelabel expensesCurrencyLabel">
                            {intl.get("field.expensesCurrency")}
                        </label>
                        <Field
                            name="deductibleExpenses.expensesCurrency"
                            component={renderSelectEnumFieldCustom}
                            enumData={optionsData.currencyOptions}
                            disabled={disabled}
                            onChange={handleChange}
                        />
                    </div> */}
                    <div className="portal-form-row">
                        <label className="subtitlelabel">
                            {intl.get("field.generalExpenses")}
                        </label>
                    </div>

                    {expenses.map((item, index) => (
                        <div className="portal-form-row" key={index}>
                            <label>{intl.get(`field.${item.label}`)}</label>
                            <Field
                                name={`deductibleExpenses.${item.name}`}
                                component={renderCurrencyCustom}
                                onChange={newValue =>
                                    onMonthlyYearlyValueChange(
                                        newValue,
                                        item.name
                                    )
                                }
                                disabled={disabled}
                                defaultCurrency={defaultCurrency}
                                symbolPrefix={defaultSymbolPrefix}
                            />
                            <Field
                                name={`deductibleExpenses.${item.name}Yearly`}
                                component={renderCurrencyCustom}
                                onChange={newValue =>
                                    onMonthlyYearlyValueChange(
                                        newValue,
                                        `${item.name}Yearly`
                                    )
                                }
                                disabled={disabled}
                                value={43434}
                                defaultCurrency={defaultCurrency}
                                symbolPrefix={defaultSymbolPrefix}
                            />
                        </div>
                    ))}
                    <div className="portal-form-row">
                        <label>
                            <b>Others</b>
                        </label>
                        <div className="row-holder">
                            <button
                                type="button"
                                onClick={() =>
                                    addOtherExpense(
                                        "deductibleExpenses.others",
                                        {
                                            value: 0,
                                            valueYearly: 0
                                        }
                                    )
                                }
                                className="btn btn-plus"
                            />
                        </div>
                    </div>
                    <FieldArray name="deductibleExpenses.others">
                        {({ fields }) =>
                            fields.map((name, index) => (
                                <div
                                    className="portal-form-row other-expense"
                                    key={index}
                                >
                                    <Field
                                        name={`${name}.name`}
                                        component={renderTextBoxCustom}
                                        disabled={disabled}
                                        validate={required}
                                        containerCssClass={
                                            "networth-other-input"
                                        }
                                    />
                                    <Field
                                        name={`${name}.value`}
                                        type="text"
                                        component={renderCurrencyCustom}
                                        onChange={newValue =>
                                            onMonthlyYearlyValueChange(
                                                newValue,
                                                `${name}.value`
                                            )
                                        }
                                        disabled={disabled}
                                        containerCssClass={"networth-other-col"}
                                        defaultCurrency={defaultCurrency}
                                        symbolPrefix={defaultSymbolPrefix}
                                    />
                                    <Field
                                        name={`${name}.valueYearly`}
                                        type="text"
                                        component={renderCurrencyCustom}
                                        onChange={newValue =>
                                            onMonthlyYearlyValueChange(
                                                newValue,
                                                `${name}.valueYearly`
                                            )
                                        }
                                        disabled={disabled}
                                        defaultCurrency={defaultCurrency}
                                        symbolPrefix={defaultSymbolPrefix}
                                        containerCssClass={"networth-other-col"}
                                    />
                                    <button
                                        type="button"
                                        onClick={() =>
                                            removeOtherExpense(
                                                "deductibleExpenses.others",
                                                index
                                            )
                                        }
                                        className="btn-remove networth-other-remove"
                                    />
                                </div>
                            ))
                        }
                    </FieldArray>
                    <div className="portal-form-row">
                        <label className="subtitlelabel">
                            {"Sub Total - General Expenses"}
                        </label>
                        <div className="row-holder">
                            <NumberFormat
                                value={countValues(
                                    model.deductibleExpenses || {}
                                )}
                                displayType={"input"}
                                allowNegative
                                decimalScale={DECIMAL_SCALE}
                                prefix={defaultSymbolPrefix}
                                decimalSeparator={"."}
                                thousandSeparator={","}
                                className={`text-input currency-input text-input-total`}
                                readOnly
                            />
                        </div>
                        <div className="row-holder">
                            <NumberFormat
                                value={countValuesYearly(
                                    model.deductibleExpenses || {}
                                )}
                                displayType={"input"}
                                allowNegative
                                decimalScale={DECIMAL_SCALE}
                                prefix={defaultSymbolPrefix}
                                decimalSeparator={"."}
                                thousandSeparator={","}
                                className={`text-input currency-input text-input-total`}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="portal-form-row">
                        <label className="subtitlelabel">{"Tax"}</label>
                    </div>
                    <div className="portal-form-row">
                        <label>{"Property / Land Tax"}</label>
                        <Field
                            name={`deductibleExpenses.propertyLandTax`}
                            type="text"
                            component={renderCurrencyCustom}
                            defaultCurrency={defaultCurrency}
                            symbolPrefix={defaultSymbolPrefix}
                            onChange={newValue =>
                                onMonthlyYearlyValueChange(
                                    newValue,
                                    `propertyLandTax`
                                )
                            }
                        />
                        <Field
                            name={`deductibleExpenses.propertyLandTaxYearly`}
                            type="text"
                            component={renderCurrencyCustom}
                            defaultCurrency={defaultCurrency}
                            symbolPrefix={defaultSymbolPrefix}
                            onChange={newValue =>
                                onMonthlyYearlyValueChange(
                                    newValue,
                                    `propertyLandTaxYearly`
                                )
                            }
                        />
                    </div>

                    <div className="portal-form-row">
                        <label className="subtitlelabel">
                            {"Finance Payments"}
                        </label>
                    </div>

                    <div className="portal-form-row">
                        <label>{"Mortgage Payment + Interest"}</label>
                        <div className="row-holder">
                            <NumberFormat
                                value={mortgagePaymentAndInterest(model)}
                                displayType={"input"}
                                allowNegative
                                decimalScale={DECIMAL_SCALE}
                                prefix={defaultSymbolPrefix}
                                decimalSeparator={"."}
                                thousandSeparator={","}
                                className={`text-input currency-input `}
                                readOnly
                            />
                        </div>
                        <div className="row-holder">
                            <NumberFormat
                                value={mortgagePaymentAndInterest(model) * 12}
                                displayType={"input"}
                                allowNegative
                                decimalScale={DECIMAL_SCALE}
                                prefix={defaultSymbolPrefix}
                                decimalSeparator={"."}
                                thousandSeparator={","}
                                className={`text-input currency-input `}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="portal-form-row">
                        <label>{"Mortgage Associated Charges"}</label>
                        <div className="row-holder">
                            <NumberFormat
                                value={mortgageAssociatedCharges(model)}
                                displayType={"input"}
                                allowNegative
                                decimalScale={DECIMAL_SCALE}
                                prefix={defaultSymbolPrefix}
                                decimalSeparator={"."}
                                thousandSeparator={","}
                                className={`text-input currency-input `}
                                readOnly
                            />
                        </div>
                        <div className="row-holder">
                            <NumberFormat
                                value={mortgageAssociatedCharges(model) * 12}
                                displayType={"input"}
                                allowNegative
                                decimalScale={DECIMAL_SCALE}
                                prefix={defaultSymbolPrefix}
                                decimalSeparator={"."}
                                thousandSeparator={","}
                                className={`text-input currency-input `}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="portal-form-row">
                        <label className="subtitlelabel">
                            {"Sub Total - Finance Payments"}
                        </label>
                        <div className="row-holder">
                            <NumberFormat
                                value={subtotalFinancePayments(model)}
                                displayType={"input"}
                                allowNegative
                                decimalScale={DECIMAL_SCALE}
                                prefix={defaultSymbolPrefix}
                                decimalSeparator={"."}
                                thousandSeparator={","}
                                className={`text-input currency-input text-input-total`}
                                readOnly
                            />
                        </div>
                        <div className="row-holder">
                            <NumberFormat
                                value={subtotalFinancePayments(model) * 12}
                                displayType={"input"}
                                allowNegative
                                decimalScale={DECIMAL_SCALE}
                                prefix={defaultSymbolPrefix}
                                decimalSeparator={"."}
                                thousandSeparator={","}
                                className={`text-input currency-input text-input-total`}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="portal-form-row">
                        <label className="subtitlelabel">
                            {"Insurance Premium"}
                        </label>
                    </div>
                    <div className="portal-form-row">
                        <label>{"Fire & Contents Insurance"}</label>
                        <div className="row-holder">
                            <NumberFormat
                                value={fireAndContentInsuranceConversion(model)}
                                displayType={"input"}
                                allowNegative
                                decimalScale={DECIMAL_SCALE}
                                prefix={defaultSymbolPrefix}
                                decimalSeparator={"."}
                                thousandSeparator={","}
                                className={`text-input currency-input `}
                                readOnly
                            />
                        </div>
                        <div className="row-holder">
                            <NumberFormat
                                value={
                                    fireAndContentInsuranceConversion(model) *
                                    12
                                }
                                displayType={"input"}
                                allowNegative
                                decimalScale={DECIMAL_SCALE}
                                prefix={defaultSymbolPrefix}
                                decimalSeparator={"."}
                                thousandSeparator={","}
                                className={`text-input currency-input `}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="portal-form-row">
                        <label>{"Loan Insurance"}</label>
                        <div className="row-holder">
                            <NumberFormat
                                value={mortgageInsuranceConversion(model)}
                                displayType={"input"}
                                allowNegative
                                decimalScale={DECIMAL_SCALE}
                                prefix={defaultSymbolPrefix}
                                decimalSeparator={"."}
                                thousandSeparator={","}
                                className={`text-input currency-input `}
                                readOnly
                            />
                        </div>
                        <div className="row-holder">
                            <NumberFormat
                                value={mortgageInsuranceConversion(model) * 12}
                                displayType={"input"}
                                allowNegative
                                decimalScale={DECIMAL_SCALE}
                                prefix={defaultSymbolPrefix}
                                decimalSeparator={"."}
                                thousandSeparator={","}
                                className={`text-input currency-input `}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="portal-form-row">
                        <label className="subtitlelabel">
                            {"Sub Total - Insurance Premium"}
                        </label>
                        <div className="row-holder">
                            <NumberFormat
                                value={insurancePremiumSubtotal(model)}
                                displayType={"input"}
                                allowNegative
                                decimalScale={DECIMAL_SCALE}
                                prefix={defaultSymbolPrefix}
                                decimalSeparator={"."}
                                thousandSeparator={","}
                                className={`text-input currency-input text-input-total`}
                                readOnly
                            />
                        </div>
                        <div className="row-holder">
                            <NumberFormat
                                value={insurancePremiumSubtotal(model) * 12}
                                displayType={"input"}
                                allowNegative
                                decimalScale={DECIMAL_SCALE}
                                prefix={defaultSymbolPrefix}
                                decimalSeparator={"."}
                                thousandSeparator={","}
                                className={`text-input currency-input text-input-total`}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="portal-form-row">
                        <label className="subtitlelabel">{"GRAND TOTAL"}</label>
                        <div className="row-holder">
                            <NumberFormat
                                value={grandTotal(model)}
                                displayType={"input"}
                                allowNegative
                                decimalScale={DECIMAL_SCALE}
                                prefix={defaultSymbolPrefix}
                                decimalSeparator={"."}
                                thousandSeparator={","}
                                className={`text-input currency-input text-input-total`}
                                readOnly
                            />
                        </div>
                        <div className="row-holder">
                            <NumberFormat
                                value={grandTotal(model) * 12}
                                displayType={"input"}
                                allowNegative
                                decimalScale={DECIMAL_SCALE}
                                prefix={defaultSymbolPrefix}
                                decimalSeparator={"."}
                                thousandSeparator={","}
                                className={`text-input currency-input text-input-total`}
                                readOnly
                            />
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    );
};

const countValues = deductibleExpenses => {
    const expenses = Object.keys(deductibleExpenses)
        .filter(
            key => !key.includes("Yearly") && !key.includes("propertyLandTax")
        )
        .map(key => {
            const value = Number(deductibleExpenses[key]);
            return Number.isNaN(value) ? 0 : value;
        });

    const otherExpenses = (deductibleExpenses.others || [])
        .filter(otherExpense =>
            Object.keys(otherExpense).find(key => !key.includes("Yearly"))
        )
        .map(otherExpense => Number(otherExpense.value) || 0);

    return [...expenses, ...otherExpenses].reduce(
        (sum, current) => sum + current,
        0
    );
};

const countValuesYearly = deductibleExpenses => {
    const expenses = Object.keys(deductibleExpenses)
        .filter(
            key => key.includes("Yearly") && !key.includes("propertyLandTax")
        )
        .map(key => {
            const value = Number(deductibleExpenses[key]);
            return Number.isNaN(value) ? 0 : value;
        });

    const otherExpenses = (deductibleExpenses.others || [])
        .filter(otherExpense =>
            Object.keys(otherExpense).find(key => key.includes("Yearly"))
        )
        .map(otherExpense => Number(otherExpense.valueYearly) || 0);

    return [...expenses, ...otherExpenses].reduce(
        (sum, current) => sum + current,
        0
    );
};

export default DeductibleExpenses;
