/*
    Author : Marlina (marlina@devstack.com.au)
    Description : Action for file operation
*/
import Alert from 'react-s-alert';
import intl from 'react-intl-universal';
// eslint-disable-next-line import/no-extraneous-dependencies
import { fileOperationAction } from '../constants/ActionConstants';
import FileOperationApi from '../api/FileOperationApi';

export const fileOperationActions = {
    upload,
};

export function uploadFileSuccess() {
    return { type: fileOperationAction.UPLOAD_SUCCESS };
}

export function upload(file) {
    return async dispatch => {
        // api request here
        FileOperationApi.upload(file).then(response => {
            Alert.success(
                intl.get('alert.addDataSuccess', {
                    name: 'File',
                })
            );
            dispatch(uploadFileSuccess(response));
        });
    };
}
