import { isNullOrUndefined } from 'util';
import { countFromArray } from './CountFromArray';
import { totalSum } from './TotalSum';

export const personalFinanceExpensesCounter = (values, currency) => {
    if (!isNullOrUndefined(values)) {
        const model = {
            expenses: countFromArray(values, currency),
        };

        return {
            clientTotal: totalSum(model, 'clientTotal'),
            partnerTotal: totalSum(model, 'partnerTotal'),
            jointTotal: totalSum(model, 'jointTotal'),
        };
    }
    return {
        clientTotal: 0,
        partnerTotal: 0,
        jointTotal: 0,
    };
};
