/*
    Author : Marlina (marlina@devstack.com.au)
    Author : Erik (erik.malik@devstack.com.au)
    Description : Share main page (modal)
*/

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import intl from "react-intl-universal";
import { netWorthFormActions, modalWindowActions } from "../../actions";
import { modalWindow } from "../../constants/ModalWindowLists";
import NetWorthForm, {
    NetWorthPath,
    NetWorthFormPropsComposer
} from "./NetWorthForm";
import { ShareForm } from "../../forms";

class Share extends NetWorthForm {
    constructor(props) {
        super(
            props,
            intl.get("shares.title"),
            modalWindow.SHARE_FORM,
            NetWorthPath.shares,
            "Share"
        );
    }

    render() {
        const {
            optionsData,
            model,
            client,
            partner,
            isJoint,
            currency
        } = this.props;
        const { handleSave, contentWrapper, readOnlyAccessComponent } = this;

        return contentWrapper(
            <ShareForm
                handleSave={handleSave}
                model={model}
                optionsData={optionsData}
                readOnlyAccess={readOnlyAccessComponent()}
                clientInformation={{ client, partner, isJoint }}
                defaultCurrency={currency}
            />
        );
    }
}
function mapStateToProps(state) {
    return NetWorthFormPropsComposer(
        state,
        modalWindow.SHARE_FORM,
        NetWorthPath.shares
    );
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        Object.assign({}, netWorthFormActions, modalWindowActions),
        dispatch
    );
}

Share = connect(mapStateToProps, mapDispatchToProps)(Share);
export default Share;
