import React, { useContext, useState, useEffect } from "react"
import intl from "react-intl-universal"
import RenderSimpleFields, {
  FieldType
} from "../components/Form/RenderSimpleFields"
import { CountryList } from "../constants/Lists"
import ClassificationSelection from "../components/_hook/ClassificationSelection"
import { translateListLabel } from "../helpers/list-translation"
import { getSymbolFromCode } from "../helpers/CurrencyConversion"
import PropertyRentPricesInfo from "./misc/PropertyRentPricesInfo"
import { AppContext } from "../context/AppContext"
import DynamicPropertyPrice from "./misc/DynamicPropertyPrice"
import Alert from "react-s-alert"
import AssetRiskClassificationApi from "../api/AssetRiskClassificationApi"
import { NetWorthApi } from "../api"
import { Field } from "react-final-form"
import { fileFieldRender } from "../helpers"
function InvestmentPropertyMainForm({
  client,
  partner,
  isJoint,
  readOnly = false,
  optionsData,
  currency,
  values,
  selectedRentalIncome,
  setSelectedRentalIncome,
  cityList,
  currentCity,
  setFinalValue,
  setCurrencyValue,
  mutators,
  setFormAttribute
}) {
  const {
    modal: { setModal, clearModal }
  } = useContext(AppContext)

  const [cashAccounts, setCashAccounts] = useState([])
  const [productTypes, setProductTypes] = useState([])
  useEffect(() => {
    let clientId = localStorage.getItem("activeClientId")
    NetWorthApi.getClientCashAccounts(clientId).then(({ data }) => {
      setCashAccounts(data)
    })
    AssetRiskClassificationApi.getProductTypes("INVESTMENT_PROPERTY").then(
      ({ data }) => {
        if (data) {
          setProductTypes(data.data)
        }
      }
    )
  }, [])
  function getCashOptions() {
    let result = []
    if (cashAccounts) {
      cashAccounts.forEach((c) => {
        result.push(
          <option key={`enum-${c.id}`} value={c.id}>
            {c.accountNumber ? c.accountNumber : c.accountName}{" "}
            {c.primary ? "(primary)" : ""}
          </option>
        )
      })
    }
    return result
  }

  const onPropertyRentPricesClosed = (result) => {
    setSelectedRentalIncome(result)
    clearModal()
  }

  function getCountryByCode(ctyCode) {
    let result
    if (ctyCode) {
      for (let index = 0; index < CountryList.length; index++) {
        let country = CountryList[index]
        if (country.code == ctyCode) {
          result = country
          break
        }
      }
    }
    return result
  }

  const onPropertyRentPricesInfoClicked = () => {
    let selectedCountryCode =
      values && values.propertyLocation ? values.propertyLocation : ""
    let selectedCountry = getCountryByCode(selectedCountryCode)
    if (selectedCountry) {
      setModal({
        title: `${intl.get(selectedCountry.name)} Property Rent Prices`,
        content: (
          <PropertyRentPricesInfo
            onClosed={onPropertyRentPricesClosed}
            country={selectedCountry}
            mutators={mutators}
          />
        ),
        isSmall: true
      })
    } else {
      Alert.error("Please select property location first to determine cities.")
    }
  }
  const onEstimatedValueClick = () => {
    if (!currentCity) {
      Alert.error("Please fill field country and city first")
    } else {
      setModal({
        title: "Property Price",
        content: (
          <DynamicPropertyPrice
            cityList={cityList}
            currentCity={currentCity}
            setFinalValue={setFinalValue}
            setCurrencyValue={setCurrencyValue}
            isInvestmentPropertyForm={true}
            values={{
              currentValueCity: values.currentValueCity,
              propertyLocation: values.propertyLocation
            }}
            mutators={mutators}
          />
        )
      })
    }
  }

  let fields = [
    {
      name: "productTypeCode",
      label: intl.get("field.productType"),
      type: FieldType.SELECTION,
      selectionData: productTypes,
      valueProperty: "code",
      labelProperty: "name",
      required: true
    },
    {
      name: "managedBy",
      label: intl.get("field.managedBy"),
      type: FieldType.ENUM_SELECTION,
      enumData: optionsData.managedByOptions,
      required: false
    },
    {
      name: "propertyName",
      label: intl.get("field.propertyName"),
      type: FieldType.TEXT,
      required: true
    },

    {
      name: "propertyType",
      label: intl.get("field.propertyType"),
      type: FieldType.ENUM_SELECTION,
      enumData: optionsData.propertyTypeOptions
    },

    {
      name: "propertyLocation",
      label: intl.get("primaryResidencePropertyOwnership.propertyLocation"),
      type: FieldType.SELECTION,
      selectionData: translateListLabel(CountryList),
      valueProperty: "code",
      labelProperty: "name",
      required: true,
      onChange: (val) => {
        let country = getCountryByCode(val)
        if (country) {
          mutators.setFormAttribute("rentalIncome", {
            currency: country.abbreviation,
            value: "0"
          })
          mutators.setFormAttribute("currentValue", {
            currency: country.abbreviation,
            value: "0"
          })
          mutators.setFormAttribute("costBase", {
            currency: country.abbreviation,
            value: "0"
          })
        }
      }
    },

    {
      name: "currentValueCity",
      label: intl.get("primaryResidencePropertyOwnership.city"),
      type: FieldType.SELECTION,
      selectionData: cityList,
      valueProperty: "city",
      labelProperty: "city",
      required: false
    },
    {
      name: "yearPurchase",
      label: intl.get("field.yearPurchased"),
      type: FieldType.YEAR,
      // onlyYear: true,
      min: 1900,
      required: false
    },
    {
      name: "costBase",
      label: intl.get("field.costBase"),
      type: FieldType.MONEY_WITH_CURRENCY,
      required: true,
      symbolPrefix: getSymbolFromCode(currency),
      defaultCurrency: currency,
      allowNegative: false
    },

    {
      name: "currentValue",
      label: intl.get("primaryResidencePropertyOwnership.currentValue"),
      type: FieldType.MONEY_WITH_CURRENCY,
      required: true,
      symbolPrefix: getSymbolFromCode(currency),
      defaultCurrency: currency,
      required: true,
      popupPickerEvent: (e) => onEstimatedValueClick()
    },
    {
      name: "valuationDate",
      label: intl.get("field.valuationDate"),
      type: FieldType.DATE,
      required: true
    },
    {
      name: "annualGrowthRate",
      label: intl.get("field.annualGrowthRate"),
      type: FieldType.PERCENTAGE,
      required: true
    },
    {
      name: "rentalIncome",
      label: intl.get("field.rentalIncome"),
      type: FieldType.MONEY_WITH_CURRENCY,
      symbolPrefix: getSymbolFromCode(currency),
      defaultCurrency: currency,
      required: true,
      popupPickerEvent: (e) => onPropertyRentPricesInfoClicked()
    },
    {
      name: "rentalFrequency",
      label: intl.get("field.rentalFrequency"),
      type: FieldType.ENUM_SELECTION,
      required: true,

      // required: makeRequired,
      enumData: optionsData.frequencyOptions
      // readOnly: !makeRequired
    },
    {
      name: "lastRentalPaymentDate",
      label: intl.get("field.lastRentalPaymentDate"),
      type: FieldType.DATE
      // readOnly: !makeRequired
    },

    {
      name: "leaseEndDate",
      label: intl.get("field.leaseEndDate"),
      type: FieldType.DATE
    },
    {
      name: "targetCashId",
      label: intl.get("field.settlementCash"),
      type: FieldType.ENUM_SELECTION,
      enumData: getCashOptions(),
      required: true
    },
    {
      name: "propertyUnderMortgage",
      label: intl.get("field.propertyUnderAMortgage"),
      type: FieldType.CHECKBOX,
      checkboxLabel: intl.get("YesNoEnum_YES"),
      required: true
    }
  ]
  if (readOnly) {
    fields = fields
      .filter(
        (key) => key.name != "propertyUnderMortgage" //disable 'Is the property under a mortgage?'
      )
      .map((field) => ({ ...field, readOnly: true }))
  }
  return (
    // 20200915 MABT-415 toggle to show side panel
    <div className={"portal-form-half"}>
      <ClassificationSelection
        client={client}
        partner={partner}
        isJoint={isJoint}
        hideJoint={!isJoint || readOnly}
      />
      <div className="subtitle2">{intl.get("investmentProperty.subtitle")}</div>
      <RenderSimpleFields
        fields={fields}
        selectedValue={selectedRentalIncome}
      />

      <div className="portal-form-row">
        <label>{intl.get("field.propertyInsured")}</label>
        <div className="row-holder residence-insured-checkbox">
          <RenderSimpleFields
            fields={[
              {
                name: "fireAndContentInsured",
                type: FieldType.CHECKBOX,
                checkboxLabel: intl.get("field.fireAndContentInsurance"),
                isLabel: false,
                readOnly,
                required: true
              },
              {
                name: "mortgageInsured",
                type: FieldType.CHECKBOX,
                checkboxLabel: intl.get("field.loanInsurance"),
                isLabel: false,
                readOnly,
                required: true
              }
            ]}
          />
        </div>
      </div>

      {/* 20200609 - MABT-344: for Singapore CPF use only */}
      {client.taxResidency === "SG" && !values && (
        <RenderSimpleFields
          fields={[
            {
              name: "propertyOwnedWithSomeone",
              label: intl.get("field.propertyOwnedWithSomeone"),
              type: FieldType.CHECKBOX,
              checkboxLabel: intl.get("YesNoEnum_YES"),
              readOnly,
              required: true
            },
            {
              name: "propertyPurchasedWithCpf",
              label: intl.get("field.propertyPurchasedWithCpf"),
              type: FieldType.CHECKBOX,
              checkboxLabel: intl.get("YesNoEnum_YES"),
              readOnly,
              required: true
            }
          ]}
        />
      )}

      {/* 20200904 - MABT-344: for non Singapore CPF use only */}
      {client.taxResidency !== "SG" && !values && (
        <RenderSimpleFields
          fields={[
            {
              name: "propertyOwnedWithSomeone",
              label: intl.get("field.propertyOwnedWithSomeone"),
              type: FieldType.CHECKBOX,
              checkboxLabel: intl.get("YesNoEnum_YES"),
              readOnly,
              required: true
            }
          ]}
        />
      )}
      <Field
        component={fileFieldRender}
        customClassName="mt-1 d-block"
        labelIdle="Drag, Drop or Browse"
        label="Upload Documents"
        onupdatefiles={(fileItems) => {
          setFormAttribute("proofDocument", fileItems[0])
        }}
        fileInformation={(values && values.proofDocument) || null}
      />
    </div>
  )
}
export default InvestmentPropertyMainForm
