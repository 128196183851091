/*
    Author : anton.nuansantio@koizai.com
    Library : AssetRiskClassificationApi
    Description : methods related with Dealership Asset Risk Classification 
*/

import api from '../lib/api';

const BASE_ROUTE = '/asset-risk-classification';

class AssetRiskClassificationApi {

    static getLoggedEntitySetting(tradeOnly) {
        if(tradeOnly){
            return api.get(`${BASE_ROUTE}?tradeOnly=${tradeOnly}`);
        }else{
            return api.get(`${BASE_ROUTE}`);
        }
    }

    static saveSetting(o) {
        return api.post(`${BASE_ROUTE}`, o);
        
    }

    static resetFromDefault() {
        return api.get(`${BASE_ROUTE}/load-default`);
    }
  
   
    static deleteById(id) {
        return api.delete(`${BASE_ROUTE}/${id}`);
        
    }

    static getProductTypes(assetType) {
        return api.get(`${BASE_ROUTE}/product-types?type=${assetType}`);
    }

    
}

export default AssetRiskClassificationApi;
