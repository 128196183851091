import React from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';
import intl from 'react-intl-universal';
import CashFlowListing from '../../CashFlowListing';
import CashFlowHeaderListing from '../../CashFlowHeaderListing';
import { AdditionalArrayInput } from '../FieldArrayList';

const ChildrenExpenses = ({
    varPrefix,
    push,
    clientTotalValue,
    partnerTotalValue,
    jointTotalValue,
    currency,
    isJointHide,
}) => (
    <div className="portal-part">
        <Accordion accordion={false}>
            <AccordionItem>
                <AccordionItemTitle>
                    <CashFlowHeaderListing
                        title={intl.get(
                            'cashflowlisting.personalExpenses.childrenExpenses'
                        )}
                        isAccordion
                        isJointHide={isJointHide}
                        clientTotalValue={clientTotalValue}
                        partnerTotalValue={partnerTotalValue}
                        jointTotalValue={jointTotalValue}
                        currency={currency}
                    />
                </AccordionItemTitle>
                <AccordionItemBody>
                    <CashFlowListing
                        varPrefix={`${varPrefix}.allowance`}
                        isOnlyTextTitle
                        isRoundColorRed={false}
                        title={intl.get(
                            'cashflowlisting.personalExpenses.allowance'
                        )}
                        isJointHide={isJointHide}
                        defaultDeductibleValue={0}
                        currency={currency}
                    />
                    <CashFlowListing
                        varPrefix={`${varPrefix}.babySupplies`}
                        isOnlyTextTitle
                        isRoundColorRed={false}
                        title={intl.get(
                            'cashflowlisting.personalExpenses.babySupplies'
                        )}
                        isJointHide={isJointHide}
                        defaultDeductibleValue={0}
                        currency={currency}
                    />
                    <CashFlowListing
                        varPrefix={`${varPrefix}.babySitterAndDaycare`}
                        isOnlyTextTitle
                        isRoundColorRed={false}
                        title={intl.get(
                            'cashflowlisting.personalExpenses.babySitterDaycare'
                        )}
                        isJointHide={isJointHide}
                        defaultDeductibleValue={0}
                        currency={currency}
                    />
                    <CashFlowListing
                        varPrefix={`${varPrefix}.childSupport`}
                        isOnlyTextTitle
                        isRoundColorRed={false}
                        title={intl.get(
                            'cashflowlisting.personalExpenses.childSupport'
                        )}
                        isJointHide={isJointHide}
                        defaultDeductibleValue={0}
                        currency={currency}
                    />
                    <CashFlowListing
                        varPrefix={`${varPrefix}.kidsActivities`}
                        isOnlyTextTitle
                        isRoundColorRed={false}
                        title={intl.get(
                            'cashflowlisting.personalExpenses.kidsActivities'
                        )}
                        isJointHide={isJointHide}
                        defaultDeductibleValue={0}
                        currency={currency}
                    />
                    <CashFlowListing
                        varPrefix={`${varPrefix}.toys`}
                        isOnlyTextTitle
                        isRoundColorRed={false}
                        title={intl.get(
                            'cashflowlisting.personalExpenses.toys'
                        )}
                        isJointHide={isJointHide}
                        defaultDeductibleValue={0}
                        currency={currency}
                    />
                    <CashFlowListing
                        varPrefix={`${varPrefix}`}
                        title={intl.get('cashflowlisting.others')}
                        isAdditionalInput
                        isJointHide={isJointHide}
                        isShowAddButton
                        push={push}
                        additionName={`${varPrefix}.others`}
                        defaultDeductibleValue={0}
                        currency={currency}
                    />
                    <AdditionalArrayInput
                        placeholderLabel={intl.get(
                            'cashflowlisting.pleaseSpecify'
                        )}
                        AdditionalInput
                        arrayName={`${varPrefix}.others`}
                        isJointHide={isJointHide}
                        currency={currency}
                    />
                </AccordionItemBody>
            </AccordionItem>
        </Accordion>
    </div>
);

export default ChildrenExpenses;
