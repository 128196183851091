import { isNullOrUndefined } from "util";
import { getNewCurrencyValue } from "../../../helpers";

export const countFromArray = (arrayVar, currency) => {
    const result = {
        clientTotal: 0,
        partnerTotal: 0,
        jointTotal: 0,
    };

    if (!isNullOrUndefined(arrayVar) && arrayVar.length > 0) {
        const sumClient = arrayVar.reduce(
            (total, item) =>
                total +
                (item.hasOwnProperty('client') &&
                    !isNullOrUndefined(item.client)
                    ? getNewCurrencyValue(item.client, currency)
                    : 0),
            0
        );
        const sumPartner = arrayVar.reduce(
            (total, item) =>
                total +
                (item.hasOwnProperty('partner') &&
                    !isNullOrUndefined(item.partner)
                    ? getNewCurrencyValue(item.partner, currency)
                    : 0),
            0
        );
        const sumJoint = arrayVar.reduce(
            (total, item) =>
                total +
                (item.hasOwnProperty('joint') && !isNullOrUndefined(item.joint)
                    ? getNewCurrencyValue(item.joint, currency)
                    : 0),
            0
        );

        result.clientTotal += sumClient;
        result.partnerTotal += sumPartner;
        result.jointTotal += sumJoint;
    }

    return result;
};

