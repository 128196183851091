import React, { useState, useEffect } from "react"
import intl from 'react-intl-universal';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import { RenderSimpleFields } from '../../../components';
import { FieldType } from '../../../components/Form/RenderSimpleFields';
import Alert from 'react-s-alert';
import moment from 'moment'
import {
    renderTextBoxCustom,
    renderSelectEnumFieldCustom,
    renderDateFieldCustom,
    renderCurrencyCustom,
    getSymbolFromCode,
} from '../../../helpers';

const FeeTable = ({
    values,
    optionsData,
    addPlan,
    removePlan,
    setFormAttribute,
    currency
}) => {
    const [ccy, setCcy] = useState(currency?currency:localStorage.getItem("theCurrency"));
    const [ccySymbol, setCcySymbol] = useState(getSymbolFromCode(ccy))

    
    function validateNewRow(){
        let result = true;
        let feePaymentList = values.feePaymentList;
        let rowNo = 1;
        let errorText = "";
        if(feePaymentList && feePaymentList.length>0){
            let size = feePaymentList.length;    
            for (let index = 0; index < size; index++) {
                const a = feePaymentList[index];
                let rowErrors = "";
                if(!a.name){
                    if(rowErrors.length==0){
                        rowErrors = "Name";
                    }else{
                        rowErrors = rowErrors + ", " + "Name";
                    }
                }
                if(!a.amount){
                    if(rowErrors.length==0){
                        rowErrors = "Amount";
                    }else{
                        rowErrors = rowErrors + ", " + "Amount";
                    }
                }
                if(!a.frequencyEnum){
                    if(rowErrors.length==0){
                        rowErrors = "Frequency";
                    }else{
                        rowErrors = rowErrors + ", " + "Frequency";
                    }
                }
               
               
                if(rowErrors.length>0){
                    let msg = `Row-${rowNo}: ${rowErrors} required`;
                    if(errorText.length==0){
                        errorText = msg;
                    }else{
                        errorText = errorText + ", " + msg;
                    } 
                }
               
                rowNo++;
            }


        }
       
        if(errorText && errorText.length>0){
            Alert.error(`Fee ${errorText}`);
            result = false;
        }
        return result;
    }
    
    function getElementAt(searchIndex){
        let result;
        if(values && values.feePaymentList){
            for (let index = 0; index < values.feePaymentList.length; index++) {
                let element = values.feePaymentList[index];
                if(index==searchIndex){
                    result = element
                }
                
            }
        }
        return result;
    }

    function onAddButtonClicked(e){
        let feePaymentList = values && values.feePaymentList? values.feePaymentList:[];
        let size = feePaymentList && feePaymentList.length>0?feePaymentList.length:0;    
        if(validateNewRow()){
            let newModel = {
                name: `Fee-${size +1}`,
                amount: 0,
                frequencyEnum:'ONE_OFF',
                currency: ccy
            }
            addPlan('feePaymentList', newModel);
        }
       
    }

   
    return (
        <>  
            
            <div className="portal-form-row text-right">
                <span
                    className="btn btn-plus btn-plus-grey"
                    onClick={(e) => onAddButtonClicked(e)}
                />
            </div>
            <table
                className="table-blue"
                width="100%"
                cellSpacing={0}
                cellPadding={0}
                border={0}
            >
                <tbody>
                    <tr>
                        <th>{intl.get("field.name")}</th>
                        <th>{intl.get("field.frequency")}</th>
                        <th>{intl.get("field.amount")}</th>
                        <th>&nbsp;</th>
                    </tr>
                    <FieldArray name="feePaymentList">
                        {({ fields }) =>
                            fields.map((name, index) => {
                                return (
                                    <tr key={index}>
                                        <td style={{width:"50%"}} >
                                            <Field
                                                name={`${name}.name`}
                                                component={renderTextBoxCustom}
                                            />
                                        </td>
                                        <td style={{width:"20%"}} >
                                            <Field
                                                name={`${name}.frequencyEnum`}
                                                component={
                                                    renderSelectEnumFieldCustom
                                                }
                                                enumData={
                                                    optionsData.frequencyOptions
                                                }

                                            />
                                        </td>
                                        <td style={{width:"20%"}} >
                                            <Field
                                                name={`${name}.amount`}
                                                defaultCurrency={ccy}
                                                symbolPrefix= {ccySymbol}
                                                component={renderCurrencyCustom}
                                            />
                                        </td>
                                      
                                        
                                        <td style={{width:"5%"}} >
                                            <span
                                                className="btn-remove"
                                                onClick={() => {
                                                    removePlan(
                                                        'feePaymentList',
                                                        index
                                                    );
                                                }}
                                            />
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </FieldArray>
                </tbody>
            </table>
        </>
    );
};

export default FeeTable;
