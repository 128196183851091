/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Description : GEAL placeholder for singapore
*/

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import intl from 'react-intl-universal';
import { netWorthFormActions } from '../../actions/KYC/NetWorth/NetWorthFormActions';
import { modalWindowActions } from '../../actions';
import { modalWindow } from '../../constants/ModalWindowLists';
import NetWorthForm, {
    NetWorthPath,
    NetWorthFormPropsComposer,
} from './NetWorthForm';
import { GEALSingaporeForm } from '../../forms';

class GEALSingapore extends NetWorthForm {
    constructor(props) {
        super(
            props,
            intl.get('geal.title.modal', { countryDesc: 'Singapore' }),
            modalWindow.GEAL_SG_MODAL,
            NetWorthPath.singaporeGEAL,
            'GEALSingapore'
        );
    }

    render() {
        const { optionsData, model } = this.props;
        const { handleSave, contentWrapper, readOnlyAccessComponent } = this;
        const { readOnlyAccess } = readOnlyAccessComponent();

        return contentWrapper(
            <GEALSingaporeForm
                handleSave={handleSave}
                model={model}
                optionsData={optionsData}
                readOnlyAccess={readOnlyAccess}
            />
        );
    }
}

function mapStateToProps(state) {
    return NetWorthFormPropsComposer(
        state,
        modalWindow.GEAL_SG_MODAL,
        NetWorthPath.singaporeGEAL
    );
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        Object.assign({}, modalWindowActions, netWorthFormActions),
        dispatch
    );
}

GEALSingapore = connect(
    mapStateToProps,
    mapDispatchToProps
)(GEALSingapore);

export default GEALSingapore;
