import React from "react";
import { Field } from "react-final-form";
import {
    renderDateFieldCustomBootstrap,
    renderTextBoxCustomBootstrap,
    renderCurrencyCustomBootstrap,
    renderSelectEnumFieldCustomBootstrap,
    renderSelectFieldCustomBootstrap,
    renderNumberCustomBootstrap,
    renderMoneyCustomBootstrap,
    renderCheckboxCustomBootstrap,
    fileFieldRenderBootstrap,
    renderPercentageCustomBootstrap,
    renderPhoneNumberFieldCustomBootstrap,
    renderPhoneNumberSimpleFieldCustomBootstrap,
    renderPasswordCustomBootstrap,
    renderMoneyWithPercentageCustomBootstrap,
    renderColorCustom,
    renderFontColorCustom,
    renderPhoneNumber
} from "../../helpers/FormRenderBootstrap";
import { email, required, composeValidators } from "../../helpers/Validator";

export const FieldType = {
    TEXT: { name: "TEXT", getComponent: () => renderTextBoxCustomBootstrap },
    PASSWORD: {
        name: "PASSWORD",
        getComponent: () => renderPasswordCustomBootstrap
    },
    MONEY: { name: "MONEY", getComponent: () => renderCurrencyCustomBootstrap },
    MONEY_WITH_CURRENCY: {
        name: "MONEY_WITH_CURRENCY",
        getComponent: () => renderMoneyCustomBootstrap
    },
    MONEY_WITH_PERCENTAGE: {
        name: "MONEY_WITH_PERCENTAGE",
        getComponent: () => renderMoneyWithPercentageCustomBootstrap
    },
    SELECTION: {
        name: "SELECTION",
        getComponent: () => renderSelectFieldCustomBootstrap
    },
    ENUM_SELECTION: {
        name: "ENUM_SELECTION",
        getComponent: () => renderSelectEnumFieldCustomBootstrap
    },
    DATE: { name: "DATE", getComponent: () => renderDateFieldCustomBootstrap },
    NUMBER: { name: "NUMBER", getComponent: () => renderNumberCustomBootstrap },
    EMAIL: { name: "EMAIL", getComponent: () => renderTextBoxCustomBootstrap },
    CHECKBOX: {
        name: "CHECKBOX",
        getComponent: () => renderCheckboxCustomBootstrap
    },
    FILE: { name: "FILE", getComponent: () => fileFieldRenderBootstrap },
    PERCENTAGE: {
        name: "PERCENTAGE",
        getComponent: () => renderPercentageCustomBootstrap
    },
    PHONE_NUMBER: {
        name: "PHONE_NUMBER",
        getComponent: () => renderPhoneNumberFieldCustomBootstrap
    },
    PHONE_NUMBER_V2: {
        name: "PHONE_NUMBER_V2",
        getComponent: () => renderPhoneNumber
    },
    PHONE_NUMBER_SIMPLE: {
        name: "PHONE_NUMBER_SIMPLE",
        getComponent: () => renderPhoneNumberSimpleFieldCustomBootstrap
    },
    CONTAINER: {
        name: "CONTAINER"
    },
    COLOR: {
        name: "COLOR",
        getComponent: () => renderColorCustom
    },
    FONT_COLOR: {
        name: "COLOR",
        getComponent: () => renderFontColorCustom
    }
};

function DateWrapper({ item, index, children }) {
    return (
        <div className="portal-form-row dateformat" key={index}>
            <label>
                {item.label} {item.required && <span>*</span>}
            </label>
            {children}
        </div>
    );
}

function CheckboxWrapper({ item, index, children }) {
    return (
        <div className="row align-items-center" key={index}>
            <div className="col">
                <label>{item.label}</label>
            </div>
            <div className="col">{children}</div>
        </div>
    );
}

function RenderSimpleFieldsBootrap(props) {
    const { fields } = props;
    return fields.map((item, index) => {
        const {
            type,
            selectionData,
            checkboxLabel,
            required: isRequired,
            allowNegative,
            content,
            readOnly,
            ...otherProps
        } = item;

        if (type.name === FieldType.CONTAINER.name) {
            return content;
        }

        const properties = {
            key: index,
            component: type.getComponent(),
            validate: isRequired ? required : undefined,
            required: isRequired,
            isLabel: true,
            data: selectionData,
            disabled: readOnly,
            ...otherProps
        };

        let Wrapper;
        if (type.name === FieldType.DATE.name) {
            Wrapper = DateWrapper;
        } else if (type.name === FieldType.PERCENTAGE.name) {
            if (allowNegative) {
                properties.allowNegative = true;
            }
        } else if (type.name === FieldType.EMAIL.name) {
            if (isRequired) {
                properties.validate = composeValidators(required, email);
            } else {
                properties.validate = email;
            }
        } else if (type.name === FieldType.CHECKBOX.name) {
            if (item.label) {
                Wrapper = CheckboxWrapper;
            }
            properties.label = checkboxLabel || "Yes";
        }

        if (Wrapper) {
            return (
                <Wrapper item={item} key={index}>
                    <Field {...properties} />
                </Wrapper>
            );
        }
        return <Field key={index} {...properties} />;
    });
}

export default RenderSimpleFieldsBootrap;
