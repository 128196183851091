import React, { useState, useEffect } from "react"
import intl from 'react-intl-universal';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';

import Alert from 'react-s-alert';

import { getSymbolFromCode, renderNumberCustom, renderPercentageCustom } from "../../../../helpers";

const VestingTable = ({
    values,
    addPlan,
    removePlan,
    setFormAttribute,
    currency
}) => {
    const [ccy, setCcy] = useState(currency?currency:localStorage.getItem("theCurrency"));
    const [ccySymbol, setCcySymbol] = useState(getSymbolFromCode(ccy))

    
    function validateNewRow(){
        let result = true;
        let vestingScaleList = values.vestingScaleList;
        let rowNo = 1;
        let errorText = "";
        if(vestingScaleList && vestingScaleList.length>0){
            let size = vestingScaleList.length;    
            for (let index = 0; index < size; index++) {
                const a = vestingScaleList[index];
                let rowErrors = "";
                if(!a.yearsOfService){
                    if(rowErrors.length==0){
                        rowErrors = "Name";
                    }else{
                        rowErrors = rowErrors + ", " + "Name";
                    }
                }
                if(!a.percentageOfEmployerBalance){
                    if(rowErrors.length==0){
                        rowErrors = "% Ownership of Employer's Voluntary Contributions";
                    }else{
                        rowErrors = rowErrors + ", " + "Amount";
                    }
                }
              
               
               
                if(rowErrors.length>0){
                    let msg = `Row-${rowNo}: ${rowErrors} required`;
                    if(errorText.length==0){
                        errorText = msg;
                    }else{
                        errorText = errorText + ", " + msg;
                    } 
                }
               
                rowNo++;
            }


        }
       
        if(errorText && errorText.length>0){
            Alert.error(`Fee ${errorText}`);
            result = false;
        }
        return result;
    }
    
    function getElementAt(searchIndex){
        let result;
        if(values && values.vestingScaleList){
            for (let index = 0; index < values.vestingScaleList.length; index++) {
                let element = values.vestingScaleList[index];
                if(index==searchIndex){
                    result = element
                }
                
            }
        }
        return result;
    }

    function onAddButtonClicked(e){
        let vestingScaleList = values && values.vestingScaleList? values.vestingScaleList:[];
        let size = vestingScaleList && vestingScaleList.length>0?vestingScaleList.length:0;    
        if(validateNewRow()){
            let newModel = {
                name: `Fee-${size +1}`,
                amount: 0,
                frequencyEnum:'ONE_OFF',
                currency: ccy
            }
            addPlan('vestingScaleList', newModel);
        }
       
    }

   
    return (
        <>  
            
            <div className="portal-form-row text-right">
                <span
                    className="btn btn-plus btn-plus-grey"
                    onClick={(e) => onAddButtonClicked(e)}
                />
            </div>
            <table
                className="table-blue"
                width="100%"
                cellSpacing={0}
                cellPadding={0}
                border={0}
            >
                <tbody>
                    <tr>
                        <th style={{width:"50%"}}>Years of Service</th>
                        <th style={{width:"50%"}}>% Ownership of Employer's Voluntary Contributions</th>
                        <th>&nbsp;</th>
                    </tr>
                    <FieldArray name="vestingScaleList">
                        {({ fields }) =>
                            fields.map((name, index) => {
                                return (
                                    <tr key={index}>
                                        <td style={{width:"50%"}} >
                                            <Field
                                                name={`${name}.yearsOfService`}
                                                component={renderNumberCustom}
                                            />
                                        </td>
                                        
                                        <td style={{width:"20%"}} >
                                            <Field
                                                name={`${name}.percentageOfEmployerBalance`}
                                                defaultCurrency={ccy}
                                                symbolPrefix= {ccySymbol}
                                                component={renderPercentageCustom}
                                            />
                                        </td>
                                      
                                        
                                        <td style={{width:"5%"}} >
                                            <span
                                                className="btn-remove"
                                                onClick={() => {
                                                    removePlan(
                                                        'vestingScaleList',
                                                        index
                                                    );
                                                }}
                                            />
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </FieldArray>
                </tbody>
            </table>
        </>
    );
};

export default VestingTable;
