import React from "react"
import { Field, Form } from "react-final-form"
import arrayMutators from "final-form-arrays"
import intl from "react-intl-universal"
import RenderSimpleFields, {
  FieldType
} from "../../../components/Form/RenderSimpleFields"
import { FormButtons } from "../../../components"
import ClassificationSelection from "../../../components/_hook/ClassificationSelection"
import { fileFieldRender, getSymbolFromCode } from "../../../helpers"

const IncomeProtectionInsuranceForm = ({
  onSubmit,
  model,
  optionsData,
  user,
  currency
}) => (
  <Form
    onSubmit={onSubmit}
    mutators={{
      ...arrayMutators,
      setFormAttribute: ([fieldName, fieldVal], state, { changeValue }) => {
        changeValue(state, fieldName, () => fieldVal)
      }
    }}
    initialValues={model}
    render={({
      handleSubmit,
      reset,
      mutators: { push, remove, setFormAttribute },
      values
    }) => (
      <form id="collectiblesForm" onSubmit={handleSubmit}>
        <div className="portal-form-popup">
          {user.isJoint && (
            <ClassificationSelection
              client={user.client}
              partner={user.partner}
              isJoint={user.isJoint}
              hideJoint={!user.isJoint}
            />
          )}
          <div className="subtitle2">
            {intl.get(
              "insurance.title.personal.incomeProtectionInsuranceDetails"
            )}
          </div>
          <RenderSimpleFields
            fields={[
              {
                name: "policyNumber",
                label: intl.get("field.uniqueCode"),
                type: FieldType.TEXT,
                required: true
              },
              {
                name: "insuranceOrganization",
                label: intl.get("field.insuranceOrganization"),
                type: FieldType.TEXT,
                required: false
              },
              {
                name: "policyProductName",
                label: intl.get("field.policyProductName"),
                type: FieldType.TEXT,
                required: false
              },
              {
                name: "currency",
                label: intl.get("goal.property.currency"),
                type: FieldType.ENUM_SELECTION,
                enumData: optionsData.currencyOptions,
                required: true
              },
              {
                name: "managedBy",
                label: intl.get("field.managedBy"),
                type: FieldType.ENUM_SELECTION,
                enumData: optionsData.managedByOptions,
                required: false
              },

              {
                name: "policyStartDate",
                label: intl.get("field.policyStartDate"),
                type: FieldType.DATE,
                required: true
              },
              {
                name: "policyTerm",
                label: intl.get("field.policyTerm"),
                type: FieldType.NUMBER,
                numberFormat: "##",
                required: true
              },
              {
                name: "coverageType",
                label: intl.get("field.coverageType"),
                type: FieldType.ENUM_SELECTION,
                enumData: optionsData.coverageTypeOptions
              },
              {
                name: "coverageLimit.value",
                label: intl.get("field.coverageLimit"),
                type: FieldType.MONEY,
                required: false,
                defaultCurrency: currency,
                symbolPrefix: getSymbolFromCode(values.currency || currency)
              },

              {
                name: "policyPremium.value",
                label: intl.get("field.premiumAmount"),
                type: FieldType.MONEY,
                required: true,
                defaultCurrency: currency,
                symbolPrefix: getSymbolFromCode(values.currency || currency)
              },
              {
                name: "policyPremiumFrequency",
                label: intl.get("field.premiumFrequency"),
                type: FieldType.ENUM_SELECTION,
                enumData: optionsData.frequencyOptions,
                required: true
              },
              {
                name: "benefitCover.value",
                label: intl.get("field.benefitCover"),
                type: FieldType.MONEY,
                defaultCurrency: currency,
                symbolPrefix: getSymbolFromCode(values.currency || currency),
                required: true
              },
              {
                name: "benefitPeriod",
                label: intl.get("field.benefitPeriod"),
                type: FieldType.ENUM_SELECTION,
                enumData: optionsData.benefitPeriodOptions
              },
              {
                name: "waitingPeriod",
                label: intl.get("field.waitingPeriod"),
                type: FieldType.NUMBER
              },

              {
                name: "cpiAdjusted",
                label: intl.get("field.cpiAdjusted"),
                type: FieldType.CHECKBOX
              }
            ]}
          />
          <Field
            component={fileFieldRender}
            customClassName="mt-1 d-block"
            labelIdle="Drag, Drop or Browse"
            label="Upload Documents"
            onupdatefiles={(fileItems) => {
              setFormAttribute("proofDocument", fileItems[0])
            }}
            fileInformation={(model && model.proofDocument) || null}
          />

          <FormButtons reset={reset} />
        </div>
      </form>
    )}
  />
)

export default IncomeProtectionInsuranceForm
