import React from "react";
import ReactModal from "react-modal";
import intl from "react-intl-universal";

const ConfirmationDialog = ({ isOpen, message, onClose, onYes }) => (
    <ReactModal
        style={{
            overlay: { zIndex: 100000 }
        }}
        isOpen={isOpen}
        className="malabar-modal"
        overlayClassName="malabar-modal-overlay"
        ariaHideApp={false}
        shouldCloseOnEsc
        onRequestClose={onClose}
    >
        <div className="popup">
            <div className="container-fluid">
                <div className="container">
                    <div className="page-title">
                        {intl.get("Confirmation")}
                        <div className="close-holder">
                            <span onClick={onClose} className="close-button" />
                        </div>
                    </div>
                    <div className="portal-form-popup">{message}</div>
                    <div className="portal-btn-popup">
                        <div className="col-half">
                            <input
                                className="portal-btn portal-btn-cancel ml-2"
                                value={intl.get("YesNoEnum_NO")}
                                type="button"
                                onClick={onClose}
                            />
                        </div>
                        <div className="col-half">
                            <input
                                className="portal-btn portal-btn-submit"
                                value={intl.get("YesNoEnum_YES")}
                                type="submit"
                                onClick={() => {
                                    onYes();
                                    onClose();
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ReactModal>
);

export default ConfirmationDialog;
