import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import intl from "react-intl-universal";
import NumberFormat from "react-number-format";

import { getSymbolFromCode } from "../../helpers";
// import { DECIMAL_SCALE } from '../../constants/NumberFormatConstants';
import {
    DefaultBarChartOptions
    // DefaultLegendOptions,
    // BarLegendsForcedLeftOptions,
} from "../../helpers/ChartHelper";
// import { commonColors } from '../../helpers/ColorHelper';
import { numberWithCommas } from "../../helpers/CurrencyConversion";


const CashflowGraphPanel = ({
    rawData: { personalIncome, personalExpense, investmentOutflow },
    currency,
    isPartner = false
}) => {    const CASHFLOW_DECIMAL_SCALE = 0; // 20200528
    const [data, setData] = useState({});
    const currencySymbol = getSymbolFromCode(currency);
    const [chartData, setChartData] = useState({
        income: personalIncome.client.totalMonthly,
        expense: personalExpense.client.totalMonthly,
        outflow: investmentOutflow.client.totalMonthly,
        saving:
            personalIncome.client.totalMonthly -
            personalExpense.client.totalMonthly -
            investmentOutflow.client.totalMonthly
    });
    const [selectedOption, setSelectedOption] = useState("client");

    useEffect(() => {
        

        setChartDataFromOption(selectedOption);
    }, [personalIncome, personalExpense, investmentOutflow]);

    useEffect(() => {
        setData(renderChartData(chartData));
    }, [chartData]);

    // const { red, green, blue } = commonColors();
    const renderChartData = cashflowData => {
        const { income, expense, outflow, saving } = cashflowData;

        return {
            labels: [
                intl.get("cashflowchart.incomes"),
                intl.get("cashflowchart.expenses"),
                intl.get("cashflowchart.outflow"),
                intl.get("cashflowchart.surplusDeficit")
            ],
            // 20200528
            datasets: [
                {
                    label: intl.get("cashflowchart.incomes"),
                    backgroundColor:
                        income >= 0
                            ? "rgb(69,114,199)" // blue
                            : "rgba(242,46,46)", // red
                    // borderColor:
                    //     income >= 0
                    //         ? 'rgba(90, 155, 211,1)'
                    //         : 'rgba(248, 2, 7,1)',
                    borderWidth: 1,
                    stack: "1",
                    // hoverBackgroundColor:
                    //     income >= 0
                    //         ? 'rgb(69,114,199)' // blue
                    //         : 'rgba(242,46,46)', // red
                    // hoverBorderColor:
                    //     income >= 0
                    //         ? 'rgba(90, 155, 211,1)'
                    //         : 'rgba(248, 2, 7,1)',
                    data: [income, 0, 0, 0]
                },
                {
                    label: intl.get("cashflowchart.expenses"),
                    backgroundColor: "rgb(242,46,46)",
                    // expense >= 0
                    //     ? 'rgb(69,114,199)' // blue
                    //     : 'rgba(242,46,46)', // red
                    // borderColor:
                    //     expense >= 0
                    //         ? 'rgba(90, 155, 211,1)'
                    //         : 'rgba(248, 2, 7,1)',
                    borderWidth: 1,
                    stack: "1",
                    // hoverBackgroundColor:'rgba(242,46,46)',
                    // expense >= 0
                    //     ? 'rgb(69,114,199)' // blue
                    //     : 'rgba(242,46,46)', // red
                    // hoverBorderColor:
                    //     expense >= 0
                    //         ? 'rgba(90, 155, 211,1)'
                    //         : 'rgba(248, 2, 7,1)',
                    data: [0, expense, 0, 0]
                },
                {
                    label: intl.get("cashflowchart.outflow"),
                    backgroundColor: "rgba(242,46,46)",
                    // outflow >= 0
                    //     ? 'rgb(69,114,199)' // blue
                    //     : 'rgba(242,46,46)', // red
                    // borderColor:
                    //     outflow >= 0
                    //         ? 'rgba(90, 155, 211,1)'
                    //         : 'rgba(248, 2, 7,1)',
                    borderWidth: 1,
                    stack: "1",
                    // hoverBackgroundColor:
                    //     outflow >= 0
                    //         ? 'rgb(69,114,199)' // blue
                    //         : 'rgba(242,46,46)', // red
                    // hoverBorderColor:
                    //     outflow >= 0
                    //         ? 'rgba(90, 155, 211,1)'
                    //         : 'rgba(248, 2, 7,1)',
                    data: [0, 0, outflow, 0]
                },
                {
                    label: intl.get("cashflowchart.surplusDeficit"),
                    backgroundColor:
                        saving >= 0
                            ? "rgb(69,114,199)" // blue
                            : "rgba(242,46,46)", // red
                    // borderColor:
                    //     saving >= 0
                    //         ? 'rgba(90, 155, 211,1)'
                    //         : 'rgba(248, 2, 7,1)',
                    borderWidth: 1,
                    stack: "1",
                    // hoverBackgroundColor:
                    //     saving >= 0
                    //         ? 'rgb(69,114,199)' // blue
                    //         : 'rgba(242,46,46)', // red
                    // hoverBorderColor:
                    //     saving >= 0
                    //         ? 'rgba(90, 155, 211,1)'
                    //         : 'rgba(248, 2, 7,1)',
                    data: [0, 0, 0, saving]
                }
            ]
        };
    };

    const handleChartOptionChange = e => {
        const option = e.target.value;
        setChartDataFromOption(option);
    };

    // 20200529
    function number_format(value, currency) {
        return ` ${getSymbolFromCode(currency)}${numberWithCommas(
            Math.ceil(value)
        )}`;
    }

    const setChartDataFromOption = option => {
        switch (option) {
            case "overview":
                {
                    const newIncome =
                        personalIncome.client.totalMonthly +
                        personalIncome.partner.totalMonthly +
                        personalIncome.joint.totalMonthly;
                    const newExpense =
                        personalExpense.client.totalMonthly +
                        personalExpense.partner.totalMonthly +
                        personalExpense.joint.totalMonthly;

                    const newOutflow =
                        investmentOutflow.client.totalMonthly +
                        investmentOutflow.partner.totalMonthly +
                        investmentOutflow.joint.totalMonthly;
                    setChartData({
                        income: newIncome,
                        expense: newExpense,
                        outflow: newOutflow,
                        saving: newIncome - newExpense - newOutflow
                    });
                    setSelectedOption(option);
                }
                break;
            case "client":
                // 20200528
                setChartData({
                    income: personalIncome.client.totalMonthly,
                    expense: personalExpense.client.totalMonthly,
                    outflow: investmentOutflow.client.totalMonthly,
                    saving:
                        personalIncome.client.totalMonthly -
                        personalExpense.client.totalMonthly -
                        investmentOutflow.client.totalMonthly
                });
                setSelectedOption(option);
                break;
            case "partner":
                setChartData({
                    income: personalIncome.partner.totalMonthly,
                    expense: personalExpense.partner.totalMonthly,
                    outflow: investmentOutflow.partner.totalMonthly,
                    saving:
                        personalIncome.partner.totalMonthly -
                        personalExpense.partner.totalMonthly -
                        investmentOutflow.partner.totalMonthly
                });
                setSelectedOption(option);
                break;

            default:
                break;
        }
    };

    // 20200817 MABT-394
    let amount = 0;

    return (
      <div className="graph-panel graph-panel-cashFlowGraphPanel">
        <div className="title text-center">
          {intl.get("currentStanding.cashflow.title")}
        </div>
        <div className="select-box">
          <select value={selectedOption} onChange={handleChartOptionChange}>
            {isPartner && (
              <option value="overview">
                {intl.get("cashflowchart.overview")}
              </option>
            )}
            <option value="client">{intl.get("cashflowchart.client")}</option>
            {isPartner && (
              <option value="partner">
                {intl.get("cashflowchart.partner")}
              </option>
            )}
          </select>
        </div>

        <div className="graph-holder">
          {/* 20200529 */}
          <Bar
            data={data}
            // width={100}
            height={300}
            options={{
              ...DefaultBarChartOptions,
              maintainAspectRatio: false,
              animation: false,
              responsive: true,
              responsiveAnimationDuration: 0,
              tooltips: {
                callbacks: {
                  label(tooltipItem, data) {
                    return number_format(
                      data.datasets[tooltipItem.datasetIndex].data[
                        tooltipItem.index
                      ].toFixed(0),
                      currency
                    )
                  }
                }
              },

              scales: {
                xAxes: [
                  {
                    gridLines: {
                      display: true,
                      drawBorder: true,
                      drawOnChartArea: false
                    }
                  }
                ],

                yAxes: [
                  {
                    gridLines: {
                      display: true,
                      drawBorder: true,
                      drawOnChartArea: false
                    },
                    ticks: {
                      beginAtZero: true,
                      callback(value) {
                        // if (
                        //     parseInt(value) >= 1000 ||
                        //     parseInt(value) <= -1000
                        // ) {
                        // value = value <=1 ? value*1000 : value;
                        amount = `${value
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`

                        return getSymbolFromCode(currency) + amount
                        // }
                        // return getSymbolFromCode(currency)+`${value*1000}`;
                      }
                    }
                    // ticks: {
                    //     beginAtZero: true,
                    //     callback(value) {
                    //         if (
                    //             parseInt(value) >= 1000 ||
                    //             parseInt(value) <= -1000
                    //         ) {
                    //             let amount = `${value
                    //                 .toString()
                    //                 .replace(
                    //                     /\B(?=(\d{3})+(?!\d))/g,
                    //                     ','
                    //                 )}`;

                    //             return getSymbolFromCode(currency)+amount;
                    //         }
                    //     },
                    // },
                  }
                ]
              },

              // legend: {
              //     ...DefaultLegendOptions,
              //     ...BarLegendsForcedLeftOptions,
              // },
              legend: false
            }}
          />
        </div>
        <div className="graph-summary">
          <div className="subtitle">
            {intl.get("cashflowchart.cashflowSummary")}
          </div>
          <div className="g-summary-box">
            <div className="text">{intl.get("cashflowchart.incomes")}</div>
            <div className="figure">
              <NumberFormat
                value={
                  chartData && chartData.income
                    ? chartData.income.toFixed(2)
                    : 0
                }
                displayType={"text"}
                thousandSeparator
                prefix={currencySymbol}
                decimalScale={CASHFLOW_DECIMAL_SCALE}
              />
            </div>
          </div>
          <div className="g-summary-box">
            <div className="text">{intl.get("cashflowchart.expenses")}</div>
            <div className="figure">
              <NumberFormat
                value={
                  chartData && chartData.expense
                    ? chartData.expense.toFixed(2)
                    : 0
                }
                displayType={"text"}
                thousandSeparator
                prefix={currencySymbol}
                decimalScale={CASHFLOW_DECIMAL_SCALE}
              />
            </div>
          </div>
          <div className="g-summary-box">
            <div className="text">{intl.get("cashflowchart.savings")}</div>
            <div className="figure">
              <NumberFormat
                value={
                  chartData && chartData.outflow
                    ? chartData.outflow.toFixed(2)
                    : 0
                }
                displayType={"text"}
                thousandSeparator
                prefix={currencySymbol}
                decimalScale={CASHFLOW_DECIMAL_SCALE}
              />
            </div>
          </div>
          <div className="g-summary-box">
            <div className="text">
              {intl.get("cashflowchart.surplusDeficit")}
            </div>
            <div className="figure">
              <NumberFormat
                value={
                  chartData
                    ? (chartData.income ? chartData.income.toFixed(2) : 0) -
                      (chartData.expense ? chartData.expense.toFixed(2) : 0) -
                      (chartData.outflow ? chartData.outflow.toFixed(2) : 0)
                    : 0
                }
                displayType={"text"}
                thousandSeparator
                prefix={currencySymbol}
                decimalScale={CASHFLOW_DECIMAL_SCALE}
              />
            </div>
          </div>
        </div>
      </div>
    )
};

export default CashflowGraphPanel;
