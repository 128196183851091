/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Description : custom component for final-form
*/

import React, { useState } from "react"
import { FormText } from "reactstrap"
import intl from "react-intl-universal"
import NumberFormat from "react-number-format"
import Datetime from "react-datetime"
import moment from "moment"
import {
  MalabarDate,
  MalabarMonthYearAge,
  MalabarYearAge,
  MalabarEmail,
  MalabarPhoneNumber,
  MalabarPhoneNumberSimple,
  MalabarCheckbox,
  MalabarMoney,
  MalabarMoneyCashflow,
  JointSelectionMenu,
  FeedbackSelectionMenu,
  Identity
} from "../components"

import "react-datetime/css/react-datetime.css"
import MalabarMoneyWithPercentage from "../components/MalabarMoney/MalabarMoneyWithPercentage"
import {
  DECIMAL_SCALE,
  PERCENT_SCALE
} from "../constants/NumberFormatConstants"
import { checkDate } from "./Validator"
import PhoneInput from "react-phone-input-2"
import { ChevronDownIcon } from "../assets/images/new-skin-images"
import CustomFilePond from "../components/FileUploader/CustomFilePond"
import { BASE_URL } from "../lib/api"
import { FaCheck, FaClock, FaCross } from "react-icons/fa"
import Tooltip from "rc-tooltip"

const maxFileSize = process.env.REACT_APP_MAX_FILE_SIZE

export const renderPhoneNumber = ({
  input,
  required,
  isLabel,
  label,
  preferred,
  disabled,
  isForGrid,
  onRemoveDataClick,
  isFullWidth,
  meta: { touched, error }
}) => {
  const cssHolder = isForGrid
    ? ""
    : isFullWidth
    ? "row-holder-full"
    : "row-holder"

  return (
    <div className="portal-form-row">
      <label htmlFor={input.name}>
        {label} {required && <span style={{ color: "red" }}>*</span>}
      </label>
      <div
        className={
          touched
            ? error
              ? `${cssHolder} itemvalidate bad`
              : `${cssHolder} `
            : `${cssHolder} `
        }
      >
        <PhoneInput
          {...input}
          inputProps={{
            name: input.name,
            required: required,
            autoFocus: true
          }}
        />
        {touched && error && <span className="alert">{error}</span>}
      </div>
    </div>
  )
}
export const renderTextBoxField = ({
  input,
  disabled,
  label,
  type,
  meta: { touched, error },
  required = false
}) => (
  <div className="portal-form-row">
    <label htmlFor={input.name}>
      {label} {required && <span>*</span>}
    </label>
    <div className={`row-holder ${touched && error && "itemvalidate bad"}`}>
      <input
        {...input}
        disabled={disabled}
        placeholder={label}
        type={type}
        className={
          touched
            ? error
              ? "is-invalid portal-text-input text-input"
              : "portal-text-input text-input"
            : "portal-text-input text-input"
        }
      />
      {/* {touched && ((error && <FormText color="red">{error}</FormText>) || (warning && <FormText color="yellow">{warning}</FormText>))} */}
      {touched && error && <span className="alert">required</span>}
    </div>
  </div>
)

export const renderTextBoxFieldColumn = ({
  input,
  disabled,
  label,
  type,
  id,
  meta: { touched, error },
  show,
  togglePassword
}) => (
  <div className="d-flex flex-column row-holder-outer ">
    <label htmlFor={input.name}>{label}</label>

    <div
      className={`${
        touched && error ? "row-holder itemvalidate bad" : ""
      }  row-holder-inner`}
    >
      <input
        {...input}
        id={id}
        disabled={disabled}
        placeholder={label}
        type={type}
        className={
          touched
            ? error
              ? "is-invalid portal-text-input text-input"
              : "portal-text-input text-input"
            : "portal-text-input text-input"
        }
      />
      {type === "password" && (
        <img
          className={`password-toggle eye-icon ${
            touched && error ? "push-upward" : ""
          }`}
          id="eyeIcon"
          onClick={togglePassword}
          src={
            show
              ? `${process.env.PUBLIC_URL}/assets/images/eyeHidden.png`
              : `${process.env.PUBLIC_URL}/assets/images/eyeIcon.png`
          }
        ></img>
      )}

      {touched && error && <span className="alert">required</span>}
    </div>
  </div>
)
export const renderSelectFieldColumn = ({
  input,
  label,
  disabled = false,
  children,
  data,
  valueProperty = "value",
  labelProperty = "label",
  onChange = null,
  meta: { touched, error },
  hideFirstEmptyOption = false
}) => {
  const primaryGroup = data ? data.filter((x) => x.isPrimary) : []
  const secondaryGroup = data ? data.filter((x) => !x.isPrimary) : []

  const SelectionOptions = data ? (
    <>
      {primaryGroup.length > 0 && (
        <>
          <optgroup>
            {primaryGroup.map((item, index) => (
              <option key={`${index}select`} value={item[valueProperty]}>
                {item[labelProperty]}
              </option>
            ))}
          </optgroup>
          <optgroup label="-------------------------">
            {secondaryGroup.map((item, index) => (
              <option key={`${index}select`} value={item[valueProperty]}>
                {item[labelProperty]}
              </option>
            ))}
          </optgroup>
        </>
      )}
      {primaryGroup.length === 0 &&
        secondaryGroup.map((item, index) => (
          <option key={`${index}select`} value={item[valueProperty]}>
            {item[labelProperty]}
          </option>
        ))}
    </>
  ) : (
    children
  )

  return (
    <div className="row-holder-outer d-flex flex-column">
      <label htmlFor={input.name}>{label}</label>

      <div
        className={`${
          touched && error ? "row-holder itemvalidate bad" : ""
        }  row-holder-inner`}
      >
        {/* <div className="select-box "> */}
        <select
          style={{
            backgroundImage: `url(${ChevronDownIcon})`
          }}
          className={
            touched
              ? error
                ? "is-invalid portal-text-input text-input"
                : "portal-text-input text-input"
              : "portal-text-input text-input"
          }
          {...input}
          onChange={(val) => {
            if (onChange !== null) {
              onChange(val.target.value)
            }
            input.onChange(val.target.value)
          }}
          disabled={disabled}
        >
          {!hideFirstEmptyOption && <option />}
          {/* <option>Select an Entity</option> */}
          {SelectionOptions}
        </select>
        {/* </div> */}

        {touched && error && <span className="alert">required</span>}
      </div>
    </div>
  )
}

export const renderPickerFieldSelection = ({
  placeholderLabel,
  input,
  required,
  label,
  disabled = false,
  meta: { touched, error },
  cssHolder = "",
  table,
  selectedName
}) => {
  const [hide, setHide] = useState(!selectedName)
  return (
    <div className="portal-form-row">
      <label>
        {label} {required && <span>*</span>}
      </label>
      <div
        className={
          touched
            ? error
              ? `${cssHolder}itemvalidate bad`
              : cssHolder
            : cssHolder
        }
      >
        <Tooltip
          zIndex={9999}
          showArrow={true}
          placement="right"
          overlay={
            <div className="cover" style={{ color: "black" }}>
              {table}
            </div>
          }
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          <input
            className="text-input"
            value={selectedName}
            placeholder={placeholderLabel}
            readOnly={true}
          />
        </Tooltip>
      </div>
    </div>
  )
}

export const renderSelectFieldCustom = ({
  input,
  required,
  label,
  disabled = false,
  children,
  data,
  // classIs,
  valueProperty = "value",
  labelProperty = "label",
  isLabel,
  isForGrid,
  isRowHolder,
  classInput,
  onRemoveDataClick,
  onChange = null,
  meta: { touched, error },
  hideFirstEmptyOption = false,
  showAlert
}) => {
  const cssHolder = isForGrid ? "" : "row-holder "
  const divRowHolder = isRowHolder

  const primaryGroup = data ? data.filter((x) => x.isPrimary) : []
  const secondaryGroup = data ? data.filter((x) => !x.isPrimary) : []

  const getOptionLabel = (item) => {
    let result = ""
    if (labelProperty) {
      labelProperty = labelProperty.trim()
      if (labelProperty.indexOf(",") > -1) {
        let arrSplit = labelProperty.split(",")
        arrSplit.forEach((el) => {
          el = el.trim()
          let opt = item[el]
          if (opt && opt.length == 0) {
            opt = "[none]"
          }
          if (result.length == 0) {
            result = opt
          } else {
            result = result + " - " + opt
          }
        })
      } else {
        result = item[labelProperty]
      }
    }

    return result
  }

  const SelectionOptions = data ? (
    <>
      {primaryGroup.length > 0 && (
        <>
          <optgroup>
            {primaryGroup.map((item, index) => (
              <option key={`${index}select`} value={item[valueProperty]}>
                {getOptionLabel(item)}
              </option>
            ))}
          </optgroup>
          <optgroup label="-------------------------">
            {secondaryGroup.map((item, index) => (
              <option key={`${index}select`} value={item[valueProperty]}>
                {item[labelProperty]}
              </option>
            ))}
          </optgroup>
        </>
      )}
      {primaryGroup.length === 0 &&
        secondaryGroup.map((item, index) => (
          <option key={`${index}select`} value={item[valueProperty]}>
            {getOptionLabel(item)}
          </option>
        ))}
    </>
  ) : (
    children
  )
  return (
    <React.Fragment>
      {isLabel ? (
        <div className="portal-form-row">
          <label>
            {label} {required && <span>*</span>}
          </label>
          <div
            className={
              touched
                ? error
                  ? `${cssHolder}itemvalidate bad`
                  : cssHolder
                : cssHolder
            }
          >
            <div className="select-box">
              <select
                // className={classInput}
                {...input}
                onChange={(val) => {
                  if (onChange !== null) {
                    onChange(val.target.value)
                  }
                  input.onChange(val.target.value)
                }}
                disabled={disabled}
              >
                {!hideFirstEmptyOption && <option />}
                {SelectionOptions}
              </select>
            </div>
            {touched && error && <div className="alert">{error}</div>}
          </div>
        </div>
      ) : divRowHolder ? (
        <div className={touched ? (error ? "itemvalidate bad" : "") : ""}>
          <div className="select-box">
            <select
              {...input}
              onChange={(val) => {
                if (onChange !== null) {
                  onChange(val.target.value)
                }
                input.onChange(val.target.value)
              }}
              disabled={disabled}
            >
              <option />
              {SelectionOptions}
            </select>
          </div>
          <div className="btn-holder">
            {" "}
            <span onClick={onRemoveDataClick} className="btn-remove" />
          </div>
          {touched && error && (
            <div className="alert otheraddress-alert">{error}</div>
          )}
        </div>
      ) : (
        <div
          className={
            touched
              ? error
                ? `${cssHolder}itemvalidate ${showAlert == false ? "" : "bad"}`
                : cssHolder
              : cssHolder
          }
        >
          <div className="select-box">
            <select
              className={classInput}
              {...input}
              onChange={(val) => {
                if (onChange !== null) {
                  onChange(val.target.value)
                }
                input.onChange(val.target.value)
              }}
              disabled={disabled}
            >
              <option />
              {SelectionOptions}
            </select>
          </div>
          {touched && error && <div className="alert">{error}</div>}
        </div>
      )}
    </React.Fragment>
  )
}

export const renderSelectEnumFieldCustom = (props) => {
  const {
    input,
    required,
    disabled = false,
    label,
    enumData,
    isLabel,
    isForGrid,
    isRowHolder,
    isNotUpperDiv,
    onRemoveDataClick,
    onChange,
    meta: { touched, error },
    classNames,
    showAsText
  } = props
  const cssHolder = isForGrid ? "" : `row-holder d-flex flex-column `
  const divRowHolder = isRowHolder
  if (showAsText) {
    const renderedEnum = enumData.find((x) => x.props.value === input.value)
    return (
      <div className={`portal-form-row ${classNames}`}>
        <label>
          {label} {required && <span>*</span>}
        </label>
        <div
          className={
            touched
              ? error
                ? `${cssHolder}itemvalidate bad`
                : cssHolder
              : cssHolder
          }
        >
          <input
            value={renderedEnum.props.children}
            disabled
            className="text-input"
          />
          {touched && error && <div className="alert">{error}</div>}
        </div>
      </div>
    )
  }

  if (isLabel) {
    return (
      <div className={`portal-form-row ${classNames}`}>
        <label>
          {label} {required && <span>*</span>}
        </label>
        <div
          className={
            touched
              ? error
                ? `${cssHolder}itemvalidate bad`
                : cssHolder
              : cssHolder
          }
        >
          <div className="select-box">
            <select
              {...input}
              onChange={(val) => {
                if (onChange !== null) {
                  if (onChange) {
                    onChange(val.target.value)
                  }
                  input.onChange(val.target.value)
                }
              }}
              disabled={disabled}
            >
              <option />
              {enumData}
            </select>
          </div>
          {touched && error && <div className="alert">{error}</div>}
        </div>
      </div>
    )
  }

  if (divRowHolder) {
    return (
      <div className={touched ? (error ? "itemvalidate bad" : "") : ""}>
        <div className="select-box">
          <select
            {...input}
            onChange={(val) => {
              if (onChange !== null) {
                if (onChange) {
                  onChange(val.target.value)
                }
                input.onChange(val.target.value)
              }
            }}
            disabled={disabled}
          >
            <option />
            {enumData}
          </select>
        </div>
        <div className="btn-holder">
          {" "}
          <span onClick={onRemoveDataClick} className="btn-remove" />
        </div>
        {touched && error && (
          <div className="alert otheraddress-alert">{error}</div>
        )}
      </div>
    )
  }

  if (isNotUpperDiv) {
    return (
      <div className="select-box">
        <select
          {...input}
          onChange={(val) => {
            if (onChange !== null) {
              if (onChange) {
                onChange(val.target.value)
              }
              input.onChange(val.target.value)
            }
          }}
          disabled={disabled}
        >
          <option />
          {enumData}
        </select>
      </div>
    )
  }

  return (
    <div
      className={
        touched
          ? error
            ? `${cssHolder}itemvalidate bad`
            : cssHolder
          : cssHolder
      }
    >
      <div className="select-box">
        <select
          {...input}
          onChange={(val) => {
            if (onChange !== null) {
              if (onChange) {
                onChange(val.target.value)
              }
              input.onChange(val.target.value)
            }
          }}
          disabled={disabled}
        >
          <option />
          {enumData}
        </select>
      </div>
      {touched && error && <div className="alert">{error}</div>}
    </div>
  )
}

export const renderSelectEnumListFieldCustom = ({
  input,
  required,
  disabled = false,
  label,
  enumData,
  isLabel,
  isForGrid,
  isRowHolder,
  onRemoveDataClick,
  // onScoreChange,
  onChange,
  meta: { touched, error },
  classNames
}) => {
  const cssHolder = isForGrid ? "" : "row-holder "
  const divRowHolder = isRowHolder

  return (
    <React.Fragment>
      {isLabel ? (
        <div className={`portal-form-row ${classNames}`}>
          <label>
            {label} {required && <span>*</span>}
          </label>
          <div
            className={
              touched
                ? error
                  ? `${cssHolder}itemvalidate bad`
                  : cssHolder
                : cssHolder
            }
          >
            <div className="select-box">
              <select
                {...input}
                onChange={(val) => {
                  // onScoreChange(
                  //     target.selectedOptions[0].getAttribute(
                  //         'data-score'
                  //     )
                  // );
                  if (onChange !== null) {
                    input.onChange(val.target.value)
                  }
                }}
                disabled={disabled}
              >
                <option />
                {enumData}
              </select>
            </div>
            {touched && error && <div className="alert">{error}</div>}
          </div>
        </div>
      ) : divRowHolder ? (
        <div className={touched ? (error ? "itemvalidate bad" : "") : ""}>
          <div className="select-box">
            <select
              {...input}
              onChange={(val) => {
                // onScoreChange(
                //     target.selectedOptions[0].getAttribute(
                //         'data-score'
                //     )
                // );
                if (onChange !== null) {
                  input.onChange(val.target.value)
                }
              }}
              disabled={disabled}
            >
              <option />
              {enumData}
            </select>
          </div>
          <div className="btn-holder">
            {" "}
            <span onClick={onRemoveDataClick} className="btn-remove" />
          </div>
          {touched && error && (
            <div className="alert otheraddress-alert">{error}</div>
          )}
        </div>
      ) : (
        <div
          className={
            touched
              ? error
                ? `${cssHolder}itemvalidate bad`
                : cssHolder
              : cssHolder
          }
        >
          <div className="select-box">
            <select
              {...input}
              onChange={(val) => {
                // onScoreChange(
                //     target.selectedOptions[0].getAttribute(
                //         'data-score'
                //     )
                // );
                if (onChange !== null) {
                  input.onChange(val.target.value)
                }
              }}
              disabled={disabled}
            >
              <option />

              {enumData}
            </select>
          </div>
          {touched && error && <div className="alert">{error}</div>}
        </div>
      )}
    </React.Fragment>
  )
}

export const renderSelectFieldArrayCustom = ({
  input,
  label,
  isLabel,
  children,
  disabled = false,
  data,
  valueProperty,
  labelProperty,
  isAddButton,
  onAddButtonClick,
  onRemoveButtonClick,
  meta: { touched, error }
}) => {
  const cssHolder = "row-holder"
  const primaryGroup = data ? data.filter((x) => x.isPrimary) : []

  const secondaryGroup = data ? data.filter((x) => !x.isPrimary) : []

  const SelectionOptions = data ? (
    <>
      {primaryGroup.length > 0 && (
        <>
          <optgroup>
            {primaryGroup.map((item, index) => (
              <option key={`${index}select`} value={item[valueProperty]}>
                {item[labelProperty]}
              </option>
            ))}
          </optgroup>
          <optgroup label="-------------------------">
            {secondaryGroup.map((item, index) => (
              <option key={`${index}select`} value={item[valueProperty]}>
                {item[labelProperty]}
              </option>
            ))}
          </optgroup>
        </>
      )}
      {primaryGroup.length === 0 &&
        secondaryGroup.map((item, index) => (
          <option key={`${index}select`} value={item[valueProperty]}>
            {item[labelProperty]}
          </option>
        ))}
    </>
  ) : (
    children
  )

  return (
    <React.Fragment>
      {isLabel && <label>{label}</label>}
      <div
        className={
          touched
            ? error
              ? `${cssHolder} itemvalidate bad`
              : cssHolder
            : cssHolder
        }
      >
        <div className="where-grid col">
          {" "}
          {intl.get("field.where")} :
          <div className="select-box">
            {/* <select {...input} disabled={disabled}>
                            <option />
                            {data
                                ? data.map((item, index) => (
                                      <option
                                          key={`${index}select`}
                                          value={item[valueProperty]}
                                      >
                                          {item[labelProperty]}
                                      </option>
                                  ))
                                : children}
                        </select> */}
            <select
              {...input}
              // onChange={val => {
              //     if (onChange !== null) {
              //         onChange(val.target.value);
              //     }
              //     input.onChange(val.target.value);
              // }}
              disabled={disabled}
            >
              <option />
              {SelectionOptions}
            </select>
          </div>
          <div className="btn-holder">
            {isAddButton ? (
              <span onClick={onAddButtonClick} className="btn btn-plus" />
            ) : (
              <span onClick={onRemoveButtonClick} className="btn-remove" />
            )}
          </div>
        </div>
        {touched && error && <div className="alert">{error}</div>}
      </div>
    </React.Fragment>
  )
}

export const renderCheckboxCustom = ({
  input,
  disabled,
  label,
  isForGrid,
  checked,
  onChange = null,
  meta: { touched, error, warning }
}) => (
  <div className={isForGrid ? "" : "row-holder"}>
    <div className="input-check input-check-alert">
      <MalabarCheckbox
        {...input}
        name={input.name}
        label={label}
        checked={checked || input.value}
        disabled={disabled}
        onChange={(isInputChecked) => {
          if (onChange !== null) {
            onChange(isInputChecked)
          }
        }}
        onToggle={(isInputChecked) => input.onChange(isInputChecked)}
      />

      {touched &&
        ((error && (
          <FormText color="red" className="alert-validate">
            {error}
          </FormText>
        )) ||
          (warning && <FormText color="yellow">{warning}</FormText>))}
    </div>
  </div>
)

export const renderCheckboxRememberMe = ({
  input,
  disabled,
  label,
  isForGrid,
  onChange = null,
  checked = false,
  meta: { touched, error, warning }
}) => (
  <div className={`${isForGrid ? "" : "row-holder"} remember-me_outer`}>
    <div className="input-check input-check-alert">
      {typeof input.value == "string" && checked && (
        <MalabarCheckbox
          {...input}
          name={input.name}
          label={label}
          checked={true}
          disabled={disabled}
          onChange={(isInputChecked) => {
            if (onChange !== null) {
              onChange(isInputChecked)
            }
          }}
          onToggle={(isInputChecked) => input.onChange(isInputChecked)}
        />
      )}

      {typeof input.value == "string" && !checked && (
        <MalabarCheckbox
          {...input}
          name={input.name}
          label={label}
          checked={true}
          disabled={disabled}
          onChange={(isInputChecked) => {
            checked = false

            if (onChange !== null) {
              onChange(isInputChecked)
            }
          }}
          onToggle={(isInputChecked) => input.onChange(isInputChecked)}
        />
      )}

      {input.value && typeof input.value == "boolean" && !checked && (
        <MalabarCheckbox
          {...input}
          name={input.name}
          label={label}
          checked={input.value}
          disabled={disabled}
          onChange={(isInputChecked) => {
            checked = false

            if (onChange !== null) {
              onChange(isInputChecked)
            }
          }}
          onToggle={(isInputChecked) => input.onChange(isInputChecked)}
        />
      )}

      {!input.value && typeof input.value == "boolean" && (
        <MalabarCheckbox
          {...input}
          name={input.name}
          label={label}
          checked={input.value}
          disabled={disabled}
          onChange={(isInputChecked) => {
            checked = false

            if (onChange !== null) {
              onChange(isInputChecked)
            }
          }}
          onToggle={(isInputChecked) => input.onChange(isInputChecked)}
        />
      )}

      {touched &&
        ((error && (
          <FormText color="red" className="alert-validate">
            {error}
          </FormText>
        )) ||
          (warning && <FormText color="yellow">{warning}</FormText>))}
    </div>
  </div>
)

export const renderCheckboxOnlyCustom = ({
  input,
  disabled,
  label,
  onChange = null
}) => (
  <MalabarCheckbox
    {...input}
    name={input.name}
    label={label}
    checked={input.value}
    disabled={disabled}
    onChange={(isInputChecked) => {
      if (onChange !== null) {
        onChange(isInputChecked)
      }
    }}
    onToggle={(isInputChecked) => input.onChange(isInputChecked)}
  />
)

export const renderDateFieldCustom = ({
  input,
  required,
  disabled,
  isLabel,
  label,
  noPadding,
  isForGrid,
  meta: { touched, error },
  dropdowncss = "",
  onlyYear,
  unsetWidth,
  minToday = false,
  maxToday = false,
  min,
  max,
  type,
  dataValue,
  showAge,
  birthDate,
  showAgeFromCurrentAge,
  onChange
}) => {
  const cssHolder = isForGrid ? "" : "row-holder malabar-date"
  let today = moment(new Date()).format("YYYY-MM-DD")
  if (minToday) {
    min = today
  }
  if (maxToday) {
    max = today
  }

  return (
    <React.Fragment>
      <div
        className={
          touched
            ? error
              ? `${cssHolder} itemvalidate bad`
              : cssHolder
            : cssHolder
        }
      >
        {
          <MalabarDate
            {...input}
            isLabel={isLabel}
            name={input.name}
            label={label}
            required={required}
            onChange={(value) => {
              input.onChange(value)
              if (onChange) {
                onChange(value)
              }
            }}
            value={dataValue ? dataValue : input.value}
            touched={touched}
            error={error}
            disabled={disabled}
            noPadding={noPadding}
            onlyYear={onlyYear}
            dropdowncss={dropdowncss}
            validate={checkDate}
            unsetWidth={unsetWidth}
            min={min}
            max={max}
            showAge={showAge}
            birthDate={birthDate}
            showAgeFromCurrentAge={showAgeFromCurrentAge}
          />
        }
      </div>
    </React.Fragment>
  )
}

export const renderDateTimeFieldCustom = ({
  input,
  required,
  disabled,
  isLabel,
  label,
  noPadding,
  isFullWidth,
  meta: { touched, error },
  containerCssClass = ""
}) => {
  const cssHolder = `${"d-flex flex-column row-holder"} ${containerCssClass}`

  return (
    <React.Fragment>
      <div className={`portal-form-row`}>
        <label htmlFor={input.name}>
          {label} {required && <span>*</span>}
        </label>
        <div
          className={
            touched
              ? error
                ? `${cssHolder} ${containerCssClass} itemvalidate bad`
                : `${cssHolder} ${containerCssClass}`
              : `${cssHolder} ${containerCssClass}`
          }
        >
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <input
              {...input}
              disabled={disabled}
              type={"datetime-local"}
              className="text-input"
            />
          </div>
          {touched && error && <span className={`${"alert"}`}>{error}</span>}
        </div>
      </div>
    </React.Fragment>
  )
}

export const renderIdentityFieldCustom = ({
  input,
  required,
  isLabel,
  label,
  disabled,
  isForGrid,
  onRemoveDataClick,
  meta: { touched, error },
  index
}) => {
  const cssHolder = isForGrid ? "" : "portal-form-row "
  return (
    <React.Fragment>
      <div
        className={
          touched
            ? error
              ? `${cssHolder}kyc-phone itemvalidate bad`
              : `${cssHolder}kyc-phone`
            : `${cssHolder}kyc-phone`
        }
      >
        <Identity
          {...input}
          isLabel={isLabel}
          name={input.name}
          label={label}
          required={required}
          onChange={(value) => {
            input.onChange(value)
          }}
          value={input.value}
          touched={touched}
          error={error}
          onRemoveDataClick={onRemoveDataClick}
          disabled={disabled}
          index={index}
        />
      </div>
    </React.Fragment>
  )
}

export const renderPhoneNumberFieldCustom = ({
  input,
  required,
  isLabel,
  label,
  preferred,
  disabled,
  isForGrid,
  onRemoveDataClick,
  meta: { touched, error }
}) => {
  const cssHolder = isForGrid ? "" : "portal-form-row "
  return (
    <React.Fragment>
      <div
        className={
          touched
            ? error
              ? `${cssHolder}kyc-phone itemvalidate bad`
              : `${cssHolder}kyc-phone`
            : `${cssHolder}kyc-phone`
        }
      >
        <MalabarPhoneNumber
          {...input}
          isLabel={isLabel}
          name={input.name}
          label={label}
          required={required}
          onChange={(value) => input.onChange(value)}
          value={input.value}
          touched={touched}
          error={error}
          preferred={preferred}
          onRemoveDataClick={onRemoveDataClick}
          disabled={disabled}
        />
      </div>
    </React.Fragment>
  )
}

export const renderPhoneNumberSimpleFieldCustom = ({
  input,
  required,
  isLabel = false,
  label,
  disabled,
  isForGrid,
  onRemoveDataClick,
  meta: { touched, error }
}) => {
  const cssHolder = isForGrid ? "" : "portal-form-row "
  return (
    <React.Fragment>
      <div
        className={
          touched
            ? error
              ? `${cssHolder}kyc-phone itemvalidate bad`
              : `${cssHolder}kyc-phone`
            : `${cssHolder}kyc-phone`
        }
      >
        <MalabarPhoneNumberSimple
          {...input}
          isLabel={isLabel}
          name={input.name}
          label={label}
          required={required}
          onChange={(value) =>
            input.onChange(
              `${value.countryCode}-${value.areaCode}-${value.number}`
            )
          }
          value={input.value}
          touched={touched}
          error={error}
          onRemoveDataClick={onRemoveDataClick}
          disabled={disabled}
        />
      </div>
    </React.Fragment>
  )
}

export const renderEmailFieldCustom = ({
  input,
  required,
  isLabel,
  label,
  preferred,
  placeholder,
  disabled,
  isForGrid,
  onRemoveDataClick,
  meta: { touched, error }
}) => {
  const cssHolder = isForGrid ? "" : "portal-form-row "
  return (
    <React.Fragment>
      <div
        className={
          touched
            ? error
              ? `${cssHolder}kyc-email itemvalidate bad`
              : `${cssHolder}kyc-email`
            : `${cssHolder}kyc-email`
        }
      >
        <MalabarEmail
          {...input}
          isLabel={isLabel}
          name={input.name}
          label={label}
          placeholder={placeholder}
          required={required}
          onChange={(value) => input.onChange(value)}
          value={input.value}
          // preferred={input.value.preferred}
          touched={touched}
          error={error}
          preferred={preferred}
          disabled={disabled}
          onRemoveDataClick={onRemoveDataClick}
        />
      </div>
    </React.Fragment>
  )
}

export const renderTextBoxCustom = ({
  input,
  required,
  disabled,
  isLabel,
  label,
  placeholderlabel,
  type,
  isFullWidth,
  isForGrid,
  isNotUpperDiv,
  containerCssClass = "",
  meta: { touched, error },
  isDisabled,
  showAlert,
  isPersonalDealings,
  minLength = 0,
  maxLength = 999,
  isSalaryForm = false,
  extraNode
}) => {
  const pd_width = isPersonalDealings ? "pd-width" : ""
  const cssHolder = `${
    isForGrid
      ? "d-flex flex-column"
      : isFullWidth
      ? "d-flex flex-column row-holder-full"
      : "d-flex flex-column row-holder"
  } ${containerCssClass}`

  return (
    <React.Fragment>
      {isLabel ? (
        <div className={`${isSalaryForm ? "full-width" : ""} portal-form-row`}>
          <label htmlFor={input.name}>
            {label} {required && <span>*</span>}
          </label>
          <div
            className={
              touched
                ? error
                  ? `${cssHolder} ${containerCssClass} itemvalidate bad`
                  : `${cssHolder} ${containerCssClass}${pd_width}`
                : `${cssHolder} ${containerCssClass}${pd_width}`
            }
          >
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <input
                {...input}
                disabled={disabled || isDisabled}
                placeholder={placeholderlabel || label}
                type={type}
                className="text-input"
                minLength={minLength}
                maxLength={maxLength}
              />
              {extraNode ? extraNode : <></>}
            </div>
            {touched && error && (
              <span className={`${!showAlert ? "alert" : "alert"}`}>
                {error}
              </span>
            )}
          </div>
        </div>
      ) : isNotUpperDiv ? (
        <input
          {...input}
          disabled={disabled || isDisabled}
          placeholder={label}
          type={type}
          className="text-input"
        />
      ) : (
        <div
          className={
            touched
              ? error
                ? `${cssHolder} ${containerCssClass} itemvalidate ${
                    showAlert == false ? "" : `bad ${pd_width}`
                  }`
                : `${cssHolder} ${containerCssClass}${pd_width}`
              : `${cssHolder} ${containerCssClass}${pd_width}`
          }
        >
          <input
            {...input}
            disabled={disabled || isDisabled}
            placeholder={label}
            value={input.value}
            type={type}
            className="text-input"
          />
          {touched && error && (
            // showAlert &&
            <span className="alert">{error}</span>
          )}
        </div>
      )}
    </React.Fragment>
  )
}
export const renderTextAreaCustomKYCC = ({
  input,
  required,
  disabled,
  isLabel,
  label,
  placeholderlabel,
  type,
  isFullWidth,
  isForGrid,
  isNotUpperDiv,
  containerCssClass = "",
  meta: { touched, error },
  isDisabled,
  showAlert,
  isPersonalDealings,
  minLength = 0,
  maxLength = 5000
}) => {
  const pd_width = isPersonalDealings ? "pd-width" : ""
  const cssHolder = `${
    isForGrid
      ? "d-flex flex-column"
      : isFullWidth
      ? "d-flex flex-column row-holder-full"
      : "d-flex flex-column row-holder"
  } ${containerCssClass}`

  const convertToCapitalized = (data) => {
    return (
      (data.length ? data.charAt(0) && data.charAt(0).toUpperCase() : "") +
      (data.length ? data.slice(1) : "")
    )
  }
  return (
    <React.Fragment>
      {isLabel ? (
        <div className="portal-form-row">
          <label htmlFor={input.name}>
            {label} {required && <span>*</span>}
          </label>
          <div
            className={
              touched
                ? error
                  ? `${cssHolder} ${containerCssClass}itemvalidate bad`
                  : `${cssHolder} ${containerCssClass}${pd_width}`
                : `${cssHolder} ${containerCssClass}${pd_width}`
            }
          >
            <textarea
              {...input}
              disabled={disabled || isDisabled}
              placeholder={placeholderlabel || label}
              type={type}
              className="text-input text-area-input"
              minLength={minLength}
              maxLength={maxLength}
            />
            {touched && error && (
              <span className={`${!showAlert ? "alert" : "alert"}`}>
                {error}
              </span>
            )}
          </div>
        </div>
      ) : isNotUpperDiv ? (
        <textarea
          {...input}
          disabled={disabled || isDisabled}
          placeholder={label}
          type={type}
          className="text-input text-area-input"
        />
      ) : (
        <div
          className={
            touched
              ? error
                ? `${cssHolder} ${containerCssClass}itemvalidate ${
                    showAlert == false ? "" : `bad ${pd_width}`
                  }`
                : `${cssHolder} ${containerCssClass}${pd_width}`
              : `${cssHolder} ${containerCssClass}${pd_width}`
          }
        >
          <textarea
            {...input}
            disabled={disabled || isDisabled}
            placeholder={label}
            value={input.value}
            type={type}
            className="text-input text-area-input"
          />
          {touched && error && (
            // showAlert &&
            <span className="alert">{error}</span>
          )}
        </div>
      )}
    </React.Fragment>
  )
}

export const renderTextBoxPercentage = ({
  input,
  required,
  disabled,
  isLabel,
  label,
  placeholderlabel,
  type = "text",
  isFullWidth,
  isForGrid,
  isNotUpperDiv,
  containerCssClass = "",
  defaultValue,
  meta: { touched, error },
  extraNode,
  extraNodeHandler
}) => {
  const cssHolder = `${
    isForGrid
      ? "d-flex flex-column"
      : isFullWidth
      ? "d-flex flex-column row-holder-full"
      : "d-flex flex-column row-holder"
  } ${containerCssClass}`

  return (
    <React.Fragment>
      {isLabel ? (
        <div className="portal-form-row">
          <label htmlFor={input.name}>
            {label} {required && <span>*</span>}
          </label>
          <div
            className={
              touched
                ? error
                  ? `${cssHolder} ${containerCssClass} itemvalidate bad`
                  : `${cssHolder} ${containerCssClass}`
                : `${cssHolder} ${containerCssClass}`
            }
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "8px"
              }}
            >
              <div className="percentage-wrapper" style={{ width: "100%" }}>
                <span id="percent-sign">%</span>
                <input
                  {...input}
                  disabled={disabled}
                  type="text"
                  className="text-input"
                />
              </div>
              {extraNode && (
                <button
                  className="btn btn-plus"
                  onClick={extraNodeHandler}
                  type="button"
                />
              )}
            </div>
            {touched && error && <span className="alert">{error}</span>}
          </div>
        </div>
      ) : isNotUpperDiv ? (
        <input
          {...input}
          disabled={disabled}
          placeholder={label}
          type={type}
          className="text-input"
        />
      ) : (
        <div
          className={
            touched
              ? error
                ? `${cssHolder} ${containerCssClass} itemvalidate bad`
                : `${cssHolder} ${containerCssClass}`
              : `${cssHolder} ${containerCssClass}`
          }
        >
          <input
            {...input}
            disabled={disabled}
            placeholder={label}
            type={type}
            className="text-input"
          />
          {touched && error && <span className="alert">{error}</span>}
        </div>
      )}
    </React.Fragment>
  )
}

export const renderPasswordCustom = ({
  input,
  required,
  disabled,
  isLabel,
  label,
  type,
  isFullWidth,
  isForGrid,
  meta: { touched, error },
  // showPassword,
  id,
  show,
  show1,
  show2
}) => {
  // Password hide/show;
  const [passwordShow, setPasswordShow] = useState(false)
  const showPassword = () => {
    setPasswordShow((passwordShow) => !passwordShow)
  }
  const cssHolder = isForGrid
    ? ""
    : isFullWidth
    ? "row-holder-full"
    : "row-holder"
  return (
    <React.Fragment>
      {isLabel ? (
        <div className="portal-form-row">
          <label htmlFor={input.name}>
            {label} {required && <span>*</span>}
          </label>
          <div
            className={
              touched
                ? error
                  ? `${cssHolder} itemvalidate worst`
                  : cssHolder
                : cssHolder
            }
          >
            <input
              {...input}
              disabled={disabled}
              placeholder={label}
              type={passwordShow ? "text" : "password"}
              id={id}
              className="text-input password"
            />
            <img
              className="eyeIcon-1"
              onClick={showPassword}
              src={
                passwordShow
                  ? `${process.env.PUBLIC_URL}/assets/images/eyeIcon.png`
                  : `${process.env.PUBLIC_URL}/assets/images/eyeHidden.png`
              }
              alt="Eye Lock"
            />
            {touched && error && <span className="alert">{error}</span>}
          </div>
        </div>
      ) : (
        <div
          className={
            touched
              ? error
                ? `${cssHolder} itemvalidate bad`
                : cssHolder
              : cssHolder
          }
        >
          <input
            {...input}
            disabled={disabled}
            placeholder={label}
            type={type}
            className="text-input"
          />
          {touched && error && <span className="alert">{error}</span>}
        </div>
      )}
    </React.Fragment>
  )
}

export const renderPasswordSimple = ({
  input,
  required,
  disabled,
  isLabel,
  label,
  type,
  isFullWidth,
  isForGrid,
  meta: { touched, error },
  showPassword,
  id,
  show,
  show1,
  show2
}) => {
  const [showpw, setShowpw] = useState(false)

  const cssHolder = isForGrid
    ? ""
    : isFullWidth
    ? "row-holder-full"
    : "row-holder"

  const togglePassword = () => {
    setShowpw(!showpw)
  }

  return (
    <React.Fragment>
      {isLabel ? (
        <div className="portal-form-row">
          <label htmlFor={input.name}>
            {label} {required && <span>*</span>}
          </label>
          <div
            className={
              touched
                ? error
                  ? `${cssHolder} itemvalidate worst`
                  : cssHolder
                : cssHolder
            }
          >
            <input
              {...input}
              disabled={disabled}
              placeholder={label}
              type={
                showpw == undefined || showpw == false ? "password" : "text"
              }
              id={id}
              className="text-input password"
            />
            <img
              className="eyeIcon-1"
              onClick={() => togglePassword(id)}
              src={
                showpw == undefined || showpw == false
                  ? `${process.env.PUBLIC_URL}/assets/images/eyeHidden.png`
                  : `${process.env.PUBLIC_URL}/assets/images/eyeIcon.png`
              }
            />
            {touched && error && <span className="alert">{error}</span>}
          </div>
        </div>
      ) : (
        <div
          className={
            touched
              ? error
                ? `${cssHolder} itemvalidate bad`
                : cssHolder
              : cssHolder
          }
        >
          <input
            {...input}
            disabled={disabled}
            placeholder={label}
            type={type}
            className="text-input"
          />
          {touched && error && <span className="alert">{error}</span>}
        </div>
      )}
    </React.Fragment>
  )
}

export const renderTitleBarColourCustom = ({
  input,
  required,
  disabled,
  isLabel,
  label,
  type,
  isFullWidth,
  isForGrid,
  meta: { touched, error }
}) => {
  const cssHolder = isForGrid
    ? ""
    : isFullWidth
    ? "row-holder-full"
    : "row-holder"
  return (
    <React.Fragment>
      {isLabel ? (
        <div className="portal-form-row">
          <label htmlFor={input.name}>
            {label} {required && <span>*</span>}
          </label>
          <div
            className={
              touched
                ? error
                  ? `${cssHolder} itemvalidate bad`
                  : cssHolder
                : cssHolder
            }
          >
            <input
              {...input}
              disabled={disabled}
              placeholder={label}
              type="password"
              className="text-input password"
            />
            <span className="fa fa-lock" />
            {touched && error && <span className="alert">{error}</span>}
          </div>
        </div>
      ) : (
        <div
          className={
            touched
              ? error
                ? `${cssHolder} itemvalidate bad`
                : cssHolder
              : cssHolder
          }
        >
          <input
            {...input}
            disabled={disabled}
            placeholder={label}
            type={type}
            className="text-input"
          />
          {touched && error && <span className="alert">{error}</span>}
        </div>
      )}
    </React.Fragment>
  )
}

export const renderTextAreaCustom = ({
  input,
  disabled,
  isLabel,
  label,
  isFullWidth,
  isForGrid,
  onChange,
  meta: { touched, error },
  extraclassName
}) => {
  const cssHolder = isForGrid
    ? ""
    : isFullWidth
    ? "row-holder-full text-area"
    : "row-holder text-area"
  return (
    <React.Fragment>
      {isLabel ? (
        <div className="portal-form-row feedback-form-row">
          <label htmlFor={input.name}>{label}</label>
          <div
            className={
              touched
                ? error
                  ? `${cssHolder} itemvalidate bad`
                  : cssHolder
                : cssHolder
            }
          >
            <textarea
              required=""
              style={{
                height: "170px",
                resize: "none"
              }}
              className="text-input text-input-greybg"
              {...input}
              placeholder=" "
              onChange={(val) => {
                if (onChange !== null) {
                  input.onChange(val.target.value)
                }
              }}
              disabled={disabled}
            />
            {touched && error && (
              <span className="alert alert-textarea">{error}</span>
            )}
          </div>
        </div>
      ) : (
        <div className={`portal-form-row feedback-form-row `}>
          <div
            className={
              touched
                ? error
                  ? `${cssHolder} itemvalidate bad ${extraclassName}`
                  : `${cssHolder} ${extraclassName}`
                : `${cssHolder} ${extraclassName}`
            }
          >
            <textarea
              required=""
              style={{
                height: "170px",
                resize: "none"
              }}
              className="text-input text-input-greybg"
              {...input}
              placeholder=" "
              onChange={(val) => {
                if (onChange !== null) {
                  input.onChange(val.target.value)
                }
              }}
              disabled={disabled}
            />
            {touched && error && (
              <span className="alert alert-textarea">{error}</span>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export const renderCurrencyCustom = ({
  input,
  isTotal,
  readonly = false,
  required,
  disabled = false,
  isLabel,
  label,
  isRounded,
  isBlueBackground,
  placeholderLabel,
  isFullWidth,
  isForGrid,
  allowNegative = true,
  symbolPrefix = "$",
  decimalScale = DECIMAL_SCALE,
  decimalSeparator = ".",
  thousandSeparator = ",",
  onChange = null,
  containerCssClass = "",
  meta: { touched, error },
  classNames,
  isMoney = false, // if true, input.value must be in money type and symbolPrefix need to be there
  defaultCurrency, // must be supplied if isMoney is true,
  isPersonalDealings,
  isTotalValue = false,
  popupPickerEvent,
  popupPickerTitle
}) => {
  const pd_width = isPersonalDealings ? "pd-width" : ""
  const cssHolder = isForGrid
    ? ""
    : isFullWidth
    ? "row-holder-full"
    : "row-holder"
  const realValue = isMoney ? input.value.value : input.value
  const currencyValue = isMoney ? input.value.currency || defaultCurrency : ""
  return (
    <React.Fragment>
      {isLabel ? (
        <div className={`portal-form-row ${classNames}`}>
          <label htmlFor={input.name}>
            {label} {required && <span>*</span>}
          </label>
          <div
            className={
              touched
                ? error
                  ? `${cssHolder} ${containerCssClass}${pd_width} itemvalidate bad`
                  : `${cssHolder} ${containerCssClass}${pd_width}`
                : `${cssHolder} ${containerCssClass}${pd_width}`
            }
          >
            <div className="d-flex">
              <NumberFormat
                value={realValue}
                displayType={"input"}
                allowNegative={allowNegative}
                disabled={disabled}
                decimalScale={decimalScale}
                // isNumericString={true}
                prefix={symbolPrefix}
                decimalSeparator={decimalSeparator}
                thousandSeparator={thousandSeparator}
                className={`text-input currency-input ${
                  isTotal ? "text-input-total" : ""
                } ${isTotalValue ? "text-input-figure" : ""}
                                ${
                                  isBlueBackground
                                    ? "text-input-figure-blue"
                                    : ""
                                }`}
                readOnly={readonly}
                onValueChange={(values) => {
                  const returned = isMoney
                    ? {
                        currency: currencyValue,
                        value: values.value
                      }
                    : values.value
                  if (input.onChange) {
                    input.onChange(returned)
                  }

                  if (onChange !== null) {
                    onChange(returned)
                  }
                }}
              />
              {popupPickerEvent && (
                <input
                  className="portal-btn portal-btn-submit"
                  type="button"
                  value={popupPickerTitle ? popupPickerTitle : "..."}
                  onClick={popupPickerEvent}
                />
              )}
            </div>

            {touched && error && <span className="alert">{error}</span>}
          </div>
        </div>
      ) : isRounded ? (
        <NumberFormat
          value={realValue}
          displayType={"input"}
          allowNegative={allowNegative}
          disabled={disabled}
          decimalScale={decimalScale}
          // isNumericString={true}
          prefix={symbolPrefix}
          decimalSeparator={decimalSeparator}
          thousandSeparator={thousandSeparator}
          className={`text-input text-input-figure ${
            isBlueBackground ? "text-input-figure-blue" : ""
          }`}
          readOnly={readonly}
          onValueChange={(values) => {
            const returned = isMoney
              ? { currency: currencyValue, value: values.value }
              : values.value

            input.onChange(returned)
            if (onChange !== null) {
              onChange(returned)
            }
          }}
          placeholder={placeholderLabel}
        />
      ) : (
        <div
          className={
            touched
              ? error
                ? `${cssHolder} ${containerCssClass}${pd_width}itemvalidate bad`
                : `${cssHolder} ${containerCssClass}${pd_width} `
              : `${cssHolder} ${containerCssClass}${pd_width}`
          }
        >
          <NumberFormat
            value={
              realValue
              // realValue !== undefined && realValue.value
              //     ? realValue.value
              //     : realValue
              //     ? realValue
              //     : ""
            }
            displayType={"input"}
            allowNegative={allowNegative}
            disabled={disabled}
            decimalScale={decimalScale}
            // isNumericString={true}
            prefix={symbolPrefix}
            decimalSeparator={decimalSeparator}
            thousandSeparator={thousandSeparator}
            // className={`text-input currency-input ${
            //   isTotal ? "text-input-total" : ""
            // }`}
            className={`text-input currency-input ${
              isTotal ? "text-input-total" : ""
            } ${isTotalValue ? "text-input-figure" : ""}
                            ${
                              isBlueBackground ? "text-input-figure-blue" : ""
                            }`}
            readOnly={readonly}
            onValueChange={(values) => {
              const returned = isMoney
                ? {
                    currency: currencyValue,
                    value: values.value
                  }
                : values.value

              input.onChange(returned)
              if (onChange !== null) {
                onChange(returned)
              }
            }}
          />
          {touched && error && <span className="alert">{error}</span>}
        </div>
      )}
    </React.Fragment>
  )
}

// for total value

export const renderCurrencyCashflow = ({
  input,
  required,
  placeholder,
  disabled = false,
  isLabel,
  label,
  isFullWidth,
  isForGrid,
  allowNegative = true,
  symbolPrefix = "$",
  decimalSeparator = ".",
  thousandSeparator = ",",
  onChange = null,
  onRemoveDataClick,
  lockToCurrency = "",
  currency = "MYR",
  isfixedDecimalScale,
  meta: { touched, error }
}) => (
  <React.Fragment>
    <MalabarMoneyCashflow
      {...input}
      isLabel={isLabel}
      name={input.name}
      label={label}
      placeholder={placeholder}
      required={required}
      onChange={(value) => {
        input.onChange(value)
        if (onChange !== null) {
          onChange(value)
        }
      }}
      value={input.value}
      touched={touched}
      isfixedDecimalScale={isfixedDecimalScale}
      error={error}
      allowNegative={allowNegative}
      symbolPrefix={symbolPrefix}
      decimalScale={DECIMAL_SCALE}
      decimalSeparator={decimalSeparator}
      thousandSeparator={thousandSeparator}
      onRemoveDataClick={onRemoveDataClick}
      lockToCurrency={lockToCurrency}
      isFullWidth={isFullWidth}
      disabled={disabled}
      currency={currency}
    />
  </React.Fragment>
)

export const renderNumberCustom = ({
  input,
  required,
  disabled,
  isLabel,
  label,
  placeholderlabel,
  isFullWidth,
  isForGrid,
  isNotUpperDiv,
  containerCssClass = "",
  meta: { touched, error },
  onChange = null,
  numberFormat = "############",
  allowNegative = true,
  readOnly = false,
  isAllowed,
  isPersonalDealings,
  isDecimal = false
}) => {
  const pd_width = isPersonalDealings ? "pd-width" : ""
  const cssHolder = isForGrid
    ? ""
    : isFullWidth
    ? "row-holder-full"
    : "row-holder"

  return (
    <React.Fragment>
      {isLabel ? (
        <div className="portal-form-row">
          <label htmlFor={input.name}>
            {label} {required && <span>*</span>}
          </label>
          <div
            className={
              touched
                ? error
                  ? `${cssHolder} ${containerCssClass}${pd_width} itemvalidate bad`
                  : `${cssHolder} ${containerCssClass}${pd_width}`
                : `${cssHolder} ${containerCssClass}${pd_width}`
            }
          >
            <NumberFormat
              value={input.value}
              displayType={"input"}
              allowNegative={allowNegative}
              disabled={disabled}
              readOnly={readOnly}
              className="text-input"
              onValueChange={(values) => {
                input.onChange(values.value)
                if (onChange !== null) {
                  onChange(values.value)
                }
              }}
              format={isDecimal ? undefined : numberFormat}
              isAllowed={isAllowed}
              placeholder={placeholderlabel}
              decimalScale={isDecimal ? DECIMAL_SCALE : undefined}
            />
            {touched && error && <span className="alert">{error}</span>}
          </div>
        </div>
      ) : isNotUpperDiv ? (
        <NumberFormat
          value={input.value}
          displayType={"input"}
          allowNegative={allowNegative}
          disabled={disabled}
          readOnly={readOnly}
          className="text-input"
          onValueChange={(values) => {
            input.onChange(values.value)
            if (onChange !== null) {
              onChange(values.value)
            }
          }}
          format={isDecimal ? undefined : numberFormat}
          placeholder={placeholderlabel}
          decimalScale={isDecimal ? DECIMAL_SCALE : undefined}
        />
      ) : (
        <div
          className={
            touched
              ? error
                ? `${cssHolder} ${containerCssClass}${pd_width} itemvalidate bad`
                : `${cssHolder} ${containerCssClass}${pd_width}`
              : `${cssHolder} ${containerCssClass}${pd_width}`
          }
        >
          <NumberFormat
            value={input.value}
            displayType={"input"}
            allowNegative={allowNegative}
            disabled={disabled}
            readOnly={readOnly}
            className="text-input"
            onValueChange={(values) => {
              input.onChange(values.value)
              if (onChange !== null) {
                onChange(values.value)
              }
            }}
            format={isDecimal ? undefined : numberFormat}
            placeholder={placeholderlabel}
            decimalScale={isDecimal ? DECIMAL_SCALE : undefined}
          />
          {touched && error && <span className="alert">{error}</span>}
        </div>
      )}
    </React.Fragment>
  )
}
export const renderYearCustom = ({
  input,
  required,
  disabled,
  isLabel,
  label,
  isFullWidth,
  isForGrid,
  isNotUpperDiv,
  containerCssClass = "",
  meta: { touched, error },
  readOnly = false,
  isPersonalDealings,
  min,
  max,
  defaultValue,
  clientDob,
  partnerDob,
  type,
  forResidency = false
}) => {
  const currentYear = new Date().getFullYear()
  const pd_width = isPersonalDealings ? "pd-width" : ""
  const cssHolder = isForGrid
    ? ""
    : isFullWidth
    ? "row-holder-full"
    : "row-holder"

  if (!input.value) {
    input = { ...input, value: defaultValue ? defaultValue : currentYear }
  }

  function getYearOptions() {
    let minYear = forResidency
      ? type === "partner"
        ? partnerDob
        : clientDob
      : min
      ? min
      : currentYear
    let maxYear = max ? max : currentYear + 100
    let options = []
    for (let y = minYear; y <= maxYear; y++) {
      options.push(
        <option key={"y-" + y} value={y}>
          {y}
        </option>
      )
    }
    return options
  }

  return (
    <React.Fragment>
      {isLabel ? (
        <div className="portal-form-row">
          <label htmlFor={input.name}>
            {label} {required && <span>*</span>}
          </label>
          <div
            className={`row-holder d-flex flex-column
              ${
                touched
                  ? error
                    ? `${cssHolder} ${containerCssClass}${pd_width} itemvalidate bad`
                    : `${cssHolder} ${containerCssClass}${pd_width}`
                  : `${cssHolder} ${containerCssClass}${pd_width}`
              }`}
          >
            <div className="select-box">
              <select
                {...input}
                disabled={disabled}
                readOnly={readOnly}
                // className="text-input"
              >
                {getYearOptions()}
              </select>
            </div>
            {touched && error && <span className="alert">{error}</span>}
          </div>
        </div>
      ) : isNotUpperDiv ? (
        <div className="select-box">
          <select
            {...input}
            disabled={disabled}
            readOnly={readOnly}
            // className="text-input"
          >
            {getYearOptions()}
          </select>
        </div>
      ) : (
        <div
          className={`row-holder d-flex flex-column
              ${
                touched
                  ? error
                    ? `${cssHolder} ${containerCssClass}${pd_width} itemvalidate bad`
                    : `${cssHolder} ${containerCssClass}${pd_width}`
                  : `${cssHolder} ${containerCssClass}${pd_width}`
              }`}
        >
          <div className="select-box">
            <select
              {...input}
              disabled={disabled}
              readOnly={readOnly}
              // className="text-input"
            >
              {getYearOptions()}
            </select>
          </div>
          {touched && error && <span className="alert">{error}</span>}
        </div>
      )}
    </React.Fragment>
  )
}

export const renderPercentageCustom = ({
  input,
  required,
  disabled = false,
  isLabel,
  label,
  isFullWidth,
  isForGrid,
  allowNegative = false,
  symbolSuffix = " %",
  decimalSeparator = ".",
  thousandSeparator = ",",
  onChange = null,
  isRounded,
  isRoundColorRed,
  meta: { touched, error },
  classNames,
  isDisabled,
  maxValue = 100
}) => {
  const cssHolder = isForGrid
    ? ""
    : isFullWidth
    ? "row-holder-full"
    : "row-holder"
  return (
    <React.Fragment>
      {isLabel ? (
        <div className={`portal-form-row ${classNames}`}>
          <label htmlFor={input.name}>
            {label} {required && <span>*</span>}
          </label>
          <div
            className={
              touched
                ? error
                  ? `${cssHolder} itemvalidate bad`
                  : cssHolder
                : cssHolder
            }
          >
            <NumberFormat
              value={input.value}
              displayType={"input"}
              thousandSeparator
              allowNegative={allowNegative}
              disabled={disabled || isDisabled}
              decimalScale={PERCENT_SCALE}
              suffix={symbolSuffix}
              decimalSeparator={decimalSeparator}
              className="text-input text-right"
              onValueChange={(values) => {
                input.onChange(+values.value)
                if (onChange !== null) {
                  onChange(+values.value)
                }
              }}
              isAllowed={(values) => {
                const lowerBound = allowNegative ? -100 : 0
                const { value } = values
                if (value === "" || value === undefined || value === null) {
                  return true
                }
                if (allowNegative && value === "-") {
                  values.floatValue = 0
                }
                return (
                  lowerBound <= values.floatValue &&
                  values.floatValue <= maxValue
                )
              }}
            />
            {touched && error && <span className="alert">{error}</span>}
          </div>
        </div>
      ) : isRounded ? (
        <NumberFormat
          value={input.value}
          displayType={"input"}
          allowNegative={allowNegative}
          disabled={disabled || isDisabled}
          decimalScale={PERCENT_SCALE}
          // isNumericString={true}
          suffix={symbolSuffix}
          decimalSeparator={decimalSeparator}
          thousandSeparator={thousandSeparator}
          className={`float-left text-input text-right text-input-figure ${
            isRoundColorRed
              ? "text-input-figure-red"
              : "text-input-figure-blue2"
          }`}
          onValueChange={(values) => {
            input.onChange(+values.value)
            if (onChange !== null) {
              onChange(+values.value)
            }
          }}
          isAllowed={(values) => {
            const { floatValue } = values
            if (!floatValue) {
              return true
            }
            return floatValue >= 0 && floatValue <= maxValue
          }}
        />
      ) : (
        <div
          className={
            touched
              ? error
                ? `${cssHolder} itemvalidate bad`
                : cssHolder
              : cssHolder
          }
        >
          <NumberFormat
            value={input.value}
            displayType={"input"}
            allowNegative={allowNegative}
            disabled={disabled || isDisabled}
            decimalScale={PERCENT_SCALE}
            // isNumericString={true}
            suffix={symbolSuffix}
            decimalSeparator={decimalSeparator}
            thousandSeparator={thousandSeparator}
            className="text-input text-right"
            onValueChange={(values) => {
              input.onChange(+values.value)
              if (onChange !== null) {
                onChange(+values.value)
              }
            }}
            isAllowed={(values) => {
              const { floatValue } = values
              if (!floatValue) {
                return true
              }
              return floatValue >= 0 && floatValue <= maxValue
            }}
          />
          {touched && error && <span className="alert">{error}</span>}
        </div>
      )}
    </React.Fragment>
  )
}

export const renderMoneyCustom = ({
  input,
  required,
  disabled,
  isLabel,
  label,
  placeholder,
  isForGrid,
  isFullWidth = true,
  onRemoveDataClick,
  allowNegative = true,
  symbolPrefix = "$",
  decimalScale = DECIMAL_SCALE,
  decimalSeparator = ".",
  thousandSeparator = ",",
  lockToCurrency = "",
  meta: { touched, error },
  defaultCurrency,
  onChange,
  popupPickerEvent,
  popupPickerTitle,
  isSalaryForm = false
}) => {
  const cssHolder = isForGrid ? "" : "portal-form-row "
  return (
    <React.Fragment>
      <div
        className={`${isFullWidth ? "" : "row-holder"} ${
          isSalaryForm ? "full-width" : ""
        } `}
      >
        <div
          className={
            touched
              ? error
                ? `${cssHolder}popup-purchaseprice itemvalidate bad`
                : `${cssHolder}popup-purchaseprice`
              : `${cssHolder}popup-purchaseprice`
          }
        >
          <MalabarMoney
            {...input}
            isLabel={isLabel}
            name={input.name}
            label={label}
            placeholder={placeholder}
            required={required}
            onChange={(value) => {
              if (onChange) {
                onChange(value)
              }
              input.onChange(value)
            }}
            value={input.value}
            touched={touched}
            error={error}
            allowNegative={allowNegative}
            symbolPrefix={symbolPrefix}
            decimalScale={decimalScale}
            decimalSeparator={decimalSeparator}
            thousandSeparator={thousandSeparator}
            onRemoveDataClick={onRemoveDataClick}
            lockToCurrency={lockToCurrency}
            isFullWidth={isForGrid ? false : isFullWidth}
            defaultCurrency={defaultCurrency}
            disabled={disabled}
            popupPickerEvent={popupPickerEvent}
            popupPickerTitle={popupPickerTitle}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export const renderMoneyWithPercentageCustom = ({
  input,
  required,
  disabled,
  isLabel,
  label,
  placeholder,
  isForGrid,
  isFullWidth = true,
  onRemoveDataClick,
  allowNegative = true,
  symbolPrefix = "$",
  decimalSeparator = ".",
  thousandSeparator = ",",
  lockToCurrency = "",
  meta: { touched, error },
  defaultCurrency,
  onChange,
  getPercentageValueSource,
  currency
}) => {
  const cssHolder = isForGrid ? "" : "portal-form-row "
  return (
    <React.Fragment>
      <div className={isFullWidth ? "" : "row-holder"}>
        <div
          className={
            touched
              ? error
                ? `${cssHolder}popup-purchaseprice itemvalidate bad`
                : `${cssHolder}popup-purchaseprice`
              : `${cssHolder}popup-purchaseprice`
          }
        >
          <MalabarMoneyWithPercentage
            {...input}
            isLabel={isLabel}
            name={input.name}
            label={label}
            placeholder={placeholder}
            required={required}
            onChange={(value) => {
              if (onChange) {
                onChange(value)
              }
              // needed by final form
              input.onChange(value)
            }}
            value={input.value}
            touched={touched}
            error={error}
            allowNegative={allowNegative}
            symbolPrefix={symbolPrefix}
            decimalScale={DECIMAL_SCALE}
            decimalSeparator={decimalSeparator}
            thousandSeparator={thousandSeparator}
            onRemoveDataClick={onRemoveDataClick}
            lockToCurrency={lockToCurrency}
            isFullWidth={isForGrid ? false : isFullWidth}
            defaultCurrency={defaultCurrency}
            disabled={disabled}
            getPercentageValueSource={getPercentageValueSource}
            currency={currency}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export const renderJointSelectionMenuCustom = ({
  input,
  onChange = null,
  clientName,
  partnerName,
  hideJoint = false,
  classification
}) => (
  <JointSelectionMenu
    {...input}
    name={input.name}
    value={input.value}
    clientName={clientName}
    partnerName={partnerName}
    onChange={(val) => {
      if (onChange !== null) {
        onChange(val)
      }
    }}
    onToggle={(selectedType) => input.onChange(selectedType)}
    hideJoint={hideJoint}
    classification={classification}
  />
)

export const renderFeedbackSelectionMenuCustom = ({
  input,
  onChange = null,
  disabled,
  meta: { touched, error }
}) => (
  <div
    className={`feedback-selection ${touched && error && `itemvalidate bad`}`}
  >
    <FeedbackSelectionMenu
      {...input}
      name={input.name}
      value={input.value}
      onChange={(val) => {
        if (onChange !== null && disabled === false) {
          onChange(val)
        }
      }}
      onToggle={(selectedType) => !disabled && input.onChange(selectedType)}
    />
    {touched && error && <span className="alert">{error}</span>}
  </div>
)
export const renderNoteSelectionMenuCustom = ({
  input,
  onChange = null,
  disabled,
  meta: { touched, error }
}) => (
  <></>
  // <div
  //     className={`feedback-selection ${touched &&
  //         error &&
  //         `itemvalidate bad`}`}
  // >
  //     <NotesSelectionMenu
  //         {...input}
  //         value={input.value}
  //         onChange={val => {
  //             if (onChange !== null && disabled === false) {
  //                 onChange(val);
  //             }
  //         }}
  //         onToggle={selectedType => !disabled && input.onChange(selectedType)}
  //     />
  //     {touched && error && <span className="alert">{error}</span>}
  // </div>
)
export const renderDateTimeCustom = ({
  input,
  isForGrid,
  dateFormat = null,
  timeFormat = null,
  setValidDate,
  setDefaultValue,
  meta: { touched, error }
}) => {
  const cssHolder = isForGrid ? "" : "portal-form-row "

  return (
    <React.Fragment>
      <div
        className={
          touched
            ? error
              ? `${cssHolder} itemvalidate bad`
              : `${cssHolder}`
            : `${cssHolder}`
        }
      >
        <Datetime
          inputProps={{ name: input.name, class: "text-input" }}
          value={input.value}
          className={touched ? (error ? "is-invalid" : "") : ""}
          onChange={(param) =>
            input.onChange(
              moment(param).format(
                dateFormat !== null ? dateFormat : timeFormat
              )
            )
          }
          dateFormat={dateFormat}
          timeFormat={timeFormat}
          isValidDate={setValidDate}
          defaultValue={setDefaultValue}
        />
        {touched && error && <span className="alert">{error}</span>}
      </div>
    </React.Fragment>
  )
}

export const renderYearAgeCustom = ({
  input,
  disabled,
  isForGrid,
  birthDate,
  dateFormat = "YYYY",
  readonly = false,
  meta: { touched, error }
}) => {
  const cssHolder = isForGrid ? "" : "portal-form-row "

  return (
    <React.Fragment>
      <div
        className={
          touched
            ? error
              ? `${cssHolder} itemvalidate bad`
              : `${cssHolder}`
            : `${cssHolder}`
        }
      >
        <MalabarYearAge
          value={input.value}
          name={input.name}
          onChange={(val) => {
            input.onChange(val)
          }}
          birthDate={birthDate}
          disabled={disabled}
          dateFormat={dateFormat}
          readonly={readonly}
          touched={touched}
          error={error}
        />
      </div>
    </React.Fragment>
  )
}

export const renderMonthYearAgeCustom = ({
  input,
  disabled,
  isForGrid,
  birthDate,
  dateFormat = "MMM YYYY",
  readonly = false,
  meta: { touched, error }
}) => {
  const cssHolder = isForGrid ? "" : "portal-form-row "

  return (
    <React.Fragment>
      <div
        className={
          touched
            ? error
              ? `${cssHolder} itemvalidate bad`
              : `${cssHolder}`
            : `${cssHolder}`
        }
      >
        <MalabarMonthYearAge
          value={input.value}
          name={input.name}
          onChange={(val) => {
            input.onChange(val)
          }}
          birthDate={birthDate}
          disabled={disabled}
          dateFormat={dateFormat}
          readonly={readonly}
          touched={touched}
          error={error}
        />
      </div>
    </React.Fragment>
  )
}

export const renderTextOnlyCustom = ({ input }) => input.value
export const renderDateAsTextOnlyCustom = ({ input, dateFormat }) =>
  moment(input.value).format(dateFormat)
const getImagePath = (path) => `${BASE_URL}${path}`

export const fileFieldRender = ({ input, label, fileInformation, ...rest }) => {
  return (
    <div className="portal-form-row">
      <label>
        <p>{label}</p>
      </label>
      <div className="row-holder">
        {((input &&
          input.value &&
          input.value.proofDocument &&
          !input.value.proofDocument.file) ||
          !input.value.proofDocument) &&
          fileInformation && (
            <div
              className="feedback-download"
              style={{
                border: "0",
                display: "flex",
                alignItems: "center",
                gap: "40px"
              }}
            >
              <a href={getImagePath(fileInformation.fileUrl)}>
                {fileInformation.fileName}
              </a>
              <div
                className={`text-center ${
                  fileInformation.approvalStatus === "APPROVED"
                    ? "text-success"
                    : fileInformation.approvalStatus === "REJECTED"
                    ? "text-danger"
                    : "text-pending"
                }`}
                style={{
                  textTransform: "capitalize",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px"
                }}
              >
                <span> {fileInformation.approvalStatus}</span>
                {fileInformation.approvalStatus === "APPROVED" ? (
                  <div>
                    <FaCheck style={{ color: "green" }} />
                  </div>
                ) : fileInformation.approvalStatus === "REJECTED" ? (
                  <div>
                    <FaCross style={{ color: "red" }} />
                  </div>
                ) : (
                  <div>
                    <FaClock style={{ color: "gray" }} />
                  </div>
                )}
              </div>
            </div>
          )}
        <CustomFilePond
          allowMultiple={false}
          maxFiles={1}
          allowReplace
          maxFileSize={maxFileSize}
          {...rest}
        />
      </div>
    </div>
  )
}

export const renderRadioCustom = ({
  input,
  disabled,
  option,
  externalChange,
  meta: { touched, error }
}) => {
  const handleChange = (newValue) => {
    input.onChange(newValue)
    if (externalChange) {
      externalChange()
    }
  }

  return (
    <div
      style={{
        marginRight: "12px"
      }}
    >
      <input
        name={input.name}
        type="radio"
        value={option.value}
        checked={input.value === option.value}
        disabled={disabled}
        onChange={() => handleChange(option.value)}
        className={
          touched
            ? error
              ? "is-invalid input-radio-vertical"
              : "input-radio-vertical"
            : "input-radio-vertical"
        }
      />
      <span className="input-radio-label">{option.label}</span>
    </div>
  )
}
