import { FrequencyEnum } from "../constants/Enums"

function LoanRepaymentFrequency(frequency) {
  switch (frequency) {
    case FrequencyEnum.Frequency_YEARLY:
      return 1
    case FrequencyEnum.Frequency_HALF_YEARLY:
      return 2
    case FrequencyEnum.Frequency_QUARTERLY:
      return 4
    case FrequencyEnum.Frequency_MONTHLY:
      return 12
    case FrequencyEnum.Frequency_FORTNIGHTLY:
      return 26
    case FrequencyEnum.Frequency_WEEKLY:
      return 52
    default:
      return 0
  }
}
function LoanRepaymentAmountCalculation(model) {
  if (!model) {
    return 0
  }
  if (
    !model.borrowingsPrinciple ||
    !model.borrowingsRepaymentFrequency ||
    !model.borrowingsTermInYears
  ) {
    return 0
  }

  const periods =
    model.borrowingsTermInYears *
    LoanRepaymentFrequency(model.borrowingsRepaymentFrequency)

  // If interest rate is 0, simplify the calculation
  if (model.borrowingsAnnualInterestRate == 0 || "") {
    return model.borrowingsPrinciple / periods
  } else {
    // If there is an interest rate, calculate using the original formula
    const periodicInterestRate =
      model.borrowingsAnnualInterestRate /
      100 /
      LoanRepaymentFrequency(model.borrowingsRepaymentFrequency)

    return (
      (model.borrowingsPrinciple * periodicInterestRate) /
      (1 - 1 / Math.pow(1 + periodicInterestRate, periods))
    )
  }
}

export default LoanRepaymentAmountCalculation
