import { riskProfileAction } from '../../../constants/ActionConstants';

export function riskProfile(state = {}, action) {
    switch (action.type) {
        case riskProfileAction.GET_CLIENT_SUCCESS: {
            return {
                ...state,
                client: {
                    ...action.response.data,
                    name: getClientName(action.response.data),
                },
                isJoint: false,
                loading: false,
            };
        }
        case riskProfileAction.GET_CLIENT_WITH_PARTNER_SUCCESS: {
            return {
                ...state,
                client: {
                    ...action.client,
                    name: getClientName(action.client),
                },
                partner: {
                    ...action.partner,
                    name: getClientName(action.partner),
                },
                isJoint: true,
                loading: false,
            };
        }
        case riskProfileAction.GET_RISK_PROFILE_SUCCESS: {
            const { data } = action;
            return {
                ...state,
                data,
            };
        }
        case riskProfileAction.SAVE_RISK_PROFILE_SUCCESS: {
            const { data } = action;
            return {
                ...state,
                data,
            };
        }
        case riskProfileAction.UPDATE_DATA_IN_STORE: {
            const { data } = action;
            return {
                ...state,
                data,
            };
        }
        default:
            return state;
    }
}

function getClientName(client) {
    return client.personalDetails.personalInformation.firstName;
}
