import React, { useState, useEffect, createContext } from "react"
import intl from "react-intl-universal"
import { connect, useSelector } from "react-redux"
import { bindActionCreators } from "redux"
import { getSymbolFromCode } from "../../helpers"
import { numberWithCommas } from "../../helpers/CurrencyConversion"
import { ConfirmationDialog } from "../../components"
import Alert from "react-s-alert"
import ActionCreators from "../../actions/ActionCreators"
import {
  ArrowMenu,
  MalabarDefaultRightMenu,
  LifelineChart
} from "../../components"
import "rc-tooltip/assets/bootstrap.css"
import Get from "../../helpers/NullHelper"
import CurrentStandingHeader from "./_components/CurrentStandingHeader"
import CrucialStagesProjectionChart from "../../components/LifelineChart/CrucialStagesProjectionChart"
import CrucialStageProjectionTable from "../../components/LifelineChart/CrucialStageProjectionTable"
import TenYearsProjectionTable from "../../components/LifelineChart/TenYearsProjectionTable"
import TenYearsProjectionChart from "../../components/LifelineChart/TenYearsProjectionChart"
import { classificationEnumsAllCapitalized } from "../../utils/enums/Enum"
import { DECIMAL_SCALE } from "../../constants/NumberFormatConstants"
export const LifeLineContext = createContext()
function LifeLine({
  lifelineObject,
  statusOfClient,
  getCurrentStanding,
  getLifeline,
  match,
  history,
  statusOfPartner
}) {
  const { defaultCurrency, data } = useSelector(
    (state) => state.personalDetails
  )
  const { client, partner } = useSelector((state) => state.entity)
  const [checked, setChecked] = useState(false)
  const [retirementAge, setRetirementAge] = useState(0)

  const [currency, setCurrency] = useState("")
  const [lifelineData, setLifelineData] = useState("")
  const [save, setSave] = useState(false)
  const [classification, setClassification] = useState()
  useEffect(() => {
    if (defaultCurrency) {
      setCurrency(defaultCurrency)
    }
  }, [defaultCurrency])

  useEffect(() => {
    if (!partner) {
      setClassification("CLIENT")
    } else {
      setClassification("COMBINED")
    }
  }, [partner])

  const clickHandler1 = () => {
    setSave(false)
  }

  const clickHandler2 = () => {
    setSave(false)
  }
  const [redrawFlag, setRedrawFlag] = useState(true)
  const doRefreshChart = (e) => {
    setRedrawFlag(false)
    function setFlagTrue() {
      setRedrawFlag(true)
    }
    setTimeout(setFlagTrue, 1)
    // setRedrawFlag(true);
  }
  useEffect(() => {
    // get data
    const getCurrentStandingData = async () => {
      if (match.params && match.params.id && match.params.id !== "null") {
        await getCurrentStanding(match.params.id, currency)
      }
    }

    getCurrentStandingData()
  }, [match.params.id, currency])

  useEffect(() => {
    if (
      (statusOfPartner ? statusOfPartner === "NEW_CONTACT" : false) ||
      statusOfClient === "NEW_CONTACT"
    ) {
      history.push(`/personaldetails/${match.params.id}`)
      Alert.error(
        "client/partner status still NEW CONTACT, please complete client/partner information and upgrade category status to higher level!"
      )
    }
  }, [statusOfClient, statusOfPartner, data, match])
  useEffect(() => {
    if (
      lifelineObject &&
      lifelineObject.client &&
      lifelineObject.client.ageInfo.id === match.params.id
    )
      if (classification == "CLIENT") {
        setLifelineData(lifelineObject.client)
      } else if (classification === "JOINT") {
        setLifelineData(lifelineObject.joint)
      } else if (classification == "PARTNER") {
        setLifelineData(lifelineObject.partner)
      } else {
        setLifelineData(lifelineObject.totalCombined)
      }
  }, [classification, match.params.id, currency, lifelineObject])

  const onChangeCurrency = (value) => {
    setCurrency(value)
  }

  const onChangeClassification = (value) => {
    setClassification(value)
  }

  useEffect(() => {
    if (retirementAge !== 0) {
      getLifeline(match.params.id, retirementAge, currency)
    }
  }, [retirementAge])

  const readOnlyAccessComponent = () => null

  const handleRetirementAgeChange = (value) => {
    setRetirementAge(value)
  }

  const handleChartClick = (index) => {
    // alert(`this will go to index: ${index}`);
  }

  const getBoxColorClass = (value) => {
    switch (value) {
      case "GOOD":
        return "overview-box-green"
      case "BAD":
        return "overview-box-red"
      default:
        if (value > 0) {
          return "overview-box-green"
        }
        return "overview-box-red"
    }
  }
  const filters = [
    {
      value: "totalProjection",
      label: "Total Projection"
    },
    {
      value: "10-years-gap-projection",
      label: "10 Years Gap Projection"
    },
    {
      value: "3-crucial-stages-gap-projection",
      label: "3 Crucial Stages Gap Projection"
    }
  ]
  const [activeTab, setActiveTab] = useState("totalProjection")
  return (
    <LifeLineContext.Provider value={{ lifelineObject, classification }}>
      <React.Fragment>
        <ConfirmationDialog
          isOpen={save}
          onClose={clickHandler1}
          onYes={clickHandler2}
          message="Would you like to save the current client personal and financial details ?"
        />
        <div className="portal-maincontent current_standing">
          <div className="content-panel lifeline">
            <CurrentStandingHeader
              title={intl.get("currentStanding.lifeline.title")}
              reportingCurrency={currency}
              onChangeCurrency={onChangeCurrency}
              onChangeClassification={onChangeClassification}
              client={client}
              partner={partner}
              show={true}
              refreshChart={doRefreshChart}
            />

            <div className="clearfloat" />
            <div className="content-panel-tab">
              {filters.map((filter, index) => (
                <span
                  key={index}
                  onClick={() => setActiveTab(filter.value)}
                  className={activeTab === filter.value ? "selected" : ""}
                  style={{ cursor: "pointer" }}
                >
                  {filter.label}
                </span>
              ))}
            </div>

            <div id="scroll-section" className="portal-form">
              <div className="flexbox">
                <div className="box-full box-info">
                  <div className="graph-holder">
                    {lifelineObject !== null &&
                      lifelineObject.client &&
                      lifelineObject.client.lifeline !== undefined && (
                        <React.Fragment>
                          {activeTab === "totalProjection" && (
                            <LifelineChart
                              showAnalytics={checked}
                              data={
                                !lifelineData
                                  ? lifelineObject.totalCombined
                                  : lifelineData
                              }
                              currentAge={
                                Get(
                                  !lifelineData
                                    ? lifelineObject.client
                                    : lifelineData,
                                  "ageInfo.age"
                                ) || 0
                              }
                              currentYear={new Date().getFullYear()}
                              onRetirementAgeChange={handleRetirementAgeChange}
                              onChartClick={handleChartClick}
                              currency={currency}
                            />
                          )}
                          {activeTab === "10-years-gap-projection" && (
                            <TenYearsProjectionChart
                              // showAnalytics={checked}
                              data={
                                !lifelineData
                                  ? lifelineObject.totalCombined
                                  : lifelineData
                              }
                              currentAge={
                                Get(
                                  !lifelineData
                                    ? lifelineObject.client
                                    : lifelineData,
                                  "ageInfo.age"
                                ) || 0
                              }
                              currentYear={new Date().getFullYear()}
                              onRetirementAgeChange={handleRetirementAgeChange}
                              onChartClick={handleChartClick}
                              currency={currency}
                              chartId="ten-year-projection-chart"
                            />
                          )}
                          {activeTab === "3-crucial-stages-gap-projection" && (
                            <CrucialStagesProjectionChart
                              // showAnalytics={checked}
                              data={
                                !lifelineData
                                  ? lifelineObject.totalCombined
                                  : lifelineData
                              }
                              currentAge={
                                Get(
                                  !lifelineData
                                    ? lifelineObject.client
                                    : lifelineData,
                                  "ageInfo.age"
                                ) || 0
                              }
                              currentYear={new Date().getFullYear()}
                              onRetirementAgeChange={handleRetirementAgeChange}
                              onChartClick={handleChartClick}
                              currency={currency}
                              chartId="crucial-stage-projection-chart"
                            />
                          )}
                        </React.Fragment>
                      )}
                  </div>

                  {checked ? (
                    <div className="ml-2 mt-4">
                      <strong>
                        * Investment asset prediction recently only for cash
                        amount prediction, depends on the changing inflation
                        rate.
                      </strong>
                      <br />
                      <br />
                      <strong>
                        ** Lowest and highest prediction mean there is 95%
                        possibility that the investment asset is between the
                        lowest and highest lines.
                      </strong>
                      <br />
                      <br />
                      <strong>
                        *** 60% lowest and highest prediction mean there is 60%
                        possibility that the investment asset is between these
                        two lines.
                      </strong>
                      <br />
                      <br />
                      <strong>
                        **** income prediction = asset prediction - original
                        amount
                      </strong>
                      <br />
                      <br />
                    </div>
                  ) : null}
                </div>
                {activeTab === "10-years-gap-projection" ? (
                  <TenYearsProjectionTable
                    data={
                      !lifelineData
                        ? lifelineObject.totalCombined
                        : lifelineData
                    }
                    currency={currency}
                  />
                ) : activeTab === "3-crucial-stages-gap-projection" ? (
                  <CrucialStageProjectionTable
                    data={
                      !lifelineData
                        ? lifelineObject.totalCombined
                        : lifelineData
                    }
                    currency={currency}
                  />
                ) : (
                  <></>
                )}

                {lifelineObject !== null &&
                  lifelineObject[
                    classificationEnumsAllCapitalized[classification]
                  ] &&
                  lifelineObject[
                    classificationEnumsAllCapitalized[classification]
                  ].summary !== undefined && (
                    <div className="finance_overview-panel finance_overview-panel-lifeline">
                      <div className="finance_overview-L1">
                        <div
                          className={`finance_overview-box1 finance_overview-box-L ${getBoxColorClass(
                            Get(
                              !lifelineData
                                ? lifelineObject[
                                    classificationEnumsAllCapitalized[
                                      classification
                                    ]
                                  ]
                                : lifelineData,
                              "summary.sustainableRating.classification"
                            )
                          )} `}
                        >
                          <div className="text-align-center">
                            <div className="title">
                              {intl.get(
                                "currentStanding.lifeline.lifestyleSustainability"
                              )}
                            </div>
                            <div className="rating">
                              {Get(
                                !lifelineData
                                  ? lifelineObject[
                                      classificationEnumsAllCapitalized[
                                        classification
                                      ]
                                    ]
                                  : lifelineData,
                                "summary.sustainableRating.classification"
                              ) || ""}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="finance_overview-R">
                        <div className="finance_overview-box1 finance_overview-box-S1">
                          <div className="title">
                            {intl.get("currentStanding.lifeline.retirementAge")}
                          </div>
                          <div className="rating">
                            {Get(
                              !lifelineData
                                ? lifelineObject[
                                    classificationEnumsAllCapitalized[
                                      classification
                                    ]
                                  ]
                                : lifelineData,
                              "summary.retirementAge"
                            ) || 0}
                          </div>
                        </div>

                        <div
                          className={`finance_overview-box1 finance_overview-box-S1 ${getBoxColorClass(
                            Get(
                              !lifelineData
                                ? lifelineObject[
                                    classificationEnumsAllCapitalized[
                                      classification
                                    ]
                                  ]
                                : lifelineData,
                              "summary.networth"
                            )
                          )}`}
                        >
                          <div className="title">
                            {intl.get(
                              "currentStanding.lifeline.currentNetWorth"
                            )}
                          </div>
                          <div className="rating">
                            {`${getSymbolFromCode(currency)}${numberWithCommas(
                              Get(
                                !lifelineData
                                  ? lifelineObject[
                                      classificationEnumsAllCapitalized[
                                        classification
                                      ]
                                    ]
                                  : lifelineData,
                                "summary.networth"
                              )
                            )}`}
                          </div>
                        </div>

                        <div
                          className={`finance_overview-box1 finance_overview-box-S1`}
                        >
                          <div className="title">
                            {intl.get("currentStanding.lifeline.riskProfile")}
                          </div>
                          <div
                            className="rating"
                            style={{ textTransform: "capitalize" }}
                          >
                            {Get(
                              !lifelineData
                                ? lifelineObject[
                                    classificationEnumsAllCapitalized[
                                      classification
                                    ]
                                  ]
                                : lifelineData,
                              "summary.riskProfile.classification"
                            )
                              .split("_")
                              .join(" ") || ""}
                            &nbsp;
                          </div>
                        </div>

                        <div
                          className={`finance_overview-box1 finance_overview-box-S1 ${getBoxColorClass(
                            Get(
                              !lifelineData
                                ? lifelineObject[
                                    classificationEnumsAllCapitalized[
                                      classification
                                    ]
                                  ]
                                : lifelineData,
                              "summary.debtServiceRatio.classification"
                            )
                          )}`}
                        >
                          <div className="title">
                            {intl.get(
                              "currentStanding.lifeline.debtServiceRatio"
                            )}
                          </div>
                          <div className="rating">
                            {`${Number(
                              Get(
                                !lifelineData
                                  ? lifelineObject[
                                      classificationEnumsAllCapitalized[
                                        classification
                                      ]
                                    ]
                                  : lifelineData,
                                "summary.debtServiceRatio.value"
                              ) || 0
                            ).toFixed(DECIMAL_SCALE)}%`}
                          </div>
                        </div>

                        <div
                          className={`finance_overview-box1 finance_overview-box-S1 ${getBoxColorClass(
                            Get(
                              !lifelineData
                                ? lifelineObject[
                                    classificationEnumsAllCapitalized[
                                      classification
                                    ]
                                  ]
                                : lifelineData,
                              "summary.savingRateRatio.classification"
                            )
                          )}`}
                        >
                          <div className="title">
                            {intl.get(
                              "currentStanding.lifeline.savingsRateRatio"
                            )}
                          </div>
                          <div className="rating">
                            {`${Number(
                              Get(
                                !lifelineData
                                  ? lifelineObject[
                                      classificationEnumsAllCapitalized[
                                        classification
                                      ]
                                    ]
                                  : lifelineData,
                                "summary.savingRateRatio.value"
                              ) || "0"
                            ).toFixed(DECIMAL_SCALE)}%`}
                          </div>
                        </div>

                        <div className="finance_overview-box1 finance_overview-box-S1">
                          <div className="title">
                            {intl.get(
                              "currentStanding.lifeline.sustainabilityAge"
                            )}
                          </div>
                          <div className="rating">
                            {`${
                              Get(
                                !lifelineData
                                  ? lifelineObject[
                                      classificationEnumsAllCapitalized[
                                        classification
                                      ]
                                    ]
                                  : lifelineData,
                                "summary.sustainabilityAge"
                              ) || 0
                            }`}
                          </div>
                        </div>

                        <div
                          className={`finance_overview-box1 finance_overview-box-S1 ${getBoxColorClass(
                            Get(
                              !lifelineData
                                ? lifelineObject[
                                    classificationEnumsAllCapitalized[
                                      classification
                                    ]
                                  ]
                                : lifelineData,
                              "summary.lifeInsuranceCoverage"
                            )
                          )}`}
                        >
                          <div className="title">
                            {intl.get(
                              "currentStanding.lifeline.lifeInsuranceCoverage"
                            )}
                          </div>
                          <div className="rating">
                            {`${getSymbolFromCode(currency)}${numberWithCommas(
                              Get(
                                !lifelineData
                                  ? lifelineObject[
                                      classificationEnumsAllCapitalized[
                                        classification
                                      ]
                                    ]
                                  : lifelineData,
                                "summary.lifeInsuranceCoverage"
                              )
                            )}`}
                          </div>
                        </div>

                        <div
                          className={`finance_overview-box1 finance_overview-box-S1 ${getBoxColorClass(
                            Get(
                              !lifelineData
                                ? lifelineObject[
                                    classificationEnumsAllCapitalized[
                                      classification
                                    ]
                                  ]
                                : lifelineData,
                              "summary.liquidityRatio.classification"
                            )
                          )}`}
                        >
                          <div className="title">
                            {intl.get(
                              "currentStanding.lifeline.liquidityRatio"
                            )}
                          </div>
                          <div className="rating">
                            {`${Number(
                              Get(
                                !lifelineData
                                  ? lifelineObject[
                                      classificationEnumsAllCapitalized[
                                        classification
                                      ]
                                    ]
                                  : lifelineData,
                                "summary.liquidityRatio.value"
                              )
                            ).toFixed(DECIMAL_SCALE)} ${intl.get(
                              "general.months"
                            )}`}
                          </div>
                        </div>

                        <div
                          className={`finance_overview-box finance_overview-box-S1 ${getBoxColorClass(
                            Get(
                              !lifelineData
                                ? lifelineObject[
                                    classificationEnumsAllCapitalized[
                                      classification
                                    ]
                                  ]
                                : lifelineData,
                              "summary.housingExpenseRatio.classification"
                            )
                          )}`}
                        >
                          <div className="title">
                            {intl.get(
                              "currentStanding.lifeline.housingExpenseRatio"
                            )}
                          </div>
                          <div className="rating">{`${Number(
                            Get(
                              !lifelineData
                                ? lifelineObject[
                                    classificationEnumsAllCapitalized[
                                      classification
                                    ]
                                  ]
                                : lifelineData,
                              "summary.housingExpenseRatio.value"
                            ) || 0
                          ).toFixed(DECIMAL_SCALE)}%`}</div>
                        </div>

                        <div
                          className={`finance_overview-box finance_overview-box-S1 ${getBoxColorClass(
                            Get(
                              !lifelineData
                                ? lifelineObject[
                                    classificationEnumsAllCapitalized[
                                      classification
                                    ]
                                  ]
                                : lifelineData,
                              "summary.solvencyRatio.classification"
                            )
                          )}`}
                        >
                          <div className="title">
                            {intl.get("currentStanding.lifeline.solvencyRatio")}
                          </div>
                          <div className="rating">
                            {`${Number(
                              Get(
                                !lifelineData
                                  ? lifelineObject[
                                      classificationEnumsAllCapitalized[
                                        classification
                                      ]
                                    ]
                                  : lifelineData,
                                "summary.solvencyRatio.value"
                              ) || 0
                            ).toFixed(DECIMAL_SCALE)}%`}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
              {/* <ScrollUpButton sectionId={"scroll-section"} /> */}
            </div>
          </div>
          <MalabarDefaultRightMenu
            readOnlyAccess={readOnlyAccessComponent()}
            // onSaveClick={saveHandler}
            enableSaveButton={false}
            enableClearButton={false}
          />
          <ArrowMenu nextDescription={"next"} prevDescription={"prev"} />
        </div>
      </React.Fragment>
    </LifeLineContext.Provider>
  )
}

function mapStateToProps(state) {
  return {
    activeAccessLevel: state.appSettings,
    currentLanguage: state.appSettings.currentLanguage,
    lifelineObject: state.currentStanding.clientCurrentStanding,
    hasAcknowledgedTermsAndConditions:
      state.entity.hasAcknowledgedTermsAndConditions,
    statusOfClient: state.entity.statusOfClient,
    statusOfPartner:
      state.entity && state.entity.partner && state.entity.partner.status
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LifeLine)
