import React from "react"
import { useSelector } from "react-redux"
import { Field } from "react-final-form"
import {
  renderDateFieldCustom,
  renderTextBoxCustom,
  renderTextBoxPercentage,
  renderCurrencyCustom,
  renderSelectEnumFieldCustom,
  renderSelectFieldCustom,
  renderNumberCustom,
  renderMoneyCustom,
  renderCheckboxCustom,
  fileFieldRender,
  renderPercentageCustom,
  renderPhoneNumberFieldCustom,
  renderPhoneNumberSimpleFieldCustom,
  renderPasswordCustom,
  renderPasswordSimple,
  renderMoneyWithPercentageCustom,
  renderRadioCustom,
  renderPhoneNumber,
  renderYearCustom,
  renderTextAreaCustomKYCC,
  renderPickerFieldSelection,
  renderDateTimeFieldCustom
} from "../../helpers/FormRender"
import {
  email,
  required,
  composeValidators,
  yearLiesInCorrectRange,
  validateMoney
} from "../../helpers/Validator"
import AutoSuggest from "../AutoSuggest/AutoSuggest"

export const FieldType = {
  TEXT: { name: "TEXT", getComponent: () => renderTextBoxCustom },
  YEAR: { name: "YEAR", getComponent: () => renderYearCustom },
  AREA: { name: "AREA", getComponent: () => renderTextAreaCustomKYCC },

  PASSWORD: { name: "PASSWORD", getComponent: () => renderPasswordCustom },
  PASSWORD_SIMPLE: {
    name: "PASSWORD",
    getComponent: () => renderPasswordSimple
  },
  MONEY: { name: "MONEY", getComponent: () => renderCurrencyCustom },
  MONEY_WITH_CURRENCY: {
    name: "MONEY_WITH_CURRENCY",
    getComponent: () => renderMoneyCustom
  },
  MONEY_WITH_PERCENTAGE: {
    name: "MONEY_WITH_PERCENTAGE",
    getComponent: () => renderMoneyWithPercentageCustom
  },
  SELECTION: {
    name: "SELECTION",
    getComponent: () => renderSelectFieldCustom
  },
  PICKER_TABLE: {
    name: "PICKER_TABLE",
    getComponent: () => renderPickerFieldSelection
  },
  ENUM_SELECTION: {
    name: "ENUM_SELECTION",
    getComponent: () => renderSelectEnumFieldCustom
  },
  DATE: { name: "DATE", getComponent: () => renderDateFieldCustom },
  DATETIME: { name: "DATETIME", getComponent: () => renderDateTimeFieldCustom },
  NUMBER: { name: "NUMBER", getComponent: () => renderNumberCustom },

  EMAIL: { name: "EMAIL", getComponent: () => renderTextBoxCustom },
  CHECKBOX: { name: "CHECKBOX", getComponent: () => renderCheckboxCustom },
  // CHECKBOX: { name: 'CHECKBOX', getComponent: () => renderCheckboxRememberMe },
  FILE: { name: "FILE", getComponent: () => fileFieldRender },
  PERCENTAGE: {
    name: "PERCENTAGE",
    getComponent: () => renderPercentageCustom
  },
  PHONE_NUMBER: {
    name: "PHONE_NUMBER",
    getComponent: () => renderPhoneNumberFieldCustom
  },
  PHONE_NUMBER_V2: {
    name: "PHONE_NUMBER_V2",
    getComponent: () => renderPhoneNumber
  },
  PHONE_NUMBER_SIMPLE: {
    name: "PHONE_NUMBER_SIMPLE",
    getComponent: () => renderPhoneNumberSimpleFieldCustom
  },
  CONTAINER: {
    name: "CONTAINER"
  },
  RADIO: {
    name: "RADIO",
    getComponent: () => renderRadioCustom
  },
  AUTOSUGGEST: {
    name: "AUTOSUGGEST",
    getComponent: () => AutoSuggest
  }
}

function DateWrapper({ item, index, children }) {
  return (
    <div className="portal-form-row dateformat" key={index}>
      <label>
        {item.label} {item.required && <span>*</span>}
      </label>
      {children}
    </div>
  )
}

function CheckboxWrapper({ item, index, children }) {
  return (
    <div className="portal-form-row checkbox" key={index}>
      <label>
        {item.label}
        {/* {item.required && <span>*</span> */}
      </label>
      {children}
    </div>
  )
}

function RenderSimpleFields(props) {
  const { onlyRequiredFields } = useSelector((state) => state.users)
  const { defaultCurrency } = useSelector((state) => state.personalDetails)

  const { fields } = props
  let filteredFields
  if (onlyRequiredFields) {
    filteredFields = fields.filter((item) => item.required || item.showAlways)
  } else {
    filteredFields = [...fields]
  }
  return filteredFields.map((item, index) => {
    item.defaultCurrency = defaultCurrency
    const {
      type,
      selectionData,
      checkboxLabel,
      required: isRequired,
      allowNegative,
      content,
      readOnly,
      validator,
      showAlways = false,
      ...otherProps
    } = item

    if (type.name === FieldType.CONTAINER.name) {
      return content
    }

    const properties = {
      ...otherProps,
      component: type.getComponent(),
      validate: validator ? validator : isRequired ? required : undefined,

      required: isRequired,
      isLabel: true,
      data: selectionData,
      disabled: readOnly,
      allowNegative: allowNegative
    }

    let Wrapper
    if (type.name === "YEAR") {
      // properties.validate = composeValidators(yearLiesInCorrectRange)
      properties.component = renderYearCustom
    }
    if (type.name === FieldType.DATE.name) {
      Wrapper = DateWrapper
    } else if (type.name === FieldType.PERCENTAGE.name) {
      if (allowNegative) {
        properties.allowNegative = true
      }
    } else if (type.name === FieldType.EMAIL.name) {
      if (isRequired) {
        properties.validate = composeValidators(required, email)
      } else {
        properties.validate = email
      }
    } else if (type.name === FieldType.CHECKBOX.name) {
      if (item.label) {
        Wrapper = CheckboxWrapper
      }
      properties.label = checkboxLabel || "Yes"
      properties.validate = undefined
    } else if (type.name === FieldType.RADIO.name) {
      properties.type = "radio"
    }

    if (type.name === FieldType.RADIO.name) {
      return (
        <div className="portal-form-row" key={index}>
          <label htmlFor={properties.name}>
            {properties.label} {properties.required && <span>*</span>}
          </label>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%"
            }}
          >
            {properties.data
              ? properties.data.map((data, key) => {
                  const prop = {
                    ...properties,
                    key: `${data.key}_${key}`,
                    type: "radio",
                    option: {
                      value: data.value,
                      label: data.label
                    }
                  }
                  return <Field {...prop} type="radio" value={data.value} />
                })
              : null}
          </div>
        </div>
      )
    }
    if (Wrapper) {
      return (
        <Wrapper item={item} key={index}>
          <Field {...properties} />
        </Wrapper>
      )
    }
    if (type.name === "MONEY_WITH_CURRENCY") {
      properties.validate = isRequired
        ? composeValidators(validateMoney, required)
        : undefined
    }
    return <Field key={index} {...properties} />
  })
}

export default RenderSimpleFields
