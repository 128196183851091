import React from "react"
import intl from "react-intl-universal"
import ClassificationSelection from "../components/_hook/ClassificationSelection"
import RenderSimpleFields, {
  FieldType
} from "../components/Form/RenderSimpleFields"
import { CountryList } from "../constants/Lists"
import { translateListLabel } from "../helpers/list-translation"
import { Field } from "react-final-form"
import { fileFieldRender } from "../helpers"

const CarFinancingType = [
  {
    label: "None",
    value: "NONE"
  },
  {
    label: "Loan",
    value: "LOAN"
  },
  {
    label: "Car Lease",
    value: "CAR_LEASE"
  },
  {
    label: "Hire Purchase",
    value: "HIRE_PURCHASE"
  }
]

function MotorVehicleMainForm({
  client,
  partner,
  isJoint,
  readOnly = false,
  onCarLeaseChange = null,
  values,
  setFormAttribute
}) {
  let fields = [
    {
      name: "registrationNumber",
      label: intl.get("field.uniqueCode"),
      type: FieldType.TEXT,
      required: true
    },
    {
      name: "vehicleLocation",
      type: FieldType.SELECTION,
      label: intl.get("field.locationOfMotorVehicle"),
      selectionData: translateListLabel(CountryList),
      valueProperty: "code",
      labelProperty: "name"
    },
    {
      name: "modelManufacturer",
      label: intl.get("field.modelManufacturer"),
      type: FieldType.TEXT,
      required: false
    },
    {
      name: "modelNumber",
      label: intl.get("field.modelNumber"),
      type: FieldType.TEXT
    },
    {
      name: "modelYear",
      label: intl.get("field.modelYear"),
      type: FieldType.YEAR,
      min: 1900,
      max: new Date().getFullYear()
      // numberFormat: "####"
    },

    {
      name: "currentValue",
      label: intl.get("field.currentValue"),
      type: FieldType.MONEY_WITH_CURRENCY,
      required: true,
      allowNegative: true
    },
    {
      name: "valuationDate",
      label: intl.get("field.valuationDate"),
      type: FieldType.DATE,
      required: true
    },
    {
      name: "annualDepreciationRate",
      label: intl.get("field.annualDepreciationRate"),
      type: FieldType.PERCENTAGE,
      required: false
    },
    {
      name: "carFinancing",
      type: FieldType.SELECTION,
      label: intl.get("field.carFinancing"),
      selectionData: CarFinancingType,
      valueProperty: "value",
      labelProperty: "label",
      onChange: onCarLeaseChange,
      showAlways: true
    },
    {
      name: "insured",
      label: intl.get("field.isMotorVehicleInsured"),
      checkboxLabel: intl.get("YesNoEnum_YES"),
      type: FieldType.CHECKBOX,
      showAlways: true
    }
  ]
  if (
    (values &&
      values.vehicleLocation &&
      values.vehicleLocation.toLowerCase() === "my") ||
    (values &&
      values.vehicleLocation &&
      values.vehicleLocation.toLowerCase() === "sg")
  ) {
    fields.splice(
      9,
      0,
      ...[
        {
          name: "coeStartDate",
          label: intl.get("field.coeStartDate"),
          type: FieldType.DATE
        },
        {
          name: "certificateOfEntitlementValue",
          label: intl.get("field.coeValue"),
          type: FieldType.MONEY_WITH_CURRENCY,
          allowNegative: false
        },
        {
          name: "coeValuationDate",
          label: intl.get("field.coeValuationDate"),
          type: FieldType.DATE
        }
      ]
    )
  }

  if (readOnly) {
    fields = fields.map((field) => ({ ...field, readOnly: true }))
  }

  return (
    // 20200915 MABT-415 toggle to show side panel
    <div className={"portal-form-half"}>
      <div className="subtitle2">{intl.get("motorVehicle.subtitle")}</div>
      <ClassificationSelection
        client={client}
        partner={partner}
        isJoint={isJoint}
        hideJoint={!isJoint || readOnly}
      />
      <RenderSimpleFields fields={fields} />
      <Field
        component={fileFieldRender}
        customClassName="mt-1 d-block"
        labelIdle="Drag, Drop or Browse"
        label="Upload Documents"
        onupdatefiles={(fileItems) => {
          setFormAttribute("proofDocument", fileItems[0])
        }}
        fileInformation={(values && values.proofDocument) || null}
      />
    </div>
  )
}

export default MotorVehicleMainForm
