import React, { useContext } from 'react';
import { Field } from 'react-final-form';
import { renderDateFieldCustom, renderCurrencyCustom } from './../../../helpers/FormRender';
import intl from 'react-intl-universal';
import { composeValidators, getSymbolFromCode } from '../../../helpers';
import { isValidDate } from '../../../helpers/Validator';
import CpfLessBorrowing from './CpfLessBorrowing';
import NumberFormat from 'react-number-format';
import CPFInvestmentProperty from './CPFInvestmentProperty';
import { enumOptionsActions, personalDetailsActions } from '../../../actions';
import { connect } from 'react-redux';
import { AppContext } from '../../../context/AppContext';
import { bindActionCreators } from 'redux';
import CPFResidenceProperty from './CPFResidenceProperty';
import CPFChildrenEducation from './CPFChildrenEducation';
function CPFSummary({ mutators, model, optionsData,
    personalDetails }) {
    const {
        modal: { setModal, clearModal },
    } = useContext(AppContext);
    const client = personalDetails;
    const partner = personalDetails.partner || {};
    const isJoint = personalDetails.partner || false;

    const cpfLessBorrowingSelected = (value) => {

    }
    const editInvestmentProperty = (model, idx) => {
        const handleSave = (model, index) => {
            mutators.update('cpfSummary.lessBorrowings.investmentPropertyList', idx, model);
            clearModal();
        }
        setModal({
            title: intl.get(
                'cashflowlisting.investmentOutflow.investmentProperty'
            ),
            content: <CPFInvestmentProperty
                optionsData={optionsData}
                client={client}
                partner={partner}
                isJoint={isJoint}
                model={model}
                handleSave={handleSave}
            />,
            formType: 'InvestmentProperty',
        });
    };

    const editResidentalProperty = (model, idx) => {
        const handleSave = (model, index) => {
            mutators.setValue('cpfSummary.lessBorrowings.residentialProperty', model);
            clearModal();
        }
        setModal({
            title: 'Residental Property',
            content: <CPFResidenceProperty
                optionsData={optionsData}
                client={client}
                partner={partner}
                isJoint={isJoint}
                model={model}
                handleSave={handleSave}
            />,
            formType: 'homeExpenses',
        });
    };

    const editChildrenEducation = (model, idx) => {
        const handleSave = (model, index) => {
            mutators.update('cpfSummary.lessBorrowings.childrenEducationList', idx, model);
            clearModal();
        }
        setModal({
            title: 'Children Education',
            content: <CPFChildrenEducation
                optionsData={optionsData}
                client={client}
                partner={partner}
                isJoint={isJoint}
                model={model}
                handleSave={handleSave}
            />,
            formType: 'childrenEducation',
        });
    };

    const deleteInvestmentProperty = (idx) => {
        mutators.remove('cpfSummary.lessBorrowings.investmentPropertyList', idx);
    };

    const deleteResidentalProperty = (idx) => {
        mutators.setValue('cpfSummary.lessBorrowings.residentialProperty', null);
    };

    const deleteChildrenEducation = (idx) => {
        mutators.remove('cpfSummary.lessBorrowings.childrenEducationList', idx);
    };

    const investmentProperties = () => {
        if (model.cpfSummary) {
            if (model.cpfSummary.lessBorrowings && Array.isArray(model.cpfSummary.lessBorrowings.investmentPropertyList)) {
                return model.cpfSummary.lessBorrowings.investmentPropertyList.map((a, idx) => (
                    <tr key={idx}>
                        <td className="text-right">
                            Investment Property
                        </td>
                        <td>
                            <NumberFormat
                                value={a.currentValue.value}
                                displayType={'text'}
                                allowNegative
                                decimalScale={2}
                                prefix={getSymbolFromCode(a.currentValue.currency)}
                                className={`text-input text-input-figure `}
                                readOnly={true}
                            />
                        </td>
                        <td>
                            <NumberFormat
                                value={a.currentValue.value}
                                displayType={'text'}
                                allowNegative
                                decimalScale={2}
                                prefix={getSymbolFromCode(a.currentValue.currency)}
                                className={`text-input text-input-figure `}
                                readOnly={true}
                            />
                        </td>
                        <td>
                            <NumberFormat
                                value={a.currentValue.value}
                                displayType={'text'}
                                allowNegative
                                decimalScale={2}
                                prefix={getSymbolFromCode(a.currentValue.currency)}
                                className={`text-input text-input-figure `}
                                readOnly={true}
                            />
                        </td>
                        <td>
                            <NumberFormat
                                value={a.currentValue.value}
                                displayType={'text'}
                                allowNegative
                                decimalScale={2}
                                prefix={getSymbolFromCode(a.currentValue.currency)}
                                className={`text-input text-input-figure `}
                                readOnly={true}
                            />
                        </td>
                        <td>
                            <button
                                onClick={() => editInvestmentProperty(a, idx)}
                                className="btn btn-edit btn-padding"
                            />
                            <button
                                onClick={() => deleteInvestmentProperty(idx)}
                                className="btn-remove"
                            />

                        </td>
                    </tr>
                ))

            }
        }
    };

    const residentalProperties = () => {
        if (model.cpfSummary) {
            if (model.cpfSummary.lessBorrowings && model.cpfSummary.lessBorrowings.residentialProperty) {
                const a = model.cpfSummary.lessBorrowings.residentialProperty;
                return (
                    <tr >
                        <td className="text-right">
                            Residental Property
                        </td>
                        <td>
                            <NumberFormat
                                value={a.propertyDetails.currentValue.value}
                                displayType={'text'}
                                allowNegative
                                decimalScale={2}
                                prefix={getSymbolFromCode(a.propertyDetails.currentValue.currency)}
                                className={`text-input text-input-figure `}
                                readOnly={true}
                            />
                        </td>
                        <td>
                            <NumberFormat
                                value={a.propertyDetails.currentValue.value}
                                displayType={'text'}
                                allowNegative
                                decimalScale={2}
                                prefix={getSymbolFromCode(a.propertyDetails.currentValue.currency)}
                                className={`text-input text-input-figure `}
                                readOnly={true}
                            />
                        </td>
                        <td>
                            <NumberFormat
                                value={a.propertyDetails.currentValue.value}
                                displayType={'text'}
                                allowNegative
                                decimalScale={2}
                                prefix={getSymbolFromCode(a.propertyDetails.currentValue.currency)}
                                className={`text-input text-input-figure `}
                                readOnly={true}
                            />
                        </td>
                        <td>
                            <NumberFormat
                                value={a.propertyDetails.currentValue.value}
                                displayType={'text'}
                                allowNegative
                                decimalScale={2}
                                prefix={getSymbolFromCode(a.propertyDetails.currentValue.currency)}
                                className={`text-input text-input-figure `}
                                readOnly={true}
                            />
                        </td>
                        <td>
                            <button
                                onClick={() => editResidentalProperty(a, 0)}
                                className="btn btn-edit btn-padding"
                            />
                            <button
                                onClick={() => deleteResidentalProperty(0)}
                                className="btn-remove"
                            />

                        </td>
                    </tr>
                )
            }
        }
    }

    const childrenEducations = () => {
        if (model.cpfSummary) {
            if (model.cpfSummary.lessBorrowings && Array.isArray(model.cpfSummary.lessBorrowings.childrenEducationList)) {
                return model.cpfSummary.lessBorrowings.childrenEducationList.map((a, idx) => (
                    <tr key={idx}>
                        <td className="text-right">
                            Children Education
                        </td>
                        <td>
                            <NumberFormat
                                value={a.currentLoanBalance.value}
                                displayType={'text'}
                                allowNegative
                                decimalScale={2}
                                prefix={getSymbolFromCode(a.currentLoanBalance.currency)}
                                className={`text-input text-input-figure `}
                                readOnly={true}
                            />
                        </td>
                        <td>
                            <NumberFormat
                                value={a.currentLoanBalance.value}
                                displayType={'text'}
                                allowNegative
                                decimalScale={2}
                                prefix={getSymbolFromCode(a.currentLoanBalance.currency)}
                                className={`text-input text-input-figure `}
                                readOnly={true}
                            />
                        </td>
                        <td>
                            <NumberFormat
                                value={a.currentLoanBalance.value}
                                displayType={'text'}
                                allowNegative
                                decimalScale={2}
                                prefix={getSymbolFromCode(a.currentLoanBalance.currency)}
                                className={`text-input text-input-figure `}
                                readOnly={true}
                            />
                        </td>
                        <td>
                            <NumberFormat
                                value={a.currentLoanBalance.value}
                                displayType={'text'}
                                allowNegative
                                decimalScale={2}
                                prefix={getSymbolFromCode(a.currentLoanBalance.currency)}
                                className={`text-input text-input-figure `}
                                readOnly={true}
                            />
                        </td>
                        <td>
                            <button
                                onClick={() => editChildrenEducation(Object.assign(a, { classification: model.classification }), idx)}
                                className="btn btn-edit btn-padding"
                            />
                            <button
                                onClick={() => deleteChildrenEducation(idx)}
                                className="btn-remove"
                            />

                        </td>
                    </tr>
                ))

            }
        }
    }

    return (
        <div className="CPF-Summary">
            <table className="cpf-summary-table">
                <thead>
                    <tr>
                        <th className="d-flex align-items-center first-col">
                            <label className="mr-2">
                                {intl.get('centralProvidenceFundInformation.valuationDate')}
                            </label>
                            <div className="w-100">
                                <Field
                                    name="valuationDate"
                                    component={renderDateFieldCustom}
                                    isForGrid={true}
                                    validate={composeValidators(isValidDate)}
                                />
                            </div>

                        </th>
                        <th >
                            <div className="title-box">
                                {intl.get('centralProvidenceFundInformation.ordinaryAccount')}
                            </div>
                        </th>
                        <th>
                            <div className="title-box">
                                {intl.get('centralProvidenceFundInformation.specialAccount')}
                            </div>
                        </th>
                        <th>
                            <div className="title-box">
                                {intl.get('centralProvidenceFundInformation.medisaveAccount')}
                            </div>
                        </th>
                        <th>
                            <div className="title-box">
                                {intl.get('centralProvidenceFundInformation.retirementAccount')}
                            </div>
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="first-col">
                            Cash Account
                        </td>
                        <td>
                            <Field
                                name="cashaccount.ordinaryAccount"
                                component={renderCurrencyCustom}
                                isForGrid={true}
                                isRounded
                            />
                        </td>
                        <td>
                            <Field
                                name="cashaccount.specialAccount"
                                component={renderCurrencyCustom}
                                isForGrid={true}
                                isRounded
                            />
                        </td>
                        <td>
                            <Field
                                name="cashaccount.medisaveAccount"
                                component={renderCurrencyCustom}
                                isForGrid={true}
                                isRounded
                            />
                        </td>
                        <td>
                            <Field
                                name="cashaccount.retirementAccount"
                                component={renderCurrencyCustom}
                                isForGrid={true}
                                isRounded
                            />
                        </td>

                    </tr>
                    <tr>
                        <td className="first-col">
                            CPFIS / CPF Life
                        </td>
                        <td>
                            <Field
                                name="CPFIS.ordinaryAccount"
                                component={renderCurrencyCustom}
                                isForGrid={true}
                                isRounded
                            />
                        </td>
                        <td>
                            <Field
                                name="CPFIS.specialAccount"
                                component={renderCurrencyCustom}
                                isForGrid={true}
                                isRounded
                            />
                        </td>
                        <td>
                            <Field
                                name="CPFIS.medisaveAccount"
                                component={renderCurrencyCustom}
                                isForGrid={true}
                                isRounded
                            />
                        </td>
                        <td>
                            <Field
                                name="CPFIS.retirementAccount"
                                component={renderCurrencyCustom}
                                isForGrid={true}
                                isRounded
                            />
                        </td>

                    </tr>
                    <tr>
                        <td colSpan="5" >
                            Less Borrowing
                            <CpfLessBorrowing model={model} onClick={cpfLessBorrowingSelected} mutators={mutators} />
                        </td>
                    </tr>
                    {investmentProperties()}
                    {residentalProperties()}
                    {childrenEducations()}
                </tbody>
            </table>

        </div>


    );
}


function mapStateToProps(state) {
    const { optionsData } = state.enumOptions;
    const { personalDetails } = state;

    return {
        optionsData,
        personalDetails: personalDetails.data
    };
}


function mapDispatchToProps(dispatch) {

    return bindActionCreators(enumOptionsActions, personalDetailsActions, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CPFSummary);
