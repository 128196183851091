import React, { useContext } from 'react';
import intl from 'react-intl-universal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { RenderSimpleFields } from '../../../components';
import { FieldType } from '../../../components/Form/RenderSimpleFields';
import { enumOptionsActions } from '../../../actions/EnumOptionsActions';
import { getSymbolFromCode, renderCurrencyCustom, renderPercentageCustom } from '../../../helpers';
import NetWorthFormButtons from '../NetWorthFormButtons';
import { renderCheckboxOnlyCustom } from '../../../helpers/FormRenderBootstrap';
import { AppContext } from '../../../context/AppContext';
import Get from '../../../helpers/NullHelper';




function CentralProvidenceBoardDetailsRightSide({ values, optionsData, mutators }) {
    const {
        modal: { setModal, clearModal },
    } = useContext(AppContext);
    
    

    const cpfisOnChange = (value) => {
        if (!value) {
            mutators.setValue(
                'sourceOfFundOa',
                false
            );

            mutators.setValue(
                'sourceOfFundSa',
                false
            );
        }

    }
    return (
        <React.Fragment>
            <div className="subtitle2">
                Balance Components Summary
            </div>
            <RenderSimpleFields
                fields={[
                    {
                        name: 'valuationDate',
                        label: 'Valuation Date',
                        type: FieldType.DATE,
                    },
                    {
                        name: 'balCpfOa',
                        label: 'CPF - OA',
                        type: FieldType.MONEY,
                        symbolPrefix: getSymbolFromCode('SGD')

                    },
                    {
                        name: 'balCpfSa',
                        label: 'CPF - SA',
                        type: FieldType.MONEY,
                        symbolPrefix: getSymbolFromCode('SGD')

                    },
                    {
                        name: 'balCpfMediSave',
                        label: 'CPF - MedSave',
                        type: FieldType.MONEY,
                        symbolPrefix: getSymbolFromCode('SGD')

                    },
                    {
                        name: 'balCpfRA',
                        label: 'CPF - RA',
                        type: FieldType.MONEY,
                        symbolPrefix: getSymbolFromCode('SGD')

                    },
                    {
                        name: 'balCpfIntegratedShield',
                        label: 'CPF - Integrated Shield',
                        type: FieldType.MONEY,
                        symbolPrefix: getSymbolFromCode('SGD')

                    }
                ]}
            />
            
            <RenderSimpleFields
                fields={[
                    {
                        name: 'annualEldershieldPremium',
                        label: 'Annual Eldershield Premium Payment',
                        type: FieldType.MONEY_WITH_CURRENCY,
                        defaultCurrency: 'SGD'
                    },
                    {
                        name: 'annualDependentProtectionScheme',
                        label: 'Annual Dependent\'s Protection Scheme Payment',
                        type: FieldType.MONEY_WITH_CURRENCY,
                        defaultCurrency: 'SGD'
                    },
                    {
                        name: 'annualMedishieldLifePremium',
                        label: 'Annual Medishield Premium Payment',
                        type: FieldType.MONEY_WITH_CURRENCY,
                        defaultCurrency: 'SGD'
                    },
                ]}
            />
            <div className="portal-form-row d-flex align-items-center">
                <label>
                    Do you have an Integrated Shield Plan?
                </label>
                <div className="row-holder d-flex">
                    <div className="input-check">
                        <Field
                            name="haveIntegratedShieldPlan"
                            component={renderCheckboxOnlyCustom}
                        />
                    </div>

                </div>
            </div>
            <div className="portal-form-row d-flex align-items-center">
                <label>
                    Do you participate in CPFIS?
                </label>
                <div className="row-holder d-flex">
                    <div className="input-check">
                        <Field
                            name="doYouParticipateInCPFIS"
                            component={renderCheckboxOnlyCustom}
                            onChange={cpfisOnChange}
                        />
                    </div>

                </div>
            </div>
            {values.doYouParticipateInCPFIS && (
                <div className="portal-form-row d-flex align-items-center">
                    <label>
                        Source of Fund
                    </label>
                    <div className="row-holder d-flex">
                        <div className="input-check">
                            <Field
                                name="sourceOfFundOa"
                                component={renderCheckboxOnlyCustom}
                                label="OA"
                            />
                        </div>
                        <div className="input-check">
                            <Field
                                name="sourceOfFundSa"
                                component={renderCheckboxOnlyCustom}
                                label="SA"
                            />
                        </div>
                    </div>
                </div>
            )}

            <div className="portal-form-row d-flex align-items-center">
                <label>
                    Do you have a Retirement Account (RA)?
                </label>
                <div className="row-holder">
                    <div className="input-check">
                        <Field
                            name="haveRA"
                            component={renderCheckboxOnlyCustom}
                        />
                    </div>
                </div>
            </div>
            <div className="portal-form-row d-flex align-items-center">
                <label>
                    Do you have CPF Life Plan?
                </label>
                <div className="row-holder">
                    <div className="input-check">
                        <Field
                            name="haveCPFLifePlan"
                            component={renderCheckboxOnlyCustom}
                        />
                    </div>
                </div>

            </div>
            <div className="subtitle2">Fee</div>
            <div className="portal-form-row align-items-center">
                <label>{intl.get('field.annualAdviserFee')}</label>
                <div className="row-holder d-flex align-items-center">
                    <Field
                        name="annualAdvsorFeePercent"
                        label={intl.get('field.annualAdviserFee')}
                        component={renderPercentageCustom}
                    />
                or
                <Field
                        name="annualAdvsorFeeAmount"
                        label={intl.get('field.annualAdviserFee')}
                        component={renderCurrencyCustom}
                        isMoney={true}
                        currency={'SGD'}
                        symbolPrefix={getSymbolFromCode('SGD')}

                    />
                pa
                </div>

            </div>
        </React.Fragment>
    );
}

function mapStateToProps(state) {
    const { optionsData } = state.enumOptions;
    return {
        optionsData,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(enumOptionsActions, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CentralProvidenceBoardDetailsRightSide);
