import { isNullOrUndefined } from "util";
import { getNewCurrencyValue } from "../../../helpers";

export const getDirectVariableValue = (objVar, currency) => {
    if (isNullOrUndefined(objVar)) {
        return {
            clientTotal: 0,
            partnerTotal: 0,
            jointTotal: 0,
        }
    }

    return {
        clientTotal: objVar.hasOwnProperty('client') && !isNullOrUndefined(objVar.client) ? getNewCurrencyValue(
            objVar.client,
            currency
        ) : 0,
        partnerTotal: objVar.hasOwnProperty('partner') && !isNullOrUndefined(objVar.partner) ? getNewCurrencyValue(
            objVar.partner,
            currency
        ) : 0,
        jointTotal: objVar.hasOwnProperty('joint') && !isNullOrUndefined(objVar.joint) ? getNewCurrencyValue(
            objVar.joint,
            currency
        ) : 0
    };
}
