import { eTFHoldingInformationAction } from '../../../constants/ActionConstants';

export function eTFHoldingInformation(state = {}, action) {
    switch (action.type) {
        case eTFHoldingInformationAction.GET_ETFHOLDINGINFORMATION_REQUEST:
            return {
                loading: true
            };
        case eTFHoldingInformationAction.GET_ETFHOLDINGINFORMATION_SUCCESS:
            return {
                items: action.data
            };
        case eTFHoldingInformationAction.GET_ETFHOLDINGINFORMATION_FAILURE:
            return {
                error: action.error
            };
        case eTFHoldingInformationAction.ADD_ETFHOLDINGINFORMATION_REQUEST:
            return {
                loading: true
            };
        case eTFHoldingInformationAction.ADD_ETFHOLDINGINFORMATION_SUCCESS:
            return {
                items: action.response
            };
        case eTFHoldingInformationAction.ADD_ETFHOLDINGINFORMATION_FAILURE:
            return {
                error: action.error
            };
        case eTFHoldingInformationAction.EDIT_ETFHOLDINGINFORMATION_REQUEST:
            return {
                loading: true
            };
        case eTFHoldingInformationAction.EDIT_ETFHOLDINGINFORMATION_SUCCESS:
            return {
                items: action.response
            };
        case eTFHoldingInformationAction.EDIT_ETFHOLDINGINFORMATION_FAILURE:
            return {
                error: action.error
            };
        case eTFHoldingInformationAction.DELETE_ETFHOLDINGINFORMATION_REQUEST:
            return {
                loading: true
            };
        case eTFHoldingInformationAction.DELETE_ETFHOLDINGINFORMATION_SUCCESS:
            return {
                items: action.response
            };
        case eTFHoldingInformationAction.DELETE_ETFHOLDINGINFORMATION_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}