import React from "react"
import intl from "react-intl-universal"
import { Form, Field, FormSpy } from "react-final-form"
import {
  ModalNoAccess,
  RenderSimpleFields,
  FormButtons,
  ClassificationSelection
} from "../components"
import { FieldType } from "../components/Form/RenderSimpleFields"
import { renderJointSelectionMenuCustom } from "../helpers"
import { nullifyFields } from "../helpers/FormUtil"
import { CountryList } from "../constants/Lists"
import { translateListLabel } from "../helpers/list-translation"
import { DECIMAL_SCALE } from "../constants/NumberFormatConstants"
import LoanRepaymentAmountWithInterestCalculation from "../services/LoanRepaymentAmountWithInterestCalculation"
import NumberFormat from "react-number-format"

const LoanExpensesForm = ({
  handleSave,
  model,
  client,
  optionsData,
  readOnlyAccess,
  clientInformation,
  type,
  isJoint
}) => {
  console.log("model is this", model)
  const form = [
    {
      name: "financialInstitutionName",
      type: FieldType.TEXT,
      label: intl.get("liabilities.loan.field.financialInstitutionName")
      //   required: true
    },
    {
      name: "borrowingsAccountName",
      type: FieldType.TEXT,
      label: intl.get("liabilities.loan.field.borrowingsAccountName")
      //   required: true
    },
    {
      name: "borrowingsAccountNumber",
      type: FieldType.TEXT,
      label: intl.get("liabilities.loan.field.borrowingsAccountNumber"),
      required: true
    },

    {
      name: "borrowingsLocation",
      type: FieldType.SELECTION,
      label: intl.get("liabilities.loan.field.borrowingsLocation"),
      selectionData: translateListLabel(CountryList).map((item) => ({
        ...item,
        value: item.code,
        label: item.name
      }))
    },
    {
      name: "borrowingsCurrency",
      type: FieldType.ENUM_SELECTION,
      enumData: optionsData.currencyOptions,
      label: intl.get("liabilities.loan.field.borrowingsCurrency"),
      required: true
    },
    {
      name: "borrowingsPrinciple",
      type: FieldType.MONEY,
      label: intl.get("liabilities.loan.field.borrowingsPrinciple"),
      required: true,
      allowNegative: false
    },
    {
      name: "borrowingsStartDate",
      type: FieldType.DATE,
      label: intl.get("liabilities.loan.field.borrowingsStartDate"),
      required: true
    },
    {
      name: "borrowingsTermInYears",
      type: FieldType.NUMBER,
      label: intl.get("liabilities.loan.field.borrowingsTermInYears"),
      required: true
    },
    {
      name: "borrowingsAnnualInterestRate",
      type: FieldType.PERCENTAGE,
      label: intl.get("liabilities.loan.field.borrowingsAnnualInterestRate"),
      required: true
    },
    {
      name: "currentBorrowingsBalance",
      type: FieldType.MONEY,
      label: intl.get("liabilities.loan.field.currentBorrowingsBalance"),
      required: true,
      allowNegative: false
    },
    {
      name: "valuationDate",
      type: FieldType.DATE,
      label: intl.get("liabilities.loan.field.valuationDate")
    },
    {
      name: "borrowingsRepaymentFrequency",
      type: FieldType.ENUM_SELECTION,
      enumData: optionsData.frequencyOptions,
      required: true,
      label: intl.get("liabilities.loan.field.borrowingsRepaymentFrequency")
    },

    {
      name: "borrowingsRepaymentAmount",
      type: FieldType.MONEY,
      label: intl.get("field.loan.loanRepaymentAmount"),
      required: false,
      allowNegative: false,
      readOnly: true
    },
    {
      name: "additionalPaymentAmount",
      type: FieldType.MONEY,
      label: intl.get("liabilities.loan.field.additionalPaymentAmount"),
      allowNegative: false
    },
    {
      name: "additionalRepaymentHandlingFees",
      type: FieldType.MONEY,
      label: intl.get("liabilities.loan.field.additionalRepaymentHandlingFees"),
      allowNegative: false
    },
    {
      name: "additionalRepaymentFrequency",
      type: FieldType.ENUM_SELECTION,
      enumData: optionsData.frequencyOptions,
      label: intl.get("liabilities.loan.field.additionalRepaymentFrequency")
    },
    {
      name: "additionalRepaymentNextPaymentDate",
      type: FieldType.DATE,
      label: intl.get(
        "liabilities.loan.field.additionalRepaymentNextPaymentDate"
      )
    }
  ]
  const loanRepaymentAndInterest = (values) => {
    return LoanRepaymentAmountWithInterestCalculation(values)
  }
  const australiaOnlyForm = [
    {
      name: "borrowingsManagementFees",
      type: FieldType.MONEY,
      label: intl.get("liabilities.loan.field.borrowingsManagementFees"),
      allowNegative: false
    },
    {
      name: "borrowingsManagementFeesFrequency",
      type: FieldType.ENUM_SELECTION,
      enumData: optionsData.frequencyOptions,
      label: intl.get(
        "liabilities.loan.field.borrowingsManagementFeesFrequency"
      )
    },
    {
      name: "borrowingsManagementFeesNextPaymentDate",
      type: FieldType.DATE,
      label: intl.get(
        "liabilities.loan.field.borrowingsManagementFeesNextPaymentDate"
      )
    }
  ]
  return !readOnlyAccess ? (
    <Form
      onSubmit={handleSave}
      initialValues={model}
      render={({ handleSubmit, reset, values }) => (
        <form id="personalLoanForm" onSubmit={handleSubmit}>
          <div className="portal-form-popup">
            <ClassificationSelection />

            <div className="subtitle2">
              {intl.get(`liabilities.${type}.subtitle`)}
            </div>
            <RenderSimpleFields fields={form} />
            <FormSpy subscription={{ values: true }}>
              {({ values }) => {
                if (values.borrowingsLocation === "Australia") {
                  return <RenderSimpleFields fields={australiaOnlyForm} />
                }

                nullifyFields(
                  values,
                  australiaOnlyForm.map((item) => item.name)
                )
                return ""
              }}
            </FormSpy>
            <div className="portal-form-row">
              <label className="subtitlelabel">
                {intl.get("field.loan.loanRepaymentAmountWithInterest")}
              </label>

              <div className="row-holder">
                <NumberFormat
                  value={loanRepaymentAndInterest(values)}
                  displayType={"input"}
                  allowNegative
                  decimalScale={DECIMAL_SCALE}
                  // prefix={currency.symbol}
                  decimalSeparator={"."}
                  thousandSeparator={","}
                  className={`text-input currency-input`}
                  readOnly
                />
              </div>
            </div>
          </div>

          <FormButtons reset={reset} readOnlyAccess={readOnlyAccess} />
        </form>
      )}
    />
  ) : (
    <ModalNoAccess />
  )
}

export default LoanExpensesForm
