/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Description : Page for cashflow management (converted to react hooks)
*/

import React, { useEffect, useState, useMemo } from "react"
import { connect, useSelector } from "react-redux"
import { bindActionCreators } from "redux"
import intl from "react-intl-universal"

import { Form, FormSpy } from "react-final-form"
import arrayMutators from "final-form-arrays"
import { Prompt } from "react-router-dom"

import MalabarDefaultRightMenu from "../../components/MalabarMenu/MalabarRightMenu/MalabarDefaultRightMenu"
import ActionCreators from "../../actions/ActionCreators"
import { ScrollUpButton, ArrowMenu, ReportingCurrency } from "../../components"
import {
  PersonalExpenses,
  PersonalIncome,
  InvestmentOutflow
} from "./AccordionSection"
import CashflowGraphPanel from "./CashflowGraphPanel"
import { isEmpty, isNullOrUndefined, getSymbolFromCode } from "../../helpers"
import { countTotalValues } from "./CashFlowCounter"
import CashFlowHeaderListing from "./CashFlowHeaderListing"

function CashFlow({
  match,
  optionsData,
  cashFlow,
  personalDetails,
  getClientCashflow,
  getPersonalDetails,
  editClientCashflow
}) {
  // const [firstName, setFirstName] = useState('CLIENT');
  // const [partnerFirstName, setPartnerFirstName] = useState('PARTNER');
  const [currency, setCurrency] = useState("MYR")
  const [symbol, setSymbol] = useState(getSymbolFromCode("MYR"))
  const [isRefresh, setIsRefresh] = useState(false)
  const [cashflow, setCashflow] = useState({})

  let updateTimeout = null

  // NOTE: to optimize performance when counting total values (but need debounce on each input later)
  const totalValues = useMemo(
    () => countTotalValues(cashflow, currency),
    [cashflow, currency]
  )

  useEffect(() => {
    if (match.params && match.params.id && match.params.id !== "null") {
      // getPersonalDetails(match.params.id);
      getClientCashflow(match.params.id)
    }
  }, [match.params.id])

  useEffect(() => {
    // change the currency symbol
    setSymbol(getSymbolFromCode(currency))
  }, [currency])

  // useEffect(() => {
  //     if (!isEmpty(personalDetails)) {
  //         setFirstName(
  //             personalDetails.data.personalDetails.personalInformation
  //                 .firstName
  //         );
  //         if (!isNullOrUndefined(personalDetails.data.partner)) {
  //             setPartnerFirstName(
  //                 personalDetails.data.partner.personalDetails
  //                     .personalInformation.firstName
  //             );
  //         }
  //     }
  // }, [personalDetails]);

  useEffect(() => {
    if (isRefresh) {
      if (match.params && match.params.id && match.params.id !== "null") {
        // getPersonalDetails(match.params.id);
        getClientCashflow(match.params.id)
      }
    }

    return () => {
      setIsRefresh(false)
    }
  }, [isRefresh])

  const handleSave = async (values) => {
    const { id } = match.params
    if (match.params && match.params.id && match.params.id !== "null") {
      editClientCashflow(id, values)
    }
  }
  const { clientFullNamePreferred, partnerFullNamePreferred } = useSelector(
    (state) => state.entity
  )

  return (
    <React.Fragment>
      <div className="portal-maincontent cashflow ctrl-smallbtn">
        <div className="content-panel">
          <Form
            onSubmit={handleSave}
            initialValues={cashFlow}
            mutators={{
              ...arrayMutators
            }}
            subscription={{ submitting: true, dirty: true }}
            render={({ handleSubmit, dirty, form: { mutators } }) => (
              <form id="cashFlowForm" onSubmit={handleSubmit}>
                <Prompt when={dirty} message={intl.get("unsavedDataMessage")} />
                <h1>{intl.get("cashflow")}</h1>
                <ReportingCurrency
                  onChange={(currencyVal) => setCurrency(currencyVal)}
                  defaultCurrency={currency}
                />
                {personalDetails.data !== undefined && (
                  <React.Fragment>
                    <div className="content-panel-title">
                      <div className="title-holder">
                        <div className="title-box">
                          {clientFullNamePreferred}
                        </div>
                        <div className="title-box-green title-box-green-s">
                          {intl.get("cashflow.monthly")} {symbol}
                        </div>
                        <div className="title-box-green">
                          {intl.get("cashflow.annually")} {symbol}
                        </div>
                      </div>
                      {personalDetails.data.classification !== "SINGLE" ? (
                        <div className="title-holder">
                          <div className="title-box">
                            {partnerFullNamePreferred}
                          </div>
                          <div className="title-box-green title-box-green-s">
                            {intl.get("cashflow.monthly")} {symbol}
                          </div>
                          <div className="title-box-green">
                            {intl.get("cashflow.annually")} {symbol}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {personalDetails.data.classification !== "SINGLE" ? (
                        <div className="title-holder">
                          <div className="title-box">
                            {intl.get("cashflow.joint")}
                          </div>
                          <div className="title-box-green title-box-green-s">
                            {intl.get("cashflow.monthly")} {symbol}
                          </div>
                          <div className="title-box-green">
                            {intl.get("cashflow.annually")} {symbol}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="title-holder">
                        <div className="title-box title-box-grey">
                          {intl.get("cashflow.taxableDeductable")}
                        </div>
                      </div>
                    </div>
                    <div className="portal-form" id="scroll-top-section">
                      {cashFlow.personalIncome && (
                        <PersonalIncome
                          varPrefix={"personalIncome"}
                          optionsData={optionsData}
                          push={mutators.push}
                          incomeTotalValues={totalValues.income}
                          currency={currency}
                          onExternalUpdated={(result) => setIsRefresh(result)}
                          clientInformation={personalDetails.data}
                          values={cashFlow}
                        />
                      )}
                      {cashFlow.personalExpense && (
                        <PersonalExpenses
                          varPrefix={"personalExpense"}
                          optionsData={optionsData}
                          push={mutators.push}
                          expenseTotalValues={totalValues.expense}
                          currency={currency}
                          onExternalUpdated={(result) => setIsRefresh(result)}
                          clientInformation={personalDetails.data}
                          personalExpensesData={cashFlow.personalExpense}
                          values={cashFlow}
                        />
                      )}
                      {cashFlow.investmentOutflow && (
                        <InvestmentOutflow
                          varPrefix={"investmentOutflow"}
                          optionsData={optionsData}
                          push={mutators.push}
                          outflowTotalValues={totalValues.outflow}
                          currency={currency}
                          onExternalUpdated={(result) => setIsRefresh(result)}
                          clientInformation={personalDetails.data}
                          values={cashFlow}
                        />
                      )}
                      <div className="portal-part">
                        <CashFlowHeaderListing
                          title={intl.get("cashflow.surplusDeficitCashflow")}
                          isJointHide={
                            personalDetails.data.classification === "SINGLE"
                          }
                          isAccordion={false}
                          clientTotalValue={totalValues.total.clientTotal}
                          partnerTotalValue={totalValues.total.partnerTotal}
                          jointTotalValue={totalValues.total.jointTotal}
                          currency={currency}
                        />
                      </div>
                      <FormSpy subscription={{ values: true }}>
                        {({ values }) => {
                          // subscribe to values to count the total
                          if (updateTimeout) {
                            clearTimeout(updateTimeout)
                          }
                          updateTimeout = setTimeout(() => {
                            setCashflow(values)
                          }, 1000)
                          return null
                        }}
                      </FormSpy>

                      {/* <ScrollUpButton sectionId={"scroll-top-section"} /> */}
                    </div>
                  </React.Fragment>
                )}
              </form>
            )}
          />
        </div>
        {!isEmpty(personalDetails) && (
          <CashflowGraphPanel
            rawData={totalValues}
            currency={currency}
            isPartner={!isNullOrUndefined(personalDetails.data.partner)}
          />
        )}
        <MalabarDefaultRightMenu
          // onSaveClick={() => {
          //     document.getElementById('cashFlowForm').dispatchEvent(
          //         new Event('submit', {
          //             cancelable: true,
          //         })
          //     );
          // }}
          enableSaveButton={false}
          enableClearButton={false}
        />
        <ArrowMenu nextDescription={"next"} prevDescription={"prev"} />
      </div>
    </React.Fragment>
  )
}

function mapStateToProps(state) {
  const { optionsData } = state.enumOptions
  const { activeAccessLevel } = state.appSettings
  const { cashFlow, personalDetails } = state

  return {
    optionsData,
    cashFlow,
    personalDetails,
    activeAccessLevel
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CashFlow)
