/*
    Author : Erik (erik.malik@devstack.com.au)
    Description : Credit Card main page (modal)
*/

import React from "react"
import { connect } from "react-redux"
import { Form } from "react-final-form"
import { bindActionCreators } from "redux"
import intl from "react-intl-universal"
import { netWorthFormActions, modalWindowActions } from "../../../actions"
import { modalWindow } from "../../../constants/ModalWindowLists"
import NetWorthForm, {
  NetWorthPath,
  NetWorthFormPropsComposer
} from "../NetWorthForm"
import NetWorthFormButtons from "../NetWorthFormButtons"
import ClassificationSelection from "../../../components/Form/ClassificationSelection"
import RenderSimpleFields, {
  FieldType
} from "../../../components/Form/RenderSimpleFields"
import { getSymbolFromCode } from "../../../helpers"

import LoanApi from "../../../api/LoanApi"

class CreditCard extends NetWorthForm {
  constructor(props) {
    super(
      props,
      intl.get("liabilities.creditCard.title"),
      modalWindow.CREDIT_CARD_FORM,
      NetWorthPath.creditCards,
      "CreditCard"
    )
  }
  state = { currency: "" }

  render() {
    const { optionsData, model } = this.props
    const { handleSave, contentWrapper, readOnlyAccessComponent } = this
    const calculateRepaymentAmt = (values, mutators) => {
      if (!values.creditCardCurrentLoanBalance) {
        return 0
      }

      let data = {
        principal: values.creditCardCurrentLoanBalance || 0,
        paymentFreq: values.creditCardLoanRepaymentFrequency,
        termFreq: values.creditCardTermFrequency,
        interestRate: values.creditCardAnnualInterestRate || 0,
        term: values.termInYears || 0,
        startDate: values.valuationDate
      }

      return LoanApi.postCalculateRepayment(data).then((res) => {
        mutators.setFormAttribute(
          "creditCardRepaymentAmount",
          res.data.repaymentAmount
        )
      })
    }
    const frequencyData = [
      {
        name: "Yearly",
        value: "YEARLY"
      },
      {
        name: "Half Yearly",
        value: "HALF_YEARLY"
      },
      {
        name: "Quarterly",
        value: "QUARTERLY"
      },
      {
        name: "Monthly",
        value: "MONTHLY"
      }
    ]
    return contentWrapper(
      <Form
        onSubmit={handleSave}
        initialValues={{
          ...model,
          creditCardTermFrequency: model.isNew
            ? "YEARLY"
            : model.creditCardTermFrequency,
          creditCardLoanRepaymentFrequency: model.isNew
            ? "MONTHLY"
            : model.creditCardLoanRepaymentFrequency,
          creditCardRepaymentAmount: model.isNew
            ? ""
            : model.creditCardRepaymentAmount
        }}
        mutators={{
          setFormAttribute: ([fieldName, fieldVal], state, { changeValue }) => {
            changeValue(state, fieldName, () => fieldVal)
          }
        }}
        keepDirtyOnReinitialize
        render={({ handleSubmit, reset, values, mutators }) => (
          <form id="creditCardForm" onSubmit={handleSubmit}>
            <div className="portal-form-popup">
              <ClassificationSelection />
              <div className="subtitle2">
                {intl.get("liabilities.creditCard.subtitle")}
              </div>
              <RenderSimpleFields
                fields={[
                  {
                    name: "creditCardName",
                    label: intl.get("liabilities.creditCard.creditCardName"),
                    type: FieldType.TEXT,
                    required: true
                  },
                  {
                    name: "creditCardIssuer",
                    label: intl.get("liabilities.creditCard.creditCardIssuer"),
                    required: false,
                    type: FieldType.TEXT
                  },

                  {
                    name: "creditCardType",
                    label: intl.get("liabilities.creditCard.creditCardType"),
                    type: FieldType.TEXT
                  },
                  {
                    name: "creditCardAnnualInterestRate",
                    label: intl.get(
                      "liabilities.creditCard.creditCardAnnualInterestRate"
                    ),
                    required: true,
                    type: FieldType.PERCENTAGE
                  },
                  {
                    name: "creditCardCurrency",
                    label: intl.get(
                      "liabilities.creditCard.creditCardCurrency"
                    ),
                    type: FieldType.ENUM_SELECTION,
                    enumData: optionsData.currencyOptions,
                    onChange: (value) =>
                      this.setState({ currency: getSymbolFromCode(value) })
                  },
                  {
                    name: "creditCardCurrentLoanBalance",
                    label: intl.get(
                      "liabilities.creditCard.creditCardCurrentLoanBalance"
                    ),
                    required: true,
                    type: FieldType.MONEY,
                    symbolPrefix:
                      this.state.currency ||
                      getSymbolFromCode(model.creditCardCurrency),
                    allowNegative: false
                  },
                  {
                    name: "valuationDate",
                    label: intl.get("liabilities.creditCard.valuationDate"),
                    type: FieldType.DATE,
                    required: true
                  },
                  {
                    name: "termInYears",
                    type: FieldType.NUMBER,
                    label: "Term",
                    // intl.get(

                    numberFormat: "##",
                    required: true
                  },
                  {
                    name: "creditCardTermFrequency",
                    type: FieldType.SELECTION,
                    required: true,
                    selectionData: frequencyData,
                    valueProperty: "value",
                    labelProperty: "name",
                    label: "Term Frequency"
                  },
                  {
                    name: "creditCardLoanRepaymentFrequency",
                    label: intl.get(
                      "liabilities.creditCard.creditCardLoanRepaymentFrequency"
                    ),
                    required: true,
                    type: FieldType.ENUM_SELECTION,
                    enumData: optionsData.frequencyOptions
                  },

                  {
                    name: "creditCardLoanNextRepaymentDate",
                    label: intl.get(
                      "liabilities.creditCard.creditCardLoanNextRepaymentDate"
                    ),
                    required: true,
                    type: FieldType.DATE
                  },
                  {
                    name: "creditCardRepaymentAmount",
                    label: "Repayment Amount",
                    type: FieldType.MONEY,
                    symbolPrefix: getSymbolFromCode(values.creditCardCurrency),
                    defaultCurrency: values.creditCardCurrency,
                    disabled: true,
                    readonly: true,
                    allowNegative: false,
                    popupPickerTitle: "Calculate",
                    popupPickerEvent: (e) =>
                      calculateRepaymentAmt(values, mutators),
                    required: true
                  }
                ]}
              />

              <NetWorthFormButtons
                reset={reset}
                readOnlyAccess={readOnlyAccessComponent()}
              />
            </div>
          </form>
        )}
      />
    )
  }
}
function mapStateToProps(state) {
  return NetWorthFormPropsComposer(
    state,
    modalWindow.CREDIT_CARD_FORM,
    NetWorthPath.creditCard
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, netWorthFormActions, modalWindowActions),
    dispatch
  )
}

CreditCard = connect(mapStateToProps, mapDispatchToProps)(CreditCard)
export default CreditCard
