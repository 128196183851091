import { personalDetailsAction } from "../../constants/ActionConstants"
import { getCurrency } from "../../helpers"

export function personalDetails(state = {}, action) {
  switch (action.type) {
    case personalDetailsAction.GET_PERSONALDETAIL_SUCCESS:
      return {
        ...state,
        data: action.response,
        model: doBeforeShowDetail(action.response),
        defaultCurrency:
          action && action.response && action.response.currency
            ? action.response.currency
            : "USD"
      }
    case personalDetailsAction.ADD_PERSONALDETAIL_SUCCESS:
      return {
        items: action.response
      }
    case personalDetailsAction.EDIT_PERSONALDETAIL_SUCCESS:
      return {
        data: action.response,
        model: doBeforeShowDetail(action.response)
      }
    case personalDetailsAction.DELETE_PERSONALDETAIL_SUCCESS:
      return {
        items: action.response
      }
    case personalDetailsAction.ADD_KYCPERSONALDETAILPARTNER_REQUEST:
      return {
        items: action.response
      }
    default:
      return { ...state }
  }
}

const doBeforeShowDetail = (dataDB) => {
  if (dataDB.personalDetails) {
    if (dataDB.personalDetails.countriesOverseasAssetsAndLiabilities) {
      dataDB.personalDetails.liability =
        dataDB.personalDetails.countriesOverseasAssetsAndLiabilities[0]
      dataDB.personalDetails.countriesOverseasAssetsAndLiabilities.shift()
    }

    if (
      dataDB.personalDetails.countriesOverseasGovernmentEducationAssistanceLoan
    ) {
      dataDB.personalDetails.education =
        dataDB.personalDetails.countriesOverseasGovernmentEducationAssistanceLoan[0]
      dataDB.personalDetails.countriesOverseasGovernmentEducationAssistanceLoan.shift()
    }

    if (dataDB.personalDetails.countriesOverseasInsurancePolicies) {
      dataDB.personalDetails.insurance =
        dataDB.personalDetails.countriesOverseasInsurancePolicies[0]
      dataDB.personalDetails.countriesOverseasInsurancePolicies.shift()
    }

    if (dataDB.personalDetails.countriesOverseasPensionSchemes) {
      dataDB.personalDetails.pension =
        dataDB.personalDetails.countriesOverseasPensionSchemes[0]
      dataDB.personalDetails.countriesOverseasPensionSchemes.shift()
    }

    if (dataDB.partner) {
      if (
        dataDB.partner.personalDetails.countriesOverseasAssetsAndLiabilities
      ) {
        dataDB.partner.personalDetails.liability =
          dataDB.partner.personalDetails.countriesOverseasAssetsAndLiabilities[0]
        dataDB.partner.personalDetails.countriesOverseasAssetsAndLiabilities.shift()
      }

      if (
        dataDB.partner.personalDetails
          .countriesOverseasGovernmentEducationAssistanceLoan
      ) {
        dataDB.partner.personalDetails.education =
          dataDB.partner.personalDetails.countriesOverseasGovernmentEducationAssistanceLoan[0]
        dataDB.partner.personalDetails.countriesOverseasGovernmentEducationAssistanceLoan.shift()
      }

      if (dataDB.partner.personalDetails.countriesOverseasInsurancePolicies) {
        dataDB.partner.personalDetails.insurance =
          dataDB.partner.personalDetails.countriesOverseasInsurancePolicies[0]
        dataDB.partner.personalDetails.countriesOverseasInsurancePolicies.shift()
      }

      if (dataDB.partner.personalDetails.countriesOverseasPensionSchemes) {
        dataDB.partner.personalDetails.pension =
          dataDB.partner.personalDetails.countriesOverseasPensionSchemes[0]
        dataDB.partner.personalDetails.countriesOverseasPensionSchemes.shift()
      }
    }
    const parentGrandParents = combineParentGrandParents(dataDB.personalDetails)
    extractParentGrandParents(parentGrandParents)
    const allEqualNull = (arr) => arr.every((val) => val !== null)
    if (allEqualNull(parentGrandParents)) {
      dataDB.personalDetails.parentGrandParent = parentGrandParents
    }

    return dataDB
  }
}

const combineParentGrandParents = ({ parents, grandparents, parentsInLaw }) => {
  let parentGrandParents = []
  parentGrandParents = parentGrandParents.concat(parents)
  parentGrandParents = parentGrandParents.concat(grandparents)
  parentGrandParents = parentGrandParents.concat(parentsInLaw)
  return parentGrandParents
}

const extractParentGrandParents = (pgpArray) => {
  if (pgpArray !== undefined) {
    const parents = pgpArray.filter((item) => {
      if (item !== null) {
        item.dependantType = "PARENT"
        return item.relationship === "PARENT"
      }
      return false
    })
    const grandparents = pgpArray.filter((item) => {
      if (item !== null) {
        item.dependantType = "GRAND_PARENT"
        return item.relationship === "GRAND_PARENT"
      }
      return false
    })
    const parentsInLaw = pgpArray.filter((item) => {
      if (item !== null) {
        item.dependantType = "PARENT_IN_LAW"
        return item.relationship === "PARENT_IN_LAW"
      }
      return false
    })

    return {
      parents,
      grandparents,
      parentsInLaw
    }
  }
  return null
}
