import api from '../../lib/api';

// change base route for different entity/domain here
const BASE_ROUTE = '/client/';

class PersonalDetailsApi {
    // retrieve data
    static get(id) {

        return api.get(BASE_ROUTE + id);
    }

    static getWithPartner(id) {
        return api.get(`${BASE_ROUTE}personal-details/${id}`);
    }

    
}

export default PersonalDetailsApi;
