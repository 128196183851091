import React, { useEffect, useState } from "react"
import intl from "react-intl-universal"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ConfirmationDialog } from "../../components"

import ActionCreators from "../../actions/ActionCreators"
import {
  ArrowMenu,
  MalabarDefaultRightMenu,
  GaugeRadialChart
} from "../../components"
import CurrentStandingApi from "../../api/CurrentStanding/CurrentStandingApi"
import CurrentStandingHeader from "./_components/CurrentStandingHeader"
import { PersonalDetailsApi } from "../../api"
import { CountryList } from "../../constants/Lists"
import { CurrencyEnum } from "../../constants/Enums"

function KeyFinancialRatios({ match, history }) {
  const [currency, setCurrency] = useState(
    localStorage.getItem("theCurrency") || "AUD"
  )
  const [currentStandingObject, setCurrentStandingObject] = useState({})
  const [client, setClient] = useState()
  const [partner, setPartner] = useState()
  const [classification, setClassification] = useState()
  useEffect(() => {
    if (!partner) {
      setClassification("CLIENT")
    } else {
      setClassification("COMBINED")
    }
  }, [partner])
  const [save, setSave] = useState(false)
  const saveHandler = () => {
    setSave(!save)
  }
  const clickHandler1 = () => {
    setSave(false)
  }

  const clickHandler2 = () => {
    setSave(false)
  }
  useEffect(() => {
    PersonalDetailsApi.getWithPartner(match.params.id).then((response) => {
      setClient(response.data.client)
      setPartner(response.data.partner)
      if (
        response.data.client &&
        response.data.client.personalDetails &&
        response.data.client.personalDetails.employmentDetails.taxResidency
      ) {
        const countryTaxResidency = CountryList.find(
          (x) =>
            x.code ===
            response.data.client.personalDetails.employmentDetails.taxResidency
        )
        if (
          countryTaxResidency &&
          Object.values(CurrencyEnum).includes(countryTaxResidency.abbreviation)
        ) {
          setCurrency(countryTaxResidency.abbreviation)
        } else {
          setCurrency("USD")
        }
      }
    })
  }, match.params.id)
  const [financialRatios, setFinancialRatios] = useState({})
  useEffect(() => {
    if (match.params && match.params.id && match.params.id !== "null") {
      CurrentStandingApi.getFinancialRatio(
        match.params.id,
        // classification,
        currency
      ).then(
        (response) => {
          setFinancialRatios(response.data)
          setCurrentStandingObject(response.data.totalCombined)
        },
        (error) => {
          if (error.response.data.errorCode === "1001") {
            history.push(`/goals/${match.params.id}`)
          }
        }
      )
    }
  }, [match.params.id, currency])

  useEffect(() => {
    if (financialRatios)
      if (classification === "PARTNER") {
        setCurrentStandingObject(financialRatios.partner)
      } else if (classification === "COMBINED") {
        setCurrentStandingObject(financialRatios.totalCombined)
      } else if (classification === "JOINT") {
        setCurrentStandingObject(financialRatios.joint)
      } else {
        setCurrentStandingObject(financialRatios.client)
      }
  }, [currency, financialRatios])

  const onChangeCurrency = (value) => {
    setCurrency(value)
    // setClassification("COMBINED");
  }
  const onChangeClassification = (value) => {
    setClassification(value)
    if (value === "PARTNER") {
      setCurrentStandingObject(financialRatios.partner)
    } else if (value === "COMBINED") {
      setCurrentStandingObject(financialRatios.totalCombined)
    } else if (value === "JOINT") {
      setCurrentStandingObject(financialRatios.joint)
    } else {
      setCurrentStandingObject(financialRatios.client)
    }
  }
  const readOnlyAccessComponent = () => null

  const getMinimumValue = (badMin, goodMin) =>
    badMin > goodMin ? goodMin : badMin

  const getMaximumValue = (badMax, goodMax) =>
    badMax > goodMax ? badMax : goodMax

  const getDangerLevelValue = (badMin, goodMin) =>
    badMin > goodMin ? badMin : goodMin

  const isInverted = (badMin, goodMin) => badMin > goodMin

  return (
    <React.Fragment>
      <ConfirmationDialog
        isOpen={save}
        onClose={clickHandler1}
        onYes={clickHandler2}
        message="Would you like to save the current client personal and financial details ?"
      />
      <div className="portal-maincontent current_standing">
        <div className="content-panel financialRatios">
          <CurrentStandingHeader
            title={intl.get("currentStanding.financialRatios.title")}
            reportingCurrency={currency}
            onChangeCurrency={onChangeCurrency}
            onChangeClassification={onChangeClassification}
            client={client}
            partner={partner}
            show={true}
          />
          <div className="clearfloat" />

          <div id="scroll-section" className="portal-form">
            <div className="flexbox">
              <div className="ratio-holder flexbox">
                <div className="ratio-title-holder flexbox">
                  <div className="ratio-title ratio-title-2col">
                    {intl.get("currentStanding.financialRatios.liquidity")}
                  </div>
                  <div className="ratio-title ratio-title-1col">
                    {intl.get("currentStanding.financialRatios.savings")}
                  </div>
                  <div className="ratio-title ratio-title-1col">
                    {intl.get("currentStanding.financialRatios.investment")}
                  </div>
                  <div className="ratio-title ratio-title-1col">
                    {intl.get("currentStanding.financialRatios.solvency")}
                  </div>
                </div>

                {currentStandingObject &&
                  currentStandingObject.financialRatio && (
                    <React.Fragment>
                      <GaugeRadialChart
                        name={"liquidity-gauge"}
                        title={intl.get(
                          "currentStanding.financialRatios.liquidityRatio"
                        )}
                        bestPracticeDescription={"> 7 months"}
                        description={intl.get(
                          "currentStanding.financialRatios.liquidityRatioDesc"
                        )}
                        value={
                          currentStandingObject.financialRatio
                            .liquidityRatioInMonths.value
                        }
                        minValue={
                          currentStandingObject.financialRatio
                            .liquidityRatioInMonths.good.min
                        }
                        maxValue={
                          currentStandingObject.financialRatio
                            .liquidityRatioInMonths.good.max
                        }
                        dangerLevelValue={getDangerLevelValue(
                          currentStandingObject.financialRatio
                            .liquidityRatioInMonths.bad.min,
                          currentStandingObject.financialRatio
                            .liquidityRatioInMonths.good.min
                        )}
                        titleAppendValue={`M`}
                        isValueInverted={isInverted(
                          currentStandingObject.financialRatio
                            .liquidityRatioInMonths.bad.min,
                          currentStandingObject.financialRatio
                            .liquidityRatioInMonths.good.min
                        )}
                        data={currentStandingObject.financialRatio}
                        numeratorLabel={"Current Assets"}
                        denominatorLabel={"Current Liabilities"}
                        numeratorValue={
                          currentStandingObject.financialRatio.totalAsset
                        }
                        denominatorValue={
                          currentStandingObject.financialRatio.totalLiability
                        }
                        inPercentage={
                          currentStandingObject.financialRatio
                            .liquidityRatioInMonths.inPercentage
                        }
                        inMonths={true}
                      />

                      <GaugeRadialChart
                        name={"liquid-asset-gauge"}
                        title={intl.get(
                          "currentStanding.financialRatios.liquidAssetNetWorthRatio"
                        )}
                        bestPracticeDescription={"> 15%"}
                        description={
                          "The Liquid Asset to Net Worth Ratio measures an individual's ability to meet short-term financial obligations using liquid assets relative to their overall Net Worth."
                        }
                        value={
                          currentStandingObject.financialRatio
                            .liquidityAssetToNetWorthRatio.value
                        }
                        inPercentage={
                          currentStandingObject.financialRatio
                            .liquidityAssetToNetWorthRatio.inPercentage
                        }
                        minValue={
                          currentStandingObject.financialRatio
                            .liquidityAssetToNetWorthRatio.good.min
                        }
                        maxValue={
                          currentStandingObject.financialRatio
                            .liquidityAssetToNetWorthRatio.good.max
                        }
                        dangerLevelValue={getDangerLevelValue(
                          currentStandingObject.financialRatio
                            .liquidityAssetToNetWorthRatio.bad.min,
                          currentStandingObject.financialRatio
                            .liquidityAssetToNetWorthRatio.good.min
                        )}
                        titleAppendValue={`%`}
                        isValueInverted={isInverted(
                          currentStandingObject.financialRatio
                            .liquidityAssetToNetWorthRatio.bad.min,
                          currentStandingObject.financialRatio
                            .liquidityAssetToNetWorthRatio.good.min
                        )}
                        data={currentStandingObject.financialRatio}
                        numeratorLabel={"Liquid Assets"}
                        denominatorLabel={"Networth"}
                        numeratorValue={
                          currentStandingObject.financialRatio.totalLiquidAsset
                        }
                        denominatorValue={
                          currentStandingObject.financialRatio.netWorth
                        }
                      />

                      <GaugeRadialChart
                        name={"savings-ratio-gauge"}
                        title={intl.get(
                          "currentStanding.financialRatios.savingsRatio"
                        )}
                        bestPracticeDescription={"> 10%"}
                        description={
                          "The Savings Ratio measures an individual's ability to save money relative to their Income. "
                        }
                        value={
                          currentStandingObject.financialRatio.savingsRatio
                            .value
                        }
                        inPercentage={
                          currentStandingObject.financialRatio.savingsRatio
                            .inPercentage
                        }
                        minValue={
                          currentStandingObject.financialRatio.savingsRatio.good
                            .min
                        }
                        maxValue={
                          currentStandingObject.financialRatio.savingsRatio.good
                            .max
                        }
                        dangerLevelValue={getDangerLevelValue(
                          currentStandingObject.financialRatio.savingsRatio.bad
                            .min,
                          currentStandingObject.financialRatio.savingsRatio.good
                            .min
                        )}
                        titleAppendValue={`%`}
                        isValueInverted={isInverted(
                          currentStandingObject.financialRatio.savingsRatio.bad
                            .min,
                          currentStandingObject.financialRatio.savingsRatio.good
                            .min
                        )}
                        data={currentStandingObject.financialRatio}
                        numeratorLabel={"Amount Saved"}
                        denominatorLabel={"Gross Income"}
                        numeratorValue={
                          currentStandingObject.financialRatio
                            .netSavingsCashflow
                        }
                        denominatorValue={
                          currentStandingObject.financialRatio.totalIncome
                        }
                      />

                      <GaugeRadialChart
                        name={"netasset-networth-gauge"}
                        title={intl.get(
                          "currentStanding.financialRatios.netAssetNetWorthRatio"
                        )}
                        bestPracticeDescription={"> 50%"}
                        description={
                          "The Net Asset to Net Worth Ratio measures an individual's reliance on tangible assets to build net Worth."
                        }
                        value={
                          currentStandingObject.financialRatio
                            .netAssetToNetWorthRatio.value
                        }
                        inPercentage={
                          currentStandingObject.financialRatio
                            .netAssetToNetWorthRatio.inPercentage
                        }
                        minValue={
                          currentStandingObject.financialRatio
                            .netAssetToNetWorthRatio.good.min
                        }
                        maxValue={
                          currentStandingObject.financialRatio
                            .netAssetToNetWorthRatio.good.max
                        }
                        dangerLevelValue={getDangerLevelValue(
                          currentStandingObject.financialRatio
                            .netAssetToNetWorthRatio.bad.min,
                          currentStandingObject.financialRatio
                            .netAssetToNetWorthRatio.good.min
                        )}
                        titleAppendValue={`%`}
                        isValueInverted={isInverted(
                          currentStandingObject.financialRatio
                            .netAssetToNetWorthRatio.bad.min,
                          currentStandingObject.financialRatio
                            .netAssetToNetWorthRatio.good.min
                        )}
                        data={currentStandingObject.financialRatio}
                        numeratorLabel={"Net Assets"}
                        denominatorLabel={"Net Worth"}
                        numeratorValue={
                          currentStandingObject.financialRatio.netAssets
                        }
                        denominatorValue={
                          currentStandingObject.financialRatio.netWorth
                        }
                      />

                      <GaugeRadialChart
                        name={"solvency-gauge"}
                        title={intl.get(
                          "currentStanding.financialRatios.solvencyRatio"
                        )}
                        bestPracticeDescription={"> 50%"}
                        description={
                          "The Solvency Ratio measures an individual's ability to meet long-term financial obligations."
                        }
                        value={
                          currentStandingObject.financialRatio.solvencyRatio
                            .value
                        }
                        inPercentage={
                          currentStandingObject.financialRatio.solvencyRatio
                            .inPercentage
                        }
                        minValue={
                          currentStandingObject.financialRatio.solvencyRatio
                            .good.min
                        }
                        maxValue={
                          currentStandingObject.financialRatio.solvencyRatio
                            .good.max
                        }
                        dangerLevelValue={getDangerLevelValue(
                          currentStandingObject.financialRatio.solvencyRatio.bad
                            .min,
                          currentStandingObject.financialRatio.solvencyRatio
                            .good.min
                        )}
                        titleAppendValue={`%`}
                        isValueInverted={isInverted(
                          currentStandingObject.financialRatio.solvencyRatio.bad
                            .min,
                          currentStandingObject.financialRatio.solvencyRatio
                            .good.min
                        )}
                        data={currentStandingObject.financialRatio}
                        numeratorLabel={"Net Worth"}
                        denominatorLabel={"Total Assets"}
                        numeratorValue={
                          currentStandingObject.financialRatio.netWorth
                        }
                        denominatorValue={
                          currentStandingObject.financialRatio.totalAsset
                        }
                      />
                    </React.Fragment>
                  )}
              </div>
            </div>
            <div
              className="ratio-holder flexbox"
              style={{ paddingBottom: "35px" }}
            >
              <div className="ratio-title-holder">
                <div className="ratio-title">
                  {intl.get("currentStanding.financialRatios.debt")}
                </div>
              </div>

              {currentStandingObject &&
                currentStandingObject.financialRatio && (
                  <React.Fragment>
                    <GaugeRadialChart
                      name={"debt-asset-gearing-gauge"}
                      title={intl.get(
                        "currentStanding.financialRatios.debtAssetGearingRatio"
                      )}
                      bestPracticeDescription={"< 51%"}
                      description={
                        "The Debt to Asset Ratio measures an individual's level of Debt relative to their total assets."
                      }
                      value={
                        currentStandingObject.financialRatio.debtToAssetRatio
                          .value
                      }
                      inPercentage={
                        currentStandingObject.financialRatio.debtToAssetRatio
                          .inPercentage
                      }
                      minValue={
                        currentStandingObject.financialRatio.debtToAssetRatio
                          .good.min
                      }
                      maxValue={
                        currentStandingObject.financialRatio.debtToAssetRatio
                          .good.max
                      }
                      dangerLevelValue={getDangerLevelValue(
                        currentStandingObject.financialRatio.debtToAssetRatio
                          .bad.min,
                        currentStandingObject.financialRatio.debtToAssetRatio
                          .good.min
                      )}
                      titleAppendValue={`%`}
                      isValueInverted={isInverted(
                        currentStandingObject.financialRatio.debtToAssetRatio
                          .bad.min,
                        currentStandingObject.financialRatio.debtToAssetRatio
                          .good.min
                      )}
                      data={currentStandingObject.financialRatio}
                      numeratorLabel={"Total Debts"}
                      denominatorLabel={"Total Assets"}
                      numeratorValue={
                        currentStandingObject.financialRatio.totalLiability
                      }
                      denominatorValue={
                        currentStandingObject.financialRatio.totalAsset
                      }
                    />

                    <GaugeRadialChart
                      name={"debt-service-gauge"}
                      title={intl.get(
                        "currentStanding.financialRatios.debtServiceRatio"
                      )}
                      bestPracticeDescription={"< 35%"}
                      description={
                        "The Debt Service Ratio is a measure of an individual's ability to meet their debt obligations based on their Income."
                      }
                      value={
                        currentStandingObject.financialRatio.debtServiceRatio
                          .value
                      }
                      inPercentage={
                        currentStandingObject.financialRatio.debtServiceRatio
                          .inPercentage
                      }
                      minValue={
                        currentStandingObject.financialRatio.debtServiceRatio
                          .good.min
                      }
                      maxValue={
                        currentStandingObject.financialRatio.debtServiceRatio
                          .good.max
                      }
                      dangerLevelValue={getDangerLevelValue(
                        currentStandingObject.financialRatio.debtServiceRatio
                          .bad.min,
                        currentStandingObject.financialRatio.debtServiceRatio
                          .good.min
                      )}
                      titleAppendValue={`%`}
                      isValueInverted={isInverted(
                        currentStandingObject.financialRatio.debtServiceRatio
                          .bad.min,
                        currentStandingObject.financialRatio.debtServiceRatio
                          .good.min
                      )}
                      data={currentStandingObject.financialRatio}
                      numeratorLabel={"Total Monthly Debt Payments"}
                      denominatorLabel={"Monthly Gross Income"}
                      numeratorValue={
                        currentStandingObject.financialRatio
                          .totalLiabilityPayment
                      }
                      denominatorValue={
                        currentStandingObject.financialRatio.totalMonthlyIncome
                      }
                    />

                    <GaugeRadialChart
                      name={"non-mortgage-debt-gauge"}
                      title={intl.get(
                        "currentStanding.financialRatios.nonMortgageDebtServiceRatio"
                      )}
                      bestPracticeDescription={"> 14%"}
                      description={
                        "The Non-Mortgage Debt Service Ratio measures an individual's ability to meet non-mortgage debt obligations based on their Income."
                      }
                      value={
                        currentStandingObject.financialRatio
                          .nonMortgageDebtServiceRatio.value
                      }
                      inPercentage={
                        currentStandingObject.financialRatio
                          .nonMortgageDebtServiceRatio.inPercentage
                      }
                      minValue={
                        currentStandingObject.financialRatio
                          .nonMortgageDebtServiceRatio.good.min
                      }
                      maxValue={
                        currentStandingObject.financialRatio
                          .nonMortgageDebtServiceRatio.good.max
                      }
                      dangerLevelValue={getDangerLevelValue(
                        currentStandingObject.financialRatio
                          .nonMortgageDebtServiceRatio.bad.min,
                        currentStandingObject.financialRatio
                          .nonMortgageDebtServiceRatio.good.min
                      )}
                      titleAppendValue={`%`}
                      isValueInverted={isInverted(
                        currentStandingObject.financialRatio
                          .nonMortgageDebtServiceRatio.bad.min,
                        currentStandingObject.financialRatio
                          .nonMortgageDebtServiceRatio.good.min
                      )}
                      data={currentStandingObject.financialRatio}
                      numeratorLabel={
                        "Total Monthly Non-Mortgage Debt Payments"
                      }
                      denominatorLabel={"Monthly Gross Income"}
                      numeratorValue={
                        currentStandingObject.financialRatio
                          .totalLiabilityPayment -
                        currentStandingObject.financialRatio
                          .totalMortgagePayment
                      }
                      denominatorValue={
                        currentStandingObject.financialRatio.totalMonthlyIncome
                      }
                    />

                    <GaugeRadialChart
                      name={"housing-expense-gauge"}
                      title={intl.get(
                        "currentStanding.financialRatios.housingExpenseRatio"
                      )}
                      bestPracticeDescription={"< 36%"}
                      description={
                        "The Housing Expense Ratio measures an individual's housing-related expenses in relation to their Income."
                      }
                      value={
                        currentStandingObject.financialRatio.housingExpenseRatio
                          .value
                      }
                      inPercentage={
                        currentStandingObject.financialRatio.housingExpenseRatio
                          .inPercentage
                      }
                      minValue={
                        currentStandingObject.financialRatio.housingExpenseRatio
                          .good.min
                      }
                      maxValue={
                        currentStandingObject.financialRatio.housingExpenseRatio
                          .good.max
                      }
                      dangerLevelValue={getDangerLevelValue(
                        currentStandingObject.financialRatio.housingExpenseRatio
                          .bad.min,
                        currentStandingObject.financialRatio.housingExpenseRatio
                          .good.min
                      )}
                      titleAppendValue={`%`}
                      isValueInverted={isInverted(
                        currentStandingObject.financialRatio.housingExpenseRatio
                          .bad.min,
                        currentStandingObject.financialRatio.housingExpenseRatio
                          .good.min
                      )}
                      data={currentStandingObject.financialRatio}
                      numeratorLabel={"Total Monthly Housing Expenses"}
                      denominatorLabel={"Monthly Gross Income"}
                      numeratorValue={
                        currentStandingObject.financialRatio.totalHousingExpense
                      }
                      denominatorValue={
                        currentStandingObject.financialRatio.totalMonthlyIncome
                      }
                    />

                    <GaugeRadialChart
                      name={"housing-loan-gauge"}
                      title={intl.get(
                        "currentStanding.financialRatios.housingLoanValuationRatio"
                      )}
                      bestPracticeDescription={"< 36%"}
                      description={
                        "The Housing Loan to Valuation Ratio, also known as the Loan-to-Value (LTV) Ratio, measures the loan amount relative to the appraised value or purchase price of a property."
                      }
                      value={
                        currentStandingObject.financialRatio
                          .housingLoanValuationRatio.value
                      }
                      inPercentage={
                        currentStandingObject.financialRatio
                          .housingLoanValuationRatio.inPercentage
                      }
                      minValue={
                        currentStandingObject.financialRatio
                          .housingLoanValuationRatio.good.min
                      }
                      maxValue={
                        currentStandingObject.financialRatio
                          .housingLoanValuationRatio.good.max
                      }
                      dangerLevelValue={getDangerLevelValue(
                        currentStandingObject.financialRatio
                          .housingLoanValuationRatio.bad.min,
                        currentStandingObject.financialRatio
                          .housingLoanValuationRatio.good.min
                      )}
                      titleAppendValue={`%`}
                      isValueInverted={isInverted(
                        currentStandingObject.financialRatio
                          .housingLoanValuationRatio.bad.min,
                        currentStandingObject.financialRatio
                          .housingLoanValuationRatio.good.min
                      )}
                      data={currentStandingObject.financialRatio}
                      numeratorLabel={"Loan Amount"}
                      denominatorLabel={"Appraised Value or Purchase Price"}
                      numeratorValue={
                        currentStandingObject.financialRatio
                          .totalMortgageLiability
                      }
                      denominatorValue={
                        currentStandingObject.financialRatio.totalPropertyValue
                      }
                    />
                  </React.Fragment>
                )}
            </div>

            {/* insurance section */}
            <div
              className="ratio-holder flexbox"
              style={{ paddingBottom: "35px" }}
            >
              <div className="ratio-title-holder">
                <div className="ratio-title">Insurance</div>
              </div>

              {currentStandingObject &&
                currentStandingObject.financialRatio && (
                  <React.Fragment>
                    <GaugeRadialChart
                      name={"debt-asset-gearing-gauge"}
                      title={"Insurance Premium Ratio"}
                      bestPracticeDescription={"< 51%"}
                      description={
                        "The insurance premium ratio, also known as the loss ratio, is a key metric used to evaluate the financial performance of an insurance company."
                      }
                      value={
                        currentStandingObject.financialRatio
                          .insurancePremiumRatio.value
                      }
                      inPercentage={
                        currentStandingObject.financialRatio
                          .insurancePremiumRatio.inPercentage
                      }
                      minValue={
                        currentStandingObject.financialRatio
                          .insurancePremiumRatio.good.min
                      }
                      maxValue={
                        currentStandingObject.financialRatio
                          .insurancePremiumRatio.good.max
                      }
                      dangerLevelValue={getDangerLevelValue(
                        currentStandingObject.financialRatio
                          .insurancePremiumRatio.bad.min,
                        currentStandingObject.financialRatio
                          .insurancePremiumRatio.good.min
                      )}
                      titleAppendValue={`%`}
                      isValueInverted={isInverted(
                        currentStandingObject.financialRatio
                          .insurancePremiumRatio.bad.min,
                        currentStandingObject.financialRatio
                          .insurancePremiumRatio.good.min
                      )}
                      data={currentStandingObject.financialRatio}
                      numeratorLabel={"Total Insurance Premiums"}
                      denominatorLabel={"Total Annual Income"}
                      numeratorValue={
                        currentStandingObject.financialRatio
                          .totalInsurancePremiums
                      }
                      denominatorValue={
                        currentStandingObject.financialRatio.totalIncome
                      }
                    />
                    <GaugeRadialChart
                      name={"debt-service-gauge"}
                      title={"Life Insurance Coverage"}
                      bestPracticeDescription={"< 35%"}
                      description={
                        "Life insurance coverage refers to the amount of money that a life insurance policy will pay out to the beneficiaries upon the death of the insured individual."
                      }
                      value={
                        currentStandingObject.financialRatio
                          .lifeInsuranceCoverage.value
                      }
                      inPercentage={
                        currentStandingObject.financialRatio
                          .lifeInsuranceCoverage.inPercentage
                      }
                      minValue={
                        currentStandingObject.financialRatio
                          .lifeInsuranceCoverage.good.min
                      }
                      maxValue={
                        currentStandingObject.financialRatio
                          .lifeInsuranceCoverage.good.max
                      }
                      dangerLevelValue={getDangerLevelValue(
                        currentStandingObject.financialRatio
                          .lifeInsuranceCoverage.bad.min,
                        currentStandingObject.financialRatio
                          .lifeInsuranceCoverage.good.min
                      )}
                      titleAppendValue={`%`}
                      isValueInverted={isInverted(
                        currentStandingObject.financialRatio
                          .lifeInsuranceCoverage.bad.min,
                        currentStandingObject.financialRatio
                          .lifeInsuranceCoverage.good.min
                      )}
                      data={currentStandingObject.financialRatio}
                      numeratorLabel={"Total Life Insurance Coverage"}
                      denominatorLabel={"Total Income"}
                      numeratorValue={
                        currentStandingObject.financialRatio.totalCoverageLife
                      }
                      denominatorValue={
                        currentStandingObject.financialRatio.totalAsset
                      }
                    />
                  </React.Fragment>
                )}
            </div>

            {/* <ScrollUpButton sectionId={"scroll-section"} /> */}
          </div>
        </div>

        <MalabarDefaultRightMenu
          readOnlyAccess={readOnlyAccessComponent()}
          // onSaveClick={saveHandler}
          enableSaveButton={false}
          enableClearButton={false}
        />
        <ArrowMenu nextDescription={"next"} prevDescription={"prev"} />
      </div>
    </React.Fragment>
  )
}

function mapStateToProps(state) {
  return {
    activeAccessLevel: state.appSettings
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(KeyFinancialRatios)
