import api from '../lib/api';

// change base route for different entity/domain here
const BASE_ROUTE = '/portfolio';

class PorfolioBuilderApi {
    static getAccountSummary(clientId, model) {
        return api.post(`${BASE_ROUTE}/${clientId}/account-summary`, model);
    }
    // static getAccountSummary() {
    //     return api.get(`${BASE_ROUTE}/test-login`);
    // }

    static getInvestmentPlatform() {
        return api.get(`/broker/providers`);
    }

    static getAdviserAccounts(ip) {
        return api.get(`/broker/advisers?selectedProvider=${ip}`);
    }

    static getClientAccounts(ip, adviser) {
        return api.get(
            `/broker/clients?selectedProvider=${ip}&selectedAdviser=${adviser}`
        );
    }

    static saveAdviserAccounts(model) {
        return api.post(`/broker/advisers`, model);
    }

    static saveClientAccounts(model) {
        return api.post(`/broker/clients`, model);
    }

    static deleteAdviserAccounts(id) {
        return api.delete(`/broker/advisers/${id}`);
    }

    static deleteClientAccounts(id) {
        return api.delete(`/broker/clients/${id}`);
    }

    static searchInvestment(model) {
        return api.post(`/portfolio/search-company`, model);
    }

    static getInvestmentDetail(model) {
        return api.post(`/portfolio/market-data`, model);
    }

    static accountLedger(clientId, model) {
        return api.post(`${BASE_ROUTE}/${clientId}/account-ledger`, model);
    }

    static trade(clientId, model) {
        return api.post(`${BASE_ROUTE}/${clientId}/execute-trade`, model);
    }

    static getSubAccounts(model) {
        return api.post(`/portfolio/sub-accounts`, model);
    }

    static invite(clientId) {
        return api.post(`/investment-account/${clientId}/account-invitation`, {});
    }

    static history(clientId, model) {
        return api.post(`${BASE_ROUTE}/${clientId}/account-trades`, model);
    }

    static confirm(clientId, model) {
        return api.post(`${BASE_ROUTE}/${clientId}/confirm-trade`, model);
    }

    static orders(clientId, model) {
        return api.post(`${BASE_ROUTE}/${clientId}/account-orders`, model);
    }

    static logout(model) {
        return api.post(`${BASE_ROUTE}/adviser-logout`, model);
    }
}

export default PorfolioBuilderApi;
