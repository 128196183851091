import React from "react"

const PortfolioTable = ({ investmentPortfolioHoldings }) => {
  return (
    <div>
      <table className="table-blue" width="100%">
        <tbody>
          <tr>
            <th>Currency</th>
            <th>Current Price</th>
            <th>Instrument Type</th>
            <th>Maturity Date</th>
            <th>Name</th>
            <th>Number of Units Purchased</th>
            <th>Purchase Data</th>
            <th>Purchase Price</th>
            <th>Symbol</th>
            <th> Transaction Id</th>
            <th>Valuation Date</th>
          </tr>
          {investmentPortfolioHoldings.length ? (
            investmentPortfolioHoldings.map((item) => {
              return (
                <tr>
                  <td>{item.currency}</td>
                  <td>$ {item.currentPrice}</td>
                  <td>{item.instrumentType}</td>
                  <td>{item.maturityDate}</td>
                  <td>{item.name}</td>
                  <td>{item.numberOfUnitsPurchased}</td>
                  <td>{item.purchasedDate}</td>
                  <td>{item.purchasedPrice}</td>
                  <td>{item.symbol}</td>
                  <td>{item.transactionId}</td>
                  <td>{item.valuationDate}</td>
                </tr>
              )
            })
          ) : (
            <></>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default PortfolioTable
