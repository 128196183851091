/*
    Author : Marlina (marlina@devstack.com.au)
    Description : Item in insurance dashboard
*/

import React from 'react';
import intl from 'react-intl-universal';
import moment from 'moment';
import { getSymbolFromCode, numberWithCommas } from '../../../helpers';

function InsuranceIlasCard({
    data,
    deleteInsurance,
    showForm,
    showDialog,
    currency,
}) {
    return (
        <div className="insurance-box-holder">
            <div className="insurance-box">
                <div className="title">
                    {intl.get('insurance.card.policyName')}: {data.policyTypes}
                </div>
                <div className="content">
                    <div className="text">
                        <div className="text-L">
                            {intl.get('insurance.card.policyType')}:
                        </div>
                        <div className="text-R">{data.policyProductName}</div>
                    </div>
                    <span
                        onClick={() => {
                            showDialog({
                                message: `You're about to delete insurance (${data.policyProductName}). Are you sure?`,
                                onYes: deleteInsurance,
                            });
                        }}
                        className="btn-remove"
                    />
                    {data.policyPremiumAmount &&
                        data.policyPremiumAmount.value > 0 && (
                            <div className="text">
                                <div className="text-L text-bold ">
                                    {intl.get('insurance.card.premium')}:
                                </div>
                                <div className="text-R text-bold ">
                                    {data.policyPremiumAmount.currency
                                        ? getSymbolFromCode(currency)
                                        : getSymbolFromCode('MYR')}{' '}
                                    {numberWithCommas(
                                        data.policyPremiumAmount.value
                                    )}
                                </div>
                            </div>
                        )}
                    <div className="text">
                        <div className="text-L">
                            {intl.get('insurance.card.coverage')}:
                        </div>
                        <div className="text-R">
                            {data.policyCoverageType || '-'}
                        </div>
                    </div>
                    <div className="text">
                        <div className="text-L">
                            {intl.get('insurance.card.maturityDate')}:
                        </div>
                        <div className="text-R">
                            {data.policyMaturityDate
                                ? moment(
                                      data.policyMaturityDate,
                                      'YYYY-MM-DD'
                                  ).format('DD MMMM YYYY')
                                : '-'}
                        </div>
                    </div>
                    <div className="text">
                        <div className="text-L">
                            {intl.get('insurance.card.paymentMethod')}:
                        </div>
                        <div className="text-R text-uppercase">
                            {data.paymentType || '-'}
                        </div>
                    </div>
                    <span onClick={showForm} className="btn btn-edit" />
                </div>
            </div>
        </div>
    );
}

export default InsuranceIlasCard;
