import React, { useState, useEffect } from "react"
import intl from 'react-intl-universal';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import { RenderSimpleFields } from '../../../components';
import { FieldType } from '../../../components/Form/RenderSimpleFields';
import Alert from 'react-s-alert';
import moment from 'moment'
import {
    renderTextBoxCustom,
    renderSelectEnumFieldCustom,
    renderDateFieldCustom,
    renderCurrencyCustom,
    getSymbolFromCode,
} from '../../../helpers';

const WithdrawalPlans = ({
    values,
    optionsData,
    addPlan,
    removePlan,
    setFormAttribute,
    hasLockInPeriods = true,
    hasFees = true,
    feesColumnTitle = "Fees",
    feesPercentage = 0,
    currency,
    minDate,
    maxDate
}) => {
    const [ccy, setCcy] = useState(currency?currency:localStorage.getItem("theCurrency"));
    const [ccySymbol, setCcySymbol] = useState(getSymbolFromCode(ccy))
    const [minWithdrawalDate, setMinWithdrawalDate] = useState(minDate)
    const [maxWithdrawalDate, setMaxWithdrawalDate] = useState(maxDate)
    
    function validateNewRow(){
        let result = true;
        let withdrawalPlans = values.withdrawalPlans;
        let rowNo = 1;
        let errorText = "";
        if(withdrawalPlans && withdrawalPlans.length>0){
            let size = withdrawalPlans.length;    
            for (let index = 0; index < size; index++) {
                const a = withdrawalPlans[index];
                let rowErrors = "";
              
                if(!a.amount){
                    if(rowErrors.length==0){
                        rowErrors = "Amount";
                    }else{
                        rowErrors = rowErrors + ", " + "Amount";
                    }
                }
                if(!a.frequency){
                    if(rowErrors.length==0){
                        rowErrors = "Frequency";
                    }else{
                        rowErrors = rowErrors + ", " + "Frequency";
                    }
                }
                if(!a.startDate){
                    if(rowErrors.length==0){
                        rowErrors = "Start Date";
                    }else{
                        rowErrors = rowErrors + ", " + "Start Date";
                    }
                }

                if(!a.endDate){
                    if(rowErrors.length==0){
                        rowErrors = "End Date";
                    }else{
                        rowErrors = rowErrors + ", " + "End Date";
                    }
                }
               
               
                if(rowErrors.length>0){
                    let msg = `Row-${rowNo}: ${rowErrors} required`;
                    if(errorText.length==0){
                        errorText = msg;
                    }else{
                        errorText = errorText + ", " + msg;
                    } 
                }
               
                rowNo++;
            }


        }
       
        if(errorText && errorText.length>0){
            Alert.error(`withdrawal ${errorText}`);
            result = false;
        }
        return result;
    }
    
    function getElementAt(searchIndex){
        let result;
        if(values && values.withdrawalPlans){
            for (let index = 0; index < values.withdrawalPlans.length; index++) {
                let element = values.withdrawalPlans[index];
                if(index==searchIndex){
                    result = element
                }
                
            }
        }
        return result;
    }

    function onAddButtonClicked(e){
        let withdrawalPlans = values && values.withdrawalPlans? values.withdrawalPlans:[];
        let size = withdrawalPlans && withdrawalPlans.length>0?withdrawalPlans.length:0;    
        if(validateNewRow()){
            let newModel = {
                description: `Withdrawal-${size +1}`,
                amount: 0,
                frequency:'ONE_OFF',
                startDate:minWithdrawalDate,
                endDate:maxWithdrawalDate,
                fee:0
                
            }
            addPlan('withdrawalPlans', newModel);
        }
       
    }

    function onLockInPeriodsChanged(val){
        let startDate = values.policyStartDate; // Current date
        let m = moment(startDate, "YYYY-MM-DD");
        let freq = values.policyPremiumFrequency;
        let futureDate;
        if(freq == "MONTHLY"){
            futureDate = m.add(val, 'months'); 
        }else if(freq == "YEARLY"){
            futureDate = m.add(val, 'years'); 
        }else if(freq == "QUARTERLY"){
            futureDate = m.add(val * 3, 'months'); 
        }else if(freq == "HALF_YEARLY"){
            futureDate = m.add(val * 6, 'months'); 
        }else if(freq == "WEEKLY"){
            futureDate = m.add(val * 7, 'days'); 
        }else if(freq == "FORTNIGHTLY"){
            futureDate = m.add(val * 14, 'days'); 
        }
        let strFutureDate = futureDate.format("YYYY-MM-DD");
        setMinWithdrawalDate(strFutureDate);

        let wPlans = values.withdrawalPlans;
        if(wPlans){
            let newPlans = [];
            for (let index = 0; index < wPlans.length; index++) {
                let w = wPlans[index];

                newPlans.push(
                    {
                        description: w.description,
                        amount: w.amount,
                        frequency: w.frequency,
                        startDate: strFutureDate,
                        endDate: w.endDate,
                        fee: w.fee
                    }
                )
            }
            //console.log('newplans :', newPlans);
            setFormAttribute("withdrawalPlans", newPlans);
        }

    }

    function getEligiblePeriods(){
        let result = [];
        let max = values && values.policyPeriods?values.policyPeriods:100;
        for (let index = 1; index <= max; index++) {
            result.push({code:index, name:index});
            
        }
        return result;
    }
    return (
        <>  
            {hasLockInPeriods && (
                <RenderSimpleFields
                    fields={[
                      
                        {
                            name: 'lockInPeriods',
                            label: intl.get('ilasInvestment.lockInPeriods'),
                            type: FieldType.SELECTION,
                            selectionData: getEligiblePeriods(),
                            valueProperty: "code",
                            labelProperty: "name",
                            onChange: (val)=>onLockInPeriodsChanged(val)
                        },
                       
                        
                    ]}
                />  
            )}
            
            <div className="portal-form-row text-right">
                <span
                    className="btn btn-plus btn-plus-grey"
                    onClick={(e) => onAddButtonClicked(e)}
                />
            </div>
            <table
                className="table-blue"
                width="100%"
                cellSpacing={0}
                cellPadding={0}
                border={0}
            >
                <tbody>
                    <tr>
                        <th>{intl.get('ilasInvestment.description')}</th>
                        <th style={{width:"20%", textAlign:"center"}}>{intl.get('ilasInvestment.amount')}</th>
                        <th style={{width:"10%", textAlign:"center"}}>{intl.get('ilasInvestment.frequency')}</th>
                        <th  style={{width:"10%", textAlign:"center"}}>{intl.get('ilasInvestment.startDate')}</th>
                        <th style={{width:"10%", textAlign:"center"}}>{intl.get('ilasInvestment.endDate')}</th>
                        {hasFees && (
                            <th style={{width:"15%", textAlign:"center"}}>{feesColumnTitle}</th>
                        )}
                        
                        <th style={{width:"5%", textAlign:"center"}}>&nbsp;</th>
                    </tr>
                    <FieldArray name="withdrawalPlans">
                        {({ fields }) =>
                            fields.map((name, index) => {
                                return (
                                    <tr key={index}>
                                        <td style={{width:"20%"}} >
                                            <Field
                                                name={`${name}.description`}
                                                component={renderTextBoxCustom}
                                            />
                                        </td>
                                        <td style={{width:"15%"}} >
                                            <Field
                                                name={`${name}.amount`}
                                                defaultCurrency={ccy}
                                                symbolPrefix= {ccySymbol}
                                                component={renderCurrencyCustom}
                                            />
                                        </td>
                                        <td style={{width:"20%"}} >
                                            <Field
                                                name={`${name}.frequency`}
                                                component={
                                                    renderSelectEnumFieldCustom
                                                }
                                                enumData={
                                                    optionsData.frequencyOptions
                                                }

                                            />
                                        </td>
                                        <td style={{width:"10%"}} >
                                            <Field
                                                name={`${name}.startDate`}
                                                component={
                                                    renderDateFieldCustom
                                                }
                                                min={minWithdrawalDate}
                                                max={maxWithdrawalDate}
                                                isForGrid
                        
                                            />
                                        </td>
                                        <td style={{width:"10%"}} >
                                            <Field
                                                name={`${name}.endDate`}
                                                component={
                                                    renderDateFieldCustom
                                                }
                                                min={minWithdrawalDate}
                                                max={maxWithdrawalDate}
                                                disabled={getElementAt(index).frequency=="ONE_OFF"}
                                                isForGrid
                                            />
                                        </td>
                                        {hasFees && (
                                            <td style={{width:"15%"}} >
                                                <Field
                                                    name={`${name}.fee`}
                                                    defaultCurrency={ccy}
                                                    symbolPrefix= {ccySymbol}
                                                    component={renderCurrencyCustom}
                                                />
                                            </td>
                                        )}
                                        
                                        <td  style={{width:"5%", textAlign:"center"}} >
                                            <span
                                                className="btn-remove"
                                                onClick={() => {
                                                    removePlan(
                                                        'withdrawalPlans',
                                                        index
                                                    );
                                                }}
                                            />
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </FieldArray>
                </tbody>
            </table>
        </>
    );
};

export default WithdrawalPlans;
