import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { AppContext } from "../../context/AppContext";
import UploadPhoto from "./UploadPhoto";
import Email from "./Email";
import Notes from "./Notes";
import EntityProfile from "../../pages/UserProfile/EntityProfile";
import TransferDialog from "./TransferDialog";


function TaskUser({
    user,
    activeEntityType,
    onAttachmentClicked,
    onEditClicked
}) {
    const {
        modal: { setModal, clearModal }
    } = useContext(AppContext);

    return (
        <div className="client-listing-taskuser">
            <span
                title="Upload Photo Profile"
                className="ico-camera iicon"
                onClick={() =>
                    setModal({
                        title: "Upload Photo Profile",
                        content: (
                            <UploadPhoto
                                id={user.id}
                                currentPhotoUrl={user.imagePath}
                                clearModal={clearModal}
                                isClient={user.type === "CLIENT"}
                                type={user.type}
                            />
                        ),
                        isSmall: true
                    })
                }
            />
            {/* <span className="ico-tag" onClick={onTagClicked} /> */}
            <span
                className="ico-attachment table-icon iicon"
                onClick={onAttachmentClicked}
            />

            {user.type !== "CLIENT" && (
                <span
                    title="Edit Profile"
                    className="ico-edit iicon"
                    onClick={() =>
                        setModal({
                            title: "Edit Profile",
                            formType: "UserProfile",
                            content: (
                                <EntityProfile
                                    userId={user.id}
                                    clearModal={clearModal}
                                    isClient={user.type === "CLIENT"}
                                    type={user.type}
                                    cc={user.email}
                                />
                            )
                        })
                    }
                />
            )}

            {user.type === "CLIENT" && (
                <Link
                    to={`/personaldetails/${user.id}`}
                    className="ico-edit iicon"
                >
                    <i />
                </Link>
            )}

            <span
                title="Send an email"
                className="ico-email table-icon iicon"
                onClick={() =>
                    setModal({
                        title: "Send an email",
                        content: (
                            <Email
                                userId={user.id}
                                clearModal={clearModal}
                                isClient={user.type === "CLIENT"}
                                type={user.type}
                                cc={user.email}
                            />
                        ),
                        isSmall: true
                    })
                }
            />

            {activeEntityType === "BUILT_IN_DEALERSHIP" && (
                <span
                    title="Transfer"
                    className="ico-arrow-right"
                    onClick={() =>
                        setModal({
                            title: `TRANSFER ${user.type.replace(
                                "BUILT_IN_",
                                ""
                            )}`,
                            content: (
                                <TransferDialog
                                    item={user}
                                    clearModal={clearModal}
                                />
                            ),
                            isSmall: true
                        })
                    }
                />
            )}

            {/* show in Client dashboard only */}
            {user.type === 'CLIENT' && (
            <Link
                to={`/dashboard/clientsDashboard?id=${user.id}`}
                className="ico-eye table-icon iicon"
                style={{ width: "10px" }}
            >
                <i />
            </Link>
            )}

            {user.type === "CLIENT" && (
                <span
                    className="notes-icon"
                    style={{ margin: "8.5px 0 5px 5px" }}
                    onClick={() =>
                        setModal({
                            title: "CLIENT FILE NOTES",
                            content: (
                                <Notes
                                    user={user}
                                    setModal={setModal}
                                    closeModal={clearModal}
                                />
                            ),
                            isSmall: true
                        })
                    }
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="18px"
                        viewBox="0 0 24 24"
                        width="18px"
                        fill="#000000"
                    >
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path d="M8 16h8v2H8zm0-4h8v2H8zm6-10H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z" />
                    </svg>
                </span>
            )}
            {/* )} */}
        </div>
    );
}


export default TaskUser;
