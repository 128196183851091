import Alert from "react-s-alert"
import intl from "react-intl-universal"
// eslint-disable-next-line import/no-extraneous-dependencies
import { userAction } from "../constants/ActionConstants"
import { DashboardRoute } from "../constants/AppSettingConstants"
import { history } from "../helpers"
import AuthApi from "../api/AuthApi"
import UserApi from "../api/UserApi"
// call api to handle failed retrieval of active entity user data
import api from "../lib/api"

import { beginApiCall, endApiCall } from "./LoaderActions"
import { storeLoginData, getEntitySuccess } from "./EntityActions"
import { setActiveUser } from "./AppSettingsActions"
import CurrencyApi from "../api/CurrencyApi"
import NotificationApi from "../api/NotificationApi"
import { EntityTypeEnum } from "../constants/Enums"

const getDashboardRoute = (entityTypeEnum) => DashboardRoute[entityTypeEnum]

export const userActions = {
  tfaLogin,
  addUser,
  setUser,
  toggleFieldsVisibility
  // currentUser
}

export function loginRequest() {
  return { type: userAction.LOGIN_REQUEST }
}

export function loginSuccess(user) {
  return { type: userAction.LOGIN_SUCCESS, user }
}

export function twoFaStep(user) {
  return { type: userAction.LOGIN_TWO_FA_STEP, user }
}

export function addUserSuccess() {
  return { type: userAction.ADD_USER_SUCCESS }
}

export function logoutSuccess() {
  return { type: userAction.LOGOUT }
}
export function toggleFieldsVisibilityStatus(showOnlyRequired) {
  return {
    type: userAction.TOGGLE_VISIBILITY_OF_FIELDS,
    payload: showOnlyRequired
  }
}

function onUserSuccessfullyLogon(response, dispatch) {
  let activeRoute = ""
  let nextPath = history.location.state
    ? history.location.state.from.pathname
    : "/"
  let user = response.data
  let eType = user.selectedEntity.entityType
  //anton said: simplify information and it must be encrypted in the future!
  let storedUser = {
    id: user.id,
    aid: user.aid,
    loginId: user.loginId,
    firstTimeLogin: user.firstTimeLogin,
    selectedEntity: user.selectedEntity,
    timeout: user.timeout,
    decimalPoints: user.decimalPoints,
    token: user.token,
    refresh: user.refreshToken
  }
  localStorage.setItem("user", JSON.stringify(storedUser))
  localStorage.setItem("loggingIn", true)
  localStorage.setItem("base-route", response.data.backendUrl)
  dispatch(loginSuccess(response.data))
  CurrencyApi.get().then(({ data }) => {
    localStorage.setItem("currencyRates", JSON.stringify(data))
  })

  api.token(response.data.token)
  api
    .get(`/entity/current/`, {
      headers: { Authorization: response.data.token }
    })
    .then((res) => {
      let loginData = res.data
      let entityData = loginData.selectedEntity
      if (eType == "CLIENT") {
        activeRoute = getDashboardRoute(eType)
        nextPath = "/dashboard/clientsDashboard"
        localStorage.setItem("activeClientAccessLevel", "READ_WRITE")
      } else {
        if (
          eType == EntityTypeEnum.EntityTypeEnum_BUILT_IN_ADVISER &&
          loginData.demoUser
        ) {
          activeRoute = "/demo/clients"
        } else {
          activeRoute = getDashboardRoute(eType)
        }

        nextPath = "/lobby"
      }

      // save to local storage
      storeLoginData(loginData)

      dispatch(getEntitySuccess(entityData))
      dispatch(setActiveUser(entityData))

      //store active route and history
      localStorage.setItem("activeDashboardRoute", activeRoute)
      history.push(nextPath)
      history.go(nextPath)
    })

  NotificationApi.getNotificatioMode().then((res) => {
    let notifMode = res.data
    localStorage.setItem("notificationMode", notifMode)
  })
}

export function setUser(response) {
  return async (dispatch) => {
    switch (response.data.status) {
      case "STEP_LOGIN_SUCCEEDED":
        onUserSuccessfullyLogon(response, dispatch)
        break
      case "STEP_NEED_TFA_CODE":
        localStorage.setItem("id", response.data.id)
        dispatch(twoFaStep(response.data))
        dispatch(endApiCall())
        break
      default:
        Alert.error(intl.get("alert.LoginFailed"))
        dispatch(endApiCall())
        break
    }
  }
}

export function tfaLogin(data) {
  return async (dispatch) => {
    dispatch(beginApiCall())
    return AuthApi.login2FAStep(data)
      .then((response) => {
        switch (response.data.status) {
          case "STEP_LOGIN_SUCCEEDED":
            onUserSuccessfullyLogon(response, dispatch)
            break

          case "STEP_SELECT_ENTITY":
            dispatch(endApiCall())
            return response.data
          default:
            Alert.error(intl.get("alert.2FAFailed"))
            dispatch(endApiCall())
            break
        }
      })
      .catch((error) => {
        Alert.error(intl.get("alert.2FAFailed"))
        dispatch(endApiCall(error.errors))
      })
  }
}

export function addUser(entityId, data) {
  return async (dispatch) => {
    // api request here
    UserApi.add(entityId, data).then((response) => {
      Alert.success(
        intl.get("alert.addDataSuccess", {
          name: "User"
        })
      )
      dispatch(addUserSuccess(response))
    })
  }
}
export function toggleFieldsVisibility(showOnlyRequired) {
  return async (dispatch) => {
    dispatch(toggleFieldsVisibilityStatus(showOnlyRequired))
  }
}
