import React from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { enumOptionsActions } from "../../../actions";
import { FormButtons, RenderSimpleFields } from "../../../components";
import { FieldType } from "../../../components/Form/RenderSimpleFields";
import arrayMutators from 'final-form-arrays';
import CPFClassification from "./CPFClassification";

const CPFGold = ({ optionsData, model = {}, handleSave }) => {

    return (
        <div>
            <Form
                onSubmit={handleSave}
                initialValues={model}
                mutators={{
                    // potentially other mutators could be merged here
                    ...arrayMutators,
                }}
                initialValuesEqual={() => true}
                render={({
                    handleSubmit,
                    reset,
                    values
                }) => (
                        <form
                            id="cpfPropertyFund"
                            onSubmit={handleSubmit}
                            noValidate
                        >
                            <div className="portal-form-popup">
                                <CPFClassification value={model.classification} disabled />
                                <div className="subtitle2">
                                    Gold Investment Details
                                </div>
                                <RenderSimpleFields
                                    fields={[
                                        {
                                            name: 'goldInvestmentType',
                                            label: 'Property Fund Issuer',
                                            type: FieldType.ENUM_SELECTION,
                                            enumData: optionsData.goldTypeOptions,
                                        },
                                        {
                                            name: 'unitsHeld',
                                            label: 'Unit Held (gm)',
                                            type: FieldType.NUMBER,
                                        },
                                        {
                                            name: 'unitPrice',
                                            label: 'Unit Price (gm)',
                                            type: FieldType.MONEY,
                                            isMoney: true,
                                            currency: 'SGD'
                                        },
                                        {
                                            name: 'costBase',
                                            label: 'Cost Base (Total)',
                                            type: FieldType.MONEY,
                                            isMoney: true,
                                            currency: 'SGD'
                                        },
                                        {
                                            name: 'currentValue',
                                            label: 'Current Value (Total)',
                                            type: FieldType.MONEY,
                                            isMoney: true,
                                            currency: 'SGD'
                                        },
                                        {
                                            name: 'valuationDate',
                                            label: 'Valuation Date',
                                            type: FieldType.DATE,
                                        },
                                        {
                                            name: 'annualGrowthRate',
                                            label: 'Annual Growth Rate',
                                            type: FieldType.PERCENTAGE,
                                        },
                                        {
                                            name: 'annualTotalReturn',
                                            label: 'Annual Total Return',
                                            type: FieldType.PERCENTAGE,
                                        },
                                        {
                                            name: 'monthlyServiceCharge',
                                            label: 'Monthly Service Charge',
                                            type: FieldType.MONEY,
                                            isMoney: true,
                                            currency: 'SGD'
                                        },
                                    ]}
                                />
                            </div>
                            <FormButtons
                                reset={reset}
                            />
                        </form>
                    )}
            />
        </div>


    )
}


function mapStateToProps(state) {
    const { optionsData } = state.enumOptions;

    return {
        optionsData,
    };
}

function mapDispatchToProps(dispatch) {

    return bindActionCreators(enumOptionsActions, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CPFGold);