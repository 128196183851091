import { CountryList } from '../constants/Lists';
import { getInAppCountryCurrency } from './CurrencyHelper';
import { translateListLabel } from './list-translation';

export const clientDataAdapter = client => {
    client.name = client.personalDetails.personalInformation.firstName;
    if (client.personalDetails.residencyDetails && client.personalDetails.residencyDetails.citizenship) {
        const { country } = client.personalDetails.residencyDetails.citizenship;
        client.country = country;
        if (country) {
            client.currency = (
                translateListLabel(CountryList).find(item => item.name === country) || {}
            ).abbreviation;
        }
    }

    client.getLink = type => client._embedded[type]._links.self.href;
    
    const { employmentDetails } = client.personalDetails;
    if (employmentDetails && employmentDetails.taxResidency) {
        client.taxResidency = employmentDetails.taxResidency;
        client.defaultCurrency = getInAppCountryCurrency(client.taxResidency);
    }
    return client;
};

export const isSingaporean = user => {
    const { residencyDetails } = user.personalDetails;
    return (
        residencyDetails &&
        residencyDetails.residencyStatus === 'CITIZEN' &&
        residencyDetails.citizenship &&
        residencyDetails.citizenship.country === 'SG'
    );
};

export const isHasSingaporeTaxRecidency = user => {
    const { employmentDetails } = user.personalDetails;
    return (
        employmentDetails &&
        employmentDetails.taxResidency &&
        employmentDetails.taxResidency.toLowerCase() === 'singapore'
    );
};
