import React, { useState, useEffect, useContext } from "react"
import Alert from "react-s-alert"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { AppContext } from "../../context/AppContext";
import AuthApi from "../../api/AuthApi";
import AppVersionDialog from "./AppVersionDialog";
function AppVersion() {
  const {
    modal: { setModal, clearModal }
  } = useContext(AppContext)

  const [resAppVersion, setResAppVersion] = useState();
  const getAppVersion = async (e) => {
    await AuthApi.getAppVersion().then(({data})=>{
      if(data){
        setResAppVersion(data);
        localStorage.setItem("app.version", JSON.stringify(data));
      }
     
    });
  }

  useEffect(() => {
    
    getAppVersion();
   
  }, [])

  
  function showVersionModal(){

    if(resAppVersion){
      setModal({
        title: `KoiZai Application Version`,
        content: (
          <AppVersionDialog 
            appVersion={resAppVersion.appVersion}
            previousVersions={resAppVersion.previousVersions}
            
          />
          
        ),
        isSmall: true
      })
    }
   
  }

  return (
    
    <div className="version" onClick={(e)=>showVersionModal()}>
      {resAppVersion && resAppVersion.appVersion?resAppVersion.appVersion.version:''}
    </div>
  )
}
export default AppVersion
