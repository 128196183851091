import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import CPFSummary from './CPFSummary';
import IntegratedShieldPlan from './IntegratedShieldPlan';
import CPFISOA from './CPFISOA';
import CPFISSA from './CPFISSA';
import RA from './RA';
import CPFLife from './CPFLife';
import Beneficary from './Beneficary';



function CPFAccountAndInvestmentDetails({ mutators, model }) {
    const [selectedTab, setTab] = useState('CPFSummary');
    const [tabs, setTabs] = useState([]);
    // const tabs = [
    //     {
    //         name: 'CPFSummary',
    //         label: intl.get('centralProvidenceFundInformation.CPFSummary'),
    //         component: key => <CPFSummary key={key} mutators={mutators} model={model} />,
    //     },
    //     {
    //         name: 'IntegratedShieldPlan',
    //         label: intl.get(
    //             'centralProvidenceFundInformation.IntegratedShieldPlan'
    //         ),
    //         component: key => <IntegratedShieldPlan key={key} mutators={mutators} model={model} />,
    //     },
    //     {
    //         name: 'COFISOA',
    //         label: intl.get('centralProvidenceFundInformation.COFISOA'),
    //         component: key => <CPFISOA key={key} mutators={mutators} model={model} />,
    //     },
    //     {
    //         name: 'COFISSA',
    //         label: intl.get('centralProvidenceFundInformation.COFISSA'),
    //         component: key => <CPFISSA key={key} mutators={mutators} model={model} />,
    //     },
    //     {
    //         name: 'RA',
    //         label: intl.get('centralProvidenceFundInformation.RA'),
    //         component: key => <RA key={key} mutators={mutators} model={model} />,
    //     },
    //     {
    //         name: 'CPFLife',
    //         label: intl.get('centralProvidenceFundInformation.CPFLife'),
    //         component: key => <CPFLife key={key} mutators={mutators} model={model} />,
    //     },
    //     {
    //         name: 'Beneficary',
    //         label: intl.get('centralProvidenceFundInformation.Beneficary'),
    //         component: key => <Beneficary key={key} mutators={mutators} model={model} />,
    //     },
    // ];

    useEffect(() => {
        const a = [
            {
                name: 'CPFSummary',
                label: intl.get('centralProvidenceFundInformation.CPFSummary'),
                component: key => <CPFSummary key={key} mutators={mutators} model={model} />,
            }
        ];
        if (model.haveRA) {
            a.push({
                name: 'RA',
                label: intl.get('centralProvidenceFundInformation.RA'),
                component: key => <RA key={key} mutators={mutators} model={model} />,
            })
        }
        if (model.haveIntegratedShieldPlan) {
            a.push({
                name: 'IntegratedShieldPlan',
                label: intl.get(
                    'centralProvidenceFundInformation.IntegratedShieldPlan'
                ),
                component: key => <IntegratedShieldPlan key={key} mutators={mutators} model={model} />,

            })
        }
        if (model.sourceOfFundOa) {
            a.push({
                name: 'COFISOA',
                label: intl.get('centralProvidenceFundInformation.COFISOA'),
                component: key => <CPFISOA key={key} mutators={mutators} model={model} />,
            });
        }
        if (model.sourceOfFundSa) {
            a.push({
                name: 'COFISSA',
                label: intl.get('centralProvidenceFundInformation.COFISSA'),
                component: key => <CPFISSA key={key} mutators={mutators} model={model} />,
            });
        }
        if (model.haveCPFLifePlan) {
            a.push({
                name: 'CPFLife',
                label: intl.get('centralProvidenceFundInformation.CPFLife'),
                component: key => <CPFLife key={key} mutators={mutators} model={model} />,
            });
        }
        a.push({
            name: 'Beneficary',
            label: intl.get('centralProvidenceFundInformation.Beneficary'),
            component: key => <Beneficary key={key} mutators={mutators} model={model} />,
        });
        setTabs(a);

        

    }, [model])
    return (
        <div className="portal-form-table">
            <div className="subtitle-holder">
                <div className="title">
                    CPF Account and Investment Details
                </div>
            </div>

            <div className="content-panel-tab tab7">
                {tabs.map((item, index) => (
                    <a
                        href="# "
                        role="button"
                        key={index}
                        onClick={() => setTab(item.name)}
                        className={selectedTab === item.name ? 'selected' : ''}
                    >
                        {item.label}
                    </a>
                ))}
            </div>

            {tabs
                .filter(item => item.name === selectedTab)
                .map((item, key) => item.component(key))}
        </div>
    );
}

export default CPFAccountAndInvestmentDetails;
