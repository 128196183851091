import React from 'react';
import intl from 'react-intl-universal';
import { RenderSimpleFields } from '../../../components';
import { FieldType } from './../../../components/Form/RenderSimpleFields';
import ClassificationSelection from './../../../components/Form/ClassificationSelection';

function SuplementaryRetirementSchemeLeftSide(props) {
    return (
        <React.Fragment>
            <ClassificationSelection />
            <div className="subtitle2">
                {intl.get('centralProvidenceFundInformation.details')}
            </div>
            <div className="portal-form-half portal-form-half-srs-LeftSide">
                <RenderSimpleFields
                    fields={[
                        {
                            name: 'srsPlatformName',
                            label: intl.get('field.srsPlatformName'),
                            type: FieldType.TEXT,
                        },
                        {
                            name: 'srsAccount',
                            label: intl.get('field.srsAccount'),
                            type: FieldType.TEXT,
                        },
                        {
                            name: 'srsIssuer',
                            label: intl.get('field.srsIssuer'),
                            type: FieldType.TEXT,
                        },
                        {
                            name: 'srsCurrentValue',
                            label: intl.get('field.currentValue'),
                            type: FieldType.MONEY,
                        },
                        {
                            name: 'srsValuationDate',
                            label: intl.get('field.valuationDate'),
                            type: FieldType.DATE,
                        },
                    ]}
                />
            </div >
        </React.Fragment>
    );
}

export default SuplementaryRetirementSchemeLeftSide;