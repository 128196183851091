import React from 'react';
import NumberFormat from 'react-number-format';
import { DECIMAL_SCALE } from '../../constants/NumberFormatConstants';
import { getSymbolFromCode } from '../../helpers';

const ReadOnlyCurrency = ({
    value,
    className = 'text-input text-input-figure',
    additionalClass,
    type = '',
    currency = 'MYR',
}) => (
    <NumberFormat
        value={value}
        displayType={'input'}
        allowNegative
        fixedDecimalScale
        decimalScale={DECIMAL_SCALE}
        prefix={getSymbolFromCode(currency)}
        decimalSeparator={'.'}
        thousandSeparator={','}
        className={`${className}${type} ${additionalClass}`}
        readOnly
    />
);

export default ReadOnlyCurrency;
