import React from 'react';
import intl from 'react-intl-universal';

function PensionInsuranceDetails() {
    return (
        <table
            className="table-investment"
            width="100%"
            cellSpacing="0"
            cellPadding="0"
            border="0"
        >
            <tbody>
                <tr>
                    <th>
                        {intl.get(
                            'netWorth.pensionInsurance.assuredPersonName'
                        )}
                    </th>
                    <th>
                        {intl.get('netWorth.pensionInsurance.insuranceIssuer')}
                    </th>
                    <th>{intl.get('netWorth.pensionInsurance.productName')}</th>
                    <th>
                        {intl.get('netWorth.pensionInsurance.policyNumber')}
                    </th>
                    <th>{intl.get('netWorth.pensionInsurance.startDate')}</th>
                    <th>
                        {intl.get('netWorth.pensionInsurance.maturityDate')}
                    </th>
                    <th>{intl.get('netWorth.pensionInsurance.policyTerm')}</th>
                    <th>
                        {intl.get('netWorth.pensionInsurance.basicAssured')}
                    </th>
                    <th>
                        {intl.get('netWorth.pensionInsurance.premiumAmount')}
                    </th>
                    <th>
                        {intl.get('netWorth.pensionInsurance.permiumFrequency')}
                    </th>
                    <th>{intl.get('netWorth.pensionInsurance.policyTypes')}</th>
                    <th>&nbsp;</th>
                    <th>
                        <button type="button" className="btn btn-plus">
                            &nbsp;
                        </button>
                    </th>
                </tr>
                <tr>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>
                        <button type="button" className="btn-remove">
                            &nbsp;
                        </button>
                    </td>
                    <td>&nbsp;</td>
                </tr>
            </tbody>
        </table>
    );
}

export default PensionInsuranceDetails;
