/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Description : collectible/antiques/arts page (modal)
*/

import React from "react"
import intl from "react-intl-universal"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { modalWindow } from "../../constants/ModalWindowLists"
import { modalWindowActions, netWorthFormActions } from "../../actions"
import NetWorthForm, {
  NetWorthPath,
  NetWorthFormPropsComposer
} from "./NetWorthForm"
import InvestmentPlatformForm from "../../forms/InvestmentPlatformForm"

class InvestmentPlatform extends NetWorthForm {
  constructor(props) {
    super(
      props,
      intl.get("investmentPlatforms.title"),
      modalWindow.INVESTMENT_PLATFORM,
      NetWorthPath.investmentPlatforms,
      "InvestmentPlatform"
    )
  }

  render() {
    const { handleSave, props, contentWrapper, readOnlyAccessComponent } = this
    const { optionsData, model, client, partner, isJoint, currency } = props

    // 20201005 todo
    return contentWrapper(
      <>
        <InvestmentPlatformForm
          handleSave={handleSave}
          model={model}
          optionsData={optionsData}
          readOnlyAccess={readOnlyAccessComponent()}
          currency={currency}
          clientInformation={{ client, partner, isJoint }}
        />
      </>
    )
  }
}

function mapStateToProps(state) {
  const props = NetWorthFormPropsComposer(
    state,
    modalWindow.INVESTMENT_PLATFORM,
    NetWorthPath.investmentPlatforms
  )

  return {
    ...props,
    isShowInnerForm: state.modalWindow[modalWindow.INVESTMENT_PLATFORM_SUB_FORM]
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, netWorthFormActions, modalWindowActions),
    dispatch
  )
}

InvestmentPlatform = connect(
  mapStateToProps,
  mapDispatchToProps
)(InvestmentPlatform)

export default InvestmentPlatform
