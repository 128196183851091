/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Description : GEAL hongkong page (modal)
*/

import React from 'react';
import intl from 'react-intl-universal';
import { Form } from 'react-final-form';

import {
    FormButtons,
    ClassificationSelection,
    ModalNoAccess,
} from '../components';
import RenderSimpleFields, {
    FieldType,
} from '../components/Form/RenderSimpleFields';

const GEALHongkongForm = ({
    handleSave,
    handleChange,
    model,
    optionsData,
    readOnlyAccess,
    showClassification = true,
    currency = 'HKD',
}) =>
    !readOnlyAccess ? (
        <Form
            onSubmit={handleSave}
            initialValues={model}
            render={({ handleSubmit, reset }) => (
                <form id="hongkongGealForm" onSubmit={handleSubmit}>
                    <div className="portal-form-popup">
                        <div className="hongkongGealForm-content">
                            {/* show Joint */}
                            {/* {showClassification && (
                                <ClassificationSelection hideJoint />
                            )} */}
                            {showClassification && (
                                <ClassificationSelection />
                            )}
                            <div className="subtitle2">
                                {intl.get('geal.subtitle.modal', {
                                    countryDesc: 'Hong Kong',
                                })}
                            </div>

                            <RenderSimpleFields
                                fields={[
                                    {
                                        name: 'educationAssistanceLoanType',
                                        label: intl.get(
                                            'field.loan.educationAssistanceLoanType'
                                        ),
                                        type: FieldType.ENUM_SELECTION,
                                        required: true,
                                        enumData:
                                            optionsData.educationAssistanceLoanTypeHKOptions,
                                    },
                                    {
                                        name: 'loanBalance',
                                        label: intl.get(
                                            'field.loan.loanBalance'
                                        ),
                                        type: FieldType.MONEY_WITH_CURRENCY,
                                        lockToCurrency: currency,
                                        required: true,
                                        allowNegative: false,
                                    },
                                    {
                                        name: 'valuationDate',
                                        label: intl.get(
                                            'field.loan.valuationDate'
                                        ),
                                        type: FieldType.DATE,
                                        required: true,
                                    },
                                    {
                                        name: 'loanStartDate',
                                        label: intl.get(
                                            'field.loan.loanStartDate'
                                        ),
                                        type: FieldType.DATE,
                                        required: true,
                                    },
                                    {
                                        name: 'repaymentStartDate',
                                        label: intl.get(
                                            'field.loan.repaymentStartDate'
                                        ),
                                        type: FieldType.DATE,
                                    },
                                    {
                                        name: 'loanAnnualInterestRate',
                                        label: intl.get(
                                            'field.loan.loanAnnualInterestRate'
                                        ),
                                        type: FieldType.PERCENTAGE,
                                    },
                                    {
                                        name: 'loanRepaymentFrequency',
                                        label: intl.get(
                                            'field.loan.loanRepaymentFrequency'
                                        ),
                                        type: FieldType.ENUM_SELECTION,
                                        enumData: optionsData.frequencyOptions,
                                        required: true,
                                    },
                                    {
                                        name: 'loanRepaymentAmount',
                                        label: intl.get(
                                            'field.loan.loanRepaymentAmount'
                                        ),
                                        type: FieldType.MONEY_WITH_CURRENCY,
                                        lockToCurrency: currency,
                                        allowNegative: false,
                                    },
                                    
                                    {
                                        name: 'loanTermsInYears',
                                        label: intl.get(
                                            'field.loan.loanTermsInYears'
                                        ),
                                        numberFormat:'##',
                                        type: FieldType.NUMBER,
                                    },
                                    {
                                        name: 'administrationFee',
                                        label: intl.get(
                                            'field.loan.administrationFee'
                                        ),
                                        type: FieldType.MONEY_WITH_CURRENCY,
                                        lockToCurrency: currency,
                                        allowNegative: false,
                                    },
                                ]}
                            />
                        </div>
                    </div>

                    <FormButtons
                        reset={reset}
                        readOnlyAccess={readOnlyAccess}
                    />
                </form>
            )}
        />
    ) : (
        <ModalNoAccess />
    );

export default GEALHongkongForm;
