import React, { useEffect, useState, useContext } from "react"
import { Chart, Bar } from "react-chartjs-2"
import intl from "react-intl-universal"
import Tooltip from "rc-tooltip"
import Slider from "rc-slider"
import "rc-tooltip/assets/bootstrap.css"
import {
  DefaultBarChartOptions,
  DefaultTicksOptions
} from "../../helpers/ChartHelper"
import { getSymbolFromCode } from "../../helpers"
import { numberNoDecimalPoints } from "../../helpers/CurrencyConversion"
import { LifeLineContext } from "../../pages/CurrentStanding/LifeLine"
import Alert from "react-s-alert"
import { useSelector } from "react-redux"
import html2canvas from "html2canvas"

function TenYearsProjectionChart({
  data,
  currentAge,
  currentYear,
  onRetirementAgeChange,
  onRetirementAgeChange1,
  isShowSlider = false,
  currency,
  chartId,
  clientChart
}) {
  const { clientFullNamePreferred, partnerFullNamePreferred } = useSelector(
    (state) => state.entity
  )
  const [chartMostLeft, setChartMostLeft] = useState(0)
  const [sliderMargin, setSliderMargin] = useState({
    marginLeft: 0
  })
  const wholelifelineData = useContext(LifeLineContext)

  // effect to draw retirement age lines (extend chart.js default drawing)
  useEffect(() => {
    const retirementLinePlugin = {
      id: "retirementLinePlugin",
      afterDraw(chart) {
        if (
          chart.config.options.id === chartId &&
          chart.config.data.lineAtIndex
        ) {
          const index = chart.config.data.lineAtIndex
          const index1 = chart.config.data.lineAtIndex1
          const clientAge = chart.config.data.clientAge
          const partnerAge = chart.config.data.partnerAge

          const { ctx } = chart
          const xaxis = chart.scales["x-axis-0"]
          const yaxis = chart.scales["y-axis-0"]

          if (index && xaxis && yaxis) {
            ctx.save()
            ctx.beginPath()
            ctx.moveTo(
              xaxis.getPixelForValue(undefined, index < 0 ? 0 : index),
              yaxis.top - 20
            )
            ctx.lineTo(
              xaxis.getPixelForValue(undefined, index < 0 ? 0 : index),
              yaxis.bottom
            )

            if (index1) {
              ctx.moveTo(
                xaxis.getPixelForValue(undefined, index1 < 0 ? 0 : index1),
                yaxis.top
              )
              ctx.lineTo(
                xaxis.getPixelForValue(undefined, index1 < 0 ? 0 : index1),
                yaxis.bottom + 30
              )
            }

            ctx.lineWidth = 2
            ctx.strokeStyle = "#ff2121"
            ctx.stroke()
            ctx.restore()

            ctx.font = "8pt Arial"
            ctx.textAlign = "center"
            ctx.fillStyle = "#ff2121"

            const clientName = clientFullNamePreferred || ""

            ctx.fillText(
              `${clientName ? `${clientName}` : ""} (Client)`,
              xaxis.getPixelForValue(undefined, index < 0 ? 0 : index),
              yaxis.top - 25 - 20
            )

            ctx.fillText(
              index > 0
                ? `Retirement - ${intl.get("general.age")} ${
                    index + clientAge
                  }, ${intl.get("general.year")} ${index + currentYear}`
                : `Already Retired ${-index} years ago (${
                    currentYear + index
                  })`,
              xaxis.getPixelForValue(undefined, index < 0 ? 0 : index),
              yaxis.top - 10 - 20
            )

            if (index1) {
              const partnerName = partnerFullNamePreferred || ""

              ctx.fillText(
                `${partnerName ? `${partnerName}` : ""} (Partner)`,
                xaxis.getPixelForValue(undefined, index1 < 0 ? 0 : index1),
                yaxis.bottom + 40
              )

              ctx.fillText(
                index1 > 0
                  ? `Retirement - ${intl.get("general.age")} ${
                      index1 + partnerAge
                    }, ${intl.get("general.year")} ${index1 + currentYear}`
                  : `Already Retired ${-index1} years ago (${
                      currentYear + index1
                    })`,
                xaxis.getPixelForValue(undefined, index1 < 0 ? 0 : index1),
                yaxis.bottom + 55
              )
            }
          }
        }
      }
    }

    // Register the plugin
    Chart.pluginService.register(retirementLinePlugin)

    // Cleanup: Unregister the plugin on component unmount
    return () => {
      Chart.pluginService.unregister(retirementLinePlugin)
    }
  }, [
    chartId,
    clientFullNamePreferred,
    partnerFullNamePreferred,
    currentYear,
    wholelifelineData
  ])

  useEffect(() => {
    calculateMargin(chartMostLeft)
  }, [chartMostLeft])

  // property for slider
  const { Handle } = Slider

  // set chart data
  const setChartDataSets = (
    arrTotalAssets,
    arrTotalLiabilities,
    arrNetAssets
  ) => {
    const datasets = [
      {
        label: "Assets",
        data: arrTotalAssets,
        backgroundColor: "#4983f0",
        // borderColor: "rgba(0,161,255,1)",
        borderWidth: 1,

        barThickness: 40 // Adjust this value for width
      },
      {
        label: "Liabilities",
        data: arrTotalLiabilities,
        backgroundColor: "#dd6e0f",
        barThickness: 40 // Adjust this value for width
      },
      {
        label: "Net Assets",
        data: arrNetAssets,
        backgroundColor: "#a4b7f9",
        borderColor: "#a4b7f9",
        borderWidth: 1,
        type: "line",
        fill: false
      }
    ]

    return datasets
  }
  const setChartData = (rawData, currAge, retirementAge) => {
    // data translation process here

    const arrTotalAssets = []
    const arrTotalLiabilities = []
    const arrNetAssets = []
    let chartLabels = []
    let curentYear = new Date().getFullYear()
    rawData &&
      rawData.gapProjectionSettings &&
      rawData.gapProjectionSettings.requestGapList &&
      rawData.gapProjectionSettings.requestGapList.forEach((label) => {
        chartLabels.push([label.name, label.numOfYears + curentYear])
      })
    //  label.numOfYears + curentYear

    rawData.gapProjectionResult.forEach((chartData) => {
      const { totalAssets, totalLiabilities, totalNetAssets } = chartData
      arrTotalAssets.push(totalAssets)
      arrTotalLiabilities.push(totalLiabilities)
      arrNetAssets.push(totalNetAssets)
    })

    return {
      labels: chartLabels,
      datasets: setChartDataSets(
        arrTotalAssets,
        arrTotalLiabilities,
        arrNetAssets
      ),

      lineAtIndex: !isShowSlider
        ? wholelifelineData.classification !== "PARTNER" &&
          wholelifelineData.lifelineObject.client
          ? wholelifelineData.lifelineObject.client.ageInfo &&
            wholelifelineData.lifelineObject.client.ageInfo.retirementAge -
              wholelifelineData.lifelineObject.client.ageInfo.age
          : "undefined"
        : wholelifelineData.classification !== "PARTNER" &&
          wholelifelineData.lifelineObject1.client
        ? wholelifelineData.lifelineObject1.client.ageInfo &&
          wholelifelineData.lifelineObject1.client.ageInfo.retirementAge -
            wholelifelineData.lifelineObject1.client.ageInfo.age
        : "undefined",

      lineAtIndex1: !isShowSlider
        ? wholelifelineData.classification !== "CLIENT" &&
          wholelifelineData.lifelineObject.partner !== null
          ? wholelifelineData.lifelineObject.partner.ageInfo.retirementAge -
            wholelifelineData.lifelineObject.partner.ageInfo.age
          : ""
        : wholelifelineData.classification !== "CLIENT" &&
          wholelifelineData.lifelineObject1.partner !== null
        ? wholelifelineData.lifelineObject1.partner.ageInfo.retirementAge -
          wholelifelineData.lifelineObject1.partner.ageInfo.age
        : "",
      clientAge: !isShowSlider
        ? wholelifelineData.lifelineObject.client !== null
          ? wholelifelineData.lifelineObject.client.ageInfo.age
          : ""
        : wholelifelineData.lifelineObject1.client !== null
        ? wholelifelineData.lifelineObject1.client.ageInfo.age
        : ""
    }
  }

  const handle = (props) => {
    const { value, dragging, index, ...restProps } = props
    return (
      <Tooltip
        zIndex="1"
        prefixCls="rc-slider-tooltip"
        overlay={value}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </Tooltip>
    )
  }

  const handleOnAfterChange = (value) => {
    onRetirementAgeChange(value)
  }
  const handleOnAfterChange1 = (value) => {
    onRetirementAgeChange1(value)
  }

  const numberFormat = (value) =>
    ` ${getSymbolFromCode(currency)}${numberNoDecimalPoints(value)}`

  const generateCustomTooltips = () => ({
    // Disable the on-canvas tooltip
    enabled: false,

    custom(tooltipModel) {
      // Tooltip Element
      let tooltipEl = document.getElementById("chartjs-tooltip")
      let tableStyle = ""

      // Create element on first render
      if (!tooltipEl) {
        tooltipEl = document.createElement("div")
        tooltipEl.id = "chartjs-tooltip"
        tableStyle =
          "background-color: #333;" +
          "border-color: #333;" +
          "color: #fff;" +
          "width: 100%;" +
          "z-index:9999 !important;" +
          "padding: 15px;"

        tooltipEl.innerHTML = `<table style="${tableStyle}"></table>`
        document.body.appendChild(tooltipEl)
      }

      // Hide if no tooltip
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0
        return
      }

      // Set caret Position
      tooltipEl.classList.remove("above", "below", "no-transform")
      if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign)
      } else {
        tooltipEl.classList.add("no-transform")
      }

      //   function getBody(bodyItem) {
      //     return bodyItem.lines
      //   }

      // get data on data array index n
      const lifelineDataItem =
        data.gapProjectionResult[tooltipModel.dataPoints[0].index]

      const { totalAssets, totalLiabilities, totalNetAssets } = lifelineDataItem

      // Set Text
      if (tooltipModel.body) {
        const titleLines = tooltipModel.title || []

        let innerHtml = "<thead><tr>"
        let boolValue =
          wholelifelineData.classification === "PARTNER" ||
          wholelifelineData.classification === "CLIENT"
        if (titleLines.length === 1) {
          const oneItemTitleLine = titleLines[0].split(",")
          innerHtml += `<th>${
            oneItemTitleLine[0] == 0 ? "Today" : oneItemTitleLine[0]
          }</th>`
          innerHtml += boolValue
            ? `<th>${intl.get("general.year")} ${oneItemTitleLine[1]}</th>`
            : ""
        } else {
          innerHtml += `<th>${titleLines[0]}</th>`
        }

        innerHtml += "</tr></thead><tbody>"

        innerHtml += `<tr class=""><td class="tooltip-chart pt-1"><div class="default">Total Assets</div></td><td class="tooltip-chart"><div class="default currency">${numberFormat(
          totalAssets
        )}</div></td></tr>`
        innerHtml += `<tr><td class="tooltip-chart pt-1"><div class="default">Total Liabilities</div></td><td class="tooltip-chart"><div class="default currency">${numberFormat(
          totalLiabilities
        )}</div></td></tr>`
        innerHtml += `<tr>
    <td style="margin-top:6px;margin-bottom:0;padding:0;" >
    </td>
    <td style="margin:0;padding:0;">
        <hr style="margin-top:6px;margin-bottom:0;padding:0;" />
    </td>
</tr>`
        if (totalNetAssets <= 0) {
          innerHtml += `<tr><td class="tooltip-chart pt-1 pb-1"><div class="default">Total Net Assets</div></td><td class="tooltip-chart"><div class="default currency bolded red">${numberFormat(
            totalNetAssets
          )}</div></td></tr>`
        } else {
          innerHtml += `<tr><td class="tooltip-chart pt-1 pb-1"><div class="default">Total Net Assets</div></td><td class="tooltip-chart"><div class="default currency bolded green">${numberFormat(
            totalNetAssets
          )}</div></td></tr>`
        }

        innerHtml += "</tbody>"

        const tableRoot = tooltipEl.querySelector("table")
        tableRoot.innerHTML = innerHtml
      }

      // `this` will be the overall tooltip
      const position = this._chart.canvas.getBoundingClientRect()

      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1
      tooltipEl.style.position = "absolute"
      const positionOfToolTipTop = clientChart ? 300 : 55

      tooltipEl.style.left = `${
        // position.left + window.pageXOffset + // open this if tooltip position in right
        tooltipModel.caretX + positionOfToolTipTop
      }px`
      tooltipEl.style.top = `${
        position.top + window.pageYOffset + tooltipModel.caretY - 150
      }px`

      tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily
      tooltipEl.style.fontSize = `${tooltipModel.bodyFontSize}px`
      tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle
      tooltipEl.style.padding = `${tooltipModel.yPadding}px ${tooltipModel.xPadding}px`
      tooltipEl.style.pointerEvents = "none"
      tooltipEl.style.zIndex = 99
    }
  })

  const calculateMargin = (cml) => {
    setSliderMargin({
      marginLeft: cml
    })
  }

  const calculateWidth = (rawData) => {
    if (rawData.lifeline !== undefined) {
      const dataCount = rawData.lifeline.length
      const segmentPercent = 100 / dataCount
      const totalNewWidthPercent =
        (dataCount - 1) * segmentPercent + segmentPercent / 2

      return { width: `${totalNewWidthPercent}%` }
    }

    return { width: `100%` }
  }

  const exportAsImage = async (imageFileName) => {
    let element = document.querySelector("#ten-year-projection-chart")
    // document.body.style.zoom = "80%"
    //element.style.zoom = "80%";
    const canvas = await html2canvas(element)
    const image = canvas.toDataURL("image/png", 1.0)
    downloadImage(image, imageFileName)
  }

  const downloadImage = (blob, fileName) => {
    const fakeLink = window.document.createElement("a")
    fakeLink.style = "display:none;"
    fakeLink.download = fileName

    fakeLink.href = blob

    document.body.appendChild(fakeLink)
    fakeLink.click()
    document.body.removeChild(fakeLink)

    fakeLink.remove()
  }

  function chartToFile(id) {
    let canvas = document.getElementById(id)
    if (canvas) {
      let dataURL = canvas.toDataURL("image/jpg", 1.0)
      navigator.clipboard.writeText(dataURL)
      Alert.success("Image copied to clipboard!")
    }
  }

  return (
    <React.Fragment>
      <div className="lifeline-chart-container" id="theChartContainer">
        <button
          className="portal-btn portal-btn-submit fa fa-camera"
          onClick={(e) => exportAsImage("Lifeline")}
        ></button>
        &nbsp;
        <button
          className="portal-btn portal-btn-submit fa fa-save"
          onClick={(e) => chartToFile("ten-year-projection-chart")}
        ></button>
        <Bar
          id="ten-year-projection-chart"
          data={setChartData(
            data,
            currentAge,
            data.ageInfo && data.ageInfo.retirementAge
              ? data.ageInfo.retirementAge
              : 0
          )}
          // width={100}
          // height={300}
          options={{
            ...DefaultBarChartOptions,
            id: chartId,
            maintainAspectRatio: false,
            responsive: true,
            scales: {
              xAxes: [
                {
                  gridLines: {
                    display: true,
                    drawBorder: true,
                    drawOnChartArea: false
                  },
                  display: true,
                  barThickness: 40,
                  weight: 10,
                  offset: true
                }
              ],
              yAxes: [
                {
                  gridLines: {
                    display: true,
                    drawBorder: true,
                    drawOnChartArea: false
                  },
                  ticks: DefaultTicksOptions,

                  scaleLabel: {
                    display: true,
                    labelString: "Amount"
                  }
                }
              ]
            },
            tooltips: generateCustomTooltips(),
            layout: {
              padding: {
                top: 60
              }
            },
            legend: {
              position: "bottom",
              align: "center",
              display: true
            }
          }}
        />
      </div>

      {/* {isShowSlider && (
        <div className="slider-1" style={sliderMargin}>
          {wholelifelineData.lifelineObject1.client && (
            <div
              className=" content-1"
              // style={calculateWidth(data)}
            >
              <span>Client</span>
              {currentAge !== 0 && data.lifeline !== undefined && (
                <Slider
                  min={currentAge} // current age
                  max={currentAge + data.lifeline.length - 1} // max 100 age for now
                  defaultValue={
                    wholelifelineData.lifelineObject1.client.ageInfo
                      .retirementAge
                  } // retirement age for selected
                  handle={handle}
                  onAfterChange={handleOnAfterChange}
                />
              )}
            </div>
          )}

          {wholelifelineData.lifelineObject.partner && (
            <div
              className=" content-1"
              // style={calculateWidth(data)}
            >
              <span>Partner</span>

              <Slider
                min={currentAge} // current age
                max={currentAge + data.lifeline.length - 1} // max 100 age for now
                defaultValue={
                  wholelifelineData.lifelineObject1.partner.ageInfo
                    .retirementAge
                } // retirement age for selected
                handle={handle}
                onAfterChange={handleOnAfterChange1}
              />
            </div>
          )}
        </div>
      )} */}
    </React.Fragment>
  )
}

export default TenYearsProjectionChart
