import Alert from 'react-s-alert';
import intl from 'react-intl-universal';
import { apiAction } from '../constants/ActionConstants';
import { history } from "../helpers";
export const loaderActions = {
    beginApiCall,
    endApiCall,
    showApiError,
};

function showAlert(errorMessage) {
    return () => {
        if (errorMessage && errorMessage !== null){
            if (errorMessage.response) {
                Alert.error(
                    errorMessage.response.data.message ||
                        errorMessage.response.data.errorMessage
                );
            } else {
                history.push(`/undermaintenance/${intl.get('error.networkConnectionFailedToApi')}`)
              
            }
        }
           
    };
}

export function beginApiCall() {
    return async (dispatch, getState) => {
        dispatch({ type: apiAction.BEGIN_API_CALL });
    };
}

export function endApiCall() {
    return async (dispatch, getState) => {
        dispatch({ type: apiAction.END_API_CALL });
    };
}

export function showApiError(errorMessage = null) {
    return async (dispatch, getState) => {
        if (errorMessage !== null) {
            dispatch(showAlert(errorMessage));
        }
        dispatch({ type: apiAction.API_CALL_ERROR });
    };
}
