import React from "react"
import ClientBurgerMenu from "./ClientBurgerMenu"

import { NonClientBurgerMenu } from "."

const getComponent = (props) => {
  if (props.entityType == "CLIENT") {
    return (
      <ClientBurgerMenu
        {...props}
        entityType={props.entityType}
        client={props.match.params.id ? props.match.params.id : props.clientId}
        clientId={
          props.match.params.id ? props.match.params.id : props.clientId
        }
      />
    )
  } else {
    if (props.match.params.id) {
      return (
        <ClientBurgerMenu
          {...props}
          client={
            props.match.params.id ? props.match.params.id : props.clientId
          }
          clientId={props.match.params.id}
        />
      )
    } else {
      return <NonClientBurgerMenu {...props} />
    }
  }
}

const BurgerMenuSelector = (props) => (
  <React.Fragment>{getComponent(props)}</React.Fragment>
)

export default BurgerMenuSelector
