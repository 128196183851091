/*
    Author : anton.nuansantio@koizai.com
    Library : TaskManagementApi
    Description : methods related with Financial Regulators
*/

import api from '../lib/api';
// change base route for different entity/domain here
const BASE_ROUTE = '/task-management';

class TaskManagementApi {

    static getById(id) {
        return api.get(`${BASE_ROUTE}/${id}`);
    }

    static getEnumList() {
        return api.get(`${BASE_ROUTE}/enums`);
        
    }
    static getList(keyword) {
        if(keyword){
            return api.get(`${BASE_ROUTE}?keyword=${keyword}`);
        }else{
            return api.get(`${BASE_ROUTE}`);
        }
        
    }

    static listPerStatus(status, keyword, filter) {
        if(keyword){
            return api.get(`${BASE_ROUTE}?status=${status}&&keyword=${keyword}&&filter=${filter}`);
            
        }else{
            return api.get(`${BASE_ROUTE}?status=${status}&&filter=${filter}`);
        }
        
    }

  

    static save(dto) {
        return api.post(`${BASE_ROUTE}`, dto);
        
    }

    static deleteById(id) {
        return api.delete(`${BASE_ROUTE}/${id}`);
        
    }

    static postComment(dto) {
        return api.post(`${BASE_ROUTE}/comment`, dto);
        
    }

    static deleteComment(taskId, commentId) {
        return api.delete(`${BASE_ROUTE}/${taskId}/comment/${commentId}`);
        
    }

  
    
}

export default TaskManagementApi;
