import { cashFlowAction } from '../../../constants/ActionConstants';

export function cashFlow(state = {}, action) {
    switch (action.type) {
        case cashFlowAction.GET_CLIENT_SUCCESS: {
            return action.response.data;
        }
        case cashFlowAction.EDIT_CLIENT_CASHFLOW_SUCCESS: {
            return action.response.data;
        }
        default:
            return state;
    }
}
