import React, { useRef, useState } from "react"
import intl from "react-intl-universal"
import Dropdown from "rc-dropdown"
import Menu, { Item as MenuItem } from "rc-menu"
import NumberFormat from "react-number-format"
import { Field } from "react-final-form"
import { createListingData } from "../../services/FundAllocationListingData"
import { DECIMAL_SCALE } from "../../constants/NumberFormatConstants"
import { getSymbolFromCode } from "../../helpers"
import CollapseDropDown from "../../components/LoginForm/CollapseDropDown"
import { useClickOutside } from "../../components/_hook/useClickOutside"

function ListingHeader({
  data,
  contextMenuItem,
  allocationType,
  onAllocationTypeChange,
  onSelectContextMenu
}) {
  const [showDropDown, setShowDropDown] = useState(false)
  const dropdownRef = useRef(null)
  useClickOutside(dropdownRef, {
    onClickOutside: () => {
      setShowDropDown(false)
    }
  })
  return (
    <React.Fragment>
      <tr>
        <th rowSpan="2">INSTRUMENT TYPE</th>
        <th rowSpan="2">NAME</th>
        <th rowSpan="2">SYMBOL</th>

        <th rowSpan="2">UNIT PRICE</th>
        <th rowSpan="2">UNITS HOLD</th>
        <th rowSpan="2">TOTAL PRICE</th>

        <th rowSpan="2">CONTRIBUTION</th>
        <th></th>

        {!data.verify ? (
          <th rowSpan="2" style={{ width: "100px", position: "relative" }}>
            {/* // 20211210 disable menu if mandatory fields are not filled in */}
            <div ref={dropdownRef} style={{ position: "relative" }}>
              {!data.verify ? (
                <span
                  className={`btn ${
                    data &&
                    data.investmentProviderName &&
                    data.institutionName &&
                    data.investmentAdviserNo &&
                    data.accountStartDate &&
                    data.reportingCurrency
                      ? "btn-plus"
                      : "btn-plus-grey"
                  }`}
                  onClick={() => setShowDropDown(!showDropDown)}
                ></span>
              ) : (
                <></>
              )}
              <CollapseDropDown
                show={showDropDown}
                className="investment-platform_overlay"
              >
                <>
                  {Object.keys(contextMenuItem).map((item, idx) => {
                    return (
                      <div
                        onClick={() => {
                          onSelectContextMenu(item)
                          setShowDropDown(false)
                        }}
                        className="language-item d-flex align-items-center "
                        key={idx}
                      >
                        <span className="lang">{contextMenuItem[item]}</span>
                      </div>
                    )
                  })}
                </>
              </CollapseDropDown>
            </div>
          </th>
        ) : (
          <></>
        )}
      </tr>
      <tr></tr>
    </React.Fragment>
  )
}

function ListingContent({
  allocationType,
  data,
  listingData,
  onItemSelect,
  onItemDelete,
  regularSavingCurrency,
  regularWithdrawalCurrency,
  savingAllocationError,
  withdrawalAllocationError
}) {
  return listingData.map((item, index) => (
    <tr key={index}>
      <td>{item.instrumentType}</td>
      <td>{item.name}</td>
      <td>{item.symbol}</td>

      <td>
        <div
          className={`allocation-field ${
            withdrawalAllocationError ? "error" : ""
          }`}
        >
          <span className="text-input money-value">
            <NumberFormat
              value={item.value || 0}
              displayType={"text"}
              thousandSeparator={","}
              decimalScale={DECIMAL_SCALE}
              decimalSeparator={"."}
              readOnly
              prefix={getSymbolFromCode(regularSavingCurrency)}
            />
          </span>
        </div>
      </td>
      <td>{item.numberOfUnitsPurchased}</td>
      <td>
        <div className="allocation-field">
          <span className="text-input money-value">
            <NumberFormat
              value={(item.value || 0) * (item.numberOfUnitsPurchased || 0)}
              displayType={"text"}
              thousandSeparator={","}
              decimalScale={DECIMAL_SCALE}
              decimalSeparator={"."}
              readOnly
              prefix={getSymbolFromCode(regularSavingCurrency)}
            />
          </span>
        </div>
      </td>

      <td>{item.allocation.toFixed(2)}%</td>

      <td>
        {/* {!data.verify ? ( */}
        <>
          {" "}
          <span onClick={() => onItemSelect(item)} className="btn btn-edit" />
          {/* <span onClick={() => onItemDelete(item)} className="btn-remove" /> */}
        </>
        {/* ) : (
          <></>
        )} */}
      </td>
    </tr>
  ))
}
function ListingSummary({
  total,
  listingData,
  regularSaving,
  regularWithdrawal,
  allocationType,
  regularSavingCurrency,
  regularWithdrawalCurrency,
  savingAllocationError,
  withdrawalAllocationError,
  totalCurrency,
  totalUnitsHold,
  totalPrice
}) {
  return (
    <tr className="total">
      <td>{intl.get("field.total")}</td>
      <td></td>
      <td></td>

      <td>
        <div
          className={`allocation-field ${
            withdrawalAllocationError ? "error" : ""
          }`}
        >
          {/* <span className="text-input money-value">
            <NumberFormat
              value={total || 0}
              displayType={"text"}
              thousandSeparator={","}
              decimalScale={DECIMAL_SCALE}
              decimalSeparator={"."}
              readOnly
              prefix={getSymbolFromCode(totalCurrency)}
            />
          </span> */}
        </div>
      </td>
      <td>{totalUnitsHold}</td>
      <td>
        <div
          className={`allocation-field ${
            withdrawalAllocationError ? "error" : ""
          }`}
        >
          <span className="text-input money-value">
            <NumberFormat
              value={totalPrice || 0}
              displayType={"text"}
              thousandSeparator={","}
              decimalScale={DECIMAL_SCALE}
              decimalSeparator={"."}
              readOnly
              prefix={getSymbolFromCode(totalCurrency)}
            />
          </span>
        </div>
      </td>
      <td>{listingData.length > 0 ? "100.00%" : "0.00%"}</td>

      <td />
    </tr>
  )
}

export const FundAllocationListing = ({
  data, //20201012
  contextMenuItem,
  onSelectContextMenu,
  onItemSelect,
  onItemDelete,
  onAllocationTypeChange: mutateOnAllocationTypeChange,
  savingAllocationError,
  withdrawalAllocationError
}) => {
  const [state, setState] = useState({ allocationType: "currency" })
  const listingData = createListingData(data, data.reportingCurrency || "MYR")
  const regularSaving = data.regularSaving || {
    currency: data.reportingCurrency || "MYR",
    value: 0
  }
  const regularWithdrawal = data.regularWithdrawal || {
    currency: data.reportingCurrency || "MYR",
    value: 0
  }
  const total = listingData.reduce((sum, current) => sum + current.value, 0)
  const totalUnitsHold = listingData.reduce(
    (sum, current) => sum + Number(current.numberOfUnitsPurchased),
    0
  )
  const totalPrice = listingData.reduce(
    (sum, current) => sum + current.numberOfUnitsPurchased * current.value,
    0
  )

  listingData.forEach((item) => {
    item.allocation =
      ((item.numberOfUnitsPurchased * item.value) / (totalPrice || 1)) * 100
  })
  return (
    <div className={``}>
      <div className="subtitle-holder">
        <div className="title">Portfolio Details</div>
      </div>
      {savingAllocationError && (
        <span className="allocation-error">
          Saving allocation should match the regular saving value
        </span>
      )}
      {withdrawalAllocationError && (
        <span className="allocation-error">
          Withdrawal allocation should match the regular withdrawal value
        </span>
      )}
      <table
        className="table-investment"
        width="100%"
        cellSpacing="0"
        cellPadding="0"
        border="0"
      >
        <tbody>
          <ListingHeader
            {...{
              data,
              contextMenuItem,
              allocationType: state.allocationType,
              onAllocationTypeChange: (e) => {
                const { value: allocationType } = e.target
                mutateOnAllocationTypeChange(allocationType)
                setState((prevState) => ({
                  ...prevState,
                  allocationType
                }))
              },
              onSelectContextMenu
            }}
          />
          <ListingContent
            {...{
              allocationType: state.allocationType,
              data,
              listingData,
              onItemSelect,
              onItemDelete,
              regularSavingCurrency: regularSaving.currency,
              regularWithdrawalCurrency: regularWithdrawal.currency,
              savingAllocationError,
              withdrawalAllocationError
            }}
          />
          <ListingSummary
            {...{
              total,
              listingData,
              regularSaving,
              regularWithdrawal,
              allocationType: state.allocationType,
              regularSavingCurrency: regularSaving.currency,
              regularWithdrawalCurrency: regularWithdrawal.currency,
              savingAllocationError,
              withdrawalAllocationError,
              totalCurrency: data.reportingCurrency,
              totalUnitsHold,
              totalPrice
            }}
          />
        </tbody>
      </table>
    </div>
  )
}
