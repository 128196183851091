import React from 'react';
import intl from 'react-intl-universal';

function PensionInvestmentPlatform() {
    return (
        <table
            className="table-investment"
            width="100%"
            cellSpacing="0"
            cellPadding="0"
            border="0"
        >
            <tbody>
                <tr>
                    <th>
                        {intl.get('netWorth.pensionInvestmentPlatform.name')}
                    </th>
                    <th>
                        {intl.get(
                            'netWorth.pensionInvestmentPlatform.institutionName'
                        )}
                    </th>
                    <th>
                        {intl.get(
                            'netWorth.pensionInvestmentPlatform.accountNumber'
                        )}
                    </th>
                    <th>
                        {intl.get(
                            'netWorth.pensionInvestmentPlatform.accountStartDate'
                        )}
                    </th>
                    <th>
                        {intl.get(
                            'netWorth.pensionInvestmentPlatform.accountBalance'
                        )}
                    </th>
                    <th>
                        {intl.get(
                            'netWorth.pensionInvestmentPlatform.valuationDate'
                        )}
                    </th>
                    <th>&nbsp;</th>
                    <th>
                        <button type="button" className="btn btn-plus">
                            &nbsp;
                        </button>
                    </th>
                </tr>
                <tr>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>
                        <button type="button" className="btn-remove">
                            &nbsp;
                        </button>
                    </td>
                    <td>&nbsp;</td>
                </tr>
            </tbody>
        </table>
    );
}

export default PensionInvestmentPlatform;
