/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Description : Helper to convert currency value
*/
import { CountryList } from "../constants/Lists"
import intl from "react-intl-universal"

import Get from "./NullHelper"
import { DECIMAL_SCALE, PERCENT_SCALE } from "../constants/NumberFormatConstants"
const currencyConversion = (fromCurrency, toCurrency, value) => {
  if (fromCurrency === toCurrency) {
    return value
  }

  const cur = localStorage.getItem("currencyRates")
  if (cur) {
    const z = JSON.parse(cur)
    const x = z.find((a) => a.from === fromCurrency)
    if (x && x.date) {
      const y = x.exchangeRates.find((a) => a.to === toCurrency)
      let rate = y && y.rate ? y.rate : 1
      return value * rate
    }
  }
}

export const getNewCurrencyValue = (currencyObject, targetCurrency) => {
  if (currencyObject && targetCurrency) {
    return currencyConversion(
      currencyObject.currency,
      targetCurrency,
      currencyObject.value
    )
  }

  return currencyObject && currencyObject.value ? currencyObject.value : 0
}

const decodeHtml = (html) => {
  const txt = document.createElement("textarea")
  txt.innerHTML = html
  return txt.value
}
const translatedCountryMap = new Map(
  CountryList.map((item) => [
    item.abbreviation,
    {
      ...item,
      name: intl.get(item.name) || item.name
    }
  ])
)
export const getSymbolFromCode = (code) => {
  const countryObject = translatedCountryMap.get(code)
  return countryObject ? decodeHtml(countryObject.symbol) : ""
}

const roundNoDecimal = (num) => Math.round(num)

const roundToDefault = (num, dec) =>
  (+`${Math.round(`${num}e+${dec}`)}e-${dec}`).toFixed(dec)

export function numberWithCommas(x) {
  if (!x) {
    return "0"
  }
  const rounded = roundToDefault(x, DECIMAL_SCALE) // Returns a string with exact decimals
  const [integerPart, decimalPart] = rounded.split(".") // Split into integer and decimal parts
  if (decimalPart) {
    return `${Number(integerPart).toLocaleString()}.${decimalPart}`
  } else {
    return `${Number(integerPart).toLocaleString()}`
  }
}
export function getNumberOf(val) {
  if (val) {
    return Number(val.replace(/[^0-9.-]+/g, ""))
  } else {
    return 0
  }
}

export function numberNoDecimalPoints(x) {
  if (!x) {
    return 0
  }
  return roundNoDecimal(x).toLocaleString()
  // return Math.round(x).toLocaleString();
}

export function rateWithCommas(x) {
  if (!x) {
    return 0
  }
  return roundToDefault(x, PERCENT_SCALE).toLocaleString()
  // return Math.round(x).toLocaleString();
}

export function numberFormat(value, currency) {
  return ` ${getSymbolFromCode(currency)}${numberWithCommas(value)}`
}

export const getCurrency = (countryCode) => {
  const country = CountryList.find((a) => a.code === countryCode)
  const cry = Get(country, "abbreviation") || "USD"
  if (
    cry === "AUD" ||
    cry === "MYR" ||
    cry === "AUD" ||
    cry === "EUR" ||
    cry === "GBP" ||
    cry === "HKD" ||
    cry === "SGD" ||
    cry === "NZD"
  ) {
    return cry
  }
  return "USD"
}
