import React, { useState, useEffect, useContext } from "react"
import Alert from "react-s-alert"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import AuthApi from "../../api/AuthApi";

function AppVersionDialog({appVersion, previousVersions}) {
  const types = ['MAJOR', 'MINOR', 'PATCH'];
  const [currentVersion, setCurrentVersion] = useState(appVersion);

  function onVersionChanged(ver){
    if(ver){
      AuthApi.getAppVersion(ver).then(({data})=>{
        if(data){
          setCurrentVersion(data.appVersion);
        }
      });
    }
   
  }
 
  function getAppVersionList(type){
    let result = [];
    if(currentVersion && type){
        let list = [];
        if(type=="MAJOR"){
          list = currentVersion.majorNotes;
        }else if(type == "MINOR"){
          list = currentVersion.minorNotes;
        }else{
          list = currentVersion.bugFixNotes;
        }

        if(list && list.length>0){
          list.forEach(item => {
            result.push(<li>{item}</li>)
          });
        }else{
          result.push(<li>{"No changes"}</li>)
        }


    }
    return result;
  }

  function createTabTitle(type){
    return (
      <Tab>{type}</Tab>
    )
  }

  function createTabPanel(type){
    return (
      <TabPanel>
        <ul>
            {getAppVersionList(type)} 
        </ul>
      </TabPanel>
    )
  }

 
  function getVersionTooltipContent(){
    return (
     
        <Tabs >
          <TabList>
            {types.map((t,index)=>(
              createTabTitle(t)
            ))}
          </TabList>
          {types.map((t,index)=>(
            createTabPanel(t)
          ))}
        </Tabs>
      
     
    )
  }

  return (
    <div className="simple-dialog">
      <div className="portal-form-row">
        <div className="col-md-8">
           <h2>v{currentVersion.version}</h2>
           {currentVersion.suffix}
        </div>
        <div className="col-md-4">
          <select className="select-box" value={currentVersion?currentVersion.version:""} onChange={(e)=>onVersionChanged(e.target.value)}>
              {previousVersions &&  (
                previousVersions.map((item, index)=>(
                  <option key={item.code} value={item.code}>{item.code} updated on {item.name}</option>
                ))
              )}
          </select>
        </div>
        
      </div>
      
      {getVersionTooltipContent()}
    </div>
    
  )
}
export default AppVersionDialog
