/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Library : UserApi
    Description : methods related with user part
*/

import api from "../lib/api";
import axios from 'axios';
import { getStrLocalTimeInfo } from "../helpers/DateHelper";


// change base route for different entity/domain here
const BASE_ROUTE = "/user/";

class UserApi {
    static getUsers(filter) {
        return api.post(`${BASE_ROUTE}`, filter);
    }

    static getUsersByPackageTypeId(packageTypeId) {
        return api.get(`${BASE_ROUTE}package/${packageTypeId}`);
    }

    static assignPackageUsers(model) {
        return api.post(`${BASE_ROUTE}assign-package`, model);
    }


    static isDemoUser() {
        return api.get(`${BASE_ROUTE}demo`);
    }

    static saveUser(item) {
        return api.post(`${BASE_ROUTE}save`, item);
    }

    static deleteUser(item) {
        return api.post(`${BASE_ROUTE}delete`, item);
    }
    // register
    static registerUser(registerModel) {
        return api.post(`${BASE_ROUTE}register/user`, registerModel);
    }

    static registerAdviser(registerModel) {
        return api.post(`${BASE_ROUTE}register/adviser`, registerModel);
    }

    static add(entityId, user) {
        return api.post(`${BASE_ROUTE}${entityId}`, user);
    }
    // static registerSuperAdmin(registerModel) {
    // 	return api.post(BASE_ROUTE + "register/superAdmin", registerModel);
    // }

    // verify
    static verifyEmail(token) {
        return api.post(`${BASE_ROUTE}verify/user`, token);
    }

    // activate user
    static activateUser(token) {
        return api.post(`${BASE_ROUTE}activate/user`, token);
    }

    static activateAdviser(token) {
        return api.post(`${BASE_ROUTE}activate/adviser`, token);
    }

    static getProfile(userId) {
        return api.get(`${BASE_ROUTE}${userId}/profile`);
    }

    static getEntiityProfile(id, type) {
        return api.get(`/dashboard-item-profile/${id}?entityType=${type}`);
    }

    static editEntiityProfile(model) {
        return api.post(`/dashboard-item-profile`, model, {
            headers: {
                "Content-Type": null
            }
        });
    }

    static editProfile(userId, model) {
        return api.post(`${BASE_ROUTE}${userId}/profile`, model, {
            headers: {
                "Content-Type": null
            }
        });
    }

    static sendLogout(logoutType) {
        const LOGIN_URL = process.env.REACT_APP_BASE_LOGIN_URL + process.env.REACT_APP_LOGIN;
        let user = JSON.parse(localStorage.getItem("user"));
        let userActivities = JSON.parse(localStorage.getItem("activities"));
        localStorage.setItem("base-route", LOGIN_URL);
        if(user && user.loginId){
            return api.post(`/auth/logout`, {
                loginId: user.loginId,
                logoutType: logoutType, 
                activities: userActivities
            });
        }else{
            //dummy promise, no need send logout if no loginId found
            return new Promise( (resolve, reject) => {});
        }
       
    }

    static sendLogoutByLoginId(loginId, logoutType) {
        let userActivities = JSON.parse(localStorage.getItem("activities"));
        const customAxiosInstance = axios.create();
        const LOGIN_URL = process.env.REACT_APP_BASE_LOGIN_URL + process.env.REACT_APP_LOGIN;
        return customAxiosInstance.post(`${LOGIN_URL}/auth/logout`, {
            loginId: loginId,
            logoutType: logoutType,
            activities: userActivities
        });
       
    }

    static track(activity){
        if(activity){
            let strUserActivities = localStorage.getItem('activities');
            let arrUserActivities = strUserActivities?JSON.parse(strUserActivities):[];
            arrUserActivities.push(getStrLocalTimeInfo() + ' ' + activity);
            localStorage.setItem('activities', JSON.stringify(arrUserActivities));
            
        }
    }

    static getLogonHistory(emailAddress) {
        const secured = JSON.parse(localStorage.getItem('user'));
        let token = secured && secured.token?secured.token:'';
        let headers = { 'Authorization': token }; 
        const customAxiosInstance = axios.create();
        const LOGIN_URL = process.env.REACT_APP_BASE_LOGIN_URL + process.env.REACT_APP_LOGIN;
        return customAxiosInstance.get(`${LOGIN_URL}/user/logon-history?emailAddress=${emailAddress}`, {headers});
       
    }

    
    static getCustomEntityModules(userId) {
        return api.get(`${BASE_ROUTE}custom-entity-modules?userId=${userId}`);
        
    }

    static saveCustomEntityModules(model) {
        return api.post(`${BASE_ROUTE}custom-entity-modules`, model);
        
    }
}

export default UserApi;
