import React from 'react';
import { createUrlFromPath } from '../../helpers/ImageHelper';

const NameBox = ({ user }) => (
    <div className="name-box">
        <div className="img">
            <img alt="" src={`${createUrlFromPath(user.imagePath)}`} />
        </div>
        <div className="content">
            <p>{user.name}</p>
            {user.value}
            <br />
            {user.worth}
        </div>
    </div>
);

export default NameBox;
