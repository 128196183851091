import React from 'react';
import intl from 'react-intl-universal';
import { Link } from "react-router-dom";

const MalabarClientRightMenu = ({}) => (
    
    <div className="action-panel">
        <div className="action-panel-top">
            <span className="action-button">
                <Link to={"/fxRates"}>
                    <div className="large">{"FXR"}</div>
                    <div className="small">{intl.get("rightmenu.currencyExchange")}</div>
                </Link>
            </span>
        </div>
        
    </div> 
    
    
    
);

export default MalabarClientRightMenu;
