import React from 'react';
import './MalabarUploadPhoto.css';

const MalabarUploadPhoto = ({
    input,
    inputOnChange,
    meta: { touched, error },
}) => {
    const inputProps = {

        ...input,

        onChange: e => {
            input.onChange(e.target.files[0]);
            inputOnChange && inputOnChange(e);
        }

    };

    return (
        <React.Fragment>
            <div
                className={
                    touched
                        ? error
                            ? `itemvalidate bad`
                            : ''
                        : ''
                }
            >
                <input type="file" {...inputProps} value={undefined} className="upload"></input>
                {/* <input style={{ width: "100%" }}   /> */}
                {
                    touched &&
                    (error && (
                        <div className="alert full-width">{error}</div>
                    ))
                }
            </div>
        </React.Fragment >
    )
}


export default MalabarUploadPhoto;