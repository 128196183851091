import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import intl from 'react-intl-universal';
import { getPrefixedColors } from '../../../helpers/ColorHelper';
import NetWorthForm, {
    NetWorthPath,
    NetWorthFormPropsComposer,
} from '../NetWorthForm';
import NetWorthFormButtons from '../NetWorthFormButtons';
import { netWorthFormActions } from '../../../actions/KYC/NetWorth/NetWorthFormActions';
import { modalWindowActions } from '../../../actions';
import { AppContext } from '../../../context/AppContext';
import { modalWindow } from '../../../constants/ModalWindowLists';
import SuplementaryRetirementSchemeLeftSide from './SuplementaryRetirementSchemeLeftSide';
import SuplementaryRetirementSchemeRightSide from './SuplementaryRetirementSchemeRightSide';
import SuplementaryRetirementSchemeChart from './SuplementaryRetirementSchemeChart';
import SRSFundAllocationDetails from './SRSFundAllocationDetails';

class SuplementaryRetirementSchemeInformation extends NetWorthForm {
    constructor(props) {
        super(
            props,
            intl.get('suplementaryRetirementSchemeInformation.title'),
            modalWindow.SUPLEMENTARY_RETIREMENT_SCHEME_INFORMATION,
            NetWorthPath.suplementaryRetirementSchemeInformation, // TODO: change when the api is ready
            'SuplementaryRetirementSchemeInformation'
        );
    }

    state = {
        dataPie: {
            labels: ['Platinum', 'Gold', 'Silver', 'Bronze'],
            datasets: [
                {
                    data: [70, 23, 31, 35],
                },
            ],
        },
        isError: false,
    };

    generateChartData = () => {
        const unitTrusts = [
            { unitTrustName: 'Platinum', valuePercentage: 70 },
            { unitTrustName: 'Gold', valuePercentage: 23 },
            { unitTrustName: 'Silver', valuePercentage: 11 },
            { unitTrustName: 'Bronze', valuePercentage: 35 },
        ];
        const colorsArray = getPrefixedColors(unitTrusts.length);
        return {
            labels: unitTrusts.map(item => item.unitTrustName),
            datasets: [
                {
                    data: unitTrusts.map(item => item.valuePercentage),
                    backgroundColor: colorsArray,
                    hoverBackgroundColor: colorsArray,
                },
            ],
        };
    };

    render() {
        const {
            contentWrapper,
            readOnlyAccessComponent,
            handleSave,
            generateChartData,
        } = this;

        const { isError } = this.state;
        return contentWrapper(
            <Form
                onSubmit={handleSave}
                mutators={{
                    pushToArray: arrayMutators.push,
                    removeFromArray: arrayMutators.remove,
                    updateArray: arrayMutators.update,
                }}
                render={({ handleSubmit, reset, values }) => (
                    <form id="employeeProvidenceFund" onSubmit={handleSubmit}>
                        <div className="portal-form-popup">
                            {/* SRS Left side */}
                            <div className="portal-form-twothird portal-form-twothird-srs">
                                <SuplementaryRetirementSchemeLeftSide />
                                <div className="portal-form-half">
                                    <SuplementaryRetirementSchemeRightSide />
                                </div>
                            </div>

                            <div className="portal-form-onethird">
                                <SuplementaryRetirementSchemeChart
                                    chartData={generateChartData(values)}
                                />
                            </div>
                            <SRSFundAllocationDetails />
                        </div>
                        <NetWorthFormButtons
                            reset={reset}
                            readOnlyAccess={
                                readOnlyAccessComponent() || isError
                            }
                        />
                    </form>
                )}
            />
        );
    }
}

function mapStateToProps(state) {
    const props = NetWorthFormPropsComposer(
        state,
        modalWindow.SUPLEMENTARY_RETIREMENT_SCHEME_INFORMATION,
        NetWorthPath.suplementaryRetirementSchemeInformation
    );
    return {
        ...props,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        Object.assign({}, netWorthFormActions, modalWindowActions),
        dispatch
    );
}

SuplementaryRetirementSchemeInformation.contextType = AppContext;

SuplementaryRetirementSchemeInformation = connect(
    mapStateToProps,
    mapDispatchToProps
)(SuplementaryRetirementSchemeInformation);

export default SuplementaryRetirementSchemeInformation;
