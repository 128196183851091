/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Description : Helper to get what menu member for certain entity type
*/

import { EntityTypeEnum } from "../constants/Enums"

export const menuItems = [
  {
    id: EntityTypeEnum.EntityTypeEnum_BUILT_IN_MALABAR_AI_GLOBAL,
    entityType: EntityTypeEnum.EntityTypeEnum_BUILT_IN_MALABAR_AI_SUBSIDIARY,
    path: "/dashboard/subsidiaries",
    pluralName: "subsidiaries"
  },
  {
    id: EntityTypeEnum.EntityTypeEnum_BUILT_IN_MALABAR_AI_SUBSIDIARY,
    entityType: EntityTypeEnum.EntityTypeEnum_BUILT_IN_DEALERSHIP,
    path: "/dashboard/dealerships",
    pluralName: "dealerships"
  },
  {
    id: EntityTypeEnum.EntityTypeEnum_BUILT_IN_DEALERSHIP,
    entityType: EntityTypeEnum.EntityTypeEnum_BUILT_IN_ADVISER_GROUP,
    path: "/dashboard/advisergroups",
    pluralName: "advisergroups"
  },
  {
    id: EntityTypeEnum.EntityTypeEnum_BUILT_IN_ADVISER_GROUP,
    entityType: EntityTypeEnum.EntityTypeEnum_BUILT_IN_SUB_BRANCH,
    path: "/dashboard/subbranches",
    pluralName: "subbranches"
  },
  {
    id: EntityTypeEnum.EntityTypeEnum_BUILT_IN_SUB_BRANCH,
    entityType: EntityTypeEnum.EntityTypeEnum_BUILT_IN_ADVISER,
    path: "/dashboard/advisers",
    pluralName: "advisers"
  },
  {
    id: EntityTypeEnum.EntityTypeEnum_BUILT_IN_ADVISER,
    entityType: EntityTypeEnum.EntityTypeEnum_CLIENT,
    path: "/dashboard/clients",
    pluralName: "clients"
  }
]

export const getRestMenuItems = (entityTypeEnum) => {
  const results = []
  let found = false

  menuItems.forEach((element) => {
    if (element.id === entityTypeEnum) found = true
    if (element.id !== entityTypeEnum && found) {
      results.push(element)
    }
  })

  return results
}

export const normalizeEntityType = (entityType) => {
  let result = ""
  if (entityType && entityType.length > 0) {
    result = entityType
    if (result.indexOf("BUILT_IN_") > -1) {
      result = result.replace("BUILT_IN_", "")
    }
    if (result.indexOf("MALABAR_AI_") > -1) {
      result = result.replace("MALABAR_AI_", "")
    }
  }

  return result
}
