import { currentStandingAction } from "../../constants/ActionConstants"
import { CurrentStandingApi } from "../../api"
import { history } from "../../helpers/history"

export const currentStandingActions = {
  getCurrentStanding,
  getLifeline,
  getCurrentStandingForScenario
}

export function getCurrentStandingSuccess(response) {
  return {
    type: currentStandingAction.GET_CURRENT_STANDING_SUCCESS,
    data: response.data
  }
}

export function getLifelineSuccess(response) {
  return {
    type: currentStandingAction.GET_LIFELINE_SUCCESS,
    data: response.data
  }
}

// action functions
export function getCurrentStanding(id, currency) {
  return async (dispatch) => {
    // api request here
    getMainCurrentStanding(id, dispatch, currency)
  }
}
export function getCurrentStandingForScenario(id, currency, scenarioId) {
  return async (dispatch) => {
    // api request here
    getMainCurrentStandingForScenario(id, dispatch, currency, scenarioId)
  }
}

export function getLifeline(
  id,
  retirementAge = 0,
  currency,
  retirementAge1 = 0
) {
  return async (dispatch) => {
    // api request here
    CurrentStandingApi.getLifeline(
      id,
      retirementAge,
      // classification,
      currency,
      retirementAge1
    ).then(
      (response) => {
        dispatch(getLifelineSuccess(response))
      },
      (error) => {
        if (error.response.data.errorCode === "1001") {
          history.push(`/goals/${id}`)
        }
      }
    )
  }
}

function getMainCurrentStanding(id, dispatch, currency) {
  CurrentStandingApi.getLifeline(id, 0, currency).then(
    (response) => {
      dispatch(getCurrentStandingSuccess(response))
    },
    (error) => {
      if (error.response.data.errorCode === "1001") {
        history.push(`/goals/${id}`)
      }
    }
  )
}

function getMainCurrentStandingForScenario(id, dispatch, currency, scenarioId) {
  CurrentStandingApi.getLifelineForScenario(id, currency, scenarioId).then(
    (response) => {
      dispatch(getLifelineSuccess(response))
    },
    (error) => {
      if (error.response.data.errorCode === "1001") {
        history.push(`/goals/${id}`)
      }
    }
  )
}
