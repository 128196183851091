import React from 'react';
import { renderDateFieldCustom, renderCurrencyCustom, renderTextBoxCustom } from './../../../helpers/FormRender';
import { Field } from 'react-final-form';
import intl from 'react-intl-universal';
import { composeValidators } from '../../../helpers';
import { isValidDate } from '../../../helpers/Validator';

function RA(props) {
    return (
        <div className="rs-col2">
            <div className="subtitle2">
                {intl.get('centralProvidenceFundInformation.RADetails')}
            </div>

            <div className="portal-form-row">
                <label>
                    {intl.get('centralProvidenceFundInformation.retirementAccount')}
                </label>
                <div className="row-holder">
                    <Field
                        name="raDetails.retirementAccountNumber"
                        component={renderTextBoxCustom}
                        isForGrid={true}
                    />
                </div>
            </div>

            <div className="portal-form-row">
                <label>
                    {intl.get('centralProvidenceFundInformation.currentValue')}
                </label>
                <div className="row-holder">
                    <Field
                        name="raDetails.currentValue"
                        component={renderCurrencyCustom}
                        isForGrid={true}
                    />
                </div>
            </div>

            <div className="portal-form-row dateformat">
                <label>
                    {intl.get('centralProvidenceFundInformation.valuationDate')}
                </label>
                <div className="row-holder">
                    <Field
                        name="raDetails.valuationDate"
                        component={renderDateFieldCustom}
                        isForGrid={true}
                        validate={composeValidators(isValidDate)}
                    />
                </div>
            </div>
        </div>
    );
}

export default RA;