import { useEffect, RefObject } from "react"

export const useClickOutside = (ref, { onClickOutside }) => {
  useEffect(() => {
    /**
     * Invoke Function onClick outside of element
     */
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside()
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [ref, onClickOutside])
}



