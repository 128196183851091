import React, { useState } from "react";
import { Form } from "react-final-form";
import Alert from "react-s-alert";
import ClientApi from "../../api/ClientApi";
import { reloadWindow } from "../../helpers/SessionHelper";
import intl from "react-intl-universal";

const ClientPermanentDelete = ({ userId, clearModal, type }) => {
    const submit = () => ClientApi.clientDelete(`${userId}`);
    const [error, setError] = useState("");
    const [attachChildren, setAttachChildren] = useState([]);
    return (
        <Form
            onSubmit={model => submit(model)}
            render={({ handleSubmit, reset }) => (
                <div className="portal-form-popup">
                    <form
                        id="emailForm"
                        onSubmit={event => {
                            const promise = handleSubmit(event);
                            if (promise) {
                                promise
                                    .then(response => {
                                        if (
                                            response &&
                                            response.status === 200
                                        ) {
                                            Alert.success(
                                                `${
                                                    type !== "CLIENT"
                                                        ? "The Organization has been removed successfully."
                                                        : "The Client has been archived successfully."
                                                }`
                                            );
                                            reset();
                                            clearModal();
                                            reloadWindow("UPLOAD_PHOTO");
                                        }
                                    })
                                    .catch(err => {
                                        setError(
                                            err.response.data.errorMessage
                                        );

                                        {
                                            err.response.data &&
                                                err.response.data
                                                    .attachedChildren &&
                                                setAttachChildren(
                                                    err.response.data
                                                        .attachedChildren
                                                );
                                        }
                                    });
                            }
                        }}
                    >
                        <div className="row m-2">
                            <div className="col">
                                {type === "CLIENT" && (
                                    <h3>
                                        Are you sure want to archive this
                                        client?
                                    </h3>
                                )}
                                {type !== "CLIENT" && (
                                    <h3>
                                        This action cannot be undone! Are you
                                        sure?
                                    </h3>
                                )}
                            </div>
                        </div>
                        <div className="row m-2">
                            <div className="col">
                                {error && (
                                    <>
                                        <p className="text-bold ico-alertquestion">
                                            {error}
                                        </p>
                                        <ul>
                                            {attachChildren &&
                                                attachChildren.map(
                                                    (item, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <p
                                                                    style={{
                                                                        color:
                                                                            "#ff0000"
                                                                    }}
                                                                >
                                                                    {item}
                                                                </p>
                                                            </li>
                                                        );
                                                    }
                                                )}
                                        </ul>
                                    </>
                                )}
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col d-flex justify-content-end ">
                                <button
                                    className="portal-btn portal-btn-submit "
                                    type="submit"
                                >
                                    {`${
                                        type !== "CLIENT"
                                            ? intl.get("button.delete")
                                            : "Archive"
                                    }`}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            )}
        />
    );
};

export default ClientPermanentDelete;
