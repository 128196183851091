import React, { useContext, useEffect,  useState } from "react"
import { Form, Field, FormSpy } from "react-final-form"
import arrayMutators from "final-form-arrays"
import intl from "react-intl-universal"

import Alert from "react-s-alert"
import WithdrawalPlans from "../../ILASInvestment/WithdrawalPlans"
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import NetWorthFormButtons from "../../NetWorthFormButtons"
import ClassificationSelection from "../../../../components/Form/ClassificationSelection"
import RenderSimpleFields, {
  FieldType
} from "../../../../components/Form/RenderSimpleFields"
import {
  renderPercentageCustom,
  renderCurrencyCustom,
  required,
  composeValidators,
  getSymbolFromCode,
  history,
 
} from "../../../../helpers"

import { AppContext } from "../../../../context/AppContext"
import RetirementApi from "../../../../api/RetirementApi"

import FeeTable from "../../components/FeeTable"
import { fileFieldRender } from "../../../../helpers"


const EmployeeProvidenceFundDetailsForm = ({
    clientId,
    values,
    mutators,
    currency = "MYR",
    currencySymbol ="RM",
    optionsData
   
  }) => {
    const rowClassName = "portal-form-row";
    const cashflowUrl = `/cashflow/${clientId}/EmployementIncomeAndBenefit?currency=${currency}`
    const [salaries, setSalaries] = useState("")
    const [selectedSalary, setSelectedSalary] = useState(salaries && values.incomeSourceId?getSalaryById(values.incomeSourceId):undefined);
    const [ruleParams, setRuleParams] = useState([]);
   
    const {
      modal: { setModal, clearModal }
    } = useContext(AppContext)
   
    useEffect(() => {
      //get salary id
      RetirementApi.getSalary(clientId).then(({data}) => {
        if(data){
          setSalaries(data)
        }
        
      })
      RetirementApi.getEpfRules().then(({data})=>{
        if(data){
           setRuleParams(data.paramList);
           
        }
      })
      
    }, [])
  
    function getParamRule(searchKey){
       let res;
        if(ruleParams){
          for (let index = 0; index < ruleParams.length; index++) {
            const r = ruleParams[index];
            if(r.key == searchKey){
                res = r.value;
                break;
            }
            
          }
        }
        return res;
    }
  
    function getSalaryOptions(){
       let options = [];
       if(salaries){
        options.push(
            <option key={"empty"} value={""}></option>
        ) 
        for (let index = 0; index < salaries.length; index++) {
           let s = salaries[index];
           options.push(
              <option key={s.id} value={s.id}>{s.employedWith}</option>
           )
          
         }
       }
       return options;
    }
  
  
    function getSalaryById(salaryId){
      let foundSalary;
      if(salaryId && salaries){
        for (let index = 0; salaries.length; index++) {
          let s = salaries[index];
          if(s.id == salaryId){
            foundSalary = s;
            break;
          }
          
        }
        
      }
      return foundSalary;
    }
    
    const onEmploymentIncomeSourceChanged = (salaryId) => {
       
        mutators.setFormAttribute("incomeSourceId", salaryId);
        mutators.setFormAttribute("retirementAge", 55);
        let foundSalary = getSalaryById(salaryId);
        setSelectedSalary(foundSalary);
        if(foundSalary){
          let params = {
            clientId: clientId,
            salaryId: salaryId,
            monthlyBased: true,
            gross: values.useGrossSalary
          } 
          RetirementApi.epfCalculate(params).then(({data})=>{
            if(data){
              mutators.setFormAttribute("joinedDate", data.joinedDate);
              mutators.setFormAttribute("monthlyWages", data.monthlyWages);
              mutators.setFormAttribute("yearlyWages", data.yearlyWages);
              mutators.setFormAttribute("clientAge", data.age);
              mutators.setFormAttribute("citizen", data.citizen);
    
              mutators.setFormAttribute("pensionContributionDetails.employerContributionComponent.mandatoryContributionPercent",data.employerPercentage);
              mutators.setFormAttribute("pensionContributionDetails.employerContributionComponent.mandatoryContributionValue.value",(data.employerContribution));
    
              mutators.setFormAttribute("pensionContributionDetails.employerContributionComponent.voluntaryContributionPercent", 0);
              mutators.setFormAttribute("pensionContributionDetails.employerContributionComponent.voluntaryContributionValue.value", 0);
    
              mutators.setFormAttribute("pensionContributionDetails.employeeContributionComponent.mandatoryContributionPercent", data.employeePercentage);
              mutators.setFormAttribute("pensionContributionDetails.employeeContributionComponent.mandatoryContributionValue.value",data.employeeContribution);
    
              mutators.setFormAttribute("pensionContributionDetails.employeeContributionComponent.voluntaryContributionPercent",0);
              mutators.setFormAttribute("pensionContributionDetails.employeeContributionComponent.voluntaryContributionValue.value",0);
    
    
            }   
    
      
          })
        }else{
          resetEmploymentInfo();
        }
        
  
        
        
    } 
  
    
  
  
    const workPlaceAreas = [
      {
        name: "B001",
        value: "Wilayah Persekutuan - Dewan Bandaraya Kuala Lumpur"
      },
      { name: "B002", value: "Wilayah Persekutuan - Perbadanan Labuan" },
      { name: "B003", value: "Wilayah Persekutuan - Perbadanan Putrajaya" },
      { name: "B007", value: "Johor - Majlis Bandaraya Iskandar Puteri" },
      { name: "B008", value: "Johor - Majlis Bandaraya Johor Bahru" },
      { name: "B022", value: "Johor - Majlis Perbandaran Batu Pahat" },
      { name: "B032", value: "Johor - Majlis Perbandaran Kluang" },
      { name: "B038", value: "Johor - Majlis Perbandaran Kulai" },
      { name: "B042", value: "Johor - Majlis Perbandaran Muar" },
      { name: "B044", value: "Johor - Majlis Perbandaran Pasir Gudang" },
      { name: "B047", value: "Johor - Majlis Perbandaran Segamat" },
      { name: "B005", value: "Kedah - Majlis Bandaraya Alor Setar" },
      { name: "B039", value: "Kedah - Majlis Perbandaran Kulim" },
      { name: "B040", value: "Kedah - Majlis Perbandaran Langkawi" },
      { name: "B052", value: "Kedah - Majlis Perbandaran Sungai Petani" },
      { name: "B033", value: "Kelantan - Majlis Perbandaran Kota Bharu" },
      { name: "B013", value: "Melaka - Majlis Bandaraya Melaka Bersejarah" },
      { name: "B020", value: "Melaka - Majlis Perbandaran Alor Gajah" },
      { name: "B025", value: "Melaka - Majlis Perbandaran Hang Tuah Jaya" },
      { name: "B026", value: "Melaka - Majlis Perbandaran Jasin" },
      { name: "B018", value: "Negeri Sembilan - Majlis Bandaraya Seremban" },
      { name: "B027", value: "Negeri Sembilan - Majlis Perbandaran Jempol" },
      {
        name: "B045",
        value: "Negeri Sembilan - Majlis Perbandaran Port Dickson"
      },
      { name: "B023", value: "Pahang - Majlis Perbandaran Bentong" },
      { name: "B036", value: "Pahang - Majlis Perbandaran Kuantan" },
      { name: "B056", value: "Pahang - Majlis Perbandaran Temerloh" },
      { name: "B006", value: "Perak - Majlis Bandaraya Ipoh" },
      { name: "B035", value: "Perak - Majlis Perbandaran Kuala Kangsar" },
      { name: "B041", value: "Perak - Majlis Perbandaran Manjung" },
      { name: "B053", value: "Perak - Majlis Perbandaran Taiping" },
      { name: "B055", value: "Perak - Majlis Perbandaran Teluk Intan" },
      { name: "B029", value: "Perlis - Majlis Perbandaran Kangar" },
      { name: "B016", value: "Pulau Pinang - Majlis Bandaraya Pulau Pinang" },
      {
        name: "B017",
        value: "Pulau Pinang - Majlis Bandaraya Seberang Perai"
      },
      {
        name: "B037",
        value: "Pulau Pinang - Majlis Perbandaran Kubang Pasu"
      },
      { name: "B009", value: "Sabah - Majlis Bandaraya Kota Kinabalu" },
      { name: "B046", value: "Sabah - Majlis Perbandaran Sandakan" },
      { name: "B054", value: "Sabah - Majlis Perbandaran Tawau" },
      { name: "B004", value: "Sarawak - Lembaga Kemajuan Bintulu" },
      { name: "B011", value: "Sarawak - Majlis Bandaraya Kuching Selatan" },
      { name: "B012", value: "Sarawak - Majlis Bandaraya Kuching Utara" },
      { name: "B014", value: "Sarawak - Majlis Bandaraya Miri" },
      { name: "B034", value: "Sarawak - Majlis Perbandaran Kota Samarahan" },
      { name: "B043", value: "Sarawak - Majlis Perbandaran Padawan" },
      { name: "B050", value: "Sarawak - Majlis Perbandaran Sibu" },
      { name: "B015", value: "Selangor - Majlis Bandaraya Petaling Jaya" },
      { name: "B019", value: "Selangor - Majlis Bandaraya Shah Alam" },
      { name: "B021", value: "Selangor - Majlis Perbandaran Ampang Jaya" },
      { name: "B028", value: "Selangor - Majlis Perbandaran Kajang" },
      { name: "B031", value: "Selangor - Majlis Perbandaran Klang" },
      { name: "B048", value: "Selangor - Majlis Perbandaran Selayang" },
      { name: "B049", value: "Selangor - Majlis Perbandaran Sepang" },
      { name: "B051", value: "Selangor - Majlis Perbandaran Subang Jaya" },
      {
        name: "B010",
        value: "Terengganu - Majlis Bandaraya Kuala Terengganu"
      },
      { name: "B024", value: "Terengganu - Majlis Perbandaran Dungun" },
      { name: "B030", value: "Terengganu - Majlis Perbandaran Kemaman" },
      {
        name: "B057",
        value: "Other than the City / Municipal Council listed"
      },
      { name: "B025", value: "Melaka - Majlis Perbandaran Hang Tuah Jaya" },
      { name: "B026", value: "Melaka - Majlis Perbandaran Jasin" },
      { name: "B018", value: "Negeri Sembilan - Majlis Bandaraya Seremban" },
      { name: "B027", value: "Negeri Sembilan - Majlis Perbandaran Jempol" },
      {
        name: "B045",
        value: "Negeri Sembilan - Majlis Perbandaran Port Dickson"
      },
      { name: "B023", value: "Pahang - Majlis Perbandaran Bentong" },
      { name: "B036", value: "Pahang - Majlis Perbandaran Kuantan" },
      { name: "B056", value: "Pahang - Majlis Perbandaran Temerloh" },
      { name: "B006", value: "Perak - Majlis Bandaraya Ipoh" },
      { name: "B035", value: "Perak - Majlis Perbandaran Kuala Kangsar" },
      { name: "B041", value: "Perak - Majlis Perbandaran Manjung" },
      { name: "B053", value: "Perak - Majlis Perbandaran Taiping" },
      { name: "B055", value: "Perak - Majlis Perbandaran Teluk Intan" },
      { name: "B029", value: "Perlis - Majlis Perbandaran Kangar" },
      { name: "B016", value: "Pulau Pinang - Majlis Bandaraya Pulau Pinang" },
      {
        name: "B017",
        value: "Pulau Pinang - Majlis Bandaraya Seberang Perai"
      },
      {
        name: "B037",
        value: "Pulau Pinang - Majlis Perbandaran Kubang Pasu"
      },
      { name: "B009", value: "Sabah - Majlis Bandaraya Kota Kinabalu" },
      { name: "B046", value: "Sabah - Majlis Perbandaran Sandakan" },
      { name: "B054", value: "Sabah - Majlis Perbandaran Tawau" },
      { name: "B004", value: "Sarawak - Lembaga Kemajuan Bintulu" },
      { name: "B011", value: "Sarawak - Majlis Bandaraya Kuching Selatan" },
      { name: "B012", value: "Sarawak - Majlis Bandaraya Kuching Utara" },
      { name: "B014", value: "Sarawak - Majlis Bandaraya Miri" },
      { name: "B034", value: "Sarawak - Majlis Perbandaran Kota Samarahan" },
      { name: "B043", value: "Sarawak - Majlis Perbandaran Padawan" },
      { name: "B050", value: "Sarawak - Majlis Perbandaran Sibu" },
      { name: "B015", value: "Selangor - Majlis Bandaraya Petaling Jaya" },
      { name: "B019", value: "Selangor - Majlis Bandaraya Shah Alam" },
      { name: "B021", value: "Selangor - Majlis Perbandaran Ampang Jaya" },
      { name: "B028", value: "Selangor - Majlis Perbandaran Kajang" },
      { name: "B031", value: "Selangor - Majlis Perbandaran Klang" },
      { name: "B048", value: "Selangor - Majlis Perbandaran Selayang" },
      { name: "B049", value: "Selangor - Majlis Perbandaran Sepang" },
      { name: "B051", value: "Selangor - Majlis Perbandaran Subang Jaya" },
      {
        name: "B010",
        value: "Terengganu - Majlis Bandaraya Kuala Terengganu"
      },
      { name: "B024", value: "Terengganu - Majlis Perbandaran Dungun" },
      { name: "B030", value: "Terengganu - Majlis Perbandaran Kemaman" },
      {
        name: "B057",
        value: "Other than the City / Municipal Council listed"
      }
    ]
  
  
    function getSalaryOptions(){
      let options = [];
      if(salaries){
       options.push(
           <option key={"empty"} value={""}></option>
       ) 
       for (let index = 0; index < salaries.length; index++) {
          let s = salaries[index];
          options.push(
             <option key={s.id} value={s.id}>{s.employedWith}</option>
          )
         
        }
      }
      return options;
    }
  
    function resetEmploymentInfo(){
      mutators.setFormAttribute("incomeSourceId", undefined);
      mutators.setFormAttribute("joinedDate", undefined);
      mutators.setFormAttribute("monthlyWages", 0);
      mutators.setFormAttribute("yearlyWages", 0);
      mutators.setFormAttribute("pensionContributionDetails.employerContributionComponent.mandatoryContributionPercent", 0);
      mutators.setFormAttribute("pensionContributionDetails.employerContributionComponent.mandatoryContributionValue.value", 0);
      mutators.setFormAttribute("pensionContributionDetails.employerContributionComponent.voluntaryContributionPercent", 0);
      mutators.setFormAttribute("pensionContributionDetails.employerContributionComponent.voluntaryContributionValue.value", 0);
      mutators.setFormAttribute("pensionContributionDetails.employeeContributionComponent.mandatoryContributionValue.value", 0);
      mutators.setFormAttribute("pensionContributionDetails.employeeContributionComponent.mandatoryContributionPercent", 0);
      mutators.setFormAttribute("pensionContributionDetails.employeeContributionComponent.voluntaryContributionValue.value", 0);
      mutators.setFormAttribute("pensionContributionDetails.employeeContributionComponent.voluntaryContributionPercent", 0);
    }
  
    function onSelfEmployedChecked(isChecked){
        if(isChecked){
           resetEmploymentInfo();
          
        }
    }
  
   
    return (
      <>
          <RenderSimpleFields
              fields={[
                {
                  name: "issuer",
                  label: intl.get("field.epfIssuer"),
                  type: FieldType.TEXT,
                  required: true
                },
                {
                  name: "accountNumber",
                  label: intl.get("field.epfAccountNumber"),
                  type: FieldType.TEXT,
                  required: true
                },
                {
                  name: "managedBy",
                  label: intl.get("field.managedBy"),
                  type: FieldType.ENUM_SELECTION,
                  enumData: optionsData.managedByOptions,
                  required: false
                },
                {
                  name: "workplaceArea",
                  label: intl.get("field.workPlace"),
                  type: FieldType.SELECTION,
                  required: false,
                  selectionData: workPlaceAreas,
                  valueProperty: "name",
                  labelProperty: "value"
                },
                {
                  name: "selfEmployed",
                  label: "Self-Employed",
                  type: FieldType.CHECKBOX,
                  onChange: (val)=>onSelfEmployedChecked(val)
                },
                {
                  name: "useGrossSalary",
                  label: "Use Gross Salary",
                  type: FieldType.CHECKBOX
                },
  
              ]}
            />
                
            {!values.selfEmployed && (
              <div className={rowClassName}>
                <label>
                    {intl.get("field.employmentIncomeSource")}
                </label>
                <div className="d-flex flex-column row-holder">
                  <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                    <select className="select-box" value={values.incomeSourceId} onChange={(e)=>onEmploymentIncomeSourceChanged(e.target.value, mutators, true)}>
                        {getSalaryOptions()}
                    </select>
                   
                    <button className="btn btn-plus" onClick={(e)=>{history.push(cashflowUrl)}}></button>
                   
                  </div>
                </div>
              </div> 
  
            )}  
           
            <RenderSimpleFields
              fields={[
                {
                  name: "joinedDate",
                  label: intl.get("field.joinedDate"),
                  type: FieldType.DATE,
                  readOnly: !values.selfEmployed
                },
                {
                  name: "clientCitizen",
                  label: "Client is Citizen",
                  type: FieldType.CHECKBOX,
                  readOnly: true
                },
                {
                  name: "clientAge",
                  label: "Client Age",
                  type: FieldType.NUMBER,
                  readOnly: true
                },
                
                {
                  name: "retirementAge",
                  label: "Retirement Age",
                  type: FieldType.NUMBER,
                },
                
              
                
              ]}
            />
            <Field
                component={fileFieldRender}
                customClassName="mt-1 d-block"
                labelIdle="Drag, Drop or Browse"
                label="Upload Documents"
                onupdatefiles={(fileItems) => {
                    mutators.setFormAttribute("proofDocument", fileItems[0])
                }}
                fileInformation={(values && values.proofDocument) || null}
            />
        
      </>
    )
  }
  
  const EpfContributionSection = ({
    clientId,
    values,
    mutators,
    currency = "MYR",
    currencySymbol ="RM",
   
  }) => {
    
  
    if(values){
       if(!values.contributionFrequency){
        mutators.setFormAttribute("contributionFrequency", "MONTHLY");
       }
         
    }
  
    
  
    const INACTIVE_TREATMENT_TYPES = [
      {
        name: "Move into cash",
        value: "MOVE_ASSET_TO_CASH"
      },
      {
        name: "Leave in Account",
        value: "LEAVE_ASSET_IN_ACCOUNT"
      }
    ]
  
    const INACTIVE_REASONS = [
      {
        name: "Unemployed 2 Months",
        value: "UNEMPLOYED_2_MONTHS"
      },
      {
        name: "Job Change",
        value: "JOB_CHANGE"
      },
      {
        name: "Other",
        value: "OTHER"
      }
    ]
  
    
    function getFrequencyTypeOptions(){
       let options = [];
       options.push({label: "Monthly",value: "MONTHLY"});
       if(values && values.selfEmployed){
         options.push({label: "Yearly",value: "YEARLY"});
         options.push({label: "Half Yearly",value: "HALF_YEARLY"});
         options.push({label: "Quarterly",value: "QUARTERLY"});
         options.push({label: "One-Off",value: "ONE_OFF"});
       }
       return options;
    }
  
    function onInactiveChecked(isInactive){
       if(!isInactive){
          mutators.setFormAttribute("lastActiveDate", undefined);
          
       }
    }
  
    function onMonthlyWagesChanged(newMonthlyWages){
      let employerMandatoryPct = 0;
      let employeeMandatoryPct = 0;
      let employerVoluntaryPct = 0;
      let employeeVoluntaryPct = 0;
      if(values && values.pensionContributionDetails){
        let det = values.pensionContributionDetails;
        if(det.employerContributionComponent){
          let employerComp = det.employerContributionComponent;
          if(employerComp.mandatoryContributionPercent){
              employerMandatoryPct = employerComp.mandatoryContributionPercent;
          }
   
          if(employerComp.voluntaryContributionPercent){
            employerVoluntaryPct = employerComp.voluntaryContributionPercent
          }
       }
  
       if(det.employeeContributionComponent){
          let employeeComp = det.employeeContributionComponent;
          if(employeeComp.mandatoryContributionPercent){
            employeeMandatoryPct = employeeComp.mandatoryContributionPercent;
          }
  
          if(employeeComp.voluntaryContributionPercent){
            employeeVoluntaryPct = employeeComp.voluntaryContributionPercent
          }
        }    
      }
  
      let employerMandatoryValue = 0;
      if(employerMandatoryPct){
         employerMandatoryValue = (employerMandatoryPct/100).toFixed(4) * newMonthlyWages;
      }
      mutators.setFormAttribute("pensionContributionDetails.employerContributionComponent.mandatoryContributionValue.value", employerMandatoryValue);
  
      let employerVoluntaryValue = 0;
      if(employerVoluntaryPct){
        employerVoluntaryValue = employerVoluntaryPct/100 * newMonthlyWages;
      }
      mutators.setFormAttribute("pensionContributionDetails.employerContributionComponent.voluntaryContributionValue.value", employerVoluntaryValue);
      
      
      let employeeMandatoryValue = 0;
      if(employeeMandatoryPct){
         employeeMandatoryValue = employeeMandatoryPct/100 * newMonthlyWages;
      }
      mutators.setFormAttribute("pensionContributionDetails.employeeContributionComponent.mandatoryContributionValue.value", employeeMandatoryValue);
  
      let employeeVoluntaryValue = 0;
      if(employeeVoluntaryPct){
        employeeVoluntaryValue = employeeVoluntaryPct/100 * newMonthlyWages;
      }
      mutators.setFormAttribute("pensionContributionDetails.employeeContributionComponent.voluntaryContributionValue.value", employeeVoluntaryValue);
  
      mutators.setFormAttribute("yearlyWages", 12 * newMonthlyWages);
    }
    
     
    
  
    function calculateTotalContributions(){
      let totalContribution = 0;
      if(values && values.selfEmployed){
        if(values && values.pensionContributionDetails){
          let det = values.pensionContributionDetails;
         
          if(det.employeeContributionComponent){
              let employeeComp = det.employeeContributionComponent;
              if(employeeComp.voluntaryContributionValue && employeeComp.voluntaryContributionValue.value){
                totalContribution = totalContribution + employeeComp.voluntaryContributionValue.value;
              }
          }
        }
      }else{
        if(values && values.pensionContributionDetails){
          let det = values.pensionContributionDetails;
          if(det.employerContributionComponent){
            let employerComp = det.employerContributionComponent;
            if(employerComp.mandatoryContributionValue && employerComp.mandatoryContributionValue.value){
              totalContribution = totalContribution + employerComp.mandatoryContributionValue.value;
            }
     
            if(employerComp.voluntaryContributionValue && employerComp.voluntaryContributionValue.value){
              totalContribution = totalContribution + employerComp.voluntaryContributionValue.value;
            }
         }
  
         if(det.employeeContributionComponent){
            let employeeComp = det.employeeContributionComponent;
            if(employeeComp.mandatoryContributionValue && employeeComp.mandatoryContributionValue.value){
              totalContribution = totalContribution + employeeComp.mandatoryContributionValue.value;
            }
    
            if(employeeComp.voluntaryContributionValue && employeeComp.voluntaryContributionValue.value){
              totalContribution = totalContribution + employeeComp.voluntaryContributionValue.value;
            }
         }
        }
      }
      
      return totalContribution;
      
    }
  
    function calculateAccount1Contribution(){
      let total = calculateTotalContributions();
      let result = 75/100 * total;
      return result;
    }
  
    function calculateAccount2Contribution(){
      let total = calculateTotalContributions();
      let result = 15/100 * total;
      return result;
    }
  
    function calculateAccount3Contribution(){
      let total = calculateTotalContributions();
      let result = 10/100 * total;
      return result;
    }
  
    function onEmployerMandatoryContributionChanged(pct){
       let val = 0;
       if(pct){
          val = pct/100 * values.monthlyWages;
       }
       mutators.setFormAttribute("pensionContributionDetails.employerContributionComponent.mandatoryContributionValue.value", val);
       
       
    }
  
    function onEmployerVoluntaryContributionChanged(pct){
      let val = 0;
      if(pct){
         val = pct/100 * values.monthlyWages;
      }
      mutators.setFormAttribute("pensionContributionDetails.employerContributionComponent.voluntaryContributionValue.value", val);
      
      
    }
  
    function onEmployeeMandatoryContributionChanged(pct){
      let val = 0;
      if(pct){
         val = pct/100 * values.monthlyWages;
      }
      mutators.setFormAttribute("pensionContributionDetails.employeeContributionComponent.mandatoryContributionValue.value", val);
      
      
   }
  
   function onEmployeeVoluntaryContributionChanged(pct){
     let val = 0;
     if(pct){
        val = pct/100 * values.monthlyWages;
     }
     mutators.setFormAttribute("pensionContributionDetails.employeeContributionComponent.voluntaryContributionValue.value", val);
     
     
   }
  
  
    return (
      <>
       {values && !values.selfEmployed && (
          <RenderSimpleFields
              fields={[
                { 
                  name: "monthlyWages",
                    label: intl.get("field.superannuation.monthlyWages"),
                    type: FieldType.MONEY,
                    symbolPrefix: currencySymbol,
                    readOnly: !values.selfEmployed,
                    onChange: (val)=>onMonthlyWagesChanged(val)
                },
                {
                  name: "yearlyWages",
                  label: intl.get("field.superannuation.yearlyWages"),
                  type: FieldType.MONEY,
                  symbolPrefix:currencySymbol,
                  readOnly: !values.selfEmployed
                }
              
              ]}
          />
        )} 
       
        <RenderSimpleFields
            fields={[
              
              {
                name: "contributionFrequency",
                label: intl.get(
                  "field.superannuation.contributionPaymentFrequency"
                ),
                type: FieldType.SELECTION,
                selectionData: getFrequencyTypeOptions(),
                valueProperty: "value",
                labelProperty: "label",
                required: true
              },
            ]}
        />
        {values && values.selfEmployed && (
          <RenderSimpleFields
            fields={[
              
              {
                name: "pensionContributionDetails.employeeContributionComponent.voluntaryContributionValue",
                label: "Contribution",
                type: FieldType.MONEY,
                isMoney:true,
                symbolPrefix:currencySymbol,
                defaultCurrency: currency,
                allowNegative: false,
                required: true
              },
            ]}
        />
        )}
        
        <br/>
        {values && !values.selfEmployed && (
          <table className="table-blue" style={{width:"100%"}}>
              <tbody>
                <tr>
                    <th style={{width:"30%"}}>{values && values.contributionFrequency} Contribution</th>
                    <th style={{width:"30%"}}> {intl.get("field.employer")}</th>
                    <th style={{width:"30%"}}>{intl.get("field.employee")}</th>
                </tr>
                <tr>
                    <td style={{width:"30%"}}>{intl.get("field.mandatoryContribution")}</td>
                    <td style={{width:"30%"}}>
                      
                        <div className="row">
                          <div className="col-md-6">
                            <Field
                              name="pensionContributionDetails.employerContributionComponent.mandatoryContributionPercent"
                              component={renderPercentageCustom}
                              isForGrid={true}
                              allowNegative={false}
                              required={!values.selfEmployed}
                              onChange={(val)=>onEmployerMandatoryContributionChanged(val)}
                            />
                          </div>
                          <div  className="col-md-6">
                            <Field
                              name="pensionContributionDetails.employerContributionComponent.mandatoryContributionValue"
                              component={renderCurrencyCustom}
                              defaultCurrency={currency}
                              symbolPrefix={currencySymbol}
                              allowNegative={false}
                              isForGrid={true}
                              isMoney={true}
                              disabled={true}
                              
                            />
                          </div>
                        </div>
                    
                      
                    </td>
                    <td style={{width:"30%"}}>
                      <div className="row">
                        <div className="col-md-6">
                          <Field
                            name="pensionContributionDetails.employeeContributionComponent.mandatoryContributionPercent"
                            component={renderPercentageCustom}
                            isForGrid={true}
                            onChange={(val)=>onEmployeeMandatoryContributionChanged(val)}
                          />
                        </div>
                        <div  className="col-md-6">
                          <Field
                            name="pensionContributionDetails.employeeContributionComponent.mandatoryContributionValue"
                            component={renderCurrencyCustom}
                            defaultCurrency={currency}
                            symbolPrefix={currencySymbol}
                            isMoney={true}
                            allowNegative={false}
                            isForGrid={true}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </td>
                </tr>
                <tr>
                    <td style={{width:"30%"}}>Voluntary Contribution</td>
                    <td style={{width:"30%"}}>
                    {values && !values.selfEmployed && (
                      <div className="row">
                        <div className="col-md-6">
                          <Field
                            name="pensionContributionDetails.employerContributionComponent.voluntaryContributionPercent"
                            component={renderPercentageCustom}
                            isForGrid={true}
                            allowNegative={false}
                            onChange={(val)=>onEmployerVoluntaryContributionChanged(val)}
                          />
                        </div>
                        <div  className="col-md-6">
                          <Field
                            name="pensionContributionDetails.employerContributionComponent.voluntaryContributionValue"
                            component={renderCurrencyCustom}
                            defaultCurrency={currency}
                            symbolPrefix={currencySymbol}
                            allowNegative={false}
                            isForGrid={true}
                            isMoney={true}
                            disabled={true}
                          
                          />
                        </div>
                      </div>
                    )}
                      
                    </td>
                    <td style={{width:"30%"}}>
                      <div className="row">
                        <div className="col-md-6">
                          <Field
                            name="pensionContributionDetails.employeeContributionComponent.voluntaryContributionPercent"
                            component={renderPercentageCustom}
                            isForGrid={true}
                            onChange={(val)=>onEmployeeVoluntaryContributionChanged(val)}
                          />
                        </div>
                        <div  className="col-md-6">
                          <Field
                            name="pensionContributionDetails.employeeContributionComponent.voluntaryContributionValue"
                            component={renderCurrencyCustom}
                            defaultCurrency={currency}
                            symbolPrefix={currencySymbol}
                            isMoney={true}
                            allowNegative={false}
                            isForGrid={true}
                            disabled={true}
                            
                          />
                        </div>
                      </div>
                    </td>
                </tr>
              </tbody>
  
            </table>
           )}
          
          <br/>
          <div className="subtitle2">
              Contribution Allocation Summary 
          </div>
          <RenderSimpleFields
              fields={[
                
                {
                  name: "totalContribution",
                  label: intl.get("field.totalContribution"),
                  type: FieldType.MONEY,
                  symbolPrefix:currencySymbol,
                  defaultCurrency: currency,
                  readOnly: true,
                  input:{
                    value : calculateTotalContributions()
                  },
                },
                
                {
                  name: "account1Contribution",
                  label: intl.get("field.account1Contribution"),
                  type: FieldType.MONEY,
                  symbolPrefix:currencySymbol,
                  defaultCurrency: currency,
                  readOnly: true,
                  input:{
                    value : calculateAccount1Contribution()
                  },
                },
                {
                  name: "account2Contribution",
                  label: intl.get("field.account2Contribution"),
                  type: FieldType.MONEY,
                  symbolPrefix:currencySymbol,
                  defaultCurrency: currency,
                  readOnly: true,
                  input:{
                    value : calculateAccount2Contribution()
                  },
                },
                {
                  name: "account3Contribution",
                  label: intl.get("field.account3Contribution"),
                  type: FieldType.MONEY,
                  symbolPrefix:currencySymbol,
                  defaultCurrency: currency,
                  readOnly: true,
                  input:{
                    value : calculateAccount3Contribution()
                  },
                },
              ]}
          />
          <br/>
          <div className="subtitle2">
              Available Balance and Rate
          </div>
          <RenderSimpleFields
              fields={[
                {
                  name: "account1Balance",
                  label: intl.get("field.account1Balance"),
                  type: FieldType.MONEY,
                  isMoney:true,
                  symbolPrefix:currencySymbol,
                  defaultCurrency: currency,
                  allowNegative: false,
                },
                {
                  name: "account2Balance",
                  label: intl.get("field.account2Balance"),
                  type: FieldType.MONEY,
                  isMoney:true,
                  symbolPrefix:currencySymbol,
                  defaultCurrency: currency,
                  allowNegative: false,
                },
                {
                  name: "account3Balance",
                  label: intl.get("field.account3Balance"),
                  type: FieldType.MONEY,
                  isMoney:true,
                  symbolPrefix:currencySymbol,
                  defaultCurrency: currency,
                  allowNegative: false,
                },
                {
                  name: "account55Balance",
                  label: intl.get("field.account55Balance"),
                  type: FieldType.MONEY,
                  isMoney:true,
                  symbolPrefix:currencySymbol,
                  defaultCurrency: currency,
                  allowNegative: false,
                  readOnly: values.clientAge < 55
                },
                {
                  name: "accountGoldBalance",
                  label: intl.get("field.accountGoldBalance"),
                  type: FieldType.MONEY,
                  isMoney:true,
                  symbolPrefix:currencySymbol,
                  defaultCurrency: currency,
                  allowNegative: false,
                  readOnly: values.clientAge < 55
                },
                {
                  name: "annualInterestRate",
                  label: intl.get("field.annualInterestRate"),
                  type: FieldType.PERCENTAGE,
                  required: true
                },
                {
                  name: "valuationDate",
                  label: intl.get("field.valuationDate"),
                  type: FieldType.DATE,
                  required: true,
                },
                {
                  name: "inactive",
                  label: "Inactive",
                  type: FieldType.CHECKBOX,
                  onChange: (val)=>onInactiveChecked(val, mutators)
                }
                
              ]}
          />
          <br/>
          {values.inactive && (
            <>
              <div className="subtitle2">
                Inactive Treatment
              </div>
              <RenderSimpleFields
                  fields={[
                    {
                      name: "lastActiveDate",
                      label: "Contribution Ending (Last Active)",
                      type: FieldType.DATE,
                      required: true,
                    },
                    {
                      name: "inactiveReason",
                      label: "Inactive Reason",
                      type: FieldType.TEXT,
                    },
                    {
                      name: "inactiveTreatmentType",
                      label: "Inactive Treatment Type",
                      type: FieldType.SELECTION,
                      required: true,
                      selectionData: INACTIVE_TREATMENT_TYPES,
                      valueProperty: "value",
                      labelProperty: "name"
                    }
                  
                  ]}
              />
            </>
            
          )} 
      </>
    )
  
  }
  
  function EPFForm({handleSave, model, client, partner, optionsData, readOnlyAccessComponent}){
    const currency = "MYR";
    const currencySymbol = getSymbolFromCode(currency)
    return (
            <Form
              onSubmit={handleSave}
              initialValues={model}
              keepDirtyOnReinitialize={true}
              mutators={{
                pushToArray: arrayMutators.push,
                removeFromArray: arrayMutators.remove,
                updateArray: arrayMutators.update,
                setFormAttribute: ([fieldName, fieldVal], state, { changeValue }) => {
                  changeValue(state, fieldName, () => fieldVal)
                }
              }}
              render={({ handleSubmit, reset, values, form: { mutators } }) => (
                <form
                  id="employeeProvidenceFund"
                  onSubmit={async (event) => {
                    await handleSubmit(event)
                    reset()
                  }}
                >
                  <div className="notification-dialog">
                    <ClassificationSelection hideJoint={true} />
                    <Tabs>
                          <TabList>
                              <Tab>EPF Details</Tab>
                              <Tab>Contributions</Tab>
                              <Tab>Withdrawal</Tab>
                              <Tab>Fees</Tab>
                          </TabList>
                          <TabPanel>
                            <EmployeeProvidenceFundDetailsForm
                              clientId={client.id}
                              values={values}
                              mutators={mutators}
                              currency={currency}
                              currencySymbol={currencySymbol}
                              optionsData={optionsData}
                            />
      
                          </TabPanel>
                          <TabPanel>
                            <EpfContributionSection  
                              clientId={client.id}
                              values={values}
                              mutators={mutators}
                              currency={currency}
                              currencySymbol={currencySymbol}
                            />
                            
                            
                          </TabPanel>
      
                          <TabPanel>
                            <WithdrawalPlans
                              hasLockInPeriods={false}
                              hasFees={false}
                              values={values}
                              optionsData={optionsData}
                              addPlan={mutators.pushToArray}
                              removePlan={mutators.removeFromArray}
                              setFormAttribute={mutators.setFormAttribute}
                              currency={currency}
                          />
                          </TabPanel>
                          <TabPanel>
                              <FeeTable
                              values={values}
                              optionsData={optionsData}
                              addPlan={mutators.pushToArray}
                              removePlan={mutators.removeFromArray}
                              setFormAttribute={mutators.setFormAttribute}
                              currency={currency}
                              />
                          </TabPanel>
                    </Tabs>                          
                      
                    
                  
                  </div>
                  <NetWorthFormButtons
                      reset={reset}
                      readOnlyAccess={false}
                  
                  />
              
                </form>
              )}
            />
    )
    
  }
  export default EPFForm