import { implementationActions } from "../constants/ActionConstants"

export function Implementation(
  state = {
    client: { kycData: "", kycStatus: "" },
    partner: { kycData: "", kycStatus: "" }
  },
  action
) {
  const isPartner = action.isPartner && action.isPartner.isPartner
  switch (action.type) {
    case implementationActions.GET_APPLICATION_SUCCESS:
      return {
        loading: true
      }
    case implementationActions.GET_KYC_STATUS:
      return {
        ...state,
        loading: false,
        client: {
          ...state.client,
          kycStatus: isPartner
            ? { ...state.client.kycStatus }
            : action.response.data.data
        },
        partner: {
          ...state.partner,
          kycStatus: !isPartner
            ? { ...state.partner.kycStatus }
            : action.response.data.data
        }
      }
    //   VALIDATE_KYC_SUCCESS

    case implementationActions.VALIDATE_KYC_SUCCESS:
      return {
        ...state,
        loading: false,
        client: {
          ...state.client,
          kycData: isPartner
            ? { ...state.client.kycData }
            : action.response.data
        },
        partner: {
          ...state.partner,
          kycData: !isPartner
            ? { ...state.partner.kycData }
            : action.response.data
        }
      }

    case implementationActions.SET_KYC_DATA_EMPTY:
      return {
        ...state,
        client: { kycData: {}, kycStatus: {} },
        partner: { kycData: {}, kycStatus: {} }
      }
    default:
      return state
  }
}
