import React, { useContext } from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from "react-accessible-accordion"
import intl from "react-intl-universal"
import api from "../../../../lib/api"

import CashFlowListing from "../../CashFlowListing"
import CashFlowHeaderListing from "../../CashFlowHeaderListing"
import { AdditionalArrayInput } from "../FieldArrayList"
import CashFlowSubheaderListing from "../../CashflowSubheaderListing"
import { AppContext } from "../../../../context/AppContext"
import {
  GEALAustraliaForm,
  GEALSingaporeForm,
  GEALHongkongForm,
  GEALNewZealandForm,
  GEALMalaysiaForm
} from "../../../../forms"
import { getNewCurrencyValue } from "../../../../helpers"
import Get from "../../../../helpers/NullHelper"

const getEducationExpensesData = (url) =>
  api.get(url).then((response) => {
    const { data } = response
    return data
  })

const calculateTotal = (list, key, subkey, currency) => {
  let total = 0
  list.forEach((item) => {
    if (item && item[key] && item[key][subkey]) {
      if (currency) {
        total += getNewCurrencyValue(item[key], currency)
      } else {
        total += item[key][subkey]
      }
    }
  })
  return total
}

const calculateGealTotal = (
  australiaGealExpenses,
  singaporeGealExpenses,
  hongkongGealExpenses,
  newZealandGealExpenses,
  malaysiaGealExpenses,
  key,
  subkey,
  currency
) =>
  calculateTotal(australiaGealExpenses, key, subkey, currency) +
  calculateTotal(singaporeGealExpenses, key, subkey, currency) +
  calculateTotal(hongkongGealExpenses, key, subkey, currency) +
  calculateTotal(newZealandGealExpenses, key, subkey, currency) +
  calculateTotal(malaysiaGealExpenses, key, subkey, currency)

const EducationExpenses = ({
  varPrefix,
  push,
  clientTotalValue,
  partnerTotalValue,
  jointTotalValue,
  currency,
  isJointHide,
  educationExpensesData,
  optionsData,
  onExternalUpdated
}) => {
  const {
    modal: { setModal }
  } = useContext(AppContext)

  const handleSaveGEAL = (model, url) => {
    api.put(url, model).then(() => {
      onExternalUpdated(true)
    })
  }

  return (
    <div className="portal-part">
      <Accordion accordion={false}>
        <AccordionItem>
          <AccordionItemTitle>
            <CashFlowHeaderListing
              title={intl.get(
                "cashflowlisting.personalExpenses.educationExpenses"
              )}
              isAccordion
              isJointHide={isJointHide}
              clientTotalValue={clientTotalValue}
              partnerTotalValue={partnerTotalValue}
              jointTotalValue={jointTotalValue}
              currency={currency}
            />
          </AccordionItemTitle>
          <AccordionItemBody>
            <CashFlowListing
              varPrefix={`${varPrefix}.schoolOrUniversityFees`}
              isOnlyTextTitle
              isRoundColorRed={false}
              title={intl.get(
                "cashflowlisting.personalExpenses.schoolUniversityFees"
              )}
              isJointHide={isJointHide}
              defaultDeductibleValue={0}
              currency={currency}
            />
            <CashFlowListing
              varPrefix={`${varPrefix}.privateTuitionFees`}
              isOnlyTextTitle
              isRoundColorRed={false}
              title={intl.get(
                "cashflowlisting.personalExpenses.privateTuitionFees"
              )}
              isJointHide={isJointHide}
              defaultDeductibleValue={0}
              currency={currency}
            />
            <CashFlowListing
              varPrefix={`${varPrefix}.bookAndSupplies`}
              isOnlyTextTitle
              isRoundColorRed={false}
              title={intl.get("cashflowlisting.personalExpenses.booksSupplies")}
              isJointHide={isJointHide}
              defaultDeductibleValue={0}
              currency={currency}
            />
            <CashFlowListing
              varPrefix={`${varPrefix}.schoolUniform`}
              isOnlyTextTitle
              isRoundColorRed={false}
              title={intl.get("cashflowlisting.personalExpenses.schoolUniform")}
              isJointHide={isJointHide}
              defaultDeductibleValue={0}
              currency={currency}
            />
            <CashFlowListing
              varPrefix={`${varPrefix}.computerAndOtherEquipment`}
              isOnlyTextTitle
              isRoundColorRed={false}
              title={intl.get(
                "cashflowlisting.personalExpenses.computerOtherEquipment"
              )}
              isJointHide={isJointHide}
              defaultDeductibleValue={0}
              currency={currency}
            />
            {!!Get(educationExpensesData, "detailResult") && (
              <Accordion accordion={false}>
                <AccordionItem>
                  <AccordionItemTitle>
                    <CashFlowSubheaderListing
                      title={intl.get(
                        "cashflowlisting.personalExpenses.governmentEducationAssistanceLoans"
                      )}
                      isAccordion
                      isJointHide={isJointHide}
                      clientTotalValue={calculateGealTotal(
                        educationExpensesData.detailResult
                          .australiaGealExpenses,
                        educationExpensesData.detailResult
                          .singaporeGealExpenses,
                        educationExpensesData.detailResult.hongkongGealExpenses,
                        educationExpensesData.detailResult
                          .newZealandGealExpenses,
                        educationExpensesData.detailResult.malaysiaGealExpenses,
                        "client",
                        "value",
                        currency
                      )}
                      partnerTotalValue={calculateGealTotal(
                        educationExpensesData.detailResult
                          .australiaGealExpenses,
                        educationExpensesData.detailResult
                          .singaporeGealExpenses,
                        educationExpensesData.detailResult.hongkongGealExpenses,
                        educationExpensesData.detailResult
                          .newZealandGealExpenses,
                        educationExpensesData.detailResult.malaysiaGealExpenses,
                        "partner",
                        "value",
                        currency
                      )}
                      jointTotalValue={calculateGealTotal(
                        educationExpensesData.detailResult
                          .australiaGealExpenses,
                        educationExpensesData.detailResult
                          .singaporeGealExpenses,
                        educationExpensesData.detailResult.hongkongGealExpenses,
                        educationExpensesData.detailResult
                          .newZealandGealExpenses,
                        educationExpensesData.detailResult.malaysiaGealExpenses,
                        "joint",
                        "value",
                        currency
                      )}
                      currency={currency}
                    />
                  </AccordionItemTitle>
                  <AccordionItemBody>
                    <br />
                    {educationExpensesData &&
                    educationExpensesData.detailResult.australiaGealExpenses ? (
                      <Accordion accordion={false}>
                        <AccordionItem>
                          <AccordionItemTitle>
                            <CashFlowSubheaderListing
                              title={intl.get(
                                "cashflowlisting.personalExpenses.governmentEducationAssistanceLoans.australia"
                              )}
                              isAccordion
                              isJointHide={isJointHide}
                              clientTotalValue={calculateTotal(
                                educationExpensesData.detailResult
                                  .australiaGealExpenses,
                                "client",
                                "value",
                                currency
                              )}
                              partnerTotalValue={calculateTotal(
                                educationExpensesData.detailResult
                                  .australiaGealExpenses,
                                "partner",
                                "value",
                                currency
                              )}
                              jointTotalValue={calculateTotal(
                                educationExpensesData.detailResult
                                  .australiaGealExpenses,
                                "joint",
                                "value",
                                currency
                              )}
                              currency={currency}
                            />
                          </AccordionItemTitle>
                          <AccordionItemBody>
                            {educationExpensesData.detailResult.australiaGealExpenses.map(
                              (expenses, index) => (
                                <CashFlowListing
                                  varPrefix={`${varPrefix}.australiaGealExpenses[${index}]`}
                                  isEditButton
                                  isRoundColorRed={false}
                                  title={intl.get(
                                    `EducationAssistanceLoanTypeAUEnum_${expenses.description}`
                                  )}
                                  key={index}
                                  isJointHide={isJointHide}
                                  currency={currency}
                                  defaultDeductibleValue={0}
                                  onEditClick={() =>
                                    getEducationExpensesData(
                                      expenses._self
                                    ).then((data) =>
                                      setModal({
                                        title: intl.get("geal.title.modal", {
                                          countryDesc: "Australian"
                                        }),
                                        content: (
                                          <GEALAustraliaForm
                                            model={data}
                                            optionsData={optionsData}
                                            handleSave={(newModel) =>
                                              handleSaveGEAL(
                                                newModel,
                                                expenses._self
                                              )
                                            }
                                          />
                                        ),
                                        formType: "educationExpenses"
                                      })
                                    )
                                  }
                                />
                              )
                            )}
                          </AccordionItemBody>
                        </AccordionItem>
                      </Accordion>
                    ) : null}
                    {educationExpensesData &&
                    educationExpensesData.detailResult.singaporeGealExpenses ? (
                      <Accordion accordion={false}>
                        <AccordionItem>
                          <AccordionItemTitle>
                            <CashFlowSubheaderListing
                              title={intl.get(
                                "cashflowlisting.personalExpenses.governmentEducationAssistanceLoans.singapore"
                              )}
                              isAccordion
                              isJointHide={isJointHide}
                              clientTotalValue={calculateTotal(
                                educationExpensesData.detailResult
                                  .singaporeGealExpenses,
                                "client",
                                "value",
                                currency
                              )}
                              partnerTotalValue={calculateTotal(
                                educationExpensesData.detailResult
                                  .singaporeGealExpenses,
                                "partner",
                                "value",
                                currency
                              )}
                              jointTotalValue={calculateTotal(
                                educationExpensesData.detailResult
                                  .singaporeGealExpenses,
                                "joint",
                                "value",
                                currency
                              )}
                              currency={currency}
                            />
                          </AccordionItemTitle>
                          <AccordionItemBody>
                            {educationExpensesData.detailResult.singaporeGealExpenses.map(
                              (expenses, index) => (
                                <CashFlowListing
                                  varPrefix={`${varPrefix}.singaporeGealExpenses[${index}]`}
                                  isEditButton
                                  isRoundColorRed={false}
                                  title={intl.get(
                                    `EducationAssistanceLoanTypeSGEnum_${expenses.description}`
                                  )}
                                  isJointHide={isJointHide}
                                  defaultDeductibleValue={0}
                                  onEditClick={() =>
                                    getEducationExpensesData(
                                      expenses._self
                                    ).then((data) =>
                                      setModal({
                                        title: intl.get("geal.title.modal", {
                                          countryDesc: "Singapore"
                                        }),
                                        content: (
                                          <GEALSingaporeForm
                                            model={data}
                                            optionsData={optionsData}
                                            handleSave={(newModel) =>
                                              handleSaveGEAL(
                                                newModel,
                                                expenses._self
                                              )
                                            }
                                          />
                                        )
                                      })
                                    )
                                  }
                                />
                              )
                            )}
                          </AccordionItemBody>
                        </AccordionItem>
                      </Accordion>
                    ) : null}
                    {educationExpensesData &&
                    educationExpensesData.detailResult.hongkongGealExpenses ? (
                      <Accordion accordion={false}>
                        <AccordionItem>
                          <AccordionItemTitle>
                            <CashFlowSubheaderListing
                              title={intl.get(
                                "cashflowlisting.personalExpenses.governmentEducationAssistanceLoans.hongkong"
                              )}
                              isAccordion
                              isJointHide={isJointHide}
                              clientTotalValue={calculateTotal(
                                educationExpensesData.detailResult
                                  .hongkongGealExpenses,
                                "client",
                                "value",
                                currency
                              )}
                              partnerTotalValue={calculateTotal(
                                educationExpensesData.detailResult
                                  .hongkongGealExpenses,
                                "partner",
                                "value",
                                currency
                              )}
                              jointTotalValue={calculateTotal(
                                educationExpensesData.detailResult
                                  .hongkongGealExpenses,
                                "joint",
                                "value",
                                currency
                              )}
                              currency={currency}
                            />
                          </AccordionItemTitle>
                          <AccordionItemBody>
                            {educationExpensesData.detailResult.hongkongGealExpenses.map(
                              (expenses, index) => (
                                <CashFlowListing
                                  varPrefix={`${varPrefix}.hongkongGealExpenses[${index}]`}
                                  isEditButton
                                  isRoundColorRed={false}
                                  title={intl.get(
                                    `EducationAssistanceLoanTypeHKEnum_${expenses.description}`
                                  )}
                                  isJointHide={isJointHide}
                                  defaultDeductibleValue={0}
                                  onEditClick={() =>
                                    getEducationExpensesData(
                                      expenses._self
                                    ).then((data) =>
                                      setModal({
                                        title: intl.get("geal.title.modal", {
                                          countryDesc: "Hongkong"
                                        }),
                                        content: (
                                          <GEALHongkongForm
                                            model={data}
                                            optionsData={optionsData}
                                            handleSave={(newModel) =>
                                              handleSaveGEAL(
                                                newModel,
                                                expenses._self
                                              )
                                            }
                                          />
                                        )
                                      })
                                    )
                                  }
                                />
                              )
                            )}
                          </AccordionItemBody>
                        </AccordionItem>
                      </Accordion>
                    ) : null}
                    {educationExpensesData &&
                    educationExpensesData.detailResult
                      .newZealandGealExpenses ? (
                      <Accordion accordion={false}>
                        <AccordionItem>
                          <AccordionItemTitle>
                            <CashFlowSubheaderListing
                              title={intl.get(
                                "cashflowlisting.personalExpenses.governmentEducationAssistanceLoans.newZealand"
                              )}
                              isAccordion
                              isJointHide={isJointHide}
                              clientTotalValue={calculateTotal(
                                educationExpensesData.detailResult
                                  .newZealandGealExpenses,
                                "client",
                                "value",
                                currency
                              )}
                              partnerTotalValue={calculateTotal(
                                educationExpensesData.detailResult
                                  .newZealandGealExpenses,
                                "partner",
                                "value",
                                currency
                              )}
                              jointTotalValue={calculateTotal(
                                educationExpensesData.detailResult
                                  .newZealandGealExpenses,
                                "joint",
                                "value",
                                currency
                              )}
                              currency={currency}
                            />
                          </AccordionItemTitle>
                          <AccordionItemBody>
                            {educationExpensesData.detailResult.newZealandGealExpenses.map(
                              (expenses, index) => (
                                <CashFlowListing
                                  varPrefix={`${varPrefix}.newZealandGealExpenses[${index}]`}
                                  isEditButton
                                  isRoundColorRed={false}
                                  title={intl.get(
                                    `EducationAssistanceLoanTypeNZEnum_${expenses.description}`
                                  )}
                                  isJointHide={isJointHide}
                                  defaultDeductibleValue={0}
                                  onEditClick={() =>
                                    getEducationExpensesData(
                                      expenses._self
                                    ).then((data) =>
                                      setModal({
                                        title: intl.get("geal.title.modal", {
                                          countryDesc: "New Zealand"
                                        }),
                                        content: (
                                          <GEALNewZealandForm
                                            model={data}
                                            optionsData={optionsData}
                                            handleSave={(newModel) =>
                                              handleSaveGEAL(
                                                newModel,
                                                expenses._self
                                              )
                                            }
                                          />
                                        )
                                      })
                                    )
                                  }
                                />
                              )
                            )}
                          </AccordionItemBody>
                        </AccordionItem>
                      </Accordion>
                    ) : null}
                    {educationExpensesData &&
                    educationExpensesData.detailResult.malaysiaGealExpenses ? (
                      <Accordion accordion={false}>
                        <AccordionItem>
                          <AccordionItemTitle>
                            <CashFlowSubheaderListing
                              title={intl.get(
                                "cashflowlisting.personalExpenses.governmentEducationAssistanceLoans.malaysia"
                              )}
                              isAccordion
                              isJointHide={isJointHide}
                              clientTotalValue={calculateTotal(
                                educationExpensesData.detailResult
                                  .malaysiaGealExpenses,
                                "client",
                                "value",
                                currency
                              )}
                              partnerTotalValue={calculateTotal(
                                educationExpensesData.detailResult
                                  .malaysiaGealExpenses,
                                "partner",
                                "value",
                                currency
                              )}
                              jointTotalValue={calculateTotal(
                                educationExpensesData.detailResult
                                  .malaysiaGealExpenses,
                                "joint",
                                "value",
                                currency
                              )}
                              currency={currency}
                            />
                          </AccordionItemTitle>
                          <AccordionItemBody>
                            {educationExpensesData.detailResult.malaysiaGealExpenses.map(
                              (expenses, index) => (
                                <CashFlowListing
                                  varPrefix={`${varPrefix}.malaysiaGealExpenses[${index}]`}
                                  isEditButton
                                  isRoundColorRed={false}
                                  title={intl.get(
                                    `EducationAssistanceLoanTypeMYEnum_${expenses.description}`
                                  )}
                                  isJointHide={isJointHide}
                                  defaultDeductibleValue={0}
                                  onEditClick={() =>
                                    getEducationExpensesData(
                                      expenses._self
                                    ).then((data) =>
                                      setModal({
                                        title: intl.get("geal.title.modal", {
                                          countryDesc: "Malaysia"
                                        }),
                                        content: (
                                          <GEALMalaysiaForm
                                            model={data}
                                            optionsData={optionsData}
                                            handleSave={(newModel) =>
                                              handleSaveGEAL(
                                                newModel,
                                                expenses._self
                                              )
                                            }
                                          />
                                        )
                                      })
                                    )
                                  }
                                />
                              )
                            )}
                          </AccordionItemBody>
                        </AccordionItem>
                      </Accordion>
                    ) : null}
                  </AccordionItemBody>
                </AccordionItem>
              </Accordion>
            )}
            <CashFlowListing
              varPrefix={`${varPrefix}`}
              title={intl.get("cashflowlisting.others")}
              isAdditionalInput
              isJointHide={isJointHide}
              isShowAddButton
              push={push}
              additionName={`${varPrefix}.others`}
              defaultDeductibleValue={0}
            />
            <AdditionalArrayInput
              placeholderLabel={intl.get("cashflowlisting.pleaseSpecify")}
              AdditionalInput
              arrayName={`${varPrefix}.others`}
              isJointHide={isJointHide}
              currency={currency}
            />
          </AccordionItemBody>
        </AccordionItem>
      </Accordion>
    </div>
  )
}

export default EducationExpenses
