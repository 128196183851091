/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Library : MalabarYearAge
    Description : component specific for showing year along with the age
*/

import React, { useState } from "react"
import moment from "moment"
import Datetime from "react-datetime"

const MalabarYearAge = ({
  value,
  name,
  onChange = null,
  birthDate,
  dateFormat = "YYYY",
  readonly = false,
  touched,
  error
}) => {
  const birthDateMoment = moment(birthDate, "YYYY-MM-DD")
  const validYear = function (current) {
    return current.isAfter(moment())
  }
  const initVal =
    moment(value, dateFormat).diff(birthDateMoment, "years") || null
  const [countAge, setCountAge] = useState(initVal !== null ? initVal : 0)

  if (readonly) {
    return <label>{`${value} - Age ${countAge}`}</label>
  }
  return (
    <React.Fragment>
      <div className="year-age-input">
        <Datetime
          inputProps={{
            name,
            className: "text-input",
            readOnly: true
          }}
          value={value}
          className={touched ? (error ? "is-invalid" : "") : ""}
          onChange={(param) => {
            onChange(moment(param).format(dateFormat))
            setCountAge(
              moment(param, dateFormat).diff(birthDateMoment, "years")
            )
          }}
          dateFormat={dateFormat}
          isValidDate={validYear}
        />
        {touched && error && <span className="alert">{error}</span>}
      </div>
      {/* <label className="age-label">{` - Age ${countAge}`}</label> */}
    </React.Fragment>
  )
}

export default MalabarYearAge
