import React from "react"
import intl from "react-intl-universal"
import { Field } from "react-final-form"
import RenderSimpleFields, {
  FieldType
} from "../../components/Form/RenderSimpleFields"

import {
  greaterThan,
  required
} from "../../helpers/Validator"

import {
  renderTextBoxCustom,
  renderSelectEnumFieldCustom,
  renderMoneyCustom,
  renderDateFieldCustom,
  composeValidators,
  getSymbolFromCode
} from "../../helpers"

const InsuranceDetails = ({
  optionsData,
  insuranceType,
  objectName = "insuranceDetails",
  defaultCurrency,
  varPrefix,
  varPrefix2,
  isFireAndContentInsured,
  isLoanInsured,
  values
}) =>
  // 20200928 MABT-400 if [Fire & Contents Insurance​] OR [Mortgage Insurance​] is checked
  isFireAndContentInsured || isLoanInsured ? (
    <div
      className={
        isFireAndContentInsured && isLoanInsured
          ? "pop-holder2col modal-wider"
          : "pop-holder"
      }
    >
      {insuranceType && (
        <Field name="insuranceType" value={insuranceType} type="hidden" />
      )}
      {/* Label Button */}
      <div className="content-panel-title content-panel-title-fireAndContentInsurance d-flex">
        {isFireAndContentInsured && (
          <div className="title-box">
            {intl.get("field.fireAndContentInsurance")}
          </div>
        )}

        {/* Label Button */}
        {isLoanInsured && (
          <div className="title-box">{intl.get("field.loanInsurance")}</div>
        )}
      </div>
      {[
         {
          name: "policyNumber",
          component: renderTextBoxCustom,
          isRequired: true
        },
        {
          name: "policyName",
          component: renderTextBoxCustom,
          isRequired: false
        },
        {
          name: "insuranceOrganization",
          component: renderTextBoxCustom,
          isRequired: false
        },
        {
          name: "managedBy",
          label: intl.get("field.managedBy"),
          type: FieldType.ENUM_SELECTION,
          enumData: optionsData.managedByOptions,
          required: false
        },
        {
          name: "insuredAmount",
          component: renderMoneyCustom,
          isFullWidth: false,
          isRequired: true,
          symbolPrefix: getSymbolFromCode(defaultCurrency),
          defaultCurrency
        },
        {
          name: "policyPremium",
          component: renderMoneyCustom,
          isFullWidth: false,
          isRequired: true,
          symbolPrefix: getSymbolFromCode(defaultCurrency),
          defaultCurrency: "AUD"
        },
        {
          name: "policyPremiumFrequency",
          component: renderSelectEnumFieldCustom,
          enumData: optionsData.frequencyOptions,
          isRequired: true
        },
        {
          name: "policyStartDate",
          component: renderDateFieldCustom,
          isRequired: true
        },
        {
          name: "policyMaturityDate",
          component: renderDateFieldCustom
        }
      ].map((item, index) => (
        <div
          className={`portal-form-row ${
            item.component === renderDateFieldCustom ? "dateformat" : ""
          }`}
          key={index}
        >
          <label>
            {item.name=='policyNumber'?intl.get(`field.uniqueCode`):intl.get(`field.${item.name}`)}
            {item.isRequired && <span>*</span>}
          </label>

          {isFireAndContentInsured &&
            (item.name === "policyMaturityDate" ? (
              <Field
                name={`${varPrefix}.${item.name}`}
                component={item.component}
                enumData={item.enumData}
                isFullWidth={item.isFullWidth}
                validate={composeValidators(
                  greaterThan(
                    values.fireAndContentInsurance &&
                      values.fireAndContentInsurance.policyStartDate
                      ? values.fireAndContentInsurance.policyStartDate
                      : null
                  )
                )}
              />
            ) : (
              <Field
                name={`${varPrefix}.${item.name}`}
                component={item.component}
                enumData={item.enumData}
                isFullWidth={item.isFullWidth}
                validate={required}
                symbolPrefix={getSymbolFromCode(defaultCurrency)}
                defaultCurrency={defaultCurrency}
              />
            ))}

          {isLoanInsured &&
            (item.name === "policyMaturityDate" ? (
              <Field
                name={`${varPrefix2}.${item.name}`}
                component={item.component}
                enumData={item.enumData}
                isFullWidth={item.isFullWidth}
                validate={composeValidators(
                  greaterThan(
                    values.fireAndContentInsurance &&
                      values.fireAndContentInsurance.policyMaturityDate
                      ? values.fireAndContentInsurance.policyMaturityDate
                      : null
                  )
                )}
              />
            ) : (
              <Field
                name={`${varPrefix2}.${item.name}`}
                component={item.component}
                enumData={item.enumData}
                isFullWidth={item.isFullWidth}
                validate={required}
                symbolPrefix={getSymbolFromCode(defaultCurrency)}
                defaultCurrency={defaultCurrency}
              />
            ))}
        </div>
      ))}
    </div>
  ) : (
    // 20200928 MABT-400 if [Fire & Contents Insurance​] AND [Mortgage Insurance​] is unchecked
    <div className="pop-holder">
      {insuranceType && (
        <Field name="insuranceType" value={insuranceType} type="hidden" />
      )}
      <RenderSimpleFields
        fields={[
          {
            name: `${objectName}.policyNumber`,
            label: intl.get(`field.uniqueCode`),
            type: FieldType.TEXT,
            required: true
          },
          {
            name: `${objectName}.policyName`,
            label: `${intl.get(`field.policyName`)}`,
            type: FieldType.TEXT,
            required: false
          },
          {
            name: `${objectName}.insuranceOrganization`,
            label: intl.get(`field.insuranceOrganization`),
            type: FieldType.TEXT,
            required: false
          },
          {
            name: "managedBy",
            label: intl.get("field.managedBy"),
            type: FieldType.ENUM_SELECTION,
            enumData: optionsData.managedByOptions,
            required: false
          },
         
          {
            name: `${objectName}.insuredAmount`,
            label: `${intl.get(`field.insuredAmount`)}`,
            type: FieldType.MONEY_WITH_CURRENCY,
            defaultCurrency,
            symbolPrefix: getSymbolFromCode(defaultCurrency),
            required: true
          },
          {
            name: `${objectName}.policyPremium`,
            label: intl.get(`field.policyPremium`),
            type: FieldType.MONEY_WITH_CURRENCY,
            defaultCurrency,
            symbolPrefix: getSymbolFromCode(defaultCurrency),
            required: true
          },
          {
            name: `${objectName}.policyPremiumFrequency`,
            label: intl.get(`field.policyPremiumFrequency`),
            type: FieldType.ENUM_SELECTION,
            enumData: optionsData.frequencyOptions,
            required: true
          },
          {
            name: `${objectName}.policyStartDate`,
            label: intl.get(`field.policyStartDate`),
            type: FieldType.DATE,
            required: true
          },
          {
            name: `${objectName}.policyMaturityDate`,
            label: intl.get(`field.policyMaturityDate`),
            type: FieldType.DATE,
            required: true,
            // validate:{composeValidators(validate)}
          }
        ]}
      />
    </div>
  )

export default InsuranceDetails
