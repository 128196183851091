import React from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';
import intl from 'react-intl-universal';
import CashFlowListing from '../../CashFlowListing';
import CashFlowHeaderListing from '../../CashFlowHeaderListing';
import { AdditionalArrayInput } from '../FieldArrayList';

const Entertainment = ({
    varPrefix,
    push,
    clientTotalValue,
    partnerTotalValue,
    jointTotalValue,
    currency,
    isJointHide,
}) => (
    <div className="portal-part">
        <Accordion accordion={false}>
            <AccordionItem>
                <AccordionItemTitle>
                    <CashFlowHeaderListing
                        title={intl.get(
                            'cashflowlisting.personalExpenses.entertainment'
                        )}
                        isAccordion
                        isJointHide={isJointHide}
                        clientTotalValue={clientTotalValue}
                        partnerTotalValue={partnerTotalValue}
                        jointTotalValue={jointTotalValue}
                        currency={currency}
                    />
                </AccordionItemTitle>
                <AccordionItemBody>
                    <CashFlowListing
                        varPrefix={`${varPrefix}.amusement`}
                        isOnlyTextTitle
                        isRoundColorRed={false}
                        title={intl.get(
                            'cashflowlisting.personalExpenses.amusement'
                        )}
                        isJointHide={isJointHide}
                        defaultDeductibleValue={0}
                        currency={currency}
                    />
                    <CashFlowListing
                        varPrefix={`${varPrefix}.art`}
                        isOnlyTextTitle
                        isRoundColorRed={false}
                        title={intl.get('cashflowlisting.personalExpenses.art')}
                        isJointHide={isJointHide}
                        defaultDeductibleValue={0}
                        currency={currency}
                    />
                    <CashFlowListing
                        varPrefix={`${varPrefix}.moviesAndDvds`}
                        isOnlyTextTitle
                        isRoundColorRed={false}
                        title={intl.get(
                            'cashflowlisting.personalExpenses.moviesDvd'
                        )}
                        isJointHide={isJointHide}
                        defaultDeductibleValue={0}
                        currency={currency}
                    />
                    <CashFlowListing
                        varPrefix={`${varPrefix}.music`}
                        isOnlyTextTitle
                        isRoundColorRed={false}
                        title={intl.get(
                            'cashflowlisting.personalExpenses.music'
                        )}
                        isJointHide={isJointHide}
                        defaultDeductibleValue={0}
                        currency={currency}
                    />
                    <CashFlowListing
                        varPrefix={`${varPrefix}.newspaperAndMagazines`}
                        isOnlyTextTitle
                        isRoundColorRed={false}
                        title={intl.get(
                            'cashflowlisting.personalExpenses.newspaperMagazines'
                        )}
                        isJointHide={isJointHide}
                        defaultDeductibleValue={0}
                        currency={currency}
                    />
                    <CashFlowListing
                        varPrefix={`${varPrefix}`}
                        title={intl.get('cashflowlisting.others')}
                        isAdditionalInput
                        isJointHide={isJointHide}
                        isShowAddButton
                        push={push}
                        additionName={`${varPrefix}.others`}
                        defaultDeductibleValue={0}
                        currency={currency}
                    />
                    <AdditionalArrayInput
                        placeholderLabel={intl.get(
                            'cashflowlisting.pleaseSpecify'
                        )}
                        AdditionalInput
                        arrayName={`${varPrefix}.others`}
                        isJointHide={isJointHide}
                        currency={currency}
                    />
                </AccordionItemBody>
            </AccordionItem>
        </Accordion>
    </div>
);

export default Entertainment;
