import EstatePlanningApi from './KYC/EstatePlanningApi';
import MotorVehicleApi from './KYC/MotorVehicleApi';
import PersonalContentsApi from './KYC/NetWorth/PersonalContentsApi';
import PersonalDetailsApi from './KYC/PersonalDetailsApi';
import PrimaryResidenceApi from './KYC/PrimaryResidenceApi';
import NetWorthApi from './KYC/NetWorth/NetWorthApi';
import BusinessOwnershipApi from './KYC/NetWorth/BusinessOwnershipApi';
import BondsHoldingInformationApi from './KYC/RetirementAsset/BondsHoldingInformationApi';
import CashAccountInformationApi from './KYC/RetirementAsset/CashAccountInformationApi';
import ETFHoldingInformationApi from './KYC/RetirementAsset/ETFHoldingInformationApi';
import FundAllocationDetailApi from './KYC/RetirementAsset/FundAllocationDetailApi';
import InvestmentPlatformDetailApi from './KYC/RetirementAsset/InvestmentPlatformDetailApi';
import MandatoryProvidentFundApi from './KYC/RetirementAsset/MandatoryProvidentFundApi';
import OccupationalRetirementSchemeApi from './KYC/RetirementAsset/OccupationalRetirementSchemeApi';
import SharesHoldingInformationApi from './KYC/RetirementAsset/SharesHoldingInformationApi';
import SuperAnnuationFundApi from './KYC/RetirementAsset/SuperAnnuationFundApi';
import SuperAnnuationFundInsuranceApi from './KYC/RetirementAsset/SuperAnnuationFundInsuranceApi';
import TermDepositInformationApi from './KYC/RetirementAsset/TermDepositInformationApi';
import UnitTrustHoldingInformationApi from './KYC/RetirementAsset/UnitTrustHoldingInformationApi';
import EntityApi from './EntityApi';
import AdviserApi from './AdviserApi';
import DashboardApi from './DashboardApi';
import FileOperationApi from './FileOperationApi';
import CurrentStandingApi from './CurrentStanding/CurrentStandingApi';
import CashFlowApi from './KYC/CashFlowApi';
import InsuranceApi from "./InsuranceApi"

export {
    EstatePlanningApi,
    MotorVehicleApi,
    PersonalContentsApi,
    PersonalDetailsApi,
    PrimaryResidenceApi,
    NetWorthApi,
    BusinessOwnershipApi,
    BondsHoldingInformationApi,
    CashAccountInformationApi,
    ETFHoldingInformationApi,
    FundAllocationDetailApi,
    InvestmentPlatformDetailApi,
    MandatoryProvidentFundApi,
    OccupationalRetirementSchemeApi,
    SharesHoldingInformationApi,
    SuperAnnuationFundApi,
    SuperAnnuationFundInsuranceApi,
    TermDepositInformationApi,
    UnitTrustHoldingInformationApi,
    EntityApi,
    AdviserApi,
    DashboardApi,
    FileOperationApi,
    CurrentStandingApi,
    CashFlowApi,
    InsuranceApi,
};
