import { businessOwnershipAction } from '../../../constants/ActionConstants';

export function businessOwnership(state = {}, action) {
    switch (action.type) {
        case businessOwnershipAction.GET_BUSINESSOWNERSHIP_REQUEST:
            return {
                loading: true
            };
        case businessOwnershipAction.GET_BUSINESSOWNERSHIP_SUCCESS:
            return {
                items: action.data
            };
        case businessOwnershipAction.GET_BUSINESSOWNERSHIP_FAILURE:
            return {
                error: action.error
            };
        case businessOwnershipAction.ADD_BUSINESSOWNERSHIP_REQUEST:
            return {
                loading: true
            };
        case businessOwnershipAction.ADD_BUSINESSOWNERSHIP_SUCCESS:
            return {
                items: action.response
            };
        case businessOwnershipAction.ADD_BUSINESSOWNERSHIP_FAILURE:
            return {
                error: action.error
            };
        case businessOwnershipAction.EDIT_BUSINESSOWNERSHIP_REQUEST:
            return {
                loading: true
            };
        case businessOwnershipAction.EDIT_BUSINESSOWNERSHIP_SUCCESS:
            return {
                items: action.response
            };
        case businessOwnershipAction.EDIT_BUSINESSOWNERSHIP_FAILURE:
            return {
                error: action.error
            };
        case businessOwnershipAction.DELETE_BUSINESSOWNERSHIP_REQUEST:
            return {
                loading: true
            };
        case businessOwnershipAction.DELETE_BUSINESSOWNERSHIP_SUCCESS:
            return {
                items: action.response
            };
        case businessOwnershipAction.DELETE_BUSINESSOWNERSHIP_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
