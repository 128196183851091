import { superAnnuationFundInsuranceAction } from '../../../constants/ActionConstants';

export function superAnnuationFundInsurance(state = {}, action) {
    switch (action.type) {
        case superAnnuationFundInsuranceAction.GET_SFINSURANCE_REQUEST:
            return {
                loading: true
            };
        case superAnnuationFundInsuranceAction.GET_SFINSURANCE_SUCCESS:
            return {
                items: action.data
            };
        case superAnnuationFundInsuranceAction.GET_SFINSURANCE_FAILURE:
            return {
                error: action.error
            };
        case superAnnuationFundInsuranceAction.ADD_SFINSURANCE_REQUEST:
            return {
                loading: true
            };
        case superAnnuationFundInsuranceAction.ADD_SFINSURANCE_SUCCESS:
            return {
                items: action.response
            };
        case superAnnuationFundInsuranceAction.ADD_SFINSURANCE_FAILURE:
            return {
                error: action.error
            };
        case superAnnuationFundInsuranceAction.EDIT_SFINSURANCE_REQUEST:
            return {
                loading: true
            };
        case superAnnuationFundInsuranceAction.EDIT_SFINSURANCE_SUCCESS:
            return {
                items: action.response
            };
        case superAnnuationFundInsuranceAction.EDIT_SFINSURANCE_FAILURE:
            return {
                error: action.error
            };
        case superAnnuationFundInsuranceAction.DELETE_SFINSURANCE_REQUEST:
            return {
                loading: true
            };
        case superAnnuationFundInsuranceAction.DELETE_SFINSURANCE_SUCCESS:
            return {
                items: action.response
            };
        case superAnnuationFundInsuranceAction.DELETE_SFINSURANCE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
