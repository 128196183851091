import {cashAccountInformationAction} from '../../../constants/ActionConstants';

export function cashAccountInformation(state = {}, action) {
    switch (action.type) {
        case cashAccountInformationAction.GET_CASHACCOUNTINFORMATION_REQUEST:
            return {
                loading: true
            };
        case cashAccountInformationAction.GET_CASHACCOUNTINFORMATION_SUCCESS:
            return {
                items: action.data
            };
        case cashAccountInformationAction.GET_CASHACCOUNTINFORMATION_FAILURE:
            return {
                error: action.error
            };
        case cashAccountInformationAction.ADD_CASHACCOUNTINFORMATION_REQUEST:
            return {
                loading: true
            };
        case cashAccountInformationAction.ADD_CASHACCOUNTINFORMATION_SUCCESS:
            return {
                items: action.response
            };
        case cashAccountInformationAction.ADD_CASHACCOUNTINFORMATION_FAILURE:
            return {
                error: action.error
            };
        case cashAccountInformationAction.EDIT_CASHACCOUNTINFORMATION_REQUEST:
            return {
                loading: true
            };
        case cashAccountInformationAction.EDIT_CASHACCOUNTINFORMATION_SUCCESS:
            return {
                items: action.response
            };
        case cashAccountInformationAction.EDIT_CASHACCOUNTINFORMATION_FAILURE:
            return {
                error: action.error
            };
        case cashAccountInformationAction.DELETE_CASHACCOUNTINFORMATION_REQUEST:
            return {
                loading: true
            };
        case cashAccountInformationAction.DELETE_CASHACCOUNTINFORMATION_SUCCESS:
            return {
                items: action.response
            };
        case cashAccountInformationAction.DELETE_CASHACCOUNTINFORMATION_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}