import React, { useState, useEffect, useContext } from "react"
import { Form, Field, FormSpy } from "react-final-form"
import arrayMutators from "final-form-arrays"
import intl from "react-intl-universal"

import NetWorthFormButtons from "../../NetWorthFormButtons"
import ClassificationSelection from "../../../../components/Form/ClassificationSelection"
import { AppContext } from "../../../../context/AppContext"
import RenderSimpleFields, {
  FieldType
} from "../../../../components/Form/RenderSimpleFields"
import RetirementApi from "../../../../api/RetirementApi"
import FinancialRegulatorApi from "../../../../api/FinancialRegulatorApi"

import UsiDialog from "./UsiDialog"

import { Tabs, TabList, Tab, TabPanel } from "react-tabs"
import { InsuranceDetails } from "../../../../forms"

import InvestmentAllocation from "../../InvestmentAllocation"
import WithdrawalPlans from "../../ILASInvestment/WithdrawalPlans"
import { fileFieldRender } from "../../../../helpers"
import { history } from "../../../../helpers"

function SuperForm({
    clientId,
    values,
    mutators,
    client,
    optionsData
  }) {
    const [salaries, setSalaries] = useState("")
    const [selectedSalary, setSelectedSalary] = useState(
      salaries && values.incomeSourceId
        ? getSalaryById(values.incomeSourceId)
        : undefined
    )
    const [superFund, setSuperFund] = useState("")
    const [ruleParams, setRuleParams] = useState([])
    const [regulators, setRegulators] = useState("")
    const [birthday, setBirthday] = useState(
      client.personalDetails.personalInformation.birthDate
    )
  
    const {
      modal: { setModal, clearModal }
    } = useContext(AppContext)
    const rowClassName = "portal-form-row"
    const currency = "AUD"
    const cashflowUrl = `/cashflow/${clientId}/EmployementIncomeAndBenefit?currency=AUD`
    const frequencyType = [
      { label: "YEARLY", value: "YEARLY" },
      { label: "QUARTERLY", value: "QUARTERLY" }
    ]
  
    useEffect(() => {
      //get salary id
      RetirementApi.getSalary(clientId).then(({ data }) => {
        if (data) {
          setSalaries(data)
        }
      })
      RetirementApi.getSuperRules().then(({ data }) => {
        if (data) {
          setRuleParams(data.paramList)
  
          FinancialRegulatorApi.getAvaliableList(data.countryCode).then((res) => {
            setRegulators(res.data.list)
          })
        }
      })
    }, [])
  
    function getParamRule(searchKey) {
      let res
      if (ruleParams) {
        for (let index = 0; index < ruleParams.length; index++) {
          const r = ruleParams[index]
          if (r.key == searchKey) {
            res = r.value
            break
          }
        }
      }
      return res
    }
  
    function getSalaryOptions() {
      let options = []
      if (salaries) {
        options.push(<option key={"empty"} value={""}></option>)
        for (let index = 0; index < salaries.length; index++) {
          let s = salaries[index]
          options.push(
            <option key={s.id} value={s.id}>
              {s.employedWith}
            </option>
          )
        }
      }
      return options
    }
  
    function getSalaryById(salaryId) {
      let foundSalary
      if (salaryId && salaries) {
        for (let index = 0; salaries.length; index++) {
          let s = salaries[index]
          if (s.id == salaryId) {
            foundSalary = s
            break
          }
        }
      }
      return foundSalary
    }
  
    const onEmploymentIncomeSourceChanged = (salaryId) => {
      mutators.setFormAttribute("incomeSourceId", salaryId)
      mutators.setFormAttribute("retirementAge", 70)
      let foundSalary = getSalaryById(salaryId)
      setSelectedSalary(foundSalary)
  
      let sgRateParamValue = getParamRule("SG_RATE")
        ? parseFloat(getParamRule("SG_RATE"))
        : 0
      //console.log('sgRateParamValue : ', sgRateParamValue);
      mutators.setFormAttribute("employerContributionPercent", sgRateParamValue)
  
      let monthlyWages = 0
      let yearlyWages = 0
      let employerContrib = 0
      let monthlySalarySacrifice = 0
      let yearlySalarySacrifice = 0
      let joinedDate = ""
  
      if (foundSalary) {
        joinedDate = foundSalary.employmentStartDate
        monthlyWages = foundSalary.totalMonthly
          ? foundSalary.totalMonthly.value
          : 0
        yearlyWages = foundSalary.totalAnnually
          ? foundSalary.totalAnnually.value
          : 0
        employerContrib = (sgRateParamValue / 100) * yearlyWages
        monthlySalarySacrifice = foundSalary.salarySacrifice
          ? foundSalary.salarySacrifice.value
          : 0
        yearlySalarySacrifice = monthlySalarySacrifice * 12
      }
      mutators.setFormAttribute("joinedDate", joinedDate)
      mutators.setFormAttribute("monthlyWages", monthlyWages)
      mutators.setFormAttribute("yearlyWages", yearlyWages)
      mutators.setFormAttribute("salarySacrifice", monthlySalarySacrifice)
      mutators.setFormAttribute("yearlySalarySacrifice", yearlySalarySacrifice)
      mutators.setFormAttribute(
        "employerConcessionalContribution",
        employerContrib
      )
    }
  
    //callback
    const onDataUSISelected = (data) => {
      if (data) {
        setSuperFund(data)
        mutators.setFormAttribute("usi", data.usi)
        mutators.setFormAttribute("fundAbn", data.fundAbn)
        mutators.setFormAttribute("fundName", data.fundName)
        mutators.setFormAttribute("productName", data.productName)
        mutators.setFormAttribute(
          "contributionsRestriction",
          data.contributionRestrictions == "Y"
        )
      }
    }
  
    function openSearchUsiDialog(e) {
      e.preventDefault()
      setModal({
        title: `Select Superannuation Fund`,
        content: (
          <UsiDialog getData={onDataUSISelected} clearModal={clearModal} />
        ),
        isSmall: true
      })
    }
  
    function onNonConcessionalChecked(val) {
      let employerContributionPercent = values.employerContributionPercent
        ? values.employerContributionPercent
        : 0
      let yearlyWages = values.yearlyWages ? values.yearlyWages : 0
      let employerConcessionalContribution =
        (employerContributionPercent / 100) * yearlyWages
  
      mutators.setFormAttribute(
        "employerContributionPercent",
        employerContributionPercent
      )
      mutators.setFormAttribute(
        "employerConcessionalContribution",
        employerConcessionalContribution
      )
      mutators.setFormAttribute("employerExtraConcessionalContribution", 0)
    }
  
    function onYearlyWagesChanged(val) {
      mutators.setFormAttribute("yearlyWages", val)
  
      let yearlyWages = val ? parseFloat(val) : 0
      let employerPct = values.employerContributionPercent
        ? parseFloat(values.employerContributionPercent)
        : 0
      let newEmployerContrib =
        employerPct > 0 ? (employerPct / 100) * yearlyWages : 0
      mutators.setFormAttribute(
        "employerConcessionalContribution",
        newEmployerContrib
      )
    }
  
    function onEmployerContributionPercentChanged(val) {
      let yearlyWages = values.yearlyWages ? values.yearlyWages : 0
      let newEmployerContrib = yearlyWages > 0 ? (val / 100) * yearlyWages : 0
      mutators.setFormAttribute("employerContributionPercent", val)
      mutators.setFormAttribute(
        "employerConcessionalContribution",
        newEmployerContrib
      )
    }
  
    function calculateTotalEmployerContribution() {
      let total = 0
      let employerConcessionalContribution =
        values.employerConcessionalContribution
          ? parseFloat(values.employerConcessionalContribution)
          : 0
      let employerExtraConcessionalContribution =
        values.employerExtraConcessionalContribution
          ? parseFloat(values.employerExtraConcessionalContribution)
          : 0
      total =
        employerConcessionalContribution + employerExtraConcessionalContribution
      return total
    }
  
    function calculateTotalEmployeeContribution() {
      let total = 0
      let yearlySalarySacrifice = values.yearlySalarySacrifice
        ? parseFloat(values.yearlySalarySacrifice)
        : 0
      let employeeContribution = values.employeeContribution
        ? parseFloat(values.employeeContribution)
        : 0
      let employeeExtraContribution = values.employeeExtraContribution
        ? parseFloat(values.employeeExtraContribution)
        : 0
      total =
        yearlySalarySacrifice + employeeContribution + employeeExtraContribution
      return total
    }
  
    function calculateTotalContributions() {
      let total = 0
      let totalEmployer = calculateTotalEmployerContribution()
      let totalEmployee = calculateTotalEmployeeContribution()
      total = totalEmployer + totalEmployee
      return total
    }
  
    function calculateYearlyInsurancePremium() {
      let insuranceUnderSuper =
        values && values.insuranceUnderSuper ? values.insuranceUnderSuper : false
      let yearlyPremium = 0
      if (insuranceUnderSuper) {
        let premiumAmount =
          values.insuranceDetails && values.insuranceDetails.policyPremium
            ? parseFloat(values.insuranceDetails.policyPremium.value)
            : 0
        let premiumFreq =
          values.insuranceDetails &&
          values.insuranceDetails.policyPremiumFrequency
            ? values.insuranceDetails.policyPremiumFrequency
            : "MONTHLY"
  
        if (premiumFreq == "WEEKLY") {
          yearlyPremium = 52 * premiumAmount
        } else if (premiumFreq == "MONTHLY") {
          yearlyPremium = 12 * premiumAmount
        } else if (premiumFreq == "QUARTERLY") {
          yearlyPremium = 4 * premiumAmount
        } else if (premiumFreq == "HALF_YEARLY") {
          yearlyPremium = 2 * premiumAmount
        } else if (premiumFreq == "YEARLY") {
          yearlyPremium = premiumAmount
        } else if (premiumFreq == "ONE_OFF") {
          yearlyPremium = premiumAmount
        }
      }
      return yearlyPremium
    }
  
    function calculateTotalContributionsAfterInsurance() {
      let total = calculateTotalContributions()
      let yearlyPremium = calculateYearlyInsurancePremium()
      let totalAfter = total - yearlyPremium
      return totalAfter
    }
  
    const calculateAge = (birthday) => {
      const ageDifMs = Date.now() - new Date(birthday).getTime()
      const ageDate = new Date(ageDifMs)
      return Math.abs(ageDate.getUTCFullYear() - 1970)
    }
  
    function onInsuranceSelected(val) {
      if (!val) {
        mutators.setFormAttribute("insuranceDetails", undefined)
      }
    }
  
    return (
      <>
      <Tabs>
        <TabList>
          <Tab>Fund Information</Tab>
          <Tab>Employment Income</Tab>
          <Tab>Account Balances</Tab>
          <Tab>Contributions</Tab>
          <Tab>Investment</Tab>
          <Tab>Withdrawal</Tab>
  
          {values && values.insuranceUnderSuper && <Tab>Insurance Details</Tab>}
        </TabList>
        <TabPanel>
          <div className={rowClassName}>
            <label htmlFor="searchUsi">Select Super Fund</label>
            <div className="d-flex flex-column row-holder-full">
              <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <button
                  className="portal-btn portal-btn-submit"
                  onClick={(e) => openSearchUsiDialog(e)}
                >
                  ...
                </button>
              </div>
            </div>
          </div>
  
          <RenderSimpleFields
            fields={[
              {
                name: "usi",
                label: intl.get("field.superannuation.usi"),
                type: FieldType.TEXT
              },
              {
                name: "fundName",
                label: intl.get("field.superannuation.fundName"),
                type: FieldType.TEXT,
                required: true
              },
              {
                name: "fundAbn",
                label: intl.get("field.superannuation.fundAbn"),
                type: FieldType.TEXT
              },
              {
                name: "productName",
                label: intl.get("field.superannuation.productName"),
                type: FieldType.TEXT,
                required: true
              },
              {
                name: "contributionsRestriction",
                label: intl.get("field.superannuation.contributionRestriction"),
                type: FieldType.CHECKBOX,
                readOnly: true
              },
              {
                name: "fundManagerName",
                label: intl.get("field.superannuation.fundManagerName"),
                type: FieldType.TEXT
              },
              {
                name: "memberName",
                label: intl.get("field.superannuation.registeredMemberName"),
                type: FieldType.TEXT
              },
              {
                name: "financialRegulator",
                label: intl.get("field.superannuation.financialRegulator"),
                type: FieldType.SELECTION,
                selectionData: regulators,
                valueProperty: "id",
                labelProperty: "name"
              },
  
              {
                name: "selfManagedSuperFund",
                label: intl.get("field.superannuation.smsf"),
                type: FieldType.CHECKBOX,
                required: true
              },
  
              {
                name: "managedBy",
                label: intl.get("field.managedBy"),
                type: FieldType.ENUM_SELECTION,
                enumData: optionsData.managedByOptions,
                required: false
              },
              
            ]}
          />
          <Field
              component={fileFieldRender}
              customClassName="mt-1 d-block"
              labelIdle="Drag, Drop or Browse"
              label="Upload Documents"
              onupdatefiles={(fileItems) => {
                  mutators.setFormAttribute("proofDocument", fileItems[0])
              }}
              fileInformation={(values && values.proofDocument) || null}
          />
          
          
        </TabPanel>
        <TabPanel>
          <div className={rowClassName}>
            <label>{intl.get("field.employmentIncomeSource")}</label>
            <div className="d-flex flex-column row-holder">
              <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <select
                  className="select-box"
                  value={values.incomeSourceId}
                  onChange={(e) =>
                    onEmploymentIncomeSourceChanged(e.target.value)
                  }
                >
                  {getSalaryOptions()}
                </select>
                <button className="btn btn-plus" onClick={(e)=>{history.push(cashflowUrl)}}></button>
              </div>
            </div>
          </div>
          <div className={rowClassName}>
            <label>{intl.get("field.joinedDate")}</label>
            <div className="d-flex flex-column row-holder-full">
              <div>
                <input
                  className="text-input"
                  type="date"
                  value={values && values.joinedDate ? values.joinedDate : ""}
                  onChange={(e) =>
                    mutators.setFormAttribute("joinedDate", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
  
          <RenderSimpleFields
            fields={[
              {
                name: "currentAge",
                label: intl.get("field.superannuation.currentAge"),
                type: FieldType.TEXT,
                input: {
                  value: calculateAge(birthday)
                }
              },
              {
                name: "retirementAge",
                label: intl.get("field.superannuation.retirementAge"),
                type: FieldType.NUMBER
              },
  
              {
                name: "monthlyWages",
                label: intl.get("field.superannuation.monthlyWages"),
                type: FieldType.MONEY
              },
              {
                name: "salarySacrifice",
                label: intl.get("field.superannuation.monthlySalarySacrifice"),
                type: FieldType.MONEY
              }
            ]}
          />
        </TabPanel>
        <TabPanel>
          <RenderSimpleFields
            fields={[
              {
                name: "accountConcessionalBalance",
                label: "Account Concessional Balance",
                type: FieldType.MONEY
              },
              {
                name: "accountNonConcessionalBalance",
                label: "Account Non-Concessional Balance",
                type: FieldType.MONEY
              },
              {
                name: "accountRolloverBalance",
                label: "Account Rollovers Balance",
                type: FieldType.MONEY
              },
              {
                name: "accountInsurancePremiumsBalance",
                label: "Account Insurance Premiums Balance",
                type: FieldType.MONEY
              },
              {
                name: "accountPensionBalance",
                label: "Account Pension Balance",
                type: FieldType.MONEY
              },
              {
                name: "accountDefinedBenefitBalance",
                label: "Account Defined-Benefit Balance",
                type: FieldType.MONEY
              },
              {
                name: "valuationDate",
                label: intl.get("field.superannuation.balanceValuationDate"),
                type: FieldType.DATE,
                required: true
              },
             
            
            ]}
          />
        </TabPanel>
  
        <TabPanel>
          <div className="flexbox">
           
          
            <div className="portal-form-half">
              <RenderSimpleFields
                fields={[
                  {
                    name: "yearlyWages",
                    label: intl.get("field.superannuation.yearlyWages"),
                    type: FieldType.MONEY,
                    onChange: (val) => onYearlyWagesChanged(val),
                    required: true
                  },
                  {
                    name: "contributionFrequency",
                    label: intl.get(
                      "field.contributionFrequency"
                    ),
                    type: FieldType.SELECTION,
                    selectionData: frequencyType,
                    valueProperty: "value",
                    labelProperty: "label",
                    required: true
                  },
                  
                  {
                    name: "nonConcessional",
                    label: intl.get("field.superannuation.nonConcessional"),
                    type: FieldType.CHECKBOX,
                    onChange: (val) => onNonConcessionalChecked(val)
                  },
    
                  {
                    name: "employerContributionPercent",
                    label: intl.get("field.superannuation.employerContributionPct"),
                    type: FieldType.PERCENTAGE,
                    isRounded: false,
                    onChange: (val) => onEmployerContributionPercentChanged(val)
                  },
                  {
                    name: "employerConcessionalContribution",
                    label: intl.get(
                      "field.superannuation.employerContributionAmount"
                    ),
                    type: FieldType.MONEY,
                    readOnly: true,
                    disabled: true
                  },
                  {
                    name: "employerExtraConcessionalContribution",
                    label: intl.get(
                      "field.superannuation.employerExtraContribution"
                    ),
                    type: FieldType.MONEY
                  },
                 
                ]}
              />
            </div>
    
            <div className="portal-form-half" style={{marginLeft:"100px"}}>
              <RenderSimpleFields
                fields={[
                  {
                    name: "yearlySalarySacrifice",
                    label: intl.get("field.superannuation.yearlySalarySacrifice"),
                    type: FieldType.MONEY,
                    readOnly: true
                  },
                  {
                    name: "insuranceUnderSuper",
                    label: intl.get("field.insurance.useInsurance"),
                    type: FieldType.CHECKBOX,
                    onChange: (val) => onInsuranceSelected(val),
                    required: true
                  },
                  {
                    name: "employeeContribution",
                    label: intl.get("field.superannuation.employeeContribution"),
                    type: FieldType.MONEY
                  },
                  {
                    name: "employeeExtraContribution",
                    label: intl.get(
                      "field.superannuation.employeeExtraContribution"
                    ),
                    type: FieldType.MONEY
                  },
                  {
                    name: "spouseContribution",
                    label: "Spouse Contribution",
                    type: FieldType.MONEY
                  },
                  {
                    name: "governmentContribution",
                    label: "Government Contribution",
                    type: FieldType.MONEY
                  }
                ]}
              />
            </div>
         
            <span className="subtitle2">{intl.get("SectionType_SUMMARY")}</span>
  
            <div className="portal-form-half">
              <RenderSimpleFields
                fields={[
                  {
                    name: "employerTotalConcessionalContribution",
                    label: intl.get(
                      "field.superannuation.employerTotalContribution"
                    ),
                    type: FieldType.MONEY,
                    input: {
                      value: calculateTotalEmployerContribution()
                    },
                    readOnly: true,
                    disabled: true
                  },
  
                  {
                    name: "totalContributions",
                    label: "Yearly Contributions",
                    type: FieldType.MONEY,
                    input: {
                      value: calculateTotalContributions()
                    },
                    readOnly: true,
                    disabled: true
                  },
                  {
                    name: "totalContributionsAfterInsurance",
                    label: "Yearly Contributions After Insurance",
                    type: FieldType.MONEY,
                    input: {
                      value: calculateTotalContributionsAfterInsurance()
                    },
                    readOnly: true,
                    disabled: true
                  }
                ]}
              />
            </div>
  
            <div className="portal-form-half"  style={{marginLeft:"80px"}}>
              <RenderSimpleFields
                fields={[
                  {
                    name: "employeeTotalContribution",
                    label: intl.get(
                      "field.superannuation.employeeTotalContribution"
                    ),
                    type: FieldType.MONEY,
                    input: { value: calculateTotalEmployeeContribution() },
                    readOnly: true,
                    disabled: true
                  },
                  {
                    name: "yearlyInsurancePremiums",
                    label: "Yearly Insurance Premium",
                    type: FieldType.MONEY,
                    input: {
                      value: calculateYearlyInsurancePremium()
                    },
                    readOnly: true,
                    disabled: true
                  }
                ]}
              />
            </div>
          </div>
        </TabPanel>
  
        <TabPanel>
          <InvestmentAllocation
            currency={currency}
            mutators={mutators}
            addInvestmentAllocation={mutators.pushToArray}
            removeInvestmentAllocation={mutators.removeFromArray}
            optionsData={optionsData}
            values={values}
          />
          <div className="portal-form-half">
            {values.investmentAllocations &&
              values.investmentAllocations.length == 0 && (
                <RenderSimpleFields
                  fields={[
                    {
                      name: "annualReturnRate",
                      label: intl.get("field.superannuation.superAnnualReturn"),
                      type: FieldType.PERCENTAGE
                    }
                  ]}
                />
              )}
          </div>
        </TabPanel>
  
        <TabPanel>
          <WithdrawalPlans
            hasLockInPeriods={false}
            values={values}
            optionsData={optionsData}
            addPlan={mutators.pushToArray}
            removePlan={mutators.removeFromArray}
            setFormAttribute={mutators.setFormAttribute}
            currency={currency}
          />
        </TabPanel>
  
        {values && values.insuranceUnderSuper && (
          <TabPanel>
            <InsuranceDetails
              optionsData={optionsData}
              defaultCurrency={currency}
            />
            <RenderSimpleFields
              fields={[
                {
                  name: "insuranceDetails.deathCoverage",
                  label: intl.get("field.deathCoverageBenefit"),
                  type: FieldType.MONEY,
                  required: true
                },
                {
                  name: "insuranceDetails.tpdCoverage",
                  label: intl.get("field.totalDisabilityCoverageBenefit"),
                  type: FieldType.MONEY
                },
                {
                  name: "insuranceDetails.criticalIllnessCoverage",
                  label: intl.get("field.criticalIllnessCoverageBenefit"),
                  type: FieldType.MONEY
                },
                {
                  name: "insuranceDetails.incomeProtectionCoverage",
                  label: intl.get("field.insurance.incomeProtection"),
                  type: FieldType.MONEY
                }
              ]}
            />
          </TabPanel>
        )}
      </Tabs>
      </>
    )
  }

function SuperannuationForm({handleSave, model, client, partner, optionsData, readOnlyAccessComponent}){
 return (
    <Form
        onSubmit={handleSave}
        mutators={{
          pushToArray: arrayMutators.push,
          removeFromArray: arrayMutators.remove,
          updateArray: arrayMutators.update,
          setFormAttribute: ([fieldName, fieldVal], state, { changeValue }) => {
            changeValue(state, fieldName, () => fieldVal)
          }
        }}
        initialValues={model}
        keepDirtyOnReinitialize={true}
        render={({ handleSubmit, reset, values, mutators }) => (
          <form id="superannuationForm" onSubmit={handleSubmit}>
            <div className="portal-form-popup">
              <ClassificationSelection />
              <SuperForm
                clientId={client.id}
                optionsData={optionsData}
                values={values}
                mutators={mutators}
                client={client}
              />
            </div>

            <NetWorthFormButtons
              reset={reset}
              readOnlyAccess={false}
            />
          </form>
        )}
      />
 )
}
export default SuperannuationForm