/*
    Author : Erik Farhan Malik (erikfarhanmalik@gmail.com)
    Description : Mortgage details form
*/

import React from "react"
import intl from "react-intl-universal"
import { ReadOnlyCurrency } from "../../components"

const NetWorthListing = ({
  title,
  showForm,
  data,
  showAddButton = true,
  onDelete,
  hideDeleteButton = false,
  descriptionI18nPrefix,
  currency = "MYR"
}) => {
  const mappedData =
    Array.isArray(data) && data.length > 0
      ? data.map((item, index) => (
          <div className="portal-form-row" key={index}>
            {descriptionI18nPrefix && (
              <label>
                {intl.get(`${descriptionI18nPrefix}${item.description.trim()}`)}
              </label>
            )}

            {!descriptionI18nPrefix && <label>{item.description}</label>}
            <div className="row-holder text-grey text-right">
              {item.classification}
            </div>
            <div className="row-holder">
              <ReadOnlyCurrency value={item.assets} currency={currency} />
            </div>
            <div className="row-holder">
              <ReadOnlyCurrency
                value={item.liabilities * -1}
                additionalClass="c-red"
                currency={currency}
              />
            </div>
            <div className="row-holder">
              <ReadOnlyCurrency
                value={item.assets - item.liabilities}
                currency={currency}
              />
            </div>
            <span onClick={() => showForm(index)} className="btn btn-edit" />
            {!hideDeleteButton && (
              <span onClick={() => onDelete(index)} className="btn-remove" />
            )}
          </div>
        ))
      : []

  return (
    <React.Fragment>
      <div className="subtitle3">
        <label>
          <h3>{title}</h3>
        </label>
        {showAddButton && (
          <div className="btn-holder">
            <div className="btn-holder">
              <span onClick={() => showForm(null)} className="btn btn-plus" />
            </div>
          </div>
        )}
      </div>
      {mappedData}
    </React.Fragment>
  )
}

export default NetWorthListing
