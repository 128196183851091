/*
    Author : Marlina (marlina@devstack.com.au)
    Author : Erik (erik.malik@devstack.com.au)
    Description : Motor vehicle main page (modal)
*/

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import intl from 'react-intl-universal';
import { netWorthFormActions, modalWindowActions } from '../../actions';
import { modalWindow } from '../../constants/ModalWindowLists';
import NetWorthForm, {
    NetWorthPath,
    NetWorthFormPropsComposer,
} from './NetWorthForm';
import { MotorVehicleForm } from '../../forms';
import { adjustYearlyExpensesOnLoad } from '../../forms/sub-forms/CarExpense';
import { getCurrency } from '../../helpers';

class MotorVehicle extends NetWorthForm {
    constructor(props) {
        super(
            props,
            intl.get('motorVehicle.title'),
            modalWindow.MOTOR_VEHICLE_FORM,
            NetWorthPath.motorVehicles,
            'MotorVehicle'
        );
    }

    render() {
        const { optionsData, model, client, partner, isJoint, currency} = this.props;
        const { handleSave, contentWrapper, readOnlyAccessComponent } = this;
        const setDefaultData = originalModel => {
            
            if(client) {
                const { taxResidency } = client;
                const defaultCurrency = taxResidency ? getCurrency(taxResidency) : currency;
                const {
                    vehicleLocation,
                    currentValue,
                    vehicleExpenses = {},
                } = originalModel;

                if (!vehicleLocation) {
                    originalModel.vehicleLocation = taxResidency;
                }
                if (!currentValue) {
                    originalModel.currentValue = { currency: defaultCurrency };
                }

                if (originalModel.underHirePurchase) {
                    originalModel.carFinancing = 'HIRE_PURCHASE';
                } else if (originalModel.underLease) {
                    originalModel.carFinancing = 'CAR_LEASE';
                } else if (originalModel.underLoan) {
                    originalModel.carFinancing = 'LOAN';
                } else {
                    originalModel.carFinancing = 'NONE';
                }
                if (!vehicleExpenses.expensesCurrency) {
                    if (!originalModel.vehicleExpenses) {
                        originalModel.vehicleExpenses = {};
                    }
                    originalModel.vehicleExpenses.expensesCurrency = defaultCurrency;
                }

                if(originalModel.carFinancing == 'NONE') {
                    originalModel.loan = null;
                }

                return originalModel;
            }
            
        };
        return contentWrapper(
            <MotorVehicleForm
                handleSave={handleSave}
                model={setDefaultData(adjustYearlyExpensesOnLoad(model))}   
                readOnlyAccess={readOnlyAccessComponent()}
                clientInformation={{ client, partner, isJoint }}
                optionsData={optionsData}
            />
        );
    }
}

function mapStateToProps(state) {
    return NetWorthFormPropsComposer(
        state,
        modalWindow.MOTOR_VEHICLE_FORM,
        NetWorthPath.motorVehicles
    );
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        Object.assign({}, netWorthFormActions, modalWindowActions),
        dispatch
    );
}

MotorVehicle = connect(
    mapStateToProps,
    mapDispatchToProps
)(MotorVehicle);
export default MotorVehicle;
