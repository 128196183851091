import React from "react"
import { Field } from "react-final-form"
import {
  renderDateFieldCustom,
  renderTextBoxCustom,
  required,
  composeValidators,
  onlyAlphabetic
} from "../../../helpers"
import AutoSuggest from "../../../components/AutoSuggest/AutoSuggest"

import { isValidDate } from "../../..//helpers/Validator"
import { useSelector } from "react-redux"
function PairField({ field, classification, existingDataField }) {
  const { onlyRequiredFields } = useSelector((state) => state.users)
  const {
    clientFullNameFamilyNameLast,
    clientFullNameFamilyNameFirst,
    partnerFullNameFamilyNameLast,
    partnerFullNameFamilyNameFirst
  } = useSelector((state) => state.entity)

  const {
    name,
    label,
    component = renderTextBoxCustom,
    isRequired = false,
    disabled,
    existingData,
    isOnlyAlphabetic = false,
    showAlways = false,

    ...props
  } = field

  // if autosuggest components

  let propItemsClient = { ...props }
  if (component === AutoSuggest) {
    if (clientFullNameFamilyNameLast && clientFullNameFamilyNameFirst) {
      propItemsClient = {
        ...props,
        names: [clientFullNameFamilyNameLast, clientFullNameFamilyNameFirst]
      }
    } else {
      propItemsClient = {
        ...props,
        names: []
      }
    }
  }
  let propItemsPartner = { ...props }
  if (component === AutoSuggest) {
    if (partnerFullNameFamilyNameLast && partnerFullNameFamilyNameFirst) {
      propItemsPartner = {
        ...props,
        names: [partnerFullNameFamilyNameLast, partnerFullNameFamilyNameFirst]
      }
    } else {
      propItemsPartner = {
        ...props,
        names: []
      }
    }
  }
  return (
    <>
      {showAlways ? (
        <div
          className={`portal-form-row ${
            component === renderDateFieldCustom ? "dateformat" : ""
          }`}
        >
          <>
            <label>
              {label} {isRequired && <span>*</span>}
            </label>
            {existingDataField && (
              <Field
                name={`existingData.client.${existingDataField}`}
                isLabel={false}
                component={component}
                required={isRequired}
                disabled={true}
                validate={
                  component === renderDateFieldCustom
                    ? isRequired
                      ? composeValidators(required, isValidDate)
                      : composeValidators(isValidDate)
                    : isRequired
                    ? required
                    : undefined
                }
                {...propItemsClient}
              />
            )}
            <Field
              name={`client.${name}`}
              isLabel={false}
              component={component}
              required={isRequired}
              disabled={disabled}
              validate={
                component === renderDateFieldCustom
                  ? isRequired
                    ? composeValidators(required, isValidDate)
                    : composeValidators(isValidDate)
                  : isRequired && isOnlyAlphabetic
                  ? composeValidators(required, onlyAlphabetic)
                  : isRequired
                  ? required
                  : isOnlyAlphabetic
                  ? onlyAlphabetic
                  : undefined
              }
              {...propItemsClient}
            />
          </>
          {classification === "JOINT" && (
            <>
              {existingDataField && (
                <Field
                  name={`existingData.partner.${existingDataField}`}
                  isLabel={false}
                  component={component}
                  required={isRequired}
                  disabled={true}
                  validate={
                    component === renderDateFieldCustom
                      ? isRequired
                        ? composeValidators(required, isValidDate)
                        : composeValidators(isValidDate)
                      : isRequired
                      ? required
                      : undefined
                  }
                  type="partner"
                  {...propItemsClient}
                />
              )}
              <Field
                name={`partner.${name}`}
                isLabel={false}
                component={component}
                required={isRequired}
                disabled={disabled}
                validate={
                  component === renderDateFieldCustom
                    ? isRequired
                      ? composeValidators(required, isValidDate)
                      : composeValidators(isValidDate)
                    : isRequired
                    ? required
                    : undefined
                }
                type="partner"
                {...propItemsClient}
              />
            </>
          )}
        </div>
      ) : (
        ""
      )}
      {(!onlyRequiredFields || isRequired) && !showAlways ? (
        <>
          <div
            className={`portal-form-row ${
              component === renderDateFieldCustom ? "dateformat" : ""
            }`}
          >
            <>
              <label>
                {label} {isRequired && <span>*</span>}
              </label>
              {existingDataField && (
                <Field
                  name={`existingData.client.${existingDataField}`}
                  isLabel={false}
                  component={component}
                  required={isRequired}
                  disabled={true}
                  validate={
                    component === renderDateFieldCustom
                      ? isRequired
                        ? composeValidators(required, isValidDate)
                        : composeValidators(isValidDate)
                      : isRequired
                      ? required
                      : undefined
                  }
                  {...propItemsClient}
                />
              )}
              <Field
                name={`client.${name}`}
                isLabel={false}
                component={component}
                required={isRequired}
                disabled={disabled}
                validate={
                  component === renderDateFieldCustom
                    ? isRequired
                      ? composeValidators(required, isValidDate)
                      : composeValidators(isValidDate)
                    : isRequired && isOnlyAlphabetic
                    ? composeValidators(required, onlyAlphabetic)
                    : isRequired
                    ? required
                    : isOnlyAlphabetic
                    ? onlyAlphabetic
                    : undefined
                }
                {...propItemsClient}
              />
            </>

            {classification === "JOINT" && (
              <>
                {existingDataField && (
                  <Field
                    name={`existingData.partner.${existingDataField}`}
                    isLabel={false}
                    component={component}
                    required={isRequired}
                    disabled={true}
                    validate={
                      component === renderDateFieldCustom
                        ? isRequired
                          ? composeValidators(required, isValidDate)
                          : composeValidators(isValidDate)
                        : isRequired
                        ? required
                        : undefined
                    }
                    type="partner"
                    {...propItemsPartner}
                  />
                )}
                <Field
                  name={`partner.${name}`}
                  isLabel={false}
                  component={component}
                  required={isRequired}
                  disabled={disabled}
                  validate={
                    component === renderDateFieldCustom
                      ? isRequired
                        ? composeValidators(required, isValidDate)
                        : composeValidators(isValidDate)
                      : isRequired
                      ? required
                      : undefined
                  }
                  type="partner"
                  {...propItemsPartner}
                />
              </>
            )}
          </div>
        </>
      ) : (
        ""
      )}
    </>
  )
}

export default PairField
