/*
    Author : Marlina (marlina@devstack.com.au)
    Library : AdviserApi
    Description : methods related with adviser
*/

import api from "../lib/api";

// change base route for different adviser/domain here
const BASE_ROUTE = "/adviser/";

class AdviserApi {
    // get current adviser
    static getCurrentAdviser() {
        return api.get(`${BASE_ROUTE}current/`);
    }

    static getUserAdviserById(id) {
        return api.get(`${BASE_ROUTE}detail/${id}`);
    }

    static addAdviser(model) {
        return api.post(`${BASE_ROUTE}`, model);
    }

    static addAdviserAsChild(parentId, model) {
        return api.post(`${BASE_ROUTE}?parentId=${parentId}`, model);
    }

    static getAdviserById(id) {
        return api.get(`${BASE_ROUTE}${id}`);
    }

    static updateAdviserById(id, model) {
        return api.post(`${BASE_ROUTE}${id}`, model);
    }

    static uploadAdviserProfile(id, photo) {
        return api.post(`${BASE_ROUTE}${id}/photo`, photo);
    }
}

export default AdviserApi;
