import React, { useEffect, useState } from "react"
import {
  DefaultPieChartLegendsOptions,
  PieLegendsForcedLeftOptions
} from "../../../helpers/ChartHelper"
import { Doughnut } from "react-chartjs-2"

const RiskClassificationSummary = ({ summaryType, currency, totalSummary }) => {
  const emptyChartProps = {
    data: {
      labels: [""],
      datasets: [
        {
          data: [0.1],
          backgroundColor: ["#dadada"]
        }
      ]
    },

    options: {
      legend: {
        display: false,
        labels: {
          display: false
        }
      },
      tooltips: {
        enabled: false
      }
    },
    height: 400
  }
  const [doughnutData, setDoughnutData] = useState()

  useEffect(() => {
    let dataFiltered
    switch (summaryType) {
      case "riskSummary":
        dataFiltered =
          totalSummary && totalSummary["combined"].byRiskClassificationTypes
        break
      case "clientriskSummary":
        dataFiltered =
          totalSummary && totalSummary["client"].byRiskClassificationTypes
        break
      case "partnerriskSummary":
        dataFiltered =
          totalSummary && totalSummary["partner"].byRiskClassificationTypes
        break
      case "jointriskSummary":
        dataFiltered =
          totalSummary && totalSummary["joint"].byRiskClassificationTypes
        break
      default:
        // Code to execute if no cases match
        break
    }
    const chartData = {
      data: {
        labels:
          dataFiltered &&
          dataFiltered.length &&
          dataFiltered.map((item) => {
            return `${item.type.code} Risk Assessment`
          }),
        datasets: [
          {
            data:
              dataFiltered &&
              dataFiltered.length &&
              dataFiltered.map((item) => {
                return item.assets
              }),
            label: "Assets Risk Assessment",
            backgroundColor: ["#216082", "#E97132", "#376C25"]
          }
        ]
      }
    }
    setDoughnutData(chartData.data)
  }, [totalSummary, summaryType])

  const [allZero, setAllZero] = useState(true)
  useEffect(() => {
    if (doughnutData) {
      let allZeroValue =
        doughnutData &&
        doughnutData.datasets &&
        doughnutData.datasets[0].data.every((number) => number === 0)
      setAllZero(allZeroValue)
    } else {
      setAllZero(true)
    }
  }, [doughnutData])
  const [chartProps, setChartProps] = useState({
    data: {
      labels: [""],
      datasets: [
        {
          data: [0.1],
          backgroundColor: ["#dadada"]
        }
      ]
    },

    options: {
      legend: {
        display: false,
        labels: {
          display: false
        }
      },
      tooltips: {
        enabled: false
      }
    },
    height: 400
  })

  useEffect(() => {
    if (doughnutData) {
      let chartPropsItem = {
        data: doughnutData,
        options: {
          legend: {
            position: "bottom",
            // display: true,
            align: "start", //20200810 legend align to left
            fullWidth: true,
            // padding: 200
            //20200810 legend align to left
            ...DefaultPieChartLegendsOptions,
            ...PieLegendsForcedLeftOptions
          },
          maintainAspectRatio: false,
          tooltips: {
            enabled: true,
            callbacks: {
              // 20200717 MABT 359
              title: function (tooltipItem, data) {
                return data["labels"][tooltipItem[0]["index"]]
              },

              label: (tooltipItem, cData) => {
                const { index } = tooltipItem
                const { datasets } = cData
                const [dataset] = datasets
                const totalAssetsValue = dataset.data.reduce(
                  (accumulator, currentValue) => accumulator + currentValue,
                  0
                )
                const percentage =
                  ((dataset.data[index] || 0) / totalAssetsValue) * 100
                return `${Math.round(percentage * 100) / 100} %`
              }
            }
          },
          scales: {}
        },
        height: 400
      }
      setChartProps(chartPropsItem)
    }
  }, [doughnutData])
  return (
    <div>
      {allZero ? (
        <Doughnut {...{ ...emptyChartProps, height: 400 }} />
      ) : (
        <Doughnut {...{ ...chartProps, height: 400 }} />
      )}
    </div>
  )
}

export default RiskClassificationSummary
