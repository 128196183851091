import React from "react";
import { ResidencePropertyForm } from "../../../forms";
import { getSymbolFromCode } from "../../../helpers";

const CPFResidenceProperty = ({ optionsData, client, partner, isJoint, model = {}, handleSave }) => {
    const currency = 'SGD';
    const initModel = mdl => {
        let propertyDetails = mdl.propertyDetails || {};
        let propertyExpenses = mdl.propertyExpenses || {};
        let mortgage = mdl.mortgage || {};
        if (!propertyDetails.propertyLocation) {
            if (
                client.personalDetails &&
                client.personalDetails.employmentDetails &&
                client.personalDetails.employmentDetails.taxResidency
            ) {
                propertyDetails = {
                    ...propertyDetails,
                    ...{
                        propertyLocation: 'SG',
                    },
                };
            }
        }

        if (!propertyDetails.purchasePrice) {
            propertyDetails = {
                ...propertyDetails,
                ...{
                    purchasePrice: {
                        currency: currency,
                        value: null,
                    },
                },
            };
        }

        if (!propertyDetails.currentValue) {
            propertyDetails = {
                ...propertyDetails,
                ...{
                    currentValue: {
                        currency: currency,
                        value: null,
                    },
                },
            };
        }

        

        if (!propertyExpenses.expensesCurrency) {
            propertyExpenses = {
                ...propertyExpenses,
                ...{
                    expensesCurrency: currency,
                },
            };
        }
        if (!mortgage.borrowingsCurrency) {
            mortgage = {
                ...mortgage,
                ...{
                    borrowingsCurrency: currency,
                },
            };
        }

        return {
            ...mdl,
            ...{
                propertyDetails,
                propertyExpenses,
                mortgage,
            },
        };
    };
    return (

        <ResidencePropertyForm
            handleSave={handleSave}
            model={initModel(model)}
            clientInformation={{
                client, partner, isJoint
            }}
            isJoint={isJoint}
            optionsData={optionsData}
            currency={getSymbolFromCode(currency)}
        />

    )
}

export default CPFResidenceProperty;