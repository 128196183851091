import { isNullOrUndefined } from 'util';
import { getDirectVariableValue } from './GetDirectValue';
import { countFromArray } from './CountFromArray';
import { totalSum } from './TotalSum';

export const travelExpenses = (values, currency) => {
    if (!isNullOrUndefined(values)) {
        const { airTravel, hotel, rentalCarAndTaxi, vacation, others } = values;

        const model = {
            airTravel: getDirectVariableValue(airTravel, currency),
            hotel: getDirectVariableValue(hotel, currency),
            rentalCarAndTaxi: getDirectVariableValue(
                rentalCarAndTaxi,
                currency
            ),
            vacation: getDirectVariableValue(vacation, currency),
            others: countFromArray(others, currency),
        };

        return {
            clientTotal: totalSum(model, 'clientTotal'),
            partnerTotal: totalSum(model, 'partnerTotal'),
            jointTotal: totalSum(model, 'jointTotal'),
        };
    }
    return {
        clientTotal: 0,
        partnerTotal: 0,
        jointTotal: 0,
    };
};
