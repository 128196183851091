import { getNewCurrencyValue } from "./../helpers/CurrencyConversion"
const contextMenuItem = {
  // cashAccounts: "Cash Account",
  // termDeposits: 'Term Deposit',
  instruments: "Add Portfolio"
  // unitTrusts: 'Unit Trust',
  // bonds: 'Bond',
}

const createListingData = (model, reportingCurrency) => {
  const data = Object.keys(contextMenuItem).map((key, i) => {
    let mark = `${i}0`

    model[key] = model[key] || []
    return model[key].map((item, index) => {
      item.type = contextMenuItem[key]
      // item.index = key + index;    //20201006 africk
      item.index = index
      item.typeKey = key
      switch (key) {
        case "cashAccounts": {
          item.name = item.accountName
          item.value = getNewCurrencyValue(
            {
              currency: item.accountCurrency,
              value: Number(item.accountBalance) || 0
            },
            reportingCurrency
          )
          break
        }
        case "termDeposits": {
          item.name = item.accountNumber
          item.value = getNewCurrencyValue(
            {
              currency: item.accountCurrency,
              value: Number(item.accountBalance) || 0
            },
            reportingCurrency
          )
          break
        }
        case "instruments": {
          item.name = item.name
          item.value = getNewCurrencyValue(
            { value: item.currentPrice, currency: item.currency },
            reportingCurrency
          )
          break
        }
        case "unitTrusts": {
          item.name = item.unitTrustName
          item.value = getNewCurrencyValue(item.currentValue, reportingCurrency)
          break
        }
        case "bonds": {
          item.name = item.bondNumber
          item.value = getNewCurrencyValue(
            {
              currency: item.tradingCurrency,
              value: Number(item.faceValue) || 0
            },
            reportingCurrency
          )
          break
        }
        default: {
          // do nothing
        }
      }

      item.mark = `${mark}${index}`
      item.mark = item.mark == "000" ? 0 : item.mark /* 20201029 MABT-380 */

      return item
    })
  })

  return [].concat(...data)
}

export { contextMenuItem, createListingData }
