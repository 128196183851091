import React from 'react';

function SRSInvestmentPlatform(props) {
    return (
        <table className="table-investment" width="100%" cellSpacing="0" cellPadding="0" border="0">
            <tbody><tr>
                <th>Investment Platform Name</th>
                <th>Institution Name</th>
                <th>Account Number </th>
                <th>Account Start Date</th>
                <th>Account Balance</th>
                <th>Valuation Date </th>
                <th></th>
                <th><button type="button" className="btn btn-plus"></button></th>
            </tr>
                <tr>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td><button type="button" className="btn-remove"></button></td>
                    <td></td>
                </tr>


            </tbody></table >
    );
}

export default SRSInvestmentPlatform;