import React from "react";
import intl from "react-intl-universal";
import { Field } from "react-final-form";

import {
    greaterThan,
    greaterThanToday,
    required,
    isValidDate
} from "../../helpers/Validator";

import {
    renderTextBoxCustom,
    renderSelectEnumFieldCustom,
    renderMoneyCustom,
    renderDateFieldCustom,
    composeValidators,
    getSymbolFromCode
} from "../../helpers";

const PropertyInsuranceDetails = ({
    optionsData,
    insuranceType,
    objectName = "insuranceDetails",
    defaultCurrency,
    varPrefix,
    varPrefix2,
    isFireAndContentInsured,
    isLoanInsured,
    values
}) => (
    <div
        className={
            isFireAndContentInsured && isLoanInsured
                ? "pop-holder2col modal-wider"
                : "pop-holder"
        }
    >
        {/* Label Button */}
        <div className="content-panel-title content-panel-title-fireAndContentInsurance d-flex">
            {isFireAndContentInsured && (
                <div className="title-box">
                    {intl.get("field.fireAndContentInsurance")}
                </div>
            )}

            {/* Label Button */}
            {isLoanInsured && (
                <div className="title-box">
                    {intl.get("field.loanInsurance")}
                </div>
            )}
        </div>

        {[
             {
                name: "policyNumber",
                component: renderTextBoxCustom,
                isRequired: true
            },
            {
                name: "insuranceOrganization",
                component: renderTextBoxCustom,
                isRequired: false
            },
            {
                name: "policyName",
                component: renderTextBoxCustom,
                isRequired: false
            },
           
            {
                name: "insuredAmount",
                component: renderMoneyCustom,
                isFullWidth: false,
                isRequired: true,
                symbolPrefix: getSymbolFromCode(defaultCurrency),
                defaultCurrency
            },
            {
                name: "policyPremium",
                component: renderMoneyCustom,
                isFullWidth: false,
                isRequired: true,
                symbolPrefix: getSymbolFromCode(defaultCurrency),
                defaultCurrency: "AUD"
            },
            {
                name: "policyPremiumFrequency",
                component: renderSelectEnumFieldCustom,
                enumData: optionsData.frequencyOptions,
                isRequired: true
            },
            {
                name: "policyStartDate",
                component: renderDateFieldCustom,
                isRequired: true
            },
            {
                name: "policyMaturityDate",
                component: renderDateFieldCustom
            }
        ].map((item, index) => (
            <div
                className={`portal-form-row ${
                    item.component === renderDateFieldCustom ? "dateformat" : ""
                }`}
                key={index}
            >
                <label>
                    {intl.get(`field.${item.name}`)}
                    {item.isRequired && <span>*</span>}
                </label>
                {isFireAndContentInsured &&
                    (item.name === "policyMaturityDate" ? (
                        <Field
                            name={`${varPrefix}.${item.name}`}
                            component={item.component}
                            enumData={item.enumData}
                            required={item.isRequired}
                            isFullWidth={item.isFullWidth}
                            validate={composeValidators(
                                greaterThanToday,
                                isValidDate,
                                greaterThan(
                                    values.fireAndContentInsurance &&
                                        values.fireAndContentInsurance
                                            .policyStartDate
                                        ? values.fireAndContentInsurance
                                              .policyStartDate
                                        : null
                                )
                            )}
                        />
                    ) : (
                        <Field
                            name={`${varPrefix}.${item.name}`}
                            component={item.component}
                            enumData={item.enumData}
                            isFullWidth={item.isFullWidth}
                            required={item.isRequired}
                            validate={required}
                            symbolPrefix={getSymbolFromCode(defaultCurrency)}
                            defaultCurrency={defaultCurrency}
                        />
                    ))}

                {isLoanInsured &&
                    (item.name === "policyMaturityDate" ? (
                        <Field
                            name={`${varPrefix2}.${item.name}`}
                            component={item.component}
                            enumData={item.enumData}
                            isFullWidth={item.isFullWidth}
                            required={item.isRequired}
                            validate={composeValidators(
                                greaterThanToday,
                                greaterThan(
                                    values.fireAndContentInsurance &&
                                        values.fireAndContentInsurance
                                            .policyStartDate
                                        ? values.fireAndContentInsurance
                                              .policyStartDate
                                        : null
                                )
                            )}
                        />
                    ) : (
                        <Field
                            name={`${varPrefix2}.${item.name}`}
                            component={item.component}
                            enumData={item.enumData}
                            isFullWidth={item.isFullWidth}
                            required={item.isRequired}
                            validate={required}
                            symbolPrefix={getSymbolFromCode(defaultCurrency)}
                            defaultCurrency={defaultCurrency}
                        />
                    ))}
            </div>
        ))}
    </div>

);

export default PropertyInsuranceDetails;
