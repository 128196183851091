/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Description : GEAL new zealand page (modal)
*/

import React from 'react';
import intl from 'react-intl-universal';
import { Form } from 'react-final-form';

import {
    FormButtons,
    ClassificationSelection,
    ModalNoAccess,
} from '../components';
import RenderSimpleFields, {
    FieldType,
} from '../components/Form/RenderSimpleFields';

const GEALNewZealandForm = ({
    handleSave,
    handleChange,
    model,
    optionsData,
    readOnlyAccess,
    showClassification = true,
    currency = 'NZD',
}) =>
    !readOnlyAccess ? (
        <Form
            onSubmit={handleSave}
            initialValues={model}
            render={({ handleSubmit, reset }) => (
                <form id="newZealandGealForm" onSubmit={handleSubmit}>
                    <div className="portal-form-popup">
                    <div className="newZealandGealForm-content">

                            {/* show Joint */}
                            {/* {showClassification && (
                                <ClassificationSelection hideJoint />
                            )} */}
                            {showClassification && (
                                <ClassificationSelection />
                            )}

                            <div className="subtitle2">
                                {intl.get('geal.subtitle.modal', {
                                    countryDesc: 'New Zealand',
                                })}
                            </div>

                            <RenderSimpleFields
                                fields={[
                                    {
                                        name: 'educationAssistanceLoanType',
                                        label: intl.get(
                                            'field.loan.educationAssistanceLoanType'
                                        ),
                                        type: FieldType.ENUM_SELECTION,
                                        required: true,
                                        enumData:
                                            optionsData.educationAssistanceLoanTypeNZOptions,
                                    },
                                    {
                                        name: 'loanBalance',
                                        label: intl.get(
                                            'field.loan.loanBalance'
                                        ),
                                        type: FieldType.MONEY_WITH_CURRENCY,
                                        lockToCurrency: currency,
                                        required: true,
                                        allowNegative: false,
                                    },
                                    {
                                        name: 'loanStartDate',
                                        label: intl.get(
                                            'field.loan.loanStartDate'
                                        ),
                                        type: FieldType.DATE,
                                        required: true,
                                    },
                                    {
                                        name: 'valuationDate',
                                        label: intl.get(
                                            'field.loan.valuationDate'
                                        ),
                                        type: FieldType.DATE,
                                        required: true,
                                    },
                                    {
                                        name: 'repaymentStartDate',
                                        label: intl.get(
                                            'field.loan.repaymentStartDate'
                                        ),
                                        type: FieldType.DATE,
                                        required: true,
                                    },
                                    
                                    {
                                        name: 'loanAnnualInterestRate',
                                        label: intl.get(
                                            'field.loan.loanAnnualInterestRate'
                                        ),
                                        type: FieldType.PERCENTAGE,
                                    },
                                    {
                                        name: 'loanRepaymentFrequency',
                                        label: intl.get(
                                            'field.loan.repaymentFrequency'
                                        ),
                                        type: FieldType.ENUM_SELECTION,
                                        enumData: optionsData.frequencyOptions,
                                        required: true,
                                    },
                                    {
                                        name: 'loanRepaymentAmount',
                                        label: intl.get(
                                            'field.loan.loanRepaymentAmount'
                                        ),
                                        type: FieldType.MONEY_WITH_CURRENCY,
                                        lockToCurrency: currency,
                                        allowNegative: false,
                                        required: true,
                                    },
                                    {
                                        name: 'loanRepaymentStatus',
                                        label: intl.get(
                                            'field.loan.loanRepaymentStatus'
                                        ),
                                        type: FieldType.ENUM_SELECTION,
                                        enumData:
                                            optionsData.loanRepaymentStatusOptions,
                                    },
                                    {
                                        name: 'repaymentHolidayStartDate',
                                        label: intl.get(
                                            'field.loan.repaymentHolidayStartDate'
                                        ),
                                        type: FieldType.DATE,
                                    },
                                    {
                                        name: 'repaymentHolidayEndDate',
                                        label: intl.get(
                                            'field.loan.repaymentHolidayEndDate'
                                        ),
                                        type: FieldType.DATE,
                                    },
                                    
                                    {
                                        name: 'annualAdministrationFee',
                                        label: intl.get(
                                            'field.loan.annualAdministrationFee'
                                        ),
                                        type: FieldType.MONEY_WITH_CURRENCY,
                                        lockToCurrency: currency,
                                        allowNegative: false,
                                    },
                                ]}
                            />
                            <div className="subtitlerp">
                                <div className="subtitlerp2">
                                    {intl.get(
                                        `field.loan.additionalVoluntaryRepayments`
                                    )}
                                </div>
                            </div>
                            <div className="portal-form-row2 riskprofile-alert">
                                <span className="ico-inforeview" />{' '}
                                {intl.get(
                                    `field.loan.noteAdditionalVoluntaryRepayment`
                                )}
                            </div>
                            <RenderSimpleFields
                                fields={[
                                    {
                                        name: 'voluntaryRepaymentAmount',
                                        label: intl.get(
                                            'field.loan.loanRepaymentAmount'
                                        ),
                                        type: FieldType.MONEY_WITH_CURRENCY,
                                        lockToCurrency: currency,
                                        allowNegative: false,
                                    },
                                    {
                                        name: 'voluntaryRepaymentFrequency',
                                        label: intl.get(
                                            'field.loan.repaymentFrequency'
                                        ),
                                        type: FieldType.ENUM_SELECTION,
                                        enumData: optionsData.frequencyOptions,
                                    },
                                    {
                                        name: 'nextVoluntaryRepaymentDate',
                                        label: intl.get(
                                            'field.loan.nextRepaymentStartDate'
                                        ),
                                        type: FieldType.DATE,
                                    },
                                ]}
                            />
                            <div className="subtitlerp">
                                <div className="subtitlerp2">
                                    {intl.get('field.loan.taxation')}
                                </div>
                            </div>
                            <div className="portal-form-row">
                                {intl.get(
                                    'field.loan.forNewZealandBasedBorrowerOnly'
                                )}
                            </div>

                            <div className="portal-form-row2 riskprofile-alert">
                                <span className="ico-inforeview" />{' '}
                                {intl.get(
                                    'field.loan.note12PercentforNewZealandBasedBorrowerOnly'
                                )}
                            </div>
                            <RenderSimpleFields
                                fields={[
                                    {
                                        name: 'currentTaxWithheld',
                                        label: intl.get(
                                            'field.loan.currentTaxWithheldForCompulsoryRepayment'
                                        ),
                                        type: FieldType.MONEY_WITH_CURRENCY,
                                        lockToCurrency: currency,
                                        allowNegative: false,
                                    },
                                ]}
                            />
                        </div>
                    </div>

                    <FormButtons
                        reset={reset}
                        readOnlyAccess={readOnlyAccess}
                    />
                </form>
            )}
        />
    ) : (
        <ModalNoAccess />
    );

export default GEALNewZealandForm;
