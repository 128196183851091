/*
    Author : Erik (erik.malik@devstack.com.au)
    Description : Form buttons for networth form. Make it general for reusability
*/

import React from 'react';

const NetWorthFormHeader = ({ title, closeForm }) => (
    <div className="page-title">
        {title}
        <div className="close-holder">
            <span onClick={closeForm} className="close-button" />
        </div>
    </div>
);
export default NetWorthFormHeader;
