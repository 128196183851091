import React, { useState, useEffect } from "react"
import { Form } from "react-final-form"
import intl from "react-intl-universal"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { entityActions } from "../../actions/EntityActions"
import { modalWindowActions } from "../../actions/ModalWindowActions"
import { userActions } from "../../actions/UserActions"

import RenderSimpleFieldsBootrap, {
  FieldType
} from "../../components/Form/RenderSimpleFieldsBootstrap"

import { PersonalDetailsApi } from "../../api"
import { history } from "../../helpers"

function FormContainer({ model, readOnlyAccess, handleEdit, entityType }) {
  /* internalIdentifier */
  const operationInformationFields = [
    {
      name: "personalDetails.personalInformation.firstName",
      label: intl.get("field.firstName"),
      type: FieldType.TEXT,
      required: false,
      readOnly: true
    },
    {
      name: "personalDetails.personalInformation.middleName",
      label: intl.get("field.middleName"),
      type: FieldType.TEXT,
      readOnly: true
    },

    {
      name: "personalDetails.personalInformation.familyName",
      label: intl.get("field.familyName"),
      type: FieldType.TEXT,
      readOnly: true
    },
    {
      name: "personalDetails.preferredEmail.email",
      label: intl.get("field.prefEmail"),
      type: FieldType.TEXT,
      readOnly: true
    },
    {
      name: "personalDetails.preferredPhone.number",
      label: intl.get("field.prefPhoneNumber"),
      type: FieldType.PHONE_NUMBER_V2,
      readOnly: true,
      disabled: true
    },
    {
      name: "adviserName",
      label: intl.get("client.adviserName"),
      type: FieldType.TEXT,
      required: false,
      readOnly: true
    }
  ]

  return (
    <Form
      onSubmit={handleEdit}
      initialValues={model}
      mutators={{}}
      render={({ handleSubmit, reset, form: { mutators } }) => (
        <form
          id="bondForm"
          onSubmit={(event) => {
            handleSubmit(event).then(() => {
              reset()
            })
          }}
        >
          <div className="portal-form-popup">
            <div
              className="portal-form"
              style={{ overflowX: "hidden", height: "auto" }}
            >
              <div className="subtitle2b">Client Information</div>
              <RenderSimpleFieldsBootrap fields={operationInformationFields} />
            </div>
          </div>
          <div className="portal-btn-popup">
            {!readOnlyAccess && (
              <React.Fragment>
                <div className="text-right">
                  <input
                    className="portal-btn portal-btn-submit portal-btn-submit-mr-10"
                    value={"Edit"}
                    type="submit"
                  />
                </div>
              </React.Fragment>
            )}
          </div>
        </form>
      )}
    />
  )
}

function ClientProfile(props) {
  const id = localStorage.getItem("activeEntityId")

  const [model, setModel] = useState({})

  useEffect(() => {
    PersonalDetailsApi.get(id).then((response) => {
      setModel(response.data)
    })
  }, [id])

  const handleEdit = (values) => {
    history.push(`/personaldetails/${id}`)
    history.go(`/personaldetails/${id}`)
  }
  const { readOnlyAccess, entityType } = props
  return (
    <>
      <FormContainer
        {...{
          model,
          readOnlyAccess,
          handleEdit,
          entityType
        }}
      />
    </>
  )
}

function mapStateToProps(state) {
  return {
    entityType: state.modalWindow.entityType,
    activeAccessLevel: state.appSettings
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, entityActions, modalWindowActions, userActions),
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientProfile)
