/*
    Author : Marlina (marlina@devstack.com.au)
    Description : Reducer for feedback part
*/
import { feedbackAction } from "../constants/ActionConstants";

const initialState = {
    items: JSON.parse(localStorage.getItem("feedbacks"))
};
export function feedbacks(state = initialState, action) {
    switch (action.type) {
        case feedbackAction.GET_BY_TYPE_FEEDBACK_REQUEST:
            return {
                loading: true
            };
        case feedbackAction.GET_BY_TYPE_FEEDBACK_SUCCESS:
            return {
                ...state,
                loading: true,
                items: action.data
            };
        case feedbackAction.GET_BY_TYPE_FEEDBACK_FAILED:
            return {
                error: action.error
            };
        case feedbackAction.GET_BY_ENTITY_FEEDBACK_REQUEST:
            return {
                loading: true
            };
        case feedbackAction.GET_BY_ENTITY_FEEDBACK_SUCCESS:
            return {
                ...state,
                loading: true,
                items: action.data
            };
        case feedbackAction.GET_BY_ENTITY_FEEDBACK_FAILED:
            return {
                error: action.error
            };
        default:
            return state;
    }
}
