import React, { useContext } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';
import intl from 'react-intl-universal';

import { AppContext } from '../../../../context/AppContext';

import CashFlowListing from '../../CashFlowListing';
import CashFlowHeaderListing from '../../CashFlowHeaderListing';
import CashFlowListingGroup from '../../CashFlowListingGroup';
import { AdditionalArrayInput } from '../FieldArrayList';
import { HealthAndMedicalInsuranceForm } from '../../../Insurance/_form';

import api from '../../../../lib/api';
import Get from '../../../../helpers/NullHelper';

const HealthFitness = ({
    varPrefix,
    push,
    optionsData,
    clientTotalValue,
    partnerTotalValue,
    jointTotalValue,
    clientInformation,
    onExternalUpdated,
    isJointHide,
    currency,
    values,
}) => {
    const {
        modal: { setModal, clearModal },
    } = useContext(AppContext);

    const handleSave = a => {
        // save the model
        api.put(a.url, a).then(response => {
            onExternalUpdated(true);
        });

        clearModal();
    };

    return (
        <div className="portal-part">
            <Accordion accordion={false}>
                <AccordionItem>
                    <AccordionItemTitle>
                        <CashFlowHeaderListing
                            title={intl.get(
                                'cashflowlisting.personalExpenses.healthFitness'
                            )}
                            isAccordion
                            clientTotalValue={clientTotalValue}
                            partnerTotalValue={partnerTotalValue}
                            jointTotalValue={jointTotalValue}
                            isJointHide={isJointHide}
                            currency={currency}
                        />
                    </AccordionItemTitle>
                    <AccordionItemBody>
                        <CashFlowListingGroup
                            varPrefix={`${varPrefix}.healthInsurances`}
                            dataArray={Get(
                                values,
                                'detailResult.healthInsurances'
                            )}
                            isRoundColorRed
                            isExternalModal
                            isJointHide={isJointHide}
                            currency={currency}
                            onEditClick={model =>
                                setModal({
                                    title: intl.get(
                                        'cashflowlisting.personalExpenses.healthInsurance'
                                    ),
                                    formType: 'healthInsurance',
                                    content: (
                                        <HealthAndMedicalInsuranceForm
                                            onSubmit={handleSave}
                                            model={model}
                                            user={clientInformation}
                                            optionsData={optionsData}
                                        />
                                    ),
                                })
                            }
                        />
                        <CashFlowListing
                            varPrefix={`${varPrefix}.dentist`}
                            isOnlyTextTitle
                            isRoundColorRed={false}
                            title={intl.get(
                                'cashflowlisting.personalExpenses.dentist'
                            )}
                            isJointHide={isJointHide}
                            defaultDeductibleValue={0}
                            currency={currency}
                        />
                        <CashFlowListing
                            varPrefix={`${varPrefix}.doctor`}
                            isOnlyTextTitle
                            isRoundColorRed={false}
                            title={intl.get(
                                'cashflowlisting.personalExpenses.doctor'
                            )}
                            isJointHide={isJointHide}
                            defaultDeductibleValue={0}
                            currency={currency}
                        />
                        <CashFlowListing
                            varPrefix={`${varPrefix}.eyeCare`}
                            isOnlyTextTitle
                            isRoundColorRed={false}
                            title={intl.get(
                                'cashflowlisting.personalExpenses.eyeCare'
                            )}
                            isJointHide={isJointHide}
                            defaultDeductibleValue={0}
                            currency={currency}
                        />
                        <CashFlowListing
                            varPrefix={`${varPrefix}.gym`}
                            isOnlyTextTitle
                            isRoundColorRed={false}
                            title={intl.get(
                                'cashflowlisting.personalExpenses.gym'
                            )}
                            isJointHide={isJointHide}
                            defaultDeductibleValue={0}
                            currency={currency}
                        />
                        <CashFlowListing
                            varPrefix={`${varPrefix}.pharmacy`}
                            isOnlyTextTitle
                            isRoundColorRed={false}
                            title={intl.get(
                                'cashflowlisting.personalExpenses.pharmacy'
                            )}
                            isJointHide={isJointHide}
                            defaultDeductibleValue={0}
                            currency={currency}
                        />
                        <CashFlowListing
                            varPrefix={`${varPrefix}.sports`}
                            isOnlyTextTitle
                            isRoundColorRed={false}
                            title={intl.get(
                                'cashflowlisting.personalExpenses.sports'
                            )}
                            isJointHide={isJointHide}
                            defaultDeductibleValue={0}
                            currency={currency}
                        />
                        <CashFlowListing
                            varPrefix={`${varPrefix}`}
                            title={intl.get('cashflowlisting.others')}
                            isAdditionalInput
                            isJointHide={isJointHide}
                            isShowAddButton
                            push={push}
                            additionName={`${varPrefix}.others`}
                            defaultDeductibleValue={0}
                            currency={currency}
                        />
                        <AdditionalArrayInput
                            placeholderLabel={intl.get(
                                'cashflowlisting.pleaseSpecify'
                            )}
                            AdditionalInput
                            arrayName={`${varPrefix}.others`}
                            isJointHide={isJointHide}
                            currency={currency}
                        />
                    </AccordionItemBody>
                </AccordionItem>
            </Accordion>
        </div>
    );
};

export default HealthFitness;
