import { clientAction, insuranceAction } from "../../constants/ActionConstants"
import api from "../../lib/api"

export const insuranceActions = {
  getClient,
  toggleSection,
  getSectionData
}

function getClientSuccess(response) {
  return { type: clientAction.GET_CLIENT_SUCCESS, response }
}

function getClientWithPartnerSuccess(client, partner) {
  return {
    type: clientAction.GET_CLIENT_WITH_PARTNER_SUCCESS,
    client,
    partner
  }
}

function getPersonalInsuranceSuccess(personalInsurance) {
  return {
    type: insuranceAction.GET_PERSONAL_INSURANCE_SUCCESS,
    personalInsurance
  }
}
function getIlasInsuranceSuccess(ilasInsurance) {
  return { type: insuranceAction.GET_ILAS_INSURANCE_SUCCESS, ilasInsurance }
}
function getGeneralInsuranceSuccess(generalInsurance) {
  return {
    type: insuranceAction.GET_GENERAL_INSURANCE_SUCCESS,
    generalInsurance
  }
}

function toggleSection(sectionName) {
  return { type: insuranceAction.TOGGLE_SECTION, sectionName }
}

function getClient(id) {
  return async (dispatch) => {
    api.get(`/client/${id}`).then((getClientResponse) => {
      const client = getClientResponse.data
      if (getClientResponse.data.classification === "JOINT") {
        api
          .get(client._embedded.partner._links.self.href)
          .then((getPartnerResponse) => {
            dispatch(
              getClientWithPartnerSuccess(client, getPartnerResponse.data)
            )
          })
      } else {
        dispatch(getClientSuccess(getClientResponse))
      }
      // dispatch(getInsuranceDashboard(client));
    })
  }
}

function getSectionData(section, client) {
  switch (section) {
    case "personal": {
      return getPersonalInsurance(client)
    }
    case "ilas": {
      return getIlasInsurance(client)
    }
    case "general": {
      return getGeneralInsurance(client)
    }
    default: {
      return null
    }
  }
}

function getPersonalInsurance(client) {
  return (dispatch) => {
    api
      .get(client._embedded.personalInsuranceListing._links.self.href)
      .then((response) => {
        dispatch(getPersonalInsuranceSuccess(response.data))
      })
  }
}
function getIlasInsurance(client) {
  return (dispatch) => {
    api
      .get(client._embedded.ilasInsuranceListing._links.self.href)
      .then((response) => {
        dispatch(getIlasInsuranceSuccess(response.data))
      })
  }
}
function getGeneralInsurance(client) {
  return (dispatch) => {
    api
      .get(client._embedded.generalInsuranceListing._links.self.href)
      .then((response) => {
        dispatch(getGeneralInsuranceSuccess(response.data))
      })
  }
}
