import React from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { enumOptionsActions } from "../../../actions";
import { ClassificationSelection, FormButtons, RenderSimpleFields } from "../../../components";
import { FieldType } from "../../../components/Form/RenderSimpleFields";
import arrayMutators from 'final-form-arrays';
import intl from 'react-intl-universal';

const CPFBeneficary = ({ optionsData, mutators, model, handleSave }) => {

    const labelPrefix = 'netWorth.investmentDetails.beneficiaryDetails';

    return (
        <div>
            <Form
                onSubmit={handleSave}
                initialValues={model}
                mutators={{
                    // potentially other mutators could be merged here
                    ...arrayMutators,
                }}
                initialValuesEqual={() => true}
                render={({
                    handleSubmit,
                    reset,
                    values
                }) => (
                        <form
                            id="cpfCashAccount"
                            onSubmit={handleSubmit}
                            noValidate
                        >
                            <div className="portal-form-popup">
                                <ClassificationSelection />
                                <div className="subtitle2">
                                    Beneficary
                                </div>
                                <RenderSimpleFields
                                    fields={[
                                        {
                                            name: 'name',
                                            label: intl.get(`${labelPrefix}.name`),
                                            type: FieldType.TEXT,
                                            required: true,
                                        },
                                        {
                                            name: 'relationship',
                                            label: intl.get(`${labelPrefix}.relationship`),
                                            type: FieldType.ENUM_SELECTION,
                                            required: true,
                                            enumData: optionsData.relationshipTypeOptions,
                                        },
                                        {
                                            name: 'contactNumber',
                                            label: intl.get(`${labelPrefix}.contact`),
                                            type: FieldType.PHONE_NUMBER_SIMPLE,
                                        },
                                        {
                                            name: 'emailAddress',
                                            label: intl.get(`${labelPrefix}.email`),
                                            type: FieldType.EMAIL,
                                        },

                                    ]}
                                />
                            </div>
                            <FormButtons
                                reset={reset}
                            />
                        </form>
                    )}
            />
        </div>


    )
}


function mapStateToProps(state) {
    const { optionsData } = state.enumOptions;

    return {
        optionsData,
    };
}

function mapDispatchToProps(dispatch) {

    return bindActionCreators(enumOptionsActions, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CPFBeneficary);