import React, { useState, useEffect, useContext } from "react"
import intl from "react-intl-universal"
import InsuranceDashboard from "./_component/InsuranceDashboard"
import InsuranceCardWrap from "./_component/InsuranceCardWrap"
import { AppContext } from "../../context/AppContext"
import { InsurancePageActions } from "./InsurancePageActions"
import { ReportingCurrency } from "../../components"
import { getCurrency } from "../../helpers/CurrencyConversion"
import MalabarDefaultRightMenu from "../../components/MalabarMenu/MalabarRightMenu/MalabarDefaultRightMenu/MalabarDefaultRightMenu"
import { connect, useSelector } from "react-redux"

function TitleName({ client, partner }) {
  return (
    <div className="content-panel-title">
      <div className="row">
        {[client, partner, partner ? "joint" : ""].map((item, index) => {
          if (item) {
            return (
              <div className="col-md-4" key={index}>
                <div className="title-holder">
                  <div className="title-box">
                    {item === "joint" ? intl.get(`topmenu.${item}`) : item}
                  </div>
                </div>
              </div>
            )
          }

          return null
        })}
      </div>
    </div>
  )
}

function Section({
  insuranceData,
  section,
  isJoint,
  showForm,
  showConfirmationDialog,
  deleteInsurance,
  toggleSection,
  openedSection,
  currency,
  client,
  hrefs
}) {
  useEffect(() => {
    if (section) {
      toggleSection({
        section
      })
    }
  }, [section])

  return (
    <div
      className={`portal-part  ${
        client && client.status == "ARCHIVE" ? "personal-details-disabled" : ""
      }`}
    >
      <div
        className="accordion__title subtitle2"
        onClick={() =>
          toggleSection({
            section
          })
        }
      >
        {intl.get(`insurance.title.${section}`)}
        <div
          className={
            openedSection.includes(section) ? "acc-arrow-up" : "acc-arrow-down"
          }
        />
      </div>
      {openedSection.includes(section) && (
        <SectionContent
          {...{
            insuranceData,
            section,
            isJoint,
            showForm,
            showConfirmationDialog,
            deleteInsurance,
            currency
          }}
        />
      )}
    </div>
  )
}

function SectionContent({
  insuranceData,
  section,
  isJoint,
  showForm,
  showConfirmationDialog,
  deleteInsurance,
  currency
}) {
  return Object.keys(insuranceData[section]).map((subSection) => (
    <InsuranceCardWrap
      key={section + subSection}
      isJoint={isJoint}
      data={insuranceData[section][subSection]}
      showForm={({ id, insuranceType, objectType }) =>
        showForm({
          id,
          type: subSection,
          insuranceType,
          objectType,
          currency
        })
      }
      showDialog={showConfirmationDialog}
      deleteInsurance={({ id, insuranceType, objectType }) =>
        deleteInsurance({
          type: subSection,
          id,
          insuranceType,
          objectType
        })
      }
      currency={currency}
      type={subSection}
    />
  ))
}

function Insurance({ match, history, hrefs }) {
  const [state, setState] = useState({
    currency: "",
    showInsuranceSummary: true
  })

  const {
    modal: { setModal, showConfirmationDialog, clearModal }
  } = useContext(AppContext)

  const {
    client = {},
    partner = {},
    isJoint,
    openedSection = [],
    insurance,
    insuranceData,
    currency,
    showInsuranceSummary,
    savedForm
  } = state

  const {
    initializeStateOnCreated,
    toggleSection,
    constructData,
    showForm,
    deleteInsurance
  } = InsurancePageActions({ state, setState, setModal, clearModal, hrefs })

  const toggleInsuranceSummary = () => {
    setState((prevProps) => ({
      ...prevProps,
      showInsuranceSummary: !prevProps.showInsuranceSummary
    }))
  }

  useEffect(() => {
    initializeStateOnCreated({
      clientId: match.params.id
    })
  }, [match.params.id, savedForm])
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      insuranceData: constructData(insurance)
    }))
  }, [insurance])
  const { clientFullNamePreferred, partnerFullNamePreferred } = useSelector(
    (state) => state.entity
  )
  return (
    <div className="portal-maincontent portal-maincontent-insurance insurance ctrl-smallbtn">
      <div
        className={
          showInsuranceSummary
            ? "content-panel content-panel-ekyc-insurance"
            : "content-panel-grow"
        }
      >
        <div
          className="d-flex w-100"
          style={{ justifyContent: "space-between" }}
        >
          <div className="insurance-portal-content">
            <h1>{intl.get("insurance.title")}</h1>
            <ReportingCurrency
              onChange={(currencyVal) =>
                setState((prevState) => ({
                  ...prevState,
                  currency: currencyVal
                }))
              }
              currentCurrency={getCurrency(
                client && client.taxResidency ? client.taxResidency : "USD"
              )}
            />
            {isJoint}

            {/* form */}
            <div className="portal-form portal-form-ekyc-insurance">
              <TitleName
                client={clientFullNamePreferred}
                partner={partnerFullNamePreferred}
              />

              <div>
                {[
                  "personal",
                  "general",
                  "healthAndMedicalInsurances",
                  "ilas"
                ].map((section) => (
                  <Section
                    key={section}
                    {...{
                      insuranceData,
                      section,
                      isJoint,
                      showForm,
                      showConfirmationDialog,
                      deleteInsurance,
                      toggleSection,
                      openedSection,
                      currency,
                      client
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
          {/* grids */}
          <div className="insurance-graph-panel">
            <InsuranceDashboard
              client={client}
              currency={currency}
              insuranceData={insuranceData}
              showInsuranceSummary={showInsuranceSummary}
              toggleInsuranceSummary={toggleInsuranceSummary}
            />
            <div className=" d-flex justify-content-center " />
          </div>
          <MalabarDefaultRightMenu
            enableClearButton={false}
            enableSaveButton={false}
          />
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    hrefs: state.entity && state.entity.client && state.entity.client._embedded,
    currentStandingObject: state.currentStanding.clientCurrentStanding
  }
}

export default connect(mapStateToProps, null)(Insurance)
