import React, { } from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { enumOptionsActions } from "../../../actions";
import { FormButtons, RenderSimpleFields } from "../../../components";
import { FieldType } from "../../../components/Form/RenderSimpleFields";
import arrayMutators from 'final-form-arrays';
import CPFClassification from "./CPFClassification";

const CPFUnitTrust = ({ optionsData, model = {}, handleSave }) => {
    if (!model.tradingCurrency) {
        Object.assign(model, { tradingCurrency: 'SGD' })
    }
    return (
        <div>
            <Form
                onSubmit={handleSave}
                initialValues={model}
                mutators={{
                    // potentially other mutators could be merged here
                    ...arrayMutators,
                }}
                render={({
                    handleSubmit,
                    reset,
                    values
                }) => (
                        <form
                            id="cpfCashAccount"
                            onSubmit={handleSubmit}
                            noValidate
                        >
                            <div className="portal-form-popup">
                                <CPFClassification value={model.classification} disabled />
                                <div className="subtitle2">
                                    Unit Trust Details
                                </div>
                                <RenderSimpleFields
                                    fields={[
                                        {
                                            name: 'unitTrustIssuerName',
                                            label: 'Unit Trust Issuer Name',
                                            type: FieldType.TEXT,
                                        },
                                        {
                                            name: 'unitTrustName',
                                            label: 'Unit Trust Name',
                                            type: FieldType.TEXT,
                                        },
                                        {
                                            name: 'yearPurchased',
                                            label: 'Year Purchased',
                                            type: FieldType.NUMBER,
                                        },
                                        {
                                            name: 'numberOfUnitsHeld',
                                            label: 'Unit Held',
                                            type: FieldType.NUMBER,
                                        },
                                        {
                                            name: 'tradingCurrency',
                                            label: 'Trading Currency',
                                            type: FieldType.ENUM_SELECTION,
                                            enumData: optionsData.currencyOptions,
                                        },
                                        {
                                            name: 'costBase',
                                            label: 'Cost Base (Total)',
                                            type: FieldType.MONEY_WITH_CURRENCY,
                                            defaultCurrency: values.tradingCurrency,
                                        },
                                        {
                                            name: 'currentValue',
                                            label: 'Current Value (Total)',
                                            type: FieldType.MONEY,
                                            isMoney: true,
                                            currency: values.tradingCurrency,
                                        },
                                        {
                                            name: 'valuationDate',
                                            label: 'Valuation Date',
                                            type: FieldType.DATE,
                                        },
                                        {
                                            name: 'annualGrowthRate',
                                            label: 'Annual Growth Rate',
                                            type: FieldType.PERCENTAGE,
                                        },
                                        {
                                            name: 'annualDistributionYield',
                                            label: 'Annual Distribution Yield',
                                            type: FieldType.PERCENTAGE,
                                        },
                                        {
                                            name: 'distributionFrequency',
                                            label: 'Distribution Frequency',
                                            type: FieldType.ENUM_SELECTION,
                                            enumData: optionsData.frequencyOptions,
                                        },
                                        {
                                            name: 'lastDistributionPayment',
                                            label: 'Last Distribution Payment Date',
                                            type: FieldType.DATE,
                                        },
                                        {
                                            name: 'annualTotalReturn',
                                            label: 'Annual Total Return',
                                            type: FieldType.PERCENTAGE,
                                        }

                                    ]}
                                />

                                <div className="subtitle2">
                                    Fees
                                </div>
                                <RenderSimpleFields
                                    fields={[
                                        {
                                            name: 'managementFee',
                                            label: 'Management Fee',
                                            type: FieldType.MONEY_WITH_PERCENTAGE,
                                            getPercentageValueSource: () => values.currentValue,
                                            currency: values.tradingCurrency,
                                        },
                                        {
                                            name: 'managementFeeFrequency',
                                            label: 'Management Fee Frequency',
                                            type: FieldType.ENUM_SELECTION,
                                            enumData: optionsData.frequencyOptions,
                                        },
                                        {
                                            name: 'administrationFee',
                                            label: 'Administration Fee',
                                            type: FieldType.MONEY_WITH_PERCENTAGE,
                                            getPercentageValueSource: () => values.currentValue,
                                            currency: values.tradingCurrency,
                                        },
                                        {
                                            name: 'administrationFeeFrequency',
                                            label: 'Administration Fee Frequency',
                                            type: FieldType.ENUM_SELECTION,
                                            enumData: optionsData.frequencyOptions,
                                        },

                                    ]}
                                />
                            </div>
                            <FormButtons
                                reset={reset}
                            />
                        </form>
                    )}
            />
        </div>


    )
}


function mapStateToProps(state) {
    const { optionsData } = state.enumOptions;

    return {
        optionsData,
    };
}

function mapDispatchToProps(dispatch) {

    return bindActionCreators(enumOptionsActions, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CPFUnitTrust);