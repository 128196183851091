import ArrowMenu from "./ArrowMenu";
import { DefaultMenu } from "./PageMenu";
import { ModalDelete, ModalSimple, ModalNoAccess } from "./Modal";
import {
    SimpleHeader,
    SingleHeaderSubMenu,
    SingleHeaderNoMenu,
    CoupleHeaderSubMenu,
    CoupleHeaderNoMenu
} from "./PageHeader";
import MalabarCheckbox from "./MalabarCheckbox";
import MalabarDOBDate from "./MalabarDOBDate/MalabarDOBDate";
import MalabarDate from "./MalabarDate/MalabarDate";
import MalabarMonthYearAge from "./MalabarMonthYearAge";
import MalabarYearAge from "./MalabarYearAge";
import MalabarEmail from "./MalabarEmail";
import MalabarGrid from "./MalabarGrid";
import MalabarPhoneNumber from "./MalabarPhoneNumber";
import Identity from "./Identity";
import MalabarPhoneNumberSimple from "./MalabarPhoneNumberSimple";
import MalabarMoney from "./MalabarMoney";
import MalabarMoneyCashflow from "./MalabarMoney/MalabarMoneyCashflow";
import {
    MalabarTopMenu,
    MalabarDefaultRightMenu,
    MalabarClientRightMenu,
    MalabarDashboardRightMenu,
    JointSelectionMenu,
    FeedbackSelectionMenu
} from "./MalabarMenu";
import ScrollUpButton from "./ScrollUpButton";
import Logout from "./Logout";
import ReadOnlyCurrency from "./ReadOnlyCurrency";
import PieLegend from "./PieLegend";
import RadialIndicator from "./RadialIndicator";
import ArrowIndicator from "./ArrowIndicator";
import ReportingCurrency from "./ReportingCurrency";
// dashboard components
import AlphabetSearch from "./DashboardElement/AlphabetSearch";
import BoxUser from "./DashboardElement/BoxUser";
import TeamBoxUser from "./DashboardElement/TeamBoxUser";
import BoxClient from "./DashboardElement/BoxClient";
import TaskUser from "./DashboardElement/TaskUser";
import GroupedBoxUser from "./DashboardElement/GroupedBoxUser";
import GroupedOverviewBoxes from "./DashboardElement/GroupedOverviewBoxes";
import NameBox from "./DashboardElement/NameBox";
import OverviewBox from "./DashboardElement/OverviewBox";
import TopPeopleBox from "./DashboardElement/TopPeopleBox";
import TableCustom from "./DashboardElement/TableCustom";
import FeedbackTitle from "./DashboardElement/FeedbackTitle";
import FeedbackTitleBox from "./DashboardElement/FeedbackTitleBox";
import Scorecard from "./DashboardElement/Scorecard";
import ConfirmationDialog from "./ConfirmationDialog";
import RenderSimpleFields from "./Form/RenderSimpleFields";
import FormButtons from "./Form/FormButtons";
import ClassificationSelection from "./Form/ClassificationSelection";
// charts
import LifelineChart from "./LifelineChart/LifelineChart";
import GaugeRadialChart from "./GaugeRadialChart/GaugeRadialChart";
import Textbox from "./Textbox/Textbox";
import Textarea from "./Textarea/Textarea";
import InputFile from "./InputFile/InputFile";

export {
    // arrow menu
    ArrowMenu,
    // side menu
    // upper menu
    DefaultMenu,
    // page header
    SimpleHeader,
    SingleHeaderSubMenu,
    SingleHeaderNoMenu,
    CoupleHeaderSubMenu,
    CoupleHeaderNoMenu,
    // checkbox
    MalabarCheckbox,
    // date
    MalabarDate,
    MalabarDOBDate,
    MalabarMonthYearAge,
    MalabarYearAge,
    // email,
    MalabarEmail,
    // forms
    RenderSimpleFields,
    FormButtons,
    ClassificationSelection,
    ReportingCurrency,
    // grid
    MalabarGrid,
    // phone number
    MalabarPhoneNumber,
    Identity,
    MalabarPhoneNumberSimple,
    // money
    MalabarMoney,
    MalabarMoneyCashflow,
    // modals
    ModalDelete,
    ModalSimple,
    ModalNoAccess,
    // menu
    MalabarTopMenu,
    MalabarDefaultRightMenu,
    MalabarClientRightMenu,
    MalabarDashboardRightMenu,
    JointSelectionMenu,
    FeedbackSelectionMenu,
    // scroll up button
    ScrollUpButton,
    // logout
    Logout,
    ReadOnlyCurrency,
    // chart related
    PieLegend,
    RadialIndicator,
    ArrowIndicator,
    // dashboard
    AlphabetSearch,
    BoxUser,
    BoxClient,
    TeamBoxUser,
    TaskUser,
    GroupedBoxUser,
    GroupedOverviewBoxes,
    NameBox,
    OverviewBox,
    TopPeopleBox,
    TableCustom,
    FeedbackTitle,
    FeedbackTitleBox,
    Scorecard,
    ConfirmationDialog,
    // charts
    LifelineChart,
    GaugeRadialChart,
    Textbox,
    Textarea,
    InputFile
};
