import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { Form, Field } from 'react-final-form';
import { ModalNoAccess, RenderSimpleFields, FormButtons, ClassificationSelection } from '../components';
import { FieldType } from '../components/Form/RenderSimpleFields';
import { renderJointSelectionMenuCustom, getSymbolFromCode } from '../helpers';

const CreditCardForm = ({
    handleSave,
    model,
    optionsData,
    readOnlyAccess,
    clientInformation,
}) => {
    const [currency, setCurrency] = useState(getSymbolFromCode(model.creditCardCurrency || 'MYR'))
    return !readOnlyAccess ? (
      <Form
        onSubmit={handleSave}
        initialValues={model}
        render={({ handleSubmit, reset }) => (
          <form id="creditCardForm" onSubmit={handleSubmit}>
            <div className="portal-form-popup">
              <ClassificationSelection />

              <div className="subtitle2">
                {intl.get("liabilities.creditCard.subtitle")}
              </div>
              <RenderSimpleFields
                fields={[
                  {
                    name: "creditCardName",
                    label: intl.get("liabilities.creditCard.creditCardName"),
                    type: FieldType.TEXT,
                    required: true
                  },
                  {
                    name: "creditCardIssuer",
                    label: intl.get("liabilities.creditCard.creditCardIssuer"),
                    type: FieldType.TEXT
                  },

                  {
                    name: "creditCardType",
                    label: intl.get("liabilities.creditCard.creditCardType"),
                    type: FieldType.TEXT
                  },
                  {
                    name: "creditCardCurrency",
                    label: intl.get(
                      "liabilities.creditCard.creditCardCurrency"
                    ),
                    type: FieldType.ENUM_SELECTION,
                    enumData: optionsData.currencyOptions,
                    onChange: (val) => setCurrency(getSymbolFromCode(val))
                  },
                  {
                    name: "creditCardCurrentLoanBalance",
                    label: intl.get(
                      "liabilities.creditCard.creditCardCurrentLoanBalance"
                    ),
                    required: true,
                    type: FieldType.MONEY,
                    symbolPrefix: currency,
                    allowNegative: false
                  },
                  {
                    name: "valuationDate",
                    label: intl.get("liabilities.creditCard.valuationDate"),
                    type: FieldType.DATE
                  },
                  {
                    name: "creditCardAnnualInterestRate",
                    label: intl.get(
                      "liabilities.creditCard.creditCardAnnualInterestRate"
                    ),
                    type: FieldType.PERCENTAGE
                  },
                  {
                    name: "creditCardRepaymentAmount",
                    label: intl.get(
                      "liabilities.creditCard.creditCardRepaymentAmount"
                    ),
                    type: FieldType.MONEY,
                    symbolPrefix: currency,
                    allowNegative: false,
                    readOnly: true
                  },
                  {
                    name: "creditCardLoanRepaymentFrequency",
                    label: intl.get(
                      "liabilities.creditCard.creditCardLoanRepaymentFrequency"
                    ),
                    type: FieldType.ENUM_SELECTION,
                    enumData: optionsData.frequencyOptions
                  },
                  {
                    name: "creditCardLoanNextRepaymentDate",
                    label: intl.get(
                      "liabilities.creditCard.creditCardLoanNextRepaymentDate"
                    ),
                    type: FieldType.DATE
                  }
                ]}
              />
            </div>
            <FormButtons reset={reset} readOnlyAccess={readOnlyAccess} />
          </form>
        )}
      />
    ) : (
      <ModalNoAccess />
    )
}

export default CreditCardForm;
