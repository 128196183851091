import React, { useState } from "react"

import intl from "react-intl-universal"
import NumberFormat from "react-number-format"
import { MalabarDate } from "../../components"
import RenderSimpleFields, {
  FieldType
} from "../../components/Form/RenderSimpleFields"
import { Form } from "react-final-form"
import { useSelector } from "react-redux"

function SuperAnnuationInvestmentDialog({
  parentId,
  doAdd,
  clearModal,
  defaultCurrency
}) {
  const { optionsData } = useSelector((state) => state.enumOptions)
  const handleSave = (data) => {
    let updated = {
        ...data,
        allocationRefId: parentId,
        currency: defaultCurrency,
        currentGrowthValue: getGrowthPrice(data),
        currentGrowthPercent: getGrowthPercent(data),
        currentValue: getCurrentValue(data)
    }
 
    doAdd(updated);
    clearModal()
  }

  function getGrowthPrice(values){
     return (values.currentPricePerUnit - values.purchasePricePerUnit);
  }

  function getGrowthPercent(values){
      return getGrowthPrice(values) / values.purchasePricePerUnit * 100
  }

  function getCurrentValue(values){
      return (values.currentPricePerUnit * values.numberOfUnit)
  }

 
  return (
    <div className="portal-form-popup SuperAnnuationDialog super-annuation-fund">
      
      <Form
        onSubmit={handleSave}
        mutators={{
          setFormAttribute: ([fieldName, fieldVal], state, { changeValue }) => {
            changeValue(state, fieldName, () => fieldVal)
          },
         
        }}
        render={({ handleSubmit, reset, values, mutators }) => (
          <form id="superannuationFund" onSubmit={handleSubmit}>
            <div className="portal-form-popup">
              <div className="portal-form-half ">
                <RenderSimpleFields fields={[
                  {
                    name: "platformName",
                    label: intl.get("field.investment.PlatformName"),
                    type: FieldType.TEXT,
                    required: true
                  },
                  {
                    name: "productName",
                    label: intl.get("field.investment.ProductName"),
                    required: true,
                    type: FieldType.TEXT
                  },
                  {
                    name: "startDate",
                    label: intl.get("field.startDate"),
                    type: FieldType.DATE
                  },
                  {
                    name: "endDate",
                    label: intl.get("field.endDate"),
                    type: FieldType.DATE
                  },
                
                ]} />
              </div>
              <div className="portal-form-half ">
                <RenderSimpleFields fields={[
                    
                    {
                      name: "numberOfUnit",
                      label: intl.get("field.investment.NumberofUnit"),
                      type: FieldType.NUMBER,
                      required: true,
                      
                    },
                    {
                      name: "purchasePricePerUnit",
                      label: intl.get("field.investment.PurchasePriceValue"),
                      type: FieldType.MONEY,
                      required: true,
                      
                    },
                    {
                      name: "currentPricePerUnit",
                      label: intl.get("field.investment.CurrentPriceValue"),
                      type: FieldType.MONEY,
                      required: true,
                      
                    },
                    {
                      name: "currentGrowthValue",
                      label: intl.get("field.investment.CurrentGrowth"),
                      type: FieldType.MONEY,
                      isDisabled: true,
                      input:{
                        value: getGrowthPrice(values)
                      }
                    },
                    
                    {
                      name: "currentGrowthPercent",
                      label: "Profit/Loss (%)",
                      type: FieldType.MONEY,
                      symbolPrefix: "",
                      isDisabled: true,
                      input:{
                        value: getGrowthPercent(values)
                      }
                    },
                    {
                      name: "currentValue",
                      label: intl.get("field.currentValue"),
                      type: FieldType.MONEY,
                      isDisabled: true,
                      input:{
                        value: getCurrentValue(values)
                      }

                    },
                    {
                      name: "valuationDate",
                      label: intl.get("field.investment.ValuationDate"),
                      type: FieldType.DATE,
                      required: true
                    },
                  ]}
                 />
                <div className="investment-button col-half">
                  <button
                    className="portal-btn portal-btn-submit"
                    onClick={(e)=>handleSubmit(values)}   
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      />
    </div>
  )
}
export default SuperAnnuationInvestmentDialog
