import React, { useState } from "react"
import Autosuggest from "react-autosuggest"
import "./AutoSuggest.css"

function AutoSuggest({
  meta: { touched, error },
  input,
  names = [],
  isPortalFormRowType,
  label,
  required = false
}) {
  const [suggestions, setSuggestions] = useState(names)
  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length

    return inputLength === 0
      ? suggestions
      : names.filter(
          (lang) => lang.toLowerCase().slice(0, inputLength) === inputValue
        )
  }

  const getSuggestionValue = (suggestion) => {
    return suggestion
  }

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value))
  }

  const renderSuggestion = (suggestion) => <div>{suggestion}</div>

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions(names)
  }
  const shouldRenderSuggestions = (value, reason) => {
    return value.trim().length > 0 || value.trim().length === 0
  }
  const onChange = (event, { newValue }) => {
    input.onChange(newValue)
  }

  const inputProps = {
    name: input.name,
    value: input.value,
    onChange
  }

  return !isPortalFormRowType ? (
    <div
      className={`d-flex flex-column row-holder  ${
        touched && error ? "itemvalidate bad" : ""
      }`}
    >
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        shouldRenderSuggestions={shouldRenderSuggestions}
      />

      {touched && error && <span className="alert">{error}</span>}
    </div>
  ) : (
    <div className="portal-form-row">
      <label for={input.name}>
        {label}
        {required && <span>*</span>}
      </label>

      <div
        className={`d-flex flex-column row-holder  ${
          touched && error ? "itemvalidate bad" : ""
        }`}
      >
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          shouldRenderSuggestions={shouldRenderSuggestions}
        />

        {touched && error && <span className="alert">{error}</span>}
      </div>
    </div>
  )
}

export default AutoSuggest
