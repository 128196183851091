import React, { useState, useEffect, useContext } from "react"
import {
  getClientIdSuccess,
  getPersonalDetailsSuccess,
  toggleFieldsVisibility
} from "../../../actions"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { AppContext } from "../../../context/AppContext"
import Api from "../../../lib/api"
import intl from "react-intl-universal"
const FieldToggle = () => {
  const {
    modal: { setModal, clearModal }
  } = useContext(AppContext)

  const { onlyRequiredFields } = useSelector((state) => state.users)
  const { client, partner, hasPartner, clientId } = useSelector(
    (state) => state.entity
  )
  const dispatch = useDispatch()
  const [showOnlyRequired, setShowOnlyRequired] = useState(false)

  const submitHandler = async () => {
    dispatch(toggleFieldsVisibility(showOnlyRequired))
    clearModal()
    try {
      const res = await Api.post(`/client/minimal-flag`, {
        clientId: clientId,
        flagMinimal: showOnlyRequired
      })
    } catch (error) {
      console.log("error", error)
    }
  }
  useEffect(() => {
    setShowOnlyRequired(onlyRequiredFields)
  }, [onlyRequiredFields])

  return (
    <form
      className="popup portal-form-feedback"
      style={{ overflow: "hidden", height: "auto" }}
    >
      <div className="d-flex align-items-center">
        <p>{intl.get("minimal.ask")}</p>
        <div className="input-check input-check-alert ml-3">
          <input
            type="checkbox"
            checked={showOnlyRequired}
            value={showOnlyRequired}
          />
          <label
            onClick={() => {
              setShowOnlyRequired(!showOnlyRequired)
            }}
            htmlFor="field-toggle"
          ></label>
        </div>
      </div>
      <p className="mt-2">
        <span className="font-weight-bold subtitle2 mr-2">
          {intl.get("minimal.note")}
        </span>
        {intl.get("minimal.noteFirst")}
        <br />
        {intl.get("minimal.noteSecond")}
      </p>
      <div className="portal-btn-popup ">
        <input
          className="portal-btn d-block portal-btn-s portal-btn-submit ml-auto"
          type="submit"
          value={intl.get("button.submit")}
          onClick={(e) => {
            e.preventDefault()
            submitHandler()
          }}
        />
      </div>
    </form>
  )
}

export default FieldToggle
