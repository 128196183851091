import React from 'react';
import './InputFile.css';
function InputFile({
    input,
    meta,
    onChange
}) {
    return (
        <div
            className={`${meta.touched && meta.error ? 'itemvalidate bad' : ''}`}
        >
            <input
                {...input}
                type="file"
                className="input-file"
                value={undefined}
                onChange={(e) => {
                    input.onChange(e.target.files[0]); //final-form's onChange
                    if (onChange) {
                        onChange(e);
                    }
                    e.target.value = '';
                }}
            />
            {
                meta.touched &&
                (meta.error && <span className="alert">{meta.error}</span>)
            }
        </div >
    )
}

export default InputFile
