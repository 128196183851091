import React, { useState } from "react"
import { getSymbolFromCode } from "../../helpers";
import { formatMoney } from "../../helpers/CommonHelper";
import { convertToLocalDateDefault } from "../../helpers/DateHelper";

function LoanSimulationDialog({loan, ccy, inArrears}) {
  const rowClassName = "portal-form-row";
  const DECIMAL_POINT = 2;
  const [ccySymbol, setCcySymbol] = useState(getSymbolFromCode(ccy))
  
 
  function renderRepayments(){
    let rows = [];
    if(loan && loan.uniqueRepaymentAmounts){
       for (let index = 0; index < loan.uniqueRepaymentAmounts.length; index++) {
         let r = loan.uniqueRepaymentAmounts[index];
         rows.push(
           <tr >
             <td style={{width:"10%", textAlign:"center"}}>{r.strPeriods}</td>
             <td style={{width:"20%", textAlign:"right"}}>{ccySymbol} {formatMoney(r.repaymentAmount, DECIMAL_POINT)}</td>
          
           </tr>
         )

       }
    }

    return (
       <div className={rowClassName}>
         <div className="col-md-2">
           Repayment Amounts
          </div>
          <div className="col-md-3" >
           <table className="table-blue" >
             <tbody>
                 <tr>
                   <th>Period(s)</th>
                   <th>Repayment Amount</th>
                 </tr>
               
                 {rows}
             </tbody>

           </table>
          </div> 
       </div>
       
      
      
    )
 }

  function renderSchedules(){
     let rows = [];
     if(loan && loan.scheduleList){
        for (let index = 0; index < loan.scheduleList.length; index++) {
          let s = loan.scheduleList[index];
          
          rows.push(
            <tr style={{color:s.passed==true?'black':'green'}}>
              <td style={{width:"10%", textAlign:"center"}}>{s.schdlNo}</td>
              <td style={{width:"10%", textAlign:"center"}}>{s.date?convertToLocalDateDefault(s.date):""}</td>
              <td style={{width:"10%", textAlign:"center"}}>{s.rateInfo}</td>
              <td style={{width:"10%", textAlign:"right"}}>{ccySymbol} {formatMoney(s.periodRepayment, DECIMAL_POINT)}</td>
              <td style={{width:"10%", textAlign:"right"}}>{ccySymbol} {formatMoney(s.periodPrincipal, DECIMAL_POINT)}</td>
              <td style={{width:"10%", textAlign:"right"}}>{ccySymbol} {formatMoney(s.periodInterest, DECIMAL_POINT)}</td>
              <td style={{width:"10%", textAlign:"right"}}>{ccySymbol} {formatMoney(s.periodExtraPayment, DECIMAL_POINT)}</td>
              <td style={{width:"10%", textAlign:"right"}}>{ccySymbol} {formatMoney(s.periodFee, DECIMAL_POINT)}</td>
              <td style={{width:"10%", textAlign:"right"}}>{ccySymbol} {formatMoney(s.remainingPrincipal, DECIMAL_POINT)}</td>
            </tr>
          )

        }
     }

     return (
        <div className={rowClassName} style={{width:"100%", height:"300px", overflowY:"scroll", display:"block"}}>
           <div className="col-md-12" >

            <table className="table-blue" >
              <tbody>
                  <tr className="sticky">
                    <th>Schdl No</th>
                    <th>Date</th>
                    <th>Rate</th>
                    <th>Repayment</th>
                    <th>Principal</th>
                    <th>Interest</th>
                    <th>Extra Payment</th>
                    <th>Fee</th>
                    <th>Remaining Principal</th>
                  </tr>
                
                  {rows}
              </tbody>

            </table>
           </div> 
        </div>
        
       
       
     )
  }

  function renderEverything(){
     return (
      <>
        <div className={rowClassName}>
          <div className="col-md-2">
            Loan Amount 
          </div>
          <div className="col-md-2">
            {formatMoney(loan?loan.loanAmount:0, DECIMAL_POINT)}
          </div>
          <div className="col-md-1">

          </div>
          <div className="col-md-2">
            Number of Periods
          </div>
          <div className="col-md-2">
            {loan?loan.numberOfPeriods:0} period(s)
          </div>

          <div className="col-md-2">
            Pay Interest Only
          </div>
          <div className="col-md-1">
            {loan? loan.interestOnlyPeriods:""} period(s)
          </div>
        </div>
        <div className={rowClassName}>
          <div className="col-md-2">
            First Repayment Date 
          </div>
          <div className="col-md-2">
            {loan && loan.firstRepaymentDate?convertToLocalDateDefault(loan.firstRepaymentDate):"-"} 
          </div>
          <div className="col-md-1">

          </div>
          <div className="col-md-2">
            End Repayment Date
          </div>
          <div className="col-md-2">
            {loan && loan.finalRepaymentDate?convertToLocalDateDefault(loan.finalRepaymentDate):"-"}
          </div>
          <div className="col-md-2">
            In Arrears
          </div>
          <div className="col-md-1">
            {inArrears?"Yes":"No"}
          </div>
        </div>
        {renderRepayments()}
        <br/>
        {renderSchedules()}
      </>
     
     )
  }


  
  return (
    <div className="notification-dialog">
       {renderEverything()}
    </div>
  )
}
export default LoanSimulationDialog
