import { modalWindow as modalWindowName } from "../constants/ModalWindowLists"

export function modalWindow(state = {}, action) {
  switch (action.type) {
    case `OPEN_${modalWindowName.INFORMATION_MODAL}`:
      return {
        [modalWindowName.INFORMATION_MODAL]: true
      }
    case `CLOSE_${modalWindowName.INFORMATION_MODAL}`:
      return {
        [modalWindowName.INFORMATION_MODAL]: false
      }
    case `OPEN_${modalWindowName.CONFIRMATION_MODAL}`:
      return {
        [modalWindowName.CONFIRMATION_MODAL]: true
      }
    case `CLOSE_${modalWindowName.CONFIRMATION_MODAL}`:
      return {
        [modalWindowName.CONFIRMATION_MODAL]: false
      }
    case `OPEN_${modalWindowName.COLLECTIBLE_ANTIQUES_MODAL}`:
      return {
        [modalWindowName.COLLECTIBLE_ANTIQUES_MODAL]: true
      }
    case `CLOSE_${modalWindowName.COLLECTIBLE_ANTIQUES_MODAL}`:
      return {
        [modalWindowName.COLLECTIBLE_ANTIQUES_MODAL]: false
      }
    case `OPEN_${modalWindowName.BUSINESS_OWNERSHIP_MODAL}`:
      return {
        [modalWindowName.BUSINESS_OWNERSHIP_MODAL]: true
      }
    case `CLOSE_${modalWindowName.BUSINESS_OWNERSHIP_MODAL}`:
      return {
        [modalWindowName.BUSINESS_OWNERSHIP_MODAL]: false
        // incase we need some flag to do post processing
        // postAndRefetchData: true
      }
    case `OPEN_${modalWindowName.PERSONAL_CONTENT_FORM}`:
      return {
        [modalWindowName.PERSONAL_CONTENT_FORM]: true
      }
    case `CLOSE_${modalWindowName.PERSONAL_CONTENT_FORM}`:
      return {
        [modalWindowName.PERSONAL_CONTENT_FORM]: false
      }

    case `OPEN_${modalWindowName.PRIMARY_RESIDENCE_PROPERTY}`:
      return {
        [modalWindowName.PRIMARY_RESIDENCE_PROPERTY]: true
      }
    case `CLOSE_${modalWindowName.PRIMARY_RESIDENCE_PROPERTY}`:
      return {
        [modalWindowName.PRIMARY_RESIDENCE_PROPERTY]: false
      }
    case `OPEN_${modalWindowName.MOTOR_VEHICLE_MODAL}`:
      return {
        [modalWindowName.MOTOR_VEHICLE_MODAL]: true
      }
    case `CLOSE_${modalWindowName.MOTOR_VEHICLE_MODAL}`:
      return {
        [modalWindowName.MOTOR_VEHICLE_MODAL]: false
      }

    case `OPEN_${modalWindowName.MORTGAGE_CASH_ACCOUNT_FORM}`:
      return {
        ...state,
        [modalWindowName.MORTGAGE_CASH_ACCOUNT_FORM]: true
      }
    case `CLOSE_${modalWindowName.MORTGAGE_CASH_ACCOUNT_FORM}`:
      return {
        ...state,
        [modalWindowName.MORTGAGE_CASH_ACCOUNT_FORM]: false
      }
    case `OPEN_${modalWindowName.INVESTMENT_PROPERTY_MODAL}`:
      return {
        ...state,
        [modalWindowName.INVESTMENT_PROPERTY_MODAL]: true
      }
    case `CLOSE_${modalWindowName.INVESTMENT_PROPERTY_MODAL}`:
      return {
        ...state,
        [modalWindowName.INVESTMENT_PROPERTY_MODAL]: false
      }
    case `OPEN_${modalWindowName.BOND_FORM}`:
      return {
        ...state,
        [modalWindowName.BOND_FORM]: true
      }
    case `CLOSE_${modalWindowName.BOND_FORM}`:
      return {
        ...state,
        [modalWindowName.BOND_FORM]: false
      }
    case `OPEN_${modalWindowName.GENERIC_PORTFOLIO_FORM}`:
      return {
        ...state,
        [modalWindowName.GENERIC_PORTFOLIO_FORM]: true
      }
    case `CLOSE_${modalWindowName.GENERIC_PORTFOLIO_FORM}`:
      return {
        ...state,
        [modalWindowName.GENERIC_PORTFOLIO_FORM]: false
      }
    case `OPEN_${modalWindowName.INVESTMENT_PLATFORM}`:
      return {
        ...state,
        [modalWindowName.INVESTMENT_PLATFORM]: true
      }
    case `CLOSE_${modalWindowName.INVESTMENT_PLATFORM}`:
      return {
        ...state,
        [modalWindowName.INVESTMENT_PLATFORM]: false
      }
    case `OPEN_${modalWindowName.INVESTMENT_FUND_PLATFORM}`:
      return {
        ...state,
        [modalWindowName.INVESTMENT_FUND_PLATFORM]: true
      }
    case `CLOSE_${modalWindowName.INVESTMENT_FUND_PLATFORM}`:
      return {
        ...state,
        [modalWindowName.INVESTMENT_FUND_PLATFORM]: false
      }
    case `OPEN_${modalWindowName.OTHER_RESIDENCE_PROPERTY}`:
      return {
        ...state,
        [modalWindowName.OTHER_RESIDENCE_PROPERTY]: true
      }
    case `CLOSE_${modalWindowName.OTHER_RESIDENCE_PROPERTY}`:
      return {
        ...state,
        [modalWindowName.OTHER_RESIDENCE_PROPERTY]: false
      }
    case `OPEN_${modalWindowName.MOTOR_VEHICLE_FORM}`:
      return {
        ...state,
        [modalWindowName.MOTOR_VEHICLE_FORM]: true
      }
    case `CLOSE_${modalWindowName.MOTOR_VEHICLE_FORM}`:
      return {
        ...state,
        [modalWindowName.MOTOR_VEHICLE_FORM]: false
      }
    case `OPEN_${modalWindowName.UNIT_TRUST_FORM}`:
      return {
        ...state,
        [modalWindowName.UNIT_TRUST_FORM]: true
      }
    case `CLOSE_${modalWindowName.UNIT_TRUST_FORM}`:
      return {
        ...state,
        [modalWindowName.UNIT_TRUST_FORM]: false
      }
    case `OPEN_${modalWindowName.SHARE_FORM}`:
      return {
        ...state,
        [modalWindowName.SHARE_FORM]: true
      }
    case `CLOSE_${modalWindowName.SHARE_FORM}`:
      return {
        ...state,
        [modalWindowName.SHARE_FORM]: false
      }
    case `OPEN_${modalWindowName.CASH_ACCOUNT_FORM}`:
      return {
        ...state,
        [modalWindowName.CASH_ACCOUNT_FORM]: true
      }
    case `CLOSE_${modalWindowName.CASH_ACCOUNT_FORM}`:
      return {
        ...state,
        [modalWindowName.CASH_ACCOUNT_FORM]: false
      }
    case `OPEN_${modalWindowName.TERM_DEPOSIT_FORM}`:
      return {
        ...state,
        [modalWindowName.TERM_DEPOSIT_FORM]: true
      }
    case `CLOSE_${modalWindowName.TERM_DEPOSIT_FORM}`:
      return {
        ...state,
        [modalWindowName.TERM_DEPOSIT_FORM]: false
      }
    case `OPEN_${modalWindowName.ENTITY_MODAL}`: {
      if (action.value !== undefined) {
        return {
          ...state,
          [modalWindowName.ENTITY_MODAL]: true,
          entityType: action.value,
          entityID: action.entityID
        }
      }
      return {
        ...state,
        [modalWindowName.ENTITY_MODAL]: true
      }
    }
    case `CLOSE_${modalWindowName.ENTITY_MODAL}`:
      return {
        ...state,
        [modalWindowName.ENTITY_MODAL]: false
      }
    case `OPEN_${modalWindowName.USER_MODAL}`:
      return {
        ...state,
        [modalWindowName.USER_MODAL]: true
      }
    case `CLOSE_${modalWindowName.USER_MODAL}`:
      return {
        ...state,
        [modalWindowName.USER_MODAL]: false
      }
    case `OPEN_${modalWindowName.RESET_PASSWORD_MODAL}`:
      return {
        ...state,
        [modalWindowName.RESET_PASSWORD_MODAL]: true
      }
    case `CLOSE_${modalWindowName.RESET_PASSWORD_MODAL}`:
      return {
        ...state,
        [modalWindowName.RESET_PASSWORD_MODAL]: false
      }

    case `OPEN_${modalWindowName.RETIREMENT_SCHEME_MPF}`:
      return {
        ...state,
        [modalWindowName.RETIREMENT_SCHEME_MPF]: true
      }
    case `CLOSE_${modalWindowName.RETIREMENT_SCHEME_MPF}`:
      return {
        ...state,
        [modalWindowName.RETIREMENT_SCHEME_MPF]: false
      }

    case `OPEN_${modalWindowName.RETIREMENT_SCHEME_CPF}`:
      return {
        ...state,
        [modalWindowName.RETIREMENT_SCHEME_CPF]: true
      }
    case `CLOSE_${modalWindowName.RETIREMENT_SCHEME_CPF}`:
      return {
        ...state,
        [modalWindowName.RETIREMENT_SCHEME_CPF]: false
      }
    case `OPEN_${modalWindowName.RETIREMENT_SCHEME_ORSO}`:
      return {
        ...state,
        [modalWindowName.RETIREMENT_SCHEME_ORSO]: true
      }
    case `CLOSE_${modalWindowName.RETIREMENT_SCHEME_ORSO}`:
      return {
        ...state,
        [modalWindowName.RETIREMENT_SCHEME_ORSO]: false
      }
    case `OPEN_${modalWindowName.UNIT_TRUST_INNER_FORM}`:
      return {
        ...state,
        [modalWindowName.UNIT_TRUST_INNER_FORM]: true
      }
    case `CLOSE_${modalWindowName.UNIT_TRUST_INNER_FORM}`:
      return {
        ...state,
        [modalWindowName.UNIT_TRUST_INNER_FORM]: false
      }
    case `OPEN_${modalWindowName.INVESTMENT_PLATFORM_SUB_FORM}`:
      return {
        ...state,
        [modalWindowName.INVESTMENT_PLATFORM_SUB_FORM]: true
      }
    case `CLOSE_${modalWindowName.INVESTMENT_PLATFORM_SUB_FORM}`:
      return {
        ...state,
        [modalWindowName.INVESTMENT_PLATFORM_SUB_FORM]: false
      }
    case `OPEN_${modalWindowName.SUPERANNUATION_FUND}`:
      return {
        ...state,
        [modalWindowName.SUPERANNUATION_FUND]: true
      }
    case `CLOSE_${modalWindowName.SUPERANNUATION_FUND}`:
      return {
        ...state,
        [modalWindowName.SUPERANNUATION_FUND]: false
      }
    case `OPEN_${modalWindowName.SUPERANNUATION_FUND_SUB_FORM}`:
      return {
        ...state,
        [modalWindowName.SUPERANNUATION_FUND_SUB_FORM]: true
      }
    case `CLOSE_${modalWindowName.SUPERANNUATION_FUND_SUB_FORM}`:
      return {
        ...state,
        [modalWindowName.SUPERANNUATION_FUND_SUB_FORM]: false
      }
    
    case `OPEN_${modalWindowName.DELETE_CONFIRMATION_MODAL}`:
      return {
        ...state,
        [modalWindowName.DELETE_CONFIRMATION_MODAL]: true
      }
    case `CLOSE_${modalWindowName.DELETE_CONFIRMATION_MODAL}`:
      return {
        ...state,
        [modalWindowName.DELETE_CONFIRMATION_MODAL]: false
      }
    case `OPEN_${modalWindowName.FEEDBACK_MODAL}`: {
      if (action.value !== undefined) {
        return {
          ...state,
          [modalWindowName.FEEDBACK_MODAL]: true,
          object: action.value
        }
      }
      return {
        ...state,
        [modalWindowName.FEEDBACK_MODAL]: true
      }
    }
    case `CLOSE_${modalWindowName.FEEDBACK_MODAL}`:
      return {
        ...state,
        [modalWindowName.FEEDBACK_MODAL]: false
      }
    case `OPEN_${modalWindowName.CAR_FEEDBACK_MODAL}`: {
      if (action.value !== undefined) {
        return {
          ...state,
          [modalWindowName.CAR_FEEDBACK_MODAL]: true,
          object: action.value
        }
      }
      return {
        ...state,
        [modalWindowName.CAR_FEEDBACK_MODAL]: true
      }
    }
    case `CLOSE_${modalWindowName.CAR_FEEDBACK_MODAL}`:
      return {
        ...state,
        [modalWindowName.CAR_FEEDBACK_MODAL]: false
      }
    case `OPEN_${modalWindowName.CREDIT_CARD_FORM}`:
      return {
        ...state,
        [modalWindowName.CREDIT_CARD_FORM]: true
      }
    case `CLOSE_${modalWindowName.CREDIT_CARD_FORM}`:
      return {
        ...state,
        [modalWindowName.CREDIT_CARD_FORM]: false
      }
    case `OPEN_${modalWindowName.PERSONAL_LOAN_FORM}`:
      return {
        ...state,
        [modalWindowName.PERSONAL_LOAN_FORM]: true
      }
    case `CLOSE_${modalWindowName.PERSONAL_LOAN_FORM}`:
      return {
        ...state,
        [modalWindowName.PERSONAL_LOAN_FORM]: false
      }
    case `OPEN_${modalWindowName.EDUCATION_LOAN_FORM}`:
      return {
        ...state,
        [modalWindowName.EDUCATION_LOAN_FORM]: true
      }
    case `CLOSE_${modalWindowName.EDUCATION_LOAN_FORM}`:
      return {
        ...state,
        [modalWindowName.EDUCATION_LOAN_FORM]: false
      }
    case `OPEN_${modalWindowName.GEAL_AU_MODAL}`:
      return {
        ...state,
        [modalWindowName.GEAL_AU_MODAL]: true
      }
    case `CLOSE_${modalWindowName.GEAL_AU_MODAL}`:
      return {
        ...state,
        [modalWindowName.GEAL_AU_MODAL]: false
      }
    case `OPEN_${modalWindowName.GEAL_SG_MODAL}`:
      return {
        ...state,
        [modalWindowName.GEAL_SG_MODAL]: true
      }
    case `CLOSE_${modalWindowName.GEAL_SG_MODAL}`:
      return {
        ...state,
        [modalWindowName.GEAL_SG_MODAL]: false
      }
    case `OPEN_${modalWindowName.GEAL_HK_MODAL}`:
      return {
        ...state,
        [modalWindowName.GEAL_HK_MODAL]: true
      }
    case `CLOSE_${modalWindowName.GEAL_HK_MODAL}`:
      return {
        ...state,
        [modalWindowName.GEAL_HK_MODAL]: false
      }
    case `OPEN_${modalWindowName.GEAL_NZ_MODAL}`:
      return {
        ...state,
        [modalWindowName.GEAL_NZ_MODAL]: true
      }
    case `CLOSE_${modalWindowName.GEAL_NZ_MODAL}`:
      return {
        ...state,
        [modalWindowName.GEAL_NZ_MODAL]: false
      }
    case `OPEN_${modalWindowName.GEAL_MY_MODAL}`:
      return {
        ...state,
        [modalWindowName.GEAL_MY_MODAL]: true
      }
    case `CLOSE_${modalWindowName.GEAL_MY_MODAL}`:
      return {
        ...state,
        [modalWindowName.GEAL_MY_MODAL]: false
      }
    case `OPEN_${modalWindowName.PERSONAL_TAX_FORM}`:
      return {
        ...state,
        [modalWindowName.PERSONAL_TAX_FORM]: true
      }
    case `CLOSE_${modalWindowName.PERSONAL_TAX_FORM}`:
      return {
        ...state,
        [modalWindowName.PERSONAL_TAX_FORM]: false
      }
    case `OPEN_${modalWindowName.EMPLOYMENT_TERMINATION_PAYMENTS}`:
      return {
        ...state,
        [modalWindowName.EMPLOYMENT_TERMINATION_PAYMENTS]: true
      }
    case `CLOSE_${modalWindowName.EMPLOYMENT_TERMINATION_PAYMENTS}`:
      return {
        ...state,
        [modalWindowName.EMPLOYMENT_TERMINATION_PAYMENTS]: false
      }
    case `OPEN_${modalWindowName.EMPLOYEE_PROVIDENCE_FUND}`:
      return {
        ...state,
        [modalWindowName.EMPLOYEE_PROVIDENCE_FUND]: true
      }
    case `CLOSE_${modalWindowName.EMPLOYEE_PROVIDENCE_FUND}`:
      return {
        ...state,
        [modalWindowName.EMPLOYEE_PROVIDENCE_FUND]: false
      }
    case `OPEN_${modalWindowName.PRIVATE_RETIREMENT_SCHEME}`:
      return {
        ...state,
        [modalWindowName.PRIVATE_RETIREMENT_SCHEME]: true
      }
    case `CLOSE_${modalWindowName.PRIVATE_RETIREMENT_SCHEME}`:
      return {
        ...state,
        [modalWindowName.PRIVATE_RETIREMENT_SCHEME]: false
      }
    case `OPEN_${modalWindowName.MALAYSIAN_OTHER_PENSION_FUND}`:
      return {
        ...state,
        [modalWindowName.MALAYSIAN_OTHER_PENSION_FUND]: true
      }
    case `CLOSE_${modalWindowName.MALAYSIAN_OTHER_PENSION_FUND}`:
      return {
        ...state,
        [modalWindowName.MALAYSIAN_OTHER_PENSION_FUND]: false
      }
    case `OPEN_${modalWindowName.CENTRAL_PROVIDENCE_FUND_INFORMATION}`:
      return {
        ...state,
        [modalWindowName.CENTRAL_PROVIDENCE_FUND_INFORMATION]: true
      }
    case `CLOSE_${modalWindowName.CENTRAL_PROVIDENCE_FUND_INFORMATION}`:
      return {
        ...state,
        [modalWindowName.CENTRAL_PROVIDENCE_FUND_INFORMATION]: false
      }
    case `OPEN_${modalWindowName.SUPLEMENTARY_RETIREMENT_SCHEME_INFORMATION}`:
      return {
        ...state,
        [modalWindowName.SUPLEMENTARY_RETIREMENT_SCHEME_INFORMATION]: true
      }
    case `CLOSE_${modalWindowName.SUPLEMENTARY_RETIREMENT_SCHEME_INFORMATION}`:
      return {
        ...state,
        [modalWindowName.SUPLEMENTARY_RETIREMENT_SCHEME_INFORMATION]: false
      }
    case `OPEN_${modalWindowName.SELF_MANAGED_SUPERANNUATION_FUND}`:
      return {
        ...state,
        [modalWindowName.SELF_MANAGED_SUPERANNUATION_FUND]: true
      }
    case `CLOSE_${modalWindowName.SELF_MANAGED_SUPERANNUATION_FUND}`:
      return {
        ...state,
        [modalWindowName.SELF_MANAGED_SUPERANNUATION_FUND]: false
      }
    case `OPEN_${modalWindowName.PENSION_INFORMATION}`:
      return {
        ...state,
        [modalWindowName.PENSION_INFORMATION]: true
      }
    case `CLOSE_${modalWindowName.PENSION_INFORMATION}`:
      return {
        ...state,
        [modalWindowName.PENSION_INFORMATION]: false
      }
    case `OPEN_${modalWindowName.USER_PROFILE_MODAL}`:
      return {
        ...state,
        [modalWindowName.USER_PROFILE_MODAL]: true
      }
    case `CLOSE_${modalWindowName.USER_PROFILE_MODAL}`:
      return {
        ...state,
        [modalWindowName.USER_PROFILE_MODAL]: false
      }
    case `OPEN_${modalWindowName.ILAS_INVESTMENT}`:
      return {
        ...state,
        [modalWindowName.ILAS_INVESTMENT]: true
      }
    case `CLOSE_${modalWindowName.ILAS_INVESTMENT}`:
      return {
        ...state,
        [modalWindowName.ILAS_INVESTMENT]: false
      }

    default:
      return state
  }
}
