/*
    Author : Marlina (marlina@devstack.com.au)
    Description : Investment property main page (modal)
*/

import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import intl from "react-intl-universal"
import { netWorthFormActions, modalWindowActions } from "../../actions"
import { modalWindow } from "../../constants/ModalWindowLists"
import NetWorthForm, {
  NetWorthPath,
  NetWorthFormPropsComposer
} from "./NetWorthForm"

import { InvestmentPropertyForm } from "../../forms"

class InvestmentProperty extends NetWorthForm {
  constructor(props) {
    super(
      props,
      intl.get("investmentProperty.title"),
      modalWindow.INVESTMENT_PROPERTY_MODAL,
      NetWorthPath.investmentProperties,
      "InvestmentProperty"
    )
  }

  calculateYearlyExpensesOnLoad = () => {
    const { model } = this.props
    model.deductibleExpenses = model.deductibleExpenses || {}
    Object.keys(model.deductibleExpenses)
      .filter((key) => !key.includes("Yearly"))
      .forEach((key) => {
        model.deductibleExpenses[`${key}Yearly`] =
          (Number(model.deductibleExpenses[key]) || 0) * 12
      })
  }

  render() {
    const {
      handleSave,
      handleChange,
      contentWrapper,
      readOnlyAccessComponent,
      calculateYearlyExpensesOnLoad,
      props
    } = this
    const { model, isJoint, client, partner, optionsData, currency } = props // 20200609 - add [currency]
    const { readOnlyAccess } = readOnlyAccessComponent()
    calculateYearlyExpensesOnLoad()

    const initModel = (mdl) => {
      if (!mdl) {
        mdl = {}
      }
      if (!mdl.mortgage) {
        mdl.mortgage = {}
      }
      if (!mdl.mortgage.borrowingsCurrency) {
        mdl.mortgage.borrowingsCurrency = currency
      }
      if (!mdl.deductibleExpenses) {
        mdl.deductibleExpenses = {}
      }
      if (!mdl.deductibleExpenses.expensesCurrency) {
        mdl.deductibleExpenses.expensesCurrency = currency
      }
      return mdl
    }

    return contentWrapper(
      <InvestmentPropertyForm
        handleSave={handleSave}
        handleChange={handleChange}
        model={initModel(model)}
        optionsData={optionsData}
        readOnlyAccess={readOnlyAccess}
        currency={currency}
        clientInformation={{
          isJoint,
          client,
          partner
        }}
      />
    )
  }
}

function mapStateToProps(state) {
  return NetWorthFormPropsComposer(
    state,
    modalWindow.INVESTMENT_PROPERTY_MODAL,
    NetWorthPath.investmentProperties
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, netWorthFormActions, modalWindowActions),
    dispatch
  )
}

InvestmentProperty = connect(
  mapStateToProps,
  mapDispatchToProps
)(InvestmentProperty)

export default InvestmentProperty
