import { jointSelectionAction } from '../constants/ActionConstants';

export const jointSelectionActions = {
    updateSelectionData,
};

function updateSelectionData(selectionData) {
    return {
        type: jointSelectionAction.UPDATE_SELECTION_DATA,
        selectionData,
    };
}
