import React from "react"

const RspTable = ({ rsp }) => {
  return (
    <div>
      <table className="table-blue" width="100%">
        <tbody>
          <tr>
            <th>Activated Date</th>
            <th>Amount</th>
            <th>Currency</th>
            <th>Dividend Transaction</th>
            <th>Instrument Type</th>
            <th>Name</th>
            <th>Payment Method</th>
            <th>Rate Fee</th>
            <th>Status</th>
          </tr>
          {rsp.length ? (
            rsp.map((item) => {
              return (
                <tr>
                  <td>{item.activatedDate}</td>
                  <td>$ {item.amount}</td>
                  <td>{item.currency}</td>
                  <td>{item.dividendInstruction}</td>
                  <td>{item.instrumentType}</td>
                  <td>{item.name}</td>
                  <td>{item.paymentMethod}</td>
                  <td>{item.rateFee}</td>
                  <td>{item.status}</td>
                </tr>
              )
            })
          ) : (
            <></>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default RspTable
