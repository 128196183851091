import { personalDetailsAction } from "../../constants/ActionConstants"
import { PersonalDetailsApi } from "../../api"
import { jointSelectionActions } from "../JointSelectionActions"

export const personalDetailsActions = {
  getPersonalDetails
}

export function getPersonalDetailsSuccess(response) {
  return { type: personalDetailsAction.GET_PERSONALDETAIL_SUCCESS, response }
}

// action functions
export function getPersonalDetails(id) {
  return async (dispatch) => {
    // api request here
    PersonalDetailsApi.getWithPartner(id).then((response) => {
      if (response) {
        if (
          response.data.client.classification === "JOINT" &&
          response.data.partner
        ) {
          dispatch(
            getPersonalDetailsSuccess({
              ...response.data.client,
              partner: response.data.partner
            })
          )
          dispatch(
            jointSelectionActions.updateSelectionData({
              client: response.data.client,
              partner: response.data.partner,
              isJoint: true
            })
          )
        } else {
          dispatch(getPersonalDetailsSuccess(response.data.client))
          dispatch(
            jointSelectionActions.updateSelectionData({
              client: response.data.client,
              isJoint: false
            })
          )
        }
      }
    })
  }
}
