import React, { useState, useEffect } from "react"
import intl from "react-intl-universal"
import { Link } from "react-router-dom"
import { menuItems, getRestMenuItems } from "../../../helpers"
import { Logout } from "../../../components/Logout"
import { createMenu, lightOrDark } from "./_helper/MenuHelper"
import { AccessLevelEnum } from "../../../constants/Enums"
import { history } from "../../../helpers"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import { logout } from "../../../helpers/SessionHelper"
const NonClientBurgerMenu = ({
  entityTypeEnum,
  location,
  feedbackClicked,
  titleBarColour,
  fontColour,
  clientId,
  toggleSidebar,
  isSidebarInActive
}) => {
  const { dealershipPhoto } = useSelector((state) => state.color)

  const topMenuItem = menuItems.find((o) => o.id === entityTypeEnum)
  const history = useHistory()
  let activeRole = JSON.parse(localStorage.getItem("activeUserRole"))
  let arrEntityMenu = activeRole ? activeRole.entityMenuList : []

  function currentPageIsLobby() {
    let currentUrl = window.location.href
    let result = currentUrl.indexOf("lobby") > -1
    return result
  }
  function goBack() {
    history.goBack()
  }
  useEffect(() => {
    if (
      activeRole &&
      activeRole.entityMenuList &&
      activeRole.entityMenuList.length > 0
    ) {
      activeRole.entityMenuList.map((item, index) => {
        if (item.id) {
          item.children.length &&
            item.children.map((itemIs, indexIs) => {
              if (itemIs.path == location.pathname) {
                setSelectedGroupMenu(item.id)
                return
              }
            })
        }
      })
    }
  }, [])
  const [selectedGroupMenu, setSelectedGroupMenu] = useState(
    arrEntityMenu[0] ? arrEntityMenu[0].id : ""
  )
  let mode = localStorage.getItem("Mode")
  function toggleGroupMenu(id, data) {
    if (data.children.length) {
      setSelectedGroupMenu(id)
    }
  }
  function createGroupMenu(data, clientId) {
    return (
      <li key={data.id}>
        <input
          id={data.id}
          type="checkbox"
          checked={data.id != selectedGroupMenu}
          onChange={() => toggleGroupMenu(data.id, data)}
        />
        <span className="hand" onClick={() => toggleGroupMenu(data.id, data)}>
          {data.label}
        </span>
        <nav className="secondary-menu">
          {data.children
            .filter(
              (d) =>
                !d.disabled &&
                d.accessLevel != AccessLevelEnum.AccessLevelEnum_NO_ACCESS &&
                d.showAtClientLogin
            )
            .map((item, idx) => createMenu(item, clientId, idx))}
          {data.children
            .filter(
              (d) =>
                !d.disabled &&
                d.accessLevel != AccessLevelEnum.AccessLevelEnum_NO_ACCESS
            )
            .map((item, idx) => createMenu(item, clientId, idx))}
        </nav>
      </li>
    )
  }

  function onTopMenuClicked() {
    // localStorage.setItem("Mode", "CM");
  }

  function onNonClientMenuClicked(e, item) {
    history.push(item.path)
  }

  function generateGroupMenuList() {
    let arr = []
    if (topMenuItem !== undefined) {
      arr.push(
        <li key="666" onClick={() => onTopMenuClicked()}>
          <input type="checkbox" />
          <Link
            to={
              mode === "DEMO_CLIENT"
                ? `${topMenuItem.path}/${mode}`
                : topMenuItem.path
            }
            style={{ background: titleBarColour, color: fontColour }}
            className={`${
              location.pathname === topMenuItem.path ? "selected" : ""
            } back-link`}
          >
            {localStorage.getItem("Mode") == "BM"
              ? "Business Management"
              : intl.get(`EntityTypeEnum_${topMenuItem.entityType}`)}
          </Link>
        </li>
      )
    }

    // render the rest
    const restMenuItems = getRestMenuItems(entityTypeEnum)
    if (mode === "BM") {
      arrEntityMenu &&
        arrEntityMenu
          .filter(
            (g) =>
              !g.disabled &&
              g.accessLevel != AccessLevelEnum.AccessLevelEnum_NO_ACCESS
          )
          .map((g) => {
            arr.push(createGroupMenu(g, clientId))
          })
    } else {
      restMenuItems.forEach((item, index) => {
        arr.push(
          <li key={index}>
            <a
              className={location.pathname === item.path ? "selected" : ""}
              // style={{backgroundColor:titleBarColour, color:fontColour}}
              onClick={(e) => onNonClientMenuClicked(e, item)}
            >
              {intl.get(`EntityTypeEnum_${item.entityType}`)}
            </a>
          </li>
        )
      })
    }
    return arr
  }
  let pathName = window.location.pathname
  return (
    <div className="portal-left">
      <a href={`${process.env.REACT_APP_BASE_URL}/lobby`}>
        <div className="sidebar-navigation-header">
          <img
            className="logo mx-auto"
            src={`${
              dealershipPhoto
                ? dealershipPhoto
                : `${process.env.PUBLIC_URL}/assets/logo/Logo-Horizontal-LightBG.svg`
            }`}
            alt="Koizai logo"
            style={{
              margin: "auto",
              width: "100%",
              maxWidth: "120px",
              maxHeight: "40px",
              height: "100%"
            }}
          />
        </div>
      </a>

      <button
        className="arrow-sidebar-toggler"
        data-toggle-tooltip="Expand/Collapse"
        onClick={() => {
          toggleSidebar()
        }}
      >
        <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
          <path
            d="M10.294 9.698a.988.988 0 010-1.407 1.01 1.01 0 011.419 0l2.965 2.94a1.09 1.09 0 010 1.548l-2.955 2.93a1.01 1.01 0 01-1.42 0 .988.988 0 010-1.407l2.318-2.297-2.327-2.307z"
            fill="currentColor"
            fillRule="evenodd"
          ></path>
        </svg>
      </button>
      {isSidebarInActive && (
        <div style={{ marginLeft: "0.5em" }}>
          <a href={`${process.env.REACT_APP_BASE_URL}/lobby`}>
            <span
              className="fa fa-lg fa-home"
              style={{ marginTop: "2em" }}
              alt="Go to Lobby"
            ></span>
          </a>
          {!currentPageIsLobby() && (
            <span
              className="fa fa-lg fa-arrow-left"
              style={{ marginTop: "2em", cursor: "pointer" }}
              onClick={() => goBack()}
              alt="Go Back"
            ></span>
          )}
          <span
            className="fa fa-lg fa-comments"
            style={{ marginTop: "2em", cursor: "pointer" }}
            onClick={feedbackClicked}
            alt="User Feedback"
          ></span>

          <Logout
            component={
              <span
                className="fa fa-lg fa-sign-out"
                style={{ marginTop: "2em", cursor: "pointer" }}
                onClick={() => logout()}
                alt="Logout"
              ></span>
            }
          />
        </div>
      )}

      <div className="sidebar-navigation-wrap">
        <nav
          className={`primary-menu primary-menu-${lightOrDark(titleBarColour)}`}
        >
          {!pathName.includes("/lobby") ? (
            <ul>{generateGroupMenuList()}</ul>
          ) : (
            <ul>
              <li>
                <input type="checkbox" />
                <Link
                  to={"#"}
                  style={{ background: titleBarColour, color: fontColour }}
                  className={"selected back-link"}
                >
                  {intl.get("leftmenu.lobby")}{" "}
                </Link>
              </li>
            </ul>
          )}
        </nav>

        <nav className="primary-menu-end">
          <ul>
            {!currentPageIsLobby() && (
              <li style={{ cursor: "pointer" }}>
                <span onClick={(e) => goBack()} activeclassname="selected">
                  {"Go Back"}
                </span>
              </li>
            )}
            {!pathName.includes("/lobby") ? (
              <Link to="/lobby">
                <li
                  onClick={() => localStorage.removeItem("Mode")}
                  style={{ cursor: "pointer" }}
                >
                  <span>{intl.get("leftmenu.lobby")}</span>
                </li>
              </Link>
            ) : (
              ""
            )}
            <li style={{ cursor: "pointer" }}>
              <span onClick={feedbackClicked} activeclassname="selected">
                {intl.get("leftmenu.feedback")}
              </span>
            </li>
            <li>
              <Logout />
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default NonClientBurgerMenu
