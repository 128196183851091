/*
    Author : Anton Nuansantio (anton.nuansantio@koizai.com)
    Description : Malaysia Tax Estimator
*/

import React,{ useState,useEffect } from 'react';
import intl from 'react-intl-universal';
import Alert from "react-s-alert";

import NumberFormat from 'react-number-format';
import { getSymbolFromCode } from '../helpers';
import ReactModal from 'react-modal';
import NetWorthFormHeader from '../pages/NetWorth/NetWorthFormHeader';
import Tooltip from 'rc-tooltip';
import TaxApi from '../api/Tax/TaxApi';
import { numberWithCommas, numberNoDecimalPoints } from '../helpers';
import { FaTrash } from 'react-icons/fa';
// import { ClassificationSelection, JointSelectionMenu } from '../components';

const MalaysiaTaxEstimator = ({client, model, handleSave, onModalClosed}) =>{
    const SECTION_KEY_EMP = "EMPLOYMENT_INCOME";
    const SECTION_KEY_OTHER = "OTHER_INCOME";
    const SECTION_KEY_NON_ASSESSIBLE = "NON_ASSESSABLE_INCOME";
    const SECTION_KEY_ALLOWABLE_DEDUCT = "ALLOWABLE_DEDUCTIONS";
    const SECTION_KEY_PERSONAL_RELIEFS = "PERSONAL_RELIEFS";
    
    const THOUSAND_SEPARATOR = ",";
    const DECIMAL_SEPARATOR = ".";
    const DECIMAL_POINT = 2;
    const {id, accountCurrency, taxPaymentCountry, taxPaymentFrequency} = model;
    const [ccySymbol, setCcySymbol] = useState(getSymbolFromCode(accountCurrency?accountCurrency:"MYR"));
    const [taxRules, setTaxRules] = useState();
    const [taxInit, setTaxInit] = useState();
    const [classification, setClasification] = useState(model && model.classification?model.classification:"CLIENT");
    const [name, setName] = useState(0);
    const [married, setMarried] = useState(false);
    const [citizen, setCitizen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [numParents, setNumParents] = useState(0);
    const [numChildren, setNumChildren] = useState(0);
    const [numDisabledChildren, setNumDisabledChildren] = useState(0);

    const [taxYearAssessment, setTaxYearAssessment] = useState(0);
    const [selectedTaxRule, setSelectedTaxRule] = useState();
    
    const [zakatFitrah, setZakatFitrah] = useState(0);

    
    

    //section total income
    const defaultEmploymentIncome = [
        {name : "Employment Income", value: 0, isReduction: false, desc: "", pickerVisible:false},  
        {name : "Bonus – Variable", value: 0, isReduction: false, desc: "", pickerVisible:false},
        {name : "Perquisites", value: 0, isReduction: false, desc: "", pickerVisible:false},
        {name : "Value of Living Accommodation", value: 0, isReduction: false, desc: "", pickerVisible:false},
        {name : "Employer's Contribution to Unapproved Pension Fund", value: 0, isReduction: false, desc: "", pickerVisible:false},
        {name : "Less: Employment Expenses", value: 0, isReduction: true, desc: "", pickerVisible:false},
       
    ]
    const [arrEmploymentIncome, setArrEmploymentIncome] = useState(defaultEmploymentIncome);
    const [totalNetEmploymentIncome, setTotalNetEmploymentIncome] = useState(0);


    //section other income / assessable income
    const defaultOtherIncome = [
        {name : "Dividends", value: 0, isReduction: false, desc: ""},  
        {name : "Interest (except bank deposit interests)", value: 0, isReduction: false, desc: ""},
        {name : "Business Income", value: 0, isReduction: false, desc: ""},
        {name : "Pensions", value: 0, isReduction: false, desc: ""},
        {name : "Annuity Income", value: 0, isReduction: false, desc: ""},
        {name : "Discounts", value: 0, isReduction: false, desc: ""},
        {name : "Premiums", value: 0, isReduction: false, desc: ""},
        {name : "Royalty, Charge, Estate/Trust Income", value: 0, isReduction: false, desc: ""},
        {name : "Gains or profits not falling under any of the foregoing classes", value: 0, isReduction: false, desc: ""},
    ]
    const [arrOtherIncome, setArrOtherIncome] = useState(defaultOtherIncome);
    const [totalOtherIncome, setTotalOtherIncome] = useState(0);

    

    //section non assessable income
    const FIELD_PROPERTY_RENTAL_INCOME = "Property Rental Income";
    const FIELD_PROPERTY_RENTAL_EXPENSES = "Less: Expenses Deduction";
    const FIELD_PROPERTY_GAIN_1 = "Real Property Gain 1 (Disposal in 1st year)";
    const FIELD_PROPERTY_GAIN_2 = "Real Property Gain 2 (Disposal in 6th+ year)";
    const defaultNonAssessableIncome = [
        {name : FIELD_PROPERTY_RENTAL_INCOME, value: 0, isReduction: false, desc: ""},  
        {name : FIELD_PROPERTY_RENTAL_EXPENSES, value: 0, isReduction: true, desc: ""},
        {name : FIELD_PROPERTY_GAIN_1, value: 0, isReduction: false, desc: "", disposalType:1},
        {name : FIELD_PROPERTY_GAIN_2, value: 0, isReduction: false, desc: "", disposalType:6},
    ]
    const [arrNonAssessableIncome, setArrNonAssessableIncome] = useState(defaultNonAssessableIncome);
    const [totalNonAssessableIncome, setTotalNonAssessableIncome] = useState(0);

    //section allowable deduction
    const defaultAllowableDeductions = [
        {name : "Basic", value: 0, isReduction: false, desc: ""},  
        {name : "Donations", value: 0, isReduction: false, desc: "Max limit 7% of Aggregate Income"},
        {name : "Margin Loan", value: 0, isReduction: false, desc: ""},
    ]
    const [arrAllowableDeductions, setArrAllowableDeductions] = useState(defaultAllowableDeductions);
    const [totalAllowableDeductions, setTotalAllowableDeductions] = useState(0);

    //section personal reliefs
    const [arrPersonalReliefs, setArrPersonalReliefs] = useState([]);
    const [totalPersonalReliefs, setTotalPersonalReliefs] = useState(0);

    function doSaveIntoTaxPayment(){
        let clientId = client.id;
        let model = {
            countryCode: selectedTaxRule?selectedTaxRule.countryCode:"MY",
            currencyCode: accountCurrency,
            currencySymbol: ccySymbol,
            classification: classification,
            taxAssessmentYear: taxYearAssessment,
            sections: [
                {key:SECTION_KEY_EMP, name:"Employment Incomes", totalName:"Total Net Employment Income", totalValue: totalNetEmploymentIncome, items: arrEmploymentIncome},
                {key:SECTION_KEY_OTHER, name:"Other Incomes", totalName:"Total Other Incomes", totalValue: totalOtherIncome, items: arrOtherIncome},
                {key:SECTION_KEY_NON_ASSESSIBLE, name:"Non Assessible Incomes", totalName:"Total Non Assessable Income", totalValue: totalNonAssessableIncome, items: arrNonAssessableIncome},
                {key:SECTION_KEY_ALLOWABLE_DEDUCT, name:"Allowable Deductions", totalName:"Total Allowable Deductions", totalValue: totalAllowableDeductions, items: arrAllowableDeductions},
                {key:SECTION_KEY_PERSONAL_RELIEFS, name:"Personal Reliefs", totalName:"Total Personal Reliefs", totalValue: totalPersonalReliefs, items: arrPersonalReliefs},
            ],
            totalAssessableIncome: getTotalAssessibleIncome(),
            netAssessableIncome : getNetAssessableIncome(),
            netChargeableIncome: getNetChargeableIncome(),
            marginalTaxBracket: getMarginalTaxBracket(),
            taxPayableChargeableIncome: getTaxPayableOnChargeableIncome(),
            rentalIncomeTax: getRentalIncomeTax(),
            realPropertyGainTax: getRealPropertyGainTax(),
            selfRebate: getSelfRebate(),
            spouseRebate : getWifeHusbandTaxRebate(),
            zakatFitrah: zakatFitrah,
            netTaxPayable : getNetTaxPayable(),
            averageTaxRate: getAverageTaxRate(),
            contributionSOSCO: getContributionSosco()



        }

        
        TaxApi.saveTaxWithDetails(clientId, model).then(({data})=>{
            if(data){
               let personalTax = data.personalTax;
               handleSave(personalTax);
               onModalClosed();
            }
           
           

        })    
    }
      
    useEffect(() => {
        let clientId = client.id;
        if(clientId){
            TaxApi.initMalaysiaTax(clientId).then(({data})=>{
                if(data){
                    setNumParents(data.clientTaxInit.numParents);
                    setNumChildren(data.clientTaxInit.numChildren);
                    setNumDisabledChildren(data.clientTaxInit.numDisabledChildren);
                    if(classification=="CLIENT" && data.clientTaxInit){
                        setName(data.clientTaxInit.name);
                        setClasification(data.clientTaxInit.classification);
                        setTaxInit(data.clientTaxInit);
                        setMarried(data.clientTaxInit.married);
                        setCitizen(data.clientTaxInit.citizen);
                        setDisabled(data.clientTaxInit.disabled);
                        
                    }
                    if(classification=="PARTNER" && data.partnerTaxInit){
                        setName(data.partnerTaxInit.name);
                        setClasification(data.partnerTaxInit.classification);
                        setTaxInit(data.partnerTaxInit);
                        setMarried(data.partnerTaxInit.married);
                        setCitizen(data.partnerTaxInit.citizen);
                        setDisabled(data.partnerTaxInit.disabled);
                    }
                   
                }
               
                setTaxRules(data.taxRules);

            })    
        }
       
    }, [])

    function getNumberOf(val){
        if(val){
            return Number(val.replace(/[^0-9.-]+/g,""));
        }else{
            return 0;
        } 
       
    }

    function getTaxYearAssessmentOptions(){
        let options = [];
        options.push(<option key={""} value={0}></option>)
        if(taxRules){
            taxRules.forEach(r => {
                options.push(<option key={r.year} value={r.year}>{r.year}</option>)
            });
        }
        return options;
    }

    function getTaxRuleByYear(searchYear){
        let found;
        if(taxRules){
            taxRules.forEach(r => {
                if(r.year== searchYear){
                    found = r;
                }
            });
        }
        return found;
    }

    function onTaxYearAssessmentChanged(e){
        let val = parseInt(e.target.value);
        setTaxYearAssessment(val);
        let taxRuleSelected = getTaxRuleByYear(val);
        setSelectedTaxRule(taxRuleSelected);
        convertToArrTaxReliefs(taxRuleSelected);
    }

    function getSelectedTaxRuleTooltipTable(){
        let rows = [];
        let title ="";
        if(selectedTaxRule){
            title = selectedTaxRule.name;
            selectedTaxRule.items.forEach((r,index) => {
                rows.push(
                    <tr key={index}>
                        <td style={{width:"30%", textAlign:"center"}}>{ccySymbol}{numberWithCommas(r.incomeRangeStart)} - {ccySymbol}{numberWithCommas(r.incomeRangeEnd)}</td>
                        <td style={{width:"30%", textAlign:"center"}}>First {ccySymbol}{numberWithCommas(r.onFirst)} use {ccySymbol}{numberWithCommas(r.firstTaxAmount)}</td>
                        <td style={{width:"40%", textAlign:"center"}}>{r.onFirst==r.incomeRangeEnd?"-":`Next ${ccySymbol}${r.onNext} is ${ccySymbol}${numberWithCommas(r.nextTaxAmount)} or ${r.taxRatePct}%`}</td>
                    </tr>
                )
            });
        }
        return (
            <Tooltip 
                zIndex={9999}
                showArrow={true}
                placement="right"
                overlay={ 
                    <div className="cover" style={{color:"black"}}>
                        <h3 className="subtitle2">{title}</h3> 
                        <table className="table-blue">
                            <tbody>
                                <tr>
                                    <th>Chargeable Income Range</th>
                                    <th>On the First Deduction</th>
                                    <th>Next Deduction</th>
                                </tr>
                                {rows}

                            </tbody>
                        </table>
                        
                    </div>   
                
                
                }
                arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
            >
            <span>{title}</span>
          </Tooltip>
           
        )
    }

    function convertToArrTaxReliefs(taxRuleSelected){
        let result = [];
        if(taxRuleSelected && taxRuleSelected.taxReliefs){
            taxRuleSelected.taxReliefs.forEach(r => {
                result.push(
                    {...r,  value: 0, isReduction: false, desc: `Max. limit ${ccySymbol}${numberWithCommas(r.maxLimit)}`, pickerVisible:false}
                )
            });
        }
        setArrPersonalReliefs(result);
    }

    
    

    function getTotalIncome(){
        let result = getTotalAssessibleIncome() + totalNonAssessableIncome;
        return result;
    }
    function getTotalAssessibleIncome(){
        let result = totalNetEmploymentIncome + totalOtherIncome;
        return result;
    }
    
   
    function getNetAssessableIncome(){
        let result = totalNetEmploymentIncome + totalOtherIncome - totalAllowableDeductions;
        return result;
    }

    function getNetChargeableIncome(){
        let result = getNetAssessableIncome() - totalPersonalReliefs;
        return result;
    }

    function getMarginalTaxBracket(){
        let result = 0;
        let income = getNetChargeableIncome();
        if(selectedTaxRule && selectedTaxRule.items){
            selectedTaxRule.items.forEach(t => {
                if(income > t.incomeRangeStart && income < t.incomeRangeEnd){
                    result = t.taxRatePct;
                }
            });
        }
        return result;
    }

    function getTaxPayableOnChargeableIncome(){
        let total = 0;
        let calcDetails = [];
        let netChargeableIncome = getNetChargeableIncome();
        let remainingIncome = getNetChargeableIncome();
        
        let taxBracketKey = getMarginalTaxBracket();
        if(selectedTaxRule && selectedTaxRule.items){
            let len = selectedTaxRule.items.length;
            for (let index = 0; index < len; index++) {
                let t = selectedTaxRule.items[index];
                if(t.taxRatePct <= taxBracketKey ){
                    let tax = 0;
                    let chargeOn = 0;
                    if(remainingIncome > t.onNext){
                        if(t.onNext==0){
                            tax = t.firstTaxAmount
                            remainingIncome = remainingIncome - t.onFirst;
                            chargeOn = t.onFirst;
                        }else{
                            tax = t.nextTaxAmount
                            remainingIncome = remainingIncome - t.onNext;
                            chargeOn = t.onNext;
                        }
                        
                    }else{
                        tax = (t.taxRatePct / 100) * remainingIncome;
                        chargeOn = remainingIncome;
                        remainingIncome = 0;
                    }
                    
                    calcDetails.push(
                        {a:chargeOn, b:t.taxRatePct, tax:tax}

                    )
                    total = total + tax;
                    if(remainingIncome==0){
                        break;
                    }
                    
                    
                }
            };
        }
        return {
            netChargeableIncome: netChargeableIncome,
            taxPayable: total,
            details: calcDetails 
        };
    }

    function displayTaxPayableCalculationResult(){
        let calcResult = getTaxPayableOnChargeableIncome();
        let taxPayablePct = calcResult.taxPayable / calcResult.netChargeableIncome * 100;
        return(
            <div className="portal-form-row">
                <div className="col-md-6 text-bold">
                    Tax Payable on Chargeable Income
                   
                </div>
                <div className="col-md-2 text-bold">
                    <NumberFormat 
                        prefix={ccySymbol} thousandSeparator={THOUSAND_SEPARATOR} decimalSeparator={DECIMAL_SEPARATOR} decimalScale={DECIMAL_POINT}
                        className="text-input text-right" 
                        value={calcResult.taxPayable} 
                        readOnly={true}
                        disabled={true}
                    /> 
                </div>
                <div className="col-md-3">
                    <table className="table-blue">
                        <tbody>
                            { calcResult && calcResult.details && calcResult.details.map((det) => (
                                <tr>
                                    <td style={{textAlign:"right"}}>{ccySymbol}{numberWithCommas(det.a)}</td>
                                    <td style={{textAlign:"center"}}>{" x "}</td>
                                    <td style={{textAlign:"right"}}>{numberWithCommas(det.b)}{"%"}</td>
                                    <td style={{textAlign:"center"}}>{" = "}</td>
                                    <td style={{textAlign:"right"}}>{ccySymbol}{numberWithCommas(det.tax)}</td>
                                </tr>
                               
                            ))}
                            <tr>
                                <td colSpan="4" style={{textAlign:"right", fontWeight:"bold"}}>Total</td>
                                <td style={{textAlign:"right", fontWeight:"bold"}}>{ccySymbol}{numberWithCommas(calcResult.taxPayable)}</td>
                            </tr>
                            <tr>
                                <td colSpan="3" style={{textAlign:"right"}}>{ccySymbol}{numberWithCommas(calcResult.taxPayable)} {" / "} {ccySymbol}{numberWithCommas(calcResult.netChargeableIncome)} {"x 100%"}  </td>
                                <td style={{textAlign:"center"}}>{" = "}</td>
                                <td style={{textAlign:"right", fontWeight:"bold"}}>{numberWithCommas(taxPayablePct)}{"%"}</td>
                            </tr>

                        </tbody>
                    </table>
                   
                </div>
            </div>
        )
       
    }

    function getCustomTaxItemByKey(searchKey){
        let result;
        if(selectedTaxRule && selectedTaxRule.customTaxItems){
            selectedTaxRule.customTaxItems.forEach(c => {
                if(c.key == searchKey){
                    result = c;
                }
            });
        }
        return result;
    }

    function getNetRentalIncomeValue(){
        let rentalIncomes = getArrayItemByName(arrNonAssessableIncome, FIELD_PROPERTY_RENTAL_INCOME);
        let rentalExpenses = getArrayItemByName(arrNonAssessableIncome, FIELD_PROPERTY_RENTAL_EXPENSES);
        let result = (rentalIncomes?rentalIncomes.value:0) - (rentalExpenses?rentalExpenses.value:0);
        return result;
    }

    function getRentalIncomeTax(){
        let result = 0;
        let citizenRentalIncomeTax = getCustomTaxItemByKey("RENTAL_INCOME_CITIZEN");
        let citizenRentalIncomeTaxPct = citizenRentalIncomeTax ?citizenRentalIncomeTax.taxPct : 0 ;
        let nonCitizenRentalIncomeTax = getCustomTaxItemByKey("RENTAL_INCOME_NON_CITIZEN");
        let nonCitizenRentalIncomeTaxPct = nonCitizenRentalIncomeTax ?nonCitizenRentalIncomeTax.taxPct : 0 ;
        let val = getNetRentalIncomeValue();
        if(citizen){
            result = val * citizenRentalIncomeTaxPct/100 ;
        }else{
            result = val * nonCitizenRentalIncomeTaxPct/100;
        }
        return result;
    }

    function getRealPropertyGainTax(){
        let result = 0;
        let realPropertyGain1 = getArrayItemByName(arrNonAssessableIncome,FIELD_PROPERTY_GAIN_1);
        let realPropertyGain1Val = realPropertyGain1 && realPropertyGain1.value?realPropertyGain1.value:0;
        let selectedDisposal1 = realPropertyGain1 && realPropertyGain1.disposalType?realPropertyGain1.disposalType:0;

        let realPropertyGain2 = getArrayItemByName(arrNonAssessableIncome,FIELD_PROPERTY_GAIN_2);
        let selectedDisposal2 = realPropertyGain2?realPropertyGain2.disposalType:0;
        let realPropertyGain2Val = realPropertyGain2 && realPropertyGain2.value?realPropertyGain2.value:0;

        let gainTax1 = 0;
        let gainTax2 = 0;
        let tableTaxItem1;
        let tableTaxItem2;
        if(citizen){
            tableTaxItem1 = getCustomTaxItemByKey(`RPGT_CITIZEN_${selectedDisposal1}`);
            tableTaxItem2 = getCustomTaxItemByKey(`RPGT_CITIZEN_${selectedDisposal2}`);
           
        }else{
            tableTaxItem1 = getCustomTaxItemByKey(`RPGT_NON_CITIZEN_${selectedDisposal1}`);
            tableTaxItem2 = getCustomTaxItemByKey(`RPGT_NON_CITIZEN_${selectedDisposal2}`);
        }
        
        let tableTaxPct1 = tableTaxItem1?tableTaxItem1.taxPct : 0;
        let tableTaxPct2 = tableTaxItem2?tableTaxItem2.taxPct : 0;
        // remark: debugging purpose
        // console.log("married : ", married);
        // console.log("realPropertyGain1Val : ", realPropertyGain1Val);
        // console.log("realPropertyGain2Val : ", realPropertyGain2Val);
        // console.log("tableTaxPct1 : ", tableTaxPct1);
        // console.log("tableTaxPct2 : ", tableTaxPct2);

        if(tableTaxPct1 && tableTaxPct1>0){
            gainTax1 = realPropertyGain1Val * tableTaxPct1/100;
        }
        if(tableTaxPct2 && tableTaxPct2>0){
            gainTax2 = realPropertyGain2Val * tableTaxPct2/100;
        }

        result = gainTax1 + gainTax2;
        return result;
    }

    function getSelfRebate(){
        let result = 0;
        let selfRebateCondition = getCustomTaxItemByKey("SELF_REBATE_NON_MARRIED_CONDITION");
        let selfRebateConditionAmount = selfRebateCondition ?selfRebateCondition.taxAmount : 0 ;
        let selfRebateTax = getCustomTaxItemByKey("SELF_REBATE_NON_MARRIED_VALUE");
        let selfRebateTaxAmount = selfRebateTax ?selfRebateTax.taxAmount : 0 ;
        let netChargeableIncome = getNetChargeableIncome();
        if(!married && netChargeableIncome>0 && netChargeableIncome < selfRebateConditionAmount){
            result = selfRebateTaxAmount;
        }
        return result;
    }

    function getWifeHusbandTaxRebate(){
        let result = 0;
        let selfRebateCondition = getCustomTaxItemByKey("SELF_REBATE_NON_MARRIED_CONDITION");
        let selfRebateConditionAmount = selfRebateCondition ?selfRebateCondition.taxAmount : 0 ;
        let selfRebateTax = getCustomTaxItemByKey("SELF_REBATE_NON_MARRIED_VALUE");
        let selfRebateTaxAmount = selfRebateTax ?selfRebateTax.taxAmount : 0 ;
        let netChargeableIncome = getNetChargeableIncome();
        if(!married && netChargeableIncome>0 && netChargeableIncome < selfRebateConditionAmount){
            result = selfRebateTaxAmount;
        }
        return result;
    }

    

    function getNetTaxPayable(){
        let tax = getTaxPayableOnChargeableIncome();
        let addition = (tax && tax.taxPayable?tax.taxPayable : 0) + getRentalIncomeTax() + getRealPropertyGainTax() ;
        let rebate = getSelfRebate() + getWifeHusbandTaxRebate() + zakatFitrah ;
        let result = addition - rebate;
        return result;
    }

    function getAverageTaxRate(){
        let result = 0;
        let netTaxPayable = getNetTaxPayable();
        let netChargeableIncome = getNetChargeableIncome();
        if(netChargeableIncome && netChargeableIncome>0){
            result = netTaxPayable/netChargeableIncome * 100;
        }
        return result;
    }

    function getContributionSosco(){
        let result = 0;
        let soscoContrib = getCustomTaxItemByKey("SOSCO_CONTRIBUTION");
        let soscoMinMonthlyContrib = getCustomTaxItemByKey("SOSCO_MIN_MONTHLY");
        let soscoContribPct = soscoContrib ?soscoContrib.taxPct : 0 ;
        let soscoMinMonthlyContribVal = soscoMinMonthlyContrib ?soscoMinMonthlyContrib.taxAmount : 0 ;
        let yearlyMin = soscoMinMonthlyContribVal * 12; 
        if(totalNetEmploymentIncome < yearlyMin){
            yearlyMin = totalNetEmploymentIncome;
        }
        result = soscoContribPct/100 * yearlyMin;
        return result;
    }
    
    function getDisposalTypeOptions(){
        let result = [];
        result.push(
            <option key={"1"} value={1}>Disposal 1st year</option>
        )
        result.push(
            <option key={"2"} value={2}>Disposal 2nd year</option>
        )
        result.push(
            <option key={"3"} value={3}>Disposal 3rd year</option>
        )
        result.push(
            <option key={"4"} value={4}>Disposal 4th year</option>
        )
        result.push(
            <option key={"5"} value={5}>Disposal 5th year</option>
        )
        result.push(
            <option key={"6"} value={6}>Disposal 6th year</option>
        )
        return result;
    }
    
    
    function TotalIncomeSection(){
        let rows = [];
        let defaultNewRowItem =  {name : "", value: 0, isReduction: false, desc: "", pickerVisible:false}  
        if(arrEmploymentIncome){
            arrEmploymentIncome.forEach((item, index) => {
                rows.push(
                    <div key={index} className="portal-form-row">
                        <div className="col-md-6">
                            <input type="text" className="text-input" defaultValue={item.name} 
                                onBlur={(e)=> onArrayItemNameBlured(SECTION_KEY_EMP, arrEmploymentIncome, index, e.target.value)}
                                onKeyDown={(e)=>onArrayItemNameKeyDown(SECTION_KEY_EMP, arrEmploymentIncome, index, e)}
                            />
                             
                        </div>
                        <div className="col-md-2" style={{whiteSpace:"nowrap"}}> 
                            <NumberFormat 
                                prefix={ccySymbol} thousandSeparator={THOUSAND_SEPARATOR} decimalSeparator={DECIMAL_SEPARATOR} decimalScale={DECIMAL_POINT}
                                className="text-input text-right" 
                                value={item.value}
                                onKeyDown={(e)=>onArrayItemKeyDown(SECTION_KEY_EMP, arrEmploymentIncome, index, e)} 
                                onBlur={(e)=>onArrayItemBlured(SECTION_KEY_EMP, arrEmploymentIncome, index, e.target.value)} 
                                readOnly={false}
                                disabled={false}
                            /> 
                            <TooltipPicker
                                arrType={SECTION_KEY_EMP}
                                arr = {arrEmploymentIncome}
                                itemIndex={index}
                            />
                            <span 
                                className="btn-remove" 
                                onClick={(e)=>onDeleteArrayItemClicked(SECTION_KEY_EMP, arrEmploymentIncome, index)}>
                            </span>
                        </div>
                      
                        <div className="col-md-3" style={{marginLeft:"3em"}}>
                            {item.desc}
                        </div>
                    </div>
                )
               
            });
        }


        return (
            <>
                <div className="portal-form-row">
                    <div className="col-md-4">
                        <h3 className="subtitle2b">{intl.get("taxation.estimator.subtitle.empIncome")}</h3>
                    </div>
                    <div className="col-md-8">
                        <button className="btn btn-plus" onClick={(e)=>setArrEmploymentIncome([...arrEmploymentIncome, defaultNewRowItem])}></button>
                        &nbsp;
                        <button className="btn btn-empty fa fa-trash" onClick={(e)=>setArrEmploymentIncome([])}></button>
                    </div>
                    
                </div>
                {rows}
                <div className="portal-form-row">
                    <div className="col-md-6 text-right text-bold">
                        Net {intl.get("taxation.estimator.subtitle.empIncome")}
                    </div>
                    <div className="col-md-2 text-bold">
                        <NumberFormat 
                            prefix={ccySymbol} thousandSeparator={THOUSAND_SEPARATOR} decimalSeparator={DECIMAL_SEPARATOR} decimalScale={DECIMAL_POINT}
                            className="text-input text-right" 
                            value={totalNetEmploymentIncome} 
                            readOnly={true}
                            disabled={true}
                        /> 
                    </div>
                    <div className="col-md-3">

                    </div>
                </div>
            </>
        )
    }
    
    function OtherIncomeSection(){
        let rows = [];
        let defaultNewRowItem =  {name : "", value: 0, isReduction: false, desc: "", pickerVisible:false}  
        if(arrOtherIncome){
            arrOtherIncome.forEach((item, index) => {
                rows.push(
                    <div key={index} className="portal-form-row">
                        <div className="col-md-6">
                            <input type="text" className="text-input" defaultValue={item.name} 
                                onBlur={(e)=> onArrayItemNameBlured(SECTION_KEY_OTHER, arrOtherIncome, index, e.target.value)}
                                onKeyDown={(e)=>onArrayItemNameKeyDown(SECTION_KEY_OTHER, arrOtherIncome, index, e)}
                            />
                        </div>
                        <div className="col-md-2" style={{whiteSpace:"nowrap"}}>
                            <NumberFormat 
                                prefix={ccySymbol} thousandSeparator={THOUSAND_SEPARATOR} decimalSeparator={DECIMAL_SEPARATOR} decimalScale={DECIMAL_POINT}
                                className="text-input text-right" 
                                value={item.value}
                                onKeyDown={(e)=>onArrayItemKeyDown(SECTION_KEY_OTHER, arrOtherIncome, index, e)} 
                                onBlur={(e)=>onArrayItemBlured(SECTION_KEY_OTHER, arrOtherIncome, index, e.target.value)} 
                                readOnly={false}
                                disabled={false}
                            /> 
                            <TooltipPicker
                                arrType={SECTION_KEY_OTHER}
                                arr = {arrOtherIncome}
                                itemIndex={index}
                            />
                            <span 
                                className="btn-remove" 
                                onClick={(e)=>onDeleteArrayItemClicked(SECTION_KEY_OTHER, arrOtherIncome, index)}>
                            </span>
                        </div>
                        <div className="col-md-3" style={{marginLeft:"3em"}}>
                            {item.desc}
                        </div>
                    </div>
                )
               
            });
        }

        return (
            <>
                <div className="portal-form-row">
                    <div className="col-md-4">
                        <h3 className="subtitle2b">Add: {intl.get("taxation.estimator.subtitle.otherIncome")}</h3>
                    </div>
                    <div className="col-md-8">
                        <button className="btn btn-plus" onClick={(e)=>setArrOtherIncome([...arrOtherIncome, defaultNewRowItem])}></button>
                        &nbsp;
                        <button className="btn btn-empty fa fa-trash" onClick={(e)=>setArrOtherIncome([])}></button>
                    </div>
                    
                </div>
                {rows}
                <div className="portal-form-row">
                    <div className="col-md-6 text-right text-bold">
                        Total {intl.get("taxation.estimator.subtitle.otherIncome")}
                    </div>
                    <div className="col-md-2 text-bold">
                        <NumberFormat 
                            prefix={ccySymbol} thousandSeparator={THOUSAND_SEPARATOR} decimalSeparator={DECIMAL_SEPARATOR} decimalScale={DECIMAL_POINT}
                            className="text-input text-right" 
                            value={totalOtherIncome} 
                            readOnly={true}
                            disabled={true}
                        /> 
                    </div>
                    <div className="col-md-3">

                    </div>
                </div>
                
            </>
        )
    }

    
    function NonAssessableIncome(){
        let rows = [];
        let defaultNewRowItem =  {name : "", value: 0, isReduction: false, desc: "", pickerVisible:false}  
        if(arrNonAssessableIncome){
            arrNonAssessableIncome.forEach((item, index) => {
                rows.push(
                    <div key={index} className="portal-form-row">
                         {item.disposalType && (
                            <>
                                <div className="col-md-4">
                                    <input type="text" className="text-input" defaultValue={item.name} 
                                        onBlur={(e)=> onArrayItemNameBlured(SECTION_KEY_NON_ASSESSIBLE, arrNonAssessableIncome, index, e.target.value)}
                                        onKeyDown={(e)=>onArrayItemNameKeyDown(SECTION_KEY_NON_ASSESSIBLE, arrNonAssessableIncome, index, e)}
                                    />
                                </div>
                                <div className="col-md-2">
                                    <select className="text-input" value={item.disposalType?item.disposalType:0} 
                                        onChange={(e)=>updateArrayItem(SECTION_KEY_NON_ASSESSIBLE, arrNonAssessableIncome,index, {...item, disposalType: e.target.value})}
                                    >
                                        {getDisposalTypeOptions()}
                                    </select>
                                </div>
                            </>
                           
                         )}

                        {!item.disposalType && (
                            <div className="col-md-6">
                                <input type="text" className="text-input" defaultValue={item.name} 
                                    onBlur={(e)=> onArrayItemNameBlured(SECTION_KEY_NON_ASSESSIBLE, arrNonAssessableIncome, index, e.target.value)}
                                    onKeyDown={(e)=>onArrayItemNameKeyDown(SECTION_KEY_NON_ASSESSIBLE, arrNonAssessableIncome, index, e)}
                                />
                            </div>
                        )}
                         
                        <div className="col-md-2" style={{whiteSpace:"nowrap"}}>
                            <NumberFormat 
                                prefix={ccySymbol} thousandSeparator={THOUSAND_SEPARATOR} decimalSeparator={DECIMAL_SEPARATOR} decimalScale={DECIMAL_POINT}
                                className="text-input text-right" 
                                value={item.value}
                                onKeyDown={(e)=>onArrayItemKeyDown(SECTION_KEY_NON_ASSESSIBLE, arrNonAssessableIncome, index, e)} 
                                onBlur={(e)=>onArrayItemBlured(SECTION_KEY_NON_ASSESSIBLE, arrNonAssessableIncome, index, e.target.value)} 
                                readOnly={false}
                                disabled={false}
                            /> 
                            <TooltipPicker
                                arrType={SECTION_KEY_NON_ASSESSIBLE}
                                arr = {arrNonAssessableIncome}
                                itemIndex={index}
                            />
                            <span 
                                className="btn-remove" 
                                onClick={(e)=>onDeleteArrayItemClicked(SECTION_KEY_NON_ASSESSIBLE, arrNonAssessableIncome, index)}>
                            </span>
                        </div>
                        <div className="col-md-3" style={{marginLeft:"3em"}}>
                            {item.desc}
                        </div>
                    </div>
                )
               
            });
        }

        return (
            <>
                <div className="portal-form-row">
                    <div className="col-md-4">
                        <h3 className="subtitle2b">{intl.get("taxation.estimator.subtitle.nonAssessableIncome")}</h3>
                    </div>
                    <div className="col-md-8">
                        <button className="btn btn-plus" onClick={(e)=>setArrNonAssessableIncome([...arrNonAssessableIncome, defaultNewRowItem])}></button>
                        &nbsp;
                        <button className="btn btn-empty fa fa-trash" onClick={(e)=>setArrNonAssessableIncome([])}></button>
                    </div>
                    
                </div>
                {rows}
                <div className="portal-form-row">
                    <div className="col-md-6 text-right text-bold">
                        Total {intl.get("taxation.estimator.subtitle.nonAssessableIncome")}
                    </div>
                    <div className="col-md-2">
                        <NumberFormat 
                            prefix={ccySymbol} thousandSeparator={THOUSAND_SEPARATOR} decimalSeparator={DECIMAL_SEPARATOR} decimalScale={DECIMAL_POINT}
                            className="text-input text-right" 
                            value={totalNonAssessableIncome} 
                            readOnly={true}
                            disabled={true}
                        /> 
                    </div>
                    <div className="col-md-3">

                    </div>
                </div>
            </>
        )
    }
   
    function AllowableDeductionSection(){
        let rows = [];
        let defaultNewRowItem =  {name : "", value: 0, isReduction: false, desc: "", pickerVisible:false}  
        
        if(arrAllowableDeductions){
            arrAllowableDeductions.forEach((item, index) => {
                rows.push(
                    <div key={index} className="portal-form-row">
                        <div className="col-md-6">
                            <input type="text" className="text-input" defaultValue={item.name} 
                                onBlur={(e)=> onArrayItemNameBlured(SECTION_KEY_ALLOWABLE_DEDUCT, arrAllowableDeductions, index, e.target.value)}
                                onKeyDown={(e)=>onArrayItemNameKeyDown(SECTION_KEY_ALLOWABLE_DEDUCT, arrAllowableDeductions, index, e)}
                            />
                        </div>
                        <div className="col-md-2" style={{whiteSpace:"nowrap"}}>
                            <NumberFormat 
                                prefix={ccySymbol} thousandSeparator={THOUSAND_SEPARATOR} decimalSeparator={DECIMAL_SEPARATOR} decimalScale={DECIMAL_POINT}
                                className="text-input text-right" 
                                value={item.value}
                                onKeyDown={(e)=>onArrayItemKeyDown(SECTION_KEY_ALLOWABLE_DEDUCT, arrAllowableDeductions, index, e)} 
                                onBlur={(e)=>onArrayItemBlured(SECTION_KEY_ALLOWABLE_DEDUCT, arrAllowableDeductions, index, e.target.value)} 
                                readOnly={false}
                                disabled={false}
                            /> 
                            <TooltipPicker
                                arrType={SECTION_KEY_ALLOWABLE_DEDUCT}
                                arr = {arrAllowableDeductions}
                                itemIndex={index}
                            />
                            <span 
                                className="btn-remove" 
                                onClick={(e)=>onDeleteArrayItemClicked(SECTION_KEY_ALLOWABLE_DEDUCT, arrAllowableDeductions, index)}>
                            </span>
                        </div>
                        <div className="col-md-3" style={{marginLeft:"3em"}}>
                            {item.desc}
                        </div>
                    </div>
                )
               
            });
        }

        return (
            <>
               
                <div className="portal-form-row">
                    <div className="col-md-4">
                        <h3 className="subtitle2b">Less: {intl.get("taxation.estimator.subtitle.allowableDeductions")}</h3>
                    </div>
                    <div className="col-md-8">
                        <button className="btn btn-plus" onClick={(e)=>setArrAllowableDeductions([...arrAllowableDeductions, defaultNewRowItem])}></button>
                        &nbsp;
                        <button className="btn btn-empty fa fa-trash" onClick={(e)=>setArrAllowableDeductions([])}></button>
                    </div>
                    
                </div>
                {rows}
                <div className="portal-form-row">
                    <div className="col-md-6 text-right text-bold">
                        Total Approved {intl.get("taxation.estimator.subtitle.allowableDeductions")}
                    </div>
                    <div className="col-md-2">
                        <NumberFormat 
                            prefix={ccySymbol} thousandSeparator={THOUSAND_SEPARATOR} decimalSeparator={DECIMAL_SEPARATOR} decimalScale={DECIMAL_POINT}
                            className="text-input text-right" 
                            value={totalAllowableDeductions} 
                            readOnly={true}
                            disabled={true}
                        /> 
                    </div>
                    <div className="col-md-3">

                    </div>
                </div>
            </>
        )
    }

    function PersonalReliefsSection(){
        let rows = [];
        if(arrPersonalReliefs){
            arrPersonalReliefs.forEach((item, index) => {
                rows.push(
                    <div key={index} className="portal-form-row">
                        <div className="col-md-6">
                            {item.name}
                        </div>
                        <div className="col-md-2">
                            <NumberFormat 
                                prefix={ccySymbol} thousandSeparator={THOUSAND_SEPARATOR} decimalSeparator={DECIMAL_SEPARATOR} decimalScale={DECIMAL_POINT}
                                className="text-input text-right" 
                                value={item.value}
                                onKeyDown={(e)=>onArrayItemKeyDown(SECTION_KEY_PERSONAL_RELIEFS, arrPersonalReliefs, index, e)} 
                                onBlur={(e)=>onArrayItemBlured(SECTION_KEY_PERSONAL_RELIEFS, arrPersonalReliefs, index, e.target.value)} 
                                readOnly={false}
                                disabled={false}
                                isAllowed={(values) => {
                                    const {floatValue} = values;
                                    const max = item.maxLimit?item.maxLimit:999999;
                                    return floatValue <= max;
                                  }
                                }
                            /> 
                        </div>
                        <div className="col-md-3">
                            {item.desc}
                        </div>
                    </div>
                )
               
            });
        }

        return (
            <>
                <div className="portal-form-row">
                    <div className="col-md-4">
                        <h3 className="subtitle2b">Less: {intl.get("taxation.estimator.subtitle.personalReliefs")}</h3>
                    </div>
                    <div className="col-md-8">
                        
                    </div>
                </div>
                
                {rows}
                <div className="portal-form-row">
                    <div className="col-md-6 text-right text-bold">
                        Total {intl.get("taxation.estimator.subtitle.personalReliefs")}
                    </div>
                    <div className="col-md-2">
                        <NumberFormat 
                            prefix={ccySymbol} thousandSeparator={THOUSAND_SEPARATOR} decimalSeparator={DECIMAL_SEPARATOR} decimalScale={DECIMAL_POINT}
                            className="text-input text-right" 
                            value={totalPersonalReliefs} 
                            readOnly={true}
                            disabled={true}
                        /> 
                    </div>
                    <div className="col-md-3">

                    </div>
                </div>
            </>
        )
    }

    function onArrayItemBlured(arrType, arr, updatedIndex, strVal){
        let val = getNumberOf(strVal);
        let old = arr[updatedIndex];
        let updatedItem = {name:old.name, isReduction: old.isReduction, desc:old.desc, pickerVisible:old.pickerVisible, value: val };
        
        updateArrayItem(arrType, arr, updatedIndex, updatedItem);
    }

    function onArrayItemKeyDown(arrType, arr, updatedIndex, e){
        if(e.keyCode===13){
            onArrayItemBlured(arrType, arr, updatedIndex, e.target.value)
        }
       
    }

    function onArrayItemNameBlured(arrType, arr, updatedIndex, strVal){
        let old = arr[updatedIndex];
        let updatedItem = {value:old.value, isReduction: old.isReduction, desc:old.desc, pickerVisible:old.pickerVisible, name: strVal };
        
        updateArrayItem(arrType, arr, updatedIndex, updatedItem);
    }

    function getArrayItemByName(arr, searchName){
        let found;
        if(arr){
            for (let index = 0; index < arr.length; index++) {
                let item = arr[index];
                if(item.name && item.name == searchName){
                    found = item;
                    break;
                }
                
            };
        }
        return found;
    }

    function onArrayItemNameKeyDown(arrType, arr, index, e){
        if(e.keyCode===13){
            let item = arr[index];
            updateArrayItem(arrType, arr, index, {...item, name: e.target.value})
        }
       
    }

    function onDeleteArrayItemClicked(arrType, arr, deletedIndex){
        let newArr = [];
        if(arrType && arr){
            let total = 0;
            arr.forEach((itm,index) => {
                if(index==deletedIndex){
                   
                }else{
                    total = total + itm.value;
                    newArr.push(itm);
                }
            });
            updateArrayValuesAndTotal(arrType, newArr, total);
           
        }
       
    }

    
    function updateArrayItem(arrType, arr, updatedIndex, updatedItem){
        if(arrType && arr){
            let newArr = [];
            let total = 0;
            arr.forEach((item, index) => {
                if(index==updatedIndex){
                    if(updatedItem.isReduction){
                        total = total - updatedItem.value;
                    }else{
                        total = total + updatedItem.value;
                    }
                   
                    newArr.push(updatedItem)     
                }else{  
                    if(item.isReduction){
                        total = total - item.value;
                    }else{
                        total = total + item.value;
                    }
                    newArr.push(item);
                }
            });
            updateArrayValuesAndTotal(arrType, newArr, total);
            
        }
            
        
    }

    function updateArrayValuesAndTotal(arrType, newArr, total){
        if(arrType == SECTION_KEY_EMP){
            setArrEmploymentIncome(newArr);
            setTotalNetEmploymentIncome(total);
        }else if(arrType == SECTION_KEY_OTHER){
            setArrOtherIncome(newArr);
            setTotalOtherIncome(total);
        }else if(arrType == SECTION_KEY_NON_ASSESSIBLE){
            setArrNonAssessableIncome(newArr);
            setTotalNonAssessableIncome(total);
        }else if(arrType == SECTION_KEY_ALLOWABLE_DEDUCT){
            setArrAllowableDeductions(newArr);
            setTotalAllowableDeductions(total);
        }else if(arrType == SECTION_KEY_PERSONAL_RELIEFS){
            setArrPersonalReliefs(newArr);
            setTotalPersonalReliefs(total);
        }
    }

    const TooltipPicker=({arrType, arr, itemIndex})=>{
        let fontStyle = {color:"black"};
        let title = "Pick from Current Data"
        let item = arr[itemIndex];
        let menuItems = [];
        if(taxInit && taxInit.pickValueList){
            taxInit.pickValueList.forEach((p,index) => {
                menuItems.push(
                    <tr key={index} className="link" style={fontStyle} onClick={(e)=>{onPickerValueClicked(p)}}>
                      <td style={{width:"60%"}}>{p.name}</td>
                      <td style={{width:"40%", textAlign:"right"}}>{ccySymbol}{numberWithCommas(p.value)}</td>
                    </tr>
                 )
            });
        }

        function onPickerValueClicked(p){
            let selectedName = p.name;
            let selectedValue = p.value;
            updateArrayItem(arrType, arr, itemIndex, {...item, value: selectedValue, pickerVisible:false});
        }

        function onPickerClicked(visible){
            updateArrayItem(arrType, arr, itemIndex, {...item, pickerVisible:visible});
        }
      
        return  (
            <Tooltip 
                id= {item.name}
                visible={item.pickerVisible}
                trigger={"click"}
                onClick={(e)=>onPickerClicked(true)}
                zIndex={9999}
                showArrow={true}
                placement="right"
                overlay={ 
                    <div className="cover">
                        <div className="page-title">
                            {title}
                            <div className="close-holder">
                                <span className="close-button" 
                                onClick={(e)=>onPickerClicked(false)}/>
                            </div>
                        </div>
                        <div className="portal-form-row mt-4" style={fontStyle}>
                            Please click row below to select or get value for {item.name}
                        </div>
                        <table className="table-blue">
                                <tbody>
                                    <tr>
                                        <th>Name</th>
                                        <th>Value</th>
                                        
                                    </tr>
                                    {menuItems}
                                </tbody>
                            </table>
                        
                    </div>   
                
                  
                }
                arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
              >
                <span className="btn btn-edit"></span>
              </Tooltip>
        )
            
    }

    
   function getChildrenInfo(){
        let result = "";
        if(numDisabledChildren>0){
            result = `${numChildren} (${numDisabledChildren} disabled)`
        }else{
            result = `${numChildren}`
        }
        return result;
   }
   
       
    
    return (
        <div className="notification-dialog">
            <div className="portal-form-row">
                <div className="col-md-4">
                    <h3 className="subtitle2b">{name}'s {intl.get("taxation.estimator.subtitle.taxAssessment")}</h3>
                </div>
                <div className="col-md-8">
                    
                </div>
            </div>
            <div className="portal-form-row">
                <div className="col-md-10">
                    <div className="input-check input-check-alert">
                        <input id="chkMarried" type="checkbox" checked={married?true:false} readOnly={true}/>
                        <label htmlFor="chkMarried">{intl.get("taxation.estimator.field.marriageStatus")}</label>
                        <input id="chkCitizen" type="checkbox" checked={citizen?true:false} readOnly={true}/>
                        <label htmlFor="chkCitizen">{intl.get("taxation.estimator.field.citizen")}</label>
                        <input id="chkDisabled" type="checkbox" checked={disabled?true:false} readOnly={true}/>
                        <label htmlFor="chkDisabled">{intl.get("taxation.estimator.field.disabled")}</label>
                    </div>
                   
                </div>
                
            </div>

            <div className="portal-form-row">
                <div className="col-md-3 ">
                    <input type="text" className="text-input" value={intl.get("taxation.estimator.field.dependantParents")} readOnly={true}/> 
                </div>
                <div className="col-md-2">
                    <div>
                        <input className="text-input" value={numParents} readOnly={true}/>
                    </div>
                </div>
                
                <div className="col-md-3">
                    <input type="text" className="text-input" value={intl.get("taxation.estimator.field.dependantChildren")} readOnly={true}/> 
                </div>
                <div className="col-md-2">
                    <input  className="text-input"  value={getChildrenInfo()} readOnly={true}/> 
                </div>
                
            </div>
            <div className="portal-form-row">
                <div className="col-md-3">
                    <input type="text" className="text-input" value={intl.get("taxation.estimator.field.yearAssessment")} readOnly={true}/> 
                </div>
                <div className="col-md-2 select-box">
                    <select value={taxYearAssessment} onChange={(e)=>onTaxYearAssessmentChanged(e)}>
                        {getTaxYearAssessmentOptions()}
                    </select>
                </div>
                {taxRules && taxRules.length==0 && (
                    <div className="col-md-5">
                        <span className="text-redchili">*Please contact your administrator to configure tax year assessment rules</span>
                    </div>
                )}
               
               {selectedTaxRule && (
                <>
                    <div className="col-md-3">
                        <input type="text" className="text-input" value={intl.get("taxation.estimator.field.taxRuleName")} readOnly={true}/> 
                    </div>
                    <div className="col-md-2">
                        {getSelectedTaxRuleTooltipTable()}
                    </div>
                </>
                 
               )}
                
            </div>
            <br/>
            {taxYearAssessment!=0 && (
                <>
                <TotalIncomeSection/>
                <OtherIncomeSection/>
                <div className="portal-form-row">
                        <div className="col-md-6 text-right text-bold">
                            {intl.get("taxation.estimator.field.totalAssessableIncome")}
                        </div>
                        <div className="col-md-2 text-bold">
                            <NumberFormat 
                            prefix={ccySymbol} thousandSeparator={THOUSAND_SEPARATOR} decimalSeparator={DECIMAL_SEPARATOR} decimalScale={DECIMAL_POINT}
                            className="text-input text-right" 
                            value={getTotalAssessibleIncome()} 
                            readOnly={true}
                            disabled={true}
                            /> 
                        </div>
                        <div className="col-md-3">

                        </div>
                    </div>
                <NonAssessableIncome/>
                <div className="portal-form-row">
                    <div className="col-md-6 text-right text-bold">
                        {intl.get("taxation.estimator.field.totalIncome")}
                    </div>
                    <div className="col-md-2 text-bold">
                        <NumberFormat 
                            prefix={ccySymbol} thousandSeparator={THOUSAND_SEPARATOR} decimalSeparator={DECIMAL_SEPARATOR} decimalScale={DECIMAL_POINT}
                            className="text-input text-right" 
                            value={getTotalIncome()} 
                            readOnly={true}
                            disabled={true}
                        />
                    
                    </div>
                </div>

                <AllowableDeductionSection/>
                <div className="portal-form-row">
                    <div className="col-md-6 text-right text-bold">
                        {intl.get("taxation.estimator.field.netAssessableIncome")}
                    </div>
                    <div className="col-md-2 text-bold">
                        <NumberFormat 
                            prefix={ccySymbol} thousandSeparator={THOUSAND_SEPARATOR} decimalSeparator={DECIMAL_SEPARATOR} decimalScale={DECIMAL_POINT}
                            className="text-input text-right" 
                            value={getNetAssessableIncome()} 
                            readOnly={true}
                            disabled={true}
                        /> 
                    </div>
                </div>

                <PersonalReliefsSection/>
                <div className="portal-form-row">
                    <div className="col-md-6 text-right text-bold">
                        {intl.get("taxation.estimator.field.netChargeableIncome")}
                    </div>
                    <div className="col-md-2 text-bold">
                        <NumberFormat 
                            prefix={ccySymbol} thousandSeparator={THOUSAND_SEPARATOR} decimalSeparator={DECIMAL_SEPARATOR} decimalScale={DECIMAL_POINT}
                            className="text-input text-right" 
                            value={getNetChargeableIncome()} 
                            readOnly={true}
                            disabled={true}
                        /> 
                    </div>
                </div>

                

                {/*Main calculation tax section */}
                <div className="portal-form-row">
                    <div className="col-md-5 text-bold">
                        {intl.get("taxation.estimator.field.marginalTaxBracket")}
                    </div>
                    <div className="col-md-1 ">
                        {getSelectedTaxRuleTooltipTable()}
                    </div>
                    <div className="col-md-2 text-bold">
                        <NumberFormat 
                            suffix={"%"} decimalScale={DECIMAL_POINT}
                            className="text-input text-right" 
                            value={getMarginalTaxBracket()}
                            readOnly={true}
                            disabled={true}
                        /> 
                    </div>
                </div>
                {displayTaxPayableCalculationResult()}

                {/*Additional tax section */}
                <div className="portal-form-row">
                    <div className="col-md-6">
                        {intl.get("taxation.estimator.field.plusRentalIncomeTax")}
                    </div>
                    <div className="col-md-2">
                        <NumberFormat 
                        prefix={ccySymbol} thousandSeparator={THOUSAND_SEPARATOR} decimalSeparator={DECIMAL_SEPARATOR} decimalScale={DECIMAL_POINT}
                            className="text-input text-right" 
                            value={getRentalIncomeTax()}
                            readOnly={true}
                            disabled={true}
                        /> 
                    </div>
                </div>
                <div className="portal-form-row">
                    <div className="col-md-6">
                        {intl.get("taxation.estimator.field.plusRealPropertyGainTax")}
                    </div>
                    <div className="col-md-2">
                        <NumberFormat 
                        prefix={ccySymbol} thousandSeparator={THOUSAND_SEPARATOR} decimalSeparator={DECIMAL_SEPARATOR} decimalScale={DECIMAL_POINT}
                            className="text-input text-right" 
                            value={getRealPropertyGainTax()}
                            readOnly={true}
                            disabled={true}
                        /> 
                    </div>
                </div>
                <div className="portal-form-row">
                    <div className="col-md-6">
                        {intl.get("taxation.estimator.field.lessSelfRebate")}
                    </div>
                    <div className="col-md-2">
                        <NumberFormat 
                        prefix={ccySymbol} thousandSeparator={THOUSAND_SEPARATOR} decimalSeparator={DECIMAL_SEPARATOR} decimalScale={DECIMAL_POINT}
                            className="text-input text-right" 
                            value={getSelfRebate()}
                            readOnly={true}
                            disabled={true}
                        /> 
                    </div>
                </div>
                <div className="portal-form-row">
                    <div className="col-md-6">
                        {intl.get("taxation.estimator.field.lessWifeHusbandRebate")}
                    </div>
                    <div className="col-md-2">
                        <NumberFormat 
                            prefix={ccySymbol} thousandSeparator={THOUSAND_SEPARATOR} decimalSeparator={DECIMAL_SEPARATOR} decimalScale={DECIMAL_POINT}
                            className="text-input text-right" 
                            value={getWifeHusbandTaxRebate()}
                            readOnly={true}
                            disabled={true}
                        /> 
                    </div>
                </div>
                <div className="portal-form-row">
                    <div className="col-md-6">
                        {intl.get("taxation.estimator.field.lessZakatFitrah")}
                    </div>
                    <div className="col-md-2">
                        <NumberFormat 
                            prefix={ccySymbol} thousandSeparator={THOUSAND_SEPARATOR} decimalSeparator={DECIMAL_SEPARATOR} decimalScale={DECIMAL_POINT}
                            className="text-input text-right" 
                            value={zakatFitrah}
                            onChange={(e)=>setZakatFitrah(getNumberOf(e.target.value))} 
                            readOnly={false}
                            disabled={false}
                        /> 
                    </div>
                </div>


                <div className="portal-form-row">
                    <div className="col-md-6 text-right text-bold">
                        {intl.get("taxation.estimator.field.netTaxPayable")}
                    </div>
                    <div className="col-md-2">
                        <NumberFormat 
                            prefix={ccySymbol} thousandSeparator={THOUSAND_SEPARATOR} decimalSeparator={DECIMAL_SEPARATOR} decimalScale={DECIMAL_POINT}
                            className="text-input text-right" 
                            value={getNetTaxPayable()} 
                            readOnly={true}
                            disabled={true}
                        /> 
                    </div>
                </div>
                <div className="portal-form-row">
                    <div className="col-md-6 text-right text-bold">
                        {intl.get("taxation.estimator.field.averageTaxRate")}
                    </div>
                    <div className="col-md-2">
                        <NumberFormat 
                            suffix={"%"} thousandSeparator={THOUSAND_SEPARATOR} decimalSeparator={DECIMAL_SEPARATOR} decimalScale={DECIMAL_POINT}
                            className="text-input text-right" 
                            value={getAverageTaxRate()} 
                            readOnly={true}
                            disabled={true}
                        /> 
                    </div>
                </div>

                <hr/>
                <div className="portal-form-row">
                    <div className="col-md-6 text-right text-bold">
                        {intl.get("taxation.estimator.field.contribToSosco")}
                    </div>
                    <div className="col-md-2">
                        <NumberFormat 
                        prefix={ccySymbol} thousandSeparator={THOUSAND_SEPARATOR} decimalSeparator={DECIMAL_SEPARATOR} decimalScale={DECIMAL_POINT}
                            className="text-input text-right" 
                            value={getContributionSosco()} 
                            readOnly={true}
                            disabled={true}
                        /> 
                    </div>
                </div>

                <br/>
                <br/>
                {getNetTaxPayable()!=0 && (
                    <div className="portal-form-row">
                        <div className="col-md-12 text-right">
                            <button className="portal-btn portal-btn-submit" onClick={(e)=>doSaveIntoTaxPayment()}>
                                Save
                            </button>
                        </div>
                    </div>
                )}
              
                </>
            )}
            

        </div>
        
    )
   
}
   

export default MalaysiaTaxEstimator;
