import React, { useContext, useState } from "react"
import { FormButtons, RenderSimpleFields } from "../../components"
import intl from "react-intl-universal"
import { Form } from "react-final-form"
import { FieldType } from "../../components/Form/RenderSimpleFields"
import { useEffect } from "react"
import createDecorator from "final-form-calculate"
import { AppContext } from "../../context/AppContext"
import { CountryList } from "../../constants/Lists"
import { translateListLabel } from "../../helpers/list-translation"

const DynamicPropertyPrice = ({
  cityList,
  currentCity,
  setFinalValue,
  setCurrencyValue,
  isInvestmentPropertyForm = false,
  values,
  mutators
}) => {
  const {
    modal: { setModal, clearModal }
  } = useContext(AppContext)
  const decorator = createDecorator(
    {
      field: "propertyDetails.propertySquareSizeUnit",
      updates: {
        "propertyDetails.currentValue": async (fooValue, allValues) => {
          const foundCityInfo =
            cityList &&
            cityList.length &&
            cityList.find((item) => item.city == currentCity)
          if (foundCityInfo) {
            if (
              fooValue == "SQUARE_METER" &&
              allValues.propertyDetails.propertySquareSize
            ) {
              setFinalValue(
                foundCityInfo.buyAvg *
                  0.092903 *
                  allValues.propertyDetails.propertySquareSize
              )
              return {
                currency: foundCityInfo.currency,
                value:
                  foundCityInfo.buyAvg *
                  0.092903 *
                  allValues.propertyDetails.propertySquareSize
              }
            } else {
              setFinalValue(
                foundCityInfo.buyAvg *
                  allValues.propertyDetails.propertySquareSize
              )
              return {
                currency: foundCityInfo.currency,
                value:
                  foundCityInfo.buyAvg *
                  allValues.propertyDetails.propertySquareSize
              }
            }
          }
        }
      }
    },
    {
      field: "propertyDetails.propertySquareSize",
      updates: {
        "propertyDetails.currentValue": async (fooValue, allValues) => {
          if (allValues.propertyDetails.propertySquareSize) {
            const foundCityInfo =
              cityList &&
              cityList.length &&
              cityList.find((item) => item.city == currentCity)
            if (foundCityInfo) {
              if (
                allValues.propertyDetails.propertySquareSizeUnit ==
                "SQUARE_METER"
              ) {
                setFinalValue(
                  foundCityInfo.buyAvg *
                    allValues.propertyDetails.propertySquareSize *
                    0.092903
                )
                return {
                  currency: foundCityInfo.currency,
                  value:
                    foundCityInfo.buyAvg *
                    allValues.propertyDetails.propertySquareSize *
                    0.092903
                }
              } else {
                setFinalValue(
                  foundCityInfo.buyAvg *
                    allValues.propertyDetails.propertySquareSize
                )
                return {
                  currency: foundCityInfo.currency,
                  value:
                    foundCityInfo.buyAvg *
                    allValues.propertyDetails.propertySquareSize
                }
              }
            }
          }
        }
      }
    },
    {
      field: "propertyDetails.currentValueCity",
      updates: {
        "propertyDetails.currentValue": async (fooValue, allValues) => {
          try {
            if (
              fooValue &&
              !allValues.propertyDetails.manualEstimatedValue &&
              cityList &&
              cityList.length
            ) {
              const foundCityInfo =
                cityList &&
                cityList.length &&
                cityList.find((item) => item.city == currentCity)

              if (foundCityInfo)
                setFinalValue(
                  foundCityInfo.buyAvg *
                    allValues.propertyDetails.propertySquareSize *
                    0.092903
                )
              if (
                allValues.propertyDetails.propertySquareSizeUnit ==
                "SQUARE_METER"
              )
                return {
                  currency: foundCityInfo.currency,
                  value:
                    foundCityInfo.buyAvg *
                    allValues.propertyDetails.propertySquareSize *
                    0.092903
                }
              else {
                setFinalValue(
                  foundCityInfo.buyAvg *
                    allValues.propertyDetails.propertySquareSize
                )
                return {
                  currency: foundCityInfo.currency,
                  value:
                    foundCityInfo.buyAvg *
                    allValues.propertyDetails.propertySquareSize
                }
              }
            } else {
              return allValues.propertyDetails.currentValue
            }
          } catch (error) {
            console.log("error is", error)
          }
        }
      }
    },

    {
      field: "propertyDetails.propertySquareUnitPrice",
      updates: {
        "propertyDetails.currentValue": async (fooValue, allValues) => {
          try {
            const foundCityInfo =
              cityList &&
              cityList.length &&
              cityList.find((item) => item.city == currentCity)

            if (foundCityInfo)
              if (
                allValues.propertyDetails.propertySquareSizeUnit ==
                "SQUARE_METER"
              ) {
                setFinalValue(
                  foundCityInfo.buyAvg *
                    allValues.propertyDetails.propertySquareSize *
                    0.092903
                )
                return {
                  currency: foundCityInfo.currency,
                  value:
                    foundCityInfo.buyAvg *
                    allValues.propertyDetails.propertySquareSize *
                    0.092903
                }
              } else {
                setFinalValue(
                  foundCityInfo.buyAvg *
                    allValues.propertyDetails.propertySquareSize
                )
                return {
                  currency: foundCityInfo.currency,
                  value:
                    foundCityInfo.buyAvg *
                    allValues.propertyDetails.propertySquareSize
                }
              }
          } catch (error) {
            console.log("error is", error)
          }
        }
      }
    }
  )
  const fields = [
    {
      name: "propertyLocation",
      label: intl.get("primaryResidencePropertyOwnership.propertyLocation"),
      type: FieldType.SELECTION,
      selectionData: translateListLabel(CountryList),
      valueProperty: "code",
      labelProperty: "name",
      required: false,
      readOnly: true
    },

    {
      name: "currentValueCity",
      label: intl.get("primaryResidencePropertyOwnership.city"),
      type: FieldType.SELECTION,
      selectionData: cityList,
      valueProperty: "city",
      labelProperty: "city",
      required: false,
      readOnly: true
    },
    {
      name: isInvestmentPropertyForm
        ? "propertySquareSizeUnit"
        : "propertyDetails.propertySquareSizeUnit",
      label: intl.get(
        "primaryResidencePropertyOwnership.propertySquareSizeUnit"
      ),
      type: FieldType.SELECTION,
      selectionData: [
        { value: "SQUARE_FEET", label: "Sq. Feet" },
        { value: "SQUARE_METER", label: "Sq. Meter" }
      ],
      valueProperty: "value",
      labelProperty: "label"
      // required: true
    },

    {
      name: isInvestmentPropertyForm
        ? "propertySquareSize"
        : "propertyDetails.propertySquareSize",
      label: intl.get("primaryResidencePropertyOwnership.propertySquareSize"),
      type: FieldType.NUMBER,
      allowNegative: false
      // required: true
    },
    {
      name: isInvestmentPropertyForm
        ? "propertySquareUnitPrice"
        : "propertyDetails.propertySquareUnitPrice",
      label: intl.get(
        "primaryResidencePropertyOwnership.propertySquareSizeUnitPrice"
      ),
      type: FieldType.MONEY_WITH_CURRENCY,
      // defaultCurrency: "USD",
      allowNegative: false,
      // required: true,
      readOnly: true
    },
    {
      name: isInvestmentPropertyForm
        ? "currentValue"
        : "propertyDetails.currentValue",
      label: intl.get("primaryResidencePropertyOwnership.currentValue"),
      type: FieldType.MONEY_WITH_CURRENCY,
      // defaultCurrency: "USD",
      // required: true,
      allowNegative: false,
      readOnly: true
    }
  ]
  const [initialData, setInitialData] = useState()
  useEffect(() => {
    if (cityList && cityList.length && currentCity) {
      const currentCityInfo = cityList.find((item) => item.city === currentCity)
      if (!isInvestmentPropertyForm) {
        setInitialData({
          propertyDetails: {
            propertySquareUnitPrice: {
              value: currentCityInfo.buyAvg,
              currency: currentCityInfo.currency
            }
          }
        })
      } else {
        setInitialData({
          propertySquareUnitPrice: {
            value: currentCityInfo.buyAvg,
            currency: currentCityInfo.currency
          }
        })
      }
      setCurrencyValue(currentCityInfo.currency)
    }
  }, [cityList, currentCity, isInvestmentPropertyForm])

  // investmentform
  const decoratorInvestmentForm = createDecorator(
    {
      field: "propertySquareSizeUnit",
      updates: {
        currentValue: async (fooValue, allValues) => {
          const foundCityInfo =
            cityList &&
            cityList.length &&
            cityList.find((item) => item.city == currentCity)
          if (foundCityInfo) {
            if (fooValue == "SQUARE_METER" && allValues.propertySquareSize) {
              setFinalValue(
                foundCityInfo.buyAvg * 0.092903 * allValues.propertySquareSize
              )
              mutators.setFormAttribute("currentValue", {
                currency: foundCityInfo.currency,
                value:
                  foundCityInfo.buyAvg * 0.092903 * allValues.propertySquareSize
              })
              return {
                currency: foundCityInfo.currency,
                value:
                  foundCityInfo.buyAvg * 0.092903 * allValues.propertySquareSize
              }
            } else {
              setFinalValue(foundCityInfo.buyAvg * allValues.propertySquareSize)
              mutators.setFormAttribute("currentValue", {
                currency: foundCityInfo.currency,
                value: foundCityInfo.buyAvg * allValues.propertySquareSize
              })
              return {
                currency: foundCityInfo.currency,
                value: foundCityInfo.buyAvg * allValues.propertySquareSize
              }
            }
          }
        }
      }
    },
    {
      field: "propertySquareSize",
      updates: {
        currentValue: async (fooValue, allValues) => {
          if (allValues.propertySquareSize) {
            const foundCityInfo =
              cityList &&
              cityList.length &&
              cityList.find((item) => item.city == currentCity)
            if (foundCityInfo) {
              if (allValues.propertySquareSizeUnit == "SQUARE_METER") {
                setFinalValue(
                  foundCityInfo.buyAvg * allValues.propertySquareSize * 0.092903
                )
                mutators.setFormAttribute("currentValue", {
                  currency: foundCityInfo.currency,
                  value:
                    foundCityInfo.buyAvg *
                    allValues.propertySquareSize *
                    0.092903
                })
                return {
                  currency: foundCityInfo.currency,
                  value:
                    foundCityInfo.buyAvg *
                    allValues.propertySquareSize *
                    0.092903
                }
              } else {
                setFinalValue(
                  foundCityInfo.buyAvg * allValues.propertySquareSize
                )
                mutators.setFormAttribute("currentValue", {
                  currency: foundCityInfo.currency,
                  value: foundCityInfo.buyAvg * allValues.propertySquareSize
                })
                return {
                  currency: foundCityInfo.currency,
                  value: foundCityInfo.buyAvg * allValues.propertySquareSize
                }
              }
            }
          }
        }
      }
    },

    {
      field: "propertySquareUnitPrice",
      updates: {
        currentValue: async (fooValue, allValues) => {
          try {
            const foundCityInfo =
              cityList &&
              cityList.length &&
              cityList.find((item) => item.city == currentCity)

            if (foundCityInfo)
              if (allValues.propertySquareSizeUnit == "SQUARE_METER") {
                setFinalValue(
                  foundCityInfo.buyAvg * allValues.propertySquareSize * 0.092903
                )
                mutators.setFormAttribute("currentValue", {
                  currency: foundCityInfo.currency,
                  value:
                    foundCityInfo.buyAvg *
                    allValues.propertySquareSize *
                    0.092903
                })
                return {
                  currency: foundCityInfo.currency,
                  value:
                    foundCityInfo.buyAvg *
                    allValues.propertySquareSize *
                    0.092903
                }
              } else {
                setFinalValue(
                  foundCityInfo.buyAvg * allValues.propertySquareSize
                )
                mutators.setFormAttribute("currentValue", {
                  currency: foundCityInfo.currency,
                  value: foundCityInfo.buyAvg * allValues.propertySquareSize
                })
                return {
                  currency: foundCityInfo.currency,
                  value: foundCityInfo.buyAvg * allValues.propertySquareSize
                }
              }
          } catch (error) {
            console.log("error is", error)
          }
        }
      }
    }
  )

  return (
    <div className="dynamic-price-dialog">
      <Form
        onSubmit={(values) => {
          clearModal()
        }}
        decorators={
          isInvestmentPropertyForm ? [decoratorInvestmentForm] : [decorator]
        }
        keepDirtyOnReinitialize={true}
        initialValues={{ ...initialData, ...values }}
        render={({ handleSubmit, form, reset, values: valuesForm }) => (
          <form onSubmit={handleSubmit}>
            <RenderSimpleFields fields={fields} />

            <FormButtons btnName={"Get Price"} />
          </form>
        )}
      />
    </div>
  )
}

export default DynamicPropertyPrice
