import React, { useContext, useEffect,  useState } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import intl from "react-intl-universal"
import { netWorthFormActions, modalWindowActions } from '../../../../actions';
import { modalWindow } from '../../../../constants/ModalWindowLists';

import NetWorthForm, {
    NetWorthPath,
    NetWorthFormPropsComposer,
} from '../../NetWorthForm';


import MPFForm from "./MPFForm";



class MandatoryProvidentFund extends NetWorthForm {
    constructor(props) {
        super(
            props,
            intl.get('mandatoryProvidentFund.title'),
            modalWindow.RETIREMENT_SCHEME_MPF,
            NetWorthPath.mandatoryProvidentFunds,
            'MandatoryProvidentFund'
        );
    }

   
  render() {
    const {
      optionsData,
      model,
      client,
      partner,
      isJoint,
      currency,
    } = this.props;
    const { handleSave, contentWrapper, readOnlyAccessComponent } = this;
    

    return contentWrapper(
       <MPFForm 
          handleSave = {handleSave}
          model={model} 
          client = {client}
          partner = {partner} 
          optionsData={optionsData} 
          isjoint = {isJoint}
       />
            
    )
  }
}

function mapStateToProps(state) {
  const props = NetWorthFormPropsComposer(
    state,
    modalWindow.RETIREMENT_SCHEME_MPF,
    NetWorthPath.mandatoryProvidentFunds
    );
    
    return {
        ...props,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        Object.assign({}, netWorthFormActions, modalWindowActions),
        dispatch
    );
}

MandatoryProvidentFund = connect(
    mapStateToProps,
    mapDispatchToProps
)(MandatoryProvidentFund);

export default MandatoryProvidentFund;
