/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Description : business ownership main page (modal)
*/

import React, { useState } from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import intl from "react-intl-universal"
import { Form, FormSpy } from "react-final-form"
import { Field } from "react-final-form"
import { FieldArray } from "react-final-form-arrays"
import { required } from "../helpers/Validator"

import { CountryList } from "../constants/Lists"
import { translateListLabel } from "../helpers/list-translation"
import { useSelector } from "react-redux"

import arrayMutators from "final-form-arrays"

import {
  fileFieldRender,
  renderCurrencyCustom,
  renderTextBoxCustom
} from "../helpers"

import {
  FormButtons,
  ClassificationSelection,
  ModalNoAccess
} from "../components"
import RenderSimpleFields, {
  FieldType
} from "../components/Form/RenderSimpleFields"

// sub-forms
import LoanDetails from "./sub-forms/LoanDetails"

const getFormMutator = (am) => ({
  ...am,
  ...{
    pushArray: am.push,
    removeArray: am.remove
  },
  setFormAttribute: ([fieldName, fieldVal], state, { changeValue }) => {
    changeValue(state, fieldName, () => fieldVal)
  }
})

function BusinessOwnershipForm({
  handleSave,
  model,
  optionsData,
  readOnlyAccess,
  showClassification = true,
  currency = localStorage.getItem("theCurrency")
}) {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  // const state = useSelector((state) => state)
  const state_networth_index = useSelector((state) => state.netWorthForm.index)

  const state_networth = useSelector((state) => state.netWorthForm.data)

  let getloan
  if (state_networth && state_networth_index !== null) {
    getloan =
      state_networth[state_networth_index] &&
      state_networth[state_networth_index].loan
  }

  if (readOnlyAccess) {
    return <ModalNoAccess />
  }

  if (!model.id) {
    model.valuationDate = new Date()
    model.dividendFrequency = "YEARLY"
  }

  const onTabSelected = (activeKey) => {
    setActiveTabIndex(activeKey)
  }

  const fields = [
    {
      name: "businessName",
      label: intl.get("field.businessName"),
      type: FieldType.TEXT,
      required: true
    },

    {
      name: "businessLocation",
      label: intl.get("field.businessLocation"),
      type: FieldType.SELECTION,
      selectionData: translateListLabel(CountryList),
      valueProperty: "name",
      labelProperty: "name"
    },
    {
      name: "businessRegistrationType",
      label: intl.get("field.businessRegistrationType"),
      type: FieldType.SELECTION,
      selectionData: optionsData.businessRegistrationTypeOptions,
      valueProperty: "code",
      labelProperty: "name"
    },
    {
      name: "businessMarketSector",
      label: intl.get("field.businessMarketSector"),
      type: FieldType.SELECTION,
      selectionData: optionsData.businessMarketSectorOptions,
      valueProperty: "name",
      labelProperty: "name"
    },
    {
      name: "businessIndustryGroup",
      label: intl.get("field.businessIndustryGroup"),
      type: FieldType.SELECTION,
      selectionData: optionsData.businessIndustryGroupOptions,
      valueProperty: "name",
      labelProperty: "name"
    },
    {
      name: "managedBy",
      label: intl.get("field.managedBy"),
      type: FieldType.ENUM_SELECTION,
      enumData: optionsData.managedByOptions,
      required: false
    },
    {
      name: "yearPurchased",
      label: intl.get("field.yearPurchased"),
      type: FieldType.YEAR
    },
    {
      name: "sharesHeld",
      label: intl.get("field.numberSharesHeld"),
      type: FieldType.NUMBER
    },
    {
      name: "paidUpCapital",
      label: intl.get("field.paidUpCapital"),
      type: FieldType.MONEY_WITH_CURRENCY
    },
    {
      name: "currentValue",
      label: intl.get("field.currentValueTotal"),
      type: FieldType.MONEY_WITH_CURRENCY,
      required: true
    },
    {
      name: "valuationDate",
      label: intl.get("field.valuationDate"),
      type: FieldType.DATE,
      required: true
    },

    {
      name: "annualDividendYield",
      label: intl.get("field.annualDividendYield"),
      type: FieldType.PERCENTAGE
    },
    {
      name: "dividendFrequency",
      label: intl.get("field.dividendFrequency"),
      type: FieldType.ENUM_SELECTION,
      enumData: optionsData.frequencyOptions,
      required: false
    },
    {
      name: "dividendValue",
      label: intl.get("field.dividendValue"),
      type: FieldType.MONEY_WITH_CURRENCY
    },

    {
      name: "lastDividendPaymentDate",
      label: intl.get("field.lastDividendRepaymentDate"),
      type: FieldType.DATE
    },

    {
      name: "paidUpCapitalFinancedThruLoan",
      label: intl.get("field.isCapitalFinancedThruLoan"),
      type: FieldType.CHECKBOX,
      checkboxLabel: intl.get("YesNoEnum_YES")
    }
  ]

  const SecondaryForms = ({ mutators }) => (
    <div
      className="portal-form-half SecondaryForms"
      style={{ paddingLeft: "30px" }}
    >
      <FormSpy subscription={{ values: true }}>
        {({ values }) => {
          const { pushArray, removeArray } = mutators

          const baseTabLabel = "primaryResidencePropertyOwnership.details"
          const tabs = [
            {
              render: true,
              label: `${baseTabLabel}.expense.cost`,
              panel: (
                <>
                  <div className="portal-form-row">
                    <label>
                      <b>Add Expense</b>
                    </label>
                    {
                      <div className="row-holder">
                        <button
                          type="button"
                          onClick={() =>
                            pushArray("businessHoldingExpenses.operational", {
                              description: "",
                              value: 0
                            })
                          }
                          className="btn btn-plus"
                        />
                      </div>
                    }
                  </div>
                  <FieldArray name="businessHoldingExpenses.operational">
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <div
                          className="portal-form-row other-expense"
                          key={index}
                        >
                          <Field
                            name={`${name}.description`}
                            component={renderTextBoxCustom}
                            // disabled={disabled}
                            validate={required}
                            containerCssClass={"networth-other-input"}
                          />
                          <Field
                            name={`${name}.value`}
                            type="text"
                            component={renderCurrencyCustom}
                            // disabled={disabled}
                            containerCssClass={"networth-other-col"}
                            symbolPrefix={currency.symbol}
                          />

                          <button
                            type="button"
                            onClick={() =>
                              removeArray(
                                "businessHoldingExpenses.operational",
                                index
                              )
                            }
                            className="btn-remove networth-other-remove"
                          />
                        </div>
                      ))
                    }
                  </FieldArray>
                </>
              )
            }
          ]
          return (
            <Tabs
              selectedIndex={activeTabIndex}
              onSelect={(activeKey) => onTabSelected(activeKey)}
            >
              <TabList>
                {tabs
                  .filter((item) => item.render)
                  .map((item, index) => (
                    <Tab key={index}>{intl.get(item.label)}</Tab>
                  ))}

                {values.paidUpCapitalFinancedThruLoan && (
                  <Tab>{intl.get("tabs.loanDetails")}</Tab>
                )}
              </TabList>

              {tabs
                .filter((item) => item.render)
                .map((item, index) => (
                  <TabPanel key={index}>{item.panel}</TabPanel>
                ))}
              {values.paidUpCapitalFinancedThruLoan && (
                <TabPanel>
                  <LoanDetails
                    optionsData={optionsData}
                    currency={currency}
                    model={values}
                    mutators={mutators}
                  />
                </TabPanel>
              )}
            </Tabs>
          )
        }}
      </FormSpy>
    </div>
  )

  return (
    <Form
      onSubmit={handleSave}
      mutators={getFormMutator(arrayMutators)}
      initialValues={model}
      keepDirtyOnReinitialize={true}
      render={({ handleSubmit, form, reset, mutators }) => (
        (model.loan = getloan),
        (
          <form id="businessOwnershipForm" onSubmit={handleSubmit}>
            <div className="portal-form-popup">
              <div className="portal-form-half">
                <FormSpy subscription={{ values: true }}>
                  {({ values }) => (
                    <React.Fragment>
                      <div
                        className={
                          values.paidUpCapitalFinancedThruLoan &&
                          "portal-form-half"
                        }
                      >
                        {showClassification && <ClassificationSelection />}

                        <div className="subtitle2">
                          {intl.get("businessOwnership.subtitle")}
                        </div>
                        <RenderSimpleFields fields={fields} />
                        <Field
                          component={fileFieldRender}
                          customClassName="mt-1 d-block"
                          labelIdle="Drag, Drop or Browse"
                          label="Upload Documents"
                          onupdatefiles={(fileItems) => {
                            mutators.setFormAttribute(
                              "proofDocument",
                              fileItems[0]
                            )
                          }}
                          fileInformation={
                            (values && values.proofDocument) || null
                          }
                        />
                      </div>
                    </React.Fragment>
                  )}
                </FormSpy>
              </div>

              <SecondaryForms mutators={form.mutators} />
            </div>

            <FormButtons reset={reset} readOnlyAccess={readOnlyAccess} />
          </form>
        )
      )}
    />
  )
}

export default BusinessOwnershipForm
