import { netWorthFormAction } from "../../../constants/ActionConstants"

export function netWorthForm(state = {}, action) {
  switch (action.type) {
    case netWorthFormAction.SET_NET_WORTH_FORM_MODEL:
      return {
        ...state,
        model: action.model
      }
    case netWorthFormAction.SET_UPDATE_NET_WORTH_FORM_SUCCESS:
      return {
        ...state,
        ...action.formData
      }

    default:
      return state
  }
}
