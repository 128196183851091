import { isNullOrUndefined } from 'util';
import { countFromArray } from './CountFromArray';
import { totalSum } from './TotalSum';

export const personalIncomeCounter = (values, currency, key) => {
    if (!isNullOrUndefined(values) && !isNullOrUndefined(values[key])) {
        const model = {
            expenses: countFromArray(values[key], currency),
        };

        return {
            clientTotal: totalSum(model, 'clientTotal'),
            partnerTotal: totalSum(model, 'partnerTotal'),
            jointTotal: totalSum(model, 'jointTotal'),
        };
    }

    return {
        clientTotal: 0,
        partnerTotal: 0,
        jointTotal: 0,
    };
};
