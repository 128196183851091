import React from 'react';
import RenderSimpleFields, { FieldType } from './../../../components/Form/RenderSimpleFields';
import intl from 'react-intl-universal';
import ClassificationSelection from './../../../components/Form/ClassificationSelection';

function PensionInformationLeftSide(props) {
    return (
        <React.Fragment>
            <ClassificationSelection />
            <div className="subtitle2">
                {intl.get('pensionInformation.details')}
            </div>
            <div className="portal-form-half">
                <RenderSimpleFields
                    fields={[
                        {
                            name: 'pensionFundIssuer',
                            label: intl.get('field.pensionFundIssuer'),
                            type: FieldType.TEXT,
                        },
                        {
                            name: 'pensionAccount',
                            label: intl.get('field.pensionAccount'),
                            type: FieldType.TEXT,
                        },
                        {
                            name: 'riskProfile',
                            label: intl.get('field.riskProfile'),
                            type: FieldType.TEXT,
                        },
                        {
                            name: 'pensionTypes',
                            label: intl.get('field.pensionTypes'),
                            type: FieldType.TEXT,
                        },
                        {
                            name: 'pensionCurrentValue',
                            label: intl.get('field.pensionCurrentValue'),
                            type: FieldType.MONEY,
                            allowNegative: false,

                        },
                        {
                            name: 'pensionValuationDate',
                            label: intl.get('field.valuationDate'),
                            type: FieldType.DATE,
                        },
                        {
                            name: 'pensionTermsOfTAP',
                            label: intl.get('field.pensionTermsOfTAP'),
                            type: FieldType.TEXT,
                        },
                        {
                            name: 'pensionAssetTestAssessible',
                            label: intl.get('field.pensionAssetTestAssessible'),
                            type: FieldType.PERCENTAGE,
                        },
                        {
                            name: 'pensionEndDateOfTTR',
                            label: intl.get('field.pensionEndDateOfTTR'),
                            type: FieldType.DATE,
                        },
                    ]}
                />
            </div >
        </React.Fragment>
    );
}

export default PensionInformationLeftSide;