import api from "../lib/api";

// change base route for different entity/domain here
const BASE_URL = '/retirement';

class RetirementApi {
    static getFind(id) {
        return api.get(`${BASE_URL}/super-funds-by-keyword?keyword=${id}`);
    }

    static getSalary(id) {
        return api.get(`/client/${id}/salary`);
    }

    static getGoal(id) {
        return api.get(`/client/${id}/goal`);
    }

    
    static getEpfRules() {
        return api.get(`${BASE_URL}/epf-rule`);
    }
    static saveEpfRules(rule) {
        return api.post(`${BASE_URL}/epf-rule`, rule);
    }

    static resetEpfRules() {
        return api.get(`${BASE_URL}/epf-init-rule`);
    }

    static epfCalculate(model){
        return api.post(`${BASE_URL}/epf-calculate`, model);
    }

    static getPrsProviders(resetFromJson) {
        if(resetFromJson){
            return api.get(`${BASE_URL}/prs-providers?reset=true`);
        }else{
            return api.get(`${BASE_URL}/prs-providers`);
        }
        
    }
    static savePrsProvider(model) {
        return api.post(`${BASE_URL}/prs-provider`, model);
    }
    static getPrsProviderByName(provider) {
        return api.get(`${BASE_URL}/prs-provider/${provider}`);
    }

    static deletePrsProviderById(id) {
        return api.delete(`${BASE_URL}/prs-provider/${id}`);
    }

    static getPrsSchemeBy(provider, scheme) {
        return api.get(`${BASE_URL}/prs-scheme?provider=${provider}&&scheme=${scheme}`);
    }

    static getPrsRules() {
        return api.get(`${BASE_URL}/prs-rule`);
    }
    static savePrsRules(rule) {
        return api.post(`${BASE_URL}/prs-rule`, rule);
    }

    static resetPrsRules() {
        return api.get(`${BASE_URL}/prs-init-rule`);
    }

    static prsCalculate(model){
        return api.post(`${BASE_URL}/prs-calculate`, model);
    }

    static getMpfRules() {
        return api.get(`${BASE_URL}/mpf-rule`);
    }
    static saveMpfRules(rule) {
        return api.post(`${BASE_URL}/mpf-rule`, rule);
    }
    static getMpfTrustees() {
        return api.get(`${BASE_URL}/mpf-trustees`);
    }
    static resetMpfRules() {
        return api.get(`${BASE_URL}/mpf-init-rule`);
    }
    static mpfCalculate(model){
        return api.post(`${BASE_URL}/mpf-calculate`, model);
    }

    static getSuperRules() {
        return api.get(`${BASE_URL}/super-rule`);
    }
    static saveSuperRules(rule) {
        return api.post(`${BASE_URL}/super-rule`, rule);
    }

    static resetSuperRules() {
        return api.get(`${BASE_URL}/super-init-rule`);
    }

}
export default RetirementApi;
