import { userAction } from "../constants/ActionConstants"
const initialState = {
  loading: false,
  items: {},
  error: "",
  onlyRequiredFields: false
}

export function users(state = initialState, action) {
  switch (action.type) {
    case userAction.GETALL_REQUEST:
      return {
        loading: true
      }
    case userAction.GETALL_SUCCESS:
      return {
        loading: true,
        items: action.users
      }
    case userAction.GETALL_FAILURE:
      return {
        error: action.error
      }
    //   show/hide fields
    case userAction.TOGGLE_VISIBILITY_OF_FIELDS:
      return {
        ...state,
        onlyRequiredFields: action.payload
      }
    default:
      return state
  }
}
