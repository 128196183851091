/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Description : GEAL malaysia page (modal)
*/

import React from 'react';
import intl from 'react-intl-universal';
import { Form } from 'react-final-form';

import {
    FormButtons,
    ClassificationSelection,
    ModalNoAccess,
} from '../components';
import RenderSimpleFields, {
    FieldType,
} from '../components/Form/RenderSimpleFields';

const GEALMalaysiaForm = ({
    handleSave,
    model,
    optionsData,
    readOnlyAccess,
    showClassification = true,
    currency = 'MYR',
}) =>
    !readOnlyAccess ? (
        <Form
            onSubmit={handleSave}
            initialValues={model}
            render={({ handleSubmit, reset }) => (
                <form id="malaysiaGealForm" onSubmit={handleSubmit}>
                    <div className="portal-form-popup">
                    <div className="malaysiaGealForm-content">

                            {/* show Joint */}
                            {/* {showClassification && (
                                <ClassificationSelection hideJoint />
                            )} */}
                            {showClassification && (
                                <ClassificationSelection />
                            )}

                            <div className="subtitle2">
                                {intl.get('geal.subtitle.modal', {
                                    countryDesc: 'Malaysia',
                                })}
                            </div>

                            <RenderSimpleFields
                                fields={[
                                    {
                                        name: 'educationAssistanceLoanType',
                                        label: intl.get(
                                            'field.loan.educationAssistanceLoanType'
                                        ),
                                        type: FieldType.ENUM_SELECTION,
                                        required: true,
                                        enumData:
                                            optionsData.educationAssistanceLoanTypeMYOptions,
                                    },
                                    {
                                        name: 'loanBalance',
                                        label: intl.get(
                                            'field.loan.loanBalance'
                                        ),
                                        type: FieldType.MONEY_WITH_CURRENCY,
                                        lockToCurrency: currency,
                                        required: true,
                                        allowNegative: false,
                                    },
                                    {
                                        name: 'valuationDate',
                                        label: intl.get(
                                            'field.loan.valuationDate'
                                        ),
                                        type: FieldType.DATE,
                                        required: true,
                                    },
                                    {
                                        name: 'loanStartDate',
                                        label: intl.get(
                                            'field.loan.loanStartDate'
                                        ),
                                        type: FieldType.DATE,
                                        required: true,
                                    },
                                    {
                                        name: 'repaymentStartDate',
                                        label: intl.get(
                                            'field.loan.repaymentStartDate'
                                        ),
                                        type: FieldType.DATE,
                                        required: true,
                                    },
                                    {
                                        name: 'loanAnnualInterestRate',
                                        label: intl.get(
                                            'field.loan.loanAnnualInterestRate'
                                        ),
                                        type: FieldType.PERCENTAGE,
                                    },
                                    {
                                        name: 'monthlyRepaymentAmount',
                                        label: intl.get(
                                            'field.loan.monthlyRepaymentAmount'
                                        ),
                                        type: FieldType.MONEY_WITH_CURRENCY,
                                        lockToCurrency: currency,
                                        allowNegative: false,
                                        required: true,
                                    },
                                ]}
                            />
                        </div>
                    </div>

                    <FormButtons
                        reset={reset}
                        readOnlyAccess={readOnlyAccess}
                    />
                </form>
            )}
        />
    ) : (
        <ModalNoAccess />
    );

export default GEALMalaysiaForm;
