import React, { useState, useEffect } from "react"
import intl from "react-intl-universal"
import { Field, Form, FormSpy } from "react-final-form"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { FormButtons, ModalNoAccess } from "../components"
import RenderSimpleFields, {
  FieldType
} from "../components/Form/RenderSimpleFields"
import ClassificationSelection from "../components/_hook/ClassificationSelection"
import GeneralLoanDetails from "./sub-forms/GeneralLoanDetails"
import { getSymbolFromCode, getCurrency, fileFieldRender } from "../helpers"
import AssetRiskClassificationApi from "../api/AssetRiskClassificationApi"
import { NetWorthApi } from "../api"
function SidePane({
  isShowSidePane,
  optionsData,
  showMortgageAssociatedCharges,
  defaultCurrency = "MYR",
  values
}) {
  if (!isShowSidePane) {
    return <></>
  }

  return (
    <div className="portal-form-half">
      <Tabs>
        <TabList>
          <Tab>{intl.get("tabs.marginLoanDetails")}</Tab>
        </TabList>
        <TabPanel>
          <GeneralLoanDetails
            optionsData={optionsData}
            showMortgageAssociatedCharges={showMortgageAssociatedCharges}
            objectName="marginLoan"
            defaultCurrency={defaultCurrency}
            values={values.marginLoan || {}}
          />
        </TabPanel>
      </Tabs>
    </div>
  )
}
function FeeSection({ isHasRegularSavingPlan, optionsData, defaultCurrency }) {
  const fields = [
    {
      name: "managementFee",
      label: intl.get("field.managementFee"),
      type: FieldType.MONEY,
      isMoney: true,
      defaultCurrency: defaultCurrency,
      symbolPrefix: getSymbolFromCode(defaultCurrency),
      allowNegative: false
    },
    {
      name: "managementFeeFrequency",
      label: intl.get("field.managementFeeFrequency"),
      type: FieldType.ENUM_SELECTION,
      enumData: optionsData.frequencyOptions
    },
    {
      name: "administrationFee",
      label: intl.get("field.administrationFee"),
      type: FieldType.MONEY,
      isMoney: true,
      defaultCurrency: defaultCurrency,
      symbolPrefix: getSymbolFromCode(defaultCurrency),
      allowNegative: false
    },
    {
      name: "administrationFeeFrequency",
      label: intl.get("field.administrationFeeFrequency"),
      type: FieldType.ENUM_SELECTION,
      enumData: optionsData.frequencyOptions
    },
    {
      name: "hasRegularSavingPlan",
      label: intl.get("field.hasRegularSavingPlan"),
      type: FieldType.CHECKBOX,
      checkboxLabel: intl.get("YesNoEnum_YES")
    },
    {
      name: "financedThruLoan",
      label: intl.get("field.investmentUnderLoan"),
      type: FieldType.CHECKBOX,
      checkboxLabel: intl.get("YesNoEnum_YES")
    }
  ]

  if (isHasRegularSavingPlan) {
    const regularSavingPlanFields = [
      {
        name: "savingAmount",
        label: intl.get("field.savingAmount"),
        type: FieldType.MONEY,
        isMoney: true,
        defaultCurrency: defaultCurrency,
        symbolPrefix: getSymbolFromCode(defaultCurrency)
      },
      {
        name: "savingFrequency",
        label: intl.get("field.savingFrequency"),
        type: FieldType.ENUM_SELECTION,
        enumData: optionsData.frequencyOptions
      }
    ]
    fields.splice(
      fields.findIndex(
        (item) => item.name === "financedThruLoan" // put before financedThruLoan
      ),
      0,
      ...regularSavingPlanFields
    )
  }

  return (
    <React.Fragment>
      <div className="subtitle2">{intl.get("netWorth.fees")}</div>
      <RenderSimpleFields fields={fields} />
    </React.Fragment>
  )
}
function MainForm({
  values,
  clientInformation: { client, partner = {}, isJoint },
  optionsData,
  onTradingCurrencyChange,
  defaultCurrency = "MYR",
  setFormAttribute
}) {
  const { taxResidency = "" } = client
  const taxRecidencyIsAustralia =
    taxResidency.toLowerCase() === "australia" ||
    taxResidency.toLowerCase() === "au"
  const {
    financedThruLoan: isShowSidePane,
    hasRegularSavingPlan: isHasRegularSavingPlan
  } = values
  const [currency, setCurrency] = useState(
    values.tradingCurrency ? values.tradingCurrency : defaultCurrency
  )
  const [cashAccounts, setCashAccounts] = useState([])
  const [productTypes, setProductTypes] = useState([])
  useEffect(() => {
    let clientId = localStorage.getItem("activeClientId")
    NetWorthApi.getClientCashAccounts(clientId).then(({ data }) => {
      setCashAccounts(data)
    })
    AssetRiskClassificationApi.getProductTypes("UNIT_TRUST").then(
      ({ data }) => {
        if (data) {
          setProductTypes(data.data)
        }
      }
    )
  }, [])
  function getCashOptions() {
    let result = []
    if (cashAccounts) {
      cashAccounts.forEach((c) => {
        result.push(
          <option key={`enum-${c.id}`} value={c.id}>
            {c.accountNumber ? c.accountNumber : c.accountName}{" "}
            {c.primary ? "(primary)" : ""}
          </option>
        )
      })
    }
    return result
  }
  const fields = [
    {
      name: "unitTrustName",
      label: intl.get("field.unitTrustName"),
      type: FieldType.TEXT,
      required: true
    },
    {
      name: "unitTrustIssuerName",
      label: intl.get("field.unitTrustIssuerName"),
      type: FieldType.TEXT,
      required: false
    },
    {
      name: "productTypeCode",
      label: intl.get("field.productType"),
      type: FieldType.SELECTION,
      selectionData: productTypes,
      valueProperty: "code",
      labelProperty: "name",
      required: true
    },
    {
      name: "managedBy",
      label: intl.get("field.managedBy"),
      type: FieldType.ENUM_SELECTION,
      enumData: optionsData.managedByOptions,
      required: false
    },
    {
      name: "targetCashId",
      label: intl.get("field.settlementCash"),
      type: FieldType.ENUM_SELECTION,
      enumData: getCashOptions(),
      required: true
    },
    {
      name: "isin",
      label: intl.get("field.isin"),
      type: FieldType.TEXT,
      required: false,
      maxLength: 15
    },
    {
      name: "tickerCode",
      label: intl.get("field.tickerCode"),
      type: FieldType.TEXT,
      required: false,
      maxLength: 5
    },
    {
      name: "datePurchased",
      label: intl.get(`field.datePurchased`),
      type: FieldType.DATE
    },
    {
      name: "numberOfUnitsHeld",
      label: intl.get("field.unitHeld"),
      type: FieldType.NUMBER,
      required: true,
      isDecimal: true
    },
    {
      name: "tradingCurrency",
      label: intl.get("field.tradingCurrency"),
      type: FieldType.ENUM_SELECTION,
      enumData: optionsData.currencyOptions,
      onChange: (val) => setCurrency(val),
      defaultValue: currency
    },
    {
      name: "costBase",
      label: intl.get("field.costBase"),
      type: FieldType.MONEY,
      className: "portal-form-row popup-purchaseprice",
      isMoney: true,
      defaultCurrency: currency,
      symbolPrefix: getSymbolFromCode(currency),
      allowNegative: false
    },
    {
      name: "currentValue",
      label: intl.get("field.currentValueTotal"),
      type: FieldType.MONEY,
      isMoney: true,
      required: true,
      defaultCurrency: currency,
      symbolPrefix: getSymbolFromCode(currency),
      allowNegative: false
    },
    {
      name: "valuationDate",
      label: intl.get(`field.valuationDate`),
      type: FieldType.DATE,
      required: true
    },
    {
      name: "annualGrowthRate",
      label: intl.get("field.annualGrowthRate"),
      type: FieldType.PERCENTAGE,
      required: true
    },

    {
      name: "annualDistributionYield",
      label: intl.get("field.annualDistributionYield"),
      type: FieldType.PERCENTAGE
    },
    {
      name: "distributionFrequency",
      type: FieldType.ENUM_SELECTION,
      label: intl.get("field.distributionFrequency"),
      enumData: optionsData.frequencyOptions
    },
    {
      name: "dividendAmount",
      label: intl.get("field.dividendValue"),
      symbolPrefix: getSymbolFromCode(currency),
      type: FieldType.MONEY
    },
    {
      name: "lastDistributionPaymentDate",
      label: intl.get("field.lastDistributionPaymentDate"),
      type: FieldType.DATE
    }
  ]

  if (taxRecidencyIsAustralia) {
    fields.splice(
      fields.findIndex(
        (item) => item.name === "lastDistributionPaymentDate" // put before lastDistributionPaymentDate
      ),
      0,
      {
        name: "frankingPercent",
        label: intl.get("field.franking"),
        type: FieldType.PERCENTAGE
      }
    )
  }

  return (
    <div className="portal-form-popup">
      <div className={isShowSidePane && "portal-form-half"}>
        <ClassificationSelection
          {...{
            client,
            partner,
            isJoint
          }}
        />
        <div className="subtitle2">{intl.get("unitTrusts.subtitle")}</div>
        <RenderSimpleFields fields={fields} />
        <FeeSection
          isHasRegularSavingPlan={isHasRegularSavingPlan}
          optionsData={optionsData}
          defaultCurrency={currency}
        />
        <Field
          component={fileFieldRender}
          customClassName="mt-1 d-block"
          labelIdle="Drag, Drop or Browse"
          label="Upload Documents"
          onupdatefiles={(fileItems) => {
            setFormAttribute("proofDocument", fileItems[0])
          }}
          fileInformation={(values && values.proofDocument) || null}
        />
      </div>
      <SidePane
        isShowSidePane={isShowSidePane}
        optionsData={optionsData}
        showMortgageAssociatedCharges={taxRecidencyIsAustralia}
        defaultCurrency={currency}
        values={values}
      />
    </div>
  )
}

function UnitTrustForm({
  handleSave,
  model = {},
  optionsData,
  readOnlyAccess,
  clientInformation,
  defaultCurrency,
  subFormModel //20201007
}) {
  if (readOnlyAccess) {
    return <ModalNoAccess />
  }

  model = subFormModel ? subFormModel : model

  const {
    client: { taxResidency }
  } = clientInformation

  const dC = defaultCurrency || getCurrency(taxResidency)

  if (!model.marginLoan) {
    model.marginLoan = null
    // = {
    //     borrowingsCurrency: dC,
    // };
  }
  if (!model.tradingCurrency) {
    model.tradingCurrency = dC
  }

  if (!model.valuationDate) {
    model.valuationDate = new Date()
  }

  if (!model.currentValue) {
    model.currentValue = { currency: defaultCurrency, value: 0 }
  }
  if (!model.distributionFrequency) {
    model.distributionFrequency = "YEARLY"
  }

  return (
    <Form
      onSubmit={handleSave}
      initialValues={{
        ...model,
        currentValue: model.isNew
          ? { ...model.currentValue, value: "" }
          : model.currentValue
      }}
      mutators={{
        onTradingCurrencyChange: (args, state, utils) => {
          const { values } = state.formState
          const [currency] = args
          let items = [
            "currentValue",
            "costBase",
            "managementFee",
            "administrationFee",
            "savingAmount"
          ]
          items.forEach((item) => {
            if (!(values[item] || {}).value) {
              utils.changeValue(state, item, () => ({
                currency,
                value: 0
              }))
            }
          })
        },
        setFormAttribute: ([fieldName, fieldVal], state, { changeValue }) => {
          changeValue(state, fieldName, () => fieldVal)
        }
      }}
      render={({
        handleSubmit,
        reset,
        form: {
          mutators: { onTradingCurrencyChange, setFormAttribute }
        }
      }) => (
        <div className="unit-trust-dialog">
          <form id="unitTrustForm" onSubmit={handleSubmit}>
            <FormSpy subscription={{ values: true }}>
              {({ values }) => (
                <MainForm
                  values={values}
                  clientInformation={clientInformation}
                  optionsData={optionsData}
                  onTradingCurrencyChange={onTradingCurrencyChange}
                  defaultCurrency={dC}
                  setFormAttribute={setFormAttribute}
                />
              )}
            </FormSpy>
            <FormButtons reset={reset} readOnlyAccess={readOnlyAccess} />
          </form>
        </div>
      )}
    />
  )
}

export default UnitTrustForm
