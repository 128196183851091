import React, { useContext, useEffect,  useState } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import intl from "react-intl-universal"
import { netWorthFormActions, modalWindowActions } from '../../../../actions';
import { modalWindow } from '../../../../constants/ModalWindowLists';

import NetWorthForm, {
    NetWorthPath,
    NetWorthFormPropsComposer,
} from '../../NetWorthForm';

import PRSForm from "./PRSForm"



class PrivateRetirementScheme extends NetWorthForm {
    constructor(props) {
        super(
            props,
            intl.get('privateRetirementScheme.title'),
            modalWindow.PRIVATE_RETIREMENT_SCHEME,
            NetWorthPath.privateRetirementSchemes,
            'PrivateRetirementScheme'
        );
    }

   
  render() {
    const {
      optionsData,
      model,
      client,
      partner,
      isJoint,
      currency,
    } = this.props;
    const { handleSave, contentWrapper, readOnlyAccessComponent } = this;
    

    return contentWrapper(
       <PRSForm 
          handleSave = {handleSave}
          model={model} 
          client = {client}
          partner = {partner} 
          optionsData={optionsData} 
          isjoint = {isJoint}
       />
            
    )
  }
}

function mapStateToProps(state) {
    const props = NetWorthFormPropsComposer(
        state,
        modalWindow.PRIVATE_RETIREMENT_SCHEME,
        NetWorthPath.privateRetirementSchemes
    );
    return {
        ...props,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        Object.assign({}, netWorthFormActions, modalWindowActions),
        dispatch
    );
}

PrivateRetirementScheme = connect(
    mapStateToProps,
    mapDispatchToProps
)(PrivateRetirementScheme);

export default PrivateRetirementScheme;
