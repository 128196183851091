/*
    Author : Erik (erik.malik@devstack.com.au)
    Description : Form buttons for networth form. Make it general for reusability
*/

import React from "react"
import intl from "react-intl-universal"

const FormButtons = ({ reset, readOnlyAccess, btnName }) => (
  <div className="portal-btn-popup">
    {!readOnlyAccess && (
      <React.Fragment>
        <div className="col-half" />
        <div className="col-half">
          <input
            className="portal-btn portal-btn-submit"
            value={btnName ? btnName : intl.get("button.save")}
            type="submit"
          />
        </div>
      </React.Fragment>
    )}
  </div>
)
export default FormButtons
