import React, { useContext } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';
import intl from 'react-intl-universal';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { AppContext } from '../../../../context/AppContext';
import CashFlowHeaderListing from '../../CashFlowHeaderListing';
import CashFlowListingGroup from '../../CashFlowListingGroup';
import api from '../../../../lib/api';
import ILASInvestmentForm from '../../../NetWorth/ILASInvestment/ILASInvestmentForm';
import { netWorthFormActions } from '../../../../actions';
import { useDispatch } from 'react-redux';

const Ilas = ({
    varPrefix,
    clientTotalValue,
    partnerTotalValue,
    jointTotalValue,
    optionsData,
    readOnlyAccessComponent,
    clientInformation,
    currency,
    onExternalUpdated,
    isJoint,
    values,
}) => {
    const {
        modal: { setModal, clearModal },
    } = useContext(AppContext);

    const handleSave = newValues => {
        // save the model
        api.put(newValues.url, newValues).then(response => {
            onExternalUpdated(true);
        });

        clearModal();
    };
    const dispatch = useDispatch()

    return (
        <div className="portal-part">
            <Accordion accordion={false}>
                <AccordionItem>
                    <AccordionItemTitle>
                        <CashFlowHeaderListing
                            title={intl.get('ilas.annuityIncome')}
                            isAccordion
                            clientTotalValue={clientTotalValue}
                            partnerTotalValue={partnerTotalValue}
                            jointTotalValue={jointTotalValue}
                            currency={currency}
                            isJointHide={!isJoint}
                        />
                    </AccordionItemTitle>
                    <AccordionItemBody>
                        <CashFlowListingGroup
                            varPrefix={`${varPrefix}`}
                            dataArray={
                                values.detailResult
                                // values.personalIncome.investmentPropertyRental
                            }
                            isRoundColorRed
                            isExternalModal
                            isJointHide={!isJoint}
                            currency={currency}
                            onEditClick={model =>{
                                 dispatch(
                                   netWorthFormActions.setNewForm(
                                     false,
                                     model.classification
                                   )
                                 )
                                setModal({
                                    title: intl.get('ilas.annuityIncome'),
                                    formType: 'ilas',
                                    content: (
                                        <Form
                                            onSubmit={handleSave}
                                            initialValues={model}
                                            mutators={ { ...arrayMutators,
                                                setFormAttribute: ([fieldName, fieldVal], state, { changeValue }) => {
                                                  changeValue(state, fieldName, () => fieldVal)
                                                }
                                             }}
                                            render={({
                                                handleSubmit,
                                                reset,
                                                mutators,
                                            }) => (
                                                <ILASInvestmentForm
                                                    handleSubmit={handleSubmit}
                                                    optionsData={optionsData}
                                                    mutators={mutators}
                                                    reset={reset}
                                                    readOnlyAccessComponent={
                                                        readOnlyAccessComponent
                                                    }
                                                    clientInformation={
                                                        clientInformation
                                                    }
                                                />
                                            )}
                                        />
                                    ),
                                    isNoMargin: true,
                                })
                            }}
                        />
                    </AccordionItemBody>
                </AccordionItem>
            </Accordion>
        </div>
    );
};

export default Ilas;
