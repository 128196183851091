import React, { useState } from "react"
import intl from "react-intl-universal"
import { Field, Form, FormSpy } from "react-final-form"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { FormButtons, ModalNoAccess } from "../components"
import RenderSimpleFields, {
  FieldType
} from "../components/Form/RenderSimpleFields"
import arrayMutators from "final-form-arrays"
import ClassificationSelection from "../components/_hook/ClassificationSelection"
import GeneralLoanDetails from "./sub-forms/GeneralLoanDetails"
import {
  fileFieldRender,
  getSymbolFromCode,
  renderNumberCustom,
  renderTextBoxCustom,
  required
} from "../helpers"
import { getCurrency } from "../helpers/CurrencyConversion"
import { FieldArray } from "react-final-form-arrays"
import { useSelector } from "react-redux"

function SidePane({
  isShowSidePane,
  optionsData,
  showMortgageAssociatedCharges,
  defaultCurrency,
  values
}) {
  if (!isShowSidePane) {
    return <></>
  }
  return (
    <div className="portal-form-half">
      <Tabs>
        <TabList>
          <Tab>{intl.get("tabs.marginLoanDetails")}</Tab>
        </TabList>
        <TabPanel>
          <GeneralLoanDetails
            optionsData={optionsData}
            showMortgageAssociatedCharges={showMortgageAssociatedCharges}
            objectName="marginLoan"
            defaultCurrency={values.reportingCurrency || defaultCurrency}
            values={
              values.marginLoan || {
                borrowingsCurrency: defaultCurrency
              }
            }
          />
        </TabPanel>
      </Tabs>
    </div>
  )
}

// array fields
let nextId = 1

function ListItems({ arrayPush, arrayRemove, pop }) {
  return (
    <React.Fragment>
      <FieldArray name={`itemList`}>
        {({ fields }) => (
          <React.Fragment>
            <div className="ContactDetails">
              <div className="subtitle3">
                <label>
                  <h3>Share Details</h3>
                </label>
                <div className="btn-holder">
                  <span
                    onClick={() => arrayPush(`itemList`, undefined)}
                    className="btn btn-plus"
                  />
                </div>
              </div>

              {fields.map((name, index) => (
                <div key={`${name}-${index}`} className="portal-form-row">
                  <label htmlFor="form"></label>
                  <div className={`row-holder d-flex flex-row`}>
                    <Field
                      name={`${name}.qty`}
                      component={renderNumberCustom}
                      placeholderlabel="Quantity"
                      required={true}
                      validate={required}
                    />
                    <Field
                      name={`${name}.tickerCode`}
                      label="Ticker Code"
                      component={renderTextBoxCustom}
                      required={true}
                      validate={required}
                    />
                    {index > 0 && (
                      <span
                        onClick={() => fields.remove(index)}
                        className="btn-remove"
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </React.Fragment>
        )}
      </FieldArray>
    </React.Fragment>
  )
}

//

function MainForm({
  clientInformation: { client, partner = {}, isJoint },
  optionsData,
  values,
  defaultCurrency,
  mutators
}) {
  console.log("this is the test", optionsData.currencyOptions)
  const { taxResidency = "" } = client
  const [currency, setcurrency] = useState(
    values.portfolioCurrency || defaultCurrency
  )

  const taxRecidencyIsAustralia =
    taxResidency.toLowerCase() === "australia" ||
    taxResidency.toLowerCase() === "au"
  const { useLoan: isShowSidePane } = values

  const fields = [
    {
      name: "portfolioType",
      label: "Portfolio Type",
      type: FieldType.SELECTION,
      required: true,
      selectionData: [
        {
          value: "SHARE",
          name: "Shares"
        },
        {
          value: "UNIT_TRUST",
          name: "Unit Trust"
        }
      ],
      valueProperty: "value",
      labelProperty: "name",
      required: true
    },
    {
      name: "portfolioName",
      label: intl.get("field.uniqueCode"),
      type: FieldType.TEXT,
      required: true
    },
    {
      name: "stockbrokerName",
      label: "Broker Name",
      type: FieldType.TEXT
    },

    {
      name: "numOfItems",
      label: "Number of Shares",
      type: FieldType.NUMBER,
      required: true
    },
    {
      name: "portfolioCurrency",
      label: intl.get("field.accountCurrency"),
      type: FieldType.ENUM_SELECTION,
      enumData: optionsData.currencyOptions,
      required: false,
      onChange: (value) => {
        setcurrency(value)
      }
    },

    {
      name: "accountBalance",
      type: FieldType.MONEY,
      label: intl.get("field.accountBalance"),
      required: true,
      //   isMoney: true,
      //   defaultCurrency: defaultCurrency
      symbolPrefix: getSymbolFromCode(defaultCurrency)
    },
    {
      name: "valuationDate",
      label: intl.get("field.valuationDate"),
      type: FieldType.DATE,
      required: true
    },

    {
      name: "annualGrowthRate",
      label: intl.get("field.annualGrowthRate"),
      type: FieldType.PERCENTAGE,
      required: true
    },

    {
      name: "useLoan",
      label: intl.get("field.investmentUnderLoan"),
      checkboxLabel: intl.get("YesNoEnum_YES"),
      type: FieldType.CHECKBOX
    },
    {
      name: "useRegularSaving",
      label: "Use Regular Saving",
      checkboxLabel: intl.get("YesNoEnum_YES"),
      type: FieldType.CHECKBOX
    }
  ]
  const regularSavingsFields = [
    {
      name: "savingAmount",
      label: "Saving Amount",
      type: FieldType.MONEY,
      symbolPrefix: getSymbolFromCode(defaultCurrency)
    },
    {
      name: "savingFrequency",
      label: "Saving Frequency",
      enumData: optionsData.frequencyOptions,
      type: FieldType.ENUM_SELECTION
    }
  ]
  return (
    <div
      className={`portal-form-popup d-flex ${isShowSidePane ? "" : ""}`}
      id="insuranceForm"
    >
      <div className={isShowSidePane ? "portal-form-half" : "w-100"}>
        <ClassificationSelection {...{ client, partner, isJoint }} />
        <div className="subtitle2">
          {intl.get("genericPortfolios.subtitle")}
        </div>
        <RenderSimpleFields fields={fields} />
        {values.useRegularSaving ? (
          <>
            <RenderSimpleFields fields={regularSavingsFields} />
          </>
        ) : (
          <></>
        )}
        <ListItems
          arrayPush={mutators.arrayPush}
          arrayRemove={mutators.arrayRemove}
          pop={mutators.pop}
        />
        <Field
          component={fileFieldRender}
          customClassName="mt-1 d-block"
          labelIdle="Drag, Drop or Browse"
          label="Upload Documents"
          onupdatefiles={(fileItems) => {
            mutators.setFormAttribute("proofDocument", fileItems[0])
          }}
          fileInformation={(values && values.proofDocument) || null}
        />
      </div>
      <SidePane
        isShowSidePane={isShowSidePane}
        optionsData={optionsData}
        showMortgageAssociatedCharges={taxRecidencyIsAustralia}
        defaultCurrency={defaultCurrency}
        values={values}
      />
    </div>
  )
}

function GenericPortfolioForm({
  handleSave,
  model = { marginLoan: { borrowingsCurrency: defaultCurrency } },
  optionsData,
  readOnlyAccess,
  clientInformation,
  defaultCurrency,
  subFormModel
}) {
  if (readOnlyAccess) {
    return <ModalNoAccess />
  }

  model = subFormModel ? subFormModel : model

  if (!model.marginLoan) {
    model.marginLoan = null
  }

  const setMutators = (am) => ({
    arrayPush: am.push,
    arrayRemove: am.remove,
    ...arrayMutators
  })
  const { defaultCurrency: currency } = useSelector(
    (state) => state.personalDetails
  )

  return (
    <Form
      onSubmit={handleSave}
      initialValues={{
        ...model,
        itemList: model.isNew ? [{ tickerCode: "", qty: "" }] : model.itemList
      }}
      mutators={{
        ...setMutators(arrayMutators),
        setFormAttribute: ([fieldName, fieldVal], state, { changeValue }) => {
          changeValue(state, fieldName, () => fieldVal)
        }
      }}
      keepDirtyOnReinitialize
      render={({ handleSubmit, reset, form, mutators }) => (
        <form id="genericPortfolioForm" onSubmit={handleSubmit}>
          <FormSpy subscription={{ values: true }}>
            {({ values }) => (
              <MainForm
                clientInformation={clientInformation}
                optionsData={optionsData}
                values={values}
                defaultCurrency={defaultCurrency || currency}
                mutators={form.mutators}
              />
            )}
          </FormSpy>
          <FormButtons reset={reset} readOnlyAccess={readOnlyAccess} />
        </form>
      )}
    />
  )
}

export default GenericPortfolioForm
