export function maskEmailAddress(email) {
    let front = email.substring(0, email.lastIndexOf("@"))
    let domain = email.substring(email.lastIndexOf("@"))
    let len = front.length
    let newFront = front
    if (len > 0 && len <= 3) {
      newFront = front.replace(/.{0,1}$/, "") + "*"
    } else if (len > 3 && len <= 5) {
      newFront = front.replace(/.{0,2}$/, "") + "**"
    } else if (len > 5 && len <= 8) {
      newFront = front.replace(/.{0,3}$/, "") + "***"
    } else if (len > 8 && len <= 15) {
      newFront = front.replace(/.{0,5}$/, "") + "*****"
    } else {
      newFront = front.replace(/.{0,5}$/, "") + "*****"
    }
    return newFront + domain
  }

 export function formatMoney(x, decimalPoints) {
    if(x){
      let num = x.toFixed(decimalPoints?decimalPoints:2);
      var num_parts = num.toString().split(".");
      num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  
      return num_parts.join(".");
    }else{
      return 0;
    }
   
}