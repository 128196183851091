// export const CountryList = [
//     {
//         name: 'Afghanistan',
//         code: 'AF',
//         phone_code: '+93',
//         currency: 'Afghanistan Afghani',
//         abbreviation: 'AFN',
//         symbol: '&#1547;',
//     },
//     // {
//     //     name: 'Åland Islands',
//     //     code: 'AX',
//     //     phone_code: '+358',
//     // },
//     {
//         name: 'Albania',
//         code: 'AL',
//         phone_code: '+355',
//         currency: 'Albania Lek',
//         abbreviation: 'ALL',
//         symbol: '&#76;&#101;&#107;',
//     },
//     // {
//     //     name: 'Algeria',
//     //     code: 'DZ',
//     //     phone_code: '+213',
//     // },
//     // {
//     //     name: 'American Samoa',
//     //     code: 'AS',
//     //     phone_code: '+1',
//     // },

//     // {
//     //     name: 'Angola',
//     //     code: 'AO',
//     //     phone_code: '+244',
//     // },
//     // {
//     //     name: 'Anguilla',
//     //     code: 'AI',
//     //     phone_code: '+1',
//     // },
//     // {
//     //     name: 'Antarctica',
//     //     code: 'AQ',
//     //     phone_code: '+672',
//     // },
//     // {
//     //     name: 'Antigua and Barbuda',
//     //     code: 'AG',
//     //     phone_code: '+1',
//     // },
//     {
//         name: 'Argentina',
//         code: 'AR',
//         phone_code: '+54',
//         currency: 'Argentina Peso',
//         abbreviation: 'ARS',
//         symbol: '&#36;',
//     },
//     // {
//     //     name: 'Armenia',
//     //     code: 'AM',
//     //     phone_code: '+374',
//     // },
//     {
//         name: 'Aruba',
//         code: 'AW',
//         phone_code: '+297',
//         currency: 'Aruba Guilder',
//         abbreviation: 'AWG',
//         symbol: '&#402;',
//     },
//     {
//         name: 'Australia',
//         code: 'AU',
//         phone_code: '+61',
//         currency: 'Australia Dollar',
//         abbreviation: 'AUD',
//         symbol: '&#36;',
//     },
//     {
//         name: 'Austria',
//         code: 'AI',
//         phone_code: '+43',
//         currency: 'Euro',
//         abbreviation: 'EUR',
//         symbol: '&#8364;',
//     },
//     {
//         name: 'Azerbaijan',
//         code: 'AZ',
//         phone_code: '+994',
//         currency: 'Azerbaijan New Manat',
//         abbreviation: 'AZN',
//         symbol: '&#1084;&#1072;&#1085;',
//     },
//     {
//         name: 'Bahamas',
//         code: 'BS',
//         phone_code: '+1',
//         currency: 'Bahamas Dollar',
//         abbreviation: 'BSD',
//         symbol: '&#36;',
//     },
//     // {
//     //     name: 'Bahrain',
//     //     code: 'BH',
//     //     phone_code: '+973',
//     // },
//     // {
//     //     name: 'Bangladesh',
//     //     code: 'BD',
//     //     phone_code: '+880',
//     // },
//     {
//         name: 'Barbados',
//         code: 'BB',
//         phone_code: '+1',
//         currency: 'Barbados Dollar',
//         abbreviation: 'BBD',
//         symbol: '&#36;',
//     },
//     {
//         name: 'Belarus',
//         code: 'BY',
//         phone_code: '+375',
//         currency: 'Belarus Ruble',
//         abbreviation: 'BYR',
//         symbol: '&#112;&#46;',
//     },
//     {
//         name: 'Belgium',
//         code: 'BE',
//         phone_code: '+32',
//         currency: 'Euro',
//         abbreviation: 'EUR',
//         symbol: '&#8364;',
//     },
//     {
//         name: 'Belize',
//         code: 'BZ',
//         phone_code: '+501',
//         currency: 'Belize Dollar',
//         abbreviation: 'BZD',
//         symbol: '&#66;&#90;&#36;',
//     },
//     // {
//     //     name: 'Benin',
//     //     code: 'BJ',
//     //     phone_code: '+229',
//     // },
//     {
//         name: 'Bermuda',
//         code: 'BM',
//         phone_code: '+1',
//         currency: 'Bermuda Dollar',
//         abbreviation: 'BMD',
//         symbol: '&#36;',
//     },
//     // {
//     //     name: 'Bhutan',
//     //     code: 'BT',
//     //     phone_code: '+975',
//     // },
//     {
//         name: 'Bolivia',
//         code: 'BO',
//         phone_code: '+591',
//         currency: 'Bolivia Boliviano',
//         abbreviation: 'BOB',
//         symbol: '&#36;&#98;',
//     },
//     // {
//     //     name: 'Bonaire',
//     //     code: 'BQ',
//     //     phone_code: '+599',
//     // },
//     {
//         name: 'Bosnia and Herzegovina',
//         code: 'BA',
//         phone_code: '+387',
//         currency: 'Bosnia and Herzegovina Convertible Marka',
//         abbreviation: 'BAM',
//         symbol: '&#75;&#77;',
//     },
//     {
//         name: 'Botswana',
//         code: 'BW',
//         phone_code: '+267',
//         currency: 'Botswana Pula',
//         abbreviation: 'BWP',
//         symbol: '&#80;',
//     },
//     // {
//     //     name: 'Bouvet Island',
//     //     code: 'BV',
//     //     phone_code: '+47',
//     // },
//     {
//         name: 'Brazil',
//         code: 'BR',
//         phone_code: '+55',
//         currency: 'Brazil Real',
//         abbreviation: 'BRL',
//         symbol: '&#82;&#36;',
//     },
//     // {
//     //     name: 'British Indian Ocean Territory',
//     //     code: 'IO',
//     //     phone_code: '+246',
//     // },
//     // {
//     //     name: 'British Virgin Islands',
//     //     code: 'VG',
//     //     phone_code: '+1',
//     // },
//     {
//         name: 'Brunei Darussalam',
//         code: 'BN',
//         phone_code: '+673',
//         currency: 'Brunei Darussalam Dollar',
//         abbreviation: 'BND',
//         symbol: '&#36;',
//     },
//     {
//         name: 'Bulgaria',
//         code: 'BG',
//         phone_code: '+359',
//         currency: 'Bulgaria Lev',
//         abbreviation: 'BGN',
//         symbol: '&#1083;&#1074;',
//     },
//     // {
//     //     name: 'Burkina Faso',
//     //     code: 'BF',
//     //     phone_code: '+226',
//     // },
//     // {
//     //     name: 'Burundi',
//     //     code: 'BI',
//     //     phone_code: '+257',
//     // },
//     {
//         name: 'Cambodia',
//         code: 'KH',
//         phone_code: '+855',
//         currency: 'Cambodia Riel',
//         abbreviation: 'KHR',
//         symbol: '&#6107;',
//     },
//     // {
//     //     name: 'Cameroon',
//     //     code: 'CM',
//     //     phone_code: '+237',
//     // },
//     {
//         name: 'Canada',
//         code: 'CA',
//         phone_code: '+1',
//         currency: 'Canada Dollar',
//         abbreviation: 'CAD',
//         symbol: '&#36;',
//     },
//     // {
//     //     name: 'Cape Verde/Cabo Verde',
//     //     code: 'CV',
//     //     phone_code: '+238',
//     // },
//     {
//         name: 'Cayman Islands',
//         code: 'KY',
//         phone_code: '+1',
//         currency: 'Cayman Islands Dollar',
//         abbreviation: 'KYD',
//         symbol: '&#36;',
//     },
//     // {
//     //     name: 'Central African Republic',
//     //     code: 'CF',
//     //     phone_code: '+236',
//     // },
//     // {
//     //     name: 'Chad',
//     //     code: 'ID',
//     //     phone_code: '+235',
//     // },
//     {
//         name: 'Chile',
//         code: 'CL',
//         phone_code: '+56',
//         currency: 'Chile Peso',
//         abbreviation: 'CLP',
//         symbol: '&#36;',
//     },
//     {
//         name: 'China',
//         code: 'CN',
//         phone_code: '+86',
//         currency: 'China Yuan Renminbi',
//         abbreviation: 'CNY',
//         symbol: '&#165;',
//     },
//     // {
//     //     name: 'Christmas Island',
//     //     code: 'CX',
//     //     phone_code: '+61',
//     // },
//     // {
//     //     name: 'Cocos (Keeling) Islands',
//     //     code: 'CC',
//     //     phone_code: '+891',
//     // },
//     {
//         name: 'Colombia',
//         code: 'CO',
//         phone_code: '+57',
//         currency: 'Colombia Peso',
//         abbreviation: 'COP',
//         symbol: '&#36;',
//     },
//     // {
//     //     name: 'Comoros',
//     //     code: 'KM',
//     //     phone_code: '+269',
//     // },
//     // {
//     //     name: 'Congo (Republic of the)',
//     //     code: 'CG',
//     //     phone_code: '+242',
//     // },
//     // {
//     //     name: 'Congo (The Democratic Republic of the)',
//     //     code: 'CD',
//     //     phone_code: '+243',
//     // },
//     // {
//     //     name: 'Cook Islands',
//     //     code: 'CK',
//     //     phone_code: '+682',
//     // },
//     {
//         name: 'Costa Rica',
//         code: 'CR',
//         phone_code: '+506',
//         currency: 'Costa Rica Colon',
//         abbreviation: 'CRC',
//         symbol: '&#8353;',
//     },
//     // {
//     //     name: "Côte d'Ivoire",
//     //     code: 'CI',
//     //     phone_code: '+225',
//     // },
//     {
//         name: 'Croatia',
//         code: 'HR',
//         phone_code: '+385',
//         currency: 'Croatia Kuna',
//         abbreviation: 'HRK',
//         symbol: '&#107;&#110;',
//     },
//     {
//         name: 'Cuba',
//         code: 'CU',
//         phone_code: '+53',
//         currency: 'Cuba Peso',
//         abbreviation: 'CUP',
//         symbol: '&#8369;',
//     },
//     // {
//     //     name: 'Curacao',
//     //     code: 'CW',
//     //     phone_code: '+599',
//     // },
//     {
//         name: 'Cyprus',
//         code: 'CY',
//         phone_code: '+357',
//         currency: 'Euro',
//         abbreviation: 'EUR',
//         symbol: '&#8364;',
//     },
//     {
//         name: 'Czech Republic',
//         code: 'CZ',
//         phone_code: '+420',
//         currency: 'Czech Republic Koruna',
//         abbreviation: 'CZK',
//         symbol: '&#75;&#269;',
//     },
//     {
//         name: 'Denmark',
//         code: 'DK',
//         phone_code: '+45',
//         currency: 'Denmark Krone',
//         abbreviation: 'DKK',
//         symbol: '&#107;&#114;',
//     },
//     // {
//     //     name: 'Djibouti',
//     //     code: 'DJ',
//     //     phone_code: '+253',
//     // },
//     // {
//     //     name: 'Dominica',
//     //     code: 'DM',
//     //     phone_code: '+1',
//     // },
//     {
//         name: 'Dominican Republic',
//         code: 'DO',
//         phone_code: '+1',
//         currency: 'Dominican Republic Peso',
//         abbreviation: 'DOP',
//         symbol: '&#82;&#68;&#36;',
//     },
//     // {
//     //     name: 'Ecuador',
//     //     code: 'EC',
//     //     phone_code: '+593',
//     // },
//     {
//         name: 'Egypt',
//         code: 'EG',
//         phone_code: '+20',
//         currency: 'Egypt Pound',
//         abbreviation: 'EGP',
//         symbol: '&#163;',
//     },
//     {
//         name: 'El Salvador',
//         code: 'SV',
//         phone_code: '+503',
//         currency: 'El Salvador Colon',
//         abbreviation: 'SVC',
//         symbol: '&#36;',
//     },
//     // {
//     //     name: 'Equatorial Guinea',
//     //     code: 'GQ',
//     //     phone_code: '+240',
//     // },
//     // {
//     //     name: 'Eritrea',
//     //     code: 'ER',
//     //     phone_code: '+291',
//     // },
//     {
//         name: 'Estonia',
//         code: 'EE',
//         phone_code: '+372',
//         currency: 'Euro',
//         abbreviation: 'EUR',
//         symbol: '&#8364;',
//     },
//     // {
//     //     name: 'Ethiopia',
//     //     code: 'ET',
//     //     phone_code: '+251',
//     // },
//     {
//         name: 'Falkland Islands',
//         code: 'FK',
//         phone_code: '+500',
//         currency: 'Falkland Islands (Malvinas) Pound',
//         abbreviation: 'FKP',
//         symbol: '&#163;',
//     },
//     // {
//     //     name: 'Faroe Islands',
//     //     code: 'FO',
//     //     phone_code: '+298',
//     // },
//     // {
//     //     name: 'Federated States of Micronesia',
//     //     code: 'FM',
//     //     phone_code: '+691',
//     // },
//     {
//         name: 'Fiji',
//         code: 'FJ',
//         phone_code: '+679',
//         currency: 'Fiji Dollar',
//         abbreviation: 'FJD',
//         symbol: '&#36;',
//     },
//     {
//         name: 'Finland',
//         code: 'FI',
//         phone_code: '+358',
//         currency: 'Euro',
//         abbreviation: 'EUR',
//         symbol: '&#8364;',
//     },
//     {
//         name: 'France',
//         code: 'FR',
//         phone_code: '+33',
//         currency: 'Euro',
//         abbreviation: 'EUR',
//         symbol: '&#8364;',
//     },
//     // {
//     //     name: 'French Guiana',
//     //     code: 'GF',
//     //     phone_code: '+594',
//     // },
//     // {
//     //     name: 'French Polynesia',
//     //     code: 'PF',
//     //     phone_code: '+689',
//     // },
//     // {
//     //     name: 'French Southern Territories',
//     //     code: 'TF',
//     //     phone_code: '+',
//     // },
//     // {
//     //     name: 'Gabon',
//     //     code: 'GA',
//     //     phone_code: '+241',
//     // },
//     // {
//     //     name: 'Gambia',
//     //     code: 'GM',
//     //     phone_code: '+220',
//     // },
//     // {
//     //     name: 'Georgia',
//     //     code: 'GE',
//     //     phone_code: '+995',
//     // },
//     {
//         name: 'Germany',
//         code: 'DE',
//         phone_code: '+49',
//         currency: 'Euro',
//         abbreviation: 'EUR',
//         symbol: '&#8364;',
//     },
//     {
//         name: 'Ghana',
//         code: 'GH',
//         phone_code: '+233',
//         currency: 'Ghana Cedis',
//         abbreviation: 'GHC',
//         symbol: '&#162;',
//     },
//     {
//         name: 'Gibraltar',
//         code: 'GI',
//         phone_code: '+350',
//         currency: 'Gibraltar Pound',
//         abbreviation: 'GIP',
//         symbol: '&#163;',
//     },
//     {
//         name: 'Greece',
//         code: 'GR',
//         phone_code: '+30',
//         currency: 'Euro',
//         abbreviation: 'EUR',
//         symbol: '&#8364;',
//     },
//     // {
//     //     name: 'Greenland',
//     //     code: 'GL',
//     //     phone_code: '+299',
//     // },
//     // {
//     //     name: 'Grenada',
//     //     code: 'GD',
//     //     phone_code: '+1',
//     // },
//     // {
//     //     name: 'Guadeloupe',
//     //     code: 'GP',
//     //     phone_code: '+590',
//     // },
//     // {
//     //     name: 'Guam',
//     //     code: 'GU',
//     //     phone_code: '+1',
//     // },
//     {
//         name: 'Guatemala',
//         code: 'GT',
//         phone_code: '+502',
//         currency: 'Guatemala Quetzal',
//         abbreviation: 'GTQ',
//         symbol: '&#81;',
//     },
//     {
//         name: 'Guernsey',
//         code: 'GG',
//         phone_code: '+44',
//         currency: 'Guernsey Pound',
//         abbreviation: 'GGP',
//         symbol: '&#163;',
//     },
//     // {
//     //     name: 'Guinea',
//     //     code: 'GN',
//     //     phone_code: '+224',
//     // },
//     // {
//     //     name: 'Guinea-Bissau',
//     //     code: 'GW',
//     //     phone_code: '+245',
//     // },
//     {
//         name: 'Guyana',
//         code: 'GY',
//         phone_code: '+592',
//         currency: 'Guyana Dollar',
//         abbreviation: 'GYD',
//         symbol: '&#36;',
//     },
//     // {
//     //     name: 'Haiti',
//     //     code: 'HT',
//     //     phone_code: '+509',
//     // },
//     // {
//     //     name: 'Heard Island and McDonald Islands',
//     //     code: 'HM',
//     //     phone_code: '+672',
//     // },
//     {
//         name: 'Honduras',
//         code: 'HN',
//         phone_code: '+504',
//         currency: 'Honduras Lempira',
//         abbreviation: 'HNL',
//         symbol: '&#76;',
//     },
//     {
//         name: 'Hong Kong',
//         code: 'HK',
//         phone_code: '+852',
//         currency: 'Hong Kong Dollar',
//         abbreviation: 'HKD',
//         symbol: '&#36;',
//     },
//     {
//         name: 'Hungary',
//         code: 'HU',
//         phone_code: '+36',
//         currency: 'Hungary Forint',
//         abbreviation: 'HUF',
//         symbol: '&#70;&#116;',
//     },
//     {
//         name: 'Iceland',
//         code: 'IS',
//         phone_code: '+354',
//         currency: 'Iceland Krona',
//         abbreviation: 'ISK',
//         symbol: '&#107;&#114;',
//     },
//     {
//         name: 'India',
//         code: 'IN',
//         phone_code: '+91',
//         currency: 'India Rupee',
//         abbreviation: 'INR',
//         symbol: null,
//     },
//     {
//         name: 'Indonesia',
//         code: 'ID',
//         phone_code: '+62',
//         currency: 'Indonesia Rupiah',
//         abbreviation: 'IDR',
//         symbol: '&#82;&#112;',
//     },
//     {
//         name: 'Iran',
//         code: 'IR',
//         phone_code: '+98',
//         currency: 'Iran Rial',
//         abbreviation: 'IRR',
//         symbol: '&#65020;',
//     },
//     // {
//     //     name: 'Iraq',
//     //     code: 'IQ',
//     //     phone_code: '+964',
//     // },
//     {
//         name: 'Ireland',
//         code: 'IE',
//         phone_code: '+353',
//         currency: 'Euro',
//         abbreviation: 'EUR',
//         symbol: '&#8364;',
//     },
//     {
//         name: 'Isle of Man',
//         code: 'IM',
//         phone_code: '+44',
//         currency: 'Isle of Man Pound',
//         abbreviation: 'IMP',
//         symbol: '&#163;',
//     },
//     {
//         name: 'Israel',
//         code: 'IL',
//         phone_code: '+972',
//         currency: 'Israel Shekel',
//         abbreviation: 'ILS',
//         symbol: '&#8362;',
//     },
//     {
//         name: 'Italy',
//         code: 'IT',
//         phone_code: '+39',
//         currency: 'Euro',
//         abbreviation: 'EUR',
//         symbol: '&#8364;',
//     },
//     {
//         name: 'Jamaica',
//         code: 'JM',
//         phone_code: '+1',
//         currency: 'Jamaica Dollar',
//         abbreviation: 'JMD',
//         symbol: '&#74;&#36;',
//     },
//     {
//         name: 'Japan',
//         code: 'JP',
//         phone_code: '+81',
//         currency: 'Japan Yen',
//         abbreviation: 'JPY',
//         symbol: '&#165;',
//     },
//     {
//         name: 'Jersey',
//         code: 'JE',
//         phone_code: '+44',
//         currency: 'Jersey Pound',
//         abbreviation: 'JEP',
//         symbol: '&#163;',
//     },
//     // {
//     //     name: 'Jordan',
//     //     code: 'JO',
//     //     phone_code: '+962',
//     // },
//     {
//         name: 'Kazakhstan',
//         code: 'KZ',
//         phone_code: '+7',
//         currency: 'Kazakhstan Tenge',
//         abbreviation: 'KZT',
//         symbol: '&#1083;&#1074;',
//     },
//     // {
//     //     name: 'Kenya',
//     //     code: 'KE',
//     //     phone_code: '+254',
//     // },
//     // {
//     //     name: 'Kiribati',
//     //     code: 'KI',
//     //     phone_code: '+686',
//     // },
//     {
//         name: "Korea (Democratic People's Republic of)",
//         code: 'KP',
//         phone_code: '+850',
//         currency: 'Korea (North) Won',
//         abbreviation: 'KPW',
//         symbol: '&#8361;',
//     },
//     {
//         name: 'Korea (Republic of)',
//         code: 'KR',
//         phone_code: '+82',
//         currency: 'Korea (South) Won',
//         abbreviation: 'KRW',
//         symbol: '&#8361;',
//     },
//     // {
//     //     name: 'Kosovo',
//     //     code: 'XK',
//     //     phone_code: '+383',
//     // },
//     // {
//     //     name: 'Kuwait',
//     //     code: 'KW',
//     //     phone_code: '+965',
//     // },
//     {
//         name: 'Kyrgyzstan',
//         code: 'KG',
//         phone_code: '+996',
//         currency: 'Kyrgyzstan Som',
//         abbreviation: 'KGS',
//         symbol: '&#1083;&#1074;',
//     },
//     {
//         name: 'Laos',
//         code: 'LA',
//         phone_code: '+856',
//         currency: 'Laos Kip',
//         abbreviation: 'LAK',
//         symbol: '&#8365;',
//     },
//     {
//         name: 'Latvia',
//         code: 'LV',
//         phone_code: '+371',
//         currency: 'Euro',
//         abbreviation: 'EUR',
//         symbol: '&#8364;',
//     },
//     {
//         name: 'Lebanon',
//         code: 'LB',
//         phone_code: '+961',
//         currency: 'Lebanon Pound',
//         abbreviation: 'LBP',
//         symbol: '&#163;',
//     },
//     // {
//     //     name: 'Lesotho',
//     //     code: 'LS',
//     //     phone_code: '+266',
//     // },
//     {
//         name: 'Liberia',
//         code: 'LR',
//         phone_code: '+231',
//         currency: 'Liberia Dollar',
//         abbreviation: 'LRD',
//         symbol: '&#36;',
//     },
//     // {
//     //     name: 'Libya',
//     //     code: 'LY',
//     //     phone_code: '+218',
//     // },

//     {
//         name: 'Lithuania',
//         code: 'LT',
//         phone_code: '+370',
//         currency: 'Euro',
//         abbreviation: 'EUR',
//         symbol: '&#8364;',
//     },
//     {
//         name: 'Luxembourg',
//         code: 'LU',
//         phone_code: '+352',
//         currency: 'Euro',
//         abbreviation: 'EUR',
//         symbol: '&#8364;',
//     },
//     // {
//     //     name: 'Macau',
//     //     code: 'MO',
//     //     phone_code: '+853',
//     // },
//     {
//         name: 'Macedonia',
//         code: 'MK',
//         phone_code: '+389',
//         currency: 'Macedonia Denar',
//         abbreviation: 'MKD',
//         symbol: '&#1076;&#1077;&#1085;',
//     },
//     // {
//     //     name: 'Madagascar',
//     //     code: 'MG',
//     //     phone_code: '+261',
//     // },
//     // {
//     //     name: 'Malawi',
//     //     code: 'MW',
//     //     phone_code: '+265',
//     // },
//     {
//         name: 'Malaysia',
//         code: 'MY',
//         phone_code: '+60',
//         currency: 'Malaysia Ringgit',
//         abbreviation: 'MYR',
//         symbol: '&#82;&#77;',
//     },
//     // {
//     //     name: 'Maldives',
//     //     code: 'MV',
//     //     phone_code: '+960',
//     // },
//     // {
//     //     name: 'Mali',
//     //     code: 'ML',
//     //     phone_code: '+223',
//     // },
//     {
//         name: 'Malta',
//         code: 'MT',
//         phone_code: '+356',
//         currency: 'Euro',
//         abbreviation: 'EUR',
//         symbol: '&#8364;',
//     },
//     // {
//     //     name: 'Marshall Islands',
//     //     code: 'MH',
//     //     phone_code: '+692',
//     // },
//     // {
//     //     name: 'Martinique',
//     //     code: 'MQ',
//     //     phone_code: '+596',
//     // },
//     // {
//     //     name: 'Mauritania',
//     //     code: 'MR',
//     //     phone_code: '+222',
//     // },
//     {
//         name: 'Mauritius',
//         code: 'MU',
//         phone_code: '+230',
//         currency: 'Mauritius Rupee',
//         abbreviation: 'MUR',
//         symbol: '&#8360;',
//     },
//     // {
//     //     name: 'Mayotte',
//     //     code: 'YT',
//     //     phone_code: '+262',
//     // },
//     {
//         name: 'Mexico',
//         code: 'MX',
//         phone_code: '+52',
//         currency: 'Mexico Peso',
//         abbreviation: 'MXN',
//         symbol: '&#36;',
//     },
//     // {
//     //     name: 'Micronesia (Federated States of)',
//     //     code: 'FM',
//     //     phone_code: '+691',
//     // },
//     // {
//     //     name: 'Moldova',
//     //     code: 'MD',
//     //     phone_code: '+373',
//     // },

//     {
//         name: 'Mongolia',
//         code: 'MN',
//         phone_code: '+976',
//         currency: 'Mongolia Tughrik',
//         abbreviation: 'MNT',
//         symbol: '&#8366;',
//     },
//     // {
//     //     name: 'Montenegro',
//     //     code: 'ME',
//     //     phone_code: '+382',
//     // },
//     // {
//     //     name: 'Montserrat',
//     //     code: 'MS',
//     //     phone_code: '+1',
//     // },
//     // {
//     //     name: 'Morocco',
//     //     code: 'MA',
//     //     phone_code: '+212',
//     // },
//     {
//         name: 'Mozambique',
//         code: 'MZ',
//         phone_code: '+258',
//         currency: 'Mozambique Metical',
//         abbreviation: 'MZN',
//         symbol: '&#77;&#84;',
//     },
//     // {
//     //     name: 'Myanmar',
//     //     code: 'MM',
//     //     phone_code: '+95',
//     // },
//     {
//         name: 'Namibia',
//         code: 'NA',
//         phone_code: '+264',
//         currency: 'Namibia Dollar',
//         abbreviation: 'NAD',
//         symbol: '&#36;',
//     },
//     // {
//     //     name: 'Nauru',
//     //     code: 'NR',
//     //     phone_code: '+674',
//     // },
//     {
//         name: 'Nepal',
//         code: 'NP',
//         phone_code: '+977',
//         currency: 'Nepal Rupee',
//         abbreviation: 'NPR',
//         symbol: '&#8360;',
//     },
//     {
//         name: 'Netherlands',
//         code: 'NL',
//         phone_code: '+31',
//         currency: 'Euro',
//         abbreviation: 'EUR',
//         symbol: '&#8364;',
//     },
//     {
//         name: 'Netherlands Antilles',
//         code: 'AN',
//         phone_code: '+599',
//         currency: 'Netherlands Antilles Guilder',
//         abbreviation: 'ANG',
//         symbol: '&#402;',
//     },
//     // {
//     //     name: 'New Caledonia',
//     //     code: 'NC',
//     //     phone_code: '+687',
//     // },
//     {
//         name: 'New Zealand',
//         code: 'NZ',
//         phone_code: '+64',
//         currency: 'New Zealand Dollar',
//         abbreviation: 'NZD',
//         symbol: '&#36;',
//     },
//     {
//         name: 'Nicaragua',
//         code: 'NI',
//         phone_code: '+505',
//         currency: 'Nicaragua Cordoba',
//         abbreviation: 'NIO',
//         symbol: '&#67;&#36;',
//     },
//     // {
//     //     name: 'Niger',
//     //     code: 'NE',
//     //     phone_code: '+227',
//     // },
//     {
//         name: 'Nigeria',
//         code: 'NG',
//         phone_code: '+234',
//         currency: 'Nigeria Naira',
//         abbreviation: 'NGN',
//         symbol: '&#8358;',
//     },
//     // {
//     //     name: 'Niue',
//     //     code: 'NU',
//     //     phone_code: '+683',
//     // },
//     // {
//     //     name: 'Norfolk Island',
//     //     code: 'NF',
//     //     phone_code: '+672',
//     // },
//     // {
//     //     name: 'Northern Mariana Islands',
//     //     code: 'MP',
//     //     phone_code: '+1',
//     // },
//     {
//         name: 'Norway',
//         code: 'NO',
//         phone_code: '+47',
//         currency: 'Norway Krone',
//         abbreviation: 'NOK',
//         symbol: '&#107;&#114;',
//     },
//     {
//         name: 'Oman',
//         code: 'OM',
//         phone_code: '+968',
//         currency: 'Oman Rial',
//         abbreviation: 'OMR',
//         symbol: '&#65020;',
//     },
//     {
//         name: 'Pakistan',
//         code: 'PK',
//         phone_code: '+92',
//         currency: 'Pakistan Rupee',
//         abbreviation: 'PKR',
//         symbol: '&#8360;',
//     },
//     // {
//     //     name: 'Palau',
//     //     code: 'PW',
//     //     phone_code: '+680',
//     // },
//     // {
//     //     name: 'Palestine',
//     //     code: 'PS',
//     //     phone_code: '+970',
//     // },
//     {
//         name: 'Panama',
//         code: 'PA',
//         phone_code: '+507',
//         currency: 'Panama Balboa',
//         abbreviation: 'PAB',
//         symbol: '&#66;&#47;&#46;',
//     },
//     // {
//     //     name: 'Papua New Guinea',
//     //     code: 'PG',
//     //     phone_code: '+675',
//     // },
//     {
//         name: 'Paraguay',
//         code: 'PY',
//         phone_code: '+595',
//         currency: 'Paraguay Guarani',
//         abbreviation: 'PYG',
//         symbol: '&#71;&#115;',
//     },
//     {
//         name: 'Peru',
//         code: 'PE',
//         phone_code: '+51',
//         currency: 'Peru Nuevo Sol',
//         abbreviation: 'PEN',
//         symbol: '&#83;&#47;&#46;',
//     },
//     {
//         name: 'Philippines',
//         code: 'PH',
//         phone_code: '+63',
//         currency: 'Philippines Peso',
//         abbreviation: 'PHP',
//         symbol: '&#8369;',
//     },
//     // {
//     //     name: 'Pitcairn Islands',
//     //     code: 'PN',
//     //     phone_code: '+64',
//     // },
//     {
//         name: 'Poland',
//         code: 'PL',
//         phone_code: '+48',
//         currency: 'Poland Zloty',
//         abbreviation: 'PLN',
//         symbol: '&#122;&#322;',
//     },
//     {
//         name: 'Portugal',
//         code: 'PT',
//         phone_code: '+351',
//         currency: 'Euro',
//         abbreviation: 'EUR',
//         symbol: '&#8364;',
//     },
//     // {
//     //     name: 'Puerto Rico',
//     //     code: 'PR',
//     //     phone_code: '+1',
//     // },
//     {
//         name: 'Qatar',
//         code: 'QA',
//         phone_code: '+974',
//         currency: 'Qatar Riyal',
//         abbreviation: 'QAR',
//         symbol: '&#65020;',
//     },
//     // {
//     //     name: 'Réunion',
//     //     code: 'RE',
//     //     phone_code: '+262',
//     // },
//     {
//         name: 'Romania',
//         code: 'RO',
//         phone_code: '+40',
//         currency: 'Romania New Leu',
//         abbreviation: 'RON',
//         symbol: '&#108;&#101;&#105;',
//     },
//     {
//         name: 'Russia',
//         code: 'RU',
//         phone_code: '+7',
//         currency: 'Russia Ruble',
//         abbreviation: 'RUB',
//         symbol: '&#1088;&#1091;&#1073;',
//     },
//     // {
//     //     name: 'Rwanda',
//     //     code: 'RW',
//     //     phone_code: '+250',
//     // },
//     // {
//     //     name: 'Saint Barthélemy',
//     //     code: 'BL',
//     //     phone_code: '+590',
//     // },
//     {
//         name: 'Saint Helena Ascension and Tristan da Cunha',
//         code: 'SH',
//         phone_code: '+290',
//         currency: 'Saint Helena Pound',
//         abbreviation: 'SHP',
//         symbol: '&#163;',
//     },
//     // {
//     //     name: 'Saint Kitts and Nevis',
//     //     code: 'KN',
//     //     phone_code: '+1',
//     // },
//     // {
//     //     name: 'Saint Lucia',
//     //     code: 'LC',
//     //     phone_code: '+1',
//     // },
//     // {
//     //     name: 'Saint Martin',
//     //     code: 'MF',
//     //     phone_code: '+590',
//     // },
//     // {
//     //     name: 'Saint Pierre and Miquelon',
//     //     code: 'PM',
//     //     phone_code: '+508',
//     // },
//     // {
//     //     name: 'Saint Vincent and the Grenadines',
//     //     code: 'VC',
//     //     phone_code: '+1',
//     // },
//     // {
//     //     name: 'Samoa',
//     //     code: 'WS',
//     //     phone_code: '+685',
//     // },
//     // {
//     //     name: 'San Marino',
//     //     code: 'SM',
//     //     phone_code: '+378',
//     // },
//     // {
//     //     name: 'São Tomé and Príncipe',
//     //     code: 'ST',
//     //     phone_code: '+239',
//     // },
//     {
//         name: 'Saudi Arabia',
//         code: 'SA',
//         phone_code: '+966',
//         currency: 'Saudi Arabia Riyal',
//         abbreviation: 'SAR',
//         symbol: '&#65020;',
//     },
//     // {
//     //     name: 'Senegal',
//     //     code: 'SN',
//     //     phone_code: '+221',
//     // },
//     {
//         name: 'Serbia',
//         code: 'RS',
//         phone_code: '+381',
//         currency: 'Serbia Dinar',
//         abbreviation: 'RSD',
//         symbol: '&#1044;&#1080;&#1085;&#46;',
//     },
//     {
//         name: 'Seychelles',
//         code: 'SC',
//         phone_code: '+248',
//         currency: 'Seychelles Rupee',
//         abbreviation: 'SCR',
//         symbol: '&#8360;',
//     },
//     // {
//     //     name: 'Sierra Leone',
//     //     code: 'SL',
//     //     phone_code: '+232',
//     // },
//     {
//         name: 'Singapore',
//         code: 'SG',
//         phone_code: '+65',
//         currency: 'Singapore Dollar',
//         abbreviation: 'SGD',
//         symbol: '&#36;',
//     },
//     // {
//     //     name: 'Sint Eustatius',
//     //     code: 'BQ',
//     //     phone_code: '+599',
//     // },
//     // {
//     //     name: 'Sint Maarten',
//     //     code: 'SX',
//     //     phone_code: '+1',
//     // },
//     {
//         name: 'Slovakia',
//         code: 'SK',
//         phone_code: '+421',
//         currency: 'Euro',
//         abbreviation: 'EUR',
//         symbol: '&#8364;',
//     },
//     {
//         name: 'Slovenia',
//         code: 'SI',
//         phone_code: '+386',
//         currency: 'Euro',
//         abbreviation: 'EUR',
//         symbol: '&#8364;',
//     },
//     {
//         name: 'Solomon Islands',
//         code: 'SB',
//         phone_code: '+677',
//         currency: 'Solomon Islands Dollar',
//         abbreviation: 'SBD',
//         symbol: '&#36;',
//     },
//     {
//         name: 'Somalia',
//         code: 'SO',
//         phone_code: '+252',
//         currency: 'Somalia Shilling',
//         abbreviation: 'SOS',
//         symbol: '&#83;',
//     },
//     {
//         name: 'South Africa',
//         code: 'ZA',
//         phone_code: '+27',
//         currency: 'South Africa Rand',
//         abbreviation: 'ZAR',
//         symbol: '&#82;',
//     },
//     // {
//     //     name: 'South Georgia and the South Sandwich Islands',
//     //     code: 'GS',
//     //     phone_code: '+500',
//     // },
//     // {
//     //     name: 'South Sudan',
//     //     code: 'SS',
//     //     phone_code: '+211',
//     // },
//     {
//         name: 'Spain',
//         code: 'ES',
//         phone_code: '+34',
//         currency: 'Euro',
//         abbreviation: 'EUR',
//         symbol: '&#8364;',
//     },
//     {
//         name: 'Sri Lanka',
//         code: 'LK',
//         phone_code: '+94',
//         currency: 'Sri Lanka Rupee',
//         abbreviation: 'LKR',
//         symbol: '&#8360;',
//     },
//     // {
//     //     name: 'Sudan',
//     //     code: 'SD',
//     //     phone_code: '+249',
//     // },
//     {
//         name: 'Suriname',
//         code: 'SR',
//         phone_code: '+597',
//         currency: 'Suriname Dollar',
//         abbreviation: 'SRD',
//         symbol: '&#36;',
//     },
//     // {
//     //     name: 'Svalbard and Jan Mayen',
//     //     code: 'SJ',
//     //     phone_code: '+47',
//     // },
//     // {
//     //     name: 'Swaziland',
//     //     code: 'SZ',
//     //     phone_code: '+268',
//     // },
//     {
//         name: 'Sweden',
//         code: 'SE',
//         phone_code: '+46',
//         currency: 'Sweden Krona',
//         abbreviation: 'SEK',
//         symbol: '&#107;&#114;',
//     },
//     {
//         name: 'Switzerland',
//         code: 'CH',
//         phone_code: '+41',
//         currency: 'Switzerland Franc',
//         abbreviation: 'CHF',
//         symbol: '&#67;&#72;&#70;',
//     },
//     {
//         name: 'Syria',
//         code: 'SY',
//         phone_code: '+963',
//         currency: 'Syria Pound',
//         abbreviation: 'SYP',
//         symbol: '&#163;',
//     },
//     {
//         name: 'Taiwan',
//         code: 'TW',
//         phone_code: '+886',
//         currency: 'Taiwan New Dollar',
//         abbreviation: 'TWD',
//         symbol: '&#78;&#84;&#36;',
//     },
//     // {
//     //     name: 'Tajikistan',
//     //     code: 'TJ',
//     //     phone_code: '+992',
//     // },
//     // {
//     //     name: 'Tanzania',
//     //     code: 'TZ',
//     //     phone_code: '+255',
//     // },
//     {
//         name: 'Thailand',
//         code: 'TH',
//         phone_code: '+66',
//         currency: 'Thailand Baht',
//         abbreviation: 'THB',
//         symbol: '&#3647;',
//     },
//     // {
//     //     name: 'The Bahamas',
//     //     code: 'BS',
//     //     phone_code: '+1',
//     // },
//     // {
//     //     name: 'Timor-Leste',
//     //     code: 'TL',
//     //     phone_code: '+670',
//     // },
//     // {
//     //     name: 'Togo',
//     //     code: 'TG',
//     //     phone_code: '+228',
//     // },
//     // {
//     //     name: 'Tokelau',
//     //     code: 'TK',
//     //     phone_code: '+690',
//     // },
//     // {
//     //     name: 'Tonga',
//     //     code: 'TO',
//     //     phone_code: '+676',
//     // },
//     {
//         name: 'Trinidad and Tobago',
//         code: 'TT',
//         phone_code: '+1',
//         currency: 'Trinidad and Tobago Dollar',
//         abbreviation: 'TTD',
//         symbol: '&#84;&#84;&#36;',
//     },
//     // {
//     //     name: 'Tunisia',
//     //     code: 'TN',
//     //     phone_code: '+216',
//     // },
//     {
//         name: 'Turkey',
//         code: 'TR',
//         phone_code: '+90',
//         currency: 'Turkey Lira',
//         abbreviation: 'TRL',
//         symbol: '&#8356;',
//     },
//     // {
//     //     name: 'Turkmenistan',
//     //     code: 'TM',
//     //     phone_code: '+993',
//     // },
//     // {
//     //     name: 'Turks and Caicos Islands',
//     //     code: 'TC',
//     //     phone_code: '+1',
//     // },
//     {
//         name: 'Tuvalu',
//         code: 'TV',
//         phone_code: '+688',
//         currency: 'Tuvalu Dollar',
//         abbreviation: 'TVD',
//         symbol: '&#36;',
//     },
//     // {
//     //     name: 'Uganda',
//     //     code: 'UG',
//     //     phone_code: '+256',
//     // },
//     {
//         name: 'Ukraine',
//         code: 'UA',
//         phone_code: '+380',
//         currency: 'Ukraine Hryvna',
//         abbreviation: 'UAH',
//         symbol: '&#8372;',
//     },
//     // {
//     //     name: 'United Arab Emirates',
//     //     code: 'AE',
//     //     phone_code: '+971',
//     // },
//     {
//         name: 'United Kingdom',
//         code: 'GB',
//         phone_code: '+44',
//         currency: 'United Kingdom Pound',
//         abbreviation: 'GBP',
//         symbol: '&#163;',
//     },
//     // {
//     //     name: 'United States Minor Outlying Islands',
//     //     code: 'UM',
//     //     phone_code: '+246',
//     // },
//     {
//         name: 'United States of America',
//         code: 'US',
//         phone_code: '+1',
//         currency: 'United States Dollar',
//         abbreviation: 'USD',
//         symbol: '&#36;',
//     },
//     {
//         name: 'Uruguay',
//         code: 'UY',
//         phone_code: '+598',
//         currency: 'Uruguay Peso',
//         abbreviation: 'UYU',
//         symbol: '&#36;&#85;',
//     },
//     {
//         name: 'Uzbekistan',
//         code: 'UZ',
//         phone_code: '+998',
//         currency: 'Uzbekistan Som',
//         abbreviation: 'UZS',
//         symbol: '&#1083;&#1074;',
//     },
//     // {
//     //     name: 'Vanuatu',
//     //     code: 'VU',
//     //     phone_code: '+678',
//     // },

//     {
//         name: 'Venezuela',
//         code: 'VE',
//         phone_code: '+58',
//         currency: 'Venezuela Bolivar',
//         abbreviation: 'VEF',
//         symbol: '&#66;&#115;',
//     },
//     {
//         name: 'Vietnam',
//         code: 'VN',
//         phone_code: '+84',
//         currency: 'Viet Nam Dong',
//         abbreviation: 'VND',
//         symbol: '&#8363;',
//     },
//     // {
//     //     name: 'Virgin Islands (British)',
//     //     code: 'VG',
//     //     phone_code: '+1',
//     // },
//     // {
//     //     name: 'Virgin Islands (US)',
//     //     code: 'VI',
//     //     phone_code: '+1',
//     // },
//     // {
//     //     name: 'Wallis and Futuna',
//     //     code: 'WF',
//     //     phone_code: '+681',
//     // },
//     {
//         name: 'Yemen',
//         code: 'YE',
//         phone_code: '+967',
//         currency: 'Yemen Rial',
//         abbreviation: 'YER',
//         symbol: '&#65020;',
//     },
//     // {
//     //     name: 'Zambia',
//     //     code: 'ZM',
//     //     phone_code: '+260',
//     // },
//     {
//         name: 'Zimbabwe',
//         code: 'ZW',
//         phone_code: '+263',
//         currency: 'Zimbabwe Dollar',
//         abbreviation: 'ZWD',
//         symbol: '&#90;&#36;',
//     },
// ];

export const CountryList = [
  {
    name: "list.country.australia",
    code: "AU",
    phone_code: "+61",
    currency: "Australia Dollar",
    abbreviation: "AUD",
    symbol: "&#36;",
    isPrimary: true
  },
  {
    name: "list.country.hongkong",
    code: "HK",
    phone_code: "+852",
    currency: "Hong Kong Dollar",
    abbreviation: "HKD",
    symbol: "&#36;",
    isPrimary: true
  },
  {
    name: "list.country.malaysia",
    code: "MY",
    phone_code: "+60",
    currency: "Malaysia Ringgit",
    abbreviation: "MYR",
    symbol: "&#82;&#77;",
    isPrimary: true
  },
  {
    name: "list.country.newzealand",
    code: "NZ",
    phone_code: "+64",
    currency: "New Zealand Dollar",
    abbreviation: "NZD",
    symbol: "&#36;",
    isPrimary: true
  },
  {
    name: "list.country.singapore",
    code: "SG",
    phone_code: "+65",
    currency: "Singapore Dollar",
    abbreviation: "SGD",
    symbol: "&#36;",
    isPrimary: true
  },
  {
    name: "list.country.afghanistan",
    code: "AF",
    phone_code: "+93",
    currency: "Afghanistan Afghani",
    abbreviation: "AFN",
    symbol: "&#1547;"
  },
  {
    name: "list.country.albania",
    code: "AL",
    phone_code: "+355",
    currency: "Albania Lek",
    abbreviation: "ALL",
    symbol: "&#76;&#101;&#107;"
  },
  {
    name: "list.country.andorra",
    code: "AD",
    phone_code: "+376",
    currency: "Euro",
    abbreviation: "EUR",
    symbol: "&#8364;"
  },
  {
    name: "list.country.argentina",
    code: "AR",
    phone_code: "+54",
    currency: "Argentina Peso",
    abbreviation: "ARS",
    symbol: "&#36;"
  },
  {
    name: "list.country.aruba",
    code: "AW",
    phone_code: "+297",
    currency: "Aruba Guilder",
    abbreviation: "AWG",
    symbol: "&#402;"
  },
  {
    name: "list.country.austria",
    code: "AI",
    phone_code: "+43",
    currency: "Euro",
    abbreviation: "EUR",
    symbol: "&#8364;"
  },
  {
    name: "list.country.azerbaijan",
    code: "AZ",
    phone_code: "+994",
    currency: "Azerbaijan New Manat",
    abbreviation: "AZN",
    symbol: "&#1084;&#1072;&#1085;"
  },
  {
    name: "list.country.bahamas",
    code: "BS",
    phone_code: "+1",
    currency: "Bahamas Dollar",
    abbreviation: "BSD",
    symbol: "&#36;"
  },
  {
    name: "list.country.barbados",
    code: "BB",
    phone_code: "+1",
    currency: "Barbados Dollar",
    abbreviation: "BBD",
    symbol: "&#36;"
  },
  {
    name: "list.country.belarus",
    code: "BY",
    phone_code: "+375",
    currency: "Belarus Ruble",
    abbreviation: "BYR",
    symbol: "&#112;&#46;"
  },
  {
    name: "list.country.belgium",
    code: "BE",
    phone_code: "+32",
    currency: "Euro",
    abbreviation: "EUR",
    symbol: "&#8364;"
  },
  {
    name: "list.country.belize",
    code: "BZ",
    phone_code: "+501",
    currency: "Belize Dollar",
    abbreviation: "BZD",
    symbol: "&#66;&#90;&#36;"
  },
  {
    name: "list.country.bermuda",
    code: "BM",
    phone_code: "+1",
    currency: "Bermuda Dollar",
    abbreviation: "BMD",
    symbol: "&#36;"
  },
  {
    name: "list.country.bolivia",
    code: "BO",
    phone_code: "+591",
    currency: "Bolivia Boliviano",
    abbreviation: "BOB",
    symbol: "&#36;&#98;"
  },
  {
    name: "list.country.bosniaandherzegovina",
    code: "BA",
    phone_code: "+387",
    currency: "Bosnia and Herzegovina Convertible Marka",
    abbreviation: "BAM",
    symbol: "&#75;&#77;"
  },
  {
    name: "list.country.botswana",
    code: "BW",
    phone_code: "+267",
    currency: "Botswana Pula",
    abbreviation: "BWP",
    symbol: "&#80;"
  },
  {
    name: "list.country.brazil",
    code: "BR",
    phone_code: "+55",
    currency: "Brazil Real",
    abbreviation: "BRL",
    symbol: "&#82;&#36;"
  },
  {
    name: "list.country.bruneidarussalam",
    code: "BN",
    phone_code: "+673",
    currency: "Brunei Darussalam Dollar",
    abbreviation: "BND",
    symbol: "&#36;"
  },
  {
    name: "list.country.bulgaria",
    code: "BG",
    phone_code: "+359",
    currency: "Bulgaria Lev",
    abbreviation: "BGN",
    symbol: "&#1083;&#1074;"
  },
  {
    name: "list.country.cambodia",
    code: "KH",
    phone_code: "+855",
    currency: "Cambodia Riel",
    abbreviation: "KHR",
    symbol: "&#6107;"
  },
  {
    name: "list.country.canada",
    code: "CA",
    phone_code: "+1",
    currency: "Canada Dollar",
    abbreviation: "CAD",
    symbol: "&#36;"
  },
  {
    name: "list.country.caymanislands",
    code: "KY",
    phone_code: "+1",
    currency: "Cayman Islands Dollar",
    abbreviation: "KYD",
    symbol: "&#36;"
  },
  {
    name: "list.country.chile",
    code: "CL",
    phone_code: "+56",
    currency: "Chile Peso",
    abbreviation: "CLP",
    symbol: "&#36;"
  },
  {
    name: "list.country.china",
    code: "CN",
    phone_code: "+86",
    currency: "China Yuan Renminbi",
    abbreviation: "CNY",
    symbol: "&#165;"
  },
  {
    name: "list.country.colombia",
    code: "CO",
    phone_code: "+57",
    currency: "Colombia Peso",
    abbreviation: "COP",
    symbol: "&#36;"
  },
  {
    name: "list.country.costarica",
    code: "CR",
    phone_code: "+506",
    currency: "Costa Rica Colon",
    abbreviation: "CRC",
    symbol: "&#8353;"
  },
  {
    name: "list.country.croatia",
    code: "HR",
    phone_code: "+385",
    currency: "Croatia Kuna",
    abbreviation: "HRK",
    symbol: "&#107;&#110;"
  },
  {
    name: "list.country.cuba",
    code: "CU",
    phone_code: "+53",
    currency: "Cuba Peso",
    abbreviation: "CUP",
    symbol: "&#8369;"
  },
  {
    name: "list.country.cyprus",
    code: "CY",
    phone_code: "+357",
    currency: "Euro",
    abbreviation: "EUR",
    symbol: "&#8364;"
  },
  {
    name: "list.country.czechrepublic",
    code: "CZ",
    phone_code: "+420",
    currency: "Czech Republic Koruna",
    abbreviation: "CZK",
    symbol: "&#75;&#269;"
  },
  {
    name: "list.country.denmark",
    code: "DK",
    phone_code: "+45",
    currency: "Denmark Krone",
    abbreviation: "DKK",
    symbol: "&#107;&#114;"
  },
  {
    name: "list.country.dominicanrepublic",
    code: "DO",
    phone_code: "+1",
    currency: "Dominican Republic Peso",
    abbreviation: "DOP",
    symbol: "&#82;&#68;&#36;"
  },
  {
    name: "list.country.egypt",
    code: "EG",
    phone_code: "+20",
    currency: "Egypt Pound",
    abbreviation: "EGP",
    symbol: "&#163;"
  },
  {
    name: "list.country.elsalvador",
    code: "SV",
    phone_code: "+503",
    currency: "El Salvador Colon",
    abbreviation: "SVC",
    symbol: "&#36;"
  },
  {
    name: "list.country.estonia",
    code: "EE",
    phone_code: "+372",
    currency: "Euro",
    abbreviation: "EUR",
    symbol: "&#8364;"
  },
  {
    name: "list.country.falklandislands",
    code: "FK",
    phone_code: "+500",
    currency: "Falkland Islands (Malvinas) Pound",
    abbreviation: "FKP",
    symbol: "&#163;"
  },
  {
    name: "list.country.fiji",
    code: "FJ",
    phone_code: "+679",
    currency: "Fiji Dollar",
    abbreviation: "FJD",
    symbol: "&#36;"
  },
  {
    name: "list.country.finland",
    code: "FI",
    phone_code: "+358",
    currency: "Euro",
    abbreviation: "EUR",
    symbol: "&#8364;"
  },
  {
    name: "list.country.france",
    code: "FR",
    phone_code: "+33",
    currency: "Euro",
    abbreviation: "EUR",
    symbol: "&#8364;"
  },
  {
    name: "list.country.germany",
    code: "DE",
    phone_code: "+49",
    currency: "Euro",
    abbreviation: "EUR",
    symbol: "&#8364;"
  },
  {
    name: "list.country.ghana",
    code: "GH",
    phone_code: "+233",
    currency: "Ghana Cedis",
    abbreviation: "GHC",
    symbol: "&#162;"
  },
  {
    name: "list.country.gibraltar",
    code: "GI",
    phone_code: "+350",
    currency: "Gibraltar Pound",
    abbreviation: "GIP",
    symbol: "&#163;"
  },
  {
    name: "list.country.greece",
    code: "GR",
    phone_code: "+30",
    currency: "Euro",
    abbreviation: "EUR",
    symbol: "&#8364;"
  },
  {
    name: "list.country.guatemala",
    code: "GT",
    phone_code: "+502",
    currency: "Guatemala Quetzal",
    abbreviation: "GTQ",
    symbol: "&#81;"
  },
  {
    name: "list.country.guernsey",
    code: "GG",
    phone_code: "+44",
    currency: "Guernsey Pound",
    abbreviation: "GGP",
    symbol: "&#163;"
  },
  {
    name: "list.country.guyana",
    code: "GY",
    phone_code: "+592",
    currency: "Guyana Dollar",
    abbreviation: "GYD",
    symbol: "&#36;"
  },
  {
    name: "list.country.honduras",
    code: "HN",
    phone_code: "+504",
    currency: "Honduras Lempira",
    abbreviation: "HNL",
    symbol: "&#76;"
  },
  {
    name: "list.country.hungary",
    code: "HU",
    phone_code: "+36",
    currency: "Hungary Forint",
    abbreviation: "HUF",
    symbol: "&#70;&#116;"
  },
  {
    name: "list.country.iceland",
    code: "IS",
    phone_code: "+354",
    currency: "Iceland Krona",
    abbreviation: "ISK",
    symbol: "&#107;&#114;"
  },
  {
    name: "list.country.india",
    code: "IN",
    phone_code: "+91",
    currency: "India Rupee",
    abbreviation: "INR",
    symbol: null
  },
  {
    name: "list.country.indonesia",
    code: "ID",
    phone_code: "+62",
    currency: "Indonesia Rupiah",
    abbreviation: "IDR",
    symbol: "&#82;&#112;"
  },
  {
    name: "list.country.iran",
    code: "IR",
    phone_code: "+98",
    currency: "Iran Rial",
    abbreviation: "IRR",
    symbol: "&#65020;"
  },
  {
    name: "list.country.ireland",
    code: "IE",
    phone_code: "+353",
    currency: "Euro",
    abbreviation: "EUR",
    symbol: "&#8364;"
  },
  {
    name: "list.country.isleofman",
    code: "IM",
    phone_code: "+44",
    currency: "Isle of Man Pound",
    abbreviation: "IMP",
    symbol: "&#163;"
  },
  {
    name: "list.country.israel",
    code: "IL",
    phone_code: "+972",
    currency: "Israel Shekel",
    abbreviation: "ILS",
    symbol: "&#8362;"
  },
  {
    name: "list.country.italy",
    code: "IT",
    phone_code: "+39",
    currency: "Euro",
    abbreviation: "EUR",
    symbol: "&#8364;"
  },
  {
    name: "list.country.jamaica",
    code: "JM",
    phone_code: "+1",
    currency: "Jamaica Dollar",
    abbreviation: "JMD",
    symbol: "&#74;&#36;"
  },
  {
    name: "list.country.japan",
    code: "JP",
    phone_code: "+81",
    currency: "Japan Yen",
    abbreviation: "JPY",
    symbol: "&#165;"
  },
  {
    name: "list.country.jersey",
    code: "JE",
    phone_code: "+44",
    currency: "Jersey Pound",
    abbreviation: "JEP",
    symbol: "&#163;"
  },
  {
    name: "list.country.kazakhstan",
    code: "KZ",
    phone_code: "+7",
    currency: "Kazakhstan Tenge",
    abbreviation: "KZT",
    symbol: "&#1083;&#1074;"
  },
  {
    name: "list.country.koreademocraticpeoplesrepublicof",
    code: "KP",
    phone_code: "+850",
    currency: "Korea (North) Won",
    abbreviation: "KPW",
    symbol: "&#8361;"
  },
  {
    name: "list.country.korearepublicof",
    code: "KR",
    phone_code: "+82",
    currency: "Korea (South) Won",
    abbreviation: "KRW",
    symbol: "&#8361;"
  },
  {
    name: "list.country.kyrgyzstan",
    code: "KG",
    phone_code: "+996",
    currency: "Kyrgyzstan Som",
    abbreviation: "KGS",
    symbol: "&#1083;&#1074;"
  },
  {
    name: "list.country.laos",
    code: "LA",
    phone_code: "+856",
    currency: "Laos Kip",
    abbreviation: "LAK",
    symbol: "&#8365;"
  },
  {
    name: "list.country.latvia",
    code: "LV",
    phone_code: "+371",
    currency: "Euro",
    abbreviation: "EUR",
    symbol: "&#8364;"
  },
  {
    name: "list.country.lebanon",
    code: "LB",
    phone_code: "+961",
    currency: "Lebanon Pound",
    abbreviation: "LBP",
    symbol: "&#163;"
  },

  {
    name: "list.country.liberia",
    code: "LR",
    phone_code: "+231",
    currency: "Liberia Dollar",
    abbreviation: "LRD",
    symbol: "&#36;"
  },
  {
    name: "list.country.liechtenstein",

    code: "LI",
    phone_code: "+423",
    currency: "Swiss Franc",
    abbreviation: "CHF",
    symbol: "Fr."
  },
  {
    name: "list.country.lithuania",
    code: "LT",
    phone_code: "+370",
    currency: "Euro",
    abbreviation: "EUR",
    symbol: "&#8364;"
  },
  {
    name: "list.country.luxembourg",
    code: "LU",
    phone_code: "+352",
    currency: "Euro",
    abbreviation: "EUR",
    symbol: "&#8364;"
  },
  {
    name: "list.country.macedonia",
    code: "MK",
    phone_code: "+389",
    currency: "Macedonia Denar",
    abbreviation: "MKD",
    symbol: "&#1076;&#1077;&#1085;"
  },
  {
    name: "list.country.malta",
    code: "MT",
    phone_code: "+356",
    currency: "Euro",
    abbreviation: "EUR",
    symbol: "&#8364;"
  },
  {
    name: "list.country.mauritius",
    code: "MU",
    phone_code: "+230",
    currency: "Mauritius Rupee",
    abbreviation: "MUR",
    symbol: "&#8360;"
  },
  {
    name: "list.country.mexico",
    code: "MX",
    phone_code: "+52",
    currency: "Mexico Peso",
    abbreviation: "MXN",
    symbol: "&#36;"
  },
  {
    name: "list.country.monaco",
    code: "MC",
    phone_code: "+377",
    currency: "Euro",
    abbreviation: "EUR",
    symbol: "&#8364;"
  },
  {
    name: "list.country.mongolia",
    code: "MN",
    phone_code: "+976",
    currency: "Mongolia Tughrik",
    abbreviation: "MNT",
    symbol: "&#8366;"
  },
  {
    name: "list.country.mozambique",
    code: "MZ",
    phone_code: "+258",
    currency: "Mozambique Metical",
    abbreviation: "MZN",
    symbol: "&#77;&#84;"
  },
  {
    name: "list.country.namibia",
    code: "NA",
    phone_code: "+264",
    currency: "Namibia Dollar",
    abbreviation: "NAD",
    symbol: "&#36;"
  },
  {
    name: "list.country.nepal",
    code: "NP",
    phone_code: "+977",
    currency: "Nepal Rupee",
    abbreviation: "NPR",
    symbol: "&#8360;"
  },
  {
    name: "list.country.netherlands",
    code: "NL",
    phone_code: "+31",
    currency: "Euro",
    abbreviation: "EUR",
    symbol: "&#8364;"
  },
  {
    name: "list.country.netherlandsantilles",
    code: "AN",
    phone_code: "+599",
    currency: "Netherlands Antilles Guilder",
    abbreviation: "ANG",
    symbol: "&#402;"
  },
  {
    name: "list.country.nicaragua",
    code: "NI",
    phone_code: "+505",
    currency: "Nicaragua Cordoba",
    abbreviation: "NIO",
    symbol: "&#67;&#36;"
  },
  {
    name: "list.country.nigeria",
    code: "NG",
    phone_code: "+234",
    currency: "Nigeria Naira",
    abbreviation: "NGN",
    symbol: "&#8358;"
  },
  {
    name: "list.country.norway",
    code: "NO",
    phone_code: "+47",
    currency: "Norway Krone",
    abbreviation: "NOK",
    symbol: "&#107;&#114;"
  },
  {
    name: "list.country.oman",
    code: "OM",
    phone_code: "+968",
    currency: "Oman Rial",
    abbreviation: "OMR",
    symbol: "&#65020;"
  },
  {
    name: "list.country.pakistan",
    code: "PK",
    phone_code: "+92",
    currency: "Pakistan Rupee",
    abbreviation: "PKR",
    symbol: "&#8360;"
  },
  {
    name: "list.country.panama",
    code: "PA",
    phone_code: "+507",
    currency: "Panama Balboa",
    abbreviation: "PAB",
    symbol: "&#66;&#47;&#46;"
  },
  {
    name: "list.country.paraguay",
    code: "PY",
    phone_code: "+595",
    currency: "Paraguay Guarani",
    abbreviation: "PYG",
    symbol: "&#71;&#115;"
  },
  {
    name: "list.country.peru",
    code: "PE",
    phone_code: "+51",
    currency: "Peru Nuevo Sol",
    abbreviation: "PEN",
    symbol: "&#83;&#47;&#46;"
  },
  {
    name: "list.country.philippines",
    code: "PH",
    phone_code: "+63",
    currency: "Philippines Peso",
    abbreviation: "PHP",
    symbol: "&#8369;"
  },
  {
    name: "list.country.poland",
    code: "PL",
    phone_code: "+48",
    currency: "Poland Zloty",
    abbreviation: "PLN",
    symbol: "&#122;&#322;"
  },
  {
    name: "list.country.portugal",
    code: "PT",
    phone_code: "+351",
    currency: "Euro",
    abbreviation: "EUR",
    symbol: "&#8364;"
  },
  {
    name: "list.country.qatar",
    code: "QA",
    phone_code: "+974",
    currency: "Qatar Riyal",
    abbreviation: "QAR",
    symbol: "&#65020;"
  },
  {
    name: "list.country.romania",
    code: "RO",
    phone_code: "+40",
    currency: "Romania New Leu",
    abbreviation: "RON",
    symbol: "&#108;&#101;&#105;"
  },
  {
    name: "list.country.russia",
    code: "RU",
    phone_code: "+7",
    currency: "Russia Ruble",
    abbreviation: "RUB",
    symbol: "&#1088;&#1091;&#1073;"
  },
  {
    name: "list.country.sainthelenaascensionandtristandacunha",
    code: "SH",
    phone_code: "+290",
    currency: "Saint Helena Pound",
    abbreviation: "SHP",
    symbol: "&#163;"
  },
  {
    name: "list.country.saudiarabia",
    code: "SA",
    phone_code: "+966",
    currency: "Saudi Arabia Riyal",
    abbreviation: "SAR",
    symbol: "&#65020;"
  },
  {
    name: "list.country.serbia",
    code: "RS",
    phone_code: "+381",
    currency: "Serbia Dinar",
    abbreviation: "RSD",
    symbol: "&#1044;&#1080;&#1085;&#46;"
  },
  {
    name: "list.country.seychelles",
    code: "SC",
    phone_code: "+248",
    currency: "Seychelles Rupee",
    abbreviation: "SCR",
    symbol: "&#8360;"
  },
  {
    name: "list.country.slovakia",
    code: "SK",
    phone_code: "+421",
    currency: "Euro",
    abbreviation: "EUR",
    symbol: "&#8364;"
  },
  {
    name: "list.country.slovenia",
    code: "SI",
    phone_code: "+386",
    currency: "Euro",
    abbreviation: "EUR",
    symbol: "&#8364;"
  },
  {
    name: "list.country.solomonislands",
    code: "SB",
    phone_code: "+677",
    currency: "Solomon Islands Dollar",
    abbreviation: "SBD",
    symbol: "&#36;"
  },
  {
    name: "list.country.somalia",
    code: "SO",
    phone_code: "+252",
    currency: "Somalia Shilling",
    abbreviation: "SOS",
    symbol: "&#83;"
  },
  {
    name: "list.country.southafrica",
    code: "ZA",
    phone_code: "+27",
    currency: "South Africa Rand",
    abbreviation: "ZAR",
    symbol: "&#82;"
  },
  {
    name: "list.country.spain",
    code: "ES",
    phone_code: "+34",
    currency: "Euro",
    abbreviation: "EUR",
    symbol: "&#8364;"
  },
  {
    name: "list.country.srilanka",
    code: "LK",
    phone_code: "+94",
    currency: "Sri Lanka Rupee",
    abbreviation: "LKR",
    symbol: "&#8360;"
  },
  {
    name: "list.country.suriname",
    code: "SR",
    phone_code: "+597",
    currency: "Suriname Dollar",
    abbreviation: "SRD",
    symbol: "&#36;"
  },
  {
    name: "list.country.sweden",
    code: "SE",
    phone_code: "+46",
    currency: "Sweden Krona",
    abbreviation: "SEK",
    symbol: "&#107;&#114;"
  },
  {
    name: "list.country.switzerland",
    code: "CH",
    phone_code: "+41",
    currency: "Switzerland Franc",
    abbreviation: "CHF",
    symbol: "&#67;&#72;&#70;"
  },
  {
    name: "list.country.syria",
    code: "SY",
    phone_code: "+963",
    currency: "Syria Pound",
    abbreviation: "SYP",
    symbol: "&#163;"
  },
  {
    name: "list.country.taiwan",
    code: "TW",
    phone_code: "+886",
    currency: "Taiwan New Dollar",
    abbreviation: "TWD",
    symbol: "&#78;&#84;&#36;"
  },
  {
    name: "list.country.thailand",
    code: "TH",
    phone_code: "+66",
    currency: "Thailand Baht",
    abbreviation: "THB",
    symbol: "&#3647;"
  },
  {
    name: "list.country.trinidadandtobago",
    code: "TT",
    phone_code: "+1",
    currency: "Trinidad and Tobago Dollar",
    abbreviation: "TTD",
    symbol: "&#84;&#84;&#36;"
  },
  {
    name: "list.country.turkey",
    code: "TR",
    phone_code: "+90",
    currency: "Turkey Lira",
    abbreviation: "TRL",
    symbol: "&#8356;"
  },
  {
    name: "list.country.tuvalu",
    code: "TV",
    phone_code: "+688",
    currency: "Tuvalu Dollar",
    abbreviation: "TVD",
    symbol: "&#36;"
  },
  {
    name: "list.country.ukraine",
    code: "UA",
    phone_code: "+380",
    currency: "Ukraine Hryvna",
    abbreviation: "UAH",
    symbol: "&#8372;"
  },
  {
    name: "list.country.unitedkingdom",
    code: "GB",
    phone_code: "+44",
    currency: "United Kingdom Pound",
    abbreviation: "GBP",
    symbol: "&#163;"
  },
  {
    name: "list.country.unitedstatesofamerica",
    code: "US",
    phone_code: "+1",
    currency: "United States Dollar",
    abbreviation: "USD",
    symbol: "&#36;"
  },
  {
    name: "list.country.uruguay",
    code: "UY",
    phone_code: "+598",
    currency: "Uruguay Peso",
    abbreviation: "UYU",
    symbol: "&#36;&#85;"
  },
  {
    name: "list.country.uzbekistan",
    code: "UZ",
    phone_code: "+998",
    currency: "Uzbekistan Som",
    abbreviation: "UZS",
    symbol: "&#1083;&#1074;"
  },
  {
    name: "list.country.vaticanCity",
    code: "VA",
    phone_code: "+39",
    currency: "Vatican Euro",
    abbreviation: "EUR",
    symbol: "&#8364;"
  },

  {
    name: "list.country.venezuela",
    code: "VE",
    phone_code: "+58",
    currency: "Venezuela Bolivar",
    abbreviation: "VEF",
    symbol: "&#66;&#115;"
  },
  {
    name: "list.country.vietnam",
    code: "VN",
    phone_code: "+84",
    currency: "Viet Nam Dong",
    abbreviation: "VND",
    symbol: "&#8363;"
  },
  {
    name: "list.country.yemen",
    code: "YE",
    phone_code: "+967",
    currency: "Yemen Rial",
    abbreviation: "YER",
    symbol: "&#65020;"
  },
  {
    name: "list.country.zimbabwe",
    code: "ZW",
    phone_code: "+263",
    currency: "Zimbabwe Dollar",
    abbreviation: "ZWD",
    symbol: "&#90;&#36;"
  }
]

export const OperationCountry = [
  {
    code: "AU",
    label: "Australia",
    value: "AUSTRALIA",
    code: "AU"
  },
  {
    code: "HK",
    label: "Hong Kong",
    value: "HONG_KONG"
  },
  {
    code: "NZ",
    label: "New Zealand",
    value: "NEW_ZEALAND"
  },
  {
    code: "SG",
    label: "Singapore",
    value: "SINGAPORE"
  },
  {
    code: "MY",
    label: "Malaysia",
    value: "MALAYSIA"
  }
]

export const BusinessLocation = [
  {
    name: "Hong Kong",
    code: "HK"
  },
  {
    name: "Australia",
    code: "AU"
  },
  {
    name: "Malaysia",
    code: "MY"
  },
  {
    name: "New Zealand",
    code: "NZ"
  },
  {
    name: "Singapore",
    code: "SG"
  }
]

export const SourceOfFund = [
  {
    name: "OA",
    code: "OA"
  },
  {
    name: "SA",
    code: "SA"
  }
]

export const BusinessMarketSector = [
  { code: 1, name: "Consumer Discretionary" },
  { code: 2, name: "Consumer Staples" },
  { code: 3, name: "Energy" },
  { code: 4, name: "Financials" },
  { code: 5, name: "Health Care" },
  { code: 6, name: "Industrials" },
  { code: 7, name: "Information Technology" },
  { code: 8, name: "Materials" },
  { code: 9, name: "Real Estate" },
  { code: 10, name: "Telecommunication Services" },
  { code: 11, name: "Utilities" },
  { code: 12, name: "Others" }
]

export const BusinessRegistrationType = [
  { code: "SOLE_PROPRIETOR", name: "Sole Proprietor" },
  { code: "PARTNERSHIP", name: "Partnership" },
  { code: "PRIVATE_COMPANY", name: "Private Company" },
  { code: "PUBLIC_COMPANY", name: "Public Company" },
  { code: "CONSULTANT", name: "Consultant" },
  { code: "OTHER", name: "Others" }
]
export const BusinessIndustryGroup = [
  { code: 1, name: "Automobiles & Components" },
  { code: 2, name: "Banks" },
  { code: 3, name: "Capital Goods" },
  { code: 4, name: "Commercial Services & Supplies" },
  { code: 5, name: "Consumer Durables & Apparel" },
  { code: 6, name: "Consumer Services" },
  { code: 7, name: "Diversified Financials" },
  { code: 8, name: "Energy" },
  { code: 9, name: "Food & Staples Retailing" },
  { code: 10, name: "Food, Beverage & Tobacco" },
  { code: 11, name: "Health Care Equipment & Services" },
  { code: 12, name: "Household & Personal Products" },
  { code: 13, name: "Insurance" },
  { code: 14, name: "Materials" },
  { code: 15, name: "Media" },
  { code: 16, name: "Pharmaceuticals, Biotechnology & Life Sciences" },
  { code: 17, name: "Real Estate" },
  { code: 18, name: "Retailing" },
  { code: 19, name: "Semiconductors & Semiconductor Equipment" },
  { code: 20, name: "Software & Services" },
  { code: 21, name: "Technology Hardware & Equipment" },
  { code: 22, name: "Telecommunication Services" },
  { code: 23, name: "Transportation" },
  { code: 24, name: "Utilities" },
  { code: 25, name: "Others" }
]

export const Occupation = [
  {
    code: 1,
    name: "list.occupation.accountant"
  },
  {
    code: 2,
    name: "list.occupation.actor"
  },
  {
    code: 3,
    name: "list.occupation.actuary"
  },
  {
    code: 4,
    name: "list.occupation.administrator"
  },
  {
    code: 5,
    name: "list.occupation.aide"
  },
  {
    code: 6,
    name: "list.occupation.aircraftcrewman"
  },
  {
    code: 7,
    name: "list.occupation.aircraftnavigator"
  },
  {
    code: 8,
    name: "list.occupation.airtrafficcontrol"
  },
  {
    code: 9,
    name: "list.occupation.airtransportworker"
  },
  {
    code: 10,
    name: "list.occupation.ambulanceofficer"
  },
  {
    code: 11,
    name: "list.occupation.anaesthetist"
  },
  {
    code: 12,
    name: "list.occupation.analyst"
  },
  {
    code: 13,
    name: "list.occupation.anatomist"
  },
  {
    code: 14,
    name: "list.occupation.animalattendant"
  },
  {
    code: 15,
    name: "list.occupation.animaltrainer"
  },
  {
    code: 16,
    name: "list.occupation.announcer"
  },
  {
    code: 17,
    name: "list.occupation.apiarist"
  },
  {
    code: 18,
    name: "list.occupation.apparelcutter"
  },
  {
    code: 19,
    name: "list.occupation.appearance"
  },
  {
    code: 20,
    name: "list.occupation.architect"
  },
  {
    code: 21,
    name: "list.occupation.archivist"
  },
  {
    code: 22,
    name: "list.occupation.architectnaval"
  },
  {
    code: 23,
    name: "list.occupation.articledclerk"
  },
  {
    code: 24,
    name: "list.occupation.assembler"
  },
  {
    code: 25,
    name: "list.occupation.assistantmetal"
  },
  {
    code: 26,
    name: "list.occupation.assistantprint"
  },
  {
    code: 27,
    name: "list.occupation.assistantpersonal"
  },
  {
    code: 28,
    name: "list.occupation.assistantconstruct"
  },
  {
    code: 29,
    name: "list.occupation.assistanttrade"
  },
  {
    code: 30,
    name: "list.occupation.attendantdoor"
  },
  {
    code: 31,
    name: "list.occupation.attendantflight"
  },
  {
    code: 32,
    name: "list.occupation.attendantfuneral"
  },
  {
    code: 33,
    name: "list.occupation.attendantgarage"
  },
  {
    code: 34,
    name: "list.occupation.attendantmachine"
  },
  {
    code: 35,
    name: "list.occupation.attendant"
  },
  {
    code: 36,
    name: "list.occupation.auctioneer"
  },
  {
    code: 37,
    name: "list.occupation.audiologist"
  },
  {
    code: 38,
    name: "list.occupation.author"
  },
  {
    code: 39,
    name: "list.occupation.baker"
  },
  {
    code: 40,
    name: "list.occupation.barmanwoman"
  },
  {
    code: 41,
    name: "list.occupation.barrister"
  },
  {
    code: 42,
    name: "list.occupation.beautytherapist"
  },
  {
    code: 43,
    name: "list.occupation.beddingmaker"
  },
  {
    code: 44,
    name: "list.occupation.blacksmith"
  },
  {
    code: 45,
    name: "list.occupation.boatbuilder"
  },
  {
    code: 46,
    name: "list.occupation.boatswain"
  },
  {
    code: 47,
    name: "list.occupation.bookmakersclerk"
  },
  {
    code: 48,
    name: "list.occupation.bookeditor"
  },
  {
    code: 49,
    name: "list.occupation.bookkeeper"
  },
  {
    code: 50,
    name: "list.occupation.bookmaker"
  },
  {
    code: 51,
    name: "list.occupation.botanist"
  },
  {
    code: 52,
    name: "list.occupation.branchmanager"
  },
  {
    code: 53,
    name: "list.occupation.bricklayer"
  },
  {
    code: 54,
    name: "list.occupation.buildingcontractor"
  },
  {
    code: 55,
    name: "list.occupation.builder"
  },
  {
    code: 56,
    name: "list.occupation.buildinginspector"
  },
  {
    code: 56.1,
    name: "list.occupation.businessPerson"
  },
  {
    code: 57,
    name: "list.occupation.butcher"
  },
  {
    code: 58,
    name: "list.occupation.buttermaker"
  },
  {
    code: 59,
    name: "list.occupation.buyer"
  },
  {
    code: 60,
    name: "list.occupation.cabinetmaker"
  },
  {
    code: 61,
    name: "list.occupation.cameraoperator"
  },
  {
    code: 62,
    name: "list.occupation.canvasgoodsmaker"
  },
  {
    code: 63,
    name: "list.occupation.cardiologist"
  },
  {
    code: 64,
    name: "list.occupation.caretaker"
  },
  {
    code: 65,
    name: "list.occupation.carpenter"
  },
  {
    code: 66,
    name: "list.occupation.cartographer"
  },
  {
    code: 67,
    name: "list.occupation.cashier"
  },
  {
    code: 68,
    name: "list.occupation.castingmachine"
  },
  {
    code: 69,
    name: "list.occupation.cheesemaker"
  },
  {
    code: 70,
    name: "list.occupation.chef"
  },
  {
    code: 71,
    name: "list.occupation.chemist"
  },
  {
    code: 72,
    name: "list.occupation.childcareworker"
  },
  {
    code: 73,
    name: "list.occupation.chiropractor"
  },
  {
    code: 74,
    name: "list.occupation.choreographer"
  },
  {
    code: 75,
    name: "list.occupation.classer"
  },
  {
    code: 76,
    name: "list.occupation.cleaner"
  },
  {
    code: 77,
    name: "list.occupation.clerk"
  },
  {
    code: 78,
    name: "list.occupation.clerkaccounts"
  },
  {
    code: 79,
    name: "list.occupation.clerkactuarial"
  },
  {
    code: 80,
    name: "list.occupation.clerkcost"
  },
  {
    code: 81,
    name: "list.occupation.clerkfilling"
  },
  {
    code: 82,
    name: "list.occupation.clerkmailsort"
  },
  {
    code: 83,
    name: "list.occupation.clerkofcourt"
  },
  {
    code: 84,
    name: "list.occupation.clerkpayroll"
  },
  {
    code: 85,
    name: "list.occupation.clerkpostal"
  },
  {
    code: 86,
    name: "list.occupation.clerkstatistical"
  },
  {
    code: 87,
    name: "list.occupation.clockmaker"
  },
  {
    code: 88,
    name: "list.occupation.coachsport"
  },
  {
    code: 89,
    name: "list.occupation.collectionclerk"
  },
  {
    code: 90,
    name: "list.occupation.commoditiesbroker"
  },
  {
    code: 91,
    name: "list.occupation.communityworker"
  },
  {
    code: 92,
    name: "list.occupation.composer"
  },
  {
    code: 93,
    name: "list.occupation.compositor"
  },
  {
    code: 94,
    name: "list.occupation.concreteworker"
  },
  {
    code: 95,
    name: "list.occupation.conductor"
  },
  {
    code: 96,
    name: "list.occupation.confectioner"
  },
  {
    code: 97,
    name: "list.occupation.conservator"
  },
  {
    code: 98,
    name: "list.occupation.cook"
  },
  {
    code: 99,
    name: "list.occupation.cookqualified"
  },
  {
    code: 100,
    name: "list.occupation.cooper"
  },
  {
    code: 101,
    name: "list.occupation.coppersmith"
  },
  {
    code: 102,
    name: "list.occupation.copywriter"
  },
  {
    code: 103,
    name: "list.occupation.coroner"
  },
  {
    code: 104,
    name: "list.occupation.cottonginner"
  },
  {
    code: 105,
    name: "list.occupation.councillor"
  },
  {
    code: 106,
    name: "list.occupation.counsellor"
  },
  {
    code: 107,
    name: "list.occupation.courtorderly"
  },
  {
    code: 108,
    name: "list.occupation.courtreporter"
  },
  {
    code: 109,
    name: "list.occupation.craftworkers"
  },
  {
    code: 110,
    name: "list.occupation.cranechaser"
  },
  {
    code: 111,
    name: "list.occupation.creditoffer"
  },
  {
    code: 112,
    name: "list.occupation.customsofficer"
  },
  {
    code: 113,
    name: "list.occupation.carparkmanager"
  },
  {
    code: 114,
    name: "list.occupation.dancer"
  },
  {
    code: 115,
    name: "list.occupation.dealer"
  },
  {
    code: 116,
    name: "list.occupation.debtcollector"
  },
  {
    code: 117,
    name: "list.occupation.defenceofficer"
  },
  {
    code: 118,
    name: "list.occupation.dentalnurse"
  },
  {
    code: 119,
    name: "list.occupation.dentist"
  },
  {
    code: 120,
    name: "list.occupation.dentaltechnician"
  },
  {
    code: 121,
    name: "list.occupation.dentaltherapist"
  },
  {
    code: 122,
    name: "list.occupation.dermatologist"
  },
  {
    code: 123,
    name: "list.occupation.designer"
  },
  {
    code: 124,
    name: "list.occupation.detective"
  },
  {
    code: 125,
    name: "list.occupation.developerphotos"
  },
  {
    code: 126,
    name: "list.occupation.diesetter"
  },
  {
    code: 127,
    name: "list.occupation.dietitian"
  },
  {
    code: 128,
    name: "list.occupation.directorart"
  },
  {
    code: 129,
    name: "list.occupation.directormusic"
  },
  {
    code: 130,
    name: "list.occupation.director"
  },
  {
    code: 131,
    name: "list.occupation.diver"
  },
  {
    code: 132,
    name: "list.occupation.dockermanwoman"
  },
  {
    code: 133,
    name: "list.occupation.doctorgp"
  },
  {
    code: 134,
    name: "list.occupation.dpmanager"
  },
  {
    code: 135,
    name: "list.occupation.draftsman"
  },
  {
    code: 136,
    name: "list.occupation.drainer"
  },
  {
    code: 137,
    name: "list.occupation.dressmaker"
  },
  {
    code: 138,
    name: "list.occupation.drivercar"
  },
  {
    code: 139,
    name: "list.occupation.driverdelivery"
  },
  {
    code: 140,
    name: "list.occupation.driverforklift"
  },
  {
    code: 141,
    name: "list.occupation.drivertram"
  },
  {
    code: 142,
    name: "list.occupation.driveroffsider"
  },
  {
    code: 143,
    name: "list.occupation.driverbus"
  },
  {
    code: 144,
    name: "list.occupation.drivertrain"
  },
  {
    code: 145,
    name: "list.occupation.drivertaxi"
  },
  {
    code: 146,
    name: "list.occupation.driverinstructor"
  },
  {
    code: 147,
    name: "list.occupation.ecologist"
  },
  {
    code: 148,
    name: "list.occupation.economist"
  },
  {
    code: 149,
    name: "list.occupation.editor"
  },
  {
    code: 150,
    name: "list.occupation.educationofficer"
  },
  {
    code: 151,
    name: "list.occupation.electricianauto"
  },
  {
    code: 152,
    name: "list.occupation.electroplater"
  },
  {
    code: 153,
    name: "list.occupation.electrician"
  },
  {
    code: 154,
    name: "list.occupation.engineeraero"
  },
  {
    code: 155,
    name: "list.occupation.engineeragric"
  },
  {
    code: 156,
    name: "list.occupation.engineeraircraft"
  },
  {
    code: 157,
    name: "list.occupation.engineerchem"
  },
  {
    code: 158,
    name: "list.occupation.engineercivil"
  },
  {
    code: 159,
    name: "list.occupation.engineerelect"
  },
  {
    code: 160,
    name: "list.occupation.engineerindust"
  },
  {
    code: 161,
    name: "list.occupation.engineermarine"
  },
  {
    code: 162,
    name: "list.occupation.engineermech"
  },
  {
    code: 163,
    name: "list.occupation.engineermining"
  },
  {
    code: 164,
    name: "list.occupation.engineermaterial"
  },
  {
    code: 165,
    name: "list.occupation.engineer"
  },
  {
    code: 166,
    name: "list.occupation.engineerpetrol"
  },
  {
    code: 167,
    name: "list.occupation.engineersoftware"
  },
  {
    code: 168,
    name: "list.occupation.engraver"
  },
  {
    code: 168.1,
    name: "list.occupation.entrepreneur"
  },
  {
    code: 169,
    name: "list.occupation.explosivesexpert"
  },
  {
    code: 170,
    name: "list.occupation.factoryhand"
  },
  {
    code: 171,
    name: "list.occupation.familyaide"
  },
  {
    code: 172,
    name: "list.occupation.farmerbeef"
  },
  {
    code: 173,
    name: "list.occupation.farmerdairy"
  },
  {
    code: 174,
    name: "list.occupation.farmer"
  },
  {
    code: 175,
    name: "list.occupation.farmhand"
  },
  {
    code: 176,
    name: "list.occupation.farmermixed"
  },
  {
    code: 177,
    name: "list.occupation.farmerpig"
  },
  {
    code: 178,
    name: "list.occupation.farmerpoultry"
  },
  {
    code: 179,
    name: "list.occupation.farmersheep"
  },
  {
    code: 180,
    name: "list.occupation.farrier"
  },
  {
    code: 181,
    name: "list.occupation.fenceerector"
  },
  {
    code: 182,
    name: "list.occupation.filmeditor"
  },
  {
    code: 183,
    name: "list.occupation.financemanager"
  },
  {
    code: 184,
    name: "list.occupation.financialplanner"
  },
  {
    code: 185,
    name: "list.occupation.firefighter"
  },
  {
    code: 186,
    name: "list.occupation.fishermanwoman"
  },
  {
    code: 187,
    name: "list.occupation.fitterelectric"
  },
  {
    code: 188,
    name: "list.occupation.fitter"
  },
  {
    code: 189,
    name: "list.occupation.flightengineer"
  },
  {
    code: 190,
    name: "list.occupation.flightservofficer"
  },
  {
    code: 191,
    name: "list.occupation.floorcoverer"
  },
  {
    code: 192,
    name: "list.occupation.florist"
  },
  {
    code: 193,
    name: "list.occupation.flyinginstruct"
  },
  {
    code: 194,
    name: "list.occupation.foodfactorycook"
  },
  {
    code: 195,
    name: "list.occupation.forester"
  },
  {
    code: 196,
    name: "list.occupation.forestryworker"
  },
  {
    code: 197,
    name: "list.occupation.formdesigner"
  },
  {
    code: 198,
    name: "list.occupation.freighthandler"
  },
  {
    code: 199,
    name: "list.occupation.funeraldirector"
  },
  {
    code: 200,
    name: "list.occupation.furnituremaker"
  },
  {
    code: 201,
    name: "list.occupation.furrier"
  },
  //     Business Person

  // Entrepreneur

  // Property Developer
  {
    code: 202,
    name: "list.occupation.garbologist"
  },
  {
    code: 203,
    name: "list.occupation.gardener"
  },
  {
    code: 204,
    name: "list.occupation.gasfitter"
  },
  {
    code: 205,
    name: "list.occupation.gemcutter"
  },
  {
    code: 206,
    name: "list.occupation.generalhand"
  },
  {
    code: 207,
    name: "list.occupation.generalmanager"
  },
  {
    code: 208,
    name: "list.occupation.geographer"
  },
  {
    code: 209,
    name: "list.occupation.geologist"
  },
  {
    code: 210,
    name: "list.occupation.geophysicist"
  },
  {
    code: 211,
    name: "list.occupation.glassblower"
  },
  {
    code: 212,
    name: "list.occupation.greenkeeper"
  },
  {
    code: 213,
    name: "list.occupation.growerfruitnut"
  },
  {
    code: 214,
    name: "list.occupation.growergrain"
  },
  {
    code: 215,
    name: "list.occupation.growersugar"
  },
  {
    code: 216,
    name: "list.occupation.growertobacco"
  },
  {
    code: 217,
    name: "list.occupation.growervegetable"
  },
  {
    code: 218,
    name: "list.occupation.guard"
  },
  {
    code: 219,
    name: "list.occupation.gunhandplastic"
  },
  {
    code: 220,
    name: "list.occupation.gunsmith"
  },
  {
    code: 221,
    name: "list.occupation.hairdresser"
  },
  {
    code: 222,
    name: "list.occupation.handprinting"
  },
  {
    code: 223,
    name: "list.occupation.handymanwoman"
  },
  {
    code: 224,
    name: "list.occupation.hatmaker"
  },
  {
    code: 225,
    name: "list.occupation.historian"
  },
  {
    code: 226,
    name: "list.occupation.homecompanion"
  },
  {
    code: 227,
    name: "list.occupation.homeduties"
  },
  {
    code: 228,
    name: "list.occupation.horsebreaker"
  },
  {
    code: 229,
    name: "list.occupation.horsebreeder"
  },
  {
    code: 230,
    name: "list.occupation.hostelparent"
  },
  {
    code: 231,
    name: "list.occupation.hotelmanager"
  },
  {
    code: 232,
    name: "list.occupation.housekeeper"
  },
  {
    code: 233,
    name: "list.occupation.icecreammaker"
  },
  {
    code: 234,
    name: "list.occupation.illustrator"
  },
  {
    code: 235,
    name: "list.occupation.importerexporter"
  },
  {
    code: 236,
    name: "list.occupation.industrelofficer"
  },
  {
    code: 237,
    name: "list.occupation.insuranceassessor"
  },
  {
    code: 238,
    name: "list.occupation.insurancebroker"
  },
  {
    code: 239,
    name: "list.occupation.inspector"
  },
  {
    code: 240,
    name: "list.occupation.inspectorhealth"
  },
  {
    code: 241,
    name: "list.occupation.inspectortraffic"
  },
  {
    code: 242,
    name: "list.occupation.inspectortrain"
  },
  {
    code: 243,
    name: "list.occupation.installer"
  },
  {
    code: 244,
    name: "list.occupation.instrumentmaker"
  },
  {
    code: 245,
    name: "list.occupation.insulationmaker"
  },
  {
    code: 246,
    name: "list.occupation.insuranceagent"
  },
  {
    code: 247,
    name: "list.occupation.intelligenceofficer"
  },
  {
    code: 248,
    name: "list.occupation.interiordecorator"
  },
  {
    code: 249,
    name: "list.occupation.interpreter"
  },
  {
    code: 250,
    name: "list.occupation.jeweller"
  },
  {
    code: 251,
    name: "list.occupation.jockey"
  },
  {
    code: 252,
    name: "list.occupation.joiner"
  },
  {
    code: 253,
    name: "list.occupation.journalistcadet"
  },
  {
    code: 254,
    name: "list.occupation.journalist"
  },
  {
    code: 255,
    name: "list.occupation.judge"
  },
  {
    code: 256,
    name: "list.occupation.kitchenhand"
  },
  {
    code: 257,
    name: "list.occupation.labourergarden"
  },
  {
    code: 258,
    name: "list.occupation.labourerglassware"
  },
  {
    code: 259,
    name: "list.occupation.labourer"
  },
  {
    code: 260,
    name: "list.occupation.lagger"
  },
  {
    code: 261,
    name: "list.occupation.lampmanwoman"
  },
  {
    code: 262,
    name: "list.occupation.landscapegarden"
  },
  {
    code: 263,
    name: "list.occupation.landscapearchi"
  },
  {
    code: 264,
    name: "list.occupation.laundryworker"
  },
  {
    code: 265,
    name: "list.occupation.lawclerk"
  },
  {
    code: 266,
    name: "list.occupation.lawyer"
  },
  {
    code: 267,
    name: "list.occupation.layoutartist"
  },
  {
    code: 268,
    name: "list.occupation.leathergoodmaker"
  },
  {
    code: 269,
    name: "list.occupation.lecturer"
  },
  {
    code: 270,
    name: "list.occupation.libraryassistant"
  },
  {
    code: 271,
    name: "list.occupation.librarian"
  },
  {
    code: 272,
    name: "list.occupation.lifemechanic"
  },
  {
    code: 273,
    name: "list.occupation.liquidsfiller"
  },
  {
    code: 274,
    name: "list.occupation.loansanalyst"
  },
  {
    code: 275,
    name: "list.occupation.locksmith"
  },
  {
    code: 276,
    name: "list.occupation.loggingworker"
  },
  {
    code: 277,
    name: "list.occupation.luggageporter"
  },
  {
    code: 278,
    name: "list.occupation.machinemoulder"
  },
  {
    code: 279,
    name: "list.occupation.machinesetter"
  },
  {
    code: 280,
    name: "list.occupation.machinist"
  },
  {
    code: 281,
    name: "list.occupation.magistrate"
  },
  {
    code: 282,
    name: "list.occupation.manager"
  },
  {
    code: 283,
    name: "list.occupation.managerengineer"
  },
  {
    code: 284,
    name: "list.occupation.managerhairdresser"
  },
  {
    code: 285,
    name: "list.occupation.managerresearch"
  },
  {
    code: 286,
    name: "list.occupation.managersportscntr"
  },
  {
    code: 287,
    name: "list.occupation.managerstore"
  },
  {
    code: 288,
    name: "list.occupation.manufacturer"
  },
  {
    code: 289,
    name: "list.occupation.margarinemaker"
  },
  {
    code: 290,
    name: "list.occupation.marinesteward"
  },
  {
    code: 291,
    name: "list.occupation.masseurmasseuse"
  },
  {
    code: 292,
    name: "list.occupation.masterfisherman"
  },
  {
    code: 293,
    name: "list.occupation.mathematician"
  },
  {
    code: 294,
    name: "list.occupation.meatboner"
  },
  {
    code: 295,
    name: "list.occupation.meatpacker"
  },
  {
    code: 296,
    name: "list.occupation.meatworkslabour"
  },
  {
    code: 297,
    name: "list.occupation.mechanicelect"
  },
  {
    code: 298,
    name: "list.occupation.mechanicrefrig"
  },
  {
    code: 299,
    name: "list.occupation.medicaltechnician"
  },
  {
    code: 300,
    name: "list.occupation.messenger"
  },
  {
    code: 301,
    name: "list.occupation.metalcasting"
  },
  {
    code: 302,
    name: "list.occupation.metalfabricator"
  },
  {
    code: 303,
    name: "list.occupation.metallurgist"
  },
  {
    code: 304,
    name: "list.occupation.metalpolisher"
  },
  {
    code: 305,
    name: "list.occupation.metalspinner"
  },
  {
    code: 306,
    name: "list.occupation.meteorologist"
  },
  {
    code: 307,
    name: "list.occupation.meterreader"
  },
  {
    code: 308,
    name: "list.occupation.methodsanalyst"
  },
  {
    code: 309,
    name: "list.occupation.miller"
  },
  {
    code: 310,
    name: "list.occupation.millertrainee"
  },
  {
    code: 311,
    name: "list.occupation.miner"
  },
  {
    code: 312,
    name: "list.occupation.minister"
  },
  {
    code: 313,
    name: "list.occupation.modelmaker"
  },
  {
    code: 314,
    name: "list.occupation.motormechanic"
  },
  {
    code: 315,
    name: "list.occupation.moulder"
  },
  {
    code: 316,
    name: "list.occupation.musician"
  },
  {
    code: 317,
    name: "list.occupation.none"
  },
  {
    code: 318,
    name: "list.occupation.nursingdirector"
  },
  {
    code: 319,
    name: "list.occupation.nurseenrolled"
  },
  {
    code: 320,
    name: "list.occupation.nurseregistered"
  },
  {
    code: 321,
    name: "list.occupation.nursereghealth"
  },
  {
    code: 322,
    name: "list.occupation.nursemidwife"
  },
  {
    code: 323,
    name: "list.occupation.nursereggeneral"
  },
  {
    code: 324,
    name: "list.occupation.nursestudentenrol"
  },
  {
    code: 325,
    name: "list.occupation.nurseassistant"
  },
  {
    code: 326,
    name: "list.occupation.nurseryman"
  },
  {
    code: 327,
    name: "list.occupation.nursestudent"
  },
  {
    code: 328,
    name: "list.occupation.nursingsupervisor"
  },
  {
    code: 329,
    name: "list.occupation.nurseveterinary"
  },
  {
    code: 330,
    name: "list.occupation.obstetrician"
  },
  {
    code: 331,
    name: "list.occupation.occtherapist"
  },
  {
    code: 332,
    name: "list.occupation.operator"
  },
  {
    code: 333,
    name: "list.occupation.operatorbackhoe"
  },
  {
    code: 334,
    name: "list.occupation.operatorbulldozer"
  },
  {
    code: 335,
    name: "list.occupation.operatorchemical"
  },
  {
    code: 336,
    name: "list.occupation.operatorcomputer"
  },
  {
    code: 337,
    name: "list.occupation.operatorcrane"
  },
  {
    code: 338,
    name: "list.occupation.operatordataentry"
  },
  {
    code: 339,
    name: "list.occupation.operatordredge"
  },
  {
    code: 340,
    name: "list.occupation.operatorexcavator"
  },
  {
    code: 341,
    name: "list.occupation.operatorfarmmach"
  },
  {
    code: 342,
    name: "list.occupation.operatorgrader"
  },
  {
    code: 343,
    name: "list.occupation.operatorkiln"
  },
  {
    code: 344,
    name: "list.occupation.operatorlift"
  },
  {
    code: 345,
    name: "list.occupation.operatorloader"
  },
  {
    code: 346,
    name: "list.occupation.operatormachine"
  },
  {
    code: 347,
    name: "list.occupation.operatormetal"
  },
  {
    code: 348,
    name: "list.occupation.operatorplant"
  },
  {
    code: 349,
    name: "list.occupation.operatorpress"
  },
  {
    code: 350,
    name: "list.occupation.operatorroller"
  },
  {
    code: 351,
    name: "list.occupation.operatorsignal"
  },
  {
    code: 352,
    name: "list.occupation.operatorwire"
  },
  {
    code: 353,
    name: "list.occupation.ophthalmologist"
  },
  {
    code: 354,
    name: "list.occupation.opticalmechanic"
  },
  {
    code: 355,
    name: "list.occupation.orthoptist"
  },
  {
    code: 356,
    name: "list.occupation.osteopath"
  },
  {
    code: 357,
    name: "list.occupation.packer"
  },
  {
    code: 358,
    name: "list.occupation.paediatrician"
  },
  {
    code: 359,
    name: "list.occupation.painterart"
  },
  {
    code: 360,
    name: "list.occupation.painterdecorator"
  },
  {
    code: 361,
    name: "list.occupation.panelbeater"
  },
  {
    code: 362,
    name: "list.occupation.paperhanger"
  },
  {
    code: 363,
    name: "list.occupation.parkranger"
  },
  {
    code: 364,
    name: "list.occupation.parliamentarian"
  },
  {
    code: 365,
    name: "list.occupation.paroleofficer"
  },
  {
    code: 366,
    name: "list.occupation.pastrycook"
  },
  {
    code: 367,
    name: "list.occupation.pathologist"
  },
  {
    code: 368,
    name: "list.occupation.patternmaker"
  },
  {
    code: 369,
    name: "list.occupation.personnelmanager"
  },
  {
    code: 370,
    name: "list.occupation.personalofficer"
  },
  {
    code: 371,
    name: "list.occupation.pestweedcontroller"
  },
  {
    code: 372,
    name: "list.occupation.pharmacist"
  },
  {
    code: 373,
    name: "list.occupation.photographer"
  },
  {
    code: 374,
    name: "list.occupation.photoretoucher"
  },
  {
    code: 375,
    name: "list.occupation.physicist"
  },
  {
    code: 376,
    name: "list.occupation.physiologist"
  },
  {
    code: 377,
    name: "list.occupation.physiotherapist"
  },
  {
    code: 378,
    name: "list.occupation.pianotuner"
  },
  {
    code: 379,
    name: "list.occupation.pictureframer"
  },
  {
    code: 380,
    name: "list.occupation.pilot"
  },
  {
    code: 381,
    name: "list.occupation.plasterboardmaker"
  },
  {
    code: 382,
    name: "list.occupation.plasterer"
  },
  {
    code: 383,
    name: "list.occupation.plumber"
  },
  {
    code: 384,
    name: "list.occupation.podiatrist"
  },
  {
    code: 385,
    name: "list.occupation.policeofficer"
  },
  {
    code: 386,
    name: "list.occupation.policemanwoman"
  },
  {
    code: 387,
    name: "list.occupation.postmaster"
  },
  {
    code: 388,
    name: "list.occupation.potter"
  },
  {
    code: 389,
    name: "list.occupation.preciousmetalsmith"
  },
  {
    code: 390,
    name: "list.occupation.printer"
  },
  {
    code: 391,
    name: "list.occupation.prisonofficer"
  },
  {
    code: 392,
    name: "list.occupation.processworker"
  },
  {
    code: 393,
    name: "list.occupation.productionmanager"
  },
  {
    code: 394,
    name: "list.occupation.producer"
  },
  {
    code: 395,
    name: "list.occupation.producermedia"
  },
  {
    code: 396,
    name: "list.occupation.profficer"
  },
  {
    code: 397,
    name: "list.occupation.programmer"
  },
  {
    code: 398,
    name: "list.occupation.proofreader"
  },
  {
    code: 398.1,
    name: "list.occupation.propertyDeveloper"
  },
  {
    code: 399,
    name: "list.occupation.psychiatrist"
  },
  {
    code: 400,
    name: "list.occupation.psychologist"
  },
  {
    code: 401,
    name: "list.occupation.purchasingofficer"
  },
  {
    code: 402,
    name: "list.occupation.quantitysurveyor"
  },
  {
    code: 403,
    name: "list.occupation.racecourseofficer"
  },
  {
    code: 404,
    name: "list.occupation.radiographer"
  },
  {
    code: 405,
    name: "list.occupation.radiologist"
  },
  {
    code: 406,
    name: "list.occupation.railwaypetrolofficer"
  },
  {
    code: 407,
    name: "list.occupation.railwaysteward"
  },
  {
    code: 408,
    name: "list.occupation.railwayguard"
  },
  {
    code: 409,
    name: "list.occupation.realestateagent"
  },
  {
    code: 410,
    name: "list.occupation.receptionist"
  },
  {
    code: 411,
    name: "list.occupation.recreationofficer"
  },
  {
    code: 412,
    name: "list.occupation.referee"
  },
  {
    code: 413,
    name: "list.occupation.refugeworker"
  },
  {
    code: 414,
    name: "list.occupation.rentalsalesperson"
  },
  {
    code: 415,
    name: "list.occupation.reporter"
  },
  {
    code: 416,
    name: "list.occupation.resawyer"
  },
  {
    code: 417,
    name: "list.occupation.researcher"
  },
  {
    code: 418,
    name: "list.occupation.researchworkers"
  },
  {
    code: 419,
    name: "list.occupation.restauranteur"
  },
  {
    code: 420,
    name: "list.occupation.rigger"
  },
  {
    code: 421,
    name: "list.occupation.safetyofficer"
  },
  {
    code: 422,
    name: "list.occupation.sailmaker"
  },
  {
    code: 423,
    name: "list.occupation.salesassistant"
  },
  {
    code: 424,
    name: "list.occupation.salesdemonstration"
  },
  {
    code: 425,
    name: "list.occupation.salesmanager"
  },
  {
    code: 426,
    name: "list.occupation.salesperson"
  },
  {
    code: 427,
    name: "list.occupation.salesrepresentative"
  },
  {
    code: 428,
    name: "list.occupation.sawmaker"
  },
  {
    code: 429,
    name: "list.occupation.scaffolder"
  },
  {
    code: 430,
    name: "list.occupation.schoolprincipal"
  },
  {
    code: 431,
    name: "list.occupation.scientist"
  },
  {
    code: 432,
    name: "list.occupation.scrapmerchant"
  },
  {
    code: 433,
    name: "list.occupation.screenprinter"
  },
  {
    code: 434,
    name: "list.occupation.scripteditor"
  },
  {
    code: 435,
    name: "list.occupation.sculptor"
  },
  {
    code: 436,
    name: "list.occupation.seamanwoman"
  },
  {
    code: 437,
    name: "list.occupation.secretary"
  },
  {
    code: 438,
    name: "list.occupation.securitiesdealer"
  },
  {
    code: 439,
    name: "list.occupation.securityofficer"
  },
  {
    code: 440,
    name: "list.occupation.servicerelectappl"
  },
  {
    code: 441,
    name: "list.occupation.servicercomputer"
  },
  {
    code: 442,
    name: "list.occupation.serviceroffequip"
  },
  {
    code: 443,
    name: "list.occupation.servicerradiotv"
  },
  {
    code: 444,
    name: "list.occupation.servicervending"
  },
  {
    code: 445,
    name: "list.occupation.shearer"
  },
  {
    code: 446,
    name: "list.occupation.sheathingextruder"
  },
  {
    code: 447,
    name: "list.occupation.sheetmetalworker"
  },
  {
    code: 448,
    name: "list.occupation.shipscaptain"
  },
  {
    code: 449,
    name: "list.occupation.shipsofficer"
  },
  {
    code: 450,
    name: "list.occupation.shipwright"
  },
  {
    code: 451,
    name: "list.occupation.shoemaker"
  },
  {
    code: 452,
    name: "list.occupation.shoerepairer"
  },
  {
    code: 453,
    name: "list.occupation.shootertrapper"
  },
  {
    code: 454,
    name: "list.occupation.shopmanager"
  },
  {
    code: 455,
    name: "list.occupation.shotfirer"
  },
  {
    code: 456,
    name: "list.occupation.signwriter"
  },
  {
    code: 457,
    name: "list.occupation.singer"
  },
  {
    code: 458,
    name: "list.occupation.slaughterman"
  },
  {
    code: 459,
    name: "list.occupation.smallgoodsmaker"
  },
  {
    code: 460,
    name: "list.occupation.socialscientist"
  },
  {
    code: 461,
    name: "list.occupation.socialworker"
  },
  {
    code: 462,
    name: "list.occupation.solicitor"
  },
  {
    code: 463,
    name: "list.occupation.soundtechnician"
  },
  {
    code: 464,
    name: "list.occupation.specialist"
  },
  {
    code: 465,
    name: "list.occupation.speechpathologist"
  },
  {
    code: 466,
    name: "list.occupation.sportsperson"
  },
  {
    code: 467,
    name: "list.occupation.spraypainter"
  },
  {
    code: 468,
    name: "list.occupation.stagemanager"
  },
  {
    code: 469,
    name: "list.occupation.stationmaster"
  },
  {
    code: 470,
    name: "list.occupation.statistician"
  },
  {
    code: 471,
    name: "list.occupation.steelerector"
  },
  {
    code: 472,
    name: "list.occupation.steelfixer"
  },
  {
    code: 473,
    name: "list.occupation.steelworker"
  },
  {
    code: 474,
    name: "list.occupation.stenographer"
  },
  {
    code: 475,
    name: "list.occupation.stereotyper"
  },
  {
    code: 476,
    name: "list.occupation.stonemason"
  },
  {
    code: 477,
    name: "list.occupation.storemanwoman"
  },
  {
    code: 478,
    name: "list.occupation.streetvendor"
  },
  {
    code: 479,
    name: "list.occupation.student"
  },
  {
    code: 480,
    name: "list.occupation.supervisor"
  },
  {
    code: 481,
    name: "list.occupation.surgeon"
  },
  {
    code: 482,
    name: "list.occupation.surveyormarine"
  },
  {
    code: 483,
    name: "list.occupation.surveyor"
  },
  {
    code: 484,
    name: "list.occupation.surveyhand"
  },
  {
    code: 485,
    name: "list.occupation.systemsanalyst"
  },
  {
    code: 486,
    name: "list.occupation.tailor"
  },
  {
    code: 487,
    name: "list.occupation.teachersaide"
  },
  {
    code: 488,
    name: "list.occupation.teacherdance"
  },
  {
    code: 489,
    name: "list.occupation.teacherdrama"
  },
  {
    code: 490,
    name: "list.occupation.teacherart"
  },
  {
    code: 491,
    name: "list.occupation.teachermusic"
  },
  {
    code: 492,
    name: "list.occupation.teacherprimary"
  },
  {
    code: 493,
    name: "list.occupation.teacherpre"
  },
  {
    code: 494,
    name: "list.occupation.teachersecondary"
  },
  {
    code: 495,
    name: "list.occupation.teacherspecial"
  },
  {
    code: 496,
    name: "list.occupation.technicianmuseum"
  },
  {
    code: 497,
    name: "list.occupation.technician"
  },
  {
    code: 498,
    name: "list.occupation.technicianlibrary"
  },
  {
    code: 499,
    name: "list.occupation.telephonist"
  },
  {
    code: 500,
    name: "list.occupation.teller"
  },
  {
    code: 501,
    name: "list.occupation.tester"
  },
  {
    code: 502,
    name: "list.occupation.textilemechanic"
  },
  {
    code: 503,
    name: "list.occupation.tickettaker"
  },
  {
    code: 504,
    name: "list.occupation.tilerroof"
  },
  {
    code: 505,
    name: "list.occupation.tilerwallfloor"
  },
  {
    code: 506,
    name: "list.occupation.timbercutter"
  },
  {
    code: 507,
    name: "list.occupation.toolmaker"
  },
  {
    code: 508,
    name: "list.occupation.touristguide"
  },
  {
    code: 509,
    name: "list.occupation.touristofficer"
  },
  {
    code: 510,
    name: "list.occupation.touristdirector"
  },
  {
    code: 511,
    name: "list.occupation.tradesperson"
  },
  {
    code: 512,
    name: "list.occupation.trainingofficer"
  },
  {
    code: 513,
    name: "list.occupation.translator"
  },
  {
    code: 514,
    name: "list.occupation.travelagent"
  },
  {
    code: 515,
    name: "list.occupation.treefaller"
  },
  {
    code: 516,
    name: "list.occupation.treesurgeon"
  },
  {
    code: 517,
    name: "list.occupation.trenchdigger"
  },
  {
    code: 518,
    name: "list.occupation.tribunalmember"
  },
  {
    code: 519,
    name: "list.occupation.turnerporcelain"
  },
  {
    code: 520,
    name: "list.occupation.tutor"
  },
  {
    code: 521,
    name: "list.occupation.typist"
  },
  {
    code: 522,
    name: "list.occupation.upholsterer"
  },
  {
    code: 523,
    name: "list.occupation.urbanplanner"
  },
  {
    code: 524,
    name: "list.occupation.urologist"
  },
  {
    code: 525,
    name: "list.occupation.usher"
  },
  {
    code: 526,
    name: "list.occupation.valuer"
  },
  {
    code: 527,
    name: "list.occupation.varietyartist"
  },
  {
    code: 528,
    name: "list.occupation.vehiclebodymaker"
  },
  {
    code: 529,
    name: "list.occupation.vehiclebodymaker"
  },
  {
    code: 530,
    name: "list.occupation.vehiclepainter"
  },
  {
    code: 531,
    name: "list.occupation.vehicletrimmer"
  },
  {
    code: 532,
    name: "list.occupation.veterinarian"
  },
  {
    code: 533,
    name: "list.occupation.waiterwaitress"
  },
  {
    code: 534,
    name: "list.occupation.wardassistant"
  },
  {
    code: 535,
    name: "list.occupation.weaver"
  },
  {
    code: 536,
    name: "list.occupation.weigher"
  },
  {
    code: 537,
    name: "list.occupation.welfareofficer"
  },
  {
    code: 538,
    name: "list.occupation.wholesaler"
  },
  {
    code: 539,
    name: "list.occupation.windowdresser"
  },
  {
    code: 540,
    name: "list.occupation.windowcleaner"
  },
  {
    code: 541,
    name: "list.occupation.woodcarver"
  },
  {
    code: 542,
    name: "list.occupation.woodmachinist"
  },
  {
    code: 543,
    name: "list.occupation.woodturner"
  },
  {
    code: 544,
    name: "list.occupation.worker"
  },
  {
    code: 545,
    name: "list.occupation.youthworker"
  },
  {
    code: 546,
    name: "list.occupation.zoologist"
  }
]

export const IndustrySector = [
  {
    name: "Industry Sector 1",
    code: "1"
  },
  {
    name: "Industry Sector 2",
    code: "2"
  },
  {
    name: "Industry Sector 3",
    code: "3"
  }
]

export const Industry = [
  {
    code: 1,
    name: "list.industry.accountingBanking"
  },
  {
    code: 2,
    name: "list.industry.aerospacedefence"
  },
  {
    code: 3,
    name: "list.industry.airfreightlogistics"
  },
  {
    code: 4,
    name: "list.industry.airlines"
  },
  {
    code: 5,
    name: "list.industry.autocomponents"
  },
  {
    code: 6,
    name: "list.industry.automobiles"
  },
  {
    code: 7.1,
    name: "list.industry.banksRetail"
  },
  {
    code: 7.2,
    name: "list.industry.banksCommercial"
  },
  {
    code: 7.3,
    name: "list.industry.banksPrivateBanking"
  },
  {
    code: 7.4,
    name: "list.industry.banksWealthManagement"
  },

  {
    code: 7.5,
    name: "list.industry.beverages"
  },
  {
    code: 8,
    name: "list.industry.biotechnology"
  },
  {
    code: 9,
    name: "list.industry.buildingproducts"
  },
  {
    code: 10,
    name: "list.industry.capitalmarkets"
  },
  {
    code: 11,
    name: "list.industry.chemicals"
  },
  // {
  //     code: 12,
  //     name: "list.industry.commercialbanks"
  // },
  {
    code: 13,
    name: "list.industry.commercialservicessupplies"
  },
  {
    code: 14,
    name: "list.industry.communicationsequipment"
  },
  {
    code: 15,
    name: "list.industry.computersperipherals"
  },
  {
    code: 16,
    name: "list.industry.constructionengineering"
  },
  {
    code: 17,
    name: "list.industry.constructionmaterials"
  },
  {
    code: 18,
    name: "list.industry.consumerfinance"
  },
  {
    code: 19,
    name: "list.industry.containerspackaging"
  },
  {
    code: 20,
    name: "list.industry.distributors"
  },
  {
    code: 21,
    name: "list.industry.diversifiedconsumerservices"
  },

  {
    code: 23,
    name: "list.industry.diversifiedtelecommunicationservices"
  },
  {
    code: 24,
    name: "list.industry.electricutilities"
  },
  {
    code: 25,
    name: "list.industry.electricalequipment"
  },
  {
    code: 26,
    name: "list.industry.electronicequipmentinstruments"
  },
  {
    code: 27,
    name: "list.industry.energyequipmentservices"
  },
  {
    code: 28,
    name: "list.industry.financeIndustry"
  },
  {
    code: 22,
    name: "list.industry.diversifiedfinancialservices"
  },
  {
    code: 29,
    name: "list.industry.foodstaplesretailing"
  },
  {
    code: 30,
    name: "list.industry.healthcareequipmentsupplies"
  },
  {
    code: 31,
    name: "list.industry.healthcareprovidersservices"
  },
  {
    code: 32,
    name: "list.industry.healthcaretechnology"
  },
  {
    code: 33,
    name: "list.industry.hotelsrestaurantsleisure"
  },
  {
    code: 34,
    name: "list.industry.householddurables"
  },
  {
    code: 35,
    name: "list.industry.householdproducts"
  },
  {
    code: 36,
    name: "list.industry.independentpowerproducersenergytraders"
  },
  {
    code: 37,
    name: "list.industry.industrialconglomerates"
  },
  {
    code: 38,
    name: "list.industry.insurance"
  },
  {
    code: 39,
    name: "list.industry.internetcatalogueretail"
  },
  {
    code: 40,
    name: "list.industry.internetsoftwareservices"
  },
  {
    code: 41,
    name: "list.industry.itservices"
  },
  {
    code: 42,
    name: "list.industry.leisureequipmentproducts"
  },
  {
    code: 43,
    name: "list.industry.lifesciencestoolsservices"
  },
  {
    code: 44,
    name: "list.industry.machinery"
  },
  {
    code: 45,
    name: "list.industry.marine"
  },
  {
    code: 46,
    name: "list.industry.media"
  },
  {
    code: 47,
    name: "list.industry.metalsmining"
  },
  {
    code: 48,
    name: "list.industry.multiutilities"
  },
  {
    code: 49,
    name: "list.industry.multilineretail"
  },
  {
    code: 50,
    name: "list.industry.officeelectronics"
  },
  {
    code: 51,
    name: "list.industry.oilgasconsumablesfuels"
  },
  {
    code: 52,
    name: "list.industry.paperforestproducts"
  },
  {
    code: 53,
    name: "list.industry.personalproducts"
  },
  {
    code: 54,
    name: "list.industry.pharmaceuticals"
  },
  {
    code: 55,
    name: "list.industry.realestateinvestmenttrustsreits"
  },
  {
    code: 56,
    name: "list.industry.realestatemanagementdevelopment"
  },
  {
    code: 57,
    name: "list.industry.roadrail"
  },
  {
    code: 58,
    name: "list.industry.semiconductorssemiconductorequipment"
  },
  {
    code: 59,
    name: "list.industry.software"
  },
  {
    code: 60,
    name: "list.industry.specialtyretail"
  },
  {
    code: 61,
    name: "list.industry.textilesapparelluxurygoods"
  },
  {
    code: 62,
    name: "list.industry.thriftsmortgagefinance"
  },
  {
    code: 63,
    name: "list.industry.tobacco"
  },
  {
    code: 64,
    name: "list.industry.tradingcompaniesdistributors"
  },
  {
    code: 65,
    name: "list.industry.transportationinfrastructure"
  },
  {
    code: 66,
    name: "list.industry.waterutilities"
  },
  {
    code: 67,
    name: "list.industry.wirelesstelecommunicationservices"
  }
]

export const TechnicalSubject = [
  {
    name: "Technical - Bugs",
    value: "TECHNICAL_ISSUE_BUG"
  },
  {
    name: "Technical - Data Related",
    value: "TECHNICAL_ISSUE_DATA_RELATED"
  },
  {
    name: "Technical - How To",
    value: "TECHNICAL_ISSUE_HOW_TO"
  },
  {
    name: "Technical - Network Problem",
    value: "TECHNICAL_ISSUE_NETWORK_PROBLEM"
  },
  {
    name: "Technical - Performance",
    value: "TECHNICAL_ISSUE_PERFORMANCE"
  },
  {
    name: "Technical - Usability",
    value: "TECHNICAL_ISSUE_USABILITY"
  },
  {
    name: "Technical - Others",
    value: "TECHNICAL_ISSUE_OTHER"
  },
  {
    name: "I Like Something",
    value: "LIKE_SOMETHING"
  },
  {
    name: "I Dislike Something",
    value: "DISLIKE_SOMETHING"
  },
  {
    name: "Suggestion",
    value: "SUGGESTION"
  },
  {
    name: "Others",
    value: "OTHER"
  }
]
export const MeetingType = [
  { name: "General Notes", value: "GENERAL_NOTE" },
  { name: "Meeting Notes", value: "MEETING_NOTE" },
  { name: "To do", value: "TO_DO" }
]
export const LanguageList = [
  {
    name: "English",
    value: "en-US"
  },
  {
    name: "中文 (繁體)",
    value: "zh-Hant"
  },
  {
    name: "中文 (简体)",
    value: "zh-Hans"
  },
  {
    name: "Bahasa Malayu",
    value: "my-MY"
  },
  {
    name: "Vietnamese",
    value: "vn-VN"
  }
]
export const meetingTypeList = [
  {
    name: ""
  }
]

export const CPFAccount = [
  {
    name: "OA",
    value: "OA"
  },
  {
    name: "MA",
    value: "MA"
  },
  {
    name: "SA",
    value: "SA"
  }
]
