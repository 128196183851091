import React, { useState, useEffect } from "react"
import { Form, FormSpy, Field } from "react-final-form"
import intl from "react-intl-universal"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Alert from "react-s-alert"
import { ConfirmationDialog } from "../../components"
import { CountryList } from "../../constants/Lists"
import { translateListLabel } from "../../helpers/list-translation"
import {
  renderDateFieldCustom,
  renderSelectFieldCustom,
  renderTextBoxCustom
} from "../../helpers/FormRender"
import { entityActions } from "../../actions/EntityActions"
import { modalWindowActions } from "../../actions/ModalWindowActions"
import { maxPhotoSize } from "../../helpers"
import { image, composeValidators, required } from "../../helpers/Validator"
import { fileFieldRenderBootstrapWithoutLabel } from "../../helpers/FormRenderBootstrap"
import { createUrlFromPath } from "../../helpers/ImageHelper"
import UserApi from "../../api/UserApi"
import { EntityApi } from "../../api"
import FinancialRegulatorApi from "../../api/FinancialRegulatorApi"
import { userActions } from "../../actions/UserActions"
import { reloadWindow } from "../../helpers/SessionHelper"
import RenderSimpleFieldsBootrap, {
  FieldType
} from "../../components/Form/RenderSimpleFieldsBootstrap"

import { Tab, Tabs, TabList, TabPanel } from "react-tabs"

function FormContainer({
  model,
  readOnlyAccess,
  handleSave,
  entityType,
  preview,
  uploaded
}) {
  /* internalIdentifier */
  const operationInformationFields = [
    {
      name: "entityName",
      label: `${intl.get(`entity.field.${entityType}`)} ${intl.get(
        "field.name"
      )}`,
      type: FieldType.TEXT,
      required: true
    },
    {
      name: "businessTitle",
      label: `${intl.get(`entity.field.${entityType}`)} ${intl.get(
        "entity.field.businessTitle"
      )}`,
      type: FieldType.TEXT
    },
    {
      name: "entityUniqueId",
      label: `${intl.get(`entity.field.${entityType}`)} ${intl.get(
        "entity.field.internalIdentifier"
      )}`,
      type: FieldType.TEXT
    },

    {
      name: "SupervisorDirectReport",
      label: `${intl.get(`entity.field.${entityType}`)} ${intl.get(
        "entity.field.SupervisorDirectReport"
      )}`,
      type: FieldType.TEXT
    },
    {
      name: "managerInCharge",
      label: intl.get("field.managerInCharge"),
      type: FieldType.TEXT
    },
    {
      name: "country",
      label: intl.get("field.countryBased"),
      type: FieldType.SELECTION,
      selectionData: translateListLabel(CountryList),
      valueProperty: "code",
      labelProperty: "name",
      required: true
    },
    {
      name: "companyRegistrationNumber",
      label: intl.get("field.companyRegistrationNo"),
      type: FieldType.TEXT
    }
  ]

  const globalAccess = [
    {
      name: "entityDetails.systemUserReferenceId",
      label: "System User Reference ID",
      type: FieldType.TEXT
    },
    {
      name: "grantKoizaiGlobal",
      label: "Grant Koizai Access",
      type: FieldType.CHECKBOX
    }
  ]

  const contactFields = [
    {
      name: "officeNo",
      label: intl.get("field.officeNo"),
      type: FieldType.TEXT
    },
    {
      name: "officeNo",
      label: intl.get("field.officeNo"),
      type: FieldType.TEXT
    },
    {
      name: "faxNo",
      label: intl.get("field.faxNo"),
      type: FieldType.PHONE_NUMBER_V2
    },
    {
      name: "email",
      label: intl.get("field.emailAddress"),
      type: FieldType.EMAIL
    },
    {
      name: "website",
      label: intl.get("field.website"),
      type: FieldType.TEXT
    }
  ]
  const addressField = [
    {
      name: "addressLine1",
      label: intl.get("field.address1"),
      type: FieldType.TEXT
    },
    {
      name: "addressLine2",
      label: intl.get("field.address2"),
      type: FieldType.TEXT
    },
    {
      name: "addressLine3",
      label: intl.get("field.address3"),
      type: FieldType.TEXT
    },
    {
      name: "suburb",
      label: intl.get("field.suburb"),
      type: FieldType.TEXT
    },
    {
      name: "state",
      label: intl.get("field.state"),
      type: FieldType.TEXT
    },
    {
      name: "postalCode",
      label: intl.get("field.postalCode"),
      type: FieldType.TEXT
    },
    {
      name: "country",
      label: intl.get("field.country"),
      type: FieldType.SELECTION,
      selectionData: translateListLabel(CountryList),
      valueProperty: "code",
      labelProperty: "name"
    }
  ]

  return (
    <Form
      onSubmit={handleSave}
      initialValues={model}
      mutators={{
        addregulatoryLicence: (args, state, utils) => {
          const { entityDetails: { regulatoryLicences = [] } = {} } =
            state.formState.values

          utils.changeValue(state, "entityDetails.regulatoryLicences", () => [
            ...regulatoryLicences,
            {}
          ])
        },
        removeregulatoryLicence: (args, state, utils) => {
          const [removedIndex] = args
          const { entityDetails: { regulatoryLicences = [] } = {} } =
            state.formState.values

          utils.changeValue(state, "entityDetails.regulatoryLicences", () =>
            regulatoryLicences.filter((item, index) => removedIndex !== index)
          )
        }
      }}
      render={({ handleSubmit, reset, form: { mutators } }) => (
        <form
          id="bondForm"
          onSubmit={(event) => {
            handleSubmit(event).then(() => {
              reset()
            })
          }}
        >
          <div className="portal-form-popup">
            <div className="portal-form-half ">
              <div className="subtitle2b">
                {intl.get("entity.field.businessInformation")}
              </div>
              <RenderSimpleFieldsBootrap fields={operationInformationFields} />
              <RegulatoryLicence mutators={mutators} />

              <div className="koizaiSystem-row">
                <div className="subtitle2b">
                  {intl.get("entity.field.koizaiSystem")}
                </div>
              </div>

              <RenderSimpleFieldsBootrap fields={globalAccess} />

              <div className="row mt-1 photo-row">
                <div className="col">
                  <label htmlFor="photoFile">
                    {intl.get("userProfile.photo")}
                  </label>
                </div>
                <div className="col">
                  <div className="row-holder ">
                    <Field
                      name="photoFile"
                      inputOnChange={uploaded}
                      component={fileFieldRenderBootstrapWithoutLabel}
                      validate={composeValidators(maxPhotoSize, image)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col" />
                <div className="col d-flex mt-2">
                  <img alt="preview" className="photo-preview" src={preview} />
                </div>
              </div>
            </div>
            <div className="portal-form-half ">
              <div className="subtitle2b">{intl.get("entity.contact")}</div>

              {/* 20210609 tabs */}
              <Tabs>
                <TabList>
                  <Tab>{intl.get("tabs.businessDetails")}</Tab>
                  <Tab>{intl.get("tabs.personalDetails")}</Tab>
                </TabList>

                <TabPanel>
                  <div
                    id="scroll-section"
                    className="portal-form portal-form-businessDetails"
                  >
                    <RenderSimpleFieldsBootrap fields={contactFields} />
                  </div>
                </TabPanel>

                <TabPanel>
                  <div
                    id="scroll-section"
                    className="portal-form portal-form-personalDetails"
                  >
                    <RenderSimpleFieldsBootrap fields={contactFields} />
                  </div>
                </TabPanel>
              </Tabs>

              <div className="address-row">
                <div className="subtitle2b"> {intl.get("field.address")}</div>
              </div>

              <RenderSimpleFieldsBootrap fields={addressField} />
            </div>
          </div>
          <div className="portal-btn-popup">
            {!readOnlyAccess && (
              <React.Fragment>
                <div className="text-right">
                  <input
                    className="portal-btn portal-btn-submit portal-btn-submit-mr-10"
                    value={intl.get("button.save")}
                    type="submit"
                  />
                </div>
              </React.Fragment>
            )}
          </div>
        </form>
      )}
    />
  )
}

function RegulatoryLicence({ mutators }) {
  const { addregulatoryLicence, removeregulatoryLicence } = mutators
  const [financialRegulators, setFinancialRegulators] = useState("")
  const [financialRegulatorsList, setFinancialRegulatorsList] = useState([])

  let financialRegulators_list = []

  function refreshList() {
    EntityApi.getFinancialInstitutions().then(({ data }) => {
      setFinancialRegulators(data.list)
    })
  }

  useEffect(() => {
    refreshList()
  }, [])

  useEffect(() => {
    if (financialRegulators && financialRegulators.length > 0) {
      financialRegulators.map((key, index) =>
        financialRegulators_list.push({
          index: index,
          name: key.name,
          value: key.id,
          data: key
        })
      )
    }
    setFinancialRegulatorsList(financialRegulators_list)
  }, [financialRegulators])

  return (
    <React.Fragment>
      <div className="portal-form-row">
        <label className="d-flex align-items-center">
          {intl.get("field.regulatoryLicence")}{" "}
          <span
            style={{ marginLeft: "18px" }}
            onClick={() =>
              addregulatoryLicence(
                "entityDetails.regulatoryLicences",
                undefined
              )
            }
            className="ml-1 btn btn-plus"
          />
        </label>
      </div>
      <FormSpy subscription={{ values: true }}>
        {({ values }) => {
          const { entityDetails: { regulatoryLicences = [] } = {} } = values

          return (
            <React.Fragment>
              {regulatoryLicences && regulatoryLicences.length > 0 && (
                <div className="row">
                  <div className="col">
                    <label />
                  </div>
                  <div className="col">
                    <div className="row-holder">
                      <div className="half-container">
                        <b>{intl.get("button.licenceName")}</b>
                      </div>
                      <div className="half-container">
                        <b>{intl.get("button.licenceNumber")}</b>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {regulatoryLicences &&
                regulatoryLicences.length > 0 &&
                regulatoryLicences.map((licence, index) => (
                  <div className="row" key={index}>
                    {/* <div className="col">
                                            <label />
                                        </div> */}
                    <div className="col">
                      <div className="row-holder">
                        <div className="_90p-width">
                          {/* label */}
                          <div
                            // className={`${
                            //   index == 0 ? "" : "hide-half-container"
                            // }`}
                            className="row"
                          >
                            <div className="col-md-4 regulatorylicense-label">
                              Regulator
                            </div>
                            <div className="col-md-4 regulatorylicense-label">
                              License Number
                            </div>
                            <div className="col-md-4 regulatorylicense-label">
                              Expiry Date
                            </div>
                          </div>
                          <div className="row" style={{ width: "100%" }}>
                            <div className="col-md-4">
                              <Field
                                name={`regulatoryLicences[${index}].regulatoryLicenceId`}
                                component={renderSelectFieldCustom}
                                valueProperty="value"
                                labelProperty="name"
                                data={financialRegulatorsList}
                                isForGrid
                              />
                            </div>
                            <div className="col-md-4">
                              <Field
                                name={`regulatoryLicences[${index}].number`}
                                component={renderTextBoxCustom}
                                isForGrid
                              />
                            </div>
                            <div className="col-md-4">
                              <Field
                                name={`regulatoryLicences[${index}].expiryDate`}
                                component={renderDateFieldCustom}
                                isForGrid
                                required={true}
                                validate={required}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="_10p-width">
                          <span
                            onClick={() => removeregulatoryLicence(index)}
                            className="btn-remove"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </React.Fragment>
          )
        }}
      </FormSpy>
    </React.Fragment>
  )
}
function EntityProfile(props) {
  const { userId, type } = props
  const [preview, setPreview] = useState("")

  const getBase64 = (file, cb) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(reader.result)
    }
    reader.onerror = function (error) {
      console.log("Error: ", error)
    }
  }
  const uploaded = (event) => {
    getBase64(event.target.files[0], (result) => {
      setPreview(result)
    })
  }
  const [model, setModel] = useState({})
  const [confirmationDialog, setConfirmationDialog] = useState({
    show: false,
    message: ""
  })

  useEffect(() => {
    UserApi.getEntiityProfile(userId, type).then((response) => {
      setModel(response.data)
      setPreview(
        `${createUrlFromPath(response.data.photoUrl)}?v=${Math.random()}`
      )
    })
  }, [userId])

  const handleConfirmEntity = () => {
    if (confirmationDialog.data) {
      confirmationDialog.data.append("confirmDisconnectEntity", true)
      UserApi.editProfile(userId, confirmationDialog.data).then((response2) => {
        Alert.success("user profile successfully updated")
        // props.currentUser();
        // window.location.reload();
      })
    }
  }

  const handleSave = (values) => {
    const formData = new FormData()
    formData.append("addressLine1", values.addressLine1 || "")
    formData.append("addressLine2", values.addressLine2 || "")
    formData.append("addressLine3", values.addressLine3 || "")
    formData.append(
      "companyRegistrationNumber",
      values.companyRegistrationNumber || ""
    )
    formData.append("managerInCharge", values.managerInCharge || "")
    formData.append("country", values.country || "")
    formData.append("email", values.email || "")
    formData.append("entityId", values.entityId || "")
    formData.append("entityName", values.entityName || "")
    formData.append("entityUniqueId", values.entityUniqueId || "")
    formData.append("faxNo", values.faxNo || "")
    formData.append("officeNo", values.officeNo || "")
    formData.append("photoUrl", values.photoUrl || "")
    formData.append("state", values.state || "")
    formData.append("postalCode", values.postalCode || "")
    formData.append("suburb", values.suburb || "")
    formData.append("suburb", values.suburb || "")
    formData.append("entityType", type)
    formData.append("grantKoizaiGlobal", values.grantKoizaiGlobal || false)

    if (values.photoFile) {
      formData.append("photoFile", values.photoFile)
    }
    if (Array.isArray(values.regulatoryLicences)) {
      values.regulatoryLicences.forEach((a, i) => {
        formData.append(`regulatoryLicences[${i}].name`, a.name || "")
        formData.append(`regulatoryLicences[${i}].number`, a.number || "")
      })
    }
    return UserApi.editEntiityProfile(formData).then(
      (response) => {
        if (response.data.errorCode === "confirm_disconnect_entity") {
          setConfirmationDialog({
            show: true,
            message: response.data.errorMessage,
            data: formData
          })
        } else {
          let loginUserEntity = localStorage.getItem("loginUserEntity")
            ? JSON.parse(localStorage.getItem("loginUserEntity"))
            : undefined
          if (loginUserEntity && response.data) {
            if (response.data.country) {
              loginUserEntity.country = response.data.country
            }

            if (response.data.photo) {
              loginUserEntity.photo = {
                id: response.data.photo.id,
                url: response.data.photo.url,
                title: response.data.photo.title,
                type: response.data.photo.type
              }
            }

            localStorage.setItem(
              "loginUserEntity",
              JSON.stringify(loginUserEntity)
            )
          }

          if (response.data.selectedUserRole) {
            let userRole = response.data.selectedUserRole
            localStorage.setItem("activeUserRole", JSON.stringify(userRole))
          }

          Alert.success("Successfully update the profile.")
          reloadWindow("EDIT_PROFILE")
        }

        return Promise.resolve()
      },
      () => Promise.reject()
    )
  }
  const { readOnlyAccess, entityType } = props
  return (
    <>
      <FormContainer
        {...{
          model,
          readOnlyAccess,
          handleSave,
          entityType,
          preview,
          uploaded
        }}
      />
      <ConfirmationDialog
        isOpen={confirmationDialog.show}
        message={confirmationDialog.message}
        onClose={() =>
          setConfirmationDialog({
            message: "",
            show: false
          })
        }
        onYes={handleConfirmEntity}
      />
    </>
  )
}

function mapStateToProps(state) {
  return {
    entityType: state.modalWindow.entityType,
    activeAccessLevel: state.appSettings
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, entityActions, modalWindowActions, userActions),
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(EntityProfile)
