/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Description : Illiquid Assets base page (modal)
*/

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import intl from "react-intl-universal";
import {
    netWorthActions,
    netWorthFormActions,
    modalWindowActions
} from "../../actions";
import NetWorthListingGroup, {
    NetWorthListingGroupPropsComposer
} from "./NetWorthListingGroup";
import { NetWorthPath } from "./NetWorthForm";
import { modalWindow } from "../../constants/ModalWindowLists";
import BusinessOwnership from "./BusinessOwnership";
import InvestmentProperty from "./InvestmentProperty";
import CollectibleAntiqueArts from "./CollectibleAntiqueArts";
import ILASInvestmentModal from "./ILASInvestment/ILASInvestmentModal";

class IlilliquidAssets extends NetWorthListingGroup {
    constructor(props) {
        const { getIlliquidAssetsListing } = props;
        const listingDefinition = [
            {
                title: intl.get("netWorth.investmentProperty"),
                path: NetWorthPath.investmentProperties,
                modalName: modalWindow.INVESTMENT_PROPERTY_MODAL
            },
            {
                title: intl.get("netWorth.businessOwnership"),
                path: NetWorthPath.businessOwnerships,
                modalName: modalWindow.BUSINESS_OWNERSHIP_MODAL
            },
            {
                title: intl.get("netWorth.collectibles"),
                path: NetWorthPath.collectibles,
                modalName: modalWindow.COLLECTIBLE_ANTIQUES_MODAL
            },
            {
                title: intl.get("netWorth.ilasInvestment"),
                path: NetWorthPath.ilasInvestment,
                modalName: modalWindow.ILAS_INVESTMENT
            }
        ];
        super(
            props,
            "illiquidAssets",
            getIlliquidAssetsListing,
            listingDefinition
        );
    }

    render() {
        const { optionsData, currency } = this.props;
        const { contentWrapper } = this;

        return contentWrapper(
            <React.Fragment>
                <InvestmentProperty
                    optionsData={optionsData}
                    currency={currency}
                />
                <BusinessOwnership
                    optionsData={optionsData}
                    currency={currency}
                />
                <CollectibleAntiqueArts
                    optionsData={optionsData}
                    currency={currency}
                />
                <ILASInvestmentModal
                    isJoint={this.props.isJoint}
                    optionsData={optionsData}
                    currency={currency}
                />
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { illiquidAssetsListing } = state.netWorth;
    return NetWorthListingGroupPropsComposer(state, illiquidAssetsListing);
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        Object.assign(
            {},
            netWorthActions,
            modalWindowActions,
            netWorthFormActions
        ),
        dispatch
    );
}

IlilliquidAssets = connect(
    mapStateToProps,
    mapDispatchToProps
)(IlilliquidAssets);

export default IlilliquidAssets;
