import React, { useContext } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';
import intl from 'react-intl-universal';
import { AppContext } from '../../../../context/AppContext';
import CashFlowHeaderListing from '../../CashFlowHeaderListing';
import CashFlowListingGroup from '../../CashFlowListingGroup';
import api from '../../../../lib/api';
import CashAccountForm from '../../../../forms/CashAccountForm';
import { useDispatch } from 'react-redux';
import { netWorthFormActions } from '../../../../actions';

const CashAccount = ({
    varPrefix,
    clientTotalValue,
    partnerTotalValue,
    jointTotalValue,
    optionsData,
    readOnlyAccessComponent,
    clientInformation,
    currency,
    onExternalUpdated,
    isJointHide,
    values,
}) => {
    const {
        modal: { setModal, clearModal },
    } = useContext(AppContext);

    const handleSave = newValues => {
        // save the model
        api.put(newValues.url, newValues).then(response => {
            onExternalUpdated(true);
        });

        clearModal();
    };
  const dispatch = useDispatch()


    return (
        <div className="portal-part">
            <Accordion accordion={false}>
                <AccordionItem>
                    <AccordionItemTitle>
                        <CashFlowHeaderListing
                            title={intl.get(
                                'cashflowlisting.personalIncome.cashAccount'
                            )}
                            isAccordion
                            clientTotalValue={clientTotalValue}
                            partnerTotalValue={partnerTotalValue}
                            jointTotalValue={jointTotalValue}
                            currency={currency}
                            isJointHide={isJointHide}
                        />
                    </AccordionItemTitle>
                    <AccordionItemBody>
                        <CashFlowListingGroup
                            varPrefix={`${varPrefix}`}
                            dataArray={values.detailResult}
                            isRoundColorRed
                            isExternalModal
                            isJointHide={isJointHide}
                            onEditClick={model =>{
                                dispatch(
                                  netWorthFormActions.setNewForm(
                                    false,
                                    model.classification
                                  )
                                )
                                setModal({
                                    title: intl.get(
                                        'cashflowlisting.personalIncome.cashAccount'
                                    ),
                                    formType: 'cashAccount',
                                    content: (
                                        <CashAccountForm
                                            handleSave={handleSave}
                                            model={model}
                                            optionsData={optionsData}
                                            clientInformation={
                                                clientInformation
                                            }
                                            readOnlyAccess={readOnlyAccessComponent()}
                                        />
                                    ),
                                })}
                            }
                        />
                    </AccordionItemBody>
                </AccordionItem>
            </Accordion>
        </div>
    );
};

export default CashAccount;
