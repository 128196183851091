import React, { useState, useEffect } from "react"
import { Form, FormSpy, Field } from "react-final-form"
import intl from "react-intl-universal"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Alert from "react-s-alert"
import { ConfirmationDialog } from "../../components"
import { CountryList } from "../../constants/Lists"
import { translateListLabel } from "../../helpers/list-translation"
import RenderSimpleFieldsBootrap, {
  FieldType
} from "../../components/Form/RenderSimpleFieldsBootstrap"
import {
  renderTextBoxCustom,
  renderSelectFieldCustom,
  renderDateFieldCustom
} from "../../helpers/FormRender"
import { entityActions } from "../../actions/EntityActions"
import { modalWindowActions } from "../../actions/ModalWindowActions"
import { maxPhotoSize } from "../../helpers"
import { image, composeValidators, required } from "../../helpers/Validator"
import { fileFieldRenderBootstrapWithoutLabel } from "../../helpers/FormRenderBootstrap"
import { createUrlFromPath } from "../../helpers/ImageHelper"
import UserApi from "../../api/UserApi"

import { EntityApi } from "../../api"
import { userActions } from "../../actions/UserActions"
import { logout } from "../../helpers/SessionHelper"

import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import Api from "../../lib/api"

function FormContainer({
  model,
  readOnlyAccess,
  handleSave,
  entityType,
  preview,
  preview1,
  uploaded,
  uploaded1
}) {
  /* internalIdentifier */
  const operationInformationFields = [
    {
      name: "entityName",
      label: `${intl.get(`entity.field.${entityType}`)} ${intl.get(
        "field.name"
      )}`,
      type: FieldType.TEXT,
      required: true
    },

    {
      name: "entityUniqueId",
      label: `${intl.get(`entity.field.${entityType}`)} ${intl.get(
        "entity.field.internalIdentifier"
      )}`,
      type: FieldType.TEXT
    },

    {
      name: "country",
      label: intl.get("field.country"),
      type: FieldType.SELECTION,
      selectionData: translateListLabel(CountryList),
      valueProperty: "code",
      labelProperty: "name",
      required: true
    },
    {
      name: "companyRegistrationNumber",
      label: intl.get("field.companyRegistrationNo"),
      type: FieldType.TEXT
    }
  ]
  const webAccessFields = [
    {
      name: "userEmailAddress",
      label: intl.get("field.userEmailAddress"),
      type: FieldType.EMAIL,
      required: true,
      disabled: true
    },
    {
      name: "username",
      label: intl.get("field.username"),
      type: FieldType.TEXT
    },
    {
      name: "userTypeName",
      label: intl.get("field.userType"),
      type: FieldType.TEXT,
      disabled: true
    },
    {
      name: "packageTypeName",
      label: intl.get("field.packageType"),
      type: FieldType.TEXT,
      disabled: true
    },
    {
      name: "userDiskUsagesInMB",
      label: "User's Disk Usages",
      type: FieldType.NUMBER,
      suffix: "MB",
      readOnly: true
    },
    {
      name: "entityDiskUsagesInMB",
      label: "Entity's Disk Usages",
      type: FieldType.NUMBER,
      suffix: "MB",
      readOnly: true
    }
  ]

  const globalAccess = [
    {
      name: "grantKoizaiGlobal",
      label: "Grant Koizai Access",
      type: FieldType.CHECKBOX
    }
  ]
  const contactFields = [
    {
      name: "businessContact.officeNo",
      label: intl.get("field.officeNo"),
      type: FieldType.PHONE_NUMBER_V2
    },
    {
      name: "businessContact.faxNo",
      label: intl.get("field.faxNo"),
      type: FieldType.PHONE_NUMBER_V2
    },
    {
      name: "businessContact.email",
      label: intl.get("field.emailAddress"),
      type: FieldType.EMAIL
    }
  ]
  const addressField = [
    {
      name: "addressDetails.addressLine1",
      label: intl.get("field.address1"),
      type: FieldType.TEXT
    },
    {
      name: "addressDetails.addressLine2",
      label: intl.get("field.address2"),
      type: FieldType.TEXT
    },
    {
      name: "addressDetails.addressLine3",
      label: intl.get("field.address3"),
      type: FieldType.TEXT
    },
    {
      name: "addressDetails.suburb",
      label: intl.get("field.suburb"),
      type: FieldType.TEXT
    },
    {
      name: "addressDetails.state",
      label: intl.get("field.state"),
      type: FieldType.TEXT
    },
    {
      name: "addressDetails.postalCode",
      label: intl.get("field.postalCode"),
      type: FieldType.TEXT
    },
    {
      name: "addressDetails.country",
      label: intl.get("field.country"),
      type: FieldType.SELECTION,
      selectionData: translateListLabel(CountryList),
      valueProperty: "code",
      labelProperty: "name"
    }
  ]

  const companyField = [
    {
      name: "companyName",
      label: intl.get("field.currentCompany"),
      type: FieldType.TEXT
      // required: true,
    }
  ]

  const colorField = [
    {
      name: "titleBarColour",
      label: intl.get("field.changeThemeColor"),
      type: FieldType.COLOR
    }
  ]

  const fontColorField = [
    {
      name: "fontColour",
      label: intl.get("field.changeThemeFontColor"),
      type: FieldType.FONT_COLOR
    }
  ]

  return (
    <Form
      onSubmit={handleSave}
      initialValues={model} //show data
      mutators={{
        addregulatoryLicence: (args, state, utils) => {
          const { regulatoryLicences } = state.formState.values
          const updatedLicences = regulatoryLicences || [] // Assign an empty array if regulatoryLicences is null

          utils.changeValue(state, "regulatoryLicences", () => [
            ...updatedLicences,
            {}
          ])
        },
        removeregulatoryLicence: (args, state, utils) => {
          const [removedIndex] = args
          const { regulatoryLicences = [] } = state.formState.values
          utils.changeValue(state, "regulatoryLicences", () =>
            regulatoryLicences.filter((item, index) => removedIndex !== index)
          )
        }
      }}
      render={({ handleSubmit, reset, form: { mutators } }) => (
        <div>
          <form
            id="userProfileFrom"
            //20211220
            onSubmit={(event) => {
              handleSubmit(event)
              // .then(() => {
              //     reset();
              // });
            }}
          >
            <div className="portal-form-popup">
              <div className="portal-form user-profile">
                <Tabs>
                  <TabList>
                    <Tab>
                      <h5 className="tab-header">
                        {intl.get("entity.field.businessInformation")}
                      </h5>
                    </Tab>
                    <Tab>
                      <h5 className="tab-header">
                        {intl.get("entity.webAccess")}
                      </h5>
                    </Tab>
                    <Tab>
                      <h5 className="tab-header">
                        {intl.get("field.regulatoryLicence")}
                      </h5>
                    </Tab>
                    <Tab>
                      <h5 className="tab-header">
                        {intl.get("entity.contact")}
                      </h5>
                    </Tab>
                    <Tab>
                      <h5 className="tab-header">
                        {intl.get("field.address")}
                      </h5>
                    </Tab>
                    <Tab>
                      <h5 className="tab-header">Settings</h5>
                    </Tab>
                  </TabList>
                  <TabPanel>
                    <div className="user-profile-formgroup">
                      <RenderSimpleFieldsBootrap
                        fields={operationInformationFields}
                      />
                    </div>
                    <div className="user-profile-formgroup">
                      <div className="row mt-1">
                        <div className="col">
                          <label htmlFor="photoFile">
                            Profile {intl.get("userProfile.photo")}
                          </label>
                        </div>
                        <div className="col">
                          <div className="row-holder">
                            <Field
                              name="photoFile"
                              inputOnChange={uploaded}
                              component={fileFieldRenderBootstrapWithoutLabel}
                              validate={composeValidators(maxPhotoSize, image)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col" />
                        <div className="col d-flex mt-2">
                          <img
                            alt="preview"
                            className="photo-preview"
                            src={preview}
                            style={{ objectFit: "contain" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="user-profile-formgroup">
                      <div className="row mt-1">
                        <div className="col">
                          <label htmlFor="photoFile">
                            {/* {intl.get("userProfile.photo")} */}
                            Company Logo
                          </label>
                        </div>
                        <div className="col">
                          <div className="row-holder">
                            <Field
                              name="image"
                              inputOnChange={uploaded1}
                              component={fileFieldRenderBootstrapWithoutLabel}
                              validate={composeValidators(maxPhotoSize, image)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col" />
                        <div className="col d-flex mt-2">
                          <img
                            alt="preview"
                            className="photo-preview"
                            src={preview1}
                            style={{ objectFit: "contain" }}
                          />
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="kozai-system-form">
                      <RenderSimpleFieldsBootrap fields={webAccessFields} />
                      <RenderSimpleFieldsBootrap fields={globalAccess} />
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <RegulatoryLicence mutators={mutators} />
                  </TabPanel>
                  <TabPanel>
                    <div
                      id="scroll-section"
                      className="portal-form portal-form-businessDetails"
                    >
                      <RenderSimpleFieldsBootrap fields={contactFields} />
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="address-row">
                      <RenderSimpleFieldsBootrap fields={addressField} />
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="user-profile-formgroup">
                      {/* 20200624 MABT 368*/}

                      <RenderSimpleFieldsBootrap fields={companyField} />

                      {/* 20200624 MABT 368*/}

                      <RenderSimpleFieldsBootrap fields={colorField} />

                      <RenderSimpleFieldsBootrap fields={fontColorField} />
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
            <div className="portal-btn-popup">
              {!readOnlyAccess && (
                <React.Fragment>
                  <div className="text-right">
                    <input
                      className="portal-btn portal-btn-submit portal-btn-submit-mr-10"
                      value={intl.get("button.save")}
                      type="submit"
                      // disabled={model && values.entityName && values.country && values.userEmailAddress ? false : true}
                    />
                  </div>
                </React.Fragment>
              )}
            </div>
          </form>
        </div>
      )}
    />
  )
}

function RegulatoryLicence({ mutators }) {
  const { id } = JSON.parse(localStorage.getItem("user"))
  const { addregulatoryLicence, removeregulatoryLicence } = mutators
  const [financialRegulators, setFinancialRegulators] = useState("")
  const [financialRegulatorsList, setFinancialRegulatorsList] = useState([])
  const [savedFinanacialRegulators, setSavedFinancialRegulators] = useState([])

  let financialRegulators_list = []

  function refreshList() {
    EntityApi.getFinancialInstitutions().then(({ data }) => {
      setFinancialRegulators(data.list)
    })
  }

  useEffect(() => {
    refreshList()
    UserApi.getProfile(id).then((response) => {
      setSavedFinancialRegulators(response.data.regulatoryLicences)
    })
  }, [])

  useEffect(() => {
    if (financialRegulators && financialRegulators.length > 0) {
      financialRegulators.map((key, index) =>
        financialRegulators_list.push({
          index: index,
          name: key.name,
          value: key.id,
          data: key
        })
      )
    }
    setFinancialRegulatorsList(financialRegulators_list)
  }, [financialRegulators])

  return (
    <React.Fragment>
      <div className="portal-form-row">
        <label className="d-flex align-items-center">
          {intl.get("field.regulatoryLicence")}{" "}
          <span
            style={{ marginLeft: "18px" }}
            onClick={() =>
              addregulatoryLicence(
                "entityDetails.regulatoryLicences",
                undefined
              )
            }
            className="ml-1 btn btn-plus"
          />
        </label>
      </div>
      <FormSpy subscription={{ values: true }}>
        {({ values }) => {
          const { regulatoryLicences = [] } = values

          return (
            <React.Fragment>
              {regulatoryLicences && regulatoryLicences.length > 0 && (
                <div className="row mb-2">
                  <div className="col">
                    <div className="row-holder d-flex align-items-center">
                      <div className="_90p-width ">
                        {/* label */}
                        <div
                          // className={`${
                          //   index == 0 ? "" : "hide-half-container"
                          // }`}
                          className="row"
                        >
                          <div className="col-md-4 regulatorylicense-label">
                            Regulator
                          </div>
                          <div className="col-md-4 regulatorylicense-label">
                            License Number
                          </div>
                          <div className="col-md-4 regulatorylicense-label">
                            Expiry Date
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {regulatoryLicences &&
                regulatoryLicences.length > 0 &&
                regulatoryLicences.map((licence, index) => (
                  <div className="row" key={index}>
                    <div className="col">
                      <div className="row-holder d-flex align-items-center">
                        <div className="_90p-width d-flex align-items-center mb-2 ">
                          {/* label */}
                          <div
                            className={`${
                              index == 0 ? "" : "hide-half-container"
                            }`}
                          ></div>
                          <div className="row" style={{ width: "100%" }}>
                            <div className="col-md-4">
                              <Field
                                name={`regulatoryLicences[${index}].regulatoryLicenceId`}
                                component={renderSelectFieldCustom}
                                valueProperty="value"
                                labelProperty="name"
                                data={financialRegulatorsList}
                                isForGrid
                                required={true}
                                validate={required}
                              />
                            </div>
                            <div className="col-md-4">
                              <Field
                                name={`regulatoryLicences[${index}].number`}
                                component={renderTextBoxCustom}
                                isForGrid
                                required={true}
                                validate={required}
                              />
                            </div>
                            <div className="col-md-4">
                              <Field
                                name={`regulatoryLicences[${index}].expiryDate`}
                                component={renderDateFieldCustom}
                                isForGrid
                                required={true}
                                validate={required}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="_10p-width">
                          <span
                            onClick={() => removeregulatoryLicence(index)}
                            className="btn-remove"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </React.Fragment>
          )
        }}
      </FormSpy>
    </React.Fragment>
  )
}
function UserProfile(props) {
  const { id } = JSON.parse(localStorage.getItem("user"))
  const [preview, setPreview] = useState("")
  const [preview1, setPreview1] = useState("")
  const formData = new FormData()
  const [companyLogoId, setCompanyLogoId] = useState("")

  const getBase64 = (file, cb) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(reader.result)
    }
    reader.onerror = function (error) {
      console.log("Error: ", error)
    }
  }
  const uploaded = (event) => {
    getBase64(event.target.files[0], (result) => {
      setPreview(result)
    })
  }
  const uploaded1 = async (event) => {
    try {
      getBase64(event.target.files[0], (result) => {
        setPreview1(result)
      })
      let isError = composeValidators(
        maxPhotoSize,
        image
      )(event.target.files[0])
      if (!isError) {
        formData.append("image", event.target.files[0])
        const res = await Api.post(`/user/${id}/logo`, formData)
        if (res) {
          setCompanyLogoId(res.data.id)
        }
      }
    } catch (error) {
      console.log("error", error)
    }
  }
  const [model, setModel] = useState({})
  const [confirmationDialog, setConfirmationDialog] = useState({
    show: false,
    message: ""
  })

  useEffect(() => {
    UserApi.getProfile(id).then((response) => {
      setModel(response.data) //show data
      setPreview(
        `${createUrlFromPath(response.data.photoUrl)}?v=${Math.random()}`
      )
      setPreview1(
        `${createUrlFromPath(response.data.companyLogoUrl)}?v=${Math.random()}`
      )
      // companyLogoUrl
    })
  }, [id])

  const handleConfirmEntity = () => {
    if (confirmationDialog.data) {
      confirmationDialog.data.append("confirmDisconnectEntity", true)
      UserApi.editProfile(id, confirmationDialog.data).then((response) => {
        Alert.success("user profile successfully updated")
        // props.currentUser();
      })
    }
  }

  const handleSave = (values) => {
    const formData = new FormData()
    formData.append("userId", values.userId || "")
    formData.append("userEntityId", values.userEntityId || "")
    formData.append("userRoleId", values.userRoleId || "")
    formData.append("userEntityType", values.userEntityType || "")
    formData.append("userEmailAddress", values.userEmailAddress || "")
    formData.append("username", values.username || "")
    formData.append("country", values.country || "")

    if (!values.addressDetails) {
      //anticipate null addressDetails
      values.addressDetails = {}
    }
    formData.append(
      "addressDetails.addressLine1",
      values.addressDetails.addressLine1 || ""
    )
    formData.append(
      "addressDetails.addressLine2",
      values.addressDetails.addressLine2 || ""
    )
    formData.append(
      "addressDetails.addressLine3",
      values.addressDetails.addressLine3 || ""
    )
    formData.append("addressDetails.state", values.addressDetails.state || "")
    formData.append(
      "addressDetails.postalCode",
      values.addressDetails.postalCode || ""
    )
    formData.append("addressDetails.suburb", values.addressDetails.suburb || "")
    formData.append(
      "addressDetails.country",
      values.addressDetails.country
        ? values.addressDetails.country
        : values.country || ""
    )

    if (!values.businessContact) {
      //anticipate null business contact
      values.businessContact = {}
    }
    formData.append(
      "businessContact.addressLine1",
      values.businessContact.addressLine1
        ? values.businessContact.addressLine1
        : values.addressDetails.addressLine1 || ""
    )
    formData.append(
      "businessContact.addressLine2",
      values.businessContact.addressLine2
        ? values.businessContact.addressLine2
        : values.addressDetails.addressLine2 || ""
    )
    formData.append(
      "businessContact.addressLine3",
      values.businessContact.addressLine3
        ? values.businessContact.addressLine3
        : values.addressDetails.addressLine3 || ""
    )
    formData.append(
      "businessContact.state",
      values.businessContact.state
        ? values.businessContact.state
        : values.addressDetails.state || ""
    )
    formData.append(
      "businessContact.postalCode",
      values.businessContact.postalCode
        ? values.businessContact.postalCode
        : values.addressDetails.postalCode || ""
    )
    formData.append(
      "businessContact.suburb",
      values.businessContact.suburb
        ? values.businessContact.suburb
        : values.addressDetails.suburb || ""
    )
    formData.append(
      "businessContact.country",
      values.businessContact.country
        ? values.businessContact.country
        : values.country || ""
    )
    formData.append("businessContact.email", values.businessContact.email || "")
    formData.append("businessContact.faxNo", values.businessContact.faxNo || "")
    formData.append(
      "businessContact.officeNo",
      values.businessContact.officeNo || ""
    )

    formData.append(
      "companyRegistrationNumber",
      values.companyRegistrationNumber || ""
    )

    formData.append("entityId", values.entityId || "")
    formData.append("entityName", values.entityName || "")
    formData.append("entityUniqueId", values.entityUniqueId || "")
    formData.append("photoUrl", values.photoUrl || "")
    formData.append("companyName", values.companyName || "")
    formData.append("titleBarColour", values.titleBarColour || "")
    formData.append("fontColour", values.fontColour || "")
    formData.append("grantKoizaiGlobal", values.grantKoizaiGlobal || false)

    if (values.photoFile) {
      formData.append("photoFile", values.photoFile)
    }
    if (companyLogoId) {
      formData.append("companyLogoId", companyLogoId)
    }
    if (values.regulatoryLicences && Array.isArray(values.regulatoryLicences)) {
      values.regulatoryLicences.forEach((a, i) => {
        formData.append(`regulatoryLicences[${i}].name`, a.name || "")
        formData.append(`regulatoryLicences[${i}].number`, a.number || "")
        formData.append(
          `regulatoryLicences[${i}].expiryDate`,
          a.expiryDate || ""
        )
      })
    }

    return UserApi.editProfile(id, formData).then(
      (response) => {
        if (response.data.errorCode === "confirm_disconnect_entity") {
          setConfirmationDialog({
            show: true,
            message: response.data.errorMessage,
            data: formData
          })
        } else {
          let loginUserEntity = localStorage.getItem("loginUserEntity")
            ? JSON.parse(localStorage.getItem("loginUserEntity"))
            : undefined
          if (loginUserEntity && response.data.country) {
            loginUserEntity.country = response.data.country
            localStorage.setItem(
              "loginUserEntity",
              JSON.stringify(loginUserEntity)
            )
          }

          Alert.success("Successfully update the profile.")
          if (response.data === "need_logout") {
            Alert.success(
              "You have changed the email address. Therefore you have been forced to log out"
            )
            logout()
            props.clearModal()
          } else {
            if (response.data.selectedUserRole) {
              let userRole = response.data.selectedUserRole
              localStorage.setItem("activeUserRole", JSON.stringify(userRole))
            }
            window.location.reload()
          }
        }

        // return Promise.resolve();
      },
      () => {
        // return Promise.reject();
      }
    )
  }
  const { readOnlyAccess, entityType } = props
  return (
    <>
      <FormContainer
        {...{
          model,
          readOnlyAccess,
          handleSave,
          entityType,
          preview,
          preview1,
          uploaded,
          uploaded1
        }}
      />
      <ConfirmationDialog
        isOpen={confirmationDialog.show}
        message={confirmationDialog.message}
        onClose={() =>
          setConfirmationDialog({
            message: "",
            show: false
          })
        }
        onYes={handleConfirmEntity}
      />
    </>
  )
}

function mapStateToProps(state) {
  return {
    entityType: state.modalWindow.entityType,
    activeAccessLevel: state.appSettings
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, entityActions, modalWindowActions, userActions),
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile)
