import { isNullOrUndefined, getNewCurrencyValue } from '../../helpers';
import {
    foodAndDining,
    educationExpense,
    childrenExpenses,
    parentExpenses,
    totalSum,
    countFromArray,
    entertainment,
    shoppingExpenses,
    travelExpenses,
    personalFinanceExpensesCounter,
    personalIncomeCounter,
    giftAndDonation,
    petExpensesCounter,
    personalBusinessCounter,
} from './CashflowCounterSections';

const initValue = {
    clientTotal: 0,
    partnerTotal: 0,
    jointTotal: 0,
};
export const countTotalValues = (values, currency) => {
    const result = {
        income: {},
        expense: {},
        outflow: {},
        total: {
            clientTotal: 0,
            partnerTotal: 0,
            jointTotal: 0,
        },
    };

    result.income = countIncomeTotalValues(values.personalIncome, currency);
    result.expense = countExpenseTotalValues(values.personalExpense, currency);
    result.outflow = countOutflowTotalValues(
        values.investmentOutflow,
        currency
    );

    const { income, expense, outflow } = result;

    result.total = {
        clientTotal:
            income.total.clientTotal -
            expense.total.clientTotal -
            outflow.total.clientTotal,
        partnerTotal:
            income.total.partnerTotal -
            expense.total.partnerTotal -
            outflow.total.partnerTotal,
        jointTotal:
            income.total.jointTotal -
            expense.total.jointTotal -
            outflow.total.jointTotal,
    };

    return result;
};

export const countIncomeTotalValues = (values, currency) => {
    const result = {
        employment: Object.create(initValue),
        total: Object.create(initValue),
        invesmentPropertyRentalIncome: Object.create(initValue),
        cashAccount: Object.create(initValue),
        termDeposit: Object.create(initValue),
        share: Object.create(initValue),
        unitTrust: Object.create(initValue),
        bond: Object.create(initValue),
    };

    if (
        !isNullOrUndefined(values) &&
        !isNullOrUndefined(values.investmentPropertyRental)
    ) {
        // count direct variables
        const countClient = countFromArray(
            values.investmentPropertyRental,
            currency
        );
        result.invesmentPropertyRentalIncome = countClient;
    }
    if (!isNullOrUndefined(values) && !isNullOrUndefined(values.employment)) {
        const { salaries, bonuses, workAllowances, others } = values.employment;

        // count direct variables
        const countSalaries = countFromArray(salaries, currency);
        const countBonuses = countFromArray(bonuses, currency);
        const countWorkAllowances = countFromArray(workAllowances, currency);
        const countOthers = countFromArray(others, currency);

        result.employment.clientTotal =
            countSalaries.clientTotal +
            countBonuses.clientTotal +
            countWorkAllowances.clientTotal +
            countOthers.clientTotal;
        result.employment.partnerTotal =
            countSalaries.partnerTotal +
            countBonuses.partnerTotal +
            countWorkAllowances.partnerTotal +
            countOthers.partnerTotal;
        result.employment.jointTotal =
            countSalaries.jointTotal +
            countBonuses.jointTotal +
            countWorkAllowances.jointTotal +
            countOthers.jointTotal;
    }
    result.cashAccount = personalIncomeCounter(values, currency, 'cashAccount');
    result.termDeposit = personalIncomeCounter(values, currency, 'termDeposit');
    result.share = personalIncomeCounter(values, currency, 'share');
    result.unitTrust = personalIncomeCounter(values, currency, 'unitTrust');
    result.bond = personalIncomeCounter(values, currency, 'bond');
    result.ilas = personalIncomeCounter(values, currency, 'ilasAnnuityIncome');

    result.total.clientTotal += totalSum(result, 'clientTotal');
    result.total.partnerTotal += totalSum(result, 'partnerTotal');
    result.total.jointTotal += totalSum(result, 'jointTotal');

    return result;
};

export const countExpenseTotalValues = (values, currency) => {
    const result = {
        personalCare: Object.create(initValue),
        carAndTransport: Object.create(initValue),
        home: Object.create(initValue),
        healthAndFitness: Object.create(initValue),
        billAndUtilities: Object.create(initValue),
        otherInsurancePremium: Object.create(initValue),
        foodAndDining: Object.create(initValue),
        educationExpenses: Object.create(initValue),
        childrenExpenses: Object.create(initValue),
        parentExpenses: Object.create(initValue),
        entertainment: Object.create(initValue),
        shoppingExpenses: Object.create(initValue),
        travelExpenses: Object.create(initValue),
        personalFinanceExpenses: Object.create(initValue),
        petExpenses: Object.create(initValue),
        personalBusiness: Object.create(initValue),
        giftAndDonations: Object.create(initValue),
        taxPayment: Object.create(initValue),       //20201103 MABT-423        
        total: Object.create(initValue),
    };

    if (!isNullOrUndefined(values)) {
        if (!isNullOrUndefined(values.personalCare)) {
            const { others } = values.personalCare;

            result.personalCare.clientTotal +=
                (values.personalCare !== undefined &&
                !isNullOrUndefined(values.personalCare.hair) &&
                !isNullOrUndefined(values.personalCare.hair.client)
                    ? getNewCurrencyValue(
                          values.personalCare.hair.client,
                          currency
                      )
                    : 0) +
                (values.personalCare !== undefined &&
                !isNullOrUndefined(values.personalCare.laundry) &&
                !isNullOrUndefined(values.personalCare.laundry.client)
                    ? getNewCurrencyValue(
                          values.personalCare.laundry.client,
                          currency
                      )
                    : 0) +
                (values.personalCare !== undefined &&
                !isNullOrUndefined(values.personalCare.facialSpaMassage) &&
                !isNullOrUndefined(values.personalCare.facialSpaMassage.client)
                    ? getNewCurrencyValue(
                          values.personalCare.facialSpaMassage.client,
                          currency
                      )
                    : 0);
            result.personalCare.partnerTotal +=
                (values.personalCare !== undefined &&
                !isNullOrUndefined(values.personalCare.hair) &&
                !isNullOrUndefined(values.personalCare.hair.partner)
                    ? getNewCurrencyValue(
                          values.personalCare.hair.partner,
                          currency
                      )
                    : 0) +
                (values.personalCare !== undefined &&
                !isNullOrUndefined(values.personalCare.laundry) &&
                !isNullOrUndefined(values.personalCare.laundry.partner)
                    ? getNewCurrencyValue(
                          values.personalCare.laundry.partner,
                          currency
                      )
                    : 0) +
                (values.personalCare !== undefined &&
                !isNullOrUndefined(values.personalCare.facialSpaMassage) &&
                !isNullOrUndefined(values.personalCare.facialSpaMassage.partner)
                    ? getNewCurrencyValue(
                          values.personalCare.facialSpaMassage.partner,
                          currency
                      )
                    : 0);
            result.personalCare.jointTotal +=
                (values.personalCare !== undefined &&
                !isNullOrUndefined(values.personalCare.hair) &&
                !isNullOrUndefined(values.personalCare.hair.joint)
                    ? getNewCurrencyValue(
                          values.personalCare.hair.joint,
                          currency
                      )
                    : 0) +
                (values.personalCare !== undefined &&
                !isNullOrUndefined(values.personalCare.laundry) &&
                !isNullOrUndefined(values.personalCare.laundry.joint)
                    ? getNewCurrencyValue(
                          values.personalCare.laundry.joint,
                          currency
                      )
                    : 0) +
                (values.personalCare !== undefined &&
                !isNullOrUndefined(values.personalCare.facialSpaMassage) &&
                !isNullOrUndefined(values.personalCare.facialSpaMassage.joint)
                    ? getNewCurrencyValue(
                          values.personalCare.facialSpaMassage.joint,
                          currency
                      )
                    : 0);

            // count direct variables
            const countOthers = countFromArray(others, currency);

            result.personalCare.clientTotal += countOthers.clientTotal;
            result.personalCare.partnerTotal += countOthers.partnerTotal;
            result.personalCare.jointTotal += countOthers.jointTotal;
        }

        if (!isNullOrUndefined(values.carAndTransport)) {
            const { publicTransportation, vehicles } = values.carAndTransport;

            // count direct variables
            const countPublicTransport = countFromArray(
                publicTransportation,
                currency
            );
            const countVehicles = countFromArray(vehicles, currency);

            result.carAndTransport.clientTotal +=
                countPublicTransport.clientTotal + countVehicles.clientTotal;
            result.carAndTransport.partnerTotal +=
                countPublicTransport.partnerTotal + countVehicles.partnerTotal;
            result.carAndTransport.jointTotal +=
                countPublicTransport.jointTotal + countVehicles.jointTotal;
        }

        if (!isNullOrUndefined(values.home)) {
            const { homes, rents, others } = values.home;

            // count direct variables
            const countHomes = countFromArray(homes, currency);
            const countRents = countFromArray(rents, currency);
            const countOthers = countFromArray(others, currency);

            result.home.clientTotal +=
                countHomes.clientTotal +
                countRents.clientTotal +
                countOthers.clientTotal;
            result.home.partnerTotal +=
                countHomes.partnerTotal +
                countRents.partnerTotal +
                countOthers.partnerTotal;
            result.home.jointTotal +=
                countHomes.jointTotal +
                countRents.jointTotal +
                countOthers.jointTotal;
        }

        if (!isNullOrUndefined(values.healthAndFitness)) {
            const { healthInsurances, others } = values.healthAndFitness;

            // count linked variables
            result.healthAndFitness.clientTotal +=
                (values.healthAndFitness !== undefined &&
                !isNullOrUndefined(values.healthAndFitness.dentist) &&
                !isNullOrUndefined(values.healthAndFitness.dentist.client)
                    ? getNewCurrencyValue(
                          values.healthAndFitness.dentist.client,
                          currency
                      )
                    : 0) +
                (values.healthAndFitness !== undefined &&
                !isNullOrUndefined(values.healthAndFitness.doctor) &&
                !isNullOrUndefined(values.healthAndFitness.doctor.client)
                    ? getNewCurrencyValue(
                          values.healthAndFitness.doctor.client,
                          currency
                      )
                    : 0) +
                (values.healthAndFitness !== undefined &&
                !isNullOrUndefined(values.healthAndFitness.eyeCare) &&
                !isNullOrUndefined(values.healthAndFitness.eyeCare.client)
                    ? getNewCurrencyValue(
                          values.healthAndFitness.eyeCare.client,
                          currency
                      )
                    : 0) +
                (values.healthAndFitness !== undefined &&
                !isNullOrUndefined(values.healthAndFitness.gym) &&
                !isNullOrUndefined(values.healthAndFitness.gym.client)
                    ? getNewCurrencyValue(
                          values.healthAndFitness.gym.client,
                          currency
                      )
                    : 0) +
                (values.healthAndFitness !== undefined &&
                !isNullOrUndefined(values.healthAndFitness.pharmacy) &&
                !isNullOrUndefined(values.healthAndFitness.pharmacy.client)
                    ? getNewCurrencyValue(
                          values.healthAndFitness.pharmacy.client,
                          currency
                      )
                    : 0) +
                (values.healthAndFitness !== undefined &&
                !isNullOrUndefined(values.healthAndFitness.sports) &&
                !isNullOrUndefined(values.healthAndFitness.sports.client)
                    ? getNewCurrencyValue(
                          values.healthAndFitness.sports.client,
                          currency
                      )
                    : 0);

            result.healthAndFitness.partnerTotal +=
                (values.healthAndFitness !== undefined &&
                !isNullOrUndefined(values.healthAndFitness.dentist) &&
                !isNullOrUndefined(values.healthAndFitness.dentist.partner)
                    ? getNewCurrencyValue(
                          values.healthAndFitness.dentist.partner,
                          currency
                      )
                    : 0) +
                (values.healthAndFitness !== undefined &&
                !isNullOrUndefined(values.healthAndFitness.doctor) &&
                !isNullOrUndefined(values.healthAndFitness.doctor.partner)
                    ? getNewCurrencyValue(
                          values.healthAndFitness.doctor.partner,
                          currency
                      )
                    : 0) +
                (values.healthAndFitness !== undefined &&
                !isNullOrUndefined(values.healthAndFitness.eyeCare) &&
                !isNullOrUndefined(values.healthAndFitness.eyeCare.partner)
                    ? getNewCurrencyValue(
                          values.healthAndFitness.eyeCare.partner,
                          currency
                      )
                    : 0) +
                (values.healthAndFitness !== undefined &&
                !isNullOrUndefined(values.healthAndFitness.gym) &&
                !isNullOrUndefined(values.healthAndFitness.gym.partner)
                    ? getNewCurrencyValue(
                          values.healthAndFitness.gym.partner,
                          currency
                      )
                    : 0) +
                (values.healthAndFitness !== undefined &&
                !isNullOrUndefined(values.healthAndFitness.pharmacy) &&
                !isNullOrUndefined(values.healthAndFitness.pharmacy.partner)
                    ? getNewCurrencyValue(
                          values.healthAndFitness.pharmacy.partner,
                          currency
                      )
                    : 0) +
                (values.healthAndFitness !== undefined &&
                !isNullOrUndefined(values.healthAndFitness.sports) &&
                !isNullOrUndefined(values.healthAndFitness.sports.partner)
                    ? getNewCurrencyValue(
                          values.healthAndFitness.sports.partner,
                          currency
                      )
                    : 0);

            result.healthAndFitness.jointTotal +=
                (values.healthAndFitness !== undefined &&
                !isNullOrUndefined(values.healthAndFitness.dentist) &&
                !isNullOrUndefined(values.healthAndFitness.dentist.joint)
                    ? getNewCurrencyValue(
                          values.healthAndFitness.dentist.joint,
                          currency
                      )
                    : 0) +
                (values.healthAndFitness !== undefined &&
                !isNullOrUndefined(values.healthAndFitness.doctor) &&
                !isNullOrUndefined(values.healthAndFitness.doctor.joint)
                    ? getNewCurrencyValue(
                          values.healthAndFitness.doctor.joint,
                          currency
                      )
                    : 0) +
                (values.healthAndFitness !== undefined &&
                !isNullOrUndefined(values.healthAndFitness.eyeCare) &&
                !isNullOrUndefined(values.healthAndFitness.eyeCare.joint)
                    ? getNewCurrencyValue(
                          values.healthAndFitness.eyeCare.joint,
                          currency
                      )
                    : 0) +
                (values.healthAndFitness !== undefined &&
                !isNullOrUndefined(values.healthAndFitness.gym) &&
                !isNullOrUndefined(values.healthAndFitness.gym.joint)
                    ? getNewCurrencyValue(
                          values.healthAndFitness.gym.joint,
                          currency
                      )
                    : 0) +
                (values.healthAndFitness !== undefined &&
                !isNullOrUndefined(values.healthAndFitness.pharmacy) &&
                !isNullOrUndefined(values.healthAndFitness.pharmacy.joint)
                    ? getNewCurrencyValue(
                          values.healthAndFitness.pharmacy.joint,
                          currency
                      )
                    : 0) +
                (values.healthAndFitness !== undefined &&
                !isNullOrUndefined(values.healthAndFitness.sports) &&
                !isNullOrUndefined(values.healthAndFitness.sports.joint)
                    ? getNewCurrencyValue(
                          values.healthAndFitness.sports.joint,
                          currency
                      )
                    : 0);

            // count direct variables
            const countHealthIns = countFromArray(healthInsurances, currency);
            const countOthers = countFromArray(others, currency);

            result.healthAndFitness.clientTotal +=
                countHealthIns.clientTotal + countOthers.clientTotal;
            result.healthAndFitness.partnerTotal +=
                countHealthIns.partnerTotal + countOthers.partnerTotal;
            result.healthAndFitness.jointTotal +=
                countHealthIns.jointTotal + countOthers.jointTotal;
        }

        if (!isNullOrUndefined(values.billAndUtilities)) {
            const { personalMobilePhones, others } = values.billAndUtilities;
            const model = {
                personalMobilePhones: countFromArray(
                    personalMobilePhones,
                    currency
                ),
                others: countFromArray(others, currency),
            };

            result.billAndUtilities.clientTotal = totalSum(
                model,
                'clientTotal'
            );
            result.billAndUtilities.partnerTotal = totalSum(
                model,
                'partnerTotal'
            );
            result.billAndUtilities.jointTotal = totalSum(model, 'jointTotal');
        }

        if (!isNullOrUndefined(values.otherInsurancePremium)) {
            const { otherInsurancePremium } = values;

            // count direct variables
            const countOtherInsurancePremium = countFromArray(
                otherInsurancePremium,
                currency
            );

            result.otherInsurancePremium.clientTotal +=
                countOtherInsurancePremium.clientTotal;
            result.otherInsurancePremium.partnerTotal +=
                countOtherInsurancePremium.partnerTotal;
            result.otherInsurancePremium.jointTotal +=
                countOtherInsurancePremium.jointTotal;
        }
        result.foodAndDining = foodAndDining(values, currency);
        result.educationExpenses = educationExpense(values, currency);
        result.childrenExpenses = childrenExpenses(values, currency);
        result.parentExpenses = parentExpenses(values, currency);
        result.entertainment = entertainment(values, currency);
        result.shoppingExpenses = shoppingExpenses(values, currency);
        result.travelExpenses = travelExpenses(values, currency);
        result.personalFinanceExpenses = personalFinanceExpensesCounter(
            values,
            currency
        );
        result.giftAndDonations = giftAndDonation(values, currency);
        result.petExpenses = petExpensesCounter(values, currency);
        result.personalBusiness = personalBusinessCounter(values, currency);
    }

    result.total.clientTotal += totalSum(result, 'clientTotal');
    result.total.partnerTotal += totalSum(result, 'partnerTotal');
    result.total.jointTotal += totalSum(result, 'jointTotal');

    return result;
};

export const countOutflowTotalValues = (values, currency) => {
    const result = {
        investmentProperty: {
            clientTotal: 0,
            partnerTotal: 0,
            jointTotal: 0,
        },
        regularSaving: {
            clientTotal: 0,
            partnerTotal: 0,
            jointTotal: 0,
        },
        total: {
            clientTotal: 0,
            partnerTotal: 0,
            jointTotal: 0,
        },
    };

    if (
        !isNullOrUndefined(values) &&
        !isNullOrUndefined(values.investmentProperty)
    ) {
        // count direct variables
        const countInvestmentProperty = countFromArray(
            values.investmentProperty,
            currency
        );
        result.investmentProperty = countInvestmentProperty;
    }

    if (
        !isNullOrUndefined(values) &&
        !isNullOrUndefined(values.regularSaving)
    ) {
        // count direct variables
        const countRegularSaving = countFromArray(
            values.regularSaving,
            currency
        );
        result.regularSaving = countRegularSaving;
    }

    // count all from investment outflow
    result.total.clientTotal +=
        result.investmentProperty.clientTotal +
        result.regularSaving.clientTotal;
    result.total.partnerTotal +=
        result.investmentProperty.partnerTotal +
        result.regularSaving.partnerTotal;
    result.total.jointTotal +=
        result.investmentProperty.jointTotal + result.regularSaving.jointTotal;

    return result;
};
