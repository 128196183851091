import {
  clientAction,
  netWorthAction,
  netWorthFormAction
} from "../../../constants/ActionConstants"
import api from "../../../lib/api"
import { jointSelectionActions } from "../../JointSelectionActions"
import { getCurrency } from "../../../helpers"
import Get from "../../../helpers/NullHelper"

export const netWorthActions = {
  getClient,
  getPersonalAssetsListing,
  getLiquidAssetsListing,
  getIlliquidAssetsListing,
  getRetirementAssetsListing,
  getGEALListing,
  getLiabilitiesListing,
  getTaxationListing,
  getEmploymentTerminationPaymentsListing,
  toggleAccordion,
  clearAccordion,
  getNetWorthTotalSummary,
  updateListingFilter,
  openConfirmationDialog,
  closeConfirmationDialog,
  getSalariesList
}

function getClientSuccess(response) {
  return { type: clientAction.GET_CLIENT_SUCCESS, response }
}
function getClientWithPartnerSuccess(client, partner) {
  return {
    type: clientAction.GET_CLIENT_WITH_PARTNER_SUCCESS,
    client,
    partner
  }
}
function getPersonalAssetSuccess(personalAssetsListing) {
  return {
    type: netWorthAction.GET_PERSONAL_ASSETS_LISTING_SUCCESS,
    personalAssetsListing
  }
}
function getLiquidAssetsSuccess(liquidAssetsListing) {
  return {
    type: netWorthAction.GET_LIQUID_ASSETS_LISTING_SUCCESS,
    liquidAssetsListing
  }
}
function getIlliquidAssetsSuccess(illiquidAssetsListing) {
  return {
    type: netWorthAction.GET_ILLIQUID_ASSETS_LISTING_SUCCESS,
    illiquidAssetsListing
  }
}
function getRetirementAssetsSuccess(retirementAssetsListing) {
  return {
    type: netWorthAction.GET_RETIREMENT_ASSETS_LISTING_SUCCESS,
    retirementAssetsListing
  }
}
function getGEALListingSuccess(gealListing) {
  return {
    type: netWorthAction.GET_GEAL_SUCCESS,
    gealListing
  }
}

function getCPFSuccess(model) {
  return {
    type: netWorthAction.GET_CPF_SUCCESS,
    model
  }
}

function getLiabilitiesSuccess(liabilitiesListing) {
  return {
    type: netWorthAction.GET_LIABILITIES_LISTING_SUCCESS,
    liabilitiesListing
  }
}
function getTaxationSuccess(taxationListing) {
  return {
    type: netWorthAction.GET_TAXATION_LISTING_SUCCESS,
    taxationListing
  }
}
function getEmploymentTerminationPaymentsSuccess(
  employmentTerminationPaymentsListing
) {
  return {
    type: netWorthAction.GET_EMPLOYMENT_TERMINATION_PAYMENTS_SUCCESS,
    employmentTerminationPaymentsListing
  }
}
function getNetWorthTotalSummarySuccess(totalSummary) {
  return {
    type: netWorthAction.GET_NET_WORTH_TOTAL_SUMMARY_SUCCESS,
    totalSummary
  }
}
function toggleAccordion(accordionName) {
  // clearAccordion();
  return { type: netWorthAction.TOGGLE_ACCORDION, accordionName }
}
function clearAccordion() {
  return { type: netWorthAction.CLEAR_ACCORDION }
}
function updateListingFilter(filter) {
  return { type: netWorthAction.UPDATE_LISTING_FILTER, filter }
}

function getClient(id, currency = "") {
  return async (dispatch) => {
    await api.get(`/client/${id}`).then((getClientResponse) => {
      if (getClientResponse) {
        const client = getClientResponse.data
        if (getClientResponse.data.classification === "JOINT") {
          api
            .get(client._embedded.partner._links.self.href)
            .then((getPartnerResponse) => {
              dispatch(
                getClientWithPartnerSuccess(client, getPartnerResponse.data)
              )
              dispatch(
                jointSelectionActions.updateSelectionData({
                  client,
                  partner: getPartnerResponse.data,
                  isJoint: true
                })
              )
            })
          //single user
        } else {
          dispatch(getClientSuccess(getClientResponse))
          dispatch(
            jointSelectionActions.updateSelectionData({
              client
            })
          )
        }
        let cry = currency
        if (!cry) {
          if (client.taxResidency) {
            cry = getCurrency(client.taxResidency)
          } else if (
            Get(client, "personalDetails.employmentDetails.taxResidency")
          ) {
            cry = getCurrency(
              Get(client, "personalDetails.employmentDetails.taxResidency")
            )
          }
        }
        dispatch(getPersonalAssetsListing(client, cry))
        dispatch(getLiquidAssetsListing(client, cry))
        dispatch(getIlliquidAssetsListing(client, cry))
        dispatch(getRetirementAssetsListing(client, cry))
        dispatch(getGEALListing(client, cry))
        dispatch(getLiabilitiesListing(client, cry))
        dispatch(getTaxationListing(client, cry))
        dispatch(getEmploymentTerminationPaymentsListing(client, cry))
        dispatch(getNetWorthTotalSummary(client, cry))
      }
    })
  }
}

function getPersonalAssetsListing(client, currency) {
  return async (dispatch) => {
    try {
      const response = await api.get(
        `${client._embedded.personalAssetsListing._links.self.href}?reportingCurrency=${currency}`
      )
      if (response) {
        dispatch(getPersonalAssetSuccess(response.data))
      }
    } catch (error) {
      console.log("error occurred", error)
    }
  }
}

function getLiquidAssetsListing(client, currency) {
  return (dispatch) => {
    api
      .get(
        `${client._embedded.liquidAssetsListing._links.self.href}?reportingCurrency=${currency}`
      )
      .then()
      .then((response) => {
        dispatch(getLiquidAssetsSuccess(response.data))
      })
  }
}

function getIlliquidAssetsListing(client, currency) {
  return (dispatch) => {
    api
      .get(
        `${client._embedded.illiquidAssetsListing._links.self.href}?reportingCurrency=${currency}`
      )
      .then()
      .then((response) => {
        dispatch(getIlliquidAssetsSuccess(response.data))
      })
  }
}

function getRetirementAssetsListing(client, currency) {
  return (dispatch) => {
    api
      .get(
        `${client._embedded.retirementAssetsListing._links.self.href}?reportingCurrency=${currency}`
      )
      .then()
      .then((response) => {
        dispatch(getRetirementAssetsSuccess(response.data))
      })
  }
}

function getGEALListing(client, currency) {
  return (dispatch) => {
    api
      .get(
        `${client._embedded.gealListing._links.self.href}?reportingCurrency=${currency}`
      )
      .then()
      .then((response) => {
        dispatch(getGEALListingSuccess(response.data))
      })
  }
}

function getLiabilitiesListing(client, currency) {
  return (dispatch) => {
    api
      .get(
        `${client._embedded.liabilitiesListing._links.self.href}?reportingCurrency=${currency}`
      )
      .then()
      .then((response) => {
        dispatch(getLiabilitiesSuccess(response.data))
      })
  }
}

function getTaxationListing(client, currency) {
  return (dispatch) => {
    api
      .get(
        `${client._embedded.taxationListing._links.self.href}?reportingCurrency=${currency}`
      )
      .then()
      .then((response) => {
        dispatch(getTaxationSuccess(response.data))
      })
  }
}

function getEmploymentTerminationPaymentsListing(client, currency) {
  return (dispatch) => {
    api
      .get(
        `${client._embedded.employmentTerminationPaymentsListing._links.self.href}?reportingCurrency=${currency}`
      )
      .then((response) => {
        dispatch(getEmploymentTerminationPaymentsSuccess(response.data))
      })
  }
}

function getNetWorthTotalSummary(client, currency) {
  return (dispatch) => {
    api
      .get(
        `${client._embedded.netWorthTotalSummary._links.self.href}?reportingCurrency=${currency}`
      )
      .then()
      .then((response) => {
        dispatch(getNetWorthTotalSummarySuccess(response.data))
      })
  }
}

function closeConfirmationDialog() {
  return {
    type: netWorthAction.CLOSE_CONFIRMATION_DIALOG
  }
}

function openConfirmationDialog(onYes) {
  return {
    type: netWorthAction.OPEN_CONFIRMATION_DIALOG,
    onYes
  }
}
function setSalariesListSuccess(salaries) {
  return {
    type: netWorthFormAction.SET_SALARIES_LIST_SUCCESS, // Adjust the action type as needed
    salaries
  }
}
function getSalariesList(client) {
  return async (dispatch) => {
    try {
      await api.get(`/client/${client.id}/salary`).then((response) => {
        dispatch(setSalariesListSuccess(response.data))
      })
    } catch (error) {
      console.log("error", error)
    }
  }
}
