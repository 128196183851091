import Alert from "react-s-alert"


export const validateEmploymentTerminationPayment = (model) => {
  if (model.paymentDetailsFile && model.paymentDetailsFile.rawFile) {
    if (model.paymentDetailsFile.rawFile.size <= 0) {
      Alert.error("File size should be more than 0 byte")
      return false
    }

    return model.paymentDetailsFile.rawFile.size > 0
  }
  return true
}
export const validateEpfBeforeSave = (model) => {
  let isValid = true

  return isValid
}
