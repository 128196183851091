import React, { useContext } from "react"
import intl from "react-intl-universal"
import { Field } from "react-final-form"
import {
  getSymbolFromCode,
  renderCurrencyCustom,
  renderSelectEnumFieldCustom
} from "../../../helpers"
import Get from "../../../helpers/NullHelper"
import SalaryDetailsForm from "../SubForm/SalaryDetailsForm"
import { AppContext } from "../../../context/AppContext"
import { netWorthFormActions } from "../../../actions"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

function CentralProvidenceVoluntaryContributions({
  model = { salaryDetails: undefined },
  optionsData,
  client,
  partner,
  isJoint,
  mutators
}) {
  const {
    modal: { setModal, clearModal }
  } = useContext(AppContext)

  const showSalaryForm = (salaryDetails) => {
    if (!salaryDetails) {
      salaryDetails = {}
    }
    if (!Get(salaryDetails, "pensionContributionDetails.contributionType")) {
      Object.assign(salaryDetails, {
        pensionContributionDetails: {
          contributionType: "CPF"
        }
      })
    }
    if (!Get(salaryDetails, "currency")) {
      Object.assign(salaryDetails, {
        currency: "SGD"
      })
    }
    const props = {
      optionsData,
      onSave: async (model) => {
        mutators.setValue("salaryDetails", model)
        if (
          Get(
            model,
            "pensionContributionDetails.employeeComponent.regularContributionValue"
          )
        ) {
          mutators.setValue(
            "employee.regularContributionValue",
            Get(
              model,
              "pensionContributionDetails.employeeComponent.regularContributionValue"
            )
          )
        }

        if (
          Get(
            model,
            "pensionContributionDetails.employerComponent.regularContributionValue"
          )
        ) {
          mutators.setValue(
            "employer.regularContributionValue",
            Get(
              model,
              "pensionContributionDetails.employerComponent.regularContributionValue"
            )
          )
        }

        clearModal()
      },
      model: salaryDetails,
      client,
      partner,
      isJoint
    }

    setModal({
      title: intl.get("salaryDetails.title"),
      content: <SalaryDetailsForm {...props} />,
      formType: "EmployeeProvidenceFundDetailForm"
    })
  }

  const { salaryDetails } = model
  const { frequencyOptions } = optionsData
  return (
    <React.Fragment>
      <div className="portal-form-row portal-form-row2col">
        <div className="subtitle2">CPF Contributions:</div>
      </div>

      <div className="portal-form-row"></div>

      <div className="portal-form-row portal-form-row2col">
        <label></label>
        <div className="row-holder text-bold-underline employer">
          {intl.get("field.employer")}
        </div>
        <div className="row-holder text-bold-underline employee">
          {intl.get("field.employee")}
        </div>
      </div>

      <div className="portal-form-row portal-form-row2col">
        <span className="mr-3">Salary</span>
        <span
          onClick={() => showSalaryForm(salaryDetails)}
          className={`btn btn-${salaryDetails ? "edit" : "plus"}`}
        />
      </div>
      <div className="portal-form-row portal-form-row2col">
        <label>{intl.get("field.mandatoryContribution")}</label>
        <Field
          name="employer.mandatoryContribution"
          component={renderCurrencyCustom}
          symbolPrefix={getSymbolFromCode("SGD")}
        />
        <Field
          name="employee.mandatoryContribution"
          component={renderCurrencyCustom}
          symbolPrefix={getSymbolFromCode("SGD")}
        />
      </div>

      <div className="portal-form-row portal-form-row2col">
        <label>{intl.get("field.contributionFrequency")}</label>
        <Field
          name={`employer.contributionFrequency`}
          component={renderSelectEnumFieldCustom}
          enumData={frequencyOptions}
        />
        <Field
          name={`employee.contributionFrequency`}
          component={renderSelectEnumFieldCustom}
          enumData={frequencyOptions}
        />
      </div>

      <div className="portal-form-row portal-form-row2col">
        <div className="text-underline">
          CPF Accounts for Voluntary Contribution:
        </div>
      </div>
      <div className="portal-form-row portal-form-row2col">
        <label>Ordinary Account (OA)</label>
        <Field
          name={`employer.ordinaryAccount`}
          component={renderCurrencyCustom}
          symbolPrefix={getSymbolFromCode("SGD")}
        />
        <Field
          name={`employee.ordinaryAccount`}
          component={renderCurrencyCustom}
          symbolPrefix={getSymbolFromCode("SGD")}
        />
      </div>
      <div className="portal-form-row portal-form-row2col">
        <label>Special Account (SA)</label>
        <Field
          name={`employer.specialAccount`}
          component={renderCurrencyCustom}
          symbolPrefix={getSymbolFromCode("SGD")}
        />
        <Field
          name={`employee.specialAccount`}
          component={renderCurrencyCustom}
          symbolPrefix={getSymbolFromCode("SGD")}
        />
      </div>
      <div className="portal-form-row portal-form-row2col">
        <label>MediSave</label>
        <Field
          name={`employer.mediSave`}
          component={renderCurrencyCustom}
          symbolPrefix={getSymbolFromCode("SGD")}
        />
        <Field
          name={`employee.mediSave`}
          component={renderCurrencyCustom}
          symbolPrefix={getSymbolFromCode("SGD")}
        />
      </div>
      <div className="portal-form-row portal-form-row2col">
        <label>Retirement Account</label>
        <Field
          name={`employer.retirementAccount`}
          component={renderCurrencyCustom}
          symbolPrefix={getSymbolFromCode("SGD")}
        />
        <Field
          name={`employee.retirementAccount`}
          component={renderCurrencyCustom}
          symbolPrefix={getSymbolFromCode("SGD")}
        />
      </div>
      <div className="portal-form-row portal-form-row2col">
        <label>CPF Life</label>
        <Field
          name={`employer.cpfLife`}
          component={renderCurrencyCustom}
          symbolPrefix={getSymbolFromCode("SGD")}
        />
        <Field
          name={`employee.cpfLife`}
          component={renderCurrencyCustom}
          symbolPrefix={getSymbolFromCode("SGD")}
        />
      </div>
      <div className="portal-form-row portal-form-row2col">
        <label>Integrated Shield</label>
        <Field
          name={`employer.integratedShield`}
          component={renderCurrencyCustom}
          symbolPrefix={getSymbolFromCode("SGD")}
        />
        <Field
          name={`employee.integratedShield`}
          component={renderCurrencyCustom}
          symbolPrefix={getSymbolFromCode("SGD")}
        />
      </div>
    </React.Fragment>
  )
}

function mapStateToProps(state) {
  // const {
  //     client,
  //     isJoint,
  //     partner,
  // } = state.netWorth;
  const { client, partner, hasPartner: isJoint } = state.entity

  const { optionsData } = state.enumOptions
  return {
    client,
    partner,
    isJoint,
    optionsData
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, netWorthFormActions), dispatch)
}

CentralProvidenceVoluntaryContributions = connect(
  mapStateToProps,
  mapDispatchToProps
)(CentralProvidenceVoluntaryContributions)

export default CentralProvidenceVoluntaryContributions
