

import { EntityTypeEnum } from '../constants/Enums';
import api from '../lib/api';
import Alert from "react-s-alert";
const BASE_ROUTE = '/notification/';

class NotificationApi {
    static getNotificatioMode() {
        return api.get(`${BASE_ROUTE}status`);
    }

    static getNotificationList() {
        return api.get(`${BASE_ROUTE}`);
    }

    static acknowledgeUserNotification(id) {
        return api.post(`${BASE_ROUTE}user-notification/${id}`);
    }

    static deleteAllMyUserNotifications() {
        return api.delete(`${BASE_ROUTE}user-notification/mine`);
    }

    static processApprovalRequest(data) {
        return api.post(`${BASE_ROUTE}approval`, data);
    }

    static checkAndDisplayWarning() {
        let result = false;
        let loginEntityType = localStorage.getItem('loginEntityType');
        let notificationMode = localStorage.getItem('notificationMode')=='true';
        if(notificationMode && (loginEntityType==EntityTypeEnum.EntityTypeEnum_CLIENT ||loginEntityType==EntityTypeEnum.EntityTypeEnum_BUILT_IN_ADVISER)){
            result = true;
            Alert.warning('Your save / update will not directly change the data! the approval-request has been sent to dealership, please monitor status regularly') ;
        }
        return result;

    }
}

export default NotificationApi;
