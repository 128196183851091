/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    File : CashFlowHeaderListing
    Description : header for cashflow section (total section)
*/

import React from "react"
import { Link } from "react-router-dom"
import { MalabarMoneyCashflow } from "../../components"
import { getSymbolFromCode } from "../../helpers"

const CashFlowHeaderListingMockup = ({
  title,
  isJointHide,
  varPrefix,
  clientTotalValue,
  partnerTotalValue,
  jointTotalValue,
  currency = "AUD",
  isTotal = false,
  detailUrl = ""
}) => (
  <React.Fragment>
    <div className="portal-form-row portal-form-row-goal2col goals-text">
      {title && (
        <label className="no-toppadding">
          <div className="header-button-plus subtitle2 ">
            {!isTotal ? (
              <Link to={detailUrl}>
                {title}
                <i className="fa fa-angle-right CashFlowHeaderListing" />
              </Link>
            ) : (
              title
            )}
          </div>
        </label>
      )}

      <div className="row-holder">
        <MalabarMoneyCashflow
          value={{
            currency,
            value: clientTotalValue
          }}
          name={`${varPrefix}.totalClient`}
          isLabel={false}
          isForGrid
          required={false}
          isRounded
          disabled
          symbolPrefix={getSymbolFromCode(currency)}
          isTotal={isTotal}
        />
      </div>
      {!isJointHide && (
        <React.Fragment>
          <div className="row-holder">
            <MalabarMoneyCashflow
              value={{
                currency,
                value: partnerTotalValue
              }}
              name={`${varPrefix}.totalPartner`}
              isLabel={false}
              isForGrid
              required={false}
              isRounded
              disabled
              symbolPrefix={getSymbolFromCode(currency)}
              isTotal={isTotal}
            />
          </div>
          <div className="row-holder">
            <MalabarMoneyCashflow
              value={{
                currency,
                value: jointTotalValue
              }}
              name={`${varPrefix}.totalJoint`}
              isLabel={false}
              isForGrid
              required={false}
              isRounded
              disabled
              symbolPrefix={getSymbolFromCode(currency)}
              isTotal={isTotal}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  </React.Fragment>
)

export default CashFlowHeaderListingMockup
