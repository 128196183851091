import React from "react"
import moment from "moment"
import ReportingCurrency from "./../../../components/ReportingCurrency/ReportingCurrency"
import ClassificationSelector from "./../../../components/ClassificationSelector/index"

function CurrentStandingHeader({
  title,
  reportingCurrency,
  onChangeCurrency,
  onChangeClassification,
  client,
  partner,
  show,
  refreshChart,
  insurance
}) {
  const perform = (a) => {
    if (refreshChart) {
      refreshChart(a)
    }
  }
  return (
    <React.Fragment>
      <ClassificationSelector
        title={title}
        isJoint={partner}
        client={client}
        partner={partner}
        changeHandler={onChangeClassification}
        show={show}
        insurance
      />
      <div className="content-wrap--spacebetween pt-6 pb-20">
        <ReportingCurrency
          onChange={onChangeCurrency}
          defaultCurrency={reportingCurrency}
          currentCurrency={reportingCurrency}
        />
        <div className="cs-date pr-13">
          <span> {moment().format("DD MMMM, YYYY")}</span>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CurrentStandingHeader
