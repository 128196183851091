/*
    Author : Gredy (gredy.prasetya@devstack.com.au)
    Library : InsuranceApi
    Description : methods related with api part
*/

import api from "../lib/api"

// change base route for different entity/domain here
const BASE_ROUTE = "/client"

class InsuranceApi {
  static saveInsuranceRemarks(clientId, model) {
    return api.post(`${BASE_ROUTE}/${clientId}/insurance/save-remarks`, model)
  }

  static getInsuranceSummary(clientId, currency, classification) {
    return api.get(
      `${BASE_ROUTE}/${clientId}/insurance_summary?reportingCurrency=${currency}${
        classification ? `&classification=${classification}` : ""
      }`
    )
  }

  static bundle(clientId, currency) {
    return api.get(
      `/current_standing/${clientId}/insurance?reportingCurrency=${currency}`
    )
  }

  static personal(clientId, currency) {
    return api.get(
      `/current_standing/${clientId}/insurance/PERSONAL?reportingCurrency=${currency}`
    )
  }

  static general(clientId, currency) {
    return api.get(
      `/current_standing/${clientId}/insurance/GENERAL?reportingCurrency=${currency}`
    )
  }

  static investment(clientId, currency) {
    return api.get(
      `/current_standing/${clientId}/insurance/INVESTMENT?reportingCurrency=${currency}`
    )
  }
  static health(clientId, currency) {
    return api.get(
      `/current_standing/${clientId}/insurance/HEALTH?reportingCurrency=${currency}`
    )
  }
  static personalInsuranceWithoutScenario(data) {
    return api.post(`/lifestyle/insurance-without-scenario`, data)
  }
  static personalInsuranceWithScenario(data) {
    return api.post(`/lifestyle/insurance`, data)
  }
}

export default InsuranceApi
