import { scenariosActions } from "../../constants/ActionConstants"
const initialState = {
  loading: false,
  error: "",
  scenariosData: null,
  selectedScenario: null
}
export function scenariosReducer(state = { ...initialState }, action) {
  switch (action.type) {
    case scenariosActions.GET_SCENARIO_REQUEST:
      return {
        loading: true
      }
    case scenariosActions.GET_SCENARIO_SUCCESS:
      return {
        ...state,
        loading: false,
        scenariosData: action.payload
      }
    case scenariosActions.GET_SCENARIO_FAILURE:
      return {
        loading: false,
        error: action.error
      }
    case scenariosActions.SET_ACTIVE_SCENARIO:
      return {
        ...state,
        loading: false,
        selectedScenario: action.payload
      }
    case scenariosActions.RESET_ACTIVE_SCENARIO:
      return {
        ...state,
        loading: false,
        selectedScenario: null
      }
    default:
      return state
  }
}
