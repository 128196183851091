import React from "react"
import intl from "react-intl-universal"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  netWorthActions,
  netWorthFormActions,
  modalWindowActions
} from "../../actions"
import NetWorthListingGroup, {
  NetWorthListingGroupPropsComposer
} from "./NetWorthListingGroup"
import { NetWorthPath } from "./NetWorthForm"
import { modalWindow } from "../../constants/ModalWindowLists"
import MandatoryProvidentFund from "./Retirements/Hongkong/MandatoryProvidenceFund"
import SuperannuationFundNew from "./Retirements/Australia/SuperannuationFundNew"
import EmployeeProvidenceFund from "./Retirements/Malaysia/EmployeeProvidenceFund"
import CentralProvidenceFundInformation from "./CentralProvidenceFundInformation/CentralProvidenceFundInformation"
import SuplementaryRetirementSchemeInformation from "./SuplementaryRetirementScheme/SuplementaryRetirementSchemeInformation"
import PensionInformation from "./PensionAustralia/PensionInformation"
import PrivateRetirementScheme from "./Retirements/Malaysia/PrivateRetirementScheme"

class RetirementAssets extends NetWorthListingGroup {
  constructor(props) {
    const { getRetirementAssetsListing } = props
    const listingDefinition = [
      {
        title: intl.get("netWorth.mandatoryProvidentFunds"),
        path: NetWorthPath.mandatoryProvidentFunds,
        modalName: modalWindow.RETIREMENT_SCHEME_MPF
      },
      // {
      //   title: intl.get("netWorth.occupationalRetirementSchemeOrdinances"),
      //   path: NetWorthPath.occupationalRetirementSchemeOrdinances,
      //   modalName: modalWindow.RETIREMENT_SCHEME_ORSO
      // },
      {
        title: intl.get("netWorth.superannuationFunds"),
        path: NetWorthPath.superannuationFunds,
        modalName: modalWindow.SUPERANNUATION_FUND
      },
   
      {
        title: intl.get("netWorth.employeeProvidenceFund"),
        path: NetWorthPath.employeeProvidenceFunds,
        modalName: modalWindow.EMPLOYEE_PROVIDENCE_FUND
      },
      //   {
      //     title: intl.get("netWorth.centralProvidenceFundInformation"),
      //     // TODO: UPDATE WHEN THE API IS READY
      //     // path: NetWorthPath.centralProvidenceFundInformation,
      //     path: NetWorthPath.centralProvidenceFundInformation,
      //     modalName: modalWindow.CENTRAL_PROVIDENCE_FUND_INFORMATION
      //   },
      //   {
      //     title: intl.get("netWorth.suplementaryRetirementSchemeInformation"),
      //     // TODO: UPDATE WHEN THE API IS READY
      //     // path: NetWorthPath.centralProvidenceFundInformation,
      //     path: NetWorthPath.suplementaryRetirementSchemeInformation,
      //     modalName: modalWindow.SUPLEMENTARY_RETIREMENT_SCHEME_INFORMATION
      //   },
      //   {
      //     title: intl.get("netWorth.pensionAustralia"),
      //     // TODO: UPDATE WHEN THE API IS READY
      //     // path: NetWorthPath.centralProvidenceFundInformation,
      //     path: NetWorthPath.pensionInformation,
      //     modalName: modalWindow.PENSION_INFORMATION
      //   }
      {
          title: intl.get('netWorth.privateRetirementScheme'),
          path: NetWorthPath.privateRetirementSchemes,
          modalName: modalWindow.PRIVATE_RETIREMENT_SCHEME,
      },
      // {
      //     title: intl.get('netWorth.malaysianOtherPensionFund'),
      //     path: NetWorthPath.malaysianOtherPensionFunds,
      //     modalName: modalWindow.MALAYSIAN_OTHER_PENSION_FUND,
      // },
    ]
    let filterdListingDefs =
      process.env.REACT_APP_NODE_ENV !== "production"
        ? [
            ...listingDefinition,
            {
              title: intl.get("netWorth.centralProvidenceFundInformation"),
              // TODO: UPDATE WHEN THE API IS READY
              // path: NetWorthPath.centralProvidenceFundInformation,
              path: NetWorthPath.centralProvidenceFundInformation,
              modalName: modalWindow.CENTRAL_PROVIDENCE_FUND_INFORMATION
            },
            {
              title: intl.get(
                "netWorth.suplementaryRetirementSchemeInformation"
              ),
              // TODO: UPDATE WHEN THE API IS READY
              // path: NetWorthPath.centralProvidenceFundInformation,
              path: NetWorthPath.suplementaryRetirementSchemeInformation,
              modalName: modalWindow.SUPLEMENTARY_RETIREMENT_SCHEME_INFORMATION
            },
            {
              title: intl.get("netWorth.pensionAustralia"),
              // TODO: UPDATE WHEN THE API IS READY
              // path: NetWorthPath.centralProvidenceFundInformation,
              path: NetWorthPath.pensionInformation,
              modalName: modalWindow.PENSION_INFORMATION
            }
          ]
        : listingDefinition
    super(
      props,
      "retirementAssets",
      getRetirementAssetsListing,
      filterdListingDefs
    )
  }

  render() {
    const { optionsData, currency, client, partner, isJoint } = this.props

    const { contentWrapper } = this

    return contentWrapper(
      <React.Fragment>
        <MandatoryProvidentFund
          optionsData={optionsData}
          type="MandatoryProvidentFund"
          currency={currency}
        />
        {/* <RetirementScheme
          optionsData={optionsData}
          type="OccupationalRetirementSchemeOrdinance"
          currency={currency}
        /> */}
        <SuperannuationFundNew
          optionsData={optionsData}
          isJoint={this.props.isJoint}
          currency={currency}
        />
      
        <EmployeeProvidenceFund
          optionsData={optionsData}
          currency={currency}
          clientInformation={{ client, partner, isJoint }}
        />

        <PrivateRetirementScheme
          optionsData={optionsData}
          currency={currency}
          client1={client}
        />

        {process.env.REACT_APP_NODE_ENV !== "production" && (
          <>
            <SuplementaryRetirementSchemeInformation currency={currency} />
            <PensionInformation currency={currency} />
            <CentralProvidenceFundInformation currency={currency} />
          </>
        )}
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { retirementAssetsListing } = state.netWorth
  return NetWorthListingGroupPropsComposer(state, retirementAssetsListing)
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, netWorthActions, netWorthFormActions, modalWindowActions),
    dispatch
  )
}

RetirementAssets = connect(
  mapStateToProps,
  mapDispatchToProps
)(RetirementAssets)

export default RetirementAssets
