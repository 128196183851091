import { fundAllocationDetailAction } from '../../../constants/ActionConstants';

export function fundAllocationDetail(state = {}, action) {
    switch (action.type) {
        case fundAllocationDetailAction.GET_FUNDALLOCATIONDETAIL_REQUEST:
            return {
                loading: true
            };
        case fundAllocationDetailAction.GET_FUNDALLOCATIONDETAIL_SUCCESS:
            return {
                items: action.data
            };
        case fundAllocationDetailAction.GET_FUNDALLOCATIONDETAIL_FAILURE:
            return {
                error: action.error
            };
        case fundAllocationDetailAction.ADD_FUNDALLOCATIONDETAIL_REQUEST:
            return {
                loading: true
            };
        case fundAllocationDetailAction.ADD_FUNDALLOCATIONDETAIL_SUCCESS:
            return {
                items: action.response
            };
        case fundAllocationDetailAction.ADD_FUNDALLOCATIONDETAIL_FAILURE:
            return {
                error: action.error
            };
        case fundAllocationDetailAction.EDIT_FUNDALLOCATIONDETAIL_REQUEST:
            return {
                loading: true
            };
        case fundAllocationDetailAction.EDIT_FUNDALLOCATIONDETAIL_SUCCESS:
            return {
                items: action.response
            };
        case fundAllocationDetailAction.EDIT_FUNDALLOCATIONDETAIL_FAILURE:
            return {
                error: action.error
            };
        case fundAllocationDetailAction.DELETE_FUNDALLOCATIONDETAIL_REQUEST:
            return {
                loading: true
            };
        case fundAllocationDetailAction.DELETE_FUNDALLOCATIONDETAIL_SUCCESS:
            return {
                items: action.response
            };
        case fundAllocationDetailAction.DELETE_FUNDALLOCATIONDETAIL_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}