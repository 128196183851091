import axios from "axios"
import decode from "jwt-decode"
import Alert from "react-s-alert"
import { history } from "../helpers"
import {
  beginApiCall,
  endApiCall,
  showApiError
} from "../actions/LoaderActions"

import { clearAllLocalStorage, logout } from "../helpers/SessionHelper"
import StoreApp from "../store"

const secured = JSON.parse(localStorage.getItem("user")) // user is the object for storing the user data after logged-in
const baseRoute = localStorage.getItem("base-route") // base route for multiple region
export const BASE_URL = baseRoute //API_URL + VERSION_URL;

const isTokenExpired = () => {
  try {
    const decoded = decode(secured.token)

    if (decoded.exp < Date.now() / 1000) {
      return true
    }
    return false
  } catch (error) {
    return false
  }
}

const customAxiosInstance = axios.create()
customAxiosInstance.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    StoreApp.dispatch(beginApiCall())
    return config
  },
  (error) => {
    console.log("error is", error)
    console.log("interceptor request :" + error)
    StoreApp.dispatch(endApiCall())
    StoreApp.dispatch(showApiError(error))
    return Promise.reject(error)
  }
)

// Add a response interceptor
customAxiosInstance.interceptors.response.use(
  (response) => {
    // Do something with response data
    StoreApp.dispatch(endApiCall())
    return response
  },
  (error) => {
    console.log("error :", error)
    if (error.status) {
      console.log("error.status :", error.status)
    }
    if (error.message) {
      console.log("error.message :", error.message)
    }
    if (error.response) {
      if (error.response.status) {
        console.log("error.response.status :", error.response.status)
        if (error.response.message) {
          console.info("error.response.message :", error.response.message)
        }

        let httpStatusCode = error.response.status
        if (httpStatusCode >= 400) {
          if (httpStatusCode == 403) {
            logout()
          }
          StoreApp.dispatch(showApiError(error))
        } else if (httpStatusCode >= 500) {
          let errMsg = httpStatusCode
          if (error.response.message) {
            errMsg = errMsg + " - " + error.response.message
          }
          history.push(`/undermaintenance/${errMsg}`)
        }
      }
    } else {
      if (error.status) {
        Alert.error(error.status)
      } else {
        history.push(`/undermaintenance/${error.message}`)
      }
    }

    StoreApp.dispatch(endApiCall())
    return Promise.reject(error)
  }
)

const expired = isTokenExpired()
if (secured != null && !expired) {
  // Add token bearer for authenticated request
  // Api.axiosInstance.defaults.headers.common.Authorization = secured.token;
  customAxiosInstance.defaults.headers.common["Authorization"] = secured.token
  // Api.axiosInstance.defaults.headers.setHeader('Authorization', secured.token);
  // Add a request interceptor
} else if (expired) {
  const LOGIN_URL =
    process.env.REACT_APP_BASE_LOGIN_URL + process.env.REACT_APP_LOGIN
  let user = JSON.parse(localStorage.getItem("user"))
  localStorage.setItem("base-route", LOGIN_URL)
  customAxiosInstance
    .post(`${LOGIN_URL}/auth/logout`, {
      loginId: user.loginId,
      logoutType: "TIMEOUT"
    })
    .then(() => {
      clearAllLocalStorage()
      localStorage.setItem("base-route", LOGIN_URL)
    })
}

const Api = {
  post: (url, data = null, config = null) => {
    return customAxiosInstance.post(
      localStorage.getItem("base-route") + url,
      data,
      config
    )
  },

  put: (url, data, config = null) => {
    return customAxiosInstance.put(
      localStorage.getItem("base-route") + url,
      data,
      config
    )
  },
  delete: (url, config = null) => {
    return customAxiosInstance.delete(
      localStorage.getItem("base-route") + url,
      config
    )
  },
  get: (url, config = null) => {
    return customAxiosInstance.get(
      localStorage.getItem("base-route") + url,
      config
    )
  },
  token: (token) => {
    customAxiosInstance.defaults.headers.common["Authorization"] = token
  }
}

export default Api
