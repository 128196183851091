import React, { useState, useEffect } from "react"
import intl from "react-intl-universal"
import { Field, Form, FormSpy } from "react-final-form"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { FormButtons, ModalNoAccess } from "../components"
import RenderSimpleFields, {
  FieldType
} from "../components/Form/RenderSimpleFields"
import ClassificationSelection from "../components/_hook/ClassificationSelection"
import GeneralLoanDetails from "./sub-forms/GeneralLoanDetails"
import { fileFieldRender, getSymbolFromCode } from "../helpers"
import { getCurrency } from "../helpers/CurrencyConversion"
import AssetRiskClassificationApi from "../api/AssetRiskClassificationApi"
import { NetWorthApi } from "../api"
function StockbrokerAndAccount({ isShowStockbrokerAndAccount }) {
  if (!isShowStockbrokerAndAccount) {
    return <></>
  }
  return (
    <RenderSimpleFields
      fields={[
        {
          name: "stockbrokerName",
          label: intl.get("field.stockbrokerName"),
          type: FieldType.TEXT
        },
        {
          name: "stockbrokerAccountName",
          label: intl.get("field.stockbrokerAccountNumber"),
          type: FieldType.TEXT
        }
      ]}
    />
  )
}
function SidePane({
  isShowSidePane,
  optionsData,
  showMortgageAssociatedCharges,
  defaultCurrency = "MYR",
  values
}) {
  if (!isShowSidePane) {
    return <></>
  }
  return (
    <div className="portal-form-half">
      <Tabs>
        <TabList>
          <Tab>{intl.get("tabs.marginLoanDetails")}</Tab>
        </TabList>
        <TabPanel>
          <GeneralLoanDetails
            optionsData={optionsData}
            showMortgageAssociatedCharges={showMortgageAssociatedCharges}
            objectName="marginLoan"
            defaultCurrency={defaultCurrency}
            values={
              values.marginLoan || {
                borrowingsCurrency: defaultCurrency
              }
            }
          />
        </TabPanel>
      </Tabs>
    </div>
  )
}

function MainForm({
  onPurchasePriceChange,
  clientInformation: { client, partner = {}, isJoint },
  optionsData,
  values,
  defaultCurrency = "MYR",
  setFormAttribute
}) {
  const { taxResidency = "" } = client
  const taxRecidencyIsAustralia =
    taxResidency.toLowerCase() === "australia" ||
    taxResidency.toLowerCase() === "au"
  const {
    financedThruLoan: isShowSidePane,
    hasStockbrokerAndAccount: isShowStockbrokerAndAccount
  } = values
  const [currency, setCurrency] = useState(
    values.tradingCurrency ? values.tradingCurrency : defaultCurrency
  )
  const [cashAccounts, setCashAccounts] = useState([])
  const [productTypes, setProductTypes] = useState([])
  useEffect(() => {
    let clientId = localStorage.getItem("activeClientId")
    NetWorthApi.getClientCashAccounts(clientId).then(({ data }) => {
      setCashAccounts(data)
    })
    AssetRiskClassificationApi.getProductTypes("SHARE").then(({ data }) => {
      if (data) {
        setProductTypes(data.data)
      }
    })
  }, [])
  function getCashOptions() {
    let result = []
    if (cashAccounts) {
      cashAccounts.forEach((c) => {
        result.push(
          <option key={`enum-${c.id}`} value={c.id}>
            {c.accountNumber ? c.accountNumber : c.accountName}{" "}
            {c.primary ? "(primary)" : ""}
          </option>
        )
      })
    }
    return result
  }

  const fields = [
    {
      name: "shareName",
      label: intl.get("field.uniqueCode"),
      type: FieldType.TEXT,
      required: true
    },
    {
      name: "hinSrnNumber",
      label: intl.get("field.hinSrnNumber"),
      type: FieldType.TEXT
    },
    {
      name: "tradingExchangeCountry",
      type: FieldType.SELECTION,
      label: intl.get("field.tradingExchangeCountry"),
      selectionData: optionsData.countryOptions,
      valueProperty: "code",
      labelProperty: "name"
    },
    {
      name: "productTypeCode",
      label: intl.get("field.productType"),
      type: FieldType.SELECTION,
      selectionData: productTypes,
      valueProperty: "code",
      labelProperty: "name",
      required: true
    },
    {
      name: "managedBy",
      label: intl.get("field.managedBy"),
      type: FieldType.ENUM_SELECTION,
      enumData: optionsData.managedByOptions,
      required: false
    },
    {
      name: "targetCashId",
      label: intl.get("field.settlementCash"),
      type: FieldType.ENUM_SELECTION,
      enumData: getCashOptions(),
      required: true
    },
    {
      name: "tickerCode",
      label: intl.get("field.tickerCode"),
      type: FieldType.TEXT,
      maxLength: 5,
      required: true
    },
    {
      name: "exchangeIsoCode",
      label: intl.get("field.exchangeIsoCode"),
      type: FieldType.TEXT
    },
    {
      name: "datePurchased",
      label: intl.get(`field.datePurchased`),
      type: FieldType.DATE
    },
    {
      name: "numberOfUnitsHeld",
      label: intl.get("field.numberOfSharesHeld"),
      type: FieldType.NUMBER,
      required: true
    },
    {
      name: "tradingCurrency",
      label: intl.get("field.tradingCurrency"),
      type: FieldType.ENUM_SELECTION,
      enumData: optionsData.currencyOptions,
      onChange: (val) => setCurrency(val)
    },
    {
      name: "purchasePrice",
      label: intl.get("field.purchasePrice"),
      type: FieldType.MONEY,
      className: "portal-form-row popup-purchaseprice",
      isMoney: true,
      symbolPrefix: getSymbolFromCode(currency),
      allowNegative: false
    },
    {
      name: "currentValue",
      type: FieldType.MONEY,
      label: intl.get("field.currentValue"),
      required: true,
      isMoney: true,
      symbolPrefix: getSymbolFromCode(currency),
      allowNegative: false
    },
    {
      name: "valuationDate",
      label: intl.get("field.valuationDate"),
      type: FieldType.DATE,
      required: true
    },

    {
      name: "annualGrowthRate",
      label: intl.get("field.annualGrowthRate"),
      type: FieldType.PERCENTAGE,
      required: true
    },
    {
      name: "annualDistributionYield",
      label: intl.get("field.annualDividendYield"),
      type: FieldType.PERCENTAGE
    },
    {
      name: "distributionFrequency",
      type: FieldType.ENUM_SELECTION,
      label: intl.get("field.dividendFrequency"),
      enumData: optionsData.frequencyOptions
    },
    {
      name: "dividendAmount",
      label: intl.get("field.dividendValue"),
      type: FieldType.MONEY,
      isMoney: false,
      defaultCurrency: currency,
      symbolPrefix: getSymbolFromCode(currency)
    },
    {
      name: "lastDistributionPaymentDate",
      label: intl.get("field.lastDividendRepaymentDate"),
      type: FieldType.DATE
    },
    {
      name: "brokageFee",
      type: FieldType.MONEY_WITH_PERCENTAGE,
      label: intl.get("field.brokageFee"),
      getPercentageValueSource: () => (values.purchasePrice || {}).value || 0,
      currency: currency,
      symbolPrefix: getSymbolFromCode(currency),
      allowNegative: false
    },

    {
      name: "financedThruLoan",
      label: intl.get("field.investmentUnderLoan"),
      checkboxLabel: intl.get("YesNoEnum_YES"),
      type: FieldType.CHECKBOX
    },
    {
      name: "hasStockbrokerAndAccount",
      label: intl.get("field.hasStockbrokerAndAccount"),
      checkboxLabel: intl.get("YesNoEnum_YES"),
      type: FieldType.CHECKBOX
    }
  ]

  if (taxRecidencyIsAustralia) {
    fields.splice(
      fields.findIndex(
        (item) => item.name === "lastDistributionPaymentDate" // put before lastDistributionPaymentDate
      ),
      0,
      {
        name: "frankingPercent",
        label: intl.get("field.franking"),
        type: FieldType.PERCENTAGE
      }
    )
  }

  return (
    <div className="portal-form-popup">
      <div className={isShowSidePane ? "portal-form-half" : ""}>
        <ClassificationSelection {...{ client, partner, isJoint }} />
        <div className="subtitle2">{intl.get("shares.subtitle")}</div>
        <RenderSimpleFields fields={fields} />
        <StockbrokerAndAccount
          isShowStockbrokerAndAccount={isShowStockbrokerAndAccount}
        />
        <Field
          component={fileFieldRender}
          customClassName="mt-1 d-block"
          labelIdle="Drag, Drop or Browse"
          label="Upload Documents"
          onupdatefiles={(fileItems) => {
            setFormAttribute("proofDocument", fileItems[0])
          }}
          fileInformation={(values && values.proofDocument) || null}
        />
      </div>
      <SidePane
        isShowSidePane={isShowSidePane}
        optionsData={optionsData}
        showMortgageAssociatedCharges={taxRecidencyIsAustralia}
        defaultCurrency={currency}
        values={values}
      />
    </div>
  )
}

function ShareForm({
  handleSave,
  model = { marginLoan: { borrowingsCurrency: "MYR" } },
  optionsData,
  readOnlyAccess,
  clientInformation,
  defaultCurrency,
  subFormModel
}) {
  if (readOnlyAccess) {
    return <ModalNoAccess />
  }

  model = subFormModel ? subFormModel : model

  if (!model.marginLoan) {
    model.marginLoan = null
  }

  if (!model.valuationDate) {
    model.valuationDate = new Date()
  }

  if (!model.purchasePrice) {
    model.purchasePrice = { currency: defaultCurrency, value: 0 }
  }
  if (!model.currentValue) {
    model.currentValue = { currency: defaultCurrency, value: 0 }
  }

  if (!model.dividendAmount) {
    model.dividentAmount = { currency: defaultCurrency, value: 0 }
  }
  if (!model.distributionFrequency) {
    model.distributionFrequency = "YEARLY"
  }

  // if (model.annualGrowthRate == 0) {
  //     model.annualGrowthRate = "0";
  // }

  // if (model.annualTotalReturn == 0) {
  //     model.annualTotalReturn = "0";
  // }
  return (
    <Form
      onSubmit={handleSave}
      initialValues={model}
      mutators={{
        setFormAttribute: ([fieldName, fieldVal], state, { changeValue }) => {
          changeValue(state, fieldName, () => fieldVal)
        }
      }}
      render={({ handleSubmit, reset, mutators: { setFormAttribute } }) => (
        <form id="shareForm" onSubmit={handleSubmit}>
          <FormSpy subscription={{ values: true }}>
            {({ values }) => (
              <>
                <MainForm
                  clientInformation={clientInformation}
                  optionsData={optionsData}
                  values={values}
                  defaultCurrency={
                    defaultCurrency ||
                    getCurrency(clientInformation.client.taxResidency)
                  }
                  setFormAttribute={setFormAttribute}
                />
              </>
            )}
          </FormSpy>
          <FormButtons reset={reset} readOnlyAccess={readOnlyAccess} />
        </form>
      )}
    />
  )
}

export default ShareForm
