/*
    Author : Hendri Firmana (hendri.firmana@devstack.com.au)
             Anova Fawzi (anovafawzi@gmail.com) - major rework for cashflow demo
    Description : Additional input on react array
*/

import React from "react"
import { Field } from "react-final-form"
import { FieldArray } from "react-final-form-arrays"
import {
  renderCurrencyCashflow,
  renderPercentageCustom,
  renderTextBoxCustom,
  renderSelectEnumFieldCustom,
  renderTextOnlyCustom,
  getSymbolFromCode
} from "../../../../helpers"
import api from "../../../../lib/api"
import { useDispatch } from "react-redux"
import { netWorthFormActions } from "../../../../actions"

const AdditionalArrayInput = ({
  arrayName,
  AdditionalInput,
  isAdditionalMoreInput,
  isAdditionalInputWithSelect,
  optionsData,
  placeholderLabel,
  placeholderMoreLabel,
  isRoundColorRed = false,
  isJointHide = false,
  isShowItemDescription,
  isShowEditButton,
  onEditClick,
  dataArray,
  onDeleteClick,
  isExternalModal = false,
  currency = "AUD"
}) => {
  const dispatch = useDispatch()

  const handleEditClick = (url) => {
    // get the model
    api.get(url).then((response) => {
      const { data } = response
      dispatch(netWorthFormActions.setNewForm(false, data.classification))

      onEditClick({
        ...data,
        url
      })
    })
  }

  const handleDeleteClick = (url) => {
    api.delete(url).then((response) => {
      onDeleteClick()
    })
  }

  return (
    <FieldArray name={arrayName}>
      {({ fields, values }) =>
        fields.map((name, index) => (
          <div className="portal-form-row portal-form-row-goal2col" key={name}>
            {isAdditionalMoreInput ? (
              isAdditionalInputWithSelect ? (
                <label className="no-toppadding seltext2col">
                  <Field
                    name="currency"
                    component={renderSelectEnumFieldCustom}
                    isLabel={false}
                    required={false}
                    enumData={optionsData}
                    isNotUpperDiv
                    disabled={isExternalModal}
                  />

                  <Field
                    name="description"
                    type="text"
                    component={renderTextBoxCustom}
                    label={placeholderLabel}
                    isNotUpperDiv
                    disabled={isExternalModal}
                  />
                </label>
              ) : (
                <label className="no-toppadding text2col">
                  <Field
                    name="test1"
                    type="text"
                    component={renderTextBoxCustom}
                    label={placeholderMoreLabel.split(",")[0]}
                    isNotUpperDiv
                    disabled={isExternalModal}
                  />
                  <Field
                    name="test2"
                    type="text"
                    component={renderTextBoxCustom}
                    label={placeholderMoreLabel.split(",")[1]}
                    isNotUpperDiv
                    disabled={isExternalModal}
                  />
                </label>
              )
            ) : AdditionalInput ? (
              <label className="no-toppadding">
                <Field
                  name={`${name}.description`}
                  component={renderTextBoxCustom}
                  type="text"
                  isLabel={false}
                  isForGrid
                  required
                  label={placeholderLabel}
                  disabled={isExternalModal}
                />
              </label>
            ) : (
              <label>
                <div className="goals-title">
                  {isShowItemDescription && (
                    <Field
                      name={`${name}.description`}
                      component={renderTextOnlyCustom}
                      disabled={isExternalModal}
                    />
                  )}
                </div>
                {isShowEditButton && (
                  <span
                    className="btn btn-edit"
                    onClick={() => handleEditClick(dataArray[index]._self)}
                  />
                )}
              </label>
            )}
            <div className="row-holder">
              <Field
                name={`${name}.client`}
                component={renderCurrencyCashflow}
                isLabel={false}
                isForGrid
                required={false}
                isRounded
                placeholderLabel={"NZD"}
                currency={currency}
                symbolPrefix={getSymbolFromCode(currency)}
                disabled={isExternalModal}
                isfixedDecimalScale={false}
              />
            </div>
            {!isJointHide && (
              <div className="row-holder">
                <Field
                  name={`${name}.partner`}
                  component={renderCurrencyCashflow}
                  isfixedDecimalScale={false}
                  isLabel={false}
                  isForGrid
                  required={false}
                  isRounded
                  placeholderLabel={"NZD"}
                  currency={currency}
                  symbolPrefix={getSymbolFromCode(currency)}
                  disabled={isExternalModal}
                />
              </div>
            )}

            {!isJointHide && (
              <div className="row-holder">
                <Field
                  name={`${name}.joint`}
                  component={renderCurrencyCashflow}
                  isLabel={false}
                  isForGrid
                  required={false}
                  isRounded
                  currency={currency}
                  lockToCurrency={currency}
                  symbolPrefix={getSymbolFromCode(currency)}
                  disabled={isExternalModal}
                  isfixedDecimalScale={false}
                />
              </div>
            )}

            <div className="row-holder-last">
              <Field
                name={`${name}.deductible`}
                component={renderPercentageCustom}
                isLabel={false}
                isForGrid
                required={false}
                isRounded
                isRoundColorRed={isRoundColorRed}
                placeholderLabel={" %"}
                disabled={isExternalModal}
                isfixedDecimalScale={false}
              />
            </div>
            <button
              type="button"
              className="btn-remove"
              onClick={() => {
                if (onDeleteClick) {
                  handleDeleteClick(dataArray[index]._self)
                }
                fields.remove(index)
              }}
            />
          </div>
        ))
      }
    </FieldArray>
  )
}

export default AdditionalArrayInput
