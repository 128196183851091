/*
    Author : Erik (erik.malik@devstack.com.au)
    Description : Personal Loan main page (modal)
*/

import React from "react";
import { connect } from "react-redux";
import LoanRepaymentAmountCalculation from "../../../services/LoanRepaymentAmountCalculation";
import { DECIMAL_SCALE } from "../../../constants/NumberFormatConstants";

import NumberFormat from "react-number-format";

import { Form, FormSpy } from "react-final-form";
import { bindActionCreators } from "redux";
import intl from "react-intl-universal";
import { netWorthFormActions, modalWindowActions } from "../../../actions";
import { modalWindow } from "../../../constants/ModalWindowLists";
import NetWorthForm, {
    NetWorthPath,
    NetWorthFormPropsComposer
} from "../NetWorthForm";
import NetWorthFormButtons from "../NetWorthFormButtons";
import { nullifyFields } from "../../../helpers/FormUtil";
import { CountryList } from "../../../constants/Lists";
import ClassificationSelection from "../../../components/Form/ClassificationSelection";
import RenderSimpleFields, {
    FieldType
} from "../../../components/Form/RenderSimpleFields";
import { translateListLabel } from "../../../helpers/list-translation";

class LoanForm extends NetWorthForm {
    constructor(props) {
        const { type } = props;
        super(
            props,
            intl.get(`liabilities.${type}.title`),
            type === "personalLoan"
                ? modalWindow.PERSONAL_LOAN_FORM
                : modalWindow.EDUCATION_LOAN_FORM,
            type === "personalLoan"
                ? NetWorthPath.personalLoans
                : NetWorthPath.educationLoans,
            type === "personalLoan" ? "PersonalLoan" : "EducationLoan"
        );

        this.type = type;
    }

    render() {
        const {
            handleSave,
            contentWrapper,
            readOnlyAccessComponent,
            type,
            props
        } = this;
        const { optionsData, model, client } = props;
        const {
            loanInterestTypeOptions,
            currencyOptions,
            frequencyOptions
        } = optionsData;
        const loanRepaymentAndInterest = values => {
            return LoanRepaymentAmountCalculation(values);
        };
        const form = [
            {
                name: "borrowingsAccountNumber",
                type: FieldType.TEXT,
                label: intl.get("field.uniqueCode"),
                required: true
            },
            {
                name: "financialInstitutionName",
                type: FieldType.TEXT,
                label: intl.get(
                    "liabilities.loan.field.financialInstitutionName"
                ),
                required: false
            },
            {
                name: "borrowingsAccountName",
                type: FieldType.TEXT,
                label: intl.get("liabilities.loan.field.borrowingsAccountName"),
                required: false
            },
          
            {
                name: "borrowingsLocation",
                type: FieldType.SELECTION,
                label: intl.get("liabilities.loan.field.borrowingsLocation"),
                selectionData: translateListLabel(CountryList).map(item => ({
                    ...item,
                    value: item.code,
                    label: item.name
                }))
            },
            {
                name: "borrowingsCurrency",
                type: FieldType.ENUM_SELECTION,
                enumData: currencyOptions,
                label: intl.get("liabilities.loan.field.borrowingsCurrency"),
                required: false
            },
            {
                name: "borrowingsPrinciple",
                type: FieldType.MONEY,
                label: intl.get("liabilities.loan.field.borrowingsPrinciple"),
                required: true,
                allowNegative: false
            },
            {
                name: "borrowingsStartDate",
                type: FieldType.DATE,
                label: intl.get("liabilities.loan.field.borrowingsStartDate"),
                required: true
            },
            {
                name: "borrowingsTermInYears",
                type: FieldType.NUMBER,
                label: intl.get('liabilities.loan.field.borrowingsTermInYears'),
                numberFormat: '##',
                required: true,    
            },
            {
                name: "borrowingsAnnualInterestRate",
                type: FieldType.PERCENTAGE,
                label: intl.get(
                    "liabilities.loan.field.borrowingsAnnualInterestRate"
                ),
                required: true
            },
            {
                name: "currentBorrowingsBalance",
                type: FieldType.MONEY,
                label: intl.get(
                    "liabilities.loan.field.currentBorrowingsBalance"
                ),
                required: true,
                allowNegative: false
            },
            {
                name: "valuationDate",
                type: FieldType.DATE,
                label: intl.get("liabilities.loan.field.valuationDate")
            },
            {
                name: "borrowingsRepaymentFrequency",
                type: FieldType.ENUM_SELECTION,
                enumData: frequencyOptions,
                label: intl.get(
                    "liabilities.loan.field.borrowingsRepaymentFrequency"
                ),
                required: true
            },
            // {
            //     name: "borrowingsRepaymentAmount",
            //     type: FieldType.MONEY,
            //     label: intl.get(
            //         "field.loan.loanRepaymentAmount"
            //     ),
            //     required: true,
            //     allowNegative: false,
            //     readOnly: true
            // },
            {
                name: "additionalPaymentAmount",
                type: FieldType.MONEY,
                label: intl.get(
                    "liabilities.loan.field.additionalPaymentAmount"
                ),
                allowNegative: false
            },
            {
                name: "additionalRepaymentHandlingFees",
                type: FieldType.MONEY,
                label: intl.get(
                    "liabilities.loan.field.additionalRepaymentHandlingFees"
                ),
                allowNegative: false
            },
            {
                name: "additionalRepaymentFrequency",
                type: FieldType.ENUM_SELECTION,
                enumData: frequencyOptions,
                label: intl.get(
                    "liabilities.loan.field.additionalRepaymentFrequency"
                )
            },
            {
                name: "additionalRepaymentNextPaymentDate",
                type: FieldType.DATE,
                label: intl.get(
                    "liabilities.loan.field.additionalRepaymentNextPaymentDate"
                )
            }
        ];
        const australiaOnlyForm = [
            {
                name: "borrowingsManagementFees",
                type: FieldType.MONEY,
                label: intl.get(
                    "liabilities.loan.field.borrowingsManagementFees"
                ),
                allowNegative: false
            },
            {
                name: "borrowingsManagementFeesFrequency",
                type: FieldType.ENUM_SELECTION,
                enumData: frequencyOptions,
                label: intl.get(
                    "liabilities.loan.field.borrowingsManagementFeesFrequency"
                )
            },
            {
                name: "borrowingsManagementFeesNextPaymentDate",
                type: FieldType.DATE,
                label: intl.get(
                    "liabilities.loan.field.borrowingsManagementFeesNextPaymentDate"
                )
            }
        ];

        if (client) {
            model.borrowingsLocation =
                model.borrowingsLocation || client.country;
        }

        if (
            client &&
            client.currency &&
            currencyOptions.includes(client.currency)
        ) {
            model.borrowingsCurrency =
                model.borrowingsCurrency || client.currency;
        }
        return contentWrapper(
            <Form
                onSubmit={handleSave}
                initialValues={model}
                render={({ handleSubmit, reset, values }) => (
                    <form id="personalLoanForm" onSubmit={handleSubmit}>
                        <div className="portal-form-popup">
                            <ClassificationSelection />
                            <div className="subtitle2">
                                {intl.get(`liabilities.${type}.subtitle`)}
                            </div>
                            <RenderSimpleFields fields={form} />
                            <div className="portal-form-row">
                                <label className="subtitlelabel">
                                    {intl.get(
                                        "field.loan.loanRepaymentAmountWithInterest"
                                    )}
                                </label>

                                <div className="row-holder">
                                    <NumberFormat
                                        value={loanRepaymentAndInterest(values)}
                                        displayType={"input"}
                                        allowNegative
                                        decimalScale={DECIMAL_SCALE}
                                        // prefix={currency.symbol}
                                        decimalSeparator={"."}
                                        thousandSeparator={","}
                                        className={`text-input currency-input`}
                                        readOnly
                                    />
                                </div>
                            </div>
                            <FormSpy subscription={{ values: true }}>
                                {({ values }) => {
                                    if (
                                        values.borrowingsLocation ===
                                        "Australia"
                                    ) {
                                        return (
                                            <RenderSimpleFields
                                                fields={australiaOnlyForm}
                                            />
                                        );
                                    }

                                    nullifyFields(
                                        values,
                                        australiaOnlyForm.map(item => item.name)
                                    );
                                    return "";
                                }}
                            </FormSpy>
                            <NetWorthFormButtons
                                reset={reset}
                                readOnlyAccess={readOnlyAccessComponent()}
                            />
                        </div>
                    </form>
                )}
            />
        );
    }
}
function mapStateToProps(state, ownProps) {
    const { type } = ownProps;

    return NetWorthFormPropsComposer(
        state,
        type === "personalLoan"
            ? modalWindow.PERSONAL_LOAN_FORM
            : modalWindow.EDUCATION_LOAN_FORM,
        NetWorthPath.personalLoan
    );
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        Object.assign({}, netWorthFormActions, modalWindowActions),
        dispatch
    );
}

LoanForm = connect(mapStateToProps, mapDispatchToProps)(LoanForm);
export default LoanForm;
