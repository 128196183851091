import React from "react"
import intl from "react-intl-universal"
import { Pie } from "react-chartjs-2"
import { DefaultPieChartLegendsOptions } from "../../../helpers/ChartHelper"

function CentralProvidenceFundChart({ chartData }) {
  return (
    <React.Fragment>
      <div className="subtitle2">
        {intl.get("employeeProvidenceFund.epfSummary")}
      </div>
      <div className="graph-holder" style={{ height: "300px", width: "300px" }}>
        <Pie
          data={chartData}
          width={300}
          height={300}
          legend={DefaultPieChartLegendsOptions}
        />
      </div>
    </React.Fragment>
  )
}

export default CentralProvidenceFundChart
