import React from "react"

const PortfolioDetails = ({ model }) => {
  return (
    <div>
      <div className="subtitle2">Portfolio Details</div>
      <div className="portfolio-information-section">
        <div className="info-section">
          <p className="label"> Total Relationship Balance</p>
          <p className="value">
            ${" "}
            {model.totalRelationshipBalance
              ? model.totalRelationshipBalance.toFixed(2)
              : " 0"}
          </p>
        </div>
        <div className="info-section">
          <p className="label"> Total Pending/Transaction Value</p>
          <p className="value">
            $
            {model.totalPendingTransactionValue
              ? model.totalPendingTransactionValue.toFixed(2)
              : " 0"}
          </p>{" "}
        </div>
        <div className="info-section">
          <p className="label"> Total Current Value</p>
          <p className="value">
            ${" "}
            {model.totalCurrentValue
              ? model.totalCurrentValue.toFixed(2)
              : " 0"}
          </p>{" "}
        </div>
        <div className="info-section">
          <p className="label"> Total Cost of Current Holdings</p>
          <p className="value">
            $
            {model.totalCostOfCurrentHolding
              ? model.totalCostOfCurrentHolding.toFixed(2)
              : " 0"}
          </p>{" "}
        </div>
        <div className="info-section">
          <p className="label"> Total Profit/Loss</p>
          <p className="value">
            $ {model.totalProfitLoss ? model.totalProfitLoss.toFixed(2) : " 0"}
          </p>{" "}
        </div>
        <div className="info-section">
          <p className="label"> Total Profit/Loss Since Inception</p>
          <p className="value">
            ${" "}
            {model.totalProfitLossSinceInception
              ? model.totalProfitLossSinceInception.toFixed(2)
              : " 0"}
          </p>{" "}
        </div>
      </div>
    </div>
  )
}

export default PortfolioDetails
