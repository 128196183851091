import React from "react"
import intl from "react-intl-universal"
import { enumOptionsAction } from "../constants/ActionConstants"
import {
  CountryList,
  OperationCountry,
  BusinessLocation,
  BusinessRegistrationType,
  BusinessMarketSector,
  BusinessIndustryGroup
} from "../constants/Lists"
import {
  PhoneTypeEnum,
  IdTypeEnum,
  EmailTypeEnum,
  MaritalStatusEnum,
  SalutationEnum,
  GenderEnum,
  PartyTypeEnum,
  EmploymentStatusEnum,
  DependantTypeEnum,
  ResidencyPropertyOwnershipTypeEnum,
  ResidentialTenureEnum,
  ClientClassificationEnum,
  PropertyTypeEnum,
  QualificationEnum,
  RelationshipTypeEnum,
  ChildrenRelationshipTypeEnum,
  SiblingRelationshipTypeEnum,
  ParentRelationshipTypeEnum,
  SchoolLevelEnum,
  ClientCategoryTypeEnum,
  ReligionTypeEnum,
  ResidencyStatusEnum,
  CashAccountTypeEnum,
  CpfEmploymentClassificationEnum,
  TaxPaymentPreferenceEnum,
  LivingWithEnum,
  LoanInterestTypeEnum,
  FrequencyEnum,
  InvestmentFrequencyEnum,
  LoanFrequencyEnum,
  InsuranceTypeEnum,
  BorrowingsTypeEnum,
  CarFinancingTypeEnum,
  InsurancePolicyTypeEnum,
  CurrencyEnum,
  BondTypeEnum,
  YesNoEnum,
  AccessLevelEnum,
  FeedbackTechnicalIssueEnum,
  FeedbackPrioritySeverityEnum,
  FeedbackStatusEnum,
  EducationAssistanceLoanTypeAUEnum,
  EducationAssistanceLoanTypeSGEnum,
  EducationAssistanceLoanTypeHKEnum,
  EducationAssistanceLoanTypeNZEnum,
  EducationAssistanceLoanTypeMYEnum,
  // InvestmentTimeHorizonEnum0,
  LeaseTypeEnum,
  LoanRepaymentStatusEnum,
  
  HospitalCoverTypeEnum,
  PeopleCoveredEnum,
  PolicyAreaCoveredEnum,
  GeneralClassificationEnum,
  TopUpRedemptionGoalFrequencyEnum,
  BenefitPeriodEnum,
  RaceEnum,
  SourceOfFundEnum,
  PensionContributionTypeEnum,
  SalaryTypeEnum,
  PremiumFrequencyEnum,
  GoldTypeEnum,
  ETFTypeEnum,
  TermDepositRolloverTypeEnum,
  ManagedByEnum
} from "../constants/Enums"

import { translateListLabel } from "../helpers/list-translation"

const getEnumOptions = (enumData) => {
  const enumOptionStr = []
  Object.keys(enumData).forEach((key) => {
    enumOptionStr.push(
      <option key={`enum-${key}`} value={enumData[key]}>
        {intl.get(key)}
      </option>
    )
  })
  return enumOptionStr
}

const getEnumOptionsWithoutTranslation = (enumData) => {
  const enumOptionStr = []
  Object.keys(enumData).forEach((key) => {
    let val = enumData[key];
    enumOptionStr.push(
      <option key={`enum-${key}`} value={val}>
        {val}
      </option>
    )
  })
  return enumOptionStr
}

export function enumOptions(state = {}, action) {
  switch (action.type) {
    case enumOptionsAction.GET_ALL_OPTIONS:
      return {
        optionsData: {
          // list
          countryOptions: translateListLabel(CountryList),
          operationCountryOptions: OperationCountry,
          businessLocationOptions: BusinessLocation,
          businessRegistrationTypeOptions: BusinessRegistrationType,
          businessMarketSectorOptions: BusinessMarketSector,
          businessIndustryGroupOptions: BusinessIndustryGroup,

          // enum
          phoneTypeOptions: getEnumOptions(PhoneTypeEnum),
          idTypeOptions: getEnumOptions(IdTypeEnum),
          emailTypeOptions: getEnumOptions(EmailTypeEnum),
          maritalStatusOptions: getEnumOptions(MaritalStatusEnum),
          salutationOptions: getEnumOptions(SalutationEnum),
          genderOptions: getEnumOptions(GenderEnum),
          raceOptions: getEnumOptions(RaceEnum),
          partyTypeOptions: getEnumOptions(PartyTypeEnum),
          employmentStatusOptions: getEnumOptions(EmploymentStatusEnum),
          dependantTypeOptions: getEnumOptions(DependantTypeEnum),
          residencyPropertyOwnershipTypeOptions: getEnumOptions(
            ResidencyPropertyOwnershipTypeEnum
          ),

          residentialTenureOptions: getEnumOptions(ResidentialTenureEnum),
          clientClassificationOptions: getEnumOptions(ClientClassificationEnum),
          propertyTypeOptions: getEnumOptions(PropertyTypeEnum),
          qualificationOptions: getEnumOptions(QualificationEnum),
          relationshipTypeOptions: getEnumOptions(RelationshipTypeEnum),
          childrenRelationshipTypeOptions: getEnumOptions(
            ChildrenRelationshipTypeEnum
          ),
          siblingRelationshipTypeOptions: getEnumOptions(
            SiblingRelationshipTypeEnum
          ),
          parentRelationshipTypeOptions: getEnumOptions(
            ParentRelationshipTypeEnum
          ),
          schoolLevelOptions: getEnumOptions(SchoolLevelEnum),
          clientCategoryTypeOptions: getEnumOptions(ClientCategoryTypeEnum),
          religionTypeEnum: getEnumOptions(ReligionTypeEnum),
          residencyStatusOptions: getEnumOptions(ResidencyStatusEnum),
          cashAccountTypeOptions: getEnumOptions(CashAccountTypeEnum),
          cpfEmploymentClassificationOptions: getEnumOptions(
            CpfEmploymentClassificationEnum
          ),
          taxPaymentPreferenceOptions: getEnumOptions(TaxPaymentPreferenceEnum),
          livingWithOptions: getEnumOptions(LivingWithEnum),
          loanInterestTypeOptions: getEnumOptions(LoanInterestTypeEnum),
          frequencyOptions: getEnumOptions(FrequencyEnum),
          investmentFrequencyOptions: getEnumOptions(InvestmentFrequencyEnum),
          loanFrequencyOptions: getEnumOptions(LoanFrequencyEnum),
          insuranceTypeOptions: getEnumOptions(InsuranceTypeEnum),
          borrowingsTypeOptions: getEnumOptions(BorrowingsTypeEnum),
          carFinancingTypeOptions: getEnumOptions(CarFinancingTypeEnum),
          currencyOptions: getEnumOptionsWithoutTranslation(CurrencyEnum),
          bondTypeOptions: getEnumOptions(BondTypeEnum),
          yesNoOptions: getEnumOptions(YesNoEnum),
          accessLevelEnum: getEnumOptions(AccessLevelEnum),
          feedbackTechnicalIssueOptions: getEnumOptions(
            FeedbackTechnicalIssueEnum
          ),
          feedbackPrioritySeverityOptions: getEnumOptions(
            FeedbackPrioritySeverityEnum
          ),
          feedbackStatusOptions: getEnumOptions(FeedbackStatusEnum),
          educationAssistanceLoanTypeAUOptions: getEnumOptions(
            EducationAssistanceLoanTypeAUEnum
          ),
          educationAssistanceLoanTypeSGOptions: getEnumOptions(
            EducationAssistanceLoanTypeSGEnum
          ),
          educationAssistanceLoanTypeHKOptions: getEnumOptions(
            EducationAssistanceLoanTypeHKEnum
          ),
          educationAssistanceLoanTypeNZOptions: getEnumOptions(
            EducationAssistanceLoanTypeNZEnum
          ),
          educationAssistanceLoanTypeMYOptions: getEnumOptions(
            EducationAssistanceLoanTypeMYEnum
          ),

          leaseTypeOptions: getEnumOptions(LeaseTypeEnum),
          loanRepaymentStatusOptions: getEnumOptions(LoanRepaymentStatusEnum),
          hospitalCoverTypeOptions: getEnumOptions(HospitalCoverTypeEnum),
          peopleCoveredOptions: getEnumOptions(PeopleCoveredEnum),
          policyAreaCoveredOptions: getEnumOptions(PolicyAreaCoveredEnum),
          generalClassificationOptions: getEnumOptions(
            GeneralClassificationEnum
          ),
          topUpRedemptionGoalFrequencyOptions: getEnumOptions(
            TopUpRedemptionGoalFrequencyEnum
          ),
          benefitPeriodOptions: getEnumOptions(BenefitPeriodEnum),
          sourceOfFundOptions: getEnumOptions(SourceOfFundEnum),
          pensionContributionTypeOptions: getEnumOptions(
            PensionContributionTypeEnum
          ),
          salaryTypeOptions: getEnumOptions(SalaryTypeEnum),
          premiumFrequencyOptions: getEnumOptions(PremiumFrequencyEnum),
          goldTypeOptions: getEnumOptions(GoldTypeEnum),
          etfTypeOptions: getEnumOptions(ETFTypeEnum),
          TermDepositRolloverTypeOptions: getEnumOptions(
            TermDepositRolloverTypeEnum
          ),
          insurancePolicyTypeOptions: getEnumOptions(InsurancePolicyTypeEnum),
          managedByOptions: getEnumOptions(ManagedByEnum)
        }
      }

    default:
      return state
  }
}
