import React, { useContext } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody
} from "react-accessible-accordion";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import intl from "react-intl-universal";
import { renderSelectFieldArrayCustom } from "../../../helpers";
import { CountryList } from "../../../constants/Lists";
import PersonalDetailsContext from "../PersonalDetailsContext";
import { translateListLabel } from "../../../helpers/list-translation";

function PlanningClassificationField({ title, fieldName, isJoint }) {
    const {
        mutators: { arrayPush, arrayRemove }
    } = useContext(PersonalDetailsContext);
    const baseName = "personalDetails";
    const props = {
        component: renderSelectFieldArrayCustom,
        data: translateListLabel(CountryList),
        valueProperty: "code",
        labelProperty: "name"
    };
    return (
        <div className="PlanningClassification">
            <div className="portal-grid">
                <div className="portal-form-colsm portal-form-colsm-label">
                    <span className="dot"></span>
                    <label className="dot-label">{title}</label>
                </div>
                <div className="portal-form-colsm portal-form-colsm-single">
                    <div className="btn-holder">
                        <span
                            onClick={() =>
                                arrayPush(
                                    `client.${baseName}.${fieldName}`,
                                    undefined
                                )
                            }
                            className="btn btn-plus"
                        />
                    </div>
                </div>
                {isJoint && (
                    <div className="portal-form-colsm portal-form-colsm-joint">
                        <div className="btn-holder">
                            <span
                                onClick={() =>
                                    arrayPush(
                                        `partner.${baseName}.${fieldName}`,
                                        undefined
                                    )
                                }
                                className="btn btn-plus"
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className="portal-grid">
                <div className="portal-form-colsm" />
                <div className="portal-form-colsm portal-form-colsm-single-where">
                    <FieldArray name={`client.${baseName}.${fieldName}`}>
                        {({ fields }) =>
                            fields.map((name, index) => (
                                <Field
                                    name={`${name}`}
                                    label={""}
                                    isLabel={false}
                                    isAddButton={false}
                                    onRemoveButtonClick={() =>
                                        arrayRemove(
                                            `client.${baseName}.${fieldName}`,
                                            index
                                        )
                                    }
                                    key={name + index}
                                    {...props}
                                />
                            ))
                        }
                    </FieldArray>
                </div>
                {isJoint && (
                    <div className="portal-form-colsm portal-form-colsm-joint-where">
                        <FieldArray name={`partner.${baseName}.${fieldName}`}>
                            {({ fields }) =>
                                fields.map((name, index) => (
                                    <Field
                                        name={`${name}`}
                                        label={""}
                                        isLabel={false}
                                        isAddButton={false}
                                        onRemoveButtonClick={() =>
                                            arrayRemove(
                                                `partner.${baseName}.${fieldName}`,
                                                index
                                            )
                                        }
                                        key={name + index}
                                        {...props}
                                    />
                                ))
                            }
                        </FieldArray>
                    </div>
                )}
            </div>
        </div>
    );
}
function PlanningClassification({ isJoint, client, partner }) {
    const fields = [
        {
            title: intl.get("field.haveOverseasAssets"),
            fieldName: "countriesOverseasAssetsAndLiabilities"
        },
        {
            title: intl.get("field.haveOverseasPension"),
            fieldName: "countriesOverseasPensionSchemes"
        },
        {
            title: intl.get("field.haveOverseasInsurance"),
            fieldName: "countriesOverseasInsurancePolicies"
        },
        {
            title: intl.get("field.haveOverseasGovEdu"),
            fieldName: "countriesOverseasGovernmentEducationAssistanceLoans"
        }
    ];
    return (
        <Accordion>
            <AccordionItem expanded>
                {/* 20220121 */}
                <div
                    className={`portal-part PlanningClassification-portal-part ${
                        client.status == "ARCHIVE"
                            ? "personal-details-disabled"
                            : ""
                    }`}
                >
                    <AccordionItemTitle>
                        <div className="subtitle2">
                            {intl.get("personaldetail.planningClassification")}
                            <span
                                className="accordion__arrow"
                                role="presentation"
                            />
                        </div>
                    </AccordionItemTitle>
                    <AccordionItemBody>
                        {fields.map(item => (
                            <PlanningClassificationField
                                key={item.fieldName}
                                title={item.title}
                                fieldName={item.fieldName}
                                isJoint={isJoint}
                            />
                        ))}
                    </AccordionItemBody>
                </div>
            </AccordionItem>
        </Accordion>
    );
}

export default PlanningClassification;
