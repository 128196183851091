import intl from 'react-intl-universal';

const translateListLabel = (list, labelKey = 'name') =>
    list.map(item => {
        const temp = {
            ...item,
        };
        temp[labelKey] = intl.get(item[labelKey])?intl.get(item[labelKey]):item[labelKey];
        return temp;
    });

export { translateListLabel };
