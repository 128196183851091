/*
    Author : Erik Farhan Malik (erikfarhanmalik@gmail.com)
    Description : Total Net Worth Summary component
*/

import React from "react"
import intl from "react-intl-universal"

const NetWorthListingFilter = ({
  listingFilter,
  updatefilter,
  clientName,
  partnerName
}) => {
  // const filters = ['All', 'Joint', clientName, partnerName];
  const filters = [
    {
      value: "All",
      label: intl.get("netWorth.listingFilter.all")
    },
    {
      value: "Joint",
      label: intl.get("netWorth.listingFilter.joint")
    },
    {
      value: "Client",
      label: clientName
    },
    {
      value: "Partner",
      label: partnerName
    }
  ]
  return (
    <div className="content-panel-tab">
      {filters.map((filter, index) => (
        <span
          key={index}
          onClick={() => updatefilter(filter.value)}
          className={listingFilter === filter.value ? "selected" : ""}
        >
          {filter.label}
        </span>
      ))}
    </div>
  )
}

export default NetWorthListingFilter
