import React, { PureComponent } from "react"
import intl from "react-intl-universal"
import { IdTypeEnum } from "../../constants/Enums"
import { CountryList } from "../../constants/Lists"
import Api from "../../lib/api"
import MalabarDate from "../MalabarDate/MalabarDate"
import CustomFilePond from "../FileUploader/CustomFilePond"
const IDTYPE_PART = "IDTYPE"
const IDCOUNTRY_PART = "COUNTRY"
const IDNUMBER_PART = "NUMBER"
const PHOTO_PART = "PHOTO"
const ID_ISSUED_PART = "ID_ISSUED"
const ID_EXPIRED_PART = "ID_EXPIRED"
const NUMBER_MAXLENGTH = 20
const fileOperationUrl = localStorage.getItem("base-route") // base route for multiple region

export default class Identity extends PureComponent {
  state = {
    idType: IdTypeEnum.IdType_NATIONAL_ID,
    idCountry: "AU",
    idNumber: "",
    value: this.props.value,
    idTypeOptions: "",
    countryOptions: "",
    file: "",
    documentId: "",
    identityImageName: "",
    identityImagePath: "",
    idIssuedDate: "",
    idExpiredDate: "",
    photo: ""
  }

  componentWillMount() {
    this.setComponentState(this.props.value)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setComponentState(nextProps.value)
    }
  }

  setComponentState = (valueSource) => {
    const idOptions = this.getIdTypeOptions()
    const countryOptions = this.getCountryOptions()
    this.setState(
      {
        idTypeOptions: idOptions,
        countryOptions: countryOptions
      },
      () => {
        if (
          valueSource !== "" &&
          valueSource !== null &&
          valueSource !== undefined
        ) {
          this.setState({
            idType: valueSource.idType,
            idCountry: valueSource.idCountry ? valueSource.idCountry : "AU",
            idNumber: valueSource.idNumber,
            documentId: valueSource.documentId,
            identityImageName: valueSource.photo
              ? valueSource.photo.originalFileName
              : "",
            identityImagePath: valueSource.photo ? valueSource.photo.url : "",
            value: valueSource,
            idIssuedDate: valueSource.idIssuedDate,
            idExpiredDate: valueSource.idExpiredDate,
            photo: valueSource.photo
          })
        } else {
          this.setState({
            idType: IdTypeEnum.IdType_NATIONAL_ID,
            idCountry: "AU",
            idNumber: "",
            documentId: "",
            identityImageName: "",
            identityImagePath: "",
            value: null,
            idIssuedDate: "",
            idExpiredDate: "",
            photo: ""
          })
        }
      }
    )
  }

  getIdTypeOptions = () => {
    const result = []
    Object.keys(IdTypeEnum).forEach((key) => {
      result.push(
        <option key={IdTypeEnum[key]} value={IdTypeEnum[key]}>
          {intl.get(key)}
        </option>
      )
    })
    return result
  }

  getCountryOptions = () => {
    const result = []
    CountryList.forEach((item) => {
      result.push(
        <option key={item.code} value={item.code}>
          {intl.get(item.name)}
        </option>
      )
    })
    return result
  }

  onPartChange = async (part, element, name, files) => {
    let inputValue = ""
    switch (part) {
      case IDTYPE_PART:
        inputValue = element.target.value
        this.setState(
          {
            idType: inputValue,
            value: {
              idType: inputValue,
              idCountry: this.state.idCountry,
              idNumber: this.state.idNumber,
              documentId: this.state.documentId,
              idIssuedDate: this.state.idIssuedDate,
              idExpiredDate: this.state.idExpiredDate,
              identityImageName: this.state.identityImageName,
              identityImagePath: this.state.identityImagePath,
              photo: this.state.photo
            }
          },
          () => {
            // notify final-form
            this.props.onChange(this.state.value)
          }
        )
        break
      case IDCOUNTRY_PART:
        inputValue = element.target.value
        this.setState(
          {
            idCountry: inputValue || this.state.idCountry,
            value: {
              idType: this.state.idType,
              idCountry: inputValue,
              idNumber: this.state.idNumber,
              documentId: this.state.documentId,
              idIssuedDate: this.state.idIssuedDate,
              idExpiredDate: this.state.idExpiredDate,
              identityImageName: this.state.identityImageName,
              identityImagePath: this.state.identityImagePath,
              photo: this.state.photo
            }
          },
          () => {
            // notify final-form
            this.props.onChange(this.state.value)
          }
        )
        break

      case IDNUMBER_PART:
        inputValue = element.target.value
        let regexTest = /^[a-z0-9]+$/i.test(inputValue)
        if (inputValue && inputValue.length > 0 && !regexTest) {
          return
        }
        if (inputValue && inputValue.length > NUMBER_MAXLENGTH) {
          return
        }
        this.setState(
          {
            number: this.state.idNumber,
            value: {
              idType: this.state.idType,
              idCountry: this.state.idCountry,
              idNumber: inputValue,
              documentId: this.state.documentId,
              idIssuedDate: this.state.idIssuedDate,
              idExpiredDate: this.state.idExpiredDate,
              identityImageName: this.state.identityImageName,
              identityImagePath: this.state.identityImagePath,
              photo: this.state.photo
            }
          },
          () => {
            // notify final-form
            this.props.onChange(this.state.value)
          }
        )
        break
      case ID_ISSUED_PART:
        inputValue = element

        this.setState(
          {
            idIssuedDate: this.state.idIssuedDate,
            value: {
              idType: this.state.idType,
              idCountry: this.state.idCountry,
              idNumber: this.state.idNumber,
              documentId: this.state.documentId,
              idIssuedDate: inputValue,
              idExpiredDate: this.state.idExpiredDate,
              identityImageName: this.state.identityImageName,
              identityImagePath: this.state.identityImagePath,
              photo: this.state.photo
            }
          },
          () => {
            // notify final-form
            this.props.onChange(this.state.value)
          }
        )
        break
      case ID_EXPIRED_PART:
        inputValue = element

        this.setState(
          {
            idExpiredDate: this.state.idExpiredDate,
            value: {
              idType: this.state.idType,
              idCountry: this.state.idCountry,
              idNumber: this.state.idNumber,
              documentId: this.state.documentId,
              idIssuedDate: this.state.idIssuedDate,
              idExpiredDate: inputValue,
              identityImageName: this.state.identityImageName,
              identityImagePath: this.state.identityImagePath,
              photo: this.state.photo
            }
          },
          () => {
            // notify final-form
            this.props.onChange(this.state.value)
          }
        )
        break
      case PHOTO_PART:
        const formData = new FormData()
        if (files[0] && files[0].file) {
          formData.append("image", files[0].file)
          this.setState({ file: files[0].file })
          try {
            const response = await Api.post(
              "/client/personal-details/upload/identity",
              formData
            )
            response.data.id
              ? this.setState({
                  documentId: response.data.id
                })
              : this.setState({ documentId: "" })
          } catch (error) {
            console.error("error while uploading", error)
          }
        }
        this.setState(
          {
            value: {
              idType: this.state.idType,
              idCountry: this.state.idCountry,
              idNumber: this.state.idNumber,
              documentId: this.state.documentId,
              idIssuedDate: this.state.idIssuedDate,
              idExpiredDate: this.state.idExpiredDate,
              identityImageName: this.state.identityImageName,
              identityImagePath: this.state.identityImagePath,
              photo: this.state.photo
            }
          },
          () => {
            // notify final-form
            this.props.onChange(this.state.value)
          }
        )
        break

      default:
        break
    }
  }

  render() {
    const { name, touched, error, onRemoveDataClick, index } = this.props
    const {
      idType,
      idCountry,
      idNumber,
      identityImageName,
      identityImagePath,
      documentId,
      idIssuedDate,
      idExpiredDate
    } = this.state
    let isMalaysiaNationalId =
      this.state.idType === "NATIONAL_ID" && this.state.idCountry === "MY"

    return (
      <React.Fragment>
        <div className="row-holder-full">
          <div className="select-box">
            <select
              name={"_idType"}
              value={idType}
              onChange={(e) => this.onPartChange(IDTYPE_PART, e, name)}
              required
            >
              {this.state.idTypeOptions}
            </select>
          </div>
          <div className="select-box">
            <select
              name={"_idCountry"}
              value={idCountry}
              onChange={(e) => this.onPartChange(IDCOUNTRY_PART, e, name)}
              required
            >
              {this.state.countryOptions}
            </select>
          </div>
          <input
            className="text-input text-input-phonenumber"
            type="text"
            value={idNumber}
            style={{ width: "80px" }}
            name={"_idNumber"}
            placeholder={"number"}
            onChange={(e) => this.onPartChange(IDNUMBER_PART, e, name)}
          />
          {!isMalaysiaNationalId ? (
            <>
              <MalabarDate
                required
                value={idIssuedDate}
                name="idIssuedDate"
                onChange={(e) => this.onPartChange(ID_ISSUED_PART, e, name)}
              />

              <MalabarDate
                required
                value={idExpiredDate}
                name="idExpiredDate"
                onChange={(e) => this.onPartChange(ID_EXPIRED_PART, e, name)}
              />
            </>
          ) : (
            <></>
          )}
          {touched && error && (
            <div className="mt-1 alert phone-alert">{error}</div>
          )}
          <CustomFilePond
            customClassName="mt-2"
            labelIdle="Drag, Drop or Browse"
            name={"_documentId"}
            onupdatefiles={(files, e) =>
              this.onPartChange(PHOTO_PART, e, name, files)
            }
            onremovefile={async (error, file) => {
              this.setState({ file: "" })
            }}
            allowReplace
          />
          <span>
            {documentId && !this.state.file ? (
              <a
                href={`${fileOperationUrl}${identityImagePath}`}
                target="_blank"
                rel="noreferrer"
                className="file-link"
              >
                {identityImageName.length > 20
                  ? identityImageName.substring(0, 14) +
                    "..." +
                    identityImageName.split(".")[1]
                  : identityImageName}
              </a>
            ) : (
              ""
            )}
          </span>
          {index && index !== 0 ? (
            <span
              onClick={onRemoveDataClick}
              className="mt-n2 btn-remove identity-remove"
              style={{ right: "inherit" }}
            />
          ) : (
            ""
          )}
        </div>
      </React.Fragment>
    )
  }
}
