import React, { useContext } from 'react';
import intl from 'react-intl-universal';
import { AppContext } from '../../../context/AppContext';
import CPFBeneficary from './CPFBeneficary';

function Beneficary({ mutators, model }) {
    const {
        modal: { setModal, clearModal },
    } = useContext(AppContext);

    const handleSave = (model) => {
        mutators.push('beneficiaries', model);
        clearModal();
    }
    const add = () => {
        setModal({
            title: intl.get(`netWorth.investmentDetails.beneficiaryDetails.title`),
            content: (
                <CPFBeneficary handleSave={handleSave} model={model} />
            ),
            formType: 'BeneficiaryDetailsTable'
        });
    }

    const editItem = (value, idx) => {
        var handleSaveItem = (model) => {
            mutators.update(`beneficiaries`, idx, model);
            clearModal();
        }
        setModal({
            title: 'Cash Account Information – CPFIS Singapore',
            content: <CPFBeneficary handleSave={handleSaveItem} model={value} />,
            formType: 'BeneficiaryDetailsTable',
        });
    }

    const deleteItem = (idx) => {
        mutators.remove(`beneficiaries`, idx);
    }

    const generate = () => {
        if (model) {
            if (model.beneficiaries) {
                return model.beneficiaries.map((a, idx) => (
                    <tr key={idx}>
                        <td>{a.name}</td>
                        <td>{a.relationship}</td>
                        <td>{a.contactNumber}</td>
                        <td><a href="mailto:XYZ@gmail.com">{a.emailAddress}</a></td>
                        <td colSpan="2">
                            <button
                                onClick={() => editItem(a, idx)}
                                className="btn btn-edit btn-padding"
                            />
                            <button type="button"
                                onClick={() => deleteItem(idx)}
                                className="btn-remove"></button>
                        </td>
                    </tr >
                ));
            }
        }
    }

    return (
        <table className="table-investment mt-2" width="100%" cellSpacing="0" cellPadding="0" border="0">
            <thead>
                <tr>
                    <th>
                        {intl.get(
                            'centralProvidenceFundInformation.beneficaryName'
                        )}
                    </th>
                    <th>
                        {intl.get(
                            'centralProvidenceFundInformation.relationship'
                        )}
                    </th>
                    <th>
                        {intl.get(
                            'centralProvidenceFundInformation.contactNumber'
                        )}
                    </th>
                    <th>
                        {intl.get(
                            'centralProvidenceFundInformation.emailAddress'
                        )}
                    </th>
                    <th></th>
                    <th><button type="button" className="btn btn-plus" onClick={add}></button></th>
                </tr>
            </thead>
            <tbody>

                {generate()}
            </tbody></table>
    );
}

export default Beneficary;