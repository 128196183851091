import React, { useEffect, useState, useContext } from "react"
import { Chart, Bar } from "react-chartjs-2"
import intl from "react-intl-universal"
import Tooltip from "rc-tooltip"
import Slider from "rc-slider"
import "rc-tooltip/assets/bootstrap.css"
import { DefaultBarChartOptions } from "../../helpers/ChartHelper"
import { getSymbolFromCode } from "../../helpers"
import { numberNoDecimalPoints } from "../../helpers/CurrencyConversion"
import { LifeLineContext } from "../../pages/CurrentStanding/LifeLine"
import Alert from "react-s-alert"
import { useSelector } from "react-redux"
import html2canvas from "html2canvas"

function LifelineChart({
  showAnalytics,
  data,
  currentAge,
  currentYear,
  onRetirementAgeChange,
  onRetirementAgeChange1,
  isShowSlider = false,
  currency,
  chartId,
  clientChart
}) {
  const { clientFullNamePreferred, partnerFullNamePreferred } = useSelector(
    (state) => state.entity
  )
  const [chartMostLeft, setChartMostLeft] = useState(0)
  const [sliderMargin, setSliderMargin] = useState({
    marginLeft: 0
  })
  const wholelifelineData = useContext(LifeLineContext)

  // effect to draw retirement age lines (extend chart.js default drawing)
  useEffect(() => {
    const retirementLinePlugin = {
      id: "retirementLinePlugin",
      afterDraw(chart) {
        if (
          chart.config.options.id === chartId &&
          chart.config.data.lineAtIndex
        ) {
          const index = chart.config.data.lineAtIndex
          const index1 = chart.config.data.lineAtIndex1
          const clientAge = chart.config.data.clientAge
          const partnerAge = chart.config.data.partnerAge

          const { ctx } = chart
          const xaxis = chart.scales["x-axis-0"]
          const yaxis = chart.scales["y-axis-0"]

          if (index && xaxis && yaxis) {
            ctx.save()
            ctx.beginPath()

            ctx.moveTo(
              xaxis.getPixelForValue(undefined, index < 0 ? 0 : index),
              yaxis.top - 20
            )
            ctx.lineTo(
              xaxis.getPixelForValue(undefined, index < 0 ? 0 : index),
              yaxis.bottom
            )

            if (index1) {
              ctx.moveTo(
                xaxis.getPixelForValue(undefined, index1 < 0 ? 0 : index1),
                yaxis.top
              )
              ctx.lineTo(
                xaxis.getPixelForValue(undefined, index1 < 0 ? 0 : index1),
                yaxis.bottom + 30
              )
            }

            ctx.lineWidth = 2
            ctx.strokeStyle = "#ff2121"
            ctx.stroke()
            ctx.restore()

            ctx.font = "8pt Arial"
            ctx.textAlign = "center"
            ctx.fillStyle = "#ff2121"

            const clientName = clientFullNamePreferred || ""

            ctx.fillText(
              `${clientName ? `${clientName}` : ""} (Client)`,
              xaxis.getPixelForValue(undefined, index < 0 ? 0 : index),
              yaxis.top - 25 - 20
            )

            ctx.fillText(
              index > 0
                ? `Retirement - ${intl.get("general.age")} ${
                    index + clientAge
                  }, ${intl.get("general.year")} ${index + currentYear}`
                : `Already Retired ${-index} years ago (${
                    currentYear + index
                  })`,
              xaxis.getPixelForValue(undefined, index < 0 ? 0 : index),
              yaxis.top - 10 - 20
            )

            if (index1) {
              const partnerName = partnerFullNamePreferred || ""

              ctx.fillText(
                `${partnerName ? `${partnerName}` : ""} (Partner)`,
                xaxis.getPixelForValue(undefined, index1 < 0 ? 0 : index1),
                yaxis.bottom + 40
              )

              ctx.fillText(
                index1 > 0
                  ? `Retirement - ${intl.get("general.age")} ${
                      index1 + partnerAge
                    }, ${intl.get("general.year")} ${index1 + currentYear}`
                  : `Already Retired ${-index1} years ago (${
                      currentYear + index1
                    })`,
                xaxis.getPixelForValue(undefined, index1 < 0 ? 0 : index1),
                yaxis.bottom + 55
              )
            }
          }
        }
      }
    }

    // Register the plugin
    Chart.pluginService.register(retirementLinePlugin)

    // Cleanup: Unregister the plugin on component unmount
    return () => {
      Chart.pluginService.unregister(retirementLinePlugin)
    }
  }, [
    chartId,
    clientFullNamePreferred,
    partnerFullNamePreferred,
    currentYear,
    wholelifelineData
  ])

  useEffect(() => {
    calculateMargin(chartMostLeft)
  }, [chartMostLeft])

  // property for slider
  const { Handle } = Slider

  // set chart data
  const setChartDataSets = (
    showAnalytics,
    retirementAssets,
    surplusCashflow,
    deficitCashflow,
    invAssets,
    personalAssets,
    totalAssets,
    totalLiabilities,
    totalNetworth,

    incomePrediction,
    totalInvestmentAssetPrediction,
    totalInvestmentLower,
    totalInvestmentUpper,
    totalInvestmentSixtyLower,
    totalInvestmentSixtyUpper
  ) => {
    const datasets = showAnalytics
      ? [
          {
            label: "Investment Assets (hard number)",
            data: invAssets,
            backgroundColor: "rgba(0,161,255, 0.8)",
            borderColor: "rgba(0,161,255,1)",
            borderWidth: 1,
            yAxisID: "y-axis-0",
            xAxisID: "x-axis-0",
            order: 1
          },
          {
            label: "Total Investment Assets Prediction *",
            data: totalInvestmentAssetPrediction,
            backgroundColor: "rgba(245, 93, 39,  0.8)",
            borderColor: "rgba(245, 93, 39, 0.8)",
            order: 2,
            yAxisID: "y-axis-0",
            xAxisID: "x-axis-0"
          },

          {
            label: intl.get("currentStanding.chart.totalInvestmentAssetLower"),
            data: totalInvestmentLower,
            backgroundColor: "rgba(141, 74, 108, 0.8)",
            borderColor: "rgba(141, 74, 108, 0.8)",
            order: 3,
            fill: false,
            borderWidth: 2,
            type: "line",
            yAxisID: "y-axis-0",
            pointHoverRadius: 5,
            pointHoverBorderWidth: 2,
            pointRadius: 0,
            pointHoverBackgroundColor: "white",
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter"
          },

          {
            label: intl.get("currentStanding.chart.totalInvestmentAssetUpper"),
            data: totalInvestmentUpper,
            backgroundColor: "rgba(100, 95, 100, 0.2)",
            borderColor: "rgba(100, 95, 100, 0.8)",
            order: 4,
            fill: "-1",
            borderWidth: 2,
            type: "line",
            yAxisID: "y-axis-0",
            pointHoverRadius: 5,
            pointHoverBorderWidth: 2,
            pointRadius: 0,
            pointHoverBackgroundColor: "white",
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter"
          },

          {
            label: intl.get(
              "currentStanding.chart.totalInvestmentAssetSixtyPercentLower"
            ),
            data: totalInvestmentSixtyLower,
            backgroundColor: "rgba(70, 115, 179, 0.2)",
            borderColor: "rgba(70, 115, 179, 0.8)",
            order: 5,
            fill: false,
            borderWidth: 2,
            type: "line",
            yAxisID: "y-axis-0",
            pointHoverRadius: 5,
            pointHoverBorderWidth: 2,
            pointRadius: 0,
            pointHoverBackgroundColor: "white",
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter"
          },

          {
            label: intl.get(
              "currentStanding.chart.totalInvestmentAssetSixtyPercentUpper"
            ),
            data: totalInvestmentSixtyUpper,
            backgroundColor: "rgba(179, 159, 70, 0.2)",
            borderColor: "rgba(179, 159, 70, 0.8)",
            order: 6,
            fill: "-1",
            borderWidth: 2,
            type: "line",
            yAxisID: "y-axis-0",
            pointHoverRadius: 5,
            pointHoverBorderWidth: 2,
            pointRadius: 0,
            pointHoverBackgroundColor: "white",
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter"
          },
          {
            label: intl.get("currentStanding.chart.totalIncomePrediction"),
            data: incomePrediction,
            backgroundColor: "rgba(245, 40, 145,  0.8)",
            borderColor: "rgba(245, 40, 145, 0.8)",
            order: 7,
            fill: false,
            borderWidth: 2,
            type: "line",
            yAxisID: "y-axis-0",
            pointHoverRadius: 5,
            pointHoverBorderWidth: 2,
            pointRadius: 0,
            pointHoverBackgroundColor: "white",
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter"
          }
        ]
      : [
          {
            label: "Total Assets (Personal + Investment + Retirement)",
            data: totalAssets,
            fill: false,
            backgroundColor: "rgba(144,197,68,1)",
            borderColor: "cyan",
            borderWidth: 2,
            type: "line",
            yAxisID: "y-axis-0",
            pointHoverRadius: 5,
            pointHoverBorderWidth: 2,
            pointRadius: 0,
            pointHoverBackgroundColor: "white",
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            order: 4
          },
          {
            label: "Investment Assets",
            data: invAssets,
            backgroundColor: "rgba(0,161,255,0.6)",
            borderColor: "rgba(0,161,255,1)",
            borderWidth: 1,
            yAxisID: "y-axis-0",
            xAxisID: "x-axis-0",
            order: 2,
            fill: false
          },
          {
            label: "Personal Assets",
            data: personalAssets,
            backgroundColor: "#4983F0",
            borderColor: "rgba(69,114,199,0.7)",
            borderWidth: 1,
            yAxisID: "y-axis-0",
            xAxisID: "x-axis-0",
            order: 1
          },
          {
            label: "Total Retirement Assets",
            data: retirementAssets,
            fill: false,
            backgroundColor: "#3CB371",
            borderColor: "#2E8B57",
            borderWidth: 2,
            type: "line",
            yAxisID: "y-axis-0",
            pointHoverRadius: 5,
            pointHoverBorderWidth: 2,
            pointRadius: 0,
            pointHoverBackgroundColor: "white",
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            order: 3
          },
          {
            label: "Total Liabilities",
            data: totalLiabilities,
            fill: false,
            backgroundColor: "#A0522D",
            borderColor: "#6B4226",
            borderWidth: 2,
            type: "line",
            yAxisID: "y-axis-0",
            pointHoverRadius: 5,
            pointHoverBorderWidth: 2,
            pointRadius: 0,
            pointHoverBackgroundColor: "white",
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            order: 5
          },
          {
            label: "Total Net Asset Balance",
            data: totalNetworth,
            fill: false,
            backgroundColor: "rgba(144,197,68,1)",
            borderColor: "rgba(144,197,68,1)",
            borderWidth: 2,
            type: "line",
            yAxisID: "y-axis-0",
            pointHoverRadius: 5,
            pointHoverBorderWidth: 2,
            pointRadius: 0,
            pointHoverBackgroundColor: "white",
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            order: 6
          },
          {
            label: intl.get("currentStanding.chart.surplusCashflow"),
            data: surplusCashflow,
            backgroundColor: "rgba(255,174,0,1)",
            borderColor: "rgba(255,174,0,1)",
            borderWidth: 1,
            xAxisID: "x-axis-1",
            order: 7
          },

          {
            label: intl.get("currentStanding.chart.deficitCashflow"),
            data: deficitCashflow,
            backgroundColor: "rgba(242,46,46,1)",
            borderColor: "rgba(242,46,46,1)",
            borderWidth: 1,
            xAxisID: "x-axis-1",
            order: 8
          }
        ]

    return datasets
  }
  const setChartData = (rawData, currAge, retirementAge) => {
    // data translation process here
    const arrRetireAssets = []
    const arrSurplusCashflow = []
    const arrDeficitCashflow = []
    const arrInvAssets = []
    const arrPersonalAssets = []
    const arrTotalAssets = []
    const arrTotalLiabilities = []
    const arrTotalNetworth = []

    const arrIncomePrediction = []
    const arrTotalInvestAssetPrediction = []
    const arrTotalInvestmentLower = []
    const arrTotalInvestmentUpper = []
    const arrTotalInvestmentSixtyLower = []
    const arrTotalInvestmentSixtyUpper = []

    const chartLabels = []
    let currAgeCount = currAge

    rawData.lifeline.forEach((chartData) => {
      const {
        totalExpense,
        totalIncome,
        pensionOutflow,
        savingOutflow,
        totalInvestmentAsset,
        totalLiability,
        totalPersonalAsset,
        totalInvestmentLiability,
        totalRetirementAsset,
        year,
        analyticSummary
      } = chartData
      const totalAsset =
        totalInvestmentAsset + totalPersonalAsset + totalRetirementAsset
      const totalLiabilities = totalInvestmentLiability + totalLiability
      const totNetworth = totalAsset - totalLiabilities
      const surplusOrDeficit =
        totalIncome - (totalExpense + pensionOutflow + savingOutflow)

      arrRetireAssets.push(totalRetirementAsset)

      arrInvAssets.push(totalInvestmentAsset)
      arrPersonalAssets.push(totalPersonalAsset)
      arrTotalAssets.push(totalAsset)
      arrTotalLiabilities.push(totalLiabilities)

      if (surplusOrDeficit >= 0) {
        arrSurplusCashflow.push(surplusOrDeficit)
        arrDeficitCashflow.push(0)
      } else {
        arrDeficitCashflow.push(surplusOrDeficit)
        arrSurplusCashflow.push(0)
      }
      arrTotalNetworth.push(totNetworth)

      arrIncomePrediction.push(analyticSummary.totalIncomePrediction)
      arrTotalInvestAssetPrediction.push(
        analyticSummary.totalInvestmentAssetPrediction
      )
      arrTotalInvestmentLower.push(analyticSummary.totalInvestmentAssetLower)
      arrTotalInvestmentUpper.push(analyticSummary.totalInvestmentAssetUpper)
      arrTotalInvestmentSixtyLower.push(
        analyticSummary.totalInvestmentAssetSixtyPercentLower
      )
      arrTotalInvestmentSixtyUpper.push(
        analyticSummary.totalInvestmentAssetSixtyPercentUpper
      )

      // for labels
      const yearAgeCombine = []
      yearAgeCombine.push(year, currAgeCount)
      chartLabels.push(yearAgeCombine)
      currAgeCount += 1
    })

    return {
      labels: chartLabels,
      datasets: setChartDataSets(
        showAnalytics,
        arrRetireAssets,
        arrSurplusCashflow,
        arrDeficitCashflow,
        arrInvAssets,
        arrPersonalAssets,
        arrTotalAssets,
        arrTotalLiabilities,
        arrTotalNetworth,
        arrIncomePrediction,
        arrTotalInvestAssetPrediction,
        arrTotalInvestmentLower,
        arrTotalInvestmentUpper,
        arrTotalInvestmentSixtyLower,
        arrTotalInvestmentSixtyUpper
      ),

      lineAtIndex:
        wholelifelineData.classification !== "JOINT" &&
        (!isShowSlider
          ? wholelifelineData.classification !== "PARTNER" &&
            wholelifelineData.lifelineObject.client
            ? wholelifelineData.lifelineObject.client.ageInfo &&
              wholelifelineData.lifelineObject.client.ageInfo.retirementAge -
                wholelifelineData.lifelineObject.client.ageInfo.age
            : "undefined"
          : wholelifelineData.classification !== "PARTNER" &&
            wholelifelineData.lifelineObject1.client
          ? wholelifelineData.lifelineObject1.client.ageInfo &&
            wholelifelineData.lifelineObject1.client.ageInfo.retirementAge -
              wholelifelineData.lifelineObject1.client.ageInfo.age
          : "undefined"),

      lineAtIndex1:
        wholelifelineData.classification !== "JOINT" &&
        (!isShowSlider
          ? wholelifelineData.classification !== "CLIENT" &&
            wholelifelineData.lifelineObject.partner !== null
            ? wholelifelineData.lifelineObject.partner.ageInfo.retirementAge -
              wholelifelineData.lifelineObject.partner.ageInfo.age
            : ""
          : wholelifelineData.classification !== "CLIENT" &&
            wholelifelineData.lifelineObject1.partner !== null
          ? wholelifelineData.lifelineObject1.partner.ageInfo.retirementAge -
            wholelifelineData.lifelineObject1.partner.ageInfo.age
          : ""),
      clientAge: !isShowSlider
        ? wholelifelineData.lifelineObject.client !== null
          ? wholelifelineData.lifelineObject.client.ageInfo.age
          : undefined
        : wholelifelineData.lifelineObject1.client !== null
        ? wholelifelineData.lifelineObject1.client.ageInfo.age
        : undefined,
      partnerAge: !isShowSlider
        ? wholelifelineData.lifelineObject &&
          wholelifelineData.lifelineObject.partner !== null
          ? wholelifelineData.lifelineObject.partner.ageInfo.age
          : undefined
        : wholelifelineData.lifelineObject1 &&
          wholelifelineData.lifelineObject1.partner !== null
        ? wholelifelineData.lifelineObject1.partner.ageInfo.age
        : undefined
    }
  }

  const handle = (props) => {
    const { value, dragging, index, ...restProps } = props
    return (
      <Tooltip
        zIndex="1"
        prefixCls="rc-slider-tooltip"
        overlay={value}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </Tooltip>
    )
  }

  const handleOnAfterChange = (value) => {
    onRetirementAgeChange(value)
  }
  const handleOnAfterChange1 = (value) => {
    onRetirementAgeChange1(value)
  }

  const numberFormat = (value) =>
    ` ${getSymbolFromCode(currency)}${numberNoDecimalPoints(value)}`

  const generateCustomTooltips = () => ({
    // Disable the on-canvas tooltip
    enabled: false,

    custom(tooltipModel) {
      // Tooltip Element
      let tooltipEl = document.getElementById("chartjs-tooltip")
      let tableStyle = ""

      // Create element on first render
      if (!tooltipEl) {
        tooltipEl = document.createElement("div")
        tooltipEl.id = "chartjs-tooltip"
        tableStyle =
          "background-color: #333;" +
          "border-color: #333;" +
          "color: #fff;" +
          "width: 100%;" +
          "z-index:9999 !important;" +
          "padding: 15px;"

        tooltipEl.innerHTML = `<table style="${tableStyle}"></table>`
        document.body.appendChild(tooltipEl)
      }

      // Hide if no tooltip
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0
        return
      }

      // Set caret Position
      tooltipEl.classList.remove("above", "below", "no-transform")
      if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign)
      } else {
        tooltipEl.classList.add("no-transform")
      }

      // get data on data array index n
      const lifelineDataItem = data.lifeline[tooltipModel.dataPoints[0].index]

      const {
        totalExpense,
        totalIncome,
        pensionOutflow,
        savingOutflow,
        totalInvestmentAsset,
        totalLiability,
        totalPersonalAsset,
        totalInvestmentLiability,
        totalRetirementAsset,
        analyticSummary
      } = lifelineDataItem

      let totalOutflow = totalExpense + pensionOutflow + savingOutflow

      // Set Text
      if (tooltipModel.body) {
        const titleLines = tooltipModel.title || []

        let innerHtml = "<thead><tr>"
        let boolValue =
          wholelifelineData.classification === "PARTNER" ||
          wholelifelineData.classification === "CLIENT"

        if (titleLines.length === 1) {
          const oneItemTitleLine = titleLines[0].split(",")
          innerHtml += `<th>${
            oneItemTitleLine[0] == 0 ? "Today" : oneItemTitleLine[0]
          }</th>`
          innerHtml += boolValue
            ? `<th>${intl.get("general.age")} ${oneItemTitleLine[1]}</th>`
            : ""
        } else {
          innerHtml += `<th>${titleLines[0]}</th>`
          innerHtml += `<th>${intl.get("general.age")} ${titleLines[1]}</th>`
        }

        innerHtml += "</tr></thead><tbody>"

        // if (showAnalytics) {
        //   innerHtml += `<tr className='p-4'><td class="tooltip-chart pt-1"><div class="default">${intl.get(
        //     "currentStanding.chart.totalInvestmentAssets"
        //   )}</div></td><td class="tooltip-chart"><div class="default currency">${numberFormat(
        //     totalInvestmentAsset
        //   )}</div></td></tr>`

        //   innerHtml += `<tr><td class="tooltip-chart"><div class="default">${intl.get(
        //     "currentStanding.chart.totalInvestmentAssetPrediction"
        //   )}</div></td><td class="tooltip-chart"><div class="default currency">${numberFormat(
        //     analyticSummary.totalInvestmentAssetPrediction
        //   )}</div></td></tr>`
        //   innerHtml += `<tr><td class="tooltip-chart"><div class="default">${intl.get(
        //     "currentStanding.chart.totalInvestmentAssetLower"
        //   )}</div></td><td class="tooltip-chart"><div class="default currency">${numberFormat(
        //     analyticSummary.totalInvestmentAssetLower
        //   )}</div></td></tr>`
        //   innerHtml += `<tr><td class="tooltip-chart"><div class="default">${intl.get(
        //     "currentStanding.chart.totalInvestmentAssetUpper"
        //   )}</div></td><td class="tooltip-chart"><div class="default currency">${numberFormat(
        //     analyticSummary.totalInvestmentAssetUpper
        //   )}</div></td></tr>`
        //   innerHtml += `<tr><td class="tooltip-chart"><div class="default">${intl.get(
        //     "currentStanding.chart.totalInvestmentAssetSixtyPercentLower"
        //   )}</div></td><td class="tooltip-chart"><div class="default currency">${numberFormat(
        //     analyticSummary.totalInvestmentAssetSixtyPercentLower
        //   )}</div></td></tr>`
        //   innerHtml += `<tr><td class="tooltip-chart"><div class="default">${intl.get(
        //     "currentStanding.chart.totalInvestmentAssetSixtyPercentUpper"
        //   )}</div></td><td class="tooltip-chart"><div class="default currency">${numberFormat(
        //     analyticSummary.totalInvestmentAssetSixtyPercentUpper
        //   )}</div></td></tr>`
        //   innerHtml += `<tr><td class="tooltip-chart"><div class="default">${intl.get(
        //     "currentStanding.chart.totalIncomePrediction"
        //   )}</div></td><td class="tooltip-chart pb-1"><div class="default currency">${numberFormat(
        //     analyticSummary.totalIncomePrediction
        //   )}</div></td></tr>`
        // } else {
        innerHtml += `<tr class=""><td class="tooltip-chart pt-1 "><div class="default">${intl.get(
          "currentStanding.chart.totalPersonalAssets"
        )}</div></td><td class="tooltip-chart pt-1"><div class="default currency">${numberFormat(
          totalPersonalAsset
        )}</div></td></tr>`
        innerHtml += `<tr class=""><td class="tooltip-chart pt-1"><div class="default">${intl.get(
          "currentStanding.chart.totalInvestmentAssets"
        )}</div></td><td class="tooltip-chart pt-1"><div class="default currency">${numberFormat(
          totalInvestmentAsset
        )}</div></td></tr>`

        innerHtml += `<tr><td class="tooltip-chart pt-1"><div class="default">${intl.get(
          "currentStanding.chart.totalRetirementAssets"
        )}</div></td><td class="tooltip-chart pt-1" style="position:relative;"><div class="default currency summary-border">${numberFormat(
          totalRetirementAsset
        )}</div></td></tr>`
        innerHtml += `<tr>
    <td style="margin-top:6px;margin-bottom:0;padding:0;" >
    </td>
    <td style="margin:0;padding:0;">
        <hr style="margin-top:6px;margin-bottom:0;padding:0;" />
    </td>
</tr>`

        innerHtml += `<tr><td class="tooltip-chart pt-1"><div class="default">${intl.get(
          "currentStanding.chart.totalAssets"
        )}</div></td><td class="tooltip-chart pt-1"><div class="default currency"> ${numberFormat(
          totalPersonalAsset + totalInvestmentAsset + totalRetirementAsset
        )}</div></td></tr>`
        innerHtml += `<tr><td class="tooltip-chart pt-1"><div class="default">${intl.get(
          "currentStanding.chart.totalLiabilities"
        )}</div></td><td class="tooltip-chart pt-1"><div class="default currency">${numberFormat(
          totalLiability + totalInvestmentLiability
        )}</div></td></tr>`
        innerHtml += `<tr>
    <td style="margin-top:6px;margin-bottom:0;padding:0;" >
    </td>
    <td style="margin:0;padding:0;">
        <hr style="margin-top:6px;margin-bottom:0;padding:0;" />
    </td>
</tr>`

        if (
          totalPersonalAsset +
            totalInvestmentAsset -
            totalLiability +
            totalInvestmentLiability >
          0
        ) {
          innerHtml += `<tr><td class="tooltip-chart pt-1"><div class="default bolded">${intl.get(
            "currentStanding.chart.investmentAssetsBalance"
          )}</div></td><td class="tooltip-chart pt-1"><div class="default bolded green currency" id="">${numberFormat(
            totalPersonalAsset +
              totalInvestmentAsset -
              totalLiability +
              totalInvestmentLiability
          )}</div></td></tr>`
        } else {
          innerHtml += `<tr><td class="tooltip-chart pt-1"><div class="default bolded">${intl.get(
            "currentStanding.chart.investmentAssetsBalance"
          )}</div></td><td class="tooltip-chart pt-1"><div class="default bolded red currency" id="">${numberFormat(
            totalPersonalAsset +
              totalInvestmentAsset -
              totalLiability +
              totalInvestmentLiability
          )}</div></td></tr>`
        }

        innerHtml += `<tr>
    <td style="margin:0;padding:0;" >
        <hr style="margin-top:6px; margin-bottom:6px;" />
    </td>
    <td style="margin:0;padding:0;">
        <hr style="margin-top:6px; margin-bottom:6px;" />
    </td>
</tr>`
        innerHtml += `<tr><td class="tooltip-chart"><div class="default">${intl.get(
          "currentStanding.chart.totalIncome"
        )}</div></td><td class="tooltip-chart"><div class="default bolded currency">${numberFormat(
          totalIncome
        )}</div></td></tr>`
        innerHtml += `<tr><td class="tooltip-chart pt-1"><div class="default">${intl.get(
          "currentStanding.chart.totalExpenses"
        )}</div></td><td class="tooltip-chart pt-1"><div class="default bolded currency">${numberFormat(
          totalExpense
        )}</div></td></tr>`
        innerHtml += `<tr><td class="tooltip-chart pt-1"><div class="default">${intl.get(
          "currentStanding.chart.pensionOutflow"
        )}</div></td><td class="tooltip-chart pt-1"><div class="default bolded currency">${numberFormat(
          pensionOutflow
        )}</div></td></tr>`
        innerHtml += `<tr><td class="tooltip-chart pt-1"><div class="default">${intl.get(
          "currentStanding.chart.savingOutflow"
        )}</div></td><td class="tooltip-chart pt-1"><div class="default bolded currency">${numberFormat(
          savingOutflow
        )}</div></td></tr>`
        innerHtml += `<tr>
    <td style="margin-top:6px;margin-bottom:0;padding:0;" >
    </td>
    <td style="margin:0;padding:0;">
        <hr style="margin-top:6px;margin-bottom:0;padding:0;" />
    </td>
</tr>`
        if (totalIncome > totalOutflow) {
          innerHtml += `<tr><td class="tooltip-chart pt-1 pb-1"><div class="default bolded">${intl.get(
            "currentStanding.chart.surplusCashflow"
          )}</div></td><td class="tooltip-chart pt-1"><div class="default bolded green currency">${numberFormat(
            totalIncome - totalOutflow
          )}</div></td></tr>`
        } else {
          innerHtml += `<tr><td class="tooltip-chart pt-1 pb-1"><div class="default bolded">${intl.get(
            "currentStanding.chart.deficitCashflow"
          )}</div></td><td class="tooltip-chart pt-1"><div class="default bolded red currency">- ${numberFormat(
            totalOutflow - totalIncome
          )}</div></td></tr>`
        }
        // }
        innerHtml += "</tbody>"

        const tableRoot = tooltipEl.querySelector("table")
        tableRoot.innerHTML = innerHtml
      }

      // `this` will be the overall tooltip
      const position = this._chart.canvas.getBoundingClientRect()

      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1
      tooltipEl.style.position = "absolute"
      const positionOfToolTipTop = clientChart ? 300 : 55

      tooltipEl.style.left = `${
        // position.left + window.pageXOffset + // open this if tooltip position in right
        tooltipModel.caretX + positionOfToolTipTop
      }px`
      tooltipEl.style.top = `${
        position.top + window.pageYOffset + tooltipModel.caretY - 150
      }px`

      tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily
      tooltipEl.style.fontSize = `${tooltipModel.bodyFontSize}px`
      tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle
      tooltipEl.style.padding = `${tooltipModel.yPadding}px ${tooltipModel.xPadding}px`
      tooltipEl.style.pointerEvents = "none"
      tooltipEl.style.zIndex = 99
    }
  })

  const calculateMargin = (cml) => {
    setSliderMargin({
      marginLeft: cml
    })
  }

  const calculateWidth = (rawData) => {
    if (rawData.lifeline !== undefined) {
      const dataCount = rawData.lifeline.length
      const segmentPercent = 100 / dataCount
      const totalNewWidthPercent =
        (dataCount - 1) * segmentPercent + segmentPercent / 2

      return { width: `${totalNewWidthPercent}%` }
    }

    return { width: `100%` }
  }

  const exportAsImage = async (imageFileName) => {
    let element = document.querySelector("#theChart")
    // document.body.style.zoom = "80%"
    //element.style.zoom = "80%";
    const canvas = await html2canvas(element)
    const image = canvas.toDataURL("image/png", 1.0)
    downloadImage(image, imageFileName)
  }

  const downloadImage = (blob, fileName) => {
    const fakeLink = window.document.createElement("a")
    fakeLink.style = "display:none;"
    fakeLink.download = fileName

    fakeLink.href = blob

    document.body.appendChild(fakeLink)
    fakeLink.click()
    document.body.removeChild(fakeLink)

    fakeLink.remove()
  }

  function chartToFile(id) {
    let canvas = document.getElementById(id)
    if (canvas) {
      let dataURL = canvas.toDataURL("image/jpg", 1.0)
      navigator.clipboard.writeText(dataURL)
      Alert.success("Image copied to clipboard!")
    }
  }

  return (
    <React.Fragment>
      <div className="lifeline-chart-container" id="theChartContainer">
        <button
          className="portal-btn portal-btn-submit fa fa-camera"
          onClick={(e) => exportAsImage("Lifeline")}
        ></button>
        &nbsp;
        <button
          className="portal-btn portal-btn-submit fa fa-save"
          onClick={(e) => chartToFile("theChart")}
        ></button>
        <Bar
          id="theChart"
          data={setChartData(
            data,
            currentAge,
            data.ageInfo && data.ageInfo.retirementAge
              ? data.ageInfo.retirementAge
              : 0
          )}
          options={{
            ...DefaultBarChartOptions,
            id: chartId,
            maintainAspectRatio: false,
            responsive: true,
            scales: {
              xAxes: [
                {
                  id: "x-axis-1",
                  stacked: true,
                  display: false,
                  barThickness: 10,
                  weight: 1
                },
                {
                  ticks: {
                    callback(value, index, values) {
                      return index % 10 === 0 ? value : ""
                    },
                    stepSize: 10,
                    autoSkip: false
                  },
                  gridLines: {
                    display: true,
                    drawBorder: true,
                    drawOnChartArea: false
                  },
                  id: "x-axis-0",
                  stacked: true,
                  display: true,
                  barThickness: 20,
                  weight: 10,
                  offset: true
                }
              ],
              yAxes: [
                {
                  gridLines: {
                    display: true,
                    drawBorder: true,
                    drawOnChartArea: false
                  },
                  ticks: {
                    beginAtZero: true,
                    callback(value) {
                      if (parseInt(value) >= 1000 || parseInt(value) <= -1000) {
                        return `${value
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                      }
                      return `${value}`
                    }
                  },
                  scaleLabel: {
                    display: true,
                    labelString: intl.get("currentStanding.chart.amount")
                  }
                }
              ]
            },
            tooltips: generateCustomTooltips(),
            layout: {
              padding: {
                top: 60
              }
            },
            legend: {
              position: "bottom",
              align: "center",
              display: true,
              labels: {
                padding: 25 // Adds space between the legend and chart
              }
            }
          }}
        />
      </div>

      {isShowSlider && (
        <div className="slider-1 pt-3" style={sliderMargin}>
          {wholelifelineData.lifelineObject1.client && (
            <div
              className="content-1"
              // style={calculateWidth(data)}
            >
              <span>Client</span>
              {currentAge !== 0 && data.lifeline !== undefined && (
                <Slider
                  min={currentAge} // current age
                  max={currentAge + data.lifeline.length - 1} // max 100 age for now
                  defaultValue={
                    wholelifelineData.lifelineObject1.client.ageInfo
                      .retirementAge
                  } // retirement age for selected
                  handle={handle}
                  onAfterChange={handleOnAfterChange}
                />
              )}
            </div>
          )}

          {wholelifelineData.lifelineObject.partner && (
            <div
              className="content-1"
              // style={calculateWidth(data)}
            >
              <span>Partner</span>

              <Slider
                min={currentAge} // current age
                max={currentAge + data.lifeline.length - 1} // max 100 age for now
                defaultValue={
                  wholelifelineData.lifelineObject1.partner.ageInfo
                    .retirementAge
                } // retirement age for selected
                handle={handle}
                onAfterChange={handleOnAfterChange1}
              />
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  )
}

export default LifelineChart
