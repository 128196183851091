import React, { useState, useEffect } from 'react';
import Alert from 'react-s-alert';
import { createUrlFromPath } from '../../helpers/ImageHelper';
import TransferApi from '../../api/TransferApi';




function TransferDialog({ id,  item, clearModal }) {
    const type = item.type.replace('BUILT_IN_', '');
    const directParent = {id:item.directParentId, name:item.directParentName};
    const info = item.info;
    const name = type=='CLIENT'?info.clientName : info[0];
    const [preview, setPreview] = useState();
    const [destList, setDestList] = useState([]);
    const [destId, setDestId] = useState('');
    const itemId = item.id;
    const requestTransfer = ()=>{
       let url = 'transfer';
       let sendData = {
            transferType: type=='CLIENT'|| type=='ADVISER'?type : 'ENTITY',
            itemId: itemId,
            sourceId:directParent.id,
            destId: destId,
       }
       TransferApi.requestTransfer(sendData).then((res)=>{
           Alert.success('Transfer request successfully submitted!'); 
           clearModal();
       }).catch(({ response }) => {
           Alert.error(response.data.errorMessage);
            
        });
    };

  

    useEffect(() => {
        if(type=='CLIENT'){
            TransferApi.getDealershipAdvisers(directParent.id).then(res=>{

                if(res.data){
                    let options = [];
                    options.push(<option key="" value=""></option>);
                    res.data.list.map((d) => {
                        options.push(<option key={d.key} value={d.key}>{d.name}</option>);
                    })
                    setDestList(options);
    
                }
              
            });
        }else {
            TransferApi.getDealershipEntities().then(res=>{

                if(res.data){
                    let options = [];
                    options.push(<option key="" value=""></option>);
                    res.data.list.map((d) => {
                        options.push(<option key={d.key} value={d.key}>{d.name}</option>);
                    })
                    setDestList(options);
    
                }
              
            });
        }
        
        setPreview(createUrlFromPath(item.currentPhotoUrl))
        
    }, item.id);
    return (
        <div className="portal-form-popup">
            <div className="row">
                <div className="col-md-8">
                    <h2>{name}</h2>
                </div>
                <div className="col d-flex justify-content-end ">
                    <img
                        className="photo-preview"
                        src={preview}
                        alt="preview"
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-md-2">
                    <label>From : </label>
                </div>
                <div className="col-md-10">
                    <label>{directParent.name}</label>
                    {/* <input type="text" value={directParent.name} readOnly={true}></input> */}
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-md-2">
                    <label>To :</label>
                </div>
                <div className="col-md-10 select-box ">
                    <select id="cmbDestList" value={destId} onChange={(e)=>setDestId(e.target.value)}>
                        {destList}
                    </select>
                </div>
            </div>
            

            <div className="row mt-4">
                <div className="col d-flex justify-content-end ">
                    <button className="portal-btn portal-btn-submit" onClick={requestTransfer}>Request Transfer</button>
                </div>
            </div>
           
        </div>
    );
}
export default TransferDialog;
