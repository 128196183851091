import React, { } from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { enumOptionsActions } from "../../../actions";
import { FormButtons, RenderSimpleFields } from "../../../components";
import { FieldType } from "../../../components/Form/RenderSimpleFields";
import arrayMutators from 'final-form-arrays';
import intl from 'react-intl-universal';
import CPFClassification from "./CPFClassification";

const CPFShare = ({ optionsData, handleSave, model = {} }) => {

    const CPFStockbrokerAndAccount = ({ isShowStockbrokerAndAccount }) => {
        if (!isShowStockbrokerAndAccount) {
            return <></>;
        }
        return (
            <RenderSimpleFields
                fields={
                    [
                        {
                            name: 'stockbrokerName',
                            label: intl.get('field.stockbrokerName'),
                            type: FieldType.TEXT,
                        },
                        {
                            name: 'stockbrokerAccountNumber',
                            label: intl.get('field.stockbrokerAccountNumber'),
                            type: FieldType.TEXT,
                        },
                    ]}
            />
        );
    }

    return (
        <div>
            <Form
                onSubmit={handleSave}
                initialValues={model}
                mutators={{
                    // potentially other mutators could be merged here
                    ...arrayMutators,
                }}
                initialValuesEqual={() => true}
                render={({
                    handleSubmit,
                    reset,
                    values
                }) => (
                        <form
                            id="cpfCashAccount"
                            onSubmit={handleSubmit}
                            noValidate
                        >
                            <div className="portal-form-popup">
                                <CPFClassification value={model.classification} disabled />
                                <div className="subtitle2">
                                    Share Details
                                </div>
                                <RenderSimpleFields
                                    fields={[
                                        {
                                            name: 'shareName',
                                            label: 'Share Name',
                                            type: FieldType.TEXT,
                                        },
                                        {
                                            name: 'hinSrnNumber',
                                            label: 'HIN/SRN #',
                                            type: FieldType.TEXT,
                                        },
                                        {
                                            name: 'tradingExchangeCountry',
                                            label: 'Trading Exchange Country',
                                            type: FieldType.TEXT,
                                        },
                                        {
                                            name: 'tickerCode',
                                            label: 'Ticker Code',
                                            type: FieldType.TEXT,
                                        },
                                        {
                                            name: 'exchangeIsoCode',
                                            label: 'Exchange ISO Code',
                                            type: FieldType.TEXT,
                                        },
                                        {
                                            name: 'yearPurchased',
                                            label: 'Year Purchased',
                                            type: FieldType.TEXT,
                                        },
                                        {
                                            name: 'numberOfUnitsHeld',
                                            label: 'Number of Shares Held',
                                            type: FieldType.PERCENTAGE,
                                        },
                                        {
                                            name: 'purchasePrice',
                                            label: 'Purchase Price',
                                            type: FieldType.MONEY,
                                            isMoney: true,
                                            currency: 'SGD',
                                        },
                                        {
                                            name: 'currentValue',
                                            label: 'Current Value',
                                            type: FieldType.MONEY,
                                            isMoney: true,
                                            currency: 'SGD',
                                        },
                                        {
                                            name: 'valuationDate',
                                            label: 'Valuation Date',
                                            type: FieldType.DATE,
                                        },
                                        {
                                            name: 'brokageFee',
                                            label: 'Brokerage Fee',
                                            type: FieldType.MONEY_WITH_PERCENTAGE,
                                            getPercentageValueSource: () => values.currentValue,
                                            currency: 'SGD',
                                            allowNegative: false,
                                        },
                                        {
                                            name: 'annualGrowthRate',
                                            label: 'Annual Growth Rate',
                                            type: FieldType.PERCENTAGE,
                                        },
                                        {
                                            name: 'annualDividendYield',
                                            label: 'Annual Dividen Yield',
                                            type: FieldType.PERCENTAGE,
                                        },
                                        {
                                            name: 'dividendFrequency',
                                            label: 'Divident Frequency',
                                            type: FieldType.ENUM_SELECTION,
                                            enumData: optionsData.frequencyOptions,
                                        },
                                        {
                                            name: 'lastDividendPaymentDate',
                                            label: 'Last Divident Payment Date',
                                            type: FieldType.DATE,
                                        },
                                        {
                                            name: 'annualTotalReturn',
                                            label: 'Annual Total Return',
                                            type: FieldType.PERCENTAGE,
                                        },
                                        {
                                            name: 'hasStockbrokerAndAccount',
                                            label: 'Do you have an existing stock broker and accountant?',
                                            type: FieldType.CHECKBOX,
                                        },
                                    ]}
                                />

                                <CPFStockbrokerAndAccount
                                    isShowStockbrokerAndAccount={values.hasStockbrokerAndAccount}
                                />

                            </div>
                            <FormButtons
                                reset={reset}
                            />
                        </form>
                    )}
            />
        </div>


    )
}


function mapStateToProps(state) {
    const { optionsData } = state.enumOptions;

    return {
        optionsData,
    };
}

function mapDispatchToProps(dispatch) {

    return bindActionCreators(enumOptionsActions, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CPFShare);