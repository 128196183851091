/*
    Author : Marlina (marlina@devstack.com.au)
    Description : Item in insurance dashboard
*/

import React from "react"
import intl from "react-intl-universal"
import moment from "moment"
import { getSymbolFromCode, numberWithCommas } from "../../../helpers"

function InsuranceCard({
  data,
  deleteInsurance,
  showForm,
  showDialog,
  currency,
  type
}) {
  const checkforGeneralInsuranceTypes =
    type === "motorVehicleInsurances" ||
    type === "personalContentsInsurances" ||
    type === "collectibleInsurances" ||
    type === "loanInsurances" ||
    type === "fireAndContentsInsurances"
  return (
    <div className="insurance-box-holder">
      <div className="insurance-box">
        <div className="title">
          {intl.get("insurance.card.policyNumber")}:{" "}
          {data.policyNumber || data.uniqueId}
        </div>
        <div className="content">
          <div className="text">
            <div className="text-L">
              {intl.get("insurance.card.policyType")}:
            </div>
            <div className="text-R">{data.policyType}</div>
          </div>
          {!checkforGeneralInsuranceTypes && (
            <span
              onClick={() => {
                showDialog({
                  message: `You're about to delete insurance (${data.policyNumber}). Are you sure?`,
                  onYes: deleteInsurance
                })
              }}
              className="btn-remove"
            />
          )}
          {data.premium > 0 && (
            <div className="text">
              <div className="text-L text-bold ">
                {intl.get("insurance.card.premium")}:
              </div>
              <div className="text-R text-bold ">
                {currency
                  ? getSymbolFromCode(currency)
                  : getSymbolFromCode("MYR")}
                {numberWithCommas(data.premium)}
              </div>
            </div>
          )}
          <div className="text">
            <div className="text-L">{intl.get("insurance.card.coverage")}:</div>
            <div className="text-R text-bold ">
              {currency
                ? getSymbolFromCode(currency)
                : getSymbolFromCode("MYR")}
              {numberWithCommas(data.coverage)}
            </div>
          </div>
          <div className="text">
            <div className="text-L">
              {intl.get("insurance.card.maturityDate")}:
            </div>
            <div className="text-R">
              {data.maturityDate
                ? moment(data.maturityDate, "YYYY-MM-DD").format("DD MMMM YYYY")
                : "-"}
            </div>
          </div>
          <div className="text">
            <div className="text-L">
              {intl.get("insurance.card.paymentMethod")}:
            </div>
            <div className="text-R text-uppercase">
              {data.paymentType || "-"}
            </div>
          </div>
          <span onClick={showForm} className="btn btn-edit" />
        </div>
      </div>
    </div>
  )
}

export default InsuranceCard
