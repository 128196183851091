import { manageAccessLevelAction } from '../constants/ActionConstants';
import api from '../lib/api';

export const manageAccessLevelActions = {
    getAccessLevelEntity,
    saveAccessLevelEntity,
};

export function getAccessLevelEntitySuccess(entity) {
    return {
        type: manageAccessLevelAction.GET_ACCESS_LEVEL_ENTITY_SUCCESS,
        entity,
    };
}

export function getAccessLevelEntity(entityId) {
    return async dispatch => {
        const response = await api.get(`/entity/${entityId}`);
        dispatch(getAccessLevelEntitySuccess(response.data));
    };
}

export function saveAccessLevelEntitySuccess(entity) {
    return {
        type: manageAccessLevelAction.SAVE_ACCESS_LEVEL_ENTITY_SUCCESS,
        entity,
    };
}

export function saveAccessLevelEntity(entity) {
    return async dispatch => {
        const response = await api.put(`/entity/${entity.id}`, entity);
        dispatch(saveAccessLevelEntitySuccess(response.data));
    };
}
